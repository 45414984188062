import { useDispatch, useSelector } from "react-redux";
import "./../PaymentReadyPage/PaymentReadyPage.scss";
import { GlobalState } from "../../Reducers/RootReducer";
import { CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { IDebitCreditRoadtax } from "../../Models/IRoadtax";
import { RoadTaxActions } from "../../Reducers/RoadTax/RoadTaxAction";
import { userRoleFits } from "../../Utils/Session";
import PayPalSubscription from "../../Components/PayPalSubscription/PayPalSubscription";
import PayPalPaymentOnce from "../../Components/PaypalPaymentOnce/PaypalPaymentOnce";

const PaymentDebitPage = () => {
  const dispatch = useDispatch();
  const roadTaxList: any = useSelector(
    (state: GlobalState) => state.roadtax.roadTaxList
  );
  const debitCost: number = useSelector(
    (state: GlobalState) => state.roadtax.debitCost
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSent, setPaymentSent] = useState<boolean>(false);
  const [paypalMode, setPaypalMode] = useState<boolean>(true);
  const [paypalPayment, setPaypalPayment] = useState<boolean>(false);
  const [navigationLink, setNavigationLink] = useState<string>("");

  const chechUserAsDebitOrCredit = () => {
    ApiService.VehicleRoadTaxController.RoadTaxCreditDebitGet(
      true,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let totalCost: number = 0;
          for (let i = 0; i < response.payload.length; i++) {
            if (response.payload[i].creditDebit) {
              totalCost = totalCost + response.payload[i].creditDebit;
            }
          }
          dispatch(
            RoadTaxActions.setRoadTaxList(
              response.payload.map((x: IDebitCreditRoadtax) => {
                return x.roadTaxID;
              })
            )
          );
          dispatch(RoadTaxActions.setDebitCost(totalCost));
        }
      }
    );
  };

  const applyRoadTaxPayment = (paypalOrderID: string) => {
    setLoading(true);
    let data = {
      roadTaxList: roadTaxList.map((x: number) => {
        return {
          roadTaxID: +x,
        };
      }),
      paypalOrderID: paypalOrderID,
    };
    ApiService.PaymentsRoadTaxController.RoadTaxPaymentsSendUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentSent(true);
        } else {
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
          setPaymentSent(false);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (debitCost === 0) {
      chechUserAsDebitOrCredit();
    }
  }, []);

  useEffect(() => {
    let navigationLink: string = "";
    if (userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) {
      navigationLink = AppRoutes.DASHBOARD___MESSAGING_ROUTE;
    } else {
      navigationLink = AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE;
    }
    setNavigationLink(navigationLink);
  }, [paymentSent]);

  return (
    <div className="payment-page-wrapper">
      <div>
        <h2 className="payment-header">
          <div>
            {" "}
            {i18next.t("navigation:payment_amount") + " "}
            {Math.abs(debitCost) + " €"}{" "}
          </div>
          <small>
            {i18next.t("navigation:payment_amount_paypal_tax") + " 3%"}
          </small>
          <br />
          <small>
            {i18next.t("navigation:payment_amount") + " tot: "}
            {Math.abs(debitCost * 1.03).toFixed(2) + " €"}{" "}
          </small>
        </h2>
      </div>
      <div>
        {paypalMode && (
          <div
            style={{
              display: "flex",
              minWidth: "40vw",
              justifyContent: "space-around",
            }}
          >
            <PayPalPaymentOnce
              price={Math.abs(debitCost * 1.03).toFixed(2)}
              onPaymentDone={(data: any) => {
                setPaypalPayment(true);
                setPaypalMode(false);
                applyRoadTaxPayment(data.data.orderID);
              }}
            />
          </div>
        )}
        {!paypalMode && (
          <div>
            {loading && (
              <div className="payment-page-spinner-loader">
                <div className="payment-page-spinner-loader-label">
                  {i18next.t("navigation:payment_processing")}
                </div>
                <CircularProgress />
              </div>
            )}
            {!loading && paymentSent && (
              <div className="payment-page-spinner-loader">
                <div className="payment-page-spinner-loader-label">
                  {i18next.t("navigation:payment_processing_done")}
                  <div className="payment-page-spinner-loader-sub-label">
                    {!paypalPayment &&
                      i18next.t("navigation:payment_bonifico_pending")}
                    {paypalPayment &&
                      i18next.t("navigation:payment_paypal_done")}
                  </div>
                  <DoneOutlineIcon />

                  <Link
                    to={AppRoutes.INTERNAL_DASHBOARD + navigationLink}
                    style={{ marginTop: "0.5em", fontSize: "0.5em" }}
                  >
                    {i18next.t("navigation:payment_done_go_to_dashboard")}
                  </Link>
                </div>
              </div>
            )}
            {!loading && !paymentSent && (
              <div className="payment-page-spinner-loader">
                <div className="payment-page-spinner-loader-label red">
                  {i18next.t("navigation:payment_processing_error")}
                  <ReportGmailerrorredIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDebitPage;
