import "./Suppliers__PurchasesPage_sub.scss";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useEffect, useState } from "react";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import StepFleetPlan from "../../../../../Components/StepFleetPlan/StepFleetPlan";
import { IDebitCreditRoadtax } from "../../../../../Models/IRoadtax";
import { RoadTaxActions } from "../../../../../Reducers/RoadTax/RoadTaxAction";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { IPaymentInsert, IPaymentType } from "../../../../../Models/IPayment";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IAddedService } from "../../../../../Models/IService";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { writePaymentData } from "../../../../PaymentReadyPage/PaymentReadyPage";
import { triggerFormValidation } from "../../../../../Components/MultiForm/MultiForm";
import i18next from "i18next";

export interface IPurchaseList {
  purchaseID: number;
  titlePurchase: string;
  descriptionPurchase: string;
  costPurchase: number;
  stored: boolean;
}

var dataClone: any = {};
var billingInfoClone: any = {};

const Suppliers__PurchasesPage_sub = () => {
  const dispatch = useDispatch();
  const [data, setData_] = useState<any>({
    price: -1,
  });
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);

  const [billingInfos, setBillingInfos_] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSent, setPaymentSent] = useState<boolean>(false);
  const [paypalMode, setPaypalMode] = useState<boolean>(true);
  const roadTaxList: any = useSelector(
    (state: GlobalState) => state.roadtax.roadTaxList
  );
  const [loadingPaymentPending, setLoadingPaymentPending] = useState<boolean>(false);
  const [paymentNumPending, setPaymentNumPending] = useState<number>(0);  
  const setData = (data: any) => {
    dataClone = { ...data };
    setData_(data);
  };

  const setBillingInfos = (infos: any) => {
    billingInfoClone = { ...infos };
    setBillingInfos_(infos);
  };

  const [discountVoucher, setDiscountVoucher] = useState<any>({});

  const getFleetPlans: IFleetPlan[] | undefined = useSelector(
    (state: GlobalState) => state.registration.fleetPlans
  );

  const getAddedServices: IAddedService[] | undefined = useSelector(
    (state: GlobalState) => state.registration.addedServices
  );

  const creditCost: any = useSelector(
    (state: GlobalState) => state.roadtax.creditCost
  );
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const applyPayment = (data: IPaymentInsert) => {
    setLoading(true);
    if (data?.accountID) {
      data["accountID"] = data.accountID;
    }
    ApiService.PaymentController.InsertPayment(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentSent(true);
        } else {
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
          setPaymentSent(false);
        }
        setLoading(false);
      }
    );
  };

  const chechUserAsCredit = () => {
    ApiService.VehicleRoadTaxController.RoadTaxCreditDebitGet(
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let totalCost: number = 0;
          // let response: any = {
          //   payload: [
          //     {
          //       roadTaxID: 1,
          //       vehicleID: 0,
          //       creditDebit: 20
          //     },
          //     {
          //       roadTaxID: 2,
          //       vehicleID: 0,
          //       creditDebit: 2090
          //     },
          //   ]
          // }
          for (let i = 0; i < response.payload.length; i++) {
            if (response.payload[i].creditDebit) {
              totalCost = totalCost + response.payload[i].creditDebit;
            }
          }
          dispatch(
            RoadTaxActions.setRoadTaxList(
              response.payload.map((x: IDebitCreditRoadtax) => {
                return x.roadTaxID;
              })
            )
          );
          dispatch(RoadTaxActions.setCreditCost(totalCost));
        }
      }
    );
  };

  const getPaymentNumPending = () => {
    setLoadingPaymentPending(true);
    ApiService.PaymentController.GetPaymentNumPending(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentNumPending(response.payload);
        }else{
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
        }
        setLoadingPaymentPending(false);
      }
    );
  }

  useEffect(() => {
    if (data) {
      chechUserAsCredit();
    }
  }, [data]);

  useEffect(() => {
    if (data && creditCost > 0) {
      let data_ = { ...data };
      data_["price"] = data_["price"] - creditCost;
      if (data_["price"] <= -1) {
        data_["price"] = 0;
      }
      setData(data_);
    }
  }, [creditCost]);

  const handlePaymentReadyToGo = () => {
    writePaymentData({
      ...dataClone,
      ...billingInfoClone,
      stepIndex: -1,
    });

    navigate(AppRoutes.PAYMENT_READY_PAGE);
  };

  useEffect(() => {
    // Add event listener for payment-ready-to-go event
    getPaymentNumPending();
    document.addEventListener("payment-ready-to-go", handlePaymentReadyToGo);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "payment-ready-to-go",
        handlePaymentReadyToGo
      );
    };
  }, []);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_add_services_purchases_sub")}
      />
      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined"></ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
            {paymentNumPending === 0 ?
              <div>
                <StepFleetPlan
                  includeBillingForm
                  emitBillingInfos={(data: any) => {
                    setBillingInfos(data);
                  }}
                  emitState={(dt: any) => {
                    setData(dt);
                    setPage(1);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    paddingRight: "2em",
                  }}
                >
                  <Button
                    disabled={billingInfos && billingInfos?.paymentTypeID === 0}
                    variant="contained"
                    onClick={() => {
                      triggerFormValidation("payment-page-form");
                    }}
                  >
                    {i18next.t("navigation:accept")}
                  </Button>
                </div>
              </div>
            :
             <Alert severity="warning">
                {i18next.t("navigation:payment_wire_transfer_pending")}
              </Alert>   
            }
            {page === 0 && parseInt(data?.price) === 0 && (
            <div style={{ marginTop: "1em" }}>
              <Alert severity="info">
                {i18next.t("navigation:select_something_to_buy_first")}
              </Alert>
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Suppliers__PurchasesPage_sub;
