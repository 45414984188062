/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";
import dayjs from "dayjs";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* MODELS */
import { IFuelAnomalyStatistics } from "../../../../../Models/IStatistics";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import MultiForm, {
  IMultiFormField,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import DoneIcon from "@mui/icons-material/Done";

/* COMPONENT */
const FuelAnomaly__StatisticsPage_Data = () => {
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>({});
  const [responseData, setResponseData] = useState<IFuelAnomalyStatistics[]>([]);
  const [rowsDef, setRowsDef] = useState<any>([]);
  // Loading
  const [loadingFuelAnomalies, setLoadingFuelAnomalies] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Load Fuel Anomalies
  const loadStatisticsFuelAnomalies = (from: Date | string, to: Date | string) => {
    setLoadingFuelAnomalies(true);

    ApiService.StatisticsController.getStatisticsFuelAnomalies(
      from,
      to,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setResponseData(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingFuelAnomalies(false);
      }
    );
  };

  /* FUNCTIONS */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      // From
      {
        width: 50,
        type: "date",
        size: "small",
        name: "statisticsFuelDateFrom",
        defaultValue: state?.statisticsFuelDateFrom,
        currentValue: state?.statisticsFuelDateFrom,
        required: state?.statisticsFuelDateTo ? true : false,
        label: String(i18next.t(`form:from`)),
        maxDate: dayjs(new Date().toISOString()),
      },
      // To
      {
        width: 50,
        type: "date",
        size: "small",
        name: "statisticsFuelDateTo",
        defaultValue: state?.statisticsFuelDateTo,
        currentValue: state?.statisticsFuelDateTo,
        disabled: state?.statisticsFuelDateFrom ? false : true,
        required: state?.statisticsFuelDateFrom ? true : false,
        label: String(i18next.t(`form:to`)),
        minDate: state?.statisticsFuelDateFrom,
        maxDate: dayjs(new Date().toISOString()),
      }
    );

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {props.value}
        </Link>
      </div>
    );
  };

  // Render value
  const renderValue = (props: GridRenderCellParams) => {
    if (props.value === true) {
      return (
        <div>
          <DoneIcon style={{ color: "#009fe3" }} />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  // Render data from API
  const getData = (months?: number) => {
    if (state?.statisticsFuelDateFrom && state?.statisticsFuelDateTo) {
      loadStatisticsFuelAnomalies(
        state?.statisticsFuelDateFrom,
        state?.statisticsFuelDateTo
      );
    } else if (months) {
      const today = new Date();
      const todayISO = new Date().toISOString();
      const monthsAgo = new Date(today.setMonth(today.getMonth() - months)).toISOString();

      loadStatisticsFuelAnomalies(todayISO, monthsAgo);
    }

    setRowsDef(
      responseData.map((x: any, i: number) => {
        // Set name
        let name = "";
        if (x.lastAssigneeName) {
          name += x.lastAssigneeName;
        }
        if (x.lastAssigneeSurname) {
          name += " " + x.lastAssigneeSurname;
        }

        // Return row
        return {
          id: i,
          vehicleID: x.vehicleID,
          plate: formatPlate(x.vehicleLicencePlate),
          model: x.vehicleModel?.replace(/\s\s+/g, " "),
          lastDriver: name.replace(/\s\s+/g, " ") || "-",
          change: x.isAssigneeChanged,
          excess: x.isExcessFuelSupply,
          improper_fuel: x.isImproperFuelType,
          improper_km: x.isImproperFuelSupplyOnKM,
          daily: x.isDailyFuelSupply,
          months: x.isMonthsFuelSupply,
        };
      })
    );
  };

  /* USE EFFECT HOOK */
  // Render for period selection
  useEffect(() => {
    getData();
  }, [state, loadingFuelAnomalies]);

  // Column
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "lastDriver",
      headerName: i18next.t("navigation:_statistics_anomaly_lastDriver"),
    },
    {
      field: "change",
      headerName: i18next.t("navigation:_statistics_anomaly_change"),
      renderCell: renderValue,
      type: "number"
    },
    {
      field: "excess",
      headerName: i18next.t("navigation:_statistics_anomaly_excess"),
      renderCell: renderValue,
      type: "number"
    },
    {
      field: "improper_fuel",
      headerName: i18next.t("navigation:_statistics_anomaly_improper_fuel"),
      renderCell: renderValue,
      type: "number"
    },
    {
      field: "improper_km",
      headerName: i18next.t("navigation:_statistics_anomaly_improper_km"),
      renderCell: renderValue,
      type: "number"
    },
    {
      field: "daily",
      headerName: i18next.t("navigation:_statistics_anomaly_daily"),
      renderCell: renderValue,
      type: "number"
    },
    {
      field: "months",
      headerName: i18next.t("navigation:_statistics_projection_contract_months"),
      renderCell: renderValue,
      type: "number"
    },
  ];

  /* RETURN */
  return (
    <Box className="CO2Emission__minHeight">
      <div className="display-flex-horizontal-space-between">
        <div>
          <MultiForm
            suppressLayout
            classNameForm="multi-form-flex"
            suppressSubmit={true}
            formId={"FormFuelDatePickerRange"}
            formTitle={"FormFuelDatePickerRange"}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState(data);
            }}
          />
        </div>

        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button onClick={() => getData(1)}>{i18next.t("navigation:lastMonth")}</Button>
          <Button onClick={() => getData(6)}>
            {i18next.t("navigation:last6Months")}
          </Button>
          <Button onClick={() => getData(12)}>
            {i18next.t("navigation:last12Months")}
          </Button>
        </ButtonGroup>
      </div>

      {!loadingFuelAnomalies && <DataGridWrap headers={columnsDef} rows={rowsDef} />}

      <LoaderBackdrop loading={loadingFuelAnomalies} />
    </Box>
  );
};

export default FuelAnomaly__StatisticsPage_Data;
