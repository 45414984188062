import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleReservationIncomingInsert,
  IVehicleReservationIncomingUpdate,
} from "../../Models/IVehicleReservationIncoming";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleReservationIncomingController = {
  VehicleReservationIncomingGet: async (
    companyID: number,
    vehicleReservationID: number,
    vehicleID: number,
    incomingVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_INCOMING_GET;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleReservationID) {
      parameters.push(`vehicleReservationID=${vehicleReservationID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (incomingVehicleID) {
      parameters.push(`incomingVehicleID=${incomingVehicleID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationIncomingInsert: async (
    body: IVehicleReservationIncomingInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_INCOMING_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationIncomingUpdate: async (
    body: IVehicleReservationIncomingUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_INCOMING_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationIncomingDelete: async (
    incomingVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_INCOMING_DELETE;
    request.method = "DELETE";
    request.payload = {
      incomingVehicleID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleReservationIncomingController;
