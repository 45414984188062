import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IVehicleContractGet } from "../../Models/IContract";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleContractController = {
  VehicleContractGet: async (
    vehicleID: number,
    vehicleContractID: number,
    stored?: number | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_GET;
    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (vehicleContractID) {
      parameters.push(`vehicleContractID=${vehicleContractID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractInsert: async (
    body: IVehicleContractGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_INSERT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractVersioning: async (
    vehicleContractID: number,
    body: IVehicleContractGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url =
      ApiEndPoints.VEHICLE_CONTRACT_INSERT +
      "?contractToStoreID=" +
      vehicleContractID;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractUpdate: async (
    body: IVehicleContractGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_UPDATE;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractDelete: async (
    vehicleContractID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_DELETE;
    request.payload = {
      vehicleContractID,
    };
    request.method = "DELETE";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractServiceGet: async (
    vehicleContractID: number,
    vehicleContractServiceID?: number,
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_GET_SERVICE;
    let parameters: string[] = [];
    if (vehicleContractID) {
      parameters.push(`vehicleContractID=${vehicleContractID}`);
    }
    if (vehicleContractServiceID) {
      parameters.push(`vehicleContractServiceID=${vehicleContractServiceID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractServiceInsert: async (
    body: IVehicleContractGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_INSERT_SERVICE;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractServiceUpdate: async (
    body: IVehicleContractGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_UPDATE_SERVICE;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleContractServiceDelete: async (
    vehicleContractServiceID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CONTRACT_DELETE_SERVICE;
    request.method = "DELETE";
    request.payload = {
      vehicleContractServiceID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleContractController;
