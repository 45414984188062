import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IFleetPlanMostConvenient } from "../../Models/IFleetPlan";
import { IFleetPlanSetBody } from "../../Models/IFleetPlanSetBody";
import { IFleetPlanUpdateBody } from "../../Models/IFleetPlanUpdateBody";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const FleetPlanController = {
  FleetPlanGetData: async (
    fleetPlanID?: number | null,
    stored?: boolean,
    allTranslations?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FLEET_PLAN_GET_DATA;
    let parameters: string[] = [];
    request.method = "GET";
    if (fleetPlanID) {
      parameters.push(`fleetPlanID=${fleetPlanID}`);
    }
    if (allTranslations !== undefined) {
      parameters.push(`allTranslations=${allTranslations}`);
    }
    if (stored !== undefined) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FleetPlanDeleteData: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FLEET_PLAN_DELETE_DATA;
    request.method = "DELETE";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FleetPlanSetData: async (
    body: IFleetPlanSetBody,
    fleetPlanID?: number,
    callback?: (resonse: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FLEET_PLAN_SET_DATA;
    if (fleetPlanID) {
      request.url += "?fleetPlanID=" + fleetPlanID;
    }
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FleetPlanUpdateSetData: async (
    body: IFleetPlanUpdateBody,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FLEET_PLAN_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  checkMostConvenientFleetPlan: async (
    body: IFleetPlanMostConvenient,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FLEET_PLAN_GET_MOST_CONVENIENT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FleetPlanController;
