import { lazy, Suspense } from "react";

const LazyMarkdownContent = lazy(() => import("./MarkdownContent"));

export const MarkdownContent: React.FC<{
  fallback?: React.ReactNode;
  filename: string;
}> = (props) => (
  <Suspense fallback={null}>
    <LazyMarkdownContent {...props} />
  </Suspense>
);
