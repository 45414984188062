/* REACT */
import { useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* MUI */
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/* COMPONENTS */
import OptionalGrid from "./OptionalGrid";
import PackageGrid from "./PackageGrid";
import ColorsGrid from "./ColorsGrid";

/* INTERFACES */
interface ITabsAddOptionals {
  groupVehicleID: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/* COMPONENT */
const TabsAddOptionals = (props: ITabsAddOptionals) => {
  /* STATE */
  const [value, setValue] = useState<number>(0);
  /* FUNCTIONS */
  // Controlled change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Customize tab
  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    // Return
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  /* RETURN */
  return (
    <div className="modal-width-extralarge">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={i18next.t("form:optional")} {...a11yProps(0)} />
          <Tab label={i18next.t("form:package")} {...a11yProps(1)} />
          <Tab label={i18next.t("form:colors")} {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <OptionalGrid groupVehicleID={props.groupVehicleID} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <PackageGrid groupVehicleID={props.groupVehicleID} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <ColorsGrid groupVehicleID={props.groupVehicleID} />
      </CustomTabPanel>
    </div>
  );
};

export default TabsAddOptionals;
