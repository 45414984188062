import i18next from "i18next";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import SmartModal, { SummonModal, DismissModal } from "../SmartModal/SmartModal";
import { IDriverGet } from "../../Models/IDriver";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import "./FleetReservationForm.scss";
import { useEffect, useState } from "react";
import FormAssignedDriver from "../StepAssignedDrivers/FormAssignedDriver";
import { useSelector } from "react-redux";
import { ICompanyGet } from "../../Models/ICompany";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  IVehicleReservation,
  IVehicleReservationAvailableVehicle,
  IVehicleReservationInsert,
  IVehicleReservationUpdate,
} from "../../Models/IVehicleReservation";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { dateToUTC0_ISO } from "../MultiForm/SpecialInputs/StrongDatePicker";

export interface IFleetReservationFormProps {
  modalUniqueId?: string;
  vehicles?: IVehicleDataGet[];
  preSelectedData?: IVehicleReservation;
  inModal?: boolean;
  loadDataBeforeDismiss?: (data: IVehicleReservation) => void;
  reservationDates?: IVehicleReservation[];
  redirectTo?: boolean;
}

const FleetReservationForm = (props: IFleetReservationFormProps) => {
  const navigate = useNavigate();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [state, setState] = useState<any>(props.preSelectedData ?? {});
  const [currentVehicle, setCurrentVehicle] = useState<IVehicleDataGet>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingAvailableVehicles, setLoadingAvailableVehicles] =
    useState<boolean>(false);
  const [availableVehicles, setAvailableVehicles] = useState<
    IVehicleReservationAvailableVehicle[]
  >([]);
  const [disablePlateOption, setDisablePlateOption] = useState<boolean>(true);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);

  const loadingDrivers = () => {
    if (loggedCompany) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDriverList(false);
      });
    }
  };

  const loadAvailableVehicles = (startDate: string, endDate: string) => {
    if (loggedCompany) {
      setLoadingAvailableVehicles(true);
      ApiService.VehicleReservationController.VehicleReservationGetAvailableVehicle(
        startDate,
        endDate,
        loggedCompany.companyID,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAvailableVehicles(response.payload);
            if (response.payload && response.payload.length > 0) {
              setDisablePlateOption(false);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAvailableVehicles(false);
        }
      );
    }
  };

  const insertReservation = (data: IVehicleReservationInsert) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.VehicleReservationController.VehicleReservationInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationSuccessfullAdded"),
              "success"
            );
            if (props.inModal) {
              if (props.loadDataBeforeDismiss && response.payload.length > 0) {
                props.loadDataBeforeDismiss(response.payload[0]);
              }
              DismissModal("new-booking-vehicle-modal");
            }

            if(props.redirectTo){
              navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET);
              return;
            }
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateReservation = (data: IVehicleReservationUpdate) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.VehicleReservationController.VehicleReservationUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationSuccessfullUpdated"),
              "success"
            );
            if (props.inModal) {
              DismissModal("new-booking-vehicle-modal");
            }
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  useEffect(() => {
    loadingDrivers();
  }, []);

  useEffect(() => {
    if (state && state?.vehicleID) {
      let currentVehicle_ = (props.vehicles ?? []).filter(
        (x: IVehicleDataGet) => x.vehicleID === state?.vehicleID
      );
      if (currentVehicle_ && currentVehicle_.length > 0) {
        setCurrentVehicle(currentVehicle_[0]);
      }
    }
  }, [state.vehicleID]);

  useEffect(() => {
    if (state?.reservationDTFrom && state?.reservationDTTo) {
      loadAvailableVehicles(state?.reservationDTFrom, state?.reservationDTTo);
      setDisablePlateOption(false);
    }
  }, [state?.reservationDTFrom, state?.reservationDTTo]);

  return (
    <div>
      <MultiForm
        suppressLayout
        suppressSubmit
        formId="fleet-reservation-form"
        inputs={[
          {
            type: "select",
            name: "firstDriverID",
            width: 33.33,
            label: i18next.t("form:firstDriver") as string,
            required: true,
            defaultValue: state?.firstDriverID,
            options: (driverList ?? []).map((x: IDriverGet) => {
              let titleDriver = x.driverInfo.driverName;
              if (x.driverInfo?.driverSurname) {
                titleDriver += " " + x.driverInfo.driverSurname;
              }
              return {
                key: x.driverInfo.driverID,
                text: titleDriver.toString(),
              };
            }),
          },
          {
            type: "select",
            name: "secondDriverID",
            width: 33.33,
            label: i18next.t("form:secondDriver") as string,
            disabled: !state?.firstDriverID,
            defaultValue: state?.secondDriverID,
            options: (
              driverList?.filter(
                (x: IDriverGet) => x.driverInfo.driverID !== state?.firstDriverID
              ) ?? []
            ).map((x: IDriverGet) => {
              let titleDriver = x.driverInfo.driverName;
              if (x.driverInfo?.driverSurname) {
                titleDriver += " " + x.driverInfo.driverSurname;
              }
              return {
                key: x.driverInfo.driverID,
                text: titleDriver.toString(),
              };
            }),
          },
          {
            width: 10,
            type: "custom",
            name: "AssignDriver",
            element: (
              <div className="multi-form-button-add-record">
                <Button
                  variant="contained"
                  onClick={() => {
                    SummonModal("fleet-driver-page-modal");
                  }}
                >
                  <AddIcon />
                </Button>
              </div>
            ),
          },
          {
            type: "radio",
            name: "isReservationDefinitive",
            width: 23.33,
            required: true,
            tooltip: { title: i18next.t("message:bookingTypeTooltipInfo") },
            label: i18next.t("form:bookingType") as string,
            defaultValue: state?.isReservationDefinitive,
            options: [
              {
                key: 1,
                text: i18next.t("message:yes"),
              },
              {
                key: 0,
                text: i18next.t("message:no"),
              },
            ],
          },
          {
            type: "date",
            name: "reservationDTFrom",
            label: (state?.isReservationDefinitive &&
            state?.isReservationDefinitive.toString() === "1"
              ? i18next.t("form:dateOutgoing")
              : i18next.t("form:dateOutgoingPrev")) as string,
            required: true,
            maxDate: state?.reservationDTTo,
            defaultValue:
              props.preSelectedData && props.preSelectedData?.reservationDTFrom
                ? props.preSelectedData?.reservationDTFrom
                : state?.reservationDTFrom,
            width: 33.33,
          },
          {
            type: "date",
            minDate: state?.reservationDTFrom,
            name: "reservationDTTo",
            defaultValue: state?.reservationDTTo,
            label: (state?.isReservationDefinitive &&
            state?.isReservationDefinitive.toString() === "1"
              ? i18next.t("form:dateIncoming")
              : i18next.t("form:dateIncomingPrev")) as string,
            required: true,
            width: 33.33,
          },
          {
            type: "select",
            name: "vehicleID",
            required: true,
            width: 33.33,
            disabled: disablePlateOption,
            label: i18next.t("form:plate") as string,
            defaultValue:
              props.preSelectedData && props.preSelectedData?.vehicleID
                ? props.preSelectedData?.vehicleID
                : state?.vehicleID,
            options: availableVehicles.map((x: IVehicleReservationAvailableVehicle) => {
              return {
                key: x.vehicleID,
                text: formatPlate(x.vehicleLicensePlate),
              };
            }),
          },
          {
            type: "custom",
            name: "descriptionVehicle",
            width: 100,
            element: currentVehicle && (
              <div className="fleet-description-vehicle">
                <div>
                  <span>{i18next.t("form:orderBrand")}</span>
                  <span>{currentVehicle.brandDescription}</span>
                </div>
                <div>
                  <span>{i18next.t("form:orderModel")}</span>
                  <span>{currentVehicle.modelDescription}</span>
                </div>
                <div>
                  <span>{i18next.t("form:orderEquipment")}</span>
                  <span>{currentVehicle.fittingDescription}</span>
                </div>
                <div>
                  <span>{i18next.t("form:engineCapacity")}</span>
                  <span>{currentVehicle.engineCapacity}</span>
                </div>
              </div>
            ),
          },
          {
            width: 100,
            type: "multiline",
            name: "motivation",
            className: "container-add-textarea",
            multilineRows: 5,
            defaultValue: state?.motivation,
            label: String(i18next.t("form:motivation")),
          },
        ]}
        onChange={(data: any) => {
          setState(data);
        }}
        onSubmit={(data: any) => {
          let parseData = { ...data };
          parseData["isReservationDefinitive"] =
            data["isReservationDefinitive"] === "1" ? true : false;
          delete parseData["AssignDriver"];
          delete parseData["descriptionVehicle"];
          
          let reservationDTFrom: any = new Date(parseData["reservationDTFrom"]);
          reservationDTFrom = reservationDTFrom.setHours(0,0,0,0);
          parseData["reservationDTFrom"] = dateToUTC0_ISO(new Date(reservationDTFrom));
          let reservationDTTo: any = new Date(parseData["reservationDTTo"]);
          reservationDTTo = reservationDTTo.setHours(0,0,0,0);
          parseData["reservationDTTo"] = dateToUTC0_ISO(new Date(reservationDTTo));
          if (state?.vehicleReservationID) {
            updateReservation(parseData);
          } else {
            insertReservation(parseData);
          }
        }}
      />
      <div className="padding-small">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              triggerFormValidation("fleet-reservation-form");
            }}
            disabled={processing}
          >
            {i18next.t("navigation:bookingVehicle")}
          </Button>
          {props.inModal && (
            <Button
              sx={{ marginRight: "1em" }}
              variant="outlined"
              onClick={() => DismissModal("new-booking-vehicle-modal")}
            >
              {i18next.t("navigation:close")}
            </Button>
          )}
        </div>
      </div>
      <SmartModal
        modalUniqueId={props.modalUniqueId ?? "fleet-driver-page-modal"}
        title={i18next.t("generic:titleAddDriver")}
        modalInnerComponent={
          <FormAssignedDriver
            loggedUser={loggedUser}
            data={[]}
            companyUser={loggedCompany}
            idModal={props.modalUniqueId ?? "fleet-driver-page-modal"}
            updateList={(data: any) => {
              let drivers_: IDriverGet[] = [...driverList];
              let data_: any = data;
              let addedDriver: any = {
                driverCostCenter: [],
                driverInfo: data_
              }
              drivers_.push(addedDriver);
              setDriverList(drivers_);
            }}
          />
        }
      />
    </div>
  );
};

export default FleetReservationForm;
