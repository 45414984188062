import React from "react";
import i18next from "i18next";
import {Accordion, AccordionDetails} from "@mui/material";
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .home-accordion-text': {
        marginLeft: theme.spacing(1),  // Aggiunge spazio tra l'icona e il contenuto dei dettagli
    },
}));

const AccordionInfoBox = (props: { info: { id: number, title: string; content: string } }) => {
    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

  return (
    <Accordion elevation={3} className="home-accordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary
          expandIcon={expanded === 'panel1' ? <RemoveRoundedIcon sx={{ color: '#059EE3' }} /> : <AddRoundedIcon sx={{ color: '#059EE3' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="home-accordion-title">{i18next.t(props.info.title) ?? ""}</span>
      </AccordionSummary>
      <AccordionDetailsStyled>
        <p className="home-accordion-text">{i18next.t(props.info.content) ?? ""}</p>

        {props.info.id === 2 && <a href="/rates"  className="intro-box-link">
          {i18next.t("navigation:readMore")}
        </a>}
      </AccordionDetailsStyled>
    </Accordion>
  );
};

const HomePage__Accordion = () => {
  return (
    <div className="ff-homepage-accordion-wrap">
      {[
        {
          id: 1,
          title: "intro:question1_homepage",
          content:
            "intro:answer1_homepage",
        },
        {
          id: 2,
          title: "intro:question2_homepage",
          content:
            "intro:answer2_homepage",
        },
        {
          id: 3,
          title:
            "intro:question3_homepage",
          content:
            "intro:answer3_homepage",
        }
      ].map((x: {id:number, title: string; content: string }, i: number) => {
        return <AccordionInfoBox key={i} info={x} />;
      })}
    </div>
  );
};

export default HomePage__Accordion;
