/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* UTILS */
import { ToastMessage } from "../../../../../../../Utils/Toastify";

/* SERVICES */
import ApiService from "../../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../../Services/Internal/AjaxService";

/* MODELS */
import { IUPDATE_CarConfiguratorGroup_Close_Vehicle_Color } from "../../../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import LoaderBackdrop from "../../../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Button, Checkbox } from "@mui/material";

/* INTERFACE */
interface IColorsGrid {
  groupVehicleID: number;
}

/* COMPONENT */
const ColorsGrid = (props: IColorsGrid) => {
  /* STATES */
  // Datagrid
  const [list, setList] = useState<any>([]);
  // Colors
  const [colors, setColors] = useState<any>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  /* API */
  // Load colors
  const GET_CarConfiguratorGroup_Close_Vehicle_Color = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup_Close_Vehicle_Color(
      props.groupVehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          // Set and sort datagrid data
          setList(
            response.payload.sort((a: any, b: any) =>
              a.codcqr > b.codcqr ? 1 : b.codcqr > a.codcqr ? -1 : 0
            )
          );

          // Set already checked colors
          setColors(
            response.payload
              .filter((x: any) => x.selected)
              .map((x: any) => {
                return {
                  groupVehicleID: props.groupVehicleID,
                  codcqr: x.codcqr,
                  cascol: x.cascol,
                  excluded: true,
                };
              })
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Update colors
  const UPDATE_CarConfiguratorGroup_Close_Vehicle_Color = (
    data: IUPDATE_CarConfiguratorGroup_Close_Vehicle_Color
  ) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Close_Vehicle_Color(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    GET_CarConfiguratorGroup_Close_Vehicle_Color();
  }, []);

  /* FUNCTIONS */
  // Render price
  const renderPrice = (props: GridRenderCellParams) => {
    return <div>{props.value + " €"}</div>;
  };

  // Row selection
  const renderSelect = (propsCell: GridRenderCellParams) => {
    return (
      <Checkbox
        checked={propsCell.row.selected}
        onChange={(_, checked) => {
          // Controlled checkbox
          list[propsCell.row.id].selected = checked;

          // Format data for save
          let tmp = [...colors];

          if (checked) {
            tmp.push({
              groupVehicleID: props.groupVehicleID,
              codcqr: propsCell.row.codcqr,
              cascol: propsCell.row.cascol,
              excluded: checked,
            });
          } else {
            tmp = tmp.filter(
              (x: any) =>
                x.codcqr !== propsCell.row.codcqr && x.cascol !== propsCell.row.cascol
            );
          }

          setColors(tmp);
        }}
      />
    );
  };

  /* COLUMNS */
  const columnsDef: any[] = [
    {
      field: "select",
      headerName: i18next.t("form:excluded"),
      flex: 1,
      renderCell: renderSelect,
    },
    {
      field: "codcqr",
      headerName: i18next.t("form:codcqr"),
      flex: 1,
    },
    {
      field: "cascol",
      headerName: i18next.t("form:codcm"),
      flex: 1,
    },
    {
      field: "description",
      headerName: i18next.t("form:description"),
      flex: 4,
    },
    {
      field: "price",
      headerName: i18next.t("form:cost"),
      flex: 1,
      renderCell: renderPrice,
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      selected: list[i].selected,
      codcqr: list[i].codcqr,
      cascol: list[i].cascol,
      description: list[i].description,
      price: list[i].price,
    });
  }

  /* RETURN */
  return (
    <>
      <div style={{ height: 450 }}>
        <DataGrid
          disableColumnMenu
          autoPageSize
          disableRowSelectionOnClick
          localeText={{ noRowsLabel: i18next.t("form:noData").toString() }}
          columns={columnsDef}
          rows={rowsDef}
        />
      </div>

      <div className="display-flex-end margin-top-small">
        <Button
          variant="contained"
          onClick={() => {
            UPDATE_CarConfiguratorGroup_Close_Vehicle_Color(colors);
          }}
        >
          {i18next.t("generic:save")}
        </Button>
      </div>

      <LoaderBackdrop loading={loadingGet || loadingUpdate} />
    </>
  );
};

export default ColorsGrid;
