import InfoIcon from "@mui/icons-material/Info";
import i18next from "i18next";
import AccordionContent from "../AccordionContent/AccordionContent";
import "./ReadFileInfo.scss";

const ReadFileInfo = (props: { templateTypeID: number }) => {
  const IconInfo = () => {
    return <InfoIcon color="primary" style={{ marginRight: "1em" }} />;
  };

  return (
    <div className="read-file-info-wrapper">
      <AccordionContent
        title={i18next.t("navigation:_forniture_page_load_file_info")}
        content={
          <div className="accordion-wrapper">
            <div className="step-wrapper">
              <div className="margin">
                <strong className="step-title">Step 1</strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_forniture_page_upload_file")}
                </strong>
              </div>
              <div>
                {i18next.t(
                  "navigation:_forniture_page_header_step1_description"
                )}
              </div>
            </div>
            <div className="step-wrapper-middle">
              <div className="margin">
                <strong className="step-title">
                  Step 2{props.templateTypeID === 1 ? ", 3, 4" : ""}
                </strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_forniture_page_header_step2")}
                </strong>
              </div>
              <div>
                {i18next.t(
                  "navigation:_forniture_page_header_step2_description"
                )}
              </div>
            </div>
            <div className="step-wrapper">
              <div className="margin">
                <strong className="step-title">
                  Step {props.templateTypeID === 1 ? "5" : "3"}
                </strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_forniture_page_header_step3")}
                </strong>
              </div>
              <div>
                {i18next.t(
                  "navigation:_forniture_page_header_step3_description"
                )}
              </div>
            </div>
          </div>
        }
        icon={IconInfo()}
      />
    </div>
  );
};

export default ReadFileInfo;
