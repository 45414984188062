import "./Footer.scss";
import React from "react";
import {useNavigate} from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import i18next from "i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import SmartModal, {SummonModal} from "../SmartModal/SmartModal";
import TermsAndCondtion from "../TermsAndCondition/TermsAndCondtion";
import CookiePolicy from "../CookiePolicy/CookiePolicy";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import {useTranslation} from "react-i18next";

const menuVoices = {
    [AppRoutes.HOME_PAGE_ROUTE]: "navigation:navTabHome",
    [AppRoutes.WHO_WE_ARE_ROUTE]: "intro:companyMenu",
    [AppRoutes.FUNCITIONALITIES_ROUTE]: "intro:functionality",
    [AppRoutes.RATES_ROUTE]: "intro:ratesTitle",
    [AppRoutes.WHYUS_ROUTE]: "intro:faq",
    [AppRoutes.CONTACTS_ROUTE]: "intro:companyContacts"
}

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleNavigation = (route: string) => {
        navigate(route);
    }

    return (
        <div>
            <footer className="ff-footer-container">

                <div className="ff-footer-box">
                    <div onClick={() => navigate(AppRoutes.HOME_PAGE_ROUTE)}>
                        <img className="logo" src="/Images/logo-footer.svg" alt='logo'/>
                    </div>

                    <p className="ff-footer-p">{i18next.t("navigation:footerText")}</p>

                    <div className="ff-social-icon-box">
                        <a target="_blank"
                           href={'https://www.facebook.com/people/Facileflottait/61559833717845/'}><FacebookIcon
                            className="footer-social_icon"/></a>
                        <a target="_blank"
                           href={'https://www.instagram.com/facileflotta.it/@facileflotta.it'}><InstagramIcon
                            className="footer-social_icon"/></a>
                        <a target="_blank"
                           href={'https://www.linkedin.com/showcase/facileflotta-it/facileflotta.it'}><LinkedInIcon
                            className="footer-social_icon"/></a>
                    </div>
                </div>

                <div className="ff-footer-box">
                    <span className="ff-footer-title text-bold">Fleet Support Srl</span>
                    <div className="footer-contact-p">
                        <PlaceRoundedIcon className="footer-contact_icon"/>
                        <p>Via Varese, 5
                            00185 Roma, Italia</p>
                    </div>
                    <div className="footer-contact-p">
                        <EmailRoundedIcon className="footer-contact_icon"/>
                        <a href="mailto:info@facileflotta.it">info@facileflotta.it</a>
                    </div>
                    <div className="footer-contact-p">
                        <LocalPhoneRoundedIcon className="footer-contact_icon"/>
                        (+39) 06 492121
                    </div>
                </div>


                <div className="ff-footer-box">
                    <ul className="ff-footer-links">
                        {Object.entries(menuVoices).map(([route, label]) =>
                            <li key={route} role="button" tabIndex={-1}
                                data-text={t(label)}
                                onClick={() => handleNavigation(route)}>
                                {t(label)}
                            </li>
                        )
                        }
                    </ul>
                </div>
            </footer>

            <div className="footer-legal-info-wrapper">
                <div>
                    <p>@ {new Date().getFullYear()} - Fleet Support Srl - P. IVA: 06598481007 </p>
                </div>

                <div className="ff-footer-condition-links">
                    <a onClick={() => SummonModal("footer-modal-cookie-policy")}>{i18next.t("generic:cookiePolicy")}</a>
                    <a onClick={() => SummonModal("footer-modal-privacy-policy")}>{i18next.t("generic:privacyPolicy")}</a>
                </div>
            </div>
            <SmartModal
                modalUniqueId="footer-modal-terms-condition"
                title={i18next.t("generic:termsCondition")}
                modalInnerComponent={
                    <TermsAndCondtion/>
                }
            />

            <SmartModal
                modalUniqueId="footer-modal-cookie-policy"
                title={i18next.t("generic:cookiePolicy")}
                modalInnerComponent={
                    <CookiePolicy/>
                }
            />

            <SmartModal
                modalUniqueId="footer-modal-privacy-policy"
                title={i18next.t("generic:privacyPolicy")}
                modalInnerComponent={
                    <PrivacyPolicy/>
                }
            />
        </div>
    )
}

export default Footer;