import { SET_BROKER_TYPE } from "./BrokerAction";
import { BrokerState } from "./BrokerState";

const DEFAULT_STATE: BrokerState = {
  brokerType: [],
};

export const BrokerReducer = (
  state: BrokerState = DEFAULT_STATE,
  action: any
): BrokerState => {
  switch (action.type) {
    case SET_BROKER_TYPE:
      return {
        ...state,
        brokerType: action.payload,
      };
    default:
      return state;
  }
};
