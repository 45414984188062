/* REACT */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./VehicleConfigurationPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* COMPONENTS */
import { DataGrid } from "@mui/x-data-grid";
import { renderBrandImageFunc } from "../VehicleOffersPage/VehicleOffersPage";

/* MUI */
import { Button } from "@mui/material";

/* INTERFACE */
export interface IVehicleConfigurationPageCompare {
  data: any[];
}

/* COMPONENT */
const VehicleConfigurationPageCompare = (props: IVehicleConfigurationPageCompare) => {
  /* STATES */
  const [list] = useState<any>(props.data || []);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* FUNCTIONS */
  const redirectCompareFunc = (driverGroupVehiclesID: number) => {
    navigate(
      AppRoutes.INTERNAL_DASHBOARD +
        AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE_COMPARE_SUMMARY +
        "?vehicleID=" +
        driverGroupVehiclesID
    );
  };

  /* COLUMNS */
  const columnsDef: any[] = [
    {
      field: "field",
      headerName: "",
      flex: 1,
      sortable: false,
    },
  ];
  // Add columns based on vehicles number
  list.map((x: any, i: number) => {
    columnsDef.push({
      field: "vehicle" + (i + 1),
      flex: 1,
      sortable: false,
      renderHeader: () => <div>{renderBrandImageFunc(x.vehicleBrandName)}</div>,
    });
  });

  /* ROWS */
  // Single row
  const rowsDef = [];
  // Fields for the rows
  const rowsDefFields = [
    "vehicleBrandName",
    "vehicleModelName",
    "vehicleFittingName",
    "vehicleAntipollutionLegislation",
    "vehicleCarBody",
    "vehicleDoorsNumber",
    "vehicleSeatsNumber",
    "vehicleLength",
    "vehicleWidth",
    "vehicleHeight",
    "vehicleTires",
  ];

  // Compose the rows
  for (let i = 0; i < 11; i++) {
    const row: any = {};

    // Add cm in length, width, height
    if (
      rowsDefFields[i] === "vehicleLength" ||
      rowsDefFields[i] === "vehicleWidth" ||
      rowsDefFields[i] === "vehicleHeight"
    ) {
      row.id = i;
      row.field = `${i18next.t("form:vehiclesCompareLabel_" + i)}`;
      for (let k = 0; k < list.length; k++) {
        row[`vehicle${k + 1}`] = list[k][`${rowsDefFields[i]}`]
          ? (row[`vehicle${k + 1}`] = list[k][`${rowsDefFields[i]}`] + " cm")
          : "-";
      }
    }
    // Others filds
    else {
      row.id = i;
      row.field = `${i18next.t("form:vehiclesCompareLabel_" + i)}`;
      for (let k = 0; k < list.length; k++) {
        row[`vehicle${k + 1}`] = list[k][`${rowsDefFields[i]}`] || "-";
      }
    }

    rowsDef.push(row);
  }

  /* RETURN */
  return (
    <div className="modal-width-extralarge">
      <div style={{ height: 450 }}>
        <DataGrid
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnSelector
          hideFooter
          localeText={{ noRowsLabel: i18next.t("form:noData").toString() }}
          columns={columnsDef}
          rows={rowsDef}
        />
      </div>

      <div className="display-flex-horizontal-space-between margin-top-small">
        <div style={{ width: `${100 / list.length}%` }}></div>

        {list.map((x: any) => (
          <div style={{ width: `${100 / list.length}%` }}>
            <Button
              variant="contained"
              onClick={() => {
                redirectCompareFunc(x.driverGroupVehiclesID);
              }}
            >
              {i18next.t("navigation:select")}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VehicleConfigurationPageCompare;
