import React from "react";
import "./MenuButton.scss";

export type MenuButtonProps = {
    isOpen?: boolean;
    onToggle: () => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({isOpen, onToggle}) => {
    return (
        <div
            id="hamburger"
            className={`hamburglar ${isOpen ? 'is-open' : 'is-closed'}`}
            onClick={onToggle}
            role="button"
            aria-expanded={isOpen}
        >
            <div className="burger-icon">
                <div className="burger-container">
                    <span className="burger-bun-top"></span>
                    <span className="burger-filling"></span>
                    <span className="burger-bun-bot"></span>
                </div>
            </div>
            {/* the masked path that animates the fill to the ring */}
            <svg width="0" height="0">
                <mask id="mask">
                    <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="#ff0000"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                        d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4"
                    />
                </mask>
            </svg>
            <div className="path-burger">
                <div className="animate-path">
                    <div className="path-rotation"></div>
                </div>
            </div>
        </div>
    )
}
