import { ICompanyGet } from "../../../Models/ICompany";
import { IUser } from "../../../Models/IUser";
import FormContract from "./FormContract";
import "./TabContract.scss";

export interface ITabContractProps {
  currentTab: string;
  dataVehicle: any;
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  doRefreshForm?: () => void;
  requestUpdate?: () => void;
}
const TabContract = (props: ITabContractProps) => {
  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-contract"}
      id="secondary-tab-contract"
      aria-labelledby="secondary-tab-contract"
    >
      <div className="container-content-form">
        <div className="container-stepper-form contract-form">
          {props.currentTab === "secondary-tab-contract" && (
            <FormContract
              companyUser={props.companyUser}
              loggedUser={props.loggedUser}
              dataVehicle={props.dataVehicle}
              disabled={!props.dataVehicle?.vehicleID}
              doRefreshForm={props.doRefreshForm}
              requestUpdate={() => {
                if(props.requestUpdate){
                  props.requestUpdate();
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TabContract;
