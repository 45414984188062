/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import TaskIcon from "@mui/icons-material/Task";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__fringeBenefits.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { JSONPrint, currencyDecoder } from "../../../../../Utils/Decoder";
import { IconButton } from "rsuite";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { IFleetPlanUpdateBody } from "../../../../../Models/IFleetPlanUpdateBody";
import { IFleetPlanSetBody } from "../../../../../Models/IFleetPlanSetBody";
import {
  IFringeCompleteModel,
  IVehicleFringeBenefitGet,
  IVehicleFringeFuel,
} from "../../../../../Models/IVehiclesFringe";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const AdminPage__fringeBenefits = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* STATES */
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fringes, setFringes] = useState<any[]>([]);
  const [fuelTypes, setFuelTypes] = useState<IVehicleFringeFuel[]>([]);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [openFringe, setOpenFringe] = useState<IFringeCompleteModel | null>(null);

  const loadFringeFuelType = () => {
    ApiService.FringeController.FringeFuelTypeAciGet(null, (response: IAPIResponse) => {
      if (response.error === null) {
        setFuelTypes(response.payload);
      } else {
        ToastMessage(i18next.t("error:errorLoadingData"), "error");
      }
    });
  };

  const getFringeBenefits = () => {
    setLoading(true);
    ApiService.FringeController.FringeBenefitGetAll((response: IAPIResponse) => {
      if (response.error === null) {
        setFringes(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadFringeFuelType();
    getFringeBenefits();
  }, []);

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          onClick={() => {
            setOpenFringe(props.row);
            SummonModal("EditFringeModal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const renderCost = (props: GridRenderCellParams) => {
    return <div>{currencyDecoder(props.value, 2)}</div>;
  };

  const COLUMNS = [
    {
      field: "",
      headerName: "",
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "aciBrandName",
      headerName: i18next.t("navigation:aci_brand") as string,
    },
    {
      field: "aciModelName",
      headerName: i18next.t("navigation:aci_model") as string,
    },
    {
      field: "aciVersionName",
      headerName: i18next.t("navigation:aci_version") as string,
    },
    {
      field: "aciCode",
      headerName: i18next.t("navigation:aci_code") as string,
    },
    { 
      field: "isInProduction",
      headerName: "IN / OUT" as string,
    },
    {
      field: "costKm",
      headerName: i18next.t("navigation:fringe_km_cost") as string,
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fringe25",
      headerName: (i18next.t("navigation:fringe_benefit") + " 25%") as string,
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fringe30",
      headerName: (i18next.t("navigation:fringe_benefit") + " 30%") as string,
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fringe50",
      headerName: (i18next.t("navigation:fringe_benefit") + " 50%") as string,
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fringe60",
      headerName: (i18next.t("navigation:fringe_benefit") + " 60%") as string,
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fuelTypeAciID_",
      headerName: i18next.t("navigation:aci_fuel") as string,
    },
  ];

  const insertRowFringe = (row: IFringeCompleteModel) => {
    setProcessing(true);
    ApiService.FringeController.FringeBenefiteInsertRow(row, (response: IAPIResponse) => {
      if (response.error === null) {
        DismissModal("EditFringeModal");
        getFringeBenefits();
      } else {
        ToastMessage(response.error, "error");
      }
      setProcessing(false);
    });
  };

  const updateRowFringe = (row: IFringeCompleteModel) => {
    setProcessing(true);
    ApiService.FringeController.FringeBenefiteUpdateRow(row, (response: IAPIResponse) => {
      if (response.error === null) {
        DismissModal("EditFringeModal");
        getFringeBenefits();
      } else {
        ToastMessage(response.error, "error");
      }
      setProcessing(false);
    });
  };

  const deleteRowFringe = (fringeBenefitID: number) => {
    setProcessing(true);
    ApiService.FringeController.FringeBenefiteDeleteRow(
      fringeBenefitID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("EditFringeModal");
          getFringeBenefits();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const uploadFringeMassive = (
    fuelTypeAciID: number,
    inProduction: string,
    fileData: File
  ) => {
    setProcessing(true);
    ApiService.FringeController.FringeMassiveImport(
      fuelTypeAciID,
      inProduction === "1" ? true : false,
      fileData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("MassiveFringeModal");
          getFringeBenefits();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_benefits")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small gap-small">
          <Button
            startIcon={<UploadIcon />}
            variant={"outlined"}
            onClick={() => {
              SummonModal("MassiveFringeModal");
            }}
          >
            {i18next.t("navigation:upload_excel")}
          </Button>

          <Button
            variant={"outlined"}
            onClick={() => {
              setOpenFringe(null);
              SummonModal("EditFringeModal");
            }}
          >
            {i18next.t("form:nuovo")}
          </Button>
        </div>
        <SmartModal
          title={
            openFringe
              ? i18next.t("navigation:fringe_edit_edit")
              : i18next.t("navigation:fringe_edit_new")
          }
          buttons={[
            {
              disabled: !openFringe?.fringeBenefitID,
              text: i18next.t("generic:delete"),
              onClick: () => {
                SummonModal("fringe-benefit-form-modal-delete");
              },
            },
            {
              text: i18next.t("form:save"),
              onClick: () => {
                triggerFormValidation("EditFringeForm");
              },
            },
          ]}
          modalUniqueId="EditFringeModal"
          modalInnerComponent={
            <div>
              <MultiForm
                suppressLayout
                suppressSubmit
                formId="EditFringeForm"
                inputs={[
                  {
                    width: 50,
                    type: "text",
                    name: "aciBrandName",
                    required: true,
                    defaultValue: openFringe?.aciBrandName,
                    label: i18next.t("navigation:aci_brand") as string,
                  },
                  {
                    width: 50,
                    type: "text",
                    name: "aciModelName",
                    required: true,
                    defaultValue: openFringe?.aciModelName,
                    label: i18next.t("navigation:aci_model") as string,
                  },
                  {
                    width: 50,
                    type: "text",
                    name: "aciVersionName",
                    required: true,
                    defaultValue: openFringe?.aciVersionName,
                    label: i18next.t("navigation:aci_version") as string,
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "costKm",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    defaultValue: openFringe?.costKm,
                    label: i18next.t("navigation:fringe_km_cost") as string,
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "fringe25",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    defaultValue: openFringe?.fringe25,
                    label: (i18next.t("navigation:fringe_benefit") + " 25%") as string,
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "fringe30",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    defaultValue: openFringe?.fringe30,
                    label: (i18next.t("navigation:fringe_benefit") + " 30%") as string,
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "fringe50",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    defaultValue: openFringe?.fringe50,
                    label: (i18next.t("navigation:fringe_benefit") + " 50%") as string,
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "fringe60",
                    required: true,
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    defaultValue: openFringe?.fringe60,
                    label: (i18next.t("navigation:fringe_benefit") + " 60%") as string,
                  },
                  {
                    width: 50,
                    type: "select",
                    name: "fuelTypeAciID",
                    required: true,
                    defaultValue: openFringe?.fuelTypeAciID,
                    label: i18next.t("navigation:aci_fuel") as string,
                    options: fuelTypes.map((x: IVehicleFringeFuel) => {
                      return {
                        key: x.fuelTypeAciID,
                        text: x.fuelTypeAciName,
                      };
                    }),
                  },
                ]}
                onSubmit={(data: any) => {
                  let data_ = openFringe ? { ...openFringe, ...data } : { ...data };

                  if (data_.fringeBenefitID) {
                    updateRowFringe(data_);
                  } else {
                    insertRowFringe(data_);
                  }
                }}
              />
            </div>
          }
        />

        <SmartModal
          onBeforeSummon={() => {
            setUploadFile(null);
          }}
          buttons={[
            {
              disabled: uploadFile === null,
              text: i18next.t("form:save"),
              onClick: () => {
                triggerFormValidation("MassiveFringeForm");
              },
            },
          ]}
          modalUniqueId="MassiveFringeModal"
          title={i18next.t("navigation:massive_fringe_load")}
          modalInnerComponent={
            <div>
              <MultiForm
                formId="MassiveFringeForm"
                suppressLayout
                suppressSubmit
                formTitle={""}
                inputs={[
                  {
                    width: 100,
                    type: "radio",
                    name: "inProduction",
                    defaultValue: 0,
                    label: i18next.t("form:changeHistoricallyVehicle") as string,
                    options: [
                      {
                        key: 0,
                        text: i18next.t("form:production_no"),
                      },
                      {
                        key: 1,
                        text: i18next.t("form:production_yes"),
                      },
                    ],
                  },
                  {
                    width: 100,
                    type: "select",
                    name: "fuelTypeAciID",
                    required: true,
                    label: i18next.t("navigation:aci_fuel") as string,
                    options: fuelTypes.map((x: IVehicleFringeFuel) => {
                      return {
                        key: x.fuelTypeAciID,
                        text: x.fuelTypeAciName,
                      };
                    }),
                  },
                  {
                    width: 100,
                    type: "custom",
                    name: "-",
                    element: (
                      <div
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        <div
                          className="select-model-wrapper"
                          onClick={() => {
                            document.getElementById("file-inp")?.click();
                          }}
                        >
                          <input
                            onChange={(e: any) => {
                              setUploadFile(e.target.files[0]);
                            }}
                            id="file-inp"
                            type="file"
                            hidden
                            accept=".xlsx"
                          />
                          <div>
                            <div className="upload-icon-wrapper">
                              <TaskIcon className="text-color-hint StepDashboardLoadTemplate__icon StepDashboardLoadTemplate__icon--size" />
                            </div>

                            <div className="title-wrapper">
                              <strong>{uploadFile?.name}</strong>
                            </div>

                            <div style={{ textAlign: "center" }}>
                              <div className="accepted-file-wrapper">
                                {i18next.t(
                                  "navigation:_forniture_page_upload_file_fringe"
                                )}
                              </div>
                              <div>.xlsx</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                onSubmit={(data: any) => {
                  if (uploadFile) {
                    uploadFringeMassive(
                      data.fuelTypeAciID,
                      data.inProduction,
                      uploadFile
                    );
                  }
                }}
              />
            </div>
          }
        />
        <Box className="dashboardForm__data">
          <DataGridWrap
            headers={COLUMNS}
            rows={fringes.map((x: any) => {
              let properFuel: IVehicleFringeFuel | undefined = fuelTypes.find(
                (y: IVehicleFringeFuel) => y.fuelTypeAciID === x.fuelTypeAciID
              );
              x["fuelTypeAciID_"] = properFuel ? properFuel.fuelTypeAciName : "-";
              x["isInProduction"] = x.inProduction ? "IN" : "OUT";
              return x;
            })}
          />
        </Box>
      </div>
      {openFringe && (
        <SmartModal
          modalUniqueId="fringe-benefit-form-modal-delete"
          title={i18next.t("generic:titleRecordModal")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModal")
                  .replace("CURRENT_ITEM_LIST", "#" + openFringe?.fringeBenefitID),
              }}
            />
          }
          onAccept={() => {
            deleteRowFringe(openFringe?.fringeBenefitID);
          }}
          onReject={() => {}}
        />
      )}
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default AdminPage__fringeBenefits;
