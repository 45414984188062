import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IDriverGet } from "../../Models/IDriver";
import {
  IFuelCardsGet,
  IFuelCardsGetType,
  IFuelCardsInsert,
} from "../../Models/IFuelCards";
import { ISupplierGetSideList } from "../../Models/ISupplier";
import { IUser } from "../../Models/IUser";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import FormSupplier from "../StepSupplier/FormSupplier";
import "./FuelCardDashboardEditor.scss";
import { ICompanyGet } from "../../Models/ICompany";
import { GetVehicleMinDate } from "../../Pages/DashboardPage/DashboardPage";

export interface IFuelCardDashboardEditorProps {
  fuelCard: IFuelCardsGet;
  fuelCardType: IFuelCardsGetType | undefined;
  supplier: ISupplierGetSideList | undefined;
  supplierList: ISupplierGetSideList[];
  fuelCardTypeList: IFuelCardsGetType[];
  formUniqueIndex: string;
  drivers: IDriverGet[];
  disabled?: boolean;
  companyUser?: ICompanyGet | undefined;
  reloadSuppliers?: () => void;
  requestReload?: () => void;
}

const FuelCardDashboardEditor = (props: IFuelCardDashboardEditorProps) => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>(
    props.supplierList
  );

  const [state, setState] = useState<any>(props.fuelCard);
  const [processing, setProcessing] = useState<boolean>(false);
  const fuelCardsInsert = (data: IFuelCardsInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullAdded"), "success");
          DismissModal("AddCardOnDashboard");
          if (props.requestReload) {
            props.requestReload();
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsUpdate = (data: IFuelCardsInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsArchive = (data: IFuelCardsGet) => {
    if (loggedUser) {
      setProcessing(true);
      data["stored"] = true;
      ApiService.FuelCardsController.FuelCardsUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.requestReload) {
            props.requestReload();
          }
          ToastMessage(i18next.t("message:fuelCardSuccessfullUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsDelete = (fuelCardID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsDelete(
        fuelCardID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:fuelCardSuccessfullDeleted"), "success");
            setState(null);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  return (
    <div>
      <MultiForm
        disableForm={props.disabled}
        formId={"StepFuelCards" + props.formUniqueIndex}
        suppressLayout
        formTitle={""}
        inputs={[
          {
            width: 40,
            type: "select",
            name: "supplierID",
            defaultValue: state?.supplierID,
            required: true,
            label: String(i18next.t(`form:supplierFuelCard`)),
            options: supplierList.map((item: ISupplierGetSideList) => {
              return {
                key: item.supplierInfo.supplierID,
                text: item.supplierInfo.supplierName.toString(),
              };
            }),
          },
          {
            width: 10,
            type: "custom",
            name: "supplierAddType",
            element: (
              <div className="multi-form-button-add-record">
                <Button
                  variant="contained"
                  sx={{ height: "55px" }}
                  onClick={() => {
                    SummonModal("supplierUser-modal_" + props.fuelCard.fuelCardID);
                  }}
                >
                  <AddIcon />
                </Button>
              </div>
            ),
          },
          {
            width: 50,
            type: "select",
            name: "driverID",
            defaultValue: state?.driverID ?? vehicle?.driverID ?? "",
            required: true,
            label: String(i18next.t(`form:assignDriverName`)),
            options: props.drivers.map((item: IDriverGet) => {
              let titleDriver = item.driverInfo.driverName;
              if (item.driverInfo?.driverSurname) {
                titleDriver += " " + item.driverInfo.driverSurname;
              }
              return {
                key: item.driverInfo.driverID,
                text: titleDriver.toString(),
              };
            }),
          },
          {
            width: 50,
            type: "select",
            name: "fuelCardTypeID",
            label: i18next.t("form:fuelCardType") as string,
            required: true,
            defaultValue: state?.fuelCardTypeID,
            options: props.fuelCardTypeList.map((fuelCardType: IFuelCardsGetType) => {
              return {
                key: fuelCardType.fuelCardTypeID,
                text: fuelCardType.fuelCardTypeName.toString(),
              };
            }),
          },
          {
            width: 25,
            type: "text",
            name: "fuelCardNumber",
            required: true,
            label: i18next.t("form:numberFuelCard") as string,
            defaultValue: state?.fuelCardNumber,
            inputProps: {
              maxLength: 50,
            },
          },
          {
            width: 25,
            type: "number",
            name: "fuelCardPIN",
            label: i18next.t("form:pinFuelCard") as string,
            inputProps: {
              min: 0,
              max: 1000000,
            },
            defaultValue: state?.fuelCardPIN,
          },
          {
            width: 50,
            type: "date",
            name: "fuelCardExpirationDT",
            label: i18next.t("form:dateExpireFuelCard") as string,
            defaultValue: state?.fuelCardExpirationDT,
            required: true,
            disablePast: true,
          },
          {
            width: 50,
            type: "date",
            name: "fuelCardEndAssignementDT",
            required: true,
            label: i18next.t("form:endAssignmentFuelCard") as string,
            defaultValue: state?.fuelCardEndAssignementDT,
            minDate: GetVehicleMinDate(vehicle),
            maxDate: state?.fuelCardExpirationDT,
          },
          {
            type: "custom",
            name: "tip",
            element: <small>{i18next.t("generic:tipTabFuelCards")}</small>,
          },
        ]}
        onChange={async (data: any) => {
          setState({ ...state, ...data });
        }}
        onSubmit={async (data: any) => {
          let parsedData = { ...data };
          delete parsedData["supplierAddType"];
          delete parsedData["endAssignmentFuelCard"];
          delete parsedData["tip"];

          parsedData["fuelCardPIN"] = Number(state.fuelCardPIN);
          parsedData["companyID"] = props.companyUser?.companyID;

          if (loggedUser && vehicle) {
            parsedData["vehicleID"] = vehicle.vehicleID;
          }

          if (state?.fuelCardID) {
            parsedData["fuelCardID"] = state.fuelCardID;
            fuelCardsUpdate(parsedData);
          } else {
            fuelCardsInsert(parsedData);
          }
        }}
        suppressSubmit={true}
      />

      <AttachementMainHandler
        attachmentFamily={"fuelcard"}
        extraGetParametersVector={
          state?.fuelCardID
            ? [
                {
                  name: "fuelCardID",
                  value: state?.fuelCardID,
                },
              ]
            : undefined
        }
      />

      <div style={{ margin: "20px 7.5px" }}>
        <div
          className="multi-form-input-wrap"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            variant="contained"
            disabled={processing || props.disabled}
            onClick={() => {
              triggerFormValidation("StepFuelCards" + props.formUniqueIndex);
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveFuelCard")}
          </Button>
          <Button
            color="error"
            sx={{ marginRight: "1em" }}
            disabled={!state?.fuelCardID || props.disabled}
            onClick={() => {
              SummonModal("FuelCardDelete-modal_" + props.fuelCard.fuelCardID);
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            {i18next.t("form:archiveFuelCard")}
          </Button>
          <SmartModal
            modalUniqueId={"FuelCardDelete-modal_" + props.fuelCard.fuelCardID}
            title={i18next.t("generic:titleRecordModalArchive")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModalArchive")
                    .replace(
                      "CURRENT_ITEM_LIST",
                      state?.fuelCardTypeName + ", " + state?.fuelCardNumber
                    ),
                }}
              />
            }
            onAccept={() => {
              fuelCardsArchive(state);
            }}
            onReject={() => {}}
          />
        </div>
      </div>

      <SmartModal
        modalUniqueId={"supplierUser-modal_" + props.fuelCard.fuelCardID}
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={loggedUser}
            data={[]}
            idModal={"supplierUser-modal_" + props.fuelCard.fuelCardID}
            forceSupplierTypeCode={"FUEL_CARD"}
            updateList={(data: ISupplierGetSideList) => {
              let supplierList_: ISupplierGetSideList[] = [...supplierList];
              supplierList_.push(data);
              setSupplierList(supplierList_);
            }}
          />
        }
      />
    </div>
  );
};

export default FuelCardDashboardEditor;
