/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "../VehicleGroupPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import FormOpenGroup from "./FormOpenGroup";

/* COMPONENT */
const OpenGroupPage = () => {
  /* STATES */
  const [payload, setPayload] = useState<any>();
  // Loading
  const [loadingOpenGroup, setLoadingOpenGroup] = useState<boolean>(false);

  /* URL PARAMS */
  const [params] = useSearchParams();
  const groupID = params.get("groupID");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get open group
  const GET_CarConfiguratorGroup_Open = () => {
    setLoadingOpenGroup(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup_Open(
      groupID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          const newPayload = response.payload[0];
          newPayload["driverLoadShare"] = newPayload["driverLoadShare"] === true ? 1 : 0;
          newPayload["hybrid"] = newPayload["hybrid"] === true ? 1 : 0;

          setPayload(newPayload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingOpenGroup(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    groupID && GET_CarConfiguratorGroup_Open();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("navigation:_configure_vechicles_group")}
        subtitle={i18next.t("generic:openList")}
        subtitlePath={AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP}
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP
          )
        }
      />

      <div className="dashboardForm__box">
        {!loadingOpenGroup && <FormOpenGroup data={payload || []} />}
      </div>

      <LoaderBackdrop loading={loadingOpenGroup} />
    </div>
  );
};

export default OpenGroupPage;
