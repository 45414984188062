import { useEffect, useState } from "react";
import "./ReduceSubscriptionPage.scss";
import StepFleetPlan from "../../Components/StepFleetPlan/StepFleetPlan";
import { Box, Button, ButtonGroup, Checkbox } from "@mui/material";
import HeaderPageDashboard from "../../Components/HeaderPageDashboard/HeaderPageDashboard";
import i18next from "i18next";
import { triggerFormValidation } from "../../Components/MultiForm/MultiForm";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "../../Costants/AppRoutes";
import { writePaymentData } from "../PaymentReadyPage/PaymentReadyPage";
import { JSONPrint } from "../../Utils/Decoder";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ICompanyGet } from "../../Models/ICompany";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import { ToastMessage } from "../../Utils/Toastify";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
import { GridRenderCellParams } from "@mui/x-data-grid";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../Components/DataGridWrap/DataGridWrap";
import moment from "moment";
import { CheckBox } from "@mui/icons-material";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { displayUTC0_ISODate } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { RegistrationAction } from "../../Reducers/Registration/RegistrationAction";
import { GenericActions } from "../../Reducers/Generic/GenericAction";

var dataClone: any = {};
var billingInfoClone: any = {};

/* RENDER BRAND IMAGE - Datagrid */
export const renderBrandImageGrid = (props: GridRenderCellParams) => {
  if (props.value) {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          onError={(e: any) =>
            (e.target.src =
              process.env.PUBLIC_URL +
              "/Images/brandVehicle/brand-not-found.svg")
          }
          src={
            process.env.PUBLIC_URL +
            "/Images/brandVehicle/" +
            props.value.replace(" ", "_") +
            ".png"
          }
          title={props.value}
          alt={props.value}
        />
      </div>
    );
  } else {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          src={
            process.env.PUBLIC_URL + "/Images/brandVehicle/brand-not-found.svg"
          }
          alt = ''
        />
      </div>
    );
  }
};

const ReduceSubscriptionPage = () => {
  const dispatch = useDispatch();
  const [data, setData_] = useState<any>({
    price: -1,
  });
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);
  const [pageSelect, setPageSelect] = useState<number>(0);

  const [keepVehiclesIds, setKeepVehiclesIds] = useState<any>({});

  const [billingInfos, setBillingInfos_] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false)
  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );

  const setData = (data: any) => {
    dataClone = { ...data };
    setData_(data);
  };
  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);

  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const setBillingInfos = (infos: any) => {
    billingInfoClone = { ...infos };
    setBillingInfos_(infos);
  };

  const handlePaymentReadyToGo = () => {
    SummonModal("VehicleRemoveList");
  };

  const getVehicles = () => {
    ApiService.VehicleDataController.VehicleDataGet(
      loggedCompany?.companyID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehiclesList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const loadSubscriptions = () => {
      ApiService.SubscriptionController.SubscriptionGetData(
        getCurrentSubscription.subscriptionID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(GenericActions.setCurrentSubscription(response.payload));
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
  };

  const CancelSubscription = () => {
    setLoading(true);
    ApiService.PaymentController.CancelSubscription(
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t('navigation:subscriptionCancelled') + displayUTC0_ISODate(getCurrentSubscription.validTo, true), "success");
          loadSubscriptions()
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getVehicles();

    // Add event listener for payment-ready-to-go event
    document.addEventListener("payment-ready-to-go", handlePaymentReadyToGo);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "payment-ready-to-go",
        handlePaymentReadyToGo
      );
    };
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "",
      headerName: i18next.t("form:plate"),
      renderCell: (props: any) => {
        return (
          <Checkbox
            checked={keepVehiclesIds[props.row.vehicleID]}
            onChange={(c) => {
              let currentIds = { ...keepVehiclesIds };
              currentIds[props.row.vehicleID] = c.target.checked;
              setKeepVehiclesIds(currentIds);
            }}
          />
        );
      },
    },
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
    },
    {
      field: "contractType",
      headerName: i18next.t("navigation:contractTabTitle"),
    },
    {
      field: "brand",
      headerName: i18next.t("form:orderBrand"),
      renderCell: renderBrandImageGrid,
      type: 'custom'
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "fitting",
      headerName: i18next.t("form:orderEquipment"),
    },
    {
      field: "chassis",
      headerName: i18next.t("form:_mctc_numeroTelaio"),
    },
    {
      field: "revision",
      headerName: i18next.t("form:lastWarrantyCheck"),
    },
    {
      field: "duty",
      headerName: i18next.t("form:dateExpirePayVehicle"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < vehiclesList.length; i++) {
    rowsDef.push({
      id: i,
      vehicleID: vehiclesList[i].vehicleID,
      plate: vehiclesList[i].vehicleLicensePlate,
      contractType: vehiclesList[i].contractTypeName || "-",
      brand: vehiclesList[i].brandDescription,
      model: vehiclesList[i].modelDescription?.replace(/\s\s+/g, " "),
      fitting: vehiclesList[i].fittingDescription,
      chassis: vehiclesList[i].vehicleChassis,
      revision: vehiclesList[i].vehicleLastInspectionDT
        ? moment(vehiclesList[i].vehicleLastInspectionDT)
            .utc()
            .format("DD-MM-YYYY")
        : "-",
      duty: vehiclesList[i].vehicleExpireRoadTaxDT
        ? moment(vehiclesList[i].vehicleExpireRoadTaxDT)
            .utc()
            .format("DD-MM-YYYY")
        : "-",
    });
  }

  const vehiclesPerSub = () => {
    if (dataClone && dataClone.selectedPlan) {
      let limit = dataClone.selectedPlan.fleetPlanMaxVehicle;
      if (dataClone.selectedPlan.extraVehicles) {
        limit += dataClone.selectedPlan.extraVehicles;
      }
      return limit;
    }
    return 0;
  };

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_add_services_purchases_sub")}
      />
      <SmartModal
        title={i18next.t("navigation:pick_vehicles_to_keep")}
        modalUniqueId="VehicleRemoveList"
        modalInnerComponent={
          <div>
            <h2>
              {i18next.t("navigation:pick_vehicles_to_keep")}{" "}
              {Object.keys(keepVehiclesIds).length}/{vehiclesPerSub()}
            </h2>
            <DataGridWrap tableName={""} headers={columnsDef} rows={rowsDef} />
          </div>
        }
        onAccept={() => {
          let vehiclesToKeep: number[] = [];
          let keys = Object.keys(keepVehiclesIds);
          for (let i = 0; i < keys.length; i++) {
            if (keepVehiclesIds[keys[i]]) {
              vehiclesToKeep.push(+keys[i]);
            }
          }

          writePaymentData({
            ...dataClone,
            ...billingInfoClone,
            stepIndex: -1,
            isDecreasePlan: true,
            vehiclesToKeep: vehiclesToKeep,
          });
          navigate(AppRoutes.PAYMENT_READY_PAGE);
        }}
        onReject={() => {
          DismissModal("VehicleRemoveList");
        }}
      />
      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined"></ButtonGroup>
        </div>
        <Box className="dashboardForm__data">
          <div>
            <StepFleetPlan
              includeBillingForm
              emitBillingInfos={(data: any) => {
                setBillingInfos(data);

                if (
                  data &&
                  data.billingEmail &&
                  Object.values(data).indexOf(null) === -1
                ) {
                  setPage(1);
                }
              }}
              emitState={(dt: any) => {
                setData(dt);
                if (dt.paymentEvaluation && +dt.paymentEvaluation.cost != 0) {
                  setPageSelect(1);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                paddingRight: "2em",
              }}
            >
              <Button
                disabled={billingInfos && billingInfos?.paymentTypeID === 0}
                variant="contained"
                onClick={() => {
                  triggerFormValidation("payment-page-form");
                }}
              >
                {i18next.t("navigation:accept")}
              </Button>
              {getCurrentSubscription.paypalSubscriptionID && <Button
                variant="contained"
                style={{backgroundColor: '#c62828', marginRight: '1em'}}
                onClick={() => {
                  SummonModal('modal_cancel_subscription')
                }}
              >
                {i18next.t('navigation:cancelSubscription')}
              </Button>}
              <SmartModal
                modalUniqueId="modal_cancel_subscription"
                title={i18next.t("navigation:cancelSubscriptionPermantly")}
                modalInnerComponent={<div>{i18next.t("navigation:cancelSubscriptionPermantlyDescr")}</div>}
                onReject={() => {
                  DismissModal('modal_cancel_subscription')
                }}
                onAccept={() => {
                  CancelSubscription()
                  DismissModal('modal_cancel_subscription')
                }}
              />
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};
export default ReduceSubscriptionPage;
