/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* REDUCER */
import { GlobalState } from "../../Reducers/RootReducer";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* UTILS */
import {
  downloadWithName,
  getBlobDate,
  getBlobDesc,
} from "../../Utils/FileToBase";
import { ToastMessage } from "../../Utils/Toastify";

/* STYLE */
import "./AttachmentMainHandler.scss";

/* MODELS */
import { IAttachment } from "../../Models/IAttachments";
import { IUser } from "../../Models/IUser";

/* COMPONENTS */
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";

/* MUI */
import {
  Alert,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
// Icons
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import PreviewIcon from "@mui/icons-material/Preview";

/* INTERFACE */
export interface IAttachementMainHandlerProps {
  allowMissingVehicleId?: boolean;
  attachmentFamily:
    | "orders"
    | "telepass"
    | "driver"
    | "assignments"
    | "contract"
    | "insurance"
    | "fuelcard"
    | "otherservices"
    | "vehicledata"
    | "ticket"
    | "disposal"
    | "fuelsupply"
    | "sendoffer"
    | "dealerproposal";
  vehicleID?: number;
  disabled?: boolean;
  extraGetParametersVector?: { name: string; value: number | string }[];
  skipSave?: boolean;
}

/* CONSTS */
// Endpoint
const targetAPIS: {
  [key: string]: {
    GET: string, 
    INSERT: string, 
    UPDATE?: string, 
    DELETE?: string, 
    paramName?: string
  };
} = {
  driver: {
    GET: ApiEndPoints.ASSIGNEE_DRIVER_GET_ATTACHMENT,
    DELETE: ApiEndPoints.ASSIGNEE_DRIVER_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.ASSIGNEE_DRIVER_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.ASSIGNEE_DRIVER_UPDATE_ATTACHMENT,
    paramName: "driverID",
  },
  orders: {
    GET: ApiEndPoints.ORDER_GET_ATTACHMENT,
    DELETE: ApiEndPoints.ORDER_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.ORDER_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.ORDER_UPDATE_ATTACHMENT,    
    paramName: "vehicleOrderID",
  },
  insurance: {
    GET: ApiEndPoints.VEHICLE_INSURANCE_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_INSURANCE_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_INSURANCE_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_INSURANCE_UPDATE_ATTACHMENT,
    paramName: "insuranceID",
  },
  contract: {
    GET: ApiEndPoints.VEHICLE_CONTRACT_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_CONTRACT_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_CONTRACT_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_CONTRACT_UPDATE_ATTACHMENT,
    paramName: "vehicleContractID"
  },
  assignments: {
    GET: ApiEndPoints.VEHICLE_ASSIGNMENT_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_ASSIGNMENT_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_ASSIGNMENT_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_ASSIGNMENT_UPDATE_ATTACHMENT,
    paramName: "assignmentID"
  },
  fuelcard: {
    GET: ApiEndPoints.VEHICLE_FUEL_CARD_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_FUEL_CARD_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_FUEL_CARD_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_FUEL_CARD_UPDATE_ATTACHMENT,
    paramName: "fuelCardID",
  },
  telepass: {
    GET: ApiEndPoints.VEHICLE_TELEPASS_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_TELEPASS_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_TELEPASS_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_TELEPASS_UPDATE_ATTACHMENT,
    paramName: "telepassID"
  },
  otherservices: {
    GET: ApiEndPoints.VEHICLE_OTHER_SERVICES_GET_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_OTHER_SERVICES_DELETE_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_OTHER_SERVICES_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_OTHER_SERVICES_UPDATE_ATTACHMENT,
    paramName: "vehicleServiceID"
  },
  vehicledata: {
    GET: ApiEndPoints.VEHICLEDATA_GET_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLEDATA_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLEDATA_UPDATE_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLEDATA_DELETE_ATTACHMENT,
    paramName: "vehicleID"
  },
  ticket: {
    GET: ApiEndPoints.TICKETS_SERVICES_GET_ATTACHMENT,
    INSERT: ApiEndPoints.TICKETS_SERVICES_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.TICKETS_SERVICES_UPDATE_ATTACHMENT,
    DELETE: ApiEndPoints.TICKETS_SERVICES_DELETE_ATTACHMENT,
    paramName: "ticketID",
  },
  disposal: {
    GET: ApiEndPoints.VEHICLE_DISPOSAL_ATTACHMENT_GET,
    INSERT: ApiEndPoints.VEHICLE_DISPOSAL_ATTACHMENT_INSERT,
    UPDATE: ApiEndPoints.VEHICLE_DISPOSAL_ATTACHMENT_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DISPOSAL_ATTACHMENT_DELETE,
    paramName: "vehicleDisposalID"
  },
  fuelsupply: {
    GET: ApiEndPoints.VEHICLE_FUEL_SUPPLY_GET_ATTACHMENT,
    INSERT: ApiEndPoints.VEHICLE_FUEL_SUPPLY_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.VEHICLE_FUEL_SUPPLY_UPDATE_ATTACHMENT,
    DELETE: ApiEndPoints.VEHICLE_FUEL_SUPPLY_DELETE_ATTACHMENT,
    paramName: "fuelSupplyID"
  },
  sendoffer: {
    GET: ApiEndPoints.SEND_OFFER_GET_ATTACHMENT,
    INSERT: ApiEndPoints.SEND_OFFER_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.SEND_OFFER_UPDATE_ATTACHMENT,
    DELETE: ApiEndPoints.SEND_OFFER_DELETE_ATTACHMENT,
  },
  dealerproposal: {
    GET: ApiEndPoints.DEALER_PROPOSAL_GET_ATTACHMENT,
    INSERT: ApiEndPoints.DEALER_PROPOSAL_INSERT_ATTACHMENT,
    UPDATE: ApiEndPoints.DEALER_PROPOSAL_UPDATE_ATTACHMENT,
    DELETE: ApiEndPoints.DEALER_PROPOSAL_DELETE_ATTACHMENT,
    paramName: "VehiclesRequestOfferID"
  },
};

/* COMPONENT */
const AttachementMainHandler = (props: IAttachementMainHandlerProps) => {
  /* REDUX */
  // USER
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* STATES */
  const [ready, setReady] = useState<boolean>(false);
  const [filePreviewData, setFilePreviewData] = useState<string[]>([]);
  const [savvingIndexes, setSavvingIndexes] = useState<boolean[]>([]);
  const [attachmentList, setAttachmentList] = useState<IAttachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [processingApiAttachment, setProcessingApiAttachment] =
    useState<boolean>(false);
  const [deleteAtIndexID, setDeleteAtIndexID] = useState<number>(-1);
  /* FUNCTIONS */
  const getExtraParams = () => {
    if (props.extraGetParametersVector) {
      let pars: string[] = [];
      for (let i = 0; i < props.extraGetParametersVector.length; i++) {
        pars.push(
          props.extraGetParametersVector[i].name +
            "=" +
            props.extraGetParametersVector[i].value
        );
      }
      return pars.join("&");
    }

    return undefined;
  };

  const tryAssumeParam = (param: string) => {
    if (props.extraGetParametersVector) {
      for (let i = 0; i < props.extraGetParametersVector.length; i++) {
        if (props.extraGetParametersVector[i].name === param) {
          return props.extraGetParametersVector[i].value;
        }
      }
    }
    return undefined;
  };

  const saveAttachmentAt = (index: number) => {
    let data = { ...attachmentList[index] };
    updateAttachment(data, index);
  };

  const setSavingAt = (index: number, value: boolean) => {
    let indxes: boolean[] = [...savvingIndexes];
    indxes[index] = value;
    setSavvingIndexes(indxes);
  };

  const downloadFile = (i: number, blobUri: string) => {
    setSavingAt(i, true);
    ApiService.UploadController.GetSASString(
      "attachment" + props.attachmentFamily,
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  const uploadFile = (e: any, i: number) => {
    setSavingAt(i, true);
    ApiService.UploadController.UploadFile(
      "attachment" + props.attachmentFamily,
      Array.from(e.target.files),
      (response: IAPIResponse) => {
        if (response.error === null) {
          let attachment = { ...attachmentList[i] };
          attachment.attachmentURL = response.payload.fileReference;
          updateAttachment(attachment, i, true);

          ToastMessage(i18next.t("message:upload_success"), "success");
        } else {
          ToastMessage(i18next.t("error:could_not_upload"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  const deleteAtIndex = (index: number) => {
    let attachment = { ...attachmentList[index] };

    setSavingAt(index, true);

    if (attachment.attachmentURL === "") {
      deleteAttachment(attachment, index);
    } else {
      ApiService.UploadController.DeleteFile(
        "attachment" + props.attachmentFamily,
        attachment.attachmentURL,
        (response: IAPIResponse) => {
          if (response.error === null) {
            deleteAttachment(attachment, index);
            DismissModal("deleteAttachmentSingle");
          } else {
            ToastMessage(i18next.t("error:could_not_delete_file"), "error");
            setSavingAt(index, false);
          }
        }
      );
    }
  };

  const previewFileWithName = (path: string, fileName: string) => {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest();

    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var file = new Blob([xhr.response], { type: "application/octet-stream" });

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result as string;
        let data: string[] = [fileName, result];

        setFilePreviewData(data);
        setTimeout(() => {
          SummonModal("preview_modal_attach");
        }, 500);
      };
    };
    xhr.send();
  };

  const previewFile = (i: number, blobUri: string) => {
    setSavingAt(i, true);
    ApiService.UploadController.GetSASString(
      "attachment" + props.attachmentFamily,
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            previewFileWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  const base64size = () => {
    if (filePreviewData.length > 1) {
      let str = filePreviewData[1];
      let size = str.length * (3 / 4) - (str.endsWith("==") ? 2 : 1);

      const formatSizeUnits = (bytes: number) => {
        if (bytes >= 1073741824) {
          return (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
          return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
          return (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
          return bytes + " bytes";
        } else if (bytes == 1) {
          return bytes + " byte";
        } else {
          return "0 bytes";
        }
      };
      return formatSizeUnits(size);
    }
    return "";
  };

  const fileSize = base64size();

  /* API */
  // Get
  const GetAttachment = () => {
    let itemId: any = props.vehicleID
    ? props.vehicleID
    : tryAssumeParam(targetAPIS[props.attachmentFamily].paramName as string);

    if (itemId || props.allowMissingVehicleId) {
      setLoading(true);

      ApiService.AttachmentController.GetAttachment(
        targetAPIS[props.attachmentFamily].GET,
        targetAPIS[props.attachmentFamily].paramName === "vehicleID" ? itemId : null,
        getExtraParams(),
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAttachmentList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }

          setLoading(false);
        }
      );
    }
  };

  // Insert
  const insertAttachment = (data: any) => {
    setProcessingApiAttachment(true);
    if (props.extraGetParametersVector) {
      for (let i = 0; i < props.extraGetParametersVector.length; i++) {
        data[props.extraGetParametersVector[i].name] =
          props.extraGetParametersVector[i].value;
      }
    }
    ApiService.AttachmentController.InsertAttachment(
      targetAPIS[props.attachmentFamily].INSERT,
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let attachments = [...attachmentList];
          attachments.push({ ...data, ...response.payload });
          setAttachmentList(attachments);
        } else if (response.raw.status === 400) {
          ToastMessage(i18next.t("error:MISSING_FIELDS"), "error");
        } else {
          ToastMessage(response.error, "error");
        }

        setProcessingApiAttachment(false);
      }
    );
  };

  // Update
  const updateAttachment = (
    data: IAttachment,
    index: number,
    nopopup = false
  ) => {
    setSavingAt(index, true);
    ApiService.AttachmentController.UpdateAttachment(
      targetAPIS[props.attachmentFamily].UPDATE,
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let attachments = [...attachmentList];
          data.createdDT = new Date().toISOString();
          attachments[index] = { ...data };
          setAttachmentList(attachments);

          if (!nopopup) {
            ToastMessage(
              i18next.t("message:attachmentSuccessfullUpdated"),
              "success"
            );
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setSavingAt(index, false);
      }
    );
  };

  // Delete
  const deleteAttachment = (data: any, index: number) => {
    if (props.extraGetParametersVector) {
      for (let i = 0; i < props.extraGetParametersVector.length; i++) {
        data[props.extraGetParametersVector[i].name] =
          props.extraGetParametersVector[i].value;
      }
    }

    ApiService.AttachmentController.DeleteAttachment(
      targetAPIS[props.attachmentFamily].DELETE,
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setAttachmentList(
            attachmentList.filter(
              (x: IAttachment) => x.attachmentID !== data.attachmentID
            )
          );
          ToastMessage(
            i18next.t("message:attachmentSuccessfullDeleted"),
            "success"
          );
        } else {
          ToastMessage(response.error, "error");
        }
        setSavingAt(index, false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    if (ready) {
      GetAttachment();
    }
  }, [ready]);

  useEffect(() => {
    if (props.vehicleID || props.extraGetParametersVector) {
      setReady(true);
    }
  }, [props.vehicleID, props.extraGetParametersVector]);

  useEffect(() => {
    if (attachmentList.length > 0) {
      let indxes: boolean[] = [];
      for (let i = 0; i < attachmentList.length; i++) {
        indxes.push(false);
      }
      setSavvingIndexes(indxes);
    }
  }, [attachmentList]);

  /* RETURN */
  return (
    <div className="full-width">
      {!ready && !props?.skipSave && (
        <div className="attachments-multi-form-title-wrap">
          {i18next.t("generic:attachment_enable")}
        </div>
      )}

      {(ready || props?.skipSave) && !loading && (
        <div
          style={{ margin: "0 0.5em 1em 0.5em" }}
          className="container-add-form-background multi-form-main-wrap"
        >
          <div>
            <div className="multi-form-title">
              {i18next.t("generic:labelAttachments")}
            </div>
          </div>

          {attachmentList.map((x: IAttachment, i: number) => {
            var disableRow = savvingIndexes[i] || props.disabled;

            if (x.attachmentURL) {
              disableRow = true;
            }

            return (
              <div key={i} className="attachment-main-row">
                <TextField
                  size="small"
                  disabled={disableRow}
                  value={x.attachmentName}
                  onChange={(e: any) => {
                    let attachments = [...attachmentList];
                    attachments[i].attachmentName = e.target.value;
                    setAttachmentList(attachments);
                  }}
                  label={i18next.t("form:title") as string}
                  placeholder={i18next.t("form:title") as string}
                />

                <div className="attachment-main-gap"></div>

                <TextField
                  size="small"
                  disabled={disableRow}
                  value={x.attachmentDescription}
                  onChange={(e: any) => {
                    let attachments = [...attachmentList];
                    attachments[i].attachmentDescription = e.target.value;
                    setAttachmentList(attachments);
                  }}
                  label={i18next.t("form:description") as string}
                  placeholder={i18next.t("form:description") as string}
                />

                <div className="attachment-main-gap"></div>

                <TextField
                  size="small"
                  disabled={true}
                  value={displayUTC0_ISODate(
                    getBlobDate(x.attachmentURL),
                    true
                  )}
                  label={i18next.t("form:uploadDate") as string}
                  placeholder={i18next.t("form:uploadDate") as string}
                />

                <div className="attachment-main-gap"></div>

                {!savvingIndexes[i] && (
                  <span style={{ position: "relative" }}>
                    <div className="attachment-url-label">
                      {getBlobDesc(x.attachmentURL)}
                    </div>
                    <Tooltip title={i18next.t("navigation:save_attachment")}>
                      <IconButton
                        disabled={disableRow}
                        color="primary"
                        aria-label="upload"
                        component="label"
                        onClick={() => {
                          saveAttachmentAt(i);
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                )}

                {!x.attachmentURL && !savvingIndexes[i] && (
                  <Tooltip title={i18next.t("navigation:upload_file")}>
                    <IconButton
                      disabled={disableRow}
                      color="primary"
                      aria-label="upload"
                      component="label"
                    >
                      <input
                        hidden
                        accept="*"
                        type="file"
                        onChange={(e: any) => {
                          uploadFile(e, i);
                        }}
                      />

                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {!savvingIndexes[i] && (
                  <span style={{ position: "relative" }}>
                    <Tooltip title={i18next.t("navigation:delete_file")}>
                      <IconButton
                        disabled={!disableRow}
                        color="primary"
                        aria-label="upload"
                        component="label"
                        onClick={() => {
                          SummonModal("deleteAttachmentSingle");
                          setDeleteAtIndexID(i);
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                )}

                {x.attachmentURL &&
                  !savvingIndexes[i] &&
                  getBlobDesc(x.attachmentURL.toLowerCase()).endsWith(".pdf") && (
                    <span style={{ position: "relative" }}>
                      <Tooltip title={i18next.t("navigation:preview_file")}>
                        <IconButton
                          color="primary"
                          aria-label="download"
                          component="label"
                          onClick={() => {
                            previewFile(i, x.attachmentURL);
                          }}
                        >
                          <PreviewIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )}

                {x.attachmentURL && !savvingIndexes[i] && (
                  <span style={{ position: "relative" }}>
                    <Tooltip title={i18next.t("navigation:download_file")}>
                      <IconButton
                        color="primary"
                        aria-label="download"
                        component="label"
                        onClick={() => {
                          downloadFile(i, x.attachmentURL);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                )}

                {savvingIndexes[i] && (
                  <div className="attachment-circular">
                    <CircularProgress />
                  </div>
                )}
              </div>
            );
          })}

          {!props.disabled && (
            <div className="multi-form-input-wrap" style={{ paddingBottom: 0 }}>
              <AddNewBanner
                forceDisplay
                suppressLayout
                label={i18next.t("generic:newAttachments") as string}
                new={() => {
                  if (loggedUser) {
                    insertAttachment({
                      attachmentURL: "",
                      attachmentDescription: "",
                      attachmentName: "",
                    });
                  }
                }}
              />
            </div>
          )}
        </div>
      )}

      {filePreviewData.length > 0 && (
        <SmartModal
          modalUniqueId="preview_modal_attach"
          title={filePreviewData[0]}
          modalInnerComponent={
            <div>
              {fileSize.includes("GB") ||
                (fileSize.includes("MB") && (
                  <Alert severity="info">
                    {i18next.t("navigation:too_big_pls_wait")} {fileSize}
                  </Alert>
                ))}
              <br />
              <iframe
                style={{ width: "80vw", height: "80vh" }}
                src={filePreviewData[1].replaceAll(
                  "application/octet-stream",
                  "application/pdf"
                )}
              ></iframe>
            </div>
          }
          onReject={() => {
            DismissModal("preview_modal_attach");
          }}
        />
      )}

      <SmartModal 
        title={i18next.t("navigation:delete_file")}
        modalUniqueId="deleteAttachmentSingle"
        modalInnerComponent={
          <p>{i18next.t("navigation:deletePermanentAttachment")}</p>
        }
        onAccept={() => {
          deleteAtIndex(deleteAtIndexID);
        }}
        onReject={() => {
          DismissModal("deleteAttachmentSingle");
        }}
      />

      {ready && loading && processingApiAttachment && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "4em",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AttachementMainHandler;
