import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

import {
  ICenterCostInsert,
  IDriverCenterCostInsert,
  IDriverInsert,
  IDriverUpdate,
} from "../../Models/IDriver";

const DriverController = {
  /* GET */
  DriverGet: async (
    DriverID?: number,
    CompanyID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_GET;
    let parameters: string[] = [];
    if (DriverID) {
      parameters.push(`DriverID=${DriverID}`);
    }
    if (CompanyID) {
      parameters.push(`CompanyID=${CompanyID}`);
    }
    if (DriverID || CompanyID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverGetLanguages: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DRIVER_GET_LANGUAGES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  DriverGetType: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_GET_TYPE;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverNameSurnameGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_NAME_SURNAME_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverGetLicenceType: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_GET_LICENCE_TYPE;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  /* INSERT */
  DriverInsertData: async (
    body: IDriverInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_INSERT_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverUpdateData: async (
    body: IDriverUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  /* DELETE */
  // Delete the selected driver
  DriverDeleteData: async (
    driverID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_DELETE_DATA;
    request.method = "DELETE";
    request.payload = {
      driverID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  // Center costs
  CenterCostGet: async (
    CostCenterID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.COST_CENTER_GET;
    let parameters: string[] = [];
    if (CostCenterID) {
      parameters.push(`CostCenterID=${CostCenterID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CenterCostInsert: async (
    body: ICenterCostInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.COST_CENTER_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CenterCostUpdate: async (
    body: ICenterCostInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.COST_CENTER_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CenterCostDelete: async (
    costCenterID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_COST_CENTER_DELETE;
    request.method = "DELETE";
    request.payload = {
      costCenterID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Driver costs center
  DriverCenterCostGet: async (
    DriverID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_COST_CENTER_GET;
    let parameters: string[] = [];
    if (DriverID) {
      parameters.push(`DriverID=${DriverID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverCenterCostInsert: async (
    body: IDriverCenterCostInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_COST_CENTER_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverCenterCostUpdate: async (
    body: IDriverCenterCostInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_COST_CENTER_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverCenterCostDelete: async (
    driverCostCenterID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_COST_CENTER_DELETE;
    request.method = "DELETE";
    request.payload = {
      driverCostCenterID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DriverInsertFromExcel: async (
    file: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    formData.append("file", file[0]);

    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.DRIVER_INSERT_FROM_EXCEL;
    request.method = "POST";
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default DriverController;
