/* REACT */
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";
/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__roadTaxes.scss";
import DownloadUploadPayment from "../../AdditionalServicesPage/Payments__AdditionalServicesPage/DownloadUploadPayment/DownloadUploadPayment";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const AdminPage__roadTaxes = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* RETURN */
  return (
    <div className="dashboardForm">
      <DownloadUploadPayment />
    </div>
  );
};

export default AdminPage__roadTaxes;
