/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./VehicleOffersPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* MODELS */
import { IUPDATE_CarConfigurator_Offer_Accept } from "../../../../../Models/ICarConfigurator";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";
import { downloadWithName, getBlobDesc } from "../../../../../Utils/FileToBase";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import AccordionContent from "../../../../../Components/AccordionContent/AccordionContent";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* MUI */
import { Alert, Box, Button, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* RENDER BRAND IMAGE - Accordion */
export const renderBrandImageFunc = (brand: string) => {
  if (brand) {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          onError={(e: any) =>
            (e.target.src =
              process.env.PUBLIC_URL + "/Images/brandVehicle/brand-not-found.svg")
          }
          src={
            process.env.PUBLIC_URL +
            "/Images/brandVehicle/" +
            brand.replace(" ", "_") +
            ".png"
          }
          title={brand}
          alt={brand}
        />
      </div>
    );
  } else {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          src={process.env.PUBLIC_URL + "/Images/brandVehicle/brand-not-found.svg"}
        />
      </div>
    );
  }
};

/* COMPONENT */
const VehicleOffersPage = () => {
  /* STATES */
  const [list, setList] = useState<any>([]);
  // File
  const [openSolution, setOpenSolution] = useState<any | null>(null);
  const [filePreviewData, setFilePreviewData] = useState<string[]>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingAcceptOffer, setLoadingAcceptOffer] = useState<boolean>(false);

  /* API */
  // Get offers list
  const GET_CarConfigurator_Vehicle_Offers = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_CarConfigurator_Vehicle_Offers(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Accept offer
  const UPDATE_CarConfigurator_Offer_Accept = (
    data: IUPDATE_CarConfigurator_Offer_Accept
  ) => {
    setLoadingAcceptOffer(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfigurator_Offer_Accept(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingAcceptOffer(false);
      }
    );
  };

  // File size
  const base64size = () => {
    if (filePreviewData.length > 1) {
      let str = filePreviewData[1];
      let size = str.length * (3 / 4) - (str.endsWith("==") ? 2 : 1);

      const formatSizeUnits = (bytes: number) => {
        if (bytes >= 1073741824) {
          return (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
          return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
          return (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
          return bytes + " bytes";
        } else if (bytes == 1) {
          return bytes + " byte";
        } else {
          return "0 bytes";
        }
      };
      return formatSizeUnits(size);
    }
    return "";
  };

  const fileSize = base64size();

  // Preview modal
  const previewFileWithName = (path: string, fileName: string) => {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest();

    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var file = new Blob([xhr.response], { type: "application/octet-stream" });

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result as string;
        let data: string[] = [fileName, result];

        setFilePreviewData(data);
        setTimeout(() => {
          SummonModal("preview_modal_attach");
        }, 500);
      };
    };
    xhr.send();
  };

  // Download file
  const downloadFile = (file: any) => {
    ApiService.UploadController.GetSASString(
      "attachment-offer-file",
      file.attachmentFile,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(file.attachmentFile ?? ""));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  // Preview file
  const previewFile = (file: any) => {
    setOpenSolution(file);
    setFilePreviewData([]);
    ApiService.UploadController.GetSASString(
      "attachment-offer-file",
      file.attachmentFile,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            previewFileWithName(sas, getBlobDesc(file.attachmentFile ?? ""));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    GET_CarConfigurator_Vehicle_Offers();
  }, []);

  /* FUNCTIONS */
  // Render download
  const renderDownload = (props: GridRenderCellParams) => {
    return (
      <div>
        <IconButton
          onClick={() => {
            if (list.attachmentFile) {
              previewFile(list);
            }
          }}
        >
          <VisibilityIcon className="icon-table" />
        </IconButton>

        <IconButton
          onClick={() => {
            if (list.attachmentFile) {
              downloadFile(list);
            }
          }}
        >
          <FileDownloadIcon className="icon-table" />
        </IconButton>
      </div>
    );
  };

  // Render button
  const renderButton = (props: GridRenderCellParams) => {
    const isAccepted = props.row.list.data.map((x: any) => x.accepted).includes(true);

    let contentAccepted;

    // Check accepted status
    if (props.row.accepted === true) {
      contentAccepted = (
        <div className="display-flex-vertical-center content-accepted">
          <DoneIcon /> {i18next.t("navigation:km_code_status_ACCEPTED")}
        </div>
      );
    } else {
      contentAccepted = (
        <div className="content-refused">
          {i18next.t("navigation:km_code_status_REFUSED")}
        </div>
      );
    }

    return isAccepted ? (
      contentAccepted
    ) : (
      <div className="text-align-center">
        <Button
          variant="contained"
          className="buttonAccept"
          startIcon={<DoneIcon />}
          onClick={() => {
            UPDATE_CarConfigurator_Offer_Accept({
              vehiclesRequestOfferID: props.row.list.data[0].vehicleRequestOfferID,
              isPromptDelivery: props.row.list.info.isPromptDelivery,
            });

            GET_CarConfigurator_Vehicle_Offers();
          }}
        >
          {i18next.t("form:accept")}
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  // Supplier
  const columnsDefSupplier: IDataGridColumnDef[] = [
    {
      field: "download",
      headerName: i18next.t("form:attachments"),
      renderCell: renderDownload,
      type: 'custom'
    },
    {
      field: "supplier",
      headerName: i18next.t("form:vehicleSupplier"),
    },
    {
      field: "contractType",
      headerName: i18next.t("navigation:contractTypeName"),
    },
    {
      field: "requestDate",
      headerName: i18next.t("form:_mctc_dataRichiestaEvento"),
    },
    {
      field: "creationDate",
      headerName: i18next.t("form:creationData"),
    },
    {
      field: "note",
      headerName: i18next.t("form:note"),
    },
    {
      field: "button",
      headerName: "",
      renderCell: renderButton,
      type: 'custom'
    },
  ];
  // Prompt Delivery
  const columnsDefPromptDelivery: IDataGridColumnDef[] = [
    {
      field: "download",
      headerName: i18next.t("form:attachments"),
      renderCell: renderDownload,
      type: 'custom'
    },
    {
      field: "supplier",
      headerName: i18next.t("form:dealer"),
    },
    {
      field: "requestDate",
      headerName: i18next.t("form:_mctc_dataRichiestaEvento"),
    },
    {
      field: "creationDate",
      headerName: i18next.t("form:creationData"),
    },
    {
      field: "note",
      headerName: i18next.t("form:note"),
    },
    {
      field: "button",
      headerName: "",
      renderCell: renderButton,
      type: 'custom'
    },
  ];

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_configure_vechicles_offers")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          {list.map((x: any) => {
            return (
              <AccordionContent
                classNameAccordion="margin-vertical-small accordion-noBorderRadius"
                classNameSummary="accordion-background padding-horizontal-medium"
                icon={renderBrandImageFunc(x.info.brandImage)}
                title={""}
                subTitle={x.info.fitting}
                middleSummary={
                  <div className="display-flex gap-extra-large">
                    <div>
                      <strong>{i18next.t("form:type")}:</strong>{" "}
                      {x.info.isPromptDelivery
                        ? i18next.t("form:promptDeliveryOffer")
                        : i18next.t("form:vehicleSupplier")}
                    </div>
                    <div>
                      <strong>{i18next.t("form:applicant")}:</strong> {x.info.applicant}
                    </div>
                  </div>
                }
                rightSummary={
                  <div className="display-flex-vertical-center gap-extra-small">
                    <CircleIcon
                      sx={x.info.newOffers ? { color: "#02aa3f" } : { color: "#c6c6c6" }}
                    />
                    {x.newOffers
                      ? i18next.t("form:newOffers")
                      : i18next.t("form:noNewOffers")}
                  </div>
                }
                content={
                  <div className={"margin-vertical-small"}>
                    <DataGridWrap
                      hideToolbar={x.data.length === 0 ? false : true}
                      headers={
                        x.info.isPromptDelivery
                          ? columnsDefPromptDelivery
                          : columnsDefSupplier
                      }
                      rows={x.data.map((k: any, i: number) => {
                        return {
                          id: i,
                          list: x,

                          accepted: k.accepted,
                          supplier: k.supplier,
                          contractType: k.contractType,
                          requestDate: displayUTC0_ISODate(k.requestDate, false),
                          creationDate: displayUTC0_ISODate(k.creationDate, false),
                          note: k.note || "-",
                        };
                      })}
                    />
                  </div>
                }
              />
            );
          })}
        </Box>

        {filePreviewData.length > 0 && (
          <SmartModal
            modalUniqueId="preview_modal_attach"
            title={filePreviewData[0]}
            modalInnerComponent={
              <div>
                <strong>{i18next.t("form:note")}</strong>
                <div>{openSolution?.note}</div>
                <hr />

                {fileSize.includes("GB") ||
                  (fileSize.includes("MB") && (
                    <Alert severity="info">
                      {i18next.t("navigation:too_big_pls_wait")} {fileSize}
                    </Alert>
                  ))}

                <br />

                <iframe
                  style={{ width: "80vw", height: "80vh" }}
                  src={filePreviewData[1].replaceAll(
                    "application/octet-stream",
                    "application/pdf"
                  )}
                ></iframe>
              </div>
            }
            onReject={() => {
              DismissModal("preview_modal_attach");
            }}
          />
        )}
      </div>

      <LoaderBackdrop loading={loadingGet || loadingAcceptOffer} />
    </div>
  );
};

export default VehicleOffersPage;
