import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IAssignmentInsert, IAssignmentUpdate } from "../../Models/IAssignment";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const AssignmentController = {
  AssignmentGetType: async (
    assignmentTypeID?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_GET_TYPE;
    if (assignmentTypeID) {
      request.url += `?assignmentTypeID=${assignmentTypeID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TerminateAssignment: async (
    payload: {
      assignmentID: number;
      effectiveEndDT: string;
      endVehicleKM: number;
    },
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_TERMINATE;
    request.method = "PUT";
    request.payload = payload;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AssignmentGetByCarID: async (
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url =
      ApiEndPoints.VEHICLE_ASSIGNMENT_GET_BY_CAR_ID + "?vehicleID=" + vehicleID;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetAssignmentDeductibility: async (
    assignmentDeductibilityID?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_GET_DEDUCIBILITY;
    if (assignmentDeductibilityID) {
      request.url += `?assignmentDeductibilityID=${assignmentDeductibilityID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AssignmentGet: async (
    driverID?: number,
    vehicleID?: number,
    assignmentID?: number,
    assignmentTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_GET;
    let parameters: string[] = [];
    if (driverID) {
      parameters.push(`driverID=${driverID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (assignmentID) {
      parameters.push(`assignmentID=${assignmentID}`);
    }
    if (assignmentTypeID) {
      parameters.push(`assignmentTypeID=${assignmentTypeID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AssignmentInsert: async (
    body: IAssignmentInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AssignmentUpdate: async (
    body: IAssignmentUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AssignmentDelete: async (
    assignmentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ASSIGNMENT_DELETE;
    request.method = "DELETE";
    request.payload = {
      assignmentID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AssignmentController;
