/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import OwnershipCosts__StatisticsPage_Body from "./OwnershipCosts__StatisticsPage_Body";

/* COMPONENT */
const OwnershipCosts__StatisticsPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_statistics_ownercost")} />

      <OwnershipCosts__StatisticsPage_Body />
    </div>
  );
};

export default OwnershipCosts__StatisticsPage;
