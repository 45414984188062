import i18next from 'i18next';
import CookiePolicy from '../../Components/CookiePolicy/CookiePolicy';

const CookiePolicyPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        minHeight: "60vh",
        margin: "0 3em",
      }}
    >
      <CookiePolicy />
    </div>
  );
}

export default CookiePolicyPage;