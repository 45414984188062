import { useEffect, useState } from "react";
import "./StoreSubscriptionPage.scss";
import StepFleetPlan from "../../Components/StepFleetPlan/StepFleetPlan";
import { Box, Button, ButtonGroup } from "@mui/material";
import HeaderPageDashboard from "../../Components/HeaderPageDashboard/HeaderPageDashboard";
import i18next from "i18next";
import { triggerFormValidation } from "../../Components/MultiForm/MultiForm";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "../../Costants/AppRoutes";
import { writePaymentData } from "../PaymentReadyPage/PaymentReadyPage";
import { ICompanyGet } from "../../Models/ICompany";
import { GlobalState } from "../../Reducers/RootReducer";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
var dataClone: any = {};
var billingInfoClone: any = {};

const StoreSubscriptionPage = () => {
  const dispatch = useDispatch();
  const [data, setData_] = useState<any>({
    price: -1,
  });
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);
  const [pageSelect, setPageSelect] = useState<number>(0);

  const [billingInfos, setBillingInfos_] = useState<any>();

  const setData = (data: any) => {
    dataClone = { ...data };
    setData_(data);
  };

  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const setBillingInfos = (infos: any) => {
    billingInfoClone = { ...infos };
    setBillingInfos_(infos);
  };

  const handlePaymentReadyToGo = () => {
    SummonModal("VehicleRemoveList");
  };

  useEffect(() => {
    // Add event listener for payment-ready-to-go event
    document.addEventListener("payment-ready-to-go", handlePaymentReadyToGo);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "payment-ready-to-go",
        handlePaymentReadyToGo
      );
    };
  }, []);

  return (
    <div className="dashboardForm">
      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined"></ButtonGroup>
        </div>
        <Box className="dashboardForm__data">
          <div>
            <StepFleetPlan
              includeBillingForm
              emitBillingInfos={(data: any) => {
                setBillingInfos(data);

                if (
                  data &&
                  data.billingEmail &&
                  Object.values(data).indexOf(null) === -1
                ) {
                  setPage(1);
                }
              }}
              emitState={(dt: any) => {
                dt.redirectToRegistrationStepper = true;
                dt.insertPlanMethod = true;
                setData(dt);
                if (dt.paymentEvaluation && +dt.paymentEvaluation.cost != 0) {
                  setPageSelect(1);
                  setPage(1);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                paddingRight: "2em",
              }}
            >
              <Button
                disabled={page !== 1 || pageSelect !== 1}
                variant="contained"
                onClick={() => {
                  writePaymentData({
                    ...dataClone,
                    ...billingInfoClone,
                  });
                  navigate(AppRoutes.PAYMENT_READY_PAGE);
                }}
              >
                {i18next.t("navigation:accept")}
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};
export default StoreSubscriptionPage;
