const SERVICE_ROLE_CODES = [
  "FLEET_ADDON",
  "ROAD_TAX_ADDON",
  "MCTC_ADDON",
  "TICKET_ADDON",
  "TICKET_ADDON_PRO",
  "PROMPT_DELIVERY_ADDON",
  "CAR_CONFIGURATOR_ADDON",
  "QUOTE_ADDON",
  "REQUEST_FUEL_CARD_ADDON",
  "REQUEST_SATELLITE_EQUIPMENT",
];

export const getServiceSupportedOptions = () => {
  return SERVICE_ROLE_CODES.map((x: string) => {
    return {
      key: x,
      text: x,
    };
  });
};
