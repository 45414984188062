import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderBackdrop from "../../../../Components/LoaderBackdrop/LoaderBackdrop";
import UrlTabbedView from "../../../../Components/UrlTabbedView/UrlTabbedView";
import AppRoutes from "../../../../Costants/AppRoutes";
import { ICompanyGet } from "../../../../Models/ICompany";
import { IDriverGet } from "../../../../Models/IDriver";
import { IUser } from "../../../../Models/IUser";
import { GlobalState } from "../../../../Reducers/RootReducer";
import { VehiclesActions } from "../../../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../../../Services/ApiService";
import { IAPIResponse } from "../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../Utils/Toastify";
import car from "./../../../../Assets/ImagesEmbedded/tesla.png";
import {
  IVehicleDataGet,
  IVehicleTotalCost,
} from "./../../../../Models/IVehicles";
import "./VehiclesPage.scss";
import VehiclesPage_subPages_StepAssignedDrivers from "./VehiclesPage_subPages/VehiclesPage_subPages_Assegnee/VehiclesPage_subPages_Assegnee_StepAssignedDrivers";
import VehiclesPage_subPages_Contracts from "./VehiclesPage_subPages/VehiclesPage_subPages_Contracts/VehiclesPage_subPages_Contracts";
import VehiclesPage_subPages_FuelCards from "./VehiclesPage_subPages/VehiclesPage_subPages_FuelCards/VehiclesPage_subPages_FuelCards";
import VehiclesPage_subPages_Orders from "./VehiclesPage_subPages/VehiclesPage_subPages_Orders/VehiclesPage_subPages_Orders";
import VehiclesPage_subPages_OtherServices from "./VehiclesPage_subPages/VehiclesPage_subPages_OtherServices/VehiclesPage_subPages_OtherServices";
import VehiclesPage_subPages_Tickets from "./VehiclesPage_subPages/VehiclesPage_subPages_Tickets/VehiclesPage_subPages_Tickets";
import VehiclesPage_subPages_insurance from "./VehiclesPage_subPages/VehiclesPage_subPages_insurance/VehiclesPage_subPages_insurance";
import VehiclesPage_subPages_status from "./VehiclesPage_subPages/VehiclesPage_subPages_status/VehiclesPage_subPages_status";
import VehiclesPage_subPages_telepass from "./VehiclesPage_subPages/VehiclesPage_subPages_telepass/VehiclesPage_subPages_telepass";
import VehiclesPage_subPages_vehicleData from "./VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { Alert } from "@mui/material";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { IAssignmentGet } from "../../../../Models/IAssignment";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import Create__VehiclePage from "./Create__VehiclePage/Create__VehiclePage";
import { IVehicleDisposal } from "../../../../Models/IDisposal";
import RefreshIcon from '@mui/icons-material/Refresh';
import { currencyDecoder } from "../../../../Utils/Decoder";

export const formatPlate = (rawPlate: string | undefined) => {
  if (rawPlate) {
    rawPlate = rawPlate.toLocaleUpperCase();
    return (
      rawPlate.substring(0, 2) +
      " " +
      rawPlate.substring(2, 5) +
      " " +
      rawPlate.substring(5, 7)
    );
  }
  return "";
};

const VehiclesPage = () => {
  const [params, setSearchParams] = useSearchParams();
  const vehicleId: string | null = params.get("vehicleID");
  const assignmentID: string | null = params.get("assignmentID");

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const drivers: IDriverGet[] = useSelector(
    (state: GlobalState) => state.vehicles.driversForCompany
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const driver: IDriverGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeDriver
  );
  const assignments: IAssignmentGet[] = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignments
  );
  const assignment: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignment
  );

  const dispatch = useDispatch();
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(false);
  const [missingVisibility, setMissingVisibility] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAssignmentList, setLoadingAssignmentsList] =
    useState<boolean>(false);

  const [totalCost, setTotalCost] = useState<IVehicleTotalCost>();
  const [loadingVehicleTotalCost, setLoadingVehicleTotalCost] = useState<boolean>(false);

  const GetVehicleDisposal = async () => {
    ApiService.DisposalController.GetVehicleDisposal(
      null,
      vehicleId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let disposedDone: IVehicleDisposal[] = response.payload.filter(
            (x: IVehicleDisposal) => x.inProgress === false
          );
          dispatch(VehiclesActions.setVehicleDisposed(disposedDone.length));
        }
      }
    );
  };

  const loadAssignmentsList = () => {
    if (vehicleId) {
      setLoadingAssignmentsList(true);
      ApiService.AssignmentController.AssignmentGetByCarID(
        vehicleId,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let assigments: IAssignmentGet[] = [
              {
                assignmentID: -1,
                driverDisplayName: i18next.t("message:all"),
                driverID: -1,
              } as any,
            ];
            assigments = assigments.concat(response.payload.reverse());

            dispatch(VehiclesActions.setCurrentAssignments(assigments));
            let requestedAssignment: number = +(assignmentID ?? -1);

            dispatch(
              VehiclesActions.setCurrentAssignment(
                assigments.find(
                  (x: IAssignmentGet) => x.assignmentID === requestedAssignment
                )
              )
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAssignmentsList(false);
        }
      );
    }
  };

  const loadVehicle = () => {
    dispatch(VehiclesActions.setActiveVehicleForCompany(undefined));
    if (loggedCompany) {
      setLoading(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        vehicleId,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;

            if (vehicles.length === 0) {
              setMissingVisibility(vehicleId);
            } else {
              dispatch(VehiclesActions.setActiveVehicleForCompany(vehicles[0]));
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadDrivers = () => {
    if (loggedUser && vehicleId) {
      setLoadingDrivers(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadAssignmentsList();

            dispatch(VehiclesActions.setDriversForCompany(response.payload));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDrivers(false);
        }
      );
    }
  };

  const loadSobstitutesVehicles = (vehicleId: string) => {
    ApiService.VehicleServiceController.VehicleSobstituteGet(
      null,
      vehicleId,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(VehiclesActions.setVehicleSubstitutes(response.payload));
        }
      }
    );
  };

  const vehicleGetTotalCost = (startDT?: string, endDT?: string, isAll?: boolean) => {
    setLoadingVehicleTotalCost(true);
    ApiService.VehicleServiceController.VehicleGetTotalCost(
      vehicleId,
      isAll ? null : (startDT ? startDT : (assignment ? assignment.startDT : null)),
      isAll ? null : (endDT ? endDT : (assignment && assignment.effectiveEndDT ? assignment.effectiveEndDT : new Date().toISOString())),
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTotalCost(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setTimeout(() => {
          setLoadingVehicleTotalCost(false);
        }, 1000);
      }
    );
  };

  const loadCoreData = () => {
    loadVehicle();
    loadDrivers();
    reloadSustitutes();
    GetVehicleDisposal();
    vehicleGetTotalCost();
  };

  useEffect(() => {
    if (vehicleId) {
      loadCoreData();
      dispatch(VehiclesActions.setVehicleMctc(undefined));
    }
  }, [vehicleId]);

  const alertMessageDisposed = (disposed: number, vehicleStatusID: number) => {
    switch (disposed) {
      case 0:
        if (vehicleStatusID === 3) {
          return (
            <div style={{ marginBottom: "0.5em" }}>
              <Alert severity="success">
                {i18next.t("navigation:vehicle_is_assigned")}
              </Alert>
            </div>
          );
        } else if (vehicleStatusID === 2) {
          return (
            <div style={{ marginBottom: "0.5em" }}>
              <Alert severity="warning">
                {i18next.t("navigation:vehicle_is_be_assigned")}
              </Alert>
            </div>
          );
        } else {
          return (
            <div style={{ marginBottom: "0.5em" }}>
              <Alert severity="warning">
                {i18next.t("navigation:vehicle_not_assigned")}
              </Alert>
            </div>
          );
        }
        break;
      case 1:
        return (
          <div style={{ marginBottom: "0.5em" }}>
            <Alert severity="error">
              {i18next.t("navigation:vehicle_is_disposed")}
            </Alert>
          </div>
        );

      default:
        break;
    }
  };

  const selectDriverForAssignment = (driverID: number) => {
    let driverSelected: IDriverGet | undefined = undefined;

    if (driverID === -1) {
      let active: IAssignmentGet | undefined = assignments.find(
        (x: IAssignmentGet) => x.effectiveEndDT === null
      );

      // no current, go on ALL
      if (!active) {
        active = assignments.find((x: IAssignmentGet) => x.driverID === -1);
      }

      if (active) {
        driverSelected = drivers.find(
          (x: IDriverGet) => x.driverInfo.driverID === active?.driverID
        );
      }

      // mark as all selected
      if (driverSelected) {
        driverSelected = JSON.parse(
          JSON.stringify(driverSelected)
        ) as IDriverGet;
        (driverSelected as any).driverInfo.driverIDOriginal =
          driverSelected.driverInfo.driverID;
        driverSelected.driverInfo.driverID = -1;
      }
    } else {
      driverSelected = drivers.find(
        (x: IDriverGet) => x.driverInfo.driverID === driverID
      );
    }

    if (driverSelected) {
      dispatch(VehiclesActions.setActiveDriverForCompany(driverSelected));
    } else {
      dispatch(VehiclesActions.setActiveDriverForCompany(undefined));
    }
  };

  useEffect(() => {
    if (assignment) {
      selectDriverForAssignment(assignment.driverID);
    }
  }, [assignment]);

  const reloadSustitutes = () => {
    if (vehicleId) {
      loadSobstitutesVehicles(vehicleId);
    }
  };
  (window as any)["reloadSustitutes"] = reloadSustitutes;
  (window as any)["reloadCoreData"] = loadCoreData;

  useEffect(() => {
    window.document.addEventListener("reload-substitutes-v", reloadSustitutes);
    return () => {
      dispatch(VehiclesActions.setVehicleDisposed(0));
      window.document.removeEventListener(
        "reload-substitutes-v",
        reloadSustitutes
      );
    };
  }, []);

  const renderTabbedView = () => {
    return (
      <div>
        {!loading && vehicleId && (
          <div
            className="vehicles-banner-image-title"
            style={{ backgroundImage: `url(${car})` }}
          >
            <div className="vehicles-banner-image-title-inner">
              <div className="vehicles-banner-image-title-label">
                {vehicle ? vehicle.fittingDescription : "-"}
              </div>
              <div className="vehicles-banner-image-title-lower-row">
                <div className="vehicles-banner-image-owner">
                  <div className="vehicles-banner-image-owner-title">
                    {i18next.t("navigation:_vehicle_page_vehicle_driver")}
                  </div>
                  <select
                    value={assignment?.assignmentID}
                    onChange={(e: any) => {
                      let ID = +e.target.value;

                      let selectedAssignment: IAssignmentGet | undefined =
                        assignments.find(
                          (x: IAssignmentGet) => x.assignmentID === ID
                        );

                      if (selectedAssignment) {
                        let newQueryParameters: URLSearchParams =
                          new URLSearchParams();
                        newQueryParameters.set("vehicleID", vehicleId);
                        newQueryParameters.set(
                          "assignmentID",
                          selectedAssignment.assignmentID.toString()
                        );
                        setSearchParams(newQueryParameters);
                        dispatch(
                          VehiclesActions.setCurrentAssignment(
                            selectedAssignment
                          )
                        );
                        vehicleGetTotalCost(
                          selectedAssignment.startDT, 
                          selectedAssignment.effectiveEndDT ? selectedAssignment.effectiveEndDT : new Date().toISOString(),
                          selectedAssignment.assignmentID === -1);
                      }
                    }}
                  >
                    {!loadingDrivers &&
                      !loadingAssignmentList &&
                      assignments
                        .sort((a: IAssignmentGet, b: IAssignmentGet) => {
                          if (a.assignmentID === -1) {
                            return -1;
                          }
                          if (b.assignmentID === -1) {
                            return 1;
                          }
                          return b.assignmentID - a.assignmentID;
                        })
                        .map((x: IAssignmentGet, i: number) => {
                          let extraDesc: string = "";
                          if (x.assignmentID !== -1) {
                            extraDesc =
                              " (" +
                              displayUTC0_ISODate(x.startDT, true) +
                              " - " +
                              (x.effectiveEndDT
                                ? displayUTC0_ISODate(x.effectiveEndDT, true)
                                : i18next.t("message:current")) +
                              ")";
                          }
                          return (
                            <option key={i} value={x.assignmentID}>
                              {x.driverDisplayName + extraDesc}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className="vehicles-banner-image-centerinfo">
                  {vehicle ? formatPlate(vehicle.vehicleLicensePlate) : "-"}
                </div>
                <div className="vehicles-banner-image-costs">
                  {i18next.t("navigation:_vehicle_page_prices")}{" "}
                  <div>
                    <RefreshIcon 
                      titleAccess={i18next.t("navigation:reloadVehicleTotalCost") as string}
                      className="refresh-icon"
                      sx={loadingVehicleTotalCost ? {
                        animation: "spin 2s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(-360deg)",
                          },
                          "100%": {
                            transform: "rotate(0deg)",
                          },
                        },
                      }: {}}
                      onClick={() => {
                        vehicleGetTotalCost();
                      }} 
                    />
                    {currencyDecoder((totalCost ? totalCost.totalCost : 0), 2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ height: "0.5em" }}></div>

        {!vehicleId && (
          <Alert sx={{ marginTop: "1em" }} severity="error">
            {i18next.t("error:missing_vehicle_id")}
          </Alert>
        )}

        {!vehicle && missingVisibility && (
          <Alert sx={{ marginTop: "1em" }} severity="error">
            {i18next.t("error:missing_vehicle_visibility") +
              " ID:" +
              missingVisibility}
          </Alert>
        )}

        {!loading &&
          alertMessageDisposed(
            disposed,
            (vehicle && vehicle.vehicleStatusID) || 1
          )}

        {vehicle && vehicleId && (
          <UrlTabbedView
            tabs={[
              {
                label: "_vheicles_tab_nav_vehicle",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB,
                element: <VehiclesPage_subPages_status />,
              },
              {
                label: "_vheicles_tab_nav_order",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__ORDERS_TAB,
                element: <VehiclesPage_subPages_Orders />,
              },
              {
                label: "_vheicles_tab_nav_vehicle_data",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__DATA_TAB,
                element: (
                  <VehiclesPage_subPages_vehicleData
                    onResetVehicleUpdate={() => {
                      loadVehicle();
                    }}
                  />
                ),
              },
              {
                label: "_vheicles_tab_nav_contract",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__CONTRACTS_TAB,
                element: <VehiclesPage_subPages_Contracts />,
              },
              {
                label: "_vheicles_tab_nav_ownership",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__OWNERSHIP_TAB,
                element: <VehiclesPage_subPages_StepAssignedDrivers />,
              },
              {
                label: "_vheicles_tab_nav_tickets",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__TICKETS_TAB,
                element: <VehiclesPage_subPages_Tickets />,
              },
              {
                label: "_vheicles_tab_nav_tpass",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__TELEPASS_TAB,
                element: <VehiclesPage_subPages_telepass />,
              },
              {
                label: "_vheicles_tab_nav_c_card",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__CARDS_TAB,
                element: <VehiclesPage_subPages_FuelCards />,
              },
              {
                label: "_vheicles_tab_nav_insurance",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__ASSURANCE_TAB,
                element: <VehiclesPage_subPages_insurance />,
              },
              {
                label: "_vheicles_tab_nav_otherservices_costs",
                path:
                  AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__OTHER_SERVICES_TAB,
                element: <VehiclesPage_subPages_OtherServices />,
              },
            ]}
          />
        )}
      </div>
    );
  };

  return (
    <div className="veichles-page-main-wrap">
      <Routes>
        <Route path="tab*" element={renderTabbedView()} />
        <Route
          path={AppRoutes.CREATE_VEHICLE_MARKER}
          element={<Create__VehiclePage />}
        />
      </Routes>

      <LoaderBackdrop
        loading={loading || loadingDrivers || loadingAssignmentList}
      />
    </div>
  );
};

export default VehiclesPage;
