import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IAdminConfirmPayment } from "../../Models/IAdminCompanyList";
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const AdminController = {
  ImpersonateStart: async (
    accountImpID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADMIN_IMP_SET;
    request.payload = { accountImpID };
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  ImpersonateClose: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADMIN_IMP_CLEAR;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AdminConfirmPayment: async (
    body: IAdminConfirmPayment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADMIN_PAYMENT;
    request.method = "PUT";
    request.port = "7238";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AdminCompanyListGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADMIN_COMPANY_LIST_GET;
    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AdminBrokerListGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADMIN_BROKER_LIST_GET;
    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AdminController;
