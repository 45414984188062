/* IMPORTs */
import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

/* CONTROLLER */
const SearchController = {
  /* GET */
  // Get the vehicle chassis
  SearchChassisGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_ASSIGNMENT_GET_TYPE}`;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default SearchController;
