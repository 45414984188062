import { IFleetPlan, ISavePlanService } from "../../Models/IFleetPlan";
import { IAddedService } from "../../Models/IService";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { IVehicleResume } from "../../Models/IVehicles";

export const SET_PLAN_SERVICE_DATA = "SET_PLAN_SERVICE";
export const SET_VEHICLE_SUMMARY_DATA = "SET_VEHICLE_SUMMARY_DATA";
export const SET_USER_SUBSCRIPTIONS = "SET_USER_SUBSCRIPTIONS";
export const SET_USER_SERVICE_ADDONS = "SET_USER_SERVICE_ADDONS";
export const SET_FLEET_PLANS = "SET_FLEET_PLANS";
export const SET_ADDED_SERVICES = "SET_ADDED_SERVICES";

interface SetPlanServiceDataActionType {
  type: typeof SET_PLAN_SERVICE_DATA;
  payload: ISavePlanService;
}

interface SetVehicleSummaryActionType {
  type: typeof SET_VEHICLE_SUMMARY_DATA;
  payload: IVehicleResume[];
}

interface SetUserSubscriptionsActionType {
  type: typeof SET_USER_SUBSCRIPTIONS;
  payload: ISubscriptions[];
}

interface SetUserServiceAddonsActionType {
  type: typeof SET_USER_SERVICE_ADDONS;
  payload: IAddedService[];
}

interface SetFleetPlansActionType {
  type: typeof SET_FLEET_PLANS;
  payload: IFleetPlan[];
}

interface SetAddedServicesActionType {
  type: typeof SET_ADDED_SERVICES;
  payload: IAddedService[];
}

export type RegistrationActionType =
  | SetPlanServiceDataActionType
  | SetVehicleSummaryActionType
  | SetUserSubscriptionsActionType
  | SetUserServiceAddonsActionType
  | SetFleetPlansActionType
  | SetAddedServicesActionType;

const setPlanServiceData = (
  planService: ISavePlanService
): SetPlanServiceDataActionType => {
  return { type: SET_PLAN_SERVICE_DATA, payload: planService };
};

const setUserSubscriptions = (
  userSubscriptions: ISubscriptions[]
): SetUserSubscriptionsActionType => {
  return { type: SET_USER_SUBSCRIPTIONS, payload: userSubscriptions };
};

const setUserServiceAddons = (
  userServiceAddons: IAddedService[]
): SetUserServiceAddonsActionType => {
  return { type: SET_USER_SERVICE_ADDONS, payload: userServiceAddons };
};

const setVehicleSummaryData = (
  vehicleSummany: IVehicleResume[]
): SetVehicleSummaryActionType => {
  return { type: SET_VEHICLE_SUMMARY_DATA, payload: vehicleSummany };
};

const setFleetPlans = (
  fleetPlans: IFleetPlan[]
): SetFleetPlansActionType => {
  return { type: SET_FLEET_PLANS, payload: fleetPlans };
};

const setAddedServices = (
  addedServices: IAddedService[]
): SetAddedServicesActionType => {
  return { type: SET_ADDED_SERVICES, payload: addedServices };
};

export const RegistrationAction = {
  setPlanServiceData,
  setUserServiceAddons,
  setVehicleSummaryData,
  setUserSubscriptions,
  setFleetPlans,
  setAddedServices,
};
