import { FormControl, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { decodeDateLocale } from "../../../Utils/Decoder";
import { IMultiFormField } from "../MultiForm";

export interface IStrongTimePickerProps extends IMultiFormField {
  onChange: (value: string) => void;
}

const StrongTimePicker = (props: IStrongTimePickerProps) => {
  const [value, setValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (props.defaultValue) {
      setValue(dayjs(props.defaultValue));
    }
  }, []);

  useEffect(() => {
    if (value !== null) {
      props.onChange(value.toISOString());
    }
  }, [value]);

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      setValue(newValue);
    }
  };

  return (
    <div>
      <FormControl fullWidth>
        <LocalizationProvider
          locale={decodeDateLocale()}
          dateAdapter={AdapterDayjs}
        >
          <TimePicker
            disabled={props.disabled}
            label={props.placeholder}
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                required={props.required}
                variant={props.variant ? props.variant : "outlined"}
                helperText={props.errorText ? props.errorText : props.hint}
                error={props.errorText !== undefined && props.errorText !== ""}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default StrongTimePicker;
