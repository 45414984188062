import InfoIcon from "@mui/icons-material/Info";
import i18next from "i18next";
import AccordionContent from "../AccordionContent/AccordionContent";
import "./StampPaymentInfo.scss";

const StampPaymentInfo = () => {
  const IconInfo = () => {
    return <InfoIcon color="primary" style={{ marginRight: "1em" }} />;
  };

  return (
    <div className="read-file-info-wrapper">
      <AccordionContent
        title={i18next.t("navigation:_stamp_payments_info_title")}
        content={
          <div className="accordion-wrapper">
            <div className="step-wrapper">
              <div className="margin">
                <strong className="step-title">Step 1</strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_stamp_payments_info_title_step1")}
                </strong>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    i18next.t("navigation:_stamp_payments_info_description_step1") ?? "",
                }}
              />
            </div>
            <div className="step-wrapper-middle">
              <div className="margin">
                <strong className="step-title">Step 2</strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_stamp_payments_info_title_step2")}
                </strong>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    i18next.t("navigation:_stamp_payments_info_description_step2") ?? "",
                }}
              />
            </div>
            <div className="step-wrapper">
              <div className="margin">
                <strong className="step-title">Step 3</strong>
              </div>
              <div className="margin">
                <strong>
                  {i18next.t("navigation:_stamp_payments_info_title_step3")}
                </strong>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    i18next.t("navigation:_stamp_payments_info_description_step3") ?? "",
                }}
              />
            </div>
          </div>
        }
        icon={IconInfo()}
      />
    </div>
  );
};

export default StampPaymentInfo;
