/* REACT */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* LIBRARY */
import i18next from "i18next";

/* MODALS */
import { IUser } from "../../../../../../Models/IUser";

/* STYLE */
import "./ViewSuppliesPage.scss";

/* API SERVICES */

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import FormInsertSupply from "../../../../../../Components/FormInsertSupply/FormInsertSupply";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import CheckIcon from "@mui/icons-material/Check";
/* MUI */
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { useEffect, useState } from "react";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import {
  IFuelSupplyType,
  IVehicleFuelSupply,
} from "../../../../../../Models/IVehicleFuelSupply";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import ApiService from "../../../../../../Services/ApiService";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { formatPlate } from "../../../VehiclesPage/VehiclesPage";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { currencyDecoder } from "../../../../../../Utils/Decoder";

/* COMPONENT */
const ViewSuppliesPage = () => {
  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  /* NAVIGATE */
  const navigate = useNavigate();
  const [loadingFuelSupplyList, setLoadingFuelSupplyList] =
    useState<boolean>(false);
  const [fuelSupplyList, setFuelSupplyList] = useState<IVehicleFuelSupply[]>(
    []
  );
  const [loadingFuelSupplyTypes, setLoadingFuelSupplyTypes] = useState<boolean>(false);
  const [fuelSupplyTypes, setFuelSupplyTypes] = useState<IFuelSupplyType[]>([]);
  const [currentFuelSupply, setCurrentFuelSupply] = useState<any>(null);
  const [currentListOtherCost, setCurrentListOtherCost] = useState<any>({});

  // Load list
  const loadFuelSupplyList = () => {
    if (loggedCompany) {
      setLoadingFuelSupplyList(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyGet(
        null,
        loggedCompany?.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelSupplyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelSupplyList(false);
        }
      );
    }
  };

  const loadFuelSupplyType = () => {
    if (loggedUser) {
      setLoadingFuelSupplyTypes(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyTypeGet(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelSupplyTypes(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelSupplyTypes(false);
        }
      );
    }
  };

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          let fuelSupply_: IVehicleFuelSupply = fuelSupplyList.filter(
            (x: IVehicleFuelSupply) =>
              x.info.fuelSupplyID === props.row.fuelSupplyID
          )[0];
          setCurrentFuelSupply({
            ...fuelSupply_["info"],
            ...{ fuelSupplyServices: fuelSupply_["details"] },
          });
          SummonModal("fuelSupplyID_Modal");
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };

  const renderServed = (props: GridRenderCellParams) => {
    if (props.value) {
      return (
        <div>
          <CheckIcon />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderAmount = (props: GridRenderCellParams) => {
    let measurementUnitSymbol = fuelSupplyTypes.find(
      (x: IFuelSupplyType) => x.fuelSupplyTypeID === props.row.fuelSupplyTypeID
    )?.measurementUnitSymbol;
    let measurementUnitName = fuelSupplyTypes.find(
      (x: IFuelSupplyType) => x.fuelSupplyTypeID === props.row.fuelSupplyTypeID
    )?.measurementUnitName;
    return (
      <div>
        {props.value +
          " - " +
          measurementUnitName +
          " (" +
          measurementUnitSymbol +
          ")"}
      </div>
    );
  };

  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  const otherCost = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value.length > 0 ? (
          <Button
              onClick={() => {
                /* COLUMNS */
                const columnsDef_otherCost: IDataGridColumnDef[] = [
                  {
                    field: "fuelSupplyServiceName",
                    headerName: i18next.t("form:nameService"),
                  },
                  {
                    field: "fuelSupplyServiceCost",
                    headerName: i18next.t("form:serviceImport"),
                  },
                ];
      
                /* ROWS */
                const rowsDef_otherCost = [];
      
                for (let i = 0; i < props.value.length; i++) {
                  // Push row
                  rowsDef_otherCost.push({
                    id: i,
                    fuelSupplyServiceName: props.value[i].fuelSupplyServiceName,
                    fuelSupplyServiceCost: currencyDecoder(props.value[i].fuelSupplyServiceCost, 2),
                  });
                }
      
                setCurrentListOtherCost({
                  columns: columnsDef_otherCost,
                  rows: rowsDef_otherCost,
                });
                SummonModal("list-other-cost-modal");
              }}
            >
              <VisibilityIcon />
            </Button>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  useEffect(() => {
    loadFuelSupplyType();
    loadFuelSupplyList();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "vehicleLicencePlate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "fuelCardNumber",
      headerName: i18next.t("form:numberFuelCard"),
    },
    {
      field: "fuelSupplyDTView",
      headerName: i18next.t("form:refillDate"),
    },
    {
      field: "fuelSupplyPlace",
      headerName: i18next.t("form:pointOfSale"),
    },
    {
      field: "fuelSupplyKm",
      headerName: i18next.t("form:km"),
      type: "number"
    },
    {
      field: "fuelTypeIDView",
      headerName: i18next.t("form:product"),
    },
    {
      field: "fuelSupplyAmount",
      headerName: i18next.t("form:fuelSupplyAmount"),
      renderCell: renderAmount,
      type: "number"
    },
    {
      field: "fuelSupplyCost",
      headerName: i18next.t("form:fuelSupplyCost"),
      type: "number"
    },
    {
      field: "fuelSupplyServed",
      headerName: i18next.t("form:served"),
      renderCell: renderServed,
    },
    {
      field: "otherCost",
      headerName: i18next.t("navigation:_other_costs"),
      renderCell: otherCost,
      type: "number"
    },
    {
      field: "fuelSupplyIVA",
      headerName: i18next.t("form:vat"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < fuelSupplyList.length; i++) {
    rowsDef.push({
      edit: "",
      fuelSupplyID: fuelSupplyList[i].info.fuelSupplyID,
      vehicleID: fuelSupplyList[i].info.vehicleID,
      vehicleLicencePlate: fuelSupplyList[i].info.vehicleLicensePlate,
      fuelCardID: fuelSupplyList[i].info.fuelCardID,
      fuelCardNumber: fuelSupplyList[i].info.fuelCardNumber,
      fuelSupplyDT: fuelSupplyList[i].info.fuelSupplyDT,
      fuelSupplyDTView: displayUTC0_ISODate(fuelSupplyList[i].info.fuelSupplyDT),
      fuelSupplyPlace: fuelSupplyList[i].info.fuelSupplyPlace,
      fuelSupplyKm: fuelSupplyList[i].info.fuelSupplyKm,
      fuelTypeIDView: fuelSupplyTypes.find(
        (x: IFuelSupplyType) => x.fuelSupplyTypeID === fuelSupplyList[i].info.fuelSupplyTypeID
      )?.fuelSupplyTypeName,
      fuelSupplyTypeID: fuelSupplyList[i].info.fuelSupplyTypeID,
      fuelSupplyAmount: fuelSupplyList[i].info.fuelSupplyAmount,
      fuelSupplyServed: fuelSupplyList[i].info.fuelSupplyServed,
      fuelSupplyCost: currencyDecoder(fuelSupplyList[i].info.fuelSupplyCost, 2),
      fuelSupplyIVA: fuelSupplyList[i].info.fuelSupplyIVA,
      otherCost: fuelSupplyList[i].details
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_forniture_page_title")}
        arrowBack
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___SERVICES_ROUTE__FORNITURE
          )
        }
      />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentFuelSupply(null);
                SummonModal("fuelSupplyID_Modal");
              }}
            >
              {i18next.t("navigation:_forniture_page_insert_single_supply")}
            </Button>

            <Button
              onClick={() => {
                navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.LOAD_FILE_SUPPLIES);
              }}
            >
              {i18next.t("navigation:_forniture_page_insert_supplies")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          modalUniqueId={"fuelSupplyID_Modal"}
          title={
            currentFuelSupply
              ? i18next.t("navigation:_forniture_page_edit_supply")
              : i18next.t("navigation:_forniture_page_add_new_supply")
          }
          modalInnerComponent={
            <FormInsertSupply
              data={currentFuelSupply ?? []}
              idModal="fuelSupplyID_Modal"
              doRefreshForm={() => {
                loadFuelSupplyList();
              }}
            />
          }
        />

        <SmartModal
          hideButtons={true}
          modalUniqueId="list-other-cost-modal"
          title={i18next.t("generic:titleOtherCost")}
          modalInnerComponent={
            <div className="modal-width-large">
              <DataGridWrap
                headers={currentListOtherCost && currentListOtherCost.columns}
                rows={currentListOtherCost && currentListOtherCost.rows}
              />
            </div>
          }
        />
        <LoaderBackdrop
          loading={loadingFuelSupplyTypes || loadingFuelSupplyList}
        />
      </div>
    </div>
  );
};

export default ViewSuppliesPage;
