import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

import {
  ISupplierInsertData,
  ISupplierInsertGeneric,
  ISupplierUpdateData,
  ISupplierUpdateGeneric,
} from "../../Models/ISupplier";

const SupplierController = {
  /* GET */
  // Get supplier type for the select type
  SupplierGetType: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = `${ApiEndPoints.SUPPLIER_GET_TYPE}`;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get the info based on the supplier type
  SupplierGetInfo: async (
    supplierTypeID: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = `${ApiEndPoints.SUPPLIER_GET_INFO}`;
    if (supplierTypeID) {
      request.url += `?supplierTypeID=${supplierTypeID}`;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get supplier generic data
  SupplierGetGeneric: async (
    GenericSupplierID: number,
    SupplierTypeCode: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_GET_GENERIC;
    let parameters: string[] = [];
    if (GenericSupplierID) {
      parameters.push(`GenericSupplierID=${GenericSupplierID}`);
    }
    if (SupplierTypeCode) {
      parameters.push(`SupplierTypeCode=${SupplierTypeCode}`);
    }
    if(parameters.length > 0) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get the suppliers side list
  SupplierGetSideList: async (
    supplierTypeID?: number,
    supplierID?: number,
    supplierTypeCode?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_GET_DATA;
    let parameters: string[] = [];
    if (supplierTypeID) {
      parameters.push(`supplierTypeID=${supplierTypeID}`);
    }
    if (supplierID) {
      parameters.push(`supplierID=${supplierID}`);
    }
    if (supplierTypeCode) {
      parameters.push(`supplierTypeCode=${supplierTypeCode}`);
    }
    if(parameters.length > 0 ){
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* INSERT */
  // Insert a new supplier
  SupplierInsertData: async (
    body: ISupplierInsertData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_POST_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert a new generic supplier
  SupplierInsertGeneric: async (
    body: ISupplierInsertGeneric,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_INSERT_GENERIC;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* UPDATE */
  // Update the selected supplier
  SupplierUpdateData: async (
    body: ISupplierUpdateData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update the selected generic supplier
  SupplierUpdateGeneric: async (
    body: ISupplierUpdateGeneric,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_UPDATE_GENERIC;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* DELETE */
  // Delete the selected supplier
  SupplierDeleteData: async (
    supplierID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_DELETE_DATA;
    request.method = "DELETE";
    request.payload = {
      supplierID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  SupplierDeleteGeneric: async (
    genericSupplierID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7239";
    request.url = ApiEndPoints.SUPPLIER_DELETE_GENERIC;
    request.method = "DELETE";
    request.payload = {
      genericSupplierID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default SupplierController;
