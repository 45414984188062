import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "./SearchPage.scss";

import i18next from "i18next";

import MultiForm, {
  IMultiFormField,
} from "../../Components/MultiForm/MultiForm";
import SearchResultBox from "../../Components/SearchResultBox/SearchResultBox";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/Toastify";

import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";

import HeaderPageDashboard from "../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";

import {
  IVehicleDataBrands,
  IVehicleDataModels,
  IVehicleDataFitting,
  IVehicleGeneralInfo,
  IVehicleSearch,
} from "../../Models/IVehicles";
import { IDriverGet } from "../../Models/IDriver";
import { IContractGetType } from "../../Models/IContract";
import AppRoutes from "../../Costants/AppRoutes";
import { dateIsPast } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { validatePlate } from "../DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { JSONPrint } from "../../Utils/Decoder";

/* COMPONENT */
const SearchPage = () => {
  const navigate = useNavigate();

  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const [searchParam, setSearchParam] = useState<string | null>(null);
  const [searchParamIsPotentialPlate, setSearchParamIsPotentialPlate] =
    useState<boolean>(false);

  /* SEARCH PARAMS */
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText") as string;

  const potentialPlate = (potPlate: string | undefined) => {
    if (potPlate) {
      let potPlate_: string = potPlate.replaceAll(" ", "");
      let potPlate_type_1: string =
        potPlate_ + "AA000AA".substring(potPlate_.length);
      let potPlate_type_2: string =
        potPlate_ + "00AAA00".substring(potPlate_.length);

      return (
        validatePlate(potPlate_type_1) === undefined ||
        validatePlate(potPlate_type_2) === undefined
      );
    }
    return false;
  };

  useEffect(() => {
    if (searchText !== searchParam) {
      setSearchParam(searchText);
      setSearchParamIsPotentialPlate(potentialPlate(searchText));
    }
  }, [searchText]);

  /* STATES */
  // State for data container
  const [state, setState] = useState<any>({});

  // State for diver list
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);

  // State for order contract type (proprietà, finanziamento, etc.)
  const [orderContractTypeList, setOrderContractTypeList] = useState<
    IContractGetType[]
  >([]);

  // States for veichles informations
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);
  const [chassisList, setChassisList] = useState<IVehicleGeneralInfo[]>([]);

  // States for search list
  const [searchList, setSearchList] = useState<IVehicleSearch[]>([]);

  // State for spinning and loading the process
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] =
    useState<boolean>(false);
  const [loadingChassisList, setLoadingChassisList] = useState<boolean>(false);
  const [loadingSearchList, setLoadingSearchList] = useState<boolean>(false);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [processingApiContract, setProcessingApiContract] =
    useState<boolean>(false);

  /* API */
  /* GET */
  // API to load brands list
  const loadBrandsList = () => {
    setLoadingBrandsList(true);
    if (loggedUser) {
      ApiService.VehicleDataController.VehicleDataGetBrandsAccountID(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setBrandsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingBrandsList(false);
        }
      );
    }
  };

  // API to load model list
  const loadModelsList = () => {
    if (loggedUser) {
      setLoadingModelsList(true);
      ApiService.VehicleDataController.VehicleDataGetModelsAccountID(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setModelsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingModelsList(false);
        }
      );
    }
  };

  // API to load fitting list
  const loadFittingList = () => {
    if (loggedUser) {
      setLoadingFittingsList(true);
      ApiService.VehicleDataController.VehicleDataGetFittingsAccountID(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFittingsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFittingsList(false);
        }
      );
    }
  };

  // Get the vehicle chassis
  const loadChassisList = () => {
    if (loggedUser) {
      setLoadingChassisList(true);
      ApiService.VehicleDataController.VehicleDataGeneralInfo(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setChassisList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingChassisList(false);
        }
      );
    }
  };

  // Get the vehicle chassis
  const loadSearchList = () => {
    if (searchParam) {
      setLoadingSearchList(true);
      ApiService.VehicleServiceController.VehicleGetSearch(
        searchParam,
        (response: IAPIResponse) => {
          if (response.error === null) {
            // if 1 result, go directly to vehicle page
            if (response.payload.length === 1) {
              let result: IVehicleSearch = response.payload[0];

              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                  "?vehicleID=" +
                  result.vehicleSearch.vehicleID.toString() +
                  "&assignmentID=" +
                  result.vehicleSearch.assignmentID.toString()
              );
            } else {
              setSearchList(response.payload);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSearchList(false);
        }
      );
    }
  };

  // Api to load drivers list
  const loadDriverList = () => {
    if (loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDriverList(false);
        }
      );
    }
  };

  // API to load the contract type (proprietà, finanziamento, etc.)
  const loadContractType = () => {
    setProcessingApiContract(true);
    ApiService.ContractController.ContractGetContractType(
      true,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setOrderContractTypeList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessingApiContract(false);
      }
    );
  };

  /* USE EFFECT */
  // Load the vehicle info
  useEffect(() => {
    loadBrandsList();
    loadModelsList();
    loadFittingList();
    loadDriverList();
    loadChassisList();
    loadContractType();
  }, []);

  useEffect(() => {
    loadSearchList();
  }, [searchParam]);

  // Multiform function
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      // User type
      {
        width: 100,
        type: "select",
        size: "small",
        name: "searchUserType",
        label: i18next.t("form:searchPageComapany_Natural").toString(),
        options: [
          {
            key: "1",
            text: i18next.t("form:userTypeNatural"),
          },
          {
            key: "2",
            text: i18next.t("navigation:navCompany"),
          },
        ],
      },
      // Brand
      {
        width: 100,
        type: "select",
        size: "small",
        name: "brandCode",
        defaultValue: state?.brandCode,
        required: false,
        label: `${i18next.t("form:orderBrand")}`,
        options: brandsList.map((item: IVehicleDataBrands) => {
          return {
            key: item.brandDescription,
            text: item.brandDescription,
          };
        }),
      },
      // Modal
      {
        width: 100,
        type: "select",
        size: "small",
        name: "modelCode",
        disabled: state?.brandCode ? false : true,
        defaultValue: state?.modelCode,
        required: false,
        label: `${i18next.t("form:orderModel")}`,
        options: modelList.map((item: IVehicleDataModels) => {
          return {
            key: item.modelDescription,
            text: item.modelDescription,
          };
        }),
      },
      // Fitting
      {
        width: 100,
        type: "select",
        size: "small",
        name: "fittingCode",
        disabled: state?.modelCode ? false : true,
        defaultValue: state?.fittingCode,
        required: false,
        label: `${i18next.t("form:orderEquipment")}`,
        options: fittingList.map((item: IVehicleDataFitting) => {
          return {
            key: item.fittingDescription,
            text: item.fittingDescription,
          };
        }),
      },
      // Chassis
      {
        width: 100,
        type: "autocomplete",
        size: "small",
        name: "chassisCode",
        id: "combo-box-chassisCode",
        label: i18next.t("form:numberChassis").toString(),
        options: chassisList.map((item: IVehicleGeneralInfo) => {
          return {
            key: item.vehicleChassis,
            text: item.vehicleChassis,
          };
        }),
      },
      // Drivers
      {
        width: 100,
        type: "select",
        size: "small",
        name: "searchDriver",
        label: i18next.t("form:searchActualAssignDriver").toString(),
        options: driverList.map((item: IDriverGet) => {
          let titleDriver = item.driverInfo.driverName;
          if (item.driverInfo?.driverSurname) {
            titleDriver += " " + item.driverInfo.driverSurname;
          }
          return {
            key: item.driverInfo.driverID.toString(),
            text: titleDriver.toString(),
          };
        }),
      },
      // Contracts
      {
        width: 100,
        type: "select",
        size: "small",
        name: "searchContract",
        label: i18next.t("form:searchContract").toString(),
        options: orderContractTypeList.map((contractType: IContractGetType) => {
          return {
            key: contractType.contractTypeID.toString(),
            text: contractType.contractTypeName.toString(),
          };
        }),
      },
      // Fines
      {
        width: 100,
        type: "select",
        size: "small",
        name: "searchFine",
        label: i18next.t("navigation:_vheicles_tab_nav_tickets").toString(),
        options: [{ key: "1", text: String(i18next.t("form:searchFine")) }],
      }
    );

    // Return
    return multiformInputs;
  };

  // Search result by URL
  let searchResult: any[] = [...searchList];

  // Advanced Search - Account type
  searchResult = searchResult.filter((el: any) => {
    return (
      (el.companySearch &&
        el.companySearch.accountTypeID == state.searchUserType) ||
      !state.searchUserType
    );
  });

  // Advanced Search - Brand
  searchResult = searchResult.filter((el: any) => {
    return (
      el.vehicleSearch.brandDescription == state.brandCode || !state.brandCode
    );
  });

  // Advanced Search - Model
  searchResult = searchResult.filter((el: any) => {
    return (
      el.vehicleSearch.modelDescription == state.modelCode || !state.modelCode
    );
  });

  // Advanced Search - Fitting
  searchResult = searchResult.filter((el: any) => {
    return (
      el.vehicleSearch.fittingDescription == state.fittingCode ||
      !state.fittingCode
    );
  });

  // Advanced Search - Chassis
  searchResult = searchResult.filter((el: any) => {
    return (
      el.vehicleSearch.vehicleChassis == state.chassisCode || !state.chassisCode
    );
  });

  // Advanced Search - Driver
  searchResult = searchResult.filter((el: any) => {
    return (
      el.driverSearch.driverID == state.searchDriver || !state.searchDriver
    );
  });

  // Advanced Search - Contract
  searchResult = searchResult.filter((el: any) => {
    return (
      el.vehicleSearch.contractTypeID == state.searchContract ||
      !state.searchContract
    );
  });

  

  /* RETURN */
  return (
    <div>
      <div className="search-wrapper">
        <HeaderPageDashboard
          title={i18next.t("navigation:_search_page_title")}
        />
        {!loadingSearchList && (
          <div className="content-search-wrapper">
            <div className="search-result-wrapper">
              {searchResult.length > 0 ? (
                searchResult.map((el: any) => {
                  return (
                    <div className="search-result">
                      <SearchResultBox data={el} />
                    </div>
                  );
                })
              ) : (
                <div className="search-result">{i18next.t("form:noData")}</div>
              )}
            </div>

            <div className="advanced-search advanced-search-sticky">
              <h4 className="advanced-seach-title">
                {i18next.t("navigation:_search_page_advanced_search")}
              </h4>

              <div>
                <MultiForm
                  suppressLayout
                  formId={"advancedSearch"}
                  formTitle={"advancedSearch"}
                  classNameForm="multi-form-flex"
                  inputs={multiformInputs()}
                  suppressSubmit={true}
                  onChange={async (data: any) => {
                    setState({ ...state, ...data });
                  }}
                  onSubmit={async () => {}}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <LoaderBackdrop
        loading={
          loadingBrandsList ||
          loadingModelsList ||
          loadingFittingsList ||
          loadingChassisList ||
          processingApiContract ||
          loadingSearchList ||
          loadingDriverList
        }
      />
    </div>
  );
};

export default SearchPage;
