import { Button, ToggleButton, Tooltip } from "@mui/material";
import i18next from "i18next";
import DoneIcon from "@mui/icons-material/Done";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import "./PricingSubscriptions.scss";
import "./PricingSubscriptionsPublic.scss";
import { JSONPrint, currencyDecoder } from "../../Utils/Decoder";
import WarningIcon from "@mui/icons-material/Warning";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Navigate, useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { IUser } from "../../Models/IUser";
import { IFleetPlan } from "../../Models/IFleetPlan";
import RedeemIcon from "@mui/icons-material/Redeem";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { HTMLAttributes, useEffect, useState } from "react";

export interface IPricingSubscriptions {
  subscription: IFleetPlan;
  pricingType: string;
  selected?: boolean;
  selectedTrial?: boolean;
  keySubscription: number;
  onClickFullMode?: (data: IFleetPlan) => void;
  onClickTrialMode?: (data: IFleetPlan) => void;
  isStored?: boolean;
  isActive?: boolean;
  isRegistration?: boolean;
  onlyView?: boolean;
  isNotRegistered?: boolean;
  isReduceMode?: boolean;
  currentSelectedPlan?: IFleetPlan | undefined;
  isPublic?: boolean;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
}

const PricingSubscriptions = (props: IPricingSubscriptions) => {
  const navigate = useNavigate();
  const [extra, setExtra] = useState<number>(0);
  const [extraInitial, setExtraInitial] = useState<number>(0);

  const loggedUser: IUser | undefined = useSelector((state: GlobalState) => state.user.currentUser);
  const { className: wrapperClassname, ...wrapperExtraProps } = props.wrapperProps || {};
  const renderIcons: JSX.Element[] = [];
  for (let i = 0; i <= props.keySubscription; i++) {
    if (
      props.subscription.fleetPlanYearlyCost > 0 &&
      props.subscription.fleetPlanMonthlyCost > 0
    ) {
      renderIcons.push(
        <DirectionsCarIcon sx={{ fontSize: "1.2em" }} key={i} />
      );
    }
  }

  const getUserSubscription: ISubscriptions[] | undefined = useSelector(
    (state: GlobalState) => state.registration.userSubscriptions
  );
  let customClassName: string = "subscription-single";
  if (props.selected) {
    customClassName += " selectedBox";
  }
  if (props.selectedTrial) {
    customClassName += " selectedBoxTrialMode";
  }
  if (props.isStored) {
    customClassName += " storedBox";
  }

  var lockSelection = false;
  if (props.currentSelectedPlan && props.isReduceMode) {
    lockSelection =
      props.currentSelectedPlan?.fleetPlanMonthlyCost + 1 <=
      props.subscription.fleetPlanMonthlyCost;
  }
  if (props.currentSelectedPlan && !props.isReduceMode) {
    lockSelection =
      props.currentSelectedPlan?.fleetPlanMonthlyCost >
      props.subscription.fleetPlanMonthlyCost;
  }

  if (!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) || window.location.pathname.includes(AppRoutes.DASHBOARD___ADMIN_ROUTE__CLIENTS)) {
    lockSelection = false;
  }

  useEffect(() => {
    if (props.onClickFullMode) {
      props.onClickFullMode({
        ...props.subscription,
        extraVehicles: extra,
      });
    }
  }, [extra]);

  useEffect(() => {
    let sub: ISubscriptions | undefined =
      getUserSubscription.length > 0 ? getUserSubscription[0] : undefined;
    if (sub && sub.fleetPlanID === props.subscription.fleetPlanID && props.currentSelectedPlan) {
      let vehicleCustomCurrentPick = sub.fleetPlanCustomMaxVehicle;
      if(vehicleCustomCurrentPick && vehicleCustomCurrentPick > props.currentSelectedPlan.fleetPlanMaxVehicle){
        vehicleCustomCurrentPick = vehicleCustomCurrentPick - props.currentSelectedPlan.fleetPlanMaxVehicle;
      }else{
        vehicleCustomCurrentPick = 0;
      }
      setExtra(vehicleCustomCurrentPick);
      setExtraInitial(vehicleCustomCurrentPick);
    }
  }, []);

  let disableReduceCustomV = false;
  let disableAugmentCustomV = false;

  if (props.isReduceMode) {
    disableReduceCustomV = extra === 0;
    disableAugmentCustomV = extra >= extraInitial;
  }

  if (!props.isReduceMode) {
    disableReduceCustomV = extra <= extraInitial;
    disableAugmentCustomV = false;
  }

  return (
    <div
      className={`${customClassName} ${props.isPublic ? "publicView" : "privateView"} ${wrapperClassname || ""}`}
      style={lockSelection ? { opacity: "0.5" } : {}}
      {...wrapperExtraProps}
    >
      <div className="subscription-header">
        <div>{props.subscription.fleetPlanName}</div>
        <div>{renderIcons}</div>
      </div>
      <div className="subscription-header-2">
        {props.isStored ? (
          <Tooltip
            title={i18next
              .t("message:fleetPlanStoredNotAvailableForPurchase")
              .replace(
                "EXPIRY_DATE",
                getUserSubscription &&
                  displayUTC0_ISODate(getUserSubscription[0].validTo, true)
              )}
          >
            <WarningIcon color="error" />
          </Tooltip>
        ) : props.isActive ? (
          <Tooltip
            title={i18next
              .t("message:fleetPlanAlreadyPurchase")
              .replace(
                "EXPIRY_DATE",
                getUserSubscription &&
                  displayUTC0_ISODate(getUserSubscription[0].validTo, true)
              )}
          >
            <CheckCircleIcon color="success" />
          </Tooltip>
        ) : (
          <></>
        )}
        <b>
          {props.pricingType === "1"
            ? currencyDecoder(props.subscription.fleetPlanMonthlyCost, 2)
            : currencyDecoder(props.subscription.fleetPlanYearlyCost, 2)}
        </b>

        {props.isPublic ?
            <sup>
              {i18next.t(
                  "navigation:priceCardsDuration_" + props.pricingType + "_cost"
              )}
            </sup>
            :
            <sup>
              &nbsp;/&nbsp;
              {i18next.t(
                  "navigation:subscriptionDuration_" + props.pricingType + "_cost"
              )}
            </sup>
        }
      </div>
      <small style={{ textAlign: "center", paddingBottom: "0.5em" }}>{i18next.t("generic:vat_excluded")}</small>
      <div className="subscription-body">
        <h3
          dangerouslySetInnerHTML={{
            __html: i18next
              .t("generic:fleetPlanVehicleRange")
              .replace(
                "VEHICLE_FROM",
                props.subscription.fleetPlanMinVehicle.toString()
              )
              .replace(
                "VEHICLE_TO",
                props.subscription.fleetPlanMaxVehicle.toString()
              ),
          }}
        />
        <p>{props.subscription.fleetPlanDescription}</p>
        {props.subscription.isTrialFree && (
          <small>
            {i18next.t("navigation:trialModeFree").replace("DAYS", "15")}
          </small>
        )}
      </div>
      {(window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) ||
        window.location.pathname.includes(AppRoutes.STORE_SUBSCRIPTION_PAGE) ||
        window.location.pathname.includes(
          AppRoutes.CONTINUE_REGISTRATION_ROUTE
        )) && (
        <div className="extra-veicle-zone">
          <div>
            {i18next.t("navigation:extra_vehicles_cost_per_unit")}{" "}
            {props.subscription.fleetPlanAdditionalCarCost + " €"}
          </div>

          <br />
          <strong>{i18next.t("navigation:extra_vehicles")}</strong>
          <div className="extra-vehicle-step">
            <Button
              style={{
                opacity: disableReduceCustomV || props.isStored ? "0.5" : "1",
              }}
              variant="contained"
              disabled={disableReduceCustomV || props.isStored}
              onClick={() => {
                setExtra(extra - 1);
              }}
            >
              -
            </Button>
            {extra}
            <Button
              variant="contained"
              style={{
                opacity: disableAugmentCustomV || props.isStored ? "0.5" : "1",
              }}
              disabled={disableAugmentCustomV || props.isStored}
              onClick={() => {
                setExtra(extra + 1);
              }}
            >
              +
            </Button>
          </div>
        </div>
      )}

      {props.isNotRegistered && !loggedUser ? (
        <ToggleButton
          fullWidth
          value={props.subscription.fleetPlanID}
          selected={props.selected}
          onClick={() => {
            navigate(AppRoutes.REGISTRATION_ROUTE);
          }}
        >
          {props.selected && <DoneIcon />}
          {props.selected
            ? i18next.t("navigation:selected")
            : i18next.t("navigation:goToRegistration")}
        </ToggleButton>
      ) : !window.location.pathname.includes(AppRoutes.RATES_ROUTE) ? (
        props.subscription.isTrialFree && !props.isReduceMode && !props.isStored ? (
          <div>
            <ToggleButton
              fullWidth
              className="trialButton"
              disabled={getUserSubscription.length > 0 && (getUserSubscription[0].isTrial || getUserSubscription[0].enabled)}
              value={props.subscription.fleetPlanID}
              selected={props.selected}
              onClick={() => {
                if (props.onClickTrialMode) {
                  props.onClickTrialMode({
                    ...props.subscription,
                    extraVehicles: extra,
                  });
                }
              }}
            >
              {props.selectedTrial && <DoneIcon />}
              {props.selectedTrial
                ? i18next.t("navigation:trialMode")
                : i18next.t("navigation:trialMode")}
              &nbsp;
              <RedeemIcon />
            </ToggleButton>
            <ToggleButton
              fullWidth
              value={props.subscription.fleetPlanID}
              selected={props.selected}
              disabled={lockSelection || props.isStored}
              onClick={() => {
                if (props.onClickFullMode) {
                  props.onClickFullMode({
                    ...props.subscription,
                    extraVehicles: extra,
                  });
                }
              }}
            >
              {props.selected && <DoneIcon />}
              {props.selected
                ? i18next.t("navigation:selected")
                : i18next.t("navigation:select")}
              &nbsp;
              <AddShoppingCartIcon />
            </ToggleButton>
          </div>
        ) : (
          <ToggleButton
            fullWidth
            value={props.subscription.fleetPlanID}
            selected={props.selected}
            disabled={lockSelection || props.isStored}
            onClick={() => {
              if (props.onClickFullMode) {
                props.onClickFullMode({
                  ...props.subscription,
                  extraVehicles: extra,
                });
              }
            }}
          >
            {props.selected && <DoneIcon />}
            {props.selected
              ? i18next.t("navigation:selected")
              : i18next.t("navigation:select")}
            &nbsp;
            <AddShoppingCartIcon />
          </ToggleButton>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default PricingSubscriptions;
