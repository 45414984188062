import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import "./DragAndDropper.scss";
import { Icon, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
export interface IDragAndDropper_DragItemProps {
  value: string;
  text: string;
}
export interface IDragAndDropper_ReceiverProps {
  onDropped: (oldValue: string | undefined, value: string) => void;
  defaultValue?: string;
}

const baseEventName: string = "drag-n-drop-event-";
var lastKnownMouseX: number = -1;
var lastKnownMouseY: number = -1;
var activeDragFlag: boolean = false;
var activeDragValue: string = "";

export const DragAndDropper_DragItem = (
  props: IDragAndDropper_DragItemProps
) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [delta, setDelta] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const startDragging = () => {
    if (!activeDragFlag) {
      activeDragValue = props.value;
      setDragActive(true);
      window.document.dispatchEvent(new Event(baseEventName + "drag-start"));
    }
  };
  const stopDragging = () => {
    if (dragActive) {
      setDelta({ x: 0, y: 0 });
      setDragActive(false);
      window.document.dispatchEvent(new Event(baseEventName + "drag-stop"));
      setTimeout(() => {
        lastKnownMouseX = -1;
        lastKnownMouseY = -1;
        activeDragValue = "";
      }, 100);
    }
  };

  return (
    <div className="drag-n-drop-dropper-main-wrap">
      <div
        title={props.text}
        className={
          "drag-n-drop-dropper-child-item " +
          (dragActive ? "drag-n-drop-dropper-child-item-active" : "")
        }
        style={{
          top: delta.y + "px",
          left: delta.x + "px",
          zIndex: dragActive ? "2" : "1",
        }}
        onMouseUp={stopDragging}
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseMove={(e) => {
          if (dragActive) {
            if (lastKnownMouseX !== -1 && lastKnownMouseY !== -1) {
              let deltaX = e.clientX - lastKnownMouseX;
              let deltaY = e.clientY - lastKnownMouseY;
              setDelta({ x: delta.x + deltaX, y: delta.y + deltaY });
            }
            lastKnownMouseX = e.clientX;
            lastKnownMouseY = e.clientY;

            window.document.dispatchEvent(
              new Event(baseEventName + "drag-move")
            );
          }
        }}
      >
        <div className="drag-n-drop-dropper-child-item-content">
          {props.text}
        </div>
      </div>
    </div>
  );
};

export const DragAndDropper_Receiver = (
  props: IDragAndDropper_ReceiverProps
) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [mouseOnMe, setMouseOnMe] = useState<boolean>(false);
  const [valueOld, setValueOld] = useState<string>("");
  const [value, setValue] = useState<string>(props.defaultValue ?? "");
  const ref = useRef<any>();
  const enableDragActive = () => {
    setDragActive(true);
  };
  const disableDragActive = () => {
    if (computeMouseOnMe()) {
      setValue(activeDragValue);
    }
    setDragActive(false);
  };

  const computeMouseOnMe = () => {
    if (ref.current) {
      let rect = ref.current.getBoundingClientRect();
      let top = rect.top;
      let left = rect.left;
      let isOnMe: boolean = false;
      if (lastKnownMouseX > left && lastKnownMouseX < left + rect.width) {
        if (lastKnownMouseY > top && lastKnownMouseY < top + rect.height) {
          isOnMe = true;
        }
      }

      return isOnMe;
    }
    return false;
  };

  const updateMouseDragPosition = () => {
    setMouseOnMe(computeMouseOnMe());
  };

  useEffect(() => {
    if (value) {
      props.onDropped(valueOld, value);
    }
    setValueOld(value);
  }, [value]);

  useEffect(() => {
    window.document.addEventListener(
      baseEventName + "drag-start",
      enableDragActive
    );
    window.document.addEventListener(
      baseEventName + "drag-stop",
      disableDragActive
    );
    window.document.addEventListener(
      baseEventName + "drag-move",
      updateMouseDragPosition
    );
    return () => {
      window.document.removeEventListener(
        baseEventName + "drag-start",
        enableDragActive
      );
      window.document.removeEventListener(
        baseEventName + "drag-stop",
        disableDragActive
      );
      window.document.removeEventListener(
        baseEventName + "drag-move",
        updateMouseDragPosition
      );
    };
  }, []);

  return (
    <div
      ref={ref}
      className={
        "drag-n-drop-receiver-main-wrap " +
        (dragActive ? "drag-n-drop-receiver-main-wrap-drag-on" : "") +
        " " +
        (dragActive && mouseOnMe
          ? "drag-n-drop-receiver-main-wrap-drag-on-me"
          : "")
      }
      onMouseEnter={() => {
        setMouseOnMe(true);
      }}
      onMouseLeave={() => {
        setMouseOnMe(false);
      }}
    >
      {value === "" && dragActive && (
        <div>{i18next.t("message:_ready_for_drop")}</div>
      )}

      {value !== "" && (
        <div className="selected-voice-drag-drop">
          {value}
          {
            <IconButton
              onClick={() => {
                setValue("");
                props.onDropped(valueOld, "");
                setValueOld("");
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        </div>
      )}
    </div>
  );
};
