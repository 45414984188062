export const CompressImageFile = async (
  file: File,
  height = 50,
  width = 180
) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (ctx) {
        canvas.height = height;
        canvas.width = width;

        // get the scale
        var scale = Math.min(
          canvas.width / image.width,
          canvas.height / image.height
        );
        // get the top left position of the image
        var x = canvas.width / 2 - (image.width / 2) * scale;
        var y = canvas.height / 2 - (image.height / 2) * scale;
        ctx.drawImage(image, x, y, image.width * scale, image.height * scale);

        const dataUrl: string = canvas.toDataURL();
        resolve(
          JSON.stringify({
            name: file.name,
            url: dataUrl,
          })
        );
      }
    };

    image.src = URL.createObjectURL(file);
  });
};

export const downloadWithName = (path: string, fileName: string) => {
  window.URL = window.URL || window.webkitURL;

  var xhr = new XMLHttpRequest(),
    a = document.createElement("a"),
    file;

  xhr.open("GET", path, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    file = new Blob([xhr.response], { type: "application/octet-stream" });
    a.href = window.URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };
  xhr.send();
};

export const getBlobContainer = (blobRaw: string) => {
  if (blobRaw) {
    let spl = blobRaw.split("____");
    return spl[0];
  }
  return "";
};

export const getBlobDesc = (blobRaw: string) => {
  if (blobRaw) {
    let spl = blobRaw.split("____");
    if (spl.length === 4) {
      return spl[3];
    } else {
      return spl[2];
    }
  }
  return "";
};

export const getBlobDate = (blobRaw: string) => {
  if (blobRaw) {
    let spl = blobRaw.split("____");
    if (spl.length === 4) {
      return spl[2];
    }
  }
  return "";
};

export const getCompressedFileName = (fileRaw: string | null) => {
  if (fileRaw) {
    try {
      let j = JSON.parse(fileRaw);
      return j.name;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const getCompressedBase64 = async (
  file: File | null,
  onReady: (response: string | null) => void,
  options = { height: 50, width: 180 }
) => {
  if (file) {
    let compressed: any = await CompressImageFile(
      file,
      options.height,
      options.width
    );
    return onReady(compressed);
  } else {
    onReady(null);
  }
};

export const base64ToFileBlob = (base64: string, fileName: string, mimeType: string): File => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const fileBlob = new Blob(byteArrays, { type: mimeType });
  return new File([fileBlob], fileName, { type: mimeType });
};


export const getMimeTypeByBase64 = (base64: string) => {
  const signatures: { [key: string]: string } = {
    JVBERi0: '.pdf',
    R0lGODdh: '.gif',
    R0lGODlh: '.gif',
    iVBORw0KGgo: '.png',
    '/9j/': '.jpg',
  };
  let base64Remove: number = base64.indexOf(',');
  base64 = base64.slice(base64Remove + 1, base64.length);
  // base64 = base64.split(',');
  for(const sign in signatures){
    if(base64.startsWith(sign)){
      return signatures[sign];
    }
  };
};