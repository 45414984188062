/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./VehicleConfigurationPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import { IGET_CarConfigurator_Vehicle } from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import { renderBrandImageGrid } from "../../VehiclesPage/Status__VehiclesPage/Status__VehiclesPage_Data";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import VehicleConfigurationPageCompare from "./VehicleConfigurationPageCompare";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Box, Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import BeenhereIcon from "@mui/icons-material/Beenhere";

/* COMPONENT */
const VehicleConfigurationPage = () => {
  /* STATES */
  // Datagrid
  const [list, setList] = useState<IGET_CarConfigurator_Vehicle[]>([]);
  // Driver config
  const [driverConfig, setDriverConfig] = useState<any>(false);
  // Current selection
  const [currentData, setCurrentData] = useState<any[]>([]);
  // Current delete
  const [currentDelete, setCurrentDelete] = useState<any>({});
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingGetDriver, setLoadingGetDriver] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get vehicles
  const GET__CARCONFIGURATOR_VEHICLES = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET__CARCONFIGURATOR_VEHICLES(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Get driver config
  const GET_CarConfigurator_Driver_Config = () => {
    setLoadingGetDriver(true);

    ApiService.CarConfiguratorController.GET_CarConfigurator_Driver_Config(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverConfig(response.payload.isEnabled);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGetDriver(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    GET__CARCONFIGURATOR_VEHICLES();
    GET_CarConfigurator_Driver_Config();
  }, []);

  /* FUNCTIONS */
  // Row selection
  const renderSelect = (props: GridRenderCellParams) => {
    return (
      <Checkbox
        checked={props.row.selected}
        onChange={(_, checked) => {
          // Controlled checkbox
          list[props.row.id].selected = checked;

          // Save selected item
          let tmp = [...currentData];

          if (checked) {
            tmp.push(props.row.list);
          } else {
            tmp = tmp.filter(
              (x: any) =>
                x.infocarCode !== props.row.infocarCode &&
                x.driverNumber !== props.row.driverNumber
            );
          }

          setCurrentData(tmp);
        }}
      />
    );
  };

  // Edit actions
  const renderActions = (props: GridRenderCellParams) => {
    return (
      <div>
        <Tooltip title={i18next.t("form:selectVehicle")}>
          <IconButton>
            <BeenhereIcon
              className="icon-table"
              onClick={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD +
                    AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE_COMPARE_SUMMARY +
                    "?vehicleID=" +
                    props.row.driverGroupVehiclesID
                );
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title={i18next.t("form:deleteVehicle")}>
          <IconButton>
            <DeleteIcon
              className="icon-table"
              onClick={() => {
                setCurrentDelete({ id: props.row.id, description: props.row.brandImage });

                SummonModal("compare-vehicles-modals-delete");
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "select",
      headerName: "",
      renderCell: renderSelect,
    },
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      type: 'custom'
    },
    {
      field: "brandImage",
      headerName: i18next.t("form:service_logo"),
      renderCell: renderBrandImageGrid,
    },
    {
      field: "details",
      headerName: i18next.t("form:vehicleDetails"),
    },
    {
      field: "infocarCode",
      headerName: i18next.t("form:infocarCode"),
    },
    {
      field: "powerSupply",
      headerName: i18next.t("form:powerSupply"),
    },
    {
      field: "color",
      headerName: i18next.t("form:color"),
    },
    {
      field: "driverNumber",
      headerName: i18next.t("form:driverNumber"),
    },
    {
      field: "groupDescription",
      headerName: i18next.t("form:vehicleGroup"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      list: list[i],
      selected: list[i].selected,

      driverGroupVehiclesID: list[i].driverGroupVehiclesID,
      brandImage: list[i].vehicleBrandName,
      details: list[i].vehicleBrandName,
      infocarCode: list[i].infocarCode,
      powerSupply: list[i].powerSupply || "-",
      color: list[i].color || "-",
      driverNumber: list[i].driverNumber,
      groupDescription: list[i].groupDescription,

      brandName: list[i].vehicleBrandName,
      modelName: list[i].vehicleModelName,
      fittingName: list[i].vehicleFittingName,
      antipollutionLegislation: list[i].vehicleAntipollutionLegislation,
      carBody: list[i].vehicleCarBody,
      doorsNumber: list[i].vehicleDoorsNumber,
      seatsNumber: list[i].vehicleSeatsNumber,
      vehicleLength: list[i].vehicleLength,
      vehicleWidth: list[i].vehicleWidth,
      vehicleHeight: list[i].vehicleHeight,
      tires: list[i].vehicleTires,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_configure_vechicles_configuration")}
      />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small gap-small">
          <Button
            disabled={
              list.length === 0 || !(currentData.length >= 1 && currentData.length <= 3)
            }
            variant="outlined"
            onClick={() => {
              SummonModal("compare-vehicles-modals");
            }}
          >
            {i18next.t("form:vehiclesCompare")}
          </Button>

          <Button
            disabled={driverConfig}
            variant="contained"
            onClick={() => {
              window.open("https://carconfigurator.gestioneflotta.it/", "_blank");
            }}
          >
            {i18next.t("form:addVehicleConfiguration")}
          </Button>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
      </div>

      <SmartModal
        modalUniqueId="compare-vehicles-modals"
        title={i18next.t("form:vehiclesCompare")}
        modalInnerComponent={<VehicleConfigurationPageCompare data={currentData || []} />}
      />

      <SmartModal
        modalUniqueId="compare-vehicles-modals-delete"
        title={i18next.t("generic:titleRecordModal")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace("CURRENT_ITEM_LIST", currentDelete.description),
            }}
          />
        }
        onAccept={() => {
          // console.log(currentDelete.id);
        }}
        onReject={() => {
          DismissModal("compare-vehicles-modals-delete");
        }}
      />

      <LoaderBackdrop loading={loadingGet || loadingGetDriver} />
    </div>
  );
};

export default VehicleConfigurationPage;
