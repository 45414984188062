/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./DetailsFileSupply.scss";

/* COMPONENTS */
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  ITemplateDateFormat,
  ITemplateTimeFormat,
} from "../../Models/ITemplate";
import { ISupplierGetSideList } from "../../Models/ISupplier";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { Button } from "@mui/material";
import { getCompressedBase64 } from "../../Utils/FileToBase";

/* INTERFACES */
export interface IDetailsFileSupplyProps {
  onChange: (data: any) => void;
  isCreating?: boolean;
  data?: any;
}

/* COMPONENT */
const DetailsFileSupply = (props: IDetailsFileSupplyProps) => {
  const [dateFormats, setDateFormats] = useState<ITemplateDateFormat[]>([]);
  const [timeFormats, setTimeFormats] = useState<ITemplateTimeFormat[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplierGetSideList[]>([]);

  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [loading3, setLoading3] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [state, setState] = useState<any>({});

  const getSuppliers = () => {
    setLoading3(true);
    ApiService.SupplierController.SupplierGetSideList(
      null,
      null,
      "FUEL_CARD",
      (response: IAPIResponse) => {
        if (response.error === null) {
          setSuppliers(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading3(false);
      }
    );
  };

  const getTimeFormats = () => {
    setLoading1(true);
    ApiService.TemplateController.TemplateGetTimeFormat(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let timeFormats_ = {timeFormatID: 0, timeFormat: i18next.t("form:emptyValue")};
          setTimeFormats([...response.payload, timeFormats_]);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading1(false);
      }
    );
  };

  const getDateFormats = () => {
    setLoading2(true);
    ApiService.TemplateController.TemplateGetDateFormat(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDateFormats(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading2(false);
      }
    );
  };

  useEffect(() => {
    getDateFormats();
    getTimeFormats();
    getSuppliers();
  }, []);

  const updateFile = (files: File[]) => {
    if (props.data) {
      getCompressedBase64(
        files.length > 0 ? files[0] : null,
        (result: string | null) => {
          let oldState = { ...props.data };
          oldState["templateImage"] = result;
          props.onChange(oldState);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    if (!props.isCreating) {
      multiformInputs.push({
        width: 33.33,
        type: "text",
        name: "templateName",
        label: i18next.t("navigation:_forniture_page_details_file_form0") ?? "",
        required: true,
      });
    }

    multiformInputs.push(
      {
        width: 33.33,
        type: "number",
        name: "readFromRow",
        label: i18next.t("navigation:_forniture_page_details_file_form1") ?? "",
        placeholder: i18next.t("navigation:example") + ": 15",
        required: true,
      },
      {
        width: 33.33,
        type: "select",
        name: "dateFormat",
        label: i18next.t("navigation:_forniture_page_details_file_form2") ?? "",
        placeholder: "dd/mm/yyyy",
        required: true,
        options: dateFormats.map((x: ITemplateDateFormat, i: number) => {
          return {
            key: x.dateFormatID,
            text: x.dateFormat,
          };
        }),
      },
      {
        width: 33.33,
        type: "select",
        name: "timeFormat",
        label:
          i18next.t("navigation:_forniture_page_details_file_form2_bis") ?? "",
        placeholder: "hh:mm:ss",
        options: timeFormats.map((x: ITemplateTimeFormat, i: number) => {
          return {
            key: x.timeFormatID,
            text: x.timeFormat,
          };
        }),
      }
    );

    if (!props.isCreating) {
      multiformInputs.push({
        width: 33.33,
        type: "select",
        name: "supplierID",
        label:
          i18next.t("navigation:_forniture_page_details_file_form2_bis_bis") ??
          "",
        placeholder: "",
        required: true,
        options: suppliers.map((x: ISupplierGetSideList, i: number) => {
          return {
            key: x.supplierInfo.supplierID,
            text: x.supplierInfo.supplierName,
          };
        }),
      });
    }

    multiformInputs.push({
      width: 33.33,
      type: "select",
      name: "separator",
      label: i18next.t("navigation:_forniture_page_details_file_form3") ?? "",
      placeholder: i18next.t("navigation:comma") + " (,)",
      required: true,
      options: [
        { key: ",", text: i18next.t("form:commaDescription")},
        { key: ".", text: i18next.t("form:dotDescription")},
        { key: "NONE", text: i18next.t("form:noneSeparator")},
      ],
    });

    if (!props.isCreating) {
      multiformInputs.push({
        width: 100,
        name: "templateImageRead",
        type: "custom",
        element: (
          <div>
            <div className="multi-form-input-upper-label">
              {i18next.t("navigation:_profile_avatar")} *
            </div>
            <Button
              onClick={() => {
                document.getElementById("avatar-input")?.click();
              }}
              // startIcon={<CameraAltIcon />}
            >
              {i18next.t("navigation:upload")}
            </Button>
            <input
              onChange={(e: any) => {
                let files = e.target.files;
                if (files.length > 0) {
                  updateFile(files);
                  let image = files[0];
                  // get image ref and preview
                  let url = URL.createObjectURL(image);
                  const img = new Image();
                  img.src = url;
                  img.onload = () => {
                    // init canvas
                    const canvas: any = document.createElement("canvas");
                    const W = 130;
                    const H = 130;
                    canvas.width = W;
                    canvas.height = H;

                    // init canvas context
                    const ctx = canvas.getContext("2d");
                    if (ctx) {
                      ctx.drawImage(img, 0, 0, W, H);
                      var pngUrl = canvas.toDataURL(); // PNG is the default
                      setImgUrl(pngUrl);
                    }
                  };
                }
              }}
              accept=".png, .jpg, .jpeg, .bmp"
              type="file"
              hidden
              required
              id="avatar-input"
            />
            {imgUrl && <img className="image-profile-preview" src={imgUrl} />}
          </div>
        ),
      });
    }
    // Return the multiform
    return multiformInputs;
  };

  useEffect(() => {
    let st: any = { ...state };
    st["templateImage"] = imgUrl;
    emit(st);
  }, [imgUrl]);

  const emit = (st: any) => {
    setState(st);
    delete st["templateImageRead"];
    props.onChange(st);
  };

  /* RETURN */
  return (
    <div className="details-file-supply-wrapper">
      <div className="details-file-supply-header">
        <div className="display-flex-center">
          <h3>{i18next.t("navigation:_forniture_page_details_file_title")}</h3>
        </div>

        <div className="display-flex-center">
          {i18next.t("navigation:_forniture_page_details_file_subtitle")}
        </div>
      </div>
      <LoaderBackdrop loading={loading1 || loading2 || loading3} />
      <MultiForm
        inputs={multiformInputs()}
        formTitle={""}
        suppressLayout={true}
        formId={"detailsFileSupply_form"}
        onChange={(data_: any) => {
          let data: any = { ...data_ };
          data["templateImage"] = imgUrl;

          // we pass text instead of ID
          let dFormatId: number | undefined = data["dateFormat"];
          let tFormatId: number | undefined = data["timeFormat"];
          let date: ITemplateDateFormat | undefined = dateFormats.find(
            (x: ITemplateDateFormat) => x.dateFormatID === dFormatId && x.dateFormatID !== 0
          );
          let time: ITemplateTimeFormat | undefined = timeFormats.find(
            (x: ITemplateTimeFormat) => x.timeFormatID === tFormatId && x.timeFormatID !== 0
          );
          data["dateFormat"] = date ? date.dateFormat : "";
          data["timeFormat"] = time ? time.timeFormat : "";

          emit(data);
        }}
      />
    </div>
  );
};

export default DetailsFileSupply;
