import { ICompanyGet } from "../../Models/ICompany";
import {
  SET_AVATAR,
  SET_BROKER_GRANTS,
  SET_COMPANY,
  SET_CURRENT_BROKER_COMPANY,
  SET_LOGIN_STATUS,
  SET_MENU_PAGES,
  SET_MENU_PAGES_EXPIRED,
  SET_USER,
  SET_USER_IMP,
  UserActionType,
} from "./UserAction";
import { UserState } from "./UserState";

const getMenuPages = () => {
  let u: any = localStorage.getItem("menuVoices");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getLoggedUser = () => {
  let u: any = localStorage.getItem("logged-user");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getLoggedUserImp = () => {
  let u: any = localStorage.getItem("logged-user-imp");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getCompanies = () => {
  let u: any = localStorage.getItem("logged-user-company");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getLoginStatus = () => {
  let u: any = localStorage.getItem("logged-user-login-status");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getMainCompany = () => {
  let cmpy = getCompanies();
  if (cmpy) {
    try {
      return cmpy.find((x: ICompanyGet) => x.companyParentID === null);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

const DEFAULT_STATE: UserState = {
  currentUser: getLoggedUser(),
  companies: getCompanies(),
  mainCompany: getMainCompany(),
  currentImpUser: getLoggedUserImp(),
  avatar: undefined,
  menuVoices: getMenuPages(),
  currentBrokerCompany: undefined,
  loginStatus: getLoginStatus(),
  menuPagesExpired: false,
  brokerGrants: [],
};

export const userReducer = (
  state: UserState = DEFAULT_STATE,
  action: UserActionType
): UserState => {
  switch (action.type) {
    case SET_USER_IMP:
      if (action.payload !== undefined) {
        localStorage.setItem("logged-user-imp", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("logged-user-imp");
      }
      return {
        ...state,
        currentImpUser: action.payload,
      };

    case SET_BROKER_GRANTS:
      return {
        ...state,
        brokerGrants: action.payload,
      };
    case SET_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case SET_MENU_PAGES_EXPIRED:
      return {
        ...state,
        menuPagesExpired: action.payload,
      };
    case SET_MENU_PAGES:
      if (action.payload !== undefined) {
        localStorage.setItem("menuVoices", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("menuVoices");
      }
      return {
        ...state,
        menuVoices: action.payload,
      };
    case SET_USER:
      if (action.payload !== undefined) {
        localStorage.setItem("logged-user", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("logged-user");
      }
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_LOGIN_STATUS:
      if (action.payload !== undefined) {
        localStorage.setItem(
          "logged-user-login-status",
          JSON.stringify(action.payload)
        );
      } else {
        localStorage.removeItem("logged-user-login-status");
      }
      return {
        ...state,
        loginStatus: action.payload,
      };
    case SET_COMPANY:
      if (action.payload !== undefined) {
        localStorage.setItem(
          "logged-user-company",
          JSON.stringify(action.payload)
        );
      } else {
        localStorage.removeItem("logged-user-company");
      }
      return {
        ...state,
        companies: action.payload,
        mainCompany: action.payload?.find(
          (x: ICompanyGet) => x.companyParentID === null
        ),
      };
    case SET_CURRENT_BROKER_COMPANY:
      return {
        ...state,
        currentBrokerCompany: action.payload,
      };
    default:
      return state;
  }
};
