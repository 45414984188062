import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { initAutocomplete } from "../../Utils/Google";
import "./PlaceAutoCompleteForm.scss";
import BetterSelect from "../MultiForm/SpecialInputs/BetterSelect";
import { ICountry } from "../../Models/ICountry";
import { ToastMessage } from "../../Utils/Toastify";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { generateInputProps } from "../MultiForm/MultiForm";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { loader } from "../../App";

export interface IPlaceAutoCompleteFormField {
  placeholder?: string;
  name: string;
  type: "text" | "number" | "select_nations";
  required?: boolean;
  defaultValue?: any;
  currentValue?: any;
  errorText?: string;
  hint?: string;
  variant?: "outlined" | "standard" | "filled";
  label?: string;
  disabled?: boolean;
  width?: number;
  maxLength?: number;
  element?: JSX.Element;
  mapDecode?: string | undefined;
  inputProps?: any;
  inputAdornament?: any;
}

export interface IPlaceAutoCompleteForm {
  inputs: IPlaceAutoCompleteFormField[];
  addressId: string;
  disableForm?: boolean | false;
  onChange?: (data: any) => void;
}

const PlaceAutoCompleteForm = (props: IPlaceAutoCompleteForm) => {
  const [state, setState] = useState<any>({});
  const [addressFromGoogle, setAddressFromGoogle] = useState<any>({});
  const [form, setForm] = useState<IPlaceAutoCompleteFormField[]>([]);
  const [addressField, setAddressField] = useState<any>();
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  
  useEffect(() => {
    if (props.disableForm !== undefined) {
      setForm(
        props.inputs.map((x: IPlaceAutoCompleteFormField) => {
          if (props.disableForm) {
            x.disabled = props.disableForm;
          }
          return x;
        })
      );
    } else {
      setForm(props.inputs);
    }

    // initialize state
    if (Object.keys(state).length === 0) {
      let stats = props.inputs.map((x: IPlaceAutoCompleteFormField) => {
        let baseValue = x.defaultValue ? x.defaultValue : null;
        if (["checkbox", "switch"].includes(x.type) && baseValue === null) {
          baseValue = false;
        }
        return { key: x.name, value: baseValue };
      });
      let initialState: any = {};
      for (let i = 0; i < stats.length; i++) {
        initialState[stats[i].key] = stats[i].value;
      }
      setState(initialState);
    }
  }, [props.inputs, props.disableForm]);

  const validateRequireds = (state: any, onlyRemove: boolean) => {
    let cloneForm: IPlaceAutoCompleteFormField[] = [...form];
    for (let i = 0; i < cloneForm.length; i++) {
      let input: IPlaceAutoCompleteFormField = cloneForm[i];
      if (!input.disabled) {
        let badValue =
          state[input.name] === "" ||
          state[input.name] === undefined ||
          state[input.name] === null ||
          (state[input.name] && state[input.name].length === 0);
        if (input.required && badValue && !onlyRemove) {
          cloneForm[i]["errorText"] = i18next.t("form:requiredField");
        }
        if (input.required && !badValue) {
          cloneForm[i]["errorText"] = undefined;
        }
      }
    }
    setForm(cloneForm);
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state);
      window.document.addEventListener(
        "newAddressAutoComplete",
        addressFromEvent
      );
    }

    let newAddressField = document.querySelector("#" + props.addressId);
    if (newAddressField !== null && newAddressField !== undefined) {
      setAddressField(newAddressField);
    }
  }, [state]);

  const updateChange = (name: string, value: any) => {
    let u: any = { ...state };
    u[name] = value;
    setState(u);
    validateRequireds(u, true);
  };

  const addressFromEvent = (e: any) => {
    let address = e.detail;
    setAddressFromGoogle(address);
  };

  useEffect(() => {
    if (addressField) {
      window.document.addEventListener(
        "newAddressAutoComplete",
        addressFromEvent
      );
      initAutocomplete(addressField, loader);
    }
  }, [addressField]);

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      let tmpState = { ...state };
      let decodeKeyList: { from: string | undefined; to: string }[] = form.map(
        (x: IPlaceAutoCompleteFormField) => {
          return { from: x.mapDecode, to: x.name };
        }
      );

      for (let i = 0; i < decodeKeyList.length; i++) {
        if (decodeKeyList[i].from) {
          let fromData = addressFromGoogle[decodeKeyList[i].from as any];
          if (decodeKeyList[i].from === "country") {
            let country = countries.find(
              (country: ICountry) => country.countryISO === fromData
            );
            if (country) {
              fromData = country.countryID;
            }
          }
          tmpState[decodeKeyList[i].to] = fromData;
        }
      }
      setState(tmpState);
    }
  }, [addressFromGoogle]);

  const loadCountries = () => {
    setLoadingCountries(true);
    ApiService.AccountController.AccountCountry((response: IAPIResponse) => {
      if (response.error === null) {
        setCountries(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingCountries(false);
    });
  };

  useEffect(() => {
    loadCountries();
  }, []);

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap" }}
      id={"form-" + props.addressId}
    >
      {form.map((x: IPlaceAutoCompleteFormField, i: number) => {
        let inp = null;
        switch (x.type) {
          case "text":
          case "number":
            inp = (
              <TextField
                id={x.name}
                type={x.type}
                fullWidth
                disabled={x.disabled}
                value={state[x.name] ? state[x.name] : ""}
                required={x.required}
                size={"small"}
                placeholder={
                  x.placeholder
                    ? x.placeholder
                    : x.mapDecode === "route"
                    ? `${i18next.t("form:enterLocation")}`
                    : ""
                }
                variant={x.variant ? x.variant : "outlined"}
                helperText={x.errorText ? x.errorText : x.hint}
                error={x.errorText !== undefined && x.errorText !== ""}
                InputProps={generateInputProps(x)}
                inputProps={x.inputProps}
                onChange={(e: any) => {
                  updateChange(x.name, e.target.value);
                }}
              />
            );
            break;
          case "select_nations":
            inp = (
              <BetterSelect
                {...x}
                options={countries.map((country: ICountry) => {
                  return {
                    key: country.countryID,
                    text: i18next.t(`countries:${country.countryISO}`),
                  };
                })}
                onChange={(v) => {
                  updateChange(x.name, v);
                }}
              />
            );
            break;
        }

        const dePadWidth = (width: number | undefined) => {
          if (width) {
            return { width: "calc(" + width.toString() + "% - 15px" };
          }
          return {};
        };

        return (
          <div
            key={i}
            className="multi-form-input-wrap"
            style={dePadWidth(x.width)}
          >
            {x.label && (
              <div
                className={
                  "multi-form-input-upper-label " +
                  (x.disabled ? "label-disabled" : "")
                }
              >
                {x.label}
                {x.required && " *"}
              </div>
            )}
            {inp}
          </div>
        );
      })}

      <LoaderBackdrop loading={loadingCountries} />
    </div>
  );
};

export default PlaceAutoCompleteForm;
