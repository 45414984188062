/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./MessageCenter.scss";

/* REDUCER */
import { GlobalState } from "../../Reducers/RootReducer";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* MODELS */
import { IUser } from "../../Models/IUser";

/* COMPONENTS */
import AppRoutes from "../../Costants/AppRoutes";
import { ToastMessage } from "../../Utils/Toastify";

/* MUI */
import {
  Badge,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
// Icons
import MailIcon from "@mui/icons-material/Mail";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { IMessage } from "../../Models/IMessages";
import i18next from "i18next";
import moment from "moment";
import { handleOptionalTranslatable } from "../../Utils/Messaging";

/* RETURN */
const MessageCenter = () => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  // State for messages
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [page, setPage] = useState<number>(0);
  // States for avatar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* API */
  const getMessages = () => {
    setLoading(true);
    ApiService.MessagesController.MessagesGet((response: IAPIResponse) => {
      if (response.error === null) {
        setMessages(response.payload.reverse());
      } else {
        ToastMessage(i18next.t("error:couldnotloadmessages"), "error");
      }
      setLoading(false);
    });
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    getMessages();
  }, []);

  /* RETURN */
  return (
    <>
      {loggedUser && (
        <div style={{ position: "relative", margin: "0 0.4em" }}>
          <IconButton
            color="primary"
            aria-label="alerts"
            component="label"
            onClick={handleClick}
          >
            {!loading &&
            messages.filter((x: any) => x.message.read === false).length > 0 ? (
              <Badge color="error" variant="dot" overlap="circular">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )}
            <div style={{ marginTop: " -25px", marginLeft: "-5px" }}>
              {loading && (
                <CircularProgress
                  style={{ color: "#fff", width: "15px", height: "15px" }}
                />
              )}
            </div>
          </IconButton>

          <Menu
            id="account-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="hideScrollbar"
            PaperProps={{
              elevation: 0,
              sx: {
                width: "400px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                height: "500px",
                overflowY: "scroll",
              },
            }}
          >
            <div>
              <div className="message--box">
                <div
                  style={{ opacity: page === 0 ? "1" : "0.5" }}
                  className="message--box--div"
                  onMouseEnter={() => {
                    setPage(0);
                  }}
                >
                  <span>{i18next.t("navigation:unread_messages")}</span>
                  <Badge
                    badgeContent={
                      messages.filter((x: any) => x.message.read === false)
                        .length
                    }
                  >
                    <MailIcon color="secondary" />
                  </Badge>
                </div>
              </div>
              <div className="message--box">
                <div
                  style={{ opacity: page === 1 ? "1" : "0.5" }}
                  className="message--box--div"
                  onMouseEnter={() => {
                    setPage(1);
                  }}
                >
                  <span>{i18next.t("navigation:read_messages")}</span>
                  <Badge
                    badgeContent={
                      messages.filter((x: any) => x.message.read === true)
                        .length
                    }
                  >
                    <MailIcon color="secondary" />
                  </Badge>
                </div>
              </div>
              <Divider />
            </div>

            {messages.length > 0 &&
              messages
                .filter((x: any) => x.message.read === (page === 1))
                .map((el: IMessage, i: number) => {
                  return (
                    <>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            AppRoutes.INTERNAL_DASHBOARD +
                              AppRoutes.DASHBOARD___MESSAGING_ROUTE
                          );
                        }}
                        style={{ marginTop: "0.5em", whiteSpace: "normal" }}
                      >
                        <ListItemIcon className="message--box--icon">
                          <MarkChatUnreadIcon />
                        </ListItemIcon>

                        <div>
                          <span className="displayBlock text-bold">
                            Da: {el.message.accountFromEmail}
                          </span>
                          <span className="displayBlock">
                            <i>
                              {moment(el.message.sentDT)
                                .utc()
                                .format("DD-MM-YYYY")}
                            </i>
                          </span>
                          <span className="displayBlock">
                            {handleOptionalTranslatable(el.message.subject)}
                          </span>
                        </div>
                      </MenuItem>

                      <Divider />
                    </>
                  );
                })}
          </Menu>
        </div>
      )}
    </>
  );
};

export default MessageCenter;
