/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__FleetPlans.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { getServiceSupportedOptions } from "../../../../../Utils/ServiceCodes";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import EditIcon from "@mui/icons-material/Edit";
import { IFleetPlanUpdateBody } from "../../../../../Models/IFleetPlanUpdateBody";
import { IFleetPlanSetBody } from "../../../../../Models/IFleetPlanSetBody";
import {
  IAddedService,
  IAddedServiceUpdate,
  IGenericDescriptor,
} from "../../../../../Models/IService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import MultiLangDescPicker from "../../../../../Components/MultiLangDescPicker/MultiLangDescPicker";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { renderServiceImage } from "../../../../../Components/ServicesSubscriptionsTile/ServicesSubscriptionsTile";

/* MUI */
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { voices } from "../../../../../i18n";
import { currencyDecoder } from "../../../../../Utils/Decoder";

/* COMPONENT */
var requestItemUpdate = false;
const AdminPage__FleetPlans = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [page, setPage] = useState<string>("1");

  /* STATES */
  const [loadingFleetPlanList, setLoadingFleetPlanList] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [hideDisabledServices, setHideDisabledServices] = useState<boolean>(true);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Container for company list
  const [processing, setProcessing] = useState<boolean>(false);
  const [servicesList, setServicesList] = useState<IAddedService[]>([]);
  const [serviceShown, setServiceShown] = useState<IAddedService[]>([]);

  const [fleetPlanList, setFleetPlanList] = useState<IFleetPlan[]>([]);
  const [fleetPlanShown, setFleetPlanShown] = useState<IFleetPlan[]>([]);
  const [currentFleetPlan, setCurrentFleetPlan] = useState<any>(null);
  const [currentService, setCurrentService] = useState<any>(null);
  const [hideDisabledFleetPlans, setHideDisabledFleetPlans] = useState<boolean>(true);

  /* APIs */
  // Load company list
  const loadFleetPlans = () => {
    setLoadingFleetPlanList(true);
    if (loggedUser) {
      ApiService.FleetPlanController.FleetPlanGetData(
        null,
        undefined,
        true,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFleetPlanShown(response.payload);
            let rawList: IFleetPlan[] = response.payload;
            let joinedList: any = {};

            for (let i = 0; i < rawList.length; i++) {
              let el: IFleetPlan = { ...rawList[i] };

              if (!joinedList[rawList[i].fleetPlanID.toString()]) {
                (el as any)["fleetPlanTranslation"] = [
                  {
                    languageID: el.languageID,
                    name: el.fleetPlanName,
                    description: el.fleetPlanDescription,
                    note: "",
                  },
                ];
                joinedList[rawList[i].fleetPlanID.toString()] = el;
              } else {
                joinedList[rawList[i].fleetPlanID.toString()].fleetPlanTranslation.push({
                  languageID: el.languageID,
                  name: el.fleetPlanName,
                  description: el.fleetPlanDescription,
                  note: "",
                });
              }
            }
            setFleetPlanList(Object.values(joinedList));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFleetPlanList(false);
        }
      );
    }
  };

  const loadServices = () => {
    setLoading2(true);
    ApiService.ServiceController.ServiceGetAddedData(
      true,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setServiceShown(response.payload);
          let rawList: IAddedService[] = response.payload;
          let joinedList: any = {};

          for (let i = 0; i < rawList.length; i++) {
            let el: IAddedService = { ...rawList[i] };

            if (!joinedList[rawList[i].addedServiceID.toString()]) {
              (el as any)["addedServiceTranslation"] = [
                {
                  languageID: el.languageID,
                  name: el.addedServiceName,
                  description: el.addedServiceDescription,
                  note: el.addedServiceNote,
                },
              ];
              joinedList[rawList[i].addedServiceID.toString()] = el;
            } else {
              joinedList[
                rawList[i].addedServiceID.toString()
              ].addedServiceTranslation.push({
                languageID: el.languageID,
                name: el.addedServiceName,
                description: el.addedServiceDescription,
                note: el.addedServiceNote,
              });
            }
          }
          setServicesList(Object.values(joinedList));
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading2(false);
      }
    );
  };

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            setIsEdit(true);
            setCurrentFleetPlan(fleetPlanList.filter((x: IFleetPlan) => x.fleetPlanID === props.row.fleetPlanID)[0]);
            SummonModal("PlanEditModal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const renderEditService = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            setIsEdit(true);
            setCurrentService(servicesList.filter((x: IAddedService) => x.addedServiceID === props.row.addedServiceID)[0]);
            if (props.row && props.row.addedServiceImage) {
              setImgUrl(props.row.addedServiceImage);
            } else {
              setImgUrl(null);
            }
            SummonModal("ServiceEditModal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const renderServiceLogo = (props: GridRenderCellParams) => {
    return (
      <div className="service-logo">
        <img src={renderServiceImage(props.row)} alt="" />
      </div>
    );
  };

  const renderCost = (props: GridRenderCellParams) => {
    return <div>{currencyDecoder(props.value, 2)}</div>;
  };

  const updatePlan = (body: IFleetPlanUpdateBody) => {
    setProcessing(true);
    ApiService.FleetPlanController.FleetPlanUpdateSetData(
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadFleetPlans();
          DismissModal("PlanEditModal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertPlan = (body: IFleetPlanSetBody, archiveOldId?: number) => {
    setProcessing(true);
    ApiService.FleetPlanController.FleetPlanSetData(
      body,
      archiveOldId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          requestItemUpdate = false;
          loadFleetPlans();
          DismissModal("PlanEditModal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const updateService = (body: IAddedServiceUpdate) => {
    setProcessing(true);
    ApiService.ServiceController.ServiceUpdateAddedData(
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadServices();
          DismissModal("ServiceEditModal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertService = (body: IAddedServiceUpdate, replaceId?: number) => {
    setProcessing(true);
    ApiService.ServiceController.ServiceInsertAddedData(
      body,
      replaceId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadServices();
          requestItemUpdate = false;
          DismissModal("ServiceEditModal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: i18next.t("generic:edit"),
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "fleetPlanName",
      headerName: i18next.t("form:fleetPlan_Name"),
    },
    {
      field: "fleetPlanDescription",
      headerName: i18next.t("form:fleetPlan_Desc"),
    },
    {
      field: "fleetPlanYearlyCost",
      headerName: i18next.t("form:fleetPlan_YCost"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fleetPlanMonthlyCost",
      headerName: i18next.t("form:fleetPlan_MCost"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fleetPlanAdditionalCarCost",
      headerName: i18next.t("form:fleetPlan_AdditionalCost"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "fleetPlanMinVehicle",
      headerName: i18next.t("form:fleetPlan_vmin"),
      type: "number"
    },
    {
      field: "fleetPlanMaxVehicle",
      headerName: i18next.t("form:fleetPlan_vmax"),
      type: "number"
    },
  ];

  const columnsDefServices: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: i18next.t("generic:edit"),
      renderCell: renderEditService,
    },
    {
      field: "addedServiceImage",
      headerName: i18next.t("form:service_logo"),
      renderCell: renderServiceLogo,
    },
    {
      field: "addedServiceName",
      headerName: i18next.t("form:service_name"),
    },
    {
      field: "addedServiceCode",
      headerName: i18next.t("form:service_code_internal"),
    },
    {
      field: "addedServiceDescription",
      headerName: i18next.t("form:service_desc"),
    },
    {
      field: "addedServiceNote",
      headerName: i18next.t("form:service_note"),
    },
    {
      field: "addedServiceMontlyCost",
      headerName: i18next.t("form:service_cost_month"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "addedServiceYearlyCost",
      headerName: i18next.t("form:service_cost_year"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "addedServiceCallCost",
      headerName: i18next.t("form:service_call_cost"),
      renderCell: renderCost,
      type: "number"
    },
    {
      field: "isTrialFree",
      headerName: i18next.t("form:service_trial_free"),
      renderCell: (props: GridRenderCellParams) => {
        return (
          <div>
            {props.row.isTrialFree ? i18next.t("message:yes") : i18next.t("message:no")}
          </div>
        );
      },
    },
  ];

  const planButtons = () => {
    let btns = [
      {
        text: isEdit ? i18next.t("form:save_langs") : i18next.t("form:save"),
        onClick: () => {
          requestItemUpdate = false;
          triggerFormValidation("PlanEditForm");
        },
      },
    ];

    if (isEdit) {
      btns.push({
        text: i18next.t("form:update_plan"),
        onClick: () => {
          requestItemUpdate = true;
          triggerFormValidation("PlanEditForm");
        },
      });
    }

    return btns;
  };

  const servButtons = () => {
    let btns = [
      {
        text: isEdit ? i18next.t("form:save_langs") : i18next.t("form:save"),
        onClick: () => {
          requestItemUpdate = false;
          triggerFormValidation("ServiceEditForm");
        },
      },
    ];

    if (isEdit) {
      btns.push({
        text: i18next.t("form:update_service"),
        onClick: () => {
          requestItemUpdate = true;
          triggerFormValidation("ServiceEditForm");
        },
      });
    }

    return btns;
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadFleetPlans();
    loadServices();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_fleetplans")} />

      <div className="dashboardForm__box">
        <div className="display-flex-wide margin-bottom-small">
          <ToggleButtonGroup exclusive size="small" color="primary" value={page}>
            <ToggleButton
              value="1"
              onClick={() => {
                setPage("1");
              }}
            >
              {i18next.t("navigation:_admin_fleetplans_plans")}
            </ToggleButton>

            <ToggleButton
              value="2"
              onClick={() => {
                setPage("2");
              }}
            >
              {i18next.t("navigation:_admin_fleetplans_services")}
            </ToggleButton>
          </ToggleButtonGroup>

          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentService(null);
                setCurrentFleetPlan(null);
                setIsEdit(false);
                setImgUrl(null);
                if (page === "1") {
                  SummonModal("PlanEditModal");
                } else {
                  SummonModal("ServiceEditModal");
                }
              }}
            >
              {i18next.t("form:nuovo")}
            </Button>
          </ButtonGroup>
        </div>
        {page === "1" && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={hideDisabledFleetPlans}
                  onChange={(e, checked) => {
                    setHideDisabledFleetPlans(checked);
                  }}
                  name="gilad"
                />
              }
              label={i18next.t("navigation:_show_disabled_plans")}
            />
            <Box className="dashboardForm__data">
              <DataGridWrap headers={columnsDef} rows={fleetPlanShown.filter(
                  (x: IFleetPlan) => (x.stored === false || hideDisabledFleetPlans === false) && x.languageID === voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num              
                )} />
            </Box>
          </div>
        )}

        {page === "2" && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={hideDisabledServices}
                  onChange={(e, checked) => {
                    setHideDisabledServices(checked);
                  }}
                  name="gilad"
                />
              }
              label={i18next.t("navigation:_show_disabled_services")}
            />
            <Box className="dashboardForm__data">
              <DataGridWrap
                headers={columnsDefServices}
                rows={serviceShown.filter(
                  (x: IAddedService) => (x.stored === false || hideDisabledServices === false) && x.languageID === voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num 
                )}
              />
            </Box>
          </div>
        )}

        <SmartModal
          title={
            isEdit
              ? i18next.t("navigation:edit_service")
              : i18next.t("navigation:new_service")
          }
          modalUniqueId="ServiceEditModal"
          modalInnerComponent={
            <div>
              <MultiForm
                formTitle={""}
                formId="ServiceEditForm"
                suppressLayout
                suppressSubmit
                inputs={[
                  {
                    type: "number",
                    width: 25,
                    name: "addedServiceMontlyCost ",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    label: i18next.t("form:service_cost_month") ?? "",
                    defaultValue: currentService?.addedServiceMontlyCost,
                  },
                  {
                    type: "number",
                    width: 25,
                    name: "addedServiceYearlyCost ",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    label: i18next.t("form:service_cost_year") ?? "",
                    defaultValue: currentService?.addedServiceYearlyCost,
                  },
                  {
                    type: "number",
                    width: 25,
                    name: "addedServiceCallCost",
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    required: true,
                    label: i18next.t("form:service_call_cost") ?? "",
                    defaultValue: currentService?.addedServiceCallCost,
                  },
                  {
                    type: "select",
                    width: 25,
                    name: "addedServiceCode",
                    options: getServiceSupportedOptions(),
                    required: true,
                    label: i18next.t("form:service_added_code") ?? "",
                    defaultValue: currentService?.addedServiceCode,
                  },
                  {
                    type: "switch",
                    width: 25,
                    name: "isTrialFree",
                    required: true,
                    label: i18next.t("form:service_trial_free") ?? "",
                    defaultValue: currentService?.isTrialFree,
                  },
                  {
                    type: "custom",
                    width: 100,
                    name: "addedServiceTranslation_",
                    element: (
                      <div>
                        <MultiLangDescPicker
                          defaultValue={
                            currentService ? currentService.addedServiceTranslation : []
                          }
                          onChange={(data: IGenericDescriptor[]) => {
                            let data_ = currentService ? { ...currentService } : {};
                            data_.addedServiceTranslation = data;
                            setCurrentService(data_);
                          }}
                        />
                      </div>
                    ),
                  },
                  {
                    width: 50,
                    name: "space",
                    type: "custom",
                    element: (
                      <div>
                        <div className="multi-form-input-upper-label">
                          {i18next.t("navigation:_profile_avatar")}
                        </div>
                        <Button
                          onClick={() => {
                            document.getElementById("avatar-input")?.click();
                          }}
                          startIcon={<CameraAltIcon />}
                        >
                          {i18next.t("navigation:upload")}
                        </Button>
                        <input
                          onChange={(e: any) => {
                            let files = e.target.files;
                            if (files.length > 0) {
                              let image = files[0];

                              // get image ref and preview
                              let url = URL.createObjectURL(image);
                              const img = new Image();
                              img.src = url;
                              img.onload = () => {
                                // init canvas
                                const canvas: any = document.createElement("canvas");
                                const W = 130;
                                const H = 130;
                                canvas.width = W;
                                canvas.height = H;

                                // init canvas context
                                const ctx = canvas.getContext("2d");
                                if (ctx) {
                                  ctx.drawImage(img, 0, 0, W, H);

                                  var pngUrl = canvas.toDataURL(); // PNG is the default
                                  setImgUrl(pngUrl);
                                }
                              };
                            }
                          }}
                          accept=".png, .jpg, .jpeg, .bmp"
                          type="file"
                          hidden
                          id="avatar-input"
                        />
                        {imgUrl && <img className="image-profile-preview" src={imgUrl} alt=""/>}
                      </div>
                    ),
                  },
                ]}
                onSubmit={(data: any) => {
                  let data_ = { ...currentService, ...data };

                  if (imgUrl) {
                    data_.addedServiceImage = imgUrl;
                  }

                  if (data_.addedServiceID) {
                    if (requestItemUpdate) {
                      let id = data_.addedServiceID;
                      delete data_.addedServiceID;
                      delete data_.space;
                      insertService(data_, id);
                    } else {
                      updateService(data_);
                    }
                  } else {
                    insertService(data_);
                  }
                }}
              />
            </div>
          }
          buttons={servButtons()}
        />

        <SmartModal
          title={
            isEdit ? i18next.t("navigation:edit_plan") : i18next.t("navigation:new_plan")
          }
          buttons={planButtons()}
          modalUniqueId="PlanEditModal"
          modalInnerComponent={
            <div>
              <MultiForm
                onSubmit={(data: any) => {
                  let data_ = { ...currentFleetPlan, ...data };
                  data_["isTrialFree"] = data?.isTrialFree;
                  data_["addedServiceMontlyCost"] = +data.addedServiceMontlyCost;
                  data_["addedServiceYearlyCost"] = +data.addedServiceYearlyCost;
                  data_["fleetPlanYearlyCost"] = +data?.fleetPlanYearlyCost;
                  data_["fleetPlanMonthlyCost"] = +data?.fleetPlanMonthlyCost;
                  if (data_.fleetPlanID) {
                    if (requestItemUpdate) {
                      let id = data_.fleetPlanID;
                      delete data_.fleetPlanID;
                      delete data_.space;
                      insertPlan(data_, id);
                    } else {
                      updatePlan(data_);
                    }
                  } else {
                    insertPlan(data_);
                  }
                }}
                suppressLayout
                suppressSubmit
                formId="PlanEditForm"
                inputs={[
                  {
                    type: "number",
                    width: 15,
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    name: "fleetPlanYearlyCost",
                    required: true,
                    label: i18next.t("navigation:fleetPlanYearlyCost") ?? "",
                    defaultValue: currentFleetPlan?.fleetPlanYearlyCost,
                  },
                  {
                    type: "number",
                    width: 15,
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    name: "fleetPlanMonthlyCost",
                    required: true,
                    label: i18next.t("navigation:fleetPlanMonthlyCost") ?? "",
                    defaultValue: currentFleetPlan?.fleetPlanMonthlyCost,
                  },
                  {
                    type: "number",
                    width: 15,
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament: { adornament: "€" },
                    name: "fleetPlanAdditionalCarCost",
                    required: true,
                    label: i18next.t("form:fleetPlan_AdditionalCost") ?? "",
                    defaultValue: currentFleetPlan?.fleetPlanAdditionalCarCost,
                  },
                  {
                    type: "number",
                    width: 15,
                    name: "fleetPlanMinVehicle",
                    required: true,
                    label: i18next.t("navigation:fleetPlanMinVehicle") ?? "",
                    defaultValue: currentFleetPlan?.fleetPlanMinVehicle,
                    inputProps: {
                      min: 1,
                    }
                  },
                  {
                    type: "number",
                    width: 15,
                    name: "fleetPlanMaxVehicle",
                    required: true,
                    label: i18next.t("navigation:fleetPlanMaxVehicle") ?? "",
                    defaultValue: currentFleetPlan?.fleetPlanMaxVehicle,
                    inputProps: {
                      min: 1
                    }
                  },
                  {
                    type: "switch",
                    width: 15,
                    name: "isTrialFree",
                    required: true,
                    label: i18next.t("form:service_trial_free") ?? "",
                    defaultValue: currentFleetPlan?.isTrialFree,
                  },
                  {
                    type: "custom",
                    width: 100,
                    name: "fleetPlanTranslation_",
                    element: (
                      <div>
                        <MultiLangDescPicker
                          noNotes
                          defaultValue={
                            currentFleetPlan ? currentFleetPlan.fleetPlanTranslation : []
                          }
                          onChange={(data: IGenericDescriptor[]) => {
                            let data_ = currentFleetPlan ? { ...currentFleetPlan } : {};
                            data_.fleetPlanTranslation = data;
                            setCurrentFleetPlan(data_);
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          }
        />
        <LoaderBackdrop loading={loadingFleetPlanList || loading2 || processing} />
      </div>
    </div>
  );
};

export default AdminPage__FleetPlans;
