import { padBlock } from "../Components/MultiForm/SpecialInputs/StrongDatePicker";

export const currencyDecoder = (
  value: number,
  decimal?: number | 2,
  currencyCode?: string | null
) => {
  if(value === null || value === undefined){
    return "-";
  }
  let lang = localStorage.getItem("language");
  if (lang === null) {
    lang = "eng";
  }
  return value.toLocaleString(lang, {
    style: "currency",
    minimumFractionDigits: decimal,
    currency: currencyCode ? currencyCode : "EUR",
  });
};

export const numberToLocalDecoder = (value: number) => {
  let lang = localStorage.getItem("language");
  if (lang === null) {
    lang = "eng";
  }
  return value.toLocaleString(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "decimal",
  });
}

export const JSONPrint = (json: any) => {
  if (json) {
    return (
      <div
        style={{
          whiteSpace: "pre",
          overflowY: "auto",
          maxHeight: "10em",
          border: "1px solid rgba(0,0,0,0.1)",
          background: "white",
        }}
      >
        {JSON.stringify(json, null, 4)}
      </div>
    );
  }
  return <div>No data</div>;
};

export const calcVatCost = (
  value: number,
  type: "total" | "extract",
  onlyNumber: boolean,
  vat?: number,
) => {
  if (vat === undefined) {
    vat = 1.22;
  }

  let vatCost = 0;
  switch (type) {
    case "extract":
      vatCost = value * vat - value;
      break;

    case "total":
      vatCost = value * vat;
      break;
  }
  if(onlyNumber){
    return vatCost.toFixed(2);
  }else{
    return currencyDecoder(vatCost);
  }
};

export const decodeHumanFileSize = (fileSizeInBytes: number) => {
  var i = -1;
  var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const decodeDateLocale = () => {
  let language = "";
  switch (localStorage.getItem("language")) {
    case "ita":
      language = "it";
      break;

    default:
      language = "en";
      break;
  }

  return language;
};

export const getDatesBetween = (
  startDate: Date,
  endDate: Date,
  includeEndDate?: boolean
) => {
  const dates = [];
  const currentDate = startDate;
  while (currentDate < endDate) {
    let date = currentDate.getFullYear() + "-" + padBlock(currentDate.getMonth() + 1) + "-" + padBlock(currentDate.getUTCDate());
    if(dates.indexOf(date) <= -1)
      dates.push(date);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  if (includeEndDate){ 
    dates.push(endDate.getFullYear() + "-" + padBlock(endDate.getMonth()) + "-" + padBlock(endDate.getDay()))
  };

  return dates;
};