/* REACT */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* LIBRARY */
import i18next from "i18next";

/* MUI */
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

/* API SERVICES */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* INTERFACE */
interface IToggleButtons {
  position: string;
}

const Payments__AdditionalServicesPage_ToggleButtons = (
  props: IToggleButtons
) => {
  // Navigate
  const navigate = useNavigate();

  /* STATES */
  const [active, setActive] = useState(props.position);

  /* FUNCTIONS */
  // Active toggle button
  const handleChangeActive = (
    event: React.MouseEvent<HTMLElement>,
    newActive: string
  ) => {
    if (newActive !== null) {
      setActive(newActive);
    }
  };

  /* RETURN */
  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      value={active}
      onChange={handleChangeActive}
    >
      <ToggleButton
        value="1"
        onClick={() => {
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS
          );
        }}
      >
        {i18next.t("navigation:_stamp_payments_request_payments")}
      </ToggleButton>

      <ToggleButton
        value="2"
        onClick={() => {
          navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.MANUAL_PAYMENTS);
        }}
      >
        {i18next.t("navigation:roadtax_page_toggle_button_to_pay")}
      </ToggleButton>

      <ToggleButton
        value="3"
        onClick={() => {
          navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.PAID_PAYMENTS);
        }}
      >
        {i18next.t("navigation:roadtax_page_toggle_button_paid")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Payments__AdditionalServicesPage_ToggleButtons;
