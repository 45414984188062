import { CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import "./ConfirmAccountPage.scss";

const ConfirmAccountPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const token = params.get("token");

  const confirmAccount = () => {
    ApiService.AccountController.ValidateAccount(
      token,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:accountConfirmed"), "success");
          navigate(AppRoutes.LOGIN_ROUTE);
        } else {
          ToastMessage(response.error, "error");
          setError(true);
        }
      }
    );
  };

  useEffect(() => {
    if (token) {
      confirmAccount();
    } else {
      setError(true);
    }
  }, [token]);

  return (
    <div className="confirm-account-page-wrap">
      <div
        className="confirm-account-main-text"
        dangerouslySetInnerHTML={{
          __html:
            (error
              ? i18next.t("error:ACCOUNT_CONFIRMATION_ERROR")
              : i18next.t("generic:resetting_title_content")) ?? "",
        }}
      ></div>
      {!error && <CircularProgress />}
    </div>
  );
};

export default ConfirmAccountPage;
