import React from "react";
import './BannerTextComponent.scss';
import {Button} from "@mui/material";
import AppRoutes from "../../Costants/AppRoutes";
import {useNavigate} from "react-router-dom";

export type BannerTextProps = {
    imgPath: string;
    textTitle: React.ReactNode;
    textContent?: React.ReactNode;
    withButtons?: boolean;
    textButtons?: React.ReactNode;
}

export const BannerTextComponent: React.FC<BannerTextProps> = ({imgPath,textTitle,textContent, withButtons, textButtons}) => {
    const navigate = useNavigate();

    return (
        <div className="ff-banner-text-wrapper">
            <div className="ff-banner-img-bg" style={{ backgroundImage: `url(${imgPath})` }}>
                <div className="ff-banner-text-overlay" />
            </div>
            <div className="ff-banner-text-container">
                <h1>{textTitle}</h1>
                {textContent && <div className="ff-banner-text-parag">{textContent}</div>}

                {withButtons && <Button variant="contained" onClick={() => {
                    navigate(AppRoutes.REGISTRATION_ROUTE);
                }}>{textButtons}</Button>}
            </div>
        </div>
    )
}

export default BannerTextComponent;