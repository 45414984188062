import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAssignmentGet } from "../../Models/IAssignment";
import { IStepperTickets } from "../../Models/IStepperTickets";
import { ITicketInsertDetailResponse } from "../../Models/ITicketInsertDetailResponse";
import { ITicketInsertPayment } from "../../Models/ITicketInsertPayment";
import { ITicketInsertPaymentResponse } from "../../Models/ITicketInsertPaymentResponse";
import { ITicketInsertResponse } from "../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../Models/ITicketStatus";
import { ITicketUpdate } from "../../Models/ITicketUpdate";
import { ITicketUpdatePayment } from "../../Models/ITicketUpdatePayment";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TicketsAction } from "../../Reducers/Tickets/TicketsAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import "./StepAmountDeadelines.scss";
import { JSONPrint } from "../../Utils/Decoder";
import { getProPDFData } from "../../Pages/DashboardPage/DashBoard_Pages/AdditionalServicesPage/Tickets__AdditionalServicesPage/Tickets__AdditionalServicesPage";

export interface IStepAmountDeadelines {
  header?: boolean;
}

const StepAmountDeadelines = (props: IStepAmountDeadelines) => {
  const dispatch = useDispatch();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );
  const ticketInsertPaymentResponse: ITicketInsertPaymentResponse[] | undefined =
    useSelector((state: GlobalState) => state.tickets.ticketInsertPaymentResponse);
  const ticketStatus: ITicketStatus | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketStatus
  );
  const ticketInsertDetailResponse: ITicketInsertDetailResponse[] | undefined =
    useSelector((state: GlobalState) => state.tickets.ticketInsertDetailResponse);
  const [refreshForm, setRefreshForm] = useState<boolean>(true);
  const driverTicket: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.tickets.driverTicket
  );
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );
  const [isHeader, setIsHeader] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [ticketRowTable, setTicketRowtable] = useState<ITicketInsertPayment[]>([
    {
      ticketPaymentDT: "",
      paidAmount: 0,
      postalCharge: 0,
    },
  ]);

  useEffect(() => {
    if (props.header === false) {
      setIsHeader(props.header);
    }
  }, []);

  useEffect(() => {
    if (stepperTicket && stepperTicket?.stepPayment && stepperTicket?.stepPayment?.length > 0) {
      setTicketRowtable(stepperTicket?.stepPayment);
    }
  }, [stepperTicket?.stepPayment]);

  const DeleteTicketPayment = (ticketPaymentID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.DeleteTicketPayment(
        ticketPaymentID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage("ticket payment deleted", "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const setNewDriver = () => {
    ApiService.TicketController.UpdateTicketDriver(
      ticketInsertDetailResponse![0].ticketDetailID,
      driverTicket?.driverID,
      (response: IAPIResponse) => {
        if (response.error === null) {
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const InsertTicketPayment = (body: ITicketInsertPayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertTicketPayment(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertPaymentResponse(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const UpdateTicketPayment = (body: ITicketUpdatePayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicketPayment(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertPaymentResponse(response.payload));
          ToastMessage(i18next.t("message:TicketPaymentCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const UpdateTicket = (body: ITicketUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          if (ticketStatus?.alreadyPaid) {
            if (ticketInsertPaymentResponse) {
              let tmpPayment: ITicketUpdatePayment = {
                ticketPaymentID: ticketInsertPaymentResponse![0].ticketpaymentID,
                ticketPaymentDT: stepperTicket?.stepAmountDeadlines!.ticketPaymentDT!,
                paidAmount: stepperTicket?.stepAmountDeadlines!.paidAmount!,
                postalCharge: stepperTicket?.stepAmountDeadlines!.postalCharge!,
              };
              UpdateTicketPayment(tmpPayment);
            } else {
              for (let i = 0; i < stepperTicket!.stepPayment!.length; i++) {
                let tmpPayment: ITicketInsertPayment = {
                  ticketID: ticketInsertResponse![0].ticketID!,
                  ticketPaymentDT: stepperTicket?.stepPayment![i].ticketPaymentDT!,
                  paidAmount: stepperTicket?.stepPayment![i].paidAmount!,
                  postalCharge: stepperTicket?.stepPayment![i].postalCharge!,
                };
                InsertTicketPayment(tmpPayment);
              }
            }
          }
          dispatch(TicketsAction.setTicketInsertResponse(response.payload));
          ToastMessage(i18next.t("message:TicketUpdateCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const DoRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 50);
  };

  const FromFormToDataVetor = (data: any) => {
    let keys: string[] = Object.keys(data);
    let newTicketRowTable: any = {};
    for (let i = 0; i < ticketRowTable.length; i++) {
      let item: ITicketUpdate = ticketRowTable[i];
      if (!newTicketRowTable[i]) {
        newTicketRowTable[i] = {};
      }
      newTicketRowTable[i] = { ...item };
    }
    for (let i = 0; i < keys.length; i++) {
      let splKey: string[] = keys[i].split("_");
      if (splKey.length === 2) {
        let keyName: string = splKey[0];
        let keyIndex: string = splKey[1];
        if (data[keys[i]] !== null && data[keys[i]] !== undefined) {
          newTicketRowTable[keyIndex][keyName] = data[keys[i]];
        }
      }
    }
    let tmp: ITicketUpdate[] = Object.values(newTicketRowTable);
    setTicketRowtable(tmp);
    return tmp;
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push({
      width: 25,
      type: "datetime",
      name: "ticketReducedExpirationDT",
      defaultValue: stepperTicket?.stepAmountDeadlines?.ticketReducedExpirationDT
        ? stepperTicket?.stepAmountDeadlines?.ticketReducedExpirationDT
        : ticketInsertResponse
        ? ticketInsertResponse![0].ticketReducedExpirationDT
        : "",
      label: i18next.t("form:ExpiryDateAmountWithReduction") ?? "",
      upperLabel: i18next.t("form:ExpiryDateAmountWithReduction") ?? "",
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      name: "ticketReducedAmount",
      defaultValue: getProPDFData(
        "suggestedReducedPayment",
        stepperTicket?.stepAmountDeadlines?.ticketReducedAmount
          ? stepperTicket?.stepAmountDeadlines?.ticketReducedAmount
          : stepperTicket?.articleSanction?.reducedPayment
      ),
      required: true,
      label: i18next.t("form:AmountValueWithReduction") ?? "",
      upperLabel: i18next.t("form:AmountValueWithReduction") ?? "",
      inputProps: {
        step: "0.01",
      },
      inputAdornament: { adornament: "€" },
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      name: "ticketReducedTaxAmount",
      defaultValue: stepperTicket?.stepAmountDeadlines?.ticketReducedTaxAmount,
      label: i18next.t("form:TaxesAndCharges") ?? "",
      upperLabel: i18next.t("form:TaxesAndCharges") ?? "",
      inputAdornament: { adornament: "€" },
      inputProps: {
        step: "0.01",
      },
      options: [],
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      name: "ticketReducedTotalAmount",
      disabled: true,
      currentValue: stepperTicket?.stepAmountDeadlines
        ? +stepperTicket.stepAmountDeadlines.ticketReducedAmount! +
          +stepperTicket.stepAmountDeadlines.ticketReducedTaxAmount!
        : null,
      inputProps: {
        step: "0.01",
      },
      label: i18next.t("form:total") ?? "",
      upperLabel: i18next.t("form:total") ?? "",
      inputAdornament: { adornament: "€" },
    });
    multiformInputs.push({
      width: 25,
      type: "datetime",
      name: "ticketExpirationDT",
      defaultValue: stepperTicket?.stepAmountDeadlines?.ticketExpirationDT
        ? stepperTicket?.stepAmountDeadlines?.ticketExpirationDT
        : ticketInsertResponse
        ? ticketInsertResponse![0].ticketExpirationDT
        : "",
      label: i18next.t("form:ExpiryDateOfAmountWithoutReduction") ?? "",
      upperLabel: i18next.t("form:ExpiryDateOfAmountWithoutReduction") ?? "",
      required: true,
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      inputProps: {
        step: "0.01",
      },
      name: "ticketAmount",
      defaultValue: getProPDFData(
        "suggestedFullPayment",
        stepperTicket?.stepAmountDeadlines?.ticketAmount
          ? stepperTicket?.stepAmountDeadlines?.ticketAmount
          : stepperTicket?.articleSanction?.fullPayment
      ),
      label: i18next.t("form:AmountValueWithoutReduction") ?? "",
      upperLabel: i18next.t("form:AmountValueWithoutReduction") ?? "",
      inputAdornament: { adornament: "€" },
      required: true,
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      inputProps: {
        step: "0.01",
      },
      name: "ticketTaxAmount",
      defaultValue: stepperTicket?.stepAmountDeadlines?.ticketTaxAmount,
      label: i18next.t("form:TaxesAndCharges") ?? "",
      upperLabel: i18next.t("form:TaxesAndCharges") ?? "",
      inputAdornament: { adornament: "€" },
      required: true,
    });
    multiformInputs.push({
      width: 25,
      type: "number",
      inputProps: {
        step: "0.01",
      },
      disabled: true,
      name: "ticketTotalAmount",
      currentValue: stepperTicket?.stepAmountDeadlines
        ? +stepperTicket.stepAmountDeadlines.ticketAmount! +
          +stepperTicket.stepAmountDeadlines.ticketTaxAmount!
        : null,
      label: i18next.t("form:total") ?? "",
      upperLabel: i18next.t("form:total") ?? "",
      inputAdornament: { adornament: "€" },
    });
    if (ticketStatus?.alreadyPaid) {
      for (let i = 0; i < ticketRowTable.length; i++) {
        let rowData: ITicketInsertPayment = ticketRowTable[i];
        multiformInputs.push({
          width: 30,
          type: "datetime",
          name: "ticketPaymentDT_" + i,
          defaultValue: rowData.ticketPaymentDT,
          currentValue: rowData.ticketPaymentDT,
          label: i18next.t("navigation:_tickets_page_payment_date") ?? "",
          upperLabel: i18next.t("navigation:_tickets_page_payment_date") ?? "",
          required: true,
        });
        multiformInputs.push({
          width: 30,
          type: "number",
          name: "paidAmount_" + i,
          currentValue: rowData.paidAmount,
          label: i18next.t("navigation:_tickets_page_amount_paid") ?? "",
          upperLabel: i18next.t("navigation:_tickets_page_amount_paid") ?? "",
          inputAdornament: { adornament: "€" },
          required: true,
        });
        multiformInputs.push({
          width: 30,
          type: "number",
          name: "postalCharge_" + i,
          currentValue: rowData.postalCharge,
          label: i18next.t("navigation:_tickets_page_postage") ?? "",
          upperLabel: i18next.t("navigation:_tickets_page_postage") ?? "",
          inputAdornament: { adornament: "€" },
          required: true,
        });
        multiformInputs.push({
          width: 4,
          type: "custom",
          name: "delete_" + i,
          element: (
            <div
              style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <Button
                className="delete-button"
                variant="contained"
                onClick={() => {
                  let tmpTicketRowTableDelete = [...ticketRowTable].filter(
                    (x: any, j: number) => j === i
                  );
                  if (tmpTicketRowTableDelete.length > 0) {
                    if (tmpTicketRowTableDelete[0].ticketpaymentID) {
                      DeleteTicketPayment(tmpTicketRowTableDelete[0].ticketpaymentID);
                    }
                  }
                  let tmpTicketRowTable = [...ticketRowTable].filter(
                    (x: any, j: number) => j !== i
                  );
                  setTicketRowtable(tmpTicketRowTable);
                  DoRefreshForm();
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ),
        });
      }
    }
    return multiformInputs;
  };

  const RenderForm = () => {
    return (
      <MultiForm
        inputs={multiformInputs()}
        formTitle={""}
        suppressSubmit
        formId="amount-deadlines"
        onChange={async (data: any) => {
          let tmp = FromFormToDataVetor(data);
          let keys: string[] = Object.keys(data);
          let tmpData: any[] = [];
          let tmpArray = Object.values(data);
          for (let i = 0; i < keys.length; i++) {
            let tmp = keys[i].split("_");
            if (tmp.length === 1) {
              tmpData.push(tmpArray[i]);
            }
          }
          let dataObject = {
            ticketReducedExpirationDT: tmpData[0],
            ticketReducedAmount: tmpData[1] !== "" ? tmpData[1] : null,
            ticketReducedTaxAmount: tmpData[2],
            ticketReducedTotalAmount: tmpData[3],
            ticketExpirationDT: tmpData[4],
            ticketAmount: tmpData[5],
            ticketTaxAmount: tmpData[6],
            ticketTotalAmount: tmpData[7],
          };
          dispatch(
            TicketsAction.setStepperTickets({
              ...stepperTicket!,
              stepAmountDeadlines: dataObject,
              stepPayment: tmp,
            })
          );
        }}
        onSubmit={(data: any) => {
          let bodyUpdate: ITicketUpdate = {
            ...stepperTicket?.stepAmountDeadlines,
          };
          bodyUpdate.ticketID = ticketInsertResponse![0].ticketID;
          UpdateTicket(bodyUpdate);
          if (ticketInsertDetailResponse) {
            setNewDriver();
          }
          stepperGoToPage(3);
        }}
      />
    );
  };

  return (
    <div className="amount-deadlines-wrapper">
      {isHeader && (
        <div className="amount-deadlines-header">
          <strong>{i18next.t("navigation:AmountsDeadlines")}</strong>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "1em",
        }}
      >
        <ErrorOutlineIcon color="warning" style={{ marginRight: "1em" }} />{" "}
        <strong>{i18next.t("navigation:_tickets_alert_amount")}</strong>
      </div>
      {refreshForm && RenderForm()}
      {!refreshForm && RenderForm()}
      {ticketStatus?.alreadyPaid && (
        <div className="add-content">
          <div className="add-left">
            <Button
              variant="contained"
              sx={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                marginRight: "1em",
              }}
              onClick={() => {
                let tmpTicketRowTable = [...ticketRowTable];
                tmpTicketRowTable.push({
                  ticketPaymentDT: "",
                  paidAmount: 0,
                  postalCharge: 0,
                });
                setTicketRowtable(tmpTicketRowTable);
                DoRefreshForm();
              }}
            >
              <AddIcon />
            </Button>
            <div style={{ display: "flex" }}>
              {i18next.t("navigation:add")}
              <strong style={{ color: "#009fe3", marginLeft: "0.3em" }}>
                {i18next.t("navigation:_tickets_page_payment")}
              </strong>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.2)",
              width: "100%",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default StepAmountDeadelines;
