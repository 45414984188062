import i18next from "i18next";

// Validation Fiscal Code
export const validationFiscalCode = (FiscalCode: string) => {
  let isValid = new RegExp(
    /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  ).test(FiscalCode);

  return isValid;
};

// Validation VAT
export const validationVAT = (PIVA: string) => {
  let isValid = new RegExp(
    /^[0-7]{1}\d{6}(0{2}[1-9]{1}|0{1}[1-9]{1}\d{1}|100|120|121|888|999)\d{1}$/i
  ).test(PIVA);

  return isValid;
};

// Validation Email
export const validationEmail = (Email: string) => {
  let isValid = new RegExp(
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
  ).test(Email);

  return isValid;
};

// Validation phone
export const validationPhone = (Phone: string) => {
  let isValid = new RegExp(
    // /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i
    /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
  ).test(Phone);

  return isValid;
};

// Validation strong password
export const validationPassword = (passwordValue: string) => {
  let message: string = "";

  if (passwordValue === undefined || passwordValue === null) {
    return message;
  }

  const strengthChecks = {
    hasLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  };

  strengthChecks.hasLength = passwordValue.length >= 8 ? true : false;
  strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
  strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
  strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
  strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

  let verifiedList = Object.values(strengthChecks).filter((value) => value);

  if (verifiedList.length === 0) message = "";
  else if (!strengthChecks.hasLength)
    message = i18next.t("form:validationPasswordLength");
  else if (!strengthChecks.hasUpperCase)
    message = i18next.t("form:validationPasswordUppercase");
  else if (!strengthChecks.hasLowerCase)
    message = i18next.t("form:validationPasswordLowercase");
  else if (!strengthChecks.hasDigit)
    message = i18next.t("form:validationPasswordDigit");
  else if (!strengthChecks.hasSpecialChar)
    message = i18next.t("form:validationPasswordSpecialChar");

  return message;
};

export const validationErrorMessages = (payload: any) => {
  if (payload) {
    let errors = payload.errors;
    if (errors) {
      let errorsKeys = Object.keys(errors);
      let errorMessage = [];
      if (errorsKeys.length > 0) {
        for (let i = 0; i < errorsKeys.length; i++) {
          errorMessage.push({ message: errors[errorsKeys[i]][0] });
        }
        return errorMessage.map((x: any) => {
          return x.message;
        });
      }
    }
    if (payload.title) {
      return [payload.title];
    }
  }

  return [];
};

export const hasWhiteSpace = (value: string) => {
  return /\s/g.test(value);
};
