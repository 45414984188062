/* REACT */
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* MODELS */
import { IUser } from "../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../Reducers/RootReducer";

/* UTILS */
import { userRoleFits } from "../../Utils/Session";

/* STYLE */
import "./AccountAvatar.scss";

/* COMPONENTS */
import { SummonModal } from "../SmartModal/SmartModal";
import { dateIsPast } from "../MultiForm/SpecialInputs/StrongDatePicker";

/* MUI */
import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
// Icons
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

/* COMPONENT */
const AccountAvatar = () => {
  const menuVoicesExpired: boolean | undefined = useSelector(
    (state: GlobalState) => state.user.menuPagesExpired
  );

  /* REDUX */
  // USER
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );

  var loggedUser: IUser | undefined = loggedUserNorm;
  var impIsActive: boolean = false;

  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
      impIsActive = true;
    }
  }
  // AVATAR
  const avatar: string | undefined = useSelector(
    (state: GlobalState) => state.user.avatar
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /* FUNCTIONS */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* STYLE */
  // Avatar badge
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  /* RETURN */
  return (
    <>
      {loggedUser && (
        <div style={{ position: "relative" }}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar sx={{ width: 34, height: 34 }} src={avatar}>
                {!loggedUser.accountImageURL &&
                  loggedUser.accountName &&
                  loggedUser.accountSurname && (
                    <div>
                      <span>{loggedUser.accountName[0].toUpperCase()}</span>
                      <span>{loggedUser.accountSurname[0].toUpperCase()}</span>
                    </div>
                  )}
              </Avatar>
            </StyledBadge>
          </IconButton>

          <Menu
            id="account-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                width: "300px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className="logged-user-name">
              <span>{loggedUser ? loggedUser.roleName : "-"}</span>
              <div>{loggedUser ? loggedUser.accountEmail : "-"}</div>
            </div>

            <Divider />

            {!menuVoicesExpired && (
              <div>
                <MenuItem
                  onClick={() => {
                    navigate(
                      AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___USERPAGE_ROUTE
                    );
                  }}
                >
                  <Button
                    startIcon={<AccountCircleIcon />}
                    className="backgroundTransparent"
                  >
                    {i18next.t("generic:profile")}
                  </Button>
                </MenuItem>

                {(impIsActive ||
                  userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) && (
                  <MenuItem
                    onClick={() => {
                      SummonModal("ImpersonateModal");
                    }}
                  >
                    <Button startIcon={<PeopleIcon />} className="backgroundTransparent">
                      {impIsActive
                        ? i18next.t("navigation:disable_imp_btn")
                        : i18next.t("navigation:impersonate")}
                    </Button>
                  </MenuItem>
                )}
              </div>
            )}

            {window.location.pathname !== AppRoutes.LOGIN_ROUTE && loggedUser && (
              <MenuItem
                onClick={() => {
                  navigate(loggedUser ? AppRoutes.LOGOUT_ROUTE : AppRoutes.LOGIN_ROUTE);
                }}
              >
                <Button
                  startIcon={loggedUser ? <LogoutIcon /> : <PersonIcon />}
                  className="backgroundTransparent"
                >
                  {i18next.t("navigation:logout")}
                </Button>
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </>
  );
};

export default AccountAvatar;
