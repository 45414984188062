import {MarkdownContent} from "../MarkdownContent";

const PrivacyPolicy = () => {
    let lang = localStorage.getItem("language");

    return (
        <>
            <a href={lang === 'ita' ? "privacy-policy_ITA.pdf" : "privacy-policy_ENG.pdf"} download>
                <button style={{padding: "8px", border: "1px solid black", borderRadius: "5px"}}>Download PDF</button>
            </a>
            <MarkdownContent filename={'PrivacyPolicy.md'}/>
        </>
    )
};

export default PrivacyPolicy;