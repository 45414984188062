import Suppliers__PurchasesPage_single from "../DashboardPage/DashBoard_Pages/ServicesPage/Suppliers__PurchasesPage_single/Suppliers__PurchasesPage_single";

const ExtraShopPage = () => {
  return (
    <div>
      <Suppliers__PurchasesPage_single />
    </div>
  );
};

export default ExtraShopPage;
