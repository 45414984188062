/* REACT */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MODELS */
import { ICompanyGet } from "../../../../../Models/ICompany";
import {
  IOrderGetSide,
  IOrderGetSideDetail,
} from "../../../../../Models/IOrder";
import { IUser } from "../../../../../Models/IUser";
import CarRepairIcon from "@mui/icons-material/CarRepair";
/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Orders__VehiclesPage.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import FormOrders from "../../../../../Components/StepOrders/FormOders";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { renderBrandImageGrid } from "../Status__VehiclesPage/Status__VehiclesPage_Data";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { ResumeModal } from "../VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { userRoleFits } from "../../../../../Utils/Session";
import { VehiclesActions } from "../../../../../Reducers/Vehicle/VehicleAction";

/* COMPONENT */
const Orders__VehiclesPage = () => {
  const dispatch = useDispatch();
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [loadingList, setLoadingList] = useState<boolean>(false);

  // Container for list
  const [list, setList] = useState<IOrderGetSide[]>([]);

  // Set current order
  const [currentOrder, setCurrentOrder] = useState<any[]>([]);
  const [currentOrderPreview, setCurrentOrderPreview] = useState<any>();

  const [convert, setConvert] = useState<IOrderGetSide | undefined>();

  /* APIs */
  // API to load order list
  const loadList = () => {
    if (loggedUser) {
      setLoadingList(true);
      ApiService.OrderController.OrderGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingList(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    // Return
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            setCurrentOrderPreview(props.row);
            SummonModal("order-details-modal");
          }}
        >
          {props.value}
        </Link>
      </div>
    );
  };

  const renderButton = (props: GridRenderCellParams) => {
    const order: IOrderGetSide | undefined = list.find(
      (x: IOrderGetSide) => x.info.vehicleOrderID === props.row.vehicleOrderID
    );

    let isComplete: boolean =
      order?.details.filter((x: IOrderGetSideDetail) => x.vehicleID === null)
        .length === 0;

    return (
      <Button
        disabled={isComplete}
        startIcon={<CarRepairIcon />}
        onClick={() => {
          setConvert(order);
          dispatch(VehiclesActions.setVehicleMctc(undefined));
          SummonModal("ToVehicleModal");
        }}
        variant="contained"
      >
        {i18next.t("navigation:toVehicle")}
      </Button>
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadList();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "brandImage",
      headerName: i18next.t("form:service_logo"),
      renderCell: renderBrandImageGrid,
      type: 'custom'
    },
    {
      field: "reference",
      headerName: i18next.t("form:orderNumber"),
      renderCell: renderLink,
    },
  ];

  if(!userRoleFits(["Driver"])){
    columnsDef.push({
      field: "conversion",
      headerName: "Converti",
      renderCell: renderButton,
    });
  }

  columnsDef.push(
    {
      type: 'custom',
      field: "orderType",
      headerName: i18next.t("form:orderType"),
    },
    {
      field: "brand",
      headerName: i18next.t("form:orderBrand"),
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "fitting",
      headerName: i18next.t("form:orderEquipment"),
    },
    {
      field: "driverNameSurname",
      headerName: i18next.t("form:assignDriverName"),
      renderCell: (props: GridRenderCellParams) => {
        return (
          <div>
            {props.value.map((x: IOrderGetSideDetail, i: number) => {
              return (
                <div key={i}>
                  {x.driverName}
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      field: "contract",
      headerName: i18next.t("navigation:contractTypeName"),
    },
    {
      field: "date",
      headerName: i18next.t("navigation:arrivalDate"),
      fieldOriginal: "dateOriginal",
    },
  );

  /* ROWS */
  const rowsDef: any[] = [];

  for (let i = 0; i < list.length; i++) {
    const currentValueInfo: any = list[i].info;

    rowsDef.push({
      id: i,
      list: list[i],
      conversion: "-",
      vehicleOrderID: currentValueInfo.vehicleOrderID,
      supplier: currentValueInfo.supplierName,
      orderType: list[i].details.length > 1 ? i18next.t("form:orderTypeMultiple") : i18next.t("form:orderTypeSingle"),
      reference: currentValueInfo.vehicleOrderNumber.toString(),
      brandImage: currentValueInfo.brandName,
      brand: currentValueInfo.brandName,
      model: currentValueInfo.modelName,
      fitting: currentValueInfo.fittingName,
      contract: currentValueInfo.contractTypeName || "-",
      date:
        list[i].details.length > 0
          ? displayUTC0_ISODate(list[i].details[0].arrivalDT, true)
          : "",
      dateOriginal: list[i].details[0].arrivalDT,
      driverNameSurname: list[i].details,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_vehicles_orders")} />

      <div className="dashboardForm__box">
        {!userRoleFits(["Driver"]) && (
          <div className="display-flex-end margin-bottom-small">
            <ButtonGroup variant="outlined">
              <Button
                onClick={() => {
                  SummonModal("order-page-modal");
                }}
              >
                {i18next.t("navigation:_add_order")}
              </Button>
            </ButtonGroup>
          </div>
        )}

        <SmartModal
          modalUniqueId="ToVehicleModal"
          title={i18next.t("navigation:toVehicleTitle")}
          modalInnerComponent={
            <div className="modal-width-medium">
              <div style={{ opacity: "0.7" }}>
                {"#" + (convert?.info.vehicleOrderNumber ?? "-")}
              </div>

              <div
                style={{
                  margin: "1em 0",
                }}
              >
                {(convert?.info.supplierName ?? "-") +
                  ", " +
                  (convert?.info.brandName ?? "-") +
                  " " +
                  (convert?.info.modelName ?? "-") +
                  " " +
                  (convert?.info.fittingName ?? "-")}
              </div>

              <hr />

              <div>
                {convert?.details.map((x: IOrderGetSideDetail, i: number) => {
                  return (
                    <div key={i} className="order-convert-row">
                      <div className="order-conver-voice">{i + 1}</div>
                      <div className="order-conver-voice">
                        <strong>
                          {i18next.t("navigation:_vehicle_page_vehicle_driver")}
                        </strong>
                        <div>{x.driverName}</div>
                      </div>
                      <div className="order-conver-voice">
                        <strong>{i18next.t("form:nameCompany")}</strong>
                        <div>{x.companyBusinessName}</div>
                      </div>

                      <div className="order-conver-voice">
                        <strong>{i18next.t("form:orderReference")}</strong>
                        <div>{x.reference}</div>
                      </div>

                      <div className="order-conver-voice">
                        <Button
                          disabled={x.vehicleID !== null}
                          onClick={() => {
                            dispatch(VehiclesActions.setVehicleMctc(undefined));
                            localStorage.setItem(
                              "order-transform",
                              JSON.stringify({
                                order: convert,
                                detailIndex: i,
                              })
                            );
                            navigate(
                              AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__CREATE
                            );
                          }}
                          startIcon={<CarRepairIcon />}
                          variant="contained"
                        >
                          {i18next.t("navigation:toVehicle")}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        />

        <Box className="dashboardForm__data">
          <DataGridWrap
            tableName={i18next.t("navigation:_vehicles_orders") ?? ""}
            headers={columnsDef}
            rows={rowsDef}
          />
        </Box>
      </div>
      <SmartModal
        onReject={() => {
          DismissModal("order-details-modal");
        }}
        title={i18next.t("navigation:order-details")}
        modalUniqueId="order-details-modal"
        modalInnerComponent={
          <div>
            {currentOrderPreview && (
              <div className="order-preview-wrap-zone">
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("form:orderType")}</strong>
                  <div>
                    {currentOrderPreview.list.info.vehicleOrderTypeName}
                  </div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("navigation:contractTypeName")}</strong>
                  <div>{currentOrderPreview.list.info.contractTypeName}</div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("form:orderNumber")}</strong>
                  <div>{currentOrderPreview.list.info.vehicleOrderNumber}</div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("navigation:arrivalDate")}</strong>
                  <div>{currentOrderPreview.date}</div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("form:vehicleSupplier")}</strong>
                  <div>{currentOrderPreview.list.info.supplierName}</div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("form:orderBrand")}</strong>
                  <div>{currentOrderPreview.list.info.brandName}</div>
                </div>
                <div className="order-preview-wrap-zone-block">
                  <strong>{i18next.t("form:orderModel")}</strong>
                  <div>{currentOrderPreview.list.info.modelName}</div>
                </div>

                <div className="order-preview-wrap-zone-block-long">
                  <strong>{i18next.t("form:orderEquipment")}</strong>
                  <div>{currentOrderPreview.list.info.fittingName}</div>
                </div>
                <div>
                  <h4>{i18next.t("generic:titleSideListVehicles")}</h4>
                  {currentOrderPreview.list.details.map((x: any, i: number) => {
                    return (
                      <div key={i}>
                        <div className="order-preview-wrap-zone-block-short">
                          <strong>
                            {i18next.t(
                              "navigation:_vehicle_page_vehicle_driver"
                            )}
                          </strong>
                          <div>{x.driverName}</div> <div>{x.driverSurname}</div>
                        </div>
                        <div className="order-preview-wrap-zone-block-short">
                          <strong>{i18next.t("navigation:arrivalDate")}</strong>
                          <div>{displayUTC0_ISODate(x.arrivalDT, true)}</div>
                        </div>

                        <div className="order-preview-wrap-zone-block-short">
                          <strong>{i18next.t("form:nameCompany")}</strong>
                          <div>{x.companyBusinessName}</div>
                        </div>
                        <div className="align-better-button">
                          <Button
                            disabled={x.vehicleID === null}
                            variant="contained"
                            onClick={() => {
                              if (x.vehicleID) {
                                navigate(
                                  AppRoutes.INTERNAL_DASHBOARD +
                                    AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__ORDERS_TAB +
                                    "?vehicleID=" +
                                    x.vehicleID
                                );
                              }
                            }}
                          >
                            {i18next.t("navigation:seeVehicle")}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        }
      />

      <SmartModal
        hideButtons={true}
        modalUniqueId="order-page-modal"
        title={i18next.t("generic:titleAddOrder")}
        modalInnerComponent={
          <FormOrders
            loggedUser={loggedUser}
            data={currentOrder || []}
            modalId={"order-page-modal"}
            doRefreshForm={() => {
              loadList();
            }}
            companyUser={companyUser}
          />
        }
      />

      <LoaderBackdrop loading={loadingList} />
    </div>
  );
};

export default Orders__VehiclesPage;
