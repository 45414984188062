/* API ENDPOINTS */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* MODELS */
import { IUPDATE_Navigation_Menu } from "../../Models/IMenu";

/* CONTROLLER */
const MenuController = {
  // Get navigation menu roles
  GET_Navigation_Menu_Roles: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_NAVIGATION_MENU_ROLES;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get navigation menu
  GET_Navigation_Menu: async (
    role: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_NAVIGATION_MENU;
    let parameters: string[] = [];
    if (role) {
      parameters.push(`role=${role}`);
    }
    if (role) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update navigation menu
  UPDATE_Navigation_Menu: async (
    body: IUPDATE_Navigation_Menu,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_NAVIGATION_MENU;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default MenuController;
