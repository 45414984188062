import { useNavigate } from "react-router-dom";
import "./HomePage.scss";
import i18next from "i18next";
import AppRoutes from "../../Costants/AppRoutes";
import BannerVideoComponent, {
  FFButtonContainer,
  FFButtonOutlined,
} from "../../Components/BannerVideoComponent/BannerVideoComponent";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";
import { Trans } from "react-i18next";
import BannerTextComponent from "../../Components/BannerTextComponent/BannerTextComponent";
import BannerCarouselComponent from "../../Components/BannerCarouselComponent/BannerCarouselComponent";
import HomePage__Accordion from "./HomePage__Accordion";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const companyLogoImg = [
  { src: "agos.png", alt: "Logo Agos" },
  { src: "carandfun.png", alt: "Logo CarAndFun" },
  { src: "coca_cola.png", alt: "Logo Coca Cola" },
  { src: "Credit_Suisse.png", alt: "Logo Credit Suisse" },
  { src: "dompe.png", alt: "Logo Dompe" },
  { src: "edison.png", alt: "Logo Edison" },
  { src: "fastweb.png", alt: "Logo Fastweb" },
  { src: "getinge.png", alt: "Logo Getinge" },
  { src: "aon.png", alt: "Logo Aon" },
  { src: "b3.png", alt: "Logo B3" },
  { src: "sobi.png", alt: "Logo Sobi" },
  { src: "txt.png", alt: "Logo TXT" },
  { src: "wolterskluwer.png", alt: "Logo Wolterskluwer" },
  { src: "wurth.png", alt: "Logo Wurth" },
];

const HomePage = () => {
  const navigate = useNavigate();
  const { ref: carsRef, inView: carsInView } = useInView({ triggerOnce: true });
  const { ref: clientsRef, inView: clientsInView } = useInView({ triggerOnce: true });
  const { ref: employeesRef, inView: employeesInView } = useInView({ triggerOnce: true });

  const handleScrollToSection = () => {
    window.location.href = window.location.pathname + '#whoweare'
  };

  return (
    <>
      <BannerVideoComponent
        filename="video-homepage-720p.mp4"
        title={<Trans i18nKey="navigation:homeBigTitle" components={[<strong />, <br />]} />}
        withButtons
        onScrollToSection={handleScrollToSection}
      />

      <BannerCardComponent
        id="whoweare"
        leftTitle={<Trans i18nKey="intro:menuWhoWeAre" />}
        leftContent={
          <div className="ff-text-box left">
            <p>{<Trans i18nKey="intro:WhoWeAreText" components={[<strong />]} />}</p>
            <p>{<Trans i18nKey="intro:WhoWeAreLast" components={[<strong />]} />}</p>
            <FFButtonContainer
              onClick={() => {
                navigate(AppRoutes.WHO_WE_ARE_ROUTE);
              }}
            >
              {<Trans i18nKey="intro:discoverCompany" />}
            </FFButtonContainer>
          </div>
        }
        rightContent={
          <div className="ff-image-box ff-img-right">
            <img src={"/Images/newImages/img_finger.jpg"} alt="" loading="lazy" />
          </div>
        }
      />

      <BannerTextComponent
        imgPath={"/Images/newImages/168030344.jpeg"}
        textTitle={<Trans i18nKey="intro:homeBannerTitle" components={[<strong />, <br />]} />}
        textContent={
          <div>
            <div className="home-middle-wide-banner-row">
              <div className="home-middle-wide-banner-block">
                <div className="home-middle-wide-banner-block-number" ref={carsRef}>
                  {carsInView ? <CountUp end={800000} duration={3} /> : "800,000"}
                </div>
                <div className="home-middle-wide-banner-block-label">{i18next.t("intro:homeBannerCars")}</div>
              </div>
              <div className="home-middle-wide-banner-block">
                <div className="home-middle-wide-banner-block-number" ref={clientsRef}>
                  {clientsInView ? <CountUp end={510} duration={3} /> : "510"}
                </div>
                <div className="home-middle-wide-banner-block-label">{i18next.t("intro:homeBannerClients")}</div>
              </div>
              <div className="home-middle-wide-banner-block">
                <div className="home-middle-wide-banner-block-number" ref={employeesRef}>
                  {employeesInView ? <CountUp end={180} duration={3} /> : "180"}
                </div>
                <div className="home-middle-wide-banner-block-label">{i18next.t("intro:homeBannerEmployees")}</div>
              </div>
            </div>
            <FFButtonOutlined
              onClick={() => {
                navigate(AppRoutes.CONTACTS_ROUTE);
              }}
              className="ff-section-number-btn"
            >
              {<Trans i18nKey="intro:homeContactUs" />}
            </FFButtonOutlined>
          </div>
        }
      />

      <BannerCardComponent
        rightTitle={<Trans i18nKey="intro:howItWorksTitle" />}
        leftContent={
          <div className="ff-image-box ff-img-left">
            <img src={"/Images/newImages/777919578.jpeg"} alt="" loading="lazy" />
          </div>
        }
        rightContent={
          <div className="ff-text-box right ff-discover-section">
            <h3>{<Trans i18nKey="intro:howItWorksIntro" components={[<strong />]} />}</h3>
            <p>{<Trans i18nKey="intro:howItWorksText" components={[<strong />]} />}</p>
            <p>{<Trans i18nKey="intro:howItWorksLastText" components={[<strong />]} />}</p>
            <div className="ff-button-container">
              <FFButtonContainer
                onClick={() => {
                  navigate(AppRoutes.FUNCITIONALITIES_ROUTE);
                }}
              >
                {<Trans i18nKey="navigation:readMore" />}
              </FFButtonContainer>
              <FFButtonOutlined
                onClick={() => {
                  navigate(AppRoutes.RATES_ROUTE);
                }}
              >
                {<Trans i18nKey="navigation:ratesAndServices" />}
              </FFButtonOutlined>
            </div>
          </div>
        }
      />

      <BannerTextComponent
        imgPath={"/Images/newImages/CTA_cropped.png"}
        textTitle={<Trans i18nKey="navigation:homeContactTitle" components={[<strong />, <br />]} />}
        withButtons
        textButtons={<Trans i18nKey="intro:freeDay" />}
      />

      <BannerCardComponent
        leftTitle={<Trans i18nKey="navigation:faqHome" />}
        leftContent={
          <div className="ff-accordion-section-wrapper">
            <HomePage__Accordion />
            <FFButtonOutlined
              onClick={() => {
                navigate(AppRoutes.WHYUS_ROUTE);
              }}
              className="ff-btn-faq"
            >
              {<Trans i18nKey="navigation:readMoreFaq" />}
            </FFButtonOutlined>
          </div>
        }
        rightContent={
          <div className="ff-image-box ff-img-right">
            <img src={"/Images/newImages/443055016.jpeg"} alt="" loading="lazy" />
          </div>
        }
      />

      <BannerCarouselComponent
        title={<Trans i18nKey="intro:mainCustomers" components={[<strong />]} />}
        images={companyLogoImg}
      />
    </>
  );
};

export default HomePage;
