import ApiEndPoints from "../../Costants/ApiEndPoints";
import { ICompanyBody } from "../../Models/ICompany";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const CompanyController = {
  CompanyGetData: async (
    CompanyID: number,
    CompanyParentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.COMPANY_GET_DATA;
    let parameters: string[] = [];
    if (CompanyID) {
      parameters.push(`CompanyID=${CompanyID}`);
    }
    if (CompanyParentID) {
      parameters.push(`CompanyParentID=${CompanyParentID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetAccountCompany: async (
    companyID: number,
    accountID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_ACCOUNT_COMPANY;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (accountID) {
      parameters.push(`accountID=${accountID}`);
    }
    if(parameters.length > 0){
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CompanySetData: async (
    body: ICompanyBody,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.COMPANY_SET_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CompanyUpdateData: async (
    body: ICompanyBody,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.COMPANY_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CompanyDeleteData: async (
    companyID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.COMPANY_DELETE_DATA;
    request.method = "DELETE";
    request.payload = {
      companyID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default CompanyController;
