import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const UploadController = {
  GetSASString: async (
    collection: string,
    blobId: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url =
      ApiEndPoints.BLOB_GET_SAS +
      `?containerName=${collection}&blobId=${blobId}`;
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteFile: async (
    collection: string,
    blobId: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url =
      ApiEndPoints.BLOB_DELETE +
      `?containerName=${collection}&blobId=${blobId}`;
    request.method = "DELETE";
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UploadFile: async (
    type: string,
    files: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    let request: IAPIRequest = createEmptyRequest();
    request.method = "post";
    request.port = "7234";
    request.url = ApiEndPoints.BLOB_UPLOAD + `?containerName=${type}`;
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UploadFileList: async (
    type: string,
    files: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    let request: IAPIRequest = createEmptyRequest();
    request.method = "post";
    request.port = "7234";
    request.url = ApiEndPoints.BLOB_UPLOAD_LIST + `?containerName=${type}`;
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default UploadController;
