/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./AccordionContent.scss";

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* INTERFACE */
export interface IAccordionContent {
  title: JSX.Element | string | null;
  subTitle?: string;
  content: JSX.Element;
  icon?: JSX.Element;
  startsOpen?: boolean;
  open?: boolean;
  classNameAccordion?: string;
  classNameSummary?: string;
  classNameDetails?: string;
  middleSummary?: JSX.Element | string;
  rightSummary?: JSX.Element | string;
}

/* COMPONENT */
const AccordionContent = (props: IAccordionContent) => {
  /* STATES */
  const [isOpen, setIsOpen] = useState<boolean>(props.startsOpen === true ? true : false);

  /* USE EFFECT HOOK */
  useEffect(() => {
    if (props.open !== undefined) {
      if (props.open) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [props.open]);

  /* RETURN */
  return (
    <div className="full-width">
      <Accordion
        expanded={isOpen}
        onChange={(_, expanded) => {
          setIsOpen(expanded);
        }}
        className={props.classNameAccordion}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          expandIcon={<ExpandMoreIcon />}
          className={props.classNameSummary}
          sx={{
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
            },
          }}
        >
          {props.icon}

          {props.subTitle ? (
            <>
              <Typography
                className="display-flex-vertical-center"
                sx={props.rightSummary ? { width: "40%" } : { width: "60%" }}
              >
                <div className="accordion-title"> {props.title}</div>

                <div className="accordion-subtitle">{props.subTitle}</div>
              </Typography>

              <Typography
                className="display-flex-vertical-center"
                sx={props.rightSummary ? { width: "35%" } : { width: "40%" }}
              >
                {props.middleSummary}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                className="display-flex-vertical-center"
                sx={props.rightSummary ? { width: "30%" } : { width: "50%" }}
              >
                <div className="accordion-title"> {props.title}</div>
              </Typography>

              <Typography
                className="display-flex-vertical-center"
                sx={props.rightSummary ? { width: "45%" } : { width: "50%" }}
              >
                {props.middleSummary}
              </Typography>
            </>
          )}

          {props.rightSummary && (
            <Typography className="display-flex-vertical-center" sx={{ width: "25%" }}>
              {props.rightSummary}
            </Typography>
          )}
        </AccordionSummary>

        <AccordionDetails className={props.classNameDetails}>
          {props.content}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionContent;
