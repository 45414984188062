import {MarkdownContent} from "../MarkdownContent";

const TermsAndCondtion = () => {
  let lang = localStorage.getItem("language");

  return (
    <>
      <a href={lang === 'ita' ? "T_C_ITA.pdf" : "T_C_ENG.pdf"} download>
        <button style={{padding: "8px", border: "1px solid black", borderRadius: "5px"}}>Download PDF</button>
      </a>
      <MarkdownContent filename={'TermsAndCondition.md'}/>
    </>
  );
};

export default TermsAndCondtion;
