import { Button, Tab, Tabs, Typography } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICompanyGet } from "../../Models/ICompany";
import { IUser } from "../../Models/IUser";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import { ToastMessage } from "../../Utils/Toastify";
import CustomSideList from "../CustomSideList/CustomSideList";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import MultiForm, { requestFormRefresh, triggerFormValidation } from "../MultiForm/MultiForm";
import UploadForm from "../UploadForm/UploadForm";
import "./StepVehicles.scss";
import TabAssignment from "./TabAssignment/TabAssignment";
import TabContract from "./TabContract/TabContract";
import TabFuelCards from "./TabFuelCards/TabFuelCards";
import TabInsurance from "./TabInsurance/TabInsurance";
import TabServices from "./TabServices/TabServices";
import TabTelepass from "./TabTelepass/TabTelepass";
import TabVehicleData from "./TabVehicleData/TabVehicleData";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import AppRoutes from "../../Costants/AppRoutes";
import { RegistrationAction } from "../../Reducers/Registration/RegistrationAction";
import { VehiclesActions } from "../../Reducers/Vehicle/VehicleAction";
import { SummonErrorExcelDialog } from "../ExcelErrorDialog/ExcelErrorDialog";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import { dateIsPast } from "../MultiForm/SpecialInputs/StrongDatePicker";

export interface IStepVehiclesProps {
  nextPage?: number;
  stepId?: number;
}

const StepVehicles = (props: IStepVehiclesProps) => {
  const dispatch = useDispatch();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [stateVehicle, setStateVehicle] = useState<any>(null);
  const [stateAssignment, setStateAssignment] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<string>("tab-manual");
  const [checkCanInsert, setCheckCanInsert] = useState<boolean>(false);
  const [currentSecondaryTab, setCurrentSecondaryTab] = useState<string>(
    "secondary-tab-vehicle"
  );
  const vehicleMctc = useSelector((state: GlobalState) => state.vehicles.vehicleMctc);

  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);
  const [loadingCurrentAssignment, setLoadingCurrentAssignment] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [templateFile, setTemplateFile] = useState<File[]>([]);

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  const handleChangeSecondary = (newValue: string) => {
    setCurrentSecondaryTab(newValue);
  };

  const updateCurrent = (item: any) => {
    setStateVehicle(item);
    dispatch(VehiclesActions.setActiveVehicleForCompany(item));
    if (currentSecondaryTab !== "secondary-tab-assignment") {
      loadCurrentAssignment(item.vehicleID);
    }
    doRefreshForm();
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  useEffect(() => {
    if (vehicleMctc && vehicleMctc.forForm !== undefined) {
      let cleanState: any = {};
      let keys = Object.keys(vehicleMctc.forForm);
      for (let i = 0; i < keys.length; i++) {
        let val: any = vehicleMctc.forForm[keys[i]];
        if (val !== null && val !== undefined) {
          cleanState[keys[i]] = val;
        }
      }
      setStateVehicle({ ...stateVehicle, ...cleanState });
      requestFormRefresh();
    }
  }, [vehicleMctc]);

  const loadCurrentAssignment = (vehicleID: number) => {
    if (loggedUser && vehicleID) {
      setLoadingCurrentAssignment(true);
      ApiService.AssignmentController.AssignmentGet(
        null,
        vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setStateAssignment(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentAssignment(false);
        }
      );
    }
  };

  const checkCanInsertVehicle = () => {
    ApiService.VehicleDataController.CheckUserCanInsertVehicle(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCheckCanInsert(response.payload);
        } else {
          if (response.payload && response.payload.detail) {
            ToastMessage(i18next.t("error:" + response.payload.detail), "error");
          } else {
            ToastMessage(response.error, "error");
          }
        }
      }
    );
  };

  const getVehicles = () => {
    setLoading(true);
    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehiclesList(response.payload);
          updateAccountRegistrationStep(
            loggedUser,
            props.stepId,
            response.payload.length > 0
          );
          if (AppRoutes.CONTINUE_REGISTRATION_ROUTE) {
            dispatch(RegistrationAction.setVehicleSummaryData(response.payload));
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const insertVehicleFromExcel = (files: File[]) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleDataController.VehicleDataInsertFromExcel(
        files,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload.length === 0) {
              ToastMessage(
                i18next.t("message:vehicleFromExcelUploadCorrectly"),
                "success"
              );
            } else {
              SummonErrorExcelDialog(response.payload);
            }
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("message" + response.payload.title), "error");
            } else if (response.payload.status === 500) {
              ToastMessage(response.payload.detail, "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setTemplateFile([]);
          setProcessing(false);
        }
      );
    }
  };

  const updateFile = (files: File[]) => {
    setTemplateFile(files);
  };

  useEffect(() => {
    checkCanInsertVehicle();
  }, [vehiclesList]);

  useEffect(() => {
    if (currentTab === "tab-manual") {
      getVehicles();
    }
    if (props.nextPage) {
      updateAccountRegistrationStep(loggedUser, props.stepId, false);
    }
  }, [currentTab]);

  useEffect(() => {
    dispatch(VehiclesActions.setVehicleMctc(undefined));
  }, []);

  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleVehicles") ?? "",
          }}
        />
        <h2
          className="custom-stepper-text"
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:subtitleVehicles") ?? "",
          }}
        />
        <Tabs
          value={currentTab}
          aria-label="basic tabs example"
          className="tabs-registration-wrapper"
        >
          <Tab
            value="tab-manual"
            onClick={() => handleChange("tab-manual")}
            label={i18next.t("generic:uploadManual")}
            id="tab-manual"
            aria-controls="tab-manual"
          />
          <Tab
            value="tab-template"
            onClick={() => handleChange("tab-template")}
            label={i18next.t("generic:uploadFromTemplate")}
            id="tab-template"
            aria-controls="tab-template"
          />
        </Tabs>
        <div
          role="tabpanel"
          hidden={currentTab !== "tab-template"}
          id="tab-template"
          aria-labelledby="tab-template"
        >
          <ol>
            <li>{i18next.t("generic:guidelineUploadTemplate1")}</li>
            <div style={{display: "flex", flexDirection: "row", gap: "2em"}}>
              <div className="wrapper-download-template">
                <div className="padding-small">VehicleTemplate_Italiano.xlsx</div>
                <div>
                  <a
                    title="Download VehicleTemplate_Italiano.xlsx"
                    href={
                      process.env.PUBLIC_URL + "templates/VehicleTemplate_Italiano.xlsx"
                    }
                  >
                    <Button>
                      <DownloadIcon />
                    </Button>
                  </a>
                </div>
              </div>
              <div className="wrapper-download-template">
                <div className="padding-small">VehicleTemplate_English.xlsx</div>
                <div>
                  <a
                    title="Download VehicleTemplate_English.xlsx"
                    href={
                      process.env.PUBLIC_URL + "templates/VehicleTemplate_English.xlsx"
                    }
                  >
                    <Button>
                      <DownloadIcon />
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <li>{i18next.t("generic:guidelineUploadTemplate2")}</li>
          </ol>
          <MultiForm
            formId={"StepVehicleMultipleUpload"}
            suppressLayout
            formTitle={""}
            inputs={[
              {
                label: i18next.t("form:titleTemplateUpload") as string,
                width: 100,
                type: "custom",
                name: "templateXSL",
                required: true,
                element: (
                  <UploadForm
                    title={i18next.t("form:titleTemplateUpload")}
                    description={i18next.t("form:subtitleTemplateUpload")}
                    accept={".xlsx"}
                    initialFiles={templateFile.map((x: File) => x.name)}
                    onFileChanged={updateFile}
                  ></UploadForm>
                ),
              },
            ]}
            suppressSubmit={true}
            onSubmit={() => {
              insertVehicleFromExcel(templateFile);
              setTemplateFile([]);
            }}
          />
          <div style={{ margin: "20px 7.5px" }}>
            <div
              className="multi-form-input-wrap"
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={templateFile.length <= 0}
                onClick={() => {
                  triggerFormValidation("StepVehicleMultipleUpload");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:uploadSubmit")}
              </Button>
            </div>
          </div>
        </div>
        {currentTab === "tab-manual" && (
          <div
            role="tabpanel"
            hidden={currentTab !== "tab-manual"}
            id="tab-manual"
            aria-labelledby="tab-manual"
          >
            <Tabs
              className="tabs-vehicle-wrapper"
              value={currentSecondaryTab}
              aria-label="basic tabs example"
            >
              <Tab
                value="secondary-tab-vehicle"
                onClick={() => handleChangeSecondary("secondary-tab-vehicle")}
                label={i18next.t("navigation:vehicleTabTitle")}
                id="secondary-tab-vehicle"
                aria-controls="secondary-tab-vehicle"
              />
              <Tab
                value="secondary-tab-contract"
                onClick={() => handleChangeSecondary("secondary-tab-contract")}
                label={i18next.t("navigation:contractTabTitle")}
                id="secondary-tab-contract"
                aria-controls="secondary-tab-contract"
              />
              <Tab
                value="secondary-tab-assignment"
                onClick={() => handleChangeSecondary("secondary-tab-assignment")}
                label={i18next.t("navigation:assignmentTabTitle")}
                id="secondary-tab-assignment"
                aria-controls="secondary-tab-assignment"
              />
              <Tab
                value="secondary-tab-fuel-cards"
                onClick={() => handleChangeSecondary("secondary-tab-fuel-cards")}
                label={i18next.t("navigation:fuelCardsTabTitle")}
                id="secondary-tab-fuel-cards"
                aria-controls="secondary-tab-fuel-cards"
              />
              <Tab
                value="secondary-tab-telepass"
                onClick={() => handleChangeSecondary("secondary-tab-telepass")}
                label={i18next.t("navigation:telepassTabTitle")}
                id="secondary-tab-telepass"
                aria-controls="secondary-tab-telepass"
              />
              <Tab
                value="secondary-tab-insurance"
                onClick={() => handleChangeSecondary("secondary-tab-insurance")}
                label={i18next.t("navigation:insuranceTabTitle")}
                id="secondary-tab-insurance"
                aria-controls="secondary-tab-insurance"
              />
              <Tab
                value="secondary-tab-services"
                onClick={() => handleChangeSecondary("secondary-tab-services")}
                label={i18next.t("navigation:servicesTabTitle")}
                id="secondary-tab-services"
                aria-controls="secondary-tab-services"
              />
            </Tabs>
            <div className="container-content-form">
              <div className="container-stepper-form add-border multi-form-background">
                {stateVehicle?.vehicleID && (
                  <Typography padding={2}>
                    {i18next.t("navigation:tab_info_vehicle")} (
                    {stateVehicle?.vehicleLicensePlate?.toUpperCase()} -{" "}
                    {stateVehicle?.vehicleChassis?.toUpperCase()})
                  </Typography>
                )}
                {!refreshForm && (
                  <>
                    <TabVehicleData
                      canInsertVehicles={checkCanInsert}
                      loggedUser={loggedUser}
                      companyUser={companyUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doResetVehicle={() => {
                        setStateVehicle(null);
                        getVehicles();
                      }}
                      doRefreshForm={() => doRefreshForm()}
                    />
                    <TabContract
                      loggedUser={loggedUser}
                      companyUser={companyUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                    />
                    <TabInsurance
                      loggedUser={loggedUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                    />
                    <TabFuelCards
                      loggedUser={loggedUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                      dataAssignment={stateAssignment}
                    />
                    <TabTelepass
                      loggedUser={loggedUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                      dataAssignment={stateAssignment}
                    />
                    <TabServices
                      loggedUser={loggedUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                    />
                    <TabAssignment
                      loggedUser={loggedUser}
                      currentTab={currentSecondaryTab}
                      companyUser={companyUser}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                    />
                  </>
                )}
              </div>
              {!loading && 
                <CustomSideList
                  title={i18next.t("generic:titleSideListVehicles")}
                  updateCurrent={updateCurrent}
                  selected={{
                    value: stateVehicle?.vehicleID,
                    key: "vehicleData.vehicleID",
                  }}
                  sideContent={".container-stepper-form.vehicle-form"}
                  list={vehiclesList}
                  elementTitle={(item: any) => {
                    return formatPlate(item?.vehicleLicensePlate);
                  }}
                />
              }
            </div>
          </div>
        )}
      </div>
      <LoaderBackdrop
        loading={loading || refreshForm || loadingCurrentAssignment || processing}
      />
    </>
  );
};

export default StepVehicles;
