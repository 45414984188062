import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import FuelCardDashboardEditor from "../../../../../../Components/FuelCardDashboardEditor/FuelCardDashboardEditor";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import {
  IFuelCardsGet,
  IFuelCardsGetType,
  IFuelCardsInsert,
} from "../../../../../../Models/IFuelCards";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import "./VehiclesPage_subPages_FuelCards.scss";
import { useNavigate } from "react-router-dom";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import { IDriverGet } from "../../../../../../Models/IDriver";
import { Alert } from "@mui/material";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const VehiclesPage_subPages_FuelCards = () => {
  const navigate = useNavigate();
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const drivers: IDriverGet[] = useSelector(
    (state: GlobalState) => state.vehicles.driversForCompany
  );
  const driver: IDriverGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeDriver
  );

  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingSupplierFuelCards, setLoadingSupplierFuelCards] =
    useState<boolean>(false);
  const [loadingFuelCardsType, setLoadingFuelCardsType] = useState<boolean>(false);
  const [loadingFuelCards, setLoadingFuelCards] = useState<boolean>(false);
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(false);

  const [supplierFuelCards, setSupplierFuelCards] = useState<ISupplierGetSideList[]>([]);

  const [fuelCards, setFuelCards] = useState<IFuelCardsGet[]>([]);
  const [fuelCardsType, setFuelCardsType] = useState<IFuelCardsGetType[]>([]);

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  const loadFuelCards = (vehicleID: number) => {
    if (loggedUser) {
      setLoadingFuelCards(true);
      ApiService.FuelCardsController.FuelCardsGet(
        null,
        vehicleID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelCards(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelCards(false);
        }
      );
    }
  };

  const fuelCardsInsert = (data: IFuelCardsInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullAdded"), "success");
          loadFuelCards(data.vehicleID);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsUpdate = (data: IFuelCardsInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsDelete = (fuelCardID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsDelete(
        fuelCardID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:fuelCardSuccessfullDeleted"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadSupplierFuelCards = () => {
    if (loggedUser) {
      setLoadingSupplierFuelCards(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "FUEL_CARD",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierFuelCards(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierFuelCards(false);
        }
      );
    }
  };

  const loadFuelCardsType = () => {
    if (loggedUser) {
      setLoadingFuelCardsType(true);
      ApiService.FuelCardsController.FuelCardsGetType((response: IAPIResponse) => {
        if (response.error === null) {
          setFuelCardsType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFuelCardsType(false);
      });
    }
  };

  useEffect(() => {
    if (vehicle?.vehicleID) {
      loadFuelCards(vehicle?.vehicleID);
    }
  }, [vehicle]);

  useEffect(() => {
    loadFuelCardsType();
    loadSupplierFuelCards();
  }, []);

  useEffect(() => {
    doRefreshForm();
  }, [driver]);

  const apiLoading =
    loadingFuelCardsType ||
    loadingDrivers ||
    loadingFuelCards ||
    refreshForm ||
    loadingSupplierFuelCards;

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:fuelCardsTabTitle")}
        rightButtons={[
          {
            disabled: disposed > 0,
            label: i18next.t(
              "navigation:_vehicle_page_fuel_card_toolbar_button_add_card"
            ),
            onClick: () => {
              if (loggedUser && vehicle) {
                SummonModal("AddCardOnDashboard");
              }
            },
          },
          {
            label: i18next.t(
              "navigation:_vehicle_page_fuel_card_toolbar_button_fuel_history"
            ),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
          // {
          //   disabled: disposed > 0,
          //   label: i18next.t(
          //     "navigation:_vehicle_page_fuel_card_toolbar_button_furniture"
          //   ),
          //   onClick: () => {},
          // },
        ]}
      />
      <div>
        {!apiLoading &&
          fuelCards.filter((x: IFuelCardsGet) => x.stored === false).length === 0 && (
            <div style={{ marginTop: "1em" }}>
              <Alert severity="warning">{i18next.t("error:nocardsonvehicle")}</Alert>
            </div>
          )}
        {!apiLoading &&
          fuelCards
            .filter(
              (x: IFuelCardsGet) =>
                x.driverID === driver?.driverInfo.driverID ||
                driver?.driverInfo.driverID === -1
            )
            .filter((x: IFuelCardsGet) => x.stored === false)
            .map((x: IFuelCardsGet, i: number) => {
              return (
                <div key={i} className="fuel-card-editor-wrap">
                  <FuelCardDashboardEditor
                    drivers={drivers}
                    disabled={disposed > 0}
                    reloadSuppliers={() => {
                      loadSupplierFuelCards();
                    }}
                    requestReload={() => {
                      if (vehicle) {
                        loadFuelCards(vehicle?.vehicleID);
                      }
                    }}
                    formUniqueIndex={"_" + i.toString()}
                    fuelCardTypeList={fuelCardsType}
                    supplierList={supplierFuelCards}
                    supplier={supplierFuelCards.find(
                      (y: ISupplierGetSideList) =>
                        y.supplierInfo.supplierID === x.fuelCardID
                    )}
                    fuelCardType={fuelCardsType.find(
                      (y: IFuelCardsGetType) => y.fuelCardTypeID === x.fuelCardTypeID
                    )}
                    fuelCard={x}
                  />
                </div>
              );
            })}
      </div>
      <ResumeModal
        title={i18next.t(
          "navigation:_vehicle_page_fuel_card_toolbar_button_fuel_history"
        )}
        rows={fuelCards
          .filter((x: IFuelCardsGet) => x.stored)
          .map((x: IFuelCardsGet) => {
            let supplier: ISupplierGetSideList | undefined = supplierFuelCards.find(
              (y: ISupplierGetSideList) => y.supplierInfo.supplierID === x.supplierID
            );
            let type: IFuelCardsGetType | undefined = fuelCardsType.find(
              (y: IFuelCardsGetType) => y.fuelCardTypeID === x.fuelCardTypeID
            );
            let driver: IDriverGet | undefined = drivers.find(
              (y: IDriverGet) => y.driverInfo.driverID === x.driverID
            );
            return {
              supplierName: supplier ? supplier.supplierInfo.supplierName : "-",
              driverName: driver
                ? driver.driverInfo.driverName + " " + driver.driverInfo.driverSurname
                : "-",
              fuelCardTypeName: type ? type.fuelCardTypeName : "-",
              fuelCardNumber: x.fuelCardNumber,
              fuelCardPIN: x.fuelCardPIN,
              fuelCardExpirationDT: displayUTC0_ISODate(x.fuelCardExpirationDT, true),
              fuelCardEndAssignementDT: displayUTC0_ISODate(x.fuelCardEndAssignementDT, true),
              fuelCardExpirationDTOriginal: x.fuelCardExpirationDT,
              fuelCardEndAssignementDTOriginal: x.fuelCardEndAssignementDT,
            };
          })}
        headers={[
          {
            field: "supplierName",
            headerName: i18next.t(`form:supplierFuelCard`),
          },
          {
            field: "driverName",
            headerName: i18next.t(`form:assignDriverName`),
          },
          {
            field: "fuelCardTypeName",
            headerName: i18next.t(`form:fuelCardType`),
          },
          {
            field: "fuelCardNumber",
            headerName: i18next.t(`form:numberFuelCard`),
          },
          {
            field: "fuelCardPIN",
            headerName: i18next.t(`form:pinFuelCard`),
          },
          {
            field: "fuelCardExpirationDT",
            headerName: i18next.t(`form:dateExpireFuelCard`),
            fieldOriginal: "fuelCardExpirationDTOriginal",
          },
          {
            field: "fuelCardEndAssignementDT",
            headerName: i18next.t(`form:endAssignmentFuelCard`),
            fieldOriginal: "fuelCardEndAssignementDTOriginal",
          },
        ]}
      />
      <SmartModal
        modalUniqueId={"AddCardOnDashboard"}
        title={i18next.t("navigation:_vehicle_page_fuel_card_toolbar_button_add_card")}
        modalInnerComponent={
          <div>
            <FuelCardDashboardEditor
              formUniqueIndex={"_new"}
              reloadSuppliers={() => {
                loadSupplierFuelCards();
              }}
              requestReload={() => {
                if (vehicle) {
                  loadFuelCards(vehicle?.vehicleID);
                }
              }}
              fuelCardType={undefined}
              supplier={undefined}
              drivers={drivers}
              fuelCardTypeList={fuelCardsType}
              supplierList={supplierFuelCards}
              fuelCard={{} as IFuelCardsGet}
            />
          </div>
        }
      />
      <LoaderBackdrop loading={apiLoading || processing} />
    </div>
  );
};

export default VehiclesPage_subPages_FuelCards;
