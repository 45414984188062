import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "dayjs/locale/en-gb";
import genericEng from "./Assets/locales/eng/generic.json";
import formEng from "./Assets/locales/eng/form.json";
import errorEng from "./Assets/locales/eng/error.json";
import messageEng from "./Assets/locales/eng/message.json";
import navigationEng from "./Assets/locales/eng/navigation.json";
import countriesEng from "./Assets/locales/eng/countries.json";
import introEng from "./Assets/locales/eng/intro.json";

import genericIta from "./Assets/locales/ita/generic.json";
import formIta from "./Assets/locales/ita/form.json";
import errorIta from "./Assets/locales/ita/error.json";
import messageIta from "./Assets/locales/ita/message.json";
import navigationIta from "./Assets/locales/ita/navigation.json";
import countriesIta from "./Assets/locales/ita/countries.json";
import introIta from "./Assets/locales/ita/intro.json";

const resources = {
  eng: {
    generic: genericEng,
    form: formEng,
    error: errorEng,
    message: messageEng,
    navigation: navigationEng,
    countries: countriesEng,
    intro: introEng,
  },
  ita: {
    generic: genericIta,
    form: formIta,
    error: errorIta,
    message: messageIta,
    navigation: navigationIta,
    countries: countriesIta,
    intro: introIta,
  },
};

export const voices = [
  {
    num: 1,
    key: "ita",
  },
  {
    num: 2,
    key: "eng",
  },
];

export const ns: string[] = Object.keys(resources.eng);
export const supportedLngs: string[] = ["eng", "ita"];

const lang = navigator.language.split("-")[0];
const storedLanguage = localStorage.getItem("language");
const target = storedLanguage ? storedLanguage : lang;
const match = voices.filter((x: { key: string }) => x.key === target);
const language = match.length > 0 ? match[0].key : voices[0].key;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: language ?? "eng",
  ns,
  lng: language ?? "eng",
  supportedLngs,
  interpolation: { escapeValue: false },
  react: { useSuspense: false },
});

export default i18n;
