/* REACT */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "../VehicleGroupPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_CarConfiguratorGroup_Close,
  IINSERT_CarConfiguratorGroup_Close_Base,
  IUPDATE_CarConfiguratorGroup_Close_Base,
  IUPDATE_CarConfiguratorGroup_Disable,
} from "../../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import FormCloseGroup from "./FormCloseGroup";
import { renderBrandImageGrid } from "../../../VehiclesPage/Status__VehiclesPage/Status__VehiclesPage_Data";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import TabsAddOptionals from "./TabsAddOptionals/TabsAddOptionals";

/* MUI */
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

/* INTERFACE */
export interface ICloseGroupGrid {
  data: IGET_CarConfiguratorGroup_Close;
  groupID: number | null;
  onSubmit: () => void;
}

/* COMPONENT */
const CloseGroupGrid = (props: ICloseGroupGrid) => {
  /* STATES */
  // Form
  const [formData, setFormData] = useState<any>(props.data || []);
  // Table grid
  const [list] = useState<any>(props.data.groupVehicles || []);
  // Current group
  const [currentGroup, setCurrentGroup] = useState<any[]>([]);
  // Current vehicle
  const [currentVehicle, setCurrentVehicle] = useState<number>(-1);
  // Loading
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingUpdateDisable, setLoadingUpdateDisable] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Insert close group base info
  const INSERT_CarConfiguratorGroup_Close_Base = (
    data: IINSERT_CarConfiguratorGroup_Close_Base
  ) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_CarConfiguratorGroup_Close_Base(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");

          const groupIDResponse = response.payload;

          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE +
              "?groupID=" +
              groupIDResponse
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  // Update close group base info
  const UPDATE_CarConfiguratorGroup_Close_Base = (
    data: IUPDATE_CarConfiguratorGroup_Close_Base
  ) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Close_Base(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  // Delete the group
  const DELETE_CarConfiguratorGroup = (groupID: number) => {
    setLoadingDelete(true);

    ApiService.CarConfiguratorController.DELETE_CarConfiguratorGroup(
      groupID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:deleteSuccess"), "success");
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingDelete(false);
      }
    );
  };

  // Disable group
  const UPDATE_CarConfiguratorGroup_Disable = (
    data: IUPDATE_CarConfiguratorGroup_Disable
  ) => {
    setLoadingUpdateDisable(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Disable(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
          navigate(-2);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateDisable(false);
      }
    );
  };

  /* FUNCTIONS */
  // Actions
  const renderActions = (props: GridRenderCellParams) => {
    return (
      <div>
        <Tooltip title={i18next.t("generic:titleUpdateVehicle")}>
          <IconButton>
            <EditIcon
              className="icon-table"
              onClick={() => {
                setCurrentGroup(props.row.list);
                SummonModal("add-vehicle-close-list-modal");
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title={i18next.t("form:selectOptionals")}>
          <IconButton>
            <PlaylistAddIcon
              className="icon-table"
              onClick={() => {
                setCurrentVehicle(props.row.groupVehicleID);
                SummonModal("add-optionals-modal-home");
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      type: 'custom'
    },
    {
      field: "brandImage",
      headerName: i18next.t("form:service_logo"),
      renderCell: renderBrandImageGrid,
    },
    {
      field: "brand",
      headerName: i18next.t("navigation:_vehicle_page_resume_row_3"),
    },
    {
      field: "model",
      headerName: i18next.t("navigation:_vehicle_page_resume_row_4"),
    },
    {
      field: "fitting",
      headerName: i18next.t("navigation:_vehicle_page_resume_row_5"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      list: list[i],
      groupVehicleID: list[i].groupVehicleID,
      groupID: list[i].groupID,
      brandImage: list[i].brandDescription,
      brand: list[i].brandDescription,
      model: list[i].modelDescription,
      fitting: list[i].fittingDescription,
    });
  }

  /* RETURN */
  return (
    <>
      <Box className="dashboardForm__data">
        <div className="display-flex-end">
          {!props.groupID && (
            <Tooltip title={i18next.t("form:createGroupTooltip")}>
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          <Button
            variant="outlined"
            disabled={!props.groupID}
            onClick={() => {
              setCurrentGroup([]);
              SummonModal("add-vehicle-close-list-modal");
            }}
          >
            {i18next.t("navigation:_add_vehicle")}
          </Button>
        </div>

        <MultiForm
          suppressLayout
          suppressSubmit={true}
          formId={"FormCloseListBase"}
          inputs={[
            {
              width: 33.33,
              type: "text",
              name: "groupDescription",
              defaultValue: formData?.groupDescription,
              label: `${i18next.t("form:description")}`,
              required: true,
              inputProps: { maxLength: 100 },
            },
            {
              width: 33.33,
              type: "date",
              name: "startDate",
              defaultValue: formData?.startDate,
              label: i18next.t("form:dateStart") ?? "",
              required: true,
              minDate: new Date().toISOString(),
            },
            {
              width: 33.33,
              type: "date",
              name: "endDate",
              defaultValue: formData?.endDate,
              label: i18next.t("form:dateFinish") ?? "",
              required: true,
              disabled: formData?.startDate ? false : true,
              minDate: formData?.startDate,
            },
          ]}
          onChange={(data: any) => {
            setFormData({ ...formData, ...data });
          }}
          onSubmit={(data: any) => {
            const tempData = lodash.cloneDeep(data);

            // Lunch the API based on the groupID
            // (if present the element already exist so UPDATE, if not INSERT)
            if (props.groupID && props.groupID > 0) {
              tempData["groupID"] = props.groupID;
              UPDATE_CarConfiguratorGroup_Close_Base(tempData);
            } else {
              INSERT_CarConfiguratorGroup_Close_Base(tempData);
            }
          }}
        />

        <DataGridWrap headers={columnsDef} rows={rowsDef} />

        <div className="display-flex-end margin-vertical-medium gap-small">
          {formData && formData.groupID && (
            <Button
              color="error"
              variant="contained"
              endIcon={<DeleteIcon />}
              onClick={() => {
                SummonModal("delete-close-group-modal");
              }}
            >
              {i18next.t("generic:delete")}
            </Button>
          )}

          {formData && formData.groupID && (
            <Button
              color="warning"
              variant="contained"
              endIcon={<ArchiveIcon />}
              onClick={() => {
                UPDATE_CarConfiguratorGroup_Disable({
                  groupID: formData.groupID,
                  enabled: !formData.enabled,
                });
                navigate(-1);
              }}
            >
              {formData.enabled ? i18next.t("form:disable") : i18next.t("form:activate")}
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => {
              triggerFormValidation("FormCloseListBase");
            }}
          >
            {props.groupID ? i18next.t("form:save") : i18next.t("form:createGroup")}
          </Button>
        </div>
      </Box>

      <SmartModal
        modalUniqueId="delete-close-group-modal"
        title={i18next.t("generic:delete")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace("CURRENT_ITEM_LIST", formData?.groupID || ""),
            }}
          />
        }
        onAccept={() => {
          DELETE_CarConfiguratorGroup(formData?.groupID);
          navigate(-1);
        }}
        onReject={() => {}}
      />

      <SmartModal
        hideButtons={true}
        modalUniqueId="add-vehicle-close-list-modal"
        title={i18next.t("navigation:_add_vehicle")}
        modalInnerComponent={
          <FormCloseGroup
            data={currentGroup}
            groupID={props.groupID}
            onSubmit={() => props.onSubmit()}
          />
        }
      />

      <SmartModal
        hideButtons
        modalUniqueId="add-optionals-modal-home"
        title={i18next.t("form:selectOptionals")}
        modalInnerComponent={<TabsAddOptionals groupVehicleID={currentVehicle} />}
      />

      <LoaderBackdrop
        loading={loadingInsert || loadingUpdate || loadingDelete || loadingUpdateDisable}
      />
    </>
  );
};

export default CloseGroupGrid;
