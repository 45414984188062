import { IAssignmentGet } from "../../Models/IAssignment";
import { IDriverGet } from "../../Models/IDriver";
import { IMctcGet } from "../../Models/IMctcGet";
import { IVehicleDataGet, IVehicleSubsitute } from "../../Models/IVehicles";

export const SET_VEHICLES_FOR_COMPANY = "SET_VEHICLES_FOR_COMPANY";
export const SET_V_DISPOSED = "SET_V_DISPOSED";
export const SET_DRIVERS_FOR_COMPANY = "SET_DRIVERS_FOR_COMPANY";
export const SET_ACTIVE_VEHICLE_FOR_COMPANY = "SET_ACTIVE_VEHICLE_FOR_COMPANY";
export const SET_ACTIVE_DRIVER_FOR_COMPANY = "SET_ACTIVE_DRIVER_FOR_COMPANY";
export const SET_VEHICLE_MCTC = "SET_VEHICLE_MCTC";
export const SET_CURRENT_ASSIGNMENTS = "SET_CURRENT_ASSIGNMENTS";
export const SET_CURRENT_ASSIGNMENT = "SET_CURRENT_ASSIGNMENT";
export const SET_CURRENT_VEHICLE_SUBSTITUTES =
  "SET_CURRENT_VEHICLE_SUBSTITUTES";

interface SetVechicleDisposedActionType {
  type: typeof SET_V_DISPOSED;
  payload: number;
}

interface SetVechicleSobstitutesActionType {
  type: typeof SET_CURRENT_VEHICLE_SUBSTITUTES;
  payload: IVehicleSubsitute[];
}

interface SetVechiclesForCompanyActionType {
  type: typeof SET_VEHICLES_FOR_COMPANY;
  payload: IVehicleDataGet[];
}

interface SetAssignmentActionType {
  type: typeof SET_CURRENT_ASSIGNMENT;
  payload: IAssignmentGet | undefined;
}

interface SetAssignmentsActionType {
  type: typeof SET_CURRENT_ASSIGNMENTS;
  payload: IAssignmentGet[];
}

interface SetDriversForCompanyActionType {
  type: typeof SET_DRIVERS_FOR_COMPANY;
  payload: IDriverGet[];
}

interface SetActiveVechicleForCompanyActionType {
  type: typeof SET_ACTIVE_VEHICLE_FOR_COMPANY;
  payload: IVehicleDataGet | undefined;
}

interface SetActiveDriverForCompanyActionType {
  type: typeof SET_ACTIVE_DRIVER_FOR_COMPANY;
  payload: IDriverGet | undefined;
}

interface SetVehicleMctcType {
  type: typeof SET_VEHICLE_MCTC;
  payload: IMctcGet | undefined;
}

export type VehicleActionType =
  | SetVechiclesForCompanyActionType
  | SetDriversForCompanyActionType
  | SetActiveVechicleForCompanyActionType
  | SetAssignmentActionType
  | SetAssignmentsActionType
  | SetActiveDriverForCompanyActionType
  | SetVechicleDisposedActionType
  | SetVechicleSobstitutesActionType
  | SetVehicleMctcType;

const setVehicleDisposed = (
  disposed: number
): SetVechicleDisposedActionType => {
  return { type: SET_V_DISPOSED, payload: disposed };
};

const setVehicleSubstitutes = (
  vehicles: IVehicleSubsitute[]
): SetVechicleSobstitutesActionType => {
  return { type: SET_CURRENT_VEHICLE_SUBSTITUTES, payload: vehicles };
};

const setVehiclesForCompany = (
  vehicles: IVehicleDataGet[]
): SetVechiclesForCompanyActionType => {
  return { type: SET_VEHICLES_FOR_COMPANY, payload: vehicles };
};

const setCurrentAssignment = (
  assignment: IAssignmentGet | undefined
): SetAssignmentActionType => {
  return { type: SET_CURRENT_ASSIGNMENT, payload: assignment };
};

const setCurrentAssignments = (
  assignments: IAssignmentGet[]
): SetAssignmentsActionType => {
  return { type: SET_CURRENT_ASSIGNMENTS, payload: assignments };
};

const setDriversForCompany = (
  drivers: IDriverGet[]
): SetDriversForCompanyActionType => {
  return { type: SET_DRIVERS_FOR_COMPANY, payload: drivers };
};

const setActiveVehicleForCompany = (
  vehicles: IVehicleDataGet | undefined
): SetActiveVechicleForCompanyActionType => {
  return { type: SET_ACTIVE_VEHICLE_FOR_COMPANY, payload: vehicles };
};

const setActiveDriverForCompany = (
  driver: IDriverGet | undefined
): SetActiveDriverForCompanyActionType => {
  return { type: SET_ACTIVE_DRIVER_FOR_COMPANY, payload: driver };
};

const setVehicleMctc = (
  vehicleMctc: IMctcGet | undefined
): SetVehicleMctcType => {
  return { type: SET_VEHICLE_MCTC, payload: vehicleMctc };
};

export const VehiclesActions = {
  setVehiclesForCompany,
  setCurrentAssignments,
  setCurrentAssignment,
  setDriversForCompany,
  setVehicleSubstitutes,
  setActiveVehicleForCompany,
  setActiveDriverForCompany,
  setVehicleDisposed,
  setVehicleMctc,
};
