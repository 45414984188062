function resizableGrid(table: any) {
  var row: any = table.getElementsByTagName("tr")[0],
    cols: any = row ? row.children : undefined;
  if (!cols) return;
  table.style.overflow = "hidden";

  var tableHeight: any = table.offsetHeight;

  for (var i = 0; i < cols.length; i++) {
    var div = createDiv(tableHeight);
    cols[i].appendChild(div);
    cols[i].style.position = "relative";
    setListeners(div);
  }

  function setListeners(div: any) {
    var pageX: any,
      curCol: any,
      nxtCol: any,
      curColWidth: any,
      nxtColWidth: any;

    div.addEventListener("mousedown", function (e: any) {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;

      var padding = paddingDiff(curCol);

      curColWidth = curCol.offsetWidth - padding;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
    });

    div.addEventListener("mouseover", function (e: any) {
      e.target.style.borderRight = "2px solid #009fe3";
    });

    div.addEventListener("mouseout", function (e: any) {
      e.target.style.borderRight = "";
    });

    document.addEventListener("mousemove", function (e) {
      if (curCol) {
        var diffX = e.pageX - pageX;

        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

        curCol.style.width = curColWidth + diffX + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      curCol = undefined;
      nxtCol = undefined;
      pageX = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined;
    });
  }

  function createDiv(height: any) {
    var div: any = document.createElement("div");
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = "5px";
    div.style.position = "absolute";
    div.style.cursor = "col-resize";
    div.style.userSelect = "none";
    div.style.height = height + "px";
    return div;
  }

  function paddingDiff(col: any) {
    if (getStyleVal(col, "box-sizing") == "border-box") {
      return 0;
    }

    var padLeft = getStyleVal(col, "padding-left");
    var padRight = getStyleVal(col, "padding-right");
    return parseInt(padLeft) + parseInt(padRight);
  }

  function getStyleVal(elm: any, css: any) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }
}

export const columnResizePlugin = () => {
  setTimeout(() => {
    var table = document.getElementById("prettytable");
    if (table) {
      resizableGrid(table);
    }
  }, 500);
};
