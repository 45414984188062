import { ICompanyGet } from "../../../Models/ICompany";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import FormAssignment from "./FormAssignment";
import "./TabAssignment.scss";

export interface ITabAssignment {
  currentTab: string;
  dataVehicle: IVehicleDataGet | null;
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  doRefreshForm?: () => void;
  dataDetails?: any;
  requestUpdate?: () => void;
}

const TabAssignDriver = (props: ITabAssignment) => {
  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-assignment"}
      id="secondary-tab-assignment"
      aria-labelledby="secondary-tab-assignment"
    >
      {props.currentTab === "secondary-tab-assignment" && (
        <FormAssignment
          disabled={!props.dataVehicle?.vehicleID}
          loggedUser={props.loggedUser}
          data={props.dataDetails}
          dataVehicle={props.dataVehicle ?? undefined}
          companyUser={props.companyUser}
          requestUpdate={() => {
            if (props.requestUpdate) {
              props.requestUpdate();
            }
          }}
        />
      )}
    </div>
  );
};

export default TabAssignDriver;
