import { useEffect, useState } from "react";
import i18next from "i18next";

import "./StepOrders.scss";
import CustomSideList from "../CustomSideList/CustomSideList";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../Utils/Toastify";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import AddNewBanner from "../AddNewBanner/AddNewBanner";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";

import { ICompanyGet } from "../../Models/ICompany";
import FormOrders from "./FormOders";
import { IContractGetProperty } from "../../Models/IContract";
import { IOrderGetSide } from "../../Models/IOrder";
import { SkipButtonCustomStepper } from "../CustomStepper/CustomStepper";

export interface IStepOrder {
  nextPage?: number;
  stepId?: number;
}

const StepOrders = (props: IStepOrder) => {
  /* REDUX */
  // User
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  // Company
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(null);

  // State for order side list
  const [orderSideList, setOrderSideList] = useState<IOrderGetSide[]>([]);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [loadingPropertyList, setLoadingPropertyList] =
    useState<boolean>(false);
  const [orderPropertyList, setOrderPropertyList] = useState<
    IContractGetProperty[]
  >([]);
  const [loadingOrderList, setLoadingOrderList] = useState<boolean>(false);

  // API to load order side list
  const loadOrderSideList = () => {
    if (loggedUser) {
      setLoadingOrderList(true);
      ApiService.OrderController.OrderGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderSideList(response.payload);

            updateAccountRegistrationStep(loggedUser, props.stepId, true);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingOrderList(false);
        }
      );
    }
  };

  // Refresh the form
  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  // Update the form with the selected element of the side list
  const updateCurrent = (item: any) => {
    let parsedItem = { ...item };
    parsedItem = {
      ...item["info"],
      ...{ vehicleOrder: item["details"] },
      ...{ contractTypeProperties: item["contractTypeProperties"] },
    };
    loadOrderPropertyList(parsedItem.contractTypeID);
    setState(parsedItem);
    doRefreshForm();
  };

  // API to load the property list
  const loadOrderPropertyList = (contractTypeID: number) => {
    if (contractTypeID) {
      setLoadingPropertyList(true);
      ApiService.ContractController.ContractGetPropertyList(
        true,
        false,
        true,
        contractTypeID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderPropertyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPropertyList(false);
        }
      );
    }
  };

  // Add a new order
  const newOrder = () => {
    setState(null);
    setOrderPropertyList([]);
    doRefreshForm();
  };

  /* HOOK EFFECT */
  // Load the page elements
  useEffect(() => {
    loadOrderSideList(); // Load order the side list

    doRefreshForm();
  }, []);

  /* RETURN */
  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleOrders") ?? "",
          }}
        />
        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitleOrders")}
        </h2>
        {props.nextPage && (
          <SkipButtonCustomStepper nextPage={props.nextPage} />
        )}
        <AddNewBanner
          label={i18next.t("navigation:addNewOrders") as string}
          new={() => newOrder()}
        />
        <div className="container-content-form">
          <div className="container-stepper-form add-border multi-form-background">
            {!refreshForm && (
              <FormOrders
                loggedUser={loggedUser}
                companyUser={companyUser}
                data={state}
                nextPage={props.nextPage}
                loadOrderSideList={() => loadOrderSideList()}
                doRefreshForm={() => {
                  doRefreshForm();
                  setState(null);
                }}
                orderPropertyList={orderPropertyList}
              />
            )}
          </div>
          {!loadingOrderList &&
            <CustomSideList
              title={i18next.t("generic:titleSideListOrders")}
              updateCurrent={updateCurrent}
              selected={{
                value: state?.vehicleOrderID,
                key: "info.vehicleOrderID",
              }}
              sideContent={"#form-StepOrders"}
              list={orderSideList}
              elementTitle={(item: any) => {
                return (
                  item?.info.contractTypeName +
                  " - " +
                  item?.info.vehicleOrderTypeName + 
                  " (N° " + item?.info.vehicleOrderNumber + ")"
                );
              }}
            />
          }
        </div>
      </div>
      <LoaderBackdrop
        loading={loadingPropertyList || loadingOrderList || refreshForm}
      />
    </>
  );
};

export default StepOrders;
