/* LIBRARY */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import CO2Emission__StatisticsPage_Body from "./CO2Emission__StatisticsPage_Body";

/* COMPONENT */
const CO2Emission__StatisticsPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_statistics_co2")} />

      <CO2Emission__StatisticsPage_Body />
    </div>
  );
};

export default CO2Emission__StatisticsPage;
