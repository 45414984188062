import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleServiceInsert,
  IVehicleServiceUpdate,
} from "../../Models/IVehicleService";
import { IVehicleSubsitute } from "../../Models/IVehicles";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleServiceController = {
  GetPaymentRecurrenceType: async (
    paymentRecurrenceID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SERVICE_GET_PAYMENT_RECURRENCE_TYPE;
    if (paymentRecurrenceID) {
      request.url += `?paymentRecurrenceID=${paymentRecurrenceID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleServiceGet: async (
    vehicleServiceID?: number,
    vehicleID?: number,
    stored?: boolean,
    pending?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SERVICE_GET;
    let parameters: string[] = [];
    if (vehicleServiceID) {
      parameters.push(`vehicleServiceID=${vehicleServiceID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    if (pending) {
      parameters.push(`pending=${pending}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleGetSearch: async (
    searchText: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_GET_SEARCH}?searchText=${searchText}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleGetTotalCost: async (
    vehicleID: number,
    from?: string,
    to?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_GET_TOTAL_COST;
    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (from) {
      parameters.push(`from=${from}`);
    }
    if (to) {
      parameters.push(`to=${to}`);
    }
    if(parameters.length > 0) {
      request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleGetDriverKm: async (
    vehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_GET_DRIVER_KM}?vehicleID=${vehicleID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleSobstituteGet: async (
    vehicleReplacementID?: number,
    vehicleID?: number,
    assignmentID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_SUBSTITUTE_GET}`;

    let parameters: string[] = [];
    if (vehicleReplacementID) {
      parameters.push(`vehicleReplacementID=${vehicleReplacementID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (assignmentID) {
      parameters.push(`assignmentID=${assignmentID}`);
    }

    request.url += "?" + parameters.join("&");

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleSobstituteInsert: async (
    body: IVehicleSubsitute,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SUBSTITUTE_INSERT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleBriefHistoryGet: async (
    vehicleID?: number,
    vehicleEventID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_GET_HISTORY_ACTIONS;
    let params: string[] = [];
    if (vehicleID) {
      params.push("vehicleID=" + vehicleID);
    }
    if (vehicleEventID) {
      params.push("vehicleEventID=" + vehicleEventID);
    }
    request.url += params.length > 0 ? "?" + params.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleSobstituteUpdate: async (
    body: IVehicleSubsitute,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SUBSTITUTE_UPDATE;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleServiceInsert: async (
    body: IVehicleServiceInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SERVICE_INSERT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleServiceUpdate: async (
    body: IVehicleServiceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SERVICE_UPDATE;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleServiceDelete: async (
    vehicleServiceID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_SERVICE_DELETE;
    request.payload = {
      vehicleServiceID,
    };
    request.method = "DELETE";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleServiceController;
