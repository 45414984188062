import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
  "data-sdk-integration-source": "integrationbuilder_sc",
  currency: "EUR",
};

const PayPalPaymentOnce = (props: {
  price: string;
  onPaymentDone: (data: any) => void;
}) => {
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState<string>("");
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    setPrice(props.price ?? "");
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 500);
  }, [props.price]);

  return (
    <div>
      {!reset && (
        <PayPalScriptProvider
          options={
            {
              ...initialOptions,
              intent: "capture",
              "enable-funding": "paylater,venmo,card",
            } as any
          }
        >
          <PayPalButtons
            fundingSource={undefined}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      description: "SemanticMap",
                      amount: {
                        currency_code: "EUR",
                        value: price,
                      },
                    },
                  ],
                })
                .then((orderId) => {
                  // Your code here after create the order
                  console.log(orderId);
                  return orderId;
                });
            }}
            onError={(err: any) => {
              console.error("Payment paypal", err);
            }}
            onApprove={(data, actions: any) => {
              return actions.order.capture().then((order: any) => {
                // Your code here after capture the order
                props.onPaymentDone({
                  data: data,
                  actions: actions,
                  order: order,
                });
              });
            }}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

export default PayPalPaymentOnce;
