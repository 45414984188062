import HeaderPageDashboard from "../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../Components/LoaderBackdrop/LoaderBackdrop";
import "./MessagingPage.scss";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Persona, { IPersonaProps } from "../../../../Components/Persona/Persona";
import ReplyIcon from "@mui/icons-material/Reply";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { JSONPrint } from "../../../../Utils/Decoder";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../Components/MultiForm/MultiForm";
import SendIcon from "@mui/icons-material/Send";
import ApiService from "../../../../Services/ApiService";
import { IAPIResponse } from "../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../Utils/Toastify";
import {
  IMessage,
  IMessageInsert,
  IMessageTo,
  IWriteableUsers,
} from "../../../../Models/IMessages";
import { IUser } from "../../../../Models/IUser";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../Reducers/RootReducer";
import { Link } from "react-router-dom";
import { userRoleFits } from "../../../../Utils/Session";
import { dateIsPast } from "../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { handleOptionalTranslatable } from "../../../../Utils/Messaging";
import { TextFields } from "@mui/icons-material";
import i18next from "i18next";

const getPaseDate = (days: number) => {
  let d = new Date();
  d.setDate(d.getDate() - days);
  return d.toISOString();
};

const printElem = (elemId: string, title: string) => {
  let el = document.getElementById(elemId);
  if (el) {
    var mywindow: any = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write("<html><head><title>" + title + "</title>");
    mywindow.document.write(
      "</head><style>button{display: none;}.read-message-content{margin-top: 2em;}.read-message-upper-zone-buttons{display:none;}.read-message-upper-zone-title{display: none;}</style><body >"
    );
    mywindow.document.write("<h1>" + title + "</h1>");
    mywindow.document.write(el.innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  return true;
};

const emailTimeDisplay = (dbDate: string) => {
  if (dbDate) {
    let dbDate_ = moment(dbDate);
    var currentDate = moment();

    if (currentDate.isSame(dbDate_, "day")) return dbDate_.utc().format("h:mm");
    else if (currentDate.isSame(dbDate_, "year"))
      return dbDate_.format("MMM DD");
    else return dbDate_.format("MM/DD/YYYY");
  }
  return "-";
};

const MessagingSlot = (props: { message: IMessage; onClick: () => void }) => {
  const getPersonaDetail = (user: string, imageUrl: string | undefined) => {
    if (!user) {
      user = "-";
    }
    let name = user.split("@")[0];
    let spl = name.split(".");
    return {
      name: spl[0],
      surname: spl.length > 1 ? spl[1] : spl[0].substring(1),
      b: "a",
      imageUrl: imageUrl,
    };
  };

  const [persona, setPersona] = useState<IPersonaProps>();

  useEffect(() => {
    const senderEmail: string = props.message.message.accountFromEmail;
    setPersona(
      getPersonaDetail(senderEmail, props.message.message.accountFromImage)
    );
  }, [props.message]);

  return (
    <div className="messaging-slot-main-wrap" onClick={props.onClick}>
      <div>{persona && <Persona {...persona} />}</div>
      <div style={{ width: "100%" }}>
        <div className="messaging-slot-from-wrap">
          <div className="messaging-slot-from">
            {props.message.message.accountFromEmail}
          </div>
          <div className="messaging-sent-date">
            {emailTimeDisplay(props.message.message.sentDT)}
          </div>
        </div>
        <div
          className="messaging-slot-main-title"
          style={props.message.message.read ? { fontWeight: "400" } : {}}
        >
          {i18next.t("form:subject")}
          {": "}
          {handleOptionalTranslatable(props.message.message.subject)}
        </div>
        <div className="messaging-slot-main-preview">
          {handleOptionalTranslatable(props.message.message.shortContent)}
        </div>
      </div>
    </div>
  );
};

const MessageReadBox = (props: {
  message: IMessage;
  goBack: () => void;
  markRead: (msg: IMessage) => void;
  forward: (msg: IMessage) => void;
}) => {
  const markAsRead = () => {
    if (!props.message.message.read) {
      ApiService.MessagesController.MessagesMarkAsRead(
        props.message.message.messageID,
        props.message.message.forwardingFromMessageID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            props.markRead(props.message);
          }
        }
      );
    }
  };

  useEffect(() => {
    markAsRead();
  }, []);

  return (
    <div id="message-content-div">
      <div className="read-message-upper-zone">
        <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
          <Button
            onClick={props.goBack}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            {i18next.t("navigation:prev")}
          </Button>
          <div>
            <div
              style={{ display: "flex", gap: "0.4em", alignItems: "center" }}
            >
              {i18next.t("navigation:from")}:
              <div className="read-message-upper-zone-from">
                {props.message.message.accountFromEmail}
              </div>
            </div>
            {props.message.messageAccountTo && (
              <div
                style={{
                  maxWidth: "20em",
                  overflow: "auto",
                  maxHeight: "5.5em",
                }}
              >
                <div style={{ display: "inline-block", marginRight: "0.4em" }}>
                  {i18next.t("navigation:to")}:
                </div>
                {(props.message.messageAccountTo ?? []).map(
                  (x: IMessageTo, i: number) => {
                    return (
                      <div
                        className="read-message-upper-zone-to"
                        style={{ display: "inline-block" }}
                      >
                        <span>
                          {(x.accountToName ?? "-") +
                            " " +
                            (x.accountToSurname ?? "-")}
                        </span>
                        {i !== props.message.messageAccountTo.length - 1
                          ? ", "
                          : ""}
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        </div>
        <div className="read-message-upper-zone-title">
          {handleOptionalTranslatable(props.message.message.subject)}
        </div>
        <div className="read-message-upper-zone-buttons">
          <Tooltip title={i18next.t("navigation:print")}>
            <IconButton
              color="primary"
              aria-label="print"
              component="label"
              onClick={() => {
                printElem("message-content-div", props.message.message.subject);
              }}
            >
              <LocalPrintshopIcon />
            </IconButton>
          </Tooltip>
          {userRoleFits(["FleetSupportAdmin", "Broker", "Driver"]) && (
            <Tooltip title={i18next.t("navigation:forward")}>
              <IconButton
                color="primary"
                aria-label="forward"
                component="label"
                onClick={() => {
                  props.forward(props.message);
                }}
              >
                <ReplyIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="read-message-upper-zone-date">
          <div>{moment(props.message.message.sentDT).format("LL")}</div>
          <small>
            {moment(props.message.message.sentDT).utc().format("LTS")}
          </small>
        </div>
      </div>
      <div className="read-message-link">
        <Link to={props.message.message.url}>
          {i18next.t("form:includedLink")}
        </Link>
      </div>
      <div className="read-message-content">
        <div
          dangerouslySetInnerHTML={{
            __html: handleOptionalTranslatable(props.message.message.content),
          }}
        ></div>
      </div>
    </div>
  );
};

var stateClone: any = {};
const MessageWriteBox = (props: {
  onSent: (msg: IMessageInsert) => void;
  saveAsDraft: (msg: IMessageInsert) => void;
  receivers: IWriteableUsers[];
  draftBase?: IMessageInsert | null;
  onBack: () => void;
  onsetBypassBroker: (bypassBroker: boolean) => void;
}) => {
  const draftToState = (message: IMessageInsert | undefined | null) => {
    if (message) {
      const blocksFromHtml = htmlToDraft(message.content);
      const { contentBlocks, entityMap } = blocksFromHtml;
      if (contentBlocks && message.content) {
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
      }
    }

    return EditorState.createEmpty();
  };

  const [receivers, setReceivers] = useState<IWriteableUsers[]>([]);

  useEffect(() => {
    setReceivers(
      [
        {
          accountID: -1,
          accountEmail: i18next.t("navigation:all"),
          accountName: "",
          accountSurname: "",
          roleID: -1,
          roleName: "",
        },
      ].concat(props.receivers)
    );
  }, [props.receivers]);

  const [writeMessage, setWriteMessage_] = useState<IMessageInsert>(
    props.draftBase ?? {
      accountToIDList: [],
      subject: "",
      content: "",
      shortContent: "",
      url: "",
      validFrom: "",
      validTo: "",
      highPriority: false,
      markAsRead: false,
      bypassBroker: false,
      accountToAll: false,
      forwardingFromMessageID: null,
    }
  );
  const [state, setState] = useState<EditorState>(
    draftToState(props.draftBase)
  );
  const [askFix, setAskFix] = useState<string>("");

  useEffect(() => {
    setWriteMessage({
      ...writeMessage,
      content: state.getCurrentContent().getPlainText("\u0001"),
    });
  }, [state]);

  const setWriteMessage = (data: any) => {
    stateClone = { ...data };
    setWriteMessage_(data);
  };

  useEffect(() => {
    return () => {
      stateClone = {};
    };
  }, []);

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  const AllIsSelected =
    writeMessage?.accountToIDList.filter((x: { id: number }) => x.id === -1)
      .length > 0;

  const getReceiverById = (id: number) => {
    let r: IWriteableUsers | undefined = receivers.find(
      (y: IWriteableUsers) => y.accountID === id
    );
    return r ? r.accountEmail : null;
  };

  const getFormInputs = () => {
    const steps: IMultiFormField[] = [
      {
        width: 50,
        type: "text",
        name: "shortContent",
        required: true,
        defaultValue: writeMessage?.shortContent,
        placeholder: i18next.t("form:shortMsg") ?? "",
        label: i18next.t("form:shortMsg") ?? "",
      },
      {
        width: 25,
        type: "date",
        name: "validFrom",
        required: true,
        defaultValue: writeMessage?.validFrom,
        minDate: new Date().toISOString(),
        maxDate: writeMessage?.validTo,
        placeholder: i18next.t("form:msgValidity") ?? "",
        label: i18next.t("form:msgValidity") ?? "",
      },
      {
        width: 25,
        type: "date",
        name: "validTo",
        defaultValue: writeMessage?.validTo,
        minDate: writeMessage?.validFrom,
        required: true,
        placeholder: i18next.t("form:msgEndValiity") ?? "",
        label: i18next.t("form:msgEndValiity") ?? "",
      },
      {
        width: 50,
        type: "url",
        name: "url",
        defaultValue: writeMessage?.url,
        required: true,
        placeholder: i18next.t("form:link") ?? "",
        label: i18next.t("form:link") ?? "",
      },

      {
        width: 50,
        type: "select",
        name: "highPriority",
        defaultValue: writeMessage?.highPriority === true ? 1 : 0,
        required: true,
        placeholder: i18next.t("form:priority") ?? "",
        label: i18next.t("form:priority") ?? "",
        options: [
          {
            key: 0,
            text: "Low priority",
          },
          {
            key: 1,
            text: "High priority",
          },
        ],
      },
    ];

    if (userRoleFits(["FleetSupportAdmin"])) {
      steps.push({
        width: 50,
        type: "checkbox",
        name: "bypassBroker",
        defaultValue: writeMessage?.bypassBroker,
        placeholder: i18next.t("form:bypassBroker") ?? "",
        label: i18next.t("form:bypassBroker") ?? "",
      });
    }
    return steps;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          marginBottom: "0.5em",
        }}
      >
        <Button
          onClick={props.onBack}
          startIcon={<ArrowBackIosIcon />}
          variant="contained"
        >
          {i18next.t("navigation:prev")}
        </Button>
        <div className="write-message-title">
          {i18next.t("navigation:writeMessage")}
        </div>
      </div>

      <Autocomplete
        fullWidth
        multiple
        title={i18next.t("form:recipients") ?? ""}
        style={{ marginBottom: "0.75em" }}
        value={
          AllIsSelected
            ? [getReceiverById(writeMessage.accountToIDList[0].id)]
            : writeMessage.accountToIDList.map((x: { id: number }) => {
                return getReceiverById(x.id);
              })
        }
        options={
          AllIsSelected
            ? [receivers[0].accountEmail]
            : receivers
                .filter(
                  (x: IWriteableUsers) =>
                    (writeMessage.accountToIDList ?? []).filter(
                      (y: { id: number }) => y.id === x.accountID
                    ).length === 0
                )
                .map((x: IWriteableUsers) => {
                  return x.accountEmail;
                })
        }
        onChange={(e, v) => {
          setWriteMessage({
            ...writeMessage,
            accountToIDList: receivers
              .filter((x: IWriteableUsers) => v.includes(x.accountEmail))
              .map((x: IWriteableUsers) => {
                return { id: x.accountID };
              }),
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={i18next.t("form:recipients")}
            placeholder={i18next.t("form:recipients") ?? ''}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "transparent !important",
              },
            }}
          />
        )}
      />

      <TextField
        variant="standard"
        style={{ marginBottom: "0.5em" }}
        fullWidth
        placeholder={i18next.t("form:subject") ?? ""}
        defaultValue={writeMessage.subject}
        onChange={(e: any) => {
          setWriteMessage({ ...writeMessage, subject: e.target.value });
        }}
      />
      <Editor
        editorState={state}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={setState}
      />
      <MultiForm
        formId="messageMainForm"
        suppressSubmit
        onChange={(data: any) => {
          if (data.bypassBroker !== undefined) {
            props.onsetBypassBroker(data.bypassBroker);
          }

          setWriteMessage(mergeObjects(data));
        }}
        onSubmit={(data: any) => {
          let final = { ...writeMessage, ...data };
          final.highPriority = final.highPriority === 1 ? true : false;

          if (final.bypassBroker === undefined) {
            final.bypassBroker = false;
          }

          props.onSent(final);
        }}
        formTitle={i18next.t("navigation:otherMsgInfo")}
        inputs={getFormInputs()}
      />
      {askFix && <Alert severity="error">{askFix}</Alert>}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "1em",
          gap: "1em",
        }}
      >
        <Button
          startIcon={<SendIcon />}
          variant="contained"
          onClick={() => {
            setAskFix("");
            if (writeMessage.accountToIDList.length === 0) {
              setAskFix(i18next.t("navigation:fix_receivers") ?? "");
              return;
            }
            if (!writeMessage.subject) {
              setAskFix(i18next.t("navigation:fix_subject") ?? "");
              return;
            }
            if (!writeMessage.content) {
              setAskFix(i18next.t("navigation:fix_content") ?? "");
              return;
            }

            triggerFormValidation("messageMainForm");
          }}
        >
          {i18next.t("navigation:send")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.saveAsDraft(writeMessage);
          }}
        >
          {i18next.t("navigation:saveDraft")}
        </Button>
      </div>
    </div>
  );
};

const MessagingPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messagesList, setMessagesList] = useState<any[]>([]);
  const [openDraft, setOpenDraft] = useState<IMessageInsert | null>(null);
  const [receivers, setReceivers] = useState<IWriteableUsers[]>([]);
  const [filter, setFilter] = useState<string>("");

  const [read, setRead] = useState<IMessage | null>(null);
  const [bypassBroker, setBypassBroker] = useState<boolean>(false);
  const [write, setWrite] = useState<boolean>(false);

  const [msgType, setMsgType] = useState<number>(0);

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const getMessages = () => {
    setLoading(true);
    ApiService.MessagesController.MessagesGet((response: IAPIResponse) => {
      if (response.error === null) {
        let rawMSGs: IMessage[] = response.payload;

        for (let i = 0; i < rawMSGs.length; i++) {
          rawMSGs[i].extraProcessing = {
            sendr: rawMSGs[i].message.accountFromEmail,
            obj: handleOptionalTranslatable(rawMSGs[i].message.subject),
            cnt: handleOptionalTranslatable(rawMSGs[i].message.content),
            cnt_s: handleOptionalTranslatable(rawMSGs[i].message.shortContent),
          };
        }

        setMessages(rawMSGs);
      } else {
        ToastMessage(i18next.t("error:couldnotloadmessages"), "error");
      }
      setLoading(false);
    });
  };

  const getReceivers = () => {
    ApiService.AccountController.GetWritableAccountsList(
      bypassBroker,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setReceivers(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  useEffect(() => {
    getReceivers();
  }, [bypassBroker]);

  const sendMessage = (msg: IMessageInsert) => {
    setProcessing(true);

    // wrote to ALL
    msg.accountToAll =
      msg.accountToIDList.filter((x: { id: number }) => x.id === -1).length > 0;

    ApiService.MessagesController.MessagesInsert(
      msg,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setRead(null);
          setWrite(false);
          setOpenDraft(null);

          ToastMessage(i18next.t("message:messageSentSuccessful"), "success");
        } else {
          ToastMessage(i18next.t("error:sent_message_error"), "error");
        }

        setProcessing(false);
      }
    );
  };

  const sortMessages = (messageA: IMessage, messageB: IMessage) => {
    if (messageA.message.sentDT < messageB.message.sentDT) {
      return 1;
    }
    if (messageA.message.sentDT > messageB.message.sentDT) {
      return -1;
    }
    return 0;
  };

  const getMessagesList = () => {
    if (msgType === 0) {
      return [...messages].filter(
        (x: IMessage) => x.message.accountFromID !== loggedUser?.accountID
      );
    }
    if (msgType === 1) {
      return [...messages].filter(
        (x: IMessage) => x.message.accountFromID === loggedUser?.accountID
      );
    }
    if (msgType === 2) {
      let drafts = localStorage.getItem("draft-messages");
      let drafts_: any[] = [];
      if (drafts) {
        drafts_ = JSON.parse(drafts);
      }

      return drafts_.reverse();
    }

    return [];
  };

  useEffect(() => {
    getMessages();
    setMessagesList(getMessagesList());
  }, [messages, msgType]);

  return (
    <div>
      <div className="deadlines-wrapper">
        <HeaderPageDashboard title={i18next.t("navigation:_messaging")} />
        {!loading && (
          <Box
            className="dashboardForm__box"
            style={{ width: "calc(100% - 2em)" }}
          >
            <div className="messaging-main-wrap">
              {!read && !write && (
                <div>
                  {userRoleFits(["FleetSupportAdmin", "Broker", "Driver"]) && (
                    <div className="messaging-top-button-zone">
                      <div className="messaging-type-wrap">
                        <select
                          value={msgType}
                          onChange={(e: any) => {
                            setMsgType(+e.target.value);
                          }}
                        >
                          {[
                            i18next.t("navigation:received"),
                            i18next.t("navigation:sent"),
                            i18next.t("navigation:draft"),
                          ].map((x: string, i: number) => {
                            return (
                              <option key={i} value={i}>
                                {x}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div style={{ width: "100%" }}>
                        <TextField
                          size="small"
                          placeholder={i18next.t("generic:Search") ?? ""}
                          fullWidth
                          onChange={(e) => {
                            setFilter(e.target.value ?? "");
                          }}
                        />
                      </div>
                      <Button
                        variant={"contained"}
                        onClick={() => {
                          setWrite(true);
                        }}
                      >
                        {i18next.t("navigation:new_message")}
                      </Button>
                    </div>
                  )}

                  {messagesList.length === 0 && (
                    <div className="margin-top-small margin-bottom-small">
                      <Alert severity="info">
                        {i18next.t("navigation:nomessages_to_read")}
                      </Alert>
                    </div>
                  )}

                  {msgType !== 2 &&
                    !read &&
                    !write &&
                    messagesList.length > 0 && (
                      <div className="margin-top-small margin-bottom-small">
                        <Alert severity="info">
                          {i18next.t("navigation:msgSelect")}
                        </Alert>
                      </div>
                    )}

                  {!userRoleFits(["FleetSupportAdmin", "Broker", "Driver"]) && (
                    <div style={{ width: "100%", marginBottom: "1em" }}>
                      <TextField
                        size="small"
                        placeholder={i18next.t("generic:Search") ?? ""}
                        fullWidth
                        onChange={(e) => {
                          setFilter(e.target.value ?? "");
                        }}
                      />
                    </div>
                  )}

                  <div className="messaging-main-wrap-list">
                    {msgType !== 2 &&
                      messagesList
                        .filter((x: IMessage) =>
                          JSON.stringify(x.extraProcessing)
                            .toLocaleLowerCase()
                            .includes(filter.toLocaleLowerCase())
                        )
                        .sort(sortMessages)
                        .map((x: IMessage, i: number) => {
                          return (
                            <MessagingSlot
                              message={x}
                              key={i}
                              onClick={() => {
                                setOpenDraft(null);
                                setWrite(false);
                                setRead(x);
                              }}
                            />
                          );
                        })}

                    {msgType == 2 &&
                      messagesList
                        .filter((x: IMessage) =>
                          JSON.stringify(x.extraProcessing)
                            .toLocaleLowerCase()
                            .includes(filter.toLocaleLowerCase())
                        )
                        .map((x: IMessageInsert, i: number) => {
                          return (
                            <div
                              key={i}
                              className="draft-msg-row"
                              onClick={() => {
                                setOpenDraft(x);
                                setWrite(true);
                                setRead(null);
                              }}
                            >
                              <div>
                                {x.subject && x.subject !== ""
                                  ? x.subject
                                  : "-"}
                              </div>
                              <div>
                                {x.content && x.content !== ""
                                  ? x.content
                                  : "-"}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              )}
              <div>
                {!write && read && (
                  <MessageReadBox
                    forward={(msg: IMessage) => {
                      setOpenDraft({
                        accountToIDList: [],
                        subject: msg.message.subject,
                        content: msg.message.content,
                        shortContent: msg.message.shortContent,
                        url: msg.message.url,
                        validFrom: msg.message.validFrom,
                        validTo: msg.message.validTo,
                        highPriority: msg.message.highPriority,
                        markAsRead: false,
                        bypassBroker: false,
                        accountToAll: false,
                        forwardingFromMessageID: msg.message.messageID,
                      });
                      setRead(null);
                      setWrite(true);
                    }}
                    markRead={(msg: IMessage) => {
                      let msgs: IMessage[] = [...messages];
                      for (let i = 0; i < msgs.length; i++) {
                        if (
                          msgs[i].message.messageID === msg.message.messageID
                        ) {
                          msgs[i].message.read = true;
                        }
                      }
                      setMessages(msgs);
                    }}
                    goBack={() => {
                      setOpenDraft(null);
                      setWrite(false);
                      setRead(null);
                    }}
                    message={read}
                  />
                )}

                {write && (
                  <MessageWriteBox
                    onsetBypassBroker={setBypassBroker}
                    onBack={() => {
                      setOpenDraft(null);
                      setWrite(false);
                      setRead(null);
                    }}
                    draftBase={openDraft}
                    receivers={receivers}
                    onSent={(msg: IMessageInsert) => {
                      sendMessage(msg);
                      getMessages();
                    }}
                    saveAsDraft={(msg: IMessageInsert) => {
                      let drafts = localStorage.getItem("draft-messages");
                      let drafts_: IMessageInsert[] = [];
                      if (drafts) {
                        drafts_ = JSON.parse(drafts);
                      }
                      // draft already exists
                      if (
                        drafts_.find(
                          (x: IMessageInsert) => x.draftId === msg.draftId
                        )
                      ) {
                        drafts_ = drafts_.filter(
                          (x: IMessageInsert) => x.draftId !== msg.draftId
                        );
                      } else {
                        msg.draftId = drafts_.length;
                      }

                      drafts_.push(msg);

                      localStorage.setItem(
                        "draft-messages",
                        JSON.stringify(
                          drafts_.sort(
                            (a: IMessageInsert, b: IMessageInsert) => {
                              return (a.draftId ?? 0) - (b.draftId ?? 0);
                            }
                          )
                        )
                      );
                      ToastMessage(
                        i18next.t("navigation:saved_as_draft") ?? "",
                        "success"
                      );
                      setOpenDraft(null);
                      setWrite(false);
                      setRead(null);
                    }}
                  />
                )}
              </div>
            </div>
          </Box>
        )}
      </div>
      <LoaderBackdrop loading={processing || loading} />
    </div>
  );
};

export default MessagingPage;
