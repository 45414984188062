import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IFleetManagerInsert } from "../../Models/IFleetManager";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const FleetManagerController = {
  FleetManagerGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FLEET_MANAGER_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FleetManagerInsert: async (
    body: IFleetManagerInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FLEET_MANAGER_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FleetManagerController;
