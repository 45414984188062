// Per tornare a localhost - Ripristinare riga 110 - 114 e 359 togliere logica FALSE
import i18next from "i18next";
import { ToastMessage } from "../../Utils/Toastify";
import { AddApiInCache, TryGetApiFromCache } from "./ApiCacheSubSystem";
import { env } from "process";

export interface IAPIRequest {
  url: string;
  port?: string | "7232";
  method: string | null;
  payload: any | null;
  extraHeaders: any | null;
  ignoreDefaultHeaders: boolean | null;
  successMessage: string | null;
  silentLevel: number;
  dontStringify: boolean | null;
  spinnerLabel: string | null;
  skipResponseJsonParse: boolean;
  alternative_base_url: string | null;
}

export interface IAPIResponse {
  error: string | null;
  payload: any | null;
  raw: any | null;
}

export interface ICustomBehaviorCallbacks {
  getSessionToken: Function;
  preRequestScript: Function;
  errorDetectionFunction: Function;
  payloadOutFormat: Function;
  payloadInFormat: Function;
}

export interface IAPISettings {
  generalErrorText: string;
  spinnerColor: string;
  baseUrl: string;
  basePort: string;
  spinnerGlobalStyle: string;
  defaultSpinnerLabel: string;
  maxToastMessageDuration: number;
  autoLogoutTest: Function;
  defaultApiParams: any;
  defaultHeaders: any;
  requiredHeaders: () => any;
  customCallBacks: ICustomBehaviorCallbacks;
  developerMode: boolean;
}

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generate request utility function
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

export const silentLevels = {
  NO_UI_INTERACTIONS: 0,
  ALLOW_TOASTS: 1,
};

export const createEmptyRequest = () => {
  let defaultRequest: IAPIRequest = {
    url: "",
    method: "get",
    payload: null,
    extraHeaders: null,
    ignoreDefaultHeaders: false,
    successMessage: null,
    silentLevel: silentLevels.ALLOW_TOASTS,
    dontStringify: false,
    spinnerLabel: null,
    skipResponseJsonParse: false,
    alternative_base_url: null,
  };

  return defaultRequest;
};

const autoLoginCallback = async () => {
  (window as any)["suppressToasts"] = true;
  (window as any)["goToLogin"]();
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generic settings:
 * The following code holds generic settings needed to setup the API system.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */
// some basic settings

var SUPPRESS_ALL_ERROR_TOASTS = true;

export const getBaseUrl = () => {
  return process.env.REACT_APP_BE_BASE_URL;
};

export const APISettings: IAPISettings = {
  developerMode: window.location.href.indexOf("localhost") !== -1,
  generalErrorText: i18next.t("error:GENERIC_ERR_500_MSG"),
  baseUrl: "",
  spinnerColor: "#FFD300",
  spinnerGlobalStyle: "solid-dual",
  defaultSpinnerLabel: "Loading",
  maxToastMessageDuration: 5000,
  autoLogoutTest: (apiResponse: IAPIResponse) => {
    if (apiResponse.raw.status === 401) {
      SUPPRESS_ALL_ERROR_TOASTS = true;
      (window as any)["deny_all_messages"] = true;
      autoLoginCallback();
    }
  },
  defaultApiParams: {
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached

    //credentials: "include", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  },
  requiredHeaders: () => {
    let headers: any = {};
    let tokenH = localStorage.getItem("sessionToken");
    if (tokenH) {
      headers["Authorization"] = "Bearer " + tokenH;
    } else {
      console.warn("Token was requested by API but is missing in storage");
    }
    headers["Content-Language"] = localStorage.getItem("language");
    return headers;
  },
  defaultHeaders: {},
  customCallBacks: {
    getSessionToken: () => {
      return null;
    },
    preRequestScript: (request: IAPIRequest) => {},
    errorDetectionFunction: (response: IAPIResponse) => {
      if (response.raw.status === 500) {
        return APISettings.generalErrorText;
      }
      if (response.payload && response.payload.detail) {
        return response.payload.detail;
      }
      return null;
    },
    payloadOutFormat: (payload: any) => {
      return payload;
    },
    payloadInFormat: (payload: any) => {
      return payload;
    },
  },
  basePort: "7232",
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Core code:
 * The following code should not be edited and is valid in general for any project.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

// exported methods
export const AjaxService = {
  call, // call Api inside controlled api flow
  downloadBase64File, // download file
  downloadFileFromUrl, // download from link
  openToastSuccess, // open toast: green
  openToastWarning, // open toast: yellow
  openToastError, // open toast: red
};

function downloadFileFromUrl(url: string) {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

function downloadBase64File(fileName: string, base64: string) {
  let type: string | null = null;
  let splName = fileName.split(".");
  let extension = "." + splName[splName.length - 1].toLowerCase();

  let contentTypes = [
    { ext: ".doc", cType: "application/msword" },
    { ext: ".dot ", cType: "application/msword" },
    {
      ext: ".docx",
      cType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      ext: ".dotx",
      cType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    },
    { ext: ".docm", cType: "application/vnd.ms-word.document.macroEnabled.12" },
    { ext: ".dotm", cType: "application/vnd.ms-word.template.macroEnabled.12" },
    { ext: ".xls ", cType: "application/vnd.ms-excel" },
    { ext: ".xlt ", cType: "application/vnd.ms-excel" },
    { ext: ".xla ", cType: "application/vnd.ms-excel" },
    {
      ext: ".xlsx",
      cType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      ext: ".xltx",
      cType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    },
    { ext: ".xlsm", cType: "application/vnd.ms-excel.sheet.macroEnabled.12" },
    {
      ext: ".xltm",
      cType: "application/vnd.ms-excel.template.macroEnabled.12",
    },
    { ext: ".xlam", cType: "application/vnd.ms-excel.addin.macroEnabled.12" },
    {
      ext: ".xlsb",
      cType: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    },
    { ext: ".ppt ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".pot ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".pps ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".ppa ", cType: "application/vnd.ms-powerpoint" },
    {
      ext: ".pptx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    },
    {
      ext: ".potx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.template",
    },
    {
      ext: ".ppsx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    },
    {
      ext: ".ppam",
      cType: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    },
    {
      ext: ".pptm",
      cType: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    },
    {
      ext: ".potm",
      cType: "application/vnd.ms-powerpoint.template.macroEnabled.12",
    },
    {
      ext: ".ppsm",
      cType: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    },
    { ext: ".mdb ", cType: "application/vnd.ms-access" },
    { ext: ".pdf ", cType: "application/pdf" },
    { ext: ".csv ", cType: "text/csv" },
    { ext: ".mp4", cType: "video/mp4" },
    { ext: ".ogg", cType: "video/ogg" },
    { ext: ".webm", cType: "video/webm" },
    { ext: ".png", cType: "image/png" },
    { ext: ".bmp", cType: "image/bmp" },
    { ext: ".jpg", cType: "image/jpg" },
  ];

  for (let i = 0; i < contentTypes.length; i++) {
    let t = contentTypes[i];
    if (t.ext === extension) {
      type = t.cType;
      break;
    }
  }

  if (type == null) {
    type = "application/octet-stream";
  }

  let base64ToArrayBuffer = function (base64: string) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  let saveByteArray = function (
    fileName: string,
    byte: Uint8Array,
    type: string
  ) {
    let blob = new Blob([byte], { type: type });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  let byteArray = base64ToArrayBuffer(base64);
  saveByteArray(fileName, byteArray, type);
}

async function call(apiRequest: IAPIRequest) {
  if (apiRequest.payload) {
    let stringDest = apiRequest.payload.toString();

    if (stringDest !== "[object FormData]") {
      apiRequest.payload = JSON.parse(
        JSON.stringify(apiRequest.payload).replace(/"\s+|\s+"/g, '"')
      );
    }
  }

  return await callInner(apiRequest);
}

async function callInner(apiRequest: IAPIRequest) {
  if (APISettings.customCallBacks.preRequestScript(apiRequest)) {
    return {
      raw: null,
      payload: null,
      error:
        "Pre request script blocked this call: " + JSON.stringify(apiRequest),
    };
  }

  /** obtain full url */
  apiRequest.url =
    apiRequest.alternative_base_url === null
      ? APISettings.baseUrl + apiRequest.url
      : apiRequest.alternative_base_url + apiRequest.url;

  /** custom format payload (if necessary) */
  apiRequest.payload = APISettings.customCallBacks.payloadOutFormat(
    apiRequest.payload
  );

  let response = await doFetch(apiRequest);

  if (response != null) {
    if (response.raw.status >= 300 || response.raw.status < 200) {
      response["error"] = extractError(response);
    } else {
      // no custom error extractor
      response["error"] = null;
    }

    if (apiRequest.silentLevel !== silentLevels.NO_UI_INTERACTIONS) {
      if (response["error"] != null) {
        if (!SUPPRESS_ALL_ERROR_TOASTS) {
          openToastError(response["error"]);
        } else {
          console.error(response["error"]);
        }
      }

      if (response["error"] == null && apiRequest.successMessage) {
        openToastSuccess(apiRequest.successMessage);
      }
    }
  }

  return response;
}

async function doFetch(apiRequest: IAPIRequest) {
  let cachedResult: IAPIResponse = TryGetApiFromCache(apiRequest);
  // try get any session token (jwt, b2c, etc... )
  let token = APISettings.customCallBacks.getSessionToken();

  // Default params for fetch call
  const params = JSON.parse(JSON.stringify(APISettings.defaultApiParams));

  if (cachedResult) {
    /* console.log(
      "Api call result (resolved from cache)",
      apiRequest,
      cachedResult,
      params
    ); */
    return cachedResult;
  }

  // set method (get is default )
  params["method"] = apiRequest.method ? apiRequest.method : "get";

  // add custom headers
  params["headers"] = obtainHeaders(apiRequest);

  if (token != null) {
    params["headers"]["Authorization"] = "Bearer " + token;
  }

  if (apiRequest.payload != null) {
    if (apiRequest.dontStringify === true) {
      params["body"] = apiRequest.payload;
    } else {
      params["body"] = JSON.stringify(apiRequest.payload); // body data type must match "Content-Type" header
    }
  }

  let response: any = null;

  try {
    let baseUrl_: any = getBaseUrl();
    if (process.env.REACT_APP_BE_BASE_URL?.includes("localhost")) {
      baseUrl_ =
        baseUrl_ +
        ":" +
        (apiRequest.port !== undefined
          ? apiRequest.port
          : APISettings.basePort);
    }
    response = await fetch(baseUrl_ + apiRequest.url, params);
  } catch (e: any) {
    response = { status: -1, message: e.message };
  }

  let value: any = null;
  if (!apiRequest.skipResponseJsonParse) {
    try {
      value = await response.json();
    } catch (e: any) {
      console.error("Could not parse response", e.message);
    }
  } else {
    value = response;
  }

  let output: IAPIResponse = {
    raw: response,
    payload: value,
    error: null,
  };

  APISettings.autoLogoutTest(output);

  output.payload = APISettings.customCallBacks.payloadInFormat(output.payload);

  // try cache this for later reuse
  AddApiInCache(apiRequest, output);

  return output;
}

function obtainHeaders(apiRequest: IAPIRequest) {
  let headers = { ...APISettings.defaultHeaders };

  if (apiRequest.ignoreDefaultHeaders === true) {
    headers = {};
  }

  //** required headers cannot be removed */
  headers = { ...headers, ...APISettings.requiredHeaders() };

  if (apiRequest.extraHeaders != null) {
    let keys = Object.keys(apiRequest.extraHeaders);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (apiRequest.extraHeaders.hasOwnProperty(key)) {
        headers[key] = apiRequest.extraHeaders[key];
      }
    }
  }

  return headers;
}

function extractError(response: IAPIResponse) {
  /** first attempt to get the error is taken by the custom error */
  let error = APISettings.customCallBacks.errorDetectionFunction(response);

  /** second attempt from the standard http error statusText voice */
  if (response.raw && response.raw.statusText && error == null) {
    error = response.raw.statusText;
  }

  /** third attempt from the standard http error message voice */
  if (response.raw && response.raw.message && error == null) {
    error = response.raw.message;
  }

  return error ?? APISettings.generalErrorText;
}

function openToastSuccess(message: string) {
  ToastMessage(message, "success");
}
function openToastWarning(message: string) {
  ToastMessage(message, "warning");
}
function openToastError(message: string) {
  ToastMessage(message, "error");
}
