import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, CircularProgress, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AttachementMainHandler from "../../../../../../Components/AttachmentMainHandler/AttachmentMainHandler";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import { dateFromUTC0_ISO, dateToUTC0_ISO, displayUTC0_ISODate, padBlock } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import StampPaymentInfo from "../../../../../../Components/StampPaymentInfo/StampPaymentInfo";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { IRoadTaxPaymentsRequestInsert } from "../../../../../../Models/IRoadTaxPaymentsRequestInsert";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleRoadTax } from "../../../../../../Models/IVehicleRoadTax";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { formatPlate } from "../../../VehiclesPage/VehiclesPage";
import { ResumeModal } from "../../../VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import Payments__AdditionalServicesPage_ToggleButtons from "../Payments__AdditionalServicesPage_ToggleButtons";
import { GetVehicleMinDate } from "../../../../DashboardPage";
import moment from "moment";

const Form__Bollo = (props: { vehicleID: number }) => {
  const [items, setItems] = useState<IVehicleRoadTax[]>([]);
  const [active, setActive] = useState<IVehicleRoadTax>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const AttachmentTab = (props: { tab: number; vId: number, attachmentCode: string }) => {
    return (
      <AttachementMainHandler
        attachmentFamily="vehicledata"
        extraGetParametersVector={[
          {
            name: "vehicleTabID",
            value: props.tab,
          },
          {
            name: "vehicleID",
            value: props.vId,
          },
          {
            name: "attachmentCode",
            value: props.attachmentCode,
          }
        ]}
      />
    );
  };

  const ArchiveModal = (props: { onArchive: () => void }) => {
    return (
      <SmartModal
        modalUniqueId="ArchiveModal"
        title={i18next.t("navigation:archive_item")}
        modalInnerComponent={<div>{i18next.t("navigation:archive_item_text")}</div>}
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("ResumeModal");
            },
          },
          {
            text: i18next.t("navigation:archive"),
            onClick: () => {
              props.onArchive();
              DismissModal("ResumeModal");
            },
          },
        ]}
      />
    );
  };

  const getRoadTaxes = () => {
    setLoading(true);
    ApiService.VehicleRoadTaxController.VehicleRoadTaxGet(
      null,
      props.vehicleID,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let res: IVehicleRoadTax[] = response.payload;
          setItems(res);
          let current = res.filter((x: IVehicleRoadTax) => x.stored === false);
          if (current.length > 0) {
            setActive(current[0]);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const resetForm = () => {
    setItems(
      items.map((x: IVehicleRoadTax) => {
        x.stored = true;
        return x;
      })
    );
    setLoading(true);
    setActive(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const updateRoadTax = (data: IVehicleRoadTax, newFormOnSuccess = false) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleRoadTaxController.VehicleRoadTaxUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (newFormOnSuccess) {
            resetForm();
          } else {
            setActive(data);
          }
          ToastMessage(i18next.t("message:roadtaxUdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertRoadTax = (data: IVehicleRoadTax) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleRoadTaxController.VehicleRoadTaxInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          data["roadTaxID"] = response.payload;
          setActive(data);
          ToastMessage(i18next.t("message:roadtaxInsert"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteRoadTax = (data: IVehicleRoadTax) => {
    setProcessing(true);
    ApiService.VehicleRoadTaxController.VehicleRoadTaxDelete(
      data.roadTaxID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:roadtaxDeleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    getRoadTaxes();
  }, []);

  const getTot = () => {
    if (active) {
      return (+active?.penalties + +active?.interests + +active?.fee + +active?.exemptions).toLocaleString();
    }
    return 0;
  };

  return (
    <div style={{ paddingTop: "1em" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "3em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div
            style={{
              display: "flex",
              gap: "1em",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              disabled={processing || !active?.roadTaxID}
              onClick={() => {
                SummonModal("ArchiveModal");
              }}
            >
              {i18next.t("navigation:archive")}
            </Button>
            <Button
              variant="contained"
              disabled={processing}
              onClick={() => {
                SummonModal("ResumeModal");
              }}
            >
              {i18next.t("navigation:tabs_vehicle_data_bollo_hist")}
            </Button>
          </div>

          <ArchiveModal
            onArchive={() => {
              if (active) {
                active.stored = true;
                updateRoadTax({ ...active }, true);
              }
            }}
          />
          <ResumeModal
            title={i18next.t("navigation:tabs_vehicle_data_bollo_hist")}
            headers={[
              {
                field: "startDT",
                headerName: i18next.t("form:vehciledata_forms_startdt"),
                fieldOriginal: "startDTOriginal"
              },
              {
                field: "endDT",
                headerName: i18next.t("form:vehciledata_forms_enddt"),
                fieldOriginal: "endDTOriginal",
              },
              {
                field: "validity",
                headerName: i18next.t("form:vehciledata_forms_validita"),
              },
              {
                field: "fee",
                headerName: i18next.t("form:vehciledata_forms_tassa"),
              },
              {
                field: "interests",
                headerName: i18next.t("form:vehciledata_forms_interessi"),
              },
              {
                field: "penalties",
                headerName: i18next.t("form:vehciledata_forms_sansioni"),
              },
              {
                field: "totAmount",
                headerName: i18next.t("form:vehciledata_forms_totbollo"),
              },
              {
                field: "exemptions",
                headerName: i18next.t("form:vehciledata_forms_esansioni"),
              },
              {
                field: "paymentCode",
                headerName: i18next.t("form:vehciledata_forms_id_pay"),
              },
              {
                field: "paymentDT",
                fieldOriginal: "paymentDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_id_datepay"),
              },
            ]}
            rows={items
              .filter((x: IVehicleRoadTax) => x.stored === true)
              .map((x: IVehicleRoadTax) => {
                return {
                  startDT: displayUTC0_ISODate(x.startDT, true) ?? "-",
                  startDTOriginal: x.startDT,
                  endDT: displayUTC0_ISODate(x.endDT, true) ?? "-",
                  endDTOriginal: x.endDT,
                  validity: x.validity,
                  fee: x.fee,
                  interests: x.interests,
                  penalties: x.penalties,
                  totAmount: x.totAmount,
                  exemptions: x.exemptions,
                  paymentCode: x.paymentCode,
                  paymentDT: displayUTC0_ISODate(x.paymentDT, true) ?? "-",
                  paymentDTOriginal: x.paymentDT,
                }
              })}
          />
          <MultiForm
            suppressLayout
            formTitle={""}
            formId="bollo-form-unique"
            suppressSubmit
            onChange={(data: any) => {
              if(data["startDT"] !== "" && data["validity"] >= 0){
                data["endDT"] = dateToUTC0_ISO(moment(data["startDT"])
                .add({ months: data["validity"] > 1 ? +(data["validity"] - 1) : +data["validity"]}).endOf('month').toDate());
              }
              setActive({ ...active, ...data });
            }}
            onSubmit={(data: any) => {
              let data_ = { ...active, ...data };
              delete data_["totAmount_"];
              data_["exemptions"] = +data_["exemptions"];
              data_["fee"] = +data_["fee"];
              data_["interests"] = +data_["interests"];
              data_["penalties"] = +data_["penalties"];
              data_["endDT"] = dateFromUTC0_ISO(data_["endDT"]);
              data_["paymentDT"] = dateFromUTC0_ISO(data_["paymentDT"]);
              data_["endDT"] =  `${data_["endDT"].getFullYear()}-${padBlock(
                data_["endDT"].getMonth() + 1
              )}-${padBlock(data_["endDT"].getDate())}T${padBlock(data_["endDT"].getHours())}:${padBlock(
                data_["endDT"].getMinutes()
              )}:${padBlock(data_["endDT"].getSeconds())}`;
              data_["paymentDT"] =  `${data_["paymentDT"].getFullYear()}-${padBlock(
                data_["paymentDT"].getMonth() + 1
              )}-${padBlock(data_["paymentDT"].getDate())}T${padBlock(data_["paymentDT"].getHours())}:${padBlock(
                data_["paymentDT"].getMinutes()
              )}:${padBlock(data_["paymentDT"].getSeconds())}`;
              if (data_.roadTaxID !== undefined) {
                updateRoadTax(data_);
              } else {
                insertRoadTax(data_);
              }
            }}
            inputs={[
              {
                width: 33,
                type: "date",
                name: "startDT",
                defaultValue: active?.startDT,
                required: true,
                minDate: GetVehicleMinDate(undefined),
                label: i18next.t("form:vehciledata_forms_startdt").toString(),
              },
              {
                width: 33,
                type: "number",
                name: "validity",
                defaultValue: active?.validity,
                required: true,
                label: i18next.t("form:vehciledata_forms_validita").toString(),
              },
              {
                width: 33,
                type: "date",
                name: "endDT",
                minDate: active ? dayjs(active.startDT) : undefined,
                defaultValue: active?.endDT,
                currentValue: active?.endDT,
                required: true,
                disabled: true,
                label: i18next.t("form:vehciledata_forms_enddt").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "fee",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.fee,
                required: true,
                label: i18next.t("form:vehciledata_forms_tassa").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "interests",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.interests,
                required: true,
                label: i18next.t("form:vehciledata_forms_interessi").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "penalties",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.penalties,
                required: true,
                label: i18next.t("form:vehciledata_forms_sansioni").toString(),
              },
              {
                width: 24,
                type: "text",
                name: "totAmount_",
                disabled: true,
                currentValue: getTot(),
                inputAdornament: { adornament: "€" },
                required: true,
                label: i18next.t("form:vehciledata_forms_totbollo").toString(),
              },
              {
                width: 33,
                type: "number",
                name: "exemptions",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.exemptions,
                required: true,
                label: i18next.t("form:vehciledata_forms_esansioni").toString(),
              },
              {
                width: 33,
                type: "text",
                name: "paymentCode",
                defaultValue: active?.paymentCode,
                inputProps: {
                  maxLength: 50,
                },
                required: true,
                label: i18next.t("form:vehciledata_forms_id_pay").toString(),
              },
              {
                width: 33,
                type: "date",
                name: "paymentDT",
                defaultValue: active?.paymentDT ?? new Date().toISOString(),
                required: true,
                label: i18next.t("form:vehciledata_forms_id_datepay").toString(),
              },
              {
                width: 100,
                type: "switch",
                name: "roadTaxPaymentAddon",
                defaultValue: active?.roadTaxPaymentAddon,
                placeholder: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18next.t("form:switchPayVehicle") ?? "",
                    }}
                  />
                ),
              },
            ]}
          />
          <AttachmentTab vId={props.vehicleID} tab={2} attachmentCode={active?.attachmentCode ?? ""} />
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              disabled={processing}
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("bollo-form-unique");
              }}
            >
              {i18next.t("form:save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentsPaid = () => {
  const navigate = useNavigate();

  const [tot, setTot] = useState<number>(0);
  const [roadTaxRows, setRoadTaxRows] = useState<number[]>([]);
  const [roadTaxRowsSelected, setRoadTaxRowsSelected] = useState<any[]>([]);
  const [editRoadTax, setEditRoadTax] = useState<number>();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const loadRoadTax = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxGet((response: IAPIResponse) => {
        if (response.error === null) {
          let tmp = response.payload.filter(
            (x: any) =>
              x.roadTaxStatusID === 6 ||
              x.roadTaxStatusID === 7 ||
              (x.roadTaxStatusID === 8 && x.creditDebit > 0)
          );
          setRoadTaxRows(tmp);
        } else {
          ToastMessage(response.error, "error");
        }
      });
    }
  };

  const RoadTaxPaymentsSendUpdate = (body: IRoadTaxPaymentsRequestInsert) => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsSendUpdate(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:RoadTaxPaymentSendCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  useEffect(() => {
    loadRoadTax();
  }, []);

  // Linkable plate
  const renderPlate = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  // Status "Expired" or "Payed"
  const renderStatus = (props: GridRenderCellParams) => {
    if (props.row.statoDelBollo === "Scaduto") {
      return <div style={{ color: "red" }}>{props.value}</div>;
    } else if (props.row.statoDelBollo === "Da saldare") {
      return <div style={{ color: "orange" }}>{props.value}</div>;
    } else {
      return <div>{props.value}</div>;
    }
  };

  // Tot bollo
  const renderBollo = (props: GridRenderCellParams) => {
    return <div>{props.value + " €"}</div>;
  };

  // Credit status
  const renderCredit = (props: GridRenderCellParams) => {
    if (props.row.creditDebit > 0) {
      return <div style={{ color: "green" }}>{"+" + props.value + " €"}</div>;
    } else if (props.row.creditDebit < 0) {
      return <div style={{ color: "red" }}>{props.value + " €"}</div>;
    } else {
      return <div>{props.value + " €"}</div>;
    }
  };

  // Edit row
  const EditStamp = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          SummonModal("payment-road-tax-id");
          setEditRoadTax(props.row.vehicleID);
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };

  const FormatData = (props: GridRenderCellParams) => {
    return <div>{displayUTC0_ISODate(props.value, true)}</div>;
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: " ",
      renderCell: EditStamp,
      type: 'custom'
    },
    {
      field: "vehicleLicensePlate",
      headerName: i18next.t("navigation:_tickets_page_column_title_plate"),
      renderCell: renderPlate,
    },
    {
      field: "roadTaxStatusName",
      headerName: i18next.t("navigation:roadtax_page_header_table_state_roadtax"),
      renderCell: renderStatus,
    },
    {
      field: "creditDebit",
      headerName: i18next.t("navigation:roadtax_page_header_table_credit_debit"),
      renderCell: renderCredit,
    },
    {
      field: "paymentCode",
      headerName: i18next.t("navigation:roadtax_page_header_table_id_payment"),
    },
    {
      field: "startDT",
      headerName: i18next.t("navigation:roadtax_page_header_table_start_date"),
      renderCell: FormatData,
    },
    {
      field: "endDT",
      headerName: i18next.t("navigation:roadtax_page_header_table_end_date"),
      renderCell: FormatData,
    },
    {
      field: "validity",
      headerName: i18next.t("navigation:roadtax_page_header_table_validity"),
    },
    {
      field: "paymentDT",
      headerName: i18next.t("navigation:roadtax_page_header_table_payment_date"),
      renderCell: FormatData,
    },
    {
      field: "totalAmount",
      headerName: i18next.t("navigation:roadtax_page_header_table_tot_roadtax"),

      renderCell: renderBollo,
    },
  ];

  /* USE EFFECT HOOK */
  // Calculate the amount to pay
  useEffect(() => {
    let tmpTot: number = 0;
    let tmp = [...roadTaxRowsSelected];
    for (let i = 0; i < tmp.length; i++) {
      tmpTot = tmpTot + tmp[i].creditDebit;
    }
    setTot(+tmpTot.toFixed(2));
  }, [roadTaxRowsSelected]);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_stamp_payments_page_title")} />

      <div className="dashboardForm__box">
        <div className="display-flex-horizontal-space-between full-height">
          <Payments__AdditionalServicesPage_ToggleButtons position="3" />

          <div className="display-flex-vertical-center"></div>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={roadTaxRows} />
        </Box>
      </div>
      <StampPaymentInfo />
      <SmartModal
        modalUniqueId={"payment-road-tax-id"}
        title={""}
        modalInnerComponent={
          <div style={{ padding: "1em" }}>
            <Form__Bollo vehicleID={editRoadTax!} />
          </div>
        }
      />
    </div>
  );
};

export default PaymentsPaid;
