import { url } from "inspector";
import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const CalendarController = {
  DeadlineCategory: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DEADLINE_CATEGORY_GET;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeadlineType: async (
    deadlineCategoryID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DEADLINE_TYPE_GET;
    if (deadlineCategoryID) {
      request.url += `&deadlineCategoryID=${deadlineCategoryID}`;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeadlineByAccount: async (
    selectedYear: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DEADLINE_BY_ACCOUNT_GET;
    if (selectedYear) {
      request.url += `?selectedYear=${selectedYear}`;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeadlineExpiredByAccount: async (
    url: string, 
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = url;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteDeadlineExpiredByAccount: async (
    deadlineID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DEADLINE_EXPIRED_BY_ACCOUNT_DELETE;
    request.method = "DELETE";
    request.payload = {
      deadlineID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeadlineByBroker: async (
    selectedYear: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DEADLINE_BY_BROKER_GET;
    if (selectedYear) {
      request.url += `?selectedYear=${selectedYear}`;
    }
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default CalendarController;
