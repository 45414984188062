/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import TemplateModel from "../TemplateModel/TemplateModel";

/* MUI */
import { Button } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { useState } from "react";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import { ValidateForm } from "./StepDashboardLoadTemplate";
import { ITemplate } from "../../Models/ITemplate";
import DetailsFileSupply from "../DetailsFileSupply/DetailsFileSupply";
import { JSONPrint } from "../../Utils/Decoder";
import { ICompanyGet } from "../../Models/ICompany";

export interface IStepDashboardLoadTemplate_ButtonsProps {
  templateTypeID: number;
}

const StepDashboardLoadTemplate_Buttons = (
  props: IStepDashboardLoadTemplate_ButtonsProps
) => {
  const dispatch = useDispatch();
  const templateGenericFormData = useSelector(
    (state: GlobalState) => state.generic.templateGenericWorkingData
  );

  const [active, setActive] = useState<ITemplate>();
  const [state, setState] = useState<any>(null);
  return (
    <>
      <div className="button-select-model">
        <div className="button-wrapper">
          <Button
            onClick={() => {
              SummonModal("add-template-supply");
            }}
          >
            <div>
              <TableViewIcon className="StepDashboardLoadTemplate__icon--size" />
            </div>
            <div className="title-wrapper">
              <strong>
                {i18next.t("navigation:_forniture_page_select_model")}
              </strong>
            </div>
            <div className="accepted-file-wrapper">
              {i18next.t("navigation:_forniture_page_select_model_description")}
            </div>
          </Button>
        </div>

        <div className="button-wrapper">
          <Button
            onClick={() => {
              SummonModal("detailsFileId");
            }}
          >
            <div>
              <AddToPhotosIcon className="StepDashboardLoadTemplate__icon--size" />
            </div>
            <div className="title-wrapper">
              <strong>
                {i18next.t("navigation:_forniture_page_new_model")}
              </strong>
            </div>
            <div className="accepted-file-wrapper">
              {i18next.t("navigation:_forniture_page_new_model_description")}
            </div>
          </Button>
        </div>
      </div>
      <SmartModal
        modalUniqueId={"add-template-supply"}
        title={""}
        buttons={[
          {
            text: i18next.t("navigation:reject"),
            onClick: () => {
              DismissModal("add-template-supply");
            },
          },
          {
            disabled: !ValidateForm(state) || active === undefined,
            text: i18next.t("navigation:accept"),
            onClick: () => {
              dispatch(
                GenericActions.setTemplateGenericWorkingData({
                  ...templateGenericFormData,
                  headers: [],
                  formData: state,
                  template: active,
                  templateTypeID: props.templateTypeID,
                })
              );
              if (props.templateTypeID === 1) {
                stepperGoToPage(4);
              } else {
                stepperGoToPage(2);
              }
            },
          },
        ]}
        modalInnerComponent={
          <div>
            <DetailsFileSupply
              isCreating
              onChange={(data: any) => {
                setState(data);
              }}
            />
            <TemplateModel
              onClick={(template: ITemplate) => {
                setActive(template);
              }}
              templateTypeID={props.templateTypeID}
            />
          </div>
        }
      />
    </>
  );
};

export default StepDashboardLoadTemplate_Buttons;
