import i18next from "i18next";
import HeaderPageDashboard from "../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import "./ProfilePage.scss";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IUser } from "../../Models/IUser";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { dateIsPast } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  IAccountGetInfo,
  IAccountUdpateWithImage,
} from "../../Models/IAccountGet";
import MultiForm from "../../Components/MultiForm/MultiForm";
import { validationFiscalCode, validationPhone } from "../../Utils/Validation";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { UserActions } from "../../Reducers/User/UserAction";
import { IBrokerGet } from "../../Models/IBroker";
import { JSONPrint } from "../../Utils/Decoder";
export interface IProfilePageProps {}

const BrokerGrantManager = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [loading, setLoading] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [broker, setBroker] = useState<IBrokerGet | null>(null);
  const [brokergrant, setBrokerGrant] = useState<any>(null);

  const getYourBroker = () => {
    setLoading(true);
    ApiService.BrokerController.BrokerInvitationsGet(
      loggedUser?.accountID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let brokers: IBrokerGet[] = response.payload;
          if (brokers.length > 0) {
            ApiService.BrokerController.BrokerGet(
              null,
              brokers[0].brokerID,
              (response2: IAPIResponse) => {
                if (response.error === null) {
                  let brokers2: IBrokerGet[] = response2.payload;
                  if (brokers2.length > 0) {
                    setBroker(brokers2[0]);
                  }
                }
              }
            );
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  const createYourBrokerGrants = () => {
    if (broker) {
      ApiService.BrokerController.BroketGrantsInsert(
        broker.brokerAccountID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if(response.payload.length > 0){
              setBrokerGrant(response.payload[0]);
            }
          } else {
            setErrored(true);
          }
        }
      );
    }
  };

  const updateYourBrokerGrants = (check: boolean) => {
    if (broker && brokergrant) {
      ApiService.BrokerController.BroketGrantsUpdate(
        brokergrant.brokerGrantID,
        check ? 1 : 0,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if(response.payload.length > 0){
              setBrokerGrant(response.payload[0]);
            }
            ToastMessage(
              i18next.t("navigation:broker_grant_updated"),
              "success"
            );
          }
        }
      );
    }
  };

  const getYourBrokerGrants = () => {
    ApiService.BrokerController.BroketGrantsGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            setBrokerGrant(response.payload[0]);
          } else {
            createYourBrokerGrants();
          }
        } else {
          setErrored(true);
        }
      }
    );
  };

  useEffect(() => {
    if (broker) {
      getYourBrokerGrants();
    }
  }, [broker]);

  useEffect(() => {
    getYourBroker();
  }, []);

  return (
    <div>
      {loading && (
        <div style={{ padding: "1em" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          {broker && brokergrant && (
            <div className="broker-card">
              <strong>{i18next.t("navigation:yourBroker")}: </strong>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {broker?.brokerName} {broker?.brokerSurname}
                <small>{broker?.brokerEmail}</small>
              </div>
              <small>
                <strong>{broker?.brokerBusinessName}</strong>,{" "}
                {broker?.brokerBillingCity}, {broker?.brokerBillingCAP},{" "}
                {broker?.brokerBillingProv}, {broker?.brokerBillingStreet},{" "}
                {broker?.brokerBillingNumber}
              </small>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={brokergrant.permission}
                    onChange={(e: any, checked: boolean) => {
                      updateYourBrokerGrants(checked);
                    }}
                  />
                }
                label={i18next.t("navigation:broker_control_on_your_data")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

/* COMPONTENT */
const ProfilePage = (props: IProfilePageProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResetPasswordSession, setLoadingResetPasswordSession] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [account, setAccount] = useState<IAccountGetInfo | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const getAccountInfo = () => {
    setLoading(true);
    ApiService.AccountController.AccountGetDataInfo(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setAccount(response.payload);
          if (response.payload.accountImage) {
            setImgUrl(response.payload.accountImage);
            dispatch(UserActions.setAvatar(response.payload.accountImage));
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const updateUser = (data: IAccountUdpateWithImage) => {
    setProcessing(true);
    ApiService.AccountController.AccountUpdateDataWithImage(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          getAccountInfo();
          ToastMessage(i18next.t("message:_profile_updated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const loadResetPasswordSession = () => {
    setLoadingResetPasswordSession(true);
    ApiService.AccountController.AccountResetPasswordSession(
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:messageResetPasswordSession"),
            "success"
          );
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingResetPasswordSession(false);
      }
    );
  };

  /* EFFECT HOOK */
  // First render
  useEffect(() => {
    getAccountInfo();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_profile_page")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                loadResetPasswordSession();
              }}
            >
              {i18next.t("navigation:_change_password")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          {!loading && account && (
            <div>
              <div style={{ paddingLeft: "1em" }}>
                <BrokerGrantManager />
              </div>
              <MultiForm
                suppressLayout
                formTitle={""}
                inputs={[
                  {
                    width: 50,
                    type: "text",
                    name: "accountName",
                    defaultValue: account?.accountName,
                    required: true,
                    label: i18next.t("navigation:_profile_name") ?? "",
                  },
                  {
                    width: 50,
                    type: "text",
                    name: "accountSurname",
                    defaultValue: account?.accountSurname,
                    required: true,
                    label: i18next.t("navigation:_profile_surname") ?? "",
                  },
                  {
                    width: 50,
                    type: "text",
                    name: "accountEmail",
                    defaultValue: account?.accountEmail,
                    required: true,
                    disabled: true,
                    label: i18next.t("navigation:_profile_email") ?? "",
                  },
                  {
                    width: 50,
                    type: "text",
                    name: "accountFiscalCode",
                    defaultValue: account?.accountFiscalCode,
                    inputProps: {
                      maxLength: 16,
                      style: { textTransform: "uppercase" },
                    },
                    required: account?.accountTypeID === 1,
                    label: i18next.t("navigation:_profile_cf") ?? "",
                    errorText:
                      account?.accountFiscalCode &&
                      account?.accountFiscalCode.length > 0 &&
                      !validationFiscalCode(account?.accountFiscalCode)
                        ? (i18next.t("form:validationCF") as string)
                        : "",
                  },
                  {
                    width: 50,
                    type: "tel",
                    name: "accountPhone",
                    defaultValue: account?.accountPhone,
                    required: account?.accountTypeID === 1,
                    label: i18next.t("navigation:_profile_phone") ?? "",
                    errorText:
                      account?.accountPhone &&
                      !validationPhone(account?.accountPhone)
                        ? (i18next.t("form:validationPhone") as string)
                        : "",
                  },
                  {
                    width: 50,
                    name: "",
                    type: "custom",
                    element: (
                      <div>
                        <div className="multi-form-input-upper-label">
                          {i18next.t("navigation:_profile_avatar")}
                        </div>

                        <div className="display-flex-horizontal-start">
                          <Button
                            onClick={() => {
                              document.getElementById("avatar-input")?.click();
                            }}
                            startIcon={<CameraAltIcon />}
                          >
                            {i18next.t("navigation:upload")}
                          </Button>

                          <input
                            onChange={(e: any) => {
                              let files = e.target.files;
                              if (files.length > 0) {
                                let image = files[0];

                                // get image ref and preview
                                let url = URL.createObjectURL(image);
                                setImgUrl(url);
                                const img = new Image();
                                img.src = url;
                                img.onload = () => {
                                  // init canvas
                                  const canvas: any =
                                    document.createElement("canvas");
                                  const W = 130;
                                  const H = 130;
                                  canvas.width = W;
                                  canvas.height = H;

                                  // init canvas context
                                  const ctx = canvas.getContext("2d");
                                  if (ctx) {
                                    ctx.drawImage(img, 0, 0, W, H);

                                    var pngUrl = canvas.toDataURL(); // PNG is the default
                                    if (account) {
                                      setAccount({
                                        ...account,
                                        accountImage: pngUrl,
                                      });
                                    }
                                  }
                                };
                              }
                            }}
                            accept=".png, .jpg, .jpeg, .bmp"
                            type="file"
                            hidden
                            id="avatar-input"
                          />

                          {imgUrl && (
                            <img
                              className="image-profile-preview"
                              src={imgUrl}
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
                onChange={(data: any) => {
                  setAccount({ ...account, ...data });
                }}
                onSubmit={(data: any) => {
                  let result: any = { ...account, ...data };
                  result["accountFiscalCode"] =
                    result["accountFiscalCode"]?.toUpperCase();
                  setAccount(result);

                  if (
                    account?.accountFiscalCode &&
                    account?.accountFiscalCode.length > 0 &&
                    !validationFiscalCode(account?.accountFiscalCode)
                  ) {
                    return;
                  }

                  if (
                    account?.accountPhone &&
                    !validationPhone(account?.accountPhone)
                  ) {
                    return;
                  }

                  delete result[""];

                  updateUser(result);
                }}
              />
            </div>
          )}
        </Box>
      </div>

      <LoaderBackdrop
        loading={processing || loading || loadingResetPasswordSession}
      />
    </div>
  );
};

export default ProfilePage;
