/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MODELS */
import { IFuelCardsGet } from "../../../../../Models/IFuelCards";
import { IUser } from "../../../../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Cards__VehiclesPage.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../VehiclesPage";
import FormRequestFuelCard from "./RequestFuelCardForm";

/* MUI */
import { Box, Button, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import FormFuelCards from "../../../../../Components/StepVehicles/TabFuelCards/FormFuelCards";
import { dateIsPast, displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { ICompanyGet } from "../../../../../Models/ICompany";
import EditIcon from "@mui/icons-material/Edit";

/* COMPONENT */
const Cards__VehiclesPage = () => {
  /* REDUCER OF LOGGED USER */
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [loadingCardsList, setLoadingCardsList] = useState<boolean>(false);

  // Container for Cards list
  const [cardsList, setCardsList] = useState<IFuelCardsGet[]>([]);
  const [currentFuelCard, setCurrentFuelCard] = useState<any>(null);

  /* APIs */
  // Load company list
  const loadCardsList = () => {
    if (loggedUser) {
      setLoadingCardsList(true);
      ApiService.FuelCardsController.FuelCardsGet(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCardsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCardsList(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__CARDS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  const editRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            let fuelCard_ = cardsList.find(
              (x: IFuelCardsGet) => x.fuelCardID === props.row.fuelCardID
            );
            setCurrentFuelCard(fuelCard_);
            SummonModal("cards-page-modal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const renderPIN = (props: GridRenderCellParams) => {
    if (props.value === 0) {
      return <div></div>;
    } else {
      return <div>{props.value}</div>;
    }
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadCardsList();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "supplier",
      headerName: i18next.t("form:vehicleSupplier"),
    },
    {
      field: "driver",
      headerName: i18next.t("navigation:_vehicle_page_vehicle_driver"),
    },
    {
      field: "type",
      headerName: i18next.t("navigation:typeCard"),
    },
    {
      field: "number",
      headerName: i18next.t("form:numberFuelCard"),
    },
    {
      field: "pin",
      headerName: i18next.t("form:PIN"),
      renderCell: renderPIN,
    },
    {
      field: "deadline",
      headerName: i18next.t("form:vehciledata_forms_addpay_scadenza"),
      fieldOriginal: "deadlineOriginal",
    },
    {
      field: "driverDeadline",
      headerName: i18next.t("form:endAssignmentFuelCard"),
      fieldOriginal: "driverDeadlineOriginal",
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < cardsList.length; i++) {
    rowsDef.push({
      edit: "",
      vehicleID: cardsList[i].vehicleID,
      fuelCardID: cardsList[i].fuelCardID,
      plate: cardsList[i].vehicleLicensePlate,
      supplier: cardsList[i].supplierName,
      driver: cardsList[i].driverName,
      type: cardsList[i].fuelCardTypeName,
      number: cardsList[i].fuelCardNumber,
      pin: cardsList[i].fuelCardPIN,
      deadline: displayUTC0_ISODate(cardsList[i].fuelCardExpirationDT, true),
      deadlineOriginal: cardsList[i].fuelCardExpirationDT,
      driverDeadline: displayUTC0_ISODate(cardsList[i].fuelCardEndAssignementDT, true),
      driverDeadlineOriginal: cardsList[i].fuelCardEndAssignementDT,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_vehicles_cards")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small gap-small">
          <Button
            variant="outlined"
            onClick={() => {
              SummonModal("request-fuel-card-modal");
            }}
          >
            {i18next.t("form:fuelCardRequest")}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setCurrentFuelCard(null);
              SummonModal("cards-page-modal");
            }}
          >
            {i18next.t("navigation:_add_fuel_card")}
          </Button>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
      </div>

      <SmartModal
        modalUniqueId={"request-fuel-card-modal"}
        title={i18next.t("form:fuelCardRequestFull")}
        modalInnerComponent={<FormRequestFuelCard />}
      />

      <SmartModal
        modalUniqueId={"cards-page-modal"}
        title={i18next.t("generic:titleAddFuelCard")}
        modalInnerComponent={
          <div className="modal-width-large">
            <FormFuelCards
              data={currentFuelCard ?? []}
              disabledAccordion={true}
              loggedUser={loggedUser}
              loggedCompany={loggedCompany}
              idModal="cards-page-modal"
              dataVehicle={[]}
              doReloadData={() => loadCardsList()}
            />
          </div>
        }
      />

      <LoaderBackdrop loading={loadingCardsList} />
    </div>
  );
};

export default Cards__VehiclesPage;
