import { useEffect, useState } from "react";
import {
  IQuotation,
  IQuotationSolution,
} from "../../../../../Models/IQuotation";
import "./QuotesPageProposed.scss";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { JSONPrint } from "../../../../../Utils/Decoder";
import i18next from "i18next";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { IBrokerCompanyGet } from "../../../../../Models/IBroker";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IWriteableUsers } from "../../../../../Models/IMessages";
import { IUser } from "../../../../../Models/IUser";
import { IAccountGetInfo } from "../../../../../Models/IAccountGet";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { Alert, Button, Typography } from "@mui/material";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import UploadForm from "../../../../../Components/UploadForm/UploadForm";
import {
  downloadWithName,
  getBlobDesc,
  getCompressedBase64,
  getCompressedFileName,
} from "../../../../../Utils/FileToBase";
import { renderInterventionTypeString } from "../QuotesPageRequests/QuotesPageRequests";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "rsuite";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { sendMessage } from "../../../../../Utils/Messaging";

const QuotesPageProposed = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [quotations, setQuotations] = useState<IQuotation[]>([]);
  const [targetUsers, setTargetUser] = useState<IAccountGetInfo[]>([]);
  const [activeQuotation, setActiveQuotation] = useState<IQuotation | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const preopenId = searchParams.get("preopenId");
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const [targetSolutions, setTargetSolutions] = useState<IQuotationSolution[]>(
    []
  );
  const [file, setFile] = useState<File | null>(null);
  const loadQuotationsRequests = (
    skipOpenModal = true,
    solutions: IQuotationSolution[] = []
  ) => {
    setLoading(true);
    ApiService.QuotationController.QuotationsGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setQuotations(response.payload);
          getReceivers(
            response.payload.map((x: IQuotation) => {
              return x.requestedById;
            })
          );
          if (!skipOpenModal) {
            sendOpen(response.payload, solutions);
          }
        } else {
          ToastMessage(response.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const markQuotationAsServed = async () => {
    if (activeQuotation) {
      let quot_: IQuotation = { ...activeQuotation };
      let dynamicStr: string = quot_.dynamicMetadata ?? "";
      let dynamic: any = JSON.parse(dynamicStr != "" ? dynamicStr : "{}");
      dynamic["servedQuotation"] = true;
      quot_.dynamicMetadata = JSON.stringify(dynamic);
      await ApiService.QuotationController.QuotationsUpdate(quot_);
      loadQuotationsRequests();
    }
  };

  const getReceivers = async (idList: number[]) => {
    let results: IAccountGetInfo[] = [];
    for (let i = 0; i < idList.length; i++) {
      if (
        results.filter((x: IAccountGetInfo) => x.accountID === idList[i])
          .length === 0
      ) {
        let response: IAPIResponse =
          await ApiService.AccountController.AccountGet(
            idList[i],
            null,
            null,
            null
          );
        if (response.error === null) {
          results.push(response.payload);
        }
      }
    }
    setTargetUser(results);
    setLoading(false);
  };

  useEffect(() => {
    loadInerentSolutions();
  }, []);

  const renderUserName = (props: GridRenderCellParams) => {
    let target: IAccountGetInfo | undefined = targetUsers.find(
      (x: IAccountGetInfo) => x.accountID === props.row.requestedById
    );

    return (
      <div>
        {target && (
          <div>
            {target.accountName} {target?.accountSurname}
          </div>
        )}
        {!target && <div>-</div>}
      </div>
    );
  };

  const activeSolution: IQuotationSolution | undefined = targetSolutions.find(
    (x: IQuotationSolution) =>
      x.solutionToQuotationId === activeQuotation?.quotationId
  );

  const sendOpen = (
    quotations_: IQuotation[],
    solutions: IQuotationSolution[]
  ) => {
    if (preopenId && quotations_.length > 0) {
      let match: IQuotation[] = quotations_.filter(
        (x: IQuotation) => x.quotationId == +preopenId
      );

      if (match.length > 0) {
        let targQuot: IQuotation = match[0];

        setActiveQuotation(targQuot);

        const sol: IQuotationSolution | undefined = solutions.find(
          (x: IQuotationSolution) =>
            x.solutionToQuotationId === targQuot?.quotationId
        );

        setFile(null);
        if (sol) {
          SummonModal("send-inspect-solution-modal");
        } else {
          SummonModal("send-solution-modal");
        }
      }
    }
  };

  const loadInerentSolutions = () => {
    ApiService.QuotationController.QuotationsSolutionGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTargetSolutions(response.payload);
          loadQuotationsRequests(false, response.payload);
        } else {
          ToastMessage(i18next.t("error:GENERIC_ERR_500_MSG"), "error");
        }
      }
    );
  };

  const handleButton = (props: GridRenderCellParams) => {
    let meta = JSON.parse(
      props.row.dynamicMetadata !== "" ? props.row.dynamicMetadata : "{}"
    );
    let handled = meta.servedQuotation === true;
    return (
      <div>
        {handled && (
          <Button
            onClick={() => {
              setActiveQuotation(props.row);
              SummonModal("send-inspect-solution-modal");
            }}
            variant="contained"
          >
            {i18next.t("navigation:inspect")}
          </Button>
        )}
        {!handled && (
          <Button
            onClick={() => {
              setActiveQuotation(props.row);
              setFile(null);
              SummonModal("send-solution-modal");
            }}
            variant="contained"
          >
            {i18next.t("navigation:quotations_request_handle")}
          </Button>
        )}
      </div>
    );
  };

  const renderInterventionType = (props: GridRenderCellParams) => {
    return (
      <div>{renderInterventionTypeString(props.row.interventionType)}</div>
    );
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "handle_required",
      headerName: i18next.t("navigation:quotations_request_handle"),
      renderCell: handleButton,
    },
    {
      field: "quotations_requested_by",
      headerName: i18next.t("navigation:quotations_requested_by"),
      renderCell: renderUserName,
    },
    {
      field: "areaOfIntervention",
      headerName: i18next.t("navigation:quotations_area"),
    },
    {
      field: "interventionType",
      headerName: i18next.t("navigation:quotation_op_type"),
      renderCell: renderInterventionType,
    },
    {
      field: "createdDT_",
      headerName: i18next.t("navigation:quotation_date"),
    },
  ];

  const uploadFile = async () => {
    let response: IAPIResponse = await ApiService.UploadController.UploadFile(
      "attachment-forquotationsolutions",
      Array.from([file])
    );
    if (response.error === null) {
      let attachment = response.payload.fileReference;
      return attachment;
    } else {
      ToastMessage(i18next.t("error:could_not_upload"), "error");
      return null;
    }
  };

  const updateFile = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const sendSolution = async (data: any) => {
    setLoading(true);
    let fileAttachmentUrl: string | null = await uploadFile();
    if (fileAttachmentUrl && activeQuotation && loggedUser) {
      let quotation: IQuotationSolution = {
        solutionOwnerId: loggedUser.accountID,
        solutionToQuotationId: activeQuotation.quotationId as number,
        attachmentFile: fileAttachmentUrl,
        note: data.notes,
        deleted: false,
        accepted: 0,
      };

      const getBaseMsg = (text: string, preopenId: number) => {
        return JSON.stringify({
          triggerContent: "unpackJsonMessage",
          baseText: text,
          extraParams: [
            {
              key: "$PREOPEN_URL",
              value:
                AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___QUOTE_ROUTE__RECEIVED +
                "?preopenId=" +
                preopenId.toString(),
            },
            {
              key: "$SERVICE_NAME",
              fun: "renderInterventionTypeString",
              value: activeQuotation.interventionType,
            },
            {
              key: "$REQUESTER",
              value: loggedUser?.accountName + " " + loggedUser?.accountSurname,
            },
          ],
        });
      };

      ApiService.QuotationController.QuotationsSolutionInsert(
        quotation,
        (response: IAPIResponse) => {
          if (response.error === null) {
            sendMessage(
              activeQuotation.requestedById,
              "translate__have_served_quote_obj",
              getBaseMsg("translate__have_served_quote_cont", response.payload),
              getBaseMsg(
                "translate__have_served_quote_cont_short",
                response.payload
              ),
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___QUOTE_ROUTE__RECEIVED +
                "?preopenId=" +
                response.payload
            );

            ToastMessage(
              i18next.t("navigation:quotation_solution_sent"),
              "success"
            );
            markQuotationAsServed();
            DismissModal("send-solution-modal");
          } else {
            ToastMessage(i18next.t("error:generic_api_error"), "error");
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const metaMap = () => {
    const metamap: { name?: string; label?: string; element?: JSX.Element }[] =
      [
        // Title - Ruote anteriori
        {
          element: (
            <div style={{ color: "var(--default-background-header)" }}>
              <Typography variant="overline" className="text-bold">
                {i18next.t("form:frontTyres")}
              </Typography>
            </div>
          ),
        },

        // Tire width
        {
          name: "meta.tireWidthFront",
          label: `${i18next.t("form:width")}`,
        },
        // Tire serie
        {
          name: "meta.tireSerieFront",
          label: `${i18next.t("form:serie")}`,
        },
        // Tire diameter
        {
          name: "meta.tireDiameterFront",
          label: `${i18next.t("form:diameter")}`,
        },
        // Tire speed code
        {
          name: "meta.tireSpeedCodeFront",
          label: `${i18next.t("form:speedCode")}`,
        },
        // Tire load index
        {
          name: "meta.tireLoadIndexFront",
          label: `${i18next.t("form:loadIndex")}`,
        },
        // Wheels number
        {
          name: "meta.wheelsNumberFront",
          label: `${i18next.t("form:wheelsNumber")}`,
        },

        // Title - Ruote Posteriori | Copia valori
        {
          element: (
            <div
              className="display-flex-vertical-center gap-extra-small"
              style={{ color: "var(--default-background-header)" }}
            >
              <Typography variant="overline" className="text-bold">
                {i18next.t("form:rearTyres")}
              </Typography>
            </div>
          ),
        },

        // Tire width
        {
          name: "meta.tireWidthRear",
          label: `${i18next.t("form:width")}`,
        },
        // Tire serie
        {
          name: "meta.tireSerieRear",
          label: `${i18next.t("form:serie")}`,
        },
        // Tire diameter
        {
          name: "meta.tireDiameterRear",
          label: `${i18next.t("form:diameter")}`,
        },
        // Tire speed code
        {
          name: "meta.tireSpeedCodeRear",
          label: `${i18next.t("form:speedCode")}`,
        },
        // Tire load index
        {
          name: "meta.tireLoadIndexRear",
          label: `${i18next.t("form:loadIndex")}`,
        },
        // Wheels number
        {
          name: "meta.wheelsNumberRear",
          label: `${i18next.t("form:wheelsNumber")}`,
        },
      ];

    if (activeQuotation) {
      let meta = activeQuotation.dynamicMetadata
        ? JSON.parse(activeQuotation.dynamicMetadata)
        : {};

      return (
        <div style={{ marginTop: "1em" }}>
          {metamap.map(
            (
              x: { name?: string; label?: string; element?: JSX.Element },
              i: number
            ) => {
              return (
                <div key={i} className="tire-detail-box">
                  {x.element}
                  <strong>{x.label}</strong>
                  {meta && x.name && (
                    <div>{meta[x.name.replace("meta.", "")]}</div>
                  )}
                </div>
              );
            }
          )}
        </div>
      );
    }
    return <div></div>;
  };

  const [filePreviewData, setFilePreviewData] = useState<string[]>([]);

  const base64size = () => {
    if (filePreviewData.length > 1) {
      let str = filePreviewData[1];
      let size = str.length * (3 / 4) - (str.endsWith("==") ? 2 : 1);

      const formatSizeUnits = (bytes: number) => {
        if (bytes >= 1073741824) {
          return (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
          return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
          return (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
          return bytes + " bytes";
        } else if (bytes == 1) {
          return bytes + " byte";
        } else {
          return "0 bytes";
        }
      };
      return formatSizeUnits(size);
    }
    return "";
  };

  const fileSize = base64size();

  const previewFileWithName = (path: string, fileName: string) => {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest();

    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var file = new Blob([xhr.response], { type: "application/octet-stream" });

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result as string;
        let data: string[] = [fileName, result];

        setFilePreviewData(data);
        setTimeout(() => {
          SummonModal("preview_modal_attach");
        }, 500);
      };
    };
    xhr.send();
  };

  const downloadFile = (blobUri: string) => {
    ApiService.UploadController.GetSASString(
      "attachment-forquotationsolutions",
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  const previewFile = (blobUri: string) => {
    setFilePreviewData([]);
    ApiService.UploadController.GetSASString(
      "attachment-forquotationsolutions",
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            previewFileWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  const hasMeta = (meta: string | undefined) => {
    if (meta) {
      let asJson = meta !== "" ? JSON.parse(meta) : {};
      delete asJson.servedQuotation;
      return Object.keys(asJson).length > 0;
    }
    return false;
  };

  return (
    <div>
      <div className="dashboardForm__box">
        <strong>{i18next.t("navigation:quotesSolutions")}</strong>
        <div className={"margin-vertical-small"}>
          <DataGridWrap
            headers={columnsDef}
            rows={quotations
              .map((x: any) => {
                let meta = JSON.parse(
                  x.dynamicMetadata !== "" ? x.dynamicMetadata : "{}"
                );
                x["handle_required"] = meta.servedQuotation === true;
                x["createdDT_"] = displayUTC0_ISODate(x.createdDT);
                return x;
              })
              .reverse()}
          />
        </div>
      </div>
      <SmartModal
        title={i18next.t("navigation:quotations_request_handle_long")}
        onAccept={() => {
          triggerFormValidation("solution-proposal-form");
        }}
        onReject={() => {
          DismissModal("send-solution-modal");
        }}
        modalUniqueId="send-solution-modal"
        modalInnerComponent={
          <div className="modal-width-large">
            <strong>{i18next.t("navigation:quotation_op_type")}</strong>
            <div>
              {renderInterventionTypeString(activeQuotation?.interventionType)}
            </div>

            {activeQuotation && (
              <div>
                {activeQuotation.note && (
                  <div>
                    <strong>{i18next.t("navigation:quotations_notes")}</strong>
                    <br />
                    {activeQuotation?.note}
                    <br />
                  </div>
                )}
                {activeQuotation.attachmentFile && (
                  <div>
                    <br />
                    <strong>{i18next.t("navigation:quotations_file")}</strong>
                    <br />
                    <div className="quote-image-wrap">
                      <img src={activeQuotation?.attachmentFile} />
                    </div>
                    <br />
                  </div>
                )}
                {hasMeta(activeQuotation.dynamicMetadata) && (
                  <div>
                    <br />
                    <strong>
                      {i18next.t("navigation:quotations_detail_tire")}
                    </strong>
                    <br />
                    <div className="quote-image-wrap">{metaMap()}</div>
                    <br />
                  </div>
                )}
                <hr />
                {i18next.t("navigation:quotations_request_handle_longest")}
                <MultiForm
                  formId="solution-proposal-form"
                  formTitle={""}
                  suppressLayout
                  suppressSubmit
                  onSubmit={(data: any) => {
                    if (!file) {
                      ToastMessage(
                        i18next.t("navigation:pleaseUpload"),
                        "warning"
                      );
                      return;
                    }
                    sendSolution(data);
                  }}
                  inputs={[
                    {
                      width: 100,
                      type: "multiline",
                      label: i18next.t("form:notes") ?? "",
                      multilineRows: 5,
                      name: "notes",
                      required: true,
                    },
                    {
                      width: 100,
                      type: "custom",
                      name: "attachmentFile",
                      required: true,
                      label: `${i18next.t("navigation:uploadFile")}`,
                      element: (
                        <div>
                          {!file && (
                            <UploadForm
                              name={"attachmentFile"}
                              accept={"application/pdf"}
                              onFileChanged={updateFile}
                              title={i18next.t("form:uploadSolutionFile")}
                              description={i18next.t(
                                "form:uploadSolutionFileDesc"
                              )}
                            />
                          )}
                          {file && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <strong>{file.name}</strong>{" "}
                              <Button
                                onClick={() => {
                                  setFile(null);
                                }}
                              >
                                {i18next.t("navigation:change")}
                              </Button>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        }
      />
      <SmartModal
        title={i18next.t("navigation:quotations_request_handle_long_readonly")}
        onReject={() => {
          DismissModal("send-inspect-solution-modal");
        }}
        modalUniqueId="send-inspect-solution-modal"
        modalInnerComponent={
          <div style={{ minWidth: "80vw" }}>
            <strong>{i18next.t("navigation:quotation_op_type")}</strong>
            <div>
              {renderInterventionTypeString(activeQuotation?.interventionType)}
            </div>
            {activeQuotation && (
              <div>
                {activeQuotation.note && (
                  <div>
                    <strong>{i18next.t("navigation:quotations_notes")}</strong>
                    <br />
                    {activeQuotation?.note}
                    <br />
                  </div>
                )}
                {activeQuotation.attachmentFile && (
                  <div>
                    <br />
                    <strong>{i18next.t("navigation:quotations_file")}</strong>
                    <br />
                    <div className="quote-image-wrap">
                      <img src={activeQuotation?.attachmentFile} />
                    </div>
                    <br />
                  </div>
                )}

                {hasMeta(activeQuotation.dynamicMetadata) && (
                  <div>
                    <br />
                    <strong>
                      {i18next.t("navigation:quotations_detail_tire")}
                    </strong>
                    <br />
                    <div className="quote-image-wrap">{metaMap()}</div>
                    <br />
                  </div>
                )}
                <hr />

                {activeSolution && (
                  <div>
                    <strong>{i18next.t("form:note")}</strong>
                    <div>{activeSolution.note}</div>
                    <br />

                    <strong>File:</strong>
                    <br />
                    <div className="button-row">
                      <IconButton
                        onClick={() => {
                          previewFile(activeSolution.attachmentFile ?? "");
                        }}
                      >
                        <VisibilityIcon
                          className="icon-table"
                          onClick={() => {}}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          downloadFile(activeSolution.attachmentFile ?? "");
                        }}
                      >
                        <FileDownloadIcon
                          className="icon-table"
                          onClick={() => {}}
                        />
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
      {filePreviewData.length > 0 && (
        <SmartModal
          modalUniqueId="preview_modal_attach"
          title={filePreviewData[0]}
          modalInnerComponent={
            <div>
              {fileSize.includes("GB") ||
                (fileSize.includes("MB") && (
                  <Alert severity="info">
                    {i18next.t("navigation:too_big_pls_wait")} {fileSize}
                  </Alert>
                ))}
              <br />
              <iframe
                style={{ width: "80vw", height: "80vh" }}
                src={filePreviewData[1].replaceAll(
                  "application/octet-stream",
                  "application/pdf"
                )}
              ></iframe>
            </div>
          }
          onReject={() => {
            DismissModal("preview_modal_attach");
          }}
        />
      )}
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default QuotesPageProposed;
