/* REACT */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* API */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* LIBRARIES  */
import i18next from "i18next";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { IAccountType } from "../../../../../Models/IAccountType";
import { ICountry } from "../../../../../Models/ICountry";
import { IBrokerCompanyPost } from "../../../../../Models/IBroker";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import CustomStepper, {
  ICustomStepperItem,
  stepperGoToPage,
} from "../../../../../Components/CustomStepper/CustomStepper";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import UploadForm from "../../../../../Components/UploadForm/UploadForm";

/* UTILS */
import {
  validationVAT,
  validationFiscalCode,
  validationPhone,
} from "../../../../../Utils/Validation";
import { IGoogleAddress } from "../../../../../Utils/Google";
import {
  getCompressedBase64,
  getCompressedFileName,
} from "../../../../../Utils/FileToBase";

/* REDUCER */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* MUI */
// Icons
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import StepFleetPlan from "../../../../../Components/StepFleetPlan/StepFleetPlan";
import StepPayment from "../../../../../Components/StepPayment/StepPayment";
import { UserActions } from "../../../../../Reducers/User/UserAction";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { IAccountRegistrationStepInsert } from "../../../../../Models/IRegistration";
import { RegistrationAction } from "../../../../../Reducers/Registration/RegistrationAction";
import { ISavePlanService } from "../../../../../Models/IFleetPlan";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { writePaymentData } from "../../../../PaymentReadyPage/PaymentReadyPage";
import { useNavigate } from "react-router-dom";

/*INTERFACE*/
export interface IFormClient {
  loggedUser: IUser | undefined;
  data: any;
  sideList?: any;
  doRefreshForm?: () => void;
  loadSideList?: () => void;
  isInModal?: boolean;
  idModal?: string;
}

var dataClone: any = {};
var billingInfoClone: any = {};

/* COMPONENT */
const FormClient = (props: IFormClient) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const subscriptionPlanService: ISavePlanService | undefined = useSelector(
    (state: GlobalState) => state.registration.planService
  );
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(props.data ?? {});

  // State for account type select
  const [accountType, setAccountType] = useState<IAccountType[]>([]);

  const [newAccountID, setNewAccountID] = useState<number | null>(null);

  // State for countries select
  const [countries, setCountries] = useState<ICountry[]>([]);

  // State for spinning the process
  const [loadingAccountType, setLoadingAccountType] = useState<boolean>(false);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
  const [loadingInsertBrokerCompany, setLoadingInsertBrokerCompany] =
    useState<boolean>(false);
  const [loadingUpdateBrokerCompany, setLoadingUpdateBrokerCompany] =
    useState<boolean>(false);
  const [loadingDeleteBrokerCompany, setLoadingDeleteBrokerCompany] =
    useState<boolean>(false);

  const [dataPlanService, setDataPlanService] = useState<any>({});
  const [disabledForm, setDisabledForm] = useState<boolean>(false);
  const [onlyPlans, setOnlyPlans] = useState<boolean>(false);
  const [stepsClientForm, setStepsClientForm] = useState<any[]>([1, 2, 3]);
  const [billingInfos, setBillingInfos_] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [data, setData_] = useState<any>({
    price: -1,
  });

  const setBillingInfos = (infos: any) => {
    billingInfoClone = { ...infos };
    setBillingInfos_(infos);
  };

  const setData = (data: any) => {
    dataClone = { ...data };
    setData_(data);
  };
  /* APIs */
  /* GET */
  // API to load account type
  const loadAccountType = () => {
    setLoadingAccountType(true);
    ApiService.AccountController.AccountType((response: IAPIResponse) => {
      if (response.error === null) {
        setAccountType(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingAccountType(false);
    });
  };

  // API to load countries
  const loadCountries = () => {
    setLoadingCountries(true);
    ApiService.AccountController.AccountCountry((response: IAPIResponse) => {
      if (response.error === null) {
        setCountries(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingCountries(false);
    });
  };

  const updateBrokerCompany = (data: IBrokerCompanyPost) => {
    setLoadingUpdateBrokerCompany(true);
    ApiService.BrokerController.BrokerCompanyUpdate(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:clientSuccessfullUpdated"), "success");
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingUpdateBrokerCompany(false);
    });
  };

  /* INSERT */
  // API to insert a broker company
  const insertBrokerCompany = (data: IBrokerCompanyPost) => {
    setLoadingInsertBrokerCompany(true);
    ApiService.BrokerController.BrokerCompanyInsert(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:clientSuccessfullAdded"), "success");
        if (props.doRefreshForm) {
          props.doRefreshForm();
        }
        setDisabledForm(true);
        setNewAccountID(response.payload);
        ApiService.BrokerController.BrokerInvitationsInsert(
          response.payload,
          loggedUser?.accountID
        );

        let registrationStepData: IAccountRegistrationStepInsert = {
          accountID: response.payload,
          accountTypeID: data.accountTypeID,
          isbrokerpay: data.isBrokerPay,
        };

        ApiService.AccountController.AccountRegistrationStepInsert(
          registrationStepData,
          (response: IAPIResponse) => {
            if (response.error === null) {
              if (props.idModal && !data.isBrokerPay) {
                DismissModal(props.idModal as string);
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        );

        if (data.isBrokerPay) {
          stepperGoToPage(1);
        }
      } else {
        if (response.payload.detail) {
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
        } else {
          ToastMessage(response.error, "error");
        }
      }
      setLoadingInsertBrokerCompany(false);
    });
  };

  /* DELETE */
  // API to delete a client
  const deleteBrokerCompany = (brokerID: number, companyID: number) => {
    setLoadingDeleteBrokerCompany(true);
    ApiService.BrokerController.BrokerCompanyDelete(
      brokerID,
      companyID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:clientSuccessfullDeleted"), "success");
          setState({});
          if (props.loadSideList) {
            props.loadSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDeleteBrokerCompany(false);
      }
    );
  };

  /* FUNCTIONS */
  // Update with a new file
  const updateFile = (files: File[]) => {
    getCompressedBase64(files.length > 0 ? files[0] : null, (result: string | null) => {
      let oldState = { ...state };
      oldState["companyImageURL"] = result;
      setState(oldState);
    });
  };

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: 33.33,
        type: "select",
        name: "accountTypeID",
        disabled: disabledForm,
        defaultValue: state.accountTypeID ?? "1",
        required: true,
        label: `${i18next.t("form:userTypePlaceholder")}`,
        options: accountType.map((accountType: IAccountType) => {
          return {
            key: accountType.accountTypeID.toString(),
            text: accountType.accountTypeName,
          };
        }),
      },
      {
        width: 33.33,
        type: "text",
        disabled: disabledForm,
        name: "companySDIPEC",
        defaultValue: state?.companySDIPEC ?? "",
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:SDICodeOrPEC")}`,
      },
      {
        disabled: disabledForm,
        type: "radio",
        required: true,
        name: "isBrokerPay",
        defaultValue: state?.isBrokerPay ?? "",
        label: i18next.t("form:clientPayment").toString(),
        width: 33.33,
        options: [
          {
            key: "true",
            text: i18next.t("form:broker"),
          },
          {
            key: "false",
            text: i18next.t("form:client"),
          },
        ],
      }
    );

    // Check if natural or legal user
    if (state.accountTypeID == 2) {
      multiformInputs.push({
        width: 50,
        disabled: disabledForm,
        type: "text",
        name: "companyBusinessName",
        defaultValue: state?.companyBusinessName,
        required: true,
        inputProps: { maxLength: 50 },
        label: `${i18next.t("form:nameCompany")}`,
      });
    }

    multiformInputs.push(
      {
        width: state.accountTypeID == 2 ? 25 : 50,
        disabled: disabledForm,
        type: "text",
        name: "companyReferentName",
        defaultValue: state?.companyReferentName,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:nameReferent")}`,
      },
      {
        width: state.accountTypeID == 2 ? 25 : 50,
        type: "text",
        name: "companyReferentSurname",
        disabled: disabledForm,
        defaultValue: state?.companyReferentSurname,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:surnameReferent")}`,
      },
      {
        disabled: disabledForm,
        width: 50,
        type: "text",
        name: "companyVAT",
        defaultValue: state?.companyVAT,
        required: state.accountTypeID == 2 ? true : false,
        inputProps: { maxLength: 13 },
        label: `${i18next.t("form:vatNumber")}`,
        errorText:
          state?.companyVAT &&
          state?.companyVAT.length > 0 &&
          !validationVAT(state?.companyVAT)
            ? (i18next.t("form:validationIVA") as string)
            : "",
      },
      {
        width: 50,
        disabled: disabledForm,
        type: "select",
        name: "companyReferentCountryID",
        defaultValue: state?.companyReferentCountryID,
        required: true,
        label: `${i18next.t("form:countryResidence")}`,
        options: countries.map((country: ICountry) => {
          return {
            key: country.countryID.toString(),
            text: country.countryName,
          };
        }),
      },
      {
        width: 34,
        disabled: disabledForm,
        type: "text",
        name: "companyFiscalCode",
        defaultValue: state?.companyFiscalCode,
        required: state.accountTypeID == 1 ? true : false,
        inputProps: {
          maxLength: 16,
          style: { textTransform: "uppercase" },
        },
        label: `${i18next.t("form:taxIdCode")}`,
        errorText:
          state?.companyFiscalCode &&
          state?.companyFiscalCode.length > 0 &&
          !validationFiscalCode(state?.companyFiscalCode)
            ? (i18next.t("form:validationCF") as string)
            : "",
      },
      {
        width: 33,
        disabled: disabledForm,
        type: "email",
        name: "companyReferentEmail",
        defaultValue: state?.companyReferentEmail,
        tooltip: {
          title: i18next.t("form:tooltipEmailReferent"),
        },
        inputProps: { maxLength: 100 },
        required: true,
        label: String(i18next.t("form:email")),
      },
      {
        width: 33,
        disabled: disabledForm,
        type: "tel",
        name: "companyReferentPhone",
        defaultValue: state?.companyReferentPhone,
        required: false,
        inputProps: { maxLength: 20 },
        label: `${i18next.t("form:telephonePersonal")}`,
        errorText: state?.companyReferentPhone
          ? validationPhone(state.companyReferentPhone) ||
            state.companyReferentPhone === null ||
            state.companyReferentPhone === ""
            ? ""
            : (i18next.t("form:validationPhone") as string)
          : "",
      },
      {
        disabled: disabledForm,
        width: 50,
        name: "googleAddress",
        fullWidth: true,
        type: "custom",
        className: "content-place-auto-complete",
        element: (
          <PlaceAutoCompleteForm
            onChange={(data: IGoogleAddress) => {
              if (!disabledForm) {
                setState({ ...state, ...data });
              }
            }}
            addressId="companyBillingStreet"
            disableForm={false}
            inputs={[
              {
                width: 70,
                type: "text",
                required: true,
                disabled: disabledForm,
                name: "companyBillingStreet",
                inputProps: { maxLength: 100 },
                defaultValue: state?.companyBillingStreet,
                mapDecode: "route",
                label: String(i18next.t("form:addressLegal")),
              },
              {
                width: 30,
                type: "text",
                disabled: disabledForm,
                required: true,
                name: "companyBillingNumber",
                defaultValue: state?.companyBillingNumber,
                mapDecode: "street_number",
                inputProps: { maxLength: 10 },
                label: String(i18next.t("form:civicNumberAbbr")),
              },
              {
                width: 100,
                disabled: disabledForm,
                required: true,
                type: "select_nations",
                name: "companyBillingCountryID",
                currentValue: state?.companyBillingCountryID ?? "",
                defaultValue: state?.companyBillingCountryID,
                mapDecode: "country",
                label: String(i18next.t("form:countryBilling")),
              },
              {
                width: 25,
                disabled: disabledForm,
                type: "number",
                required: true,
                name: "companyBillingCAP",
                defaultValue: state?.companyBillingCAP,
                mapDecode: "postal_code",
                inputProps: { maxLength: 5 },
                label: String(i18next.t("form:postalCode")),
              },
              {
                width: 50,
                type: "text",
                disabled: disabledForm,
                required: true,
                name: "companyBillingCity",
                defaultValue: state?.companyBillingCity,
                mapDecode: "locality",
                inputProps: { maxLength: 50 },
                label: String(i18next.t("form:city")),
              },
              {
                width: 25,
                disabled: disabledForm,
                type: "text",
                required: true,
                name: "companyBillingProv",
                defaultValue: state?.companyBillingProv,
                inputProps: { maxLength: 50 },
                mapDecode: "province",
                label: String(i18next.t("form:provinceAbbr")),
              },
            ]}
          />
        ),
      },
      {
        width: 50,
        type: "custom",
        name: "companyImageURL",
        defaultValue: state?.companyImageURL,
        required: true,
        label: `${i18next.t("form:titleImageImageCompanyUpload")}`,
        disabled: disabledForm,
        element: (
          <UploadForm
            disabled={disabledForm}
            initialFiles={
              state && state.companyImageURL
                ? [getCompressedFileName(state.companyImageURL)]
                : []
            }
            name={"companyImageURL"}
            title={i18next.t("form:subtitleImageCompanyUpload")}
            description={i18next.t("form:descriptionImageUpload")}
            onFileChanged={updateFile}
            accept={"image/*"}
          />
        ),
      }
    );
    if (state && state?.brokerID && state?.companyID) {
      multiformInputs.push({
        width: 100,
        type: "custom",
        name: "save",
        element: (
          <div className="display-flex-end">
            <Button
              color="error"
              onClick={() => {
                SummonModal(`deleteModal_FormBrokerCompany`);
              }}
              variant="contained"
              endIcon={<DeleteIcon />}
            >
              {i18next.t("generic:delete")}
            </Button>
            <SmartModal
              modalUniqueId={`deleteModal_FormBrokerCompany`}
              title={i18next.t("generic:titleRecordModal")}
              modalInnerComponent={
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18next
                      .t("generic:descriptionRecordModal")
                      .replace(
                        "CURRENT_ITEM_LIST",
                        state?.companyReferentName + " " + state?.companyReferentSurname
                      ),
                  }}
                />
              }
              onAccept={() => {
                deleteBrokerCompany(state?.brokerID, state?.companyID);
              }}
              onReject={() => {}}
            />
          </div>
        ),
      });
    }

    // Return the full and complete Multiform
    return multiformInputs;
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadAccountType();
    loadCountries();
  }, []);

  useEffect(() => {
    if ((props.data && props.data.isBrokerPay) || state?.isBrokerPay === "true") {
      if (props.data && props.data.accountID) {
        setNewAccountID(props.data.accountID);
      }
      setOnlyPlans(true);
      setStepsClientForm([1, 2]);
    } else {
      setStepsClientForm([1]);
      setOnlyPlans(false);
      setNewAccountID(null);
    }
    dispatch(UserActions.setCurrentBrokerCompany(state));
  }, [state]);

  useEffect(() => {
    if (props.data && props.data.companyID) {
      setDisabledForm(true);
      setState(props.data);
    }
  }, [props.data]);

  const availableSteps: (ICustomStepperItem | undefined)[] = stepsClientForm.map(
    (item: number) => {
      switch (item) {
        case 1:
          // Step 1 - Continue Registration
          return {
            element: (
              <MultiForm
                suppressLayout
                suppressSubmit
                classNameForm="multi-form-flex"
                formId={"FormClient"}
                formTitle={"FormClient"}
                inputs={multiformInputs()}
                onChange={(data: any) => {
                  if (!disabledForm) {
                    setState({ ...state, ...data });
                  }
                }}
                onSubmit={() => {
                  if (props.loggedUser) {
                    state["brokerID"] = props.loggedUser.brokerID;
                  }
                  state["isBrokerPay"] = state.isBrokerPay === "true" ? true : false;

                  // Convert string to number
                  state["accountTypeID"] = +state["accountTypeID"];
                  state["companyBillingCountryID"] = +state["companyBillingCountryID"];
                  state["companyReferentCountryID"] = +state["companyReferentCountryID"];
                  state["companyFiscalCode"] = state?.companyFiscalCode?.toUpperCase();

                  if (state.accountTypeID == 1) {
                    state["companyBusinessName"] = "";
                  }

                  // Delete unuseful state
                  delete state["googleAddress"];
                  delete state["save"];

                  // Lunch the API based on the accountID
                  // (if present the element already exist so UPDATE, if not INSERT)
                  if (!state?.companyID) {
                    insertBrokerCompany(state);
                  }
                }}
              />
            ),
            stepTitle: i18next.t("navigation:registration_client"),
            onNext: () => {
              triggerFormValidation("FormClient");
              return disabledForm;
            },
          } as ICustomStepperItem;
        case 2:
          // Step 9 - Payment
          return {
            element: (
              <StepFleetPlan
                onlyPlans={onlyPlans}
                subscriptionID={state?.subscriptionID}
                newAccountID={newAccountID}
                nextPage={2}
                emitState={(dt: any) => {
                  dt.insertPlanMethod = true;
                  dt.accountID = newAccountID;
                  setData(dt);
                  if (dt.paymentEvaluation && +dt.paymentEvaluation.cost != 0) {
                    setPage(1);
                  }
                }}
                includeBillingForm
                emitBillingInfos={(data: any) => {
                  setBillingInfos(data);
  
                  if (
                    data &&
                    data.billingEmail &&
                    Object.values(data).indexOf(null) === -1
                  ) {
                    setPage(1);
                  }
                }}
              />
            ),
            stepTitle: i18next.t("navigation:registration_client_fleetPlan"),
          } as ICustomStepperItem;
      }
    }
  );

  /* RETURN */
  return (
    <>
      {props.data && (
        <div>
          <MultiForm
            suppressLayout
            suppressSubmit
            classNameForm="multi-form-flex"
            formId={"FormClientUpdate"}
            formTitle={"FormClient"}
            inputs={multiformInputs()}
            onChange={async (data: any) => {
              if (!disabledForm) {
                setState({ ...state, ...data });
              }
            }}
            onSubmit={async () => {
              if (props.loggedUser) {
                state["brokerID"] = props.loggedUser.brokerID;
              }
              state["isBrokerPay"] = state.isBrokerPay === "true" ? true : false;

              // Convert string to number
              state["accountTypeID"] = +state["accountTypeID"];
              state["companyBillingCountryID"] = +state["companyBillingCountryID"];
              state["companyReferentCountryID"] = +state["companyReferentCountryID"];
              state["companyFiscalCode"] = state?.companyFiscalCode?.toUpperCase();

              if (state.accountTypeID == 1) {
                state["companyBusinessName"] = "";
              }

              // Delete unuseful state
              delete state["googleAddress"];
              delete state["save"];

              updateBrokerCompany(state);
            }}
          />
        </div>
      )}
      {!props.data && (
        <CustomStepper
          isCustomDashboard
          isNextStep
          stepperNodes={
            availableSteps.filter(
              (x: ICustomStepperItem | undefined) => x !== undefined
            ) as ICustomStepperItem[]
          }
          onFinish={() => {
            if (stepsClientForm.length === 2) {
              writePaymentData({
                ...dataClone,
                ...billingInfoClone,
              });
              navigate(AppRoutes.PAYMENT_READY_PAGE);
            } else {
              if (!disabledForm) {
                triggerFormValidation("FormClient");
              } else {
                if (props.idModal) {
                  DismissModal(props.idModal as string);
                }
              }
            }
          }}
        />
      )}

      <LoaderBackdrop
        loading={
          loadingAccountType ||
          loadingCountries ||
          loadingInsertBrokerCompany ||
          loadingUpdateBrokerCompany ||
          loadingDeleteBrokerCompany
        }
      />
    </>
  );
};

export default FormClient;
