/* REACT */
import { useEffect, useState } from "react";

/* API */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* LIBRARIES  */
import i18next from "i18next";
/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { IAccountType } from "../../../../../Models/IAccountType";
import { ICountry } from "../../../../../Models/ICountry";
import {
  IBrokerDelete,
  IBrokerPost,
  IBrokerPut,
} from "../../../../../Models/IBroker";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";

/* UTILS */
import {
  validationVAT,
  validationFiscalCode,
  validationPhone,
} from "../../../../../Utils/Validation";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import { IGoogleAddress } from "../../../../../Utils/Google";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Checkbox, CircularProgress } from "@mui/material";
import { JSONPrint } from "../../../../../Utils/Decoder";
import FormControlLabel from "@mui/material/FormControlLabel";

/*INTERFACE*/
export interface IFormBroker {
  loggedUser: IUser | undefined;
  data: any;
  sideList?: any;
  doRefreshForm?: () => void;
  loadSideList?: () => void;
  isInModal?: boolean;
  idModal?: string;
}

const BrokerTypeSubform = (props: {
  brokerId?: number;
  emit: (typeList: string[]) => void;
}) => {
  const supportedTypes: string[][] = [
    ["RENT_SERVICE", i18next.t("navigation:_broker_type_r")],
    ["MECHANIC_SERVICE", i18next.t("navigation:_broker_type_m")],
    ["CARBODY_SERVICE", i18next.t("navigation:_broker_type_c")],
    ["TIRE_SERVICE", i18next.t("navigation:_broker_type_t")],
    ["DEALER_SERVICE", i18next.t("navigation:_broker_type_d")],
  ];

  const [currentTypes, setCurrentTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getTypesForBroker = () => {
    setLoading(true);
    ApiService.UserTypesController.BrokerTypeGet(
      props.brokerId,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCurrentTypes(
            response.payload.map((x: any) => {
              return x.brokerType;
            })
          );
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (props.brokerId) {
      getTypesForBroker();
    } else {
      setCurrentTypes([]);
    }
  }, [props.brokerId]);

  return (
    <div>
      <div>{i18next.t("navigation:_broker_type")}</div>
      {loading && <CircularProgress />}
      {!loading && (
        <div>
          {supportedTypes.map((x: string[], i: number) => {
            return (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={currentTypes.includes(x[0])}
                      onChange={(e: any, check: boolean) => {
                        let t: string[] = [...currentTypes];
                        if (check) {
                          t.push(x[0]);
                        } else {
                          t = t.filter((y: string) => y !== x[0]);
                        }
                        setCurrentTypes(t);
                        props.emit(t);
                      }}
                    />
                  }
                  label={x[1]}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

/* COMPONENT */
const FormBroker = (props: IFormBroker) => {
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(props.data);

  // State for account type select
  const [accountType, setAccountType] = useState<IAccountType[]>([]);

  // State for countries select
  const [countries, setCountries] = useState<ICountry[]>([]);

  // State for spinning the process
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingAccountType, setLoadingAccountType] = useState<boolean>(false);
  const [loadingCountires, setLoadingCountires] = useState<boolean>(false);
  const [loadingInsertBroker, setLoadingInsertBroker] =
    useState<boolean>(false);
  const [loadingUpdateBroker, setLoadingUpdateBroker] =
    useState<boolean>(false);
  const [loadingDeleteBroker, setLoadingDeleteBroker] =
    useState<boolean>(false);

  const [typesForBroker, setTypesForBroker] = useState<string[] | undefined>(
    undefined
  );

  /* APIs */
  /* GET */
  // API to load account type
  const loadAccountType = () => {
    setLoadingAccountType(true);
    ApiService.AccountController.AccountType((response: IAPIResponse) => {
      if (response.error === null) {
        setAccountType(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingAccountType(false);
    });
  };

  // API to load countries
  const loadCountries = () => {
    setLoadingCountires(true);
    ApiService.AccountController.AccountCountry((response: IAPIResponse) => {
      if (response.error === null) {
        setCountries(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingCountires(false);
    });
  };

  const saveTypesForBroker = (brokerid: number) => {
    if (typesForBroker) {
      ApiService.UserTypesController.BrokerTypesInser(
        typesForBroker.join(":"),
        brokerid,
        (response: IAPIResponse) => {
          if (response.error === null) {
          }
        }
      );
    }
  };

  /* INSERT */
  // API to insert a broker
  const insertBroker = (data: IBrokerPost) => {
    setLoadingInsertBroker(true);

    ApiService.BrokerController.BrokerInsert(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:brokerSuccessfullAdded"), "success");
        saveTypesForBroker(response.payload.brokerID);

        setState({}); // Clean the state so inputs are empty
        if (props.loadSideList) {
          props.loadSideList();
        }
        if (props.doRefreshForm) {
          props.doRefreshForm();
        }
        if (props.idModal) {
          DismissModal(props.idModal as string);
        }
      } else {
        if (response.payload && response.payload.detail) {
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
        } else {
          ToastMessage(response.error, "error");
        }
      }
      if (props.isInModal && props.idModal !== "") {
        DismissModal(props.idModal as string);
      }
      setLoadingInsertBroker(false);
    });
  };

  /* UPDATE */
  // API to update a broker
  const updateBroker = (data: IBrokerPut) => {
    setLoadingUpdateBroker(true);

    ApiService.BrokerController.BrokerUpdate(data, (response: IAPIResponse) => {
      if (response.error === null) {
        saveTypesForBroker(data.brokerID);

        ToastMessage(i18next.t("message:brokerSuccessfullUpdated"), "success");
        if (props.loadSideList) {
          props.loadSideList();
        }
        if (props.doRefreshForm) {
          props.doRefreshForm();
        }
        if (props.idModal) {
          DismissModal(props.idModal as string);
        }
      } else {
        if (response.payload.detail) {
          ToastMessage(i18next.t("error:" + response.payload.detail), "error");
        } else {
          ToastMessage(response.error, "error");
        }
      }
      setLoadingUpdateBroker(false);
    });
  };

  /* DELETE */
  // API to delete a broker
  const deleteBroker = (data: IBrokerDelete) => {
    setLoadingDeleteBroker(true);
    if (data === null) {
      ToastMessage(i18next.t("message:brokerNotDeleted"), "warning");
    }
    ApiService.BrokerController.BrokerDelete(
      data.brokerID,
      data.brokerAccountID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:brokerSuccessfullDeleted"),
            "success"
          );
          setState({}); // Clean the state so inputs are empty
          if (props.loadSideList) {
            props.loadSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDeleteBroker(false);
      }
    );
  };

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: 50,
        type: "select",
        name: "brokerAccountTypeID",
        defaultValue: state.brokerAccountTypeID ?? "1",
        required: true,
        label: `${i18next.t("form:userTypePlaceholder")}`,
        options: accountType.map((accountType: IAccountType) => {
          return {
            key: accountType.accountTypeID.toString(),
            text: i18next.t(`form:userType_${accountType.accountTypeID}`),
          };
        }),
      },
      {
        width: 50,
        type: "text",
        name: "brokerSDIPEC",
        defaultValue: state.brokerSDIPEC,
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:SDICodeOrPEC")}`,
      }
    );

    // Check if natural or legal user
    if (state.brokerAccountTypeID == 2) {
      multiformInputs.push({
        width: 50,
        type: "text",
        name: "brokerBusinessName",
        defaultValue: state.brokerBusinessName,
        required: true,
        inputProps: { maxLength: 50 },
        label: `${i18next.t("form:nameCompany")}`,
      });
    }

    multiformInputs.push(
      {
        width: state.brokerAccountTypeID == 2 ? 25 : 50,
        type: "text",
        name: "brokerName",
        defaultValue: state.brokerName,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:brokerName")}`,
      },
      {
        width: state.brokerAccountTypeID == 2 ? 25 : 50,
        type: "text",
        name: "brokerSurname",
        defaultValue: state.brokerSurname,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:brokerSurname")}`,
      },
      {
        width: 50,
        type: "text",
        name: "brokerVAT",
        defaultValue: state.brokerVAT,
        required: state.brokerAccountTypeID == 2 ? true : false,
        inputProps: { maxLength: 13 },
        label: `${i18next.t("form:vatNumber")}`,
        errorText:
          state?.brokerVAT &&
          state?.brokerVAT.length > 0 &&
          !validationVAT(state?.brokerVAT)
            ? (i18next.t("form:validationIVA") as string)
            : "",
      },
      {
        width: 50,
        type: "select",
        name: "brokerCountryID",
        defaultValue: state.brokerCountryID,
        required: true,
        label: `${i18next.t("form:countryResidence")}`,
        options: countries.map((country: ICountry) => {
          return {
            key: country.countryID,
            text: i18next.t(`countries:${country.countryISO}`),
          };
        }),
      },
      {
        width: 34,
        type: "text",
        name: "brokerFiscalCode",
        defaultValue: state?.brokerFiscalCode,
        required: state.brokerAccountTypeID == 1 ? true : false,
        inputProps: {
          maxLength: 16,
          style: { textTransform: "uppercase" },
        },
        label: `${i18next.t("form:taxIdCode")}`,
        errorText:
          state?.brokerFiscalCode &&
          state?.brokerFiscalCode.length > 0 &&
          !validationFiscalCode(state?.brokerFiscalCode)
            ? (i18next.t("form:validationCF") as string)
            : "",
      },
      {
        width: 33,
        type: "email",
        name: "brokerEmail",
        defaultValue: state?.brokerEmail,
        tooltip: {
          title: i18next.t("form:tooltipEmailReferent"),
        },
        inputProps: { maxLength: 100 },
        required: true,
        label: String(i18next.t("form:email")),
      },
      {
        width: 33,
        type: "tel",
        name: "brokerPhone",
        defaultValue: state?.brokerPhone,
        required: false,
        inputProps: { maxLength: 20 },
        label: `${i18next.t("form:telephonePersonal")}`,
        errorText: state?.brokerPhone
          ? validationPhone(state.brokerPhone) ||
            state.brokerPhone === null ||
            state.brokerPhone === ""
            ? ""
            : (i18next.t("form:validationPhone") as string)
          : "",
      },
      {
        width: 100,
        name: "googleAddress",
        fullWidth: true,
        type: "custom",
        className: "content-place-auto-complete",
        element: (
          <PlaceAutoCompleteForm
            onChange={(data: IGoogleAddress) => {
              setState({ ...state, ...data });
            }}
            addressId="brokerBillingStreet"
            disableForm={processing}
            inputs={[
              {
                width: 70,
                type: "text",
                required: true,
                name: "brokerBillingStreet",
                inputProps: { maxLength: 100 },
                defaultValue: state?.brokerBillingStreet,
                mapDecode: "route",
                label: String(i18next.t("form:addressLegal")),
              },
              {
                width: 30,
                type: "text",
                required: true,
                name: "brokerBillingNumber",
                defaultValue: state?.brokerBillingNumber,
                mapDecode: "street_number",
                inputProps: { maxLength: 10 },
                label: String(i18next.t("form:civicNumberAbbr")),
              },
              {
                width: 100,
                required: true,
                type: "select_nations",
                name: "brokerBillingCountryID",
                currentValue: state?.brokerBillingCountryID ?? "",
                defaultValue: state?.brokerBillingCountryID,
                mapDecode: "country",
                label: String(i18next.t("form:countryBilling")),
              },
              {
                width: 25,
                type: "text",
                required: true,
                name: "brokerBillingCAP",
                defaultValue: state?.brokerBillingCAP,
                mapDecode: "postal_code",
                inputProps: { maxLength: 20 },
                label: String(i18next.t("form:postalCode")),
              },
              {
                width: 50,
                type: "text",
                required: true,
                name: "brokerBillingCity",
                defaultValue: state?.brokerBillingCity,
                mapDecode: "locality",
                inputProps: { maxLength: 50 },
                label: String(i18next.t("form:city")),
              },
              {
                width: 25,
                type: "text",
                required: true,
                name: "brokerBillingProv",
                defaultValue: state?.brokerBillingProv,
                inputProps: { maxLength: 50 },
                mapDecode: "province",
                label: String(i18next.t("form:provinceAbbr")),
              },
            ]}
          />
        ),
      },
      {
        width: 50,
        type: "checkbox",
        name: "brokerReport",
        defaultValue: state?.brokerReport,
        placeholder: String(i18next.t("form:brokerReport")),
      },
      {
        width: 100,
        type: "custom",
        name: "_types",
        element: (
          <BrokerTypeSubform
            brokerId={state?.brokerID}
            emit={(list: string[]) => {
              setTypesForBroker(list);
            }}
          />
        ),
      }
    );

    multiformInputs.push({
      width: 100,
      type: "custom",
      name: "save",
      element: (
        <div className="display-flex-end">
          <Button
            color="error"
            sx={{ marginRight: "1em" }}
            disabled={state && !state?.brokerID}
            onClick={() => {
              SummonModal(`deleteModal_FormBroker`);
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            {i18next.t("generic:delete")}
          </Button>
          <Button
            variant="contained"
            disabled={processing}
            onClick={() => {
              triggerFormValidation("FormBroker");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("generic:save")}
          </Button>
          <SmartModal
            modalUniqueId={`deleteModal_FormBroker`}
            title={i18next.t("generic:titleRecordModal")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModal")
                    .replace(
                      "CURRENT_ITEM_LIST",
                      state?.brokerName + " " + state?.brokerSurname
                    ),
                }}
              />
            }
            onAccept={() => {
              deleteBroker(state);
            }}
            onReject={() => {}}
          />
        </div>
      ),
    });

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Init country and account type API
  useEffect(() => {
    loadAccountType();
    loadCountries();
  }, []);

  /* RETURN */
  return (
    <>
      <MultiForm
        suppressLayout
        classNameForm="multi-form-flex"
        suppressSubmit={true}
        formId={"FormBroker"}
        formTitle={"FormBroker"}
        inputs={multiformInputs()}
        onChange={async (data: any) => {
          setState({ ...state, ...data });
        }}
        onSubmit={async () => {
          // Convert string to number
          state["brokerAccountTypeID"] = +state["brokerAccountTypeID"];
          state["brokerBillingCountryID"] = +state["brokerBillingCountryID"];
          state["brokerFiscalCode"] = state?.brokerFiscalCode?.toUpperCase();
          // Delete unuseful state
          delete state["googleAddress"];
          delete state["save"];

          // Lunch the API based on the brokerID
          // (if present the element already exist so UPDATE, if not INSERT)
          if (state?.brokerID && state?.brokerID > 0) {
            updateBroker(state);
          } else {
            insertBroker(state);
          }
        }}
      />
      <LoaderBackdrop
        loading={
          loadingAccountType ||
          loadingCountires ||
          loadingInsertBroker ||
          loadingUpdateBroker ||
          loadingDeleteBroker
        }
      />
    </>
  );
};

export default FormBroker;
