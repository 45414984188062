/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./VehicleGroupPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import { IUPDATE_CarConfiguratorGroup_Disable } from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";

/* COMPONENT */
const VehicleGroupPage = () => {
  /* STATES */
  // Group list
  const [list, setList] = useState<any>([]);
  // Button choose list type
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingUpdateDisable, setLoadingUpdateDisable] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  const GET_CarConfiguratorGroup = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Disable group
  const UPDATE_CarConfiguratorGroup_Disable = (
    data: IUPDATE_CarConfiguratorGroup_Disable
  ) => {
    setLoadingUpdateDisable(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Disable(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");

          setTimeout(() => {
            GET_CarConfiguratorGroup();
          }, 1000);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateDisable(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    GET_CarConfiguratorGroup();
  }, []);

  /* FUNCTIONS */
  // Button add new list
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TABLE
  // Edit actions
  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <IconButton>
          <EditIcon
            onClick={() => {
              props.row.listStatus === true
                ? navigate(
                    AppRoutes.INTERNAL_DASHBOARD +
                      AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__OPEN +
                      "?groupID=" +
                      props.row.groupID
                  )
                : navigate(
                    AppRoutes.INTERNAL_DASHBOARD +
                      AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE +
                      "?groupID=" +
                      props.row.groupID
                  );
            }}
            className="icon-table"
          />
        </IconButton>
      </div>
    );
  };

  // Enable group
  const renderEnable = (props: GridRenderCellParams) => {
    return (
      <Switch
        checked={props.row.enabled}
        onClick={() => {
          UPDATE_CarConfiguratorGroup_Disable({
            groupID: props.row.groupID,
            enabled: !props.row.enabled,
          });
        }}
      />
    );
  };

  // Budget limit
  const renderLimit = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value === true ? i18next.t("message:yes") : i18next.t("message:no")}
      </div>
    );
  };

  // List status
  const renderListStatus = (props: GridRenderCellParams) => {
    return (
      <div className="display-flex-vertical-center gap-extra-small">
        {props.value === true ? (
          <>
            <MeetingRoomIcon />
            {i18next.t("form:listStatusOpen")}
          </>
        ) : (
          <>
            <NoMeetingRoomIcon />
            {i18next.t("form:listStatusClose")}
          </>
        )}
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "enable",
      headerName: i18next.t("form:enable"),
      renderCell: renderEnable,
    },
    {
      field: "groupID",
      headerName: i18next.t("form:number"),
    },
    {
      field: "groupDescription",
      headerName: i18next.t("form:groupDescription"),
    },
    {
      field: "budgetReference",
      headerName: i18next.t("form:budgetReference"),
    },
    {
      field: "budgetLimit",
      headerName: i18next.t("form:budgetLimit"),
      renderCell: renderLimit,
      type: "number"
    },
    {
      field: "cubicCapacity",
      headerName: i18next.t("form:cubicCapacityMax"),
      type: "number"
    },
    {
      field: "listStatus",
      headerName: i18next.t("form:listStatus"),
      renderCell: renderListStatus,
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      groupID: list[i].groupID,
      enabled: list[i].enabled,
      groupDescription: list[i].groupDescription,
      budgetReference: list[i].budgetReference || "-",
      budgetLimit: list[i].budgetLimit,
      cubicCapacity: list[i].cubicCapacity || "-",
      listStatus: list[i].listStatus,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_configure_vechicles_group")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <Button
            variant="outlined"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            {i18next.t("navigation:_add_group")}
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD +
                    AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__OPEN
                );
              }}
            >
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              {i18next.t("generic:openList")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD +
                    AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE
                );
              }}
            >
              <ListItemIcon>
                <NoMeetingRoomIcon />
              </ListItemIcon>
              {i18next.t("generic:closeList")}
            </MenuItem>
          </Menu>
        </div>

        <Box className="dashboardForm__data">
          {!loadingGet && <DataGridWrap headers={columnsDef} rows={rowsDef} />}
        </Box>
      </div>

      <LoaderBackdrop loading={loadingGet || loadingUpdateDisable} />
    </div>
  );
};

export default VehicleGroupPage;
