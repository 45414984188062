/* REACT */
import { useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./SatelliteApparatus__VehiclesPage.scss";

/* COMPONENTS */
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import UploadForm from "../../../../../Components/UploadForm/UploadForm";

/* UTILS */
import {
  getCompressedBase64,
  getCompressedFileName,
} from "../../../../../Utils/FileToBase";

/* IMAGES */
import tmdIcon from "../../../../../Assets/ImagesEmbedded/tmd.jpg";

/* MUI */
import { Button, Typography } from "@mui/material";
// Icons
import SendIcon from "@mui/icons-material/Send";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

/* COMPONENT */
const SatelliteApparatus__VehiclesPageModal = () => {
  /* STATES */
  const [formData, setFormData] = useState<any>([]);

  /* LODASH */
  const lodash = require("lodash");

  /* FUNCTIONS */
  // Upload files
  const updateFile = (files: File[]) => {
    getCompressedBase64(files.length > 0 ? files[0] : null, (result: string | null) => {
      let oldState = { ...formData }; // Valutare un deep clone
      oldState["filesURL"] = result;
      setFormData(oldState);
    });
  };

  /* RETURN */
  return (
    <div className="modal-width-medium">
      <div className="display-flex-center">
        <img src={tmdIcon} className="tmdImage" alt="telematics mobile diagnostic" />
      </div>

      <Typography
        dangerouslySetInnerHTML={{
          __html: i18next.t("form:satelliteApparatursText"),
        }}
        className="margin-vertical-small"
      />

      <div className="padding-extrasmall">
        <Button variant="outlined" startIcon={<FileDownloadIcon />}>
          {i18next.t("form:downloadDocuments")}
        </Button>
      </div>

      <MultiForm
        suppressLayout
        suppressSubmit={true}
        formId={"FormSatelliteApparatus"}
        inputs={[
          // Upload file
          {
            width: 100,
            type: "custom",
            name: "filesURL",
            label: "",
            element: (
              <UploadForm
                name={"filesURL"}
                accept={"image/*"}
                onFileChanged={updateFile}
                title={i18next.t("form:uploadFilledDocuments")}
                description={i18next.t("form:descriptionImageUpload")}
                initialFiles={
                  formData && formData.filesURL
                    ? [getCompressedFileName(formData.filesURL)]
                    : []
                }
              />
            ),
          },
        ]}
        onChange={(data: any) => {
          setFormData({ ...formData, ...data });
        }}
        onSubmit={(data: any) => {
          const tempData = lodash.cloneDeep(data);
        }}
      />

      <Typography
        dangerouslySetInnerHTML={{
          __html: i18next.t("form:tmdText"),
        }}
        className="margin-vertical-small"
      />

      <div className="display-flex-end padding-x-extrasmall">
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() => {
            triggerFormValidation("FormSatelliteApparatus");
          }}
        >
          {i18next.t("navigation:send")}
        </Button>
      </div>
    </div>
  );
};

export default SatelliteApparatus__VehiclesPageModal;
