import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { IGoogleAddress } from "../../Utils/Google";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import PlaceAutoCompleteForm from "../PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import "./StepAssignedDrivers.scss";

import { ICompanyGet } from "../../Models/ICompany";
import {
  IDriverGetLicenceType,
  IDriverGetType,
  IDriverInsert,
  IDriverUpdate,
} from "../../Models/IDriver";
import { IUser } from "../../Models/IUser";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { validationErrorMessages, validationPhone } from "../../Utils/Validation";
import CenterCostMainHandler from "../CenterCostMainHandler/CenterCostMainHandler";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import FormCompanyUser from "../StepCompanyUsers/FormCompanyUser";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import { IGET_CarConfigurator_GroupList_Dropdown } from "../../Models/ICarConfigurator";
import { UserActions } from "../../Reducers/User/UserAction";
import { useDispatch } from "react-redux";
export interface IFormAssignedDriver {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  data: any;
  nextPage?: number;
  doRefreshForm?: () => void;
  onLoadingDriverList?: (doReload: true) => void;
  isInModal?: boolean;
  idModal?: string;
  updateList?: (data: any) => void;
}

const FormAssignedDriver = (props: IFormAssignedDriver) => {
  const [state, setState] = useState<any>(props.data);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [driverType, setDriverType] = useState<IDriverGetType[]>([]);
  const [groupList, setGroupList] = useState<IGET_CarConfigurator_GroupList_Dropdown[]>(
    []
  );
  const [loadingDriverType, setLoadingDriverType] = useState<boolean>(false);
  const [loadingLicenceType, setLoadingLicenceType] = useState<boolean>(false);
  const [loadingGroupList, setLoadingGroupList] = useState<boolean>(false);
  const [licenceType, setLicenceType] = useState<IDriverGetLicenceType[]>([]);

  const loadDriverType = () => {
    setLoadingDriverType(true);
    ApiService.DriverController.DriverGetType((response: IAPIResponse) => {
      if (response.error === null) {
        setDriverType(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingDriverType(false);
    });
  };

  const loadGroupList = () => {
    setLoadingGroupList(true);

    ApiService.CarConfiguratorController.GET_CarConfigurator_GroupList_Dropdown(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setGroupList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGroupList(false);
      }
    );
  };

  const loadCompanyList = () => {
    setLoadingCompany(true);
    if (props.loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompany(false);
        }
      );
    }
  };

  const loadLicenceType = () => {
    setLoadingLicenceType(true);
    if (props.loggedUser) {
      ApiService.DriverController.DriverGetLicenceType((response: IAPIResponse) => {
        if (response.error === null) {
          setLicenceType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingLicenceType(false);
      });
    }
  };

  const insertDriver = (data: IDriverInsert) => {
    if (props.loggedUser) {
      setProcessing(true);

      ApiService.DriverController.DriverInsertData(data, (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.onLoadingDriverList) {
            props.onLoadingDriverList(true);
          }
          if (props.updateList) {
            props.updateList(response.payload);
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(i18next.t("error:" + response.error), "error");
            }
          }
        }

        if (props.isInModal && props.idModal !== "") {
          DismissModal(props.idModal as string);
        }

        setProcessing(false);
      });
    }
  };

  const updateDriver = (data: IDriverUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.DriverController.DriverUpdateData(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:assignDriverSuccessfullUpdated"), "success");
          if (props.onLoadingDriverList) {
            props.onLoadingDriverList(true);
          }
          if (props.updateList) {
            props.updateList(response.payload);
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }

        setProcessing(false);
      });
    }
  };

  const deleteDriver = (data: any) => {
    if (props.loggedUser) {
      setProcessing(true);
      if (data === null) {
        ToastMessage(i18next.t("message:companyNotDeleted"), "warning");
      } else {
        ApiService.DriverController.DriverDeleteData(
          data.driverID,
          (response: IAPIResponse) => {
            if (response.error === null) {
              ToastMessage(
                i18next.t("message:assignDriverSuccessfullDeleted"),
                "success"
              );
              if (props.onLoadingDriverList) {
                props.onLoadingDriverList(true);
              }
              if (props.doRefreshForm) {
                props.doRefreshForm();
              }
              if (props.idModal) {
                DismissModal(props.idModal as string);
              }
            } else {
              ToastMessage(response.error, "error");
            }
            setProcessing(false);
          }
        );
      }
    }
  };

  // Function to get the fiscal code
  const getFiscalCodeForUser = () => {
    if (props.companyUser) {
      return props.companyUser.companyFiscalCode;
    }
    return null;
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    if (props.loggedUser?.accountTypeID === 2) {
      multiformInputs.push(
        {
          width: props.loggedUser?.accountTypeID === 2 ? 40 : 50,
          type: "select",
          name: "companyID",
          defaultValue: state?.companyID,
          label: i18next.t("form:referentCompany").toString(),
          required: true,
          options: companyList.map((item: ICompanyGet) => {
            return {
              key: item.companyID,
              text: item.companyBusinessName,
            };
          }),
        },
        {
          width: 10,
          type: "custom",
          name: "companyUserAdd",
          element: (
            <div className="multi-form-button-add-record">
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  SummonModal("companyUser-modal");
                }}
              >
                <AddIcon />
              </Button>
            </div>
          ),
        }
      );
    } else {
      multiformInputs.push({
        width: 50,
        type: "text",
        name: "companyID",
        disabled: true,
        defaultValue: getFiscalCodeForUser(),
        label: i18next.t("form:taxIdCode").toString(),
        required: true,
      });
    }

    multiformInputs.push(
      {
        width: 25,
        type: "select",
        name: "driverTypeID",
        defaultValue: state?.driverTypeID,
        required: true,
        options: driverType.map((item: IDriverGetType) => {
          return {
            key: item.driverTypeID,
            text: item.driverTypeName.toString(),
          };
        }),
        label: i18next.t("form:userType").toString(),
      },
      {
        width: 25,
        type: "select",
        name: "groupID",
        defaultValue: state?.groupID,
        label: i18next.t("form:belongingGroup").toString(),
        required: false,
        options: groupList.map((item: IGET_CarConfigurator_GroupList_Dropdown) => {
          return {
            key: item.id,
            text: item.description,
          };
        }),
      }
    );

    if (state?.driverTypeID === 2) {
      multiformInputs.push(
        {
          width: 50,
          type: "text",
          required: true,
          name: "driverName",
          defaultValue: state?.driverName,
          label: i18next.t("form:nameAlias").toString(),
          inputProps: {
            maxLength: 20,
          },
        },
        {
          width: 50,
          type: "text",
          name: "driverDescription",
          defaultValue: state?.driverDescription,
          inputProps: { maxLength: 20 },
          label: i18next.t("form:descriptionAlias").toString(),
        },
        {
          width: 50,
          type: "email",
          name: "driverEmail",
          defaultValue: state?.driverEmail,
          required: true,
          label: String(i18next.t("form:emailReferent")),
        },
        {
          width: 50,
          type: "tel",
          name: "driverPhone",
          defaultValue: state?.driverPhone,
          label: String(i18next.t("form:telephoneReferent")),
          errorText: state?.driverPhone
            ? validationPhone(state.driverPhone) ||
              state.driverPhone === null ||
              state.driverPhone === ""
              ? ""
              : (i18next.t("form:validationPhone") as string)
            : "",
        }
      );
    }

    if (state?.driverTypeID === 1) {
      multiformInputs.push(
        {
          width: 25,
          type: "text",
          name: "driverName",
          defaultValue: state?.driverName,
          required: true,
          inputProps: {
            maxLength: 20,
          },
          label: i18next.t("form:name").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "driverSurname",
          required: true,
          defaultValue: state?.driverSurname,
          inputProps: {
            maxLength: 20,
          },
          label: i18next.t("form:surname").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "driverEmail",
          defaultValue: state?.driverEmail,
          required: true,
          label: String(i18next.t("form:email")),
        },
        {
          width: 25,
          type: "tel",
          name: "driverPhone",
          defaultValue: state?.driverPhone,
          label: String(i18next.t("form:telephonePersonal")),
          errorText: state?.driverPhone
            ? validationPhone(state.driverPhone) ||
              state.driverPhone === null ||
              state.driverPhone === ""
              ? ""
              : (i18next.t("form:validationPhone") as string)
            : "",
        },
        {
          width: 50,
          type: "text",
          name: "driverNumber",
          inputProps: {
            maxLength: 20,
          },
          defaultValue: state?.driverNumber,
          label: String(i18next.t("form:uniqueCodeIdentification_SKU")),
          required: true,
        },
        {
          width: 50,
          type: "select",
          name: "licenceTypeList",
          label: String(i18next.t("form:patentType")),
          defaultValue: state?.licenceTypeList ? state.licenceTypeList : [],
          required: true,
          multiple: true,
          options: licenceType.map((item: IDriverGetLicenceType) => {
            return {
              key: item.licenceTypeID,
              text: item.licenceTypeName,
            };
          }),
        },
        {
          width: 50,
          type: "date",
          name: "licenceDeadline",
          defaultValue: state?.licenceDeadline,
          disablePast: true,
          label: String(i18next.t("form:patentExpireDate")),
          required: true,
        },
        {
          width: 50,
          type: "text",
          name: "licenceNumber",
          inputProps: {
            maxLength: 50,
          },
          defaultValue: state?.licenceNumber,
          label: String(i18next.t("form:patentNumber")),
          required: true,
        }
      );
    }

    multiformInputs.push(
      {
        fullWidth: true,
        width: 100,
        type: "custom",
        name: "PlaceAutoCompleteForm",
        className: "content-place-auto-complete",
        element: (
          <PlaceAutoCompleteForm
            onChange={(data: IGoogleAddress) => setState({ ...state, ...data })}
            addressId="driverAddressStreet"
            disableForm={processing}
            inputs={[
              {
                width: 70,
                type: "text",
                name: "driverAddressStreet",
                defaultValue: state?.driverAddressStreet,
                mapDecode: "route",
                inputProps: {
                  maxLength: 100,
                },
                required: true,
                label: String(i18next.t("form:addressWorkLocation")),
              },
              {
                width: 30,
                type: "text",
                name: "driverAddressNumber",
                defaultValue: state?.driverAddressNumber,
                mapDecode: "street_number",
                inputProps: {
                  maxLength: 20,
                },
                required: true,
                label: String(i18next.t("form:civicNumberAbbr")),
              },
              {
                width: 100,
                type: "select_nations",
                name: "driverAddressCountryID",
                currentValue: state?.driverAddressCountryID ?? "",
                defaultValue: state?.driverAddressCountryID,
                mapDecode: "country",
                required: true,
                label: String(i18next.t("form:countryResidence")),
              },
              {
                width: 25,
                type: "text",
                name: "driverAddressCAP",
                defaultValue: state?.driverAddressCAP,
                mapDecode: "postal_code",
                inputProps: {
                  maxLength: 20,
                },
                required: true,
                label: String(i18next.t("form:postalCode")),
              },
              {
                width: 50,
                type: "text",
                name: "driverAddressCity",
                defaultValue: state?.driverAddressCity,
                mapDecode: "locality",
                inputProps: {
                  maxLength: 100,
                },
                required: true,
                label: String(i18next.t("form:city")),
              },
              {
                width: 25,
                type: "text",
                name: "driverAddressProv",
                defaultValue: state?.driverAddressProv,
                inputProps: {
                  maxLength: 100,
                },
                required: true,
                mapDecode: "province",
                label: String(i18next.t("form:provinceAbbr")),
              },
            ]}
          />
        ),
      },
      {
        ignore: state?.driverID ? true : false,
        type: "switch",
        width: 100,
        name: "enabledCarConfigurator",
        defaultValue: state?.enabledCarConfigurator ? true : false,
        placeholder: (
          <div
            dangerouslySetInnerHTML={{
              __html: i18next.t("form:assignEnabledAccessSmartFleet") ?? "",
            }}
          />
        ),
      }
    );
    return multiformInputs;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    loadCompanyList();
    loadDriverType();
    loadLicenceType();
    loadGroupList();
  }, []);

  useEffect(() => {
    dispatch(UserActions.setCurrentBrokerCompany(state));
  }, [state]);

  const loadingData =
    loadingLicenceType || loadingCompany || loadingDriverType || loadingGroupList;

  return (
    <div>
      {!loadingData && (
        <div>
          <MultiForm
            styles={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            formId={"StepAssignedDriver"}
            suppressLayout
            suppressSubmit={true}
            formTitle={""}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState({ ...state, ...data });
            }}
            onSubmit={() => {
              let parseData = { ...state };
              if (parseData["driverAddressNumber"]) {
                parseData["driverAddressNumber"] =
                  parseData["driverAddressNumber"].toString();
              }
              parseData["licenceTypeIDList"] = (parseData["licenceTypeList"] ?? [])
              .filter((x: any) => x !== 0)
              .map((x: number | string) => {
                return {
                  id: +x,
                };
              });
              

              if (props.loggedUser?.accountTypeID === 1) {
                parseData["companyID"] = props.companyUser?.companyID;
              }

              delete parseData["companyUserAdd"];
              delete parseData["driverTypeName"];
              delete parseData["companyBusinessName"];
              delete parseData["PlaceAutoCompleteForm"];
              delete parseData["licenceTypeName"];
              delete parseData["driverCostCenter"];
              delete parseData["licenceTypeList"];

              parseData["driverName"] &&
                (parseData["driverName"] = parseData["driverName"].trim());
              parseData["driverSurname"]
                ? (parseData["driverSurname"] = parseData["driverSurname"].trim())
                : (parseData["driverSurname"] = "");

              if (parseData.driverID) {
                parseData["driverID"] = parseData.driverID;
                parseData["licenceID"] = parseData.licenceID;

                parseData["driverEmail"] === props.data.driverEmail
                  ? (parseData["isEmailChanged"] = false)
                  : (parseData["isEmailChanged"] = true);

                updateDriver(parseData);
              } else {
                insertDriver(parseData);
              }
            }}
          />

          <div>
            <AttachementMainHandler
              allowMissingVehicleId
              attachmentFamily={"driver"}
              extraGetParametersVector={
                state?.driverID
                  ? [
                      {
                        name: "driverID",
                        value: state?.driverID,
                      },
                    ]
                  : undefined
              }
            />
          </div>

          <CenterCostMainHandler
            companyUser={props.companyUser}
            loggedUser={props.loggedUser}
            driverID={state?.driverID}
          />

          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={processing}
                onClick={() => {
                  triggerFormValidation("StepAssignedDriver");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveCompanyUser")}
              </Button>

              <Button
                color="error"
                sx={{ marginRight: "1em" }}
                disabled={!state?.driverID}
                onClick={() => {
                  SummonModal("StepAssignedDriver-modal");
                }}
                variant="contained"
                endIcon={<DeleteIcon />}
              >
                {i18next.t("form:deleteCompanyUser")}
              </Button>

              <SmartModal
                modalUniqueId="StepAssignedDriver-modal"
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          state?.driverName +
                            (state?.driverSurname ? " " + state.driverSurname : "")
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  deleteDriver(state);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </div>
      )}

      <SmartModal
        modalUniqueId="companyUser-modal"
        title={i18next.t("generic:titleAddCompany")}
        hideButtons={true}
        modalInnerComponent={
          <FormCompanyUser
            loggedUser={props.loggedUser}
            data={[]}
            companyUser={props.companyUser}
            idModal="companyUser-modal"
          />
        }
      />

      <LoaderBackdrop loading={processing || loadingData} />
    </div>
  );
};

export default FormAssignedDriver;
