/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./NotificationCenter.scss";

/* REDUCER */
import { GlobalState } from "../../Reducers/RootReducer";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* MODELS */
import { IUser } from "../../Models/IUser";
import { IDeadlinesByAccount } from "../../Models/IDeadlinesByAccount";

/* MUI */
import {
  Badge,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
// Icons
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { ToastMessage } from "../../Utils/Toastify";
import AppRoutes from "../../Costants/AppRoutes";
import { InitExpiredDeadline } from "../../Pages/DashboardPage/DashBoard_Pages/ExpiryCalendarPage/ExpiryCalendarPage";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { JSONPrint } from "../../Utils/Decoder";
import ApiEndPoints from "../../Costants/ApiEndPoints";
import { userRoleFits } from "../../Utils/Session";

/* RETURN */
const NotificationCenter = () => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  // State for deadline
  const [deadlineExpiredByAccount, setDeadlineExpiredByAccount] = useState<
    IDeadlinesByAccount[]
  >([]);
  const [loadingDeadlineExpiredByAccount, setLoadingDeadlineExpiredByAccount] =
    useState<boolean>(true);

  // States for avatar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* API */
  const DeadlineExpiredByAccount = () => {
    if (loggedUser) {
      let accountIsBroker: boolean = userRoleFits(["Broker"]);
      let url: string = userRoleFits(["Broker"]) ? ApiEndPoints.DEADLINE_BY_ACCOUNT_BROKER_GET : ApiEndPoints.DEADLINE_EXPIRED_BY_ACCOUNT_GET;
      ApiService.CalendarController.DeadlineExpiredByAccount(
        url,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let tmpDeadlines: IDeadlinesByAccount[] = [];
            if(accountIsBroker){
              for (let index = 0; index < response.payload.length; index++) {
                let tmp: any = Object.entries(response.payload[index].deadlineCalendar);
                tmp = tmp.map((el: any) => {
                  return el[1];
                });
                for (let i = 0; i < tmp.length; i++) {
                  if (tmp[i].length > 0) {
                    for (let j = 0; j < tmp[i].length; j++) {
                      let ddl = {
                        ...tmp[i][j],
                        brokerInspectingAccountId: response.payload[index].userAccountID
                      };
                      tmpDeadlines.push(ddl);
                    }
                  }
                }
                
              }
            }else{
              let tmp: any = Object.entries(response.payload);
              tmp = tmp.map((el: any) => {
                return el[1];
              });
              for (let i = 0; i < tmp.length; i++) {
                if (tmp[i].length > 0) {
                  for (let j = 0; j < tmp[i].length; j++) {
                    tmpDeadlines.push(tmp[i][j]);
                  }
                }
              }
            }
            setDeadlineExpiredByAccount(tmpDeadlines);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineExpiredByAccount(false);
        }
      );
    }
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    DeadlineExpiredByAccount();
  }, []);

  /* RETURN */
  return (
    <>
      {loggedUser && (
        <div style={{ position: "relative", margin: "0 0.4em" }}>
          <IconButton
            color="primary"
            aria-label="alerts"
            component="label"
            onClick={handleClick}
          >
            {!loadingDeadlineExpiredByAccount &&
            deadlineExpiredByAccount.length > 0 ? (
              <Badge color="error" variant="dot" overlap="circular">
                <NotificationsIcon />
              </Badge>
            ) : (
              <NotificationsIcon />
            )}
            {loadingDeadlineExpiredByAccount && (
              <div style={{ marginTop: " -25px", marginLeft: "-5px" }}>
                <CircularProgress
                  style={{ color: "#fff", width: "15px", height: "15px" }}
                />
              </div>
            )}
          </IconButton>

          <Menu
            id="account-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="hideScrollbar"
            PaperProps={{
              elevation: 0,
              sx: {
                width: "400px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                height: "500px",
                overflowY: "scroll",
              },
            }}
          >
            <div>
              <div className="notification--box">
                <div className="notification--box--div">
                  <span>Notifiche</span>

                  <Badge
                    badgeContent={deadlineExpiredByAccount.length}
                    color="primary"
                  >
                    <NotificationsActiveIcon color="secondary" />
                  </Badge>
                </div>
              </div>

              <Divider />
            </div>

            {deadlineExpiredByAccount.length > 0 &&
              deadlineExpiredByAccount.map(
                (el: IDeadlinesByAccount, i: number) => {
                  return (
                    <div key={i}>
                      <MenuItem
                        onClick={() => {
                          navigate(
                            AppRoutes.INTERNAL_DASHBOARD +
                              AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE
                          );
                        }}
                        style={{ marginTop: "0.5em", whiteSpace: "normal" }}
                      >
                        <ListItemIcon className="notification--box--icon">
                          <CalendarMonthIcon />
                        </ListItemIcon>

                        <div>
                          <span className="text-bold">
                            {InitExpiredDeadline(el.deadlineTypeID, el)}
                          </span>

                          {el.deadlineTypeID !== 5 && (
                            <div>
                              {el.brandDescription +
                                " " +
                                el.fittingDescription +
                                (el.deadlineTypeID !== 9
                                  ? " [" +
                                    formatPlate(el.vehicleLicensePlate) +
                                    "]"
                                  : "")}
                            </div>
                          )}

                          {el.deadlineTypeID !== 9 &&
                            el.deadlineTypeID !== 5 &&
                            (el.driverDisplayName ? el.driverDisplayName : "")}

                          {el.deadlineTypeID === 5 && (
                            <div className="expired-deadline-vehicle-type">
                              {el.driverDisplayName ? el.driverDisplayName : ""}
                            </div>
                          )}
                        </div>
                      </MenuItem>

                      <Divider />
                    </div>
                  );
                }
              )}
          </Menu>
        </div>
      )}
    </>
  );
};

export default NotificationCenter;
