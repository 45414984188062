import { useEffect, useState } from "react";

/* STYLE */
import "./StepSuppliers.scss";

/* LIBRARY */
import i18next from "i18next";

/* MODELS */
import { ISupplierGetSideList } from "../../Models/ISupplier";
import { IUser } from "../../Models/IUser";

import CustomSideList from "../CustomSideList/CustomSideList";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../Utils/Toastify";
import FormSupplier from "./FormSupplier";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import AddNewBanner from "../AddNewBanner/AddNewBanner";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { Alert } from "@mui/material";
import { SkipButtonCustomStepper } from "../CustomStepper/CustomStepper";

export interface IStepSuppliers {
  nextPage?: number;
  stepId?: number;
}

const StepSuppliers = (props: IStepSuppliers) => {
  /* USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(null);

  // State for supplier side list
  const [supplierSideList, setSupplierSideList] = useState<
    ISupplierGetSideList[]
  >([]);

  // State for spinning and loading the process
  const [loadingSuppliersSideList, setLoadingSuppliersSideList] =
    useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);

  /* APIs */
  // API to load suppliers side list
  const loadSuppliersSideList = () => {
    setLoadingSuppliersSideList(true);
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierSideList(response.payload);

            updateAccountRegistrationStep(loggedUser, props.stepId, true);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSuppliersSideList(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Convert the API values for the form state
  const fromAPItoState = (rawState: ISupplierGetSideList) => {
    let supplierInfo: any = { ...rawState.supplierInfo };

    let arr: any = rawState.companyIDs.map((x: any) => x.companyID.toString());
    let companyIDs: any = { supplierCompany: arr };

    // Merge into a final state
    let finaleState: any = {
      ...supplierInfo,
      ...companyIDs,
    };

    // Return the final state with all the values
    return finaleState;
  };

  // Refresh the form
  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  // Add a new supplier
  const newSupplier = () => {
    setState(null);
    doRefreshForm();
  };

  // Update the form with the selected element of the side list
  const updateCurrent = (item: any) => {
    setState(fromAPItoState(item));
    doRefreshForm();
  };

  /* HOOK EFFECT */
  // Load the page elements
  useEffect(() => {
    loadSuppliersSideList(); // Load suppliers side list
  }, []);

  /* RETURN */
  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleSuppliers") ?? "",
          }}
        />
        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitleSuppliers")}
        </h2>
        {props.nextPage && (
          <SkipButtonCustomStepper nextPage={props.nextPage} />
        )}
        <Alert severity="info">
          <div
            dangerouslySetInnerHTML={{
              __html:
                i18next.t(
                  "navigation:generic_supplier_not_found_request_to_add"
                ) ?? "",
            }}
          />
        </Alert>
        <AddNewBanner
          label={i18next.t("navigation:addNewSuppliers")}
          new={() => newSupplier()}
        />

        {!refreshForm && (
          <div className="container-content-form">
            <div className="container-stepper-form add-border multi-form-background">
              {!refreshForm && (
                <FormSupplier
                  loggedUser={loggedUser}
                  data={state}
                  supplierSideList={supplierSideList}
                  doRefreshForm={() => {
                    doRefreshForm();
                    setState(null);
                  }}
                  loadSuppliersSideList={() => loadSuppliersSideList()}
                  numPage={props.nextPage}
                />
              )}
            </div>
            {!loadingSuppliersSideList &&
              <CustomSideList
                title={i18next.t("generic:titleSideListSuppliers")}
                updateCurrent={updateCurrent}
                selected={{
                  value: state?.supplierID,
                  key: "supplierInfo.supplierID",
                }}
                sideContent={"#form-StepSuppliers"}
                list={supplierSideList}
                elementTitle={(item: ISupplierGetSideList) => {
                  return item.supplierInfo.supplierName;
                }}
              />
            }
          </div>
        )}
      </div>
      <LoaderBackdrop loading={loadingSuppliersSideList || refreshForm} />
    </>
  );
};

export default StepSuppliers;
