import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleDisposal,
  IVehicleDisposalComplete,
} from "../../Models/IDisposal";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const DisposalController = {
  GetVehicleDisposalMode: async (
    vehicleDisposalModeId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_GET_DISPOSAL_MODE}`;
    if (vehicleDisposalModeId) {
      request.url += "?vehicleDisposalModeId=" + vehicleDisposalModeId;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CompleteVehicleDisposal: async (
    payload: IVehicleDisposalComplete,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_COMPLETE_DISPOSAL}`;
    request.payload = payload;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetVehicleDisposal: async (
    vehicleDisposalID?: number,
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_GET_DISPOSAL}`;
    let params: string[] = [];
    if (vehicleDisposalID) {
      params.push("vehicleDisposalID=" + vehicleDisposalID);
    }
    if (vehicleID) {
      params.push("vehicleID=" + vehicleID);
    }

    if (params.length > 0) {
      request.url += "?" + params.join("&");
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertVehicleDisposal: async (
    payload: IVehicleDisposal,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_INSERT_DISPOSAL}`;
    request.payload = payload;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetVehicleDisposalLinkedItems: async (
    vehicleID?: number,
    companyID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_GET_LINKED_ITEMS}`;
    let params: string[] = [];
    if (companyID) {
      params.push("companyID=" + companyID);
    }
    if (vehicleID) {
      params.push("vehicleID=" + vehicleID);
    }

    if (params.length > 0) {
      request.url += "?" + params.join("&");
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  UpdateVehicleDisposal: async (
    payload: IVehicleDisposal,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DISPOSAL_UPDATE_DISPOSAL}`;
    request.payload = payload;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default DisposalController;
