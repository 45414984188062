import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { FormGroup, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { ISubscriptionsDuration } from "../../Models/ISubscriptions";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import { voices } from "../../i18n";
import i18next from "i18next";
import ServicesSubscriptions from "../../Components/ServicesSubscriptions/ServicesSubscriptions";
import { IAddedService } from "../../Models/IService";
import MarketplaceImage from "../../Assets/ImagesEmbedded/marketplace.jpg"
import './MarketplacePage.scss'

const MarketplacePage = () => {
  const [state, setState] = useState<any>({
    pricingType: "1",
  });
  const [subscriptionDuration, setSubscriptionsDuration] = useState<
    ISubscriptionsDuration[]
  >([]);
  const [servicesList, setServicesList] = useState<IAddedService[]>([]);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);

  const loadServices = () => {
    setLoadingServices(true);
    ApiService.ServiceController.ServiceGetAddedData(
      true,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp = response.payload
          tmp = tmp.filter((el: IAddedService) => el.addedServiceID === 51 || el.addedServiceID === 55 || el.addedServiceID === 56 || el.addedServiceID === 57)
          tmp.map((el: IAddedService) => {
            let tmpService: IAddedService = el
            if (el.addedServiceID === 51) {
              tmpService.addedServiceDescription = i18next.t("intro:marketplace_carTaxpayment")
            } else if (el.addedServiceID === 55) {
              tmpService.addedServiceDescription = i18next.t("intro:marketplace_readyDelivery")
            } else if(el.addedServiceID === 56) {
              tmpService.addedServiceDescription = i18next.t("intro:marketplace_carConfigurator")
            } else if(el.addedServiceID === 57) {
              tmpService.addedServiceDescription = i18next.t("intro:marketplace_repairEstimate")
            }
            return tmpService
          })
          setServicesList(tmp);
        }else{
          ToastMessage(response.error, "error");
        }
        setLoadingServices(false)
      }
    )
  }

  const updateChange = (name: string, value: any) => {
    let u: any = { ...state };
    u[name] = value;
    setState(u);
  };

  useEffect(() => {
    loadServices();
    setSubscriptionsDuration(
      [
        {
          subscriptionDurationID: 1,
          subscriptionDurationName: i18next.t("navigation:montly"),
        },
        {
          subscriptionDurationID: 2,
          subscriptionDurationName: i18next.t("navigation:yearly"),
        },
      ]
    );
  }, []);

  return (
    <div>
      <div className="banner-image">
        <div className="title-banner">{'Marketplace'}</div>
        <img src={MarketplaceImage} alt='' />
        </div>
        <div style={{margin: '6em'}}>
            <FormGroup className="container-switch">
              <ToggleButtonGroup
                color="primary"
                value={state.pricingType}
                onChange={(e: any) => {
                  updateChange("pricingType", e.target.value);
                }}
                exclusive
              >
                {subscriptionDuration.map(
                  (duration: ISubscriptionsDuration, i: number) => {
                    return (
                      <ToggleButton
                        key={i}
                        value={duration.subscriptionDurationID.toString()}
                      >
                        {duration.subscriptionDurationName}
                      </ToggleButton>
                    );
                  }
                )}
              </ToggleButtonGroup>
            </FormGroup>
            <h2 className="title-background">{i18next.t("intro:marketplace_title")}</h2>
            <div>
            {servicesList
              .filter((x: IAddedService) => x.languageID === voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num)
                .map((service: IAddedService, i: number) => {
                  return (
                    <ServicesSubscriptions
                      isTrial={false}
                      key={i}
                      isStored={service.stored}
                      keySubscription={i}
                      service={service}
                      pricingType={state.pricingType} 
                      selected={""} 
                      onClick={() => {}} 
                      onlyView={true}
                      isWebSite={true}        
                    />
                  );
                })}
            </div>
          <br />
        </div>
      <LoaderBackdrop loading={loadingServices} />
    </div>
  );
  };

  export default MarketplacePage