import { useEffect, useState } from "react";
import i18next from "i18next";
import "./VehiclesPage_subPages_Assegnee_StepAssignedDrivers.scss";
import { IUser } from "../../../../../../Models/IUser";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IDriverGet } from "../../../../../../Models/IDriver";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import VehiclesPage_subPages_FormAssignedDriver from "./VehiclesPage_subPages_Assegnee_FormAssignedDriver";
import CustomSideList from "../../../../../../Components/CustomSideList/CustomSideList";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import AddNewBanner from "../../../../../../Components/AddNewBanner/AddNewBanner";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { IAssignmentGet, IAssignmentUpdate } from "../../../../../../Models/IAssignment";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { Alert } from "@mui/material";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import FringeModal from "../../../../../../Components/FringeModal/FringeModal";
import ClearIcon from "@mui/icons-material/Clear";

export interface IStepAssignedDrivers {
  numPage?: number;
}

const VehiclesPage_subPages_StepAssignedDrivers = (props: IStepAssignedDrivers) => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const driver: IDriverGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeDriver
  );
  const assignment: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignment
  );

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const [loadingAssignmentList, setLoadingAssignmentsList] = useState<boolean>(false);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [endAssignment, setEndAssignment] = useState<any>([]);
  const [assignmentList, setAssignmentList] = useState<IAssignmentGet[]>([]);
  const [visibleAssignmentList, setVisibleAssignmentList] = useState<IAssignmentGet[]>(
    []
  );
  const [minKm, setMinKm] = useState<number>(0);

  const [processingTerminate, setProcessingTerminate] = useState<boolean>(false);

  const loadDriverList = () => {
    if (loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverList(response.payload);
          loadAssignmentsList();
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDriverList(false);
      });
    }
  };

  const assignmentTerminate = (data: IAssignmentUpdate) => {
    if (loggedUser) {
      setProcessingTerminate(true);
      ApiService.AssignmentController.TerminateAssignment(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:assignmentSucceffulTerminated"), "success");
            DismissModal("TerminateAssegneeModal");
            loadAssignmentsList();
            newCompanyUser();
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessingTerminate(false);
        }
      );
    }
  };

  const applySideList = () => {
    let currents: IAssignmentGet[] = [...assignmentList];
    currents = currents.filter(
      (x: IAssignmentGet) =>
        x.driverID === assignment?.driverID || assignment?.driverID === -1
    );

    setVisibleAssignmentList(currents);
  };

  const selectFirstActiveAssignment = () => {
    if (visibleAssignmentList.length > 0) {
      setState(visibleAssignmentList[0]);
    } else {
      setState(null);
    }

    doRefreshForm();
  };

  const loadAssignmentsList = () => {
    if (vehicle) {
      setLoadingAssignmentsList(true);
      ApiService.AssignmentController.AssignmentGetByCarID(
        vehicle.vehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAssignmentList(response.payload.reverse());
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAssignmentsList(false);
        }
      );
    }
  };
  const updateCurrent = (item: any) => {
    setState(item);
    doRefreshForm();
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
      getVehicleKMLimit();
    }, 1000);
  };

  const newCompanyUser = () => {
    setState(null);
    doRefreshForm();
  };

  const reloadAssigneeList = () => {
    (window as any)["reloadCoreData"]();
    loadAssignmentsList();
  };
  (window as any)["reloadAssigneeList"] = reloadAssigneeList;

  useEffect(() => {
    loadDriverList();

    window.document.addEventListener(
      "reload-assigners-list-hook",
      (window as any)["reloadAssigneeList"]
    );
    return () => {
      window.document.removeEventListener(
        "reload-assigners-list-hook",
        (window as any)["reloadAssigneeList"]
      );
    };
  }, []);

  useEffect(() => {
    applySideList();
  }, [assignmentList, driver]);

  useEffect(() => {
    selectFirstActiveAssignment();
  }, [visibleAssignmentList]);

  const canCreateNew =
    visibleAssignmentList.filter((x: IAssignmentGet) => x.effectiveEndDT === null)
      .length === 0;

  const getVehicleKMLimit = () => {
    if (vehicle && vehicle.vehicleID) {
      ApiService.VehicleKilometersEntriesController.GetVehicleKilometersLimit(
        vehicle.vehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setMinKm(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  useEffect(() => {
    getVehicleKMLimit();
  }, [vehicle]);

  return (
    <>
      <div className="custom-stepper-content-wrap">
        <DashboardSectionTitle
          title={i18next.t("navigation:_vheicles_tab_nav_ownership")}
          rightButtons={[
            {
              disabled:
                !state ||
                !state.assignmentID ||
                state.effectiveEndDT ||
                state?.assignmentTypeID !== 1,
              label: i18next.t("navigation:fringeBenefit"),
              onClick: () => {
                SummonModal("FringeBenefitModal");
              },
            },
            {
              type: "icon",
              icon: (
                <ClearIcon
                  style={
                    !state || !state.assignmentID || state.effectiveEndDT
                      ? {}
                      : {
                          backgroundColor: "#cf2734",
                          color: "white",
                          borderRadius: "100%",
                        }
                  }
                />
              ),
              disabled: !state || !state.assignmentID || state.effectiveEndDT,
              label: i18next.t(
                "navigation:_vehicle_page_assegnee_toolbar_button_stop_dial"
              ),
              onClick: () => {
                SummonModal("TerminateAssegneeModal");
              },
            },
          ]}
        />

        <FringeModal />

        <div style={{ marginTop: "1em" }}></div>

        {!canCreateNew && (
          <Alert severity="info">{i18next.t("navigation:newRequiresTermination")}</Alert>
        )}

        <SmartModal
          title={i18next.t("navigation:_vehicle_page_assegnee_toolbar_button_stop_dial")}
          modalUniqueId="TerminateAssegneeModal"
          modalInnerComponent={
            <div>
              <MultiForm
                formId="TerminateAssignment"
                inputs={[
                  {
                    width: 50,
                    type: "datetime",
                    name: "effectiveEndDT",
                    required: true,
                    defaultValue: endAssignment.effectiveEndDT,
                    label: String(i18next.t(`form:dateFinishReal`)),
                    maxDate: new Date().toISOString(),
                  },
                  {
                    width: 50,
                    type: "number",
                    name: "endVehicleKM",
                    defaultValue: endAssignment.endVehicleKM,
                    inputProps: {
                      max: 1000000,
                    },
                    min: minKm,
                    required: true,
                    label: String(i18next.t(`form:kmFinishReal`)),
                  },
                ]}
                suppressLayout
                suppressSubmit
                onChange={(data: any) => {
                  setEndAssignment({ ...endAssignment, ...data });
                }}
                onSubmit={(data: any) => {
                  data["endVehicleKM"] = +data["endVehicleKM"];
                  data["assignmentID"] = state["assignmentID"];
                  assignmentTerminate(data);
                }}
              />
            </div>
          }
          buttons={[
            {
              text: i18next.t("navigation:close"),
              onClick: () => {
                DismissModal("TerminateAssegneeModal");
              },
            },
            {
              text: i18next.t("navigation:accept"),
              onClick: () => {
                triggerFormValidation("TerminateAssignment");
              },
            },
          ]}
        />
        <div>
          <AddNewBanner
            label={i18next.t("navigation:addNewAssignedDrivers")}
            new={() => newCompanyUser()}
            forceDisplay={canCreateNew}
          />

          {!refreshForm && (
            <div className="container-content-form">
              <div className="container-stepper-form add-border assignmentsVehicle">
                <VehiclesPage_subPages_FormAssignedDriver
                  loggedUser={loggedUser}
                  companyUser={companyUser}
                  data={state}
                  doRefreshForm={() => doRefreshForm()}
                  numPage={props.numPage}
                />
              </div>

              {!refreshForm && !loadingDriverList && !loadingAssignmentList && 
                <CustomSideList
                  title={i18next.t("generic:titleSideListAssigneDrivers")}
                  updateCurrent={updateCurrent}
                  selected={{
                    value: state?.assignmentID,
                    key: "assignmentID",
                  }}
                  list={visibleAssignmentList}
                  elementTitle={(item: IAssignmentGet) => {
                    let driver: IDriverGet | undefined = driverList.find(
                      (x: IDriverGet) => x.driverInfo.driverID === item.driverID
                    );
                    let titleDriver = "";
                    if (driver) {
                      if (driver.driverInfo?.driverSurname) {
                        titleDriver +=
                          driver.driverInfo.driverName +
                          " " +
                          driver.driverInfo.driverSurname;
                      } else if (
                        driver.driverInfo?.driverName &&
                        !driver.driverInfo?.driverSurname
                      ) {
                        titleDriver += driver.driverInfo.driverName;
                      }
                    }
                    return [
                      titleDriver,
                      item.effectiveEndDT
                        ? "" +
                          i18next.t("message:terminated") +
                          " " +
                          displayUTC0_ISODate(item.effectiveEndDT, false).slice(0, -3)
                        : displayUTC0_ISODate(item.startDT, false).slice(0, -3) +
                          " - " +
                          displayUTC0_ISODate(item.endDT, false).slice(0, -3),
                    ];
                  }}
                />
              }
            </div>
          )}
        </div>
      </div>
      <LoaderBackdrop
        loading={
          loading ||
          loadingAssignmentList ||
          processingTerminate ||
          refreshForm ||
          loadingDriverList
        }
      />
    </>
  );
};

export default VehiclesPage_subPages_StepAssignedDrivers;
