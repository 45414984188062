import {
  RegistrationActionType,
  SET_FLEET_PLANS,
  SET_PLAN_SERVICE_DATA,
  SET_ADDED_SERVICES,
  SET_USER_SERVICE_ADDONS,
  SET_USER_SUBSCRIPTIONS,
  SET_VEHICLE_SUMMARY_DATA,
} from "./RegistrationAction";
import { RegistrationState } from "./RegistrationState";

const DEFAULT_STATE: RegistrationState = {
  planService: {
    accountID: 0,
    subscriptionID: null,
    subscriptionDurationID: 0,
    fleetPlanID: 0,
    selectedServiceIDList: [],
    isTrial: false
  },
  vehicleSummany: [],
  userSubscriptions: [],
  userServiceAddons: [],
  fleetPlans: [],
  addedServices: [],
};

export const registrationReducer = (
  state: RegistrationState = DEFAULT_STATE,
  action: RegistrationActionType
): RegistrationState => {
  switch (action.type) {
    case SET_PLAN_SERVICE_DATA:
      return {
        ...state,
        planService: action.payload,
      };
    case SET_VEHICLE_SUMMARY_DATA:
      return {
        ...state,
        vehicleSummany: action.payload,
      };
    case SET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        userSubscriptions: action.payload,
      };
    case SET_USER_SERVICE_ADDONS:
      return {
        ...state,
        userServiceAddons: action.payload,
      };
    case SET_FLEET_PLANS:
      return {
        ...state,
        fleetPlans: action.payload,
      };
    case SET_ADDED_SERVICES:
      return {
        ...state,
        addedServices: action.payload,
      };
    default:
      return state;
  }
};
