/* REACT */
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";

/* STYLE */
import "./App.scss";
import "./Utils/Utils.scss";

import AppLayout from "./AppLayout";
import "./i18n";
import "./Utils/Toastify";
import { clearOrderToVehicleCache } from "./Utils/OrderToVehicle";
import { IUser } from "./Models/IUser";
import { useSelector } from "react-redux";
import { GlobalState } from "./Reducers/RootReducer";
import { dateIsPast } from "./Components/MultiForm/SpecialInputs/StrongDatePicker";

window.onbeforeunload = function (event) {
  clearOrderToVehicleCache();
};

(window as any).getDistance = (origin: string, destination: string) => {
  fetch(
    "https://maps.googleapis.com/maps/api/distancematrix/json?destinations=" +
      destination +
      "&origins=" +
      origin +
      "&units=decimal&key=" + process.env.REACT_APP_GOOGLE_MAP_API_KEY
  )
    .then((r) => {
      r.json();
    })
    .then((j) => console.log(j));
};

const AppInner = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  
  return (
    <div>
      <ToastContainer className="toast-position" />

      <Router>
        <AppLayout />
      </Router>
    </div>
  );
};

export default AppInner;

/*
models/basicmeasure -> si chiama una volta e crea l'input del nostro script
quali misure sono associate alla singola turbina

ti da tutte le misure di un certo asset


assets/tree 
tutti gli asset




asset/assettimeseries
from: date 
to: date      <---time span
assetId: id
temporalAggregation: "2" <--- sempre a 2 va bene
measureNameIds [array di id interi presi dalla api successiva]


/cd/system/enumerations/getEnumerationItemsByName?enumerationName=
{value ("Functional Aggregation")}&includeObsolete={value ("false")}&includeAllLanguages={value ("true")}

Wind active power average (name = 712)
irradiance avg (711)


active power 1437
*/
