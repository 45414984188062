export const getSessionForm = (formName: string) => {
  let s = sessionStorage.getItem("form-stepper");
  if (s === null) {
    s = "{}";
  }
  let s_ = JSON.parse(s);
  return s_[formName];
};

export const setSessionForm = (formName: string, value: any) => {
  let s = sessionStorage.getItem("form-stepper");
  if (s === null) {
    s = "{}";
  }
  let s_ = JSON.parse(s);
  s_[formName] = value;
  sessionStorage.setItem("form-stepper", JSON.stringify(s_));
};