const ApiEndPoints = {
  LOGIN: "/registration/login/login",
  LOGIN_STATUS: "/registration/login/LoginStatus",
  LOGIN_GET_MENU_VOICES: "/registration/login/menuvoices",
  RESET_PASSWORD: "/registration/login/resetpassword",

  QUOTATION_GET: "/addedservice/quotation/getquotations",
  QUOTATION_CAN_BE_SERVED: "/addedservice/quotation/TestBrokerInDistance",

  QUOTATION_INSERT: "/addedservice/quotation/insertquotations",
  QUOTATION_UPDATE: "/addedservice/quotation/updatequotations",
  QUOTATION_DELETE: "/addedservice/quotation/deletequotations",

  QUOTATION_SOLUTION_GET: "/addedservice/quotation/getquotationssolutions",
  QUOTATION_SOLUTION_INSERT:
    "/addedservice/quotation/insertquotationssolutions",
  QUOTATION_SOLUTION_UPDATE:
    "/addedservice/quotation/updatequotationssolutions",
  QUOTATION_SOLUTION_DELETE:
    "/addedservice/quotation/deletequotationssolutions",

  BLOB_UPLOAD: "/registration/fileblobstorage/uploadfile",
  BLOB_UPLOAD_LIST: "/registration/fileblobstorage/uploadfilelist",
  BLOB_GET_SAS: "/registration/fileblobstorage/generatesastoken",
  BLOB_DELETE: "/registration/fileblobstorage/DeleteFile",

  INVOICE_ITEM_DELETE: "/registry/invoice/deleteinvoice",
  INVOICE_TYPE_GET: "/registry/invoice/getinvoicetype",
  INVOICE_LIST_GET: "/registry/invoice/getinvoice",
  INVOICE_UPLOAD: "/registry/invoice/uploadinvoices",
  INVOICE_UPLOAD_ZIP: "/registry/invoice/uploadinvoicezip",

  GET_ACCOUNT: "/registry/account/getaccount",
  ACCOUNT_DETAIL: "/registry/account/getaccountdetaillist",
  ACCOUNT_TYPE: "/registry/account/getaccounttype",
  GET_WRITEABLE_USERS: "/registry/account/GetWriteableAccountList",
  GET_IMPERSONATABLE_USERS: "/registry/account/getaccountlist",
  ACCOUNT_COUNTRY: "/registry/account/getallcountry",
  ACCOUNT_GET_DATA_INFO: "/registry/account/getaccountinfo",
  ACCOUNT_SET_DATA: "/registry/account/insertaccount",
  ACCOUNT_PRE_REGISTRATION: "/registry/preregistration/insertpreregistration",
  ACCOUNT_UPDATE_DATA: "/registry/account/updateaccount",
  ACCOUNT_UPDATE_DATA_W_IMAGE: "/registry/account/updateAccount_w_Image",
  ACCOUNT_REGISTRATION: "/registration/registration/registeraccount",
  ACCOUNT_REGISTRATION_RESEND_EMAIL:
    "/registration/registration/ResendRegisterAccountEmail",

  ACCOUNT_REQUEST_SPECIAL_OFFER:
    "/registration/registration/InsertUserSpecialOfferRequest",

  ACCOUNT_CONFIRM_EMAIL: "/registration/registration/confirmemail",
  ACCOUNT_RESET_PASSWORD: "/registration/login/confirmnewpassword",
  ACCOUNT_RESET_EMAIL: "/registration/registration/sendagainemailregistration",
  ACCOUNT_REGISTRATION_STEP_INSERT:
    "/registration/registration/insertaccountregistrationstep",
  ACCOUNT_RESET_PASSWORD_SESSION:
    "/registration/login/resetpasswordfromsession",

  FLEET_PLAN_GET_DATA: "/registry/fleetplan/getfleetplan",
  FLEET_PLAN_DELETE_DATA: "/registry/fleetplan/deletefleetplan",
  FLEET_PLAN_SET_DATA: "/registry/fleetplan/insertfleetplan",
  FLEET_PLAN_UPDATE_DATA: "/registry/fleetplan/updatefleetplan",
  FLEET_PLAN_GET_MOST_CONVENIENT:
    "/registry/fleetplan/getmostconvenientfleetplan",

  SERVICE_GET_DATA: "/registry/service/getservice",
  SERVICE_INSERT_DATA: "/registry/service/insertservice",
  SERVICE_UPDATE_DATA: "/registry/service/updateservice",
  SERVICE_DELETE_DATA: "/registry/service/deleteservice",

  /* FAQ */
  GET_FAQ_CATEGORY: "/registry/faq/getfaqcategory",
  GET_FAQ_VISIBILITY: "/registry/faq/getfaqvisibility",
  GET_FAQ_BY_CATEGORY: "/registry/faq/getfaqbycategory",
  INSERT_FAQ: "/registry/faq/insertfaq",
  UPDATE_FAQ: "/registry/faq/updatefaq",
  DELETE_FAQ: "/registry/faq/deletefaq",

  /* CAR CONFIGURATOR */
  // Vehicle
  GET__CARCONFIGURATOR_VEHICLES:
    "/carconfigurator/vehicleconfigurator/getcarconfiguratorvehicle",
  // General
  GET_GROUP: "/registry/group/getgroup",
  DELETE_GROUP: "/registry/group/deletegroup",
  // Open
  GET_GROUP_OPEN: "/registry/group/getgroupopenlist",
  INSERT_GROUP_OPEN: "/registry/group/insertgroupopenlist",
  UPDATE_GROUP_OPEN: "/registry/group/updategroupopenlist",
  // Close
  GET_GROUP_CLOSE: "/registry/group/getgroupcloselist",
  INSERT_GROUP_CLOSE_BASE: "/registry/group/insertgroupcloselistbaseinfo",
  UPDATE_GROUP_CLOSE_BASE: "/registry/group/updategroupcloselistbaseinfo",
  INSERT_GROUP_CLOSE_VEHICLE: "/registry/group/insertvehiclegroup",
  UPDATE_GROUP_CLOSE_VEHICLE: "/registry/group/updatevehiclegroup",
  DELETE_GROUP_CLOSE_VEHICLE: "/registry/group/deletevehiclegroup",
  // Optionals
  GET_VEHICLE_OPTIONAL: "/registry/group/getgroupvehicleoptional",
  UPDATE_VEHICLE_OPTIONAL: "/registry/group/updategroupvehicleoptional",
  GET_VEHICLE_PACKAGE: "/registry/group/getgroupvehiclepackage",
  UPDATE_VEHICLE_PACKAGE: "/registry/group/updategroupvehiclepackage",
  GET_VEHICLE_COLOR: "/registry/group/getgroupvehiclecolor",
  UPDATE_VEHICLE_COLOR: "/registry/group/updategroupvehiclecolor",
  // Disable group
  DISABLE_GROUP: "/registry/group/disabledgroup",
  // Vehicle configuration summary
  INSERT_VEHICLE_SUMMARY:
    "/carconfigurator/vehicleconfigurator/insertvehiclerequestinformation",
  UPDATE_VEHICLE_SUMMARY:
    "/carconfigurator/vehicleconfigurator/updatevehiclerequestinformation",
  GET_VEHICLE_SUMMARY:
    "/carconfigurator/vehicleconfigurator/getvehiclerequestinformation",
  // Vehicle requests
  GET_VEHICLE_REQUEST: "/registry/vehiclerequest/getdrivergroupvehiclesrequest",
  UPDATE_VEHICLE_REQUEST_APPROVE:
    "/carconfigurator/vehiclerequest/approvedrivergroupvehiclesrequest",
  UPDATE_VEHICLE_REQUEST_REJECT:
    "/carconfigurator/vehiclerequest/rejectdrivergroupvehiclesrequest",
  GET_VEHICLE_OFFERS: "/registry/vehiclerequest/getvehiclerequestoffers",
  INSERT_VEHICLE_REQUEST:
    "/carconfigurator/vehicleRequest/sendVehiclesRequestEmails",
  // Supplier send offer page
  INSERT_SUPPLIER_SEND_OFFER:
    "/carconfigurator/vehiclerequest/insertvehiclesrequestoffer",
  GET_SUPPLIER_SEND_OFFER:
    "/carconfigurator/vehiclerequest/getvehiclerequestoffersprecompiledfields",
  // Offer page
  UPDATE_REQUEST_OFFER_ACCEPT:
    "/carconfigurator/vehiclerequest/acceptvehiclesrequestoffer",
  // Driver config
  GET_DRIVER_CONFIG: "/registry/driver/getIsDriverEnableForCarConfig",
  UPDATE_DRIVER_CONFIG: "/registry/driver/updateIsDriverEnableForCarConfig",
  // Prompt Delivery
  INSERT_REQUEST_PROMPT_DELIVERY:
    "/carconfigurator/configurator/sendcarrequest",
  INSERT_PROPOSAL_PROMPT_DELIVERY:
    "/carconfigurator/promptdelivery/insertvehiclesrequestpromptdeliveryproposal",
  UPDATE_IGNORE_REQUEST_PROMPT_DELIVERY:
    "/carconfigurator/promptdelivery/ignorepromptdeliveryrequest",
  GET_REQUEST_DEALER_PROMPT_DELIVERY:
    "/carconfigurator/promptdelivery/getpromptdeliveryrequest",
  GET_CAR_COLORS: "/carconfigurator/promptdeliverydropdown/getdropdowncarcolor",
  GET_PROMPT_DELIVERY_PROPOSAL:
    "/carconfigurator/promptdelivery/getvehiclesrequestpromptdeliveryproposal",

  // Dropdown
  GET_ENGINE_POWER_SUPPLY: "/registry/groupdropdown/getenginepowersupply",
  GET_ELECTRIC_HYBRID_TYPE: "/registry/groupdropdown/getelectrichybridtype",
  GET_VEHICLE_MODEL: "/registry/groupdropdown/getvehiclemodel",
  GET_VEHICLE_OPTIONAL_TYPE: "/registry/groupdropdown/getvehicleoptionaltype",
  GET_VEHICLE_COMPANY_CODE:
    "/registry/groupdropdown/getgroupvehiclecompanycode",
  GET_VEHICLE_MONTH_YEAR: "/registry/groupdropdown/getfittingmonthyear",
  GET_GROUP_DROPDOWN_LIST: "/registry/groupdropdown/getgroupsdropdown",
  GET_RELATIONSHIP: "/registry/groupdropdown/getrelationships",

  /* MENU */
  GET_NAVIGATION_MENU_ROLES: "/registry/menu/getroledropdownsitenavigation",
  GET_NAVIGATION_MENU: "/registry/menu/getsitenavigationrolemapbyrole",
  UPDATE_NAVIGATION_MENU: "/registry/menu/updatesitenavigationrolemap",

  /* SUPPLIER STEP */
  SUPPLIER_GET_TYPE: "/suppliers/supplier/getsuppliertype", // Get the type of supplier for the select type
  SUPPLIER_GET_INFO: "/suppliers/supplier/getgenericsupplierdropdown", // Get the info based on the supplier type
  SUPPLIER_GET_GENERIC: "/suppliers/supplier/getgenericsupplier", // Get supplier generic data
  SUPPLIER_INSERT_GENERIC: "/suppliers/supplier/insertgenericsupplier", // Insert supplier generic data
  SUPPLIER_UPDATE_GENERIC: "/suppliers/supplier/updategenericsupplier", // Update supplier generic data
  SUPPLIER_DELETE_GENERIC: "/suppliers/supplier/deletegenericsupplier", // Delete supplier generic data
  SUPPLIER_GET_DATA: "/suppliers/supplier/getsupplier", // Get the side list suppliers
  SUPPLIER_POST_DATA: "/suppliers/supplier/insertsupplier", // Insert a new supplier
  SUPPLIER_UPDATE_DATA: "/suppliers/supplier/updatesupplier", // Update the selected supplier
  SUPPLIER_DELETE_DATA: "/suppliers/supplier/deletesupplier", // Delete the selected supplier

  /* ORDER STEP */
  ORDER_GET_TYPE: "/cars/vehicleorder/getvehicleordertype", // Get the type of order for the select type (single/multiple)
  ORDER_GET_CONTRACT_TYPE: "/cars/contract/getcontracttype", // Get the contract type (proprietà, finanziamento, etc.)
  ORDER_GET_PROPERTY_LIST: "/cars/contract/getcontracttypepropertylist", // Get the property list
  ORDER_GET_DATA: "/cars/vehicleorder/getvehicleorder", // Get the side list orders
  ORDER_SET_DETAIL_VEHICLE: "/cars/vehicleorder/setorderdetailvehicle",
  ORDER_POST_DATA: "/cars/vehicleorder/insertvehicleorder", // Insert a new order
  ORDER_UPDATE_DATA: "/cars/vehicleorder/updatevehicleorder", // Update the selected order
  ORDER_DELETE_DATA: "/cars/vehicleorder/deletevehicleorder", // Delete the selected order
  ORDER_GET_ATTACHMENT: "/cars/vehicleorder/getvehicleorderattachment", // Insert the attachment
  ORDER_INSERT_ATTACHMENT: "/cars/vehicleorder/insertvehicleorderattachment", // Insert the attachment
  ORDER_UPDATE_ATTACHMENT: "/cars/vehicleorder/updatevehicleorderattachment", // Update the attachment
  ORDER_DELETE_ATTACHMENT: "/cars/vehicleorder/deletevehicleorderattachment", // Delete the attachment

  // template support
  TEMPLATE_API_GET_DATE_FORMAT: "/cars/template/getdateformat",
  TEMPLATE_API_GET_TIME_FORMAT: "/cars/template/gettimeformat",
  TEMPLATE_API_GET_SYS_PROP: "/cars/template/gettemplatesystemproperty",
  TEMPLATE_API_GET_EXCEL_H: "/cars/template/gettemplateheader",
  TEMPLATE_API_UPLOAD_EXCEL: "/cars/template/uploadentitybytemplate",

  // template proprerty mapping
  TEMPLATE_API_GET_PROP_MAPPING: "/cars/template/gettemplatepropertymapping",
  TEMPLATE_API_INSERT_PROP_MAPPING:
    "/cars/template/inserttemplatepropertymapping",
  TEMPLATE_API_UPDATE_PROP_MAPPING:
    "/cars/template/updatetemplatepropertymapping",

  // template prop mapping supply
  TEMPLATE_API_GET_PROP_MAPPING_SUPPLY:
    "/cars/template/gettemplatefuelsupplytypemapping",
  TEMPLATE_API_INSERT_PROP_MAPPING_SUPPLY:
    "/cars/template/inserttemplatefuelsupplytypemapping",
  TEMPLATE_API_UPDATE_PROP_MAPPING_SUPPLY:
    "/cars/template/updatetemplatefuelsupplytypemapping",

  TEMPLATE_API_GET_FUEL_SUPPLY_SERVED: "/cars/template/getfuelsupplyserved",

  // template prop mapping supply served
  TEMPLATE_API_GET_PROP_MAPPING_SUPPLY_SERVED:
    "/cars/template/gettemplatefuelsupplyservedmapping",
  TEMPLATE_API_INSERT_PROP_MAPPING_SUPPLY_SERVED:
    "/cars/template/inserttemplatefuelsupplyservedmapping",
  TEMPLATE_API_UPDATE_PROP_MAPPING_SUPPLY_SERVED:
    "/cars/template/updatetemplatefuelsupplyservedmapping",

  // template APIs
  TEMPLATE_API_GET: "/cars/template/gettemplate",
  TEMPLATE_API_INSERT_TEMPLATE: "/cars/template/inserttemplate",
  TEMPLATE_API_UPDATE_TEMPLATE: "/cars/template/updatetemplate",
  TEMPLATE_API_DELETE_TEMPLATE: "/cars/template/deletetemplate",

  FRINGE_MASSIVE_IMPORT: "/cars/fringe/uploadfringetables",
  FRINGE_FUEL_TYPE_GET: "/cars/fringe/getfueltypeaci",
  FRINGE_BRAND_GET: "/cars/fringe/getacibrand",
  FRINGE_MODEL_GET: "/cars/fringe/getacimodel",
  FRINGE_VERSION_GET: "/cars/fringe/getaciversion",
  FRINGE_BENEFIT_GET: "/cars/fringe/getfringebenefit",
  FRINGE_BENEFIT_GET_ALL: "/cars/fringe/GetAllFringeBenefits",
  FRINGE_BENEFIT_UPDATE_ROW: "/cars/fringe/updatefringebenefit",
  FRINGE_BENEFIT_INSERT_ROW: "/cars/fringe/insertfringebenefit",
  FRINGE_BENEFIT_DELETE_ROW: "/cars/fringe/deletefringebenefit",
  FRINGE_BENEFIT_UPDATE: "/cars/fringe/updatevehiclefringebenefit",

  SERVICE_GET_ADDED_DATA: "/registry/service/getaddedservice",
  SERVICE_GET_SELECTED_DATA: "/registry/service/getaddedservicesubscription",
  SERVICE_INSERT_ADDED_DATA: "/registry/service/insertaddedservice",
  SERVICE_UPDATE_ADDED_DATA: "/registry/service/updateaddedservice",
  SERVICE_SET_SELECTED_DATA: "/registry/service/insertselectedservice",
  SERVICE_DELETE_SELECTED_DATA: "/registry/service/deleteseelectedservice",
  SERVICE_DELETE_ADDED_SUBSCRIPTION:
    "/registry/service/deleteaddedservicesubscription",

  SUBSCRIPTION_GET_DATA: "/registry/subscription/getsubscription",
  SUBSCRIPTION_UPDATE_DATA: "/registry/subscription/updatesubscription",
  SUBSCRIPTION_GET_DATA_DURATION:
    "/registry/subscription/getsubscriptionduration",
  SUBSCRIPTION_SET_PLAN_SERVICE_DATA: "/registry/registration/saveplanservice",
  SUBSCRIPTION_INSERT_TRIAL_PLAN_DATA:
    "/registry/registration/inserttrialsubscription",

  STEPPER_GET_DATA: "/registration/registration/getAccountRegistrationStep",
  STEPPER_GET_LAST_DATA:
    "/registration/registration/getlastaccountregistrationstep",
  STEPPER_SET_DATA: "/registration/registration/updateaccountregistrationstep",

  COMPANY_GET_DATA: "/registry/company/getcompany",
  COMPANY_SET_DATA: "/registry/company/insertcompany",
  COMPANY_UPDATE_DATA: "/registry/company/updatecompany",
  COMPANY_DELETE_DATA: "/registry/company/deletecompany",

  DRIVER_GET_TYPE: "/registry/driver/getdrivertype",
  DRIVER_GET_LICENCE_TYPE: "/registry/driver/getlicencetype",
  DRIVER_GET_LANGUAGES: "/registry/driver/getlanguages",
  DRIVER_GET: "/registry/driver/getdriver",
  DRIVER_NAME_SURNAME_GET: "/registry/driver/getdrivernamesurname",
  DRIVER_INSERT_DATA: "/registry/driver/insertdriver",
  DRIVER_UPDATE_DATA: "/registry/driver/updatedriver",
  DRIVER_DELETE_DATA: "/registry/driver/deletedriver",

  COST_CENTER_GET: "/registry/driver/getcostcenter",
  COST_CENTER_INSERT: "/registry/driver/insertcostcenter",
  COST_CENTER_UPDATE: "/registry/driver/updatecostcenter",
  COST_CENTER_DELETE: "/registry/driver/deletecostcenter",

  DRIVER_COST_CENTER_GET: "/registry/driver/getdrivercostcenter",
  DRIVER_COST_CENTER_INSERT: "/registry/driver/insertdrivercostcenter",
  DRIVER_COST_CENTER_UPDATE: "/registry/driver/updatedrivercostcenter",
  DRIVER_COST_CENTER_DELETE: "/registry/driver/deletedrivercostcenter",
  DRIVER_INSERT_FROM_EXCEL: "/registry/driver/insertdriverfromexcel",

  VEHICLE_DATA_GET_BY_COMPANY: "/cars/vehicledata/getvehicles",
  VEHICLE_DATA_GET_BY_ACCOUNT_ID:
    "/cars/vehicledata/getvehicleplatebyaccountid",
  VEHICLE_DATA_GET_BRANDS: "/cars/vehicledata/getvehiclebrands",
  VEHICLE_DATA_GET_MODELS: "/cars/vehicledata/getvehiclemodels",
  VEHICLE_DATA_GET_FITTING: "/cars/vehicledata/getvehiclefittings",
  VEHICLE_DATA_GET_BRANDS_ACCOUNTID:
    "/cars/vehicledata/getvehiclebrandsbyaccountid",
  VEHICLE_DATA_GET_MODELS_ACCOUNTID:
    "/cars/vehicledata/getvehiclemodelsbyaccountid",
  VEHICLE_DATA_GET_FITTING_ACCOUNTID:
    "/cars/vehicledata/getvehiclefittingsbyaccountid",
  VEHICLE_DATA_INSERT: "/cars/vehicledata/insertvehicle",
  VEHICLE_DATA_INSERT_FROM_EXCEL: "/cars/vehicledata/insertvehiclefromexcel",
  VEHICLE_DATA_UPDATE: "/cars/vehicledata/updatevehicle",
  VEHICLE_DATA_DELETE: "/cars/vehicledata/deletevehicle",

  VEHICLE_PLATE_REIMM_GET: "/cars/vehicledata/getvehiclelicenceplate",
  VEHICLE_PLATE_REIMM_INSERT: "/cars/vehicledata/insertvehiclelicenceplate",
  VEHICLE_PLATE_REIMM_UPDATE: "/cars/vehicledata/updatevehiclelicenceplate",

  VEHICLE_DATA_GET_VEHICLE_CATEGORY: "/cars/vehicledata/getvehiclecategory",
  VEHICLE_DATA_GET_VEHICLE_TYPE: "/cars/vehicledata/getvehicletype",
  VEHICLE_DATA_GET_VEHICLE_REGION: "/cars/vehicledata/getregion",
  VEHICLE_DATA_GET_VEHICLE_PROVINCE: "/cars/vehicledata/getprovince",
  VEHICLE_DATA_CHECK_USER_CAN_INSERT:
    "/cars/vehicledata/checkusercaninsertvehicle",

  VEHICLE_GET_TAB_DEADLINE: "/cars/vehicledeadline/getapptab",

  VEHICLE_CONTRACT_GET_TYPE: "/cars/vehiclecontract/getvehiclecontracttype",
  VEHICLE_CONTRACT_GET_PROPERTY_LIST:
    "/cars/vehiclecontract/getvehiclectpropertylist",
  VEHICLE_CONTRACT_GET: "/cars/vehiclecontract/getvehiclecontract",
  VEHICLE_CONTRACT_INSERT: "/cars/vehiclecontract/insertvehiclecontract",
  VEHICLE_CONTRACT_UPDATE: "/cars/vehiclecontract/updatevehiclecontract",
  VEHICLE_CONTRACT_DELETE: "/cars/vehiclecontract/deletevehiclecontract",
  VEHICLE_CONTRACT_GET_SERVICE:
    "/cars/vehiclecontract/getvehiclecontractservice",
  VEHICLE_CONTRACT_INSERT_SERVICE:
    "/cars/vehiclecontract/insertvehiclecontractservice",
  VEHICLE_CONTRACT_UPDATE_SERVICE:
    "/cars/vehiclecontract/updatevehiclecontractservice",
  VEHICLE_CONTRACT_DELETE_SERVICE:
    "/cars/vehiclecontract/deletevehiclecontractservice",
  VEHICLE_CONTRACT_GET_ATTACHMENT:
    "/cars/vehiclecontract/getvehiclecontractattachment",
  VEHICLE_CONTRACT_INSERT_ATTACHMENT:
    "/cars/vehiclecontract/insertvehiclecontractattachment",
  VEHICLE_CONTRACT_UPDATE_ATTACHMENT:
    "/cars/vehiclecontract/updatevehiclecontractattachment",
  VEHICLE_CONTRACT_DELETE_ATTACHMENT:
    "/cars/vehiclecontract/deletevehiclecontractattachment",

  VEHICLE_INSURANCE_GET_TYPE: "/cars/insurance/getinsurancedetailtype",
  VEHICLE_INSURANCE_DELETE_TYPE: "/cars/insurance/deleteinsurancedetail",
  VEHICLE_INSURANCE_GET: "/cars/insurance/getinsurance",
  VEHICLE_INSURANCE_INSERT: "/cars/insurance/insertinsurance",
  VEHICLE_INSURANCE_UPDATE: "/cars/insurance/updateinsurance",
  VEHICLE_INSURANCE_DELETE: "/cars/insurance/deleteinsurance",
  VEHICLE_INSURANCE_GET_ATTACHMENT: "/cars/insurance/getinsuranceattachment",
  VEHICLE_INSURANCE_INSERT_ATTACHMENT:
    "/cars/insurance/insertinsuranceattachment",
  VEHICLE_INSURANCE_UPDATE_ATTACHMENT:
    "/cars/insurance/updateinsuranceattachment",
  VEHICLE_INSURANCE_DELETE_ATTACHMENT:
    "/cars/insurance/deleteinsuranceattachment",

  VEHICLE_FUEL_CARD_GET_TYPE: "/cars/fuelcard/getfuelcardtype",
  VEHICLE_FUEL_CARD_GET: "/cars/fuelcard/getfuelcard",
  VEHICLE_FUEL_CARD_INSERT: "/cars/fuelcard/insertfuelcard",
  VEHICLE_FUEL_CARD_UPDATE: "/cars/fuelcard/updatefuelcard",
  VEHICLE_FUEL_CARD_DELETE: "/cars/fuelcard/deletefuelcard",
  VEHICLE_FUEL_CARD_GET_ATTACHMENT: "/cars/fuelcard/getfuelcardattachment",
  VEHICLE_FUEL_CARD_INSERT_ATTACHMENT:
    "/cars/fuelcard/insertfuelcardattachment",
  VEHICLE_FUEL_CARD_UPDATE_ATTACHMENT:
    "/cars/fuelcard/updatefuelcardattachment",
  VEHICLE_FUEL_CARD_DELETE_ATTACHMENT:
    "/cars/fuelcard/deletefuelcardattachment",

  VEHICLE_FUEL_SUPPLY_GET: "/cars/fuelsupply/getfuelsupply",
  VEHICLE_FUEL_SUPPLY_MEASUREMENT_UNIT_GET:
    "/cars/fuelsupply/getmeasurementunit",
  VEHICLE_FUEL_SUPPLY_INSERT: "/cars/fuelsupply/insertfuelsupply",
  VEHICLE_FUEL_SUPPLY_UPDATE: "/cars/fuelsupply/updatefuelsupply",
  VEHICLE_FUEL_SUPPLY_DELETE: "/cars/fuelsupply/deletefuelsupply",
  VEHICLE_FUEL_GET_TYPE: "/cars/fuelsupply/getfueltype",
  VEHICLE_FUEL_SUPPLY_GET_TYPE: "/cars/fuelsupply/getfuelsupplytype",

  VEHICLE_FUEL_SUPPLY_GET_ATTACHMENT:
    "/cars/fuelsupply/getfuelsupplyattachment",
  VEHICLE_FUEL_SUPPLY_INSERT_ATTACHMENT:
    "/cars/fuelsupply/insertfuelsupplyattachment",
  VEHICLE_FUEL_SUPPLY_UPDATE_ATTACHMENT:
    "/cars/fuelsupply/updatefuelsupplyattachment",
  VEHICLE_FUEL_SUPPLY_DELETE_ATTACHMENT:
    "/cars/fuelsupply/deletefuelsupplyattachment",

  SEND_OFFER_GET_ATTACHMENT:
    "/carconfigurator/VehicleRequest/getVehiclesRequestOfferAttachment",
  SEND_OFFER_INSERT_ATTACHMENT:
    "/carconfigurator/VehicleRequest/insertVehiclesRequestOfferAttachment",
  SEND_OFFER_UPDATE_ATTACHMENT:
    "/carconfigurator/VehicleRequest/updateVehiclesRequestOfferAttachment",
  SEND_OFFER_DELETE_ATTACHMENT:
    "/carconfigurator/VehicleRequest/deleteVehiclesRequestOfferAttachment",

  DEALER_PROPOSAL_GET_ATTACHMENT:
    "/carconfigurator/PromptDelivery/getPromptDeliveryProposalAttachment",
  DEALER_PROPOSAL_INSERT_ATTACHMENT:
    "/carconfigurator/PromptDelivery/insertPromptDeliveryProposalAttachment",
  DEALER_PROPOSAL_UPDATE_ATTACHMENT:
    "/carconfigurator/PromptDelivery/updatePromptDeliveryProposalAttachment",
  DEALER_PROPOSAL_DELETE_ATTACHMENT:
    "/carconfigurator/PromptDelivery/deletePromptDeliveryProposalAttachment",

  VEHICLE_FUEL_SUPPLY_SERVICE_GET:
    "/cars/fuelsupplyservice/getfuelsupplyservice",
  VEHICLE_FUEL_SUPPLY_SERVICE_INSERT:
    "/cars/fuelsupplyservice/insertfuelsupplyservice",
  VEHICLE_FUEL_SUPPLY_SERVICE_UPDATE:
    "/cars/fuelsupplyservice/updatefuelsupplyservice",
  VEHICLE_FUEL_SUPPLY_SERVICE_DELETE:
    "/cars/fuelsupplyservice/deletefuelsupplyservice",

  VEHICLE_TELEPASS_GET_TYPE: "/cars/telepass/gettelepasstype",
  VEHICLE_TELEPASS_GET: "/cars/telepass/gettelepass",
  VEHICLE_TELEPASS_INSERT: "/cars/telepass/inserttelepass",
  VEHICLE_TELEPASS_UPDATE: "/cars/telepass/updatetelepass",
  VEHICLE_TELEPASS_DELETE: "/cars/telepass/deletetelepass",
  VEHICLE_TELEPASS_GET_ATTACHMENT: "/cars/telepass/gettelepassattachment",
  VEHICLE_TELEPASS_INSERT_ATTACHMENT: "/cars/telepass/inserttelepassattachment",
  VEHICLE_TELEPASS_UPDATE_ATTACHMENT: "/cars/telepass/updatetelepassattachment",
  VEHICLE_TELEPASS_DELETE_ATTACHMENT: "/cars/telepass/deletetelepassattachment",

  VEHICLE_TELEPASS_USAGE_GET: "/cars/telepassusage/gettelepassusage",
  VEHICLE_TELEPASS_USAGE_TYPE_GET: "/cars/telepassusage/gettelepassusagetype",
  VEHICLE_TELEPASS_CLASS_GET: "/cars/telepassusage/gettelepassclass",
  VEHICLE_TELEPASS_USAGE_INSERT: "/cars/telepassusage/inserttelepassusage",
  VEHICLE_TELEPASS_USAGE_UPDATE: "/cars/telepassusage/updatetelepassusage",
  VEHICLE_TELEPASS_USAGE_DELETE: "/cars/telepassusage/deletetelepassusage",

  VEHICLE_ASSIGNMENT_GET_TYPE: "/cars/assignment/getassignmenttype",
  VEHICLE_ASSIGNMENT_GET_BY_CAR_ID: "/cars/assignment/getassignmentbyvehicleid",
  VEHICLE_ASSIGNMENT_TERMINATE: "/cars/assignment/endassignment",
  VEHICLE_ASSIGNMENT_GET_DEDUCIBILITY:
    "/cars/assignment/getassignmentdeductibility",
  VEHICLE_ASSIGNMENT_GET: "/cars/assignment/getassignment",
  VEHICLE_ASSIGNMENT_INSERT: "/cars/assignment/insertassignment",
  VEHICLE_ASSIGNMENT_UPDATE: "/cars/assignment/updateassignment",
  VEHICLE_ASSIGNMENT_DELETE: "/cars/assignment/deleteassignment",
  VEHICLE_ASSIGNMENT_GET_ATTACHMENT: "/cars/assignment/getassignmentattachment",
  VEHICLE_ASSIGNMENT_INSERT_ATTACHMENT:
    "/cars/assignment/insertassignmentattachment",
  VEHICLE_ASSIGNMENT_UPDATE_ATTACHMENT:
    "/cars/assignment/updateassignmentattachment",
  VEHICLE_ASSIGNMENT_DELETE_ATTACHMENT:
    "/cars/assignment/deleteassignmentattachment",

  VEHICLE_OTHER_SERVICES_GET_ATTACHMENT:
    "/cars/vehicleservice/getvehicleserviceattachment",
  VEHICLE_OTHER_SERVICES_INSERT_ATTACHMENT:
    "/cars/vehicleservice/insertvehicleserviceattachment",
  VEHICLE_OTHER_SERVICES_UPDATE_ATTACHMENT:
    "/cars/vehicleservice/updatevehicleserviceattachment",
  VEHICLE_OTHER_SERVICES_DELETE_ATTACHMENT:
    "/cars/vehicleservice/deletevehicleserviceattachment",

  VEHICLE_SERVICE_GET_PAYMENT_RECURRENCE_TYPE:
    "/cars/vehicleservice/getpaymentrecurrencetype",
  VEHICLE_SERVICE_GET: "/cars/vehicleservice/getvehicleservice",
  VEHICLE_SERVICE_INSERT: "/cars/vehicleservice/insertvehicleservice",
  VEHICLE_SERVICE_UPDATE: "/cars/vehicleservice/updatevehicleservice",
  VEHICLE_SERVICE_DELETE: "/cars/vehicleservice/deletevehicleservice",
  VEHICLE_SERVICE_INSERT_ATTACHMENT:
    "/cars/vehicleservice/insertvehicleserviceattachment",
  VEHICLE_SERVICE_UPDATE_ATTACHMENT:
    "/cars/vehicleservice/updatevehicleserviceattachment",
  VEHICLE_SERVICE_DELETE_ATTACHMENT:
    "/cars/vehicleservice/deletevehicleserviceattachment",
  VEHICLE_DATA_RESUME_GET_BY_ACCOUNT:
    "/cars/vehicleinfo/getvehicleresumebyaccountid",
  VEHICLE_DATA_GET_DEADLINE_RESUME: "/cars/vehicleinfo/getdeadlinebyvehicleid",
  VEHICLE_DATA_GET_SEARCH_PLATE_RESUME: "/cars/vehicleinfo/getdriverandplate",
  VEHICLE_DATA_GENERAL_INFO: "/cars/vehicleinfo/getvehicleinfo",
  VEHICLE_MCTC_GET: "/cars/vehicledata/getmctcvehicleinfo",
  VEHICLE_CHECK_USER_CAN_MCTC: "/cars/vehicledata/checkusercanusemctc",
  VEHICLE_GET_SEARCH: "/cars/vehicleinfo/getvehicleinsearch",
  VEHICLE_SUBSTITUTE_GET: "/cars/vehicleinfo/getvehiclereplacement",
  VEHICLE_SUBSTITUTE_UPDATE: "/cars/vehicleinfo/updatevehiclereplacement",
  VEHICLE_SUBSTITUTE_INSERT: "/cars/vehicleinfo/insertvehiclereplacement",
  VEHICLE_GET_HISTORY_ACTIONS: "/cars/vehicleinfo/getvehiclehistory",
  VEHICLE_GET_DRIVER_KM: "/cars/vehicleinfo/getvehicledriverkm",
  VEHICLE_GET_TOTAL_COST: "/cars/vehicleinfo/getvehicletotalcost",

  VEHICLE_GET_KM_VEHICLE_CURR_LEVEL: "/cars/vehicleinfo/GetVehicleTotalKm",

  VEHICLE_GET_KM_ENTRIES: "/cars/vehicleinfo/GetVehicleKilometersEntries",
  VEHICLE_INSERT_KM_ENTRIES: "/cars/vehicleinfo/InsertVehicleKilometersEntries",
  VEHICLE_CHANGE_STATUS_KM_ENTRIES:
    "/cars/vehicleinfo/ChangeStatusVehicleKilometersEntries",
  VEHICLE_KM_ENTRIES_STATUS_LIST:
    "/cars/vehicleinfo/VehicleKilometersEntriesStatusList",
  PAYMENT_PAYPAL_TOKEN_GET: "/payment/payment/getpaypaltoken",
  PAYMENT_TYPE_GET: "/payment/payment/getpaymenttype",
  PAYMENT_GET: "/payment/payment/getpayment",
  PAYMENT_INSERT: "/payment/payment/insertpayment",
  PAYMENT_UPDATE: "/payment/payment/updatepayment",
  PAYMENT_EVALUATE: "/payment/payment/EvaluateCostAndCredit",
  PAYMENT_PAYPAL_CREATE_PLAN: "/payment/payment/CreatePaypalPlan",
  PAYMENT_INSERT_SUBSCRIPTION_FROM_PAYPAL:
    "/payment/payment/InsertSubscriptionPaypalSubscription",
  PAYMENT_INCREASE_PLAN: "/payment/payment/increaseplan",
  PAYMENT_DECREASE_PLAN: "/payment/payment/DecreasePlan",
  PAYMENR_SINGLE_PAYMENT: "/payment/payment/BuyCallService",
  PAYMENT_INSERT_PLAN: "/payment/payment/insertplan",
  PAYMENT_GET_CREDITS: "/payment/payment/GetAccountCredit",
  PAYMENT_UPDATE_CREDIT: "/payment/payment/UpdateAccountCredit",
  PAYMENT_CREATE_SUBSCRIPTION: "/payment/payment/createsubscription",
  PAYMENT_CANCEL_SUBSCRIPTION: "/payment/payment/cancelsubscription",
  PAYMENT_GET_NUM_PENDING: "/payment/payment/getpaymentnumpending",

  /* ADMIN */
  ADMIN_IMP_SET: "/registry/admin/openimpersonation",
  ADMIN_IMP_CLEAR: "/registry/admin/closeimpersonation",
  ADMIN_PAYMENT: "/payment/payment/confirmpayment",

  /* STATISTICS */
  STATISTICS_CO2_EMISSIONS: "/cars/vehicleinfo/GetStatisticsCO2",
  STATISTICS_KILOMETRIC_PROJECTION: "/cars/vehicleinfo/GetStatisticsKmForecast",
  STATISTICS_FUEL_ANOMALIES: "/cars/fuelcard/getfuelcardabnormalusage",
  STATISTICS_OWNERSHIP_COSTS: "/cars/vehicleinfo/getstatisticstotalcost",

  /* BROKER */
  BROKER_INVITATION_GET: "/registry/broker/getbrokerinvitations",
  BROKER_INVITATION_INSERT: "/registry/broker/addbrokerinvitations",

  BROKER_GRANT_GET: "/registry/broker/getbrokergrants",
  BROKER_GRANT_INSERT: "/registry/broker/insertbrokergrants",
  BROKER_GRANT_UPDATE: "/registry/broker/updatebrokergrants",
  BROKER_GRANT_DELETE: "/registry/broker/deletebrokergrants",

  BROKER_GET: "/registry/broker/getbroker",
  BROKER_INSERT: "/registry/broker/insertbroker",
  BROKER_UPDATE: "/registry/broker/updatebroker",
  BROKER_DELETE: "/registry/broker/deletebroker",

  BROKER_GET_ID: "/registry/broker/getaccountbroker",

  BROKER_GET_COMPANY: "/registry/brokercompany/getbrokercompany",
  BROKER_INSERT_COMPANY: "/registry/brokercompany/insertbrokercompany",
  BROKER_UPDATE_COMPANY: "/registry/brokercompany/updatebrokercompany",
  BROKER_DELETE_COMPANY: "/registry/brokercompany/deletebrokercompany",

  // order
  ORDER_GET_DEADLINE: "/cars/vehicleorder/getvehicleorderdeadline", // Get the deadline
  ORDER_INSERT_DEADLINE: "/cars/vehicleorder/insertvehicleorderdeadline", // Insert the deadline

  // assignment
  VEHICLE_ASSIGNMENT_GET_DEADLINE:
    "/cars/vehicledeadline/getassignmentdeadlines",
  VEHICLE_ASSIGNMENT_INSERT_DEADLINE:
    "/cars/vehicledeadline/insertassignmentdeadline",

  // telepass
  VEHICLE_TELEPASS_GET_DEADLINE: "/cars/vehicledeadline/gettelepassdeadlines",
  VEHICLE_TELEPASS_INSERT_DEADLINE:
    "/cars/vehicledeadline/inserttelepassdeadline",

  // fuel card
  VEHICLE_FUEL_CARD_GET_DEADLINE: "/cars/vehicledeadline/getfuelcarddeadlines",
  VEHICLE_FUEL_CARD_INSERT_DEADLINE:
    "/cars/vehicledeadline/insertfuelcarddeadline",

  // contract
  VEHICLE_CONTRACT_GET_DEADLINE:
    "/cars/vehicledeadline/getvehiclecontractdeadlines",
  VEHICLE_CONTRACT_INSERT_DEADLINE:
    "/cars/vehicledeadline/insertvehiclecontractdeadline",

  // driver
  DRIVER_DEADLINE_GET: "/registry/driver/getdeadlinedriver",
  DRIVER_DEADLINE_INSERT: "/registry/driver/insertdeadlinedriver",

  ASSIGNEE_DRIVER_GET_ATTACHMENT: "/registry/driver/getdriverattachment",
  ASSIGNEE_DRIVER_INSERT_ATTACHMENT: "/registry/driver/insertdriverattachment",
  ASSIGNEE_DRIVER_UPDATE_ATTACHMENT: "/registry/driver/updatedriverattachment",
  ASSIGNEE_DRIVER_DELETE_ATTACHMENT: "/registry/driver/deletedriverattachment",

  // vehicle
  VEHICLE_DEADLINE_GET: "/cars/vehicledeadline/getvehicledeadlines",
  VEHICLE_DEADLINE_INSERT: "/cars/vehicledeadline/insertvehicledeadline",
  VEHICLE_DEADLINE_UPDATE: "/cars/vehicledeadline/updatevehicledeadline",
  VEHICLE_DEADLINE_DELETE: "/cars/vehicledeadline/deletevehicledeadline",

  DEADLINE_CATEGORY_GET: "/registry/deadlinecalendar/getdeadlinecategory",
  DEADLINE_TYPE_GET: "/registry/deadlinecalendar/getdeadlinetype",
  DEADLINE_BY_ACCOUNT_GET: "/registry/deadlinecalendar/getdeadlinebyaccount",
  DEADLINE_BY_ACCOUNT_BROKER_GET:
    "/registry/deadlinecalendar/getdeadlineexpiredbybroker",
  DEADLINE_EXPIRED_BY_ACCOUNT_GET:
    "/registry/deadlinecalendar/getdeadlineexpiredbyaccount",
  DEADLINE_EXPIRED_BY_ACCOUNT_DELETE:
    "/registry/deadlinecalendar/deletedeadline",
  DEADLINE_BY_BROKER_GET: "/registry/deadlinecalendar/getdeadlinebybroker",

  TICKETS_PDF_PRO: "/addedservice/ticket/insertticketpro",
  TICKETS_GET_STATUS: "/addedservice/ticket/getticketstatus",
  TICKETS_GET_LIST: "/addedservice/ticket/getticket",
  TICKETS_INSERT_TICKET: "/addedservice/ticket/insertticket",
  TICKETS_DELETE_TICKET: "/addedservice/ticket/deleteticket",
  TICKETS_UPDATE_TICKET: "/addedservice/ticket/updateticket",
  TICKETS_INSERT_DETAIL_TICKET: "/addedservice/ticket/insertticketdetail",
  TICKETS_UPDATE_DETAIL_TICKET: "/addedservice/ticket/updateticketdetail",
  TICKETS_DELETE_TICKET_DETAIL: "/addedservice/ticket/deleteticketdetail",
  TICKETS_INSERT_TICKET_PAYMENT: "/addedservice/ticket/insertticketpayment",
  TICKETS_UPDATE_TICKET_PAYMENT: "/addedservice/ticket/updateticketpayment",
  TICKETS_GET_PAYMENT_LIST: "/addedservice/ticket/getticketpayment",
  TICKETS_DELETE_PAYMENT: "/addedservice/ticket/deleteticketpayment",
  TICKETS_INSERT_MULTIPLE_DETAIL_TICKET:
    "/addedservice/ticket/insertmultipleticketdetail",
  TICKETS_UPDATE_MULTIPLE_DETAIL_TICKET:
    "/addedservice/ticket/updatemultipleticketdetail",
  TICKETS_UPDATE_DRIVER: "/addedservice/ticket/updateticketdriver",
  TICKETS_GET_VIOLATION_TYPE: "/addedservice/ticket/getviolationtype",
  TICKETS_TYPE_GET_LIST: "/addedservice/ticket/gettickettype",
  TICKETS_ISSUING_INSTITUTION_GET_LIST:
    "/addedservice/ticket/getticketissuinginstitution",
  TICKETS_ISSUING_INSTITUTION_INSERT:
    "/addedservice/ticket/insertticketissuinginstitution",
  TICKETS_ISSUING_INSTITUTION_UPDATE:
    "/addedservice/ticket/updateticketissuinginstitution",
  TICKETS_ISSUING_INSTITUTION_DELETE:
    "/addedservice/ticket/deleteticketissuinginstitution",

  TICKETS_ARTICLE_GET_LIST: "/addedservice/ticket/getticketarticle",
  TICKETS_ARTICLE_INSERT: "/addedservice/ticket/insertticketarticle",
  TICKETS_ARTICLE_UPDATE: "/addedservice/ticket/updateticketarticle",
  TICKETS_ARTICLE_DELETE: "/addedservice/ticket/deleteticketarticle",

  TICKETS_LINKABLE_TICKET_GET: "/addedservice/ticket/getlinkableticket",
  TICKETS_DELIVERY_TYPE_GET_LIST: "/addedservice/ticket/getticketdeliverytype",

  TICKETS_SERVICES_GET_ATTACHMENT: "/addedservice/ticket/getticketattachment",

  SEND_TICKET_MAIL_TO_DRIVER: "/addedservice/ticket/sendticketemailtodriver",
  TICKETS_SERVICES_INSERT_ATTACHMENT:
    "/addedservice/ticket/insertticketattachment",
  TICKETS_SERVICES_UPDATE_ATTACHMENT:
    "/addedservice/ticket/updateticketattachment",
  TICKETS_SERVICES_DELETE_ATTACHMENT:
    "/addedservice/ticket/deleteticketattachment",

  /// attachments
  VEHICLEDATA_GET_ATTACHMENT: "/cars/vehicledata/getvehicledataattachment",
  VEHICLEDATA_INSERT_ATTACHMENT:
    "/cars/vehicledata/insertvehicledataattachment",
  VEHICLEDATA_UPDATE_ATTACHMENT:
    "/cars/vehicledata/updatevehicledataattachment",
  VEHICLEDATA_DELETE_ATTACHMENT:
    "/cars/vehicledata/deletevehicledataattachment",

  // ROAD TAX
  VEHICLE_ROAD_TAX_GET: "/cars/vehicledata/getroadtax",
  VEHICLE_ROAD_TAX_INSERT: "/cars/vehicledata/insertroadtax",
  VEHICLE_ROAD_TAX_UPDATE: "/cars/vehicledata/updateroadtax",
  VEHICLE_ROAD_TAX_DELETE: "/cars/vehicledata/deleteroadtax",
  // ROAD TAX ADDITIONAL
  VEHICLE_ROAD_TAX_ADDITIONAL_GET: "/cars/vehicledata/getadditionalroadtax",
  VEHICLE_ROAD_TAX_ADDITIONAL_INSERT:
    "/cars/vehicledata/insertadditionalroadtax",
  VEHICLE_ROAD_TAX_ADDITIONAL_UPDATE:
    "/cars/vehicledata/updateadditionalroadtax",
  VEHICLE_ROAD_TAX_ADDITIONAL_DELETE:
    "/cars/vehicledata/deleteadditionalroadtax",
  // VEHICLE TEST
  VEHICLE_TEST_GET: "/cars/vehicledata/getvehicletest",
  VEHICLE_TEST_INSERT: "/cars/vehicledata/insertvehicletest",
  VEHICLE_TEST_UPDATE: "/cars/vehicledata/updatevehicletest",
  VEHICLE_TEST_DELETE: "/cars/vehicledata/deletevehicletest",
  // VEHICLE INSPECTION
  VEHICLE_INSPECTION_GET: "/cars/vehicledata/getvehicleinspection",
  VEHICLE_INSPECTION_INSERT: "/cars/vehicledata/insertvehicleinspection",
  VEHICLE_INSPECTION_UPDATE: "/cars/vehicledata/updatevehicleinspection",
  VEHICLE_INSPECTION_DELETE: "/cars/vehicledata/deletevehicleinspection",

  // vehicle disposal
  VEHICLE_DISPOSAL_GET_DISPOSAL_MODE:
    "/cars/vehicledisposal/getvehicledisposalmode",
  VEHICLE_DISPOSAL_GET_DISPOSAL: "/cars/vehicledisposal/getvehicledisposal",
  VEHICLE_DISPOSAL_INSERT_DISPOSAL:
    "/cars/vehicledisposal/insertvehicledisposal",
  VEHICLE_DISPOSAL_UPDATE_DISPOSAL:
    "/cars/vehicledisposal/updatevehicledisposal",
  VEHICLE_DISPOSAL_COMPLETE_DISPOSAL:
    "/cars/vehicledisposal/completevehicledisposal",

  VEHICLE_DISPOSAL_GET_LINKED_ITEMS:
    "/cars/vehicledisposal/GetVehicleDisposalItems",

  VEHICLE_DISPOSAL_ATTACHMENT_GET:
    "/cars/vehicledisposal/getvehicledisposalattachment",
  VEHICLE_DISPOSAL_ATTACHMENT_INSERT:
    "/cars/vehicledisposal/insertvehicledisposalattachment",
  VEHICLE_DISPOSAL_ATTACHMENT_UPDATE:
    "/cars/vehicledisposal/updatevehicledisposalattachment",
  VEHICLE_DISPOSAL_ATTACHMENT_DELETE:
    "/cars/vehicledisposal/deletevehicledisposalattachment",

  VEHICLE_DAMAGE_GET: "/cars/vehicledamage/getvehicledamage",
  VEHICLE_DAMAGE_INSERT: "/cars/vehicledamage/insertvehicledamage",
  VEHICLE_DAMAGE_UPDATE: "/cars/vehicledamage/updatevehicledamage",
  VEHICLE_DAMAGE_DELETE: "/cars/vehicledamage/deletevehicledamage",
  VEHICLE_DAMAGE_REPAIR: "/cars/vehicledamage/reparevehicledamage",

  VEHICLE_RESERVATION_GET: "/cars/vehiclereservation/getfleetreservation",
  VEHICLE_RESERVATION_GET_VEHICLE_AVAILABLE:
    "/cars/vehiclereservation/getavailablevehicle",
  VEHICLE_RESERVATION_INSERT:
    "/cars/vehiclereservation/insertvehiclereservation",
  VEHICLE_RESERVATION_UPDATE:
    "/cars/vehiclereservation/updatevehiclereservation",
  VEHICLE_RESERVATION_DELETE:
    "/cars/vehiclereservation/deletevehiclereservation",

  VEHICLE_RESERVATION_INCOMING_GET:
    "/cars/vehiclereservation/getincomingvehicle",
  VEHICLE_RESERVATION_INCOMING_INSERT:
    "/cars/vehiclereservation/insertincomingvehicle",
  VEHICLE_RESERVATION_INCOMING_UPDATE:
    "/cars/vehiclereservation/updateincomingvehicle",
  VEHICLE_RESERVATION_INCOMING_DELETE:
    "/cars/vehiclereservation/deleteincomingvehicle",

  VEHICLE_RESERVATION_OUTGOING_GET:
    "/cars/vehiclereservation/getoutgoingvehicle",
  VEHICLE_RESERVATION_OUTGOING_INSERT:
    "/cars/vehiclereservation/insertoutgoingvehicle",
  VEHICLE_RESERVATION_OUTGOING_UPDATE:
    "/cars/vehiclereservation/updateoutgoingvehicle",
  VEHICLE_RESERVATION_OUTGOING_DELETE:
    "/cars/vehiclereservation/deleteoutgoingvehicle",

  // messages
  MESSAGES_GET: "/registry/message/getmessage",
  MESSAGES_INSERT: "/registry/message/insertmessage",
  MESSAGES_UPDATE: "/registry/message/updatemessage",
  MESSAGES_ATTACHMENT_GET: "/registry/message/getmessageattachment",
  MESSAGES_ATTACHMENT_INSERT: "/registry/message/insertmessageattachment",

  // PAYMENTS
  PAYMENTS_ROAD_TAX_GET: "/addedservice/roadtax/getroadtax",
  PAYMENTS_ROAD_TAX_INSERT_PAYMENT_REQUEST:
    "/addedservice/roadtax/insertroadtaxpaymentrequest",
  PAYMENTS_ROAD_TAX_DOWNLOAD_PAYMENT_REQUEST_REPORT:
    "/addedservice/roadtax/downloadroadtaxpayreqreport",
  PAYMENTS_ROAD_TAX_DOWNLOAD_PAYMENT_REQUEST_PAID:
    "/addedservice/roadtax/downloadroadtaxpayreqpaid",
  PAYMENTS_ROAD_TAX_UPLOAD_INT: "/addedservice/roadtax/uploadroadtaxint",
  PAYMENTS_ROAD_TAX_UPLOAD_VER: "/addedservice/roadtax/uploadroadtaxver",
  PAYMENTS_ROAD_TAX_UPLOAD_SCAN_FILES:
    "/addedservice/roadtax/uploadroadtaxscanfiles",
  PAYMENTS_ROAD_TAX_BILL_GET: "/addedservice/roadtax/getroadtaxbill",
  PAYMENTS_ROAD_TAX_SEND_PAYMENT_UPDATE:
    "/addedservice/roadtax/updateroadtaxsendpayment",
  PAYMENTS_ROAD_TAX_PAYMENT_REQ_STEP_GET:
    "/addedservice/roadtax/getroadtaxpayreqstep",
  PAYMENTS_ROAD_TAX_PAYMENT_REQ_STEP_UPDATE:
    "/addedservice/roadtax/updateroadtaxpayreqstep",

  ROAD_TAX_CREDIT_DEDIT_GET: "/addedservice/roadtax/getroadtaxcreditdebit",

  FLEET_MANAGER_GET: "/registry/fleetmanager/getfleetmanagerlist",
  FLEET_MANAGER_INSERT: "/registry/fleetmanager/insertfleetmanager",

  GET_BROKER_TYPES: "/registration/usertypes/getbrokertypes",
  INSERT_BROKER_TYPES: "/registration/usertypes/setbrokertypes",

  PAYMENT_VOUCHER_GET: "/payment/voucher/getvoucher",
  PAYMENT_VOUCHER_CHECK_GET: "/payment/voucher/checkvoucher",
  PAYMENT_VOUCHER_INSERT: "/payment/voucher/insertvoucher",
  PAYMENT_VOUCHER_UPDATE: "/payment/voucher/updatevoucher",
  PAYMENT_VOUCHER_DELETE: "/payment/voucher/deletevoucher",

  ADMIN_COMPANY_LIST_GET: "/registry/admin/getcompanylist",
  ADMIN_BROKER_LIST_GET: "/registry/admin/getbrokerlist",

  MCTC_ATTACHMENT_GET: "/cars/vehicledata/getmctcattachment",
  MCTC_ATTACHMENT_INSERT: "/cars/vehicledata/insertmctcattachment",
  MCTC_ATTACHMENT_UPDATE: "/cars/vehicledata/updatemctcattachment",
  MCTC_ATTACHMENT_DELETE: "/cars/vehicledata/deletemctcattachment",

  GET_ACCOUNT_COMPANY: "/registry/company/getaccountcompany",

  INSERT_FAQ_CATEGORY: "/registry/faq/insertfaqcategory",
  UPDATE_FAQ_CATEGORY: "/registry/faq/updatefaqcategory",
  DELETE_FAQ_CATEGORY: "/registry/faq/deletefaqcategory",

  GET_BILLING_LAST_INFO: "/payment/payment/getbillinglatestinfo",
  GET_PURCHASE: "/payment/payment/getpurchase",
  SEND_REQUEST_TO_CAR_CONFIGURATOR: "/carconfigurator/outbound/sendrequesttocarconfigurator",

  GET_INFO_CAR_ZIP_UPLOAD: "/registry/infocar/getinfocarzipupload",
  INSERT_INFO_CAR_ZIP_UPLOAD: "/registry/infocar/insertinfocarzipupload",
  DELETE_INFO_CAR_ZIP_UPLOAD: "/registry/infocar/deleteinfocarzipupload",
  GET_INFO_CAR_DATA: "/registry/infocar/getinfocardata",
  GET_DOWNLOAD_ALL_ATTACHMENT: "/registry/account/downloadallattachments",
  VEHICLE_DELETE_KM_ENTRIES: "/cars/vehicleinfo/deletevehiclekilometersentries",
};

export default ApiEndPoints;
