import i18next from "i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { VehiclesActions } from "../../../../../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import FleetReservationForm from "../../../../../../Components/FleetReservationForm/FleetReservationForm";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";

const Fleet__AdditionalServicesPage__Reservation = () => {
  const dispatch = useDispatch();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const [loading, setLoading] = useState<boolean>(false);
  
  const loadVehicles = () => {
    if (loggedCompany) {
      setLoading(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    loadVehicles();
  }, []);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t(
          "navigation:_add_services_fleet_reservation_vehicle_title"
        )}
      />
      <div className="dashboardForm__box">
        <FleetReservationForm vehicles={vehicles} redirectTo={true} />
      </div>
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default Fleet__AdditionalServicesPage__Reservation;
