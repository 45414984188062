import i18next from "i18next";

import DashboardSectionTitle from "../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { ICompanyGet } from "../../../../../Models/ICompany";
import FormCompanyUser from "../../../../../Components/StepCompanyUsers/FormCompanyUser";
import { IUser } from "../../../../../Models/IUser";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* RETURN */
export default function AnagraphicsCompanies() {
  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* RETURN */
  return (
    <div className="padding-small">
      <DashboardSectionTitle title={i18next.t("navigation:_data_companies")} />
      <FormCompanyUser
        loggedUser={loggedUser}
        data={[]}
        companyUser={companyUser}
      />
    </div>
  );
}
