/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

/* STYLE */
import "./VehicleRequestsPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ISupplierGetSideList } from "../../../../../Models/ISupplier";
import { IUPDATE_CarConfigurator_Vehicle_Request_Approve } from "../../../../../Models/ICarConfigurator";
import { IINSERT_CarConfigurator_Request_SupplierPage } from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import FormSupplier from "../../../../../Components/StepSupplier/FormSupplier";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Box, Button, Checkbox, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import EditIcon from "@mui/icons-material/Edit";

/* COMPONENT */
const VehicleRequestsPageOrder = () => {
  /* REDUX */
  // User
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* STATES */
  const [list, setList] = useState<ISupplierGetSideList[]>([]);
  // Supplier selected
  const [currentSupplier, setCurrentSupplier] = useState<any>([]);
  // Current selection
  const [currentData, setCurrentData] = useState<any[]>([]);
  // Loading
  const [loadingSupplierList, setLoadingSupplierList] = useState<boolean>(false);
  const [loadingInsertRequest, setLoadingInsertRequest] = useState<boolean>(false);
  const [loadingUpdateApprove, setLoadingUpdateApprove] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* URL PARAMS */
  const [params] = useSearchParams();
  const vehicleID: string | null = params.get("vehicle");

  /* APIs */
  // Load suppliers list
  const loadSuppliersList = () => {
    setLoadingSupplierList(true);

    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            const tempPayload = response.payload.filter((x: any) =>
              [15, 16, 19].includes(x.supplierInfo.supplierTypeID)
            );

            setList(tempPayload);
          } else {
            ToastMessage(response.error, "error");
          }

          setLoadingSupplierList(false);
        }
      );
    }
  };

  // Insert request
  const INSERT_CarConfigurator_Request_SupplierPage = (
    data: IINSERT_CarConfigurator_Request_SupplierPage
  ) => {
    setLoadingInsertRequest(true);

    ApiService.CarConfiguratorController.INSERT_CarConfigurator_Request_SupplierPage(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsertRequest(false);
      }
    );
  };

  // Accept request
  const UPDATE_CarConfigurator_Vehicle_Request_Approve = (
    data: IUPDATE_CarConfigurator_Vehicle_Request_Approve
  ) => {
    setLoadingUpdateApprove(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfigurator_Vehicle_Request_Approve(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");

          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateApprove(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadSuppliersList();
  }, []);

  /* FUNCTIONS */
  // Row selection
  const renderSelect = (props: GridRenderCellParams) => {
    return (
      <Checkbox
        checked={props.row.selected}
        onChange={(_, checked) => {
          // Controlled checkbox
          list[props.row.id].selected = checked;

          // Save selected item
          let tmp = [...currentData];

          if (checked) {
            tmp.push(props.row.list);
          } else {
            tmp = tmp.filter((x: any) => x.supplierID !== props.row.supplierID);
          }

          setCurrentData(tmp);
        }}
      />
    );
  };

  // Convert API to State
  const fromAPItoState = (rawState: ISupplierGetSideList) => {
    let supplierInfo: any = { ...rawState.supplierInfo };

    let arr: any = rawState.companyIDs.map((x: any) => x.companyID.toString());
    let companyIDs: any = { supplierCompany: arr };

    // Merge into a final state
    let finaleState: any = {
      ...supplierInfo,
      ...companyIDs,
    };

    return finaleState;
  };

  // Actions
  const renderActions = (props: GridRenderCellParams) => {
    return (
      <div>
        <IconButton>
          <EditIcon
            className="icon-table"
            onClick={() => {
              const supplier = list.filter(
                (x: ISupplierGetSideList) =>
                  x.supplierInfo.supplierID === props.row.supplierID
              )[0];

              setCurrentSupplier(fromAPItoState(supplier));

              SummonModal("add-supplier-modal");
            }}
          />
        </IconButton>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "select",
      headerName: "",
      renderCell: renderSelect,
    },
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      type: 'custom'
    },
    {
      field: "supplier",
      headerName: i18next.t("form:vehicleSupplier"),
    },
    {
      field: "supplierType",
      headerName: i18next.t("form:suppliersType"),
    },
    {
      field: "supplierEmail",
      headerName: i18next.t("form:emailReferent"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    let supplierTypeDescription: string = "";
    switch (list[i].supplierInfo.supplierTypeID) {
      case 15:
        supplierTypeDescription = i18next.t("navigation:statistics_LongRental");
        break;
      case 16:
        supplierTypeDescription = i18next.t("navigation:statistics_MidShortRental");
        break;
      case 19:
        supplierTypeDescription = i18next.t("navigation:statistics_Leasing");
        break;
      default:
        break;
    }

    // Row
    rowsDef.push({
      id: i,
      list: list[i].supplierInfo,
      selected: list[i].selected,

      supplierID: list[i].supplierInfo.supplierID,
      supplier: list[i].supplierInfo.supplierName,
      supplierType: supplierTypeDescription,
      supplierEmail: list[i].supplierInfo.supplierReferentEmail,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("navigation:_configure_vechicles_request_summary")}
        subtitle={i18next.t("navigation:_configure_vechicles_request_order")}
        subtitlePath={AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_SUMMARY}
        onBack={() => navigate(-1)}
      />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small gap-small">
          <Button
            variant="outlined"
            onClick={() => {
              SummonModal("add-supplier-modal");
            }}
          >
            {i18next.t("navigation:_add_supplier")}
          </Button>

          <Button
            disabled={currentData.length === 0}
            variant="contained"
            onClick={() => {
              SummonModal("send-selections-modal");
            }}
          >
            {i18next.t("form:sendSelections")}
          </Button>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
      </div>

      <SmartModal
        hideButtons={true}
        modalUniqueId="add-supplier-modal"
        title={i18next.t("navigation:_add_supplier")}
        modalInnerComponent={
          <FormSupplier
            loggedUser={loggedUser}
            idModal={"add-supplier-modal"}
            data={currentSupplier ?? []}
            loadSuppliersSideList={() => loadSuppliersList()}
            newSupplierTypeList={[
              "RENT_LONGTIME",
              "RENT_MIDSHORTTIME",
              "LEASING",
            ]}
          />
        }
      />

      <SmartModal
        modalUniqueId="send-selections-modal"
        title={i18next.t("form:sendSelections")}
        modalInnerComponent={
          <div className="modal-width-medium">
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModalSelection")
                  .replace("CURRENT_ITEM_LIST", currentData.length.toString()),
              }}
            />
          </div>
        }
        onAccept={() => {
          if (vehicleID) {
            INSERT_CarConfigurator_Request_SupplierPage({
              driverGroupVehiclesRequestID: +vehicleID,
              suppliers: currentData.map((x: any) => x.supplierID),
            });

            UPDATE_CarConfigurator_Vehicle_Request_Approve({
              driverGroupVehiclesRequestID: +vehicleID,
            });
          }
        }}
        onReject={() => {
          DismissModal("send-selections-modal");
        }}
      />

      <LoaderBackdrop
        loading={loadingSupplierList || loadingInsertRequest || loadingUpdateApprove}
      />
    </div>
  );
};

export default VehicleRequestsPageOrder;
