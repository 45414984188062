/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

/* MODELS */
import { IInsuranceGet, IInsuranceGetDetailType } from "../../../../../Models/IInsurance";
import { IUser } from "../../../../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Assurances__ServicesPage.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import FormInsurance from "../../../../../Components/StepVehicles/TabInsurance/FormInsurance";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IVehicleDataByAccountID } from "../../../../../Models/IVehicleDataByAccountID";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IPaymentType } from "../../../../../Models/IPayment";
import { IVehicleServicePaymentRecurrenceType } from "../../../../../Models/IVehicleService";
import { currencyDecoder } from "../../../../../Utils/Decoder";

/* COMPONENT */
const Assurances__ServicesPage = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const mainCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [loadingVehicleData, setLoadingVehicleData] = useState<boolean>(false);
  // Container for list
  const [list, setList] = useState<IInsuranceGet[]>([]);
  const [editInsurance, setEditInsurance] = useState<IInsuranceGet | undefined>(
    undefined
  );
  const [vehicleData, setVehicleData] = useState<IVehicleDataByAccountID[]>([]);
  const [currentListAssuranceDetails, setCurrentListAssuranceDetails] = useState<any>({});
  const [loadingPaymentRecurrenceType, setLoadingPaymentRecurrenceType] = useState<boolean>(false);
  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<IVehicleServicePaymentRecurrenceType[]>([]);
  /* APIs */
  // Load list
  const loadList = () => {
    if (loggedUser) {
      setLoadingList(true);
      ApiService.InsuranceController.InsuranceGet(
        null,
        null,
        mainCompany?.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingList(false);
        }
      );
    }
  };

  const loadPaymentRecurrenceType = () => {
    if (loggedUser) {
      setLoadingPaymentRecurrenceType(true);
      ApiService.VehicleServiceController.GetPaymentRecurrenceType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentRecurrenceType(response.payload.filter((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID !== 6));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentRecurrenceType(false);
        }
      );
    }
  };

  const loadVehicleDataByAccountID = () => {
    if (loggedUser) {
      setLoadingVehicleData(true);
      ApiService.VehicleDataController.VehicleDataGetByAccountID(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setVehicleData(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicleData(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__ASSURANCE_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  const editCurrentAssurance = (assurance: any) => {
    let insurance_: IInsuranceGet | undefined = list.find(
      (x: IInsuranceGet) => x.info.insuranceID === assurance.insuranceID
    );
    if (insurance_) {
      setEditInsurance(insurance_);
      SummonModal("insurance-page-modal");
    }
  };

  // Linkable value
  const editRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            editCurrentAssurance(props.row);
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  
  const detailsRow = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          /* COLUMNS */
          const columnsDef_AssuranceDetails: IDataGridColumnDef[] = [
            {
              field: "insuranceDetailTypeID",
              headerName: i18next.t("form:insuranceDetailType"),
            },
            {
              field: "covarageLimit",
              headerName: i18next.t("form:insuranceMaxValue"),
            },
            {
              field: "deductible",
              headerName: i18next.t("form:serviceShoreLeave"),
            },
            {
              field: "covarage",
              headerName: i18next.t("form:insuranceValue"),
            },
          ];

          /* ROWS */
          const rowsDef_AssuranceDetails = [];

          for (let i = 0; i < props.value.length; i++) {
            // Push row
            rowsDef_AssuranceDetails.push({
              id: i,
              insuranceDetailTypeID: props.value[i].insuranceDetailTypeName,
              covarageLimit: currencyDecoder(props.value[i].covarageLimit, 2),
              deductible: currencyDecoder(props.value[i].deductible, 2),
              covarage: props.value[i].covarage + " %",
            });
          }

          setCurrentListAssuranceDetails({
            columns: columnsDef_AssuranceDetails,
            rows: rowsDef_AssuranceDetails,
          });
          SummonModal("assurance-details-modal");
        }}
      >
        <VisibilityIcon />
      </Button>
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadList();
    loadVehicleDataByAccountID();
    loadPaymentRecurrenceType();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "assurancesPlate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "assurancesSupplier",
      headerName: i18next.t("form:vehicleInsuranceCompany"),
    },
    {
      field: "assurancesDateFrom",
      headerName: i18next.t("navigation:start"),
    },
    {
      field: "assurancesDateTo",
      headerName: i18next.t("navigation:end"),
    },
    {
      field: "assurancesDetails",
      headerName: i18next.t("form:insuranceDetailType"),
      renderCell: detailsRow,
    },
    {
      field: "paymentTypeID",
      headerName: i18next.t("form:insuranceModePayment"),
    },
    {
      field: "paymentRecurrenceID",
      headerName: i18next.t("form:insuranceRecurrence"),
    },
    {
      field: "amount",
      headerName: i18next.t("form:insuranceImportInstallment"),
      type: "number"
    },
    {
      field: "policyNumber",
      headerName: i18next.t("form:insuranceNumber"),
      type: "number"
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    if (list[i].info.stored === false) {
      rowsDef.push({
        id: i,
        actions: "",  
        vehicleID: list[i].info.vehicleID,
        insuranceID: list[i].info.insuranceID,
        assurancesPlate: list[i].info.vehicleLicensePlate,
        assurancesSupplier: list[i].info.supplierName,
        assurancesDateFrom: displayUTC0_ISODate(list[i].info.startDT),
        assurancesDateTo: displayUTC0_ISODate(list[i].info.endDT),
        assurancesDetails: list[i].details,
        paymentTypeID: list[i].info.paymentTypeName,
        paymentRecurrenceID: paymentRecurrenceType.find((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID === list[i].info.paymentRecurrenceID)?.paymentRecurrenceName,
        amount: currencyDecoder(list[i].info.amount, 2),
        policyNumber: list[i].info.policyNumber,
      });
    }
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_insurance_title")} />
      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setEditInsurance(undefined);
                SummonModal("insurance-page-modal");
              }}
            >
              {i18next.t("navigation:_add_insurance")}
            </Button>
          </ButtonGroup>
        </div>
        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          hideButtons={true}
          modalUniqueId="insurance-page-modal"
          title={i18next.t("generic:titleAddInsurance")}
          modalInnerComponent={
            <FormInsurance
              disabledAccordion={true}
              loggedUser={loggedUser}
              dataVehicle={undefined}
              expressInsuranceData={editInsurance}
              loadingInsurance={true}
              vehicleData={vehicleData}
              onDataEdited={() => {
                DismissModal("insurance-page-modal");
                loadList();
              }}
            />
          }
        />
        <SmartModal
          hideButtons={true}
          modalUniqueId="assurance-details-modal"
          title={i18next.t("generic:assuranceDetails")}
          modalInnerComponent={
            <div className="modal-width-large">
              <DataGridWrap
                headers={currentListAssuranceDetails && currentListAssuranceDetails.columns}
                rows={currentListAssuranceDetails && currentListAssuranceDetails.rows}
              />
            </div>
          }
        />
        <LoaderBackdrop loading={loadingList || loadingVehicleData || loadingPaymentRecurrenceType} />
      </div>
    </div>
  );
};

export default Assurances__ServicesPage;
