import i18next from "i18next";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import "./VehiclesPage_subPages_OtherServices.scss";
import { useSelector } from "react-redux";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import FormServices from "../../../../../../Components/StepVehicles/TabServices/FormServices";
import {
  IVehicleServiceGet,
  IVehicleServicePaymentRecurrenceType,
} from "../../../../../../Models/IVehicleService";
import { useState } from "react";
import { SummonModal } from "../../../../../../Components/SmartModal/SmartModal";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { currencyDecoder } from "../../../../../../Utils/Decoder";

const VehiclesPage_subPages_OtherServices = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const [vehicleServices, setVehicleServices] = useState<IVehicleServiceGet[]>([]);
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<
    IVehicleServicePaymentRecurrenceType[]
  >([]);

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_vheicles_tab_nav_otherservices_costs")}
        rightButtons={[
          {
            label: i18next.t("navigation:_service_history"),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
        ]}
      />
      <ResumeModal
        title={i18next.t("navigation:_service_history")}
        rows={vehicleServices
          .filter((x: IVehicleServiceGet) => x.stored)
          .map((x: IVehicleServiceGet) => {
            let supplier: ISupplierGetSideList | undefined = supplierList.find(
              (y: ISupplierGetSideList) => y.supplierInfo.supplierID === x.supplierID
            );
            let payType: IVehicleServicePaymentRecurrenceType | undefined =
              paymentRecurrenceType.find(
                (y: IVehicleServicePaymentRecurrenceType) =>
                  y.paymentRecurrenceID === x.paymentRecurrenceID
              );
            return {
              paymentRecurrenceID: x.paymentRecurrenceID === 1 ? (i18next.t("form:costRecurring") as string) : (i18next.t("form:costNotRecurring") as string),
              vehicleServiceName: x.vehicleServiceName,
              supplierName: supplier ? supplier.supplierInfo.supplierName : "-",
              vehicleServiceStartDT: displayUTC0_ISODate(x.vehicleServiceStartDT, true),
              vehicleServiceStartDTOriginal: x.vehicleServiceStartDT,
              vehicleServiceEndDT: displayUTC0_ISODate(x.vehicleServiceEndDT, true),
              vehicleServiceEndDTOriginal: x.vehicleServiceEndDT,
              vehicleServiceAmount: currencyDecoder(x.vehicleServiceAmount, 2),
              paymentRecurrenceName: payType ? payType.paymentRecurrenceName : "-",
              eventDT: x.eventDT ? displayUTC0_ISODate(x.eventDT, true) : "-",
              eventDTOriginal: x.eventDT,
              vehicleServiceNote: x.vehicleServiceNote,
            };
          })}
        headers={[
          {
            field: "paymentRecurrenceID",
            headerName: i18next.t("form:costRecurrTitle"),
          },
          {
            field: "vehicleServiceName",
            headerName: i18next.t("form:nameService"),
          },
          {
            field: "supplierName",
            headerName: i18next.t("form:supplierVehicleService"),
          },
          {
            field: "vehicleServiceStartDT",
            headerName: i18next.t("form:dateStart"),
            fieldOriginal: "vehicleServiceStartDTOriginal",
          },
          {
            field: "vehicleServiceEndDT",
            headerName: i18next.t("form:dateFinish"),
            fieldOriginal: "vehicleServiceEndDTOriginal",
          },
          {
            field: "vehicleServiceAmount",
            headerName: i18next.t("form:serviceImport"),
          },
          {
            field: "paymentRecurrenceName",
            headerName: i18next.t("form:serviceDebitType"),
          },
          {
            field: "eventDT",
            headerName: i18next.t("form:dateEvent"),
            fieldOriginal: "eventDTOriginal",
          },
          {
            field: "vehicleServiceNote",
            headerName: String(i18next.t("form:note")),
          },
        ]}
      />
      {vehicle && (
        <div className="add-border margin-top-small">
          <FormServices
            disabled={disposed > 0}
            emitAllPaymentTypes={setPaymentRecurrenceType}
            emitAllServices={setVehicleServices}
            emitAllSuppliers={setSupplierList}
            loggedUser={loggedUser}
            dataVehicle={vehicle}
            doRefreshForm={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default VehiclesPage_subPages_OtherServices;
