/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "./VehicleConfigurationPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* SERVICE */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IGoogleAddress } from "../../../../../Utils/Google";

/* MODELS */
import { IDriverGetLicenceType } from "../../../../../Models/IDriver";
import {
  IGET_CarConfigurator_Vehicle,
  IGET_RELATIONSHIP,
  IINSERT_CarConfigurator_Vehicle_Summary,
} from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import MultiForm, {
  IMultiFormField,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { renderBrandImageFunc } from "../VehicleOffersPage/VehicleOffersPage";

/* MUI */
import { Alert, Box, Divider, Typography } from "@mui/material";
// Icons
import PinDropIcon from "@mui/icons-material/PinDrop";

/* COMPONENT */
const VehicleConfigurationPageSummary = () => {
  /* STATES */
  // Vehicle's data
  const [list, setList] = useState<IGET_CarConfigurator_Vehicle[]>([]);
  // Form data
  const [formData, setFormData] = useState<any>([]);
  // Licence multiselect
  const [licenceType, setLicenceType] = useState<IDriverGetLicenceType[]>([]);
  // Relationship
  const [relationship, setRelationship] = useState<IGET_RELATIONSHIP[]>([]);
  // Loading
  const [loadingLicenceType, setLoadingLicenceType] = useState<boolean>(false);
  const [loadingRelationship, setLoadingRelationship] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingGet, setLoadingGet] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* URL PARAMS */
  const [params] = useSearchParams();
  const vehicleID: string | null = params.get("vehicleID");

  /* API */
  // Get vehicle info
  const GET__CARCONFIGURATOR_VEHICLES = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET__CARCONFIGURATOR_VEHICLES(
      vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Load licence type
  const loadLicenceType = () => {
    setLoadingLicenceType(true);

    ApiService.DriverController.DriverGetLicenceType((response: IAPIResponse) => {
      if (response.error === null) {
        setLicenceType(response.payload);
      } else {
        ToastMessage(i18next.t("error:ERROR"), "error");
      }

      setLoadingLicenceType(false);
    });
  };

  // Load relationship
  const loadRelationshio = () => {
    setLoadingRelationship(true);

    ApiService.CarConfiguratorController.GET_RELATIONSHIP(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setRelationship(response.payload);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingRelationship(false);
      }
    );
  };

  // Save the vehicle summary
  const INSERT_CarConfigurator_Vehicle_Summary = (
    data: IINSERT_CarConfigurator_Vehicle_Summary
  ) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_CarConfigurator_Vehicle_Summary(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");

          navigate(-1);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    loadLicenceType();
    loadRelationshio();

    if (vehicleID) {
      GET__CARCONFIGURATOR_VEHICLES();
    }
  }, []);

  /* MULTIFORM */
  const multiFormInputs: IMultiFormField[] = [
    // Title
    {
      type: "custom",
      name: "title",
      width: 100,
      element: (
        <div>
          <Typography variant="h5">{i18next.t("form:delivery")}</Typography>
          <Typography variant="body2">{i18next.t("form:deliveryText")}</Typography>
        </div>
      ),
    },
    // Address
    {
      fullWidth: true,
      width: 100,
      type: "custom",
      name: "PlaceAutoCompleteForm",
      className: "content-place-auto-complete",
      element: (
        <PlaceAutoCompleteForm
          addressId="vehicleAddressStreet"
          onChange={(data: IGoogleAddress) => {
            setFormData({ ...formData, ...data });
          }}
          inputs={[
            {
              width: 33.3,
              type: "text",
              name: "vehicleAddressStreet",
              defaultValue: "",
              mapDecode: "route",
              required: true,
              label: `${i18next.t("form:deliveryLocation")}`,
              inputAdornament: { adornament: <PinDropIcon /> },
            },
            {
              width: 33.3,
              type: "text",
              name: "vehicleAddressNumber",
              defaultValue: formData?.vehicleAddressNumber,
              mapDecode: "street_number",
              required: true,
              label: String(i18next.t("form:civicNumberAbbr")),
            },
            {
              width: 33.3,
              type: "select_nations",
              name: "vehicleAddressCountryID",
              currentValue: formData?.vehicleAddressCountryID ?? "",
              defaultValue: formData?.vehicleAddressCountryID,
              mapDecode: "country",
              required: true,
              label: String(i18next.t("form:countryResidence")),
            },
            {
              width: 33.3,
              type: "text",
              name: "vehicleAddressCAP",
              defaultValue: formData?.vehicleAddressCAP,
              mapDecode: "postal_code",
              inputProps: { maxLength: 20 },
              required: true,
              label: String(i18next.t("form:postalCode")),
            },
            {
              width: 33.3,
              type: "text",
              name: "vehicleAddressCity",
              defaultValue: formData?.vehicleAddressCity,
              mapDecode: "locality",
              required: true,
              label: String(i18next.t("form:city")),
            },
            {
              width: 33.3,
              type: "text",
              name: "vehicleAddressProv",
              defaultValue: formData?.vehicleAddressProv,
              maxLength: 5,
              required: true,
              mapDecode: "province",
              label: String(i18next.t("form:provinceAbbr")),
            },
          ]}
        />
      ),
    },
    // Divider
    {
      type: "custom",
      name: "space",
      width: 100,
      className: "minHeight",
      element: <Divider />,
    },
    // Driver number
    {
      width: 20,
      type: "select",
      name: "driversNumber",
      required: true,
      defaultValue: formData?.driversNumber,
      label: `${i18next.t("form:driversNumber")}`,
      options: [1, 2, 3, 4, 5].map((item: any) => {
        return {
          key: item,
          text: item.toString(),
        };
      }),
    },
  ];

  // Inputs based on drivers number
  if (formData?.driversNumber >= 1) {
    for (let i = 0; i < formData?.driversNumber; i++) {
      multiFormInputs.push(
        {
          type: "custom",
          name: "title",
          width: 100,
          className: "VehicleConfigurationPage__Summary-driver",
          element: (
            <div>
              <Typography variant="subtitle2">
                {i18next.t("form:assignDriverName")} {i + 1}
              </Typography>
            </div>
          ),
        },
        {
          width: 20,
          type: "select",
          name: "vehicleRelationshipID_" + i,
          required: true,
          label: `${i18next.t("form:relationship")}`,
          options: relationship.map((item: any) => {
            return {
              key: item.id,
              text: item.description,
            };
          }),
        },
        {
          width: 20,
          type: "text",
          name: "vehicleLicenceNumber_" + i,
          required: true,
          label: `${i18next.t("form:patentNumber")}`,
          inputProps: {
            maxLength: 10,
          },
        },
        {
          width: 20,
          type: "select",
          name: "vehicleLicenceType_" + i,
          required: true,
          label: `${i18next.t("form:patentType")}`,
          defaultValue: formData?.["vehicleLicenceType_" + i] || [],
          multiple: true,
          options: licenceType.map((item: IDriverGetLicenceType) => {
            return {
              key: item.licenceTypeID,
              text: item.licenceTypeName,
            };
          }),
        },
        {
          width: 20,
          type: "date",
          name: "vehicleLicenseIssuing_" + i,
          required: true,
          disableFuture: true,
          label: `${i18next.t("form:licenseIssuing")}`,
        },
        {
          width: 20,
          type: "date",
          name: "vehicleLicenceDeadline_" + i,
          required: true,
          disablePast: true,
          label: `${i18next.t("form:patentExpireDate")}`,
        }
      );
    }
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("form:vehiclesCompare")}
        subtitle={i18next.t("navigation:_configure_vechicles_request_summary")}
        subtitlePath={AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE}
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE
          )
        }
      />

      <div className="dashboardForm__box">
        {vehicleID && list && Object.keys(list).length > 0 ? (
          <div>
            <Box className="dashboardForm__data display-flex gap-large padding-small">
              <div className="VehicleRequestsPage-box">
                <div className="display-flex-horizontal-space-between">
                  <div>
                    <Typography variant="h4">{list[0].vehicleBrandName}</Typography>

                    <Typography variant="overline">
                      {list[0].vehicleFittingName}
                    </Typography>
                  </div>
                  {renderBrandImageFunc(list[0].vehicleBrandName)}
                </div>

                <Divider className="margin-vertical-small" />

                <div className="VehicleConfigurationPage__Summary-list">
                  {[
                    "vehicleAntipollutionLegislation",
                    "vehicleCarBody",
                    "vehicleDoorsNumber",
                    "vehicleSeatsNumber",
                    "vehicleLength",
                    "vehicleWidth",
                    "vehicleHeight",
                    "vehicleTires",
                  ].map((x: any, i: number) => (
                    <Typography variant="overline" className="margin-vertical-small">
                      <strong>{i18next.t(`form:vehiclesCompareLabel_${i + 3}`)}</strong>
                      {": "}
                      {/* list[x] */}
                    </Typography>
                  ))}
                </div>
              </div>
            </Box>

            <div className="VehicleConfigurationPage__Summary-box">
              <MultiForm
                suppressLayout
                suppressSubmit={false}
                submitButtonLabel={`${i18next.t("generic:sendRequest")}`}
                formId={"VehicleConfigurationSummaryForm"}
                inputs={multiFormInputs}
                onChange={(data: any) => {
                  setFormData({ ...formData, ...data });
                }}
                onSubmit={() => {
                  // Clone
                  const tempData = lodash.cloneDeep(formData);

                  // Convert element for API
                  const finalArr: any = [];

                  for (let i = 0; i < tempData["driversNumber"]; i++) {
                    const tempObj: any = {};

                    const tempArr: any[] = Object.keys(tempData).filter((x: any) =>
                      x.includes("_" + i)
                    );

                    for (let j = 0; j < 5; j++) {
                      tempObj[tempArr[j].slice(0, -2)] = tempData[tempArr[j]];
                    }

                    finalArr.push(tempObj);
                  }

                  // Delete
                  delete tempData["title"];
                  delete tempData["PlaceAutoCompleteForm"];
                  delete tempData["driversNumber"];
                  delete tempData["space"];

                  // Save
                  INSERT_CarConfigurator_Vehicle_Summary({
                    driverGroupVehiclesID: +vehicleID,
                    delivery: {
                      vehicleAddressStreet: tempData["vehicleAddressStreet"],
                      vehicleAddressNumber: tempData["vehicleAddressNumber"],
                      vehicleAddressCountryID: tempData["vehicleAddressCountryID"],
                      vehicleAddressCAP: tempData["vehicleAddressCAP"],
                      vehicleAddressCity: tempData["vehicleAddressCity"],
                      vehicleAddressProv: tempData["vehicleAddressProv"],
                    },
                    drivers: finalArr,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
        )}
      </div>

      <LoaderBackdrop
        loading={loadingLicenceType || loadingRelationship || loadingInsert || loadingGet}
      />
    </div>
  );
};

export default VehicleConfigurationPageSummary;
