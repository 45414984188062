import { useSelector } from "react-redux";
import "./Create__VehiclePage.scss";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { Form__vehicleData } from "../VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import {
  IOrderGetSide,
  IOrderGetSideDetail,
} from "../../../../../Models/IOrder";
import { Alert, Button, Tab, Tabs, Typography } from "@mui/material";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import ApiService from "../../../../../Services/ApiService";
import { useEffect, useState } from "react";
import { IVehicleDataGet } from "../../../../../Models/IVehicles";
import { ICompanyGet } from "../../../../../Models/ICompany";
import TabContract from "../../../../../Components/StepVehicles/TabContract/TabContract";
import TabAssignment from "../../../../../Components/StepVehicles/TabAssignment/TabAssignment";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { requestFormRefresh } from "../../../../../Components/MultiForm/MultiForm";
import FormVehicleData from "../../../../../Components/StepVehicles/TabVehicleData/FormVehicleData";

const Create__VehiclePage = () => {
  const navigate = useNavigate();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [stateVehicle, setStateVehicle] = useState<IVehicleDataGet | null>(
    null
  );
  const [stateAssignment, setStateAssignment] = useState<any>(null);
  const [currentSecondaryTab, setCurrentSecondaryTab] = useState<string>(
    "secondary-tab-vehicle"
  );
  const vehicleMctc = useSelector(
    (state: GlobalState) => state.vehicles.vehicleMctc
  );

  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);
  const [loadingCurrentAssignment, setLoadingCurrentAssignment] =
    useState<boolean>(false);
  const [stateDetails, setStateDetails] = useState<IOrderGetSideDetail[]>([]);
  const [contractUpdate, setContractUpdate] = useState<boolean>(false);
  const [assignmentUpdate, setAssignmentUpdate] = useState<boolean>(false);

  const handleChangeSecondary = (newValue: string) => {
    setCurrentSecondaryTab(newValue);
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  useEffect(() => {
    if (vehicleMctc && vehicleMctc.forForm !== undefined) {
      let cleanState: any = {};
      let keys = Object.keys(vehicleMctc.forForm);
      for (let i = 0; i < keys.length; i++) {
        let val: any = vehicleMctc.forForm[keys[i]];
        if (val !== null && val !== undefined) {
          cleanState[keys[i]] = val;
        }
      }
      setStateVehicle({ ...stateVehicle, ...cleanState });
      requestFormRefresh();
    }
  }, [vehicleMctc]);

  const loadCurrentAssignment = (vehicleID: number) => {
    if (loggedUser && vehicleID) {
      setLoadingCurrentAssignment(true);
      ApiService.AssignmentController.AssignmentGet(
        null,
        vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setStateAssignment(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentAssignment(false);
        }
      );
    }
  };

  const showOrderResume = () => {
    let o: string | null = localStorage.getItem("order-transform");

    if (o) {
      let order: { detailIndex: number; order: IOrderGetSide } = JSON.parse(o);

      return (
        <div className="order-conversion-box">
          <strong>{i18next.t("navigation:gettingVehicleFromOrder")}</strong>
          <div style={{ opacity: "0.7" }}>
            {"#" + (order.order.info.vehicleOrderNumber ?? "-")}
          </div>
          <div>
            {(order.order.info.contractTypeName ?? "-") +
              ", " +
              (order.order.info.brandName ?? "-") +
              " " +
              (order.order.info.modelName ?? "-") +
              " " +
              (order.order.info.fittingName ?? "-")}
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  useEffect(() => {
    doRefreshForm();
    let o: string | null = localStorage.getItem("order-transform");
    if(o){
      let order: { detailIndex: number; order: IOrderGetSide } = JSON.parse(o);
      setStateVehicle(order.order.info as any);
      setStateDetails(order.order.details as any);
    }
  }, []);

  return (
    <div style={{ background: "white", padding: "1em" }}>
      <div className="row-items-title">
        <h2>{i18next.t("navigation:new_vehicle_create")}</h2>
        {showOrderResume()}
      </div>

      {loggedUser && (
        <div>
          {stateVehicle?.vehicleID === undefined && (
            <Alert severity="info">
              {i18next.t("navigation:mustCreateVehicleToContinue")}
            </Alert>
          )}
          <div role="tabpanel" id="tab-manual" aria-labelledby="tab-manual">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tabs
                className="tabs-vehicle-wrapper"
                value={currentSecondaryTab}
                aria-label="basic tabs example"
              >
                <Tab
                  value="secondary-tab-vehicle"
                  onClick={() => handleChangeSecondary("secondary-tab-vehicle")}
                  label={i18next.t("navigation:vehicleTabTitle")}
                  id="secondary-tab-vehicle"
                  aria-controls="secondary-tab-vehicle"
                />
                <Tab
                  disabled={stateVehicle?.vehicleID === undefined}
                  value="secondary-tab-contract"
                  onClick={() =>
                    handleChangeSecondary("secondary-tab-contract")
                  }
                  label={i18next.t("navigation:contractTabTitle")}
                  id="secondary-tab-contract"
                  aria-controls="secondary-tab-contract"
                />
                <Tab
                  disabled={stateVehicle?.vehicleID === undefined}
                  value="secondary-tab-assignment"
                  onClick={() =>
                    handleChangeSecondary("secondary-tab-assignment")
                  }
                  label={i18next.t("navigation:assignmentTabTitle")}
                  id="secondary-tab-assignment"
                  aria-controls="secondary-tab-assignment"
                />
              </Tabs>
              <div style={{ minWidth: "8em" }}>
                <Button
                  disabled={stateVehicle?.vehicleID === undefined}
                  variant={"contained"}
                  onClick={() => {
                    if(!contractUpdate || !assignmentUpdate){
                      SummonModal("gotovehiclemodal");
                    }else{
                      navigate(
                        AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                          "?vehicleID=" +
                          stateVehicle?.vehicleID.toString()
                      );
                    }
                  }}
                >
                  {i18next.t("navigation:gotovehicle")}
                </Button>
              </div>
            </div>
            <SmartModal
              title={i18next.t("navigation:gotovehicle")}
              modalUniqueId="gotovehiclemodal"
              modalInnerComponent={
                <div>
                  <Alert severity="info">
                    {i18next.t("navigation:gotovehicleWarn")}
                  </Alert>
                </div>
              }
              onAccept={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                    "?vehicleID=" +
                    stateVehicle?.vehicleID.toString()
                );
              }}
              onReject={() => {
                DismissModal("gotovehiclemodal");
              }}
            />

            <div className="container-content-form">
              <div className="add-border multi-form-background-wide">
                {stateVehicle?.vehicleID && (
                  <Typography padding={2}>
                    {i18next.t("navigation:tab_info_vehicle")} (
                    {stateVehicle?.vehicleLicensePlate?.toUpperCase()} -{" "}
                    {stateVehicle?.vehicleChassis?.toUpperCase()})
                  </Typography>
                )}
                {!refreshForm && stateVehicle && (
                  <>
                    <div
                      hidden={currentSecondaryTab !== "secondary-tab-vehicle"}
                    >
                      <FormVehicleData
                        canInsertVehicles
                        companyUser={companyUser}
                        loggedUser={loggedUser}
                        dataVehicle={stateVehicle ?? undefined}
                        isModal={true}
                        doRefreshForm={(data: any) => {
                          setStateVehicle(data);
                          doRefreshForm();
                        }}
                      />
                    </div>
                    <TabContract
                      loggedUser={loggedUser}
                      companyUser={companyUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      doRefreshForm={() => doRefreshForm()}
                      requestUpdate={() => {
                        setContractUpdate(true);
                      }}
                    />
                    <TabAssignment
                      loggedUser={loggedUser}
                      companyUser={companyUser}
                      currentTab={currentSecondaryTab}
                      dataVehicle={stateVehicle}
                      dataDetails={stateDetails}
                      doRefreshForm={() => doRefreshForm()}
                      requestUpdate={() => {
                        setAssignmentUpdate(true);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create__VehiclePage;