import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  ITemplateInsert,
  ITemplateInsertProperty,
} from "../../Models/ITemplate";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const TemplateController = {
  TemplateGet: async (
    templateTypeID?: number,
    templateID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (templateTypeID) {
      parameters.push("templateTypeID=" + templateTypeID);
    }
    if (templateID) {
      parameters.push("templateID=" + templateID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  TemplateGetDateFormat: async (
    dateFormatID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_DATE_FORMAT;
    request.port = "7236";
    request.method = "GET";
    let parameters: string[] = [];
    if (dateFormatID) {
      parameters.push("dateFormatID=" + dateFormatID);
    }

    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetTimeFormat: async (
    timeFormatID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_TIME_FORMAT;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (timeFormatID) {
      parameters.push("timeFormatID=" + timeFormatID);
    }

    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetSysProperty: async (
    templateTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_SYS_PROP;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (templateTypeID) {
      parameters.push("templateTypeID=" + templateTypeID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetSysPropertyMapping: async (
    templateID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_PROP_MAPPING;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (templateID) {
      parameters.push("templateID=" + templateID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateInsertSysPropertyMapping: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_INSERT_PROP_MAPPING;
    request.method = "POST";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateUpdateSysPropertyMapping: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_UPDATE_PROP_MAPPING;
    request.method = "PUT";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetSysPropertyMappingFuel: async (
    templateID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_PROP_MAPPING_SUPPLY;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (templateID) {
      parameters.push("templateID=" + templateID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateInsertSysPropertyMappingFuel: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_INSERT_PROP_MAPPING_SUPPLY;
    request.method = "POST";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateUpdateSysPropertyMappingFuel: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_UPDATE_PROP_MAPPING_SUPPLY;
    request.method = "PUT";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetSysPropertyMappingFuelServed: async (
    templateID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_PROP_MAPPING_SUPPLY_SERVED;
    request.method = "GET";
    request.port = "7236";
    let parameters: string[] = [];
    if (templateID) {
      parameters.push("templateID=" + templateID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateInsertSysPropertyMappingFuelServed: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_INSERT_PROP_MAPPING_SUPPLY_SERVED;
    request.method = "POST";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateUpdateSysPropertyMappingFuelServed: async (
    template?: ITemplateInsertProperty,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_UPDATE_PROP_MAPPING_SUPPLY_SERVED;
    request.method = "PUT";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateInsert: async (
    template?: ITemplateInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_INSERT_TEMPLATE;
    request.method = "POST";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateUpdate: async (
    template?: ITemplateInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_UPDATE_TEMPLATE;
    request.method = "POST";
    request.port = "7236";
    request.payload = template;
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateDelete: async (
    templateID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_DELETE_TEMPLATE;
    request.method = "DELETE";
    request.port = "7236";
    request.payload = { templateID };
    request.extraHeaders = { "Content-Type": "application/json" };
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  TemplateGetFromExcel: async (
    file: File,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    var formData = new FormData();
    formData.append("file", file);
    request.url = ApiEndPoints.TEMPLATE_API_GET_EXCEL_H;
    request.method = "POST";
    request.port = "7236";
    request.payload = formData;
    request.dontStringify = true;
    let parameters: string[] = [];

    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  TemplateUploadFromExcel: async (
    file: File,
    errorCheck: boolean,
    templateTypeID: number,
    templateID: number,
    decimalSeparator: string,
    startingRow: number,
    dateFormat: string,
    timeFormat?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_UPLOAD_EXCEL;
    let parameters: string[] = [];
    parameters.push("errorCheck=" + (errorCheck ? "true" : "false"));

    if (templateTypeID) {
      parameters.push("templateTypeID=" + templateTypeID);
    }
    if (templateID) {
      parameters.push("templateID=" + templateID);
    }
    if (decimalSeparator) {
      parameters.push("decimalSeparator=" + decimalSeparator);
    }
    if (startingRow) {
      parameters.push("startingRow=" + startingRow);
    }
    if (dateFormat) {
      parameters.push("dateFormat=" + dateFormat);
    }
    if (timeFormat) {
      parameters.push("timeFormat=" + timeFormat);
    }

    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";

    var formData = new FormData();
    formData.append("file", file);
    request.method = "POST";
    request.dontStringify = true;
    request.payload = formData;
    request.port = "7236";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TemplateGetFuelSupplyServed: async (
    fuelSupplyServedID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TEMPLATE_API_GET_FUEL_SUPPLY_SERVED;
    request.port = "7236";
    let parameters: string[] = [];
    if (fuelSupplyServedID) {
      parameters.push("fuelSupplyServedID=" + fuelSupplyServedID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TemplateController;
