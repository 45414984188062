/* REACT */
import { useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./Cards__VehiclesPage";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { formatPlate } from "../VehiclesPage";

/* IMAGES */
import DkvIcon from "../../../../../Assets/ImagesEmbedded/dkv.png";

/* MUI */
import { Button } from "@mui/material";
// Icons
import SendIcon from "@mui/icons-material/Send";

/* COMPONENT */
const RequestFuelCardForm = () => {
  /* STATES */
  const [formData, setFormData] = useState<any>([]);

  /* LODASH */
  const lodash = require("lodash");

  /* MULTI FORM INPUTS */
  let multiFormInputs: IMultiFormField[] = [
    // Card type
    {
      width: 50,
      type: "select",
      name: "cardType",
      required: true,
      defaultValue: formData?.cardType || "",
      label: `${i18next.t("form:cardType")}`,
      options: ["DKV Card", "DKV Card +Charge"].map((x: any) => {
        return {
          key: x,
          text: x,
        };
      }),
    },
    // Plate
    {
      width: 50,
      type: "autocomplete",
      name: "vehicleLicensePlate",
      required: true,
      defaultValue: formData?.vehicleLicensePlate,
      label: `${i18next.t("form:plate")}`,
      options: ["FZ134TA", "GB641LA", "DJ419YT", "CE761ES"].map((item: any) => {
        return {
          key: item,
          text: formatPlate(item),
        };
      }),
    },
    // Card extension
    {
      width: 50,
      type: "text",
      name: "cardExtension",
      required: false,
      defaultValue: formData?.cardExtension,
      label: `${i18next.t("form:cardExtension")}`,
      placeholder: "Ex. ER91919",
      tooltip: {
        title: i18next.t("form:cardExtensionTooltip"),
      },
      inputProps: {
        maxLength: 13,
      },
    },
    // Card type
    {
      width: 50,
      type: "select",
      name: "pinCard",
      required: true,
      defaultValue: formData?.pinCard || "",
      label: `${i18next.t("form:pinCard")}`,
      options: ["Sistema", "PIN personale"].map((x: any) => {
        return {
          key: x,
          text: x,
        };
      }),
    },
    // Cost center
    {
      width: 50,
      type: "text",
      name: "costCenter",
      required: false,
      defaultValue: formData?.costCenter,
      label: `${i18next.t("form:costCenter")}`,
      placeholder: "Ex. KT1952",
      tooltip: {
        title: i18next.t("form:costCenterTooltip"),
      },
      inputProps: {
        maxLength: 20,
      },
    },
  ];

  // PIN number
  if (formData?.pinCard === "PIN personale") {
    multiFormInputs.push({
      width: 50,
      type: "number",
      name: "pinNumber",
      required: false,
      defaultValue: formData?.pinNumber,
      label: `${i18next.t("form:pinNumber")}`,
      placeholder: "Ex. 96180",
      inputProps: {
        maxLength: 5,
      },
    });
  }

  // Safe delivery
  multiFormInputs.push({
    width: 100,
    type: "checkbox",
    name: "safeDelivery",
    defaultValue: formData?.safeDelivery,
    placeholder: `${i18next.t("form:safeDeliveryPlaceholder")}`,
    label: `${i18next.t("form:safeDeliveryLabel")}`,
  });

  /* RETURN */
  return (
    <div className="modal-width-medium">
      <div className="display-flex-center">
        <img src={DkvIcon} className="dkvImage" alt="dkv" />
      </div>

      <MultiForm
        suppressLayout
        suppressSubmit={true}
        formId={"RequestFuelCardForm"}
        inputs={multiFormInputs}
        onChange={(data: any) => {
          setFormData({ ...formData, ...data });
        }}
        onSubmit={(data: any) => {
          const tempData = lodash.cloneDeep(data);
        }}
      />

      <div className="display-flex-end padding-x-small margin-bottom-small">
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() => {
            triggerFormValidation("RequestFuelCardForm");
          }}
        >
          {i18next.t("navigation:send")}
        </Button>
      </div>
    </div>
  );
};

export default RequestFuelCardForm;
