import { useState } from "react";
import StepNewAccount_Natural from "./StepNewAccount_Natural";
import StepNewAccount_Legal from "./StepNewAccount_Legal";

import "./StepNewAccount.scss";
import StepConfirmEmail from "../StepConfirmEmail/StepConfirmEmail";
import i18next from "i18next";
import SmartModal, { SummonModal } from "../SmartModal/SmartModal";
import { Link } from "react-router-dom";
import TermsAndCondtion from "../TermsAndCondition/TermsAndCondtion";
import CookiePolicy from "../CookiePolicy/CookiePolicy";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

export interface IStepNewAccount {
  setPage: number;
}

const StepNewAccount_Parent = () => {
  // State for the pages
  const [page, setPageInner] = useState<number>(0);
  const setPage = (newPage: number) => {
    setPageInner(newPage);
  };

  const termsConditionPrivacyPolicy = (
    <>
      {i18next.t("generic:acceptTermsPrivacyPolicy")}{" "}
      <a
        className="text-color-hint"
        onClick={(e: any) => {
          e.preventDefault();
          SummonModal("termsCondition-modal");
        }}
      >
        {i18next.t("generic:termsCondition")}
      </a>
      ,{" "}
      <a
        className="text-color-hint"
        onClick={(e: any) => {
          e.preventDefault();
          SummonModal("cookiePolicy-modal");
        }}
      >
        {i18next.t("generic:cookiePolicy")}
      </a>{" "}
      {i18next.t("generic:and")}{" "}
      <a
        className="text-color-hint"
        onClick={(e: any) => {
          e.preventDefault();
          SummonModal("privacyPolicy-modal");
        }}
      >
        {i18next.t("generic:privacyPolicy")}
      </a>
    </>
  );

  // List of sub-step
  const componentList = [
    <StepNewAccount_Natural
      checkBoxDescription={termsConditionPrivacyPolicy}
      setPage={setPage}
    />,
    <StepNewAccount_Legal
      checkBoxDescription={termsConditionPrivacyPolicy}
      setPage={setPage}
    />,
    <StepConfirmEmail />,
  ];

  /* RETURN */
  return (
    <div>
      <SmartModal
        modalUniqueId="termsCondition-modal"
        title={i18next.t("generic:termsCondition")}
        modalInnerComponent={
          <TermsAndCondtion />
        }
      />

      <SmartModal
        modalUniqueId="cookiePolicy-modal"
        title={i18next.t("generic:cookiePolicy")}
        modalInnerComponent={
          <CookiePolicy />
        }
      />

      <SmartModal
        modalUniqueId="privacyPolicy-modal"
        title={i18next.t("generic:privacyPolicy")}
        modalInnerComponent={
          <PrivacyPolicy />
        }
      />

      {componentList[page]}
    </div>
  );
};

export default StepNewAccount_Parent;
