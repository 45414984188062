/* REACT */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ISubscriptions } from "../../../../../Models/ISubscriptions";
import { IAdminBrokerList } from "../../../../../Models/IAdminBrokerList";
import { IAdminAccountCompany, IAdminCompanyList } from "../../../../../Models/IAdminCompanyList";
import { IPaymentAdminPage } from "../../../../../Models/IPayment";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { UserActions } from "../../../../../Reducers/User/UserAction";

/* STYLE */
import "./AdminPage__Clients.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import FormClient from "./AdminPage__ClientsForm";
import FormBroker from "./AdminPage__Clients_BrokerForm";

/* UTILS */
import { userRoleFits } from "../../../../../Utils/Session";

/* MUI */
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ConstructionIcon from "@mui/icons-material/Construction";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditIcon from "@mui/icons-material/Edit";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { currencyDecoder } from "../../../../../Utils/Decoder";

/* COMPONENT */
const AdminPage__Clients = () => {
  const dispatch = useDispatch();
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );

  var loggedUser: IUser | undefined = loggedUserNorm;

  if (loggedUserImp) {
    // Expired impersonation
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* STATES */
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);
  const [loadingBrokerList, setLoadingBrokerList] = useState<boolean>(false);

  // Container for list
  const [companyList, setCompanyList] = useState<IAdminCompanyList[]>([]);
  const [brokerList, setBrokerList] = useState<IAdminBrokerList[]>([]);

  // Container for current value
  const [currentTrialPeriod, setCurrentTrialPeriod] = useState<any>({});
  const [currentConfirmBank, setCurrentConfirmBank] = useState<string>("");
  const [currentListCompanies, setCurrentListCompanies] = useState<any>({});
  const [currentListClients, setCurrentListClients] = useState<any>({});
  const [currentBroker, setCurrentBroker] = useState<any>({});
  const [currentClient, setCurrentClient] = useState<any>(null);
  const [paymentInfo, setPaymentInfo] = useState<any>([]);

  // Loading state
  const [loadingSetSubscriptionGet, setSubscriptionGet] = useState<boolean>(false);
  const [loadingSetSubscriptionUpdate, setSubscriptionUpdate] = useState<boolean>(false);
  const [loadingBrokerData, setLoadingBrokerData] = useState<boolean>(false);
  const [loadingConfirmBank, setLoadingConfirmBank] = useState<boolean>(false);
  const [loadingBrokerCompany, setLoadingBrokerCompany] = useState<boolean>(false);
  const [loadingPaymentInfo, setLoadingPaymentInfo] = useState<boolean>(false);
  const [page, setPage] = useState<string>("1");

  // State for data container for confirm payment
  const [statePaymentPayload, setStatePaymentPayload] = useState<any>([]);
  const [statePaymentOptions, setStatePaymentOptions] = useState<any>([]);
  const [loadingCompanyParent, setLoadingCompanyParent] = useState<boolean>(false);
  const [companyParent, setCompanyParent] = useState<ICompanyGet[]>([]);
  const [loadingAccountCompany, setLoadingAccountCompany] = useState<boolean>(false);
  const [accountCompany, setAccountCompany] = useState<IAdminAccountCompany[]>([]);
  /* APIs */
  // Load list
  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    ApiService.AdminController.AdminCompanyListGet((response: IAPIResponse) => {
      if (response.error === null) {
        setCompanyList(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingCompanyList(false);
    });
  };

  const loadBrokerList = () => {
    setLoadingBrokerList(true);
    ApiService.AdminController.AdminBrokerListGet((response: IAPIResponse) => {
      if (response.error === null) {
        setBrokerList(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingBrokerList(false);
    });
  };

  // Get broker data
  const loadBrokerData = (brokerID: number) => {
    setLoadingBrokerData(true);
    ApiService.BrokerController.BrokerGet(brokerID, null, (response: IAPIResponse) => {
      if (response.error === null) {
        setCurrentBroker(response.payload[0]);
        SummonModal("broker-page-modal");
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingBrokerData(false);
    });
  };

  const loadCompanyParent = (companyID: number, companyParentID: number, single?: boolean, data?: any) => {
    setLoadingCompanyParent(true);
    ApiService.CompanyController.CompanyGetData(
      companyID,
      companyParentID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if(!single){
            setCompanyParent(response.payload);
          }else{
            if(response.payload && response.payload.length > 0){
              let data_: any = {
                ...response.payload[0],
                ...{ accountTypeID: data.accountTypeID },
                ...{ isBrokerPay: data.isBrokerPay ? data.isBrokerPay : false},
              };
              setCurrentClient(data_);
              SummonModal("client-page-modal");
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingCompanyParent(false);
      }
    );
  }

  const loadAccountCompany = (companyID: number) => {
    setLoadingAccountCompany(true);
    ApiService.CompanyController.GetAccountCompany(
      companyID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setAccountCompany(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingAccountCompany(false);
      }
    );
  }

  const loadBrokerCompanyData = (companyID: number, rowData: any) => {
    setLoadingBrokerCompany(true);
    if (loggedUser) {
      ApiService.BrokerController.BrokerCompanyGet(
        companyID,
        loggedUser.brokerID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload && response.payload.length > 0) {
              let data = {
                ...response.payload[0],
                ...{ subscriptionID: rowData.subscriptionID },
              };

              setCurrentClient(data);

              SummonModal("client-page-modal");
            }
          } else {
            ToastMessage(response.error, "error");
          }

          setLoadingBrokerCompany(false);
        }
      );
    }
  };

  // API to get the trial date
  const getSubscriptionDate = (subscriptionID: number) => {
    setSubscriptionGet(true);
    ApiService.SubscriptionController.SubscriptionGetData(
      subscriptionID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            setCurrentTrialPeriod({
              subscriptionID: subscriptionID,
              date: moment(response.payload[0].validTo).utc().format("DD-MM-YYYY"),
            });
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setSubscriptionGet(false);
      }
    );
  };

  // API to update the trial date
  const updateSubscriptionDate = (data: ISubscriptions) => {
    setSubscriptionUpdate(true);
    ApiService.SubscriptionController.SubscriptionUpdateData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          getSubscriptionDate(currentTrialPeriod.subscriptionID);
          DismissModal("trial-period-modal");
          ToastMessage(i18next.t("message:trialPeriodUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setSubscriptionUpdate(false);
      }
    );
  };

  // API to update the trial date
  const updateConfirmBank = (data: any) => {
    setLoadingConfirmBank(true);
    ApiService.AdminController.AdminConfirmPayment(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:confirmBankTransfer"), "success");
        DismissModal("confirm-bank-modal");
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingConfirmBank(false);
    });
  };

  const loadPaymentInfo = (subscriptionID: number) => {
    if (loggedUser) {
      setLoadingPaymentInfo(true);
      ApiService.PaymentController.GetPayment(
        null,
        subscriptionID,
        null,
        true,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let paymentContainerFiltered: any = [];
            let paymentContainer: any = [];

            paymentContainerFiltered = response.payload.filter(
              (x: any) => x.paymentInfo.pending !== false
            );

            setStatePaymentPayload(
              paymentContainerFiltered.map((x: any) => {
                return {
                  paymentID: x.paymentInfo.paymentID,
                  paymentDT: moment(x.paymentInfo.paymentDT).utc().format("DD-MM-YYYY"),
                  amount: x.paymentInfo.amount,
                };
              })
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentInfo(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Impersonation
  const impersonateUser = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          ApiService.AdminController.ImpersonateStart(
            props.row.accountID,
            (response: IAPIResponse) => {
              if (response.error === null) {
                dispatch(UserActions.setUserImp(response.payload));
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } else {
                ToastMessage(response.error, "error");
              }
            }
          );
        }}
      >
        <Tooltip title={i18next.t("navigation:impersonation")} placement="top">
          <RemoveRedEyeIcon className="icon-table" />
        </Tooltip>
      </Button>
    );
  };

  // Actions
  const renderActions = (props: GridRenderCellParams) => {
    /* 
    Dovresti chiamare GetPayment passando subscriptionID e pending=true 
    1) E true P false = pagamento completato | Non confermare
    2) E false P false = pagamento con bonifico | Confermare
    3) E false P true = non ha ancora pagato | Non confermare
    */
    let resultPayment: boolean = false;
    if (!props.row.paymentPending) {
      resultPayment = true;
    }

    // Return
    return (
      <div>
        {userRoleFits(["FleetSupportAdmin"]) && (
          <>
            {page === "1" && (
              <Button
                disabled={resultPayment}
                onClick={() => {
                  setCurrentConfirmBank(props.row.subscriptionID);
                  loadPaymentInfo(props.row.subscriptionID);
                  SummonModal("confirm-bank-modal");
                }}
              >
                <Tooltip
                  title={i18next.t("navigation:_confirm_bank_transfer")}
                  placement="top"
                >
                  <AccountBalanceIcon className="icon-table" />
                </Tooltip>
              </Button>
            )}

            {page === "1" && (
              <Button
                disabled={props.row.fleetPlanID ? false : true}
                onClick={() => {
                  getSubscriptionDate(props.row.subscriptionID);
                  SummonModal("trial-period-modal");
                }}
              >
                <Tooltip title={i18next.t("navigation:trialPeriod")} placement="top">
                  <ConstructionIcon className="icon-table" />
                </Tooltip>
              </Button>
            )}

            <Button
              onClick={() => {
                loadCompanyParent(props.row.companyID, props.row.companyParentID);
              }}
            >
              <Tooltip title={i18next.t("navigation:companiesList")} placement="top">
                <FormatListBulletedIcon className="icon-table" />
              </Tooltip>
            </Button>

            <Button
              onClick={() => {
                loadAccountCompany(props.row.companyID)
              }}
            >
              <Tooltip title={i18next.t("navigation:clientsList")} placement="top">
                <PermContactCalendarIcon className="icon-table" />
              </Tooltip>
            </Button>
            {page === "1" && props.row.accountTypeID && (
              <Button
                onClick={() => {
                  loadCompanyParent(props.row.companyID, props.row.companyParentID, true, props.row);
                }}
              >
                <Tooltip title={i18next.t("navigation:viewDetailCompany")} placement="top">
                  <RemoveRedEyeIcon className="icon-table" />
                </Tooltip>
              </Button>
            )}
          </>
        )}

        {userRoleFits(["FleetSupportAdmin"]) && !props.row.companyID && (
          <Button
            onClick={() => {
              if (props.row.brokerID) {
                loadBrokerData(props.row.brokerID);
              }
            }}
          >
            <Tooltip title={i18next.t("generic:edit")} placement="top">
              <EditIcon className="icon-table" />
            </Tooltip>
          </Button>
        )}

        {userRoleFits(["Broker"]) && (
          <Button
            onClick={() => {
              if (props.row.companyID) {
                loadBrokerCompanyData(props.row.companyID, props.row);
              }
            }}
          >
            <Tooltip title={i18next.t("generic:edit")} placement="top">
              <EditIcon className="icon-table" />
            </Tooltip>
          </Button>
        )}
      </div>
    );
  };
  // Render Paymnet
  const renderPayment = (props: GridRenderCellParams) => {
    if (props.row.enabled) {
      if (props.row.enabled) {
        return <CheckIcon style={{ color: "green" }} />;
      }
    } else {
      if (props.row.PaymentRequired !== undefined) {
        if (!props.row.PaymentRequired) {
          return <AccessTimeIcon style={{ color: "orange" }} />;
        } else {
          return <CloseIcon style={{ color: "red" }} />;
        }
      }
    }

    return <div></div>;
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    if(page === "1" && companyList.length === 0){
      loadCompanyList();
    }
    
    if (page === "2" && brokerList.length === 0 && userRoleFits(["FleetSupportAdmin"])) {
      loadBrokerList();
    }
  }, [page]);

  useEffect(() => {
    /* COLUMNS */
    if(companyParent.length > 0){
      const columnsDef_subCompanies: IDataGridColumnDef[] = [
        {
          field: "companyBusinessName",
          headerName: i18next.t("form:orderCompany"),
        },
        {
          field: "companyVAT",
          headerName: i18next.t("form:vatNumber"),
        },
        {
          field: "companyFiscalCode",
          headerName: i18next.t("form:taxIdCode"),
        },
        {
          field: "companyReferentName",
          headerName: i18next.t("form:nameAlias"),
        },
        {
          field: "companyReferentEmail",
          headerName: i18next.t("form:email"),
        },
        {
          field: "companyReferentPhone",
          headerName: i18next.t("form:telephone"),
        },
        {
          field: "companyBillingCity",
          headerName: i18next.t("form:city"),
        },
      ];

      /* ROWS */
      const rowsDef_subCompanies = [];
      for (let i = 0; i < companyParent.length; i++) {
        // Push row
        rowsDef_subCompanies.push({
          id: i,
          companyID: companyParent[i].companyID,
          companyBusinessName: companyParent[i].companyBusinessName,
          companyVAT: companyParent[i].companyVAT,
          companyFiscalCode: companyParent[i].companyFiscalCode,
          companyReferentName:
            companyParent[i].companyReferentName +
            " " +
            companyParent[i].companyReferentSurname,
          companyReferentEmail: companyParent[i].companyReferentEmail,
          companyReferentPhone: companyParent[i].companyReferentPhone,
          companyBillingCity: companyParent[i].companyBillingCity,
        });
      }

      setCurrentListCompanies({
        columns: columnsDef_subCompanies,
        rows: rowsDef_subCompanies,
      });
      SummonModal("list-companies-modal");
    }
  }, [companyParent]);

  useEffect(() => {
    if(accountCompany.length > 0){
      const columnsDef_users: IDataGridColumnDef[] = [
        {
          field: "action",
          headerName: i18next.t("navigation:actions"),
          renderCell: impersonateUser,
        },
        {
          field: "accountName",
          headerName: i18next.t("form:name"),
        },
        {
          field: "accountSurname",
          headerName: i18next.t("form:surname"),
        },
        {
          field: "accountTypeName",
          headerName: i18next.t("navigation:_issuing_istitute_type"),
        },
        {
          field: "accountEmail",
          headerName: i18next.t("form:email"),
        },
        {
          field: "accountFiscalCode",
          headerName: i18next.t("form:taxIdCode"),
        },
        {
          field: "lastAccessDT",
          headerName: i18next.t("form:lastAccess"),
          fieldOriginal: "lastAccessDTOriginal",
        },
      ];

      /* ROWS */
      const rowsDef_users = [];

      for (let i = 0; i < accountCompany.length; i++) {
        // Push row
        rowsDef_users.push({
          id: i,
          companyID: accountCompany[i].companyID,
          accountID: accountCompany[i].accountID,
          accountTypeName: accountCompany[i].accountTypeName,
          accountName: accountCompany[i].accountName,
          accountSurname: accountCompany[i].accountSurname,
          accountEmail: accountCompany[i].accountEmail,
          accountFiscalCode: accountCompany[i].accountFiscalCode?.toUpperCase(),
          lastAccessDT: accountCompany[i].lastAccessDT
            ? displayUTC0_ISODate(accountCompany[i].lastAccessDT)
            : i18next.t("message:noLogin"),
          lastAccessDTOriginal: accountCompany[i].lastAccessDT,
        });
      }

      setCurrentListClients({
        columns: columnsDef_users,
        rows: rowsDef_users,
      });
      SummonModal("list-clients-modal");
    }
  }, [accountCompany]);

  /* COLUMNS */
  const columnsDefCompanyList: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      hideExport: true,
    },
    {
      field: "companyBusinessName",
      headerName: i18next.t("form:nameCompany"),
    },
    {
      field: "companyVatFiscalCode",
      headerName:
        i18next.t("form:vatNumber") + " / " + i18next.t("navigation:_profile_cf"),
    },
    {
      field: "accountTypeName",
      headerName: i18next.t("navigation:_issuing_istitute_type"),
    },
    {
      field: "email",
      headerName: i18next.t("navigation:email"),
    },
    {
      field: "paymentRequired",
      headerName: i18next.t("navigation:_tickets_page_payment"),
      renderCell: renderPayment,
    },
    {
      field: "vehicles",
      headerName: i18next.t("form:orderNumberVehiclesShort"),
    },
    {
      field: "lastAccess",
      headerName: i18next.t("form:lastAccess"),
      fieldOriginal: "lastAccessDTOriginal",
    },
    
  ];

  if(columnsDefCompanyList && userRoleFits(["FleetSupportAdmin"])) {
    columnsDefCompanyList.push(
      {
        field: "fleetPlanName",
        headerName: i18next.t("navigation:fleetPlan"),
      },
      {
        field: "fleetPlanCost",
        headerName: i18next.t("navigation:fleetPlanCost"),
        type: "number",
      },
      {
        field: "addedServiceName",
        headerName: i18next.t("navigation:addedServiceName"),
      },
      {
        field: "addedServiceCost",
        headerName: i18next.t("navigation:addedServiceCost"),
        type: "number",
      },
      {
        field: "voucherNameDiscount",
        headerName: i18next.t("navigation:voucherUsed"),
        type: "number",
      }, 
      {
        field: "creditUsed",
        headerName: i18next.t("navigation:creditUsed"),
        type: "number",
      },
      {
        field: "paymentTypeName",
        headerName: i18next.t("navigation:paymentTypeName"),
      },
      { 
        field: "paymentID",
        headerName: i18next.t("navigation:paymentID"),
      },
      {
        field: "subscriptionDurationName",
        headerName: i18next.t("navigation:subscriptionDurationName"),
      },
      { 
        field: "numExtraVehicles",
        headerName: i18next.t("navigation:numExtraVehicles"),
      },
      {
        field: "validFrom",
        headerName: i18next.t("navigation:datePayment"),
        fieldOriginal: "validFromDTOriginal",
      },
      {
        field: "validTo",
        headerName: i18next.t("navigation:dateRenew"),
        fieldOriginal: "validToOriginal",
      },
    )

  }

  /* ROWS */
  const rowsDefCompanyList: any[] = [];

  for (let i = 0; i < companyList.length; i++) {
    let vehiclesNumbers = companyList[i].company.fleetPlanMaxVehicle
      ? "(" + companyList[i].company.numberOfVehicles +
        " / " +
        companyList[i].company.fleetPlanMaxVehicle + ")"
      : "- / -";
    let lastAccessStatus = companyList[i].company.lastAccessDT
      ? displayUTC0_ISODate(companyList[i].company.lastAccessDT)
      : i18next.t("message:noLogin");
    // Push row
    
    rowsDefCompanyList.push({
      id: i,
      edit: "",
      subscriptionID: companyList[i].company.subscriptionID,
      companyBusinessName: companyList[i].company.companyBusinessName || "-",
      companyVatFiscalCode:
        companyList[i].company.vat ||
        companyList[i].company.fiscalCode?.toUpperCase() ||
        "-",
      accountTypeName: companyList[i].company.accountTypeName,
      email: companyList[i].company.accountEmail,
      enabled: companyList[i].company.enabled,
      paymentRequired: companyList[i].company.paymentRequired,
      vehicles: vehiclesNumbers,
      lastAccess: lastAccessStatus,
      lastAccessDTOriginal: companyList[i].company.lastAccessDT,
      companyID: companyList[i].company.companyID,
      accountTypeID: companyList[i].company.accountTypeID,
    });
    
    if (userRoleFits(["FleetSupportAdmin"])) {
      for (let s = 0; s < companyList[i].paymentReports.length; s++) {
        rowsDefCompanyList.push({
          id: i,
          edit: "",
          subscriptionID: companyList[i].company.subscriptionID,
          companyBusinessName: companyList[i].company.companyBusinessName || "-",
          companyVatFiscalCode:
            companyList[i].company.vat ||
            companyList[i].company.fiscalCode?.toUpperCase() ||
            "-",
          accountTypeName: companyList[i].company.accountTypeName,
          email: companyList[i].company.accountEmail,
          enabled: companyList[i].company.enabled,
          paymentRequired: companyList[i].company.paymentRequired,
          vehicles: vehiclesNumbers,
          lastAccess: lastAccessStatus,
          lastAccessDTOriginal: companyList[i].company.lastAccessDT,
          companyID: companyList[i].company.companyID,
          fleetPlanID: companyList[i].paymentReports[s].fleetPlanID,
          fleetPlanName: companyList[i].paymentReports[s].fleetPlanName ?? "-",
          fleetPlanCost: companyList[i].paymentReports[s].fleetPlanCost !== null ? currencyDecoder(companyList[i].paymentReports[s].fleetPlanCost,2 ) : "",
          addedServiceID: companyList[i].paymentReports[s].addedServiceID,
          addedServiceName: companyList[i].paymentReports[s].addedServiceName ?? "-",
          addedServiceCost: companyList[i].paymentReports[s].addedServiceCost !== null ? currencyDecoder(companyList[i].paymentReports[s].addedServiceCost, 2) : "",
          paymentTypeName: companyList[i].paymentReports[s].paymentTypeName ?? "-",
          subscriptionDurationName: companyList[i].paymentReports[s].subscriptionDurationName ?? "-",
          voucherNameDiscount: companyList[i].paymentReports[s].discount > 0 ? companyList[i].paymentReports[s].voucherName + " ("  + companyList[i].paymentReports[s].discount * 100 +"%)" : "-",
          creditUsed: companyList[i].paymentReports[s].creditUsed !== null ? currencyDecoder(companyList[i].paymentReports[s].creditUsed, 2) : "",
          paymentID: "FacileFlotta PaymentID: " + companyList[i].paymentReports[s].paymentID,
          validFrom: displayUTC0_ISODate(companyList[i].paymentReports[s].paymentDT, true),
          validFromDTOriginal: companyList[i].paymentReports[s].paymentDT,
          numExtraVehicles: companyList[i].paymentReports[s].numExtraVehicles > 0 ? 
            companyList[i].paymentReports[s].numExtraVehicles + " (" + companyList[i].paymentReports[s].extraVehiclesCost + " €)" 
            : "-",
          validTo: displayUTC0_ISODate(companyList[i].paymentReports[s].validTo, true),
          validToOriginal: companyList[i].paymentReports[s].validTo,
          paymentPending: companyList[i].paymentReports.filter((x: any) => x.pending).length > 0,
        });
      }
    }
    
  }

  const columnsDefBrokerList: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      hideExport: true,
    },
    {
      field: "accountName",
      headerName: i18next.t("form:name"),
    },
    {
      field: "accountSurname",
      headerName: i18next.t("form:surname"),
    },
    {
      field: "accountEmail",
      headerName: i18next.t("form:email"),
    },
    {
      field: "brokerBusinessName",
      headerName: i18next.t("form:nameCompany"),
    },
    {
      field: "brokerVatFiscalCode",
      headerName:
        i18next.t("form:vatNumber") + " / " + i18next.t("navigation:_profile_cf"),
    },
    {
      field: "accountTypeName",
      headerName: i18next.t("navigation:_issuing_istitute_type"),
    },
    {
      field: "lastAccess",
      headerName: i18next.t("form:lastAccess"),
      fieldOriginal: "lastAccessDTOriginal",
    },
  ];

  /* ROWS */
  const rowsDefBrokerList = [];

  for (let i = 0; i < brokerList.length; i++) {
    let lastAccessStatus = brokerList[i].broker.lastAccessDT
      ? displayUTC0_ISODate(brokerList[i].broker.lastAccessDT)
      : i18next.t("message:noLogin");
    // Push row
    rowsDefBrokerList.push({
      id: i,
      edit: "",
      accountName: brokerList[i].broker.accountName,
      accountSurname: brokerList[i].broker.accountSurname,
      accountEmail: brokerList[i].broker.accountEmail,
      brokerBusinessName: brokerList[i].broker.brokerBusinessName,
      subCompanies: brokerList[i].companies,
      users: brokerList[i].users,
      brokerVatFiscalCode:
        brokerList[i].broker.vat ?? brokerList[i].broker.fiscalCode?.toUpperCase(),
      accountTypeName: brokerList[i].broker.accountTypeName,
      lastAccess: lastAccessStatus,
      lastAccessDTOriginal: brokerList[i].broker.lastAccessDT,
      brokerID: brokerList[i].broker.brokerID,
      accountID: brokerList[i].broker.accountID,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t(
          "navigation:" +
            (userRoleFits(["FleetSupportAdmin"])
              ? "_admin_clients_broker"
              : "_admin_clients")
        )}
      />

      <div className="dashboardForm__box">
        <div
          className={
            "margin-bottom-small gap-small " +
            (userRoleFits(["FleetSupportAdmin"])
              ? "display-flex-wide"
              : "display-flex-end")
          }
        >
          {userRoleFits(["FleetSupportAdmin"]) && (
            <>
              <ToggleButtonGroup exclusive size="small" color="primary" value={page}>
                <ToggleButton
                  value="1"
                  onClick={() => {
                    setPage("1");
                  }}
                >
                  {i18next.t("navigation:_admin_navigation_clients")}
                </ToggleButton>
                <ToggleButton
                  value="2"
                  onClick={() => {
                    setPage("2");
                  }}
                >
                  {i18next.t("navigation:_admin_navigation_brokers")}
                </ToggleButton>
              </ToggleButtonGroup>
              {page == "2" && (
                <ButtonGroup variant="outlined">
                  <Button
                    onClick={() => {
                      setCurrentBroker({});
                      SummonModal("broker-page-modal");
                    }}
                  >
                    {i18next.t("navigation:_add_broker")}
                  </Button>
                </ButtonGroup>
              )}
            </>
          )}

          {page == "1" && userRoleFits(["Broker"]) && (
            <ButtonGroup variant="outlined">
              <Button
                onClick={() => {
                  setCurrentClient(null);
                  SummonModal("client-page-modal");
                }}
              >
                {i18next.t("navigation:_add_client")}
              </Button>
            </ButtonGroup>
          )}
        </div>

        {page === "1" && (
          <Box className="dashboardForm__data">
            <DataGridWrap headers={columnsDefCompanyList} rows={rowsDefCompanyList} />
          </Box>
        )}
        {page === "2" && (
          <Box className="dashboardForm__data">
            <DataGridWrap headers={columnsDefBrokerList} rows={rowsDefBrokerList} />
          </Box>
        )}

        <SmartModal
          hideButtons={true}
          modalUniqueId="list-companies-modal"
          title={i18next.t("generic:titleSideListCompany")}
          modalInnerComponent={
            <div className="modal-width-large">
              <DataGridWrap
                headers={currentListCompanies && currentListCompanies.columns}
                rows={currentListCompanies && currentListCompanies.rows}
              />
            </div>
          }
        />
        {currentListClients && currentListClients.hasOwnProperty("rows") && (
          <SmartModal
            hideButtons={true}
            modalUniqueId="list-clients-modal"
            title={i18next.t("generic:titleSideListClients")}
            modalInnerComponent={
              <div className="modal-width-large">
                <DataGridWrap
                  headers={currentListClients && currentListClients.columns}
                  rows={currentListClients && currentListClients.rows}
                />
              </div>
            }
          />
        )}
        <SmartModal
          hideButtons={true}
          modalUniqueId="client-page-modal"
          title={
            currentClient
              ? i18next.t("navigation:_view_client")
              : i18next.t("navigation:_add_client")
          }
          modalInnerComponent={
            <div>
              <FormClient
                loggedUser={loggedUser}
                data={currentClient}
                idModal="client-page-modal"
                doRefreshForm={() => {
                  loadCompanyList();
                }}
              />
            </div>
          }
        />

        <SmartModal
          hideButtons={true}
          modalUniqueId="broker-page-modal"
          title={i18next.t("navigation:_add_broker")}
          modalInnerComponent={
            <FormBroker
              loggedUser={loggedUser}
              data={currentBroker ?? []}
              idModal="broker-page-modal"
              doRefreshForm={() => {
                loadBrokerList();
              }}
            />
          }
        />

        <SmartModal
          modalUniqueId={"confirm-bank-modal"}
          title={i18next.t(`navigation:_confirm_bank_transfer`)}
          modalInnerComponent={
            <div className="add-expire-wrapper">
              {!loadingPaymentInfo && (
                <>
                  <MultiForm
                    suppressLayout
                    classNameForm="multi-form-flex"
                    suppressSubmit={true}
                    formId={"confirm-bank-modal"}
                    formTitle={"confirm-bank-modal"}
                    inputs={[
                      {
                        className: "modal-width-small",
                        width: 100,
                        type: "select",
                        name: "paymentIDList",
                        label: String(i18next.t("navigation:_confirm_wire_transfer")),
                        defaultValue: statePaymentOptions?.paymentIDList
                          ? statePaymentOptions.paymentIDList
                          : [],
                        required: true,
                        multiple: true,
                        options: statePaymentPayload.map((x: IPaymentAdminPage) => {
                          return {
                            key: x.paymentID,
                            text: "Facileflotta PaymentID: " + x.paymentID + " " + x.paymentDT + " - " + x.amount + " €",
                          };
                        }),
                      },
                    ]}
                    onChange={async (data: any) => {
                      setStatePaymentOptions({
                        ...statePaymentOptions,
                        ...data,
                      });
                    }}
                    onSubmit={(data: any) => {
                      let parseData = { ...data };

                      parseData["subscriptionID"] = currentConfirmBank;

                      updateConfirmBank(parseData);
                    }}
                  />
                </>
              )}
            </div>
          }
          onAccept={() => {
            triggerFormValidation("confirm-bank-modal");
            DismissModal("confirm-bank-modal");
          }}
          onReject={() => DismissModal("confirm-bank-modal")}
        />

        <SmartModal
          hideButtons={true}
          modalUniqueId={"trial-period-modal"}
          title={i18next.t(`navigation:trialPeriodDays`)}
          modalInnerComponent={
            <div>
              {loadingSetSubscriptionGet && (
                <div className="display-flex-center">
                  <CircularProgress />
                </div>
              )}
              {!loadingSetSubscriptionGet && (
                <div className="add-expire-wrapper">
                  {i18next.t("navigation:_add_days_trial_period")}

                  <p>
                    {i18next.t("navigation:_current_trial_period")}:{" "}
                    <strong>
                      <i>{currentTrialPeriod && currentTrialPeriod.date}</i>
                    </strong>
                  </p>

                  <div>
                    <MultiForm
                      suppressLayout
                      inputs={[
                        {
                          width: 100,
                          type: "number",
                          name: "validTo",
                          label: String(i18next.t(`navigation:trialPeriod`)),
                          hint: String(i18next.t(`navigation:_hint_trial_period`)),
                          inputProps: { min: -30, max: +60 },
                        },
                      ]}
                      onSubmit={(data: any) => {
                        data["validTo"] = moment(currentTrialPeriod.date, "DD-MM-YYYY")
                          .add(+data.validTo, "days")
                          .format("YYYY-MM-DD");
                        if (data["validTo"] === "Invalid date") {
                          data["validTo"] = new Date();
                        }
                        data["trialEndDT"] = data["validTo"];
                        data["subscriptionID"] = currentTrialPeriod.subscriptionID;
                        updateSubscriptionDate(data);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          }
        />

        <LoaderBackdrop
          loading={
            loadingCompanyList ||
            loadingBrokerList ||
            loadingSetSubscriptionGet ||
            loadingSetSubscriptionUpdate ||
            loadingBrokerData ||
            loadingConfirmBank ||
            loadingBrokerCompany ||
            loadingPaymentInfo ||
            loadingCompanyParent ||
            loadingAccountCompany
          }
        />
      </div>
    </div>
  );
};

export default AdminPage__Clients;
