import { combineReducers } from "redux";

import { brandsReducer } from "./Brands/BrandsReducer";
import { BrandsState } from "./Brands/BrandsState";
import { genericReducer } from "./Generic/GenericReducer";
import { GenericState } from "./Generic/GenericState";
import { TicketsReducer } from "./Tickets/TicketsReducer";
import { TicketsState } from "./Tickets/TicketsState";
import { userReducer } from "./User/UserReducer";
import { UserState } from "./User/UserState";
import { vehiclesReducer } from "./Vehicle/VehicleReducer";
import { VehicleState } from "./Vehicle/VehicleState";
import { registrationReducer } from "./Registration/RegistrationReducer";
import { RegistrationState } from "./Registration/RegistrationState";
import { roadTaxReducer } from "./RoadTax/RoadTaxReducer";
import { RoadTaxState } from "./RoadTax/RoadTaxState";
import { BrokerState } from "./Broker/BrokerState";
import { BrokerReducer } from "./Broker/BrokerReducer";

const rootReducer = combineReducers({
  user: userReducer,
  generic: genericReducer,
  vehicles: vehiclesReducer,
  brands: brandsReducer,
  tickets: TicketsReducer,
  registration: registrationReducer,
  roadtax: roadTaxReducer,
  broker: BrokerReducer,
});

export interface GlobalState {
  generic: GenericState;
  user: UserState;
  vehicles: VehicleState;
  brands: BrandsState;
  tickets: TicketsState;
  registration: RegistrationState;
  roadtax: RoadTaxState;
  broker: BrokerState;
}

export default rootReducer;
