import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IAccountBody,
  IAccountPreRegistration,
  IUserSpecialOffer,
} from "../../Models/IAccountBody";
import { IAccountUdpateWithImage } from "../../Models/IAccountGet";
import { IAccountRegistrationStepInsert } from "../../Models/IRegistration";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const AccountController = {
  AccountGet: async (
    accountID?: number,
    accountTypeID?: number,
    accountEmail?: string,
    accountFiscalCode?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let parameters: string[] = [];
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_ACCOUNT;

    if (accountID) {
      parameters.push("accountID=" + accountID);
    }
    if (accountTypeID) {
      parameters.push("accountTypeID=" + accountTypeID);
    }
    if (accountEmail) {
      parameters.push("accountEmail=" + accountEmail);
    }
    if (accountFiscalCode) {
      parameters.push("accountFiscalCode=" + accountFiscalCode);
    }

    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountDetail: async (
    bypassBroker: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let parameters: string[] = [];
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_DETAIL;
    parameters.push(`bypassBroker=${bypassBroker}`);
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountType: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_TYPE;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetImpersonatableAccountsList: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_IMPERSONATABLE_USERS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetWritableAccountsList: async (
    bypassBroker?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_WRITEABLE_USERS;
    if (bypassBroker !== null && bypassBroker !== undefined) {
      request.url += "?bypassBroker=" + bypassBroker;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ValidateAccount: async (
    token: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.ACCOUNT_CONFIRM_EMAIL}?token=${token}`;
    request.port = "7234";
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertUserSpecialOfferRequest: async (
    body: IUserSpecialOffer,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_REQUEST_SPECIAL_OFFER;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ResetPassword: async (
    token: string,
    password: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.ACCOUNT_RESET_PASSWORD}?token=${token}&password=${password}`;
    request.method = "GET";
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AccountCountry: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_COUNTRY;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountGetDataInfo: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_GET_DATA_INFO;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountSetData: async (
    body: IAccountBody,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_SET_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AccountRegistrationResendEmail: async (
    email: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url =
      ApiEndPoints.ACCOUNT_REGISTRATION_RESEND_EMAIL + "?email=" + email;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AccountRegistration: async (
    body: IAccountBody,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_REGISTRATION;
    request.port = "7234";
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountUpdateData: async (
    body: IAccountBody,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountUpdateDataWithImage: async (
    body: IAccountUdpateWithImage,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_UPDATE_DATA_W_IMAGE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  AccountResendEmail: async (
    accountID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.ACCOUNT_RESET_EMAIL}?accountID=${accountID}`;
    request.port = "7234";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountRegistrationStepInsert: async (
    body: IAccountRegistrationStepInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.ACCOUNT_REGISTRATION_STEP_INSERT}`;
    request.port = "7234";
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountResetPasswordSession: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_RESET_PASSWORD_SESSION;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  AccountPreRegistration: async (
    body: IAccountPreRegistration,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ACCOUNT_PRE_REGISTRATION;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetDownloadAllAttachment: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_DOWNLOAD_ALL_ATTACHMENT;
    request.method = "GET";
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AccountController;
