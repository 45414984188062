/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_CarConfiguratorGroup_Open,
  IGET_CarConfiguratorHybridType,
  IGET_CarConfiguratorPowerSupply,
  IGET_CarConfiguratorVehicleModel,
  IINSERT_CarConfiguratorGroup_Open,
  IUPDATE_CarConfiguratorGroup_Disable,
  IUPDATE_CarConfiguratorGroup_Open,
} from "../../../../../../Models/ICarConfigurator";
import { IVehicleDataBrands } from "../../../../../../Models/IVehicles";

/* COMPONENTS */
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Button } from "@mui/material";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";

/* INTERFACE */
export interface IFormOpenGroup {
  data: IGET_CarConfiguratorGroup_Open;
}

/* COMPONENT */
const FormOpenGroup = (props: IFormOpenGroup) => {
  /* STATES */
  const [formData, setFormData] = useState<any>(props.data);
  // Load dropdown data
  const [powerSupply, setPowerSupply] = useState<any[]>([]);
  const [hybridType, setHybridType] = useState<any[]>([]);
  const [vehicleModel, setVehicleModel] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  // Loading
  const [loadingPowerSupply, setLoadingPowerSupply] = useState<boolean>(false);
  const [loadingHybridType, setLoadingHybridType] = useState<boolean>(false);
  const [loadingVehicleModel, setLoadingVehicleModel] = useState<boolean>(false);
  const [loadingBrands, setLoadingBrands] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingUpdateDisable, setLoadingUpdateDisable] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get Engine Power Supply
  const GET_CarConfiguratorPowerSupply = () => {
    setLoadingPowerSupply(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorPowerSupply(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPowerSupply(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingPowerSupply(false);
      }
    );
  };

  // Get Electrich Hybrid Type
  const GET_CarConfiguratorHybridType = () => {
    setLoadingHybridType(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorHybridType(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setHybridType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingHybridType(false);
      }
    );
  };

  // Get Vehicle Model
  const GET_CarConfiguratorVehicleModel = () => {
    setLoadingVehicleModel(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorVehicleModel(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehicleModel(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingVehicleModel(false);
      }
    );
  };

  // Get brand list
  const GET_CarConfiguratorBrands = () => {
    setLoadingBrands(true);

    ApiService.VehicleDataController.VehicleDataGetBrands(
      true,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBrands(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingBrands(false);
      }
    );
  };

  // Insert open group
  const INSERT_CarConfiguratorGroup_Open = (data: IINSERT_CarConfiguratorGroup_Open) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_CarConfiguratorGroup_Open(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");
          navigate(-1);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  // Update open group
  const UPDATE_CarConfiguratorGroup_Open = (data: IUPDATE_CarConfiguratorGroup_Open) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Open(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  // Disable group
  const UPDATE_CarConfiguratorGroup_Disable = (
    data: IUPDATE_CarConfiguratorGroup_Disable
  ) => {
    setLoadingUpdateDisable(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Disable(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
          navigate(-1);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateDisable(false);
      }
    );
  };

  // Delete the group
  const DELETE_CarConfiguratorGroup = (groupID: number) => {
    setLoadingDelete(true);

    ApiService.CarConfiguratorController.DELETE_CarConfiguratorGroup(
      groupID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:deleteSuccess"), "success");
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingDelete(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    GET_CarConfiguratorPowerSupply();
    GET_CarConfiguratorHybridType();
    GET_CarConfiguratorVehicleModel();
    GET_CarConfiguratorBrands();
  }, []);

  /* RETURN */
  return (
    <div className="padding-bottom-small">
      <MultiForm
        suppressLayout
        suppressSubmit={true}
        formId={"FormOpenGroup"}
        inputs={[
          {
            width: 33.33,
            type: "text",
            name: "groupDescription",
            defaultValue: formData?.groupDescription,
            label: `${i18next.t("form:description")}`,
            required: false,
            inputProps: { maxLength: 100 },
          },
          {
            width: 33.33,
            type: "date",
            name: "startDate",
            defaultValue: formData?.startDate,
            label: i18next.t("form:dateStart") ?? "",
            required: true,
            minDate: new Date().toISOString(),
          },
          {
            width: 33.33,
            type: "date",
            name: "endDate",
            defaultValue: formData?.endDate,
            label: i18next.t("form:dateFinish") ?? "",
            required: true,
            disabled: formData?.startDate ? false : true,
            minDate: formData?.startDate,
          },

          {
            width: 25,
            type: "number",
            name: "plafond",
            defaultValue: formData?.plafond,
            label: `${i18next.t("form:plafond")}`,
            required: false,
            inputProps: { maxLength: 100 },
            inputAdornament: { adornament: "€" },
          },
          {
            width: 25,
            type: "number",
            name: "maxRentalRate",
            defaultValue: formData?.maxRentalRate,
            label: `${i18next.t("form:maxRentalRate")}`,
            required: false,
            inputProps: { maxLength: 100 },
            inputAdornament: { adornament: "€" },
          },
          {
            width: 25,
            type: "number",
            name: "listPrice",
            defaultValue: formData?.listPrice,
            label: `${i18next.t("form:listPrice")}`,
            required: false,
            inputProps: { maxLength: 100 },
            inputAdornament: { adornament: "€" },
          },
          {
            width: 25,
            type: "radio",
            name: "driverLoadShare",
            defaultValue: formData?.driverLoadShare,
            label: `${i18next.t("form:driverLoadShare")}`,
            required: false,
            options: [
              {
                key: 1,
                text: i18next.t("message:yes"),
              },
              {
                key: 0,
                text: i18next.t("message:no"),
              },
            ],
          },

          {
            width: 25,
            type: "number",
            name: "cubicCapacity",
            defaultValue: formData?.cubicCapacity,
            label: `${i18next.t("form:cubicCapacityMax")}`,
            required: false,
            inputProps: { maxLength: 100 },
          },
          {
            width: 25,
            type: "text",
            name: "minPorts",
            defaultValue: formData?.minPorts,
            label: `${i18next.t("form:minimumPorts")}`,
            required: false,
            inputProps: { maxLength: 100 },
          },
          {
            width: 25,
            type: "text",
            name: "minSeats",
            defaultValue: formData?.minSeats,
            label: `${i18next.t("form:minimumSeats")}`,
            required: false,
            inputProps: { maxLength: 100 },
          },
          {
            width: 25,
            type: "text",
            name: "co2ConsumptionMax",
            defaultValue: formData?.co2ConsumptionMax,
            label: `${i18next.t("form:co2ConsumptionMax")}`,
            required: false,
            inputProps: { maxLength: 100 },
          },

          {
            width: 33.33,
            type: "select",
            name: "powerSupply",
            defaultValue: formData?.powerSupply || [],
            label: `${i18next.t("form:powerSupply")}`,
            required: false,
            multiple: true,
            options: powerSupply.map((x: IGET_CarConfiguratorPowerSupply) => {
              return {
                key: x.id,
                text: x.description,
              };
            }),
          },
          {
            width: 33.33,
            type: "radio",
            name: "hybrid",
            defaultValue:
              formData?.powerSupply && formData?.powerSupply.includes(3)
                ? formData?.hybrid
                : -1,
            label: `${i18next.t("form:hybrid")}`,
            required: false,
            disabled:
              formData?.powerSupply && formData?.powerSupply.includes(3) ? false : true,
            options: [
              {
                key: 1,
                text: i18next.t("message:yes"),
              },
              {
                key: 0,
                text: i18next.t("message:no"),
              },
            ],
          },
          {
            width: 33.33,
            type: "select",
            name: "hybridType",
            defaultValue: (formData?.hybrid == 0 ? [] : formData?.hybridType) || [],
            label: `${i18next.t("form:hybridType")}`,
            required: false,
            disabled: formData?.hybrid == 1 ? false : true,
            multiple: true,
            options: hybridType.map((x: IGET_CarConfiguratorHybridType) => {
              return {
                key: x.id,
                text: x.description,
              };
            }),
          },
          {
            width: 50,
            type: "select",
            name: "brandsType",
            defaultValue: formData?.brandsType || [],
            label: `${i18next.t("form:brandsType")}`,
            required: false,
            multiple: true,
            options: brands.map((x: IVehicleDataBrands) => {
              return {
                key: x.brandCode,
                text: x.brandDescription,
              };
            }),
          },
          {
            width: 50,
            type: "select",
            name: "excludedVehicle",
            defaultValue: formData?.excludedVehicle || [],
            label: `${i18next.t("form:excludedVehicle")}`,
            required: false,
            multiple: true,
            options: vehicleModel.map((x: IGET_CarConfiguratorVehicleModel) => {
              return {
                key: x.id,
                text: x.description,
              };
            }),
          },
        ]}
        onChange={(data: any) => {
          setFormData({ ...formData, ...data });
        }}
        onSubmit={(data: any) => {
          const tempData = lodash.cloneDeep(data);

          tempData["plafond"] = +tempData["plafond"];
          tempData["maxRentalRate"] = +tempData["maxRentalRate"];
          tempData["cubicCapacity"] = +tempData["cubicCapacity"];
          tempData["minPorts"] = +tempData["minPorts"];
          tempData["minSeats"] = +tempData["minSeats"];
          tempData["co2ConsumptionMax"] = +tempData["co2ConsumptionMax"];
          tempData["listPrice"] = +tempData["listPrice"];
          tempData["driverLoadShare"] = tempData["driverLoadShare"] == 1 ? true : false;
          tempData["hybrid"] = tempData["hybrid"] == 1 ? true : false;
          formData["groupID"] && (tempData["groupID"] = formData["groupID"]);

          // Lunch the API based on the groupID
          // (if present the element already exist so UPDATE, if not INSERT)
          if (tempData?.groupID && tempData?.groupID > 0) {
            UPDATE_CarConfiguratorGroup_Open(tempData);
          } else {
            INSERT_CarConfiguratorGroup_Open(tempData);
          }
        }}
      />

      <div className="display-flex-end margin-vertical-medium gap-small">
        {formData && formData.groupID && (
          <Button
            color="error"
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={() => {
              SummonModal("delete-open-group-modal");
            }}
          >
            {i18next.t("generic:delete")}
          </Button>
        )}

        {formData && formData.groupID && (
          <Button
            color="warning"
            variant="contained"
            endIcon={<ArchiveIcon />}
            onClick={() => {
              UPDATE_CarConfiguratorGroup_Disable({
                groupID: formData.groupID,
                enabled: !formData.enabled,
              });
            }}
          >
            {formData.enabled ? i18next.t("form:disable") : i18next.t("form:activate")}
          </Button>
        )}

        <Button
          variant="contained"
          onClick={() => {
            triggerFormValidation("FormOpenGroup");
          }}
        >
          {i18next.t("generic:save")}
        </Button>
      </div>

      <SmartModal
        modalUniqueId="delete-open-group-modal"
        title={i18next.t("generic:delete")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace("CURRENT_ITEM_LIST", formData?.groupID || ""),
            }}
          />
        }
        onAccept={() => {
          DELETE_CarConfiguratorGroup(formData?.groupID);
          navigate(-1);
        }}
        onReject={() => {}}
      />

      <LoaderBackdrop
        loading={
          loadingPowerSupply ||
          loadingHybridType ||
          loadingVehicleModel ||
          loadingBrands ||
          loadingInsert ||
          loadingUpdate ||
          loadingDelete ||
          loadingUpdateDisable
        }
      />
    </div>
  );
};

export default FormOpenGroup;
