import { useEffect, useState } from "react";
import i18next from "i18next";
import "./StepCompanyUsers.scss";
import CustomSideList from "../CustomSideList/CustomSideList";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ICompanyGet } from "../../Models/ICompany";
import { ToastMessage } from "../../Utils/Toastify";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import FormCompanyUser from "./FormCompanyUser";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import { SkipButtonCustomStepper } from "../CustomStepper/CustomStepper";
export interface IStepCompanyUsers {
  numPage?: number;
  nextPage?: number;
  stepId?: number;
}

const StepCompanyUsers = (props: IStepCompanyUsers) => {
  /* REDUCER OF LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);

  const updateCurrent = (item: any) => {
    setState(item);
    doRefreshForm();
  };

  const loadCompanyList = () => {
    setLoadingCompany(true);
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(
              response.payload.filter(
                (company: ICompanyGet) => company.companyParentID !== null
              )
            );

            if (props.nextPage) {
              updateAccountRegistrationStep(
                loggedUser,
                props.stepId,
                response.payload.length > 0
              );
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompany(false);
        }
      );
    }
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  const newCompanyUser = () => {
    setState(null);
    doRefreshForm();
  };

  useEffect(() => {
    loadCompanyList();
  }, []);

  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleCompanyUsers") ?? "",
          }}
        />
        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitleCompanyUsers")}
        </h2>

        {props.nextPage && <SkipButtonCustomStepper nextPage={props.nextPage} />}

        <AddNewBanner
          label={i18next.t("navigation:addNewCompanyUsers") as string}
          new={() => newCompanyUser()}
        />

        <div className="container-content-form">
          <div className="container-stepper-form add-border multi-form-background">
            {!refreshForm && (
              <FormCompanyUser
                loggedUser={loggedUser}
                companyUser={companyUser}
                data={state}
                doRefreshForm={() => {
                  doRefreshForm();
                  setState(null);
                }}
                onLoadingCompanyList={() => loadCompanyList()}
              />
            )}
          </div>
          {!loadingCompany &&
            <CustomSideList
              title={i18next.t("generic:titleSideListCompanyUsers")}
              updateCurrent={updateCurrent}
              selected={{ value: state?.companyID, key: "companyID" }}
              sideContent={"#form-StepCompanyUsers"}
              list={companyList}
              elementTitle={(item: any) => {
                return item["companyBusinessName"];
              }}
            />
          }
        </div>
      </div>
      <LoaderBackdrop loading={loadingCompany || refreshForm} />
    </>
  );
};

export default StepCompanyUsers;
