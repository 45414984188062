import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import "./GitCordBox.scss";

export interface IGitCordBoxProps {
  content: JSX.Element;
  date: string;
  type: "first" | "middle" | "last";
}

const GitCordBox = (props: IGitCordBoxProps) => {
  return (
    <div className="git-cord-wrapper">
      <div>
        <div className="git-cord-cord">
          <div className="git-cord-dot"></div>
          {props.type !== "last" && <div className={"git-cord-cord-first"}></div>}
          {props.type !== "first" && <div className={"git-cord-cord-last"}></div>}
        </div>
        <div className="git-cord-content">{props.content}</div>
      </div>
      <div>
        <div className="git-cord-date">
          <div>{displayUTC0_ISODate(props.date, true)}</div>
          <div>{props.date.toString().substring(11, 16)}</div>
        </div>
      </div>
    </div>
  );
};

export default GitCordBox;
