import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { ISupplierGetSideList } from "../../../Models/ISupplier";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";

import EditIcon from "@mui/icons-material/Edit";
import { GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import AppRoutes from "../../../Costants/AppRoutes";
import {
  IVehicleServiceGet,
  IVehicleServiceInsert,
  IVehicleServicePaymentRecurrenceType,
  IVehicleServiceUpdate,
} from "../../../Models/IVehicleService";
import AddNewBanner from "../../AddNewBanner/AddNewBanner";
import AttachementMainHandler from "../../AttachmentMainHandler/AttachmentMainHandler";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../DataGridWrap/DataGridWrap";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import { displayUTC0_ISODate } from "../../MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../SmartModal/SmartModal";
import FormSupplier from "../../StepSupplier/FormSupplier";
import ArchiveIcon from "@mui/icons-material/Archive";
import { GetVehicleMinDate } from "../../../Pages/DashboardPage/DashboardPage";

export interface IFormVehicleServicesProps {
  loggedUser: IUser | undefined;
  dataVehicle?: IVehicleDataGet;
  disabled?: boolean;
  suppressWarning?: boolean;
  expiredVehicle?: IVehicleDataGet;
  doRefreshForm?: () => void;
  emitAllServices?: (services: IVehicleServiceGet[]) => void;
  emitAllSuppliers?: (suppliers: ISupplierGetSideList[]) => void;
  emitAllPaymentTypes?: (types: IVehicleServicePaymentRecurrenceType[]) => void;
}

const FormServices = (props: IFormVehicleServicesProps) => {
  const [state, setState] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingPaymentRecurrenceType, setLoadingPaymentRecurrenceType] =
    useState<boolean>(false);
  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<
    IVehicleServicePaymentRecurrenceType[]
  >([]);

  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [loadingCurrentVehicleService, setLoadingCurrentVehicleService] =
    useState<boolean>(false);
  const [loadingVehicleServices, setLoadingVehicleServices] =
    useState<boolean>(false);
  const [vehicleServices, setVehicleServices] = useState<IVehicleServiceGet[]>(
    []
  );
  const isInDashBoard = window.location.href.includes(
    AppRoutes.INTERNAL_DASHBOARD
  );

  const loadSupplierList = () => {
    if (props.loggedUser) {
      setLoadingSupplierList(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "GENERIC",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
            if (props.emitAllSuppliers) {
              props.emitAllSuppliers(response.payload);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  const loadPaymentRecurrenceType = () => {
    if (props.loggedUser) {
      setLoadingPaymentRecurrenceType(true);
      ApiService.VehicleServiceController.GetPaymentRecurrenceType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentRecurrenceType(response.payload.filter((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID !== 6));
            if (props.emitAllPaymentTypes) {
              props.emitAllPaymentTypes(response.payload.filter((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID !== 6));
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentRecurrenceType(false);
        }
      );
    }
  };

  const loadVehicleServices = (
    vehicleID: number,
    vehicleServiceID?: number
  ) => {
    if (props.loggedUser) {
      loadCurrentVehicleService(null);
      setLoadingVehicleServices(true);
      ApiService.VehicleServiceController.VehicleServiceGet(
        vehicleServiceID,
        vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (props.expiredVehicle) {
              let tmp = response.payload[0];
              tmp = { ...tmp, paymentRecurrenceType: tmp.eventDT ? "2" : "1" };
              setState(tmp);
            } else {
              setVehicleServices(
                response.payload.filter(
                  (x: IVehicleServiceGet) => x.stored === false
                )
              );
              if (props.emitAllServices) {
                props.emitAllServices(response.payload);
              }
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicleServices(false);
        }
      );
    }
  };

  const loadCurrentVehicleService = (
    vehicleService: IVehicleServiceGet | null
  ) => {
    setLoadingCurrentVehicleService(true);
    setState(vehicleService);
    setTimeout(() => {
      setLoadingCurrentVehicleService(false);
    }, 1000);
  };

  const insertVehicleService = (data: IVehicleServiceInsert) => {
    if (props.loggedUser && props.dataVehicle) {
      setProcessing(true);
      let id: number = props.dataVehicle?.vehicleID;
      ApiService.VehicleServiceController.VehicleServiceInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadVehicleServices(id);
            ToastMessage(
              i18next.t("message:vehicleServiceSuccessfullAdded"),
              "success"
            );
            DismissModal("ModalServices");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateVehicleService = (data: IVehicleServiceUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleServiceController.VehicleServiceUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleServiceSuccessfullUpdated"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleServiceArchive = (data: IVehicleServiceUpdate) => {
    if (props.loggedUser && props.dataVehicle) {
      data.stored = true;
      let id: number = props.dataVehicle?.vehicleID;
      setProcessing(true);
      ApiService.VehicleServiceController.VehicleServiceUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleServiceSuccessfullUpdated"),
              "success"
            );
            loadVehicleServices(id);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleServiceDelete = (vehicleServiceID: number) => {
    if (props.loggedUser && props.dataVehicle) {
      let id: number = props.dataVehicle?.vehicleID;
      setProcessing(true);
      ApiService.VehicleServiceController.VehicleServiceDelete(
        vehicleServiceID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadVehicleServices(id);
            ToastMessage(
              i18next.t("message:vehicleServiceSuccessfullDeleted"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  useEffect(() => {
    if (props.dataVehicle) {
      loadVehicleServices(props.dataVehicle?.vehicleID);
    }
  }, [props?.dataVehicle?.vehicleID]);

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push(
      {
        type: "radio",
        name: "paymentRecurrenceType",
        defaultValue: state?.paymentRecurrenceType ?? "1",
        width: 100,
        disabled: props.disabled,
        options: [
          {
            key: "1",
            text: i18next.t("form:costRecurring"),
          },
          {
            key: "2",
            text: i18next.t("form:costNotRecurring"),
          },
        ],
      },
      {
        width: 50,
        type: "text",
        required: true,
        disabled: props.disabled,
        name: "vehicleServiceName",
        inputProps: { maxLength: 50 },
        defaultValue: state?.vehicleServiceName,
        label: i18next.t("form:nameService") as string,
      },
      {
        width: 40,
        type: "select",
        name: "supplierID",
        required: true,
        disabled: props.disabled,
        defaultValue: state?.supplierID,
        label: String(i18next.t(`form:supplierVehicleService`)),
        options: supplierList.map((supplier: ISupplierGetSideList) => {
          return {
            key: supplier.supplierInfo.supplierID.toString(),
            text: supplier.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: 10,
        type: "custom",
        name: "supplierAddType",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={props.disabled}
              variant="contained"
              onClick={() => {
                SummonModal("Supplier-VehicleService-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      },
      {
        width: 33.33,
        type: "text",
        name: "vehicleServicePlace",
        inputProps: { maxLength: 300 },
        defaultValue: state?.vehicleServicePlace,
        label: i18next.t("form:pointOfSale") as string,
        disabled: props.disabled,
      }
    );

    if (state?.paymentRecurrenceType === "1") {
      multiformInputs.push(
        {
          width: 33.33,
          type: "date",
          disabled: props.disabled,
          required: true,
          name: "vehicleServiceStartDT",
          label: i18next.t("form:dateStart") as string,
          defaultValue: state?.vehicleServiceStartDT,
          minDate: GetVehicleMinDate(props.dataVehicle),
          maxDate: state?.vehicleServiceEndDT,
        },
        {
          width: 33.33,
          type: "date",
          required: true,
          name: "vehicleServiceEndDT",
          disabled: props.disabled,
          label: i18next.t("form:dateFinish") as string,
          defaultValue: state?.vehicleServiceEndDT,
          minDate: state?.vehicleServiceStartDT,
        }
      );
    }

    multiformInputs.push({
      width: 33.33,
      type: "number",
      required: true,
      disabled: props.disabled,
      name: "vehicleServiceAmount",
      inputProps: {
        step: "0.01",
      },
      label: i18next.t("form:serviceImport") as string,
      defaultValue: state?.vehicleServiceAmount,
    });

    if (state?.paymentRecurrenceType === "1") {
      multiformInputs.push({
        type: "select",
        width: 33.33,
        disabled: props.disabled,
        required: true,
        defaultValue: state?.paymentRecurrenceID,
        name: "paymentRecurrenceID",
        label: i18next.t("form:serviceDebitType") as string,
        options: paymentRecurrenceType.map(
          (item: IVehicleServicePaymentRecurrenceType) => {
            return {
              key: item.paymentRecurrenceID,
              text: item.paymentRecurrenceName,
            };
          }
        ),
      });
    } else {
      multiformInputs.push({
        type: "date",
        width: 33.33,
        required: true,
        disabled: props.disabled,
        defaultValue: state?.eventDT,
        name: "eventDT",
        minDate: state?.vehicleServiceStartDT,
        maxDate: state?.vehicleServiceEndDT,
        label: i18next.t("form:dateEvent") as string,
      });
    }

    multiformInputs.push({
      width: 100,
      type: "multiline",
      disabled: props.disabled,
      name: "vehicleServiceNote",
      className: "container-add-textarea",
      multilineRows: 5,
      defaultValue: state?.vehicleServiceNote,
      label: String(i18next.t("form:note")),
    });

    return multiformInputs;
  };

  const newService = () => {
    setState(null);
    if (props.doRefreshForm) {
      props.doRefreshForm();
    }
  };

  const EditRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            loadCurrentVehicleService(props.row);
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    loadSupplierList();
    loadPaymentRecurrenceType();
    if (props.expiredVehicle) {
      loadVehicleServices(
        props.expiredVehicle.vehicleID,
        props.expiredVehicle.vehicleServiceID
      );
    }
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: EditRow,
      type: 'custom'
    },
    {
      field: "vehicleServiceName",
      headerName: i18next.t("form:nameService") as string,
    },
    {
      field: "supplierName",
      headerName: i18next.t("form:supplierVehicleService") as string,
    },
    {
      field: "vehicleServicePlace",
      headerName: i18next.t("form:pointOfSale") as string,
    },
    {
      field: "vehicleServiceStartDTView",
      headerName: i18next.t("form:dateStart") as string,
    },
    {
      field: "vehicleServiceEndDTView",
      headerName: i18next.t("form:dateFinish") as string,
    },
    {
      field: "eventDTView",
      headerName: i18next.t("form:dateEvent") as string,
    },
    {
      field: "vehicleServiceAmount",
      headerName: i18next.t("form:serviceImport") as string,
      type: "number"
    },
  ];

  /* ROWS */
  const rowsDef = [];
  for (let i = 0; i < vehicleServices.length; i++) {
    rowsDef.push({
      edit: "",
      vehicleServiceName: vehicleServices[i].vehicleServiceName,
      supplierName: supplierList.filter(
        (item: ISupplierGetSideList) =>
          item.supplierInfo.supplierID === vehicleServices[i].supplierID
      )[0]?.supplierInfo.supplierName,
      supplierID: vehicleServices[i].supplierID,
      vehicleServiceAmount: vehicleServices[i].vehicleServiceAmount,
      vehicleServiceID: vehicleServices[i].vehicleServiceID,
      paymentRecurrenceID: vehicleServices[i].paymentRecurrenceID,
      vehicleServiceStartDTView: displayUTC0_ISODate(
        vehicleServices[i].vehicleServiceStartDT,
        true
      ),
      vehicleServiceEndDTView: displayUTC0_ISODate(
        vehicleServices[i].vehicleServiceEndDT,
        true
      ),
      vehicleServiceStartDT: vehicleServices[i].vehicleServiceStartDT,
      vehicleServiceEndDT: vehicleServices[i].vehicleServiceEndDT,
      eventDT: vehicleServices[i].eventDT,
      eventDTView: displayUTC0_ISODate(vehicleServices[i].eventDT, true),
      vehicleServiceNote: vehicleServices[i].vehicleServiceNote,
      paymentRecurrenceType: vehicleServices[i].eventDT ? "2" : "1",
      vehicleServicePlace: vehicleServices[i].vehicleServicePlace,
    });
  }

  return (
    <div>
      {props.disabled && !props.suppressWarning && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}
      {(props.dataVehicle || props.expiredVehicle) && (
        <div>
          <div className="padding-small">
            <AddNewBanner
              suppressLayout
              forceDisplay
              label={i18next.t("navigation:addNewOtherService") as string}
              new={() => newService()}
            />
          </div>
          {vehicleServices.length > 0 && (
            <div className="padding-small">
              <DataGridWrap
                tableName={i18next.t("navigation:servicesTabTitle") as string}
                headers={columnsDef}
                rows={rowsDef}
              />
            </div>
          )}
          {!loadingCurrentVehicleService && (
            <div>
              <MultiForm
                formId={"StepServices"}
                suppressLayout
                formTitle={""}
                inputs={multiformInputs()}
                onChange={async (data: any) => {
                  setState({ ...state, ...data });
                }}
                suppressSubmit={true}
                onSubmit={(data: any) => {
                  let parsedData = { ...data };
                  parsedData["vehicleID"] = props.dataVehicle?.vehicleID;
                  if (parsedData["paymentRecurrenceType"] === "2") {
                    parsedData["vehicleServiceStartDT"] = null;
                    parsedData["vehicleServiceEndDT"] = null;
                  } else {
                    parsedData["eventDT"] = null;
                  }
                  delete parsedData["supplierAddType"];
                  delete parsedData["paymentRecurrenceType"];
                  if (state.vehicleServiceID) {
                    parsedData["vehicleServiceID"] = state.vehicleServiceID;
                    updateVehicleService(parsedData);
                  } else {
                    insertVehicleService(parsedData);
                  }
                }}
              />
              <div className="margin-vertical-medium">
                <AttachementMainHandler
                  attachmentFamily={"otherservices"}
                  extraGetParametersVector={
                    state?.vehicleServiceID
                      ? [
                          {
                            name: "vehicleServiceID",
                            value: state?.vehicleServiceID,
                          },
                        ]
                      : undefined
                  }
                />
              </div>
              <div className="padding-small">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={props.disabled}
                    onClick={() => {
                      triggerFormValidation("StepServices");
                    }}
                    endIcon={<SaveIcon />}
                  >
                    {i18next.t("form:saveService")}
                  </Button>

                  {isInDashBoard ? (
                    <Button
                      color="error"
                      sx={{ marginRight: "1em" }}
                      disabled={!state?.vehicleServiceID}
                      onClick={() => {
                        SummonModal("VehicleServiceArchive-modal");
                      }}
                      variant="contained"
                      endIcon={<DeleteIcon />}
                    >
                      {i18next.t("form:archiveService")}
                    </Button>
                  ) : (
                    <Button
                      color="error"
                      sx={{ marginRight: "1em" }}
                      disabled={!state?.vehicleServiceID}
                      onClick={() => {
                        SummonModal("VehicleServiceDelete-modal");
                      }}
                      variant="contained"
                      endIcon={<DeleteIcon />}
                    >
                      {i18next.t("form:deleteService")}
                    </Button>
                  )}

                  <SmartModal
                    modalUniqueId="VehicleServiceArchive-modal"
                    title={i18next.t("generic:titleRecordModalArchive")}
                    modalInnerComponent={
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: i18next
                              .t("generic:descriptionRecordModalArchive")
                              .replace(
                                "CURRENT_ITEM_LIST",
                                state?.vehicleServiceName
                              ),
                          }}
                        />
                        <p>
                          <i>
                            {i18next.t(
                              "generic:descriptionRecordModalArchiveService"
                            )}
                          </i>
                        </p>
                      </>
                    }
                    onAccept={() => {
                      let state_ = { ...state };
                      state_["vehicleID"] = props.dataVehicle?.vehicleID;
                      if (state_["paymentRecurrenceType"] === "2") {
                        state_["vehicleServiceStartDT"] = null;
                        state_["vehicleServiceEndDT"] = null;
                      } else {
                        state_["eventDT"] = null;
                      }
                      delete state_["supplierAddType"];
                      delete state_["paymentRecurrenceType"];
                      VehicleServiceArchive(state_);
                    }}
                    onReject={() => {}}
                  />

                  <SmartModal
                    modalUniqueId="VehicleServiceDelete-modal"
                    title={i18next.t("generic:titleRecordModal")}
                    modalInnerComponent={
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: i18next
                              .t("generic:descriptionRecordModal")
                              .replace(
                                "CURRENT_ITEM_LIST",
                                state?.vehicleServiceName
                              ),
                          }}
                        />
                        <p>
                          <i>
                            {i18next.t(
                              "generic:descriptionRecordModalDeleteService"
                            )}
                          </i>
                        </p>
                      </>
                    }
                    onAccept={() => {
                      VehicleServiceDelete(state?.vehicleServiceID);
                    }}
                    onReject={() => {}}
                  />
                </div>
              </div>
            </div>
          )}

          <SmartModal
            modalUniqueId="Supplier-VehicleService-modal"
            title={i18next.t("generic:titleAddSupplier")}
            hideButtons={true}
            modalInnerComponent={
              <FormSupplier
                loggedUser={props.loggedUser}
                data={[]}
                idModal="Supplier-VehicleService-modal"
                updateList={(data: ISupplierGetSideList) => {
                  let supplierList_: ISupplierGetSideList[] = [...supplierList];
                  supplierList_.push(data);
                  setSupplierList(supplierList_);
                }}
              />
            }
          />
          <LoaderBackdrop
            loading={
              loadingVehicleServices ||
              loadingCurrentVehicleService ||
              processing ||
              loadingPaymentRecurrenceType ||
              loadingSupplierList
            }
          />
        </div>
      )}
    </div>
  );
};

export default FormServices;
