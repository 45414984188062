import {
  RoadTaxActionType,
  SET_DEBIT_COST,
  SET_CREDIT_COST,
  SET_ROAD_TAX_LIST
} from "./RoadTaxAction";
import { RoadTaxState } from "./RoadTaxState";

const DEFAULT_STATE: RoadTaxState = {
  debitCost: 0,
  creditCost: 0,
  roadTaxList: [],
};

export const roadTaxReducer = (
  state: RoadTaxState = DEFAULT_STATE,
  action: RoadTaxActionType
): RoadTaxState => {
  switch (action.type) {
    case SET_DEBIT_COST:
      return {
        ...state,
        debitCost: action.payload,
      };
    case SET_CREDIT_COST:
      return {
        ...state,
        creditCost: action.payload,
      };
    case SET_ROAD_TAX_LIST:
      return {
        ...state,
        roadTaxList: action.payload,
      };
    default:
      return state;
  }
};
