import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import i18next from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import AppRoutes from "../../../Costants/AppRoutes";
import { ICompanyGet } from "../../../Models/ICompany";
import {
  IContractGetProperty,
  IContractGetType,
  IVehicleContractGet,
  IVehicleContractService,
  IVehicleContractServiceInsert,
  IVehicleContractServiceUpdate,
} from "../../../Models/IContract";
import { IVehicleService } from "../../../Models/IService";
import { ISupplierGetSideList } from "../../../Models/ISupplier";
import { IUser } from "../../../Models/IUser";
import { IVehicleServicePaymentRecurrenceType } from "../../../Models/IVehicleService";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { fromOrderGet_contract } from "../../../Utils/OrderToVehicle";
import { ToastMessage } from "../../../Utils/Toastify";
import AddNewBanner from "../../AddNewBanner/AddNewBanner";
import AttachementMainHandler from "../../AttachmentMainHandler/AttachmentMainHandler";
import DataGridWrap, { IDataGridColumnDef } from "../../DataGridWrap/DataGridWrap";
import DeadlineMainHandler from "../../DeadlineMainHandler/DeadlineMainHandler";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../../SmartModal/SmartModal";
import FormCompanyUser from "../../StepCompanyUsers/FormCompanyUser";
import FormSupplier from "../../StepSupplier/FormSupplier";
import { IAssignmentGetDeducibility } from "../../../Models/IAssignment";
import { GetVehicleMinDate } from "../../../Pages/DashboardPage/DashboardPage";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../Reducers/RootReducer";
import { VehiclesActions } from "../../../Reducers/Vehicle/VehicleAction";

export interface IFormContractProps {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  dataVehicle?: IVehicleDataGet | null;
  expiredVehicle?: IVehicleDataGet | null;
  suppressWarning?: boolean;
  doRefreshForm?: () => void;
  disabled: boolean;
  requestUpdate?: () => void;
  updateArchive?: (data: any, contractID: number) => void;
}

let stateClone: any = {};

const FormContract = (props: IFormContractProps) => {
  const [state, setState_] = useState<any>({});
  const [updatedState, setUpdatedState] = useState<any>(null);
  const [stateService, setStateService] = useState<any>(null);
  const [reloadForm, setReloadForm] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingContractType, setLoadingContractType] = useState<boolean>(false);
  const [contractType, setContractType] = useState<IContractGetType[]>([]);
  const [loadingContractPropertyList, setLoadingContractPropertyList] =
    useState<boolean>(false);
  const [contractPropertyList, setContractPropertyList] = useState<
    IContractGetProperty[]
  >([]);

  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [companies, setCompanies] = useState<ICompanyGet[]>([]);

  const [loadingCurrentContract, setLoadingCurrentContract] = useState<boolean>(false);
  const [loadingSupplierList, setLoadingSupplierList] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [services, setServices] = useState<IVehicleService[]>([]);
  const [loadingPaymentRecurrenceType, setLoadingPaymentRecurrenceType] =
    useState<boolean>(false);
  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<
    IVehicleServicePaymentRecurrenceType[]
  >([]);
  const [loadingCurrentContractService, setLoadingCurrentContractService] =
    useState<boolean>(false);
  const [currentContractService, setCurrentContractService] = useState<
    IVehicleContractService[]
  >([]);
  const [supplierIDValue, setSupplierIDValue] = useState<number | undefined>(0);

  const [inputToSum, setInputToSum] = useState<any>();
  const isInDashBoard = window.location.href.includes(AppRoutes.INTERNAL_DASHBOARD);

  const [assignmentDeducibility, setAssignmentDeducibility] = useState<
    IAssignmentGetDeducibility[]
  >([]);
  const [loadingAssignmentDeducibility, setLoadingAssignmentDeducibility] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const doReloadForm = () => {
    setReloadForm(true);
    setTimeout(() => {
      setReloadForm(false);
    }, 200);
  };

  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key] || data[key] === 0) {
          state_[key] = data[key];
        }
      }
      return state_;
    }

    return {};
  };

  const setState = (state: any) => {
    stateClone = JSON.parse(JSON.stringify(state));
    setState_(state);
  };

  const loadContractType = () => {
    if (props.loggedUser) {
      setLoadingContractType(true);
      ApiService.ContractController.ContractGetContractType(
        false,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setContractType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingContractType(false);
        }
      );
    }
  };

  const loadContractPropertyList = (contractTypeID: number) => {
    if (contractTypeID) {
      setLoadingContractPropertyList(true);
      ApiService.ContractController.ContractGetPropertyList(
        false,
        true,
        true,
        contractTypeID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setContractPropertyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingContractPropertyList(false);
        }
      );
    }
  };

  const loadSupplierList = () => {
    if (props.loggedUser) {
      setLoadingSupplierList(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  const loadCompanies = () => {
    if (props.loggedUser) {
      setLoadingCompanies(true);
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanies(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanies(false);
        }
      );
    }
  };

  const loadServices = () => {
    if (props.loggedUser) {
      setLoadingServices(true);
      ApiService.ServiceController.ServiceGetData(
        null,
        null,
        false,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setServices(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingServices(false);
        }
      );
    }
  };

  const loadPaymentRecurrenceType = () => {
    if (props.loggedUser) {
      setLoadingPaymentRecurrenceType(true);
      ApiService.VehicleServiceController.GetPaymentRecurrenceType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentRecurrenceType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentRecurrenceType(false);
        }
      );
    }
  };

  const loadCurrentContract = (vehicleID: number) => {
    if (props.loggedUser) {
      setLoadingCurrentContract(true);
      ApiService.VehicleContractController.VehicleContractGet(
        vehicleID,
        null,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let list: IVehicleContractGet[] = response.payload;
            let activeOnly: IVehicleContractGet[] = list.filter(
              (x: IVehicleContractGet) => x.info.stored === false
            );
            if (activeOnly.length > 0) {
              let deserialized = {
                ...activeOnly[0]["info"],
                ...{ contractTypeProperties: activeOnly[0]["details"] },
              };
              setState(mergeObjects(deserialized));
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentContract(false);
        }
      );
    }
  };

  const loadCurrentContractService = (vehicleContractID: number) => {
    if (props.loggedUser) {
      setLoadingCurrentContractService(true);
      ApiService.VehicleContractController.VehicleContractServiceGet(
        vehicleContractID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCurrentContractService(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentContractService(false);
        }
      );
    }
  };

  const loadAssignmentDeducibility = () => {
    if (props.loggedUser) {
      setLoadingAssignmentDeducibility(true);
      ApiService.AssignmentController.GetAssignmentDeductibility(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAssignmentDeducibility(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAssignmentDeducibility(false);
        }
      );
    }
  };

  const VehicleContractInsert = (data: IVehicleContractGet) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:vehicleContractSuccessfullAdded"), "success");
            (data as any).vehicleContractID = response.payload.vehicleContractID;
            setState(data);
            if (props.requestUpdate) {
              props.requestUpdate();
            }
            DismissModal("contractModal");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractUpdate = (data: IVehicleContractGet) => {
    DismissModal("versioningContractForm-modal");
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicle_: any = { ...vehicle };
            let indexContractDT = response.payload.details.findIndex((x: any) => x.propertyName === "DataInizio" || x.propertyName === "DataAcquisto");
            vehicle_["contractStartDT"] = response.payload.details[indexContractDT]?.propertyValue;
            vehicle_["contractTypeID"] = response.payload.info.contractTypeID;
            dispatch(VehiclesActions.setActiveVehicleForCompany(vehicle_));
            ToastMessage(
              i18next.t("message:vehicleContractSuccessfullUpdated"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractVersioning = (
    data: IVehicleContractGet,
    vehicleContractID: number
  ) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractVersioning(
        vehicleContractID,
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicle_: any = { ...vehicle };
            let indexContractDT = response.payload.details.findIndex((x: any) => x.propertyName === "DataInizio" || x.propertyName === "DataAcquisto");
            vehicle_["contractStartDT"] = response.payload.details[indexContractDT]?.propertyValue;
            vehicle_["contractTypeID"] = response.payload.info.contractTypeID;
            dispatch(VehiclesActions.setActiveVehicleForCompany(vehicle_));
            setStateService(null);
            if (props.updateArchive && response.payload) {
              props.updateArchive(response.payload, vehicleContractID);
            }
            ToastMessage(
              i18next.t("message:vehicleContractSuccessfullVersioned"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractDelete = (vehicleContractID: number) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractDelete(
        vehicleContractID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleContractSuccessfullDeleted"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractServiceInsert = (data: IVehicleContractServiceInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractServiceInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setStateService(null);
            ToastMessage(
              i18next.t("message:vehicleContractServiceSuccessfullAdded"),
              "success"
            );
            DismissModal("ServiceActiveRent-modal");
            loadCurrentContractService(state?.vehicleContractID);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractServiceUpdate = (data: IVehicleContractServiceUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractServiceUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setStateService(null);
            ToastMessage(
              i18next.t("message:vehicleContractServiceSuccessfullUpdated"),
              "success"
            );
            DismissModal("ServiceActiveRent-modal");
            loadCurrentContractService(state?.vehicleContractID);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleContractServiceDelete = (vehicleContractServiceID: number) => {
    if (props.loggedUser && vehicleContractServiceID) {
      setProcessing(true);
      ApiService.VehicleContractController.VehicleContractServiceDelete(
        vehicleContractServiceID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setStateService(null);
            ToastMessage(
              i18next.t("message:vehicleContractServiceSuccessfullDeleted"),
              "success"
            );
            DismissModal("ServiceActiveRent-modal");
            loadCurrentContractService(state?.vehicleContractID);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const propertyIDsToSum = (data: any, type: string, ids: any[] | undefined) => {
    if (ids && ids.length > 0) {
      if (type !== "datetime" && type !== "date") {
        let newPropertyValue: number = 0;
        for (let index = 0; index < ids.length; index++) {
          if (data[ids[index].id]) {
            newPropertyValue += +data[ids[index].id];
          }
        }
        return newPropertyValue;
      }
      if (type === "datetime") {
        let newPropertyValue: string = "";
        let monthsToAdd: number = 0;
        let idDateIndex: number = 0;
        for (let index = 0; index < ids.length; index++) {
          if (data[ids[index].id] && ids[index].type !== "datetime") {
            monthsToAdd += +data[ids[index].id];
          }
          if (ids[index].type === "datetime") {
            idDateIndex = index;
          }
        }

        if (data[ids[idDateIndex].id] !== undefined) {
          newPropertyValue = moment(data[ids[idDateIndex].id])
            .add({ months: monthsToAdd })
            .toISOString();
          return newPropertyValue;
        }
      }
    }
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    if (state?.vehicleContractID && contractPropertyList.length === 0) {
      return [];
    }
    multiformInputs.push({
      width: 50,
      type: "select",
      disabled: props.disabled,
      name: "contractTypeID",
      defaultValue: state?.contractTypeID,
      label: i18next.t("form:orderContractType") as string,
      required: true,
      options: contractType.map((item: IContractGetType) => {
        return {
          key: item.contractTypeID,
          text: item.contractTypeName?.toString(),
        };
      }),
    });

    // get isDropdown element and autofill with suppliers or assignment
    contractPropertyList
      .filter((item: IContractGetProperty) => item.isDropdown)
      .map((item: IContractGetProperty) => {
        if (item.propertyID === 8) {
          multiformInputs.push(
            {
              type: "select",
              name: item.propertyID.toString(),
              required: item.mandatory,
              label: item.propertyClientName,
              disabled: props.disabled,
              ordinal: item.propertyIndex,
              defaultValue:
                state?.contractTypeProperties !== undefined
                ? state?.contractTypeProperties.find(
                    (x: IContractGetProperty) => x.propertyID === item.propertyID
                  )?.propertyValue
                : state?.[item.propertyID],
              width: 40,
              options: supplierList
                .filter(
                  (x: ISupplierGetSideList) =>
                    x.supplierInfo.supplierTypeID === supplierIDValue
                )
                .map((item: ISupplierGetSideList) => {
                  return {
                    key: item.supplierInfo.supplierID?.toString(),
                    text: item.supplierInfo.supplierName?.toString(),
                  };
                }),
            },
            {
              width: 10,
              type: "custom",
              name: "addSupplier",
              element: (
                <div className="multi-form-button-add-record">
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: "55px" }}
                    onClick={() => {
                      SummonModal("supplierContract-modal");
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              ),
            }
          );
        }
        if (item.propertyID === 2 || item.propertyID === 1) {
          multiformInputs.push(
            {
              type: "select",
              name: item.propertyID.toString(),
              required: item.mandatory,
              label: item.propertyClientName,
              ordinal: item.propertyIndex,
              disabled: props.disabled,
              defaultValue:
                state?.contractTypeProperties !== undefined
                  ? state?.contractTypeProperties.find(
                      (x: IContractGetProperty) => x.propertyID === item.propertyID
                    )?.propertyValue
                  : state?.[item.propertyID],
              width: 40,
              options: companies.map((item: ICompanyGet) => {
                return {
                  key: item.companyID?.toString(),
                  text: item.companyBusinessName?.toString(),
                };
              }),
            },
            {
              width: 10,
              type: "custom",
              name: "addSupplier",
              element: (
                <div className="multi-form-button-add-record">
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: "55px" }}
                    onClick={() => {
                      SummonModal("companyUserContract-modal");
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              ),
            }
          );
        }

        if (item.propertyID === 20) {
          multiformInputs.push({
            ordinal: item.propertyIndex,
            className: "contract-input-form",
            type: "select",
            name: item.propertyID.toString(),
            required: item.mandatory,
            label: item.propertyClientName,
            disabled: props.disabled,
            defaultValue:
              state?.contractTypeProperties !== undefined
                ? state?.contractTypeProperties.find(
                    (x: IContractGetProperty) => x.propertyID === item.propertyID
                  )?.propertyValue
                : state?.[item.propertyID],
            options: assignmentDeducibility.map((item: IAssignmentGetDeducibility) => {
              return {
                key: item.assignmentDeductibilityID,
                text: (item.deductPercentage + " %").toString(),
              };
            }),
          });
        }
      });

    contractPropertyList
      .filter((item: IContractGetProperty) => !item.isDropdown)
      .map((item: IContractGetProperty) => {
        let typeID = "text";
        switch (item.propertyTypeName) {
          case "decimal":
          case "integer":
            typeID = "number";
            break;
          case "datetime":
            typeID = "date";
            break;
        }

        if (item.propertyToSum !== null) {
          multiformInputs.push({
            className: "contract-input-form",
            type: typeID as any,
            ordinal: item.propertyIndex,
            name: item.propertyID.toString(),
            required: item.mandatory,
            label: item.propertyClientName,
            disabled: props.disabled || item.propertyToSum !== null,
            currentValue: state?.[item.propertyID],
            inputAdornament: item.isMoney ? { adornament: "€" } : {},
          });
        } else {
          multiformInputs.push({
            className: "contract-input-form",
            type: typeID as any,
            minDate:
              typeID === "date" && props?.dataVehicle
                ? GetVehicleMinDate(props.dataVehicle, true)
                : new Date().toISOString(),
            ordinal: item.propertyIndex,
            name: item.propertyID.toString(),
            required: item.mandatory,
            label: item.propertyClientName + (item.propertyName === "ChilometriContratto" ? ((item.mandatory ? " *" : "") + "<i class='info-modal-form' title='" + i18next.t("message:chilometriContrattoIlliminato") + "'>i</i>") : "") as string,
            disabled: props.disabled,
            defaultValue:
              state?.contractTypeProperties !== undefined
                ? state?.contractTypeProperties.find(
                    (x: IContractGetProperty) => x.propertyID === item.propertyID
                  )?.propertyValue
                : state?.[item.propertyID],
            inputAdornament: item.isMoney ? { adornament: "€" } : {},
            tooltip: item.propertyName === "ChilometriContratto" ? {title: i18next.t("message:chilometriContrattoIlliminato")} : undefined,
          });
        }
      });

    // Return the full and complete Multiform
    return multiformInputs.sort((a: any, b: any) => a.ordinal - b.ordinal);
  };

  useEffect(() => {
    loadContractType();
    loadCompanies();
    loadSupplierList();

    loadServices();
    loadPaymentRecurrenceType();
    setState(fromOrderGet_contract({}));
    doReloadForm();
    loadAssignmentDeducibility();
    return () => {
      stateClone = {};
    };
  }, []);

  useEffect(() => {
    if (state?.contractTypeID) {
      loadContractPropertyList(state.contractTypeID);
      setSupplierIDValue(contractType.find(x => x.contractTypeID === state.contractTypeID)?.supplierTypeID);
    }
  }, [state?.contractTypeID]);

  useEffect(() => {
    if (props.dataVehicle?.vehicleID) {
      loadCurrentContract(props.dataVehicle.vehicleID);
    }
  }, [props?.dataVehicle]);

  useEffect(() => {
    if (props.expiredVehicle?.vehicleID) {
      loadCurrentContract(props.expiredVehicle.vehicleID);
    }
  }, [props?.expiredVehicle]);

  useEffect(() => {
    if (state?.vehicleContractID) {
      loadCurrentContractService(state.vehicleContractID);
    }
  }, [state?.vehicleContractID]);

  useEffect(() => {
    // raccolgo i campi da addizionare
    let inputToSum: any = [];
    contractPropertyList
      .filter(
        (item: IContractGetProperty) => !item.isDropdown && item.propertyToSum !== null
      )
      .map((item: IContractGetProperty) => {
        let inputIDsToSum: any = [];
        let idsToSum = item.propertyToSum.split(",");
        for (let i = 0; i < idsToSum.length; i++) {
          inputIDsToSum.push({
            id: idsToSum[i],
            type: contractPropertyList.find(
              (x: IContractGetProperty) => x.propertyID === +idsToSum[i]
            )?.propertyTypeName,
          });
        }

        inputToSum[item.propertyID] = {
          ids: inputIDsToSum,
          type: item.propertyTypeName,
        };
      });
    setInputToSum(inputToSum);
  }, [contractPropertyList]);

  const editRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            setStateService(props.row);
            SummonModal("ServiceActiveRent-modal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "name",
      headerName: i18next.t("form:serviceID") as string,
    },
    {
      field: "cost",
      headerName: i18next.t("form:serviceCost") as string,
      type: "number"
    },
    {
      field: "deductible",
      headerName: i18next.t("form:serviceShoreLeave") as string,
    },
    {
      field: "uncovered",
      headerName: i18next.t("form:serviceUncovered") as string,
    },
    {
      field: "paymentRecurrenceIDView",
      headerName: i18next.t("form:serviceDebitType") as string,
    },
  ];

  /* ROWS */
  const rowsDef = [];
  for (let i = 0; i < currentContractService.length; i++) {
    rowsDef.push({
      edit: "",
      name: services?.find(
        (x: IVehicleService) => currentContractService[i].serviceID === x.serviceID
      )?.serviceName,
      cost: currentContractService[i].cost,
      deductible: currentContractService[i].deductible,
      uncovered: currentContractService[i].uncovered,
      paymentRecurrenceIDView: paymentRecurrenceType.filter(
        (x: IVehicleServicePaymentRecurrenceType) =>
          x.paymentRecurrenceID === currentContractService[i].paymentRecurrenceID
      )[0]?.paymentRecurrenceName,
      paymentRecurrenceID: currentContractService[i].paymentRecurrenceID,
      serviceID: currentContractService[i].serviceID,
      vehicleContractServiceID: currentContractService[i].vehicleContractServiceID,
    });
  }

  const loadData =
    loadingCurrentContract ||
    loadingCurrentContractService ||
    loadingContractType ||
    loadingContractPropertyList ||
    loadingCompanies ||
    loadingSupplierList ||
    loadingServices ||
    loadingPaymentRecurrenceType;

  return (
    <div>
      {props.disabled && !props.suppressWarning && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}
      {!reloadForm && !loadData && (
        <MultiForm
          styles={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          formId={"StepContract"}
          suppressLayout
          formTitle={""}
          inputs={multiformInputs()}
          onChange={async (data: any) => {
            let parseData = { ...data };
            let inputToSumKeys = Object.keys(inputToSum);
            for (let index = 0; index < inputToSumKeys.length; index++) {
              let propertID = +inputToSumKeys[index];
              parseData[propertID] = propertyIDsToSum(
                data,
                inputToSum[inputToSumKeys[index]].type,
                inputToSum[inputToSumKeys[index]].ids
              );
            }
            setState(mergeObjects(parseData));
            
            setSupplierIDValue(contractType.find(x => x.contractTypeID === data.contractTypeID)?.supplierTypeID);
          }}
          onSubmit={async (data: any) => {
            let parsedData = { ...data };

            if (props.expiredVehicle) {
              parsedData["vehicleID"] = props.expiredVehicle?.vehicleID;
            } else {
              parsedData["vehicleID"] = props.dataVehicle?.vehicleID;
            }
            parsedData["contractTypeID"] = state.contractTypeID;
            parsedData["contractTypeProperties"] = [];
            for (let i = 0; i < contractPropertyList.length; i++) {
              parsedData["contractTypeProperties"].push({
                propertyID: contractPropertyList[i].propertyID,
                propertyValue: state?.[contractPropertyList[i].propertyID]?.toString(),
              });

              delete parsedData[contractPropertyList[i].propertyID];
            }
            if (state?.vehicleContractID) {
              parsedData["vehicleContractID"] = state.vehicleContractID;
              setUpdatedState(parsedData);
              if (isInDashBoard) {
                SummonModal("versioningContractForm-modal");
              } else {
                VehicleContractUpdate(parsedData);
              }
            } else {
              VehicleContractInsert(parsedData);
            }
          }}
          suppressSubmit={true}
        />
      )}

      {currentContractService.length > 0 && (
        <div className="padding-small">
          <DataGridWrap
            tableName={i18next.t("navigation:_add_services") as string}
            headers={columnsDef}
            rows={rowsDef}
          />
        </div>
      )}

      {!props.disabled && state?.vehicleContractID && (
        <div style={{ margin: "0px 0.5em 1em" }}>
          <AddNewBanner
            forceDisplay
            label={i18next.t("navigation:addServices")}
            new={() => {
              setStateService(null);
              SummonModal("ServiceActiveRent-modal");
            }}
          />
        </div>
      )}

      <DeadlineMainHandler
        DeadlineFamily={"contract"}
        extraGetParameters={
          state?.vehicleContractID
            ? {
                name: "vehicleContractID",
                value: state?.vehicleContractID,
              }
            : undefined
        }
      />

      <div className="margin-vertical-medium">
        <AttachementMainHandler
          attachmentFamily={"contract"}
          extraGetParametersVector={
            state?.vehicleContractID
              ? [
                  {
                    name: "vehicleContractID",
                    value: state?.vehicleContractID,
                  },
                ]
              : undefined
          }
        />
      </div>

      <div className="padding-small">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            variant="contained"
            disabled={props.disabled || processing}
            onClick={() => {
              triggerFormValidation("StepContract");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveContract")}
          </Button>

          {!isInDashBoard && (
            <Button
              color="error"
              sx={{ marginRight: "1em" }}
              disabled={!state?.vehicleContractID}
              onClick={() => {
                SummonModal("ContractDelete-modal");
              }}
              variant="contained"
              endIcon={<DeleteIcon />}
            >
              {i18next.t("form:deleteContract")}
            </Button>
          )}

          <SmartModal
            modalUniqueId="ContractDelete-modal"
            title={i18next.t("generic:titleRecordModal")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModal")
                    .replace(
                      "CURRENT_ITEM_LIST",
                      i18next.t("form:searchContract").toLowerCase() +
                        " #" +
                        state?.vehicleContractID
                    ),
                }}
              />
            }
            onAccept={() => {
              VehicleContractDelete(state?.vehicleContractID);
            }}
            onReject={() => {}}
          />

          <SmartModal
            modalUniqueId="versioningContractForm-modal"
            title={i18next.t("generic:titleRecordModalVersioning")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModalVersioning")
                    .replace(
                      "CURRENT_ITEM_LIST",
                      i18next.t("form:searchContract").toLowerCase() +
                        " #" +
                        state?.vehicleContractID
                    ),
                }}
              />
            }
            buttons={[
              {
                text: "Sovrascrivi",
                onClick: () => {
                  VehicleContractUpdate(updatedState);
                  DismissModal("versioningContractForm-modal");
                },
                enabled: true,
              },
              {
                text: "Versiona",
                onClick: () => {
                  let vehicleContractID_ = updatedState.vehicleContractID;
                  delete updatedState["vehicleContractID"];
                  VehicleContractVersioning(updatedState, vehicleContractID_);
                  DismissModal("versioningContractForm-modal");
                },
                enabled: true,
              },
            ]}
          />
        </div>
      </div>

      <SmartModal
        modalUniqueId="ServiceActiveRent-modal"
        title={i18next.t("generic:titleServiceActiveRentModal")}
        modalInnerComponent={
          <div>
            <MultiForm
              suppressLayout
              formId="ServiceActiveRent"
              formTitle=""
              inputs={[
                {
                  type: "select",
                  width: 100,
                  required: true,
                  disabled: props.disabled,
                  defaultValue: stateService?.serviceID ? stateService?.serviceID : "",
                  name: "serviceID",
                  label: i18next.t("form:serviceID") as string,
                  options: services.map((item: IVehicleService) => {
                    return {
                      key: item.serviceID,
                      text: item.serviceName.toString(),
                    };
                  }),
                },
                {
                  width: 50,
                  type: "number",
                  disabled: props.disabled,
                  required: true,
                  name: "cost",
                  inputProps: {
                    step: "0.01",
                  },
                  defaultValue: stateService?.cost,
                  label: i18next.t("form:serviceCost") as string,
                },
                {
                  width: 50,
                  type: "number",
                  disabled: props.disabled,
                  required: true,
                  name: "deductible",
                  defaultValue: stateService?.deductible,
                  label: i18next.t("form:serviceShoreLeave") as string,
                },
                {
                  width: 50,
                  type: "number",
                  name: "uncovered",
                  required: true,
                  disabled: props.disabled,
                  defaultValue: stateService?.uncovered,
                  label: i18next.t("form:serviceUncovered") as string,
                },
                {
                  type: "select",
                  width: 50,
                  required: true,
                  defaultValue: stateService?.paymentRecurrenceID
                    ? stateService?.paymentRecurrenceID
                    : "",
                  name: "paymentRecurrenceID",
                  label: i18next.t("form:serviceDebitType") as string,
                  options: paymentRecurrenceType.map(
                    (item: IVehicleServicePaymentRecurrenceType) => {
                      return {
                        key: item.paymentRecurrenceID,
                        text: item.paymentRecurrenceName,
                      };
                    }
                  ),
                },
              ]}
              onChange={async (data: any) => {
                setStateService({ ...stateService, ...data });
              }}
              suppressSubmit={true}
              onSubmit={async (data: any) => {
                let parsedData = { ...data };
                if (stateService?.vehicleContractServiceID) {
                  parsedData["vehicleContractServiceID"] =
                    stateService?.vehicleContractServiceID;
                  VehicleContractServiceUpdate(parsedData);
                } else {
                  if (state?.vehicleContractID) {
                    parsedData["vehicleContractID"] = state?.vehicleContractID;
                    VehicleContractServiceInsert(parsedData);
                  }
                }
              }}
            />
            <div className="padding-small">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  variant="contained"
                  disabled={props.disabled || processing}
                  onClick={() => {
                    triggerFormValidation("ServiceActiveRent");
                  }}
                  endIcon={<SaveIcon />}
                >
                  {i18next.t("form:saveService")}
                </Button>
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!stateService?.vehicleContractServiceID}
                  onClick={() => {
                    SummonModal("ServiceActiveRentDelete-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteService")}
                </Button>
                <SmartModal
                  modalUniqueId="ServiceActiveRentDelete-modal"
                  title={i18next.t("generic:titleRecordModal")}
                  modalInnerComponent={
                    <p
                      dangerouslySetInnerHTML={{
                        __html: i18next
                          .t("generic:descriptionRecordModal")
                          .replace(
                            "CURRENT_ITEM_LIST",
                            services.filter(
                              (x: IVehicleService) =>
                                x.serviceID === stateService?.serviceID
                            )[0]?.serviceName
                          ),
                      }}
                    />
                  }
                  onAccept={() => {
                    VehicleContractServiceDelete(stateService?.vehicleContractServiceID);
                  }}
                  onReject={() => {}}
                />
              </div>
            </div>
          </div>
        }
      />

      <SmartModal
        modalUniqueId="companyUserContract-modal"
        title={i18next.t("generic:titleAddCompany")}
        hideButtons={true}
        modalInnerComponent={
          <FormCompanyUser
            loggedUser={props.loggedUser}
            data={[]}
            companyUser={props.companyUser}
            idModal="companyUserContract-modal"
          />
        }
      />

      <SmartModal
        modalUniqueId="supplierContract-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            forceSupplierTypeID={supplierIDValue}
            loggedUser={props.loggedUser}
            data={[]}
            idModal="supplierContract-modal"
            updateList={(data: ISupplierGetSideList) => {
              let supplierList_: ISupplierGetSideList[] = [...supplierList];
              supplierList_.push(data);
              setSupplierList(supplierList_);
            }}
          />
        }
      />

      <LoaderBackdrop
        loading={processing || reloadForm || loadData || loadingAssignmentDeducibility}
      />
    </div>
  );
};

export default FormContract;
