/* LIBRARIES */
import i18next from "i18next";

/* MUI */
import { Link } from "@mui/material";

/* COMPONENT */
const StepLogIn = () => {
  /* RETURN */
  return (
    <h2 className="custom-stepper-text custom-stepper-text-bold">
      {i18next.t("generic:checkAlreadyAccount")}{" "}
      <Link href="/login" underline="hover">
        {i18next.t("navigation:login")}
      </Link>
    </h2>
  );
};

export default StepLogIn;
