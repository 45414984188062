/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "./VehiclePromptDeliveryPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* CONST */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IGoogleAddress } from "../../../../../Utils/Google";

/* MODELS */
import { IVehicleRegion } from "../../../../../Models/IVehicles";
import { IINSERT_Request_Prompt_Delivery } from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import MultiForm from "../../../../../Components/MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Box, Button, Typography } from "@mui/material";
// Icons
import SendIcon from "@mui/icons-material/Send";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import PinDropIcon from "@mui/icons-material/PinDrop";

/* COMPONENT */
const VehiclePromptDeliveryPageSearch = () => {
  /* STATE */
  // Search form
  const [formData, setFormData] = useState<any>({});
  // Map form
  const [mapData, setMapData] = useState<any>([]);
  // Map form
  const [addressData, setAddressData] = useState<any>("");
  // Italian regions
  const [regions, setRegions] = useState<IVehicleRegion[]>([]);
  // Slider marks
  const [marks] = useState<any>([
    {
      value: 0,
      label: "0 Km",
    },
    {
      value: 10,
      label: "10 Km",
    },
    {
      value: 20,
      label: "20 Km",
    },
    {
      value: 30,
      label: "30 Km",
    },
  ]);
  // Loading
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingRegions, setLoadingRegions] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* GET URL PARAMS */
  const [params] = useSearchParams();
  const driverGroupVehiclesRequestID: string | null = params.get("vehicle");

  /* API */
  // Get italian regions
  const loadRegions = () => {
    setLoadingRegions(true);

    ApiService.VehicleDataController.VehicleDataGetVehicleRegion(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setRegions(response.payload);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingRegions(false);
      }
    );
  };

  // Insert request for prompt delivery
  const INSERT_Request_Prompt_Delivery = (data: IINSERT_Request_Prompt_Delivery) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_Request_Prompt_Delivery(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");

          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadRegions();
  }, []);

  /* MULIFORM INPUTS */
  let formDataInputs: any[] = [
    // Search type
    {
      width: 35,
      type: "select",
      name: "searchDealer",
      defaultValue: formData?.searchDealer || "",
      label: i18next.t("form:searchDealer"),
      options: ["Cerca per regione", "Cerca per distanza da un punto"].map((x: any) => {
        return {
          key: x,
          text: x,
        };
      }),
    },
    // Space
    {
      type: "custom",
      name: "space",
      width: 65,
      element: <div></div>,
    },
  ];

  if (formData?.searchDealer === "Cerca per regione") {
    formDataInputs.push({
      width: 35,
      type: "select",
      name: "selectRegions",
      defaultValue: formData?.selectRegions || [],
      label: `${i18next.t("form:selectRegions")}`,
      multiple: true,
      options: regions.map((x: IVehicleRegion) => {
        return {
          key: x.regionID,
          text: x.regionName,
        };
      }),
    });
  } else if (formData?.searchDealer === "Cerca per distanza da un punto") {
    formDataInputs.push(
      {
        type: "custom",
        name: "mapsButton",
        width: 35,
        element: (
          <Button
            variant="contained"
            startIcon={<AddLocationAltIcon />}
            onClick={() => {
              SummonModal("modal-maps-prompt-delivery");
            }}
          >
            {i18next.t("form:mapsButton")}
          </Button>
        ),
      },
      // Space
      {
        type: "custom",
        name: "space",
        width: 65,
        element: <div></div>,
      },
      // Address
      {
        type: "custom",
        name: "address",
        width: 100,
        element: addressData && (
          <Typography variant="overline">
            <strong>{i18next.t("form:location")}:</strong> {addressData}
          </Typography>
        ),
      }
    );
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("form:searchPromptDeliver")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          <Typography>{i18next.t("form:selectDealer")}</Typography>

          <MultiForm
            suppressLayout
            suppressSubmit
            formId={"FormPromptDelivery_Send"}
            inputs={formDataInputs}
            onChange={(data: any) => {
              if (data?.searchDealer !== formData?.searchDealer) {
                // Reset Search from regions
                formData.selectRegions && (formData.selectRegions.length = 0);
                // Reset Search from a point
                setMapData([]);
                setAddressData("");
              }

              setFormData({ ...formData, ...data });
            }}
          />

          <div className="display-flex-end padding-bottom-small padding-right-small">
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              disabled={
                driverGroupVehiclesRequestID === null ||
                ((formData.selectRegions ? !(formData.selectRegions.length > 0) : true) &&
                  !(addressData.length > 0))
              }
              onClick={() => {
                SummonModal("modal-send-prompt-delivery");
              }}
            >
              {i18next.t("navigation:send")}
            </Button>
          </div>
        </Box>
      </div>

      <SmartModal
        hideButtons
        modalUniqueId={"modal-maps-prompt-delivery"}
        title={i18next.t("form:searchMap")}
        modalInnerComponent={
          <div className="modal-width-medium">
            <MultiForm
              suppressLayout
              suppressSubmit
              formId={"FormPromptDelivery_Maps"}
              inputs={[
                // Address
                {
                  fullWidth: true,
                  width: 100,
                  type: "custom",
                  name: "PlaceAutoCompleteForm",
                  className: "content-place-auto-complete",
                  element: (
                    <PlaceAutoCompleteForm
                      addressId="addressStreet"
                      onChange={(data: IGoogleAddress) => {
                        setMapData({ ...mapData, ...data });
                      }}
                      inputs={[
                        {
                          width: 100,
                          type: "text",
                          name: "addressStreet",
                          defaultValue: mapData?.addressStreet,
                          mapDecode: "route",
                          required: true,
                          label: `${i18next.t("form:address")}`,
                          inputAdornament: { adornament: <PinDropIcon /> },
                        },
                        {
                          width: 20,
                          type: "text",
                          name: "addressStreetNumber",
                          defaultValue: mapData?.addressStreetNumber,
                          mapDecode: "street_number",
                          required: true,
                          label: `${i18next.t("form:civicNumberAbbr")}`,
                          disabled: true,
                        },
                        {
                          width: 20,
                          type: "select_nations",
                          name: "addressStreetCountryID",
                          currentValue: mapData?.addressStreetCountryID ?? "",
                          defaultValue: mapData?.addressStreetCountryID,
                          mapDecode: "country",
                          required: true,
                          label: `${i18next.t("form:countryResidence")}`,
                          disabled: true,
                        },
                        {
                          width: 20,
                          type: "text",
                          name: "addressStreetCAP",
                          defaultValue: mapData?.addressStreetCAP,
                          mapDecode: "postal_code",
                          inputProps: { maxLength: 20 },
                          required: true,
                          label: `${i18next.t("form:postalCode")}`,
                          disabled: true,
                        },
                        {
                          width: 20,
                          type: "text",
                          name: "addressStreetCity",
                          defaultValue: mapData?.addressStreetCity,
                          mapDecode: "locality",
                          required: true,
                          label: `${i18next.t("form:city")}`,
                          disabled: true,
                        },
                        {
                          width: 20,
                          type: "text",
                          name: "addressStreetProv",
                          defaultValue: mapData?.addressStreetProv,
                          maxLength: 5,
                          required: true,
                          mapDecode: "province",
                          label: `${i18next.t("form:provinceAbbr")}`,
                          disabled: true,
                        },
                      ]}
                    />
                  ),
                },
                // Fake map
                {
                  fullWidth: true,
                  width: 100,
                  type: "custom",
                  name: "maps",
                  element: (
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190028.3544266238!2d12.371191385595191!3d41.9102087918475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f6196f9928ebb%3A0xb90f770693656e38!2sRome%2C%20Metropolitan%20City%20of%20Rome%20Capital!5e0!3m2!1sen!2sit!4v1694521291912!5m2!1sen!2sit"
                      height="400"
                      style={{ width: "100%", border: "0" }}
                      loading="lazy"
                      referrerPolicy={"no-referrer-when-downgrade"}
                    ></iframe>
                  ),
                },
                // KM slider
                {
                  width: 100,
                  type: "slider",
                  name: "kmSlider",
                  defaultValue: mapData?.kmSlider || 0,
                  label: `${i18next.t("form:selectRange")}`,
                  marks: marks,
                  min: 0,
                  max: 30,
                  step: 5,
                },
                // Confirm
                {
                  width: 100,
                  type: "custom",
                  name: "save",
                  element: (
                    <div className="display-flex-end gap-small">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          DismissModal("modal-maps-prompt-delivery");
                        }}
                      >
                        {i18next.t("navigation:reject")}
                      </Button>

                      <Button
                        variant="contained"
                        disabled={
                          !(mapData && mapData?.addressStreet && mapData?.kmSlider > 0)
                        }
                        onClick={() => {
                          setAddressData(
                            mapData?.addressStreet +
                              " " +
                              mapData?.addressStreetNumber +
                              ", " +
                              mapData?.addressStreetCAP +
                              ", " +
                              mapData?.addressStreetCity +
                              " (" +
                              mapData?.addressStreetProv +
                              ") | " +
                              mapData?.kmSlider +
                              " Km"
                          );
                          DismissModal("modal-maps-prompt-delivery");
                        }}
                      >
                        {i18next.t("form:confirm")}
                      </Button>
                    </div>
                  ),
                },
              ]}
              onChange={(data: any) => {
                delete data["maps"];
                delete data["PlaceAutoCompleteForm"];
                delete data["save"];

                setMapData({ ...mapData, ...data });
              }}
            />
          </div>
        }
      />

      <SmartModal
        modalUniqueId={"modal-send-prompt-delivery"}
        title={i18next.t("form:sendRequests")}
        labelAcceptButton={`${i18next.t("navigation:send")}`}
        modalInnerComponent={
          <Typography
            dangerouslySetInnerHTML={{
              __html: i18next.t("form:textPromptDelivery").replace(
                "CURRENT_REGIONS",
                formData?.selectRegions && formData?.selectRegions.length > 0
                  ? regions
                      .filter((x: any) =>
                        formData?.selectRegions.some((g: any) => x.regionID === g)
                      )
                      .map((k: any) => k.regionName)
                      .join(", ")
                  : addressData
              ),
            }}
          />
        }
        onAccept={() => {
          const tempDataForm = lodash.cloneDeep(formData);
          const tempDataMaps = lodash.cloneDeep(mapData);

          if (formData?.searchDealer === "Cerca per distanza da un punto") {
            if (driverGroupVehiclesRequestID) {
              tempDataMaps["driverGroupVehiclesRequestID"] =
                +driverGroupVehiclesRequestID;

              delete tempDataMaps["addressStreetNumber"];
              delete tempDataMaps["addressStreetCountryID"];
              delete tempDataMaps["addressStreetCAP"];
              delete tempDataMaps["addressStreetCity"];
              delete tempDataMaps["addressStreetProv"];

              INSERT_Request_Prompt_Delivery(tempDataMaps);
            }
          } else if ("Cerca per regione") {
            delete tempDataForm["space"];
            delete tempDataForm["searchDealer"];

            if (driverGroupVehiclesRequestID) {
              tempDataForm["driverGroupVehiclesRequestID"] =
                +driverGroupVehiclesRequestID;

              INSERT_Request_Prompt_Delivery(tempDataForm);
            }
          }
        }}
        onReject={() => {
          DismissModal("modal-send-prompt-delivery");
        }}
      />

      <LoaderBackdrop loading={loadingRegions || loadingInsert} />
    </div>
  );
};

export default VehiclePromptDeliveryPageSearch;
