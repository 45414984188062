import { useState } from "react";
import i18next from "i18next";

import "./StepConfirmEmail.scss";

import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../Utils/Toastify";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";

import { Link } from "@mui/material";

const StepConfirmEmail = () => {
  /* VARS */
  let accountID = Number(localStorage.getItem("resendAccountID"));

  /* STATE */
  // State for spinning and loading the process
  const [processing, setProcessing] = useState<boolean>(false);

  /* API */
  // API to insert a supplier type
  const resendEmail = (accountID: any) => {
    setProcessing(true);
    ApiService.AccountController.AccountResendEmail(
      accountID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:accountResendEmail"), "success");
        } else if (response.raw.status === 500) {
          ToastMessage(i18next.t("error:REGISTRATION_RESEND_EMAIL_ERROR"), "error");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* RETURN */
  return (
    <div className="StepWelcome">
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleConfirmEmail") ?? "",
          }}
        ></h1>

        <h2
          className="custom-stepper-text"
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:subtitleConfirmEmail") ?? "",
          }}
        ></h2>

        <p className="custom-stepper-text custom-stepper-text-bold stepConfirmEmail-marginTop">
          {i18next.t("navigation:resendConfirmEmail")}
          <Link style={{ cursor: "pointer" }} onClick={() => resendEmail(accountID)}>
            {i18next.t("navigation:resendConfirmEmailLink")}
          </Link>
        </p>
      </div>

      <LoaderBackdrop loading={processing} />
    </div>
  );
};

export default StepConfirmEmail;
