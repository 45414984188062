/* REACT */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./LoadFileTelepassUsage.scss";

/* MODELS */
import { IUser } from "../../../../../../Models/IUser";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* REDUCER */
import { GlobalState } from "../../../../../../Reducers/RootReducer";

/* COMPONENTS */
import CustomStepper from "../../../../../../Components/CustomStepper/CustomStepper";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import ReadFileInfo from "../../../../../../Components/ReadFileInfo/ReadFileInfo";
import StepDashboardImportComplete from "../../../../../../Components/StepDashboardImportComplete/StepDashboardImportComplete";
import StepDashboardLoadTemplate from "../../../../../../Components/StepDashboardLoadTemplate/StepDashboardLoadTemplate";
import StepDashboardMapFileTemplate from "../../../../../../Components/StepDashboardMapFileTemplate/StepDashboardMapFileTemplate";
import { dateIsPast } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const LoadFileTelepassUsage = () => {
  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* NAVIGATE */
  const navigate = useNavigate();

  /* RETURN */
  return (
    <div className="display-flex">
      <div className="load-file-telepass-usage-wrapper">
        <HeaderPageDashboard
          title={i18next.t("navigation:_telepass_page_title")}
          arrowBack
          onBack={() =>
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES
            )
          }
        />

        <div className="stepper-content-wrapper">
          <CustomStepper
            isCustomDashboard
            stepperNodes={[
              {
                element: (
                  <StepDashboardLoadTemplate
                    primaryTitle={i18next.t(
                      "navigation:_telepass_page_upload_file_supplies"
                    )}
                    primarySubTitle={i18next.t(
                      "navigation:_forniture_page_supported_files"
                    )}
                    templateTypeID={2}
                  />
                ),
                stepTitle: i18next.t("navigation:_forniture_page_upload_file"),
                onNext: () => {
                  return true;
                },
              },
              {
                element: <StepDashboardMapFileTemplate templateTypeID={2} />,
                stepTitle: i18next.t(
                  "navigation:_forniture_page_header_stepper2"
                ),
                onNext: () => {
                  return true;
                },
              },
              {
                element: <StepDashboardImportComplete />,
                stepTitle: i18next.t(
                  "navigation:_forniture_page_header_stepper3"
                ),
                onNext: () => {
                  return true;
                },
              },
            ]}
            onFinish={() => {}}
          />
        </div>

        <div>
          <ReadFileInfo templateTypeID={2} />
        </div>
      </div>
    </div>
  );
};

export default LoadFileTelepassUsage;
