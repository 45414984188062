import { useEffect } from "react";

export interface ICustomCKEditorFourProps {
  formSelector: string;
  formData?: any;
  onChange: (data: any) => void;
  isReadOnly?: boolean;
}
const CustomCKEditorFour = (props: ICustomCKEditorFourProps) => {
  useEffect(() => {
    (window as any).CKEDITOR.editorConfig = (config: any) => {
      config.language = window.localStorage.getItem("language") ?? "ita";

      config.height = 200;

      config.extraPlugins = "image2,autogrow";

      config.allowedContent = true;

      config.autoGrow_minHeight = 200;
    };

    var editorElement = (window as any).CKEDITOR.document.getById(props.formSelector);

    editorElement.setHtml(props.formData);
    (window as any).CKEDITOR.document.getById(props.formSelector);

    let editor = (window as any).CKEDITOR.replace(props.formSelector, {
      readOnly: props.isReadOnly,
    });

    if (editor) {
      editor.on("change", (evt: any) => {
        props.onChange(evt.editor.getData());
      });
    }
  }, [props.formData]);

  return <div id={props.formSelector}></div>;
};

export default CustomCKEditorFour;
