import { ArrowBack } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  CircularProgress,
  ToggleButton,
} from "@mui/material";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AccordionContent from "../../../../Components/AccordionContent/AccordionContent";
import FuelCardDashboardEditor from "../../../../Components/FuelCardDashboardEditor/FuelCardDashboardEditor";
import HeaderPageDashboard from "../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import InsertAssuranceModal from "../../../../Components/InsertAssuranceModal/InsertAssuranceModal";
import LoaderBackdrop from "../../../../Components/LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
} from "../../../../Components/MultiForm/MultiForm";
import {
  dateIsPast,
  padBlock,
} from "../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../Components/SmartModal/SmartModal";
import StepAmountDeadelines from "../../../../Components/StepAmountDeadelines/StepAmountDeadelines";
import FormAssignedDriver from "../../../../Components/StepAssignedDrivers/FormAssignedDriver";
import StepAttachmentsTickets from "../../../../Components/StepAttachmentsTickets/StepAttachmentsTickets";
import StepBreachData from "../../../../Components/StepBreachData/StepBreachData";
import StepSanctionData from "../../../../Components/StepSanctionData/StepSanctionData";
import FormAssignement from "../../../../Components/StepVehicles/TabAssignment/FormAssignment";
import FormContract from "../../../../Components/StepVehicles/TabContract/FormContract";
import FormServices from "../../../../Components/StepVehicles/TabServices/FormServices";
import FormTelepass from "../../../../Components/StepVehicles/TabTelepass/FormTelepass";
import AppRoutes from "../../../../Costants/AppRoutes";
import { ICompanyGet } from "../../../../Models/ICompany";
import { IDeadlinesByAccount } from "../../../../Models/IDeadlinesByAccount";
import { IDeadlinesCategory } from "../../../../Models/IDeadlinesCategory";
import { IDeadlinesType } from "../../../../Models/IDeadlinesType";
import { IDriverGet } from "../../../../Models/IDriver";
import {
  IFuelCardsGet,
  IFuelCardsGetType,
} from "../../../../Models/IFuelCards";
import { IStepperTickets } from "../../../../Models/IStepperTickets";
import { ISupplierGetSideList } from "../../../../Models/ISupplier";
import { ITicketInsert } from "../../../../Models/ITicketInsert";
import { ITicketInsertDetail } from "../../../../Models/ITicketInsertDetail";
import { ITicketInsertPayment } from "../../../../Models/ITicketInsertPayment";
import { ITicketInsertPaymentResponse } from "../../../../Models/ITicketInsertPaymentResponse";
import { ITicketInsertResponse } from "../../../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../../../Models/ITicketStatus";
import { ITicketUpdate } from "../../../../Models/ITicketUpdate";
import { ITicketUpdateDetail } from "../../../../Models/ITicketUpdateDetail";
import { ITicketUpdatePayment } from "../../../../Models/ITicketUpdatePayment";
import { IUser } from "../../../../Models/IUser";
import { IVehicleDataByAccountID } from "../../../../Models/IVehicleDataByAccountID";
import { IVehicleDataGet } from "../../../../Models/IVehicles";
import { GlobalState } from "../../../../Reducers/RootReducer";
import { TicketsAction } from "../../../../Reducers/Tickets/TicketsAction";
import ApiService from "../../../../Services/ApiService";
import { IAPIResponse } from "../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../Utils/Toastify";
import { formatPlate } from "../VehiclesPage/VehiclesPage";
import {
  Form__Bollo,
  Form__Revisione,
  Form__SuperBollo,
  Form__Tagliando,
} from "../VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import "./ExpiryCalendarPage.scss";
import { JSONPrint } from "../../../../Utils/Decoder";
import { userRoleFits } from "../../../../Utils/Session";
import ApiEndPoints from "../../../../Costants/ApiEndPoints";
import { IVehicleServiceGet } from "../../../../Models/IVehicleService";

export const InitExpiredDeadline = (deadlineTypeID: number, el: any) => {
  switch (deadlineTypeID) {
    case 1:
      return i18next.t("navigation:_calendar_page_insurance");
    case 2:
      return i18next.t("navigation:_calendar_page_leasing");
    case 3:
      return i18next.t("navigation:_calendar_page_financing");
    case 4:
      return i18next.t("navigation:_calendar_page_revision");
    case 5:
      return i18next.t("navigation:_calendar_page_license");
    case 6:
      return i18next.t("navigation:_calendar_page_fuel_card");
    case 7:
      return i18next.t("navigation:_calendar_page_short_term_contract");
    case 8:
      return i18next.t("navigation:_calendar_page_long_term_contract");
    case 9:
      return i18next.t("navigation:_calendar_page_arrival_vehicle_date");
    case 10:
      return i18next.t("navigation:_calendar_page_maintenance");
    case 11:
      return i18next.t("navigation:_calendar_page_reduced_payment");
    case 12:
      return i18next.t("navigation:_calendar_page_full_payment");
    case 13:
      return i18next.t("navigation:_calendar_page_car_service");
    case 14:
      return "Custom";
    case 15:
      return i18next.t("navigation:_calendar_page_assignment_fuel_card");
    case 16:
      return i18next.t("navigation:_calendar_page_stamp");
    case 17:
      return i18next.t("navigation:_calendar_page_vehicle_assignment");
    case 18:
      return i18next.t("navigation:_calendar_page_telepass");
    case 19:
      return i18next.t("navigation:_calendar_page_vehicle_service");
    case 20:
      return i18next.t("navigation:_calendar_page_vehicle_service_payment");
    case 21:
      return i18next.t("navigation:_calendar_page_superstamp");
    default:
      return " ";
  }
};

const localizer = momentLocalizer(moment);

const ExpiryCalendarPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const [deleteDeadlineId, setDeleteDeadlineId] = useState<number>(0);

  const [filtering, setFiltering] = useState<boolean>(false);
  const [advancedFiltering, setAdvancedFiltering] = useState<boolean>(false);
  const [filteringList, setFilteringList] = useState<any[]>([]);
  const [advancedFilteringList, setAdvancedFilteringList] = useState<any[]>([]);
  const [isNewAssurance, setIsNewAssurance] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [culture, setCulture] = useState<any>();
  const [defaultDate, setDefaultDate] = useState<any>();
  const [messages, setMessages] = useState<any>({});
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [expired, setExpired] = useState<any[]>([]);
  const [currentYearCalendar, setCurrentYearCalendar] = useState<string>(
    new Date().getFullYear().toString()
  );

  const [vehicleData, setVehicleData] = useState<IVehicleDataByAccountID[]>([]);
  const [currentYearScad, setCurrentYearScad] = useState<string>(
    new Date().getFullYear().toString()
  );

  const [selectedMonth, setSelectedMonth] = useState<any>({ x: -1, y: -1 });
  const [deadlineCategory, setDeadlineCategory] = useState<
    IDeadlinesCategory[]
  >([]);
  const [loadingDeadlineCategory, setLoadingDeadlineCategory] =
    useState<boolean>(true);
  const [deadlineType, setDeadlineType] = useState<IDeadlinesType[]>([]);
  const [loadingDeadlineType, setLoadingDeadlineType] =
    useState<boolean>(false);
  const [deadlineByAccount, setDeadlineByAccount] = useState<
    IDeadlinesByAccount[]
  >([]);
  const [deadlineByAccountScad, setDeadlineByAccountScad] = useState<
    IDeadlinesByAccount[]
  >([]);
  const [loadingDeadlineByAccount, setLoadingDeadlineByAccount] =
    useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [deadlineExpiredByAccount, setDeadlineExpiredByAccount] = useState<
    IDeadlinesByAccount[]
  >([]);
  const [loadingDeadlineExpiredByAccount, setLoadingDeadlineExpiredByAccount] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingSupplierFuelCards, setLoadingSupplierFuelCards] =
    useState<boolean>(false);
  const [loadingFuelCardsType, setLoadingFuelCardsType] =
    useState<boolean>(false);
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(false);
  const [addExpire, setAddExpire] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [vehicleSelected, setVehicleSelected] = useState<any>();

  const [supplierFuelCards, setSupplierFuelCards] = useState<
    ISupplierGetSideList[]
  >([]);
  const [fuelCardsType, setFuelCardsType] = useState<IFuelCardsGetType[]>([]);
  const [drivers, setDrivers] = useState<IDriverGet[]>([]);
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );
  const ticketInsertPaymentResponse:
    | ITicketInsertPaymentResponse[]
    | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertPaymentResponse
  );
  const ticketStatus: ITicketStatus | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketStatus
  );
  const brokerGrants: any[] | undefined = useSelector(
    (state: GlobalState) => state.user.brokerGrants
  );
  const [vehicleServices, setVehicleServices] = useState<any[]>([])

  const [params] = useSearchParams();
  const preloadDriverId: string | null = params.get("preload");
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [currentDriver, setCurrentDriver] = useState<any>(null);
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false);
  const [state, setState] = useState<any>({
    deadlineTypeID: "",
    vehicleID: "",
    driverID: "",
  });

  const [loadingDeadlineByAccountBrokerlUser, setLoadingDeadlineByAccountBrokerlUser] = useState<boolean>(false);
  const [loadingDeadlineByAccountNormalUser, setLoadingDeadlineByAccountNormalUser] = useState<boolean>(false);

  /* API */
  // Load drivers list
  const loadDriverList = () => {
    if (loggedUser) {
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverList(response.payload);

            if (preloadDriverId) {
              selectDriver(+preloadDriverId, response.payload);
            }
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadDriverList();
  }, []);

  const selectDriver = (driverID: number, thisList?: IDriverGet[]) => {
    let driver_: IDriverGet | undefined = (thisList ?? driverList).find(
      (x: IDriverGet) => x.driverInfo?.driverID === driverID
    );
    if (driver_) {
      let parsedItem = {
        ...driver_["driverInfo"],
        ...{ driverCostCenter: driver_["driverCostCenter"] },
        ...{ deadlineDriver: driver_["deadlineDriver"] },
        ...{
          licenceTypeList: driver_["driverInfo"]["licenceTypeList"].map(
            (x: any) => x.licenceTypeID
          ),
        },
      };
      setCurrentDriver(parsedItem);
      SummonModal("driver-page-modal");
    }
  };

  const loadVehicleServices = (
    vehicleID: number,
    vehicleServiceID?: number
  ) => {
      ApiService.VehicleServiceController.VehicleServiceGet(
        vehicleServiceID,
        vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
              setVehicleServices(
                response.payload.filter(
                  (x: IVehicleServiceGet) => x.stored === false
                )
              );
          } else {
            ToastMessage(response.error, "error");
          }
      }
      );
    }



  const TicketAlredyPaid = (id: number) => {
    switch (id) {
      case 1:
      case 2:
      case 4:
        return false;
      case 3:
      case 5:
        return true;
      default:
        return false;
    }
  };

  const loadVehicleDataByAccountID = () => {
    ApiService.VehicleDataController.VehicleDataGetByAccountID(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehicleData(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const UpdateMultipleTicketDetail = (body: any) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateMultipleDetailTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:TicketDetailUpdateCorrectly"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicketDetail = (body: ITicketUpdateDetail) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateDetailTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              TicketsAction.setTicketInsertDetailResponse(response.payload)
            );
            ToastMessage(
              i18next.t("message:TicketDetailUpdateCorrectly"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const InsertTicketPayment = (body: ITicketInsertPayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertTicketPayment(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              TicketsAction.setTicketInsertPaymentResponse(response.payload)
            );
            ToastMessage(
              i18next.t("message:TicketPaymentCorrectly"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicketPayment = (body: ITicketUpdatePayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicketPayment(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              TicketsAction.setTicketInsertPaymentResponse(response.payload)
            );
            ToastMessage(
              i18next.t("message:TicketPaymentCorrectly"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicket = (
    body: ITicketUpdate,
    bodyUpdateDetail?: any,
    bodyUpdateMultipleDetail?: any
  ) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (
              body.ticketTypeID === 1 ||
              body.ticketTypeID === 5 ||
              body.ticketTypeID === 6
            ) {
              let tmpBody: any = { ticketDetails: bodyUpdateMultipleDetail };
              UpdateMultipleTicketDetail(tmpBody);
            } else {
              UpdateTicketDetail(bodyUpdateDetail);
            }
            if (ticketStatus?.alreadyPaid) {
              if (ticketInsertPaymentResponse) {
                let tmpPayment: ITicketUpdatePayment = {
                  ticketPaymentID:
                    ticketInsertPaymentResponse![0].ticketpaymentID,
                  ticketPaymentDT:
                    stepperTicket?.stepAmountDeadlines!.ticketPaymentDT!,
                  paidAmount: stepperTicket?.stepAmountDeadlines!.paidAmount!,
                  postalCharge:
                    stepperTicket?.stepAmountDeadlines!.postalCharge!,
                };
                UpdateTicketPayment(tmpPayment);
              } else {
                for (let i = 0; i < stepperTicket!.stepPayment!.length; i++) {
                  let tmpPayment: ITicketInsertPayment = {
                    ticketID: ticketInsertResponse![0].ticketID!,
                    ticketPaymentDT:
                      stepperTicket?.stepPayment![i].ticketPaymentDT!,
                    paidAmount: stepperTicket?.stepPayment![i].paidAmount!,
                    postalCharge: stepperTicket?.stepPayment![i].postalCharge!,
                  };
                  InsertTicketPayment(tmpPayment);
                }
              }
            }
            dispatch(TicketsAction.setTicketInsertResponse(response.payload));
            ToastMessage(i18next.t("message:TicketUpdateCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadTicketsPayment = async (ticketID?: number) => {
    let response: IAPIResponse =
      ApiService.TicketController.GetTicketPaymentList(ticketID);
    let tmp = [];
    if (response.error === null) {
      if (response.payload.length > 0) {
        tmp = response.payload;
      }
    } else {
      ToastMessage(response.error, "error");
    }
    return tmp;
  };

  const loadTickets = (ticketID?: number) => {
    ApiService.TicketController.GetTicketList(
      ticketID,
      null,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp: any[] = [];
          if (ticketID) {
            let tmpPayment: any[] = await loadTicketsPayment(ticketID);
            dispatch(
              TicketsAction.setTicketInsertResponse([
                {
                  ticketID: response.payload[0].ticket.ticketID,
                  ticketIssuingInstitutionName:
                    response.payload[0].ticket.ticketIssuingInstitutionName,
                },
              ])
            );
            let tmpSanction: ITicketInsert = {};
            tmpSanction.ticketTypeID = response.payload[0].ticket.ticketTypeID;
            tmpSanction.ticketNumber = response.payload[0].ticket.ticketNumber;
            tmpSanction.ticketIssuingInstitutionID =
              response.payload[0].ticket.ticketIssuingInstitutionID;
            tmpSanction.ticketDeliveryTypeID =
              response.payload[0].ticket.ticketDeliveryTypeID;
            tmpSanction.ticketPickUpDT =
              response.payload[0].ticket.ticketPickUpDT;
            tmpSanction.ticketCADDT = response.payload[0].ticket.ticketCADDT;
            tmpSanction.ticketNotificationDT =
              response.payload[0].ticket.ticketNotificationDT;
            dispatch(
              TicketsAction.setTicketDriver({
                driverID: response.payload[0]?.ticketDetail[0]?.driverID,
                driverDisplayName:
                  response.payload[0]?.ticketDetail[0]?.driverDisplayName,
              })
            );
            dispatch(
              TicketsAction.setTicketStatus({
                alreadyDisputed: response.payload[0].ticket.ticketContested,
                alreadyPaid: TicketAlredyPaid(
                  response.payload[0].ticket.ticketStatusID
                ),
              })
            );
            let tmp: any = [];
            for (let i = 0; i < response.payload[0].ticketDetail.length; i++) {
              let tmpBreach: ITicketInsertDetail = {};
              tmpBreach.violationDT =
                response.payload[0].ticketDetail[i].violationDT;
              tmpBreach.vehicleID =
                response.payload[0].ticketDetail[i].vehicleID;
              tmpBreach.vehicleLicensePlate =
                response.payload[0].ticketDetail[i].vehicleLicensePlate;
              tmpBreach.driverID = response.payload[0].ticketDetail[i].driverID;
              tmpBreach.amount = response.payload[0].ticketDetail[i].amount;
              tmpBreach.ticketDetailID =
                response.payload[0].ticketDetail[i].ticketDetailID;
              tmpBreach.ticketArticleID =
                response.payload[0].ticketDetail[i].ticketArticleID;
              tmpBreach.isEdit = true;
              tmp.push(tmpBreach);
            }
            let tmpAmountDeadlines: ITicketUpdate = {};
            tmpAmountDeadlines.ticketReducedExpirationDT =
              response.payload[0].ticket.ticketReducedExpirationDT;
            tmpAmountDeadlines.ticketReducedAmount =
              response.payload[0].ticket.ticketReducedAmount;
            tmpAmountDeadlines.ticketReducedTaxAmount =
              response.payload[0].ticket.ticketReducedTaxAmount;
            tmpAmountDeadlines.ticketReducedTotalAmount =
              response.payload[0].ticket.ticketReducedTotalAmount;
            tmpAmountDeadlines.ticketExpirationDT =
              response.payload[0].ticket.ticketExpirationDT;
            tmpAmountDeadlines.ticketAmount =
              response.payload[0].ticket.ticketAmount;
            tmpAmountDeadlines.ticketTaxAmount =
              response.payload[0].ticket.ticketTaxAmount;
            tmpAmountDeadlines.ticketTotalAmount =
              response.payload[0].ticket.ticketTotalAmount;
            dispatch(
              TicketsAction.setStepperTickets({
                ...stepperTicket!,
                stepAmountDeadlines: tmpAmountDeadlines,
                stepBreach: tmp,
                stepSanction: tmpSanction,
                stepPayment: tmpPayment,
              })
            );
          }
        } else {
          ToastMessage(response.error, "error");
        }
        if (ticketID) {
          SummonModal("editBreach");
        }
      }
    );
  };

  const DeleteTicket = (ticketID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.DeleteTicket(
        ticketID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:ticketDeleted"), "success");
            DeadlineExpiredByAccount();
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadDrivers = () => {
    if (loggedUser) {
      setLoadingDrivers(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDrivers(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDrivers(false);
        }
      );
    }
  };
  const loadSupplierFuelCards = () => {
    if (loggedUser) {
      setLoadingSupplierFuelCards(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "FUEL_CARD",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierFuelCards(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierFuelCards(false);
        }
      );
    }
  };
  const loadFuelCardsType = () => {
    if (loggedUser) {
      setLoadingFuelCardsType(true);
      ApiService.FuelCardsController.FuelCardsGetType(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelCardsType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelCardsType(false);
        }
      );
    }
  };

  useEffect(() => {
    loadDrivers();
    loadFuelCardsType();
    loadSupplierFuelCards();
    loadVehicleDataByAccountID();
  }, []);

  useEffect(() => {
    setLoadingCalendar(true);
    setTimeout(() => {
      setLoadingCalendar(false);
    }, 50);
  }, [defaultDate]);

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push({
      width: 100,
      type: "select",
      name: "deadlineTypeID",
      label: i18next.t("form:vehciledata_forms_addpay_scadenza_expr") ?? "",
      upperLabel:
        i18next.t("form:vehciledata_forms_addpay_scadenza_expr") ?? "",
      options: deadlineType
        .map((el: IDeadlinesType) => {
          return {
            key: el.deadlineTypeID,
            text: el.deadlineTypeName,
          };
        })
        .filter(
          (x: any) => x.key !== 5 && x.key !== 9 && x.key !== 14 && x.key !== 10
        ),
      required: true,
    });

    if (
      state.deadlineTypeID === 1 ||
      state.deadlineTypeID === 2 ||
      state.deadlineTypeID === 3 ||
      state.deadlineTypeID === 4 ||
      state.deadlineTypeID === 7 ||
      state.deadlineTypeID === 8 ||
      state.deadlineTypeID === 6 ||
      state.deadlineTypeID === 13 ||
      state.deadlineTypeID === 15 ||
      state.deadlineTypeID === 16 ||
      state.deadlineTypeID === 17 ||
      state.deadlineTypeID === 18 ||
      state.deadlineTypeID === 19 ||
      state.deadlineTypeID === 21
    ) {
      multiformInputs.push({
        width: 100,
        type: "select",
        name: "vehicleID",
        label: "Veicolo",
        upperLabel: "Veicolo",
        options: vehicleData.map((x: IVehicleDataByAccountID) => {
          return {
            key: x.vehicleID,
            text: formatPlate(x.vehicleLicensePlate),
          };
        }),
        required:
          state.deadlineTypeID !== 6 &&
          state.deadlineTypeID !== 18 &&
          state.deadlineTypeID !== 15,
      });
    }

    if(state.deadlineTypeID === 19 && state.vehicleID) {
      multiformInputs.push({
        width: 100,
        type: "select",
        name: "calendarServiceID",
        label: "Servizio",
        upperLabel: "Servizio",
        options: vehicleServices.map((x: IVehicleServiceGet) => {
          return {
            key: x.vehicleServiceID,
            text: x.vehicleServiceName,
            type: x.paymentRecurrenceName
          };
        }).filter((el: any) => el.type),
      });
    }



    return multiformInputs;
  };

  const lang = {
    en: null,
    "en-GB": null,
    ita: {
      week: "Settimana",
      day: "Giorno",
      month: "Mese",
      previous: "Precedente",
      next: "Successivo",
      today: "Oggi",
      agenda: "Agenda",
      showMore: function showMore(total: number) {
        return "+" + total + " altro";
      },
    },
  };

  const GetDeadlineCategory = () => {
    if (loggedUser) {
      setLoadingDeadlineCategory(true);
      ApiService.CalendarController.DeadlineCategory(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDeadlineCategory(response.payload);
            InitFilteringlist(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineCategory(false);
        }
      );
    }
  };

  const GetDeadlineType = () => {
    if (loggedUser) {
      setLoadingDeadlineType(true);
      ApiService.CalendarController.DeadlineType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDeadlineType(response.payload);
            InitAdvancedFilteringlist(response.payload);
            GetDeadlineByAccount(
              new Date().getFullYear().toString(),
              response.payload,
              false
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineType(false);
        }
      );
    }
  };

  const DeadlineExpiredByAccount = () => {
    if (loggedUser) {
      setLoadingDeadlineExpiredByAccount(true);
      let accountIsBroker: boolean = userRoleFits(["Broker"]);
      let url: string = userRoleFits(["Broker"]) ? ApiEndPoints.DEADLINE_BY_ACCOUNT_BROKER_GET : ApiEndPoints.DEADLINE_EXPIRED_BY_ACCOUNT_GET;
      ApiService.CalendarController.DeadlineExpiredByAccount(
        url,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let tmpDeadlines: IDeadlinesByAccount[] = [];
            if(accountIsBroker){
              for (let index = 0; index < response.payload.length; index++) {
                let tmp: any = Object.entries(response.payload[index].deadlineCalendar);
                tmp = tmp.map((el: any) => {
                  return el[1];
                });
                for (let i = 0; i < tmp.length; i++) {
                  if (tmp[i].length > 0) {
                    for (let j = 0; j < tmp[i].length; j++) {
                      let ddl = {
                        ...tmp[i][j],
                        brokerInspectingAccountId: response.payload[index].userAccountID
                      };
                      tmpDeadlines.push(ddl);
                    }
                  }
                }
                
              }
            }else{
              let tmp: any = Object.entries(response.payload);
              tmp = tmp.map((el: any) => {
                return el[1];
              });
              for (let i = 0; i < tmp.length; i++) {
                if (tmp[i].length > 0) {
                  for (let j = 0; j < tmp[i].length; j++) {
                    tmpDeadlines.push(tmp[i][j]);
                  }
                }
              }
            }
            setDeadlineExpiredByAccount(tmpDeadlines);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineExpiredByAccount(false);
        }
      );
    }
  };

  const GetDeadlineByAccountBrokerlUser = (
    selectedYear: string,
    deadlineType: IDeadlinesType[],
    isScad: boolean
  ) => {
    if (loggedUser) {
      setLoadingDeadlineByAccountBrokerlUser(true);
      ApiService.CalendarController.DeadlineByBroker(
        selectedYear,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let joinedResult = response.payload;
            let tmpDeadlines: IDeadlinesByAccount[] = [];

            for (let i = 0; i < joinedResult.length; i++) {
              let mapForUser = obtainDeadlineVectorMap(
                joinedResult[i].deadlineCalendar,
                joinedResult[i].userAccountID
              );
              tmpDeadlines = tmpDeadlines.concat(mapForUser);
            }

            if (isScad) {
              setDeadlineByAccountScad(tmpDeadlines);
            } else {
              if (deadlineByAccountScad.length === 0) {
                setDeadlineByAccountScad(tmpDeadlines);
              }
              setEvents(InitEvents(tmpDeadlines, deadlineType));
              setDeadlineByAccount(tmpDeadlines);
            }

            //aggregateDeadlineResult(joinedResult, deadlineType, isScad);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineByAccountBrokerlUser(false);
        }
      );
    }
  };

  const GetDeadlineByAccount = (
    selectedYear: string,
    deadlineType: IDeadlinesType[],
    isScad: boolean
  ) => {
    // you are a broker
    if (brokerGrants && brokerGrants.length > 0) {
      GetDeadlineByAccountBrokerlUser(selectedYear, deadlineType, isScad);
    } else {
      GetDeadlineByAccountNormalUser(selectedYear, deadlineType, isScad);
    }
  };

  const obtainDeadlineVectorMap = (
    object: any,
    inspectedUserIdFromBroker?: number
  ) => {
    let tmp: any = Object.entries(object);
    let tmpDeadlines: IDeadlinesByAccount[] = [];
    tmp = tmp.map((el: any) => {
      return el[1];
    });
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].length > 0) {
        for (let j = 0; j < tmp[i].length; j++) {
          let ddl = tmp[i][j];
          if (inspectedUserIdFromBroker) {
            ddl = {
              ...ddl,
              brokerInspectingAccountId: inspectedUserIdFromBroker,
            };
          }
          tmpDeadlines.push(ddl);
        }
      }
    }
    return tmpDeadlines;
  };

  const aggregateDeadlineResult = (
    object: any,
    deadlineType: IDeadlinesType[],
    isScad: boolean
  ) => {
    let tmpDeadlines: IDeadlinesByAccount[] = obtainDeadlineVectorMap(object);
    if (isScad) {
      setDeadlineByAccountScad(tmpDeadlines);
    } else {
      if (deadlineByAccountScad.length === 0) {
        setDeadlineByAccountScad(tmpDeadlines);
      }
      setEvents(InitEvents(tmpDeadlines, deadlineType));
      setDeadlineByAccount(tmpDeadlines);
    }
  };

  const GetDeadlineByAccountNormalUser = (
    selectedYear: string,
    deadlineType: IDeadlinesType[],
    isScad: boolean
  ) => {
    if (loggedUser) {
      setLoadingDeadlineByAccountNormalUser(true);
      ApiService.CalendarController.DeadlineByAccount(
        selectedYear,
        (response: IAPIResponse) => {
          if (response.error === null) {
            aggregateDeadlineResult(response.payload, deadlineType, isScad);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDeadlineByAccountNormalUser(false);
        }
      );
    }
  };

  const DeleteDeadline = (deadlineID: number) => {
    setDeleteDeadlineId(deadlineID);
    SummonModal("delete-deadline-id");
  };

  const DeleteDeadlineProceed = () => {
    if (loggedUser && deleteDeadlineId) {
      setProcessing(true);
      ApiService.CalendarController.DeleteDeadlineExpiredByAccount(
        deleteDeadlineId,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:deadlineSuccessfullDeleted"),
              "success"
            );
            DeadlineExpiredByAccount();
            DismissModal("delete-deadline-id");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadCardsList = (fuelCardID: number, driverID: number, el: any) => {
    if (loggedUser) {
      ApiService.FuelCardsController.FuelCardsGet(
        fuelCardID,
        driverID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setVehicleSelected(response.payload[0]);
            SummonModal(SelectModal(el.deadlineTypeID));
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const InitEvents = (
    data: IDeadlinesByAccount[],
    deadlineType: IDeadlinesType[]
  ) => {
    let tmpEvents = [];
    tmpEvents = data.map((el: IDeadlinesByAccount) => {
      return {
        start: new Date(el.deadlineExpirationDT),
        end: new Date(el.deadlineExpirationDT),
        title:
          (el.deadlineTypeID === 14
            ? el?.deadlineDescription
            : deadlineType.find(
                (x: IDeadlinesType) => el.deadlineTypeID === x.deadlineTypeID
              )?.deadlineTypeName) +
          (el?.brandDescription ? " - " + el?.brandDescription + " - " : "") +
          (el?.fittingDescription ? el?.fittingDescription + " - " : "") +
          (el.vehicleLicensePlate ? formatPlate(el.vehicleLicensePlate) : "") +
          (el.driverDisplayName ? " - " + el.driverDisplayName : ""),
        allDay: true,
        idDeadline: el.deadlineCategoryID,
        vehicleID: el.vehicleID,
        driverID: el.driverID,
        vehicleOrderID: el.vehicleOrderID,
        brokerInspectingAccountId: el.brokerInspectingAccountId,
      };
    });
    let tmpExpired = tmpEvents.filter(
      (el: any) =>
        el.start.getDate() === new Date().getDate() &&
        el.start.getMonth() === new Date().getMonth()
    );
    setExpired(tmpExpired);
    return tmpEvents;
  };

  const InitFilteringlist = (data: IDeadlinesCategory[]) => {
    let tmp = data.map((el: IDeadlinesCategory) => {
      return { key: el.deadlineCategoryID, filter: false };
    });
    setFilteringList(tmp);
  };

  const InitAdvancedFilteringlist = (data: IDeadlinesType[]) => {
    let tmp = data.map((el: IDeadlinesType) => {
      return { key: el.deadlineTypeID, filter: false };
    });
    setAdvancedFilteringList(tmp);
  };

  useEffect(() => {
    GetDeadlineType();
    GetDeadlineCategory();
    DeadlineExpiredByAccount();
    let tmpCulture = localStorage.getItem("language");
    if (tmpCulture === "ita") {
      setCulture("it");
      setMessages(lang.ita);
    } else if (tmpCulture === "eng") {
      setCulture("en");
      setMessages(lang.en);
    }
    setDefaultDate(new Date().toISOString());
    setSelectedDay(new Date());
  }, []);

  const columnsDef = [
    {
      headerName: culture === "it" ? "GEN" : "JAN",
    },
    {
      headerName: culture === "it" ? "FEB" : "FEB",
    },
    {
      headerName: culture === "it" ? "MAR" : "MAR",
    },
    {
      headerName: culture === "it" ? "APR" : "APR",
    },
    {
      headerName: culture === "it" ? "MAG" : "MAY",
    },
    {
      headerName: culture === "it" ? "GIU" : "JUN",
    },
    {
      headerName: culture === "it" ? "LUG" : "JUN",
    },
    {
      headerName: culture === "it" ? "AGO" : "AUG",
    },
    {
      headerName: culture === "it" ? "SET" : "SEP",
    },
    {
      headerName: culture === "it" ? "OTT" : "OCT",
    },
    {
      headerName: culture === "it" ? "NOV" : "NOV",
    },
    {
      headerName: culture === "it" ? "DIC" : "DEC",
    },
  ];

  const InitResumeDeadline = (monthNumber: string) => {
    let tmp = [...deadlineByAccountScad];
    let tmpScad: any = {
      length: 0,
      color: "#7de97d",
    };
    tmp = tmp.filter(
      (x: IDeadlinesByAccount) =>
        (new Date(x.deadlineExpirationDT).getMonth() + 1).toString() ===
        monthNumber
    );
    if (tmp.length > 0) {
      if (
        new Date(tmp[0].deadlineExpirationDT).getFullYear() ===
        new Date().getFullYear()
      ) {
        if (
          new Date(tmp[0].deadlineExpirationDT).getMonth() >
          new Date().getMonth()
        ) {
          if (
            new Date(tmp[0].deadlineExpirationDT).getMonth() >
            new Date().getMonth() + 5
          ) {
            tmpScad = { length: tmp.length, color: "#7de97d" };
          } else if (
            new Date(tmp[0].deadlineExpirationDT).getMonth() <
            new Date().getMonth() + 3
          ) {
            tmpScad = {
              length: tmp.length,
              color: tmp.length > 0 ? "#ffcb6a" : "#7de97d",
            };
          } else if (
            new Date(tmp[0].deadlineExpirationDT).getMonth() <
            new Date().getMonth() + 5
          ) {
            tmpScad = {
              length: tmp.length,
              color: tmp.length > 0 ? "##ffff74" : "#7de97d",
            };
          }
        } else {
          tmpScad = {
            length: tmp.length,
            color: tmp.length > 0 ? "#ff6a6a" : "#7de97d",
          };
        }
      } else {
        if (
          new Date(tmp[0].deadlineExpirationDT).getFullYear() >
          new Date().getFullYear()
        ) {
          tmpScad = { length: tmp.length, color: "#7de97d" };
        } else {
          tmpScad = {
            length: tmp.length,
            color: tmp.length > 0 ? "#ff6a6a" : "#7de97d",
          };
        }
      }
    } else {
      tmpScad = {
        length: tmp.length,
        color: "#7de97d",
      };
    }

    return tmpScad;
  };

  const rowsDef: any[] = [
    {
      row: [
        currentYearScad,
        InitResumeDeadline("1"),
        InitResumeDeadline("2"),
        InitResumeDeadline("3"),
        InitResumeDeadline("4"),
        InitResumeDeadline("5"),
        InitResumeDeadline("6"),
        InitResumeDeadline("7"),
        InitResumeDeadline("8"),
        InitResumeDeadline("9"),
        InitResumeDeadline("10"),
        InitResumeDeadline("11"),
        InitResumeDeadline("12"),
        (+currentYearScad + 1).toString(),
      ],
    },
  ];

  const customClassname = (item: any) => {
    if (item.idDeadline === 1) {
      return "amministrative-menu";
    } else if (item.idDeadline === 2) {
      return "gestionali-menu";
    } else if (item.idDeadline === 3) {
      return "multe-menu";
    } else if (item.idDeadline === 4) {
      return "altre-scadenze-menu";
    } else {
      return "";
    }
  };

  const DayDeadlines = (item: any[]) => {
    let tmpDeadlines = item.map((el: any, i: number) => {
      return (
        <List key={i}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CircleIcon className={customClassname(el)} />
              </ListItemIcon>
              <div className="expiri-list-wrap-item">
                <div>{el.title}</div>
              </div>
            </ListItemButton>
          </ListItem>
        </List>
      );
    });
    return tmpDeadlines;
  };

  const SetDeadlineType = () => {
    let tmpdeadlineType = [...deadlineType];
    let tmpDeadlinesType = [];
    for (let i = 0; i < filteringList.length; i++) {
      for (let j = 0; j < tmpdeadlineType.length; j++) {
        if (
          tmpdeadlineType[j].deadlineCategoryID === filteringList[i].key &&
          filteringList[i].filter
        ) {
          tmpDeadlinesType.push(tmpdeadlineType[j]);
        }
      }
    }
    return tmpDeadlinesType;
  };

  const customDayPropGetter = (date: any) => {
    if (
      date.getDate() === selectedDay.getDate() &&
      date.getMonth() === selectedDay.getMonth()
    )
      return {
        style: {
          border: "solid 1px #4289fc",
        },
      };
    else return {};
  };

  useEffect(() => {
    let tmp = [...deadlineByAccount];
    if (filtering) {
      for (let i = 0; i < filteringList.length; i++) {
        if (!filteringList[i].filter) {
          tmp = tmp.filter(
            (el: IDeadlinesByAccount) =>
              el.deadlineCategoryID !== filteringList[i].key
          );
        }
      }
    }
    setEvents(InitEvents(tmp, deadlineType));
  }, [filteringList]);

  useEffect(() => {
    let tmp = [...deadlineByAccount];
    if (advancedFiltering) {
      for (let i = 0; i < advancedFilteringList.length; i++) {
        if (!advancedFilteringList[i].filter) {
          tmp = tmp.filter(
            (el: IDeadlinesByAccount) =>
              el.deadlineTypeID !== advancedFilteringList[i].key
          );
        }
      }
      if(advancedFilteringList !== undefined && advancedFilteringList.filter((x: any) => x.filter).length === 0){
        let tmp = [...deadlineByAccount];
        for (let i = 0; i < filteringList.length; i++) {
          if (!filteringList[i].filter) {
            tmp = tmp.filter(
              (el: IDeadlinesByAccount) =>
                el.deadlineCategoryID !== filteringList[i].key
            );
          }
        }
        setEvents(InitEvents(tmp, deadlineType));
      }else{
        setEvents(InitEvents(tmp, deadlineType));
      }
    } else {
      for (let i = 0; i < filteringList.length; i++) {
        if (!filteringList[i].filter) {
          tmp = tmp.filter(
            (el: IDeadlinesByAccount) =>
              el.deadlineCategoryID !== filteringList[i].key
          );
        }
      }
      setEvents(InitEvents(tmp, deadlineType));
    }
  }, [advancedFilteringList]);

  const tradDate = (dateStrEng: string) => {
    if (localStorage.getItem("language") === "ita") {
      // mesi
      dateStrEng = dateStrEng.replaceAll("Jan", "Gen");
      dateStrEng = dateStrEng.replaceAll("May", "Mag");
      dateStrEng = dateStrEng.replaceAll("Jun", "Giu");
      dateStrEng = dateStrEng.replaceAll("Jul", "Lug");
      dateStrEng = dateStrEng.replaceAll("Aug", "Ago");
      dateStrEng = dateStrEng.replaceAll("Sep", "Set");
      dateStrEng = dateStrEng.replaceAll("Oct", "Ott");
      dateStrEng = dateStrEng.replaceAll("Dec", "Dic");

      // giorni
      dateStrEng = dateStrEng.replaceAll("Mon", "Lun");
      dateStrEng = dateStrEng.replaceAll("Tue", "Mar");
      dateStrEng = dateStrEng.replaceAll("Wed", "Mer");
      dateStrEng = dateStrEng.replaceAll("Thu", "Gio");
      dateStrEng = dateStrEng.replaceAll("Fri", "Ven");
      dateStrEng = dateStrEng.replaceAll("Sat", "Sab");
      dateStrEng = dateStrEng.replaceAll("Sun", "Dom");
    }
    return dateStrEng;
  };

  const SelectAlertIcon = (idTypeOfAlert?: number) => {
    switch (idTypeOfAlert) {
      default:
        return <PriorityHighIcon className="alert-icon" />;
    }
  };

  const SelectModaltitle = (deadlineTypeID: number) => {
    switch (deadlineTypeID) {
      case 1:
        return "AddInsuranceModal";
      case 6:
      case 15:
        return "AddCardOnDashboard";
      case 11:
      case 12:
        return "editBreach";
      case 18:
        return "AddTelepassOnDashboard";
      case 2:
      case 3:
      case 7:
      case 8:
        return "contractModal";
      case 17:
        return i18next.t(
          "navigation:_calendar_page_expired_modal_title_assignment"
        );
      default:
        return "";
    }
  };

  const SelectModal = (deadlineTypeID: number) => {
    switch (deadlineTypeID) {
      case 1:
        return "AddInsuranceModal";
      case 4:
        return "ModalRevisione";
      case 6:
      case 15:
        return "AddCardOnDashboard";
      case 11:
      case 12:
        return "editBreach";
      case 13:
        return "ModalTagliando";
      case 16:
        return "ModalBollo";
      case 18:
        return "AddTelepassOnDashboard";
      case 21:
        return "ModalSuperBollo";
      case 2:
      case 3:
      case 7:
      case 8:
        return "contractModal";
      case 17:
        return "vehicleAssignment";
      case 19:
      case 20:
        return "ModalServices";
      default:
        return "";
    }
  };

  const customSlotPropGetter = (event?: any) => {
    if (event.idDeadline === 1) {
      return { className: "amministrative" };
    } else if (event.idDeadline === 2) {
      return { className: "gestionali" };
    } else if (event.idDeadline === 3) {
      return { className: "multe" };
    } else if (event.idDeadline === 4) {
      return { className: "altre-scadenze" };
    } else {
      return {};
    }
  };

  const expiredInCurrentYear = () => {
    let cnt = 0;
    for (let i = 0; i < deadlineByAccountScad.length; i++) {
      let dt_exp = deadlineByAccountScad[i].deadlineExpirationDT;
      let notice = 0; // deadlineByAccountScad[i].deadlineDaysNotice;
      var date = new Date(dt_exp);
      date.setDate(date.getDate() - notice);
      if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
        cnt += 1;
      }
    }

    return cnt;
  };

  useEffect(() => {
    if(filtering && filteringList !== undefined && filteringList.filter((x: any) => x.filter).length === 0){
      let tmp = [...deadlineByAccount];
      setEvents(InitEvents(tmp, deadlineType));
    }
  }, [filtering, filteringList]);

  return (
    <>
      {
        <LoaderBackdrop
          loading={
            loadingDeadlineByAccount ||
            loadingDeadlineCategory ||
            loadingDeadlineType ||
            loadingDeadlineExpiredByAccount ||
            loadingCalendar ||
            loadingDeadlineByAccountBrokerlUser ||
            loadingDeadlineByAccountNormalUser
          }
        />
      }
      {!loadingDeadlineByAccount &&
        !loadingDeadlineCategory &&
        !loadingDeadlineType && (
          <div className="deadlines-wrapper">
            <HeaderPageDashboard
              title={i18next.t("navigation:_calendar_page_title")}
            />
            <div className="content-wrapper">
              <div className="accordion-calendar-wrapper">
                <div className="accordion-wrapper">
                  <div className="accordion-content">
                    <AccordionContent
                      title={
                        deadlineExpiredByAccount.length +
                        " " +
                        i18next.t(
                          "navigation:_calendar_page_expiration_not_managed"
                        )
                      }
                      icon={
                        loadingDeadlineExpiredByAccount ? 
                          <div style={{ marginLeft: "-5px" }}>
                          <CircularProgress size={20}/>
                        </div>
                        :  
                          <IconButton aria-label="Actions">
                            {SelectAlertIcon()}
                          </IconButton>
                      }
                      content={
                        <div>
                          {
                            <List>
                              {deadlineExpiredByAccount.length > 0 &&
                                deadlineExpiredByAccount.map(
                                  (el: IDeadlinesByAccount, i: number) => {
                                    if (i < page * 5 && i >= (page - 1) * 5) {
                                      return (
                                        <ListItem
                                          key={i}
                                          disableGutters
                                          secondaryAction={
                                            <div style={{ marginLeft: "1em" }}>
                                              {el.deadlineTypeID !== 14 &&
                                                el.deadlineTypeID !== 9 && (
                                                  <IconButton
                                                    aria-label="Actions"
                                                    onClick={() => {
                                                      let numIndex: number = vehicleData.findIndex((x: IVehicleDataByAccountID) => x.vehicleID === el.vehicleID);
                                                      let contractStartDT: string = vehicleData[numIndex]?.contractStartDT;
                                                      let vehicleFirstRegistrationDT: string = vehicleData[numIndex]?.vehicleFirstRegistrationDT;
                                                      if (
                                                        el.deadlineTypeID === 12
                                                      ) {
                                                        loadTickets(
                                                          el.ticketID
                                                        );
                                                      } else if (
                                                        el.deadlineTypeID === 15
                                                      ) {
                                                        if (el.fuelCardID) {
                                                          loadCardsList(
                                                            el.fuelCardID,
                                                            el.vehicleID,
                                                            el
                                                          );
                                                        }
                                                      } else if (
                                                        el.deadlineTypeID === 5
                                                      ) {
                                                        selectDriver(
                                                          el.driverID
                                                        );
                                                      } else {
                                                        if (
                                                          el.deadlineTypeID ===
                                                          1
                                                        ) {
                                                          setIsNewAssurance(
                                                            true
                                                          );
                                                          SummonModal("AddInsuranceModal");
                                                          setState({deadlineTypeID: el.deadlineTypeID, vehicleID: el.vehicleID});
                                                        } else {
                                                          SummonModal(
                                                            SelectModal(
                                                              el.deadlineTypeID
                                                            )
                                                          );
                                                        }
                                                        if (
                                                          el.deadlineTypeID ===
                                                          18
                                                        ) {
                                                          setVehicleSelected({
                                                            vehicleID: el.vehicleID,
                                                            telepassID: el.telepassID,
                                                            contractStartDT: contractStartDT,
                                                            vehicleFirstRegistrationDT: vehicleFirstRegistrationDT
                                                          });
                                                        } else if (
                                                          el.deadlineTypeID ===
                                                          1
                                                        ) {
                                                          setVehicleSelected({
                                                            vehicleID: el.vehicleID,
                                                            insuranceID: el.insuranceID,
                                                            contractStartDT: contractStartDT,
                                                            vehicleFirstRegistrationDT: vehicleFirstRegistrationDT
                                                          });
                                                        } else if (
                                                          el.deadlineTypeID ===
                                                            19 ||
                                                          el.deadlineTypeID ===
                                                            20
                                                        ) {
                                                          setVehicleSelected({
                                                            vehicleID:  el.vehicleID,
                                                            vehicleServiceID: el.vehicleServiceID,
                                                            contractStartDT: contractStartDT,
                                                            vehicleFirstRegistrationDT: vehicleFirstRegistrationDT
                                                          });
                                                        } else {
                                                          setVehicleSelected({
                                                            vehicleID:el.vehicleID,
                                                            contractStartDT: contractStartDT,
                                                            vehicleFirstRegistrationDT: vehicleFirstRegistrationDT
                                                          });
                                                        }
                                                        setModalTitle(
                                                          SelectModaltitle(
                                                            el.deadlineTypeID
                                                          ) +
                                                            el.brandDescription +
                                                            " " +
                                                            el.fittingDescription +
                                                            " " +
                                                            el.vehicleLicensePlate
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <EditIcon className="edit-icon" />
                                                  </IconButton>
                                                )}
                                              <IconButton
                                                aria-label="Actions"
                                                onClick={() => {
                                                  DeleteDeadline(el.deadlineID);
                                                }}
                                              >
                                                <DeleteIcon className="delete-icon" />
                                              </IconButton>
                                            </div>
                                          }
                                        >
                                          <ListItemText
                                            primary={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  marginRight: "1em",
                                                }}
                                              >
                                                {InitExpiredDeadline(
                                                  el.deadlineTypeID,
                                                  el
                                                ) + " "}{" "}
                                                {el.deadlineTypeID !== 9 &&
                                                  el.deadlineTypeID !== 5 && (
                                                    <div
                                                      className="expired-deadline-vehicle-type"
                                                      onClick={() => {
                                                        if (el.brokerInspectingAccountId) {
                                                          (window as any)["preloadImp"] =
                                                            el.brokerInspectingAccountId;
                                                          SummonModal("ImpersonateModal");
                                                        } else {
                                                          navigate(
                                                            AppRoutes.INTERNAL_DASHBOARD +
                                                              AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                                                              "?vehicleID=" +
                                                              el.vehicleID.toString()
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {formatPlate(
                                                        el.vehicleLicensePlate
                                                      ) +
                                                        " - " +
                                                        el.brandDescription +
                                                        " " +
                                                        el.modelDescription}
                                                    </div>
                                                  )}
                                                {el.deadlineTypeID !== 9 &&
                                                el.deadlineTypeID !== 5 &&
                                                (el.driverDisplayName
                                                  ? localStorage.getItem(
                                                      "language"
                                                    ) === "ita"
                                                    ? "di "
                                                    : "of "
                                                  : "") + el.driverDisplayName
                                                  ? el.driverDisplayName
                                                  : ""}
                                                {el.deadlineTypeID === 5 && (
                                                  <div
                                                    className="expired-deadline-vehicle-type"
                                                    onClick={() => {
                                                      selectDriver(el.driverID);
                                                    }}
                                                  >
                                                    {(el.driverDisplayName
                                                      ? localStorage.getItem(
                                                          "language"
                                                        ) === "ita"
                                                        ? "di "
                                                        : "of "
                                                      : "") +
                                                    el.driverDisplayName
                                                      ? el.driverDisplayName
                                                      : ""}
                                                  </div>
                                                )}
                                                {el.deadlineTypeID === 9 && (
                                                  <strong
                                                    className="expired-deadline-vehicle-type-strong"
                                                    onClick={() => {
                                                      /* navigate(
                                                      AppRoutes.INTERNAL_DASHBOARD +
                                                        AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                                                        "?vehicleID=" +
                                                        el.vehicleID.toString()
                                                    ); */
                                                    }}
                                                  >
                                                    {el.brandDescription +
                                                      " " +
                                                      el.fittingDescription}
                                                  </strong>
                                                )}
                                              </div>
                                            }
                                          />
                                        </ListItem>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                            </List>
                          }
                          <div className="pagination-wrapper">
                            <Pagination
                              count={Math.ceil(
                                deadlineExpiredByAccount.length / 5
                              )}
                              onChange={(_, page) => {
                                setPage(page);
                              }}
                            />
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="calendar-filter-wrapper">
                  <div className="filter-wrapper">
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "0.5em" }}>
                        <ToggleButton
                          value={"Filter"}
                          color={"primary"}
                          selected={filtering}
                          onChange={() => {
                            setFiltering(!filtering);
                            if (filtering) {
                              let tmp = [...deadlineByAccount];
                              setEvents(InitEvents(tmp, deadlineType));
                              InitFilteringlist(deadlineCategory);
                            }
                          }}
                        >
                          {i18next.t(
                            "navigation:_calendar_page_expired_filter"
                          )}
                        </ToggleButton>
                      </div>
                      {filtering && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              height: "3em",
                            }}
                          >
                            {deadlineCategory.map(
                              (el: IDeadlinesCategory, i: number) => {
                                return (
                                  <div style={{ padding: "0.5em" }} key={i}>
                                    <ToggleButton
                                      value={"Filter"}
                                      color={"primary"}
                                      selected={
                                        filteringList.filter((x) => x.key === el.deadlineCategoryID && x.filter === true).length === 1
                                      }
                                      onChange={(e: any) => {
                                        let tmp = [
                                          ...filteringList,
                                        ];
                                        let keyIndex: number = filteringList.findIndex((x: any) => x.key === el.deadlineCategoryID);
                                        tmp[keyIndex].filter = !tmp[keyIndex].filter;
                                        setFilteringList(tmp);
                                      }}
                                    >
                                      {el.deadlineCategoryName}
                                    </ToggleButton>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {filteringList.length > 0 &&
                      filtering &&
                      SetDeadlineType().length > 0 && (
                        <div style={{ display: "flex" }}>
                          <div style={{ marginTop: "0.5em" }}>
                            <ToggleButton
                              value={"Filter"}
                              color={"primary"}
                              selected={advancedFiltering}
                              onChange={() => {
                                if (advancedFiltering) {
                                  InitAdvancedFilteringlist(deadlineType);
                                }
                                setAdvancedFiltering(!advancedFiltering);
                              }}
                            >
                              {i18next.t(
                                "navigation:_calendar_page_expired_filter"
                              )}
                            </ToggleButton>
                          </div>
                          {advancedFiltering &&
                            SetDeadlineType().length > 0 && (
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {SetDeadlineType().map(
                                  (el: IDeadlinesType, i: number) => {
                                    return (
                                      <div style={{ padding: "0.5em" }} key={i}>
                                        <ToggleButton
                                          value={"Filter"}
                                          color={"primary"}
                                          selected={
                                            advancedFilteringList.filter((x) => x.key === el.deadlineTypeID && x.filter === true).length === 1
                                          }
                                          onChange={(e: any) => {
                                            let tmp = [
                                              ...advancedFilteringList,
                                            ];
                                            let keyIndex: number = advancedFilteringList.findIndex((x: any) => x.key === el.deadlineTypeID);
                                            tmp[keyIndex].filter = !tmp[keyIndex].filter;
                                            setAdvancedFilteringList(tmp);
                                          }}
                                        >
                                          {el.deadlineTypeName}
                                        </ToggleButton>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                  {!loadingCalendar && !loadingDeadlineByAccountBrokerlUser && !loadingDeadlineByAccountNormalUser && (
                    <div className="calendar-wrapper">
                      <Calendar
                        localizer={localizer}
                        events={events}
                        culture={culture}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        messages={messages}
                        defaultDate={defaultDate}
                        dayPropGetter={customDayPropGetter}
                        eventPropGetter={(event: any) =>
                          customSlotPropGetter(event)
                        }
                        onRangeChange={(range: any) => {
                          if (range.end) {
                            if (
                              range.end.getFullYear() !== +currentYearCalendar
                            ) {
                              setCurrentYearCalendar(range.end.getFullYear());
                              GetDeadlineByAccount(
                                range.end.getFullYear(),
                                deadlineType,
                                false
                              );
                            }
                          } else {
                            if (
                              range[0].getFullYear() !== +currentYearCalendar
                            ) {
                              setCurrentYearCalendar(range[0].getFullYear());
                              GetDeadlineByAccount(
                                range[0].getFullYear(),
                                deadlineType,
                                false
                              );
                            }
                          }
                        }}
                        onSelectEvent={(el: any) => {
                          let id = el.vehicleID;
                          if (el.brokerInspectingAccountId) {
                            (window as any)["preloadImp"] =
                              el.brokerInspectingAccountId;
                            SummonModal("ImpersonateModal");
                          } else {
                            if (id) {
                              navigate(
                                AppRoutes.INTERNAL_DASHBOARD +
                                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                                  "?vehicleID=" +
                                  id.toString()
                              );
                            }
                            if (el.vehicleOrderID) {
                              navigate(
                                AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___VEHICLES_ROUTE__ORDERS
                              );
                            }
                            if(el.driverID){
                              selectDriver(el.driverID);
                            }
                          }
                        }}
                        onSelectSlot={(slotInfo: any) => {
                          setSelectedDay(slotInfo.start);
                          let tmpEvents = [...events];
                          let tmpExpired = tmpEvents.filter(
                            (el: any) =>
                              el.start.getDate() === slotInfo.start.getDate() &&
                              el.start.getMonth() === slotInfo.start.getMonth()
                          );
                          setExpired(tmpExpired);
                        }}
                        style={{ minHeight: 500 }}
                      />
                    </div>
                  )}
                </div>
                <div className="expired-resume">
                  {
                    <div className="expired-resume-header">
                      <h4>
                        {i18next.t("navigation:expiryResume")} {currentYearScad}
                      </h4>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "1em" }}>
                          {" "}
                          {i18next.t("navigation:_calendar_page_total") +
                            ": " +
                            deadlineByAccountScad.length}
                        </div>
                        <div style={{ marginRight: "1em" }}>
                          {" "}
                          {i18next.t("navigation:_calendar_page_next") +
                            ": " +
                            (deadlineByAccountScad.length -
                              expiredInCurrentYear())}
                        </div>
                        <div style={{ marginRight: "1em" }}>
                          {" "}
                          {i18next.t("navigation:_calendar_page_expired") +
                            ": " +
                            expiredInCurrentYear()}
                        </div>
                      </div>
                    </div>
                  }
                  <div className="expired-count-header">
                    <Button
                      onClick={() => {
                        let tmp = +currentYearScad;
                        tmp = tmp - 1;
                        setCurrentYearScad(tmp.toString());
                        GetDeadlineByAccount(
                          tmp.toString(),
                          deadlineType,
                          true
                        );
                      }}
                    >
                      <ArrowBack />
                    </Button>
                    {columnsDef.map((el: any, i: number) => {
                      return (
                        <div className="expire-count-cell" key={i}>
                          {el.headerName}
                        </div>
                      );
                    })}
                    <Button
                      onClick={() => {
                        let tmp = +currentYearScad;
                        tmp = tmp + 1;
                        setCurrentYearScad(tmp.toString());
                        GetDeadlineByAccount(
                          tmp.toString(),
                          deadlineType,
                          true
                        );
                      }}
                    >
                      <ArrowForwardIcon />
                    </Button>
                  </div>
                  {rowsDef.map((el: any, i: number) => {
                    return (
                      <div className="expired-count" key={i}>
                        {el.row.map((ele: any, j: number) => {
                          return (
                            <div className="expire-count-cell-button" key={j}>
                              {ele.color !== undefined ? (
                                <ToggleButton
                                  value={"Filter"}
                                  style={{ backgroundColor: ele.color }}
                                  selected={j === selectedMonth.y}
                                  onChange={() => {
                                    let newDate = new Date(
                                      currentYearScad + "-" + padBlock(j)
                                    ).toISOString();
                                    setDefaultDate(newDate);
                                    setSelectedMonth({ x: i, y: j });
                                    GetDeadlineByAccount(
                                      currentYearScad,
                                      deadlineType,
                                      false
                                    );
                                  }}
                                >
                                  {ele.length}
                                </ToggleButton>
                              ) : (
                                <div>{ele}</div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="selected-day-deadlines-wrapper">
                <div className="selected-day-deadlines-header">
                  <div>
                    {selectedDay === new Date() ? (
                      <h3>
                        {i18next.t("navigation:_calendar_page_current_day")}
                      </h3>
                    ) : (
                      ""
                    )}
                    <div>{tradDate(selectedDay.toDateString())}</div>
                  </div>
                  <div>
                    <IconButton aria-label="Example">
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                <div style={{ overflowY: "auto", maxHeight: "55vh" }}>
                  {DayDeadlines(expired)}
                </div>
                <div className="button-add-deadline">
                  <Button
                    onClick={async () => {
                      SummonModal("addExpire");
                    }}
                    variant="contained"
                    startIcon={<AddIcon />}
                    style={{ width: "60%" }}
                  >
                    {i18next.t("navigation:_calendar_page_add_expiration")}
                  </Button>
                </div>
              </div>
            </div>
            <SmartModal
              modalUniqueId={"addExpire"}
              title={i18next.t(
                "navigation:_calendar_page_expired_modal_title_add"
              )}
              modalInnerComponent={
                <div className="add-expire-wrapper">
                  <div className="modal-width-medium">
                    <MultiForm
                      suppressSubmit
                      suppressLayout
                      inputs={multiformInputs()}
                      formId="newExpiry"
                      onChange={async (data: any) => {
                        if(data.vehicleID && data.deadlineTypeID && data.deadlineTypeID === 19) {
                          loadVehicleServices(data.vehicleID)
                        }

                        if (data.deadlineTypeID === 1) {
                          setIsNewAssurance(true);
                        } else {
                          setIsNewAssurance(false);
                        }
                        
                        let numIndex: number = vehicleData.findIndex((x: IVehicleDataByAccountID) => x.vehicleID === data.vehicleID);
                        data["contractStartDT"] = vehicleData[numIndex]?.contractStartDT;
                        data["vehicleFirstRegistrationDT"] = vehicleData[numIndex]?.vehicleFirstRegistrationDT;
                        setState({ ...state, ...data });
                      }}
                      onSubmit={(data: any) => {}}
                    />
                  </div>
                </div>
              }
              onAccept={() => {
                setVehicleSelected(state);
                if (
                  state.deadlineTypeID === 11 ||
                  state.deadlineTypeID === 12
                ) {
                  navigate(
                    AppRoutes.INTERNAL_DASHBOARD + AppRoutes.TICKETS_MANAGEMENT
                  );
                } else {
                  SummonModal(SelectModal(state.deadlineTypeID));
                }
                DismissModal("addExpire");
              }}
              onReject={() => DismissModal("addExpire")}
            />
            <SmartModal
              modalUniqueId={"AddCardOnDashboard"}
              title={i18next.t(
                "navigation:_vehicle_page_fuel_card_toolbar_button_add_card"
              )}
              modalInnerComponent={
                <FuelCardDashboardEditor
                  formUniqueIndex={"_new"}
                  fuelCardType={undefined}
                  supplier={undefined}
                  drivers={drivers}
                  companyUser={companyUser}
                  fuelCardTypeList={fuelCardsType}
                  supplierList={supplierFuelCards}
                  fuelCard={
                    vehicleSelected
                      ? (vehicleSelected as IFuelCardsGet)
                      : ({} as IFuelCardsGet)
                  }
                />
              }
            />
            <SmartModal
              modalUniqueId={"editBreach"}
              title={""}
              buttons={[
                {
                  text: i18next.t("navigation:_tickets_page_delete_ticket"),
                  onClick: () => {
                    DeleteTicket(ticketInsertResponse![0].ticketID!);
                    dispatch(TicketsAction.setStepperTickets(undefined));
                    dispatch(TicketsAction.setTicketInsertResponse(undefined));
                    dispatch(
                      TicketsAction.setTicketInsertDetailResponse(undefined)
                    );
                    dispatch(
                      TicketsAction.setTicketInsertMultipleDetailResponse(
                        undefined
                      )
                    );
                    dispatch(
                      TicketsAction.setTicketInsertPaymentResponse(undefined)
                    );
                    dispatch(TicketsAction.setTicketStatus(undefined));
                    dispatch(TicketsAction.setTicketDriver(undefined));
                    DismissModal("editBreach");
                  },
                  disabled: false,
                },
                {
                  text: i18next.t("navigation:_tickets_page_edit_ticket"),
                  onClick: () => {
                    let tmpUpdate: any = {};
                    let tmpSanction: ITicketInsert = {
                      ...stepperTicket?.stepSanction,
                    };
                    tmpUpdate.ticketTypeID = tmpSanction.ticketTypeID;
                    tmpUpdate.ticketNumber = tmpSanction.ticketNumber;
                    tmpUpdate.ticketIssuingInstitutionID =
                      tmpSanction.ticketIssuingInstitutionID;
                    tmpUpdate.ticketDeliveryTypeID =
                      tmpSanction.ticketDeliveryTypeID;
                    tmpUpdate.ticketPickUpDT = tmpSanction.ticketPickUpDT;
                    tmpUpdate.ticketCADDT = tmpSanction.ticketCADDT;
                    tmpUpdate.ticketNotificationDT =
                      tmpSanction.ticketNotificationDT;
                    tmpUpdate.ticketID = ticketInsertResponse![0].ticketID;
                    let tmpBreach: any = { ...stepperTicket?.stepBreach! };
                    tmpBreach = Object.values(tmpBreach);
                    let tmpUpdateDetail: any = {};
                    let tmpUpdateMultipleDetail: any[] = [];
                    let isMultiple = false;
                    if (tmpBreach.length === 1) {
                      tmpUpdateDetail.linkedTicketID =
                        tmpBreach[0].linkedTicketID;
                      tmpUpdateDetail.ticketArticleID =
                        tmpBreach[0].ticketArticleID;
                      tmpUpdateDetail.violationDT = tmpBreach[0].violationDT;
                      tmpUpdateDetail.vehicleID = tmpBreach[0].vehicleID;
                      tmpUpdateDetail.vehicleLicensePlate =
                        tmpBreach[0].vehicleLicensePlate;
                      tmpUpdateDetail.driverID = tmpBreach[0].driverID;
                      tmpUpdateDetail.pointsDeduction =
                        tmpBreach[0].pointsDeduction;
                      tmpUpdateDetail.pointsDeducted =
                        tmpBreach[0].pointsDeducted;
                      tmpUpdateDetail.licenseSuspension =
                        tmpBreach[0].licenseSuspension;
                      tmpUpdateDetail.licenseSuspensionPeriod =
                        tmpBreach[0].licenseSuspensionPeriod;
                      tmpUpdateDetail.ticketDetailID =
                        tmpBreach[0].ticketDetailID;
                    } else {
                      isMultiple = true;
                      for (let i = 0; i < tmpBreach.length; i++) {
                        let tmp: any = {};
                        tmp.ticketID = ticketInsertResponse![0].ticketID;
                        tmp.violationDT = tmpBreach[i].violationDT;
                        tmp.amount = tmpBreach[i].amount;
                        tmp.vehicleID = tmpBreach[i].vehicleID;
                        tmp.vehicleLicensePlate =
                          tmpBreach[i].vehicleLicensePlate;
                        tmp.driverID = tmpBreach[i].driverID;
                        tmp.ticketDetailID = tmpBreach[i].ticketDetailID;
                        tmpUpdateMultipleDetail.push(tmp);
                      }
                    }
                    let tmpAmountDeadlines: any =
                      stepperTicket?.stepAmountDeadlines;
                    tmpUpdate.ticketReducedExpirationDT =
                      tmpAmountDeadlines.ticketReducedExpirationDT;
                    tmpUpdate.ticketReducedAmount =
                      tmpAmountDeadlines.ticketReducedAmount;
                    tmpUpdate.ticketReducedTaxAmount =
                      tmpAmountDeadlines.ticketReducedTaxAmount;
                    tmpUpdate.ticketReducedTotalAmount =
                      tmpAmountDeadlines.ticketReducedTotalAmount;
                    tmpUpdate.ticketExpirationDT =
                      tmpAmountDeadlines.ticketExpirationDT;
                    tmpUpdate.ticketAmount = tmpAmountDeadlines.ticketAmount;
                    tmpUpdate.ticketTaxAmount =
                      tmpAmountDeadlines.ticketTaxAmount;
                    tmpUpdate.ticketTotalAmount =
                      tmpAmountDeadlines.ticketTotalAmount;
                    if (isMultiple) {
                      UpdateTicket(tmpUpdate, null, tmpUpdateMultipleDetail);
                    } else {
                      UpdateTicket(tmpUpdate, tmpUpdateDetail, null);
                    }
                    dispatch(TicketsAction.setStepperTickets(undefined));
                    dispatch(TicketsAction.setTicketInsertResponse(undefined));
                    dispatch(
                      TicketsAction.setTicketInsertDetailResponse(undefined)
                    );
                    dispatch(
                      TicketsAction.setTicketInsertMultipleDetailResponse(
                        undefined
                      )
                    );
                    dispatch(
                      TicketsAction.setTicketInsertPaymentResponse(undefined)
                    );
                    dispatch(TicketsAction.setTicketStatus(undefined));
                    dispatch(TicketsAction.setTicketDriver(undefined));
                    DismissModal("editBreach");
                  },
                  disabled: false,
                },
              ]}
              modalInnerComponent={
                <div>
                  <div className="modal-header">
                    <strong>
                      ID {i18next.t("navigation:ticket").toUpperCase()}:
                      12345678
                    </strong>
                  </div>
                  <StepSanctionData header={false} />
                  <StepBreachData header={false} />
                  <StepAmountDeadelines header={false} />
                  <StepAttachmentsTickets header={false} />
                </div>
              }
            />
            <SmartModal
              modalUniqueId={"AddTelepassOnDashboard"}
              title={i18next.t(
                "navigation:_vehicle_page_fuel_card_toolbar_button_add_telepass"
              )}
              modalInnerComponent={
                <div>
                  <FormTelepass
                    doRefreshForm={() => {
                      DismissModal("AddTelepassOnDashboard");
                    }}
                    loggedUser={loggedUser}
                    dataVehicle={vehicleSelected}
                  />
                </div>
              }
            />
            <SmartModal
              modalUniqueId={"contractModal"}
              title={i18next.t(
                "navigation:_calendar_page_title_modal_contract"
              )}
              modalInnerComponent={
                <FormContract
                  loggedUser={loggedUser}
                  companyUser={undefined}
                  expiredVehicle={vehicleSelected}
                  disabled={false}
                />
              }
            />
            <SmartModal
              modalUniqueId={"vehicleAssignment"}
              title={modalTitle}
              modalInnerComponent={
                <FormAssignement
                  loggedUser={loggedUser}
                  companyUser={undefined}
                  dataVehicle={vehicleSelected}
                  disabled={false}
                />
              }
            />
            {isNewAssurance && state.deadlineTypeID === 1 && (
              <InsertAssuranceModal
                disabled={false}
                vehicle={vehicleSelected}
                loggedUser={loggedUser}
                isNewAssurance
                onCloseModal={() => {
                  setIsNewAssurance(false);
                }}
              />
            )}
            <SmartModal
              hideButtons={true}
              modalUniqueId="driver-page-modal"
              title={i18next.t("generic:titleUpdateDriver")}
              modalInnerComponent={
                <FormAssignedDriver
                  loggedUser={loggedUser}
                  data={currentDriver ?? []}
                  companyUser={companyUser}
                  idModal="driver-page-modal"
                  doRefreshForm={() => {
                    loadDriverList();
                  }}
                />
              }
            />
            <SmartModal
              modalUniqueId={"ModalRevisione"}
              title={i18next.t(
                "navigation:_calendar_page_title_modal_inspection"
              )}
              modalInnerComponent={
                <div className="modal-width-medium">
                  <Form__Revisione
                    vehicleID={vehicleSelected ? vehicleSelected.vehicleID : 0}
                    isNewRev
                    dataVehicle={vehicleSelected}
                  />
                </div>
              }
            />
            <SmartModal
              modalUniqueId={"ModalBollo"}
              title={i18next.t(
                "navigation:_calendar_page_title_modal_road_tax"
              )}
              modalInnerComponent={
                <Form__Bollo vehicle={vehicleSelected} isNewBollo />
              }
            />
            <SmartModal
              modalUniqueId={"ModalSuperBollo"}
              title={i18next.t(
                "navigation:_calendar_page_title_modal_additional_road_tax"
              )}
              modalInnerComponent={
                <Form__SuperBollo
                  vehicleID={vehicleSelected ? vehicleSelected.vehicleID : 0}
                  isNewSuperBollo
                  dataVehicle={vehicleSelected}
                />
              }
            />
            <SmartModal
              modalUniqueId={"ModalTagliando"}
              title={i18next.t("navigation:_calendar_page_title_modal_test")}
              modalInnerComponent={
                <Form__Tagliando
                  vehicleID={vehicleSelected ? vehicleSelected.vehicleID : 0}
                  isNewTagliando
                  dataVehicle={vehicleSelected}
                />
              }
            />
            <SmartModal
              modalUniqueId={"ModalServices"}
              title={i18next.t("navigation:edit_service")}
              modalInnerComponent={
                <>
                  <FormServices
                    loggedUser={loggedUser}
                    expiredVehicle={vehicleSelected}
                    doRefreshForm={() => {}}
                  />
                </>
              }
            />
          </div>
        )}
      <SmartModal
        modalUniqueId="delete-deadline-id"
        title={i18next.t("generic:proceedSureDeadline")}
        modalInnerComponent={
          <div>{i18next.t("generic:proceedDeleteSure")}</div>
        }
        onAccept={() => {
          DeleteDeadlineProceed();
        }}
        onReject={() => {
          DismissModal("delete-deadline-id");
        }}
      />
    </>
  );
};

export default ExpiryCalendarPage;
