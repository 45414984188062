/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* MODELS */
import {
  IVehicleDataBrands,
  IVehicleDataFitting,
  IVehicleDataModels,
} from "../../../../../../Models/IVehicles";
import {
  IGET_CarConfiguratorGroup_Close_Vehicle,
  IGET_CarConfiguratorVehicle_MonthYear,
  IINSERT_CarConfiguratorGroup_Close_Vehicle,
  IUPDATE_CarConfiguratorGroup_Close_Vehicle,
} from "../../../../../../Models/ICarConfigurator";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* COMPONENTS */
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import TabsAddOptionals from "./TabsAddOptionals/TabsAddOptionals";

/* MUI */
import { Alert, Button } from "@mui/material";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

/* INTERFACE */
export interface IFormCloseGroup {
  data: any;
  groupID: number | null;
  onSubmit: () => void;
}

/* COMPONENT */
const FormCloseGroup = (props: IFormCloseGroup) => {
  /* STATES */
  const [formData, setFormData] = useState<IGET_CarConfiguratorGroup_Close_Vehicle>(
    props.data
  );
  // States for veichle informations
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);
  const [monthYear, setMonthYear] = useState<IGET_CarConfiguratorVehicle_MonthYear[]>([]);
  // Loading
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] = useState<boolean>(false);
  const [loadingCompanyCode, setLoadingCompanyCode] = useState<boolean>(false);
  const [loadingMonthYear, setLoadingMonthYear] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* API */
  // Load brands list
  const loadBrandsList = () => {
    setLoadingBrandsList(true);

    ApiService.VehicleDataController.VehicleDataGetBrands(
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBrandsList(response.payload);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingBrandsList(false);
      }
    );
  };

  // Load model list
  const loadModelsList = (brand: string) => {
    if (brand) {
      setLoadingModelsList(true);

      ApiService.VehicleDataController.VehicleDataGetModels(
        brand,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setModelsList(response.payload);
          } else {
            ToastMessage(i18next.t("error:ERROR"), "error");
          }

          setLoadingModelsList(false);
        }
      );
    }
  };

  // Load fitting list
  const loadFittingList = (brand: string, model: string) => {
    if (brand && model) {
      setLoadingFittingsList(true);

      ApiService.VehicleDataController.VehicleDataGetFittings(
        brand,
        model,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFittingsList(response.payload);
          } else {
            ToastMessage(i18next.t("error:ERROR"), "error");
          }

          setLoadingFittingsList(false);
        }
      );
    }
  };

  // Load company code
  const GET_CarConfiguratorVehicleCompanyCode = (fitting: string) => {
    setLoadingCompanyCode(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorVehicleCompanyCode(
      null,
      fitting,
      false,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          const companyCodeValue = await response.payload.text();
          setFormData({ ...formData, ...{ companyCode: companyCodeValue } });
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingCompanyCode(false);
      }
    );
  };

  // Load month year
  const GET_CarConfiguratorVehicle_MonthYear = (fitting: string) => {
    setLoadingMonthYear(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorVehicle_MonthYear(
      null,
      fitting,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setMonthYear(response.payload);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingMonthYear(false);
      }
    );
  };

  // Insert vehicle in close group
  const INSERT_CarConfiguratorGroup_Close_Vehicle = (
    data: IINSERT_CarConfiguratorGroup_Close_Vehicle
  ) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_CarConfiguratorGroup_Close_Vehicle(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          props.onSubmit();

          ToastMessage(i18next.t("message:success"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  // Update vehicle in close group
  const UPDATE_CarConfiguratorGroup_Close_Vehicle = (
    data: IUPDATE_CarConfiguratorGroup_Close_Vehicle
  ) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Close_Vehicle(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          props.onSubmit();

          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  // Delete vehicle in close group
  const DELETE_CarConfiguratorGroup_Close_Vehicle = (groupVehicleID: number) => {
    setLoadingDelete(true);

    ApiService.CarConfiguratorController.DELETE_CarConfiguratorGroup_Close_Vehicle(
      groupVehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          props.onSubmit();

          ToastMessage(i18next.t("message:deleteSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingDelete(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  // Load brand
  useEffect(() => {
    loadBrandsList();
  }, []);

  // Load model
  useEffect(() => {
    if (formData?.brand) {
      loadModelsList(formData?.brand);
    }
  }, [formData?.brand]);

  // Load fitting
  useEffect(() => {
    if (formData?.brand && formData?.model) {
      loadFittingList(formData?.brand, formData?.model);
    }
  }, [formData?.brand, formData?.model]);

  // Load fittingCode and companyCode
  useEffect(() => {
    if (formData?.brand && formData?.model) {
      loadFittingList(formData?.brand, formData?.model);

      GET_CarConfiguratorVehicleCompanyCode(formData?.fitting);

      GET_CarConfiguratorVehicle_MonthYear(formData?.fitting);
    }
  }, [formData?.fitting]);

  /* RETURN */
  return (
    <div className="modal-width-medium">
      {!loadingBrandsList && !loadingModelsList && !loadingFittingsList && (
        <MultiForm
          suppressLayout
          suppressSubmit={true}
          formId={"FormCloseGroupVehicle"}
          inputs={[
            // Brand
            {
              width: 30,
              type: "select",
              name: "brand",
              defaultValue: formData?.brand,
              required: true,
              label: `${i18next.t("form:orderBrand")}`,
              options: brandsList.map((item: IVehicleDataBrands) => {
                return {
                  key: item.brandCode,
                  text: item.brandDescription,
                };
              }),
            },
            // Order modal
            {
              width: 30,
              type: "select",
              name: "model",
              disabled: formData?.brand ? false : true,
              defaultValue: formData?.model,
              required: true,
              label: `${i18next.t("form:orderModel")}`,
              options: modelList.map((item: IVehicleDataModels) => {
                return {
                  key: item.modelCode,
                  text: item.modelDescription,
                };
              }),
            },
            // Order equipment
            {
              width: 30,
              type: "select",
              name: "fitting",
              disabled: formData?.model ? false : true,
              defaultValue: formData?.fitting,
              required: true,
              label: `${i18next.t("form:orderEquipment")}`,
              options: fittingList.map((item: IVehicleDataFitting) => {
                return {
                  key: item.fittingCode,
                  text: item.fittingDescription,
                };
              }),
            },
            // Add
            {
              width: 10,
              type: "custom",
              name: "addOptionals",
              element: (
                <div className="multi-form-button-add-record">
                  <Button
                    disabled={formData?.groupVehicleID ? false : true}
                    variant="contained"
                    onClick={() => {
                      SummonModal("add-optionals-modal");
                    }}
                  >
                    <EditIcon />
                  </Button>
                </div>
              ),
            },

            // Start date
            {
              width: 33.33,
              type: "date",
              name: "startDate",
              defaultValue: formData?.startDate,
              label: `${i18next.t("form:dateStart")}`,
              required: true,
              minDate: new Date().toISOString(),
            },
            // End date
            {
              width: 33.33,
              type: "date",
              name: "endDate",
              defaultValue: formData?.endDate,
              label: `${i18next.t("form:dateFinish")}`,
              required: true,
              disabled: formData?.startDate ? false : true,
              minDate: formData?.startDate && formData?.startDate.toString(),
            },
            // Month / year
            {
              width: 33.33,
              type: "select",
              name: "monthYear",
              defaultValue: formData?.monthYear,
              label: `${i18next.t("form:monthYear")}`,
              required: true,
              disabled: formData?.fitting ? false : true,
              options: monthYear.map((item: IGET_CarConfiguratorVehicle_MonthYear) => {
                return {
                  key: item.description,
                  text: item.description,
                };
              }),
            },

            // Brand code
            {
              width: 25,
              type: "text",
              name: "brandCode",
              defaultValue: formData?.brand,
              label: `${i18next.t("form:brandCode")}`,
              required: true,
              disabled: true,
            },
            // Model code
            {
              width: 25,
              type: "text",
              name: "modelCode",
              defaultValue: formData?.model,
              label: `${i18next.t("form:modelCode")}`,
              required: true,
              disabled: true,
            },
            // Fitting code
            {
              width: 25,
              type: "text",
              name: "fittingCode",
              defaultValue: formData?.fitting,
              label: `${i18next.t("form:fittingCode")}`,
              required: true,
              disabled: true,
            },
            // Company code
            {
              width: 25,
              type: "text",
              name: "companyCode",
              defaultValue: formData?.companyCode,
              label: `${i18next.t("form:companyCode")}`,
              required: false,
              disabled: true,
            },
          ]}
          onChange={(data: any) => {
            setFormData({ ...formData, ...data });
          }}
          onSubmit={(data: any) => {
            const tempData = lodash.cloneDeep(data);

            delete tempData["addOptionals"];

            tempData["groupID"] = props.groupID;

            if (formData.groupVehicleID) {
              tempData["groupVehicleID"] = formData.groupVehicleID;
            }

            // Lunch the API based on the groupVehicleID
            // (if present the element already exist so UPDATE, if not INSERT)
            if (tempData?.groupVehicleID && tempData?.groupVehicleID > 0) {
              UPDATE_CarConfiguratorGroup_Close_Vehicle(tempData);
            } else {
              INSERT_CarConfiguratorGroup_Close_Vehicle(tempData);
            }
          }}
        />
      )}

      {!loadingBrandsList &&
        !loadingModelsList &&
        !loadingFittingsList &&
        !formData.groupVehicleID && (
          <Alert severity="info">
            <div>{i18next.t("navigation:optionalWarning")}</div>
          </Alert>
        )}

      {!loadingBrandsList && !loadingModelsList && !loadingFittingsList && (
        <div className="display-flex-end padding-x-extrasmall margin-top-small">
          {formData && formData.groupVehicleID && (
            <Button
              color="error"
              variant="contained"
              className="margin-right-small"
              endIcon={<DeleteIcon />}
              onClick={() => {
                SummonModal("delete-vehicle-group-modal");
              }}
            >
              {i18next.t("generic:delete")}
            </Button>
          )}

          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={() => {
              triggerFormValidation("FormCloseGroupVehicle");
            }}
          >
            {i18next.t("generic:save")}
          </Button>
        </div>
      )}

      <SmartModal
        modalUniqueId="delete-vehicle-group-modal"
        title={i18next.t("generic:delete")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace(
                  "CURRENT_ITEM_LIST",
                  formData?.brandDescription +
                    " " +
                    formData?.modelDescription +
                    " " +
                    formData?.fittingDescription || ""
                ),
            }}
          />
        }
        onAccept={() => {
          DELETE_CarConfiguratorGroup_Close_Vehicle(formData?.groupVehicleID);
        }}
        onReject={() => {}}
      />

      <SmartModal
        hideButtons
        modalUniqueId="add-optionals-modal"
        title={i18next.t("form:selectOptionals")}
        modalInnerComponent={
          <TabsAddOptionals groupVehicleID={formData?.groupVehicleID} />
        }
      />

      <LoaderBackdrop
        loading={
          loadingBrandsList ||
          loadingModelsList ||
          loadingFittingsList ||
          loadingCompanyCode ||
          loadingMonthYear ||
          loadingInsert ||
          loadingUpdate ||
          loadingDelete
        }
      />
    </div>
  );
};

export default FormCloseGroup;
