import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IAddedServiceUpdate,
  IServiceInsert,
  IServiceUpdate,
} from "../../Models/IService";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const ServiceController = {
  ServiceGetAddedData: async (
    AllTranslations?: boolean,
    stored?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_GET_ADDED_DATA;
    request.method = "GET";
    let parameters: string[] = [];

    if (AllTranslations !== undefined) {
      parameters.push(`AllTranslations=${AllTranslations}`);
    }
    if (stored !== undefined) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceInsertAddedData: async (
    data: IAddedServiceUpdate,
    addedServiceID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_INSERT_ADDED_DATA;
    if (addedServiceID) {
      request.url += "?addedServiceID=" + addedServiceID;
    }
    request.method = "POST";
    request.payload = data;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceUpdateAddedData: async (
    data: IAddedServiceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_UPDATE_ADDED_DATA;
    request.method = "PUT";
    request.payload = data;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceDeleteAddedSubscription: async (
    subscriptionID: number,
    deselectedServiceIDList: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_DELETE_ADDED_SUBSCRIPTION;
    request.method = "DELETE";
    request.payload = {
      subscriptionID,
      deselectedServiceIDList,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceGetSelectedData: async (
    SubscriptionID?: number | null,
    AddedServiceID?: number | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_GET_SELECTED_DATA;
    let parameters: string[] = [];
    if (SubscriptionID) {
      parameters.push(`SubscriptionID=${SubscriptionID}`);
    }
    if (AddedServiceID) {
      parameters.push(`AddedServiceID=${AddedServiceID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceGetData: async (
    serviceID: number,
    serviceName: string,
    allTranslations?: boolean,
    stored?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_GET_DATA;
    let parameters: string[] = [];
    if (serviceID) {
      parameters.push(`serviceID=${serviceID}`);
    }
    if (serviceName) {
      parameters.push(`serviceName=${serviceName}`);
    }
    if (stored !== undefined) {
      parameters.push(`stored=${stored}`);
    }
    if (allTranslations !== undefined) {
      parameters.push(`allTranslations=${allTranslations}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceInsertData: async (
    body: IServiceInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_INSERT_DATA;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceUpdateData: async (
    body: IServiceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_UPDATE_DATA;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ServiceDeleteData: async (
    serviceID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SERVICE_DELETE_DATA;
    request.payload = {
      serviceID,
    };
    request.method = "DELETE";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default ServiceController;
