import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import "./FringeModal.scss";
import SmartModal, { DismissModal } from "../SmartModal/SmartModal";
import i18next from "i18next";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import {
  IVehicleFringeBenefit,
  IVehicleFringeBenefitGet,
  IVehicleFringeBrandName,
  IVehicleFringeFuel,
  IVehicleFringeModel,
  IVehicleFringeVersion,
} from "../../Models/IVehiclesFringe";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { VehiclesActions } from "../../Reducers/Vehicle/VehicleAction";

const FringeModal = () => {
  const [state, setState] = useState<any>({});
  const dispatch = useDispatch(); 

  const [fuelTypes, setFuelTypes] = useState<IVehicleFringeFuel[]>([]);
  const [brands, setBrands] = useState<IVehicleFringeBrandName[]>([]);
  const [models, setModels] = useState<IVehicleFringeModel[]>([]);
  const [versions, setVersions] = useState<IVehicleFringeVersion[]>([]);
  const [fringeBenefit, setFringeBenefit] = useState<IVehicleFringeBenefitGet | null>(
    null
  );
  const [loadingFringeBenefit, setLoadingFringeBenefit] = useState<boolean>(false);
  const [loadingFuelType, setLoadingFuelType] = useState<boolean>(false);

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const loadFringeFuelType = () => {
    setLoadingFuelType(true);
    ApiService.FringeController.FringeFuelTypeAciGet(null, (response: IAPIResponse) => {
      if (response.error === null) {
        setFuelTypes(response.payload);
      } else {
        ToastMessage(i18next.t("error:errorLoadingData"), "error");
      }
      setLoadingFuelType(false);
    });
  };

  const loadFringeBrands = (fuelTypeAciID: number, inProduction: number) => {
    ApiService.FringeController.FringeBrandAciGet(
      +fuelTypeAciID,
      +inProduction === 1 ? true : false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBrands(response.payload);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  const loadFringeModels = (fuelTypeAciID: number, inProduction: number, aciBrandName: string) => {
    ApiService.FringeController.FringeModelAciGet(
      +fuelTypeAciID,
      +inProduction === 1 ? true : false,
      aciBrandName,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setModels(response.payload);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  const loadFringeVersion = (fuelTypeAciID: number, inProduction: number, aciBrandName: string, aciModelName: string) => {
    ApiService.FringeController.FringeVersionAciGet(
      +fuelTypeAciID,
      +inProduction === 1 ? true : false,
      aciBrandName,
      aciModelName,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVersions(response.payload);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  const loadFringeBenefit = (aciCode: string) => {
    setLoadingFringeBenefit(true);
    ApiService.FringeController.FringeBenefitGet(
      aciCode,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setFringeBenefit(response.payload);
          setState(response.payload);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
        setLoadingFringeBenefit(false);
      }
    );
  };

  const updateFringeBenefit = (payload: IVehicleFringeBenefit) => {
    ApiService.FringeController.FringeBenefitUpdate(payload, (response: IAPIResponse) => {
      if (response.error === null) {
        if(response.payload.length > 0){
          dispatch(VehiclesActions.setActiveVehicleForCompany({...vehicle, ...response.payload[0]}))  
        }

        ToastMessage(i18next.t("navigation:fringeUpdated"), "success");
        DismissModal("FringeBenefitModal");
      } else {
        ToastMessage(i18next.t("error:errorLoadingData"), "error");
      }
    });
  };

  useEffect(() => {
    if (state?.aciBrandName) {
      loadFringeModels(state?.fuelTypeAciID, state?.inProduction, state?.aciBrandName);
    }
  }, [state?.aciBrandName, state?.inProduction]);

  useEffect(() => {
    if (state?.aciBrandName && state?.aciModelName) {
      loadFringeVersion(state?.fuelTypeAciID, state?.inProduction, state?.aciBrandName, state?.aciModelName);
    }
  }, [state?.aciBrandName, state?.aciModelName, state?.inProduction]);

  useEffect(() => {
    if (state?.inProduction !== undefined && state?.inProduction !== null) {
      loadFringeBrands(state?.fuelTypeAciID, +state?.inProduction);
    } else {
      setBrands([]);
      setModels([]);
      setVersions([]);
    }
  }, [state?.inProduction]);

  const getInputs = () => {
    let inputs: IMultiFormField[] = [
      {
        width: 100,
        type: "radio",
        name: "inProduction",
        defaultValue: fringeBenefit?.inProduction ? 1 : 0,
        label: i18next.t("form:changeHistoricallyVehicle") as string,
        options: [
          {
            key: 1,
            text: i18next.t("form:vehicleInProdution"),
          },
          {
            key: 0,
            text: i18next.t("form:vehicleNotMoreInProdution"),
          },
          {
            key: 2,
            text: i18next.t("navigation:fringe_custom_option"),
          },
        ],
      },
    ];

    let inputsManual: IMultiFormField[] = [
      {
        width: 50,
        type: "number",
        required: true,
        name: "customPick",
        defaultValue: vehicle?.fringeValue,
        placeholder: i18next.t("navigation:fringe_custom_pick") ?? "",
        label: i18next.t("navigation:fringe_custom_pick") ?? "",
      },
    ];

    let inputsAuto: IMultiFormField[] = [
      {
        width: 50,
        type: "select",
        required: true,
        name: "fuelTypeAciID",
        defaultValue: fringeBenefit?.fuelTypeAciID,
        placeholder: i18next.t("navigation:aci_fuel") ?? "",
        label: i18next.t("navigation:aci_fuel") ?? "",
        options: fuelTypes.map((x: IVehicleFringeFuel) => {
          return {
            key: x.fuelTypeAciID,
            text: x.fuelTypeAciName,
          };
        }),
      },
      {
        width: 50,
        type: "select",
        required: true,
        disabled: !state?.fuelTypeAciID,
        name: "aciBrandName",
        defaultValue: fringeBenefit?.aciBrandName,
        placeholder: i18next.t("navigation:aci_brand") ?? "",
        label: i18next.t("navigation:aci_brand") ?? "",
        options: brands.map((x: IVehicleFringeBrandName) => {
          return {
            key: x.aciBrandName,
            text: x.aciBrandName,
          };
        }),
      },
      {
        width: 50,
        type: "select",
        required: true,
        disabled: !state?.aciBrandName,
        name: "aciModelName",
        defaultValue: fringeBenefit?.aciModelName,
        placeholder: i18next.t("navigation:aci_model") ?? "",
        label: i18next.t("navigation:aci_model") ?? "",
        options: models.map((x: IVehicleFringeModel) => {
          return {
            key: x.aciModelName,
            text: x.aciModelName,
          };
        }),
      },
      {
        width: 50,
        type: "select",
        required: true,
        disabled: !state?.aciModelName,
        name: "aciVersionName",
        defaultValue: fringeBenefit?.aciCode,
        placeholder: i18next.t("navigation:aci_version") ?? "",
        label: i18next.t("navigation:aci_version") ?? "",
        options: versions.map((x: IVehicleFringeVersion) => {
          return {
            key: x.aciCode,
            text: x.aciVersionName,
          };
        }),
      },
      {
        width: 50,
        type: "select",
        required: true,
        disabled: !fringeBenefit,
        name: "fringePercentage",
        defaultValue: vehicle?.fringePercentage,
        placeholder: i18next.t("navigation:fringe_benefit") ?? "",
        label: i18next.t("navigation:fringe_benefit") ?? "",
        options: [
          {
            key: 25,
            text: "(25%) " + fringeBenefit?.fringe25,
          },
          {
            key: 30,
            text: "(30%) " + fringeBenefit?.fringe30,
          },
          {
            key: 50,
            text: "(50%) " + fringeBenefit?.fringe50,
          },
          {
            key: 60,
            text: "(60%) " + fringeBenefit?.fringe60,
          },
        ],
      },
    ];

    return state?.inProduction === "2"
      ? inputs.concat(inputsManual)
      : inputs.concat(inputsAuto);
  };

  const getFringeValueFromPt = (pt: number) => {
    if (fringeBenefit) {
      if (pt === 25) {
        return fringeBenefit.fringe25;
      }
      if (pt === 30) {
        return fringeBenefit.fringe30;
      }
      if (pt === 50) {
        return fringeBenefit.fringe50;
      }
      if (pt === 60) {
        return fringeBenefit.fringe60;
      }
    }

    return 0;
  };

  useEffect(() => {
    if(vehicle?.aciCode){
      loadFringeBenefit(vehicle?.aciCode);
    }
  }, [vehicle]);

  return (
    <>
      {vehicle && (
        <SmartModal
          onBeforeSummon={() => {
            setFuelTypes([]);
            setBrands([]);
            setModels([]);
            setVersions([]);
            setState({});
          
            loadFringeFuelType();
          }}
          modalUniqueId="FringeBenefitModal"
          title={i18next.t("navigation:fringeBenefit")}
          buttons={[
            {
              text: i18next.t("navigation:close"),
              onClick: () => {
                DismissModal("FringeBenefitModal");
              },
            },
            {
              text: i18next.t("navigation:accept"),
              onClick: () => {
                triggerFormValidation("FringeBenefitForm");
              },
            },
          ]}
          modalInnerComponent={
            <div style={{ minWidth: "60vw" }}>
              {!loadingFuelType && (
                <MultiForm
                  formId="FringeBenefitForm"
                  formTitle={""}
                  onChange={(data: any) => {
                    setState(data);
                    if(data?.aciVersionName && data?.aciVersionName !== fringeBenefit?.aciCode){
                      loadFringeBenefit(data?.aciVersionName);
                    }
                  }}
                  onSubmit={(data: any) => {
                    if (state?.inProduction === "2") {
                      updateFringeBenefit({
                        vehicleID: vehicle?.vehicleID,
                        fringeValue: state?.customPick,
                      } as IVehicleFringeBenefit);
                    } else {
                      updateFringeBenefit({
                        vehicleID: vehicle?.vehicleID,
                        aciCode: fringeBenefit?.aciCode,
                        fringeValue: getFringeValueFromPt(+state?.fringePercentage),
                        fringePercentage: +state?.fringePercentage,
                      } as IVehicleFringeBenefit);
                    }
                  }}
                  suppressLayout
                  suppressSubmit
                  inputs={getInputs()}
                />
              )}
            </div>
          }
        />
      )}
    </>
  );
};

export default FringeModal;
