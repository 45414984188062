/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import FuelAnomaly__StatisticsPage_Body from "./FuelAnomaly__StatisticsPage_Body";

/* COMPONENT */
const KProjection__StatisticsPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_statistics_anomaly")} />

      <FuelAnomaly__StatisticsPage_Body />
    </div>
  );
};

export default KProjection__StatisticsPage;
