import { useEffect, useState } from "react";
import { IBillingLastInfo, IPaymentType } from "../../Models/IPayment";
import "./BasicBillingInfoForm.scss";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { Stack } from "@mui/material";
import i18next from "i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IResumeContent } from "../StepSummary/StepSummary";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import { validationPhone } from "../../Utils/Validation";

export interface IBasicBillingInoFormProps {
  selectedSubscriptionDuration: number;
  emit: (state: any) => void;
}

const BasicBillingInfoForm = (props: IBasicBillingInoFormProps) => {
  const [currentState, setCurrentState] = useState<any>({});
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const [loadingBillingLastInfo, setLoadingBillingLastInfo] = useState<boolean>(false); 
  const [billingLastInfo, setBillingLastInfo] = useState<IBillingLastInfo>();
  const [duration, setDuration] = useState<number>(
    props.selectedSubscriptionDuration
  );

  const loadBillingLastInfo = () => {
    setLoadingBillingLastInfo(true);
    ApiService.PaymentController.GetBillingLastInfo(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBillingLastInfo(response.payload);
          setCurrentState(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingBillingLastInfo(false);
      }
    );
  }

  useEffect(() => {
    props.emit({ ...currentState, paymentTypeID: selected });
  }, [currentState]);

  useEffect(() => {
    setDuration(props.selectedSubscriptionDuration);
  }, [props.selectedSubscriptionDuration]);

  useEffect(() => {
    loadPaymentType();
    loadBillingLastInfo();
  }, []);

  const loadPaymentType = () => {
    ApiService.PaymentController.GetPaymentType(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentType(response.payload.filter((x: IPaymentType) => x.enabledInStore));
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const handleSelected = (value: number) => {
    setSelected(value);
    setCurrentState({ ...currentState, paymentTypeID: value });
  };

  const getInputs = () => {
    let inputs: IMultiFormField[] = [
      {
        width: 50,
        type: "text",
        name: "billingName",
        defaultValue: currentState?.billingName,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:name")}`,
      },
      {
        width: 50,
        type: "text",
        name: "billingSurname",
        defaultValue: currentState?.billingSurname,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:surname")}`,
      },
      {
        width: 100,
        type: "email",
        name: "billingEmail",
        disabled: billingLastInfo?.billingEmail ? true : false,
        defaultValue: currentState?.billingEmail,
        required: true,
        label: `${i18next.t("form:email")}`,
      },
      {
        width: 100,
        type: "tel",
        name: "billingPhone",
        defaultValue: currentState?.billingPhone,
        required: true,
        label: `${i18next.t("form:telephonePersonal")}`,
        errorText:
          currentState &&
          (validationPhone(currentState.billingPhone) ||
            !currentState.billingPhone)
            ? ""
            : `${i18next.t("form:validationPhone")}`,
      },
    ];

    inputs.push({
      type: "custom",
      name: "paymentStack",
      width: 100,
      defaultValue: selected,
      element: (
        <div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            marginTop={2}
          >
            {paymentType
              .filter(
                (x: IPaymentType) => x.paymentTypeID === 1 || duration == 2
              )
              .map((item: IPaymentType, i: number) => {
                return (
                  <div
                    key={i}
                    className={
                      "StepPayment-image-box StepPayment-image-box-hover " +
                      (selected === item.paymentTypeID &&
                        "StepPayment-image-box-selected")
                    }
                    onClick={() => handleSelected(item.paymentTypeID)}
                  >
                    <img
                      className="StepPayment-image-paypal"
                      src={"/Images/paymentType_" + item.paymentTypeID + ".png"}
                      alt={item.paymentTypeName}
                    />
                    {item.paymentTypeDescription && (
                      <p className="StepPayment-bankTransfer-title">
                        {i18next.t("form:wireTransfer")}
                        <span className="StepPayment-bankTransfer-subtitle">
                          {item.paymentTypeDescription}
                        </span>
                      </p>
                    )}
                    {selected === item.paymentTypeID && (
                      <CheckCircleOutlineIcon className="StepPayment-icon" />
                    )}
                  </div>
                );
              })}
          </Stack>
        </div>
      ),
    });
    return inputs;
  };

  return (
    <div>
      {!loadingBillingLastInfo && (
        <MultiForm
          suppressSubmit
          suppressLayout
          formTitle={i18next.t("navigation:personaDataPayment")}
          formId={"payment-page-form"}
          inputs={getInputs()}
          onChange={setCurrentState}
          onSubmit={async (data: any) => {
            document.dispatchEvent(new Event("payment-ready-to-go"));
          }}
        />
      )}
    </div>
  );
};

export default BasicBillingInfoForm;
