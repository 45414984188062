import { useEffect, useState } from "react";
import i18next from "i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { ICenterCost, ICenterCostInsert, IDriverCenterCost } from "../../Models/IDriver";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { validationErrorMessages } from "../../Utils/Validation";
import { IUser } from "../../Models/IUser";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import "./CenterCostMainHandler.scss";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import { ICompanyGet } from "../../Models/ICompany";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
export interface ICenterCostMainHandlerProps {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  driverID: number;
}

const CenterCostMainHandler = (props: ICenterCostMainHandlerProps) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [hasErrorCenterCost, setHasErrorCenterCost] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [totalCenterCost, setTotalCenterCost] = useState<number>(0);
  const [driverCenterCostList, setDriverCenterCostList] = useState<IDriverCenterCost[]>([
    {
      driverCostCenterID: 0,
      driverID: 0,
      costCenterID: 0,
      costCenterCode: "",
      costCenterDescription: "",
      driverCostCenterPercentage: 0,
    },
  ]);
  const [loadingDriverCenterCostList, setLoadingDriverCenterCostList] =
    useState<boolean>(false);
  const [centerCostList, setCenterCostList] = useState<ICenterCost[]>([]);
  const [loadingCenterCostList, setLoadingCenterCostList] = useState<boolean>(false);

  const [driverCenterCostListIDs, setDriverCenterCostListIDs] = useState<string[]>([]);

  const DoRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 50);
  };

  const loadDriverCenterCost = (driverID: number) => {
    if (props.loggedUser) {
      setLoadingDriverCenterCostList(true);
      ApiService.DriverController.DriverCenterCostGet(
        driverID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverCenterCostList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDriverCenterCostList(false);
        }
      );
    }
  };

  const loadCenterCost = () => {
    if (props.loggedUser) {
      setLoadingCenterCostList(true);
      ApiService.DriverController.CenterCostGet(null, (response: IAPIResponse) => {
        if (response.error === null) {
          setCenterCostList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingCenterCostList(false);
      });
    }
  };

  const insertCenterCost = (data: ICenterCostInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.DriverController.CenterCostInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:centerCostSuccessfullAdded"), "success");
          DismissModal("CenterCost-modal");
          let centerCostList_: any[] = [...centerCostList];
          centerCostList_.push(response.payload[0]);
          setCenterCostList(centerCostList_);
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessing(false);
      });
    }
  };

  // Center cost API
  const insertCenterCostDriver = (index: number) => {
    if (props.loggedUser) {
      let data: any = { ...driverCenterCostList[index] };
      data["driverID"] = props.driverID;
      setProcessing(true);
      ApiService.DriverController.DriverCenterCostInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:centerCostSuccessfullAdded"), "success");
            setReady(true);
          } else {
            let errors = validationErrorMessages(response.payload);
            if (response.raw.status === 402) {
              ToastMessage(i18next.t("error:" + response.error), "error");
            } else {
              if (errors) {
                for (let e = 0; e < errors.length; e++) {
                  ToastMessage(`${errors[e]}`, "error");
                }
              } else {
                ToastMessage(response.error, "error");
              }
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateCenterCostDriver = (index: number) => {
    if (props.loggedUser) {
      let data: any = { ...driverCenterCostList[index] };
      data["driverID"] = props.driverID;
      setProcessing(true);
      ApiService.DriverController.DriverCenterCostUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:centerCostSuccessfullUpdated"), "success");
          } else {
            let errors = validationErrorMessages(response.payload);
            if (response.raw.status === 402) {
              ToastMessage(i18next.t("error:" + response.error), "error");
            } else {
              if (errors) {
                for (let e = 0; e < errors.length; e++) {
                  ToastMessage(`${errors[e]}`, "error");
                }
              } else {
                ToastMessage(response.error, "error");
              }
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const deleteCenterCostDriver = (index: number, driverCostCenterID?: number) => {
    if (props.loggedUser && driverCostCenterID) {
      setProcessing(true);
      ApiService.DriverController.DriverCenterCostDelete(
        driverCostCenterID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:centerCostSuccessfullDeleted"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const RenderForm = () => {
    return (
      <MultiForm
        inputs={multiformInputs()}
        suppressLayout
        suppressSubmit
        onChange={async (data: any) => {
          FromFormToDataVetor(data);
        }}
      />
    );
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    for (let i = 0; i < driverCenterCostList.length; i++) {
      let rowData: IDriverCenterCost = driverCenterCostList[i];
      multiformInputs.push(
        {
          type: "select",
          label: i18next.t("form:code") as string,
          name: "costCenterID_" + i,
          defaultValue: rowData?.costCenterID,
          required: true,
          width: 40,
          options: centerCostList.map((option: ICenterCost) => {
            return {
              key: option.costCenterID,
              text: (
                option.costCenterCode +
                " - " +
                option.costCenterDescription
              ).toString(),
              disabled:
                driverCenterCostListIDs.indexOf(option.costCenterID.toString()) > -1,
            };
          }),
        },
        {
          type: "custom",
          name: "addCodeCenterCost",
          width: 10,
          element: (
            <div className="multi-form-button-add-record">
              <Button
                variant="contained"
                onClick={() => {
                  SummonModal("CenterCost-modal");
                }}
              >
                <AddIcon />
              </Button>
            </div>
          ),
        },
        {
          type: "number",
          label: i18next.t("form:percentage") as string,
          name: "driverCostCenterPercentage_" + i,
          width: 30,
          required: true,
          defaultValue: rowData.driverCostCenterPercentage,
        },
        {
          type: "custom",
          name: "formButtons",
          element: (
            <div className="multi-form-button-add-record">
              <Tooltip title={i18next.t("navigation:save_center_cost")}>
                <IconButton
                  className="center-cost-button"
                  color="primary"
                  aria-label="upload"
                  component="label"
                  disabled={
                    !rowData.costCenterID && rowData.driverCostCenterPercentage <= 0
                  }
                  onClick={() => {
                    if (rowData.driverCostCenterID > 0) {
                      updateCenterCostDriver(i);
                    } else {
                      insertCenterCostDriver(i);
                    }
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={i18next.t("navigation:delete_center_cost")}>
                <IconButton
                  className="center-cost-button"
                  color="primary"
                  aria-label="upload"
                  component="label"
                  onClick={() => {
                    if (rowData.driverCostCenterID > 0) {
                      deleteCenterCostDriver(i, rowData.driverCostCenterID);
                    }
                    let tmpDriverCenterCostList = [...driverCenterCostList].filter(
                      (x: any, j: number) => j !== i
                    );
                    setDriverCenterCostList(tmpDriverCenterCostList);
                    DoRefreshForm();
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </div>
          ),
        }
      );
    }
    multiformInputs.push({
      type: "custom",
      name: "addNewCenterCostRow",
      element: (
        <AddNewBanner
          forceDisplay
          suppressLayout
          label={i18next.t("generic:newCenterCost")}
          new={() => {
            let driverCenterCost_ = [...driverCenterCostList];
            driverCenterCost_.push({
              driverCostCenterID: 0,
              driverID: 0,
              costCenterID: 0,
              costCenterCode: "",
              costCenterDescription: "",
              driverCostCenterPercentage: 0,
            });
            setDriverCenterCostList(driverCenterCost_);
          }}
        />
      ),
    });
    return multiformInputs;
  };

  const FromFormToDataVetor = (data: any) => {
    let keys: string[] = Object.keys(data);
    let newDriverCenterCostList: any = {};
    for (let i = 0; i < driverCenterCostList.length; i++) {
      let item: IDriverCenterCost = driverCenterCostList[i];
      if (!newDriverCenterCostList[i]) {
        newDriverCenterCostList[i] = {};
      }
      newDriverCenterCostList[i] = { ...item };
    }
    for (let i = 0; i < keys.length; i++) {
      let splKey: string[] = keys[i].split("_");
      if (splKey.length === 2) {
        let keyName: string = splKey[0];
        let keyIndex: string = splKey[1];
        if (data[keys[i]] !== null && data[keys[i]] !== undefined) {
          newDriverCenterCostList[keyIndex][keyName] = data[keys[i]];
        }
      }
    }
    let tmp: IDriverCenterCost[] = Object.values(newDriverCenterCostList);
    setDriverCenterCostList(tmp);
  };

  useEffect(() => {
    let totalCenterCost: number = 0;
    for (var i = 0; i < driverCenterCostList.length; i++) {
      if (
        driverCenterCostList[i].driverCostCenterPercentage !== undefined &&
        driverCenterCostList[i].driverCostCenterPercentage !== null
      ) {
        totalCenterCost += +driverCenterCostList[i].driverCostCenterPercentage;
      }
    }
    setTotalCenterCost(totalCenterCost);
    if (
      driverCenterCostList.length > 0 &&
      totalCenterCost > 0 &&
      (totalCenterCost < 100 || totalCenterCost > 100)
    ) {
      setHasErrorCenterCost(true);
    } else {
      setHasErrorCenterCost(false);
    }
  }, [driverCenterCostList]);

  useEffect(() => {
    let driverCenterCostListIDs: string[] = [];
    for (let i = 0; i < driverCenterCostList.length; i++) {
      if (driverCenterCostList[i].costCenterID > 0) {
        driverCenterCostListIDs.push(driverCenterCostList[i].costCenterID.toString());
      }
    }
    setDriverCenterCostListIDs(driverCenterCostListIDs);
  }, [driverCenterCostList]);

  useEffect(() => {
    if (props?.driverID) {
      loadCenterCost();
      loadDriverCenterCost(props.driverID);
      setReady(true);
    }
  }, [props.driverID]);

  useEffect(() => {
    setLoadingDriverCenterCostList(true);
    setTimeout(() => {
      setLoadingDriverCenterCostList(false);
    }, 50);
  }, [centerCostList]);

  return (
    <div>
      {!ready && (
        <div className="attachments-multi-form-title-wrap">
          {i18next.t("generic:center_cost_enable")}
        </div>
      )}
      {ready && !loadingDriverCenterCostList && (
        <div
          style={{ margin: "0.5em" }}
          className="container-add-form-background multi-form-main-wrap"
        >
          <div className="multi-form-title">{i18next.t("generic:labelCenterCost")}</div>
          {refreshForm && RenderForm()}
          {!refreshForm && RenderForm()}
          {driverCenterCostList.length <= 0 && (
            <Alert severity="warning">
              {i18next.t("message:driverCostCenterNotProvided")}
            </Alert>
          )}
          {driverCenterCostList.length > 0 &&
            totalCenterCost > 0 &&
            (totalCenterCost < 100 || totalCenterCost > 100) && (
              <Alert severity="error">
                <Typography color={"error"}>
                  {totalCenterCost > 0 && totalCenterCost < 100
                    ? (i18next.t("form:validationCenterCostLower") as string)
                    : totalCenterCost > 100
                    ? (i18next.t("form:validationCenterCostBigger") as string)
                    : ""}
                </Typography>
              </Alert>
            )}
        </div>
      )}

      {ready && processing && loadingCenterCostList && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "4em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <SmartModal
        modalUniqueId="CenterCost-modal"
        title={i18next.t("generic:titleAddCenterCost")}
        hideButtons={true}
        modalInnerComponent={
          <MultiForm
            suppressLayout={true}
            inputs={[
              {
                type: "text",
                name: "costCenterCode",
                label: i18next.t("form:code") as string,
                width: 100,
                required: true,
              },
              {
                type: "text",
                label: i18next.t("form:description") as string,
                name: "costCenterDescription",
                width: 100,
                required: true,
              },
            ]}
            formTitle={""}
            submitButtonLabel={i18next.t("form:save") as string}
            onSubmit={(data: any) => {
              let parseData = { ...data };
              if (props.companyUser) {
                parseData["companyID"] = props.companyUser?.companyID;
              }
              insertCenterCost(parseData);
            }}
          />
        }
      />
    </div>
  );
};

export default CenterCostMainHandler;
