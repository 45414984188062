import i18next from "i18next";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import "./VehiclesPage_subPages_Contracts.scss";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import FormContract from "../../../../../../Components/StepVehicles/TabContract/FormContract";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { useEffect, useState } from "react";
import {
  IContractGetProperty,
  IContractGetType,
  IVehicleContractDetailGet,
  IVehicleContractGet,
} from "../../../../../../Models/IContract";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { SummonModal } from "../../../../../../Components/SmartModal/SmartModal";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { Alert } from "@mui/material";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IDriverGet } from "../../../../../../Models/IDriver";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import { currencyDecoder } from "../../../../../../Utils/Decoder";


const VehiclesPage_subPages_Contracts = () => {
  const dispatch = useDispatch();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const drivers: IDriverGet[] = useSelector(
    (state: GlobalState) => state.vehicles.driversForCompany
  );
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [contracts, setContracts] = useState<IVehicleContractGet[]>([]);
  const [contractPropertyList, setContractPropertyList] = useState<
    IContractGetProperty[]
  >([]);
  const [contractType, setContractType] = useState<IContractGetType[]>([]);
  const [companiesList, setCompaniesList] = useState<ICompanyGet[]>([]);
  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [loading3, setLoading3] = useState<boolean>(true);
  const [loading4, setLoading4] = useState<boolean>(true);
  const [loading5, setLoading5] = useState<boolean>(true);

  const loadAllContracts = () => {
    if (loggedUser) {
      ApiService.VehicleContractController.VehicleContractGet(
        vehicle?.vehicleID,
        null,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setContracts(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading1(false);
        }
      );
    }
  };

  const removeDuplicatedNames = (rawList: IContractGetProperty[]) => {
    let smoothList: IContractGetProperty[] = [];
    for (let i = 0; i < rawList.length; i++) {
      if (
        smoothList.filter(
          (x: IContractGetProperty) => x.propertyID === rawList[i].propertyID
        ).length === 0
      ) {
        smoothList.push({ ...rawList[i] });
      }
    }

    return smoothList;
  };

  const loadContractPropertyList = () => {
    ApiService.ContractController.ContractGetPropertyList(
      false,
      true,
      false,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setContractPropertyList(removeDuplicatedNames(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading2(false);
      }
    );
  };

  const loadSupplierList = () => {
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading3(false);
        }
      );
    }
  };

  const loadCompanies = () => {
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompaniesList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading5(false);
        }
      );
    }
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  const loadContractType = () => {
    if (loggedUser) {
      setLoading4(true);
      ApiService.ContractController.ContractGetContractType(
        false,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setContractType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading4(false);
        }
      );
    }
  };

  useEffect(() => {
    loadContractType();
    loadSupplierList();
    loadContractPropertyList();
    loadAllContracts();
    loadCompanies();
  }, []);

  const pageLoading =
    loading1 || loading2 || loading3 || loading4 || refreshForm || loading5;

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_vheicles_tab_nav_contract")}
        rightButtons={[
          {
            label: i18next.t("navigation:_history_contract"),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
        ]}
      />

      {!refreshForm && (
        <ResumeModal
          title={i18next.t("navigation:_history_contract")}
          rows={contracts
            .filter((x: IVehicleContractGet) => x.info.stored)
            .map((x: IVehicleContractGet, i: number) => {
              let type: IContractGetType | undefined = contractType.find(
                (y: IContractGetType) => y.contractTypeID === x.info.contractTypeID
              );
              let data: any = {};
              contractPropertyList.forEach((y: IContractGetProperty) => {
                let prop = x.details.find(
                  (j: IVehicleContractDetailGet) => j.propertyID === y.propertyID
                );
                let value: string = prop?.propertyValue ?? "-";
                let valueOriginal: any = undefined;
                if (value !== "-") {
                  if (y.propertyTypeName === "datetime") {
                    valueOriginal = value;
                    value = displayUTC0_ISODate(value, true);
                  }

                  if (y.isMoney) {
                    value = currencyDecoder(+value, 2);
                  }

                  // proprietario / intestatario
                  if (y.propertyID === 1 || y.propertyID === 2) {
                    let company: ICompanyGet | undefined = companiesList.find(
                      (k: ICompanyGet) => k.companyID === +value
                    );
                    if (company) {
                      value = company.companyBusinessName;
                    } else {
                      value = (i18next.t("error:missingCompanyItem") ?? "").replace(
                        "ID",
                        "ID: " + value
                      );
                    }
                  }
                  // fornitori
                  if (y.propertyID === 8) {
                    let supplier: ISupplierGetSideList | undefined = supplierList.find(
                      (k: ISupplierGetSideList) => k.supplierInfo.supplierID === +value
                    );
                    if (supplier) {
                      value = supplier.supplierInfo.supplierName;
                    } else {
                      value = (i18next.t("error:missingSupplierItem") ?? "").replace(
                        "ID",
                        "ID: " + value
                      );
                    }
                  }
                }

                data["property_" + y.propertyID] = value;
                if (valueOriginal){
                  data["property_" + y.propertyID + "_Original"] = valueOriginal;
                }
              });

              return { ...{"propertyContractTypeID" : type ? type.contractTypeName : "-"}, ...data };
            })}
          headers={
            [
              {
                field: "propertyContractTypeID",
                headerName: i18next.t("form:orderContractType"),
              },
              ...contractPropertyList.map((x: IContractGetProperty) => ({
                field: "property_" + x.propertyID.toString(),
                fieldOriginal: "property_" + x.propertyID + "_Original",
                type: x.isMoney || x.propertyTypeName === "decimal" || x.propertyTypeName === "integer" ? "number" : "",
                headerName: x.propertyClientName.toString(),
              }))
            ]
          }
        />
      )}

      {!pageLoading && vehicle && (
        <div className="add-border margin-top-small">
          {contracts.filter((x: IVehicleContractGet) => x.info.stored === false).length >
            0 && (
            <div style={{ margin: "1em" }}>
              <Alert severity="info">
                {i18next.t("navigation:newContractTermination")}
              </Alert>
            </div>
          )}

          <FormContract
            suppressWarning
            disabled={disposed > 0}
            requestUpdate={() => {
              loadAllContracts();
              doRefreshForm();
            }}
            updateArchive={(
              archivedContract: IVehicleContractGet,
              archivedVehicleContractID: number
            ) => {
              let contracts_: IVehicleContractGet[] = contracts;
              contracts_
                .filter(
                  (x: IVehicleContractGet) =>
                    x.info.vehicleContractID === archivedVehicleContractID
                )
                .map((x: IVehicleContractGet) => {
                  x.info.stored = true;
                  return x;
                });
              contracts_.push(archivedContract);
              setContracts(contracts_);
              doRefreshForm();
            }}
            companyUser={loggedUserCompany}
            loggedUser={loggedUser}
            dataVehicle={vehicle}
            doRefreshForm={() => {}}
          />
        </div>
      )}

      <LoaderBackdrop loading={pageLoading} />
    </div>
  );
};

export default VehiclesPage_subPages_Contracts;
