import i18next from "i18next";
import { useSelector } from "react-redux";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import FormInsurance from "../../../../../../Components/StepVehicles/TabInsurance/FormInsurance";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import "./VehiclesPage_subPages_insurance.scss";
import { useState } from "react";
import { SummonModal } from "../../../../../../Components/SmartModal/SmartModal";
import InsertAssuranceModal from "../../../../../../Components/InsertAssuranceModal/InsertAssuranceModal";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import {
  IInsuranceGet,
  IInsuranceGetDetailType,
} from "../../../../../../Models/IInsurance";
import { IPaymentType } from "../../../../../../Models/IPayment";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { currencyDecoder } from "../../../../../../Utils/Decoder";

const VehiclesPage_subPages_insurance = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const [supplierInsurance, setSupplierInsurance] = useState<ISupplierGetSideList[]>([]);
  const [insuranceDetailGetType, setInsuranceDetailGetType] = useState<
    IInsuranceGetDetailType[]
  >([]);
  const [insurance, setInsurance] = useState<IInsuranceGet[]>([]);
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  /* RETURN */
  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_insurance_title")}
        rightButtons={[
          {
            label: i18next.t("navigation:_insurance_button_history"),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
        ]}
      />

      <InsertAssuranceModal disabled={false} vehicle={vehicle} loggedUser={loggedUser} />

      <ResumeModal
        title={i18next.t("navigation:_insurance_button_history")}
        headers={[
          {
            field: "supplierName",
            headerName: i18next.t("form:vehicleInsuranceCompany"),
          },
          {
            field: "paymentTypeName",
            headerName: i18next.t("form:insuranceModePayment"),
          },
          {
            field: "amount",
            headerName: i18next.t("form:insuranceImportInstallment"),
            type: "number",
          },
          {
            field: "policyNumber",
            headerName: i18next.t("form:insuranceNumber"),
          },
          {
            field: "startDT",
            headerName: i18next.t("form:dateStart"),
            fieldOriginal: "startDTOriginal",
          },
          {
            field: "endDT",
            headerName: i18next.t("form:dateFinish"),
            fieldOriginal: "endDTOriginal",
          }
        ]}
        rows={insurance
          .filter((x: IInsuranceGet) => x.info.stored)
          .map((x: IInsuranceGet) => {
            let supp: ISupplierGetSideList | undefined = supplierInsurance.find(
              (y: ISupplierGetSideList) => y.supplierInfo.supplierID === x.info.supplierID
            );
            let payment: IPaymentType | undefined = paymentType.find(
              (y: IPaymentType) => y.paymentTypeID === x.info.paymentTypeID
            );
            return {
              supplierName: supp ? supp.supplierInfo.supplierName : "-",
              paymentTypeName: payment ? payment.paymentTypeName : "-",
              amount: currencyDecoder(x.info.amount, 2),
              policyNumber: x.info.policyNumber,
              startDT: displayUTC0_ISODate(x.info.startDT),
              startDTOriginal: x.info.startDT,
              endDT: displayUTC0_ISODate(x.info.endDT),
              endDTOriginal: x.info.endDT,
            };
          })}
      />

      {vehicle && !refreshForm && (
        <div className="add-border margin-top-small">
          <FormInsurance
            suppressWarning
            disabled={disposed > 0}
            loggedUser={loggedUser}
            dataVehicle={vehicle}
            doRefreshForm={() => doRefreshForm()}
            emitCoreLists={(
              suppInsuranceList: ISupplierGetSideList[],
              detailGetType: IInsuranceGetDetailType[],
              paymentType: IPaymentType[],
              insurances: IInsuranceGet[]
            ) => {
              setSupplierInsurance([...suppInsuranceList]);
              setInsuranceDetailGetType([...detailGetType]);
              setPaymentType([...paymentType]);
              setInsurance([...insurances]);
            }}
            loadingInsurance={true}
          />
        </div>
      )}
    </div>
  );
};

export default VehiclesPage_subPages_insurance;
