import i18next from "i18next";
import { useSelector } from "react-redux";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import FormTelepass from "../../../../../../Components/StepVehicles/TabTelepass/FormTelepass";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import "./VehiclesPage_subPages_telepass.scss";
import { useNavigate } from "react-router-dom";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import { useEffect, useState } from "react";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import ApiService from "../../../../../../Services/ApiService";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { ITelepassGet, ITelepassGetType } from "../../../../../../Models/ITelepass";
import { Alert } from "@mui/material";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const VehiclesPage_subPages_telepass = () => {
  const navigate = useNavigate();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const [telepassGetType, setTelepassGetType] = useState<ITelepassGetType[]>([]);
  const [supplierTelepass, setSupplierTelepass] = useState<ISupplierGetSideList[]>([]);
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const [telepassList, setTelepassList] = useState<ITelepassGet[]>([]);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [loading3, setLoading3] = useState<boolean>(false);

  const loadCurrentTelepass = (vehicleID: number) => {
    if (loggedUser) {
      setLoading1(true);
      ApiService.TelepassController.TelepassGet(
        null,
        vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload.length > 0) {
              setTelepassList(response.payload);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading1(false);
        }
      );
    }
  };

  const loadSupplierTelepass = () => {
    if (loggedUser) {
      setLoading2(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "TELEPASS",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierTelepass(response.payload);
          }
          setLoading2(false);
        }
      );
    }
  };

  const loadTelepassGetType = () => {
    if (loggedUser) {
      setLoading3(true);
      ApiService.TelepassController.TelepassGetType(null, (response: IAPIResponse) => {
        if (response.error === null) {
          setTelepassGetType(response.payload);
        }
        setLoading3(false);
      });
    }
  };

  useEffect(() => {
    loadSupplierTelepass();
    loadTelepassGetType();
    if (vehicle) {
      loadCurrentTelepass(vehicle.vehicleID);
    }
  }, []);

  const loading = !loading1 && !loading2 && !loading3;

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_vehicle_page_fuel_card_title_telepass")}
        rightButtons={[
          {
            disabled: disposed > 0,
            label: i18next.t(
              "navigation:_vehicle_page_fuel_card_toolbar_button_add_telepass"
            ),
            onClick: () => {
              if (loggedUser && vehicle) {
                SummonModal("AddTelepassOnDashboard");
              }
            },
          },
          {
            label: i18next.t(
              "navigation:_vehicle_page_fuel_card_toolbar_button_telepass_history"
            ),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
        ]}
      />

      <ResumeModal
        title={i18next.t(
          "navigation:_vehicle_page_fuel_card_toolbar_button_telepass_history"
        )}
        rows={telepassList.map((x: ITelepassGet) => {
          let supplier: ISupplierGetSideList | undefined = supplierTelepass.find(
            (y: ISupplierGetSideList) => y.supplierInfo.supplierID === x.supplierID
          );
          let type: ITelepassGetType | undefined = telepassGetType.find(
            (y: ITelepassGetType) => y.telepassTypeID === x.telepassTypeID
          );
          return {
            supplierName: supplier ? supplier.supplierInfo.supplierName : "-",
            telepassTypeName: type ? type.telepassTypeName : "-",
            driverName: x.driverName,
            telepassSerialNumber: x.telepassSerialNumber,
            telepassActivateDT: displayUTC0_ISODate(x.telepassActivateDT, true),
            telepassActivateDTOriginal: x.telepassActivateDT,
            telepassExpirationDT: displayUTC0_ISODate(x.telepassExpirationDT, true),
            telepassExpirationDTOriginal: x.telepassExpirationDT,
          };
        })}
        headers={[
          {
            field: "supplierName",
            headerName: i18next.t(`form:supplierTelepass`),
          },
          {
            field: "telepassTypeName",
            headerName: i18next.t(`form:typeTelepass`),
          },
          {
            field: "driverName",
            headerName: i18next.t(`form:assignDriverName`),
          },
          {
            field: "telepassSerialNumber",
            headerName: i18next.t(`form:numberSerialTelepass`),
          },
          {
            field: "telepassActivateDT",
            headerName: i18next.t(`form:dateStartActivation`),
            fieldOriginal: "telepassActivateDTOriginal",
          },
          {
            field: "telepassExpirationDT",
            headerName: i18next.t(`form:dateEndActivation`),
            fieldOriginal: "telepassExpirationDTOriginal",
          }
        ]}
      />
      <SmartModal
        modalUniqueId={"AddTelepassOnDashboard"}
        title={i18next.t(
          "navigation:_vehicle_page_fuel_card_toolbar_button_add_telepass"
        )}
        modalInnerComponent={
          <div>
            {vehicle && (
              <FormTelepass
                disabled={disposed > 0}
                doRefreshForm={() => {
                  DismissModal("AddTelepassOnDashboard");
                  if (vehicle) {
                    loadCurrentTelepass(vehicle.vehicleID);
                  }
                }}
                loggedUser={loggedUser}
                dataVehicle={vehicle}
              />
            )}
          </div>
        }
      />
      {loading && (
        <div>
          {telepassList.filter((x: ITelepassGet) => x.stored === false).length === 0 && (
            <div style={{ marginTop: "1em" }}>
              <Alert severity="warning">{i18next.t("error:notelepassonvehicle")}</Alert>
            </div>
          )}
          {vehicle && (
            <div className="add-border margin-top-small">
              {telepassList
                .filter((x: ITelepassGet) => x.stored === false)
                .map((x: ITelepassGet, i: number) => {
                  return (
                    <div key={i}>
                      <FormTelepass
                        index={i}
                        suppressWaring
                        disabled={disposed > 0}
                        doRefreshForm={() => {
                          if (vehicle) {
                            loadCurrentTelepass(vehicle.vehicleID);
                          }
                        }}
                        currentTelepass={x}
                        loggedUser={loggedUser}
                        dataVehicle={vehicle}
                      />
                      <hr />
                      {i !==
                        telepassList.filter((x: ITelepassGet) => x.stored === false)
                          .length -
                          1 && <hr />}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VehiclesPage_subPages_telepass;
