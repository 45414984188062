/* STYLE */
import "./Status__VehiclesPage.scss";

/* LIBRARY */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import Status__VehiclesPage_Data from "./Status__VehiclesPage_Data";

/* COMPONENT */
const Status__VehiclesPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_vehicles_status")} />

      <Status__VehiclesPage_Data />
    </div>
  );
};

export default Status__VehiclesPage;
