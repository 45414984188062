import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ICompanyGet } from "../../Models/ICompany";
import { IInsuranceGetDetailType, IInsuranceInsert } from "../../Models/IInsurance";
import { IPaymentType } from "../../Models/IPayment";
import { ISupplierGetSideList } from "../../Models/ISupplier";
import { IUser } from "../../Models/IUser";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import FormSupplier from "../StepSupplier/FormSupplier";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { GetVehicleMinDate } from "../../Pages/DashboardPage/DashboardPage";

import { IVehicleServicePaymentRecurrenceType } from "../../Models/IVehicleService";

let stateClone: any = {};

export interface IInsertAssuranceModalProps {
  disabled: boolean;
  loggedUser?: IUser;
  vehicle?: IVehicleDataGet;
  isNewAssurance?: boolean;
  onCloseModal?: () => void;
}

const InsertAssuranceModal = (props: IInsertAssuranceModalProps) => {
  const [state, setState_] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [loadingCurrentInsurance, setLoadingCurrentInsurance] = useState<boolean>(false);
  const [loadingSupplierInsurance, setLoadingSupplierInsurance] =
    useState<boolean>(false);
  const [supplierInsurance, setSupplierInsurance] = useState<ISupplierGetSideList[]>([]);
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [insuranceDetailGetType, setInsuranceDetailGetType] = useState<
    IInsuranceGetDetailType[]
  >([]);

  const [loadingInsuranceDetailGetType, setLoadingInsuranceDetailGetType] =
    useState<boolean>(false);
  const [stateDetails, setStateDetails] = useState<any[]>([]);
  const [loadingInsuranceGet, setLoadingInsuranceGet] = useState<boolean>(false);
  const [loadingPaymentRecurrenceType, setLoadingPaymentRecurrenceType] =
    useState<boolean>(false);

  const [loadingPaymentType, setLoadingPaymentType] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);

  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<
    IVehicleServicePaymentRecurrenceType[]
  >([]);

  const setState = (state: any) => {
    stateClone = JSON.parse(JSON.stringify(state));
    setState_(state);
  };

  const loadSupplierInsurance = () => {
    if (props.loggedUser) {
      setLoadingSupplierInsurance(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "INSURANCE",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierInsurance(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierInsurance(false);
        }
      );
    }
  };

  const loadPaymentRecurrenceType = () => {
    if (props.loggedUser) {
      setLoadingPaymentRecurrenceType(true);
      ApiService.VehicleServiceController.GetPaymentRecurrenceType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentRecurrenceType(response.payload.filter((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID !== 6));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentRecurrenceType(false);
        }
      );
    }
  };

  const insuranceInsert = (data: IInsuranceInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.InsuranceController.InsuranceInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:insuranceSuccessfullAdded"), "success");
          DismissModal("AddInsuranceModal");
          if (props.onCloseModal) {
            props.onCloseModal();
          }
          setLoadingCurrentInsurance(true);
          setState(null);
          setStateDetails([]);
          setTimeout(() => {
            setLoadingCurrentInsurance(false);
          }, 100);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const loadInsuranceDetailGetType = () => {
    if (props.loggedUser) {
      setLoadingInsuranceDetailGetType(true);
      ApiService.InsuranceController.InsuranceGetDetailType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setInsuranceDetailGetType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsuranceDetailGetType(false);
        }
      );
    }
  };

  const loadInsuranceGet = () => {
    if (props.loggedUser && props.vehicle && loggedUserCompany) {
      setLoadingInsuranceGet(true);
      ApiService.InsuranceController.InsuranceGet(
        props.vehicle.insuranceID,
        props.vehicle?.driverID,
        loggedUserCompany.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (!props.isNewAssurance) {
              setState(response.payload[0].info);
            }
            SummonModal("AddInsuranceModal");
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsuranceGet(false);
        }
      );
    }
  };

  const loadPaymentType = () => {
    if (props.loggedUser) {
      setLoadingPaymentType(true);
      ApiService.PaymentController.GetPaymentType(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentType(false);
        }
      );
    }
  };

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push(
      {
        width: 27,
        type: "select",
        required: true,
        name: "supplierID",
        label: i18next.t("form:vehicleInsuranceCompany") as string,
        defaultValue: state?.supplierID ?? "",
        options: supplierInsurance.map((item: ISupplierGetSideList) => {
          return {
            key: item.supplierInfo.supplierID.toString(),
            text: item.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: 6.33,
        type: "custom",
        name: "supplierAddType",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={props.disabled}
              variant="contained"
              onClick={() => {
                SummonModal("Supplier-Insurance-vehicle-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      },
      {
        width: 33.33,
        required: true,
        type: "datetime",
        name: "startDT",
        disabled: props.disabled,
        label: i18next.t("form:dateStart") as string,
        defaultValue: state?.startDT,
        minDate: GetVehicleMinDate(props.vehicle),
      },
      {
        width: 33.33,
        required: true,
        type: "datetime",
        name: "endDT",
        disabled: props.disabled,
        label: i18next.t("form:dateFinish") as string,
        defaultValue: state?.endDT,
        minDate: state?.startDT,
      }
    );

    for (let i = 0; i < stateDetails.length; i++) {
      multiformInputs.push(
        {
          type: "hidden",
          name: `insuranceDetailID_${i}`,
          defaultValue: stateDetails[i].insuranceDetailID,
        },
        {
          width: 5,
          name: "",
          type: "custom",
          element: <div className="row-added-line"></div>,
        },
        {
          width: 30,
          type: "select",
          required: true,
          disabled: props.disabled,
          name: `insuranceDetailTypeID_${i}`,
          label: i18next.t("form:insuranceDetailType") as string,
          defaultValue: stateDetails[i].insuranceDetailTypeID,
          options: insuranceDetailGetType.map(
            // options: insuranceDetailGetType.filter((x: IInsuranceGetDetailType) => x.insuranceDetailTypeID ).map(
            (insuranceDetail: IInsuranceGetDetailType) => {
              return {
                key: insuranceDetail.insuranceDetailTypeID,
                text: insuranceDetail.insuranceDetailTypeName.toString(),
              };
            }
          ),
        },
        {
          width: 20,
          type: "number",
          required: true,
          disabled: props.disabled,
          defaultValue: stateDetails[i].covarageLimit,
          name: `covarageLimit_${i}`,
          label: i18next.t("form:insuranceMaxValue") as string,
        },
        {
          width: 20,
          type: "number",
          required: true,
          disabled: props.disabled,
          defaultValue: stateDetails[i].deductible,
          name: `deductible_${i}`,
          label: i18next.t("form:serviceShoreLeave") as string,
        },
        {
          width: 20,
          type: "number",
          required: true,
          defaultValue: stateDetails[i].covarage,
          disabled: props.disabled,
          name: `covarage_${i}`,
          label: i18next.t("form:insuranceValue") as string,
        }
      );
    }

    multiformInputs.push(
      {
        width: 25,
        type: "select",
        name: "paymentTypeID",
        label: i18next.t("form:insuranceModePayment") as string,
        defaultValue: state?.paymentTypeID ?? "",
        options: paymentType.map((item: IPaymentType) => {
          return {
            key: item.paymentTypeID,
            text: item.paymentTypeName.toString(),
          };
        }),
      },
      {
        width: 25,
        type: "number",
        name: "amount",
        label: i18next.t("form:insuranceImportInstallment") as string,
        defaultValue: state?.amount,
        required: true,
      },
      {
        type: "select",
        width: 25,
        disabled: props.disabled,
        required: true,
        defaultValue: state?.paymentRecurrenceID,
        name: "paymentRecurrenceID",
        label: i18next.t("form:insuranceRecurrence") as string,
        options: paymentRecurrenceType.map(
          (item: IVehicleServicePaymentRecurrenceType) => {
            return {
              key: item.paymentRecurrenceID,
              text: item.paymentRecurrenceName,
            };
          }
        ),
      },
      {
        width: 25,
        type: "text",
        name: "policyNumber",
        label: i18next.t("form:insuranceNumber") as string,
        defaultValue: state?.policyNumber,
        required: true,
      }
    );

    return multiformInputs;
  };

  useEffect(() => {
    loadSupplierInsurance();
    loadInsuranceDetailGetType();
    loadPaymentType();
    loadPaymentRecurrenceType();
    if (props.vehicle?.insuranceID) {
      loadInsuranceGet();
    }
  }, []);

  return (
    <div>
      <SmartModal
        modalUniqueId="AddInsuranceModal"
        title={i18next.t("navigation:_insurance_title_new")}
        modalInnerComponent={
          <div>
            <div>
              {(props.vehicle || props.isNewAssurance) &&
                !loadingCurrentInsurance &&
                !loadingDetails && (
                  <div>
                    <MultiForm
                      formId={"InsuranceInsertForm"}
                      suppressLayout
                      formTitle={""}
                      inputs={multiformInputs()}
                      onChange={async (data: any) => {
                        setState(mergeObjects(data));
                        let parsedData = mergeObjects(data);
                        parsedData["insuranceDetails"] = [];
                        for (let i = 0; i < stateDetails.length; i++) {
                          parsedData["insuranceDetails"].push({
                            insuranceDetailTypeID:
                              parsedData["insuranceDetailTypeID_" + i],
                            covarageLimit: parsedData["covarageLimit_" + i],
                            deductible: parsedData["deductible_" + i],
                            covarage: parsedData["covarage_" + i],
                          });

                          if (
                            parsedData.insuranceID &&
                            parsedData["insuranceDetailID_" + i] !== null
                          ) {
                            parsedData["insuranceDetails"][i].insuranceDetailID =
                              parsedData["insuranceDetailID_" + i];
                          }
                        }

                        setStateDetails(parsedData["insuranceDetails"]);
                      }}
                      disableForm={processing}
                      onSubmit={async (data: any) => {
                        let parsedData = { ...data };
                        parsedData["vehicleID"] = props.vehicle?.vehicleID;

                        delete parsedData["addInsuranceCompany"];
                        delete parsedData["detailsInsuranceRow"];
                        parsedData["insuranceDetails"] = stateDetails;

                        if (!state.insuranceID) {
                          insuranceInsert(parsedData);
                        }
                      }}
                      suppressSubmit={true}
                    />

                    <div className="margin-vertical-medium">
                      <AttachementMainHandler
                        attachmentFamily={"insurance"}
                        extraGetParametersVector={
                          state?.insuranceID
                            ? [
                                {
                                  name: "insuranceID",
                                  value: state?.insuranceID,
                                },
                              ]
                            : undefined
                        }
                      />
                    </div>

                    <div className="padding-small">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={props.disabled}
                          onClick={() => {
                            triggerFormValidation("InsuranceInsertForm");
                          }}
                          endIcon={<SaveIcon />}
                        >
                          {i18next.t("form:saveInsurance")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        }
      />

      <SmartModal
        modalUniqueId="Supplier-Insurance-vehicle-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={props.loggedUser}
            data={[]}
            forceSupplierTypeCode={"INSURANCE"}
            idModal="Supplier-Insurance-vehicle-modal"
            updateList={(data: ISupplierGetSideList) => {
              let supplierInsurance_: ISupplierGetSideList[] = [...supplierInsurance];
              supplierInsurance_.push(data);
              setSupplierInsurance(supplierInsurance_);
            }}
          />
        }
      />

      <LoaderBackdrop
        loading={
          loadingPaymentType ||
          loadingSupplierInsurance ||
          loadingInsuranceDetailGetType ||
          loadingInsuranceGet ||
          loadingPaymentRecurrenceType
        }
      />
    </div>
  );
};

export default InsertAssuranceModal;

/* {
  width: 100,
  type: "custom",
  name: "addInsuranceCompany",
  element: (
    <AddNewBanner
      suppressLayout
      label={i18next.t("generic:newInsuranceCompany")}
      new={() => {
        let stateDetails_ = [...stateDetails];
        stateDetails_.push({
          insuranceDetailID: 0,
          insuranceDetailTypeID: 0,
          covarageLimit: "",
          deductible: "",
          covarage: "",
        });
        setStateDetails(stateDetails_);
        setLoadingDetails(true);
        setTimeout(() => {
          setLoadingDetails(false);
        }, 100);
      }}
    />
  ),
}, */
