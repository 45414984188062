import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleRoadTaxAdditionalInsert,
  IVehicleRoadTaxAdditionalUpdate,
} from "../../Models/IVehicleRoadTaxAdditional";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleRoadTaxAdditionalController = {
  VehicleRoadTaxAdditionalGet: async (
    additionalRoadTaxID: number,
    vehicleID: number,
    stored: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_ADDITIONAL_GET;
    let parameters: string[] = [];
    if (additionalRoadTaxID) {
      parameters.push(`additionalRoadTaxID=${additionalRoadTaxID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxAdditionalInsert: async (
    body: IVehicleRoadTaxAdditionalInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_ADDITIONAL_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxAdditionalUpdate: async (
    body: IVehicleRoadTaxAdditionalUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_ADDITIONAL_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxAdditionalDelete: async (
    additionalRoadTaxID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_ADDITIONAL_DELETE;
    request.method = "DELETE";
    request.payload = {
      additionalRoadTaxID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleRoadTaxAdditionalController;
