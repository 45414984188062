/* COMPONENTS */
import FuelAnomaly__StatisticsPage_Data from "./FuelAnomaly__StatisticsPage_Data";

/* INTERFACE */
interface IFuelAnomaly__StatisticsPage_Body {
  title?: string | null;
}

/* COMPONENT */
const FuelAnomaly__StatisticsPage_Body = (props: IFuelAnomaly__StatisticsPage_Body) => {
  /* RETURN */
  return (
    <div className="dashboardForm__box">
      {props.title && (
        <h1
          className="text-color-hint"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      )}

      <FuelAnomaly__StatisticsPage_Data />
    </div>
  );
};

export default FuelAnomaly__StatisticsPage_Body;
