import i18next from "i18next";
import { useSelector } from "react-redux";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import "./VehiclesPage_subPages_Orders.scss";
import AddNewBanner from "../../../../../../Components/AddNewBanner/AddNewBanner";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { useEffect, useState } from "react";
import {
  IOrderGetSide,
  IOrderGetSideDetail,
} from "../../../../../../Models/IOrder";
import { IContractGetProperty } from "../../../../../../Models/IContract";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { SummonModal } from "../../../../../../Components/SmartModal/SmartModal";
import { ResumeModal } from "../VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { IAssignmentGet } from "../../../../../../Models/IAssignment";
import { Alert } from "@mui/material";
import DataGridWrap from "../../../../../../Components/DataGridWrap/DataGridWrap";

const VehicleOrderDisplayCard = (props: { order: IOrderGetSide }) => {
  const order = props.order;
  return (
    <div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderContractType")}
        </div>
        {order.info?.contractTypeName ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">{i18next.t("form:orderType")}</div>
        {order.info?.vehicleOrderTypeName ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderSupplier")}
        </div>
        {order.info?.supplierName ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderNumberVehiclesShort")}
        </div>
        {order.info?.numberOfVehicles ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderBrand")}
        </div>
        {order.info?.brandName ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderModel")}
        </div>
        {order.info?.modelName ?? "-"}
      </div>
      <div className="order-display-wrap">
        <div className="order-display-title">
          {i18next.t("form:orderEquipment")}
        </div>
        {order.info?.fittingName ?? "-"}
      </div>
      <hr style={{ opacity: "0.3" }} />
      <strong>{i18next.t("navigation:order-details")}</strong>
      <div>
        <DataGridWrap
          tableName={i18next.t("navigation:_vheicles_tab_nav_order") ?? ""}
          rows={order.details.map((x: IOrderGetSideDetail, i: number) => {
            return {
              companyBusinessName: x.companyBusinessName,
              driverName: x.driverName,
              arrivalDT: displayUTC0_ISODate(x.arrivalDT, true),
              arrivalDTOriginal: x.arrivalDT,
              reference: x.reference,
            };
          })}
          headers={[
            {
              field: "companyBusinessName",
              headerName: i18next.t("form:orderCompany"),
            },
            {
              field: "driverName",
              headerName: i18next.t("form:orderDriver"),
            },
            {
              field: "arrivalDT",
              headerName: i18next.t("form:orderArriveDate"),
              fieldOriginal: "arrivalDTOriginal",
            },
            {
              field: "reference",
              headerName: i18next.t("form:orderNumber"),
            }
          ]}
        />
      </div>
    </div>
  );
};

const VehiclesPage_subPages_Orders = () => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const assignment: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignment
  );

  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(null);

  // State for order side list
  const [orderSideList, setOrderSideList] = useState<IOrderGetSide[]>([]);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [loadingPropertyList, setLoadingPropertyList] =
    useState<boolean>(false);
  const [orderPropertyList, setOrderPropertyList] = useState<
    IContractGetProperty[]
  >([]);
  const [loadingOrderList, setLoadingOrderList] = useState<boolean>(false);

  // API to load order side list
  const loadOrderSideList = () => {
    if (loggedUser) {
      let dId: number | undefined = assignment?.driverID;
      let vehicleId: number | undefined = vehicle?.vehicleID;

      if (dId !== -1) {
        vehicleId = undefined;
      } else {
        dId = undefined;
      }

      if (!vehicleId && !dId) {
        return;
      }
      setLoadingOrderList(true);

      ApiService.OrderController.OrderGetSideList(
        null,
        vehicleId,
        dId,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderSideList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingOrderList(false);
        }
      );
    }
  };

  // Refresh the form
  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 200);
  };

  // Update the form with the selected element of the side list
  const updateCurrent = (item: any) => {
    let parsedItem = { ...item };
    parsedItem = {
      ...item["info"],
      ...{ vehicleOrder: item["details"] },
      ...{ contractTypeProperties: item["contractTypeProperties"] },
    };
    loadOrderPropertyList(parsedItem.contractTypeID);
    setState(parsedItem);
    doRefreshForm();
  };

  // API to load the property list
  const loadOrderPropertyList = (contractTypeID: number) => {
    if (contractTypeID) {
      setLoadingPropertyList(true);
      ApiService.ContractController.ContractGetPropertyList(
        true,
        false,
        true,
        contractTypeID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderPropertyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPropertyList(false);
        }
      );
    }
  };

  // Add a new order
  const newOrder = () => {
    setState(null);
    doRefreshForm();
  };

  /* HOOK EFFECT */
  // Load the page elements
  useEffect(() => {
    doRefreshForm();
  }, []);

  useEffect(() => {
    loadOrderSideList(); // Load order the side list
  }, [assignment]);

  const isPendingOrder = (x: IOrderGetSide) => {
    for (let i = 0; i < x.details.length; i++) {
      if (x.details[i].vehicleID !== null) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_vheicles_tab_nav_order")}
        rightButtons={[
          {
            label: i18next.t("navigation:orderHistory"),
            onClick: () => {
              SummonModal("ResumeModal");
            },
          },
        ]}
      />
      <ResumeModal
        rows={orderSideList
          .filter((x: IOrderGetSide) => !isPendingOrder(x))
          .map((x: IOrderGetSide, i: number) => {
            return {
              contractTypeName: x.info?.contractTypeName ?? "-",
              vehicleOrderTypeName: x.info?.vehicleOrderTypeName ?? "-",
              numberOfVehicles: x.info?.numberOfVehicles ?? "-",
              brandName: x.info?.brandName ?? "-",
              modelName: x.info?.modelName ?? "-",
              fittingName: x.info?.fittingName ?? "-",
              companyBusinessName: x.details
                .map((x: IOrderGetSideDetail) => {
                  return x.companyBusinessName;
                })
                .join(", "),
              driverName: x.details
                .map((x: IOrderGetSideDetail) => {
                  return x.driverName;
                })
                .join(", "),
              arrivalDT: x.details
                .map((x: IOrderGetSideDetail) => {
                  return displayUTC0_ISODate(x.arrivalDT, true);
                })
                .join(", "),
              reference: x.details
                .map((x: IOrderGetSideDetail) => {
                  return x.reference;
                })
                .join(", "),
            };
          })}
        headers={[
          {
            field: "contractTypeName",
            headerName: i18next.t("form:orderContractType"),
          },
          {
            field: "vehicleOrderTypeName",
            headerName: i18next.t("form:orderType"),
          },
          {
            field: "numberOfVehicles",
            headerName: i18next.t("form:orderNumberVehiclesShort"),
          },
          {
            field: "brandName",
            headerName: i18next.t("form:orderBrand"),
          },
          {
            field: "modelName",
            headerName: i18next.t("form:orderModel"),
          },
          {
            field: "fittingName",
            headerName: i18next.t("form:orderEquipment"),
          },
          {
            field: "companyBusinessName",
            headerName: i18next.t("form:orderCompany"),
          },
          {
            field: "driverName",
            headerName: i18next.t("form:orderDriver"),
          },
          {
            field: "arrivalDT",
            headerName: i18next.t("form:orderArriveDate"),
          },
          {
            field: "reference",
            headerName: i18next.t("form:orderNumber"),
          },
        ]}
        title={i18next.t("navigation:orderHistory")}
      />
      {!loadingOrderList && vehicle && (
        <div>
          <AddNewBanner
            label={i18next.t("navigation:addNewOrders")}
            new={() => newOrder()}
          />
          {!refreshForm && (
            <div>
              {orderSideList.length === 0 && (
                <div style={{ width: "100%" }}>
                  <Alert severity="info">
                    {i18next.t("navigation:no_orders_to_see")}
                  </Alert>
                </div>
              )}
              {orderSideList
                .filter((x: IOrderGetSide) => isPendingOrder(x))
                .map((x: IOrderGetSide, i: number) => {
                  return (
                    <div key={i}>
                      <VehicleOrderDisplayCard order={x} />
                    </div>
                  );
                })}
            </div>
          )}
          <LoaderBackdrop
            loading={loadingPropertyList || loadingOrderList || refreshForm}
          />
        </div>
      )}
    </div>
  );
};

export default VehiclesPage_subPages_Orders;
