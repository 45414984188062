import { IAssignmentGet } from "../../../Models/IAssignment";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import FormFuelCards from "./FormFuelCards";
import "./TabFuelCards.scss";

export interface ITabFuelCards {
  currentTab: string;
  dataVehicle: IVehicleDataGet | null;
  loggedUser: IUser | undefined;
  doRefreshForm?: () => void;
  dataAssignment: IAssignmentGet;
}

const TabFuelCards = (props: ITabFuelCards) => {
  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-fuel-cards"}
      id="secondary-tab-fuel-cards"
      aria-labelledby="secondary-tab-fuel-cards"
    >
      {props.currentTab === "secondary-tab-fuel-cards" && (
        <FormFuelCards
          loggedUser={props.loggedUser}
          dataVehicle={props.dataVehicle ?? undefined}
          dataAssignment={props.dataAssignment}
          disabled={!props.dataVehicle?.vehicleID}
          doRefreshForm={props.doRefreshForm}
        />
      )}
    </div>
  );
};

export default TabFuelCards;
