import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

import {
  IBrokerCompanyPost,
  IBrokerCompanyPut,
  IBrokerPost,
  IBrokerPut,
} from "../../Models/IBroker";

const BrokerController = {
  BroketGrantsGet: async (
    brokerID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_GRANT_GET;
    if (brokerID) {
      request.url += "?brokerID=" + brokerID;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  BroketGrantsInsert: async (
    brokerId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_GRANT_INSERT + "?brokerId=" + brokerId;
    request.method = "POST";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  BroketGrantsUpdate: async (
    BrokerGrantID: number,
    permission: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url =
      ApiEndPoints.BROKER_GRANT_UPDATE +
      "?BrokerGrantID=" +
      BrokerGrantID +
      "&permission=" +
      permission;
    request.method = "PUT";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  BroketGrantsDelete: async (
    BrokerGrantID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url =
      ApiEndPoints.BROKER_GRANT_DELETE + "?BrokerGrantID=" + BrokerGrantID;
    request.method = "DELETE";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  BrokerInvitationsGet: async (
    userId: number,
    brokerId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_INVITATION_GET;
    let parameters: string[] = [];
    if (userId) {
      parameters.push(`userId=${userId}`);
    }
    if (brokerId) {
      parameters.push(`brokerId=${brokerId}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  BrokerInvitationsInsert: async (
    userId: number,
    brokerId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_INVITATION_INSERT;
    let parameters: string[] = [];
    if (userId) {
      parameters.push(`userId=${userId}`);
    }
    if (brokerId) {
      parameters.push(`brokerId=${brokerId}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "POST";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  BrokerGet: async (
    BrokerID?: number,
    brokerAccountId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_GET;
    let parameters: string[] = [];
    if (BrokerID) {
      parameters.push(`BrokerID=${BrokerID}`);
    }
    if (brokerAccountId) {
      parameters.push(`brokerAccountId=${brokerAccountId}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get broker company info
  BrokerCompanyGet: async (
    CompanyID: number,
    BrokerID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_GET_COMPANY;
    let parameters: string[] = [];
    if (CompanyID) {
      parameters.push(`CompanyID=${CompanyID}`);
    }
    if (BrokerID) {
      parameters.push(`BrokerID=${BrokerID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get broker ID
  BrokerGetID: async (
    brokerID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_GET_ID;
    let parameters: string[] = [];
    if (brokerID) {
      parameters.push(`brokerID=${brokerID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* INSERT */
  // Insert broker info
  BrokerInsert: async (
    body: IBrokerPost,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert broker company info
  BrokerCompanyInsert: async (
    body: IBrokerCompanyPost,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_INSERT_COMPANY;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* UPDATE */
  // Update broker info
  BrokerUpdate: async (
    body: IBrokerPut,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update broker company info
  BrokerCompanyUpdate: async (
    body: IBrokerCompanyPut,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_UPDATE_COMPANY;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* DELETE */
  // Delete broker info
  BrokerDelete: async (
    brokerID: number,
    brokerAccountID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_DELETE;
    request.method = "DELETE";
    request.payload = {
      brokerID,
      brokerAccountID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Delete broker company info
  BrokerCompanyDelete: async (
    brokerID: number,
    companyID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7232";
    request.url = ApiEndPoints.BROKER_DELETE_COMPANY;
    request.method = "DELETE";
    request.payload = {
      brokerID,
      companyID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default BrokerController;
