/* REACT */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/* STYLE */
import "./BlueToolBar.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* UTILS */
import useSticky from "../../Utils/useSticky";

/* MODELS */
import { IUser } from "../../Models/IUser";

/* REDUCER */
import { GlobalState } from "../../Reducers/RootReducer";

/* COMPONENTS */
import { dateIsPast } from "../MultiForm/SpecialInputs/StrongDatePicker";
import FlagPicker from "../FlagPicker/FlagPicker";

/* MUI */
import { Button } from "@mui/material";
// Icons
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";

/* COMPONENT */
const BlueToolbar = () => {
  /* REDUX */
  // USER
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  // IMPERSONATE
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* STATE */
  const [path, setPath] = useState<string>(window.location.pathname);

  /* NAGIVATE */
  const navigate = useNavigate();

  /* STICKY */
  const { sticky, stickyRef } = useSticky();

  /* LOCATION */
  const location = useLocation();

  /* USE EFFECT HOOK */
  useEffect(() => {
    setPath(window.location.pathname);
  }, [location]);

  /* RETURN */
  return (
    <>
      <nav ref={stickyRef} className={"nav" + (sticky ? " sticky" : "")}>
        <div className="blue-toolbar-main-wrap">
          <div className="blue-toolbar-left-wrap">
            <div></div>

            <div
              onClick={() => {
                navigate(AppRoutes.HOME_PAGE_ROUTE);
              }}
            >
              <img className="logo" src="/Images/logo-dark.svg" />
            </div>
          </div>

          <div className="blue-toolbar-main-wrap-right">
            <FlagPicker />

            {path !== AppRoutes.LOGIN_ROUTE && (
              <Button
                onClick={() => {
                  navigate(loggedUser ? AppRoutes.LOGOUT_ROUTE : AppRoutes.LOGIN_ROUTE);
                }}
                variant="contained"
                startIcon={loggedUser ? <LogoutIcon /> : <PersonIcon />}
              >
                {loggedUser
                  ? i18next.t("navigation:logout")
                  : i18next.t("navigation:login")}
              </Button>
            )}
          </div>
        </div>
      </nav>

      {sticky && (
        <div
          style={{
            height: `${stickyRef.current?.clientHeight}px`,
          }}
        />
      )}
    </>
  );
};

export default BlueToolbar;
