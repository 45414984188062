import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IPaymentsRoadTaxBill } from "../../../../../../Models/IPaymentsRoadTaxBill";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import Payments__AdditionalServicesPage_ToggleButtons from "../Payments__AdditionalServicesPage_ToggleButtons";

const PaymentRegister = () => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const [roadTaxBillRows, setRoadTaxBillRows] = useState<
    IPaymentsRoadTaxBill[]
  >([]);

  const RoadTaxBillGet = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxBillGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setRoadTaxBillRows(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  useEffect(() => {
    RoadTaxBillGet();
  }, []);

  const FormatData = (props: GridRenderCellParams) => {
    return <div>{displayUTC0_ISODate(props.value, true)}</div>;
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "requestDT",
      headerName: "DATA DELLA RICHIESTA",
      renderCell: FormatData,
    },
    {
      field: "roadTaxBillName",
      headerName: "BOLLO",
    },
    {
      field: "roadTaxBillDescription",
      headerName: "DESCRIZIONE",
    },
    {
      field: "roadTaxBillURL",
      headerName: "URL",
    },
  ];

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_stamp_payments_page_title")}
      />

      <div className="dashboardForm__box">
        <div className="display-flex-horizontal-start">
          <Payments__AdditionalServicesPage_ToggleButtons position="3" />
        </div>
        <Box className="dashboardForm__data">
          <DataGridWrap rows={roadTaxBillRows} headers={columnsDef} />
        </Box>
      </div>
    </div>
  );
};

export default PaymentRegister;
