import i18next from "i18next";
import { useSelector } from "react-redux";
import { ICompanyGet } from "../../../../Models/ICompany";
import { GlobalState } from "../../../../Reducers/RootReducer";
import { IUser } from "../../../../Models/IUser";
import { useEffect, useState } from "react";
import ApiService from "../../../../Services/ApiService";
import { IAPIResponse } from "../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../Utils/Toastify";
import { Tab, Tabs, Button, CircularProgress, Alert } from "@mui/material";
import DataGridWrap from "../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ISupplierGetSideList } from "../../../../Models/ISupplier";
import { IDriverGet } from "../../../../Models/IDriver";
import "./DownloadDataPage.scss";
import { IFleetManager } from "../../../../Models/IFleetManager";
import { IVehicleDataGet, IVehicleKilometersEntries } from "../../../../Models/IVehicles";
import moment from "moment";
import { renderBrandImageGrid } from "../VehiclesPage/Status__VehiclesPage/Status__VehiclesPage_Data";
import { IOrderGetSide } from "../../../../Models/IOrder";
import { ITelepassGet } from "../../../../Models/ITelepass";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { formatPlate } from "../VehiclesPage/VehiclesPage";
import { IFuelCardsGet } from "../../../../Models/IFuelCards";
import { IInsuranceGet } from "../../../../Models/IInsurance";
import { displayUTC0_ISODate } from "../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import HeaderPageDashboard from "../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import { ITelepassUsage } from "../../../../Models/ITelepassUsage";
import { IFuelSupplyType, IVehicleFuelSupply } from "../../../../Models/IVehicleFuelSupply";
import EuroIcon from "@mui/icons-material/Euro";
import { ITicketDetail, ITicketInfo } from "../../../../Models/ITicket";
import DownloadIcon from "@mui/icons-material/Download";
import { currencyDecoder } from "../../../../Utils/Decoder";


const DownloadDataPage = () => {
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);
  const [loadingSupplierList, setLoadingSupplierList] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [tabs, setTabs] = useState<string[]>([]);
  const [value, setValue] = useState<string>("_data_suppliers");
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [loadingFleetManager, setLoadingFleetManager] = useState<boolean>(false);
  const [fleetManager, setFleetManager] = useState<IFleetManager[]>([]);
  const [loadingVehiclesList, setLoadingVehiclesList] = useState<boolean>(false);
  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);
  const [loadingOrderList, setLoadingOrderList] = useState<boolean>(false);
  const [orderList, setOrderList] = useState<IOrderGetSide[]>([]);
  const [loadingTelepassList, setLoadingTelepassList] = useState<boolean>(false);
  const [telepassList, setTelepassList] = useState<ITelepassGet[]>([]);
  const [loadingCardsList, setLoadingCardsList] = useState<boolean>(false);
  const [cardsList, setCardsList] = useState<IFuelCardsGet[]>([]);
  const [loadingVehicleKilometersEntries, setLoadingVehicleKilometersEntries] = useState<boolean>(false);
  const [entriesVehicleKilometersEntries, setEntriesVehicleKilometersEntries] = useState<IVehicleKilometersEntries[]>([]);
  const [insuranceList, setInsuranceList] = useState<IInsuranceGet[]>([]);
  const [loadingInsuranceList, setLoadingInsuranceList] = useState<boolean>(false);
  const [loadingTelepassUsage, setLoadingTelepassUsage] = useState<boolean>(false);
  const [telepassUsageList, setTelepassUsageList] = useState<ITelepassUsage[]>([]);
  const [loadingFuelSupplyList, setLoadingFuelSupplyList] = useState<boolean>(false);
  const [fuelSupplyList, setFuelSupplyList] = useState<IVehicleFuelSupply[]>([]);
  const [loadingFuelSupplyTypes, setLoadingFuelSupplyTypes] = useState<boolean>(false);
  const [fuelSupplyTypes, setFuelSupplyTypes] = useState<IFuelSupplyType[]>([]);
  const [loadingTicketList, setLoadingTicketList] = useState<boolean>(false);
  const [ticketList, setTicketList] = useState<ITicketInfo[]>([]);
  const [downloadAllLoading, setDownloadAllLoading] = useState<boolean>(false);

  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanyList(false);
        }
      );
    }
  };

  const loadSuppliersList = () => {
    setLoadingSupplierList(true);
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  const loadDriverList = () => {
    setLoadingDriverList(true);
    ApiService.DriverController.DriverGet(
      null, 
      null, 
      (response: IAPIResponse) => {
      if (response.error === null) {
        setDriverList(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingDriverList(false);
    });
  }

  const loadFleetManager = () => {
    setLoadingFleetManager(true);
    if (loggedUser) {
      ApiService.FleetManagerController.FleetManagerGet((response: IAPIResponse) => {
        if (response.error === null) {
          setFleetManager(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFleetManager(false);
      });
    }
  };

  const loadVehiclesList = () => {
    setLoadingVehiclesList(true);
    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehiclesList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehiclesList(false);
      }
    );
  };

  const loadOrderList = () => {
    if (loggedUser) {
      setLoadingOrderList(true);
      ApiService.OrderController.OrderGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingOrderList(false);
        }
      );
    }
  };

  const loadTelepassList = () => {
    if (loggedUser) {
      setLoadingTelepassList(true);
      ApiService.TelepassController.TelepassGet(
        null,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassList(false);
        }
      );
    }
  };

  const loadCardsList = () => {
    if (loggedUser) {
      setLoadingCardsList(true);
      ApiService.FuelCardsController.FuelCardsGet(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCardsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCardsList(false);
        }
      );
    }
  };

  const loadVehicleKilometersEntries = () => {
    setLoadingVehicleKilometersEntries(true);
    ApiService.VehicleKilometersEntriesController.GetVehicleKilometersEntries(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setEntriesVehicleKilometersEntries(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehicleKilometersEntries(false);
      }
    );
  };

  const loadInsuranceList = () => {
    if (loggedUser) {
      setLoadingInsuranceList(true);
      ApiService.InsuranceController.InsuranceGet(
        null,
        null,
        companyUser?.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setInsuranceList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsuranceList(false);
        }
      );
    }
  };

  const loadTelepassUsage = () => {
    if (loggedUser) {
      setLoadingTelepassUsage(true);
      ApiService.TelepassUsageController.TelepassUsageGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassUsageList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassUsage(false);
        }
      );
    }
  };

  const loadFuelSupplyList = () => {
    if (companyUser) {
      setLoadingFuelSupplyList(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyGet(
        null,
        companyUser?.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelSupplyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelSupplyList(false);
        }
      );
    }
  };

  const loadFuelSupplyType = () => {
    if (loggedUser) {
      setLoadingFuelSupplyTypes(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyTypeGet(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelSupplyTypes(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelSupplyTypes(false);
        }
      );
    }
  };

  const loadTicketsList = () => {
    setLoadingTicketList(true);

    ApiService.TicketController.GetTicketList(
      null,
      null,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          setTicketList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingTicketList(false);
      }
    );
  };

  const renderPlate = (props: GridRenderCellParams) => {
    return <div>{formatPlate(props.value)}</div>;
  };

  const renderCost = (props: GridRenderCellParams) => {
    return <div>{props.value ? (currencyDecoder(props.value, 2)) : ""}</div>;
  };

  const renderSource = (props: GridRenderCellParams) => {
    return (
      <div>
        {i18next
          .t("navigation:km_code_source_" + props.row.kmDetectionSourceCode)
          .toLocaleUpperCase()}
      </div>
    );
  };

  const renderServed = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value ? i18next.t("message:yes") : i18next.t("message:no")}
      </div>
    );
  };
  
  const renderStatus = (props: GridRenderCellParams) => {
    let color = "#fff";
    if (props.row.kmDetectionStatusCode === "ACCEPTED") {
      color = "rgb(115 255 96)";
    }
    if (props.row.kmDetectionStatusCode === "PENDING") {
      color = "rgb(255 197 157)";
    }
    if (props.row.kmDetectionStatusCode === "REFUSED") {
      color = "rgb(255 96 96)";
    }
    return (
      <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
        <div style={{ background: color }} className="status-ball"></div>
        {i18next
          .t("navigation:km_code_status_" + props.row.kmDetectionStatusCode)
          .toLocaleUpperCase()}
      </div>
    );
  };

  const StatusTicket = (props: GridRenderCellParams) => {
    let tmpTickets: any[] = [...ticketList];
    let color: string = "";
    let now = new Date().getTime();
    let expirationDT = new Date(props.row.ticketExpirationDTFormat).getTime();
    if (now > expirationDT && props.row.ticketStatusID !== 3) {
      color = "red";
    } else {
      switch (props.row.ticketStatusID) {
        case 1:
          color = "white";
          break;
        case 3:
          color = "green";
          break;
        case 5:
          color = "orange";
          break;
      }
    }

    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EuroIcon
            className="euro-wrapper"
            style={{
              backgroundColor: color,
              color:
                props.row.ticketStatusID === 1 && color !== "red" ? "black" : "white",
              border:
                props.row.ticketStatusID === 1 && color !== "red"
                  ? "1px solid black"
                  : "",
            }}
          />
          <div style={{ marginLeft: "0.5em" }}>{props.value}</div>
        </div>
      </>
    );
  };

  const renderAmount = (props: GridRenderCellParams) => {
    let measurementUnitSymbol = fuelSupplyTypes.find(
      (x: IFuelSupplyType) => x.fuelSupplyTypeID === props.row.fuelSupplyTypeID
    )?.measurementUnitSymbol;
    let measurementUnitName = fuelSupplyTypes.find(
      (x: IFuelSupplyType) => x.fuelSupplyTypeID === props.row.fuelSupplyTypeID
    )?.measurementUnitName;
    return (
      <div>
        {props.value +
          " - " +
          measurementUnitName +
          " (" +
          measurementUnitSymbol +
          ")"}
      </div>
    );
  };

  const renderOtherCost = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value.map((x: any) => {
          return (
            <p>
              {x.fuelSupplyServiceName}: {x.fuelSupplyServiceCost} €
            </p>
          )
        })}
      </div>
    );
  };
  
  const GetDownloadAllAttachment = () => {
    setDownloadAllLoading(true);
    ApiService.AccountController.GetDownloadAllAttachment(
      (response: IAPIResponse) => {
        if (response.error === null) {
          response.payload.blob().then((blob: any) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "download-data.zip";
            a.click();
            setDownloadAllLoading(false);
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);
          });
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  useEffect(() => {
    setTabs([
      "_data_suppliers",
      "_data_drivers",
      "_data_companies",
      "_fleet_manager",
      "_vehicles_status",
      "_vehicles_orders",
      "_vehicles_telepass",
      "_vehicles_cards",
      "_vehicles_km",
      "_insurance_title",
      "_telepass_page_title",
      "_forniture_page_title",
      "_add_services_tickets",
    ]);
  }, []);

  useEffect(() => {
    if(value === "_data_suppliers" && supplierList.length === 0){
      loadSuppliersList();
    }else if(value === "_data_drivers" && driverList.length === 0){
      loadDriverList();
    }else if(value === "_fleet_manager" && fleetManager.length === 0){
      loadFleetManager();
    }else if(value === "_data_companies" && companyList.length === 0){
      loadCompanyList();
    }else if(value === "_vehicles_status" && vehiclesList.length === 0){
      loadVehiclesList();
    }else if(value === "_vehicles_orders" && orderList.length === 0){
      loadOrderList();
    }else if(value === "_vehicles_telepass" && telepassList.length === 0){
      loadTelepassList();
    }else if(value === "_vehicles_cards" && cardsList.length === 0){
      loadCardsList();
    }else if(value === "_vehicles_km" && entriesVehicleKilometersEntries.length === 0){
      if(driverList.length === 0){
        loadDriverList();
      }
      if(vehiclesList.length === 0){
        loadVehiclesList();
      }
      loadVehicleKilometersEntries();
    }else if(value === "_insurance_title" && insuranceList.length === 0){
      loadInsuranceList();
    }else if(value === "_telepass_page_title" && telepassUsageList.length === 0){
      loadTelepassUsage();
    }else if(value === "_forniture_page_title" && fuelSupplyList.length === 0){
      loadFuelSupplyType();
      loadFuelSupplyList();
    }else if(value === "_add_services_tickets" && ticketList.length === 0){
      loadTicketsList();
    }
  }, [value]);
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard subtitle={i18next.t("navigation:" + value)} title={i18next.t("navigation:_download_data")} />
      
      <div className="dashboardForm__box">
        <Alert severity="warning">
          {i18next.t("generic:canUseApplicationOnlyForDownload")}
        </Alert>
        <div style={{display: "flex", marginBottom: "0.5em", flexDirection: "row-reverse", alignItems: "end"}}>
          <Button
            className="btn-secondary "
            onClick={() => {
              GetDownloadAllAttachment();
            }}
            variant={"contained"}
            startIcon={
              downloadAllLoading ? <CircularProgress size={25} sx={{color: "#fff" }}/> : <DownloadIcon />
            }
          >
            {i18next.t("generic:downloadAllAttachments")}
          </Button>
        </div>
        <div style={{display: "flex", marginTop: "1em"}}>
          {tabs && (
            <Tabs
              className="download-data-tabs"
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={(e: any, data: string) => {
                setValue(data)
              }}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {tabs.map((x: string, i: number) => {
                return (
                  <Tab key={i} value={x} label={i18next.t("navigation:" + x)} id={"vertical-tab-" + i} aria-controls={"vertical-tabpanel-" + i} />
                );
              })}
            </Tabs>
          )}
          <div className="download-data-wrapper">
            <div
              role="tabpanel"
              hidden={value !== "_data_suppliers"}
              id={`vertical-tabpanel-data_suppliers`}
              aria-labelledby={`vertical-tab-data_suppliers`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "supplierName",
                    headerName: i18next.t("form:nameSupplier"),
                  },
                  {
                    field: "supplierVAT",
                    headerName: i18next.t("form:vatNumber"),
                  },
                  {
                    field: "supplierPhone",
                    headerName: i18next.t("form:contactCenter"),
                  },
                  {
                    field: "supplierReferentName",
                    headerName: i18next.t("form:referent"),
                  },
                  {
                    field: "supplierReferentEmail",
                    headerName: i18next.t("form:emailReferent"),
                  },
                  {
                    field: "supplierReferentPhone",
                    headerName: i18next.t("form:telephoneReferent"),
                  },
                  {
                    field: "supplierCity",
                    headerName: i18next.t("form:city"),
                  }
                ]}
                rows={supplierList.map((x: ISupplierGetSideList) => { return {
                  supplierID: x.supplierInfo.supplierID,
                  supplierName: x.supplierInfo.supplierName,
                  supplierVAT: x.supplierInfo.supplierVAT,
                  supplierPhone: x.supplierInfo.supplierPhone,
                  supplierReferentName: x.supplierInfo.supplierReferentName,
                  supplierReferentEmail: x.supplierInfo.supplierReferentEmail,
                  supplierReferentPhone: x.supplierInfo.supplierReferentPhone,
                  supplierCity: x.supplierInfo.supplierAddressCity,
                }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_data_drivers"}
              id={`vertical-tabpanel-data_drivers`}
              aria-labelledby={`vertical-tab-data_drivers`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "driverName",
                    headerName: "Nominativo",
                  },
                  {
                    field: "driverCompany",
                    headerName: "Azienda",
                  },
                  {
                    field: "driverType",
                    headerName: "Tipo utente",
                  },
                  {
                    field: "driverEmail",
                    headerName: "Email",
                  },
                  {
                    field: "driverPhone",
                    headerName: "Telefono",
                  },
                  {
                    field: "driverCity",
                    headerName: "Città",
                  },
                ]}
                rows={driverList.map((x: IDriverGet) => {
                  return {
                    driverID: x.driverInfo.driverID,
                    driverCompany: x.driverInfo.companyBusinessName,
                    driverType: x.driverInfo.driverTypeName,
                    driverName: x.driverInfo.driverName + (x.driverInfo.driverSurname ? " " + x.driverInfo.driverSurname : ""),
                    driverEmail: x.driverInfo.driverEmail,
                    driverPhone: x.driverInfo.driverPhone || "-",
                    driverCity: x.driverInfo.driverAddressCity,
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_vehicles_status"}
              id={`vertical-tabpanel-vehicles_status`}
              aria-labelledby={`vertical-tab-vehicles_status`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "plate",
                    headerName: i18next.t("form:plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "contractType",
                    headerName: i18next.t("navigation:contractTabTitle"),
                  },
                  {
                    field: "brand",
                    headerName: i18next.t("form:orderBrand"),
                    renderCell: renderBrandImageGrid,
                  },
                  {
                    field: "model",
                    headerName: i18next.t("form:orderModel"),
                  },
                  {
                    field: "fitting",
                    headerName: i18next.t("form:orderEquipment"),
                  },
                  {
                    field: "chassis",
                    headerName: i18next.t("form:_mctc_numeroTelaio"),
                  },
                  {
                    field: "revision",
                    headerName: i18next.t("form:lastWarrantyCheck"),
                    fieldOriginal: "revisionOriginal",
                  },
                  {
                    field: "duty",
                    headerName: i18next.t("form:dateExpirePayVehicle"),
                    fieldOriginal: "dutyOriginal",
                  },
                ]}
                rows={vehiclesList.map((x: IVehicleDataGet) => { 
                  return {
                    vehicleID: x.vehicleID,
                    plate: x.vehicleLicensePlate,
                    contractType: x.contractTypeName || "-",
                    brand: x.brandDescription,
                    model: x.modelDescription?.replace(/\s\s+/g, " "),
                    fitting: x.fittingDescription,
                    chassis: x.vehicleChassis,
                    revision: x.vehicleLastInspectionDT
                      ? displayUTC0_ISODate(x.vehicleLastInspectionDT, true)
                      : "-",
                    revisionOriginal: x.vehicleLastInspectionDT,
                    duty: x.vehicleExpireRoadTaxDT
                      ? displayUTC0_ISODate(x.vehicleExpireRoadTaxDT, true)
                      : "-",
                    dutyOriginal: x.vehicleExpireRoadTaxDT,
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_data_companies"}
              id={`vertical-tabpanel-data_companies`}
              aria-labelledby={`vertical-tab-data_companies`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "companyBusinessName",
                    headerName: i18next.t("form:orderCompany"),
                  },
                  {
                    field: "companyVAT",
                    headerName: i18next.t("form:vatNumber"),
                  },
                  {
                    field: "companyFiscalCode",
                    headerName: i18next.t("form:taxIdCode"),
                  },
                  {
                    field: "companyReferentName",
                    headerName: i18next.t("form:nameAlias"),
                  },
                  {
                    field: "companyReferentEmail",
                    headerName: i18next.t("form:email"),
                  },
                  {
                    field: "companyReferentPhone",
                    headerName: i18next.t("form:telephone"),
                  },
                  {
                    field: "companyBillingCity",
                    headerName: i18next.t("form:city"),
                  }
                ]}
                rows={companyList.map((x) => {return x})}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_fleet_manager"}
              id={`vertical-tabpanel-fleet_manager`}
              aria-labelledby={`vertical-tab-fleet_manager`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "accountName",
                    headerName: i18next.t("form:name"),
                  },
                  {
                    field: "accountSurname",
                    headerName: i18next.t("form:surname"),
                  },
                  {
                    field: "accountEmail",
                    headerName: i18next.t("form:email"),
                  },
                  {
                    field: "accountFiscalCode",
                    headerName: i18next.t("form:taxIdCode"),
                  },
                  {
                    field: "accountPhone",
                    headerName: i18next.t("form:telephonePersonal"),
                  },
                ]}
                rows={fleetManager.map((x) => { return x})}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_vehicles_orders"}
              id={`vertical-tabpanel-vehicles_orders`}
              aria-labelledby={`vertical-tab-vehicles_orders`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "brandImage",
                    headerName: i18next.t("form:service_logo"),
                    renderCell: renderBrandImageGrid,
                  },
                  {
                    field: "reference",
                    headerName: i18next.t("form:orderNumber"),
                  },
                  {
                    field: "orderType",
                    headerName: i18next.t("form:orderType"),
                  },
                  {
                    field: "supplier",
                    headerName: i18next.t("form:vehicleSupplier"),
                  },
                  {
                    field: "brand",
                    headerName: i18next.t("form:orderBrand"),
                  },
                  {
                    field: "model",
                    headerName: i18next.t("form:orderModel"),
                  },
                  {
                    field: "fitting",
                    headerName: i18next.t("form:orderEquipment"),
                  },
                  {
                    field: "contract",
                    headerName: i18next.t("navigation:contractTypeName"),
                  },
                  {
                    field: "date",
                    headerName: i18next.t("navigation:arrivalDate"),
                    fieldOriginal: "dateOriginal",
                  },
                ]}
                rows={orderList.map((x: IOrderGetSide) => { 
                  return {
                    vehicleOrderID: x.info.vehicleOrderID,
                    supplier: x.info.supplierName,
                    orderType: x.details.length > 1 ? "Multiplo" : "Singolo",
                    reference: x.info.vehicleOrderNumber.toString(),
                    brandImage: x.info.brandName,
                    model: x.info.modelName,
                    fitting: x.info.fittingName,
                    contract: x.info.contractTypeName || "-",
                    date:
                      x.details.length > 0
                        ? displayUTC0_ISODate(x.details[0].arrivalDT, true)
                        : "",
                    dateOriginal: x.details.length > 0 ? x.details[0].arrivalDT : undefined,
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_vehicles_telepass"}
              id={`vertical-tabpanel-vehicles_telepass`}
              aria-labelledby={`vertical-tab-vehicles_telepass`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "telepassSerialNumber",
                    headerName: "Seriale",
                  },
                  {
                    field: "telepassPlate",
                    headerName: i18next.t("navigation:_ticket_modal_detail_plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "telepassSupplier",
                    headerName: i18next.t("form:vehicleSupplier"),
                  },
                  {
                    field: "telepassDevice",
                    headerName: i18next.t("form:typeTelepass"),
                  },
                  {
                    field: "telepassDriver",
                    headerName: i18next.t("navigation:_ticket_modal_detail_driver"),
                  },
                  {
                    field: "telepassDateFrom",
                    headerName: i18next.t("navigation:start"),
                    fieldOriginal: "telepassDateFromOriginal",
                  },
                  {
                    field: "telepassDateTo",
                    headerName: i18next.t("navigation:end"),
                    fieldOriginal: "telepassDateToOriginal",
                  },
                ]}
                rows={telepassList.map((x: ITelepassGet) => { 
                  return {
                    telepassPlate: x.vehicleLicensePlate,
                    telepassSupplier: x.supplierName,
                    telepassDevice: x.telepassTypeName,
                    telepassDriver: x.driverName,
                    telepassSerialNumber: x.telepassSerialNumber,
                    telepassDateFrom: displayUTC0_ISODate(x.telepassActivateDT, true),
                    telepassDateFromOriginal: x.telepassActivateDT,
                    telepassDateTo: displayUTC0_ISODate(x.telepassExpirationDT, true),
                    telepassDateToOriginal: x.telepassExpirationDT,
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_vehicles_cards"}
              id={`vertical-tabpanel-vehicles_cards`}
              aria-labelledby={`vertical-tab-vehicles_cards`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "plate",
                    headerName: i18next.t("form:plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "supplier",
                    headerName: i18next.t("form:vehicleSupplier"),
                  },
                  {
                    field: "driver",
                    headerName: i18next.t("navigation:_vehicle_page_vehicle_driver"),
                  },
                  {
                    field: "type",
                    headerName: i18next.t("navigation:typeCard"),
                  },
                  {
                    field: "number",
                    headerName: i18next.t("form:numberFuelCard"),
                  },
                  {
                    field: "pin",
                    headerName: i18next.t("form:PIN"),
                  },
                  {
                    field: "deadline",
                    headerName: i18next.t("form:vehciledata_forms_addpay_scadenza"),
                    fieldOriginal: "deadlineOriginal",
                  },
                  {
                    field: "driverDeadline",
                    headerName: i18next.t("form:endAssignmentFuelCard"),
                    fieldOriginal: "driverDeadlineOriginal",
                  },
                ]}
                rows={cardsList.map((x: IFuelCardsGet) => { 
                  return {
                    plate: x.vehicleLicensePlate,
                    supplier: x.supplierName,
                    driver: x.driverName,
                    type: x.fuelCardTypeName,
                    number: x.fuelCardNumber,
                    pin: x.fuelCardPIN,
                    deadline: displayUTC0_ISODate(x.fuelCardExpirationDT, true),
                    deadlineOriginal: x.fuelCardExpirationDT,
                    driverDeadline: displayUTC0_ISODate(x.fuelCardEndAssignementDT, true),
                    driverDeadlineOriginal: x.fuelCardEndAssignementDT,
                  }
                })}
              />
            </div>
            {!loadingDriverList && !loadingVehiclesList && (
              <div
                role="tabpanel"
                hidden={value !== "_vehicles_km"}
                id={`vertical-tabpanel-vehicles_km`}
                aria-labelledby={`vertical-tab-vehicles_km`}
              >
                <DataGridWrap
                  headers={[
                    {
                      field: "kmDetectionSourceCode",
                      headerName: i18next.t("form:km_detection_source_code"),
                      renderCell: renderSource,
                    },
                    {
                      field: "km",
                      headerName: i18next.t("form:_km_detection_km"),
                    },
                    {
                      field: "totalVehicleKm",
                      headerName: i18next.t("form:_km_detection_km_total"),
                    },
                    {
                      field: "driver_",
                      headerName: i18next.t("form:_km_detection_driver"),
                    },
                    {
                      field: "vehicle_",
                      headerName: i18next.t("form:_km_detection_vehicle"),
                    },
                    {
                      field: "kmDetectionStatusCode",
                      headerName: i18next.t("form:_km_detection_status_code"),
                      renderCell: renderStatus,
                    },
                    {
                      field: "kmDetectionDT",
                      headerName: i18next.t("form:_km_detection_dt"),
                    },
                  ]}
                  rows={entriesVehicleKilometersEntries.map((x: any) => { 
                    let d: IDriverGet | undefined = driverList.find(
                      (y: IDriverGet) => y.driverInfo.driverID === x.driverID
                    );
                    let v: IVehicleDataGet | undefined = vehiclesList.find(
                      (y: IVehicleDataGet) => y.vehicleID === x.vehicleID
                    );
      
                    x["vehicle_"] = v ? formatPlate(v.vehicleLicensePlate) : "";
                    if(d){
                      x["driver_"] = d?.driverInfo.driverName + " " + (d?.driverInfo.driverSurname !== null ? d?.driverInfo.driverSurname : "");
                    }else{
                      x["driver_"] = "";
                    }
                    x["kmDetectionDT"] = displayUTC0_ISODate(x.kmDetectionDT);
                    return x;
                  })}
                />
              </div>
            )}
            <div
              role="tabpanel"
              hidden={value !== "_insurance_title"}
              id={`vertical-tabpanel-insurance_title`}
              aria-labelledby={`vertical-tab-insurance_title`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "assurancesPlate",
                    headerName: i18next.t("form:plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "assurancesSupplier",
                    headerName: i18next.t("form:vehicleInsuranceCompany"),
                  },
                  {
                    field: "assurancesDateFrom",
                    headerName: i18next.t("navigation:start"),
                  },
                  {
                    field: "assurancesDateTo",
                    headerName: i18next.t("navigation:end"),
                  },
                  {
                    field: "assurancesDetails",
                    headerName: i18next.t("form:insuranceDetailType"),
                  },
                  {
                    field: "assurancesMax",
                    headerName: i18next.t("form:insuranceMaxValue"),
                    renderCell: renderCost
                  },
                  {
                    field: "assurancesDeductibles",
                    headerName: i18next.t("form:serviceShoreLeave"),
                    renderCell: renderCost
                  },
                  {
                    field: "assurancesCover",
                    headerName: i18next.t("form:insuranceValue"),
                    renderCell: renderCost
                  },
                ]}
                rows={insuranceList.map((x: IInsuranceGet) => { 
                  return {
                    assurancesPlate: x.info.vehicleLicensePlate,
                    assurancesSupplier: x.info.supplierName,
                    assurancesDateFrom: displayUTC0_ISODate(x.info.startDT, true),
                    assurancesDateTo: displayUTC0_ISODate(x.info.endDT, true),
                    assurancesDetails: x.details.map((e: any, i: number) => { return e?.insuranceDetailTypeName}).join(", "),
                    assurancesMax: x.details && x.details.length > 0 ? x.details[0].covarageLimit : "",
                    assurancesDeductibles: x.details && x.details.length > 0 ? x.details[0].deductible : "",
                    assurancesCover: x.details && x.details.length > 0 ? x.details[0].covarage : "",
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_telepass_page_title"}
              id={`vertical-tabpanel-telepass_page_title`}
              aria-labelledby={`vertical-tab-telepass_page_title`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "vehicleLicencePlate",
                    headerName: i18next.t("form:plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "telepassTypeName",
                    headerName: i18next.t("form:telepassType"),
                  },
                  {
                    field: "telepassSerialNumber",
                    headerName: i18next.t("form:numberSerial"),
                  },
                  {
                    field: "telepassUsageDTView",
                    headerName: i18next.t("form:telepassUsageDT"),
                  },
                  {
                    field: "telepassUsageRoute",
                    headerName: i18next.t("form:telepassUsageRoute"),
                  },
                  {
                    field: "telepassClassName",
                    headerName: i18next.t("form:telepassUsageClass"),
                  },
                  {
                    field: "telepassUsageCost",
                    headerName: i18next.t("form:telepassUsageCost"),
                    renderCell: renderCost
                  },
                ]}
                rows={telepassUsageList.map((x: ITelepassUsage) => { 
                  return {
                    telepassTypeName: x.telepassTypeName,
                    telepassSerialNumber: x.telepassSerialNumber,
                    telepassUsageDTView: displayUTC0_ISODate(x.telepassUsageDT),
                    telepassClassName: x.telepassClassName,
                    telepassUsageRoute: x.telepassUsageRoute,
                    telepassClassID: x.telepassClassID,
                    telepassUsageCost: x.telepassUsageCost,
                    vehicleLicencePlate: x.vehicleLicensePlate,
                    telepassUsageDT: x.telepassUsageDT,
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_forniture_page_title"}
              id={`vertical-tabpanel-forniture_page_title`}
              aria-labelledby={`vertical-tab-forniture_page_title`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "vehicleLicencePlate",
                    headerName: i18next.t("form:plate"),
                    renderCell: renderPlate,
                  },
                  {
                    field: "fuelSupplyDTView",
                    headerName: i18next.t("form:refillDate"),
                  },
                  {
                    field: "fuelSupplyPlace",
                    headerName: i18next.t("form:pointOfSale"),
                  },
                  {
                    field: "fuelSupplyKm",
                    headerName: i18next.t("form:km"),
                  },
                  {
                    field: "fuelTypeIDView",
                    headerName: i18next.t("form:product"),
                  },
                  {
                    field: "fuelSupplyAmount",
                    headerName: i18next.t("form:fuelSupplyAmount"),
                    renderCell: renderAmount,
                  },
                  {
                    field: "fuelSupplyCost",
                    headerName: i18next.t("form:fuelSupplyCost"),
                    renderCell: renderCost
                  },
                  {
                    field: "fuelSupplyServed",
                    headerName: i18next.t("form:served"),
                    renderCell: renderServed,
                  },
                  {
                    field: "otherCost",
                    headerName: i18next.t("navigation:_other_costs"),
                    renderCell: renderOtherCost,
                  },
                  {
                    field: "fuelSupplyIVA",
                    headerName: i18next.t("form:vat"),
                  },
                ]}
                rows={fuelSupplyList.map((x: IVehicleFuelSupply) => { 
                  return {
                    vehicleLicencePlate: x.info.vehicleLicensePlate,
                    fuelCardNumber: x.info.fuelCardNumber,
                    fuelSupplyDTView: displayUTC0_ISODate(x.info.fuelSupplyDT),
                    fuelSupplyPlace: x.info.fuelSupplyPlace,
                    fuelSupplyKm: x.info.fuelSupplyKm,
                    fuelTypeIDView: fuelSupplyTypes.find(
                      (y: IFuelSupplyType) => y.fuelSupplyTypeID === x.info.fuelSupplyTypeID
                    )?.fuelSupplyTypeName,
                    fuelSupplyTypeID: x.info.fuelSupplyTypeID,
                    fuelSupplyAmount: x.info.fuelSupplyAmount,
                    fuelSupplyServed: x.info.fuelSupplyServed,
                    fuelSupplyCost: x.info.fuelSupplyCost,
                    fuelSupplyIVA: x.info.fuelSupplyIVA,
                    otherCost: x.details
                  }
                })}
              />
            </div>
            <div
              role="tabpanel"
              hidden={value !== "_add_services_tickets"}
              id={`vertical-tabpanel-add_services_tickets`}
              aria-labelledby={`vertical-tab-add_services_tickets`}
            >
              <DataGridWrap
                headers={[
                  {
                    field: "ticketStatusName",
                    headerName: i18next.t("navigation:_tickets_page_column_title_ticket_status"),
                    renderCell: StatusTicket,
                  },
                  {
                    field: "vehicleLicensePlate",
                    headerName: i18next.t("navigation:_tickets_page_column_title_plate"),
                  },
                  {
                    field: "driverDisplayName",
                    headerName: i18next.t("navigation:_tickets_page_column_assignee"),
                  },
                  {
                    field: "ticketReducedExpirationDT",
                    headerName: i18next.t("navigation:_tickets_page_column_data_reduced_expiry"),
                    fieldOriginal: "ticketReducedExpirationDTOriginal",
                  },
                  {
                    field: "reducedTotalAmount",
                    headerName: i18next.t("navigation:_tickets_page_column_reduce_amount"),
                    type: "number",
                    fieldOriginal: "reducedTotalAmountOriginal",
                  },
                  {
                    field: "ticketExpirationDT",
                    headerName: i18next.t("navigation:_tickets_page_column_data_expiry"),
                    fieldOriginal: "ticketExpirationDTOriginal",
                  },
                  {
                    field: "totalAmount",
                    headerName: i18next.t("navigation:_tickets_page_column_amount"),
                    type: "number",
                    fieldOriginal: "totalAmountOriginal",
                  },
                  {
                    field: "paidAmount",
                    headerName: i18next.t("navigation:_tickets_page_column_amount_paid"),
                    type: "number",
                    fieldOriginal: "paidAmountOriginal",
                  },
                  {
                    field: "ticketTypeName",
                    headerName: i18next.t("navigation:_tickets_page_column_type_of_ticket"),
                  },
                  {
                    field: "ticketIssuingInstitutionName",
                    headerName: i18next.t("navigation:_tickets_page_column_issuer_entity"),
                  },
                ]}
                rows={ticketList.map((x: ITicketInfo) => { 
                  return {
                    ticketStatusID: x.ticket.ticketStatusID,
                    ticketStatusName: x.ticket.ticketStatusName,
                    vehicleLicensePlate: x.ticketDetail.map((e: ITicketDetail) => { return formatPlate(e.vehicleLicensePlate)}).join(", "),
                    driverDisplayName: x.ticketDetail.map((e: ITicketDetail) => { return e.driverDisplayName}).join(", "),
                    ticketReducedExpirationDT: displayUTC0_ISODate(x.ticket.ticketReducedExpirationDT, true),
                    ticketReducedExpirationDTOriginal: x.ticket.ticketReducedExpirationDT,
                    reducedTotalAmount: currencyDecoder(x.ticket.reducedTotalAmount, 2),
                    reducedTotalAmountOriginal: x.ticket.reducedTotalAmount,
                    ticketExpirationDT: displayUTC0_ISODate(x.ticket.ticketExpirationDT, true),
                    ticketExpirationDTOriginal: x.ticket.ticketExpirationDT,
                    totalAmount: currencyDecoder(x.ticket.totalAmount, 2),
                    totalAmountOriginal: x.ticket.totalAmount,
                    paidAmount: currencyDecoder(x.ticket.paidAmount, 2),
                    paidAmountOriginal: x.ticket.paidAmount,
                    ticketID: x.ticket.ticketID,
                    ticketTypeName: x.ticket.ticketTypeName,
                    ticketIssuingInstitutionName: x.ticket.ticketIssuingInstitutionName
                  }
                })}
              />
            </div>
          </div>
          <LoaderBackdrop loading={loadingCompanyList || loadingSupplierList || loadingDriverList || loadingFleetManager || loadingVehiclesList
            || loadingOrderList || loadingTelepassList
            || loadingCardsList
            || loadingVehicleKilometersEntries
            || loadingInsuranceList
            || loadingTelepassUsage
            || loadingFuelSupplyTypes
            || loadingFuelSupplyList
            || loadingTicketList
          } />
        </div>
      </div>
  </div>
  )
} 

export default DownloadDataPage;