import { useState } from "react";
import { IGenericDescriptor } from "../../Models/IService";
import MultiLangDescPicker from "../MultiLangDescPicker/MultiLangDescPicker";
import { IGET_FAQ_CATEGORY, IInsertFaqCategory, IUpdateFaqCategory } from "../../Models/IFAQ";
import ApiService from "../../Services/ApiService";
import { IAPIRequest, IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import MultiForm from "../MultiForm/MultiForm";
import i18next from "i18next";

export interface IFaqCategoryFormProps {
  currentFaqCategory?: IGET_FAQ_CATEGORY | undefined;
  onCloseModal?: () => void;
  formID: string;
  onRefresh?: (data: any) => void;
}

const FaqCategoryForm = (props: IFaqCategoryFormProps) => {

  const [state, setState] = useState<any>(props.currentFaqCategory ?? null);
  const [processing, setProcessing] = useState<boolean>(false);

  const insertFaqCategory = (data: IInsertFaqCategory) => {
    setProcessing(true);
    ApiService.FAQController.InsertFaqCategory(data, 
      (response: IAPIResponse) => {
        if (response.error === null) {
          if(props.onCloseModal){
            props.onCloseModal();
          }
          if(props.onRefresh){
            props.onRefresh(response.payload);
          }
          ToastMessage(i18next.t("message:faqCategorySuccessfullAdded"), "success");
        }else{
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
     }
    );
  };

  const updateFaqCategory = (data: IUpdateFaqCategory) => {
    setProcessing(true);
    ApiService.FAQController.UpdateFaqCategory(data, 
      (response: IAPIResponse) => {
        if (response.error === null) {
          if(props.onCloseModal){
            props.onCloseModal();
          }
          if(props.onRefresh){
            props.onRefresh(response.payload);
          }
          ToastMessage(i18next.t("message:faqCategorySuccessfullUpdated"), "success");
        }else{
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };


  return (
    <MultiForm
      styles={{width: "60vw"}}
      formId={props.formID}
      inputs={[
        {
          type: "custom",
          width: 100,
          name: "faqCategoryTranslation",
          element: (
            <MultiLangDescPicker
              noNotes
              noDescription
              defaultValue={
                state ? state.faqCategoryTranslation : []
              }
              onChange={(data: IGenericDescriptor[]) => {
                let data_ = state ? { ...state } : {};
                data_.faqCategoryTranslation = data;
                setState(data_);
              }}
            />
          )
        }
      ]}
      onSubmit={(data: any) => {
        let data_ = state
          ? { ...data, ...state }
          : { ...data };
        if (data_.faqCategoryID) {
          updateFaqCategory(data_);
        } else {
          insertFaqCategory(data_);
        }
      }}
      suppressLayout
      suppressSubmit
    />
  )
}

export default FaqCategoryForm;