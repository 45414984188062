import { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import i18next from "i18next";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { IDriverGet } from "../../../Models/IDriver";
import { IUser } from "../../../Models/IUser";
import MultiForm, {
  requestFormRefresh,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../../SmartModal/SmartModal";
import FormAssignedDriver from "../../StepAssignedDrivers/FormAssignedDriver";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import {
  IAssignmentGet,
  IAssignmentGetDeducibility,
  IAssignmentGetType,
  IAssignmentInsert,
  IAssignmentUpdate,
} from "../../../Models/IAssignment";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import { ICompanyGet } from "../../../Models/ICompany";
import AttachementMainHandler from "../../AttachmentMainHandler/AttachmentMainHandler";
import DeadlineMainHandler from "../../DeadlineMainHandler/DeadlineMainHandler";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../Reducers/RootReducer";
import { displayUTC0_ISODate } from "../../MultiForm/SpecialInputs/StrongDatePicker";
import { validationErrorMessages } from "../../../Utils/Validation";
import AppRoutes from "../../../Costants/AppRoutes";
import { fromOrderGet_assignment } from "../../../Utils/OrderToVehicle";
import { GetVehicleMinDate } from "../../../Pages/DashboardPage/DashboardPage";
import { JSONPrint } from "../../../Utils/Decoder";

export interface IFormAssignement {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  dataVehicle?: IVehicleDataGet;
  disabled?: boolean;
  data?: any;
  suppressWarning?: boolean;
  requestUpdate?: () => void;
}
let stateClone: any = {};

const FormAssignement = (props: IFormAssignement) => {
  const driver: IDriverGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeDriver
  );
  const mainCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [minKm, setMinKm] = useState<number>(0);

  const [state, setState_] = useState<any>({});
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(false);
  const [loadingCurrentAssignment, setLoadingCurrentAssignment] =
    useState<boolean>(false);
  const [loadingAssignmentTypes, setLoadingAssignmentTypes] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<IDriverGet[]>([]);
  const [assignmentTypes, setAssignmentTypes] = useState<IAssignmentGetType[]>([]);
  const [processingInsert, setProcessingInsert] = useState<boolean>(false);
  const [processingUpdate, setProcessingUpdate] = useState<boolean>(false);
  const [processingDelete, setProcessingDelete] = useState<boolean>(false);

  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);

  const [loadingAssignmentDeducibility, setLoadingAssignmentDeducibility] =
    useState<boolean>(false);
  const [assignmentDeducibility, setAssignmentDeducibility] = useState<
    IAssignmentGetDeducibility[]
  >([]);

  const loadAssignmentTypes = () => {
    if (props.loggedUser) {
      setLoadingAssignmentTypes(true);
      ApiService.AssignmentController.AssignmentGetType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAssignmentTypes(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAssignmentTypes(false);
        }
      );
    }
  };

  const loadAssignmentDeducibility = () => {
    if (props.loggedUser) {
      setLoadingAssignmentDeducibility(true);
      ApiService.AssignmentController.GetAssignmentDeductibility(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAssignmentDeducibility(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAssignmentDeducibility(false);
        }
      );
    }
  };

  const loadCurrentAssignment = () => {
    if (props.loggedUser && props.dataVehicle?.vehicleID) {
      setLoadingCurrentAssignment(true);
      ApiService.AssignmentController.AssignmentGetByCarID(
        props.dataVehicle.vehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let currents: IAssignmentGet[] = response.payload.filter(
              (x: IAssignmentGet) => x.effectiveEndDT === null
            );
            if (currents.length === 1) {
              setState(currents[0]);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentAssignment(false);
        }
      );
    }
  };

  const loadCompany = () => {
    if (props.loggedUser) {
      setLoadingCompany(true);
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let rawList: ICompanyGet[] = response.payload;
            for (let i = 0; i < rawList.length; i++) {
              let c: ICompanyGet = rawList[i];
              let parentID = c.companyParentID;
              if (parentID) {
                let parentRef: ICompanyGet | undefined = rawList.find(
                  (x: ICompanyGet) => x.companyID === parentID
                );
                if (parentRef && parentRef.companyBusinessName === "") {
                  parentRef.companyBusinessName = c.companyBusinessName;
                }
              }
            }

            setCompanyList(
              rawList.filter((x: ICompanyGet) => x.companyParentID === null)
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompany(false);
        }
      );
    }
  };

  const loadDrivers = (companyID: number) => {
    if (props.loggedUser) {
      setLoadingDrivers(true);
      ApiService.DriverController.DriverGet(null, companyID, (response: IAPIResponse) => {
        if (response.error === null) {
          setDrivers(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDrivers(false);
      });
    }
  };

  const assignmentInsert = (data: IAssignmentInsert) => {
    if (props.loggedUser) {
      setProcessingInsert(true);
      ApiService.AssignmentController.AssignmentInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:assignmentSucceffulAdded"), "success");
          window.document.dispatchEvent(new Event("reload-assigners-list-hook"));
          DismissModal("vehicleAssignment");
          if(props.requestUpdate){
            props.requestUpdate();
          }
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessingInsert(false);
      });
    }
  };

  const assignmentUpdate = (data: IAssignmentUpdate) => {
    if (props.loggedUser) {
      setProcessingUpdate(true);
      ApiService.AssignmentController.AssignmentUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:assignmentSucceffulUpdated"), "success");
          window.document.dispatchEvent(new Event("reload-assigners-list-hook"));
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessingUpdate(false);
      });
    }
  };

  const assignmentDelete = (assignmentID: number) => {
    if (props.loggedUser) {
      setProcessingUpdate(true);
      ApiService.AssignmentController.AssignmentDelete(
        assignmentID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:assignmentSucceffulDeleted"), "success");
            setState({});
            requestFormRefresh();
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessingUpdate(false);
        }
      );
    }
  };

  useEffect(() => {
    if (props.data) {
      setLoadingCurrentAssignment(true);
      setState(fromOrderGet_assignment(props.data));
      setTimeout(() => {
        setLoadingCurrentAssignment(false);
      }, 200);
    }

    if (mainCompany) {
      loadDrivers(mainCompany.companyID);
    }
    loadCompany();
    loadAssignmentTypes();
    loadAssignmentDeducibility();

    // setState(fromOrderGet_assignment({}));

    return () => {
      stateClone = {};
    };
  }, []);

  useEffect(() => {
    let driverId = driver ? driver.driverInfo.driverID === -1 : 1;
    if (!props.data && driverId === 1) {
      loadCurrentAssignment();
    }
    getVehicleKMLimit();
  }, [props.dataVehicle]);

  const setState = (state: any) => {
    stateClone = JSON.parse(JSON.stringify(state));
    setState_(state);
  };

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  const getDriverOptions = () => {
    if ((window as any)["usefakedriver"]) {
      return [{ key: "1", text: "Fake driver" }];
    }

    return drivers.map((driver: IDriverGet) => {
      let titleDriver = driver.driverInfo.driverName;
      if (driver.driverInfo?.driverSurname) {
        titleDriver += " " + driver.driverInfo.driverSurname;
      }
      return {
        key: driver.driverInfo.driverID.toString(),
        text: titleDriver.toString(),
      };
    });
  };

  const getVehicleKMLimit = () => {
    if (props.dataVehicle && props.dataVehicle?.vehicleID) {
      ApiService.VehicleKilometersEntriesController.GetVehicleKilometersLimit(
        props.dataVehicle?.vehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setMinKm(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const loadingAll =
    loadingCurrentAssignment ||
    loadingDrivers ||
    loadingAssignmentTypes ||
    loadingCompany;

  return (
    <div>
      {props.disabled && !props.suppressWarning && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}
      {state && state.effectiveEndDT && (
        <Alert severity="warning">
          {i18next
            .t("navigation:archivedAssignment")
            .replace("DATE", displayUTC0_ISODate(state?.effectiveEndDT, true))
            .replace("K_M", state?.endVehicleKM)}
        </Alert>
      )}
      {!loadingAll && (
        <>
          <MultiForm
            formId={"StepAssingment"}
            suppressLayout
            formTitle={""}
            disableForm={state && state.effectiveEndDT}
            inputs={[
              {
                width: 50,
                type: "select",
                name: "companyID",
                disabled: props.disabled,
                defaultValue: state?.companyID,
                required: true,
                label: String(i18next.t(`form:referentCompany`)),
                options: companyList.map((company: ICompanyGet) => {
                  return {
                    key: company.companyID,
                    text: company.companyBusinessName.toString(),
                  };
                }),
              },
              {
                width: 40,
                type: "select",
                name: "driverID",
                disabled: props.disabled,
                required: true,
                defaultValue: state?.driverID,
                label: String(i18next.t(`form:assignDriverName`)),
                options: getDriverOptions(),
              },
              {
                width: 10,
                type: "custom",
                name: "AssignDriver",
                element: (
                  <div className="multi-form-button-add-record">
                    <Button
                      disabled={props.disabled}
                      variant="contained"
                      onClick={() => {
                        SummonModal("AssignDriver-modal");
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                ),
              },
              {
                width: 50,
                disabled: props.disabled,
                type: "select",
                name: "assignmentTypeID",
                required: true,
                defaultValue: state?.assignmentTypeID,
                label: String(i18next.t(`form:assignmentTypeID`)),
                options: assignmentTypes?.map((assignmentType: IAssignmentGetType) => {
                  return {
                    key: assignmentType.assignmentTypeID.toString(),
                    text: assignmentType.assignmentTypeName.toString(),
                  };
                }),
              },
              {
                width: 25,
                disabled: props.disabled,
                type: "datetime",
                name: "startDT",
                required: true,
                maxDate: state?.endDT,
                label: i18next.t("form:dateStartAssignDriver") as string,
                defaultValue: state?.startDT,
                minDate: GetVehicleMinDate(props.dataVehicle),
              },
              {
                width: 25,
                disabled: props.disabled,
                type: "datetime",
                name: "endDT",
                required: true,
                label: i18next.t("form:dateFinishAssignDriver") as string,
                defaultValue: state?.endDT,
                minDate: state?.startDT,
              },
              {
                width: 33.33,
                disabled: props.disabled,
                type: "number",
                required: true,
                name: "vehicleKM",
                min: minKm,
                label: i18next.t("form:mileageStartAssignDriver") as string,
                defaultValue: state?.vehicleKM,
                inputProps: {
                  max: 1000000,
                },
              },
              {
                width: 33.33,
                disabled: props.disabled,
                type: "select",
                required: true,
                name: "assignmentDeductibilityID",
                label: i18next.t("form:assignmentDeductibilityID") as string,
                defaultValue: state?.assignmentDeductibilityID,
                options: assignmentDeducibility.map(
                  (item: IAssignmentGetDeducibility) => {
                    return {
                      key: item.assignmentDeductibilityID,
                      text: (item.deductPercentage + " %").toString(),
                    };
                  }
                ),
              },
              {
                width: 33.33,
                disabled: props.disabled,
                type: "number",
                required: true,
                name: "driverShare",
                label: i18next.t("form:driverShare") as string,
                defaultValue: state?.driverShare,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              },
              {
                width: 33.33,
                disabled: props.disabled,
                type: "number",
                required: true,
                name: "driverMonthlyAverageKm",
                label: i18next.t("form:month_avg_km") as string,
                defaultValue: state?.driverMonthlyAverageKm,
                inputProps: {
                  max: 1000000,
                },
              },
            ]}
            onChange={(data: any) => {
              setState(mergeObjects(data));
            }}
            suppressSubmit={true}
            onSubmit={(data: any) => {
              let newData = { ...state, ...data };
              delete newData["AssignDriver"];
              newData["vehicleID"] = props.dataVehicle?.vehicleID;
              newData["vehicleKM"] = +state?.vehicleKM;
              newData["driverID"] = parseInt(state.driverID);
              newData["assignmentTypeID"] = parseInt(state.assignmentTypeID);
              newData["driverMonthlyAverageKm"] = parseInt(state.driverMonthlyAverageKm);
              newData["effectiveEndDT"] = null;
              newData["companyID"] = data["companyID"];

              if (state.assignmentID) {
                newData["assignmentID"] = state.assignmentID;
                assignmentUpdate(newData);
              } else {
                assignmentInsert(newData);
              }
            }}
          />
          <DeadlineMainHandler
            DeadlineFamily={"assignments"}
            extraGetParameters={
              state?.assignmentID
                ? {
                    name: "assignmentID",
                    value: state?.assignmentID,
                  }
                : undefined
            }
          />

          <div className="margin-vertical-medium">
            <AttachementMainHandler
              attachmentFamily={"assignments"}
              extraGetParametersVector={
                state?.assignmentID
                  ? [
                      {
                        name: "assignmentID",
                        value: state?.assignmentID,
                      },
                    ]
                  : undefined
              }
            />
          </div>

          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={
                  props.disabled ||
                  processingInsert ||
                  processingUpdate ||
                  (state && state.effectiveEndDT)
                }
                onClick={() => {
                  triggerFormValidation("StepAssingment");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveAssignment")}
              </Button>

              {!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) && (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.vehicleID && !state?.effectiveEndDT}
                  onClick={() => {
                    SummonModal("StepAssignment-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteAssignment")}
                </Button>
              )}

              <SmartModal
                modalUniqueId="StepAssignment-modal"
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace("CURRENT_ITEM_LIST", state?.driverDisplayName),
                    }}
                  />
                }
                onAccept={() => {
                  assignmentDelete(state?.assignmentID);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </>
      )}
      <SmartModal
        modalUniqueId="AssignDriver-modal"
        title={i18next.t("generic:titleAddDriver")}
        modalInnerComponent={
          <FormAssignedDriver
            loggedUser={props.loggedUser}
            data={[]}
            companyUser={props.companyUser}
            idModal="AssignDriver-modal"
            updateList={(data: any) => {
              let drivers_: IDriverGet[] = [...drivers];
              let data_: any = data;
              let addedDriver: any = {
                driverCostCenter: [],
                driverInfo: data_,
              };
              drivers_.push(addedDriver);
              setDrivers(drivers_);
            }}
          />
        }
      />
      <LoaderBackdrop
        loading={loadingAll || processingInsert || processingUpdate || processingDelete}
      />
    </div>
  );
};

export default FormAssignement;
