import i18next from "i18next";
import SmartModal, { DismissModal } from "../SmartModal/SmartModal";
import "./DismissVehicleModal.scss";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  IVehicleDisposal,
  IVehicleDisposalComplete,
  IVehicleDisposalLinkedItemList,
  IVehicleDisposalMode,
} from "../../Models/IDisposal";
import { Alert, Button, CircularProgress } from "@mui/material";
import CustomStepper, { stepperGoToPage } from "../CustomStepper/CustomStepper";
import { ICompanyGet } from "../../Models/ICompany";
import AccordionContent from "../AccordionContent/AccordionContent";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { stat } from "fs";
import { X } from "@mui/icons-material";
export interface IDismissVehicleModalProps {
  dismissDone: () => void;
}
export interface IDimissExtraRowProps {
  idList: number[];
  mainTitle: string;
  resumeHeaders: string[];
  resumeRows: any[][];
  state: { id: number; selection: number, code: string }[];
  codeRow: string;
  emitRowSelection: (items: { id: number; selection: number, code: string }[]) => void;
}

const DimissExtraRow = (props: IDimissExtraRowProps) => {

  let allDispose = props.state
    ? (Object.values(props.state)).filter((x: { id: number; selection: number, code: string }) => x.selection === 1 || x.selection === 0)
        .length === 0
    : false;
  let allDisassociate = props.state
    ? (Object.values(props.state)).filter((x: { id: number; selection: number, code: string }) => x.selection === 2 || x.selection === 0)
        .length === 0
    : false;

  let extraText: string = "";

  if (allDisassociate) {
    extraText =
      i18next.t("navigation:disassociate") +
        " " +
        i18next.t("navigation:all").toLowerCase() ?? "";
  }
  if (allDispose) {
    extraText =
      i18next.t("navigation:dispose") + " " + i18next.t("navigation:all").toLowerCase() ??
      "";
  }

  return (
    <div className="dismiss-resume-table-outer">
      <div style={{ display: "flex", gap: "1em" }}>
        <div style={{ marginLeft: "0.5em", width: "100%" }}>
          <AccordionContent
            title={props.mainTitle}
            subTitle={extraText}
            content={
              <div className="dismiss-resume-table">
                <table>
                  <thead className="dismiss-resume-row">
                    <tr>
                      {[i18next.t("navigation:action")]
                        .concat(props.resumeHeaders)
                        .map((x: string, i: number) => {
                          return (
                            <th key={i} className="dismiss-resume-header">
                              {i18next.t("navigation:" +x)}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {props.resumeRows.map((x: any[], i: number) => {
                      return (
                        <tr key={i} className="dismiss-resume-row">
                          {[
                            <div>
                              <select
                                value={props.state ? props.state.find((e: { id: number; selection: number, code: string }) => e.id === props.idList[i])?.selection : 0}
                                onChange={(e: any) => {
                                  props.emitRowSelection([
                                    {
                                      id: props.idList[i],
                                      selection: +e.target.value,
                                      code: props.codeRow
                                    },
                                  ]);
                                }}
                              >
                                <option value={0}></option>
                                <option value={1}>
                                  {i18next.t("navigation:disassociate")}
                                </option>
                                <option value={2}>
                                  {i18next.t("navigation:dispose")}
                                </option>
                              </select>
                            </div>,
                          ]
                            .concat(x)
                            .map((y: any, j: number) => {
                              let v: any = y;
                              if (y && y.toString().includes("T00:00:00")) {
                                v = displayUTC0_ISODate(y, true);
                              }

                              if (
                                v &&
                                (typeof v === "string" || v instanceof String) &&
                                props.resumeHeaders[j]?.toLowerCase().includes("plate")
                              ) {
                                v = formatPlate(v.toString());
                              }

                              return (
                                <td
                                  key={j}
                                  className={
                                    "dismiss-resume-content " +
                                    (i % 2 === 0 ? "dismiss-resume-content-even" : "")
                                  }
                                >
                                  {v}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
          />
        </div>
        <div>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              variant={"outlined"}
              startIcon={<LinkOffIcon />}
              onClick={() => {
                let all: any[] = props.idList.map((x: number) => {
                  return {
                    id: x,
                    selection: 1,
                    code: props.codeRow
                  };
                });

                props.emitRowSelection(all);
              }}
            >
              {i18next.t("navigation:disassociate")}
            </Button>
            <div className="red-button-outlined">
              <Button
                variant={"outlined"}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  let all: any[] = props.idList.map((x: number) => {
                    return {
                      id: x,
                      selection: 2,
                      code: props.codeRow
                    };
                  });

                  props.emitRowSelection(all);
                }}
              >
                {i18next.t("navigation:dispose")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DismissVehicleModal = (props: IDismissVehicleModalProps) => {
  const [state, setState] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [actionMissing, setActionMissing] = useState<boolean>(false);

  const [disposalLinkedItemsMap, setDisposalLinkedItemsMap] = useState<any>({});
  const [disposalModes, setDisposalModes] = useState<IVehicleDisposalMode[]>([]);
  const [linkedItems, setLinkedItems] = useState<IVehicleDisposalLinkedItemList[]>([]);

  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const mainCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const setLinkedItemMapValue = (
    set: number,
    items: {
      id: number;
      selection: number;
      code: string;
    }[]
  ) => {
    let currMap: any = { ...disposalLinkedItemsMap };
    if (!currMap[set.toString()]) {
      currMap[set.toString()] = [];
    }
    for (let i = 0; i < items.length; i++) {
      currMap[set.toString()] = items;
    } 

    setDisposalLinkedItemsMap(currMap);
  };

  const GetVehicleDisposal = async () => {
    setLoading(true);
    let response: IAPIResponse = await ApiService.DisposalController.GetVehicleDisposal(
      null,
      vehicle?.vehicleID
    );
    if (response.error === null) {
      let pending: IVehicleDisposal[] = response.payload.filter(
        (x: IVehicleDisposal) => x.inProgress
      );
      if (pending.length > 0) {
        setState(pending[0]);
      }
    }
    return true;
  };

  const UpdateVehicleDisposal = (data: IVehicleDisposal) => {
    stepperGoToPage(1);
    // typefix
    if (data.amount) {
      data.amount = +data.amount;
    }

    setLoading(true);
    ApiService.DisposalController.UpdateVehicleDisposal(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setState(data);
          setTimeout(() => {
            stepperGoToPage(1);
          }, 50);
        } else {
          ToastMessage(i18next.t("error:cannot_dismiss_vehicle"), "error");
        }
        setLoading(false);
      }
    );
  };

  const getLinkedItems = () => {
    ApiService.DisposalController.GetVehicleDisposalLinkedItems(
      vehicle?.vehicleID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setLinkedItems(response.payload);
        }
      }
    );
  };

  const CompleteVehicleDisposal = () => {
    
    if (!state?.vehicleDisposalID) {
      return;
    }
    let data: IVehicleDisposalComplete = {
      vehicleDisposalID: state?.vehicleDisposalID,
      itemsToDisassociate: {
        telepassIDList: [],
        insuranceIDList: [],
        fuelCardIDList: [],
        vehicleServiceIDList: [],
      },
      itemsToDispose: {
        telepassIDList: [],
        insuranceIDList: [],
        fuelCardIDList: [],
        vehicleServiceIDList: [],
      },
    };
    setActionMissing(false);
    
    let rows = Object.keys(disposalLinkedItemsMap);

    let rowsEmpty: any[] = [];
    Object.keys(disposalLinkedItemsMap).map((x: any, i: number) => {
      if (disposalLinkedItemsMap[x].length === 0) {
        rowsEmpty.push(i);
      }
    });

    if (
      (rows.length - rowsEmpty.length) < linkedItems.filter((x: IVehicleDisposalLinkedItemList) => x.itemIDList.length > 0).length
    ) {
      setActionMissing(true);
      return;
    }
     
    for (let i = 0; i < rows.length; i++) {
      if(rowsEmpty.filter((x: number) => x === i).length > 0) {
        continue; 
      }else {
        let row: any = disposalLinkedItemsMap[rows[i]];
        let keys: string[] = Object.keys(row);
        for (let j = 0; j < keys.length; j++) {
          let selection: number = row[keys[j]].selection;
          let id: number = row[keys[j]].id;
          let code: string = row[keys[j]].code;
          if (selection !== 1 && selection !== 2) {
            setActionMissing(true);
            return;
          }
          if (selection === 1) {
            if (code.toUpperCase() === "TELEPASS") {
              data.itemsToDisassociate.telepassIDList.push(+id);
            }
            if (code.toUpperCase() === "INSURANCE") {
              data.itemsToDisassociate.insuranceIDList.push(+id);
            }
            if (code.toUpperCase() === "FUEL_CARD") {
              data.itemsToDisassociate.fuelCardIDList.push(+id);
            }
            if (code.toUpperCase() === "VEHICLE_SERVICE") {
              data.itemsToDisassociate.vehicleServiceIDList.push(+id);
            }
          }
          
          // dispose
          if (selection === 2) {
            if (code.toUpperCase() === "TELEPASS") {
              data.itemsToDispose.telepassIDList.push(+id);
            }
            if (code.toUpperCase() === "INSURANCE") {
              data.itemsToDispose.insuranceIDList.push(+id);
            }
            if (code.toUpperCase() === "FUEL_CARD") {
              data.itemsToDispose.fuelCardIDList.push(+id);
            }
            if (code.toUpperCase() === "VEHICLE_SERVICE") {
              data.itemsToDispose.vehicleServiceIDList.push(+id);
            }
          }
        }
      }
    }
    setLoading(true);
    ApiService.DisposalController.CompleteVehicleDisposal(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDone(true);
        } else {
          ToastMessage(i18next.t("error:cannot_dismiss_vehicle"), "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (done) {
      props.dismissDone();
    }
  }, [done]);

  const InsertVehicleDisposal = (data: IVehicleDisposal) => {
    // typefix
    if (data.amount) {
      data.amount = +data.amount;
    }

    if (vehicle && loggedUser) {
      data.vehicleID = vehicle?.vehicleID;
      data.driverID = vehicle?.driverID;
    }

    if (data.driverID === 0) {
      data.driverID = null;
    }

    setLoading(true);
    ApiService.DisposalController.InsertVehicleDisposal(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setState(response.payload);
          setTimeout(() => {
            stepperGoToPage(1);
          }, 50);
        } else {
          ToastMessage(i18next.t("error:cannot_dismiss_vehicle"), "error");
        }
        setLoading(false);
      }
    );
  };

  const LoadDisposalModes = () => {
    setLoading(true);
    ApiService.DisposalController.GetVehicleDisposalMode(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDisposalModes(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    LoadDisposalModes();
    getLinkedItems();
  }, [vehicle]);

  const forceAllOnState = (state: number) => {
    let finalState: any = {};
    for (let i = 0; i < linkedItems.length; i++) {
      finalState[i] = [];
      for (let j = 0; j < linkedItems[i].itemIDList.length; j++) { 
        finalState[i].push({
          id: linkedItems[i].itemIDList[j],
          selection: state,
          code: linkedItems[i].code
        });
      } 
    }
    setDisposalLinkedItemsMap(finalState);
  };

  useEffect(() => {
    if (linkedItems.length > 0) {
      forceAllOnState(0);
    }
  }, [linkedItems]);

  return (
    <div>
      <SmartModal
        buttons={
          done
            ? [
                {
                  text: i18next.t("navigation:close"),
                  onClick: () => {
                    setDone(false);
                    setState(null);
                    setDisposalLinkedItemsMap({});
                    DismissModal("DismissVehicleModal");
                  },
                },
              ]
            : []
        }
        title={i18next.t("navigation:dismissSubstituteVehicleTitle")}
        modalUniqueId="DismissVehicleModal"
        onBeforeSummon={async () => {
          setDisposalLinkedItemsMap({});
          setState(null);
          setDone(false);
          await GetVehicleDisposal();

          setTimeout(() => {
            setLoading(false);
          }, 500);
        }}
        modalInnerComponent={
          <div>
            {!done && (
              <Alert severity="info">
                {i18next.t("message:dismissionOperativeAtEnd")}
              </Alert>
            )}
            {loading && (
              <div className="center-progress-spinner">
                <CircularProgress />
              </div>
            )}
            {!loading && (
              <div>
                {done && (
                  <div className="end-disposal-wrap">
                    <DoneOutlineIcon />
                    {i18next.t("navigation:dismiss_complete")}
                  </div>
                )}
                {!done && (
                  <CustomStepper
                    isCustomDashboard
                    isNextStep
                    stepperNodes={[
                      {
                        element: (
                          <MultiForm
                            formId="DismissVehicleForm"
                            formTitle={""}
                            suppressLayout
                            suppressSubmit
                            inputs={[
                              {
                                width: 50,
                                type: "date",
                                name: "disposalDT",
                                defaultValue: state?.disposalDT,
                                label: i18next.t("form:disposalDate") ?? "",
                                required: true,
                              },
                              {
                                width: 50,
                                type: "number",
                                name: "amount",
                                defaultValue: state?.amount,
                                label: i18next.t("form:disposalAmount") ?? "",
                                required: true,
                                inputAdornament: { adornament: "€" },
                              },
                              {
                                width: 50,
                                type: "text",
                                name: "buyer",
                                defaultValue: state?.buyer,
                                label: i18next.t("form:disposalBuyer") ?? "",
                                required: true,
                              },
                              {
                                width: 50,
                                type: "number",
                                name: "disposalKm",
                                defaultValue: state?.disposalKm,
                                label: i18next.t("form:disposalKM") ?? "",
                                required: true,
                                inputProps: {
                                  max: 1000000,
                                },
                              },
                              {
                                width: 50,
                                type: "select",
                                name: "vehicleDisposalModeID",
                                defaultValue: state?.vehicleDisposalModeID,
                                label: i18next.t("form:disposalMode") ?? "",
                                required: true,
                                options: disposalModes.map((x: IVehicleDisposalMode) => {
                                  return {
                                    key: x.vehicleDisposalModeID,
                                    text: x.vehicleDisposalModeName,
                                  };
                                }),
                              },
                            ]}
                            onSubmit={(data: any) => {
                              data = { ...state, ...data };
                              if (data.vehicleDisposalID) {
                                UpdateVehicleDisposal(data);
                              } else {
                                InsertVehicleDisposal(data);
                              }
                            }}
                          />
                        ),
                        stepTitle: i18next.t("navigation:disposalStep_1"),
                        onNext: () => {
                          triggerFormValidation("DismissVehicleForm");
                          return false;
                        },
                        onBack: () => {
                          return true;
                        },
                      },
                      {
                        element: (
                          <AttachementMainHandler
                            attachmentFamily="disposal"
                            extraGetParametersVector={
                              state?.vehicleDisposalID
                                ? [
                                    {
                                      name: "vehicleDisposalID",
                                      value: state?.vehicleDisposalID,
                                    },
                                  ]
                                : undefined
                            }
                          />
                        ),
                        stepTitle: i18next.t("navigation:disposalStep_2"),
                        onBack: () => {
                          return true;
                        },
                        onNext: () => {
                          return true;
                        },
                      },
                      {
                        element: (
                          <div className="dismiss-main-resume-wrap">
                            {!state?.vehicleDisposalID && (
                              <Alert severity="warning">
                                {i18next.t("navigation:bad_disposal_state")}
                              </Alert>
                            )}
                            {state?.vehicleDisposalID && (
                              <div>
                                {actionMissing && (
                                  <div style={{ marginBottom: "0.5em" }}>
                                    <Alert severity="error">
                                      {i18next.t("error:select_action_for_all_entities")}
                                    </Alert>
                                  </div>
                                )}
                                {linkedItems.map(
                                  (x: IVehicleDisposalLinkedItemList, i: number) => {
                                    return (
                                      <div key={i}>
                                        {x.rows.length > 0 && (
                                          <DimissExtraRow
                                            state={disposalLinkedItemsMap[i]}
                                            emitRowSelection={(
                                              items: {
                                                id: number;
                                                selection: number;
                                                code: string;
                                              }[]
                                            ) => {
                                              setLinkedItemMapValue(i, items);
                                            }}
                                            mainTitle={x.displayName}
                                            codeRow={x.code}
                                            resumeHeaders={x.headerNameList}
                                            resumeRows={x.rows}
                                            idList={x.itemIDList}
                                          />
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row-reverse",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "1em",
                                        margin: "1em 0",
                                      }}
                                    >
                                      <Button
                                        variant={"contained"}
                                        startIcon={<LinkOffIcon />}
                                        onClick={() => {
                                          forceAllOnState(1);
                                        }}
                                      >
                                        {i18next.t("navigation:disassociate") +
                                          " " +
                                          i18next.t("navigation:all").toLowerCase()}
                                      </Button>
                                      <div className="red-button-filled">
                                        <Button
                                          variant={"contained"}
                                          startIcon={<DeleteIcon />}
                                          onClick={() => {
                                            forceAllOnState(2);
                                          }}
                                        >
                                          {i18next.t("navigation:dispose") +
                                            " " +
                                            i18next.t("navigation:all").toLowerCase()}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ),
                        stepTitle: i18next.t("navigation:disposalStep_3"),
                        onNext: () => {
                          return false;
                        },
                      },
                    ]}
                    onFinish={() => {
                      
                      CompleteVehicleDisposal();
                    }}
                  />
                )}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default DismissVehicleModal;
