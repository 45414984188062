import AccountController from "./Controllers/AccountController";
import AssignmentController from "./Controllers/AssignmentController";
import AttachmentController from "./Controllers/AttachmentController";
import BrokerController from "./Controllers/BrokerController";
import CalendarController from "./Controllers/CalendarController";
import CompanyController from "./Controllers/CompanyController";
import ContractController from "./Controllers/ContractController";
import DeadlineController from "./Controllers/DeadlineController";
import DisposalController from "./Controllers/DisposalController";
import DriverController from "./Controllers/DriverController";
import FleetPlanController from "./Controllers/FleetPlanController";
import FringeController from "./Controllers/FringeController";
import FuelCardsController from "./Controllers/FuelCardsController";
import InsuranceController from "./Controllers/InsuranceController";
import MessagesController from "./Controllers/MessagesController";
import OrderController from "./Controllers/OrderController";
import PaymentController from "./Controllers/PaymentController";
import PaymentsRoadTaxController from "./Controllers/PaymentsRoadTaxController";
import SearchController from "./Controllers/SearchController";
import ServiceController from "./Controllers/ServiceController";
import SessionController from "./Controllers/SessionController";
import StepperController from "./Controllers/StepperController";
import SubscriptionController from "./Controllers/SubscriptionController";
import SupplierController from "./Controllers/SupplierController";
import StatisticsController from "./Controllers/StatisticsController";
import TelepassController from "./Controllers/TelepassController";
import TicketController from "./Controllers/TicketController";
import UploadController from "./Controllers/UploadController";
import VehicleContractController from "./Controllers/VehicleContractController";
import VehicleDamageController from "./Controllers/VehicleDamageController";
import VehicleDataController from "./Controllers/VehicleDataController";
import VehicleFuelSupplyController from "./Controllers/VehicleFuelSupplyController";
import VehicleInspectionController from "./Controllers/VehicleInspectionController";
import VehicleReservationController from "./Controllers/VehicleReservationController";
import VehicleReservationIncomingController from "./Controllers/VehicleReservationIncomingController";
import VehicleReservationOutgoingController from "./Controllers/VehicleReservationOutgoingController";
import VehicleRoadTaxAdditionalController from "./Controllers/VehicleRoadTaxAdditionalController";
import VehicleRoadTaxController from "./Controllers/VehicleRoadTaxController";
import VehicleServiceController from "./Controllers/VehicleServiceController";
import TelepassUsageController from "./Controllers/TelepassUsageController";
import VehicleTestController from "./Controllers/VehicleTestController";
import AdminController from "./Controllers/AdminController";
import FleetManagerController from "./Controllers/FleetManagerController";
import InvoiceController from "./Controllers/InvoiceController";
import VoucherController from "./Controllers/VoucherController";
import TemplateController from "./Controllers/TemplateController";
import VehicleKilometersEntriesController from "./Controllers/VehicleKilometersEntriesController";
import CarConfiguratorController from "./Controllers/CarConfiguratorController";
import FAQController from "./Controllers/FAQController";
import MenuController from "./Controllers/MenuController";
import UserTypesController from "./Controllers/UserTypesController";
import QuotationController from "./Controllers/QuotationController";
import InfoCarController from "./Controllers/InfoCarController";

const ApiService: any = {
  TicketController,
  UploadController,
  FAQController,
  QuotationController,
  UserTypesController,
  PaymentsRoadTaxController,
  SessionController,
  FringeController,
  MessagesController,
  AccountController,
  FleetPlanController,
  MenuController,
  InvoiceController,
  TemplateController,
  ServiceController,
  SubscriptionController,
  AdminController,
  StepperController,
  SupplierController,
  StatisticsController,
  CompanyController,
  VehicleKilometersEntriesController,
  OrderController,
  DriverController,
  VehicleDataController,
  DeadlineController,
  VehicleContractController,
  VehicleServiceController,
  AttachmentController,
  InsuranceController,
  FuelCardsController,
  TelepassController,
  AssignmentController,
  ContractController,
  CarConfiguratorController,
  SearchController,
  PaymentController,
  BrokerController,
  CalendarController,
  VehicleRoadTaxController,
  VehicleRoadTaxAdditionalController,
  VehicleTestController,
  VehicleInspectionController,
  VehicleFuelSupplyController,
  DisposalController,
  VehicleDamageController,
  VehicleReservationController,
  VehicleReservationIncomingController,
  VehicleReservationOutgoingController,
  TelepassUsageController,
  FleetManagerController,
  VoucherController,
  InfoCarController,
};
if (window.location.href.indexOf("localhost") !== -1) {
  (window as any)["ApiService"] = ApiService;
}

export default ApiService;
