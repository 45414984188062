import { useState, useEffect } from "react";
import i18next from "i18next";
import "./StepPayment.scss";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { IPaymentType } from "../../Models/IPayment";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import FormPayment from "./FormPayment";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import { writePaymentData } from "../../Pages/PaymentReadyPage/PaymentReadyPage";
import AppRoutes from "../../Costants/AppRoutes";
import { useNavigate } from "react-router-dom";
import { IStepperStep } from "../../Models/IStepperBody";
import { JSONPrint } from "../../Utils/Decoder";

export interface IStepPaymentProps {
  nextPage?: number;
  stepId?: number;
  subscriptionID?: number;
  accountID?: number | null;
  isRegistration: boolean;
  payingForDriver?: boolean;
}

const StepPayment = (props: IStepPaymentProps) => {
  const navigate = useNavigate();
  /* REDUCER OF LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  // Selected state and state to check Multiform

  // State for spinning the process
  const [missingStepsNames, setMissingStepsNames] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingPaymentInfo, setLoadingPaymentInfo] = useState<boolean>(false);
  const [loadingPaymentType, setLoadingPaymentType] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);
  const [subscriptionDurationID, setSubscriptionDurationID] =
    useState<number>();

  useEffect(() => {
    if (props.isRegistration) {
      updateAccountRegistrationStep(loggedUser, props.stepId, false);
    }
  }, []);

  const loadPaymentType = (subscriptionDurationID: number) => {
    if (loggedUser) {
      setLoadingPaymentType(true);
      ApiService.PaymentController.GetPaymentType(
        null,
        subscriptionDurationID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentType(false);
        }
      );
    }
  };

  const testPaymentReady = (paymentData: any) => {
    ApiService.StepperController.StepperGetData((response: IAPIResponse) => {
      if (response.error === null) {
        let steps: IStepperStep[] = response.payload;

        let skippableSteps = [3, 4, 5, 6, 8];
        let pendingSteps = steps.filter(
          (x: IStepperStep) =>
            x.isLastStep === false &&
            x.registrationStepCompleted === false &&
            skippableSteps.indexOf(x.registrationStepID) === -1
        );

        setMissingStepsNames(
          pendingSteps.map((x: IStepperStep) => {
            return x.registrationStepDescription;
          })
        );

        if (
          pendingSteps.length === 0 ||
          (window as any)["allow-direct-payment"]
        ) {
          paymentData.stepIndex = props.stepId;
          if ((window as any)["use-payment-price"]) {
            paymentData.amount = (window as any)["use-payment-price"];
          }
          if (props?.accountID) {
            paymentData["accountID"] = props.accountID;
          }
          if (props.payingForDriver) {
            paymentData["payingForDriver"] = true;
          }
          writePaymentData({
            ...paymentData,
            isFirstPayment: true,
            data: {
              subscriptionDurationID: subscriptionDurationID,
              selectedServiceIDList: [],
            },
          });
          navigate(AppRoutes.PAYMENT_READY_PAGE);
        } else {
          SummonModal("Pending_Steps_Alert");
        }
      } else {
        ToastMessage(response.error, "error");
      }
    });
  };

  /* RETURN */
  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titlePayment") ?? "",
          }}
        ></h1>
        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitlePayment")}
        </h2>

        <FormPayment
          updatePaymentType={(subscriptionDurationID: number) => {
            setSubscriptionDurationID(subscriptionDurationID);
            loadPaymentType(subscriptionDurationID);
          }}
          isRegistration={props.isRegistration}
          onPaymentEdit={(paymentData) => {
            if (
              paymentData &&
              paymentData.amount !== undefined &&
              paymentData.billingEmail &&
              paymentData.billingName &&
              paymentData.billingPhone &&
              paymentData.billingSurname &&
              paymentData.paymentTypeID
            ) {
              testPaymentReady(paymentData);
            } else {
              SummonModal("cant_pay_modal");
            }
          }}
          loggedUser={loggedUser}
          paymentType={paymentType}
          subscriptionID={props?.subscriptionID}
        />
      </div>

      <SmartModal
        modalUniqueId="cant_pay_modal"
        title={i18next.t("message:cant_pay_title")}
        modalInnerComponent={<div>{i18next.t("message:cant_pay_text")}</div>}
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("cant_pay_modal");
            },
          },
        ]}
      />

      <SmartModal
        modalUniqueId="Pending_Steps_Alert"
        title={i18next.t("message:missing_steps_alert_title")}
        modalInnerComponent={
          <div>
            {i18next.t("message:missing_steps_alert_text")}
            <ul>
              {missingStepsNames.map((x: string, i: number) => {
                return <li key={i}>{x}</li>;
              })}
            </ul>
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("Pending_Steps_Alert");
            },
          },
        ]}
      />

      <LoaderBackdrop
        loading={processing || loadingPaymentInfo || loadingPaymentType}
      />
    </>
  );
};

export default StepPayment;
