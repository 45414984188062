import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IDeadline } from "../../Models/IDeadlines";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SaveIcon from "@mui/icons-material/Save";
import "./DeadlineMainHandler.scss";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { ICompanyGet } from "../../Models/ICompany";
import PreviewIcon from "@mui/icons-material/Preview";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StrongDatePicker from "../MultiForm/SpecialInputs/StrongDatePicker";
import { downloadWithName, getBlobDesc } from "../../Utils/FileToBase";
import DownloadIcon from "@mui/icons-material/Download";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
export interface IDeadlineMainHandlerProps {
  DeadlineFamily:
    | "vehicles"
    | "orders"
    | "telepass"
    | "assignments"
    | "contract"
    | "fuelcard"
    | "otherservices";
  vehicleID?: number;
  disabled?: boolean;
  extraGetParameters?: { name: string; value: number };
}

const targetAPIS: {
  [key: string]: {
    GET: string, 
    INSERT: string, 
    UPDATE?: string, 
    DELETE?: string, 
    paramName?: string
  };
} = {
  vehicles: {
    GET: ApiEndPoints.VEHICLE_DEADLINE_GET,
    INSERT: ApiEndPoints.VEHICLE_DEADLINE_INSERT,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "vehicleID",
  },
  orders: {
    GET: ApiEndPoints.ORDER_GET_DEADLINE,
    INSERT: ApiEndPoints.ORDER_INSERT_DEADLINE,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "vehicleOrderID",
  },
  contract: {
    GET: ApiEndPoints.VEHICLE_CONTRACT_GET_DEADLINE,
    INSERT: ApiEndPoints.VEHICLE_CONTRACT_INSERT_DEADLINE,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "vehicleContractID"
  },
  assignments: {
    GET: ApiEndPoints.VEHICLE_ASSIGNMENT_GET_DEADLINE,
    INSERT: ApiEndPoints.VEHICLE_ASSIGNMENT_INSERT_DEADLINE,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "assignmentID"
  },
  fuelcard: {
    GET: ApiEndPoints.VEHICLE_FUEL_CARD_GET_DEADLINE,
    INSERT: ApiEndPoints.VEHICLE_FUEL_CARD_INSERT_DEADLINE,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "fuelCardID"
  },
  telepass: {
    GET: ApiEndPoints.VEHICLE_TELEPASS_GET_DEADLINE,
    INSERT: ApiEndPoints.VEHICLE_TELEPASS_INSERT_DEADLINE,
    UPDATE: ApiEndPoints.VEHICLE_DEADLINE_UPDATE,
    DELETE: ApiEndPoints.VEHICLE_DEADLINE_DELETE,
    paramName: "telepassID"
  },
};

const DeadlineMainHandler = (props: IDeadlineMainHandlerProps) => {
  const [ready, setReady] = useState<boolean>(false);
  const [savvingIndexes, setSavvingIndexes] = useState<boolean[]>([]);
  const [DeadlineList, setDeadlineList] = useState<IDeadline[]>([]);
  const [processingApiDeadline, setProcessingApiDeadline] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [DeadlineOrderRows, setDeadlineOrderRows] = useState<number>(1);
  const [filePreviewData, setFilePreviewData] = useState<string[]>([]);

  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const [deleteAtIndexID, setDeleteAtIndexID] = useState<number>(-1);
  const getExtraParams = () => {
    if (props.extraGetParameters) {
      return (
        props.extraGetParameters.name + "=" + props.extraGetParameters.value
      );
    }
    return undefined;
  };

  const tryAssumeParam = (param: string) => {
    if (props.extraGetParameters && props.extraGetParameters.name === param) {
      return props.extraGetParameters.value;
    }
    return undefined;
  };

  // deadline
  const GetDeadline = () => {
    let itemId: any = props.vehicleID
      ? props.vehicleID
      : tryAssumeParam(targetAPIS[props.DeadlineFamily].paramName as string);

    if (itemId) {
      setLoading(true);
      ApiService.DeadlineController.GetDeadline(
        targetAPIS[props.DeadlineFamily].GET,
        targetAPIS[props.DeadlineFamily].paramName === "vehicleID" ? itemId : null,
        getExtraParams(),
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDeadlineList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };
  // deadline
  const insertDeadline = (data: any) => {
    setProcessingApiDeadline(true);
    if (props.extraGetParameters) {
      data[props.extraGetParameters.name] = props.extraGetParameters.value;
    }

    ApiService.DeadlineController.InsertDeadline(
      targetAPIS[props.DeadlineFamily].INSERT,
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let Deadlines = [...DeadlineList];
          Deadlines.push({ ...data, ...response.payload });
          setDeadlineList(Deadlines);

          /*ToastMessage(
            i18next.t("message:DeadlineSuccessfullAdded"),
            "success"
          );*/
        } else if (response.raw.status === 400) {
          ToastMessage(i18next.t("error:MISSING_FIELDS"), "error");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessingApiDeadline(false);
      }
    );
  };

  // deadline
  const deleteDeadline = (data: any, index: number) => {
    if (props.extraGetParameters) {
      data[props.extraGetParameters.name] = props.extraGetParameters.value;
    }
    if(targetAPIS[props.DeadlineFamily].DELETE){
      ApiService.DeadlineController.DeleteDeadline(
        targetAPIS[props.DeadlineFamily].DELETE,
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDeadlineList(
              DeadlineList.filter(
                (x: IDeadline) => x.deadlineID !== data.deadlineID
              )
            );
            ToastMessage(
              i18next.t("message:DeadlineSuccessfullDeleted"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setSavingAt(index, false);
        }
      );
    }
  };

  const saveDeadlineAt = (index: number) => {
    let data = { ...DeadlineList[index] };
    updateDeadline(data, index);
  };

  // deadline
  const updateDeadline = (data: IDeadline, index: number, nopopup = false) => {
    setSavingAt(index, true);
    ApiService.DeadlineController.UpdateDeadline(
      targetAPIS["vehicles"].UPDATE,
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let Deadlines = [...DeadlineList];
          Deadlines[index] = { ...data };
          setDeadlineList(Deadlines);

          if (!nopopup) {
            ToastMessage(
              i18next.t("message:DeadlineSuccessfullUpdated"),
              "success"
            );
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setSavingAt(index, false);
      }
    );
  };

  useEffect(() => {
    if (ready) {
      GetDeadline();
    }
  }, [ready]);

  useEffect(() => {
    if (props.vehicleID || props.extraGetParameters) {
      setReady(true);
    }
  }, [props.vehicleID, props.extraGetParameters]);

  const setSavingAt = (index: number, value: boolean) => {
    let indxes: boolean[] = [...savvingIndexes];
    indxes[index] = value;
    setSavvingIndexes(indxes);
  };

  useEffect(() => {
    if (DeadlineList.length > 0) {
      let indxes: boolean[] = [];
      for (let i = 0; i < DeadlineList.length; i++) {
        indxes.push(false);
      }
      setSavvingIndexes(indxes);
    }
  }, [DeadlineList]);

  const downloadFile = (i: number, blobUri: string) => {
    setSavingAt(i, true);
    ApiService.UploadController.GetSASString(
      "deadline" + props.DeadlineFamily,
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  const uploadFile = (e: any, i: number) => {
    setSavingAt(i, true);
    ApiService.UploadController.UploadFile(
      "deadline" + props.DeadlineFamily,
      Array.from(e.target.files),
      (response: IAPIResponse) => {
        if (response.error === null) {
          let deadline = { ...DeadlineList[i] };
          deadline.deadlineAttachmentURL = response.payload.fileReference;
          updateDeadline(deadline, i, true);

          ToastMessage(i18next.t("message:upload_success"), "success");
        } else {
          ToastMessage(i18next.t("error:could_not_upload"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  const deleteAtIndex = (index: number) => {
    let deadline = { ...DeadlineList[index] };

    setSavingAt(index, true);
    ApiService.UploadController.DeleteFile(
      "deadline" + props.DeadlineFamily,
      deadline.deadlineAttachmentURL,
      (response: IAPIResponse) => {
        if (response.error === null) {
          deadline.deadlineAttachmentURL = "";
          updateDeadline(deadline, index);
          DismissModal("deletePermanentAttachmentDeadline");
        } else {
          ToastMessage(i18next.t("error:could_not_delete_file"), "error");
          setSavingAt(index, false);
        }
      }
    );
  };

  const previewFileWithName = (path: string, fileName: string) => {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest(),
      file;

    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      file = new Blob([xhr.response], { type: "application/octet-stream" });

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result as string;
        setFilePreviewData([result, fileName]);
        setTimeout(() => {
          SummonModal("preview_modal_deadline");
        }, 500);
      };
    };
    xhr.send();
  };

  const previewFile = (i: number, blobUri: string) => {
    setSavingAt(i, true);
    ApiService.UploadController.GetSASString(
      "deadline" + props.DeadlineFamily,
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            previewFileWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setSavingAt(i, false);
      }
    );
  };

  return (
    <div className="full-width">
      {!ready && (
        <div className="deadline-multi-form-title-wrap">
          {i18next.t("generic:Deadline_enable")}
        </div>
      )}

      {ready && !loading && (
        <div
          style={{ margin: "0px 0.5em 1em" }}
          className="container-add-form-background multi-form-main-wrap"
        >
          <div>
            <div className="multi-form-title">
              {i18next.t("generic:labelDeadlines")}
            </div>
          </div>
          {DeadlineList.map((x: IDeadline, i: number) => {
            return (
              <div key={i} className="deadline-main-row">
                <div className="deadline-main-row-contain-input">
                  <TextField
                    fullWidth
                    size="small"
                    disabled={savvingIndexes[i] || props.disabled}
                    value={x.deadlineDescription}
                    onChange={(e: any) => {
                      let Deadlines = [...DeadlineList];
                      Deadlines[i].deadlineDescription = e.target.value;
                      setDeadlineList(Deadlines);
                    }}
                    label={i18next.t("form:description") as string}
                    placeholder={i18next.t("form:description") as string}
                  />
                </div>
                <div className="deadline-main-gap"></div>

                <div className="deadline-main-row-contain-input-date">
                  <StrongDatePicker
                    type="date"
                    name="deadlineExpirationDT"
                    disabled={savvingIndexes[i] || props.disabled}
                    defaultValue={x.deadlineExpirationDT}
                    label={
                      i18next.t("navigation:_ticket_modal_exp_date") as string
                    }
                    placeholder={
                      i18next.t("navigation:_ticket_modal_exp_date") as string
                    }
                    onChange={(value: string) => {
                      let Deadlines = [...DeadlineList];
                      Deadlines[i].deadlineExpirationDT = value;
                      setDeadlineList(Deadlines);
                    }}
                  />
                </div>

                <div className="deadline-main-gap"></div>

                <div className="deadline-row-button-liner">
                  <div className="deadline-url-label">
                    {getBlobDesc(x.deadlineAttachmentURL)}
                  </div>
                  {!savvingIndexes[i] && (
                    <Tooltip title={i18next.t("navigation:save_deadline")}>
                      <IconButton
                        disabled={props.disabled}
                        color="primary"
                        aria-label="upload"
                        component="label"
                        onClick={() => {
                          saveDeadlineAt(i);
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!x.deadlineAttachmentURL && !savvingIndexes[i] && (
                    <Tooltip title={i18next.t("navigation:upload_file")}>
                      <IconButton
                        disabled={props.disabled}
                        color="primary"
                        aria-label="upload"
                        component="label"
                      >
                        <input
                          hidden
                          accept="*"
                          type="file"
                          onChange={(e: any) => {
                            uploadFile(e, i);
                          }}
                        />
                        <AttachFileIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {!savvingIndexes[i] && x.deadlineAttachmentURL !== "" && (
                    <span style={{ position: "relative" }}>
                      <Tooltip title={i18next.t("navigation:delete_file")}>
                        <IconButton
                          disabled={props.disabled}
                          color="primary"
                          aria-label="upload"
                          component="label"
                          onClick={() => {
                            SummonModal("deletePermanentAttachmentDeadline");
                            setDeleteAtIndexID(i);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )}
                  {x.deadlineAttachmentURL !== "" &&
                    !savvingIndexes[i] &&
                    getBlobDesc(x.deadlineAttachmentURL.toLowerCase()).endsWith(".pdf") && (
                      <span style={{ position: "relative" }}>
                        <Tooltip title={i18next.t("navigation:preview_file")}>
                          <IconButton
                            color="primary"
                            aria-label="download"
                            component="label"
                            onClick={() => {
                              previewFile(i, x.deadlineAttachmentURL);
                            }}
                          >
                            <PreviewIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    )}
                  {x.deadlineAttachmentURL && !savvingIndexes[i] && (
                    <span style={{ position: "relative" }}>
                      <Tooltip title={i18next.t("navigation:download_file")}>
                        <IconButton
                          color="primary"
                          aria-label="doqnload"
                          component="label"
                          onClick={() => {
                            downloadFile(i, x.deadlineAttachmentURL);
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )}
                  {x.deadlineID && (
                    <span style={{ position: "relative" }}>
                      <Tooltip title={i18next.t("navigation:delete_deadline")}>
                        <IconButton
                          color="primary"
                          aria-label="doqnload"
                          component="label"
                          onClick={() => {
                            deleteDeadline(x, i);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  )}

                  {savvingIndexes[i] && (
                    <div className="deadline-circular">
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {!props.disabled && (
            <div className="multi-form-input-wrap" style={{ paddingBottom: 0 }}>
              <AddNewBanner
                forceDisplay
                suppressLayout
                label={i18next.t("generic:newDeadlines")}
                new={() => {
                  if (loggedUser) {
                    insertDeadline({
                      deadlineURL: "",
                      deadlineDescription: "",
                      deadlineExpirationDT: new Date().toISOString(),
                      deadlineDaysNotice: 0,
                      deadlineAttachmentURL: "",
                    }); // we want an empty new deadline
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
      {filePreviewData.length > 0 && (
        <SmartModal
          modalUniqueId="preview_modal_deadline"
          title={filePreviewData[1]}
          modalInnerComponent={
            <div>
              <iframe
                style={{ width: "80vw", height: "80vh" }}
                src={filePreviewData[0].replace(
                  "data:application/octet-stream;",
                  "data:application/pdf;"
                )}
              ></iframe>
            </div>
          }
          onReject={() => {
            DismissModal("preview_modal_deadline");
          }}
        />
      )}
      <SmartModal 
        title={i18next.t("navigation:delete_file")}
        modalUniqueId="deletePermanentAttachmentDeadline"
        modalInnerComponent={
          <p>{i18next.t("navigation:deletePermanentAttachment")}</p>
        }
        onAccept={() => {
          deleteAtIndex(deleteAtIndexID);
        }}
        onReject={() => {
          DismissModal("deletePermanentAttachmentDeadline");
        }}
      />
      {ready && loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "4em",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default DeadlineMainHandler;
