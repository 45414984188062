import { useEffect, useState } from "react";

/* STYLE */
import "./CustomSideList.scss";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* MODELS */
import { IDriverGet } from "../../Models/IDriver";

/* MUI */
import { IconButton, ListSubheader, List, ListItem, ListItemText } from "@mui/material/";
// Icons
import EditIcon from "@mui/icons-material/Edit";

/* INTERFACES */
export interface ICustomSideList {
  title?: string | null;
  sideContent?: string;
  list: any[];
  updateCurrent: any;
  elementTitle: (item: any) => string | string[];
  selected?: { value: string; key: string };
}

/* EXPORT FUNCTIONS */
export const testEnabledSidebar2 = (driver: IDriverGet | undefined) => {
  if (driver) {
    let driverID = driver ? driver?.driverInfo.driverID : 1;
    if (window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD)) {
      return driverID !== -1;
    } else {
      return true;
    }
  }

  return true;
};

/* COMPONENT */
const CustomSideList = (props: ICustomSideList) => {
  const [maxHeight, setMaxHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    let sideContentHeight = 0;
    if (props.sideContent && 
      (window as any).document.querySelector(props.sideContent)) {
        sideContentHeight = (window as any).document.querySelector(props.sideContent).clientHeight;
      }
      if(sideContentHeight > 50){
        setMaxHeight(sideContentHeight + 30);
      }else{
        setMaxHeight(window.innerHeight);
      }
  }, [props.sideContent, window.innerHeight]);

  // Estrapolate data
  const estrapolateFromDotNotation = (item: any, path: string) => {
    if (item) {
      let keys: string[] = path.split(".");
      let cursor: any = { ...item };

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (cursor[key]) {
          cursor = cursor[key];
        }
      }

      return cursor;
    }

    return null;
  };

  /* RETURN */
  return (
    <div className={"container-stepper-list-main-wrap"}>
      <div className="container-stepper-list add-border" style={{maxHeight: maxHeight + "px", overflow: "auto"}}>
        <List
          sx={{
            maxHeight: maxHeight,
            width: "100%",
            height: "100%",
            overflow: "auto",
            paddingTop: "0",
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {props.title && (
            <ListSubheader
              className="sideList-Title"
              sx={{ lineHeight: "1.2em", paddingTop: "1em" }}
            >
              {props.title}
            </ListSubheader>
          )}
          {props.list.map((item: any, i: number) => {
            let t: string | string[] = props.elementTitle(item);
            let t1: string = "";
            let sub: string = "";
            if (Array.isArray(t)) {
              t1 = t[0];
              sub = t[1];
            } else {
              t1 = t;
            }

            return (
              <ListItem
                key={i}
                className={
                  props.selected
                    ? props.selected.value ===
                      estrapolateFromDotNotation(item, props.selected.key)
                      ? "selectedList"
                      : ""
                    : ""
                }
              >
                <ListItemText primary={t1} secondary={sub} />
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => props.updateCurrent(item)}
                >
                  <EditIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default CustomSideList;
