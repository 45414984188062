import { IMenuRole } from "../../Components/NavMenu/NavMenu";
import { IBrokerCompanyGet } from "../../Models/IBroker";
import { ICompanyGet } from "../../Models/ICompany";
import { ILoginStatus } from "../../Models/ILoginResult";
import { IUser } from "../../Models/IUser";

export const SET_USER_IMP = "SET_USER_IMP";
export const SET_USER = "SET_USER";
export const SET_BROKER_GRANTS = "SET_BROKER_GRANTS";

export const SET_MENU_PAGES = "SET_MENU_PAGES";
export const SET_MENU_PAGES_EXPIRED = "SET_MENU_PAGES_EXPIRED";
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS";
export const SET_COMPANY = "SET_COMPANY";
export const SET_AVATAR = "SET_AVATAR";
export const SET_CURRENT_BROKER_COMPANY = "SET_CURRENT_BROKER_COMPANY";

interface SetBrokerGrantsActionType {
  type: typeof SET_BROKER_GRANTS;
  payload: any[];
}

interface SetLoginStatusActionType {
  type: typeof SET_LOGIN_STATUS;
  payload: ILoginStatus | undefined;
}

interface SetMenuPagesExpiredActionType {
  type: typeof SET_MENU_PAGES_EXPIRED;
  payload: boolean;
}

interface SetMenuPagesActionType {
  type: typeof SET_MENU_PAGES;
  payload: IMenuRole[] | undefined;
}

interface SetUserAvatarActionType {
  type: typeof SET_AVATAR;
  payload: string | undefined;
}

interface SetUserImpActionType {
  type: typeof SET_USER_IMP;
  payload: IUser | undefined;
}
interface SetUserActionType {
  type: typeof SET_USER;
  payload: IUser | undefined;
}

interface SetCompanyActionType {
  type: typeof SET_COMPANY;
  payload: ICompanyGet[] | undefined;
}

interface SetCurrentBrokerCompanyActionType {
  type: typeof SET_CURRENT_BROKER_COMPANY;
  payload: IBrokerCompanyGet | undefined;
}

export type UserActionType =
  | SetUserActionType
  | SetCompanyActionType
  | SetUserImpActionType
  | SetUserAvatarActionType
  | SetMenuPagesActionType
  | SetMenuPagesExpiredActionType
  | SetLoginStatusActionType
  | SetBrokerGrantsActionType
  | SetCurrentBrokerCompanyActionType;

const setLoginStatus = (
  loginStatus: ILoginStatus | undefined
): SetLoginStatusActionType => {
  return { type: SET_LOGIN_STATUS, payload: loginStatus };
};

const setBrokerGrants = (grants: any[]): SetBrokerGrantsActionType => {
  return { type: SET_BROKER_GRANTS, payload: grants };
};

const setAvatar = (user: string | undefined): SetUserAvatarActionType => {
  return { type: SET_AVATAR, payload: user };
};

const setMenuPagesExpired = (
  expired: boolean
): SetMenuPagesExpiredActionType => {
  return { type: SET_MENU_PAGES_EXPIRED, payload: expired };
};

const setMenuPages = (
  user: IMenuRole[] | undefined
): SetMenuPagesActionType => {
  return { type: SET_MENU_PAGES, payload: user };
};

const setUser = (user: IUser | undefined): SetUserActionType => {
  return { type: SET_USER, payload: user };
};

const setUserImp = (user: IUser | undefined): SetUserImpActionType => {
  return { type: SET_USER_IMP, payload: user };
};

const setCompanies = (
  user: ICompanyGet[] | undefined
): SetCompanyActionType => {
  return { type: SET_COMPANY, payload: user };
};

const setCurrentBrokerCompany = (
  brokerCompany: IBrokerCompanyGet | undefined
): SetCurrentBrokerCompanyActionType => {
  return { type: SET_CURRENT_BROKER_COMPANY, payload: brokerCompany };
};

export const UserActions = {
  setUser,
  setUserImp,
  setBrokerGrants,
  setLoginStatus,
  setCompanies,
  setMenuPages,
  setMenuPagesExpired,
  setAvatar,
  setCurrentBrokerCompany,
};
