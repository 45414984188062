/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* UTILS */
import { ToastMessage } from "../../../../../../../Utils/Toastify";

/* SERVICES */
import ApiService from "../../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../../Services/Internal/AjaxService";

/* MODELS */
import { IUPDATE_CarConfiguratorGroup_Close_Vehicle_Package } from "../../../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import LoaderBackdrop from "../../../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
// Icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* INTERFACE */
interface IPackageGrid {
  groupVehicleID: number;
}

/* COMPONENT */
const PackageGrid = (props: IPackageGrid) => {
  /* STATES */
  // Datagrid
  const [list, setList] = useState<any>([]);
  // Packages
  const [packages, setPackages] = useState<any>([]);
  // Optionals type
  const [optionalsType, setOptionalsType] = useState<any>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState(false);
  const [loadingGetType, setLoadingGetType] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  /* API */
  // Load packages
  const GET_CarConfiguratorGroup_Close_Vehicle_Package = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup_Close_Vehicle_Package(
      props.groupVehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          // Set and sort datagrid data
          setList(
            response.payload
              .sort((a: any, b: any) =>
                a.codmar > b.codmar ? 1 : b.codmar > a.codmar ? -1 : 0
              )
              .sort((a: any, b: any) =>
                a.codqrt > b.codqrt ? 1 : b.codqrt > a.codqrt ? -1 : 0
              )
          );

          // Set already checked packages
          setPackages(
            response.payload
              .filter((x: any) => x.selected)
              .map((x: any) => {
                return {
                  groupVehicleID: props.groupVehicleID,
                  codmar: x.codmar,
                  codqrt: x.codqrt,
                  type: x.type,
                };
              })
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Update packages
  const UPDATE_CarConfiguratorGroup_Close_Vehicle_Package = (
    data: IUPDATE_CarConfiguratorGroup_Close_Vehicle_Package
  ) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfiguratorGroup_Close_Vehicle_Package(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  // Load optional type
  const GET_CarConfiguratorGroup_Close_Vehicle_Optional_Type = () => {
    setLoadingGetType(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup_Close_Vehicle_Optional_Type(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setOptionalsType(response.payload);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingGetType(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    GET_CarConfiguratorGroup_Close_Vehicle_Package();
    GET_CarConfiguratorGroup_Close_Vehicle_Optional_Type();
  }, []);

  /* FUNCTIONS */
  // Render price
  const renderPrice = (props: GridRenderCellParams) => {
    return <div>{props.value + " €"}</div>;
  };

  // Row selection
  const renderSelect = (propsCell: GridRenderCellParams) => {
    return (
      <Checkbox
        checked={propsCell.row.selected}
        onChange={(_, checked) => {
          // Controlled checkbox
          list[propsCell.row.id].selected = checked;

          // Format data for save
          let tmp = [...packages];

          if (checked) {
            tmp.push({
              groupVehicleID: props.groupVehicleID,
              codmar: propsCell.row.codmar,
              codqrt: propsCell.row.codqrt,
              type: propsCell.row.type,
            });
          } else {
            tmp = tmp.filter(
              (x: any) =>
                x.codmar !== propsCell.row.codmar && x.codqrt !== propsCell.row.codqrt
            );
          }

          setPackages(tmp);
        }}
      />
    );
  };

  // Render type
  const renderType = (props: GridRenderCellParams) => {
    return (
      <FormControl variant="filled" disabled={!props.row.selected}>
        <Select
          value={props.value}
          onChange={(event: SelectChangeEvent) => {
            setLoadingType(true);

            setTimeout(() => {
              const listArr = list.filter(
                (x: any) => x.codmar === props.row.codmar && x.codqrt === props.row.codqrt
              );
              const filteredArr = packages.filter(
                (x: any) => x.codmar === props.row.codmar && x.codqrt === props.row.codqrt
              );
              if (listArr[0]) {
                listArr[0].type = event.target.value;
              }
              if (filteredArr[0]) {
                filteredArr[0].type = event.target.value;
              }

              setLoadingType(false);
            }, 500);
          }}
          sx={{
            "& .MuiSelect-select": {
              padding: "16px",
            },
          }}
        >
          {optionalsType.map((x: any) => (
            <MenuItem value={x.id}>{x.description}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /* COLUMNS */
  const columnsDef: any[] = [
    {
      field: "select",
      headerName: i18next.t("navigation:select"),
      flex: 1,
      renderCell: renderSelect,
    },
    {
      field: "codmar",
      headerName: i18next.t("form:codmar"),
      flex: 1,
    },
    {
      field: "codqrt",
      headerName: i18next.t("form:codqrt"),
      flex: 1,
    },
    {
      field: "description",
      headerName: i18next.t("form:description"),
      flex: 5,
    },
    {
      field: "price",
      headerName: i18next.t("form:cost"),
      flex: 1,
      renderCell: renderPrice,
    },
    {
      field: "type",
      editable: true,
      headerName: i18next.t("form:type"),
      flex: 3,
      renderHeader: () => (
        <div style={{ fontWeight: 500 }}>
          {i18next.t("form:type")}{" "}
          <Tooltip title="Seleziona tipologia">
            <IconButton>
              <InfoOutlinedIcon sx={{ fontSize: "0.7em" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
      renderCell: renderType,
    },
    {
      field: "contribution",
      headerName: i18next.t("form:contribution"),
      flex: 1,
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      selected: list[i].selected,
      codmar: list[i].codmar,
      codqrt: list[i].codqrt,
      description: list[i].description,
      price: list[i].price,
      type: list[i].type || 1,
      contribution: list[i].contribution,
    });
  }

  /* RETURN */
  return (
    <>
      {!loadingType && (
        <div style={{ height: 450 }}>
          <DataGrid
            disableColumnMenu
            autoPageSize
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: i18next.t("form:noData").toString() }}
            columns={columnsDef}
            rows={rowsDef}
          />
        </div>
      )}

      <div className="display-flex-end margin-top-small">
        <Button
          variant="contained"
          onClick={() => {
            UPDATE_CarConfiguratorGroup_Close_Vehicle_Package(packages);
          }}
        >
          {i18next.t("generic:save")}
        </Button>
      </div>

      <LoaderBackdrop
        loading={loadingGet || loadingGetType || loadingType || loadingUpdate}
      />
    </>
  );
};

export default PackageGrid;
