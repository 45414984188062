import { CircularProgress } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";

export interface IPayPalSubscriptionProps {
  planId: string;
  disabled?: boolean;
  onPaymentDone: (data: any) => void;
  onPaymentCancelled: () => void;
}

export const PayPalPaymentSubscription = (props: {
  planId: string;
  onPaymentDone: (data: any) => void;
  onPaymentCancelled: () => void;
}) => {
  const [message, setMessage] = useState("");
  const [waitLoadingFake, setWaitLoadingFake] = useState<boolean>(true);
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  useEffect(() => {
    setTimeout(() => {
      setWaitLoadingFake(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
      {waitLoadingFake && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            minHeight: "10em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        style={
          waitLoadingFake
            ? {
                opacity: "0",
                maxHeight: "0",
                overflow: "hidden",
              }
            : {}
        }
      >
        <PayPalScriptProvider
          options={
            {
              ...initialOptions,
              intent: "subscription",
              vault: true,
              "enable-funding": "paylater,card",
            } as any
          }
        >
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
            }}
            createSubscription={(data: any, actions: any) => {
              return actions.subscription.create({
                plan_id: props.planId.substring(0, 26),
              });
            }}
            onCancel={() => {
              props.onPaymentCancelled();
            }}
            onApprove={async (data: any, actions: any) => {
              props.onPaymentDone({
                data: data,
                actions: actions,
                order: { id: data.orderID },
              });
            }}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
};

const PayPalSubscription = (props: IPayPalSubscriptionProps) => {
  return (
    <div>
      <PayPalPaymentSubscription {...props} />
    </div>
  );
};

export default PayPalSubscription;
