import i18next from "i18next";
import { IVehicleDamage } from "../../Models/IVehicleDamage";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

export interface IVehicleDamageImageMapProps {
  damageList: IVehicleDamage[];
  onGetCoordinates: (e: any, imgId: string) => void;
  imageSchemaID?: string;
  imageMapSchemaID?: string;
  imgClassName?: string;
}

const VehicleDamageImageMap = (props: IVehicleDamageImageMapProps) => {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  const resizeWindow = () => {
    let imageWrapper = window.document.getElementsByClassName(
      props.imgClassName ?? "image-wrapper-noprintable"
    );
    if (imageWrapper && imageWrapper.length > 0) {
      for (let e = 0; e < imageWrapper.length; e++) {
        if (imageWrapper[e].clientWidth > 0 && imageWrapper[e].clientHeight) {
          setWindowWidth(imageWrapper[e].clientWidth);
          setWindowHeight(imageWrapper[e].clientHeight);
          return false;
        }
      }
    }
  };

  const recalcCoordinatesMap = (
    imageWidth: number,
    imageHeight: number,
    coordinates: string
  ) => {
    let wPercent: number = windowWidth / 100;
    let hPercent: number = windowHeight / 100;
    var coords: string[] = coordinates.split(","),
      coordsPercent: any[] = new Array(coords.length);

    for (var i = 0; i < (coordsPercent ?? []).length; ++i) {
      if (i % 2 === 0) {
        coordsPercent[i] = ((coords[i] as any) / imageWidth) * 100 * wPercent;
      } else {
        coordsPercent[i] = ((coords[i] as any) / imageHeight) * 100 * hPercent;
      }
      coordsPercent[i] = parseInt(coordsPercent[i]);
    }
    return coordsPercent.join(",");
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);

    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  });

  return (
    <div
      className={"image-wrapper " + (props.imgClassName ?? "image-wrapper-noprintable")}
    >
      {(props.damageList ?? []).map((x: IVehicleDamage, i: number) => {
        return (
          <Tooltip title={x.vehicleDamageDescription as string}>
            <div
              className="cursor-point-key"
              key={i}
              style={{
                color: "#fff",
                background: x.vehicleDamageID ? "#e74c3c" : "#f1c40f",
                left: `calc(${x.vehicleDamageCoordinateX}% - 20px)`,
                top: `calc(${x.vehicleDamageCoordinateY}% - 20px)`,
              }}
            >
              {i + 1}
            </div>
          </Tooltip>
        );
      })}
      <img
        onLoad={() => {
          resizeWindow();
        }}
        id={props.imageSchemaID ?? "imgDamageVehicleSchema"}
        src={process.env.PUBLIC_URL + "/Images/damageVehicleSchema.svg"}
        useMap={"#" + (props.imageMapSchemaID ?? "image_damage_car")}
        alt=""
        width="841"
        height="516"
      />
      <map
        style={{ position: "absolute" }}
        name={props.imageMapSchemaID ?? "image_damage_car"}
      >
        <area
          onClick={(e: any) => {
            props.onGetCoordinates(e, props.imageSchemaID ?? "imgDamageVehicleSchema");
          }}
          alt={i18next.t("generic:front") as string}
          title={i18next.t("generic:front") as string}
          shape="poly"
          coords={recalcCoordinatesMap(
            841,
            516,
            "635,178,608,178,604,130,602,93,608,73,600,67,589,64,585,57,599,46,612,49,622,53,642,5,690,3,720,2,763,4,781,6,809,62,814,51,829,49,839,56,837,66,823,66,816,75,820,99,820,137,818,162,817,178,790,176,790,165,776,161,773,158,653,158,633,161"
          )}
        />
        <area
          alt={i18next.t("generic:side") as string}
          title={i18next.t("generic:side") as string}
          shape="poly"
          coords={recalcCoordinatesMap(
            841,
            516,
            "145,154,380,152,378,161,388,155,389,150,401,169,415,177,430,178,447,174,458,165,466,150,482,149,502,148,510,146,520,136,520,126,517,112,524,110,521,104,512,97,511,87,511,64,474,22,485,16,433,8,436,0,428,0,412,5,352,1,311,1,265,4,227,12,159,46,139,57,122,59,95,62,53,71,23,84,8,94,4,108,0,114,1,129,4,142,2,153,14,158,59,159,67,162,71,157,78,168,92,176,111,179,128,173,139,163"
          )}
          onClick={(e: any) => {
            props.onGetCoordinates(e, props.imageSchemaID ?? "imgDamageVehicleSchema");
          }}
        />
        <area
          alt={i18next.t("generic:top") as string}
          title={i18next.t("generic:top") as string}
          shape="poly"
          coords={recalcCoordinatesMap(
            841,
            516,
            "48,282,178,280,182,271,192,262,202,258,204,267,201,274,200,280,266,278,292,278,349,280,399,279,445,282,476,286,502,296,515,310,520,330,520,340,521,362,521,390,521,440,517,459,512,469,498,480,486,485,461,492,439,492,406,493,359,495,293,494,274,497,254,494,200,494,205,514,199,516,184,508,176,492,154,494,87,494,47,493,20,475,3,442,0,406,2,342,7,320,19,297,36,286"
          )}
          onClick={(e: any) => {
            props.onGetCoordinates(e, props.imageSchemaID ?? "imgDamageVehicleSchema");
          }}
        />
        <area
          alt={i18next.t("generic:rear") as string}
          title={i18next.t("generic:rear") as string}
          shape="poly"
          coords={recalcCoordinatesMap(
            841,
            516,
            "605,470,603,423,603,386,606,372,604,369,608,367,605,361,589,359,584,351,590,345,601,343,610,344,613,357,619,351,637,312,642,302,646,298,659,299,711,294,778,298,786,306,797,326,806,350,813,359,813,350,816,344,824,342,835,345,840,350,838,358,830,361,824,361,821,369,822,379,822,401,821,437,820,469,814,475,792,473,790,468,790,456,774,456,770,452,652,453,650,459,635,457,635,472,620,475,612,474"
          )}
          onClick={(e: any) => {
            props.onGetCoordinates(e, props.imageSchemaID ?? "imgDamageVehicleSchema");
          }}
        />
      </map>
    </div>
  );
};

export default VehicleDamageImageMap;
