import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleDataInsert,
  IVehicleDataUpdate,
  IVehicleReimmPlate,
} from "../../Models/IVehicles";

import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const VehicleDataController = {
  VehicleDataGet: async (
    companyID?: number,
    vehicleID?: number,
    driverID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_BY_COMPANY;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (driverID) {
      parameters.push(`driverID=${driverID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetByAccountID: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_BY_ACCOUNT_ID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetBrands: async (
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_BRANDS}?inProduction=${inProduction}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetModels: async (
    brandCode: string,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_MODELS}?brandCode=${brandCode}&inProduction=${inProduction}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetFittings: async (
    brandCode: string,
    modelCode: string,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_FITTING}?brandCode=${brandCode}&modelCode=${modelCode}&inProduction=${inProduction}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetBrandsAccountID: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_BRANDS_ACCOUNTID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetModelsAccountID: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_MODELS_ACCOUNTID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetFittingsAccountID: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GET_FITTING_ACCOUNTID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleGetDeadlinesByVehicleId: async (
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.VEHICLE_DATA_GET_DEADLINE_RESUME;
    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    request.port = "7236";
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  VehicleSearchPreview: async (
    companyID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_SEARCH_PLATE_RESUME;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataResumeGetByAccount: async (
    companyID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_RESUME_GET_BY_ACCOUNT;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleMctc: async (
    vehicleLicensePlate: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_MCTC_GET;
    let parameters: string[] = [];
    if (vehicleLicensePlate) {
      parameters.push(`vehicleLicensePlate=${vehicleLicensePlate}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleCheckCanUserUseMctc: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_CHECK_USER_CAN_MCTC;
    let parameters: string[] = [];

    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGeneralInfo: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.VEHICLE_DATA_GENERAL_INFO}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetVehicleRegion: async (
    regionID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_VEHICLE_REGION;
    if (regionID) {
      request.url += "?regionID" + regionID;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetVehicleProvince: async (
    regionID?: number,
    provinceID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_VEHICLE_PROVINCE;
    let parameters: string[] = [];
    if (regionID) {
      parameters.push(`regionID=${regionID}`);
    }
    if (provinceID) {
      parameters.push(`provinceID=${provinceID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetVehicleType: async (
    vehicleTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_VEHICLE_TYPE;
    if (vehicleTypeID) {
      request.url += "?vehicleTypeID" + vehicleTypeID;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataGetVehicleCategory: async (
    vehicleTypeID?: number,
    vehicleCategory?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_GET_VEHICLE_CATEGORY;

    let parameters: string[] = [];
    if (vehicleTypeID) {
      parameters.push(`vehicleTypeID=${vehicleTypeID}`);
    }
    if (vehicleCategory) {
      parameters.push(`vehicleCategory=${vehicleCategory}`);
    }
    request.url += "?" + parameters.join("&");

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataInsert: async (
    body: IVehicleDataInsert,
    isRegistration: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url =
      ApiEndPoints.VEHICLE_DATA_INSERT +
      "?isRegistration=" +
      isRegistration.toString();
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataUpdate: async (
    body: IVehicleDataUpdate,
    isRegistration: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url =
      ApiEndPoints.VEHICLE_DATA_UPDATE +
      "?isRegistration=" +
      isRegistration.toString();
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReimmPlateGet: async (
    vehicleID?: number,
    vehicleLicencePlateID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_PLATE_REIMM_GET;

    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (vehicleLicencePlateID) {
      parameters.push(`vehicleLicencePlateID=${vehicleLicencePlateID}`);
    }
    request.url += "?" + parameters.join("&");

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReimmPlateInsert: async (
    payload: IVehicleReimmPlate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_PLATE_REIMM_INSERT;
    request.method = "POST";
    request.payload = payload;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReimmPlateUpdate: async (
    payload: IVehicleReimmPlate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_PLATE_REIMM_UPDATE;
    request.method = "PUT";
    request.payload = payload;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataDelete: async (
    vehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_DELETE;
    request.method = "DELETE";
    request.payload = {
      vehicleID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CheckUserCanInsertVehicle: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_CHECK_USER_CAN_INSERT;
    let parameters: string[] = [];
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDataInsertFromExcel: async (
    file: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    formData.append("file", file[0]);

    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DATA_INSERT_FROM_EXCEL;
    request.method = "POST";
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  MctcAttachmentGet: async (
    mctcAttachmentID?: number,
    vehicleID?: number,
    mctcAttachmentName?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.MCTC_ATTACHMENT_GET;
    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if(mctcAttachmentName){
      parameters.push(`mctcAttachmentName=${mctcAttachmentName}`);
    }
    if (mctcAttachmentID) {
      parameters.push(`mctcAttachmentID=${mctcAttachmentID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleDataController;
