import { Button, IconButton, Tooltip } from "@mui/material";
import "./DashboardSectionTitle.scss";

export interface IDashboardSectionTitleProps {
  title: string;

  rightButtons?: {
    label: string;
    onClick: () => void;
    type?: string;
    icon?: JSX.Element;
    disabled?: boolean;
  }[];
}

const DashboardSectionTitle = (props: IDashboardSectionTitleProps) => {
  return (
    <div className="dashboard-section-title-wrap">
      <div className="dashboard-section-title-label">{props.title}</div>
      <div className="dashboard-section-title-row">
        {props.rightButtons &&
          props.rightButtons.map(
            (
              x: {
                label: string;
                onClick: () => void;
                type?: string;
                icon?: JSX.Element;
                disabled?: boolean;
              },
              i: number
            ) => {
              if (x?.type === "icon") {
                return (
                  <Tooltip title={x.label}>
                    <IconButton disabled={x.disabled} key={i} onClick={x.onClick}>
                      {x.icon}
                    </IconButton>
                  </Tooltip>
                );
              } else {
                return (
                  <Button
                    disabled={x.disabled}
                    variant="contained"
                    key={i}
                    onClick={x.onClick}
                  >
                    {x.label}
                  </Button>
                );
              }
            }
          )}
      </div>
    </div>
  );
};

export default DashboardSectionTitle;
