import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import i18next from "i18next";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../../SmartModal/SmartModal";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import { ISupplierGetSideList } from "../../../Models/ISupplier";
import {
  IFuelCardsGet,
  IFuelCardsGetType,
  IFuelCardsInsert,
  IFuelCardsUpdate,
} from "../../../Models/IFuelCards";
import { IDriverGet } from "../../../Models/IDriver";
import DeadlineMainHandler from "../../DeadlineMainHandler/DeadlineMainHandler";
import { IAssignmentGet } from "../../../Models/IAssignment";
import FormSupplier from "../../StepSupplier/FormSupplier";
import { displayUTC0_ISODate } from "../../MultiForm/SpecialInputs/StrongDatePicker";
import AddNewBanner from "../../AddNewBanner/AddNewBanner";
import DataGridWrap, { IDataGridColumnDef } from "../../DataGridWrap/DataGridWrap";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ICompanyGet } from "../../../Models/ICompany";
import AppRoutes from "../../../Costants/AppRoutes";
import { VehiclesActions } from "../../../Reducers/Vehicle/VehicleAction";
import { useDispatch, useSelector } from "react-redux";
import { formatPlate } from "../../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { GlobalState } from "../../../Reducers/RootReducer";

export interface IFormFuelCardsProps {
  loggedUser: IUser | undefined;
  dataVehicle: IVehicleDataGet | any;
  dataAssignment?: IAssignmentGet;
  disabled?: boolean;
  disabledAccordion?: boolean;
  doRefreshForm?: () => void;
  loggedCompany?: ICompanyGet | undefined;
  idModal?: string;
  data?: any;
  doReloadData?: () => void;
}

const FormFuelCards = (props: IFormFuelCardsProps) => {
  const dispatch = useDispatch();
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [processing, setProcessing] = useState<boolean>(false);
  const [supplierFuelCards, setSupplierFuelCards] = useState<ISupplierGetSideList[]>([]);
  const [loadingSupplierFuelCards, setLoadingSupplierFuelCards] =
    useState<boolean>(false);
  const [fuelCardsType, setFuelCardsType] = useState<IFuelCardsGetType[]>([]);
  const [loadingFuelCardsType, setLoadingFuelCardsType] = useState<boolean>(false);
  const [loadingCurrentFuelCard, setLoadingCurrentFuelCard] = useState<boolean>(false);
  const [loadingFuelCards, setLoadingFuelCards] = useState<boolean>(false);
  const [fuelCards, setFuelCards] = useState<IFuelCardsGet[]>([]);

  const [state, setState] = useState<any>(props.data);
  const [loadingDriver, setLoadingDrivers] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<IDriverGet[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState<boolean>(false);
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);

  const isInVehiclePage = window.location.pathname.includes(
    AppRoutes.SEARCH_VEHICLE_DETAIL + "/tab/"
  );
  const isInDashBoard = window.location.href.includes(AppRoutes.INTERNAL_DASHBOARD);

  const loadSupplierFuelCards = () => {
    if (props.loggedUser) {
      setLoadingSupplierFuelCards(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "FUEL_CARD",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierFuelCards(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierFuelCards(false);
        }
      );
    }
  };

  const loadFuelCardsType = () => {
    if (props.loggedUser) {
      setLoadingFuelCardsType(true);
      ApiService.FuelCardsController.FuelCardsGetType((response: IAPIResponse) => {
        if (response.error === null) {
          setFuelCardsType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFuelCardsType(false);
      });
    }
  };

  const loadVehicles = () => {
    if (props.loggedCompany) {
      setLoadingVehicles(true);
      ApiService.VehicleDataController.VehicleDataGet(
        props.loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicles(false);
        }
      );
    }
  };

  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    if (props.loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanyList(false);
        }
      );
    }
  };

  const loadDrivers = () => {
    if (props.loggedUser) {
      setLoadingDrivers(true);
      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          setDrivers(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDrivers(false);
      });
    }
  };

  const loadFuelCards = (vehicleID: number) => {
    if (props.loggedUser) {
      setLoadingFuelCards(true);
      ApiService.FuelCardsController.FuelCardsGet(
        null,
        vehicleID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelCards(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelCards(false);
        }
      );
    }
  };

  const fuelCardsInsert = (data: IFuelCardsInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullAdded"), "success");
          if (props.idModal) {
            if (props.doReloadData) {
              props.doReloadData();
            }
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsUpdate = (data: IFuelCardsUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:fuelCardSuccessfullUpdated"), "success");
          if (props.idModal) {
            if (props.doReloadData) {
              props.doReloadData();
            }
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const fuelCardsDelete = (fuelCardID: number) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.FuelCardsController.FuelCardsDelete(
        fuelCardID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:fuelCardSuccessfullDeleted"), "success");
            requestFormRefresh();
            setState(null);
            if (props.idModal) {
              if (props.doReloadData) {
                props.doReloadData();
              }
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadCurrentFuelCard = (fuelCard: IFuelCardsGet) => {
    setLoadingCurrentFuelCard(true);
    setState(fuelCard);
    setTimeout(() => {
      setLoadingCurrentFuelCard(false);
    }, 1000);
  };

  const newFuelCard = () => {
    setState(null);
    if (props.doRefreshForm) {
      props.doRefreshForm();
    }
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: isInVehiclePage ? 40 : 26.33,
        type: "select",
        name: "supplierID",
        defaultValue: state?.supplierID,
        required: true,
        disabled: props.disabled,
        label: String(i18next.t(`form:supplierFuelCard`)),
        options: supplierFuelCards.map((item: ISupplierGetSideList) => {
          return {
            key: item.supplierInfo.supplierID,
            text: item.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 10 : 7,
        type: "custom",
        name: "supplierAddType",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              variant="contained"
              disabled={props.disabled}
              onClick={() => {
                SummonModal("Supplier-FuelCard-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      }
    );

    if (!isInVehiclePage && isInDashBoard) {
      multiformInputs.push(
        {
          type: "select",
          name: "companyID",
          required: true,
          width: 33.33,
          label: i18next.t("form:orderCompany") as string,
          disabled: props.loggedUser?.accountTypeID === 1,
          defaultValue:
            props.loggedUser?.accountTypeID === 1
              ? companyUser?.companyID
              : state?.companyID,
          options: companyList.map((x: ICompanyGet) => {
            return {
              key: x.companyID,
              text:
                x.companyBusinessName !== ""
                  ? x.companyBusinessName.toUpperCase()
                  : x.companyFiscalCode.toUpperCase(),
            };
          }),
        },
        {
          type: "select",
          name: "vehicleID",
          required: false,
          width: 33.33,
          label: i18next.t("form:plate") as string,
          defaultValue: state?.vehicleID,
          options: vehicles.map((x: IVehicleDataGet) => {
            return {
              key: x.vehicleID,
              text: formatPlate(x.vehicleLicensePlate),
            };
          }),
        }
      );
    }

    multiformInputs.push(
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "select",
        name: "driverID",
        defaultValue: props.dataAssignment?.driverID
          ? props.dataAssignment.driverID
          : state?.driverID,
        required: true,
        disabled: props.disabled,
        label: String(i18next.t(`form:assignDriverName`)),
        options: drivers.map((item: IDriverGet) => {
          let titleDriver = item.driverInfo.driverName;
          if (item.driverInfo?.driverSurname) {
            titleDriver += " " + item.driverInfo.driverSurname;
          }
          return {
            key: item.driverInfo.driverID,
            text: titleDriver.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "select",
        name: "fuelCardTypeID",
        disabled: props.disabled,
        required: true,
        label: i18next.t("form:fuelCardType") as string,
        defaultValue: state?.fuelCardTypeID,
        options: fuelCardsType.map((fuelCardType: IFuelCardsGetType) => {
          return {
            key: fuelCardType.fuelCardTypeID,
            text: fuelCardType.fuelCardTypeName.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 25 : 33.33,
        type: "text",
        disabled: props.disabled,
        name: "fuelCardNumber",
        required: true,
        inputProps: { maxLength: 50 },
        label: i18next.t("form:numberFuelCard") as string,
        defaultValue: state?.fuelCardNumber,
      },
      {
        width: isInVehiclePage ? 25 : 33.33,
        type: "number",
        disabled: props.disabled,
        name: "fuelCardPIN",
        required: false,
        inputProps: {
          min: 0,
          max: 1000000,
        },
        label: i18next.t("form:pinFuelCard") as string,
        defaultValue: state?.fuelCardPIN,
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        disabled: props.disabled,
        type: "date",
        required: true,
        name: "fuelCardExpirationDT",
        label: i18next.t("form:dateExpireFuelCard") as string,
        defaultValue: state?.fuelCardExpirationDT,
        disablePast: true,
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "date",
        required: true,
        disabled: props.disabled,
        name: "fuelCardEndAssignementDT",
        label: i18next.t("form:endAssignmentFuelCard") as string,
        defaultValue: state?.fuelCardEndAssignementDT,
        maxDate: state?.fuelCardExpirationDT,
      },
      {
        type: "custom",
        name: "tip",
        element: <small>{i18next.t("generic:tipTabFuelCards")}</small>,
      }
    );

    return multiformInputs;
  };

  useEffect(() => {
    loadFuelCardsType();
    loadSupplierFuelCards();
    loadDrivers();

    if (!isInVehiclePage && isInDashBoard) {
      loadVehicles();
      loadCompanyList();
    }
  }, []);

  const EditRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            loadCurrentFuelCard(props.row);
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (!props.disabled) {
      loadFuelCards(props?.dataVehicle.vehicleID);
    }
  }, [props.dataVehicle]);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: EditRow,
      type: 'custom'
    },
    {
      field: "supplierName",
      headerName: i18next.t("form:supplierFuelCard") as string,
    },
    {
      field: "driverName",
      headerName: i18next.t("form:assignDriverName") as string,
    },
    {
      field: "fuelCardTypeName",
      headerName: i18next.t("form:fuelCardType") as string,
    },
    {
      field: "fuelCardNumber",
      headerName: i18next.t("form:numberFuelCard") as string,
    },
    {
      field: "fuelCardPIN",
      headerName: i18next.t("form:pinFuelCard") as string,
    },
    {
      field: "fuelCardExpirationDTView",
      headerName: i18next.t("form:dateExpireFuelCard") as string,
      fieldOriginal: "fuelCardExpirationDT",
    },
    {
      field: "fuelCardEndAssignementDTView",
      headerName: i18next.t("form:endAssignmentFuelCard") as string,
      fieldOriginal: "fuelCardEndAssignementDT",
    },
  ];

  /* ROWS */
  const rowsDef = [];
  for (let i = 0; i < fuelCards.length; i++) {
    let driverName = drivers?.find(
      (item: IDriverGet) => item.driverInfo.driverID === fuelCards[i].driverID
    )?.driverInfo.driverName;
    let driverSurname = drivers?.find(
      (item: IDriverGet) => item.driverInfo.driverID === fuelCards[i].driverID
    )?.driverInfo.driverSurname;

    let titleDriver = driverName;
    if (driverSurname) {
      titleDriver += " " + driverSurname;
    }

    rowsDef.push({
      id: i,
      supplierName: supplierFuelCards?.find(
        (x: ISupplierGetSideList) => x.supplierInfo.supplierID === fuelCards[i].supplierID
      )?.supplierInfo.supplierName,
      driverName: titleDriver,
      fuelCardTypeName: fuelCardsType?.find(
        (x: IFuelCardsGetType) => x.fuelCardTypeID === fuelCards[i].fuelCardTypeID
      )?.fuelCardTypeName,
      fuelCardNumber: fuelCards[i].fuelCardNumber,
      fuelCardPIN: fuelCards[i].fuelCardPIN,
      fuelCardExpirationDTView: displayUTC0_ISODate(
        fuelCards[i].fuelCardExpirationDT,
        true
      ),
      fuelCardEndAssignementDTView: displayUTC0_ISODate(
        fuelCards[i].fuelCardEndAssignementDT,
        true
      ),
      fuelCardTypeID: fuelCards[i].fuelCardTypeID,
      supplierID: fuelCards[i].supplierID,
      fuelCardID: fuelCards[i].fuelCardID,
      driverID: fuelCards[i].driverID,
      fuelCardExpirationDT: fuelCards[i].fuelCardExpirationDT,
      fuelCardEndAssignementDT: fuelCards[i].fuelCardEndAssignementDT,
    });
  }

  return (
    <div>
      <div className="padding-small">
        <AddNewBanner
          suppressLayout
          label={i18next.t("navigation:addNewFuelCard") as string}
          new={() => newFuelCard()}
        />
      </div>
      {props.disabled && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}
      {fuelCards.length > 0 && !props.disabledAccordion && (
        <div className="padding-small">
          <DataGridWrap
            tableName={i18next.t("navigation:fuelCardsTabTitle") as string}
            headers={columnsDef}
            rows={rowsDef}
          />
        </div>
      )}
      {!loadingCurrentFuelCard && (
        <div>
          <MultiForm
            formId={"StepFuelCards"}
            suppressLayout
            formTitle={""}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState(data);
            }}
            onSubmit={async (data: any) => {
              let parsedData = { ...data };
              delete parsedData["supplierAddType"];
              delete parsedData["endAssignmentFuelCard"];
              delete parsedData["tip"];

              parsedData["fuelCardPIN"] = +parsedData["fuelCardPIN"];

              if (props.dataVehicle.vehicleID) {
                parsedData["vehicleID"] = props.dataVehicle.vehicleID;
              }

              if (!isInVehiclePage && isInDashBoard) {
                parsedData["vehicleID"] = parsedData?.vehicleID;
              }
              if (props.data?.fuelCardID) {
                parsedData["fuelCardID"] = props.data.fuelCardID;
                fuelCardsUpdate(parsedData);
              } else {
                fuelCardsInsert(parsedData);
              }
            }}
            suppressSubmit={true}
          />
          <DeadlineMainHandler
            DeadlineFamily={"fuelcard"}
            extraGetParameters={
              props.data?.fuelCardID
                ? {
                    name: "fuelCardID",
                    value: props.data?.fuelCardID,
                  }
                : undefined
            }
          />
          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={props.disabled || processing}
                onClick={() => {
                  triggerFormValidation("StepFuelCards");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveFuelCard")}
              </Button>
              <Button
                color="error"
                sx={{ marginRight: "1em" }}
                disabled={!props.data?.fuelCardID}
                onClick={() => {
                  SummonModal("FuelCardDelete-modal");
                }}
                variant="contained"
                endIcon={<DeleteIcon />}
              >
                {i18next.t("form:deleteFuelCard")}
              </Button>
              <SmartModal
                modalUniqueId="FuelCardDelete-modal"
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace("CURRENT_ITEM_LIST", state?.fuelCardNumber),
                    }}
                  />
                }
                onAccept={() => {
                  fuelCardsDelete(props.data?.fuelCardID);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </div>
      )}
      <SmartModal
        modalUniqueId="Supplier-FuelCard-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={props.loggedUser}
            data={[]}
            idModal="Supplier-FuelCard-modal"
            forceSupplierTypeCode={"FUEL_CARD"}
            updateList={(data: ISupplierGetSideList) => {
              let supplierFuelCards_: ISupplierGetSideList[] = [...supplierFuelCards];
              supplierFuelCards_.push(data);
              setSupplierFuelCards(supplierFuelCards_);
            }}
          />
        }
      />
      <LoaderBackdrop
        loading={
          loadingFuelCardsType ||
          loadingSupplierFuelCards ||
          loadingFuelCards ||
          loadingCurrentFuelCard ||
          loadingDriver ||
          loadingVehicles ||
          loadingCompanyList
        }
      />
    </div>
  );
};

export default FormFuelCards;
