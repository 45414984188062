import "./Persona.scss";

export interface IPersonaProps {
  imageUrl?: string;
  name: string;
  surname: string;
}
const stringToColour = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour + "BB";
};

const Persona = (props: IPersonaProps) => {
  let asText: string =
    props.name.charAt(0).toUpperCase() +
    " " +
    props.surname.charAt(0).toUpperCase();
  return (
    <div
      title={props.name + " " + props.surname}
      className="persona-main-wrap"
      style={{ background: stringToColour(asText) }}
    >
      {props.imageUrl && <img className="persona-image" src={props.imageUrl} />}
      {!props.imageUrl && <div className="persona-letter-zone">{asText}</div>}
    </div>
  );
};

export default Persona;
