/* API ENDPOINTS */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* MODELS */
import {
  IINSERT_CarConfiguratorGroup_Close_Vehicle,
  IINSERT_CarConfiguratorGroup_Close_Base,
  IINSERT_CarConfiguratorGroup_Open,
  IUPDATE_CarConfiguratorGroup_Close_Vehicle,
  IUPDATE_CarConfiguratorGroup_Close_Base,
  IUPDATE_CarConfiguratorGroup_Disable,
  IUPDATE_CarConfiguratorGroup_Open,
  IUPDATE_CarConfiguratorGroup_Close_Vehicle_Optional,
  IUPDATE_CarConfiguratorGroup_Close_Vehicle_Package,
  IUPDATE_CarConfiguratorGroup_Close_Vehicle_Color,
  IINSERT_CarConfigurator_Vehicle_Summary,
  IUPDATE_CarConfigurator_Vehicle_Summary,
  IUPDATE_CarConfigurator_Vehicle_Request_Approve,
  IUPDATE_CarConfigurator_Vehicle_Request_Reject,
  IINSERT_CarConfigurator_Request_SupplierPage,
  IUPDATE_CarConfigurator_Driver_Config,
  IINSERT_CarConfigurator_Supplier_SendOffer,
  IUPDATE_CarConfigurator_Offer_Accept,
  IINSERT_Request_Prompt_Delivery,
  IUPDATE_Ignore_Request_Prompt_Delivery,
  IINSERT_PROPOSAL_PROMPT_DELIVERY,
} from "../../Models/ICarConfigurator";

/* CONTROLLER */
const CarConfiguratorController = {
  // Get group group
  GET_CarConfiguratorGroup: async (
    groupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_GROUP;
    let parameters: string[] = [];
    if (groupID) {
      parameters.push(`groupID=${groupID}`);
    }
    if (groupID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Delete group
  DELETE_CarConfiguratorGroup: async (
    groupID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DELETE_GROUP;
    request.method = "DELETE";
    request.payload = {
      groupID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get Engine Power Supply
  GET_CarConfiguratorPowerSupply: async (
    enginePowerSupply?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_ENGINE_POWER_SUPPLY;
    let parameters: string[] = [];
    if (enginePowerSupply) {
      parameters.push(`enginePowerSupply=${enginePowerSupply}`);
    }
    if (enginePowerSupply) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get Electrich Hybrid Type
  GET_CarConfiguratorHybridType: async (
    electricHybridType?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_ELECTRIC_HYBRID_TYPE;
    let parameters: string[] = [];
    if (electricHybridType) {
      parameters.push(`electricHybridType=${electricHybridType}`);
    }
    if (electricHybridType) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get Vehicle Model
  GET_CarConfiguratorVehicleModel: async (
    vehicleModel?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_MODEL;
    let parameters: string[] = [];
    if (vehicleModel) {
      parameters.push(`vehicleModel=${vehicleModel}`);
    }
    if (vehicleModel) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get group list dropwdown
  GET_CarConfigurator_GroupList_Dropdown: async (
    groupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_GROUP_DROPDOWN_LIST;
    let parameters: string[] = [];
    if (groupID) {
      parameters.push(`groupID=${groupID}`);
    }
    if (groupID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get optional type list
  GET_CarConfiguratorGroup_Close_Vehicle_Optional_Type: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_OPTIONAL_TYPE;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get company code
  GET_CarConfiguratorVehicleCompanyCode: async (
    groupVehicleID?: number,
    codAll?: string,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_COMPANY_CODE;
    let parameters: string[] = [];
    if (groupVehicleID) {
      parameters.push(`groupVehicleID=${groupVehicleID}`);
    }
    if (codAll) {
      parameters.push(`codAll=${codAll}`);
    }
    if (inProduction) {
      parameters.push(`inProduction=${inProduction}`);
    }
    if (groupVehicleID || codAll || inProduction) {
      request.url += "?" + parameters.join("&");
    }
    request.skipResponseJsonParse = true;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get month/year
  GET_CarConfiguratorVehicle_MonthYear: async (
    groupVehicleID?: number,
    codAll?: string,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_MONTH_YEAR;
    let parameters: string[] = [];
    if (groupVehicleID) {
      parameters.push(`groupVehicleID=${groupVehicleID}`);
    }
    if (codAll) {
      parameters.push(`codAll=${codAll}`);
    }
    if (inProduction) {
      parameters.push(`inProduction=${inProduction}`);
    }
    if (groupVehicleID || codAll || inProduction) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get open group
  GET_CarConfiguratorGroup_Open: async (
    groupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_GROUP_OPEN;
    let parameters: string[] = [];
    if (groupID) {
      parameters.push(`groupID=${groupID}`);
    }
    if (groupID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert open group
  INSERT_CarConfiguratorGroup_Open: async (
    body: IINSERT_CarConfiguratorGroup_Open,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_GROUP_OPEN;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update open group
  UPDATE_CarConfiguratorGroup_Open: async (
    body: IUPDATE_CarConfiguratorGroup_Open,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_GROUP_OPEN;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Disable group
  UPDATE_CarConfiguratorGroup_Disable: async (
    body: IUPDATE_CarConfiguratorGroup_Disable,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DISABLE_GROUP;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get close group
  GET_CarConfiguratorGroup_Close: async (
    groupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_GROUP_CLOSE;
    let parameters: string[] = [];
    if (groupID) {
      parameters.push(`groupID=${groupID}`);
    }
    if (groupID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert close group
  INSERT_CarConfiguratorGroup_Close_Base: async (
    body: IINSERT_CarConfiguratorGroup_Close_Base,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_GROUP_CLOSE_BASE;
    request.method = "POST";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update close group
  UPDATE_CarConfiguratorGroup_Close_Base: async (
    body: IUPDATE_CarConfiguratorGroup_Close_Base,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_GROUP_CLOSE_BASE;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert vehicle in close group
  INSERT_CarConfiguratorGroup_Close_Vehicle: async (
    body: IINSERT_CarConfiguratorGroup_Close_Vehicle,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_GROUP_CLOSE_VEHICLE;
    request.method = "POST";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update vehicle in close group
  UPDATE_CarConfiguratorGroup_Close_Vehicle: async (
    body: IUPDATE_CarConfiguratorGroup_Close_Vehicle,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_GROUP_CLOSE_VEHICLE;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Delete vehicle in close group
  DELETE_CarConfiguratorGroup_Close_Vehicle: async (
    groupVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DELETE_GROUP_CLOSE_VEHICLE;
    request.method = "DELETE";
    request.port = "7233";
    request.payload = {
      groupVehicleID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get optional
  GET_CarConfiguratorGroup_Close_Vehicle_Optional: async (
    groupVehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_OPTIONAL;
    let parameters: string[] = [];
    if (groupVehicleID) {
      parameters.push(`groupVehicleID=${groupVehicleID}`);
    }
    if (groupVehicleID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get package
  GET_CarConfiguratorGroup_Close_Vehicle_Package: async (
    groupVehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_PACKAGE;
    let parameters: string[] = [];
    if (groupVehicleID) {
      parameters.push(`groupVehicleID=${groupVehicleID}`);
    }
    if (groupVehicleID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get color
  GET_CarConfiguratorGroup_Close_Vehicle_Color: async (
    groupVehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_COLOR;
    let parameters: string[] = [];
    if (groupVehicleID) {
      parameters.push(`groupVehicleID=${groupVehicleID}`);
    }
    if (groupVehicleID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update optionals
  UPDATE_CarConfiguratorGroup_Close_Vehicle_Optional: async (
    body: IUPDATE_CarConfiguratorGroup_Close_Vehicle_Optional,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_OPTIONAL;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update packages
  UPDATE_CarConfiguratorGroup_Close_Vehicle_Package: async (
    body: IUPDATE_CarConfiguratorGroup_Close_Vehicle_Package,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_PACKAGE;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update colors
  UPDATE_CarConfiguratorGroup_Close_Vehicle_Color: async (
    body: IUPDATE_CarConfiguratorGroup_Close_Vehicle_Color,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_COLOR;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get vehicles in car configurator
  GET__CARCONFIGURATOR_VEHICLES: async (
    driverGroupVehiclesID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET__CARCONFIGURATOR_VEHICLES;
    let parameters: string[] = [];
    if (driverGroupVehiclesID) {
      parameters.push(`driverGroupVehiclesID=${driverGroupVehiclesID}`);
    }
    if (driverGroupVehiclesID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get relationship
  GET_RELATIONSHIP: async (
    relationshipID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_RELATIONSHIP;
    let parameters: string[] = [];
    if (relationshipID) {
      parameters.push(`relationshipID=${relationshipID}`);
    }
    if (relationshipID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get vehicle in summary page
  GET_CarConfigurator_Vehicle_Summary: async (
    driverGroupVehiclesRequestID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_SUMMARY;
    let parameters: string[] = [];
    if (driverGroupVehiclesRequestID) {
      parameters.push(
        `driverGroupVehiclesRequestID=${driverGroupVehiclesRequestID}`
      );
    }
    if (driverGroupVehiclesRequestID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert vehicle in summary page
  INSERT_CarConfigurator_Vehicle_Summary: async (
    body: IINSERT_CarConfigurator_Vehicle_Summary,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_VEHICLE_SUMMARY;
    request.method = "POST";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update vehicle in summary page
  UPDATE_CarConfigurator_Vehicle_Summary: async (
    body: IUPDATE_CarConfigurator_Vehicle_Summary,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_SUMMARY;
    request.method = "PUT";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get vehicle request list
  GET_CarConfigurator_Vehicle_Request: async (
    driverGroupVehiclesRequestID?: number,
    excludeRejected?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_REQUEST;
    let parameters: string[] = [];
    if (driverGroupVehiclesRequestID) {
      parameters.push(
        `driverGroupVehiclesRequestID=${driverGroupVehiclesRequestID}`
      );
    }
    if (excludeRejected) {
      parameters.push(`excludeRejected=${excludeRejected}`);
    }
    if (driverGroupVehiclesRequestID || excludeRejected) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Approve request
  UPDATE_CarConfigurator_Vehicle_Request_Approve: async (
    body: IUPDATE_CarConfigurator_Vehicle_Request_Approve,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_REQUEST_APPROVE;
    request.method = "PUT";
    request.payload = body;
    request.port = "7233";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Reject request
  UPDATE_CarConfigurator_Vehicle_Request_Reject: async (
    body: IUPDATE_CarConfigurator_Vehicle_Request_Reject,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_VEHICLE_REQUEST_REJECT;
    request.port = "7233";
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert request in supplier page
  INSERT_CarConfigurator_Request_SupplierPage: async (
    body: IINSERT_CarConfigurator_Request_SupplierPage,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_VEHICLE_REQUEST;
    request.port = "7233";
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get vehicle offers list
  GET_CarConfigurator_Vehicle_Offers: async (
    vehiclesRequestOfferID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_VEHICLE_OFFERS;
    request.port = "7233";
    let parameters: string[] = [];
    if (vehiclesRequestOfferID) {
      parameters.push(`vehiclesRequestOfferID=${vehiclesRequestOfferID}`);
    }
    if (vehiclesRequestOfferID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get driver config
  GET_CarConfigurator_Driver_Config: async (
    driverID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7233";
    request.url = ApiEndPoints.GET_DRIVER_CONFIG;
    let parameters: string[] = [];
    if (driverID) {
      parameters.push(`driverID=${driverID}`);
    }
    if (driverID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update driver config
  UPDATE_CarConfigurator_Driver_Config: async (
    body: IUPDATE_CarConfigurator_Driver_Config,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_DRIVER_CONFIG;
    request.port = "7233";
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get supplier page offer page
  GET_CarConfigurator_Supplier_SendOffer: async (
    requestNumber?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_SUPPLIER_SEND_OFFER;
    request.port = "7233";
    let parameters: string[] = [];
    if (requestNumber) {
      parameters.push(`requestNumber=${requestNumber}`);
    }
    if (requestNumber) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert supplier page offer page
  INSERT_CarConfigurator_Supplier_SendOffer: async (
    body: IINSERT_CarConfigurator_Supplier_SendOffer,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_SUPPLIER_SEND_OFFER;
    request.method = "POST";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update and accept offer request (from button in offers page)
  UPDATE_CarConfigurator_Offer_Accept: async (
    body: IUPDATE_CarConfigurator_Offer_Accept,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_REQUEST_OFFER_ACCEPT;
    request.method = "PUT";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert request in prompt delivery
  INSERT_Request_Prompt_Delivery: async (
    body: IINSERT_Request_Prompt_Delivery,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_REQUEST_PROMPT_DELIVERY;
    request.method = "POST";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get prompt delivery requests for dealer
  GET_Request_Prompt_Delivery_Dealer: async (
    vehiclesRequestEmailID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_REQUEST_DEALER_PROMPT_DELIVERY;
    let parameters: string[] = [];
    if (vehiclesRequestEmailID) {
      parameters.push(`vehiclesRequestEmailID=${vehiclesRequestEmailID}`);
    }
    if (vehiclesRequestEmailID) {
      request.url += "?" + parameters.join("&");
    }
    request.port = "7233";
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get car colors
  GET_Car_Colors: async (
    fittingCode: string,
    vehiclesRequestPromptDeliveryID?: number,
    externalColor?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_CAR_COLORS;
    let parameters: string[] = [];
    if (fittingCode) {
      parameters.push(`fittingCode=${fittingCode}`);
    }
    if (vehiclesRequestPromptDeliveryID) {
      parameters.push(
        `vehiclesRequestPromptDeliveryID=${vehiclesRequestPromptDeliveryID}`
      );
    }
    if (externalColor) {
      parameters.push(`externalColor=${externalColor}`);
    }
    if (parameters.length > 0) {
      request.url += "?" + parameters.join("&");
    }
    request.port = "7233";
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update and accept offer request (from button in offers page)
  UPDATE_Ignore_Request_Prompt_Delivery: async (
    body: IUPDATE_Ignore_Request_Prompt_Delivery,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_IGNORE_REQUEST_PROMPT_DELIVERY;
    request.method = "PUT";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Insert prompt delivery proposal
  INSERT_PROPOSAL_PROMPT_DELIVERY: async (
    body: IINSERT_PROPOSAL_PROMPT_DELIVERY,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_PROPOSAL_PROMPT_DELIVERY;
    request.method = "POST";
    request.port = "7233";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get prompt delivery proposal
  GET_PROPOSAL_PROMPT_DELIVERY: async (
    vehiclesRequestPromptDeliveryProposalID: number,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_PROMPT_DELIVERY_PROPOSAL;
    let parameters: string[] = [];
    if (vehiclesRequestPromptDeliveryProposalID) {
      parameters.push(
        `vehiclesRequestPromptDeliveryProposalID=${vehiclesRequestPromptDeliveryProposalID}`
      );
    }
    if (inProduction) {
      parameters.push(`inProduction=${inProduction}`);
    }
    if (vehiclesRequestPromptDeliveryProposalID || inProduction) {
      request.url += "?" + parameters.join("&");
    }
    request.port = "7233";
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  SendRequestToCarConfigurator: async (
    groupID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SEND_REQUEST_TO_CAR_CONFIGURATOR + "?groupID=" + groupID;
    request.port = "7233";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default CarConfiguratorController;
