import {
  SET_STEPPER_TICKETS,
  SET_TICKET_DRIVER,
  SET_TICKET_INSERT_DETAIL_RESPONSE,
  SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE,
  SET_TICKET_INSERT_PAYMENT_RESPONSE,
  SET_TICKET_INSERT_RESPONSE,
  SET_TICKET_STATUS,
  TicketsActionType,
} from "./TicketsAction";
import { TicketsState } from "./TicketsState";

const DEFAULT_STATE: TicketsState = {
  ticketsStepper: {
    stepSanction: undefined,
    stepBreach: undefined,
    stepAmountDeadlines: undefined,
    stepPayment: undefined,
    articleSanction: undefined,
  },
  ticketInsertResponse: undefined,
  ticketInsertDetailResponse: undefined,
  ticketInsertMultipleDetailResponse: undefined,
  ticketInsertPaymentResponse: undefined,
  driverTicket: undefined,
  ticketStatus: undefined,
};

export const TicketsReducer = (
  state: TicketsState = DEFAULT_STATE,
  action: TicketsActionType
): TicketsState => {
  switch (action.type) {
    case SET_STEPPER_TICKETS:
      return {
        ...state,
        ticketsStepper: action.payload,
      };
    case SET_TICKET_INSERT_RESPONSE:
      return {
        ...state,
        ticketInsertResponse: action.payload,
      };
    case SET_TICKET_INSERT_DETAIL_RESPONSE:
      return {
        ...state,
        ticketInsertDetailResponse: action.payload,
      };
    case SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE:
      return {
        ...state,
        ticketInsertMultipleDetailResponse: action.payload,
      };
    case SET_TICKET_INSERT_PAYMENT_RESPONSE:
      return {
        ...state,
        ticketInsertPaymentResponse: action.payload,
      };
    case SET_TICKET_DRIVER:
      return {
        ...state,
        driverTicket: action.payload,
      };
    case SET_TICKET_STATUS:
      return {
        ...state,
        ticketStatus: action.payload,
      };
    default:
      return state;
  }
};
