import "./FunctionalitiesPage.scss";
import {Trans} from "react-i18next";
import BannerVideoComponent from "../../Components/BannerVideoComponent/BannerVideoComponent";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";
import React from "react";

const FunctionalitiesPage = () => {

    return (
        <>
            <BannerVideoComponent
                filename='315630139-small.mp4'
                title={<Trans i18nKey='intro:functionalitiesTitle' components={[<strong/>, <br/>]}/>}
            />

            <div className="ff-section-functionalities">
                <BannerCardComponent
                    leftTitle={<Trans i18nKey='intro:VehicleCreation_title'/>}
                    leftContent={
                        <div className="ff-text-box left">
                            <p>{<Trans i18nKey='intro:VehicleCreation_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:VehicleCreation_secondParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:VehicleCreation_thirdParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                    rightContent={
                        <div className="ff-image-box ff-img-right">
                            <img src={'/Images/newImages/705580611.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                />

                <BannerCardComponent
                    rightTitle={<Trans i18nKey='intro:OrdersClosersTitle'/>}
                    leftContent={
                        <div className="ff-image-box ff-img-left">
                            <img src={'/Images/newImages/679998891.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                    rightContent={
                        <div className="ff-text-box right">
                            <p>{<Trans i18nKey='intro:OrdersClosers_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                />

                <BannerCardComponent
                    leftTitle={<Trans i18nKey='intro:VehicleManagementTitle'/>}
                    leftContent={
                        <div className="ff-text-box left">
                            <p>{<Trans i18nKey='intro:VehicleManagement_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:VehicleManagement_secondParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                    rightContent={
                        <div className="ff-image-box ff-img-right">
                            <img src={'/Images/newImages/812285572.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                />

                <BannerCardComponent
                    rightTitle={<Trans i18nKey='intro:Timetable_title'/>}
                    leftContent={
                        <div className="ff-image-box ff-img-left">
                            <img src={'/Images/newImages/509370181.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                    rightContent={
                        <div className="ff-text-box right">
                            <p>{<Trans i18nKey='intro:Timetable_firstParagraph' components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:Timetable_secondParagraph' components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:Timetable_thirdParagraph' components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                />

                <BannerCardComponent
                    leftTitle={<Trans i18nKey='intro:manageTickets_title'/>}
                    leftContent={
                        <div className="ff-text-box left">
                            <p>{<Trans i18nKey='intro:manageTickets_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:manageTickets_secondParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:manageTickets_thirdParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                    rightContent={
                        <div className="ff-image-box ff-img-right">
                            <img src={'/Images/newImages/159200986.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                />

                <BannerCardComponent
                    rightTitle={<Trans i18nKey='intro:administrativeManagement_title'/>}
                    leftContent={
                        <div className="ff-image-box ff-img-left">
                            <img src={'/Images/newImages/614431506.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                    rightContent={
                        <div className="ff-text-box right">
                            <p>{<Trans i18nKey='intro:administrativeManagement_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:administrativeManagement_secondParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:administrativeManagement_thirdParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                />

                <BannerCardComponent
                    leftTitle={<Trans i18nKey='intro:fleet_title'/>}
                    leftContent={
                        <div className="ff-text-box left">
                            <p>{<Trans i18nKey='intro:fleet_firstParagraph' components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:fleet_secondParagraph' components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                    rightContent={
                        <div className="ff-image-box ff-img-right">
                            <img src={'/Images/newImages/711036874.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                />

                <BannerCardComponent
                    rightTitle={<Trans i18nKey='intro:ReportsDashboards_title'/>}
                    leftContent={
                        <div className="ff-image-box ff-img-left">
                            <img src={'/Images/newImages/589427987.jpeg'} alt="" loading="lazy"/>
                        </div>
                    }
                    rightContent={
                        <div className="ff-text-box right">
                            <p>{<Trans i18nKey='intro:ReportsDashboards_firstParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                            <p>{<Trans i18nKey='intro:ReportsDashboards_secondParagraph'
                                       components={[<strong/>, <br/>]}/>}</p>
                        </div>
                    }
                />
            </div>
        </>
    )
};

export default FunctionalitiesPage;
