import "./Suppliers__PurchasesPage_single.scss";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import StepFleetPlan from "../../../../../Components/StepFleetPlan/StepFleetPlan";
import { IDebitCreditRoadtax } from "../../../../../Models/IRoadtax";
import { RoadTaxActions } from "../../../../../Reducers/RoadTax/RoadTaxAction";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import AppRoutes from "../../../../../Costants/AppRoutes";
import {
  BuyCallServicePayload,
  CallService,
  IPaymentInsert,
  IPaymentType,
} from "../../../../../Models/IPayment";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IAddedService } from "../../../../../Models/IService";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { writePaymentData } from "../../../../PaymentReadyPage/PaymentReadyPage";
import { triggerFormValidation } from "../../../../../Components/MultiForm/MultiForm";
import i18next from "i18next";
import { ISubscriptions } from "../../../../../Models/ISubscriptions";
import { RegistrationAction } from "../../../../../Reducers/Registration/RegistrationAction";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import PayPalPaymentOnce from "../../../../../Components/PaypalPaymentOnce/PaypalPaymentOnce";
import { userRoleFits } from "../../../../../Utils/Session";

export interface IPurchaseList {
  purchaseID: number;
  titlePurchase: string;
  descriptionPurchase: string;
  costPurchase: number;
  stored: boolean;
}

var dataClone: any = {};
var billingInfoClone: any = {};

const SinglePurchaseBox = (props: {
  service: IAddedService;
  activeItems: IAddedService[];
  onQuantityChange: (item: IChartItem) => void;
}) => {
  const [amount, setAmount] = useState<number>(0);

  let usages = 0;

  props.activeItems.forEach((x: IAddedService) => {
    usages += x.usageCount;
  });

  return (
    <div className="single-item-box-store">
      <div className="single-item-box-store-title">
        {props.service.addedServiceName}
      </div>
      <div className="single-item-box-store-desc">
        {props.service.addedServiceDescription}
      </div>
      <div className="single-item-box-store-image">
        <img src={props.service.addedServiceImage} />
      </div>
      <div className="single-item-box-store-cost">
        {props.service.addedServiceMontlyCost} €
      </div>
      <div>
        {(i18next.t("navigation:packet_items_count") ?? "").replace(
          "$ITEMS",
          (
            props.service.addedServiceMontlyCost /
            props.service.addedServiceCallCost
          ).toString()
        )}
      </div>
      <div className="button-zone">
        <div className="button-counter-zone">
          <IconButton
            color="primary"
            disabled={amount === 0}
            onClick={() => {
              if (amount > 0) {
                setAmount(amount - 1);
              }
            }}
          >
            <RemoveIcon />
          </IconButton>
          <div>{amount}</div>
          <IconButton
            color="primary"
            onClick={() => {
              setAmount(amount + 1);
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
        <div>{amount * props.service.addedServiceMontlyCost} €</div>
        <IconButton
          color="primary"
          onClick={() => {
            props.onQuantityChange({
              quantity: amount,
              addedServiceID: props.service.addedServiceID,
              itemCost: props.service.addedServiceMontlyCost,
            });
          }}
        >
          <AddShoppingCartIcon />
        </IconButton>
      </div>
    </div>
  );
};

interface IChartItem {
  addedServiceID: number;
  itemCost: number;
  quantity: number;
}

const Suppliers__PurchasesPage_single = () => {
  const dispatch = useDispatch();
  const [data, setData_] = useState<any>({
    price: -1,
  });
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);

  const [billingInfos, setBillingInfos_] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [paypalMode, setPaypalMode] = useState<boolean>(true);
  const roadTaxList: any = useSelector(
    (state: GlobalState) => state.roadtax.roadTaxList
  );

  const setData = (data: any) => {
    dataClone = { ...data };
    setData_(data);
  };

  const setBillingInfos = (infos: any) => {
    billingInfoClone = { ...infos };
    setBillingInfos_(infos);
  };

  const [discountVoucher, setDiscountVoucher] = useState<any>({});
  const [paymentDone, setPaymentDone] = useState<boolean>(false);
  const [paymentErrored, setPaymentErrored] = useState<boolean>(false);
  const [navigationLink, setNavigationLink] = useState<string>("");

  const getFleetPlans: IFleetPlan[] | undefined = useSelector(
    (state: GlobalState) => state.registration.fleetPlans
  );

  const [servicesList, setServicesList] = useState<IAddedService[]>([]);

  const [servicesSelected, setServicesSelected] = useState<IAddedService[]>([]);
  const [servicesSelectedSingle, setServicesSelectedSingle] = useState<
    IAddedService[]
  >([]);

  const [purchaseList, setPurchaseList] = useState<IChartItem[]>([]);

  const getAddedServices: IAddedService[] | undefined = useSelector(
    (state: GlobalState) => state.registration.addedServices
  );

  const creditCost: any = useSelector(
    (state: GlobalState) => state.roadtax.creditCost
  );
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const subscriptions: ISubscriptions[] | undefined = useSelector(
    (state: GlobalState) => state.registration.userSubscriptions
  );

  const loadSelectedData = () => {
    if (subscriptions.length > 0) {
      setLoading(true);
      ApiService.ServiceController.ServiceGetSelectedData(
        subscriptions[0].subscriptionID,
        "",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setServicesSelected(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadServices = () => {
    ApiService.ServiceController.ServiceGetAddedData(
      false,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setServicesList(
            response.payload.filter((x: IAddedService) => !x.stored)
          );
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const loadSubscriptions = () => {
    if (subscriptions.length === 0) {
      setLoading(true);
      ApiService.SubscriptionController.SubscriptionGetData(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(RegistrationAction.setUserSubscriptions(response.payload));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadSingleServices = () => {
    let services: IAddedService[] = [];
    servicesSelected.forEach((x: IAddedService) => {
      if (
        services.find(
          (y: IAddedService) => y.addedServiceID === x.addedServiceID
        ) === undefined
      ) {
        services.push({ ...x });
      }
    });
    setServicesSelectedSingle(services);
  };

  useEffect(() => {
    loadServices();
    loadSubscriptions();
  }, []);

  useEffect(() => {
    loadSelectedData();
  }, [subscriptions]);

  useEffect(() => {
    loadSingleServices();
  }, [servicesSelected]);

  var total = 0;
  purchaseList.forEach((x: IChartItem) => {
    total += x.itemCost * x.quantity;
  });

  const performSignlePurchase = (orderId: string) => {
    setLoading(true);
    const data: BuyCallServicePayload = {
      callServices: purchaseList.map((x: IChartItem) => {
        return {
          addedServiceID: x.addedServiceID,
          quantity: x.quantity,
        } as CallService;
      }),
      paypalOrderID: orderId,
    };
    setPaymentDone(false);
    setPaymentErrored(false);

    ApiService.PaymentController.BuySinglePurchase(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentDone(true);
        } else {
          setPaymentErrored(true);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    let navigationLink: string = "";
    if (userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) {
      navigationLink = AppRoutes.DASHBOARD___MESSAGING_ROUTE;
    } else {
      navigationLink = AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE;
    }
    setNavigationLink(navigationLink);
  }, [paymentDone]);

  return (
    <div className="dashboardForm">
      {window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) && (
        <HeaderPageDashboard
          title={i18next.t("navigation:_add_services_purchases")}
        />
      )}
      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined"></ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          {!loading && paymentDone && (
            <div className="payment-page-spinner-loader">
              <div className="payment-page-spinner-loader-label">
                {i18next.t("navigation:payment_processing_done")}
                <div className="payment-page-spinner-loader-sub-label">
                  {i18next.t("navigation:payment_paypal_done")}
                </div>
                <DoneOutlineIcon />

                <Link
                  to={AppRoutes.INTERNAL_DASHBOARD + navigationLink}
                  style={{ marginTop: "0.5em", fontSize: "0.5em" }}
                >
                  {i18next.t("navigation:payment_done_go_to_dashboard")}
                </Link>
              </div>
            </div>
          )}
          {!loading && paymentErrored && (
            <div className="payment-page-spinner-loader">
              <div className="payment-page-spinner-loader-label red">
                {i18next.t("navigation:payment_processing_error")}
                <ReportGmailerrorredIcon />
              </div>
            </div>
          )}

          {!paymentDone && !paymentErrored && (
            <div>
              {servicesList
                .filter(
                  (x: IAddedService) =>
                    x.addedServiceCallCost != null &&
                    x.addedServiceCallCost != 0
                )
                .map((x: IAddedService, i: number) => {
                  return (
                    <SinglePurchaseBox
                      key={i}
                      service={x}
                      activeItems={servicesSelected.filter(
                        (y: IAddedService) =>
                          x.addedServiceID === y.addedServiceID
                      )}
                      onQuantityChange={(qty: IChartItem) => {
                        let tmp: IChartItem[] = [...purchaseList];
                        let index = tmp.findIndex(
                          (y: IChartItem) =>
                            y.addedServiceID === qty.addedServiceID
                        );
                        if (index === -1) {
                          tmp.push(qty);
                        } else {
                          tmp[index] = qty;
                        }
                        setPurchaseList(
                          tmp.filter((x: IChartItem) => x.quantity != 0)
                        );
                      }}
                    />
                  );
                })}
              <hr />
              {purchaseList.length > 0 && (
                <div className="recap-zone">
                  <h2 style={{ color: "var(--default-color-title)" }}>
                    {i18next.t("navigation:purchase_summary")} {total} €
                  </h2>
                  <ul>
                    {purchaseList.map((x: IChartItem, i: number) => {
                      let service: IAddedService | undefined =
                        servicesSelected.find(
                          (y: IAddedService) =>
                            y.addedServiceID === x.addedServiceID
                        );
                      return (
                        <li key={i}>
                          <div className="recap-voice">
                            <div>{i + 1}</div>
                            <strong>{service?.addedServiceName}</strong>
                            <div>(x{x.quantity})</div>
                            <div>{x.itemCost * x.quantity} €</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="center-paypal">
                    <PayPalPaymentOnce
                      price={total.toString()}
                      onPaymentDone={(data: any) => {
                        let orderId = data.data.orderID;

                        performSignlePurchase(orderId);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Box>
      </div>
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default Suppliers__PurchasesPage_single;
