import i18next from "i18next";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import "./SubstituteVehicleModal.scss";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import { ISupplierGetSideList } from "../../Models/ISupplier";
import FormSupplier from "../StepSupplier/FormSupplier";
import { useEffect, useState } from "react";
import { IVehicleDataGet, IVehicleSubsitute } from "../../Models/IVehicles";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { useSelector } from "react-redux";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IAssignmentGet } from "../../Models/IAssignment";

export interface ISubstituteVehicleModalProps {}

const SubstituteVehicleModal = (props: ISubstituteVehicleModalProps) => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const assignments: IAssignmentGet[] = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignments
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const vehicleSubstitutes: IVehicleSubsitute[] | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentVehicleSubstitutes
  );

  const activeAssignment: IAssignmentGet | undefined = assignments.find(
    (x: IAssignmentGet) => x.effectiveEndDT === null
  );

  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [state, setState] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);

  useEffect(() => {
    let pendingSubstitutes: IVehicleSubsitute[] = vehicleSubstitutes.filter(
      (x: IVehicleSubsitute) => x.endDT === null
    );

    setIsCreate(pendingSubstitutes.length === 0);
    if (pendingSubstitutes.length > 0) {
      setState(pendingSubstitutes[0]);
    }
  }, [vehicleSubstitutes]);

  // API to load suppliers select list
  const loadSupplierList = () => {
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const insertSobstitutesVehicles = (data: any) => {
    setLoading(true);
    ApiService.VehicleServiceController.VehicleSobstituteInsert(
      data as IVehicleSubsitute,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setState({});
          window.document.dispatchEvent(new Event("reload-substitutes-v"));
          DismissModal("SubstituteVehicleModal");

          ToastMessage(i18next.t("navigation:replaceAdded"), "success");
        } else {
          ToastMessage(i18next.t("error:could_not_create_replace_vehicle"), "error");
        }
        setLoading(false);
      }
    );
  };

  const updateSobstitutesVehicles = (data: IVehicleSubsitute) => {
    setLoading(true);
    ApiService.VehicleServiceController.VehicleSobstituteUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setState({});
          ToastMessage(i18next.t("navigation:replaceDisposed"), "success");

          window.document.dispatchEvent(new Event("reload-substitutes-v"));
          DismissModal("SubstituteVehicleModal");
        } else {
          ToastMessage(i18next.t("error:could_not_update_replace_vehicle"), "error");
        }
        setLoading(false);
      }
    );
  };

  const submitData = (data: any) => {
    data["vehicleID"] = vehicle?.vehicleID;
    data["assignmentID"] = activeAssignment?.assignmentID;

    if (isCreate) {
      insertSobstitutesVehicles(data);
    } else {
      let complete: IVehicleSubsitute = { ...state, ...data };
      updateSobstitutesVehicles(complete);
    }
  };

  useEffect(() => {
    loadSupplierList();
  }, []);

  return (
    <div>
      <SmartModal
        loadingButtons={loading}
        modalUniqueId="SubstituteVehicleModal"
        title={
          isCreate
            ? i18next.t("navigation:activateSubstituteVehicle")
            : i18next.t("navigation:deactivateSubstituteVehicle")
        }
        buttons={[
          {
            text: i18next.t("navigation:reject"),
            onClick: () => {
              DismissModal("SubstituteVehicleModal");
            },
          },
          {
            text: i18next.t("navigation:accept"),
            onClick: () => {
              triggerFormValidation("SubstituteVehicleFrom");
            },
          },
        ]}
        modalInnerComponent={
          <div style={{ minWidth: "70vw" }}>
            <MultiForm
              disableForm={loading}
              suppressLayout
              suppressSubmit
              onChange={(data: any) => {
                setState({ ...state, ...data });
              }}
              formId="SubstituteVehicleFrom"
              inputs={
                isCreate
                  ? [
                      {
                        width: 50,
                        type: "text",
                        name: "licencePlate",
                        label: i18next.t("form:plate") ?? "",
                        required: true,
                      },
                      {
                        width: 40,
                        type: "select",
                        name: "supplierID",
                        required: true,
                        label: String(i18next.t(`form:vehicleSupplier`)),
                        options: supplierList.map(
                          (supplierItem: ISupplierGetSideList) => {
                            return {
                              key: supplierItem.supplierInfo.supplierID.toString(),
                              text: supplierItem.supplierInfo.supplierName.toString(),
                            };
                          }
                        ),
                      },
                      // Add Supplier
                      {
                        width: 10,
                        type: "custom",
                        name: "addSupplier",
                        element: (
                          <div className="multi-form-button-add-record">
                            <Button
                              variant="contained"
                              onClick={() => {
                                SummonModal("supplierUser-modal");
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </div>
                        ),
                      },
                      {
                        width: 50,
                        type: "date",
                        name: "startDT",
                        maxDate: state?.endDT,
                        label: i18next.t("form:date_start") ?? "",
                        required: true,
                      },
                      {
                        width: 50,
                        type: "number",
                        name: "startKM",
                        label: i18next.t("form:km_start") ?? "",
                        required: true,
                      },
                      {
                        width: 50,
                        type: "number",
                        name: "dailyRent",
                        label: i18next.t("form:daily_rent") ?? "",
                        required: true,
                      },
                    ]
                  : [
                      {
                        width: 50,
                        type: "date",
                        name: "endDT",
                        minDate: state?.startDT,
                        label: i18next.t("form:date_finish") ?? "",
                        required: true,
                      },
                      {
                        width: 50,
                        type: "number",
                        name: "endKm",
                        inputProps: {
                          max: 1000000,
                        },
                        label: i18next.t("form:km_finish") ?? "",
                        required: true,
                      },
                    ]
              }
              onSubmit={(data: any) => {
                submitData(data);
              }}
            />
          </div>
        }
      />
      <SmartModal
        modalUniqueId="supplierUser-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier loggedUser={loggedUser} data={[]} idModal="supplierUser-modal" />
        }
      />
    </div>
  );
};

export default SubstituteVehicleModal;
