/* STYLE */
import "./HelpPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../Components/HeaderPageDashboard/HeaderPageDashboard";

/* COMPONENT */
const HelpPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_help")} />

      <div className="dashboardForm__box">
        <iframe
          style={{ width: "100%", height: "80vh" }}
          src={"../../Manuale _FacileFlotta_Fleet_Manager.pdf"}
        ></iframe>
      </div>
    </div>
  );
};

export default HelpPage;
