import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const InvoiceController = {
  InvoiceTypesGet: async (
    invoiceTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INVOICE_TYPE_GET;
    request.method = "GET";
    let parameters: string[] = [];
    if (invoiceTypeID) {
      parameters.push("invoiceTypeID=" + invoiceTypeID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InvoiceListGet: async (
    invoiceID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INVOICE_LIST_GET;
    request.method = "GET";
    let parameters: string[] = [];
    if (invoiceID) {
      parameters.push("invoiceID=" + invoiceID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InvoiceUpload: async (
    files: File[],
    invoiceTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INVOICE_UPLOAD;
    request.method = "POST";
    let parameters: string[] = [];
    if (invoiceTypeID) {
      parameters.push("invoiceTypeID=" + invoiceTypeID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";

    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    request.payload = formData;
    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InvoiceUploadZip: async (
    file: File,
    invoiceTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INVOICE_UPLOAD_ZIP;
    request.method = "POST";
    let parameters: string[] = [];
    if (invoiceTypeID) {
      parameters.push("invoiceTypeID=" + invoiceTypeID);
    }
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";

    var formData = new FormData();
    formData.append("zip", file);
    request.payload = formData;

    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InvoiceDelete: async (
    invoiceID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INVOICE_ITEM_DELETE;
    request.method = "DELETE";
    let parameters: string[] = [];
    request.url += parameters.length > 0 ? "?" + parameters.join("&") : "";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = {
      invoiceID: invoiceID,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default InvoiceController;
