import i18next from "i18next";
import MultiForm from "../../Components/MultiForm/MultiForm";
import React, { useLayoutEffect, useState } from "react";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import { validationEmail } from "../../Utils/Validation";
import "./ContactsPage.scss";
import { loader } from "../../App";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { Trans } from "react-i18next";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";
import PlaceRoundedIcon from "@mui/icons-material/PlaceRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { useMediaQuery } from "usehooks-ts";

const maps = [
  {
    mapId: "mapRome",
    title: i18next.t("generic:rome"),
    description: "Via Varese, 5<br/>00185 Roma",
    contact: { email: "info@fleetsupport.it", phone: "+39 06 49 21 21" },
    lat: 41.9,
    lng: 12.5,
  },
  {
    mapId: "mapMilan",
    title: i18next.t("generic:milan"),
    description: "Via Ippolito Nievo, 19<br/>20145 Milano",
    contact: { email: "info@fleetsupport.it", phone: "+39 02 38 31 29 31" },
    lat: 45.4748,
    lng: 9.1610914,
  },
];

const ContactsPage: React.FC = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const matches = useMediaQuery("(max-width: 768px)");

  const sendRequestDemo = (data: any) => {
    if (data) {
      setProcessing(true);
      ApiService.AccountController.InsertUserSpecialOfferRequest(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:requestDemoSent"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  useLayoutEffect(() => {
    const initMaps = (index: number) => {
      let mapFrame: any;
      loader.load().then((google: any) => {
        mapFrame = new google.maps.Map((window.document as any).getElementById(maps[index].mapId), {
          center: { lat: maps[index].lat, lng: maps[index].lng },
          zoom: 17,
        });

        new google.maps.Marker({
          position: { lat: maps[index].lat, lng: maps[index].lng },
          map: mapFrame,
          title: maps[index].title,
        });
      });
    };

    for (let i = 0; i < maps.length; i++) {
      initMaps(i);
    }
  }, [matches]);

  return (
    <div className="ff-contact-page-container">
      <div className="ff-contact-main-wrapper">
        <div className="ff-contact-main-intro">
          <h2>{<Trans i18nKey="navigation:contactFormTitle" components={[<strong />, <br />]} />}</h2>
          <p className="ff-contact-main-intro-par">
            {<Trans i18nKey="navigation:contactFormIntro" components={[<strong />, <br />]} />}
          </p>
        </div>
        <div className="ff-contact-form-wrapper">
          <MultiForm
            formId="contact"
            inputs={[
              {
                type: "text",
                name: "name",
                label: i18next.t("form:name") as string,
                required: true,
                width: 50,
              },
              {
                type: "text",
                name: "surname",
                label: i18next.t("form:surname") as string,
                required: true,
                width: 50,
              },
              {
                type: "email",
                name: "email",
                label: i18next.t("form:email") as string,
                required: true,
                width: 50,
                errorText:
                  state?.email && state?.email.length > 0 && !validationEmail(state?.email)
                    ? (i18next.t("form:validationEmail") as string)
                    : "",
              },
              {
                type: "tel",
                name: "phone",
                label: i18next.t("form:telephone") as string,
                required: true,
                width: 50,
              },
              {
                type: "text",
                name: "roleInCompany",
                label: i18next.t("form:roleCompany") as string,
                required: true,
                width: 50,
              },
              {
                type: "number",
                name: "desiredFleetSize",
                label: i18next.t("form:fleetDimension") as string,
                required: true,
                width: 50,
              },
              {
                type: "multiline",
                label: i18next.t("form:noteAdditional") as string,
                name: "notes",
                width: 100,
                multilineRows: 6,
              },
              {
                type: "checkbox",
                name: "privacy",
                width: 100,
                placeholder: i18next.t("form:privacyContactForm") as string,
              },
            ]}
            onChange={(data: any) => {
              setState(data);
            }}
            submitButtonDisabled={!state || !state?.privacy}
            submitButtonLabel={i18next.t("form:demoRequestWebiste") as string}
            onSubmit={(data: any) => {
              data.desiredFleetSize = +data.desiredFleetSize;
              sendRequestDemo(data);
            }}
          />
        </div>
      </div>

      <BannerCardComponent
        imgPath={"/Images/mapRome.jpg"}
        leftTitle="roma"
        disableAnimation
        leftContent={
          <div className="ff-text-box">
            <div className="footer-contact-p">
              <PlaceRoundedIcon className="footer-contact_icon" />
              <p>
                Via Varese, 5 <br />
                00185 Roma, Italia
              </p>
            </div>
            <div className="footer-contact-p">
              <EmailRoundedIcon className="footer-contact_icon" />
              <a href="mailto:info@facileflotta.it">info@fleetsupport.it</a>
            </div>
            <div className="footer-contact-p">
              <LocalPhoneRoundedIcon className="footer-contact_icon" />
              (+39) 06 492121
            </div>
          </div>
        }
        rightContent={
          <div className="ff-maps-right-wrapper">
            <div id="mapRome"></div>
          </div>
        }
      />

      {!matches && (
        <BannerCardComponent
          imgPath={"/Images/mapMilan.jpg"}
          rightTitle="milano"
          disableAnimation
          leftContent={
            <div className="ff-maps-left-wrapper">
              <div id="mapMilan"></div>
            </div>
          }
          rightContent={
            <div className="ff-text-box">
              <div className="footer-contact-p">
                <PlaceRoundedIcon className="footer-contact_icon" />
                <p>
                  Via Ippolito Nievo, 19 <br />
                  20145 Milano, Italia
                </p>
              </div>
              <div className="footer-contact-p">
                <EmailRoundedIcon className="footer-contact_icon" />
                <a href="mailto:info@facileflotta.it">info@fleetsupport.it</a>
              </div>
              <div className="footer-contact-p">
                <LocalPhoneRoundedIcon className="footer-contact_icon" />
                (+39) 02 38312931
              </div>
            </div>
          }
        />
      )}

      {matches && (
        <BannerCardComponent
          imgPath={"/Images/mapMilan.jpg"}
          rightTitle="milano"
          disableAnimation
          leftContent={
            <div className="ff-text-box">
              <div className="footer-contact-p">
                <PlaceRoundedIcon className="footer-contact_icon" />
                <p>
                  Via Ippolito Nievo, 19 <br />
                  20145 Milano, Italia
                </p>
              </div>
              <div className="footer-contact-p">
                <EmailRoundedIcon className="footer-contact_icon" />
                <a href="mailto:info@facileflotta.it">info@fleetsupport.it</a>
              </div>
              <div className="footer-contact-p">
                <LocalPhoneRoundedIcon className="footer-contact_icon" />
                (+39) 02 38312931
              </div>
            </div>
          }
          rightContent={
            <div className="ff-maps-left-wrapper">
              <div id="mapMilan"></div>
            </div>
          }
        />
      )}

      <LoaderBackdrop loading={processing} />
    </div>
  );
};

export default ContactsPage;
