import {
  SET_ACTIVE_DRIVER_FOR_COMPANY,
  SET_ACTIVE_VEHICLE_FOR_COMPANY,
  SET_CURRENT_ASSIGNMENT,
  SET_CURRENT_ASSIGNMENTS,
  SET_CURRENT_VEHICLE_SUBSTITUTES,
  SET_DRIVERS_FOR_COMPANY,
  SET_VEHICLES_FOR_COMPANY,
  SET_VEHICLE_MCTC,
  SET_V_DISPOSED,
  VehicleActionType,
} from "./VehicleAction";
import { VehicleState } from "./VehicleState";

const DEFAULT_STATE: VehicleState = {
  vehiclesForCompany: [],
  activeVehicleDispose: 0,
  activeVehicle: undefined,
  vehicleMctc: undefined,
  activeDriver: undefined,
  driversForCompany: [],
  currentAssignments: [],
  currentAssignment: undefined,
  currentVehicleSubstitutes: [],
};

export const vehiclesReducer = (
  state: VehicleState = DEFAULT_STATE,
  action: VehicleActionType
): VehicleState => {
  switch (action.type) {
    case SET_V_DISPOSED:
      return {
        ...state,
        activeVehicleDispose: action.payload,
      };
    case SET_CURRENT_VEHICLE_SUBSTITUTES:
      return {
        ...state,
        currentVehicleSubstitutes: action.payload,
      };
    case SET_CURRENT_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: action.payload,
      };
    case SET_CURRENT_ASSIGNMENTS:
      return {
        ...state,
        currentAssignments: action.payload,
      };
    case SET_DRIVERS_FOR_COMPANY:
      return {
        ...state,
        driversForCompany: action.payload,
      };
    case SET_VEHICLES_FOR_COMPANY:
      return {
        ...state,
        vehiclesForCompany: action.payload,
      };
    case SET_ACTIVE_DRIVER_FOR_COMPANY:
      return {
        ...state,
        activeDriver: action.payload,
      };
    case SET_ACTIVE_VEHICLE_FOR_COMPANY:
      return {
        ...state,
        activeVehicle: action.payload,
      };
    case SET_VEHICLE_MCTC:
      return {
        ...state,
        vehicleMctc: action.payload,
      };
    default:
      return state;
  }
};
