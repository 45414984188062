import {
  ToggleButton,
  AccordionDetails,
  Tooltip,
  IconButton,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import "./ServicesSubscriptionsTile.scss";
import { currencyDecoder } from "../../Utils/Decoder";
import WarningIcon from "@mui/icons-material/Warning";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { SummonModal } from "../SmartModal/SmartModal";

// Images

import GenericCarService from "../../Assets/ImagesEmbedded/car-service-generic.png";
import AppRoutes from "../../Costants/AppRoutes";
import { IAddedService } from "../../Models/IService";

export interface IServicesSubscriptionsTile {
  service: IAddedService;
  selected: any;
  keySubscription: number;
  onClick: (data: boolean) => void;
  isStored?: boolean;
  isActive?: boolean;
  isReduceMode?: boolean;
  isTrial: boolean;
  onDeleteAddedService?: (data: number) => void;
  pricingType: string;
  getUserServiceAddons: IAddedService[];
}

export const renderServiceImage = (service: any) => {
  return service.addedServiceImage
    ? service.addedServiceImage
    : GenericCarService;
};

const ServicesSubscriptionsTile = (props: IServicesSubscriptionsTile) => {
  const [selected, setSelected] = useState<boolean>(false);
  const updateSelected = (value: any) => {
    if(!nextAddedService.includes(props.service.addedServiceCode)){
      props.onClick(value);
    }
  };
  const [selectedAndNotReducing, setSelectedAndNotReducig] = useState<boolean>(
    props.selected && !props.isReduceMode
  );

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  let customClassName: string = "subscription-single";
  if (selected) {
    customClassName += " service-tile-main-wrap-selected";
  }
  if (props.isStored) {
    customClassName += " storedBox";
  }

  const disableSelection =
    (!selected && props.isReduceMode) ||
    props.isTrial ||
    (props.selected && !props.isReduceMode && props.getUserServiceAddons.find((x: IAddedService) => x.addedServiceID === props.service.addedServiceID));

  const nextAddedService = [
    "PROMPT_DELIVERY_ADDON",
    "CAR_CONFIGURATOR_ADDON",
    "QUOTE_ADDON"
  ];

  return (
    <AccordionDetails
      sx={{ padding: "0px" }}
      style={disableSelection ? { opacity: "0.5" } : {}}
      className="service-tile-subscription-accordion"
    >
      <div className={customClassName}>
        {props.isActive && props.onDeleteAddedService && props.isReduceMode && (
          <div className="deleteAddedService">
            <IconButton
              disabled={disableSelection}
              color="error"
              onClick={() => {
                if (props.onDeleteAddedService) {
                  props.onDeleteAddedService(props.service.addedServiceID);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
        <div>
          <div className="service-tile-subscription-title">
            {props.isStored ? (
              <Tooltip
                title={i18next
                  .t("message:serviceStoredNotAvailableForPurchase")
                  .replace(
                    "EXPIRY_DATE",
                    displayUTC0_ISODate(props.service.validTo, true)
                  )}
              >
                <WarningIcon color="error" />
              </Tooltip>
            ) : props.isActive ? (
              <Tooltip
                title={i18next
                  .t("message:serviceAlreadyPurchase")
                  .replace(
                    "EXPIRY_DATE",
                    displayUTC0_ISODate(props.service.validTo, true)
                  )}
              >
                <CheckCircleIcon color="success" />
              </Tooltip>
            ) : (
              <></>
            )}
            {props.service.addedServiceName}
          </div>
          <div className="service-tile-subscription-cost">
            {!nextAddedService.includes(props.service.addedServiceCode) ?
              <>
              {props.pricingType === "1"
                ? currencyDecoder(+props.service.addedServiceMontlyCost, 2)
                : currencyDecoder(+props.service.addedServiceYearlyCost, 2)}
              {props.service.addedServiceCallCost > 0 && (
                <span>
                  <small>
                    ({i18next.t("generic:costPerCall")}&nbsp;
                    {currencyDecoder(+props.service.addedServiceCallCost, 0)})
                  </small>
                </span>
              )}
              </>
            :
              i18next.t("generic:availableSoon")
            }
          </div>
          <img
            className="service-tile-subscription-image"
            src={renderServiceImage(props.service)}
          />
          <div className="service-tile-subscription-description">
            {props.service.addedServiceDescription}
            {props.service.addedServiceNote && (
              <div>
                <small>({props.service.addedServiceNote})</small>
              </div>
            )}
          </div>
          {(!nextAddedService.includes(props.service.addedServiceCode) && !disableSelection) && !props.isReduceMode ?
            <div>
              <ToggleButton
                disabled={disableSelection}
                className="service-tile-subscription-addButton"
                fullWidth
                value={props.service.addedServiceID}
                selected={selected}
                onClick={() => {
                  if (
                    props.service.addedServiceCode ===
                    "REQUEST_SATELLITE_EQUIPMENT"
                  ) {
                    SummonModal("request-satellite-equipment-modal");
                  } else if (
                    props.service.addedServiceCode === "REQUEST_FUEL_CARD_ADDON"
                  ) {
                    SummonModal("request-fuel-card-modal");
                  } else {
                    if(!nextAddedService.includes(props.service.addedServiceCode)){
                      updateSelected(props.service);
                    }
                  }
                }}
              >
                {selected ? <DoneIcon /> : <AddIcon />}

                {selected
                  ? i18next.t("navigation:addedService")
                  : i18next.t("navigation:addService")}
              </ToggleButton>
            </div>
          :
            <div style={{display: "flex", flexDirection: "column"}} className="service-tile-subscription-addButton"><span>&nbsp;</span><span>&nbsp;</span></div>
          }
        </div>
      </div>
    </AccordionDetails>
  );
};

export default ServicesSubscriptionsTile;
