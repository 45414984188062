import { useEffect, useState } from "react";
import { IAssignmentGet } from "../../../Models/IAssignment";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import FormTelepass from "./FormTelepass";
import "./TabTelepass.scss";
import { ITelepassGet } from "../../../Models/ITelepass";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";

export interface ITabTelepassProps {
  currentTab: string;
  dataVehicle: IVehicleDataGet | null;
  dataAssignment: IAssignmentGet;
  loggedUser: IUser | undefined;
  doRefreshForm?: () => void;
}

const TabTelepass = (props: ITabTelepassProps) => {
  const [loadingCurrentTelepass, setLoadingCurrentTelepass] =
    useState<boolean>(false);
  const [telepass, setTelepass] = useState<ITelepassGet>();
  const loadCurrentTelepass = () => {
    if (props.loggedUser && props.dataVehicle !== null) {
      setLoadingCurrentTelepass(true);
      ApiService.TelepassController.TelepassGet(
        null,
        props.dataVehicle.vehicleID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let list: ITelepassGet[] = response.payload;
            let activeOnly: ITelepassGet[] = list.filter(
              (x: ITelepassGet) => x.stored === false
            );
            if (activeOnly.length > 0) {
              setTelepass(activeOnly[activeOnly.length - 1]);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCurrentTelepass(false);
        }
      );
    }
  };

  useEffect(() => {
    loadCurrentTelepass();
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-telepass"}
      id="secondary-tab-telepass"
      aria-labelledby="secondary-tab-telepass"
    >
      {props.currentTab === "secondary-tab-telepass" && (
        <div>
          {!loadingCurrentTelepass && (
            <FormTelepass
              currentTelepass={telepass}
              loggedUser={props.loggedUser}
              dataVehicle={props.dataVehicle ?? undefined}
              dataAssignment={props.dataAssignment}
              disabled={!props.dataVehicle?.vehicleID}
              doRefreshForm={props.doRefreshForm}
            />
          )}
        </div>
      )}
      <LoaderBackdrop loading={loadingCurrentTelepass} />
    </div>
  );
};

export default TabTelepass;
