import {
  ToggleButton,
  AccordionDetails,
  Tooltip,
  IconButton,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import "./ServicesSubscriptions.scss";
import { currencyDecoder } from "../../Utils/Decoder";
import WarningIcon from "@mui/icons-material/Warning";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { renderServiceImage } from "../ServicesSubscriptionsTile/ServicesSubscriptionsTile";
import { SummonModal } from "../SmartModal/SmartModal";
import AppRoutes from "../../Costants/AppRoutes";
import { IAddedService } from "../../Models/IService";
import {IUser} from "../../Models/IUser";
import {useSelector} from "react-redux";
import {GlobalState} from "../../Reducers/RootReducer";

export interface IServicesSubscriptions {
  service: any;
  selected: any;
  keySubscription: number;
  onClick: (data: boolean) => void;
  isStored?: boolean;
  isActive?: boolean;
  isTrial: boolean;
  onDeleteAddedService?: (data: number) => void;
  pricingType: string;
  onlyView?: boolean;
  isReduceMode?: boolean;
  getUserServiceAddons?: IAddedService[];
  isWebSite?: boolean;
  isPublic?: boolean;
}

const ServicesSubscriptions = (props: IServicesSubscriptions) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedAndNotReducing, setSelectedAndNotReducig] = useState<boolean>(
    props.selected && !props.isReduceMode
  );
  const updateSelected = (value: any) => {
    props.onClick(value);
  };

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  let customClassName: string = "service-subscription-row";
  if (selected) {
    customClassName += " selectedBox";
  }
  if (props.isStored) {
    customClassName += " storedBox";
  }

  const disablePick =
    props.isTrial ||
    (props.isReduceMode && !selected) ||
    selectedAndNotReducing;

  const nextAddedService = [
    "PROMPT_DELIVERY_ADDON",
    "CAR_CONFIGURATOR_ADDON",
    "QUOTE_ADDON"
  ];

  return (
      <>
        {!props.isPublic && <AccordionDetails
            style={disablePick ? { opacity: "0.5" } : {}}
            sx={{ padding: "0px" }}
            className="service-subscription-accordion"
        >
          <div className={customClassName}>
            <img
                className="service-logo-span"
                src={renderServiceImage(props.service)}
                alt=""
            />
            <div className="service-subscription-title">
              {props.isStored ? (
                  <Tooltip
                      title={i18next
                          .t("message:serviceStoredNotAvailableForPurchase")
                          .replace(
                              "EXPIRY_DATE",
                              displayUTC0_ISODate(props.service.validTo, true)
                          )}
                  >
                    <WarningIcon color="error" />
                  </Tooltip>
              ) : (
                  props.isActive && (
                      <Tooltip
                          title={i18next
                              .t("message:serviceAlreadyPurchase")
                              .replace(
                                  "EXPIRY_DATE",
                                  displayUTC0_ISODate(props.service.validTo, true)
                              )}
                      >
                        <CheckCircleIcon color="success" />
                      </Tooltip>
                  )
              )}
              {props.isActive && props.onDeleteAddedService && (
                  <div className="deleteAddedService">
                    <IconButton
                        disabled={disablePick}
                        color="error"
                        onClick={() => {
                          if (props.onDeleteAddedService) {
                            props.onDeleteAddedService(props.service.addedServiceID);
                          }
                        }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
              )}
              {props.service.addedServiceName}
            </div>
            <div className="service-subscription-cost">
              {!nextAddedService.includes(props.service.addedServiceCode) && (
                  <>
                    {props.pricingType === "1"
                        ? currencyDecoder(props.service.addedServiceMontlyCost, 2)
                        : currencyDecoder(props.service.addedServiceYearlyCost, 2)}
                    {props.service.addedServiceCallCost > 0 && (
                        <span>
                <small>
                  ({i18next.t("generic:costPerCall")}{" "}
                  {currencyDecoder(+props.service.addedServiceCallCost, 0)})
                </small>
              </span>
                    )}
                  </>
              )}
            </div>
            <div className="service-subscription-description">
              {props.service.addedServiceDescription}
              {props.service.addedServiceNote && (
                  <div>
                    <small>({props.service.addedServiceNote})</small>
                  </div>
              )}
            </div>
            {!disablePick && !props.onlyView || !nextAddedService.includes(props.service.addedServiceCode) ?
                <div>
                  {!props.isWebSite && <ToggleButton
                      disabled={disablePick}
                      className="service-subscription-addButton"
                      fullWidth
                      value={props.service.addedServiceID}
                      selected={selected}
                      onClick={() => {
                        if (
                            props.service.addedServiceCode ===
                            "REQUEST_SATELLITE_EQUIPMENT"
                        ) {
                          SummonModal("request-satellite-equipment-modal");
                        } else if (
                            props.service.addedServiceCode === "REQUEST_FUEL_CARD_ADDON"
                        ) {
                          SummonModal("request-fuel-card-modal");
                        } else {
                          updateSelected(props.service);
                        }
                      }}
                  >
                    {selected ? <DoneIcon /> : <AddIcon />}

                    {selected
                        ? i18next.t("navigation:addedService")
                        : i18next.t("navigation:addService")}
                  </ToggleButton>}
                </div>
                :
                <div className="service-subscription-addButton">
                  {i18next.t("generic:availableSoon")}
                </div>
            }
          </div>
        </AccordionDetails> }

        {props.isPublic &&
          <div className="ff-services-boxes-wrapper">
            <div className="ff-services-logo-container">
              <img
                  className="ff-services-logo"
                  src={renderServiceImage(props.service)}
                  alt=""
              />
            </div>
            <div className="ff-services-boxes-container">
              <div className="ff-services-boxes-title">
                <p className="ff-box-title">{props.service.addedServiceName}</p>
                <div>
                  {!disablePick && !props.onlyView || !nextAddedService.includes(props.service.addedServiceCode) ?
                      <div>
                        {!props.isWebSite && <ToggleButton
                            disabled={disablePick}
                            className="ff-service-subscription-addButton"
                            fullWidth
                            value={props.service.addedServiceID}
                            selected={selected}
                            onClick={() => {
                              if (
                                  props.service.addedServiceCode ===
                                  "REQUEST_SATELLITE_EQUIPMENT"
                              ) {
                                SummonModal("request-satellite-equipment-modal");
                              } else if (
                                  props.service.addedServiceCode === "REQUEST_FUEL_CARD_ADDON"
                              ) {
                                SummonModal("request-fuel-card-modal");
                              } else {
                                updateSelected(props.service);
                              }
                            }}
                        >
                          {selected ? <DoneIcon /> : <AddIcon />}

                          {selected
                              ? i18next.t("navigation:addedService")
                              : i18next.t("navigation:addService")}
                        </ToggleButton>}
                      </div>
                      :
                      <div className="ff-service-subscription-addButton">
                        {i18next.t("generic:availableSoon")}
                      </div>
                  }
                </div>
                {!nextAddedService.includes(props.service.addedServiceCode) && (
                    <div className="ff-box-price">
                      {props.pricingType === "1"
                          ? currencyDecoder(props.service.addedServiceMontlyCost, 2)
                          : currencyDecoder(props.service.addedServiceYearlyCost, 2)}
                      {props.service.addedServiceCallCost > 0 && (
                          <span>
                <small className="ff-box-additional-price">
                    ({i18next.t("generic:costMCTC")})
                </small>
              </span>
                      )}
                      {props.service.addedServiceName !== "Visure MCTC"
                          && props.service.addedServiceName !== "Certificates MCTC"
                          && props.service.addedServiceMontlyCost !== 0
                          && props.service.addedServiceYearlyCost !== 0
                          &&(
                          <>
                            &nbsp;/&nbsp;
                            {i18next.t("navigation:subscriptionDuration_" + props.pricingType + "_cost")}
                          </>
                      )}

                      {props.service.addedServiceMontlyCost !== 0
                          && props.service.addedServiceYearlyCost !== 0
                          &&(
                              <>
                                <p className="ff-vat-excluded">
                                  <small>{i18next.t("generic:vat_excluded")}</small>
                                </p>
                              </>
                          )}
                    </div>
                )}
              </div>
              <div>
                <p className="ff-box-description">
                  {props.service.addedServiceDescription}
                  {props.service.addedServiceNote && (
                      <div>
                        <small>({props.service.addedServiceNote})</small>
                      </div>
                  )}
                </p>
              </div>
            </div>
          </div>

        }

      </>

  );
};

export default ServicesSubscriptions;
