/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";
import dayjs from "dayjs";
import moment from "moment";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* MODELS */
import { IKmProjectionStatistics } from "../../../../../Models/IStatistics";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import MultiForm, {
  IMultiFormField,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* MUI */
import { Box, Button, ButtonGroup, Link, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { JSONPrint } from "../../../../../Utils/Decoder";
import "./KProjection__StatisticsPage.scss";
import { displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const HorizLineChart = (props: { value: number; max: number }) => {
  let width =
    props.value > 0
      ? Math.floor((props.value / props.max) * 100)
      : Math.floor(((-1 * props.value) / props.max) * 100);

  let microdesc = <div></div>;

  let sign = props.value > 0;
  if (props.value > 0) {
    microdesc = (
      <div
        className="display-flex-horizontal-start gap-extra-small"
        style={{ color: "red" }}
      >
        <NorthIcon />
        <span style={{ color: "white" }}>
          {props.value.toLocaleString("it-IT") + " Km"}
        </span>
      </div>
    );
  } else {
    microdesc = (
      <div
        className="display-flex-horizontal-start gap-extra-small"
        style={{ color: "#2fe92f" }}
      >
        <SouthIcon />{" "}
        <span style={{ color: "white" }}>
          {props.value.toLocaleString("it-IT") + " Km"}
        </span>
      </div>
    );
  }

  return (
    <div>
      <Tooltip title={microdesc}>
        <div className="horizchart-wrap">
          <div className="chart-slot-left">
            {props.value < 0 && (
              <div
                style={{ width: width + "%" }}
                className="horizchart-wrap-pos"
              ></div>
            )}
          </div>
          <div className="horizchart-wrap-bar"></div>
          <div className="chart-slot-right">
            {props.value > 0 && (
              <div
                style={{ width: width + "%" }}
                className="horizchart-wrap-neg"
              ></div>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

/* COMPONENT */
const KProjection__StatisticsPage_Data = () => {
  /* STATES */
  // Date
  const [state, setState] = useState<any>({});
  // Payload
  const [responseData, setResponseData] = useState<IKmProjectionStatistics[]>(
    []
  );
  const [rowsDef, setRowsDef] = useState<any>([]);
  // Loading
  const [loadingKmProjection, setLoadingKmProjection] =
    useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Load kilometric projection
  const loadStatisticsKilometricProjection = (
    from: Date | string,
    to: Date | string
  ) => {
    setLoadingKmProjection(true);

    ApiService.StatisticsController.getStatisticsKilometricProjection(
      from,
      to,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setResponseData(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingKmProjection(false);
      }
    );
  };

  /* FUNCTIONS */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      // From
      {
        width: 50,
        type: "date",
        size: "small",
        name: "statisticsKmDateFrom",
        defaultValue: state?.statisticsKmDateFrom,
        currentValue: state?.statisticsKmDateFrom,
        required: state?.statisticsKmDateTo ? true : false,
        label: String(i18next.t(`form:from`)),
        maxDate: dayjs(new Date().toISOString()),
      },
      // To
      {
        width: 50,
        type: "date",
        size: "small",
        name: "statisticsKmDateTo",
        defaultValue: state?.statisticsKmDateTo,
        currentValue: state?.statisticsKmDateTo,
        disabled: state?.statisticsKmDateFrom ? false : true,
        required: state?.statisticsKmDateFrom ? true : false,
        label: String(i18next.t(`form:to`)),
        minDate: state?.statisticsKmDateFrom,
      }
    );

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {props.value}
        </Link>
      </div>
    );
  };

  // Render pos/neg km
  const renderKm = (props: GridRenderCellParams) => {
    let sign = Math.sign(props.row.additionalkm);

    switch (sign) {
      case 1:
        return (
          <div style={{ color: "red" }}>
            {props.value.toLocaleString("it-IT") + " Km"}
          </div>
        );
      default:
        return (
          <div style={{ color: "green" }}>
            {props.value.toLocaleString("it-IT") + " Km"}
          </div>
        );
    }
  };

  // Render additional km
  const renderAdditionalKm = (props: GridRenderCellParams) => {
    return <HorizLineChart value={props.value} max={props.row.maxAddKm} />;
  };

  // Render data from API
  const getData = (months?: number) => {
    if (state?.statisticsKmDateFrom && state?.statisticsKmDateTo) {
      loadStatisticsKilometricProjection(
        state?.statisticsKmDateFrom,
        state?.statisticsKmDateTo
      );
    } else if (months) {
      const today = new Date();
      const todayISO = new Date().toISOString();
      const monthsAgo = new Date(
        today.setMonth(today.getMonth() - months)
      ).toISOString();

      loadStatisticsKilometricProjection(todayISO, monthsAgo);
    }

    setRowsDef(
      responseData.map((x: any, i: number) => {
        return {
          id: i,
          vehicleID: x.vehicleID,
          plate: formatPlate(x.vehicleLicensePlate),
          model: x.modelDescription.replace(/\s\s+/g, " "),
          ownershipType: x.contractTypeName,
          since: displayUTC0_ISODate(x.contractStartDT, true),
          sinceOriginal: x.contractStartDT,
          until: displayUTC0_ISODate(x.contractEndDT, true),
          untilOriginal: x.contractEndDT,
          km: x.contractKm,
          additionalkm: x.kmExcess,
          kmForecast: x.kmForecast.toLocaleString("it-IT") + " Km",
          months:
            dayjs(x.contractEndDT).diff(dayjs(x.contractStartDT), "month") +
            " " +
            i18next.t("navigation:_statistics_projection_months"),
        };
      })
    );
    // }
  };

  /* USE EFFECT HOOK */
  // Render for period selection
  useEffect(() => {
    getData();
  }, [state, loadingKmProjection]);

  // Columns
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "ownershipType",
      headerName: i18next.t("navigation:_statistics_projection_ownership"),
    },
    {
      field: "since",
      headerName: i18next.t("navigation:_statistics_projection_since"),
    },
    {
      field: "until",
      headerName: i18next.t("navigation:_statistics_projection_until"),
    },
    {
      field: "km",
      headerName: i18next.t("navigation:_statistics_projection_km"),
      renderCell: renderKm,
      type: "number"
    },
    {
      field: "additionalkm",
      headerName: i18next.t("navigation:_statistics_projection_additionalkm"),
      renderCell: renderAdditionalKm,
      type: "number"
    },
    {
      field: "kmForecast",
      headerName: i18next.t("navigation:_statistics_projection_forecastkm"),
      type: "number"
    },
    {
      field: "months",
      headerName: i18next.t(
        "navigation:_statistics_projection_contract_months"
      ),
    },
  ];

  let max_AddKm = 0;

  rowsDef.forEach((element: any) => {
    let modulus =
      element.additionalkm > 0
        ? element.additionalkm
        : -1 * element.additionalkm;
    if (element.additionalkm > max_AddKm) {
      max_AddKm = element.additionalkm;
    }
  });

  var rowsDef_: any[] = [];
  rowsDef.forEach((element: any) => {
    let element_ = { ...element };
    element_["maxAddKm"] = max_AddKm;
    rowsDef_.push(element_);
  });

  /* RETURN */
  return (
    <Box className="CO2Emission__minHeight">
      <div className="display-flex-horizontal-space-between">
        <div>
          <MultiForm
            suppressLayout
            classNameForm="multi-form-flex"
            suppressSubmit={true}
            formId={"FormKmDatePickerRange"}
            formTitle={"FormKmDatePickerRange"}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState(data);
            }}
          />
        </div>

        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button onClick={() => getData(1)}>
            {i18next.t("navigation:lastMonth")}
          </Button>
          <Button onClick={() => getData(6)}>
            {i18next.t("navigation:last6Months")}
          </Button>
          <Button onClick={() => getData(12)}>
            {i18next.t("navigation:last12Months")}
          </Button>
        </ButtonGroup>
      </div>

      {!loadingKmProjection && (
        <DataGridWrap headers={columnsDef} rows={rowsDef_} />
      )}

      <LoaderBackdrop loading={loadingKmProjection} />
    </Box>
  );
};

export default KProjection__StatisticsPage_Data;
