import i18next from "i18next";
import { ITicketDetail, ITicketInfo } from "../../Models/ITicket";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";
import "./TicketDetailForm.scss";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import { IDriverGet } from "../../Models/IDriver";
import { GlobalState } from "../../Reducers/RootReducer";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";

export interface ITicketDetailFormProps {
  ticket: ITicketInfo;
  minorEdit: boolean;
  disabled?: boolean;
  onTicketEdit: (edited: ITicketInfo) => void;
}

const EditDriverOnTicketField = (props: {
  driverID: number;
  name: string;
  detailID: number;
  disabled?: boolean;
  onEdit: (newDriverID: number, newDriverName: string) => void;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const drivers: IDriverGet[] = useSelector(
    (state: GlobalState) => state.vehicles.driversForCompany
  );

  const useDrivers: IDriverGet[] = [...drivers];
  if (
    useDrivers.filter(
      (x: IDriverGet) => x.driverInfo.driverID === props.driverID
    ).length === 0
  ) {
    useDrivers.push({
      driverInfo: {
        driverID: props.driverID,
        driverName: props.name,
        driverSurname: "",
      },
    } as IDriverGet);
  }

  const setNewDriver = (newDriverID: number, newDriverName: string) => {
    setLoading(true);
    ApiService.TicketController.UpdateTicketDriver(
      props.detailID,
      newDriverID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:driverTicketUpdated"), "success");
          setEditMode(false);
          props.onEdit(newDriverID, newDriverName);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  return (
    <div>
      {!editMode && (
        <div className="edit-driver-row-on-ticket">
          {props.name}{" "}
          <IconButton
            edge="end"
            disabled={props.disabled}
            aria-label="edit"
            onClick={() => setEditMode(true)}
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
      {editMode && (
        <select
          style={{ width: "100%" }}
          disabled={loading}
          value={props.driverID}
          onChange={(e: any) => {
            let id: number = +e.target.value;
            let driver = useDrivers.find(
              (x: IDriverGet) => x.driverInfo.driverID === id
            );
            if (driver) {
              setNewDriver(
                id,
                driver.driverInfo.driverName +
                  " " +
                  driver.driverInfo.driverSurname
              );
            }
          }}
        >
          {useDrivers
            .sort((a: IDriverGet, b: IDriverGet) => {
              let nameA =
                a.driverInfo.driverName + " " + a.driverInfo.driverSurname ??
                "";
              let nameB =
                b.driverInfo.driverName + " " + b.driverInfo.driverSurname ??
                "";
              return nameA.localeCompare(nameB);
            })
            .map((x: IDriverGet, i: number) => {
              return (
                <option value={x.driverInfo.driverID}>
                  {x.driverInfo.driverName + " " + x.driverInfo.driverSurname}
                </option>
              );
            })}
        </select>
      )}
    </div>
  );
};

const TicketField = (props: {
  title: string;
  value: any;
  subtitle?: string;
}) => {
  return (
    <div className="ticket-modal-field-wrap">
      <div className="ticket-modal-field-title">{props.title}</div>
      <div className="ticket-modal-field-value">{props.value}</div>
      <div className="ticket-modal-field-subtitle">{props.subtitle}</div>
    </div>
  );
};

const TicketDetailTable = (props: {
  details: ITicketDetail[];
  disabled?: boolean;
  onEdit: (
    newDriverID: number,
    newDriverName: string,
    ticketDetailID: number
  ) => void;
}) => {
  const [articleDetail, setArticleDetail] = useState<string>("")
  let headers: string[] = [
    i18next.t("navigation:_ticket_modal_detail_driver"),
    i18next.t("navigation:_ticket_modal_detail_amount"),
    i18next.t("navigation:_ticket_modal_detail_violation_dt"),
    i18next.t("navigation:_ticket_modal_detail_plate"),
    i18next.t("navigation:_ticket_modal_detail_model"),
    i18next.t("navigation:_ticket_modal_detail_license_suspended"),
    i18next.t("navigation:_ticket_modal_detail_license_suspended_time"),
    i18next.t("navigation:_ticket_modal_detail_license_points_deducted"),
    i18next.t("navigation:_ticket_modal_detail_license_points_deducted_amount"),
    i18next.t("navigation:_ticket_modal_detail_article"),
    i18next.t("navigation:_ticket_modal_detail_comma"),
    i18next.t("navigation:_ticket_modal_detail_article_detail"),
  ];

  let values: (string | number)[][] = props.details.map((x: ITicketDetail) => {
    return [
      <EditDriverOnTicketField
        disabled={props.disabled}
        detailID={x.ticketDetailID}
        driverID={x.driverID}
        name={x.driverDisplayName}
        onEdit={(newDriverID: number, newDriverName: string) => {
          props.onEdit(newDriverID, newDriverName, x.ticketDetailID);
        }}
      />,
      x.amount.toString() + "€",
      displayUTC0_ISODate(x.violationDT, true),
      x.vehicleLicensePlate,
      x.modelDescription,
      x.pointsDeduction ? i18next.t("message:yes") : i18next.t("message:no"),
      x.pointsDeducted ?? "-",
      x.licenseSuspension ? i18next.t("message:yes") : i18next.t("message:no"),
      x.licenseSuspensionPeiriod ?? "-",
      x.article,
      x.comma,
      x.ticketArticleDescription,
    ] as (string | number)[];
  });

  return (
    <div>
      <table className="ticket-details-table-wrap">
        <thead>
          <tr>
            {headers.map((x: string, i: number) => {
              return <th key={i}>{x}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {values.map((x: (string | number)[], i: number) => {
            return (
              <tr key={i}>
                {x.map((x: string | number, j: number) => {
                  if(j === values[0].length - 1) {
                    return <td><SearchIcon onClick={() => {
                      setArticleDetail(x as string)
                      setTimeout(() => {
                        SummonModal("articleDetail-modal")
                      }, 300)
                    }} /></td>
                  } else {
                  return <td style={{width: j === 0 || j === values[0].length - 3 ? "15%" : "8%"}} key={j}>{x}</td>;}
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <SmartModal
      modalUniqueId="articleDetail-modal"
      title={i18next.t("navigation:_ticket_modal_detail_article_detail")}
      modalInnerComponent={<div>{articleDetail}</div>}
      onReject={() => {
        DismissModal("articleDetail-modal");
      }}
      />
    </div>
  );
};

const TicketDetailForm = (props: ITicketDetailFormProps) => {
  const fields: { title: string; value: any; subtitle?: string }[] = [];
  const [ticket, setTicket] = useState<ITicketInfo>(props.ticket);
  const [openAttach, setOpenAttach] = useState<boolean>(false);

  useEffect(() => {
    if (!ticket) {
      setTicket(props.ticket);
    } else {
      props.onTicketEdit(ticket);
    }
  }, [ticket]);

  if (ticket) {
    fields.push({
      title: i18next.t("navigation:ticket_verbal_n"),
      value: ticket.ticket.ticketNumber,
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_amount"),
      value: ticket.ticket.ticketAmount ? ticket.ticket.ticketAmount.toFixed(2) + "€" : "-",
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_exp_date"),
      value: ticket.ticket.ticketExpirationDT
        ? displayUTC0_ISODate(ticket.ticket.ticketExpirationDT, true)
        : "-",
    });

    if (ticket.ticket.reducedTotalAmount) {
      fields.push({
        title: i18next.t("navigation:_ticket_modal_reduced_amount"),
        value: ticket.ticket.reducedTotalAmount ? ticket.ticket.reducedTotalAmount.toFixed(2) + "€" : "-",
        subtitle:
          i18next.t("navigation:_ticket_modal_reduced_amount_subtitle") ?? "",
      });
      fields.push({
        title: i18next.t("navigation:_ticket_modal_reduced_amount_date"),
        value:
          displayUTC0_ISODate(ticket.ticket.ticketReducedExpirationDT, true) ??
          "-",
      });
    }
    
    fields.push({
      title: i18next.t("navigation:_tickets_page_amount_paid"),
      value: ticket.ticket.paidAmount ? ticket.ticket.paidAmount.toFixed(2) + "€" : "-",
    });

    fields.push({
      title: i18next.t("navigation:_ticket_modal_issuing_istitute"),
      value: ticket.ticket.ticketIssuingInstitutionName ?? "-",
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_issuing_from"),
      value: ticket.ticket.ticketDeliveryTypeName ?? "-",
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_type_name"),
      value: ticket.ticket.ticketTypeName ?? "-",
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_description"),
      value: ticket.ticket.ticketDescription ?? "-",
    });
    fields.push({
      title: i18next.t("navigation:_ticket_modal_status_name"),
      value: ticket.ticket.ticketStatusName ?? "-",
    });
  }

  return (
    <div>
      <div className="ticket-detail-title" style={{ marginTop: "0" }}>
        {i18next.t("navigation:ticket-details")}
      </div>
      {fields.map((x: { title: string; value: any }, i: number) => {
        return <TicketField {...x} key={i} />;
      })}
      <div
        className="ticket-detail-title accordion-ticket-detail-title"
        onClick={() => {
          setOpenAttach(!openAttach);
        }}
      >
        {i18next.t("navigation:ticket-attachments")}{" "}
        {!openAttach ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </div>
      {openAttach && (
        <div className="accordion-ticket-detail-content">
          <AttachementMainHandler
            attachmentFamily={"ticket"}
            extraGetParametersVector={
              ticket.ticketDetail.length > 0
                ? [
                    {
                      name: "ticketID",
                      value: ticket.ticketDetail[0].ticketID,
                    },
                  ]
                : undefined
            }
          />
        </div>
      )}

      <div className="ticket-detail-title">
        {i18next.t("navigation:ticket-table")}
      </div>
      <TicketDetailTable
        disabled={props.disabled}
        onEdit={(
          newDriverID: number,
          newDriverName: string,
          ticketDetailID: number
        ) => {
          let tk_: ITicketInfo = { ...ticket };
          for (let i = 0; i < tk_.ticketDetail.length; i++) {
            if (tk_.ticketDetail[i].ticketDetailID === ticketDetailID) {
              tk_.ticketDetail[i].driverID = newDriverID;
              tk_.ticketDetail[i].driverDisplayName = newDriverName;
            }
          }

          setTicket(tk_);
        }}
        details={ticket.ticketDetail}
      />
    </div>
  );
};

export default TicketDetailForm;
