import { Alert } from "@mui/material";
import { ICompanyGet } from "../../../Models/ICompany";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import FormVehicleData from "./FormVehicleData";
import "./TabVehicleData.scss";
import i18next from "i18next";
import AppRoutes from "../../../Costants/AppRoutes";

export interface ITabVehicle {
  currentTab: string;
  dataVehicle: IVehicleDataGet | null;
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  canInsertVehicles?: boolean;
  doRefreshForm?: () => void;
  doResetVehicle: (vehicle: IVehicleDataGet | null) => void;
}

const TabVehicle = (props: ITabVehicle) => {
  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-vehicle"}
      id="secondary-tab-vehicle"
      aria-labelledby="secondary-tab-vehicle"
    >
      <div className="container-content-form">
        <div className="container-stepper-form vehicle-form">
          {props.currentTab === "secondary-tab-vehicle" && (
            <div>
              {window.location.pathname.includes(AppRoutes.CONTINUE_REGISTRATION_ROUTE) &&
                props.canInsertVehicles === false && (
                <Alert severity="warning">
                  {i18next.t("message:MAX_NUMBER_VEHICLES")}
                </Alert>
              )}

              {window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) &&
                <Alert severity="info">
                  {i18next.t("navigation:reached_max_limit_vehicles")}
                </Alert>
              }

              <FormVehicleData
                canInsertVehicles={props.canInsertVehicles}
                companyUser={props.companyUser}
                loggedUser={props.loggedUser}
                dataVehicle={props.dataVehicle ?? undefined}
                doResetVehicle={props.doResetVehicle}
                isModal={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabVehicle;
