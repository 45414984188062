import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IInfoCarZipDelete, IInfoCarZipInsert } from "../../Models/IInfoCarZip";
import { AjaxService, IAPIRequest, IAPIResponse, createEmptyRequest } from "../Internal/AjaxService";

const InfoCarController = {
    GetInfoCarZipUpload: async (
        callback?: (response: IAPIResponse) => void
      ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = ApiEndPoints.GET_INFO_CAR_ZIP_UPLOAD;
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
          callback(response);
        }
        return response;
      },
    
      InsertInfoCarZipUpload: async (
        body: IInfoCarZipInsert,
        callback?: (response: IAPIResponse) => void
      ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = ApiEndPoints.INSERT_INFO_CAR_ZIP_UPLOAD;
        request.method = "POST";
        request.payload = body;
        request.extraHeaders = { "Content-Type": "application/json" };
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
          callback(response);
        }
        return response;
      },
    
      DeleteInfoCarZipUpload: async (
        body: IInfoCarZipDelete,
        callback?: (response: IAPIResponse) => void
      ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = ApiEndPoints.DELETE_INFO_CAR_ZIP_UPLOAD;
        request.method = "DELETE";
        request.payload = body;
        request.extraHeaders = { "Content-Type": "application/json" };
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
          callback(response);
        }
        return response;
      },
      GetInfoCarData: async (
        fittingCode: string,
        callback?: (response: IAPIResponse) => void
      ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = ApiEndPoints.GET_INFO_CAR_DATA;
        let parameters: string[] = [];
        if (fittingCode) {
          parameters.push(`fittingCode=${fittingCode}`);
        }
        request.url += "?" + parameters.join("&");
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
          callback(response);
        }
        return response;
      },
}

export default InfoCarController