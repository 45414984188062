import "./RatesPage.scss";
import PricingSubscriptions from "../../Components/PricingSubscriptions/PricingSubscriptions";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { IFleetPlan } from "../../Models/IFleetPlan";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { FormGroup, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { ISubscriptionsDuration } from "../../Models/ISubscriptions";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import { voices } from "../../i18n";
import i18next from "i18next";
import ServicesSubscriptions from "../../Components/ServicesSubscriptions/ServicesSubscriptions";
import { IAddedService } from "../../Models/IService";
import { Trans } from "react-i18next";
import BannerVideoComponent from "../../Components/BannerVideoComponent/BannerVideoComponent";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";
import Slider from "react-slick";
import { useMediaQuery } from "usehooks-ts";
import AppRoutes from "../../Costants/AppRoutes";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../Reducers/RootReducer";
import { useSelector } from "react-redux";

const RatesPage = () => {
  const navigate = useNavigate();
  const imgStructureSection = "/Images/newImages/CTA_cropped.png";
  const [loadingFleetPlans, setLoadingFleetPlans] = useState<boolean>(false);
  const [fleetPlans, setFleetPlans] = useState<IFleetPlan[]>([]);
  const [state, setState] = useState<any>({
    pricingType: "1",
  });
  const [subscriptionDuration, setSubscriptionsDuration] = useState<ISubscriptionsDuration[]>([]);
  const [servicesList, setServicesList] = useState<IAddedService[]>([]);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [currentFleetPlanIndex, setCurrentFleetPlanIndex] = useState<{ clicked: number; hover?: number }>({
    clicked: 0,
  });

  // marketplace area services
  const [marketplaceServicesList, setmarketplaceServicesList] = useState<IAddedService[]>([]);
  const [loadingMarketplaceServices, setLoadingMarketplaceServices] = useState<boolean>(false);
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const matches = useMediaQuery("(max-width: 768px)");
  const sliderRef = useRef<Slider>(null);

  const loadFleetPlans = () => {
    setLoadingFleetPlans(true);
    ApiService.FleetPlanController.FleetPlanGetData(null, false, true, (response: IAPIResponse) => {
      if (response.error === null) {
        setFleetPlans(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingFleetPlans(false);
    });
  };

  const loadServices = () => {
    setLoadingServices(true);
    ApiService.ServiceController.ServiceGetAddedData(true, false, (response: IAPIResponse) => {
      if (response.error === null) {
        let tmp = response.payload;
        tmp = tmp.filter(
          (el: IAddedService) =>
            el.addedServiceCode === "TICKET_ADDON_PRO" ||
            el.addedServiceCode === "FLEET_ADDON" ||
            el.addedServiceCode === "MCTC_ADDON"
        );
        tmp.map((el: IAddedService) => {
          let tmpService: IAddedService = el;
          if (el.addedServiceCode === "TICKET_ADDON_PRO") {
            tmpService.addedServiceDescription = i18next.t("intro:additionalServices_finesManagementPro");
          } else if (el.addedServiceCode === "FLEET_ADDON") {
            tmpService.addedServiceDescription = i18next.t("intro:additionalServices_fleetService");
          }
          return tmpService;
        });
        setServicesList(tmp);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingServices(false);
    });
  };

  // marketplace area services
  const loadMarketplaceServices = () => {
    setLoadingMarketplaceServices(true);
    ApiService.ServiceController.ServiceGetAddedData(true, false, (response: IAPIResponse) => {
      if (response.error === null) {
        let tmp = response.payload;
        tmp = tmp.filter(
          (el: IAddedService) =>
            el.addedServiceCode === "ROAD_TAX_ADDON" ||
            el.addedServiceCode === "QUOTE_ADDON" ||
            el.addedServiceCode === "CAR_CONFIGURATOR_ADDON" ||
            el.addedServiceCode === "PROMPT_DELIVERY_ADDON"
        );
        tmp.map((el: IAddedService) => {
          let tmpService: IAddedService = el;
          if (el.addedServiceCode === "ROAD_TAX_ADDON") {
            tmpService.addedServiceDescription = i18next.t("intro:marketplace_carTaxpayment");
          } else if (el.addedServiceCode === "PROMPT_DELIVERY_ADDON") {
            tmpService.addedServiceDescription = i18next.t("intro:marketplace_readyDelivery");
          } else if (el.addedServiceCode === "CAR_CONFIGURATOR_ADDON") {
            tmpService.addedServiceDescription = i18next.t("intro:marketplace_carConfigurator");
          } else if (el.addedServiceCode === "QUOTE_ADDON") {
            tmpService.addedServiceDescription = i18next.t("intro:marketplace_repairEstimate");
          }
          return tmpService;
        });
        setmarketplaceServicesList(tmp);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingMarketplaceServices(false);
    });
  };

  const updateChange = (name: string, value: any) => {
    let u: any = { ...state };
    u[name] = value;
    setState(u);
  };

  useEffect(() => {
    loadFleetPlans();
    loadServices();
    loadMarketplaceServices();
    setSubscriptionsDuration([
      {
        subscriptionDurationID: 1,
        subscriptionDurationName: i18next.t("navigation:montly"),
      },
      {
        subscriptionDurationID: 2,
        subscriptionDurationName: i18next.t("navigation:yearly"),
      },
    ]);
  }, []);

  const fleetPlansCards = useMemo(
    () =>
      fleetPlans
        .filter(
          (x: IFleetPlan) =>
            x.languageID ===
            voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num
        )
        .sort((a, b) => (a.fleetPlanMaxVehicle > b.fleetPlanMaxVehicle ? 1 : -1))
        .map((fleetPlan: IFleetPlan, i: number) => {
          return (
            <PricingSubscriptions
              wrapperProps={
                !matches
                  ? {
                      className:
                        typeof currentFleetPlanIndex.hover === "undefined"
                          ? currentFleetPlanIndex.clicked === i
                            ? "ff-fleetPlans-active"
                            : undefined
                          : currentFleetPlanIndex.hover === i
                          ? "ff-fleetPlans-active"
                          : undefined,
                      onClick: () => {
                        setCurrentFleetPlanIndex({ clicked: i });
                        navigate(
                          loggedUser
                            ? AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS
                            : AppRoutes.REGISTRATION_ROUTE
                        );
                      },
                      onMouseEnter: () => setCurrentFleetPlanIndex((prev) => ({ ...prev, hover: i })),
                    }
                  : {
                      onClick: () => {
                        setCurrentFleetPlanIndex({ clicked: i });
                        navigate(
                          loggedUser
                            ? AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS
                            : AppRoutes.REGISTRATION_ROUTE
                        );
                      },
                    }
              }
              isStored={fleetPlan.stored}
              subscription={fleetPlan}
              pricingType={state.pricingType}
              keySubscription={i}
              key={i}
              onlyView={true}
              isNotRegistered
              isPublic
            />
          );
        }),
    [
      currentFleetPlanIndex.clicked,
      currentFleetPlanIndex.hover,
      fleetPlans,
      loggedUser,
      matches,
      navigate,
      state.pricingType,
    ]
  );

  useLayoutEffect(() => {
    sliderRef.current?.slickGoTo(0);
  }, [fleetPlansCards]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <BannerVideoComponent
        filename="825144393.mp4"
        title={<Trans i18nKey="intro:ratesPageTitle" components={[<strong />, <br />]} />}
      />

      <div>
        <div className="ff-banner-rates-img-bg" style={{ backgroundImage: `url('./Images/newImages/168030344.jpeg')` }}>
          <div className="ff-banner-rates-overlay" />
          <div className="ff-banner-rates-container">
            <FormGroup className="container-switch">
              <ToggleButtonGroup
                color="primary"
                value={state.pricingType}
                onChange={(e: any) => {
                  updateChange("pricingType", e.target.value);
                }}
                exclusive
              >
                {subscriptionDuration.map((duration: ISubscriptionsDuration, i: number) => {
                  return (
                    <ToggleButton
                      className="ff-toggle-rates-btn"
                      key={i}
                      value={duration.subscriptionDurationID.toString()}
                    >
                      {duration.subscriptionDurationName}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </FormGroup>
            {!matches && (
              <div
                className="list-rates-wrapper"
                onMouseLeave={() => setCurrentFleetPlanIndex((prev) => ({ ...prev, hover: undefined }))}
              >
                {fleetPlansCards}
              </div>
            )}

            {matches && (
              <div className="ff-fleetPlans-carousel-mobile">
                <Slider ref={sliderRef} {...settings}>
                  {fleetPlansCards}
                </Slider>
              </div>
            )}
          </div>
        </div>

        <BannerCardComponent
          leftTitle={<Trans i18nKey="intro:additionalServices" />}
          leftContent={
            <div className="ff-extra-services-container">
              {servicesList
                .filter(
                  (x: IAddedService) =>
                    x.languageID ===
                    voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num
                )
                .map((service: IAddedService, i: number) => {
                  return (
                    <ServicesSubscriptions
                      isTrial={false}
                      key={i}
                      isStored={service.stored}
                      keySubscription={i}
                      service={service}
                      pricingType={state.pricingType}
                      selected={""}
                      onClick={() => {}}
                      onlyView={true}
                      isWebSite={true}
                      isPublic
                    />
                  );
                })}
            </div>
          }
          rightContent={
            <div className="ff-image-box ff-img-right-rates">
              <img src={"/Images/newImages/716084695.jpeg"} alt="" loading="lazy" />
            </div>
          }
        />

        <div className="ff-structure-banner-rates" style={{ backgroundImage: `url(${imgStructureSection})` }}>
          <div className="ff-structure-overlay-rates"></div>
          <h2 className="ff-title-section-rates">
            {<Trans i18nKey="intro:marketplace_title" components={[<strong />, <br />]} />}
          </h2>
          <div className="ff-structure-content-rates">
            {marketplaceServicesList
              .filter(
                (x: IAddedService) =>
                  x.languageID ===
                  voices.find((v: any) => localStorage.getItem("language") === v.key && v.num === x.languageID)?.num
              )
              .map((service: IAddedService, i: number) => {
                return (
                  <ServicesSubscriptions
                    isTrial={false}
                    key={i}
                    isStored={service.stored}
                    keySubscription={i}
                    service={service}
                    pricingType={state.pricingType}
                    selected={""}
                    onClick={() => {}}
                    onlyView={true}
                    isWebSite={true}
                    isPublic
                  />
                );
              })}
          </div>
        </div>
      </div>

      <LoaderBackdrop loading={loadingFleetPlans || loadingServices || loadingMarketplaceServices} />
    </>
  );
};

export default RatesPage;
