import i18next from "i18next";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import { useEffect, useState } from "react";
import DataGridWrap from "../DataGridWrap/DataGridWrap";

export interface IExcelErrorDialogRow {
  column: string;
  row: number;
  sheetName: string;
  title: "MANDATORY_FIELD_MISSING" | "MANDATORY_FIELD_INVALID_VALUE";
  value: any;
  expectedValueType: string;
  valueMayMatch: any;
}

var modalErrors: IExcelErrorDialogRow[] = [];
export const SummonErrorExcelDialog = (errors: IExcelErrorDialogRow[]) => {
  modalErrors = [...errors];
  SummonModal("error_excel_modal_dialog");
};

const ExcelErrorDialog = () => {
  const [errors, setErrors] = useState<IExcelErrorDialogRow[]>([]);
  const [errorCols, setErrorCols] = useState<any[]>([]);
  const [loadingErrors, setLoadingErrors] = useState(false);

  useEffect(() => {
    let errorCols_: any[] = [];
    if (errors.length > 0) {
      setLoadingErrors(true);
      for (let index = 0; index < errors.length; index++) {
        errorCols_.push(
          {
            ...errors[index],
            title: i18next
              .t("message:" + errors[index].title)
              .replace(
                "EXPECTED_VALUE_TYPE",
                i18next.t("message:" + errors[index].expectedValueType)
              ).toString(),
            value: errors[index].value ?? "-",
            valueMayMatch: errors[index].valueMayMatch ?? "-"
          }
        );
      }
      setLoadingErrors(false);
      setErrorCols(errorCols_);
    }
  }, [errors]); 

  return (
    <div>
      <SmartModal
        onBeforeSummon={() => {
          setErrors(modalErrors);
        }}
        modalUniqueId="error_excel_modal_dialog"
        onReject={() => {
          DismissModal("error_excel_modal_dialog");
        }}
        title={i18next.t("navigation:error_excel_modal_title")}
        modalInnerComponent={
          <div className="table-error-wrap">
            {!loadingErrors && (
              <DataGridWrap 
                tableName="Excel-Errors"
                headerToPrint={["sheetName", "column", "row", "title", "value"]}
                rows={errorCols}
                headers={[
                  {
                    field: "sheetName",
                    headerName: i18next.t("navigation:error_excel_table_sheet"),
                  },
                  {
                    field: "column",
                    headerName: i18next.t("navigation:error_excel_table_column"),
                  },
                  {
                    field: "row",
                    headerName: i18next.t("navigation:error_excel_table_row"),
                    type: "number"
                  },
                  {
                    field: "title",
                    headerName: i18next.t("navigation:error_excel_table_title"),
                  },
                  {
                    field: "value",
                    headerName: i18next.t("navigation:error_excel_table_value"),
                  },
                  {
                    field: "valueMayMatch",
                    headerName: i18next.t("navigation:error_excel_table_value_may_match")
                  }
                ]}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default ExcelErrorDialog;
