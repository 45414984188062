import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import i18next from "i18next";
import greenCard from "../../Assets/ImagesEmbedded/green-card.png";
import orangeCard from "../../Assets/ImagesEmbedded/orange-card.png";
import "./StepSummary.scss";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ResumeIconLine, {
  IResumeIconLineProps,
} from "../ResumeIconLine/ResumeIconLine";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/Toastify";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { IVehicleResume } from "../../Models/IVehicles";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import { ICompanyGet } from "../../Models/ICompany";
import moment from "moment";
import {
  displayUTC0_ISODate,
} from "../MultiForm/SpecialInputs/StrongDatePicker";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { RegistrationAction } from "../../Reducers/Registration/RegistrationAction";
import AppRoutes from "../../Costants/AppRoutes";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import { IStepperStep } from "../../Models/IStepperBody";
import { useNavigate } from "react-router-dom";
import { IBillingLastInfo, IPurchaseList } from "../../Models/IPayment";
import { currencyDecoder } from "../../Utils/Decoder";
import { set } from "rsuite/esm/utils/dateUtils";

export interface IStepSummary {
  nextPage: number;
  stepId?: number;
}

export interface IResumeContent extends IResumeIconLineProps {
  isTrialFree?: boolean;
  fleetPlanID?: number;
  addedServiceID?: number;
}

const StepSummary = (props: IStepSummary) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* REDUCER OF LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [vechiclesSummary, setVechiclesSummary] = useState<IVehicleResume[]>(
    []
  );
  const [missingStepsNames, setMissingStepsNames] = useState<string[]>([]);
  const [recapVoices, setRecapVoices] = useState<IResumeContent[]>([]);
  const [purchaseList, setPurchaseList] = useState<IPurchaseList[]>([]);
  const [loadingPurchaseList, setLoadingPurchaseList] = useState<boolean>(false);
  const [billingLastInfo, setBillingLastInfo] = useState<IBillingLastInfo>();
  const [loadingBillingLastInfo, setLoadingBillingLastInfo] = useState<boolean>(false);

  const fromServicesAndPlansToRecapVoices = () => {
    let recapVoices: IResumeContent[] = [];
    let total: number, totalLater: number = 0;

    for (let i = 0; i < purchaseList.length; i++) {
       
      if (purchaseList[i].fleetPlanID) {
        recapVoices.push({
          title: purchaseList[i].fleetPlanName,
          price: purchaseList[i].fleetPlanCost.toString(),
          icon: "check-car",
          subtitle: "",
          isTrialFree: getCurrentSubscription.isTrial,
        });
      }

      if (
        purchaseList[i].numExtraVehicles && purchaseList[i].numExtraVehicles > 0
      ) {
        recapVoices.push({
          title: i18next.t("navigation:extraVehicle"),
          subtitle: i18next
            .t("navigation:extraVehicleDescriptionCost")
            .replace("EXTRA_VEHICLES", purchaseList[i].numExtraVehicles.toString())
            .replace(
              "FLEET_PLAN_ADDITIONAL_CAR_COST",
              currencyDecoder(purchaseList[i].fleetPlanAdditionalCarCost, 2)
            ),
          price: purchaseList[i].fleetPlanAdditionalCarCostTotal.toString(),
          warning: "",
          icon: "extra-car",
        });
      }

      if (purchaseList[i].addedServiceID) {
        recapVoices.push({
          title: purchaseList[i].addedServiceName,
          price: purchaseList[i].addedServiceCost.toString(),
          icon: "rental-car",
          subtitle: "",
          isTrialFree: getCurrentSubscription.isTrial,
        });
    }
    }
    // abbonamento scaduto.
    if (
      !getCurrentSubscription.enabled &&
      getCurrentSubscription.paymentRequired &&
      !getCurrentSubscription.isTrial
    ) {
      recapVoices
        .filter((x: IResumeContent) => x.isTrialFree)
        .forEach((x: IResumeContent) => {
          total += +x.price;
        });
      recapVoices
        .filter((x: IResumeContent) => !x.isTrialFree)
        .forEach((x: IResumeContent) => {
          totalLater += +x.price;
        });
      }
    // abbonamento scaduto.
    if (
      !getCurrentSubscription.enabled &&
      getCurrentSubscription.paymentRequired &&
      !getCurrentSubscription.isTrial
    ) {
      recapVoices
        .filter((x: IResumeContent) => x.isTrialFree)
        .forEach((x: IResumeContent) => {
          total += +x.price;
        });
      recapVoices
        .filter((x: IResumeContent) => !x.isTrialFree)
        .forEach((x: IResumeContent) => {
          totalLater += +x.price;
        });
    }

    setRecapVoices(recapVoices);
  };

  const getProperIcon = (iconName: string) => {
    if (iconName === "green") {
      return greenCard;
    }
    if (iconName === "orange") {
      return orangeCard;
    }

    return iconName + "?";
  };

  const loadVehiclesResume = () => {
    if (companyUser) {
      setLoadingSummary(true);
      ApiService.VehicleDataController.VehicleDataResumeGetByAccount(
        companyUser?.companyID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setVechiclesSummary(response.payload);
            dispatch(
              RegistrationAction.setVehicleSummaryData(response.payload)
            );
          } else {
            ToastMessage(
              i18next.t("error:could_not_load_vehicle_resume"),
              "error"
            );
          }
          setLoadingSummary(false);
        }
      );
    }
  };

  const registrationEnd = () => {
    ApiService.StepperController.StepperGetData((response: IAPIResponse) => {
      if (response.error === null) {
        let steps: IStepperStep[] = response.payload;
  
        let skippableSteps = [3, 4, 5, 6, 8];
        let pendingSteps = steps.filter(
          (x: IStepperStep) =>
            x.isLastStep === false &&
            x.registrationStepCompleted === false &&
            skippableSteps.indexOf(x.registrationStepID) === -1
        );
  
        setMissingStepsNames(
          pendingSteps.map((x: IStepperStep) => {
            return x.registrationStepDescription;
          })
        );
  
        if (
          pendingSteps.length === 0) {
          updateAccountRegistrationStep(loggedUser, props.stepId, true);
          navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE);
        } else {
          SummonModal("Pending_Steps_Alert");
        }
      } else {
        ToastMessage(response.error, "error");
      }
    });
  };

  const loadPurchaseList = (paymentID: number) => {
    setLoadingPurchaseList(true);
    ApiService.PaymentController.GetPurchase(
      paymentID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPurchaseList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingPurchaseList(false);
      }
    );
  }

  const loadBillingLastInfo = () => {
    setLoadingBillingLastInfo(true);
    ApiService.PaymentController.GetBillingLastInfo(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBillingLastInfo(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingBillingLastInfo(false);
      }
    );
  }

  useEffect(() => {
    if (purchaseList.length > 0) {
      fromServicesAndPlansToRecapVoices();
    }
  }, [purchaseList]);

  useEffect(() => {
    if(billingLastInfo){
      loadPurchaseList(billingLastInfo.paymentID);
    }else{
      setPurchaseList([
        {
          purchaseID: 0,
          paymentID: 0,
          fleetPlanID: getCurrentSubscription.fleetPlanID,
          addedServiceID: 0,
          fleetPlanName: getCurrentSubscription.fleetPlanName,
          addedServiceName: "",
          purchaseCost: 0,
          numExtraVehicles: 0,
          fleetPlanAdditionalCarCost: 0,
          fleetPlanAdditionalCarCostTotal: 0,
          fleetPlanCost: 0,
          addedServiceCost: 0
        }
      ]);
    }
  }, [billingLastInfo]);

  useEffect(() => {
    loadBillingLastInfo();
    loadVehiclesResume();
    updateAccountRegistrationStep(loggedUser, props.stepId, true);
  }, []);

  useEffect(() => {
    updateAccountRegistrationStep(loggedUser, props.stepId, false);
    window.document.addEventListener("set-registration-end", registrationEnd);
    return () => {
      window.document.removeEventListener("set-registration-end", registrationEnd);
    };
  }, []);

  /* RETURN */
  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleSummary") ?? "",
          }}
        />

        <h2
          className="custom-stepper-text"
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:subtitleSummary") ?? "",
          }}
        />

        <div className="margin-top-small">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {i18next.t("message:resumePageDialSummary")}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <div>
                {recapVoices.map((x: IResumeIconLineProps, i: number) => {
                  return (
                    <ResumeIconLine
                      key={i}
                      title={x.title}
                      subtitle={x.subtitle}
                      warning={x.warning}
                      icon={x.icon}
                      price={x.price}
                    />
                  );
                })}
                {getCurrentSubscription?.isTrial && (
                  <div className="step-summary-resume-wrap">
                    <div className="step-summary-left-resume">
                      <div>{i18next.t("message:resumePageSummaryInfo")}</div>
                      <small style={{ opacity: "0.6" }}>
                        {i18next.t("message:resumePageFirstPayment") +
                          (() => {
                            return displayUTC0_ISODate(
                              moment().add(15, "days").toISOString(),
                              true
                            );
                          })()}
                      </small>
                    </div>

                    {/* <div className="step-summary-right-resume">
                      <StepSummaryTotalLabel
                        title={i18next.t("message:resumePageSummaryTotal")}
                        iva={calcVatCost(recapTotals.total, "extract", false)}
                        price={calcVatCost(recapTotals.total, "total", false)}
                      />

                      <StepSummaryTotalLabel
                        title={
                          !getCurrentSubscription.enabled &&
                          dateIsPast(getCurrentSubscription.validTo)
                            ? i18next.t("message:resumePageAlreadyPaid")
                            : i18next.t("message:resumePageSummaryTotalNext")
                        }
                        iva={calcVatCost(
                          recapTotals.totalLater,
                          "extract",
                          false
                        )}
                        price={calcVatCost(
                          recapTotals.totalLater,
                          "total",
                          false
                        )}
                      />
                    </div> */}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {vechiclesSummary.length > 0 && (
          <div className="margin-top-small">
            <div
              className="add-border"
              style={{
                padding: "1em",
                background: "var(--default-background-primary-body)",
              }}
            >
              {i18next.t("message:resumePageVeichleSummary")}
              <table className="recap-summary-veichle-table">
                <thead>
                  <tr>
                    <th>
                      <div></div>
                    </th>
                    <th>
                      <div>{i18next.t("message:resumePage_plate")}</div>
                    </th>
                    <th>
                      <div>{i18next.t("message:resumePage_veichle")}</div>
                    </th>
                    <th>
                      <div>{i18next.t("message:resumePage_owner")}</div>
                    </th>
                    <th>
                      <div>{i18next.t("message:resumePage_dial")}</div>
                    </th>
                    <th>
                      <div>{i18next.t("message:resumePage_cards")}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vechiclesSummary.map((x: IVehicleResume, i: number) => {
                    let icons = [];
                    for (let i = 0; i < x.numberOfFuelCard; i++) {
                      icons.push(
                        <img
                          key={i}
                          className="card-image-resume-img"
                          alt={getProperIcon("green")}
                          src={getProperIcon("green")}
                        />
                      );
                    }
                    for (let i = 0; i < x.numberOfTelepass; i++) {
                      icons.push(
                        <img
                          key={i}
                          className="card-image-resume-img"
                          alt={getProperIcon("orange")}
                          src={getProperIcon("orange")}
                        />
                      );
                    }

                    return (
                      <tr key={i}>
                        <td>
                          <div>{(i + 1).toString().padStart(2, "0")}</div>
                        </td>
                        <td>
                          <div>{formatPlate(x.vehicleLicensePlate)}</div>
                        </td>
                        <td>
                          <div>
                            {x.brandDescription && (
                              <span className="table-image-brand-wrapper">
                                <img
                                  className="image-responsive"
                                  onError={(e: any) =>
                                    (e.target.src =
                                      process.env.PUBLIC_URL +
                                      "/Images/brandVehicle/brand-not-found.svg")
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Images/brandVehicle/" +
                                    x.brandDescription.replace(" ", "_") +
                                    ".png"
                                  }
                                  title={x.brandDescription}
                                  alt={x.brandDescription}
                                />
                              </span>
                            )}
                            {(x.brandDescription ?? "-") +
                              " + " +
                              (x.modelDescription ?? "-")}
                          </div>
                        </td>
                        <td>
                          <div>
                            {(x.driverName ?? "-") +
                              " " +
                              (x.driverSurname ?? "-")}
                          </div>
                        </td>
                        <td>
                          <div>
                            {(x.contractTypeName ?? "-") +
                              " : " +
                              (x.contractTypeDescription ?? "-")}
                          </div>
                        </td>
                        <td>
                          <div>{icons}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <SmartModal
          modalUniqueId="Pending_Steps_Alert"
          title={i18next.t("message:missing_steps_alert_title")}
          modalInnerComponent={
            <div>
              {i18next.t("message:missing_steps_alert_text")}
              <ul>
                {missingStepsNames.map((x: string, i: number) => {
                  return <li key={i}>{x}</li>;
                })}
              </ul>
            </div>
          }
          buttons={[
            {
              text: i18next.t("navigation:close"),
              onClick: () => {
                DismissModal("Pending_Steps_Alert");
              },
            },
          ]}
        />
        <LoaderBackdrop
          loading={
            loadingPurchaseList ||
            loadingSummary ||
            loadingBillingLastInfo
          }
        />
      </div>
    </>
  );
};

export default StepSummary;
