import AppRoutes from "../Costants/AppRoutes";
import { IOrderGetSide, IOrderGetSideDetail } from "../Models/IOrder";

const tryLoadOrderData = (
  data: {
    order: IOrderGetSide;
    detailIndex: number;
  },
  mergeParameters: string[]
) => {
  // join info and details
  let default_: IOrderGetSideDetail = data.order.details[data.detailIndex];
  let mergedInfos: any = { ...data.order.info, ...default_ };

  // add contract fields
  mergedInfos["contract_data"] = {};
  for (let i = 0; i < data.order.contractTypeProperties.length; i++) {
    let prop = data.order.contractTypeProperties[i];
    mergedInfos["contract_data"][prop.propertyID] = prop.propertyValue;
  }

  let stateFromOrder: any = {};
  for (let i = 0; i < mergeParameters.length; i++) {
    stateFromOrder[mergeParameters[i]] = mergedInfos[mergeParameters[i]];
  }
  let final: any = mergeParameters.length === 0 ? mergedInfos : stateFromOrder;
  return final;
};

const tryLoadOrder = (requestedParams: string[]) => {
  const orderTransform = localStorage.getItem("order-transform");
  const pending:
    | {
        order: IOrderGetSide;
        detailIndex: number;
      }
    | undefined = orderTransform ? JSON.parse(orderTransform) : undefined;
  if (pending) {
    return tryLoadOrderData(pending, requestedParams);
  }
  return {};
};

export const clearOrderToVehicleCache = () => {
  localStorage.removeItem("order-transform");
};

export const fromOrderGet_contract = (initialStatus: any) => {
  if (!window.location.pathname.includes(AppRoutes.CREATE_VEHICLE_MARKER)) {
    return initialStatus;
  }

  if (!initialStatus) {
    initialStatus = {};
  }

  let data = tryLoadOrder([]);

  return {
    ...initialStatus,
    ...data["contract_data"],
    ...tryLoadOrder([
      "contractTypeID",
      "contractTypeName",
      "supplierID",
      "supplierName",
    ]),
  };
};

export const fromOrderGet_assignment = (initialStatus: any) => {
  if (!window.location.pathname.includes(AppRoutes.CREATE_VEHICLE_MARKER)) {
    return initialStatus;
  }
  if (!initialStatus) {
    initialStatus = {};
  }

  return {
    ...initialStatus,
    ...tryLoadOrder(["driverID", "companyID", "companyBusinessName"]),
  };
};
export const fromOrderGet_vehicle = (initialStatus: any) => {
  if (!window.location.pathname.includes(AppRoutes.CREATE_VEHICLE_MARKER)) {
    return initialStatus;
  }
  if (!initialStatus) {
    initialStatus = {};
  }

  return {
    ...initialStatus,
    ...tryLoadOrder([
      "brandCode",
      "brandName",
      "modelCode",
      "modelName",
      "fittingCode",
      "fittingName",
      "inProduction",
    ]),
  };
};
