import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IQuotation, IQuotationSolution } from "../../Models/IQuotation";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const QuotationController = {
  QuotationsGet: async (
    quotationId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_GET;
    request.port = "7237";
    if (quotationId) {
      request.url += "?quotationId=" + quotationId;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationCanBeServed: async (
    quotationType: string,
    address: string,
    searchDistance: number,
    RequestedToBrokerId: number | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_CAN_BE_SERVED;
    request.port = "7237";
    let params: string[] = [];
    if (quotationType) {
      params.push("quotationType=" + quotationType);
    }
    if (address) {
      params.push("address=" + address);
    }
    if (searchDistance) {
      params.push("searchDistance=" + searchDistance);
    }
    if (RequestedToBrokerId) {
      params.push("RequestedToBrokerId=" + RequestedToBrokerId);
    }
    request.url += params.length > 0 ? "?" + params.join("&") : "";
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsInsert: async (
    quotation: IQuotation,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_INSERT;
    request.method = "POST";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = quotation;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsUpdate: async (
    quotation: IQuotation,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_UPDATE;
    request.method = "PATCH";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = quotation;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsDelete: async (
    quotationId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_DELETE + "?quotationId=" + quotationId;
    request.method = "POST";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsSolutionGet: async (
    quotationSolutionId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_SOLUTION_GET;
    request.port = "7237";
    if (quotationSolutionId) {
      request.url += "?quotationSolutionId=" + quotationSolutionId;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsSolutionInsert: async (
    quotationSolution: IQuotationSolution,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_SOLUTION_INSERT;
    request.method = "POST";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = quotationSolution;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsSolutionUpdate: async (
    quotationSolution: IQuotationSolution,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.QUOTATION_SOLUTION_UPDATE;
    request.method = "PATCH";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = quotationSolution;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  QuotationsSolutionDelete: async (
    quotationSolutionId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url =
      ApiEndPoints.QUOTATION_SOLUTION_DELETE +
      "?quotationSolutionId=" +
      quotationSolutionId;
    request.port = "7237";
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default QuotationController;
