import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import { IUser } from "../../../../../../Models/IUser";
import {
  IVehicleCategory,
  IVehicleDataBrands,
  IVehicleDataFitting,
  IVehicleDataGet,
  IVehicleDataModels,
  IVehicleDataUpdateFull,
  IVehicleProvince,
  IVehicleRegion,
  IVehicleType,
} from "../../../../../../Models/IVehicles";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import AccordionContent from "../../../../../../Components/AccordionContent/AccordionContent";
import AttachementMainHandler from "../../../../../../Components/AttachmentMainHandler/AttachmentMainHandler";
import DeadlineMainHandler from "../../../../../../Components/DeadlineMainHandler/DeadlineMainHandler";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import MctcTableViewer from "../../../../../../Components/MctcTableViewer/MctcTableViewer";
import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import {
  dateFromUTC0_ISO,
  dateIsPast,
  dateToUTC0_ISO,
  displayUTC0_ISODate,
  padBlock,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import PrettyTable from "../../../../../../Components/PrettyTable/PrettyTable";
import ReimmatricolateVehicleModal from "../../../../../../Components/ReimmatricolateVehicleModal/ReimmatricolateVehicleModal";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import FormSupplier from "../../../../../../Components/StepSupplier/FormSupplier";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IMctcGet, IMctcGetRaw } from "../../../../../../Models/IMctcGet";
import { IOrderGetSide } from "../../../../../../Models/IOrder";
import { IRoadTaxPaymentsRequestInsert } from "../../../../../../Models/IRoadTaxPaymentsRequestInsert";
import { ISupplierGetSideList } from "../../../../../../Models/ISupplier";
import { IFuelType } from "../../../../../../Models/IVehicleFuelSupply";
import { IVehicleInspection } from "../../../../../../Models/IVehicleInspection";
import { IVehicleRoadTax } from "../../../../../../Models/IVehicleRoadTax";
import { IVehicleAdditionalRoadTax } from "../../../../../../Models/IVehicleRoadTaxAdditional";
import { IVehicleTest } from "../../../../../../Models/IVehicleTest";
import { BrandsActions } from "../../../../../../Reducers/Brands/BrandsAction";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { VehiclesActions } from "../../../../../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { fromOrderGet_vehicle } from "../../../../../../Utils/OrderToVehicle";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { validationErrorMessages } from "../../../../../../Utils/Validation";
import "./VehiclesPage_subPages_vehicleData.scss";
import { GetVehicleMinDate } from "../../../../DashboardPage";
import { base64ToFileBlob } from "../../../../../../Utils/FileToBase";
import ApiEndPoints from "../../../../../../Costants/ApiEndPoints";
import { IInfocarData } from "../../../../../../Models/IInfocarData";
import moment from "moment";
import { userRoleFits } from "../../../../../../Utils/Session";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DataGridWrap, { IDataGridColumnDef } from "../../../../../../Components/DataGridWrap/DataGridWrap";

export const validatePlate = (plate: string) => {
  if (plate === undefined || plate === null) {
    return "";
  }

  let valid1 = new RegExp("^[0-9]{2}[A-Za-z]{3}[0-9]{2}$").test(plate);
  let valid2 = new RegExp("^[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}$").test(plate);
  if (valid1 || valid2) {
    return undefined;
  }
  return i18next.t("form:validPlateError") ?? "";
};

const ArchiveModal = (props: { onArchive: () => void }) => {
  return (
    <SmartModal
      modalUniqueId="ArchiveModal"
      title={i18next.t("navigation:archive_item")}
      modalInnerComponent={
        <div>{i18next.t("navigation:archive_item_text")}</div>
      }
      buttons={[
        {
          text: i18next.t("navigation:close"),
          onClick: () => {
            DismissModal("ArchiveModal");
          },
        },
        {
          text: i18next.t("navigation:archive"),
          onClick: () => {
            props.onArchive();
            DismissModal("ArchiveModal");
          },
        },
      ]}
    />
  );
};

interface IResumeModalProps {
  title: string;
  headers: IDataGridColumnDef[];
  rows: any[];
}
export const ResumeModal = (props: IResumeModalProps) => {
  return (
    <SmartModal
      modalUniqueId="ResumeModal"
      title={props.title}
      modalInnerComponent={
        <DataGridWrap
          tableName={props.title}
          rows={props.rows}
          headers={props.headers}
        />
      }
      buttons={[
        {
          text: i18next.t("navigation:close"),
          onClick: () => {
            DismissModal("ResumeModal");
          },
        },
      ]}
    />
  );
};

var stateClone: any = {};
export const Form__vehicleData = (props: {
  vehicle: IVehicleDataGet | undefined;
  reducedForm?: boolean;
  onResetVehicleUpdate: (data: any) => void;
}) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const dispatch = useDispatch();
  const [state, setState_] = useState<any>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingDeadline, setProcessingDeadline] = useState<boolean>(false);
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingFuelTypeList, setLoadingFuelTypeList] =
    useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] =
    useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);
  const [canUserUseMctc, setCanUserUseMctc] = useState<boolean>(false);
  const [vehcileTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [vehcileCategories, setVehicleCategories] = useState<
    IVehicleCategory[]
  >([]);
  const [regions, setRegions] = useState<IVehicleRegion[]>([]);
  const [provinces, setProvinces] = useState<IVehicleProvince[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [firstLoadingDelay, setFirstLoadingDelay] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [loadingRegions, setLoadingRegions] = useState<boolean>(false);
  const [loadingInfocarData, setLoadingInfocarData] = useState<boolean>(false)
  const [loadingProvices, setLoadingProvices] = useState<boolean>(false);
  const [infocarData, setInfocarData] = useState<IInfocarData>();
  const [mctcUsage, setMctcUsage] = useState<boolean>(false);
  const [alreadySavedVehicle, setAlreadySavedVehicle] = useState<boolean>(false);

  const [loadingVehcileTypes, setLoadingVehcileTypes] =
    useState<boolean>(false);
  const [loadingVehcileCategories, setLoadingVehcileCategories] =
    useState<boolean>(false);
  const vehicleMctc: IMctcGet | any | undefined = useSelector(
    (state: GlobalState) => state.vehicles.vehicleMctc
  );

  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 500);
  };

  const setState = (state: any) => {
    stateClone = state;
    setState_(state);
  };

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  const newVehicleData = () => {
    setResetForm(true);
    setState(null);
    setTimeout(() => {
      setResetForm(false);
    }, 200);
  };

  const loadVehicleRegion = () => {
    setLoadingRegions(true);
    ApiService.VehicleDataController.VehicleDataGetVehicleRegion(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setRegions(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingRegions(false);
      }
    );
  };
  const loadVehicleProvince = () => {
    setLoadingProvices(true);
    ApiService.VehicleDataController.VehicleDataGetVehicleProvince(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setProvinces(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingProvices(false);
      }
    );
  };

  const loadVehicleType = () => {
    setLoadingVehcileTypes(true);
    ApiService.VehicleDataController.VehicleDataGetVehicleType(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehicleTypes(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehcileTypes(false);
      }
    );
  };
  const loadVehicleCategory = () => {
    setLoadingVehcileCategories(true);
    ApiService.VehicleDataController.VehicleDataGetVehicleCategory(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehicleCategories(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehcileCategories(false);
      }
    );
  };

  const loadInfocarData = (fittingCode: string) => {
    setLoadingInfocarData(true)
    ApiService.InfoCarController.GetInfoCarData(
      fittingCode,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setInfocarData(response.payload)
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingInfocarData(false)
      }
    );
  };

  const ReprocessMctcData = (raw: IMctcGetRaw) => {
    return {
      raw: raw,
      forForm: {
        vehicleLicensePlate: raw.datiBaseVeicolo.targaVeicolo,
        brandCode: brandsList.find(
          (el: IVehicleDataBrands) =>
            el.brandDescription === raw.datiBaseVeicolo.marcaVeicolo
        )?.brandCode,
        modelCode: modelList.find((el: IVehicleDataModels) => el.modelDescription === raw.datiTecniciVeicolo.denominazioneCommercialeVeicolo)?.modelCode,
        vehicleChassis: raw.datiBaseVeicolo.numeroTelaio,
        vehicleFirstRegistrationDT: dateToUTC0_ISO(
          new Date (raw.datiBaseVeicolo.dataPrimaImmatricolazione)
        ),
        vehicleLastInspectionDT: raw.datiUltimaRevisione !== null ? dateToUTC0_ISO(
          new Date (raw.datiUltimaRevisione.dataUltimaRevisione)
        ) : null,

      },
    };
  };

  const getFuelType = () => {
    setLoadingFuelTypeList(true);
    ApiService.VehicleFuelSupplyController.VehicleFuelTypeGet(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setFuelTypes(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFuelTypeList(false);
      }
    );
  };

  const VehicleMctc = () => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleMctc(
      state?.vehicleLicensePlate,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let mctcData: any = ReprocessMctcData(response.payload);
            if(mctcData.raw.pdf){
              uploadMctcFile(mctcData.raw.pdf, mctcData.forForm.vehicleLicensePlate, JSON.stringify(mctcData.raw));
            }
            DismissModal("MCTCModalAccept");
            dispatch(VehiclesActions.setVehicleMctc(mctcData));
        } else {
          if(response.raw.status === 400){
            ToastMessage(response.payload.title, "error");
          }else{
            ToastMessage(response.error, "error");
          }
        }
        setProcessing(false);
      }
    );
  };

  const CheckCanUserUseMctc = () => {
    ApiService.VehicleDataController.VehicleCheckCanUserUseMctc(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCanUserUseMctc(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const uploadMctcFile = (file: string, fileName: string, mctcAttachmentJSON: string) => {
    ApiService.UploadController.UploadFile(
      "attachmentmctc",
      [base64ToFileBlob(file, fileName, "application/pdf")],
      (response: IAPIResponse) => {
        if (response.error === null) {
          setProcessing(true);
          let data: any = {
            attachmentName: fileName,
            attachmentDescription: "",
            attachmentURL: response.payload.fileReference,
            mctcAttachmentJSON: mctcAttachmentJSON,
          }
          ApiService.AttachmentController.InsertAttachment(
            ApiEndPoints.MCTC_ATTACHMENT_INSERT,
            data,
            (response: IAPIResponse) => {
              if (response.error === null) {
                if(state?.vehicleID > 0){
                  let parseData = { ...state };
                  let mctcData: IMctcGetRaw = JSON.parse(mctcAttachmentJSON);
                  parseData["vehicleLastInspectionKm"] = Number(
                  parseData["vehicleLastInspectionKm"]
                  );
                  parseData["inProduction"] = parseData["inProduction"] == 1 ? true : false;
                  if(mctcData){
                    parseData["euroDirective"] =  mctcData.datiBaseVeicolo.classeEURO;
                    parseData["cO2Emission"] = mctcData.datiTecniciVeicolo.cO2;
                    parseData["power"] = mctcData.datiTecniciVeicolo.kw;
                    parseData["engineCapacity"] = mctcData.datiTecniciVeicolo.cilindrataInCentimetriCubi;
                    parseData["horses"] = mctcData.datiTecniciVeicolo.cavalliFiscali;
                    parseData["vehicleChassis"] = mctcData.datiBaseVeicolo.numeroTelaio;
                  }
                  updateVehicleData(parseData);
                }
              } else if (response.raw.status === 400) {
                ToastMessage(i18next.t("error:MISSING_FIELDS"), "error");
              } else {
                ToastMessage(response.error, "error");
              }
      
              setProcessing(false);
            }
          );

          ToastMessage(i18next.t("message:upload_success"), "success");
        } else {
          ToastMessage(i18next.t("error:could_not_upload"), "error");
        }
      }
    );
  };

  const loadBrandsList = (inProduction: number) => {
    setLoadingBrandsList(true);
    ApiService.VehicleDataController.VehicleDataGetBrands(
      inProduction == 1 ? true : false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.vehicle && props.vehicle.customBrandName) {
            response.payload.push({
              brandCode: props.vehicle.customBrandName,
              brandDescription: props.vehicle.customBrandName,
            });
          }
          setBrandsList(response.payload);
          dispatch(BrandsActions.setBrandsList(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingBrandsList(false);
      }
    );
  };

  const loadModelsList = (brandCode: string, inProduction: number) => {
    setLoadingModelsList(true);
    ApiService.VehicleDataController.VehicleDataGetModels(
      brandCode,
      inProduction == 1 ? true : false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.vehicle && props.vehicle.customModelName) {
            response.payload.push({
              modelCode: props.vehicle.customModelName,
              modelDescription: props.vehicle.customModelName,
            });
          }
          setModelsList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingModelsList(false);
      }
    );
  };

  const loadFittingList = (
    brandCode: string,
    modelCode: string,
    inProduction: number
  ) => {
    setLoadingFittingsList(true);
    ApiService.VehicleDataController.VehicleDataGetFittings(
      brandCode,
      modelCode,
      inProduction == 1 ? true : false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.vehicle && props.vehicle.customFittingName) {
            response.payload.push({
              fittingCode: props.vehicle.customFittingName,
              fittingDescription: props.vehicle.customFittingName,
            });
          }
          setFittingsList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFittingsList(false);
      }
    );
  };

  const markOrderAsCompleted = (vehicle: IVehicleDataGet) => {
    let order: { detailIndex: number; order: IOrderGetSide } = JSON.parse(
      localStorage.getItem("order-transform") ?? "{}"
    );
    let data: IOrderGetSide = { ...order.order };
    let vehicleOrderDetailID: number =
      data.details[order.detailIndex].vehicleOrderDetailID;
    ApiService.OrderController.OrderMarkDetailForVehicle(
      vehicleOrderDetailID,
      vehicle.vehicleID
    );
  };

  const insertVehicleData = (data: IVehicleDataUpdateFull) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataInsert(
      data,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          // if vehicle is from order
          if (localStorage.getItem("order-transform")) {
            markOrderAsCompleted(response.payload);
          }

          ToastMessage(
            i18next.t("message:vehicleDataSucceffulAdded"),
            "success"
          );
          props.onResetVehicleUpdate(response.payload);
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessing(false);
      }
    );
  };

  const updateVehicleData = (data: IVehicleDataUpdateFull) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataUpdate(
      data,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:vehicleDataSucceffulUpdated"),
            "success"
          );
          props.onResetVehicleUpdate(data);
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessing(false);
      }
    );
  };

  const deleteVehicleData = (vehicleID: number) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataDelete(
      vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:vehicleDataSucceffulDeleted"),
            "success"
          );
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const multiformInputs = () => {
    if (refreshForm) {
      return [];
    }

    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push(
      {
        width: 20,
        type: "text",
        name: "vehicleLicensePlate",
        disabled: props.vehicle?.vehicleLicensePlate !== undefined,
        defaultValue: state?.vehicleLicensePlate ?? props.vehicle?.vehicleLicensePlate,
        inputProps: {
          maxLength: 20,
          style: { textTransform: "uppercase" },
        },
        errorText: state
          ? validatePlate(state?.vehicleLicensePlate)
          : undefined,
        label: i18next.t("form:numberPlateLicense") as string,
        placeholder: "(A)",
        required: true,
      },
      {
        width: 15,
        type: "custom",
        name: "MCTC",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={!canUserUseMctc || disposed > 0}
              variant="contained"
              onClick={() => {
                if(vehicleMctc && props.vehicle?.vehicleLicensePlate !== undefined){
                  SummonModal("MCTCModalAccept");
                }else{
                  if (state?.vehicleLicensePlate) {
                    VehicleMctc();
                  }
                }
              }}
            >
              MCTC
            </Button>
          </div>
        ),
      }
    );

    if (!props.reducedForm) {
      multiformInputs.push({
        width: 15,
        type: "custom",
        ignore: props.vehicle?.vehicleLicensePlate === undefined,
        name: "reimmatricola",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={disposed > 0}
              variant="contained"
              onClick={() => {
                SummonModal("ReimmatricolateVehicleModal");
              }}
            >
              {(i18next.t("form:reimmatricolate") ?? "").toUpperCase()}
            </Button>
          </div>
        ),
      });
    }

    multiformInputs.push(
      {
        width: props.reducedForm ? 30 : 25,
        type: "date",
        required: true,
        disabled: props.vehicle?.vehicleLicensePlate !== undefined,
        name: "vehicleFirstRegistrationDT",
        defaultValue: state?.vehicleFirstRegistrationDT,
        disableFuture: true,
        label: String(i18next.t("form:firstWarrantyCheck")),
      },
      {
        width: props.reducedForm ? 35 : 25,
        type: "text",
        name: "vehicleSatelliteDeviceNumber",
        defaultValue: state?.vehicleSatelliteDeviceNumber,
        label: i18next.t("form:numberSATVehicle") as string,
      }
    );

    multiformInputs.push(
      {
        width: 100,
        type: "custom",
        name: "banner",
        element: (
          <Alert severity="info">
            {i18next.t("navigation:vehicleDataMCTCInfoBanner")}
          </Alert>
        )
      }
    );

    if (!props.reducedForm) {
      multiformInputs.push({
        type: "radio",
        name: "inProduction",
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        defaultValue:
          state?.inProduction !== undefined && state?.inProduction !== null
            ? state.inProduction
              ? 1
              : 0
            : 1,
        width: loggedUser?.managedByBroker ? 50 : 100,
        label: i18next.t("form:changeHistoricallyVehicle") as string,
        options: [
          {
            key: 1,
            text: i18next.t("form:vehicleInProdution"),
          },
          {
            key: 0,
            text: i18next.t("form:vehicleNotMoreInProdution"),
          },
        ],
      });
    }

    if (loggedUser?.managedByBroker) {
      multiformInputs.push({
        width: 50,
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        type: "switch",
        name: "managedByBroker",
        defaultValue: state?.managedByBroker,
        label: i18next.t("form:managedByBroker").toString(),
      });
    }

    multiformInputs.push(
      {
        width: 25,
        type: "select",
        name: "brandCode",
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        inputProps: { maxLength: 6 },
        defaultValue: state?.customBrandName
          ? state?.customBrandName
          : state?.brandCode,
        required: true,
        label: i18next.t("form:orderBrand") as string,
        placeholder: "(D,1)",
        options: brandsList.map((item: IVehicleDataBrands) => {
          return {
            key: item.brandCode,
            text: item.brandDescription,
          };
        }),
      },
      {
        width: 25,
        type: "select",
        name: "modelCode",
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        required: true,
        inputProps: { maxLength: 6 },
        defaultValue: state?.customModelName
          ? state?.customModelName
          : state?.modelCode,
        label: i18next.t("form:orderModel") as string,
        placeholder: "(D,2)",
        options: modelList.map((item: IVehicleDataModels) => {
          return {
            key: item.modelCode,
            text: item.modelDescription,
          };
        }),
      },
      {
        width: 25,
        type: "select",
        name: "fittingCode",
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        required: true,
        defaultValue: state?.customFittingName
          ? state?.customFittingName
          : state?.fittingCode,
        inputProps: { maxLength: 6 },
        label: i18next.t("form:orderEquipment") as string,
        placeholder: "(D,3)",
        options: fittingList.map((item: IVehicleDataFitting) => {
          return {
            key: item.fittingCode,
            text: item.fittingDescription,
          };
        }),
      },
      {
        width: 25,
        type: "select",
        name: "fuelTypeID",
        idcontainer: "boxed-form",
        customContainerClass: "boxed-form-section-white",
        required: true,
        defaultValue: state?.fuelTypeID ? state?.fuelTypeID : infocarData?.fuelTypeID,
        label: i18next.t("form:carDrainPower") as string,
        options: fuelTypes.map((x: IFuelType) => {
          return {
            key: x.fuelTypeID,
            text: x.fuelTypeDescription,
          };
        }),
      }
    );

    if (!props.reducedForm) {
      multiformInputs.push(
        {
          width: 25,
          type: "select",
          name: "vehicleTypeID",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          options: vehcileTypes.map((x: IVehicleType) => {
            return {
              key: x.vehicleTypeID,
              text: x.vehicleTypeName ?? "(" + x.vehicleTypeID + ")",
            };
          }),
          defaultValue: state?.vehicleTypeID,
          label: i18next.t("form:carType").toString(),
        },
        {
          width: 25,
          type: "select",
          name: "vehicleCategoryID",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          options: vehcileCategories
            .filter(
              (x: IVehicleCategory) =>
                state &&
                state?.vehicleTypeID &&
                x.vehicleTypeID === state?.vehicleTypeID
            )
            .map((x: IVehicleCategory) => {
              return {
                key: x.vehicleCategoryID,
                text: x.vehicleCategoryName ?? "(" + x.vehicleCategoryID + ")",
              };
            }),
          defaultValue: state?.vehicleCategoryID,
          label: i18next.t("form:carCategory").toString(),
        },

        {
          width: 25,
          type: "select",
          name: "regRegionID",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          required: true,
          defaultValue: state?.regRegionID,
          options: regions.map((x: IVehicleRegion) => {
            return {
              key: x.regionID,
              text: x.regionName,
            };
          }),
          label: i18next.t("form:regionRegister").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "vehicleChassis",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.vehicleChassis,
          required: true,
          inputProps: {
            maxLength: 17,
            style: { textTransform: "uppercase" },
          },
          label: i18next.t("form:numberChassis").toString(),
        },
        {
          name: "style",
          width: 100,
          type: "custom",
          element: <hr />,
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
        },
        {
          width: 25,
          type: "select",
          name: "regProvinceID",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.regProvinceID,
          options: provinces
            .filter(
              (x: IVehicleProvince) =>
                state && state.regRegionID && state.regRegionID === x.regionID
            )
            .map((x: IVehicleProvince) => {
              return {
                key: x.provinceID,
                text: x.provinceName,
              };
            }),
          label: i18next.t("form:provinceRegister").toString(),
        },
        {
          width: 12.5,
          type: "number",
          name: "tankCapacity",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.tankCapacity ? state?.tankCapacity : infocarData?.tankCapacity,
          label: i18next.t("form:tankCapacity").toString(),
          inputAdornament: {
            adornament: infocarData?.tankCapacity && (props.vehicle?.tankCapacity === undefined || props.vehicle?.tankCapacity === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.tankCapacity && (props.vehicle?.tankCapacity === undefined || props.vehicle?.tankCapacity === null) ? "missing-data-warning" : "",
          
        },
        {
          width: 12.5,
          type: "number",
          name: "tankCapacitySecondary",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.tankCapacitySecondary ? state?.tankCapacitySecondary : infocarData?.tankCapacity2,
          inputAdornament: {
            adornament: infocarData?.tankCapacity2 && (props.vehicle?.tankCapacitySecondary === undefined || props.vehicle?.tankCapacitySecondary === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.tankCapacity2 && (props.vehicle?.tankCapacitySecondary === undefined || props.vehicle?.tankCapacitySecondary === null) ? "missing-data-warning" : "",
          label: i18next.t("form:tankCapacitySecondary").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "euroDirective",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.euroDirective,
          label: i18next.t("form:euroDirective").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "cO2Emission",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.cO2Emission ? state?.cO2Emission : infocarData?.cO2Emission,
          inputAdornament: {
            adornament: infocarData?.cO2Emission && (props.vehicle?.cO2Emission === undefined || props.vehicle?.cO2Emission === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.cO2Emission && (props.vehicle?.cO2Emission === undefined || props.vehicle?.cO2Emission === null) ? "missing-data-warning" : "",
          label: i18next.t("form:co2Emission").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "power",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.power ? state?.power : infocarData?.power,
          inputAdornament: {
            adornament: infocarData?.power && (props.vehicle?.power === undefined || props.vehicle?.power === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.power && (props.vehicle?.power === undefined || props.vehicle?.power === null) ? "missing-data-warning" : "",
          label: i18next.t("form:carPower").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "engineCapacity",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.engineCapacity ? state?.engineCapacity : infocarData?.engineCapacity,
          inputAdornament: {
            adornament: infocarData?.engineCapacity && (props.vehicle?.engineCapacity === undefined || props.vehicle?.engineCapacity === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.engineCapacity && (props.vehicle?.engineCapacity === undefined || props.vehicle?.engineCapacity === null) ? "missing-data-warning" : "",
          label: i18next.t("form:carCilinder").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "horses",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.horses ? state?.horses : infocarData?.horses,
          inputAdornament: {
            adornament: infocarData?.horses && (props.vehicle?.horses === undefined || props.vehicle?.horses === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.horses && (props.vehicle?.horses === undefined || props.vehicle?.horses === null) ? "missing-data-warning" : "",
          label: i18next.t("form:carHorser").toString(),
        },
        {
          width: 25,
          type: "text",
          name: "doors",
          idcontainer: "boxed-form",
          customContainerClass: "boxed-form-section-white",
          defaultValue: state?.doors ? state?.doors : infocarData?.doors,
          inputAdornament: {
            adornament: infocarData?.doors && (props.vehicle?.doors === undefined || props.vehicle?.doors === null) ? <WarningAmberIcon titleAccess={i18next.t("navigation:missingDataWarningInfoCar") as string} color="warning" /> : "",
          },
          className: infocarData?.doors && (props.vehicle?.doors === undefined || props.vehicle?.doors === null) ? "missing-data-warning" : "",
          
          label: i18next.t("form:carDoors").toString(),
        }
      );
    }

    return multiformInputs;
  };

  useEffect(() => {
    loadModelsList(state?.brandCode, state?.inProduction);
  }, [state?.brandCode, state?.inProduction]);

  useEffect(() => {
    loadFittingList(state?.brandCode, state?.modelCode, state?.inProduction);
  }, [state?.brandCode, state?.modelCode, state?.inProduction]);

  useEffect(() => {
    if (state?.inProduction !== undefined && state?.inProduction !== null) {
      loadBrandsList(state?.inProduction);
    } else {
      setBrandsList([]);
      setModelsList([]);
      setFittingsList([]);
    }
    getFuelType();
  }, [state?.inProduction]);

  useEffect(() => {
    if (vehicleMctc && vehicleMctc.forForm !== undefined) {
      let cleanState: any = {};
      let keys = Object.keys(vehicleMctc.forForm);
      for (let i = 0; i < keys.length; i++) {
        let val: any = vehicleMctc.forForm[keys[i]];
        if (val !== null && val !== undefined) {
          cleanState[keys[i]] = val;
        }
      }
      setState({ ...state, ...cleanState });
      requestFormRefresh();
    }
  }, [vehicleMctc]);

  useEffect(() => {
    CheckCanUserUseMctc();
    loadVehicleType();
    loadVehicleCategory();
    loadVehicleRegion();
    loadVehicleProvince();
    if(props.vehicle?.fittingCode){
      loadInfocarData(props.vehicle?.fittingCode)
    }

    setState(fromOrderGet_vehicle(props.vehicle ?? {}));

    setTimeout(() => {
      setFirstLoadingDelay(true);
    }, 1000);

    console.log("vehicle", props.vehicle);
    return () => {
      stateClone = {};
    };
  }, []);

  const globalLoading =
    loadingBrandsList ||
    loadingModelsList ||
    loadingFittingsList ||
    loadingVehcileTypes ||
    loadingVehcileCategories ||
    loadingProvices ||
    loadingRegions ||
    loadingFuelTypeList ||
    loadingInfocarData;

  useEffect(() => {
    if (!globalLoading && firstLoading && firstLoadingDelay) {
      setFirstLoading(false);
    }
  }, [globalLoading, firstLoadingDelay]);

  return (
    <div>
      {vehicleMctc && (
        <div className="padding-small">
          <AccordionContent
            title={i18next.t("navigation:showMCTCDATA")}
            content={<MctcTableViewer vehicleInfoMctc={vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw : vehicleMctc} />}
          />
        </div>
      )}

      <ReimmatricolateVehicleModal
        onDone={(data: any) => {
          setState({
            ...state,
            vehicleLicensePlate: data.vehicleRegistrationDT,
          });
          props.onResetVehicleUpdate({});
        }}
      />
      {!firstLoading && (
        <MultiForm
          disableForm={disposed > 0}
          formId={"StepVehicles"}
          suppressLayout
          formTitle={""}
          inputs={multiformInputs()}
          onChange={async (data: any) => {
            setState(mergeObjects(data));
          }}
          suppressSubmit={true}
          onSubmit={(data: any) => {
            if (validatePlate(state?.vehicleLicensePlate)) {
              return;
            }
            let parseData = { ...state };
            let dataK: string[] = Object.keys(data);
            for (let i = 0; i < dataK.length; i++) {
              if (data[dataK[i]] !== null) {
                parseData[dataK[i]] = data[dataK[i]];
              }
            }

            delete parseData["MCTC"];
            parseData["vehicleLastInspectionKm"] = Number(
              parseData["vehicleLastInspectionKm"]
            );

            parseData["inProduction"] =
              parseData["inProduction"] == 1 ? true : false;

            if (state?.vehicleID) {
              parseData["vehicleTypeID"] = state?.vehicleTypeID;
              parseData["vehicleID"] = state?.vehicleID;
              if (props.vehicle) {
                if (
                  parseData["brandCode"] !== props.vehicle["customBrandName"]
                ) {
                  delete parseData["customBrandName"];
                } else {
                  delete parseData["brandCode"];
                  parseData["customBrandName"] =
                    props.vehicle["customBrandName"];
                }
              }
              if (props.vehicle) {
                if (
                  parseData["modelCode"] !== props.vehicle["customModelName"]
                ) {
                  delete parseData["customModelName"];
                } else {
                  delete parseData["modelCode"];
                  parseData["customModelName"] =
                    props.vehicle["customModelName"];
                }
              }
              if (props.vehicle) {
                if (
                  parseData["fittingCode"] !==
                  props.vehicle["customFittingName"]
                ) {
                  delete parseData["customFittingName"];
                } else {
                  delete parseData["fittingCode"];
                  parseData["customFittingName"] =
                    props.vehicle["customFittingName"];
                }
              }
              updateVehicleData(parseData);
            } else {
              parseData["vehicleTypeID"] = 1;
              if(vehicleMctc){
                parseData["euroDirective"] =  vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiBaseVeicolo.classeEURO : vehicleMctc.datiTecniciVeicolo.classeEURO;
                parseData["cO2Emission"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cO2 : vehicleMctc.datiTecniciVeicolo.cO2;
                parseData["power"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.kw : vehicleMctc.datiTecniciVeicolo.kw;
                parseData["engineCapacity"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cilindrataInCentimetriCubi : vehicleMctc.datiTecniciVeicolo.cilindrataInCentimetriCubi;
                parseData["horses"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cavalliFiscali : vehicleMctc.datiTecniciVeicolo.cavalliFiscali;
              }
              insertVehicleData(parseData);
            }
          }}
        />
      )}
      <div className="padding-small">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            variant="contained"
            disabled={processing || processingDeadline || disposed > 0}
            onClick={() => {
              triggerFormValidation("StepVehicles");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveVehicle")}
          </Button>
        </div>
      </div>

      {!props.reducedForm && props.vehicle?.vehicleID && props.vehicle?.attachmentCode !== undefined ?
        <AttachmentTab vId={props.vehicle.vehicleID} tab={1} attachmentCode={props.vehicle?.attachmentCode} />
      :
        <div className="full-width">
          <div className="attachments-multi-form-title-wrap">
            {i18next.t("generic:attachment_enable")}
          </div>
        </div>
      }
      <SmartModal 
        modalUniqueId={"MCTCModalAccept"}
        title={i18next.t("navigation:MCTCModalAccepTitle")}
        modalInnerComponent={
          <p>{i18next.t("navigation:MCTCModalAcceptDescription")}</p>
        }
        onAccept={() => {
          VehicleMctc();
        }}
        onReject={() => {
          DismissModal("MCTCModalAccept");
        }}
      />
      <LoaderBackdrop loading={globalLoading || processing || resetForm} />
    </div>
  );
};

export const Form__Bollo = (props: {
  vehicle: IVehicleDataGet;
  isNewBollo?: boolean;
}) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const [items, setItems] = useState<IVehicleRoadTax[]>([]);
  const [active, setActive] = useState<IVehicleRoadTax>();
  const [storeBollo, setStoreBollo] = useState<IVehicleRoadTax>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [roadTaxRowsSelected, setRoadTaxRowsSelected] = useState<any[]>([]);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const getRoadTaxes = () => {
    setLoading(true);
    ApiService.VehicleRoadTaxController.VehicleRoadTaxGet(
      null,
      props.vehicle.vehicleID,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let res: IVehicleRoadTax[] = response.payload;
          setItems(res);
          let current = res.filter((x: IVehicleRoadTax) => x.stored === false);
          if (current.length > 0) {
            if (props.isNewBollo) {
              setStoreBollo(current[0]);
            } else {
              setActive(current[0]);
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const resetForm = () => {
    setItems(
      items.map((x: IVehicleRoadTax) => {
        x.stored = true;
        return x;
      })
    );
    setLoading(true);
    setActive(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const updateRoadTax = (
    data: IVehicleRoadTax,
    newFormOnSuccess = false,
    dataInsert?: IVehicleRoadTax
  ) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicle.vehicleID;
    ApiService.VehicleRoadTaxController.VehicleRoadTaxUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.isNewBollo) {
            if (dataInsert) {
              insertRoadTax(dataInsert);
            }
          } else {
            if (newFormOnSuccess) {
              resetForm();
            } else {
              setActive(data);
            }
          }
          ToastMessage(i18next.t("message:roadtaxUdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertRoadTax = (data: IVehicleRoadTax) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicle.vehicleID;
    ApiService.VehicleRoadTaxController.VehicleRoadTaxInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          data["roadTaxID"] = response.payload;
          setActive(response.payload);
          ToastMessage(i18next.t("message:roadtaxInsert"), "success");
          DismissModal("ModalBollo");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteRoadTax = (data: IVehicleRoadTax) => {
    setProcessing(true);
    ApiService.VehicleRoadTaxController.VehicleRoadTaxDelete(
      data.roadTaxID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:roadtaxDeleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const InsertRoadTaxPaymentsRequest = (
    body: IRoadTaxPaymentsRequestInsert
  ) => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsRequestInsert(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:RoadTaxRequestSend"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  useEffect(() => {
    getRoadTaxes();
  }, []);

  const getTot = () => {
    if (active) {
      return (+active?.penalties + +active?.interests + +active?.fee + +active?.exemptions).toLocaleString();
    }
    return 0;
  };

  return (
    <div style={{ paddingTop: "1em" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "3em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <ArchiveModal
            onArchive={() => {
              if (active) {
                active.stored = true;
                updateRoadTax({ ...active }, true);
              }
            }}
          />
          <ResumeModal
            title={i18next.t("navigation:tabs_vehicle_data_bollo_hist")}
            headers={[
              {
                field: "startDT",
                fieldOriginal: "startDT",
                headerName: i18next.t("form:vehciledata_forms_startdt"),
              },
              {
                field: "endDT",
                fieldOriginal: "endDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_enddt"),
              }, 
              {
                field: "validity",
                headerName: i18next.t("form:vehciledata_forms_validita"),
              },
              {
                field: "fee",
                headerName: i18next.t("form:vehciledata_forms_tassa"),
              },
              {
                field: "interests",
                headerName: i18next.t("form:vehciledata_forms_interessi"),
              },
              {
                field: "penalties",
                headerName: i18next.t("form:vehciledata_forms_sansioni"),
              },
              {
                field: "totAmount",
                headerName: i18next.t("form:vehciledata_forms_totbollo"),
              },
              {
                field: "exemptions",
                headerName: i18next.t("form:vehciledata_forms_esansioni"),
              },
              {
                field: "paymentCode",
                headerName: i18next.t("form:vehciledata_forms_id_pay"),
              },
              {
                field: "paymentDT",
                fieldOriginal: "paymentDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_id_datepay"),
              },
            ]}
            rows={items
              .filter((x: IVehicleRoadTax) => x.stored === true)
              .map((x: IVehicleRoadTax) => {
                return {
                  startDT: displayUTC0_ISODate(x.startDT, true) ?? "-",
                  startDTOriginal: x.startDT,
                  endDT: displayUTC0_ISODate(x.endDT, true) ?? "-",
                  endDTOriginal: x.endDT,
                  validity: x.validity,
                  fee: x.fee,
                  interests: x.interests,
                  penalties: x.penalties,
                  totAmount: x.totAmount,
                  exemptions: x.exemptions,
                  paymentCode: x.paymentCode,
                  paymentDT: displayUTC0_ISODate(x.paymentDT, true) ?? "-",
                  paymentDTOriginal: x.paymentDT,
                };
              })}
          />

          <MultiForm
            disableForm={disposed > 0}
            suppressLayout
            formTitle={""}
            formId="bollo-form-unique"
            suppressSubmit
            onChange={(data: any) => {
              if(data["startDT"] !== "" && data["validity"] >= 0){
                data["endDT"] = dateToUTC0_ISO(moment(data["startDT"])
                .add({ months: data["validity"] > 1 ? +(data["validity"] - 1) : +data["validity"]}).endOf('month').toDate());
              }
              
              setActive({ ...active, ...data });
            }}
            onSubmit={(data: any) => {
              let data_ = { ...active, ...data };
              delete data_["totAmount_"];
              data_["exemptions"] = +data_["exemptions"];
              data_["fee"] = +data_["fee"];
              data_["interests"] = +data_["interests"];
              data_["penalties"] = +data_["penalties"];
              data_["endDT"] = dateFromUTC0_ISO(data_["endDT"]);
              data_["paymentDT"] = dateFromUTC0_ISO(data_["paymentDT"]);
              data_["endDT"] =  `${data_["endDT"].getFullYear()}-${padBlock(
                data_["endDT"].getMonth() + 1
              )}-${padBlock(data_["endDT"].getDate())}T${padBlock(data_["endDT"].getHours())}:${padBlock(
                data_["endDT"].getMinutes()
              )}:${padBlock(data_["endDT"].getSeconds())}`;
              data_["paymentDT"] =  `${data_["paymentDT"].getFullYear()}-${padBlock(
                data_["paymentDT"].getMonth() + 1
              )}-${padBlock(data_["paymentDT"].getDate())}T${padBlock(data_["paymentDT"].getHours())}:${padBlock(
                data_["paymentDT"].getMinutes()
              )}:${padBlock(data_["paymentDT"].getSeconds())}`;
              if (data_.roadTaxID !== undefined) {
                updateRoadTax(data_);
              } else {
                if (props.isNewBollo) {
                  if (storeBollo) {
                    storeBollo.stored = true;
                    updateRoadTax({ ...storeBollo }, false, data_);
                  } else {
                    insertRoadTax(data_);
                  }
                } else {
                  insertRoadTax(data_);
                }
              }
            }}
            inputs={[
              {
                width: 33,
                type: "date",
                name: "startDT",
                defaultValue: active?.startDT,
                minDate: GetVehicleMinDate(props.vehicle),
                required: true,
                label: i18next.t("form:vehciledata_forms_startdt").toString(),
              },
              {
                width: 33,
                type: "number",
                name: "validity",
                inputProps: {
                  min: 0,
                  step: 1,
                },
                defaultValue: active?.validity,
                required: true,
                label: i18next.t("form:vehciledata_forms_validita").toString(),
              },
              {
                width: 33,
                type: "date",
                name: "endDT",
                minDate: active ? dayjs(active.startDT) : undefined,
                currentValue: active?.endDT,
                required: true,
                disabled: true,
                label: i18next.t("form:vehciledata_forms_enddt").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "fee",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.fee,
                required: true,
                label: i18next.t("form:vehciledata_forms_tassa").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "interests",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.interests,
                required: true,
                label: i18next.t("form:vehciledata_forms_interessi").toString(),
              },
              {
                width: 25,
                type: "number",
                name: "penalties",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.penalties,
                required: true,
                label: i18next.t("form:vehciledata_forms_sansioni").toString(),
              },
              {
                width: 24,
                type: "text",
                name: "totAmount_",
                disabled: true,
                currentValue: getTot(),
                inputAdornament: { adornament: "€" },
                required: true,
                label: i18next.t("form:vehciledata_forms_totbollo").toString(),
              },
              {
                width: 33,
                type: "number",
                name: "exemptions",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.exemptions,
                required: true,
                label: i18next.t("form:vehciledata_forms_esansioni").toString(),
              },
              {
                width: 33,
                type: "text",
                name: "paymentCode",
                defaultValue: active?.paymentCode,
                inputProps: {
                  maxLength: 50,
                },
                required: true,
                label: i18next.t("form:vehciledata_forms_id_pay").toString(),
              },
              {
                width: 33,
                type: "date",
                name: "paymentDT",
                defaultValue: active?.paymentDT ?? new Date().toISOString(),
                required: true,
                label: i18next
                  .t("form:vehciledata_forms_id_datepay")
                  .toString(),
              },
              {
                width: 100,
                type: "switch",
                name: "roadTaxPaymentAddon",
                defaultValue: active?.roadTaxPaymentAddon,
                placeholder: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18next.t("form:switchPayVehicle") ?? "",
                    }}
                  />
                ),
              },
            ]}
          />
           {active && active?.roadTaxID && (!processing || processing) ?
            <AttachmentTab vId={props.vehicle.vehicleID} tab={2} attachmentCode={active?.attachmentCode} />
          :
            <div className="full-width">
              <div className="attachments-multi-form-title-wrap">
                {i18next.t("generic:attachment_enable")}
              </div>
            </div>
          }
          <div className="display-flex-end padding-x-extrasmall gap-small ">
            <Button
              variant="outlined"
              disabled={processing || !active?.roadTaxID || disposed > 0}
              onClick={() => {
                SummonModal("ArchiveModal");
              }}
            >
              {i18next.t("navigation:archive")}
            </Button>
            <Button
              variant="outlined"
              disabled={processing}
              onClick={() => {
                SummonModal("ResumeModal");
              }}
            >
              {i18next.t("navigation:tabs_vehicle_data_bollo_hist")}
            </Button>
            {active && active.roadTaxPaymentAddon && (
              <Button
                variant="outlined"
                disabled={processing || disposed > 0 || active.roadTaxID === undefined}
                onClick={() => {
                  if (active) {
                    if (active.roadTaxPaymentAddon) {
                      setRoadTaxRowsSelected([{ roadTaxID: active.roadTaxID }]);
                      SummonModal("confirmPayment");
                    }
                  }
                }}
              >
                {i18next.t("navigation:tabs_vehicle_data_bollo_pay")}
              </Button>
            )}

            <Button
              variant="contained"
              disabled={processing || disposed > 0}
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("bollo-form-unique");
              }}
            >
              {i18next.t("form:save")}
            </Button>
          </div>
        </div>
      )}
      <SmartModal
        modalUniqueId={"confirmPayment"}
        title={""}
        modalInnerComponent={
          <div className="modal-wrapper">
            <strong className="font-size-medium">
              {i18next.t("navigation:_stamp_payment_confirm_payment_title")}
            </strong>
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html:
                  i18next.t(
                    "navigation:_stamp_payment_confirm_payment_description"
                  ) ?? "",
              }}
            />
          </div>
        }
        onAccept={() => {
          if (loggedUser) {
            if (roadTaxRowsSelected.length > 0) {
              let tmp: IRoadTaxPaymentsRequestInsert = {
                roadTaxList: roadTaxRowsSelected.map((x: any) => {
                  return {
                    roadTaxID: x.roadTaxID,
                  };
                }),
              };
              InsertRoadTaxPaymentsRequest(tmp);
            }
            DismissModal("confirmPayment");
          }
        }}
        onReject={() => {
          DismissModal("confirmPayment");
        }}
      />
    </div>
  );
};

export const Form__SuperBollo = (props: {
  vehicleID: number;
  isNewSuperBollo?: boolean;
  dataVehicle: IVehicleDataGet;
}) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const [items, setItems] = useState<IVehicleAdditionalRoadTax[]>([]);
  const [active, setActive] = useState<IVehicleAdditionalRoadTax>();
  const [storeSuperBollo, setStoreSuperBollo] =
    useState<IVehicleAdditionalRoadTax>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const resetForm = () => {
    setItems(
      items.map((x: IVehicleAdditionalRoadTax) => {
        x.stored = true;
        return x;
      })
    );
    setLoading(true);
    setActive(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const getAdditionalRoadTaxes = () => {
    setLoading(true);
    ApiService.VehicleRoadTaxAdditionalController.VehicleRoadTaxAdditionalGet(
      null,
      props.vehicleID,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let res: IVehicleAdditionalRoadTax[] = response.payload;
          setItems(res);
          let current = res.filter(
            (x: IVehicleAdditionalRoadTax) => x.stored === false
          );
          if (current.length > 0) {
            if (props.isNewSuperBollo) {
              setStoreSuperBollo(current[0]);
            } else {
              setActive(current[0]);
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const updateAdditionalRoadTax = (
    data: IVehicleAdditionalRoadTax,
    newFormOnSuccess = false,
    dataInsert?: IVehicleAdditionalRoadTax
  ) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleRoadTaxAdditionalController.VehicleRoadTaxAdditionalUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.isNewSuperBollo) {
            if (dataInsert) {
              insertAdditionalRoadTax(dataInsert);
            }
          } else {
            if (newFormOnSuccess) {
              resetForm();
            } else {
              setActive(data);
            }
          }
          ToastMessage(i18next.t("message:addroadtaxUdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertAdditionalRoadTax = (data: IVehicleAdditionalRoadTax) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleRoadTaxAdditionalController.VehicleRoadTaxAdditionalInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          data["additionalRoadTaxID"] = response.payload;
          setActive(response.payload);
          ToastMessage(i18next.t("message:addroadtaxInsert"), "success");
          DismissModal("ModalSuperBollo");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteAdditionalRoadTax = (data: IVehicleAdditionalRoadTax) => {
    setProcessing(true);
    ApiService.VehicleRoadTaxAdditionalController.VehicleRoadTaxAdditionalDelete(
      {
        additionalRoadTaxID: data.additionalRoadTaxID,
      },
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:addroadtaxDeleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    getAdditionalRoadTaxes();
  }, []);

  return (
    <div style={{ paddingTop: "1em" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "3em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div
            className="padding-small"
            style={{
              display: "flex",
              gap: "1em",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              disabled={
                processing || !active?.additionalRoadTaxID || disposed > 0
              }
              onClick={() => {
                SummonModal("ArchiveModal");
              }}
            >
              {i18next.t("navigation:archive")}
            </Button>
            <Button
              variant="contained"
              disabled={processing}
              onClick={() => {
                SummonModal("ResumeModal");
              }}
            >
              {i18next.t("navigation:tabs_vehicle_data_superbollo_hist")}
            </Button>
          </div>
          <ArchiveModal
            onArchive={() => {
              if (active) {
                active.stored = true;
                updateAdditionalRoadTax({ ...active }, true);
              }
            }}
          />
          <ResumeModal
            title={i18next.t("navigation:tabs_vehicle_data_superbollo_hist")}
            headers={[
              {
                field: "additionalTaxAmount",
                headerName: i18next.t("form:vehciledata_forms_addpay_erary"),
              },
              {
                field: "referenceYear",
                headerName: i18next.t("form:vehciledata_forms_addpay_year"),
              },
              {
                field: "startDT",
                fieldOriginal: "startDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_addpay_startdt"),
              },
              {
                field: "expirationDT",
                fieldOriginal: "expirationDTOriginal",
                headerName: i18next.t("form:expirationDT"),
              },
              {
                field: "paymentCode",
                headerName: i18next.t("form:vehciledata_forms_addpay_id_pay"),
              },
            ]}
            rows={items
              .filter((x: IVehicleAdditionalRoadTax) => x.stored === true)
              .map((x: IVehicleAdditionalRoadTax) => {
                return {
                  additionalTaxAmount: x.additionalTaxAmount,
                  referenceYear: x.referenceYear,
                  expirationDT: displayUTC0_ISODate(x.expirationDT, true) ?? "-",
                  startDT: displayUTC0_ISODate(x.startDT, true) ?? "-",
                  startDTOriginal: x.startDT,
                  paymentCode: x.paymentCode,
                  expirationDTOriginal: x.expirationDT,
                };
              })}
          />
          <MultiForm
            disableForm={disposed > 0}
            suppressLayout
            formTitle={""}
            formId="super-bollo-form-unique"
            suppressSubmit
            onChange={(data: any) => {
              setActive({ ...active, ...data });
            }}
            onSubmit={(data: any) => {
              let data_ = { ...active, ...data };
              if (data_.additionalRoadTaxID !== undefined) {
                updateAdditionalRoadTax(data_);
              } else {
                if (props.isNewSuperBollo) {
                  if (storeSuperBollo) {
                    storeSuperBollo.stored = true;
                    updateAdditionalRoadTax(
                      { ...storeSuperBollo },
                      false,
                      data_
                    );
                  } else {
                    insertAdditionalRoadTax(data_);
                  }
                } else {
                  insertAdditionalRoadTax(data_);
                }
              }
            }}
            inputs={[
              {
                width: 50,
                type: "number",
                name: "additionalTaxAmount",
                defaultValue: active?.additionalTaxAmount,
                inputAdornament: { adornament: "€" },
                required: true,
                label: i18next
                  .t("form:vehciledata_forms_addpay_erary")
                  .toString(),
              },
              {
                width: 50,
                type: "number",
                name: "referenceYear",
                defaultValue: active?.referenceYear,
                required: true,
                errorText:
                  active?.referenceYear &&
                  active?.referenceYear?.toString().length !== 4
                    ? i18next.t("error:invalidYear") ?? ""
                    : "",
                label: i18next
                  .t("form:vehciledata_forms_addpay_year")
                  .toString(),
              },
              {
                width: 25,
                type: "date",
                name: "startDT",
                defaultValue: active?.startDT,
                required: true,
                minDate: GetVehicleMinDate(props.dataVehicle),
                maxDate: active ? dayjs(active.expirationDT) : undefined,
                label: i18next
                  .t("form:vehciledata_forms_addpay_startdt")
                  .toString(),
              },
              {
                width: 25,
                type: "date",
                name: "expirationDT",
                defaultValue: active?.expirationDT,
                minDate: active ? dayjs(active.startDT) : undefined,
                required: true,
                label: i18next
                  .t("form:expirationDT")
                  .toString(),
              },
              {
                width: 50,
                type: "text",
                name: "paymentCode",
                defaultValue: active?.paymentCode,
                required: true,
                inputProps: {
                  maxLength: 50,
                },
                label: i18next
                  .t("form:vehciledata_forms_addpay_id_pay")
                  .toString(),
              },
            ]}
          />
          {active && active.additionalRoadTaxID && (!processing || processing) ?
            <AttachmentTab vId={props.vehicleID} tab={3} attachmentCode={active.attachmentCode} />
          :
            <div className="full-width">
              <div className="attachments-multi-form-title-wrap">
                {i18next.t("generic:attachment_enable")}
              </div>
            </div>
          }
          <div
            className="padding-small"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              variant="contained"
              disabled={
                processing ||
                active?.referenceYear?.toString().length !== 4 ||
                disposed > 0
              }
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("super-bollo-form-unique");
              }}
            >
              {i18next.t("form:save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export const Form__Tagliando = (props: {
  vehicleID: number;
  isNewTagliando?: boolean;
  dataVehicle: IVehicleDataGet;
}) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const [items, setItems] = useState<IVehicleTest[]>([]);
  const [active, setActive] = useState<IVehicleTest>();
  const [storeTagliando, setStoreTagliando] = useState<IVehicleTest>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const resetForm = () => {
    setItems(
      items.map((x: IVehicleTest) => {
        x.stored = true;
        return x;
      })
    );
    setLoading(true);
    setActive(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  // State for supplier list select
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  // API to load suppliers select list
  const loadSupplierList = () => {
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
            getTagliandi();
          } else {
            ToastMessage(response.error, "error");
            setLoading(false);
          }
        }
      );
    }
  };

  const getTagliandi = () => {
    setLoading(true);
    ApiService.VehicleTestController.VehicleTestGet(
      null,
      props.vehicleID,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let res: IVehicleTest[] = response.payload;
          setItems(res);
          let current = res.filter((x: IVehicleTest) => x.stored === false);
          if (current.length > 0) {
            if (props.isNewTagliando) {
              setStoreTagliando(current[0]);
            } else {
              setActive(current[0]);
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const updateTagliando = (
    data: IVehicleTest,
    newFormOnSuccess = false,
    dataInsert?: IVehicleTest
  ) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleTestController.VehicleTestUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.isNewTagliando) {
            if (dataInsert) {
              insertTagliando(dataInsert);
            }
          } else {
            if (newFormOnSuccess) {
              resetForm();
            } else {
              setActive(data);
            }
          }
          ToastMessage(i18next.t("message:testUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertTagliando = (data: IVehicleTest) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleTestController.VehicleTestInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          data["vehicleTestID"] = response.payload;
          setActive(response.payload);
          ToastMessage(i18next.t("message:testInsert"), "success");
          DismissModal("ModalTagliando");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteTagliando = (data: IVehicleTest) => {
    setProcessing(true);
    ApiService.VehicleTestController.VehicleTestDelete(
      data.vehicleTestID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:testDeleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    loadSupplierList();
  }, []);

  return (
    <div style={{ paddingTop: "1em" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "3em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div
            className="padding-small"
            style={{
              display: "flex",
              gap: "1em",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              disabled={processing || !active?.vehicleTestID || disposed > 0}
              onClick={() => {
                SummonModal("ArchiveModal");
              }}
            >
              {i18next.t("navigation:archive")}
            </Button>
            <Button
              variant="contained"
              disabled={processing}
              onClick={() => {
                SummonModal("ResumeModal");
              }}
            >
              {i18next.t("navigation:tabs_vehicle_data_tagliando_hist")}
            </Button>
          </div>
          <ArchiveModal
            onArchive={() => {
              if (active) {
                active.stored = true;
                updateTagliando({ ...active }, true);
              }
            }}
          />
          <ResumeModal
            title={i18next.t("navigation:tabs_vehicle_data_tagliando_hist")}
            headers={[
              {
                field: "supplierName",
                headerName: i18next.t("form:vehciledata_forms_test_forn"),
              },
              {
                field: "vehicleTestDT",
                fieldOriginal: "vehicleTestDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_test_date"),
              },
              {
                field: "cost",
                headerName: i18next.t("form:vehciledata_forms_test_cost"),
              },
              {
                field: "nextExpirationDT",
                fieldOriginal: "nextExpirationDTOrginal",
                headerName: i18next.t("form:vehciledata_forms_test_date_next_exp"),
              },
              {
                field: "nextExpirationKm",
                headerName: i18next.t("form:vehciledata_forms_test_km_next_exp"),
              }
            ]}
            rows={items
              .filter((x: IVehicleTest) => x.stored === true)
              .map((x: IVehicleTest) => {
                let forn = supplierList.find(
                  (y: ISupplierGetSideList) =>
                    y.supplierInfo.supplierID === x.supplierID
                );
                return {
                  supplierName: forn ? forn.supplierInfo.supplierName : "-",
                  vehicleTestDT: displayUTC0_ISODate(x.vehicleTestDT, true) ?? "-",
                  vehicleTestDTOriginal: x.vehicleTestDT,
                  cost: x.cost,
                  nextExpirationDT: displayUTC0_ISODate(x.nextExpirationDT, true) ?? "-",
                  nextExpirationDTOrginal: x.nextExpirationDT,
                  nextExpirationKm: x.nextExpirationKm,
                };
              })}
          />

          <SmartModal
            modalUniqueId="supplierUser-modal"
            title={i18next.t("generic:titleAddSupplier")}
            hideButtons={true}
            modalInnerComponent={
              <FormSupplier
                updateList={(data: ISupplierGetSideList) => {
                  let supplierList_: ISupplierGetSideList[] = [...supplierList];
                  supplierList_.push(data);
                  setSupplierList(supplierList_);
                }}
                loggedUser={loggedUser}
                data={[]}
                idModal="supplierUser-modal"
              />
            }
          />

          <MultiForm
            disableForm={disposed > 0}
            suppressLayout
            formTitle={""}
            formId="test-form-unique"
            suppressSubmit
            onSubmit={(data: any) => {
              delete data["addSupplier"];
              let data_ = { ...active, ...data };
              if (data_.vehicleTestID !== undefined) {
                updateTagliando(data_);
              } else {
                if (props.isNewTagliando) {
                  if (storeTagliando) {
                    storeTagliando.stored = true;
                    updateTagliando({ ...storeTagliando }, false, data_);
                  } else {
                    insertTagliando(data_);
                  }
                } else {
                  insertTagliando(data_);
                }
              }
            }}
            inputs={[
              {
                width: 33.33,
                type: "select",
                name: "supplierID",
                defaultValue: active?.supplierID,
                required: true,
                label: i18next.t("form:vehciledata_forms_test_forn").toString(),
                options: supplierList.map(
                  (supplierItem: ISupplierGetSideList) => {
                    return {
                      key: supplierItem.supplierInfo.supplierID.toString(),
                      text: supplierItem.supplierInfo.supplierName.toString(),
                    };
                  }
                ),
              },
              // Add Supplier
              {
                width: 10,
                type: "custom",
                name: "addSupplier",
                element: (
                  <div className="multi-form-button-add-record">
                    <Button
                      variant="contained"
                      sx={{ height: "55px" }}
                      onClick={() => {
                        SummonModal("supplierUser-modal");
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                ),
              },
              {
                width: 23.33,
                type: "date",
                name: "vehicleTestDT",
                defaultValue: active?.vehicleTestDT,
                minDate: GetVehicleMinDate(props.dataVehicle),
                required: true,
                label: i18next.t("form:vehciledata_forms_test_date").toString(),
              },
              {
                width: 33.33,
                type: "number",
                name: "cost",
                inputAdornament: { adornament: "€" },
                defaultValue: active?.cost,
                required: true,
                label: i18next.t("form:vehciledata_forms_test_cost").toString(),
              },
              {
                width: 33.33,
                type: "date",
                name: "nextExpirationDT",
                defaultValue: active?.nextExpirationDT,
                required: true,
                label: i18next
                  .t("form:vehciledata_forms_test_date_next_exp")
                  .toString(),
              },
              {
                width: 33.33,
                type: "number",
                name: "nextExpirationKm",
                inputProps: {
                  max: 1000000,
                },
                defaultValue: active?.nextExpirationKm,
                required: true,
                label: i18next
                  .t("form:vehciledata_forms_test_km_next_exp")
                  .toString(),
              },
            ]}
          />
           {active && active.vehicleTestID && (!processing || processing) ?
            <AttachmentTab vId={props.vehicleID} tab={4} attachmentCode={active?.attachmentCode} />
          :
            <div className="full-width">
              <div className="attachments-multi-form-title-wrap">
                {i18next.t("generic:attachment_enable")}
              </div>
            </div>
          }
          <div
            className="padding-small"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              variant="contained"
              disabled={processing || disposed > 0}
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("test-form-unique");
              }}
            >
              {i18next.t("form:save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export const Form__Revisione = (props: {
  vehicleID: number;
  isNewRev?: boolean;
  readonlyForm?: boolean;
  dataVehicle: IVehicleDataGet;
}) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const [items, setItems] = useState<IVehicleInspection[]>([]);
  const [active, setActive] = useState<IVehicleInspection>();
  const [storeRev, setStoreRev] = useState<IVehicleInspection>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const resetForm = () => {
    setItems(
      items.map((x: IVehicleInspection) => {
        x.stored = true;
        return x;
      })
    );
    setLoading(true);
    setActive(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const getInspections = () => {
    setLoading(true);
    ApiService.VehicleInspectionController.VehicleInspectionGet(
      null,
      props.vehicleID,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let res: IVehicleInspection[] = response.payload;
          setItems(res);
          let current = res.filter(
            (x: IVehicleInspection) => x.stored === false
          );
          if (current.length > 0) {
            if (props.isNewRev) {
              setStoreRev(current[0]);
            } else {
              setActive(current[0]);
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const updateInspection = (
    data: IVehicleInspection,
    newFormOnSuccess = false,
    dataInsert?: IVehicleInspection
  ) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleInspectionController.VehicleInspectionUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (props.isNewRev) {
            if (dataInsert) {
              insertInspection(dataInsert);
            }
          } else {
            if (newFormOnSuccess) {
              resetForm();
            } else {
              setActive(data);
            }
          }
          ToastMessage(i18next.t("message:inspectionUpdated"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const insertInspection = (data: IVehicleInspection) => {
    setProcessing(true);
    data["vehicleID"] = props.vehicleID;
    ApiService.VehicleInspectionController.VehicleInspectionInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          data["vehicleInspectionID"] = response.payload;
          setActive(response.payload);
          ToastMessage(i18next.t("message:inspectionInsert"), "success");
          DismissModal("ModalRevisione");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteInspection = (data: IVehicleInspection) => {
    setProcessing(true);
    ApiService.VehicleInspectionController.VehicleInspectionDelete(
      data.vehicleInspectionID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:inspectionDeleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    getInspections();
  }, []);

  return (
    <div style={{ paddingTop: "1em" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "3em",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div
            className="padding-small"
            style={{
              display: "flex",
              gap: "1em",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              disabled={
                processing || !active?.vehicleInspectionID || disposed > 0
              }
              onClick={() => {
                SummonModal("ArchiveModal");
              }}
            >
              {i18next.t("navigation:archive")}
            </Button>
            <Button
              variant="contained"
              disabled={processing}
              onClick={() => {
                SummonModal("ResumeModal");
              }}
            >
              {i18next.t("navigation:tabs_vehicle_data_revisione_hist")}
            </Button>
          </div>
          <ArchiveModal
            onArchive={() => {
              if (active) {
                active.stored = true;
                updateInspection({ ...active }, true);
              }
            }}
          />
          <ResumeModal
            title={i18next.t("navigation:tabs_vehicle_data_revisione_hist")}
            headers={[
              {
                field: "vehicleInspectionDT",
                fieldOriginal: "vehicleInspectionDTOriginal",
                headerName: i18next.t("form:vehciledata_forms_rev_dt"),
              },
              {
                field: "vehicleInspectionKm",
                headerName: i18next.t("form:vehciledata_forms_rev_km"),
              }
            ]}
            rows={items
              .filter((x: IVehicleInspection) => x.stored === true)
              .map((x: IVehicleInspection) => {
                return {
                  vehicleInspectionDT: displayUTC0_ISODate(x.vehicleInspectionDT, true) ?? "-",
                  vehicleInspectionDTOriginal: x.vehicleInspectionDT,
                  vehicleInspectionKm: x.vehicleInspectionKm,
                };
              })}
          />
          <MultiForm
            disableForm={disposed > 0}
            suppressLayout
            formTitle={""}
            formId="inspection-form-unique"
            suppressSubmit
            onChange={(data: any) => {
              setActive({ ...active, ...data });
            }}
            onSubmit={(data: any) => {
              let data_ = { ...active, ...data };
              if (data_.vehicleInspectionID !== undefined) {
                updateInspection(data_);
              } else {
                if (props.isNewRev) {
                  if (storeRev) {
                    storeRev.stored = true;
                    updateInspection({ ...storeRev }, false, data_);
                  } else {
                    insertInspection(data_);
                  }
                } else {
                  insertInspection(data_);
                }
              }
            }}
            inputs={[
              {
                width: 33.3,
                type: "date",
                name: "vehicleInspectionDT",
                defaultValue: active?.vehicleInspectionDT ?? "",
                required: true,
                label: i18next.t("form:vehciledata_forms_rev_dt").toString(),
                minDate: GetVehicleMinDate(props.dataVehicle),
                maxDate: active ? dayjs(active.vehicleInspectionEndDT) : undefined,
              },
              {
                width: 33.3,
                type: "date",
                name: "vehicleInspectionEndDT",
                defaultValue: active?.vehicleInspectionEndDT,
                currentValue: active?.vehicleInspectionEndDT,
                required: true,
                label: i18next
                  .t("form:vehciledata_forms_deadline_dt")
                  .toString(),
                minDate: dayjs(active?.vehicleInspectionDT),
              },
              {
                width: 33.3,
                type: "number",
                name: "vehicleInspectionKm",
                defaultValue: active?.vehicleInspectionKm,
                required: true,
                inputProps: {
                  min: 0,
                  max: 1000000,
                },
                label: i18next.t("form:vehciledata_forms_rev_km").toString(),
              },
            ]}
          />
         {active && active.vehicleInspectionID && (!processing || processing) ?
            <AttachmentTab vId={props.vehicleID} tab={5} attachmentCode={active?.attachmentCode} />
          :
            <div className="full-width">
              <div className="attachments-multi-form-title-wrap">
                {i18next.t("generic:attachment_enable")}
              </div>
            </div>
          }
          <div
            className="padding-small"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              variant="contained"
              disabled={processing || disposed > 0}
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("inspection-form-unique");
              }}
            >
              {i18next.t("form:save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
const Form__scadenze = (props: { vehicleID: number }) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  return (
    <div style={{ paddingTop: "1em" }}>
      <DeadlineMainHandler
        DeadlineFamily={"vehicles"}
        extraGetParameters={
          props.vehicleID
            ? {
                name: "vehicleID",
                value: props.vehicleID,
              }
            : undefined
        }
      />
    </div>
  );
};

const AttachmentTab = (props: { tab: number; vId: number, disabled?: boolean, attachmentCode: string }) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  return (
    <AttachementMainHandler
      disabled={props?.disabled}
      attachmentFamily="vehicledata"
      extraGetParametersVector={[
        {
          name: "vehicleTabID",
          value: props.tab,
        },
        {
          name: "vehicleID",
          value: props.vId,
        },
        {
          name: "attachmentCode",
          value: props.attachmentCode
        }
      ]}
    />
  );
};

const VehiclesPage_subPages_vehicleData = (props: {
  onResetVehicleUpdate: (data: any) => void;
}) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<string>("1");
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const loadVehicles = () => {
    if (loggedUserCompany && vehicle) {
      ApiService.VehicleDataController.VehicleDataGet(
        loggedUserCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
            let current: IVehicleDataGet | undefined = vehicles.find(
              (x: IVehicleDataGet) => x.vehicleID === vehicle.vehicleID
            );
            if (current) {
              dispatch(VehiclesActions.setActiveVehicleForCompany(current));
            }
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  return (
    <div>
      <DashboardSectionTitle
        title={i18next.t("navigation:_vheicles_tab_nav_vehicle_data")}
        rightButtons={[]}
      />

      <Tabs
        value={currentTab}
        aria-label="basic tabs example"
        className="tabs-vehicle-data-wrapper"
      >
        <Tab
          value="1"
          onClick={() => setCurrentTab("1")}
          label={i18next.t("navigation:tabs_vehicle_data_tech")}
          id="1"
          aria-controls="1"
        />
        <Tab
          value="2"
          onClick={() => setCurrentTab("2")}
          label={i18next.t("navigation:tabs_vehicle_data_bollo")}
          id="2"
          aria-controls="2"
        />
        <Tab
          value="3"
          onClick={() => setCurrentTab("3")}
          label={i18next.t("navigation:tabs_vehicle_data_superbollo")}
          id="3"
          aria-controls="3"
        />
        <Tab
          value="4"
          onClick={() => setCurrentTab("4")}
          label={i18next.t("navigation:tabs_vehicle_data_tagliando")}
          id="4"
          aria-controls="4"
        />
        <Tab
          value="5"
          onClick={() => setCurrentTab("5")}
          label={i18next.t("navigation:tabs_vehicle_data_revisione")}
          id="5"
          aria-controls="5"
        />
        <Tab
          value="6"
          onClick={() => setCurrentTab("6")}
          label={i18next.t("navigation:tabs_vehicle_data_scadenze")}
          id="6"
          aria-controls="6"
        />
      </Tabs>
      <div className="div-vehicle-data-wrapper">
        {vehicle && loggedUser && currentTab === "1" && (
          <div>
            <Form__vehicleData
              onResetVehicleUpdate={props.onResetVehicleUpdate}
              vehicle={vehicle}
            />
          </div>
        )}
        {vehicle && loggedUser && currentTab === "2" && (
          <div>
            <Form__Bollo vehicle={vehicle} />
          </div>
        )}
        {vehicle && loggedUser && currentTab === "3" && (
          <div>
            <Form__SuperBollo vehicleID={vehicle.vehicleID} dataVehicle={vehicle} />
          </div>
        )}
        {vehicle && loggedUser && currentTab === "4" && (
          <div>
            <Form__Tagliando vehicleID={vehicle.vehicleID} dataVehicle={vehicle} />
          </div>
        )}
        {vehicle && loggedUser && currentTab === "5" && (
          <div>
            <Form__Revisione vehicleID={vehicle.vehicleID} dataVehicle={vehicle}/>
          </div>
        )}
        {loggedUser && vehicle && currentTab === "6" && (
          <div>
            <Form__scadenze vehicleID={vehicle.vehicleID} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VehiclesPage_subPages_vehicleData;
