import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import BlueToolbar from "../BlueToolbar/BlueToolBar";
import BlueToolbarInternal from "../BlueToolbarInternal/BlueToolbarInternal";
import ToolBar from "../ToolBar/ToolBar";
import "./ToolBarSelector.scss";

const ToolBarSelector = () => {
  const location = useLocation();
  const [path, setPath] = useState<string>(window.location.pathname);

  useEffect(() => {
    setPath(window.location.pathname);
  }, [location]);

  const whiteRoutes: string[] = [
    AppRoutes.HOME_PAGE_ROUTE,
    AppRoutes.WHO_WE_ARE_ROUTE,
    AppRoutes.CONTACTS_ROUTE,
    AppRoutes.FUNCITIONALITIES_ROUTE,
    AppRoutes.RATES_ROUTE,
    AppRoutes.MARKETPLACE_ROUTE,
    AppRoutes.WHYUS_ROUTE,
    AppRoutes.STRUCTURE_ROUTE,
    AppRoutes.INFO_RECOGNITION_ROUTE,
    AppRoutes.MOBILE_MENU_ROUTE,
    AppRoutes.COOKIE_POLICY_PAGE,
  ];

  return (
    <>
      {path.includes(AppRoutes.INTERNAL_DASHBOARD) && (
        <>
          <BlueToolbarInternal />
        </>
      )}
      {!path.includes(AppRoutes.INTERNAL_DASHBOARD) && (
        <>{whiteRoutes.includes(path) ? <ToolBar /> : <BlueToolbar />}</>
      )}
    </>
  );
};

export default ToolBarSelector;
