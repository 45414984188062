import { Autocomplete, Stepper, TextField } from "@mui/material";
import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStepperTickets } from "../../Models/IStepperTickets";
import { ITicketDeliveryTypeGet } from "../../Models/ITicketDeliveryTypeGet";
import { ITicketInsert } from "../../Models/ITicketInsert";
import { ITicketInsertResponse } from "../../Models/ITicketInsertResponse";
import { ITicketIssuingInstitutionGet } from "../../Models/ITicketIssuingInstitutionGet";
import { ITicketTypeGet } from "../../Models/ITicketTypeGet";
import { ITicketUpdate } from "../../Models/ITicketUpdate";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { TicketsAction } from "../../Reducers/Tickets/TicketsAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import "./StepSanctionData.scss";
import SmartModal, { SummonModal } from "../SmartModal/SmartModal";
import CAD_Image from "../../Assets/ImagesEmbedded/CAD_Immagine.jpg";
import { JSONPrint } from "../../Utils/Decoder";
import { getProPDFData } from "../../Pages/DashboardPage/DashBoard_Pages/AdditionalServicesPage/Tickets__AdditionalServicesPage/Tickets__AdditionalServicesPage";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";

export interface IStepSanctionData {
  header?: boolean;
}

const StepSanctionData = (props: IStepSanctionData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );

  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );
  const [isHeader, setIsHeader] = useState<boolean>(true);
  const [loadingTicketType, setLoadingTicketType] = useState<boolean>(false);
  const [ticketType, setTicketType] = useState<ITicketTypeGet[]>([]);
  const [ticketIssuingInstitution, setTicketIssuingInstitution] = useState<
    ITicketIssuingInstitutionGet[]
  >([]);
  const [loadingTicketDeliveryType, setLoadingTicketDeliveryType] =
    useState<boolean>(false);
  const [loadingTicketIssuingInstitution, setLoadingTicketIssuingInstitution] =
    useState<boolean>(false);
  const [ticketDeliveryType, setTicketDeliveryType] = useState<
    ITicketDeliveryTypeGet[]
  >([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const [infoIssuingInstitution, setInfoIssuingInstitution] = useState<any>({});

  const InsertTicket = (body: ITicketInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(TicketsAction.setTicketInsertResponse(response.payload));
            ToastMessage(i18next.t("message:TicketInsertCorrectly"), "success");
          } else {
            if (
              response.error &&
              response.error.startsWith("Cannot insert duplicate")
            ) {
              ToastMessage(
                i18next.t("error:duplicate_ticket_verb_error"),
                "error"
              );
              stepperGoToPage(0);
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicket = (body: ITicketUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(TicketsAction.setTicketInsertResponse(response.payload));
            ToastMessage(i18next.t("message:TicketUpdateCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadTicketType = () => {
    setLoadingTicketType(true);
    ApiService.TicketController.GetTicketTypeList(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTicketType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingTicketType(false);
      }
    );
  };

  const loadTicketIssuingInstitution = () => {
    setLoadingTicketIssuingInstitution(true);
    ApiService.TicketController.GetTicketIssuingInstitutionList(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTicketIssuingInstitution(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingTicketIssuingInstitution(false);
      }
    );
  };

  const loadTicketDeliveryType = () => {
    setLoadingTicketDeliveryType(true);
    ApiService.TicketController.GetTicketDeliveryTypeList(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTicketDeliveryType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingTicketDeliveryType(false);
      }
    );
  };

  useEffect(() => {
    loadTicketType();
    loadTicketIssuingInstitution();
    loadTicketDeliveryType();
    if (props.header === false) {
      setIsHeader(props.header);
    }
  }, []);

  useEffect(() => {
    if (stepperTicket?.stepSanction?.ticketIssuingInstitutionID) {
      setInfoIssuingInstitution(
        ticketIssuingInstitution.find(
          (x: ITicketIssuingInstitutionGet) =>
            x.ticketIssuingInstitutionID ===
            stepperTicket?.stepSanction?.ticketIssuingInstitutionID
        )
      );
    }
  }, [stepperTicket?.stepSanction?.ticketIssuingInstitutionID]);

  const getSelectedIstitution = (istName: any) => {
    let tmp: any = {
      ticketIssuingInstitutionID: undefined,
      ticketIssuingInstitutionName: undefined,
    };
    if (istName) {
      let match: ITicketIssuingInstitutionGet | undefined =
        ticketIssuingInstitution.find(
          (x: ITicketIssuingInstitutionGet) =>
            x.ticketIssuingInstitutionName === istName
        );
      if (match) {
        tmp.ticketIssuingInstitutionID = match.ticketIssuingInstitutionID;
        tmp.ticketIssuingInstitutionName = istName;
      }
    }
    return tmp;
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push({
      width: 33.3,
      type: "select",
      name: "ticketTypeID",
      defaultValue: getProPDFData(
        "ticketTypeID",
        stepperTicket ? stepperTicket?.stepSanction?.ticketTypeID : null
      ),
      label: i18next.t("form:TypeOfVerbalInfraction") ?? "",
      upperLabel: i18next.t("form:TypeOfVerbalInfraction") ?? "",
      required: true,
      options: ticketType.map((x: ITicketTypeGet) => {
        return {
          text: x.ticketTypeName,
          key: x.ticketTypeID,
        };
      }),
    });
    multiformInputs.push({
      width: 33.3,
      type: "text",
      name: "ticketNumber",
      defaultValue: getProPDFData(
        "verbalNumber",
        stepperTicket ? stepperTicket?.stepSanction?.ticketNumber : ""
      ),
      label: i18next.t("form:VerbalNumber") ?? "",
      upperLabel: i18next.t("form:VerbalNumber") ?? "",
      required: true,
    });
    multiformInputs.push({
      width: 33.3,
      type: "custom",
      name: "",
      required: true,
      label: i18next.t("form:IssuerBody") ?? "",
      upperLabel: i18next.t("form:IssuerBody") ?? "",
      modalId: "IssuerBodyInfo_modal",
      modalInfo: infoIssuingInstitution?.ticketIssuingInstitutionID,
      element: (
        <div>
          <Autocomplete
            freeSolo
            size="small"
            options={ticketIssuingInstitution.map(
              (x: ITicketIssuingInstitutionGet) => {
                return x.ticketIssuingInstitutionName;
              }
            )}
            defaultValue={getProPDFData(
              "ticketIssuingInstitutionName",
              ticketInsertResponse
                ? ticketInsertResponse[0].ticketIssuingInstitutionName
                : ""
            )}
            onChange={(_, value: any) => {
              let tmp = {
                ...stepperTicket?.stepSanction,
                ...getSelectedIstitution(value),
              };

              dispatch(
                TicketsAction.setStepperTickets({
                  ...stepperTicket!,
                  stepSanction: tmp,
                })
              );
            }}
            renderInput={(params) => <TextField required {...params} />}
          />
        </div>
      ),
    });
    multiformInputs.push({
      width: 33.3,
      type: "select",
      name: "ticketDeliveryTypeID",
      defaultValue: stepperTicket
        ? stepperTicket?.stepSanction?.ticketDeliveryTypeID
        : null,
      label: i18next.t("form:SelectDeliveryMethod") ?? "",
      upperLabel: i18next.t("form:DeliveryDone") ?? "",
      options: ticketDeliveryType.map((x: ITicketDeliveryTypeGet) => {
        return {
          text: x.ticketDeliveryTypeName,
          key: x.ticketDeliveryTypeID,
        };
      }),
      required: true,
    });
    if (stepperTicket) {
      if (stepperTicket?.stepSanction?.ticketDeliveryTypeID) {
        if (stepperTicket?.stepSanction?.ticketDeliveryTypeID === 3) {
          multiformInputs.push({
            width: 33.3,
            type: "datetime",
            name: "ticketCADDT",
            defaultValue: stepperTicket
              ? stepperTicket?.stepSanction?.ticketCADDT
              : null,
            label: i18next.t("form:InsertDateCAD") ?? "",
            upperLabel: i18next.t("form:DateCAD") ?? "",
            required: true,
            modalInfo: true,
            modalId: "ticketCADDT_modal",
          });
          multiformInputs.push({
            width: 33.3,
            type: "datetime",
            name: "ticketPickUpDT",
            defaultValue: stepperTicket
              ? stepperTicket?.stepSanction?.ticketPickUpDT
              : null,
            label: i18next.t("form:InsertPickupDate") ?? "",
            upperLabel: i18next.t("form:PickupDate") ?? "",
            required: true,
          });
        }
        if (stepperTicket?.stepSanction?.ticketDeliveryTypeID !== 3) {
          multiformInputs.push({
            width: 66.6,
            type: "datetime",
            name: "ticketNotificationDT",
            defaultValue: stepperTicket
              ? stepperTicket?.stepSanction?.ticketNotificationDT
              : null,
            label: i18next.t("form:InsertNotificationDate") ?? "",
            upperLabel: i18next.t("form:NotificationDate") ?? "",
            required: true,
          });
        }
      }
    }
    return multiformInputs;
  };

  return (
    <div className="step-sanction-data-wrapper">
      {isHeader && (
        <div className="step-sanction-header">
          <strong>{i18next.t("navigation:_tickets_page_step1_header")}</strong>
        </div>
      )}
      <MultiForm
        inputs={multiformInputs()}
        formTitle={""}
        suppressSubmit
        formId="sanction"
        onChange={async (data: any) => {
          let tmp = { ...stepperTicket?.stepSanction };
          if (tmp.ticketIssuingInstitutionID) {
            data = {
              ...data,
              ticketIssuingInstitutionID: tmp.ticketIssuingInstitutionID,
            };
          }

          if (data.ticketIssuingInstitutionID === undefined) {
            let tmp = getSelectedIstitution(
              getProPDFData(
                "ticketIssuingInstitutionName",
                ticketInsertResponse
                  ? ticketInsertResponse[0].ticketIssuingInstitutionName
                  : ""
              )
            );
            data = { ...data, ...tmp };
          }

          if (
            data.ticketDeliveryTypeID === 1 ||
            data.ticketDeliveryTypeID === 2
          ) {
            data.ticketCADDT = null;
            data.ticketPickUpDT = null;
          } else if (data.ticketDeliveryTypeID === 3) {
            data.ticketNotificationDT = null;
          }
          dispatch(
            TicketsAction.setStepperTickets({
              ...stepperTicket!,
              stepSanction: data,
            })
          );
          localStorage.setItem(" stepSanction", JSON.stringify(data));
        }}
        onSubmit={(data: any) => {
          let bodyUpdate: any = stepperTicket?.stepSanction!;

          if (bodyUpdate.ticketIssuingInstitutionID === undefined) {
            let tmp = getSelectedIstitution(
              getProPDFData(
                "ticketIssuingInstitutionName",
                ticketInsertResponse
                  ? ticketInsertResponse[0].ticketIssuingInstitutionName
                  : ""
              )
            );
            bodyUpdate = { ...bodyUpdate, ...tmp };
          }

          if (ticketInsertResponse) {
            bodyUpdate.ticketID = ticketInsertResponse[0].ticketID;
            UpdateTicket(bodyUpdate);
          } else {
            InsertTicket(bodyUpdate);
          }
          stepperGoToPage(1);
        }}
      />
      <SmartModal
        className="modal-sanction-info"
        modalUniqueId="ticketCADDT_modal"
        title={i18next.t("navigation:ticketCADDT_modal_title")}
        modalInnerComponent={
          <div className="modal-sanction-info-wrapper">
            <p
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:ticketCADDT_info") as string,
              }}
            />
            <img src={CAD_Image} title={""} />
          </div>
        }
      />
      {infoIssuingInstitution && (
        <SmartModal
          modalUniqueId="IssuerBodyInfo_modal"
          title={i18next.t("navigation:IssuerBodyInfo_modal_title")}
          modalInnerComponent={
            <div>
              <p>
                <b>{i18next.t("form:IssuerBody")}</b>:{" "}
                {infoIssuingInstitution.ticketIssuingInstitutionName}
              </p>
              <p>
                <b>{i18next.t("form:address")}</b>:&nbsp;
                {infoIssuingInstitution.addressStreet}{" "}
                {infoIssuingInstitution.addressCity}
                {infoIssuingInstitution.addressProv &&
                  " (" + infoIssuingInstitution.addressProv + ") "}
                {infoIssuingInstitution.addressCAP &&
                  " - " + infoIssuingInstitution.addressCAP}
              </p>
              <p>
                <b>{i18next.t("form:pec")}</b>:&nbsp;
                <a
                  href={
                    "email:" +
                    infoIssuingInstitution.ticketIssuingInstitutionPEC
                  }
                >
                  {infoIssuingInstitution.ticketIssuingInstitutionPEC}
                </a>
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default StepSanctionData;
