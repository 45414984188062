import { useState, useEffect } from "react";
import i18next from "i18next";

import "./StepNewAccount.scss";

import {
  validationFiscalCode,
  validationVAT,
  validationPhone,
  validationPassword,
} from "../../Utils/Validation";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import StepLogIn from "./StepLogIn";
import UploadForm from "../UploadForm/UploadForm";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { IGoogleAddress } from "../../Utils/Google";
import PlaceAutoCompleteForm from "../PlaceAutoCompleteForm/PlaceAutoCompleteForm";

import { ICountry } from "../../Models/ICountry";
import { IAccountType } from "../../Models/IAccountType";
import { IAccountBody } from "../../Models/IAccountBody";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getCompressedBase64, getCompressedFileName } from "../../Utils/FileToBase";

export interface IStepNewAccount_Natural {
  setPage: (page: number) => void;
  checkBoxDescription: JSX.Element;
}

const StepNewAccount_Natural = (props: IStepNewAccount_Natural) => {
  /* STATEs */
  // States for validations
  const [state, setState] = useState<any>({});
  const [checkPassword, setCheckPassword] = useState<boolean>(false);

  // State for spinning the process
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingCountires, setLoadingCountires] = useState<boolean>(false);
  const [loadingAccountType, setLoadingAccountType] = useState<boolean>(false);

  // State for account type select
  const [accountType, setAccountType] = useState<IAccountType[]>([]);

  // State for countries select
  const [countries, setCountries] = useState<ICountry[]>([]);

  // State for checkbox terms
  const [checkedBox, setCheckedBox] = useState<boolean>(false);
  const [checkboxError, setcheckboxError] = useState<string>("");

  /* APIs */
  // API that takes care of sending the data
  const makeRegistration = async (data: IAccountBody) => {
    setProcessing(true);
    delete data.accountPayload["accountConfirmPassword"];
    data.accountPayload["accountTypeID"] = +data.accountPayload["accountTypeID"];
    data.companyPayload["companyFiscalCode"] =
      data.companyPayload["companyFiscalCode"].toUpperCase();
    data.companyPayload["companyBusinessName"] =
      data.companyPayload["companyFiscalCode"].toUpperCase();
    data.companyPayload["companyReferentEmail"] =
      data.accountPayload["accountEmail"].trim();

    ApiService.AccountController.AccountRegistration(data, (response: IAPIResponse) => {
      if (response.error === null) {
        localStorage.setItem("resendAccountID", response.payload.accountID);
        props.setPage(2);
      } else if (response.raw.status === 409) {
        ToastMessage(i18next.t("error:REGISTRATION_USER_ALREADY_REGISTRED"), "error");
      } else if (response.raw.status === 400) {
        ToastMessage(i18next.t("error:REGISTRATION_USER_ERROR_FIELD"), "error");
      } else if (response.raw.status === 500) {
        ToastMessage(i18next.t("error:REGISTRATION_USER_ERROR"), "error");
      } else {
        ToastMessage(response.error, "error");
      }
      setProcessing(false);
    });
  };

  // API to load account type
  const loadAccountType = () => {
    setLoadingAccountType(true);
    ApiService.AccountController.AccountType((response: IAPIResponse) => {
      if (response.error === null) {
        setAccountType(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingAccountType(false);
    });
  };

  // API to load countries
  const loadCountries = () => {
    setLoadingCountires(true);
    ApiService.AccountController.AccountCountry((response: IAPIResponse) => {
      if (response.error === null) {
        setCountries(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingCountires(false);
    });
  };

  /* FUNCTIONS */
  // Update with a new file
  const updateFile = (files: File[]) => {
    getCompressedBase64(files.length > 0 ? files[0] : null, (result: string | null) => {
      let oldState = { ...state };
      oldState["companyImageURL"] = result;
      setState(oldState);
    });
  };

  /* EFFECT HOOK */
  // Check that the passwords match
  useEffect(() => {
    if (state) {
      setCheckPassword(
        state.accountConfirmPassword &&
          state.accountPassword !== state.accountConfirmPassword
      );
    }
  }, [state]);

  // Init country and account type API
  useEffect(() => {
    loadCountries();
    loadAccountType();
  }, []);

  /* RETURN */
  return (
    <div>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleNewAccountNatural") ?? "",
          }}
        ></h1>

        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitleNewAccountNatural")}
        </h2>

        <MultiForm
          suppressLayout
          suppressSubmit={true}
          formId={"StepNewAccount_Natural"}
          disableForm={processing}
          formTitle={i18next.t("form:titleNaturalPerson")}
          inputs={[
            {
              width: 50,
              type: "select",
              name: "accountTypeID",
              defaultValue: "1",
              required: true,
              label: `${i18next.t("form:userTypePlaceholder")}`,
              options: accountType.map((accountType: IAccountType) => {
                return {
                  key: accountType.accountTypeID.toString(),
                  text: accountType.accountTypeName,
                };
              }),
            },
            {
              width: 25,
              type: "text",
              name: "companyReferentName",
              required: true,
              inputProps: {
                maxLength: 50,
              },
              label: `${i18next.t("form:name")}`,
            },
            {
              width: 25,
              type: "text",
              name: "companyReferentSurname",
              required: true,
              inputProps: {
                maxLength: 50,
              },
              label: `${i18next.t("form:surname")}`,
            },
            {
              width: 25,
              type: "email",
              name: "accountEmail",
              required: true,
              inputProps: { maxLength: 100 },
              label: `${i18next.t("form:email")}`,
            },
            {
              width: 25,
              type: "tel",
              name: "companyReferentPhone",
              required: false,
              inputProps: { maxLength: 20 },
              label: `${i18next.t("form:telephonePersonal")}`,
              errorText: state?.clientPhone
                ? validationPhone(state.companyReferentPhone) ||
                  state.companyReferentPhone === null ||
                  state.companyReferentPhone === ""
                  ? ""
                  : (i18next.t("form:validationPhone") as string)
                : "",
            },
            {
              width: 25,
              type: "text",
              name: "companyVAT",
              required: false,
              inputProps: { maxLength: 13 },
              label: `${i18next.t("form:vatNumber")}`,
              errorText:
                state?.companyVAT &&
                state?.companyVAT.length > 0 &&
                !validationVAT(state?.companyVAT)
                  ? (i18next.t("form:validationIVA") as string)
                  : "",
            },
            {
              width: 25,
              type: "text",
              name: "companyFiscalCode",
              required: true,
              inputProps: {
                style: { textTransform: "uppercase" },
                maxLength: 16,
              },
              label: `${i18next.t("form:taxIdCode")}`,
              errorText:
                state?.companyFiscalCode &&
                state?.companyFiscalCode.length > 0 &&
                !validationFiscalCode(state?.companyFiscalCode)
                  ? (i18next.t("form:validationCF") as string)
                  : "",
            },
            {
              width: 50,
              type: "password",
              name: "accountPassword",
              required: true,
              inputProps: { maxLength: 50 },
              label: `${i18next.t("form:password")}`,
              errorText: state ? validationPassword(state.accountPassword) : "",
            },
            {
              width: 50,
              type: "password",
              name: "accountConfirmPassword",
              required: true,
              inputProps: { maxLength: 50 },
              label: `${i18next.t("form:passwordConfirm")}`,
              errorText: checkPassword ? `${i18next.t("form:validationPassword")}` : "",
            },
            {
              width: 50,
              type: "email",
              name: "companySDIPEC",
              required: true,
              inputProps: { maxLength: 100 },
              label: `${i18next.t("form:emailBilling")}`,
            },
            {
              width: 50,
              type: "select",
              name: "companyReferentCountryID",
              required: true,
              label: `${i18next.t("form:countryResidence")}`,
              defaultValue: state?.companyReferentCountryID,
              options: countries.map((country: ICountry) => {
                return {
                  key: country.countryID,
                  text: i18next.t(`countries:${country.countryISO}`),
                };
              }),
            },
            {
              width: 50,
              name: "autocomplete",
              fullWidth: true,
              type: "custom",
              className: "content-place-auto-complete",
              element: (
                <PlaceAutoCompleteForm
                  onChange={(data: IGoogleAddress) => setState({ ...state, ...data })}
                  addressId="companyBillingStreet"
                  disableForm={processing}
                  inputs={[
                    {
                      width: 70,
                      type: "text",
                      required: true,
                      inputProps: { maxLength: 100 },
                      name: "companyBillingStreet",
                      defaultValue: state?.companyBillingStreet,
                      mapDecode: "route",
                      label: String(i18next.t("form:addressLegal")),
                    },
                    {
                      width: 30,
                      type: "text",
                      required: true,
                      inputProps: { maxLength: 10 },
                      name: "companyBillingNumber",
                      defaultValue: state?.companyBillingNumber,
                      mapDecode: "street_number",
                      label: String(i18next.t("form:civicNumberAbbr")),
                    },
                    {
                      width: 100,
                      required: true,
                      type: "select_nations",
                      name: "companyBillingCountryID",
                      currentValue: state?.companyBillingCountryID ?? "",
                      mapDecode: "country",
                      label: String(i18next.t("form:countryBilling")),
                    },
                    {
                      width: 25,
                      type: "text",
                      required: true,
                      name: "companyBillingCAP",
                      defaultValue: state?.companyBillingCAP,
                      mapDecode: "postal_code",
                      inputProps: { maxLength: 20 },
                      label: String(i18next.t("form:postalCode")),
                    },
                    {
                      width: 50,
                      type: "text",
                      required: true,
                      inputProps: { maxLength: 50 },
                      name: "companyBillingCity",
                      defaultValue: state?.companyBillingCity,
                      mapDecode: "locality",
                      label: String(i18next.t("form:city")),
                    },
                    {
                      width: 25,
                      type: "text",
                      required: true,
                      name: "companyBillingProv",
                      defaultValue: state?.companyBillingProv,
                      inputProps: { maxLength: 50 },
                      mapDecode: "province",
                      label: String(i18next.t("form:provinceAbbr")),
                    },
                  ]}
                />
              ),
            },
            {
              width: 50,
              type: "custom",
              name: "companyImageURL",
              label: `${i18next.t("form:titleImageImageCompanyUpload")}`,
              element: (
                <UploadForm
                  initialFiles={
                    state && state.companyImageURL
                      ? [getCompressedFileName(state.companyImageURL)]
                      : []
                  }
                  name={"companyImageURL"}
                  title={i18next.t("form:subtitleImageCompanyUpload")}
                  description={i18next.t("form:descriptionImageUpload")}
                  onFileChanged={updateFile}
                  accept={"image/*"}
                />
              ),
            },
          ]}
          onChange={async (data: any) => {
            const accountTypeID = data.accountTypeID;
            if (accountTypeID === "2") {
              props.setPage(1);
            }
            setState({ ...state, ...data });
          }}
          onSubmit={async () => {
            if (checkedBox) {
              let compressData: any = {
                accountPayload: {},
                companyPayload: {},
              };

              let dataObjects = Object.keys(state);
              for (let index = 0; index < dataObjects.length; index++) {
                const element = dataObjects[index];
                if (element.startsWith("account")) {
                  compressData["accountPayload"][element] = state[element];
                }
                if (element.startsWith("company")) {
                  compressData["companyPayload"][element] = state[element];
                }
              }
              setcheckboxError("");
              makeRegistration(compressData);
            } else {
              setcheckboxError(String(i18next.t("form:validationTerms")));
            }
          }}
        />

        <FormControl className="StepNewAccount--checkbox" error={checkboxError !== ""}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e: any) => {
                  if (e.target.checked) {
                    setcheckboxError("");
                  }

                  setCheckedBox(e.target.checked);
                }}
                name="accountCheckbox"
                required
              />
            }
            label={props.checkBoxDescription}
          />
          <FormHelperText>{checkboxError}</FormHelperText>
        </FormControl>
      </div>

      <div className="StepNewAccount-button-box">
        <StepLogIn />

        <Button
          disabled={checkedBox ? false : true}
          variant="contained"
          onClick={() => {
            triggerFormValidation("StepNewAccount_Natural");
          }}
          endIcon={<ChevronRightIcon />}
        >
          {i18next.t("navigation:nextLabelNewAccount")}
        </Button>
      </div>
      <LoaderBackdrop loading={processing || loadingCountires || loadingAccountType} />
    </div>
  );
};

export default StepNewAccount_Natural;
