/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./DownloadUploadPayment.scss";

/* LIBRARY */
import i18next from "i18next";

/* MODELS */
import { IUser } from "../../../../../../Models/IUser";

/* MUI */
import { Box, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";

/* API */
import moment from "moment";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IPaymentRoadTaxReqStep } from "../../../../../../Models/IPaymentRoadTaxReqStep";
import { IPaymentsRoadTax } from "../../../../../../Models/IPaymentsRoadTax";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    directory?: string;
    webkitdirectory?: string;
  }
}

/* COMPONENT */
const DownloadUploadPayment = () => {
  /* REDUX */
  // User
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [roadTaxRows, setRoadTaxRows] = useState<IPaymentsRoadTax[]>([]);
  const [rowSelected, setRowSelected] = useState<any>();
  const [zipPdfFile, setzipPdfFile] = useState<File[]>([]);
  const [intFile, setIntFile] = useState<File[]>([]);
  const [verFile, setVerFile] = useState<File[]>([]);

  const loadRoadTaxPaymentReqStep = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsReqStepGet(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setRoadTaxRows(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const DownloadRoadTaxPaymentRequestReport = (dateTo: string) => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.DownloadRoadTaxPaymentRequestReportGet(
        dateTo,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let time = moment().format("YYYYMMDDhmmss");
            response.payload.blob().then((blob: any) => {
              const a = document.createElement("a");
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = "monthly-roadtax-payreq-" + time;
              a.click();
              setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }, 0);
            });
            ToastMessage(
              i18next.t("message:RoadTaxDownloadRequestReport"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const DownloadRoadTaxPaymentRequestPaid = (dateTo: string) => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.DownloadRoadTaxPaymentRequestPaidGet(
        dateTo,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let time = moment().format("YYYYMMDDhmmss");
            response.payload.blob().then((blob: any) => {
              const a = document.createElement("a");
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = "monthly-roadtax-payreq-paid-" + time;
              a.click();
              setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }, 0);
            });
            ToastMessage(
              i18next.t("message:RoadTaxDownloadRequestPayment"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const RoadTaxPaymentsUploadInterrogationFile = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsUploadInt(
        intFile,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:RoadTaxUploadCorrectly"),
              "success"
            );
            DismissModal("uploadFileModal");
            setIntFile([]);
            let tmp = { ...rowSelected };
            tmp.uploadInt = true;
            RoadTaxPaymentsReqStepUpdate(tmp);
          } else {
            ToastMessage(i18next.t("error:badFileUploaded"), "error");
          }
        }
      );
    }
  };

  const RoadTaxPaymentsUploadVer = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsUploadVer(
        verFile,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:RoadTaxUploadCorrectly"),
              "success"
            );
            DismissModal("uploadFileDirectoryModal");

            let tmp = { ...rowSelected };
            tmp.uploadVer = true;
            RoadTaxPaymentsReqStepUpdate(tmp);
          } else {
            ToastMessage(i18next.t("error:badFileUploaded"), "error");
          }
        }
      );
    }
  };

  const RoadTaxPaymentsUploadScanFiles = () => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsUploadScanFiles(
        zipPdfFile,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:RoadTaxUploadCorrectly"),
              "success"
            );
            DismissModal("uploadFileDirectoryModal");
          } else {
            ToastMessage(i18next.t("error:badFileUploaded"), "error");
          }
        }
      );
    }
  };

  const RoadTaxPaymentsReqStepUpdate = (data: IPaymentRoadTaxReqStep) => {
    if (loggedUser) {
      ApiService.PaymentsRoadTaxController.RoadTaxPaymentsReqStepUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadRoadTaxPaymentReqStep();
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  useEffect(() => {
    loadRoadTaxPaymentReqStep();
  }, []);

  /* FUNCTIONS */
  // Download Buttons

  const DownloadReport = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          props.row.downloadReport = true;
          DownloadRoadTaxPaymentRequestReport(
            new Date(props.row.requestDT).toISOString()
          );
          RoadTaxPaymentsReqStepUpdate(props.row);
        }}
      >
        <DownloadIcon className="icon-table" />
      </Button>
    );
  };

  const DownloadPaid = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          props.row.downloadPaid = true;
          DownloadRoadTaxPaymentRequestPaid(
            new Date(props.row.requestDT).toISOString()
          );
          RoadTaxPaymentsReqStepUpdate(props.row);
        }}
      >
        <DownloadIcon className="icon-table" />
      </Button>
    );
  };

  const UploadInt = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          setIntFile([]);
          setRowSelected(props.row);
          SummonModal("uploadFileModal");
        }}
      >
        <UploadIcon className="icon-table" />
      </Button>
    );
  };

  // Upload Buttons
  const UploadVer = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          setVerFile([]);
          setzipPdfFile([]);
          setRowSelected(props.row);
          SummonModal("uploadFileDirectoryModal");
        }}
      >
        <UploadIcon className="icon-table" />
      </Button>
    );
  };

  const FormatData = (props: GridRenderCellParams) => {
    return <div>{displayUTC0_ISODate(props.value, true)}</div>;
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "requestDT",
      headerName: i18next.t(
        "navigation:roadtax_page_header_table_request_date"
      ),
      renderCell: FormatData,
    },
    {
      field: "downloadReport",
      headerName: i18next.t(
        "navigation:roadtax_page_header_table_download_requests"
      ),
      renderCell: DownloadReport,
    },
    {
      field: "uploadInt",
      headerName: i18next.t(
        "navigation:roadtax_page_header_table_upload_queries"
      ),
      renderCell: UploadInt,
    },
    {
      field: "downloadPaid",
      headerName: i18next.t(
        "navigation:roadtax_page_header_table_dwonload_payments"
      ),
      renderCell: DownloadPaid,
    },
    {
      field: "uploadVer",
      headerName: i18next.t(
        "navigation:roadtax_page_header_table_upload_payments"
      ),
      renderCell: UploadVer,
    },
  ];

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_cartax")} />

      <div className="dashboardForm__box">
        {/* <div className="data-grid-header">
        <div>
          <Button
            variant="contained"
            className="button-data-grid-header"
            onClick={() => {}}
          >
            {i18next.t("navigation:_stamp_payments_invoice_register")}
          </Button>
        </div>
      </div> */}
        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={roadTaxRows} />
        </Box>
      </div>
      <SmartModal
        modalUniqueId={"uploadFileModal"}
        title={""}
        modalInnerComponent={
          <>
            <div className="upload-file-modal">
              <div className="upload-file-title">
                <strong>
                  {i18next.t(
                    "navigation:_stamp_payment_download_upload_title1"
                  )}
                </strong>
              </div>
              <div className="button-load-supplies-wrapper">
                {intFile.length === 0 && (
                  <>
                    <div className="upload-icon-wrapper">
                      <UploadIcon fontSize="large" />
                    </div>
                    <div className="title-wrapper">
                      {i18next.t(
                        "navigation:_stamp_payment_download_upload_subtitle1"
                      )}
                    </div>
                    <div className="button-select-file">
                      <Button
                        variant="contained"
                        onClick={() => {
                          document.getElementById("uploadFile")?.click();
                        }}
                      >
                        {i18next.t(
                          "navigation:_stamp_payment_download_upload_button1"
                        )}
                        <input
                          id="uploadFile"
                          hidden
                          accept=".csv,.xls,.xlsx"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              setIntFile(e.target.files as any);
                            }
                          }}
                        />
                      </Button>
                    </div>
                    <div className="accepted-file-wrapper">
                      {i18next.t("navigation:_forniture_page_supported_files")}:
                      .csv .xls .xlsx
                    </div>
                  </>
                )}
                {intFile.length > 0 && (
                  <>
                    <div className="upload-icon-wrapper">
                      <UploadIcon fontSize="large" />
                    </div>
                    <div className="title-wrapper">
                      {intFile.length > 0 ? intFile[0].name : ""}
                    </div>
                    <div className="button-select-file">
                      <Button
                        variant="contained"
                        className="button"
                        onClick={() => {
                          document.getElementById("uploadFile")?.click();
                        }}
                      >
                        {i18next.t(
                          "navigation:roadtax_page_upload_another_file"
                        )}
                        <input
                          id="uploadFile"
                          hidden
                          accept=".csv,.xls,.xlsx"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              setIntFile(e.target.files as any);
                            }
                          }}
                        />
                      </Button>
                      <Button
                        variant="contained"
                        className="button"
                        onClick={() => {
                          RoadTaxPaymentsUploadInterrogationFile();
                        }}
                      >
                        {i18next.t(
                          "navigation:_stamp_payment_download_upload_button3"
                        )}
                      </Button>
                    </div>
                    <div className="accepted-file-wrapper">
                      {i18next.t("navigation:_forniture_page_supported_files")}:
                      .csv .xls .xlsx
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        }
      />
      <SmartModal
        modalUniqueId={"uploadFileDirectoryModal"}
        title={""}
        modalInnerComponent={
          <>
            <div className="upload-file-directory-modal">
              <div className="upload-file-title">
                <strong>
                  {i18next.t(
                    "navigation:_stamp_payment_download_upload_title1_2"
                  )}
                </strong>
              </div>
              <div className="button-load-supplies-wrapper">
                <div className="box">
                  {verFile.length === 0 && (
                    <>
                      <div className="upload-icon-wrapper">
                        <UploadIcon fontSize="large" />
                      </div>
                      <div className="title-wrapper">
                        {i18next.t(
                          "navigation:_stamp_payment_download_upload_subtitle1"
                        )}
                      </div>
                      <div className="button-select-file">
                        <Button
                          variant="contained"
                          className="button"
                          disabled={zipPdfFile.length > 0}
                          onClick={() => {
                            document.getElementById("uploadFile")?.click();
                          }}
                        >
                          {i18next.t(
                            "navigation:_stamp_payment_download_upload_button1"
                          )}
                          <input
                            id="uploadFile"
                            hidden
                            disabled={zipPdfFile.length > 0}
                            accept=".csv,.xls,.xlsx"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files) {
                                setVerFile(e.target.files as any);
                              }
                            }}
                          />
                        </Button>
                      </div>
                      <div className="accepted-file-wrapper">
                        {i18next.t(
                          "navigation:_forniture_page_supported_files"
                        )}
                        : .csv .xls .xlsx
                      </div>
                    </>
                  )}
                  {verFile.length > 0 && (
                    <>
                      <div className="upload-icon-wrapper">
                        <UploadIcon fontSize="large" />
                      </div>
                      <div className="title-wrapper">
                        {verFile.length > 0 ? verFile[0].name : ""}
                      </div>
                      <div className="button-select-file">
                        <Button
                          variant="contained"
                          className="button"
                          disabled={zipPdfFile.length > 0}
                          onClick={() => {
                            document.getElementById("uploadFile")?.click();
                          }}
                        >
                          {i18next.t(
                            "navigation:roadtax_page_upload_another_file"
                          )}
                          <input
                            id="uploadFile"
                            hidden
                            disabled={zipPdfFile.length > 0}
                            accept=".csv,.xls,.xlsx"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files) {
                                setVerFile(e.target.files as any);
                              }
                            }}
                          />
                        </Button>
                      </div>
                      <div className="accepted-file-wrapper">
                        {i18next.t(
                          "navigation:_forniture_page_supported_files"
                        )}
                        : .csv .xls .xlsx
                      </div>
                    </>
                  )}
                </div>

                <div className="box">
                  {zipPdfFile.length === 0 && (
                    <>
                      <div className="upload-icon-wrapper">
                        <UploadIcon fontSize="large" />
                      </div>
                      <div className="title-wrapper">
                        {i18next.t(
                          "navigation:_stamp_payment_download_upload_subtitle2"
                        )}
                      </div>
                      <div className="button-select-file">
                        <Button
                          disabled={verFile.length > 0}
                          variant="contained"
                          component="label"
                          onClick={() => {}}
                        >
                          {i18next.t(
                            "navigation:_stamp_payment_download_upload_button2"
                          )}
                          <input
                            disabled={verFile.length > 0}
                            hidden
                            accept=".zip"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files) {
                                setzipPdfFile(e.target.files as any);
                              }
                            }}
                          />
                        </Button>
                      </div>
                      <div className="accepted-file-wrapper">
                        {i18next.t(
                          "navigation:_forniture_page_supported_files"
                        )}
                        : .zip
                      </div>
                      <br />
                      <div
                        style={{
                          fontSize: "0.8em",
                          opacity: "0.8",
                          textAlign: "center",
                        }}
                      >
                        {i18next.t(
                          "navigation:_forniture_page_supported_files_pdf_zip_desc"
                        )}
                      </div>
                    </>
                  )}
                  {zipPdfFile.length > 0 && (
                    <>
                      <div className="upload-icon-wrapper">
                        <UploadIcon fontSize="large" />
                      </div>
                      <div className="title-wrapper">
                        {zipPdfFile.length > 0 ? zipPdfFile[0].name : ""}
                      </div>
                      <div className="button-select-file">
                        <Button
                          variant="contained"
                          component="label"
                          disabled={verFile.length > 0}
                          onClick={() => {}}
                        >
                          {i18next.t(
                            "navigation:_stamp_payment_download_upload_button2"
                          )}
                          <input
                            hidden
                            disabled={verFile.length > 0}
                            accept=".zip"
                            type="file"
                            onChange={(e) => {
                              if (e.currentTarget.files) {
                                let tmp = [];
                                for (
                                  let i = 0;
                                  i < e.currentTarget.files.length;
                                  i++
                                ) {
                                  tmp.push(e.currentTarget.files[i]);
                                }
                                setzipPdfFile(tmp);
                              }
                            }}
                          />
                        </Button>
                      </div>
                      <div className="accepted-file-wrapper">
                        {i18next.t(
                          "navigation:_forniture_page_supported_files"
                        )}
                        : .zip
                      </div>
                      <br />
                      <div
                        style={{
                          fontSize: "0.8em",
                          opacity: "0.8",
                          textAlign: "center",
                        }}
                      >
                        {i18next.t(
                          "navigation:_forniture_page_supported_files_pdf_zip_desc"
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        }
        buttons={[
          {
            text: i18next.t("navigation:reject") ?? "",
            onClick: () => {
              DismissModal("uploadFileDirectoryModal");
            },
            disabled: false,
          },
          {
            text: i18next.t("navigation:accept") ?? "",
            disabled: verFile.length === 0 && zipPdfFile.length === 0,
            onClick: () => {
              if (verFile.length > 0) {
                RoadTaxPaymentsUploadVer();
              }
              if (zipPdfFile.length > 0) {
                RoadTaxPaymentsUploadScanFiles();
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default DownloadUploadPayment;
