import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EuroIcon from "@mui/icons-material/Euro";
import { Alert, Button, ToggleButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import AttachementMainHandler from "../../../../../Components/AttachmentMainHandler/AttachmentMainHandler";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import BetterSelect from "../../../../../Components/MultiForm/SpecialInputs/BetterSelect";
import { displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import UploadIcon from "@mui/icons-material/Upload";
import StepAmountDeadelines from "../../../../../Components/StepAmountDeadelines/StepAmountDeadelines";
import StepAttachmentsTickets from "../../../../../Components/StepAttachmentsTickets/StepAttachmentsTickets";
import StepBreachData from "../../../../../Components/StepBreachData/StepBreachData";
import StepSanctionData from "../../../../../Components/StepSanctionData/StepSanctionData";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { IStepperTickets } from "../../../../../Models/IStepperTickets";
import { ITicketInsert } from "../../../../../Models/ITicketInsert";
import { ITicketInsertDetail } from "../../../../../Models/ITicketInsertDetail";
import { ITicketInsertPayment } from "../../../../../Models/ITicketInsertPayment";
import { ITicketInsertPaymentResponse } from "../../../../../Models/ITicketInsertPaymentResponse";
import { ITicketInsertResponse } from "../../../../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../../../../Models/ITicketStatus";
import { ITicketUpdate } from "../../../../../Models/ITicketUpdate";
import { ITicketUpdateDetail } from "../../../../../Models/ITicketUpdateDetail";
import { ITicketUpdatePayment } from "../../../../../Models/ITicketUpdatePayment";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { TicketsAction } from "../../../../../Reducers/Tickets/TicketsAction";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import "./Tickets__AdditionalServicesPage.scss";
import { IAddedService } from "../../../../../Models/IService";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { ITicketTypeGet } from "../../../../../Models/ITicketTypeGet";
import { IISOLanguage } from "../../../../../Components/FlagPicker/FlagPicker";
import PdfProAutoForm from "../../../../../Components/PdfProAutoForm/PdfProAutoForm";
import SendIcon from '@mui/icons-material/Send';
import { ITicket, ITicketInfo } from "../../../../../Models/ITicket";
import { currencyDecoder } from "../../../../../Utils/Decoder";

export const getProPDFData = (dataName: string, defaultValue: any) => {
  if ((window as any)["pdf-pro-data-results"]) {
    let data = (window as any)["pdf-pro-data-results"][dataName];
    if (data) {
      return data;
    }
  }
  return defaultValue && typeof defaultValue === "string" ? defaultValue.replace(" ", "").replace("€", "").replace(",", ".") : defaultValue;
};

const Tickets__AdditionalServicesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );
  const [currentAssignment, setCurrentAssignment] = useState<any[]>([]);
  const [assignmentSelected, setAssignmentSelected] = useState<any>();
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );

  const [pdfFile, setPdfFile] = useState<File[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const ticketInsertPaymentResponse: ITicketInsertPaymentResponse[] | undefined =
    useSelector((state: GlobalState) => state.tickets.ticketInsertPaymentResponse);
  const ticketStatus: ITicketStatus | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketStatus
  );
  const [ticketType, setTicketType] = useState<ITicketTypeGet[]>([]);
  const [ticketTypePro, setTicketTypePro] = useState<ITicketTypeGet[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const driverTicket: any | undefined = useSelector(
    (state: GlobalState) => state.tickets.driverTicket
  );
  const addonService: IAddedService[] = useSelector(
    (state: GlobalState) => state.generic.addons
  );

  const [loadingPdfPro, setLoadingPdfPro] = useState<boolean>(false);

  const [pdfProResponse, setPdfProResponse] = useState<any>(null);

  const [languages, setLanguages] = useState<IISOLanguage[]>([]);
  const [errorPdf, setErrorPdf] = useState<string>("");

  const [ticketRows, setTicketRows] = useState<any[]>([]);
  const [ticketRowsCopy, setTicketRowsCopy] = useState<any[]>([]);
  const [driverList, setDriverList] = useState<any[]>([]);
  const [plateList, setPlateList] = useState<any[]>([]);
  const [ticketID, setTicketID] = useState<number>();
  const [filterStatusticket, setFilterStatusticket] = useState<any[]>([
    { status: "orange", selected: false },
    { status: "red", selected: false },
    { status: "green", selected: false },
    { status: "white", selected: false },
  ]);

  const loadCurrentAssignment = () => {
    if (loggedUser) {
      ApiService.DriverController.DriverNameSurnameGet((response: IAPIResponse) => {
        if (response.error === null) {
          setCurrentAssignment(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      });
    }
  };

  const loadTicketTypes = () => {
    ApiService.TicketController.GetTicketTypeList(null, (response: IAPIResponse) => {
      if (response.error === null) {
        setTicketType(response.payload);
        let tmp = response.payload.filter((el: any) => el.ticketTypeID === 2 || el.ticketTypeID === 3 || el.ticketTypeID === 5)
        setTicketTypePro(tmp)
      } else {
        ToastMessage(response.error, "error");
      }
    });
  };

  const InsertTicketPayment = (body: ITicketInsertPayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertTicketPayment(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertPaymentResponse(response.payload));
          ToastMessage(i18next.t("message:TicketPaymentCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const UpdateTicketPayment = (body: ITicketUpdatePayment) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicketPayment(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertPaymentResponse(response.payload));
          ToastMessage(i18next.t("message:TicketPaymentCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const UpdateMultipleTicketDetail = (body: any) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateMultipleDetailTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:TicketDetailUpdateCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicketDetail = (body: ITicketUpdateDetail) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateDetailTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertDetailResponse(response.payload));
          ToastMessage(i18next.t("message:TicketDetailUpdateCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const sendTicketMailToDriverManually = (body: {ticketID: number}) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.SendTicketMailToDriver(body, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("navigation:sendTicketMailToDriverSuccessfully"), "success");
          DismissModal("send-email-to-driver-modal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  useEffect(() => {
    if (pdfFile && pdfFile.length === 1) {
      let url = window.URL.createObjectURL(pdfFile[0]);
      if (url !== pdfUrl) {
        setPdfUrl(url);
      }
    }
  }, [pdfFile]);

  const UpdateTicket = (
    body: ITicketUpdate,
    bodyUpdateDetail?: any,
    bodyUpdateMultipleDetail?: any
  ) => {
    if (loggedUser) {
      setProcessing(true);
      
      if (ticketStatus?.alreadyDisputed) {
        body.ticketContested = ticketStatus?.alreadyDisputed;
      }
      ApiService.TicketController.UpdateTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          if (
            body.ticketTypeID === 1 ||
            body.ticketTypeID === 5 ||
            body.ticketTypeID === 6
          ) {
            let tmpBody: any = { ticketDetails: bodyUpdateMultipleDetail };
            UpdateMultipleTicketDetail(tmpBody);
          } else {
            UpdateTicketDetail(bodyUpdateDetail);
          }
          if (ticketStatus?.alreadyPaid) {
            let tmp = stepperTicket!.stepPayment ?? [];
            let bodyUpdate = [];
            let bodyInsert = [];
            for (let i = 0; i < tmp.length; i++) {
              if (tmp[i].ticketpaymentID) {
                bodyUpdate.push(tmp[i]);
              } else {
                bodyInsert.push(tmp[i]);
              }
            }
            if (bodyUpdate.length > 0) {
              for (let i = 0; i < bodyUpdate.length; i++) {
                let tmpPayment: ITicketUpdatePayment = {
                  ticketPaymentID: bodyUpdate[i].ticketpaymentID!,
                  ticketPaymentDT: bodyUpdate[i].ticketPaymentDT!,
                  paidAmount: bodyUpdate[i].paidAmount!,
                  postalCharge: bodyUpdate[i].postalCharge!,
                };
                UpdateTicketPayment(tmpPayment);
              }
            }
            if (bodyInsert.length > 0) {
              for (let i = 0; i < bodyInsert.length; i++) {
                let tmpPayment: ITicketInsertPayment = {
                  ticketID: ticketInsertResponse![0].ticketID!,
                  ticketPaymentDT: bodyInsert[i].ticketPaymentDT!,
                  paidAmount: bodyInsert[i].paidAmount!,
                  postalCharge: bodyInsert[i].postalCharge!,
                };
                InsertTicketPayment(tmpPayment);
              }
            }
          }
          dispatch(TicketsAction.setTicketInsertResponse(response.payload));
          loadTickets();
          ToastMessage(i18next.t("message:TicketUpdateCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const loadTicketsPayment = async (ticketID?: number) => {
    setLoading(true);
    let response: IAPIResponse = await ApiService.TicketController.GetTicketPaymentList(
      ticketID
    );
    let tmp = [];
    if (response.error === null) {
      if (response.payload.length > 0) {
        tmp = response.payload;
      }
    } else {
      ToastMessage(response.error, "error");
    }
    return tmp;
  };

  const loadTickets = (ticketID?: number) => {
    setLoading(true);

    ApiService.TicketController.GetTicketList(
      ticketID,
      null,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp: any[] = [];

          if (ticketID) {
            let tmpPayment: any[] = await loadTicketsPayment(ticketID);
            dispatch(
              TicketsAction.setTicketInsertResponse([
                {
                  ticketID: response.payload[0].ticket.ticketID,
                  ticketIssuingInstitutionName:
                    response.payload[0].ticket.ticketIssuingInstitutionName,
                },
              ])
            );
            let tmpSanction: ITicketInsert = {};
            tmpSanction.ticketTypeID = response.payload[0].ticket.ticketTypeID;
            tmpSanction.ticketNumber = response.payload[0].ticket.ticketNumber;
            tmpSanction.ticketIssuingInstitutionID =
              response.payload[0].ticket.ticketIssuingInstitutionID;
            tmpSanction.ticketDeliveryTypeID =
              response.payload[0].ticket.ticketDeliveryTypeID;
            tmpSanction.ticketPickUpDT = response.payload[0].ticket.ticketPickUpDT;
            tmpSanction.ticketCADDT = response.payload[0].ticket.ticketCADDT;
            tmpSanction.ticketNotificationDT =
              response.payload[0].ticket.ticketNotificationDT;

            dispatch(
              TicketsAction.setTicketDriver({
                driverID: response.payload[0]?.ticketDetail[0]?.driverID,
                driverDisplayName:
                  response.payload[0]?.ticketDetail[0]?.driverDisplayName,
              })
            );

            dispatch(
              TicketsAction.setTicketStatus({
                alreadyDisputed: response.payload[0].ticket.ticketContested,
                alreadyPaid: TicketAlredyPaid(response.payload[0].ticket.ticketStatusID),
              })
            );

            let tmp: any = [];

            for (let i = 0; i < response.payload[0].ticketDetail.length; i++) {
              let tmpBreach: ITicketInsertDetail = {};

              tmpBreach.licenseSuspension =
                response.payload[0].ticketDetail[i].licenseSuspension;
              tmpBreach.licenseSuspensionPeriod =
                response.payload[0].ticketDetail[i].licenseSuspensionPeriod;
              tmpBreach.pointsDeduction =
                response.payload[0].ticketDetail[i].pointsDeduction;
              tmpBreach.pointsDeducted =
                response.payload[0].ticketDetail[i].pointsDeducted;

              tmpBreach.violationDT = response.payload[0].ticketDetail[i].violationDT;
              tmpBreach.vehicleID = response.payload[0].ticketDetail[i].vehicleID;
              tmpBreach.vehicleLicensePlate =
                response.payload[0].ticketDetail[i].vehicleLicensePlate;
              tmpBreach.driverID = response.payload[0].ticketDetail[i].driverID;
              tmpBreach.amount = response.payload[0].ticketDetail[i].amount;
              tmpBreach.ticketDetailID =
                response.payload[0].ticketDetail[i].ticketDetailID;
              tmpBreach.ticketArticleID =
                response.payload[0].ticketDetail[i].ticketArticleID;
              tmpBreach.isEdit = true;
              tmp.push(tmpBreach);
            }

            let tmpAmountDeadlines: ITicketUpdate = {};
            tmpAmountDeadlines.ticketReducedExpirationDT =
              response.payload[0].ticket.ticketReducedExpirationDT;
            tmpAmountDeadlines.ticketReducedAmount =
              response.payload[0].ticket.ticketReducedAmount;
            tmpAmountDeadlines.ticketReducedTaxAmount =
              response.payload[0].ticket.ticketReducedTaxAmount;
            tmpAmountDeadlines.ticketReducedTotalAmount =
              response.payload[0].ticket.ticketReducedTotalAmount;
            tmpAmountDeadlines.ticketExpirationDT =
              response.payload[0].ticket.ticketExpirationDT;
            tmpAmountDeadlines.ticketAmount = response.payload[0].ticket.ticketAmount;
            tmpAmountDeadlines.ticketTaxAmount =
              response.payload[0].ticket.ticketTaxAmount;
            tmpAmountDeadlines.ticketTotalAmount =
              response.payload[0].ticket.ticketTotalAmount;

            dispatch(
              TicketsAction.setStepperTickets({
                ...stepperTicket!,
                stepAmountDeadlines: tmpAmountDeadlines,
                stepBreach: tmp,
                stepSanction: tmpSanction,
                stepPayment: tmpPayment,
              })
            );
          } else {
            tmp = response.payload.map((x: ITicketInfo) => {
              let tmpRow = {
                ticketID: x.ticket.ticketID,
                ticketNumber: x.ticket.ticketNumber,
                ticketTypeName: x.ticket.ticketTypeName,
                ticketDT: displayUTC0_ISODate(x.ticket.ticketDT, true),
                ticketIssuingInstitutionName: x.ticket.ticketIssuingInstitutionName,
                ticketReducedExpirationDT: displayUTC0_ISODate(x.ticket.ticketReducedExpirationDT, true),
                ticketReducedExpirationDTOriginal: x.ticket.ticketReducedExpirationDT,
                reducedTotalAmount: currencyDecoder(x.ticket.reducedTotalAmount, 2),
                reducedTotalAmountOriginal: x.ticket.reducedTotalAmount,
                ticketExpirationDT: displayUTC0_ISODate(x.ticket.ticketExpirationDT, true),
                ticketExpirationDTOriginal: x.ticket.ticketExpirationDT,
                totalAmount: currencyDecoder(x.ticket.totalAmount, 2),
                totalAmountOriginal: x.ticket.totalAmount,
                paidAmount: currencyDecoder(x.ticket.paidAmount, 2),
                paidAmountOriginal: x.ticket.paidAmount,
                vehicleLicensePlate:
                  x.ticketDetail.length === 0
                    ? null
                    : x.ticketDetail.length === 1
                    ? x.ticketDetail[0].vehicleLicensePlate
                    : "multiple",
                driverDisplayName:
                  x.ticketDetail.length === 0
                    ? null
                    : x.ticketDetail.length === 1
                    ? x.ticketDetail[0].driverDisplayName
                    : "multiple",
                driverDisplayNameMultiple: x.ticketDetail.map((x: any) => {
                  return x;
                }),
                vehicleLicensePlateMultiple: x.ticketDetail.map((x: any) => {
                  return x;
                }),
                ticketStatusName: x.ticket.ticketContested
                  ? i18next.t("navigation:_tickets_page_contested")
                  : x.ticket.ticketStatusID === 5
                  ? i18next.t("navigation:_tickets_page_paid")
                  : x.ticket.ticketStatusName,
                ticketStatusNameContested: x.ticket.ticketStatusName,
                ticketContested: x.ticket.ticketContested,
                ticketStatusID: x.ticket.ticketStatusID,
              };

              return tmpRow;
            });

            setTicketRows(tmp);

            setTicketRowsCopy(tmp);
          }
        } else {
          ToastMessage(response.error, "error");
        }

        if (ticketID) {
          SummonModal("editBreach");
        }

        setLoading(false);
      }
    );
  };

  const DeleteTicket = (ticketID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.DeleteTicket(ticketID, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:ticketDeleted"), "success");
          loadTickets();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const loadLangs = () => {
    ApiService.DriverController.DriverGetLanguages((response: IAPIResponse) => {
      if (response.error === null) {
        setLanguages(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadTickets();
    loadCurrentAssignment();
    loadTicketTypes();
    loadLangs();

    (window as any)["pdf-pro-data-results"] = undefined;
  }, []);

  useEffect(() => {
    let tmpTicketRows: any[] = [];
    let tmp = [...ticketRowsCopy];
    if (filterStatusticket.filter((x: any) => x.selected === true).length === 0) {
      setTicketRows(ticketRowsCopy);
    } else {
      for (let i = 0; i < filterStatusticket.length; i++) {
        if (filterStatusticket[i].selected) {
          for (let j = 0; j < tmp.length; j++) {
            if (tmp[j].statusTicket === filterStatusticket[i].status) {
              tmpTicketRows.push(tmp[j]);
            }
          }
        }
      }
      setTicketRows(tmpTicketRows);
    }
  }, [filterStatusticket]);

  const EditTicket = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            setTicketID(props.row.ticketID);
            SummonModal("attachFile");
          }}
        >
          <AttachFileIcon className="icon-table" />
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem("typeInsurance", "2");
            loadTickets(props.row.ticketID);
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const StatusTicket = (props: GridRenderCellParams) => {
    let tmpTickets: any[] = [...ticketRows];
    let color: string = "";
    let now = new Date().getTime();
    let expirationDT = new Date(props.row.ticketExpirationDTFormat).getTime();
    if (now > expirationDT && props.row.ticketStatusID !== 3) {
      color = "red";
    } else {
      switch (props.row.ticketStatusID) {
        case 1:
          color = "white";
          break;
        case 3:
          color = "green";
          break;
        case 5:
          color = "orange";
          break;
      }
    }

    if (tmpTickets.length > 0) {
      for (let i = 0; i < tmpTickets.length; i++) {
        if (
          tmpTickets && props.row.ticketID === tmpTickets[i].ticketID &&
          !tmpTickets[i].statusTicket
        ) {
          tmpTickets[i].statusTicket = color;

          setTicketRows(tmpTickets);

          setTicketRowsCopy(tmpTickets);
        }
      }
    }

    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EuroIcon
            className="euro-wrapper"
            style={{
              backgroundColor: color,
              color:
                props.row.ticketStatusID === 1 && color !== "red" ? "black" : "white",
              border:
                props.row.ticketStatusID === 1 && color !== "red"
                  ? "1px solid black"
                  : "",
            }}
          />
          <div style={{ marginLeft: "0.5em" }}>{props.value}</div>
        </div>
      </>
    );
  };

  const TicketAlredyPaid = (id: number) => {
    switch (id) {
      case 1:
      case 2:
      case 4:
        return false;
      case 3:
      case 5:
        return true;
      default:
        return false;
    }
  };

  const multiplePlate = (props: GridRenderCellParams) => {
    if (props.value === "multiple") {
      return (
        <Button
          onClick={() => {
            setPlateList(props.row.vehicleLicensePlateMultiple);
            SummonModal("multiplePlate");
          }}
        >
          multiple
        </Button>
      );
    } else {
      return <div>{props.value}</div>;
    }
  };

  const multipleDriver = (props: GridRenderCellParams) => {
    if (props.value === "multiple") {
      return (
        <Button
          onClick={() => {
            setDriverList(props.row.driverDisplayNameMultiple);
            SummonModal("multipleDriver");
          }}
        >
          multiple
        </Button>
      );
    } else {
      return <div>{props.value}</div>;
    }
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: " ",
      renderCell: EditTicket,
      type: 'custom'
    },
    {
      field: "ticketStatusName",
      headerName: i18next.t("navigation:_tickets_page_column_title_ticket_status").toUpperCase(),
      renderCell: StatusTicket,
    },
    {
      field: "ticketNumber",
      headerName: i18next.t("form:VerbalNumber").toUpperCase(),
    },
    {
      field: "vehicleLicensePlate",
      headerName: i18next.t("navigation:_tickets_page_column_title_plate").toUpperCase(),
      renderCell: multiplePlate,
    },
    {
      field: "driverDisplayName",
      headerName: i18next.t("navigation:_tickets_page_column_assignee").toUpperCase(),
      renderCell: multipleDriver,
    },
    {
      field: "ticketReducedExpirationDT",
      headerName: i18next.t("navigation:_tickets_page_column_data_reduced_expiry").toUpperCase(),
      fieldOriginal: "ticketReducedExpirationDTOriginal",
    },
    {
      field: "reducedTotalAmount",
      headerName: i18next.t("navigation:_tickets_page_column_reduce_amount").toUpperCase(),
      type: "number",
      fieldOriginal: "reducedTotalAmountOriginal",
    },
    {
      field: "ticketExpirationDT",
      headerName: i18next.t("navigation:_tickets_page_column_data_expiry").toUpperCase(),
      fieldOriginal: "ticketExpirationDTOriginal",
    },
    {
      field: "totalAmount",
      headerName: i18next.t("navigation:_tickets_page_column_amount").toUpperCase(),
      type: "number",
      fieldOriginal: "totalAmountOriginal",
    },
    {
      field: "paidAmount",
      headerName: i18next.t("navigation:_tickets_page_column_amount_paid").toUpperCase(),
      type: "number",
      fieldOriginal: "paidAmountOriginal",
    },
    {
      field: "ticketTypeName",
      headerName: i18next.t("navigation:_tickets_page_column_type_of_ticket").toUpperCase(),
    },
    {
      field: "ticketIssuingInstitutionName",
      headerName: i18next.t("navigation:_tickets_page_column_issuer_entity").toUpperCase(),
    },
  ];

  const proPDFRead = (pdfFormData: any) => {
    if (pdfFormData.languageID && pdfFormData.ticketTypeID && pdfFile.length === 1) {
      setErrorPdf("");
      let languageID = pdfFormData.languageID;
      let ticketTypeID = pdfFormData.ticketTypeID;
      let pdf = pdfFile[0];

      setLoadingPdfPro(true);

      ApiService.TicketController.TicketAddProPDF(
        ticketTypeID,
        languageID,
        pdf,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPdfProResponse({
              ...response.payload,
              ticketTypeID: ticketTypeID,
              languageID: languageID,
            });
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPdfPro(false);
        }
      );
    } else {
      setErrorPdf(i18next.t("navigation:_all_fields_are_required") ?? "");
    }
  };

  return (
    <div className="ticket-wrapper">
      <HeaderPageDashboard title={i18next.t("navigation:_add_services_tickets")} />

      <div className="data-grid-wrapper margin-top-small">
        <div className="data-grid-header column-flex-on-small-screen">
          <strong style={{ marginRight: "1em" }}>
            {i18next.t("navigation:_vheicles_tab_nav_tickets")}
          </strong>

          <div style={{ width: "100%" }}>
            <div className="button-line-tickets column-flex-on-small-screen">
              <ToggleButton
                value={"orange"}
                color={"primary"}
                selected={
                  filterStatusticket.find((x: any) => x.status === "orange").selected
                }
                onChange={(_, value: any) => {
                  let tmp: any[] = [...filterStatusticket];
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].status === value) {
                      tmp[i].selected = !tmp[i].selected;
                    }
                  }
                  setFilterStatusticket(tmp);
                }}
              >
                <EuroIcon
                  className="euro-wrapper"
                  style={{ backgroundColor: "orange", color: "white" }}
                />
                <div style={{ marginLeft: "0.5em", marginRight: "1em" }}>
                  {i18next.t("navigation:_tickets_page_insufficient_payment")}
                </div>
              </ToggleButton>

              <ToggleButton
                value={"red"}
                color={"primary"}
                selected={
                  filterStatusticket.find((x: any) => x.status === "red").selected
                }
                onChange={(_, value: any) => {
                  let tmp: any[] = [...filterStatusticket];
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].status === value) {
                      tmp[i].selected = !tmp[i].selected;
                    }
                  }
                  setFilterStatusticket(tmp);
                }}
              >
                <EuroIcon
                  className="euro-wrapper"
                  style={{ backgroundColor: "red", color: "white" }}
                />
                <div style={{ marginLeft: "0.5em", marginRight: "1em" }}>
                  {i18next.t("navigation:_tickets_page_payment_expired")}
                </div>
              </ToggleButton>

              <ToggleButton
                value={"green"}
                color={"primary"}
                selected={
                  filterStatusticket.find((x: any) => x.status === "green").selected
                }
                onChange={(_, value: any) => {
                  let tmp: any[] = [...filterStatusticket];
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].status === value) {
                      tmp[i].selected = !tmp[i].selected;
                    }
                  }
                  setFilterStatusticket(tmp);
                }}
              >
                <EuroIcon
                  className="euro-wrapper"
                  style={{ backgroundColor: "green", color: "white" }}
                />
                <div style={{ marginLeft: "0.5em", marginRight: "1em" }}>
                  {i18next.t("navigation:_tickets_page_correct_payment")}
                </div>
              </ToggleButton>

              <ToggleButton
                value={"white"}
                color={"primary"}
                selected={
                  filterStatusticket.find((x: any) => x.status === "white").selected
                }
                onChange={(_, value: any) => {
                  let tmp: any[] = [...filterStatusticket];
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].status === value) {
                      tmp[i].selected = !tmp[i].selected;
                    }
                  }
                  setFilterStatusticket(tmp);
                }}
              >
                <EuroIcon
                  className="euro-wrapper"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                />
                <div style={{ marginLeft: "0.5em", marginRight: "1em" }}>
                  {i18next.t("navigation:_tickets_page_payment_to_be_made")}
                </div>
              </ToggleButton>
            </div>
          </div>

          <div>
            <Button
              variant="contained"
              className="button-data-grid-header"
              onClick={() => {
                dispatch(TicketsAction.setStepperTickets(undefined));
                dispatch(TicketsAction.setTicketInsertResponse(undefined));
                dispatch(TicketsAction.setTicketInsertDetailResponse(undefined));
                dispatch(TicketsAction.setTicketInsertMultipleDetailResponse(undefined));
                dispatch(TicketsAction.setTicketInsertPaymentResponse(undefined));
                dispatch(TicketsAction.setTicketStatus(undefined));
                dispatch(TicketsAction.setTicketDriver(undefined));

                SummonModal("ticket-add-modal-service");
              }}
            >
              <div style={{ whiteSpace: "pre" }}>
                <div>{"+ " + i18next.t("navigation:_add_ticket")}</div>
              </div>
            </Button>
          </div>
        </div>

        <div className="data-grid">
          <DataGridWrap headers={columnsDef} rows={ticketRows} tableName="Tickets" />
        </div>

        <SmartModal
          title={i18next.t("navigation:_add_ticket_wich")}
          modalUniqueId="ticket-add-modal-service"
          buttons={[
            {
              text: i18next.t("navigation:_add_ticket_wich_2"),
              disabled:
                addonService.filter(
                  (x: IAddedService) => x.addedServiceCode === "TICKET_ADDON_PRO"
                ).length === 0,
              onClick: () => {
                DismissModal("ticket-add-modal-service");
                setPdfProResponse(null);
                setPdfFile([]);
                SummonModal("import-ticket-from-scan-PDF");
              },
              forcePrimary: true,
            },
            {
              text: i18next.t("navigation:_add_ticket_wich_1"),
              onClick: () => {
                DismissModal("ticket-add-modal-service");
                navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.TICKETS_MANAGEMENT);
              },
              forcePrimary: true,
            },
          ]}
          modalInnerComponent={
            <div>
              {addonService.filter(
                (x: IAddedService) => x.addedServiceCode === "TICKET_ADDON_PRO"
              ).length === 0 && (
                <Alert severity="info">
                  {i18next.t("navigation:ticket_import_which_meta")}
                </Alert>
              )}
            </div>
          }
        />

        <SmartModal
          modalUniqueId="import-ticket-from-scan-PDF"
          modalInnerComponent={
            <div>
              {pdfProResponse === null && (
                <div>
                  <Alert severity="info">
                    {i18next.t("navigation:ticket_scan_pdf_guide")}
                  </Alert>

                  <br />

                  <MultiForm
                    formId="pdf_pro_scan_form"
                    formTitle={""}
                    suppressLayout
                    suppressSubmit
                    onSubmit={(data: any) => {
                      proPDFRead(data);
                    }}
                    inputs={[
                      {
                        width: 100,
                        type: "select",
                        name: "ticketTypeID",
                        label: i18next.t("navigation:_ticket_type") ?? "",
                        required: true,
                        options: ticketTypePro.map((x: ITicketTypeGet) => {
                          return {
                            key: x.ticketTypeID,
                            text: x.ticketTypeName,
                          };
                        }),
                      },
                      {
                        width: 100,
                        type: "select",
                        name: "languageID",
                        label: i18next.t("navigation:_ticket_lang") ?? "",
                        required: true,
                        options: languages.map((x: IISOLanguage) => {
                          return {
                            key: x.languageID,
                            text: x.languageName,
                          };
                        }),
                      },
                    ]}
                  />

                  <br />

                  <div className="button-load-supplies-wrapper">
                    <div className="box">
                      {pdfFile.length === 0 && (
                        <>
                          <div
                            style={{ cursor: "pointer" }}
                            className="upload-icon-wrapper"
                            onClick={() => {
                              document.getElementById("uploadFile")?.click();
                            }}
                          >
                            <UploadIcon fontSize="large" />
                          </div>
                          <div className="title-wrapper">
                            {i18next.t(
                              "navigation:_stamp_payment_download_upload_subtitle1"
                            )}
                          </div>
                          <div className="button-select-file">
                            <input
                              id="uploadFile"
                              hidden
                              accept=".pdf"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setPdfFile(e.target.files as any);
                                }
                              }}
                            />
                          </div>
                          <div className="accepted-file-wrapper">
                            {i18next.t("navigation:_forniture_page_supported_files")}:
                            .pdf
                          </div>
                        </>
                      )}
                      {pdfFile.length > 0 && (
                        <>
                          <div className="upload-icon-wrapper">
                            <UploadIcon fontSize="large" />
                          </div>
                          <div className="title-wrapper">
                            {pdfFile.length > 0 ? pdfFile[0].name : ""}
                          </div>
                          <div className="button-select-file">
                            <Button
                              variant="contained"
                              className="button"
                              onClick={() => {
                                document.getElementById("uploadFile")?.click();
                              }}
                            >
                              {i18next.t("navigation:roadtax_page_upload_another_file")}
                              <input
                                id="uploadFile"
                                hidden
                                accept=".pdf"
                                type="file"
                                onChange={(e) => {
                                  if (e.target.files && e.target.files.length === 1) {
                                    setPdfFile(e.target.files as any);
                                  }
                                }}
                              />
                            </Button>
                          </div>
                          <div className="accepted-file-wrapper">
                            {i18next.t("navigation:_forniture_page_supported_files")}:
                            .pdf
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {errorPdf !== "" && <Alert severity="error">{errorPdf}</Alert>}
                </div>
              )}

              {pdfProResponse && (
                <div className="flex-pdf-scan">
                  <iframe className="iframe-pdf-scan-preview" src={pdfUrl} />

                  <div className="iframe-near-column">
                    {!loadingPdfPro && (
                      <PdfProAutoForm
                        data={pdfProResponse}
                        onSubmit={(data: any) => {
                          let result: any = { ...pdfProResponse, ...data };

                          let dtos = result.infringementArticle?.ticketArticleDTOs ?? [];

                          if (
                            dtos.length > 0 &&
                            result.articleSelectedByUser === undefined
                          ) {
                            ToastMessage(
                              i18next.t("navigation:select_one_article"),
                              "warning"
                            );
                            return;
                          }

                          let istitution =
                            result.ticketIssuingInstitution &&
                            result.ticketIssuingInstitution.length > 0
                              ? result.ticketIssuingInstitution[0]
                              : null;
                              
                          (window as any)["pdf-pro-data-results"] = {
                            ...result,
                            ticketIssuingInstitutionName:
                              istitution?.ticketIssuingInstitutionName,
                          };
                          DismissModal("import-ticket-from-scan-PDF");

                          setTimeout(() => {
                            navigate(
                              AppRoutes.INTERNAL_DASHBOARD + AppRoutes.TICKETS_MANAGEMENT
                            );
                          }, 200);
                        }}
                        onChange={(data: any) => {
                          setPdfProResponse({ ...pdfProResponse, ...data });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}

              <LoaderBackdrop loading={loadingPdfPro} />
            </div>
          }
          title={i18next.t("navigation:_add_ticket_wich_2")}
          buttons={[
            {
              text: i18next.t("navigation:accept"),
              onClick: () => {
                triggerFormValidation("pdf_pro_scan_form");
              },
            },
          ]}
        />
      </div>

      <SmartModal
        modalUniqueId={"editBreach"}
        title={""}
        buttons={[
          {
            text: i18next.t("navigation:_tickets_page_delete_ticket"),
            onClick: () => {
              DeleteTicket(ticketInsertResponse![0].ticketID!);
              DismissModal("editBreach");
            },
            disabled: false,
          },
          {
            text: i18next.t("navigation:_tickets_page_edit_ticket"),
            onClick: () => {
              let tmpUpdate: any = {};
              let tmpSanction: ITicketInsert = {
                ...stepperTicket?.stepSanction,
              };
              tmpUpdate.ticketTypeID = tmpSanction.ticketTypeID;
              tmpUpdate.ticketNumber = tmpSanction.ticketNumber;
              tmpUpdate.ticketIssuingInstitutionID =
                tmpSanction.ticketIssuingInstitutionID;
              tmpUpdate.ticketDeliveryTypeID = tmpSanction.ticketDeliveryTypeID;
              tmpUpdate.ticketPickUpDT = tmpSanction.ticketPickUpDT;
              tmpUpdate.ticketCADDT = tmpSanction.ticketCADDT;
              tmpUpdate.ticketNotificationDT = tmpSanction.ticketNotificationDT;
              tmpUpdate.ticketID = ticketInsertResponse![0].ticketID;
              let tmpBreach: any = { ...stepperTicket?.stepBreach! };
              tmpBreach = Object.values(tmpBreach);
              let tmpUpdateDetail: any = {};
              let tmpUpdateMultipleDetail: any[] = [];
              
              let isMultiple = false;
              if (tmpBreach.length === 1) {
                tmpUpdateDetail.linkedTicketID = tmpBreach[0].linkedTicketID;
                tmpUpdateDetail.linkedTicketNumber = tmpBreach[0].linkedTicketNumber;
                tmpUpdateDetail.ticketArticleID = tmpBreach[0].ticketArticleID;
                tmpUpdateDetail.violationDT = tmpBreach[0].violationDT;
                tmpUpdateDetail.vehicleID =
                  tmpBreach[0].vehicleID !== 0 ? tmpBreach[0].vehicleID : null;
                tmpUpdateDetail.vehicleLicensePlate = tmpBreach[0].vehicleLicensePlate;
                tmpUpdateDetail.driverID = tmpBreach[0].driverID;
                tmpUpdateDetail.pointsDeduction = tmpBreach[0].pointsDeduction == 1;
                tmpUpdateDetail.pointsDeducted = +tmpBreach[0].pointsDeducted;
                tmpUpdateDetail.licenseSuspension = tmpBreach[0].licenseSuspension == 1;
                tmpUpdateDetail.licenseSuspensionPeriod =
                  +tmpBreach[0].licenseSuspensionPeriod;
                tmpUpdateDetail.ticketDetailID = tmpBreach[0].ticketDetailID;
              } else {
                isMultiple = true;
                for (let i = 0; i < tmpBreach.length; i++) {
                  let tmp: any = {};
                  tmp.ticketID = ticketInsertResponse![0].ticketID;
                  tmp.violationDT = tmpBreach[i].violationDT;
                  tmp.amount = tmpBreach[i].amount;
                  tmp.vehicleID =
                    tmpBreach[i].vehicleID !== 0 ? tmpBreach[i].vehicleID : null;
                  tmp.vehicleLicensePlate = tmpBreach[i].vehicleLicensePlate;
                  tmp.driverID = tmpBreach[i].driverID;
                  tmp.ticketDetailID = tmpBreach[i].ticketDetailID;
                  tmpUpdateMultipleDetail.push(tmp);
                }
              }
              let tmpAmountDeadlines: any = stepperTicket?.stepAmountDeadlines;
              tmpUpdate.ticketReducedExpirationDT =
                tmpAmountDeadlines.ticketReducedExpirationDT;
              tmpUpdate.ticketReducedAmount = tmpAmountDeadlines.ticketReducedAmount;
              tmpUpdate.ticketReducedTaxAmount =
                tmpAmountDeadlines.ticketReducedTaxAmount;
              tmpUpdate.ticketReducedTotalAmount =
                tmpAmountDeadlines.ticketReducedTotalAmount;
              tmpUpdate.ticketExpirationDT = tmpAmountDeadlines.ticketExpirationDT;
              tmpUpdate.ticketAmount = tmpAmountDeadlines.ticketAmount;
              tmpUpdate.ticketTaxAmount = tmpAmountDeadlines.ticketTaxAmount;
              tmpUpdate.ticketTotalAmount = tmpAmountDeadlines.ticketTotalAmount;
              if(ticketStatus?.alreadyPaid){
                let tmpPayment: ITicketInsertPayment[] = [...stepperTicket?.stepPayment!];
                let errorPayments: number = 0;
                for(let i = 0; i < tmpPayment.length; i++){
                  if(tmpPayment[i].paidAmount === undefined || tmpPayment[i].postalCharge === undefined || tmpPayment[i].ticketPaymentDT === undefined){
                    errorPayments++;
                  }
                }
                if(tmpPayment.length === 0){
                  errorPayments++;
                }
                if(errorPayments > 0){
                  ToastMessage(i18next.t("navigation:_tickets_page_payment_amount_required"), "warning");
                }else{
                  if (isMultiple) {
                    UpdateTicket(tmpUpdate, null, tmpUpdateMultipleDetail);
                  } else {
                    UpdateTicket(tmpUpdate, tmpUpdateDetail, null);
                  }
                  DismissModal("editBreach");
                }
              }else {
                if (isMultiple) {
                  UpdateTicket(tmpUpdate, null, tmpUpdateMultipleDetail);
                } else {
                  UpdateTicket(tmpUpdate, tmpUpdateDetail, null);
                }
                DismissModal("editBreach");
              }
            },
            disabled: false,
          },
        ]}
        modalInnerComponent={
          <div>
            <div className="modal-header">
              {driverTicket ? (
                <>
                  <div>
                    <strong>Driver: {driverTicket.driverDisplayName}</strong>
                    <Button
                      style={{ color: "grey" }}
                      onClick={() => {
                        SummonModal("changeAssignment");
                      }}
                    >
                      <EditIcon style={{ padding: "0.1em" }} />
                    </Button>
                  </div>
                </>
              ) : (
                <div></div>
              )}
              {ticketInsertResponse && ticketInsertResponse.length > 0 && (
                <Button
                  onClick={() => {
                    SummonModal("send-email-to-driver-modal");
                  }}
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  {i18next.t("navigation:sendEmailToDriver")}
                </Button>
              )}
            </div>

            <StepSanctionData header={false} />

            <StepBreachData header={false} />

            <StepAmountDeadelines header={false} />
              
            {ticketInsertResponse && (ticketInsertResponse ?? []).length > 0 && ticketStatus?.alreadyPaid &&
              (ticketRows ?? []).find(
                (x: any) => ticketInsertResponse[0].ticketID === x.ticketID
              )?.statusTicket === "orange" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1em",
                  }}
                >
                  <ErrorOutlineIcon color="warning" style={{ marginRight: "1em" }} />{" "}
                  <strong>{i18next.t("navigation:_tickets_alert_payment")}</strong>
                </div>
              )}
            <StepAttachmentsTickets header={false} />
          </div>
        }
      />

      <SmartModal
        modalUniqueId={"attachFile"}
        title={""}
        modalInnerComponent={
          <div className="attachment-wrapper">
            <AttachementMainHandler
              attachmentFamily={"ticket"}
              extraGetParametersVector={
                ticketID
                  ? [
                      {
                        name: "ticketID",
                        value: ticketID,
                      },
                    ]
                  : undefined
              }
            />
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("attachFile");
            },
          },
        ]}
      />

      <SmartModal
        modalUniqueId={"multipleDriver"}
        title={i18next.t("navigation:_tickets_page_payment_driver_list")}
        modalInnerComponent={
          <div>
            {driverList.map((x: any) => {
              return <div className="multiple-driver-wrapper">{x.driverDisplayName}</div>;
            })}
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("multipleDriver");
            },
          },
        ]}
      />

      <SmartModal
        modalUniqueId={"multiplePlate"}
        title={i18next.t("navigation:_tickets_page_payment_plate_list")}
        modalInnerComponent={
          <div>
            {plateList.map((x: any) => {
              return (
                <div className="multiple-driver-wrapper">{x.vehicleLicensePlate}</div>
              );
            })}
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("multiplePlate");
            },
          },
        ]}
      />

      <SmartModal
        modalUniqueId={"changeAssignment"}
        title={i18next.t("navigation:_tickets_page_payment_change_assignment")}
        modalInnerComponent={
          <div>
            <div className="change-assignment-header">
              {i18next.t("navigation:_tickets_page_payment_infrigment_assignment")}
            </div>
            <BetterSelect
              onChange={(value) => {
                let tmp = currentAssignment.find((x: any) => {
                  return x.driverID.toString() === value.toString();
                });
                if (tmp) {
                  setAssignmentSelected(tmp);
                }
              }}
              name={""}
              type={"select"}
              options={currentAssignment.map((x: any) => {
                return {
                  key: x.driverID,
                  text: x.driverName + " " + x.driverSurname,
                };
              })}
            />
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:reject"),
            onClick: () => {
              DismissModal("changeAssignment");
            },
          },
          {
            text: i18next.t("navigation:_tickets_page_payment_owner_change"),
            onClick: () => {
              dispatch(
                TicketsAction.setTicketDriver({
                  driverID: assignmentSelected?.driverID,
                  driverDisplayName:
                    assignmentSelected.driverName +
                    " " +
                    assignmentSelected.driverSurname,
                })
              );
              let tmp: any = { ...stepperTicket };
              let tmpStepBreach: any = tmp.stepBreach;
              tmpStepBreach[0].driverID = assignmentSelected?.driverID;
              tmp.stepBreach = tmpStepBreach;
              dispatch(TicketsAction.setStepperTickets(tmp));
              DismissModal("changeAssignment");
            },
          },
        ]}
      />
      <SmartModal 
        modalUniqueId={"send-email-to-driver-modal"} 
        title={i18next.t("navigation:sendEmailToDriver")} 
        onAccept={() => {
          sendTicketMailToDriverManually({
            ticketID: ticketInsertResponse![0].ticketID ?? 0
          });
        }}
        onReject={() => {
          DismissModal("send-email-to-driver-modal");
        }}
        modalInnerComponent={
          <div>
            {i18next.t("navigation:sendEmailToDriverMessage")}
          </div>
        }
      />
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default Tickets__AdditionalServicesPage;
