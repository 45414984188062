import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleDamageInsert,
  IVehicleDamageUpdate,
} from "../../Models/IVehicleDamage";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleDamageController = {
  VehicleDamageGet: async (
    vehicleID: number,
    vehicleDamageID: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DAMAGE_GET;
    let parameters: string[] = [];
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (vehicleDamageID) {
      parameters.push(`vehicleDamageID=${vehicleDamageID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDamageInsert: async (
    body: IVehicleDamageInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DAMAGE_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDamageUpdate: async (
    body: IVehicleDamageUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DAMAGE_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDamageDelete: async (
    vehicleDamageID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DAMAGE_DELETE;
    request.method = "DELETE";
    request.payload = {
      vehicleDamageID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleDamageRepair: async (
    vehicleDamageID: number,
    vehicleDamageCost: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DAMAGE_REPAIR;
    request.method = "PUT";
    request.payload = {
      vehicleDamageID,
      vehicleDamageCost,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleDamageController;
