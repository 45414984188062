import './WhyUsPage.scss'
import AccordionFaq from '../../Components/AccordionFaq/AccordionFaq';
import {IGET_FAQ_BY_CATEGORY} from '../../Models/IFAQ';
import {useEffect, useState} from 'react';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/Internal/AjaxService';
import {ToastMessage} from '../../Utils/Toastify';
import LoaderBackdrop from '../../Components/LoaderBackdrop/LoaderBackdrop';
import BannerVideoComponent from "../../Components/BannerVideoComponent/BannerVideoComponent";
import {Trans} from "react-i18next";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";

const WhyUsPage = () => {

    const [faqByCategory, setFaqByCategory] = useState<IGET_FAQ_BY_CATEGORY[]>([]);
    const [loadingFaqByCategory, setLoadingFaqByCategory] = useState<boolean>(false);

    const GET_FAQ_BY_CATEGORY = (faqCategoryID?: number, languageID?: number) => {
        setLoadingFaqByCategory(true);

        ApiService.FAQController.GET_FAQ_BY_CATEGORY(
            null,
            languageID || null,
            1,
            null,
            (response: IAPIResponse) => {
                if (response.error === null) {
                    const payload = response.payload;
                    setFaqByCategory(payload);
                } else {
                    ToastMessage(response.error, "error");
                }

                setLoadingFaqByCategory(false);
            }
        );
    };

    useEffect(() => {
        GET_FAQ_BY_CATEGORY();
    }, []);

    return (
        <div>

            <BannerVideoComponent
                filename='315798484-small.mp4'
                title={<Trans i18nKey='navigation:faqTitle' components={[<strong/>, <br/>]}/>}
            />

            <BannerCardComponent
                variant='light'
                leftTitle={<Trans i18nKey='navigation:faqHome'/>}
                leftContent={
                    <div className="ff-extra-faq-container">
                        <div className='accordin-wrapper'>
                            {!loadingFaqByCategory && faqByCategory.map((x: IGET_FAQ_BY_CATEGORY, i: number) => {
                                return <div key={x.faqID} className='single-accordion'><AccordionFaq infoFaq={x} isPublic/></div>;
                            })}
                        </div>
                    </div>
                }
                rightContent={
                    <div className="ff-image-box ff-img-right">
                        <img src={'/Images/newImages/443055016.jpeg'} alt="" loading="lazy"/>
                    </div>
                }
            />

            <LoaderBackdrop loading={loadingFaqByCategory}/>

        </div>
    );
};

export default WhyUsPage