import { useEffect, useState } from "react";
import i18next from "i18next";

import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import PlaceAutoCompleteForm from "../PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import { IGoogleAddress } from "../../Utils/Google";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { IUser } from "../../Models/IUser";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";

import {
  ISupplierGetType,
  ISupplierInsertData,
  ISupplierGetInfo,
  ISupplierDeleteData,
} from "../../Models/ISupplier";
import { ICompanyGet } from "../../Models/ICompany";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";

import {
  validationVAT,
  validationPhone,
  validationErrorMessages,
} from "../../Utils/Validation";
import { JSONPrint } from "../../Utils/Decoder";

export interface IFormSupplier {
  loggedUser: IUser | undefined;
  data: any;
  numPage?: number;
  supplierSideList?: any;
  loadSuppliersSideList?: () => void;
  doRefreshForm?: () => void;
  idModal?: string;
  lockForm?: string;
  forceSupplierTypeCode?: string;
  forceSupplierTypeID?: number;
  newSupplierTypeList?: any[string];
  updateList?: (data: any) => void;
}

let stateClone: any = {};

const FormSupplier = (props: IFormSupplier) => {
  // Company
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* STATES */
  // State for data container
  const [state, setState_] = useState<any>(props.data ?? {});

  // State for supplierType and genericSupplier
  const [supplierType, setSupplierType] = useState<string | undefined | null>(
    props.data && props.data.supplierTypeID
      ? props.data.supplierTypeID.toString()
      : null
  );
  const [genericSupplier, setGenericSupplier] = useState<
    string | undefined | null
  >(
    props.data && props.data.genericSupplierID
      ? props.data.genericSupplierID.toString()
      : null
  );

  // State for company list select
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);

  // State for supplier list select
  const [supplierTypeList, setSupplierTypeList] = useState<ISupplierGetType[]>(
    []
  );

  // State for supplier info list
  const [supplierListInfo, setSupplierListInfo] = useState<ISupplierGetInfo[]>(
    []
  );

  // Lock the form and disable the inputs
  const [lockForm, setLockForm] = useState<boolean>(
    props.lockForm === "disabled" ? false : true
  );
  const [lockFormAll, setLockFormAll] = useState<boolean>(
    props.lockForm === "disabled" ? false : true
  );

  // State for spinning and loading the process
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingSupplierInfo, setLoadingSupplierInfo] =
    useState<boolean>(false);
  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);
  const [loadingSupplierGeneric, setLoadingSupplierGeneric] =
    useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);

  /* GET */
  // API to load suppliers select list
  const loadSupplierList = () => {
    setLoadingSupplierList(true);
    if (props.loggedUser) {
      ApiService.SupplierController.SupplierGetType(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierTypeList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  // API to get the info based on the supplier type
  const loadSupplierInfo = (supplierTypeID: string) => {
    setLoadingSupplierInfo(true);
    ApiService.SupplierController.SupplierGetInfo(
      supplierTypeID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setSupplierListInfo(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingSupplierInfo(false);
      }
    );
  };

  // API to get supplier generic data
  const loadSupplierGeneric = (GenericSupplierID: number) => {
    setLoadingSupplierGeneric(true);
    ApiService.SupplierController.SupplierGetGeneric(
      GenericSupplierID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload && response.payload.length > 0) {
            setState({ ...state, ...response.payload[0] });
            setLockForm(false);
            requestFormRefresh();
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingSupplierGeneric(false);
      }
    );
  };

  // API to load company select list
  const loadCompanyList = () => {
    setLoadingCompany(true);
    if (props.loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompany(false);
        }
      );
    }
  };

  /* INSERT */
  // API to insert a supplier
  const insertSupplier = (data: ISupplierInsertData) => {
    setProcessing(true);
    ApiService.SupplierController.SupplierInsertData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullAdded"),
            "success"
          );
          if (props.loadSuppliersSideList) {
            props.loadSuppliersSideList();
          }
          if (props.updateList) {
            props.updateList(response.payload);
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
          setState({}); // Clean the state so inputs are empty
        } else {
          if (
            response.error &&
            response.error.includes("ists Supplier associated to")
          ) {
            ToastMessage(
              i18next.t("error:supplier_error_duplicated_vat"),
              "error"
            );
          } else {
            let errors = validationErrorMessages(response.payload);
            if (response.raw.status === 402) {
              ToastMessage(i18next.t("error:" + response.error), "error");
            } else {
              if (errors) {
                for (let e = 0; e < errors.length; e++) {
                  ToastMessage(`${errors[e]}`, "error");
                }
              } else {
                ToastMessage(response.error, "error");
              }
            }
          }
        }

        setProcessing(false);
      }
    );
  };

  /* UPDATE */
  // API to update a supplier
  const updateSupplier = (data: ISupplierInsertData) => {
    setProcessing(true);
    ApiService.SupplierController.SupplierUpdateData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullUpdated"),
            "success"
          );
          if (props.loadSuppliersSideList) {
            props.loadSuppliersSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          if (
            response.error &&
            response.error.includes("ists Supplier associated to")
          ) {
            ToastMessage(
              i18next.t("error:supplier_error_duplicated_vat"),
              "error"
            );
          } else {
            let errors = validationErrorMessages(response.payload);
            if (response.raw.status === 402) {
              ToastMessage(i18next.t("error:" + response.error), "error");
            } else {
              if (errors) {
                for (let e = 0; e < errors.length; e++) {
                  ToastMessage(`${errors[e]}`, "error");
                }
              } else {
                ToastMessage(response.error, "error");
              }
            }
          }
        }
        setProcessing(false);
      }
    );
  };

  /* DELETE */
  // API to delete a supplier
  const deleteSupplier = (data: ISupplierDeleteData) => {
    setProcessing(true);
    if (data === null) {
      ToastMessage(i18next.t("message:supplierNotDeleted"), "warning");
    }
    ApiService.SupplierController.SupplierDeleteData(
      data.supplierID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullDeleted"),
            "success"
          );
          setState({}); // Clean the state so inputs are empty

          if (props.loadSuppliersSideList) {
            props.loadSuppliersSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  // Set state
  const setState = (state: any) => {
    stateClone = JSON.parse(JSON.stringify(state));
    setState_(state);
  };

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  /* HOOK EFFECT */
  // Load the page elements
  useEffect(() => {
    loadSupplierList(); // Load suppliers select types
    loadCompanyList(); // Load company list

    if (genericSupplier) {
      if (parseInt(genericSupplier) <= -1) {
        // requestFormRefresh();
        setLockFormAll(false);
        setLockForm(false);
      }
    }
    return () => {
      stateClone = {};
    };
  }, []);

  // Load the supplier info list
  useEffect(() => {
    if (supplierType) {
      loadSupplierInfo(supplierType);
    }
  }, [supplierType]);

  useEffect(() => {
    if (state?.supplierTypeID && state?.supplierTypeID != supplierType) {
      setSupplierType(state?.supplierTypeID);
    }
  }, [state?.supplierTypeID]);

  // Load the supplier generic data
  useEffect(() => {
    if (genericSupplier) {
      if (parseInt(genericSupplier) > 0) {
        loadSupplierGeneric(+genericSupplier);
        setLockFormAll(true);
      } else {
        setState(props.data ?? {supplierTypeID: supplierType});
        requestFormRefresh();
        setLockForm(false);
        setLockFormAll(false);
      }
    }
  }, [genericSupplier]);

  useEffect(() => {
    if (
      state?.genericSupplierID &&
      state?.genericSupplierID != genericSupplier
    ) {
      setGenericSupplier(state?.genericSupplierID);
    }
  }, [state?.genericSupplierID]);

  useEffect(() => {
    if(supplierTypeList.length > 0 && props.forceSupplierTypeCode){
      let data: any = {...state};
      data["supplierTypeID"] = supplierTypeList.find((x: ISupplierGetType) => props.forceSupplierTypeCode === x.supplierTypeCode)?.supplierTypeID;
      setState(mergeObjects(data));
    }
  }, [supplierTypeList, props.forceSupplierTypeCode]);

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push(
      {
        width: 50,
        type: "select",
        name: "supplierTypeID",
        currentValue: state?.supplierTypeID ?? "",
        defaultValue: props.forceSupplierTypeID ? props.forceSupplierTypeID : state?.supplierTypeID,
        disabled: props.forceSupplierTypeCode !== undefined || props.forceSupplierTypeID !== undefined ? true : false,
        required: true,
        label: String(i18next.t(`form:suppliersTypePlaceholderSelect`)),
        options: props.newSupplierTypeList
          ? supplierTypeList
              .filter((x: ISupplierGetType) =>
                props.newSupplierTypeList.some(
                  (g: any) => x.supplierTypeCode === g
                )
              )
              .map((supplierType: ISupplierGetType) => {
                return {
                  key: supplierType.supplierTypeID.toString(),
                  text: supplierType.supplierTypeName.toString(),
                };
              })
          : supplierTypeList.map((supplierType: ISupplierGetType) => {
              return {
                key: supplierType.supplierTypeID.toString(),
                text: supplierType.supplierTypeName.toString(),
              };
            }),
      },
      {
        width: 50,
        type: "select",
        name: "genericSupplierID",
        defaultValue: genericSupplier,
        disabled: state?.supplierTypeID ? false : true,
        required: true,
        label: String(i18next.t(`form:vehicleSupplier`)),
        options: supplierListInfo.map((supplierInfo: ISupplierGetInfo) => {
          return {
            key: supplierInfo.genericSupplierID.toString(),
            text: supplierInfo.genericSupplierName.toString(),
          };
        }),
      }
    );

    // Set comapny based on accountTypeID
    if (props.loggedUser?.accountTypeID === 2) {
      multiformInputs.push({
        // Company
        width: 50,
        type: "select",
        name: "supplierCompany",
        multiple: true,
        disabled: lockForm,
        defaultValue: state?.supplierCompany ? state.supplierCompany : [],
        required: true,
        label: String(i18next.t(`form:orderCompany`)),
        options: companyList.map((companyItem: ICompanyGet) => {
          return {
            key: companyItem.companyID.toString(),
            text:
              companyItem.companyBusinessName !== ""
                ? companyItem.companyBusinessName
                : companyItem.companyFiscalCode,
          };
        }),
      });
    }

    multiformInputs.push(
      {
        width: props.loggedUser?.accountTypeID === 2 ? 50 : 100,
        type: "text",
        name: "supplierName",
        disabled: lockFormAll,
        defaultValue: state?.supplierName,
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:nameSupplier")}`,
      },
      {
        width: 34,
        type: "text",
        name: "supplierVAT",
        disabled: lockFormAll,
        defaultValue: state?.supplierVAT,
        required: true,
        inputProps: { maxLength: 13 },
        label: `${i18next.t("form:vatNumber")}`,
        errorText:
          state?.supplierVAT &&
          state?.supplierVAT.length > 0 &&
          !validationVAT(state?.supplierVAT)
            ? (i18next.t("form:validationIVA") as string)
            : "",
      },
      {
        width: 33,
        type: "email",
        name: "supplierPEC",
        disabled: lockFormAll,
        defaultValue: state?.supplierPEC,
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:pec")}`,
      },
      {
        width: 33,
        type: "number",
        name: "supplierPhone",
        disabled: lockFormAll,
        defaultValue: state?.supplierPhone,
        required: true,
        label: String(i18next.t("form:contactCenter")),
        errorText:
          state?.supplierPhone &&
          state?.supplierPhone.length > 0 &&
          !validationPhone(state?.supplierPhone)
            ? (i18next.t("form:validationPhone") as string)
            : "",
      }
    );

    if (props.lockForm !== "disabled") {
      multiformInputs.push(
        {
          width: 34,
          type: "text",
          name: "supplierReferentName",
          disabled: lockForm,
          defaultValue: state?.supplierReferentName,
          required: true,
          inputProps: { maxLength: 50 },
          label: `${i18next.t("form:referent")}`,
        },
        {
          width: 33,
          type: "email",
          name: "supplierReferentEmail",
          disabled: lockForm,
          tooltip: {
            title: i18next.t("form:tooltipEmailReferent"),
          },
          inputProps: { maxLength: 100 },
          defaultValue: state?.supplierReferentEmail,
          required: true,
          label: String(i18next.t("form:emailReferent")),
        },
        {
          width: 33,
          type: "number",
          inputProps: { maxLength: 20 },
          name: "supplierReferentPhone",
          disabled: lockForm,
          defaultValue: state?.supplierReferentPhone,
          required: false,
          label: String(i18next.t("form:telephoneReferent")),
          errorText:
            state?.supplierReferentPhone &&
            state?.supplierReferentPhone.length > 0 &&
            !validationPhone(state?.supplierReferentPhone)
              ? (i18next.t("form:validationPhone") as string)
              : "",
        }
      );
    }

    multiformInputs.push({
      width: props.lockForm !== "disabled" ? 50 : 100,
      fullWidth: true,
      type: "custom",
      name: "PlaceAutoCompleteForm",
      className: "content-place-auto-complete",
      element: (
        <PlaceAutoCompleteForm
          onChange={(data: IGoogleAddress) => setState(mergeObjects(data))}
          addressId="supplierAddressStreet"
          disableForm={processing}
          inputs={[
            {
              width: 70,
              type: "text",
              name: "supplierAddressStreet",
              disabled: lockFormAll,
              inputProps: { maxLength: 100 },
              defaultValue: state?.supplierAddressStreet,
              mapDecode: "route",
              label: String(i18next.t("form:addressLegal")),
            },
            {
              width: 30,
              type: "text",
              name: "supplierAddressNumber",
              inputProps: { maxLength: 10 },
              disabled: lockFormAll,
              defaultValue: state?.supplierAddressNumber,
              mapDecode: "street_number",
              label: String(i18next.t("form:civicNumberAbbr")),
            },
            {
              width: 100,
              type: "select_nations",
              name: "supplierAddressCountryID",
              disabled: lockFormAll,
              currentValue: state?.supplierAddressCountryID ?? "",
              defaultValue: state?.supplierAddressCountryID,
              mapDecode: "country",
              label: String(i18next.t("form:countryResidence")),
            },
            {
              width: 25,
              type: "text",
              name: "supplierAddressCAP",
              disabled: lockFormAll,
              defaultValue: state?.supplierAddressCAP,
              mapDecode: "postal_code",
              inputProps: { maxLength: 20 },
              label: String(i18next.t("form:postalCode")),
            },
            {
              width: 50,
              type: "text",
              name: "supplierAddressCity",
              inputProps: { maxLength: 50 },
              disabled: lockFormAll,
              defaultValue: state?.supplierAddressCity,
              mapDecode: "locality",
              label: String(i18next.t("form:city")),
            },
            {
              width: 25,
              type: "text",
              name: "supplierAddressProv",
              disabled: lockFormAll,
              defaultValue: state?.supplierAddressProv,
              inputProps: { maxLength: 50 },
              mapDecode: "province",
              label: String(i18next.t("form:provinceAbbr")),
            },
          ]}
        />
      ),
    });

    if (props.lockForm !== "disabled") {
      multiformInputs.push({
        width: 50,
        type: "multiline",
        name: "supplierNote",
        disabled: lockForm,
        className: "container-add-textarea",
        multilineRows: 8.7,
        defaultValue: state?.supplierNote,
        required: false,
        label: String(i18next.t("form:note")),
      });
    }

    multiformInputs.push({
      width: 100,
      type: "custom",
      name: "save",
      element: (
        <div className="display-flex-end">
          <Button
            color="error"
            sx={{ marginRight: "1em" }}
            disabled={!state?.supplierID}
            onClick={() => {
              SummonModal("StepSupplier-modal");
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            {i18next.t("form:deleteSupplier")}
          </Button>

          <Button
            variant="contained"
            disabled={processing}
            onClick={() => {
              triggerFormValidation("StepSuppliers");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveSupplier")}
          </Button>

          <SmartModal
            modalUniqueId="StepSupplier-modal"
            title={i18next.t("generic:titleRecordModal")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModal")
                    .replace("CURRENT_ITEM_LIST", state?.supplierName),
                }}
              />
            }
            onAccept={() => {
              deleteSupplier(state);
            }}
            onReject={() => {}}
          />
        </div>
      ),
    });

    // Return the full and complete Multiform
    return multiformInputs;
  };
  /* RETURN */
  return (
    <>
      {!loadingSupplierList && (
        <MultiForm
          formId={"StepSuppliers"}
          suppressLayout
          formTitle={"StepSuppliers"}
          classNameForm="multi-form-flex"
          inputs={multiformInputs()}
          suppressSubmit={true}
          onChange={async (data: any) => {
            setState(mergeObjects(data));
          }}
          onSubmit={async () => {
            let parsedState = { ...state };
            // Create the companyIDs object
            if (props.loggedUser?.accountTypeID === 2) {
              parsedState["companyIDs"] = parsedState["supplierCompany"].map(
                (x: number | string) => {
                  return {
                    companyID: +x,
                  };
                }
              );
            } else {
              parsedState["companyIDs"] = [{ companyID: companyUser?.companyID }];
            }

            if (parsedState["supplierAddressCountryID"] <= 0) {
              parsedState["supplierAddressCountryID"] = null;
            }

            // String to number
            parsedState["supplierTypeID"] = +parsedState["supplierTypeID"];
            parsedState["genericSupplierID"] = +parsedState["genericSupplierID"];

            if (props.lockForm === "disabled") {
              parsedState["supplierReferentName"] = "";
              parsedState["supplierReferentEmail"] = "";
            }

            // Delete for the payload the unuseful state
            delete parsedState["supplierCompany"];
            delete parsedState["save"];
            delete parsedState["PlaceAutoCompleteForm"];
            delete parsedState["AddTypeSupplier"];

            // Lunch the API based on the supplierID
            // (if present the element already exist so UPDATE, if not INSERT)
            if (parsedState?.supplierID && parsedState?.supplierID > 0) {
              updateSupplier(parsedState);
            } else {
              insertSupplier(parsedState);
            }
          }}
        />
      )}
      <LoaderBackdrop
        loading={
          processing ||
          loadingSupplierInfo ||
          loadingSupplierList ||
          loadingSupplierGeneric ||
          loadingCompany
        }
      />
    </>
  );
};

export default FormSupplier;
