/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./CustomStepper.scss";

/* MUI */
import { Button, CircularProgress } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RedoIcon from "@mui/icons-material/Redo";

/* INTERFACES */
export interface ICustomStepperItem {
  element: JSX.Element;
  stepTitle: string;
  nextLabel?: string;
  prevLabel?: string;
  skipStep?: boolean;
  onNext?: () => boolean;
  onBack?: () => boolean;
}

export interface ICustomStepperProps {
  stepperNodes: ICustomStepperItem[];
  isCustomDashboard?: boolean;
  isNextStep?: boolean;
  hideBackButton?: boolean;
  hideButtons?: boolean;
  onFinish: () => void;
}

export interface SkipButtonCustomStepperProps {
  nextPage: number;
}

/* EXPORT FUNCTIONS */
export const stepperGoToPage = (page: number) => {
  window.document.dispatchEvent(
    new CustomEvent("stepper-go-page", {
      detail: page,
    })
  );
};

export const SkipButtonCustomStepper = (props: SkipButtonCustomStepperProps) => {
  return (
    <div className="skip-button-wrapper">
      <div>{i18next.t("navigation:subtitleSkipCurrentStep")}</div>

      <div>
        <Button
          onClick={async () => {
            stepperGoToPage(props.nextPage);
          }}
          className="btn-secondary"
        >
          <span>{i18next.t("navigation:titleSkipCurrentStep")}</span>

          <span>
            <RedoIcon />
          </span>
        </Button>
      </div>
    </div>
  );
};

/* COMPONENT */
const CustomStepper = (props: ICustomStepperProps) => {
  /* STATES */
  const [step, setStep_] = useState<number>(0);

  /* CONTS */
  const hideBackButton = props.hideBackButton ? false : true;

  /* FUNCTIONS */
  const setStep = (step: number) => {
    setStep_(step);
    resetScroll();
  };

  const nextStep = () => {
    if (step === props.stepperNodes.length - 1) {
      props.onFinish();
    } else {
      if (step < props.stepperNodes.length) {
        setStep(step + 1);
      }
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const goToPage = (e: any) => {
    setStep(e.detail);
  };

  const getCurrentStep = () => {
    return props.stepperNodes.length > 0 && props.stepperNodes[step]
      ? props.stepperNodes[step]
      : {
          element: <div></div>,
          stepTitle: "",
        };
  };

  const resetScroll = () => {
    setTimeout(() => {
      window.scroll(0, 0);
      let content = document.getElementById("custom-stepper-content");
      if (content) {
        content.scrollTop = 0;
      }
    }, 500);
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    window.document.addEventListener("stepper-go-page", goToPage);
    return () => {
      window.document.removeEventListener("stepper-go-page", goToPage);
    };
  }, []);

  /* RETURN */
  return (
    <div
      className={
        "custom-stepper-main-wrap " +
        (props.isCustomDashboard && props.isNextStep
          ? "custom-stepper-main-dashboard-with-next-step"
          : props.isCustomDashboard
          ? "custom-stepper-main-dashboard"
          : "")
      }
    >
      {props.isCustomDashboard && (
        <div className="custom-stepper-step-view-reduced-title">
          {props.stepperNodes.length > step && props.stepperNodes[step].stepTitle}
        </div>
      )}

      <div className="custom-stepper-step-view">
        <div className="custom-stepper-step-view-inner">
          {props.stepperNodes.map((x: ICustomStepperItem, i: number) => {
            let classIs = "custom-stepper-dot-current";
            let barClassIs = "custom-stepper-join-bar";
            let widthBar = 30 / props.stepperNodes.length;

            if (i > step) {
              classIs += " custom-stepper-dot";
            }

            if (i > step - 1) {
              barClassIs += " custom-stepper-join-bar-next";
            }

            return (
              <span style={{ position: "relative" }} key={i}>
                {i === step && !props.isCustomDashboard && (
                  <div className="custom-stepper-over-title">{x.stepTitle}</div>
                )}

                {i === step && props.isCustomDashboard && (
                  <div className="custom-stepper-over-title-dashboard">{x.stepTitle}</div>
                )}

                <div className={classIs} title={x.stepTitle}>
                  {i + 1}
                </div>

                {i < props.stepperNodes.length - 1 && (
                  <div
                    className={barClassIs}
                    style={{
                      width: widthBar.toString() + "em",
                    }}
                  ></div>
                )}
              </span>
            );
          })}
        </div>
      </div>

      <div className="custom-stepper-content" id="custom-stepper-content">
        {getCurrentStep().element}
      </div>

      {!props.hideButtons && (
        <div className="custom-stepper-buttons-zone">
          <div className="custom-stepper-buttons-zone-inner">
            {hideBackButton && step > 0 && (
              <Button
                onClick={async () => {
                  if (getCurrentStep().onBack) {
                    let result = await (getCurrentStep() as any).onBack();
                    if (result) {
                      prevStep();
                    }
                  } else {
                    prevStep();
                  }
                }}
                variant="contained"
                startIcon={<ChevronLeft />}
              >
                {getCurrentStep().prevLabel
                  ? getCurrentStep().prevLabel
                  : i18next.t("generic:prev")}
              </Button>
            )}

            {(step === 0 || !hideBackButton) && <div></div>}

            <Button
              onClick={async () => {
                if (step === props.stepperNodes.length - 1) {
                  props.onFinish();
                } else {
                  if (getCurrentStep().onNext) {
                    let result = await (getCurrentStep() as any).onNext();
                    if (result) {
                      nextStep();
                    }
                  } else {
                    nextStep();
                  }
                }
              }}
              variant="contained"
              endIcon={
                step === props.stepperNodes.length - 1 ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ChevronRight />
                )
              }
            >
              {step === props.stepperNodes.length - 1
                ? i18next.t("generic:finish")
                : getCurrentStep().nextLabel
                ? getCurrentStep().nextLabel
                : i18next.t("generic:next")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomStepper;
