import i18next from "i18next";
import { useEffect, useState } from "react";
import { Chip, IconButton, TextField, Tooltip } from "@mui/material";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import {
  IVehicleDamage,
  IVehicleDamageInsert,
  IVehicleDamageUpdate,
} from "../../Models/IVehicleDamage";
import "./VehicleDamageForm.scss";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import VehicleDamageImageMap from "./VehicleDamageImageMap";
import { userRoleFits } from "../../Utils/Session";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import MultiForm from "../MultiForm/MultiForm";

export interface IVehicleDamageFormProps {
  vehicleID: number;
  forceLoadDamageVehicle?: boolean;
  onlyImage?: boolean;
  damageList?: IVehicleDamage[];
}

const VehicleDamageForm = (props: IVehicleDamageFormProps) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [saveBlocked, setSaveBlocked] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const [damageList, setDamageList] = useState<IVehicleDamage[]>([]);
  const [currentDamage, setCurrentDamage] = useState<any>(null);

  const FindPosition = (oElement: any) => {
    if (typeof oElement.offsetParent !== "undefined") {
      for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
      return [posX, posY];
    } else {
      return [oElement.x, oElement.y];
    }
  };

  const GetCoordinates = (e: any, imageID: string) => {
    var PosX: number = 0;
    var PosY: number = 0;
    let ImgInfo = window.document.getElementById(imageID);
    let ImgPos = FindPosition(window.document.getElementById(imageID));
    let dialogWrapper = window.document.getElementsByClassName("MuiDialogContent-root");
    if (e.pageX || e.pageY) {
      PosX = e.pageX;
      PosY = e.pageY;
    }

    if (dialogWrapper && dialogWrapper.length > 0) {
      PosY += dialogWrapper[0].scrollTop;
    }
    PosX = PosX - ImgPos[0];
    PosY = PosY - ImgPos[1];

    if (ImgInfo) {
      PosX = (PosX / ImgInfo.clientWidth) * 100;
      PosY = (PosY / ImgInfo.clientHeight) * 100;
    }

    let damageList_ = [...damageList];
    damageList_.push({
      vehicleDamageCoordinateX: +PosX.toFixed(2),
      vehicleDamageCoordinateY: +PosY.toFixed(2),
      vehicleID: props.vehicleID,
      vehicleDamageDescription: "",
    });
    setDamageList(damageList_);

    setState({ ...state, ...damageList_ });
  };

  const VehicleDamageGet = () => {
    setDamageList([]);
    if (props.vehicleID) {
      setProcessing(true);
      ApiService.VehicleDamageController.VehicleDamageGet(
        props.vehicleID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDamageList(response.payload.filter((x: IVehicleDamage) => !x.repared));
            setState(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleDamageInsert = (data: IVehicleDamageInsert, lastItem: boolean) => {
    if (props.vehicleID) {
      setProcessing(true);
      ApiService.VehicleDamageController.VehicleDamageInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (lastItem) {
              ToastMessage(i18next.t("message:vehicleDamageAdded"), "success");
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleDamageUpdate = (data: IVehicleDamageUpdate, lastItem: boolean) => {
    if (props.vehicleID) {
      setProcessing(true);
      ApiService.VehicleDamageController.VehicleDamageUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (lastItem) {
              ToastMessage(i18next.t("message:vehicleDamageUpdated"), "success");
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const VehicleDamageRepair = (vehicleDamageID: number, vehicleDamageCost: number) => {
    if (props.vehicleID) {
      setProcessing(true);
      ApiService.VehicleDamageController.VehicleDamageRepair(
        vehicleDamageID,
        vehicleDamageCost,
        (response: IAPIResponse) => {
          if (response.error === null) {
            DismissModal("repair-damange-modal");
            ToastMessage(i18next.t("message:vehicleDamageRepairAdded"), "success");
            let damageList_ = [...damageList].filter(
              (x: IVehicleDamage) => x.vehicleDamageID !== vehicleDamageID
            );
            setDamageList(damageList_);
            doRefreshList();
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const saveDataForm = () => {
    let stateObject = Object.keys(state);
    for (let i = 0; i < stateObject.length; i++) {
      let lastItem: boolean = stateObject.length - 1 === i;
      if (userRoleFits(["FleetSupportAdmin"])) {
        if (state[i].vehicleDamageID) {
          VehicleDamageUpdate(state[i], lastItem);
        }
      }

      if (state[i].vehicleDamageID === undefined) {
        VehicleDamageInsert(state[i], lastItem);
      }
    }
    return false;
  };

  const doRefreshList = () => {
    setRefreshList(true);
    setTimeout(() => {
      setRefreshList(false);
    }, 10);
  };

  useEffect(() => {
    if (props.forceLoadDamageVehicle) {
      VehicleDamageGet();
    }
  }, [props.forceLoadDamageVehicle]);

  useEffect(() => {
    let isSaveBlocked =
      damageList.filter((x: IVehicleDamage) => !x.vehicleDamageID).length > 0;
    setSaveBlocked(isSaveBlocked);
  }, [damageList]);

  return (
    <div>
      {props.onlyImage ? (
        <div className="vehicle-damage-wrapper">
          <VehicleDamageImageMap
            damageList={damageList ?? props.damageList}
            onGetCoordinates={GetCoordinates}
            imageSchemaID={"imageSchemaPrintable"}
            imageMapSchemaID={"image_damage_car_printable"}
            imgClassName={"image-wrapper-printable"}
          />
        </div>
      ) : (
        <SmartModal
          modalUniqueId={"VehicleDamage-modal"}
          title={i18next.t("navigation:vehicleDamageTitle")}
          modalInnerComponent={
            <div style={{ minWidth: "70vw" }}>
              <div className="vehicle-damage-wrapper">
                <VehicleDamageImageMap
                  damageList={damageList ?? props.damageList}
                  onGetCoordinates={GetCoordinates}
                />
                <div className="list-wrapper">
                  <form>
                    <div className="list-elements">
                      {!refreshList &&
                        (damageList ?? []).map((x: IVehicleDamage, i: number) => {
                          return (
                            <div className="single-damage-list">
                              <input
                                type="hidden"
                                name={"vehicleDamageID_" + i}
                                defaultValue={x.vehicleDamageID}
                              />
                              <input
                                type="hidden"
                                name={"vehicleDamageCoordinateX_" + i}
                                defaultValue={+x.vehicleDamageCoordinateX}
                              />
                              <input
                                type="hidden"
                                name={"vehicleDamageCoordinateY_" + i}
                                defaultValue={+x.vehicleDamageCoordinateY}
                              />
                              <Chip
                                className="single-damage-list-icon"
                                sx={{
                                  color: "#fff",
                                  background: x.vehicleDamageID ? "#e74c3c" : "#f1c40f",
                                }}
                                label={i + 1}
                              />
                              <TextField
                                disabled={
                                  x.vehicleDamageID !== undefined && userRoleFits(["FleetManager"])
                                }
                                onChange={(e: any) => {
                                  let state_ = { ...state };
                                  state_[i].vehicleDamageDescription = e.target.value;
                                  setState(state_);
                                }}
                                fullWidth
                                size="small"
                                name={"vehicleDamageDescription_" + i}
                                className="single-damage-list-text-field"
                                defaultValue={x.vehicleDamageDescription}
                              />
                              {!x.vehicleDamageID ||
                                (userRoleFits(["FleetManager"]) && (
                                  <Tooltip
                                    title={i18next.t("navigation:repair_damage_hotpoint")}
                                  >
                                    <IconButton
                                      color="primary"
                                      component="label"
                                      onClick={() => {
                                        setCurrentDamage(damageList[i]);
                                        SummonModal("repair-damange-modal");
                                      }}
                                    >
                                      <BuildCircleIcon />
                                    </IconButton>
                                  </Tooltip>
                                ))}
                            </div>
                          );
                        })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
          buttons={[
            {
              text: i18next.t("form:exitWithoutSave"),
              onClick: () => {
                setDamageList(
                  damageList.filter((x: IVehicleDamage) => x.vehicleDamageID)
                );
                DismissModal("VehicleDamage-modal");
              },
            },
            {
              text: i18next.t("form:save"),
              onClick: () => {
                saveDataForm();
                DismissModal("VehicleDamage-modal");
              },
            },
          ]}
        />
      )}

      {currentDamage && (
        <SmartModal
          modalUniqueId={"repair-damange-modal"} 
          title={i18next.t("navigation:repairDamageTitle")} 
          modalInnerComponent={
            <div>
              <p>{i18next.t("navigation:repairDescription")}: {currentDamage?.vehicleDamageDescription}</p>
              <MultiForm 
                suppressLayout
                inputs={[
                  {
                    type: "number",
                    name: "vehicleDamageCost",
                    width: 100,
                    required: true,
                    label: i18next.t("form:repairDamageCost") as string,
                    inputProps: {
                      step: "0.01",
                    },
                    inputAdornament:
                      { adornament: "€" }
                  }
                ]}
                formId="repair-damage-form"
                onSubmit={(data: any) => {
                  if(currentDamage?.vehicleDamageID){
                    VehicleDamageRepair(currentDamage.vehicleDamageID, data.vehicleDamageCost);
                  }
                }}
                />
            </div>
          }
        />
      )}
    </div>
  );
};

export default VehicleDamageForm;
