/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MODELS */
import { ITelepassGet } from "../../../../../Models/ITelepass";
import { IUser } from "../../../../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Telepass__VehiclesPage.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import FormTelepass from "../../../../../Components/StepVehicles/TabTelepass/FormTelepass";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../VehiclesPage";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { dateIsPast, displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { ICompanyGet } from "../../../../../Models/ICompany";
import EditIcon from "@mui/icons-material/Edit";

/* COMPONENT */
const Telepass__VehiclesPage = () => {
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [loadingList, setLoadingList] = useState<boolean>(false);

  // Container for list
  const [list, setList] = useState<ITelepassGet[]>([]);
  const [currentTelepass, setCurrentTelepass] = useState<any>(null);

  /* APIs */
  const loadTelepass = () => {
    if (loggedUser) {
      setLoadingList(true);
      ApiService.TelepassController.TelepassGet(
        null,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingList(false);
        }
      );
    }
  };

  /* FUNCTIONS */
  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__TELEPASS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {props.value}
        </Link>
      </div>
    );
  };

  const editRow = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            let telepass_ = list.find(
              (x: ITelepassGet) => x.telepassID === props.row.telepassID
            );
            setCurrentTelepass(telepass_);
            SummonModal("telepass-page-modal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  // Format plate
  const renderPlate = (props: GridRenderCellParams) => {
    return <div>{formatPlate(props.value)}</div>;
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadTelepass();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "telepassSerialNumber",
      headerName: "Seriale",
      renderCell: renderLink,
    },
    {
      field: "telepassPlate",
      headerName: i18next.t("navigation:_ticket_modal_detail_plate"),
      renderCell: renderPlate,
    },
    {
      field: "telepassSupplier",
      headerName: i18next.t("form:vehicleSupplier"),
    },
    {
      field: "telepassDevice",
      headerName: i18next.t("form:typeTelepass"),
    },
    {
      field: "telepassDriver",
      headerName: i18next.t("navigation:_ticket_modal_detail_driver"),
    },
    {
      field: "telepassDateFrom",
      headerName: i18next.t("navigation:start"),
      fieldOriginal: "telepassDateFromOriginal",
    },
    {
      field: "telepassDateTo",
      headerName: i18next.t("navigation:end"),
      fieldOriginal: "telepassDateToOriginal",
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      edit: "",
      telepassID: list[i].telepassID,
      vehicleID: list[i].vehicleID,
      telepassPlate: list[i].vehicleLicensePlate,
      telepassSupplier: list[i].supplierName,
      telepassDevice: list[i].telepassTypeName,
      telepassDriver: list[i].driverName,
      telepassSerialNumber: list[i].telepassSerialNumber,
      telepassDateFrom: displayUTC0_ISODate(list[i].telepassActivateDT, true),
      telepassDateFromOriginal: list[i].telepassActivateDT,
      telepassDateTo: displayUTC0_ISODate(list[i].telepassExpirationDT, true),
      telepassDateToOriginal: list[i].telepassExpirationDT,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_vehicles_telepass")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentTelepass(null);
                SummonModal("telepass-page-modal");
              }}
            >
              {i18next.t("navigation:_add_telepass")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          modalUniqueId={"telepass-page-modal"}
          title={i18next.t("generic:titleAddTelepass")}
          modalInnerComponent={
            <div className="modal-width-large">
              <FormTelepass
                currentTelepass={currentTelepass ?? []}
                idModal="telepass-page-modal"
                loggedUser={loggedUser}
                loggedCompany={loggedCompany}
                doReloadData={() => loadTelepass()}
                dataVehicle={[]}
              />
            </div>
          }
        />

        <LoaderBackdrop loading={loadingList} />
      </div>
    </div>
  );
};

export default Telepass__VehiclesPage;
