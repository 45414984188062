/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./DealerPageRequest.scss";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* MODELS */
import { IUPDATE_Ignore_Request_Prompt_Delivery } from "../../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* MUI */
import { Badge, Box, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import DoneIcon from "@mui/icons-material/Done";

/* COMPONENT */
const DealerPageRequest = () => {
  /* STATES */
  const [list, setList] = useState<any>([]);
  // Current selection
  const [currentData, setCurrentData] = useState<number>();
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get dealer request list
  const GET_Request_Prompt_Delivery_Dealer = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_Request_Prompt_Delivery_Dealer(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // Reject request
  const UPDATE_Ignore_Request_Prompt_Delivery = (
    data: IUPDATE_Ignore_Request_Prompt_Delivery
  ) => {
    setLoadingUpdate(true);

    ApiService.CarConfiguratorController.UPDATE_Ignore_Request_Prompt_Delivery(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");

          GET_Request_Prompt_Delivery_Dealer();
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdate(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    GET_Request_Prompt_Delivery_Dealer();
  }, []);

  /* FUNCTIONS */
  // Actions
  const renderActions = (props: GridRenderCellParams) => {
    const color: string = props.row.new
      ? "#02aa3f"
      : props.row.actionRequired
      ? "#d32f2f"
      : "";

    return (
      <div className="display-flex-vertical-center VehicleRequestsPage-badge">
        <Badge
          color="primary"
          variant="dot"
          invisible={!props.row.new && !props.row.actionRequired}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: color,
            },
          }}
        />
      </div>
    );
  };

  // Render button
  const renderButton = (props: GridRenderCellParams) => {
    let contentAccpeted;

    // Check accepted status
    if (props.row.accepted === true) {
      contentAccpeted = (
        <div className="display-flex-vertical-center content-accepted">
          <DoneIcon /> {i18next.t("navigation:requestSend")}
        </div>
      );
    } else {
      contentAccpeted = (
        <div className="content-refused">{i18next.t("navigation:requestRejected")}</div>
      );
    }

    // Return
    return props.row.actionRequired ? (
      <div className="display-flex-horizontal-space-evenly">
        <Button
          variant="outlined"
          className="buttonAccept"
          onClick={() => {
            setCurrentData(props.row.vehiclesRequestPromptDeliveryID);

            SummonModal("ignore-request-modal");
          }}
        >
          {i18next.t("navigation:ignoreRequest")}
        </Button>

        <Button
          variant="contained"
          className="buttonAccept"
          onClick={() =>
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___DEALER_PROPOSAL +
                "?request=" +
                props.row.vehiclesRequestPromptDeliveryID +
                "&brand=" +
                props.row.brandCode +
                "&model=" +
                props.row.modelCode
            )
          }
        >
          {i18next.t("navigation:sendProposal")}
        </Button>
      </div>
    ) : (
      contentAccpeted
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: "",
      renderCell: renderActions,
      type: 'custom'
    },
    {
      field: "date",
      headerName: i18next.t("form:vehciledata_forms_test_date"),
    },
    {
      field: "requestOwner",
      headerName: i18next.t("form:applicant"),
    },
    {
      field: "brand",
      headerName: i18next.t("form:orderBrand"),
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "fitting",
      headerName: i18next.t("form:orderEquipment"),
    },
    {
      field: "button",
      headerName: "",
      renderCell: renderButton,
      type: 'custom'
    },
  ];

  /* ROWS */
  const rowsDef: any = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,

      vehiclesRequestPromptDeliveryID: list[i].vehiclesRequestPromptDeliveryID,
      date: displayUTC0_ISODate(list[i].date),
      new: list[i].new,
      requestOwner: list[i].requestOwner,
      brand: list[i].brandDescription,
      model: list[i].modelDescription,
      fitting: list[i].fittingDescription,
      actionRequired: list[i].actionRequired,
      accepted: list[i].proposalSent,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_configure_vechicles_prompt_delivery_request")}
      />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} hideToolbar={true} />
        </Box>
      </div>

      <SmartModal
        modalUniqueId="ignore-request-modal"
        title={i18next.t("navigation:refuseRequest")}
        modalInnerComponent={
          <div className="modal-width-small">
            <p>{i18next.t("navigation:refuseRequestText")}</p>
          </div>
        }
        onAccept={() => {
          if (currentData) {
            UPDATE_Ignore_Request_Prompt_Delivery({
              vehiclesRequestPromptDeliveryID: currentData,
            });
          }
        }}
        onReject={() => {
          DismissModal("ignore-request-modal");
        }}
      />

      <LoaderBackdrop loading={loadingGet || loadingUpdate} />
    </div>
  );
};

export default DealerPageRequest;
