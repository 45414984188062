import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleFuelSupplyInsert,
  IVehicleFuelSupplyUpdate,
} from "../../Models/IVehicleFuelSupply";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";
const VehicleFuelSupplyController = {
  VehicleFuelSupplyGet: async (
    fuelSupplyID: number,
    companyID: number,
    vehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_GET;
    let parameters: string[] = [];
    if (fuelSupplyID) {
      parameters.push(`fuelSupplyID=${fuelSupplyID}`);
    }
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }

    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelTypeGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_GET_TYPE;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelSupplyTypeGet: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_GET_TYPE;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelMeasurementUnitGet: async (
    measurementUnitID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    let parameters: string[] = [];
    if (measurementUnitID) {
      parameters.push(`measurementUnitID=${measurementUnitID}`);
    }

    request.url += "?" + parameters.join("&");
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_MEASUREMENT_UNIT_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelSupplyInsert: async (
    body: IVehicleFuelSupplyInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelSupplyUpdate: async (
    body: IVehicleFuelSupplyUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleFuelSupplyDelete: async (
    fuelSupplyID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_FUEL_SUPPLY_DELETE;
    request.method = "DELETE";
    request.payload = {
      fuelSupplyID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleFuelSupplyController;
