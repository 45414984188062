import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IFleetManagerInsert } from "../../Models/IFleetManager";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";
const UserTypesController = {
  BrokerTypeGet: async (
    brokerId?: number,
    brokerTypeId?: number,
    brokerAccountId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7234";
    request.url = `${ApiEndPoints.GET_BROKER_TYPES}?`;

    if (brokerTypeId) {
      request.url += `brokerTypeId=${brokerTypeId}`;
    }
    if (brokerId) {
      request.url += `&brokerId=${brokerId}`;
    }
    if (brokerAccountId) {
      request.url += `&brokerAccountId=${brokerAccountId}`;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  BrokerTypesInser: async (
    typesList: string,
    brokerId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.INSERT_BROKER_TYPES}?typesList=${typesList}&brokerId=${brokerId}`;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default UserTypesController;
