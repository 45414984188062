import { useEffect, useState } from "react";
import { IDriverGet } from "../../Models/IDriver";
import { IVehicleDamage } from "../../Models/IVehicleDamage";
import { IVehicleDataGet } from "../../Models/IVehicles"
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { Chip } from "@mui/material";
import "./FleetReservationDeliveryLetter.scss";
import { ICompanyGet } from "../../Models/ICompany";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import i18next from "i18next";
import VehicleDamageForm from "../VehicleDamageForm/VehicleDamageForm";

export interface IFleetReservationDeliveryLetterProps {
  vehicleInfo: IVehicleDataGet;
  driversData: {
    firstDriver: IDriverGet;
    secondDriver?: IDriverGet | undefined;
  }
}

const FleetReservationDeliveryLetter = (props: IFleetReservationDeliveryLetterProps) => {
  const mainCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  
  const [render, setRender] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [damageList, setDamageList] = useState<IVehicleDamage[]>([]);

  const VehicleDamageGet = () => {
    setDamageList([]);
    if (props.vehicleInfo.vehicleID) {
      setProcessing(true);
      ApiService.VehicleDamageController.VehicleDamageGet(
        props.vehicleInfo.vehicleID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDamageList(response.payload.filter((x: IVehicleDamage) => !x.repared));
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  useEffect(() => {
    if(props.vehicleInfo && props.driversData && props.driversData.firstDriver){
      setRender(true);
      VehicleDamageGet();
    }
  }, [props.vehicleInfo]);

  return (
    <div>
      {render && (
        <div className="printable-only delivery-letter-wrapper">
          <div className="header-left">
            <div>
              <b>{i18next.t("form:nameCompany")}:</b>
              <br />
              <p>{mainCompany?.companyBusinessName}</p>
              <p>{mainCompany?.companyBillingStreet}, {mainCompany?.companyBillingNumber}</p>
              <p>{mainCompany?.companyBillingCity} {mainCompany?.companyBillingCAP}</p>
            </div>
          </div>
          <div className="header-right">
            {props.driversData.firstDriver && props.driversData.firstDriver !== undefined && (
            <div>
              <b>{i18next.t("form:firstDriver")}:</b>
              <br />
              <p>{props.driversData.firstDriver.driverInfo.companyBusinessName} - {props.driversData.firstDriver.driverInfo.driverName} {props.driversData.firstDriver.driverInfo.driverSurname}</p>
              <p>{props.driversData.firstDriver.driverInfo.driverAddressStreet} {props.driversData.firstDriver.driverInfo.driverAddressNumber}</p>
              <p>{props.driversData.firstDriver.driverInfo.driverAddressCity} {props.driversData.firstDriver.driverInfo.driverAddressCAP}</p>
            </div>
            )}
            {props.driversData.secondDriver && props.driversData.secondDriver !== undefined && (
              <div>
                <b>{i18next.t("form:secondDriver")}:</b>
                 <br />
                <p>{props.driversData.secondDriver.driverInfo.companyBusinessName} - {props.driversData.secondDriver.driverInfo.driverName} {props.driversData.secondDriver.driverInfo.driverSurname}</p>
                <p>{props.driversData.secondDriver.driverInfo.driverAddressStreet} {props.driversData.secondDriver.driverInfo.driverAddressNumber}</p>
                <p>{props.driversData.secondDriver.driverInfo.driverAddressCity} {props.driversData.secondDriver.driverInfo.driverAddressCAP}</p>
              </div>
            )}
            </div>
          <div className="body-center">
            <b>OGGETTO: Comunicazione di assegnazione autovettura aziendale</b>
            <p>Con la presente La informiamo che, come da accordi intercorsi e ad integrazione del contratto di lavoro già in corso con la scrivente, per l'espletamento della Sua mansione la S.V. è autorizzata ad utilizzare l'autovettura di proprietà dell'azienda marca e modello <b>{props.vehicleInfo.brandDescription} - {props.vehicleInfo.modelDescription} ({props.vehicleInfo.fittingDescription})</b> targata <b>{formatPlate(props.vehicleInfo.vehicleLicensePlate)}</b>.
              La S.V. potrà utilizzare l'auto anche per uso privato e pertanto verrà lasciata nella sua disponibilità per tutti i giorni dell'anno.</p>
            <p>La concessione dell'auto ad uso promiscuo ha effetto dalla data odierna e varrà per tutta la durata del rapporto di lavoro.
            La nostra società si riserva l'insindacabile facoltà di revocare in tutto o in parte l'uso dell'autovettura senza obbligo di giustificazione o di preavviso.
          Nell'utilizzo della suddetta autovettura Lei sarà comunque tenuto al rispetto delle seguenti limitazioni:</p>
          <p>Non farà utilizzare l'autovettura a terzi compresi i familiari
            Si impegna a non modificare o installare apparecchiature e/o accessori senza esplicita autorizzazione da parte della nostra società.
            Le spese di carburante e di altro genere da Lei eventualmente sostenute per l'uso dell'autovettura medesima Le saranno rimborsate solo se accompagnate da
            valida e specifica documentazione. Non le saranno rimborsate multe per infrazione al Codice della strada imputabili al conducente o a cattiva manutenzione della stessa non imputabili alla società.
            La preghiamo di controfirmare copia a titolo di accettazione e conferma della presente integrazione contrattuale.</p>

           <p>Cordiali saluti</p>
          </div>
          <div className="vehicle-damage-wrapper">
            <VehicleDamageForm forceLoadDamageVehicle={true} onlyImage={true} vehicleID={props.vehicleInfo.vehicleID} />
          </div>
          <br/>
          <b>{i18next.t("navigation:vehicleDamageTitle")}</b>
          <ul>
            {damageList.map((item: IVehicleDamage, i: number) => {
              return (
                <li style={{ listStyle: "none", padding: "0.3em" }}>
                  <Chip
                    className="single-damage-list-icon"
                    sx={{
                      color: "#fff",
                      background: item.vehicleDamageID
                        ? "#e74c3c"
                        : "#f1c40f",
                    }}
                    label={i + 1} />&nbsp;&nbsp;{item.vehicleDamageDescription}</li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default FleetReservationDeliveryLetter;