import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import AppRoutes from "../../../Costants/AppRoutes";
import { IAssignmentGet } from "../../../Models/IAssignment";
import { IDriverGet } from "../../../Models/IDriver";
import { ISupplierGetSideList } from "../../../Models/ISupplier";
import {
  ITelepassGet,
  ITelepassGetType,
  ITelepassInsert,
  ITelepassUpdate,
} from "../../../Models/ITelepass";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import AttachementMainHandler from "../../AttachmentMainHandler/AttachmentMainHandler";
import DeadlineMainHandler from "../../DeadlineMainHandler/DeadlineMainHandler";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../SmartModal/SmartModal";
import FormSupplier from "../../StepSupplier/FormSupplier";
import { formatPlate } from "../../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { VehiclesActions } from "../../../Reducers/Vehicle/VehicleAction";
import { ICompanyGet } from "../../../Models/ICompany";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../Reducers/RootReducer";
import { GetVehicleMinDate } from "../../../Pages/DashboardPage/DashboardPage";

export interface IFormTelepassProps {
  loggedUser: IUser | undefined;
  dataVehicle: IVehicleDataGet | any;
  dataAssignment?: IAssignmentGet;
  disabled?: boolean;
  doRefreshForm?: () => void;
  currentTelepass?: ITelepassGet;
  suppressWaring?: boolean;
  expiryTelepass?: ITelepassGet;
  index?: number;
  loggedCompany?: ICompanyGet | undefined;
  idModal?: string;
  doReloadData?: () => void;
}

const FormTelepass = (props: IFormTelepassProps) => {
  const dispatch = useDispatch();
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [state, setState] = useState<any>(props.currentTelepass ?? null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingTelepassGetType, setLoadingTelepassGetType] =
    useState<boolean>(false);
  const [telepassGetType, setTelepassGetType] = useState<ITelepassGetType[]>(
    []
  );
  const [supplierTelepass, setSupplierTelepass] = useState<
    ISupplierGetSideList[]
  >([]);
  const [loadingSupplierTelepass, setLoadingSupplierTelepass] =
    useState<boolean>(false);
  const [loadingVehicles, setLoadingVehicles] = useState<boolean>(false);
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(false);
  const [loadingTelepass, setLoadingTelepass] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<IDriverGet[]>([]);
  const isInDashBoard = window.location.href.includes(
    AppRoutes.INTERNAL_DASHBOARD
  );
  const isInVehiclePage = window.location.pathname.includes(
    AppRoutes.SEARCH_VEHICLE_DETAIL + "/tab/"
  );

  const loadSupplierTelepass = () => {
    if (props.loggedUser) {
      setLoadingSupplierTelepass(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "TELEPASS",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierTelepass(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierTelepass(false);
        }
      );
    }
  };

  const loadTelepassGetType = () => {
    if (props.loggedUser) {
      setLoadingTelepassGetType(true);
      ApiService.TelepassController.TelepassGetType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassGetType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassGetType(false);
        }
      );
    }
  };

  const loadTelepassGet = () => {
    if (props.loggedUser && props.expiryTelepass?.telepassID) {
      setLoadingTelepass(true);
      ApiService.TelepassController.TelepassGet(
        props.expiryTelepass?.telepassID,
        props.expiryTelepass.driverID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setState(response.payload[0]);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepass(false);
        }
      );
    }
  };

  const loadVehicles = () => {
    if (props.loggedCompany) {
      setLoadingVehicles(true);
      ApiService.VehicleDataController.VehicleDataGet(
        props.loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicles(false);
        }
      );
    }
  };

  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    if (props.loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanyList(false);
        }
      );
    }
  };

  const loadDrivers = () => {
    if (props.loggedUser) {
      setLoadingDrivers(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDrivers(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDrivers(false);
        }
      );
    }
  };

  const telepassInsert = (data: ITelepassInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.TelepassController.TelepassInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassSuccessfullAdded"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              if (props.doReloadData) {
                props.doReloadData();
              }
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const telepasArchive = (data: ITelepassUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      data.stored = true;
      ApiService.TelepassController.TelepassUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassSuccessfullUpdated"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              if (props.doReloadData) {
                props.doReloadData();
              }
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };
  const telepassUpdate = (data: ITelepassUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.TelepassController.TelepassUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassSuccessfullUpdated"),
              "success"
            );
            if (props.idModal) {
              if (props.doReloadData) {
                props.doReloadData();
              }
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const telepassDelete = (telepassID: number) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.TelepassController.TelepassDelete(
        telepassID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:insuranceSuccessfullUpdated"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              if (props.doReloadData) {
                props.doReloadData();
              }
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: isInVehiclePage ? 40 : 28.33,
        type: "select",
        name: "supplierID",
        disabled: props?.disabled,
        defaultValue: state?.supplierID,
        required: true,
        label: String(i18next.t(`form:supplierTelepass`)),
        options: supplierTelepass.map((supplier: ISupplierGetSideList) => {
          return {
            key: supplier.supplierInfo.supplierID,
            text: supplier.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 10 : 5,
        type: "custom",
        name: "supplierAddType",
        label: "",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={props?.disabled}
              variant="contained"
              sx={{ height: "55px" }}
              onClick={() => {
                SummonModal("Supplier-Telepass-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      }
    );

    if (!isInVehiclePage && isInDashBoard) {
      multiformInputs.push({
        type: "select",
        name: "companyID",
        required: true,
        width: 33.33,
        label: i18next.t("form:orderCompany") as string,
        disabled: props.loggedUser?.accountTypeID === 1,
        defaultValue:
          props.loggedUser?.accountTypeID === 1
            ? companyUser?.companyID
            : state?.companyID,
        options: companyList.map((x: ICompanyGet) => {
          return {
            key: x.companyID,
            text:
              x.companyBusinessName !== ""
                ? x.companyBusinessName.toUpperCase()
                : x.companyFiscalCode.toUpperCase(),
          };
        }),
      });
    }

    if (!props.dataVehicle?.vehicleID) {
      multiformInputs.push({
        type: "select",
        name: "vehicleID",
        required: false,
        width: 33.33,
        label: i18next.t("form:plate") as string,
        defaultValue: state?.vehicleID,
        options: vehicles.map((x: IVehicleDataGet) => {
          return {
            key: x.vehicleID,
            text: formatPlate(x.vehicleLicensePlate),
          };
        }),
      });
    }

    multiformInputs.push(
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "select",
        disabled: props?.disabled,
        name: "telepassTypeID",
        label: i18next.t("form:typeTelepass") as string,
        required: true,
        defaultValue: state?.telepassTypeID,
        options: telepassGetType.map((telepassType: ITelepassGetType) => {
          return {
            key: telepassType.telepassTypeID,
            text: telepassType.telepassTypeName.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "select",
        name: "driverID",
        defaultValue: props.dataAssignment?.driverID
          ? props.dataAssignment.driverID
          : state?.driverID,
        required: isInVehiclePage,
        disabled: props.disabled,
        label: String(i18next.t(`form:assignDriverName`)),
        options: drivers.map((item: IDriverGet) => {
          let titleDriver = item.driverInfo.driverName;
          if (item.driverInfo?.driverSurname) {
            titleDriver += " " + item.driverInfo.driverSurname;
          }
          return {
            key: item.driverInfo.driverID,
            text: titleDriver.toString(),
          };
        }),
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        disabled: props?.disabled,
        type: "text",
        name: "telepassSerialNumber",
        required: true,
        label: i18next.t("form:numberSerialTelepass") as string,
        defaultValue: state?.telepassSerialNumber,
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "date",
        disabled: props?.disabled,
        required: true,
        name: "telepassActivateDT",
        label: i18next.t("form:dateStartActivation") as string,
        defaultValue: state?.telepassActivateDT,
        minDate: GetVehicleMinDate(props.dataVehicle),
        maxDate: state?.telepassExpirationDT,
      },
      {
        width: isInVehiclePage ? 50 : 33.33,
        type: "date",
        disabled: props?.disabled,
        required: true,
        name: "telepassExpirationDT",
        label: i18next.t("form:dateEndActivation") as string,
        defaultValue: state?.telepassExpirationDT,
        minDate: state?.telepassActivateDT,
      },
      {
        type: "custom",
        name: "tip",
        element: <small>{i18next.t("generic:tipTabFuelCards")}</small>,
      }
    );

    return multiformInputs;
  };

  useEffect(() => {
    loadSupplierTelepass();
    loadTelepassGetType();
    loadDrivers();
    if (props.expiryTelepass?.telepassID) {
      loadTelepassGet();
    }
    if (!isInVehiclePage && isInDashBoard) {
      loadVehicles();
      loadCompanyList();
    }
  }, []);

  let loadingForm =
    loadingSupplierTelepass ||
    loadingTelepassGetType ||
    loadingDrivers ||
    loadingTelepass;

  return (
    <div>
      {props.disabled && !props.suppressWaring && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}
      {!loadingForm && (
        <div>
          <MultiForm
            disableForm={props.disabled}
            formId={
              "StepTelepass" +
              (!props.currentTelepass ? "_new_telepass" : "_" + props.index)
            }
            suppressLayout
            formTitle={""}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState({ ...state, ...data });
            }}
            onSubmit={(data: any) => {
              let parsedData = { ...data };
              delete parsedData["supplierAddType"];
              delete parsedData["tip"];

              if (!parsedData.vehicleID) {
                parsedData["vehicleID"] = props.dataVehicle?.vehicleID;
              }

              if (!isInVehiclePage && isInDashBoard) {
                parsedData["companyID"] = parsedData?.companyID;
                parsedData["vehicleID"] = parsedData?.vehicleID;
              }

              if (state?.telepassID) {
                parsedData["telepassID"] = state.telepassID;
                telepassUpdate(parsedData);
              } else {
                telepassInsert(parsedData);
              }
            }}
            suppressSubmit={true}
          />
          <DeadlineMainHandler
            DeadlineFamily={"telepass"}
            extraGetParameters={
              state?.telepassID
                ? {
                    name: "telepassID",
                    value: state?.telepassID,
                  }
                : undefined
            }
          />
          <AttachementMainHandler
            attachmentFamily={"telepass"}
            extraGetParametersVector={
              state?.telepassID
                ? [
                    {
                      name: "telepassID",
                      value: state?.telepassID,
                    },
                  ]
                : undefined
            }
          />
          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={props.disabled}
                onClick={() => {
                  triggerFormValidation(
                    "StepTelepass" +
                      (!props.currentTelepass
                        ? "_new_telepass"
                        : "_" + props.index)
                  );
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveTelepass")}
              </Button>
              {isInDashBoard ? (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.telepassID || props.disabled}
                  onClick={() => {
                    SummonModal("TelepassArchive-modal_" + props.index);
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:archiveTelepass")}
                </Button>
              ) : (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.telepassID || props.disabled}
                  onClick={() => {
                    SummonModal("TelepassDelete-modal_" + props.index);
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteTelepass")}
                </Button>
              )}

              <SmartModal
                modalUniqueId={"TelepassArchive-modal_" + props.index}
                title={i18next.t("generic:titleRecordModalArchive")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModalArchive")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          i18next
                            .t("navigation:telepassTabTitle")
                            .toLowerCase() +
                            " #" +
                            state?.telepassSerialNumber
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  telepasArchive(state);
                }}
                onReject={() => {}}
              />
              <SmartModal
                modalUniqueId={"TelepassDelete-modal_" + props.index}
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          i18next
                            .t("navigation:telepassTabTitle")
                            .toLowerCase() +
                            " #" +
                            state?.telepassSerialNumber
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  telepassDelete(state?.telepassID);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </div>
      )}
      <SmartModal
        modalUniqueId="Supplier-Telepass-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={props.loggedUser}
            data={[]}
            idModal="Supplier-Telepass-modal"
            updateList={(data: ISupplierGetSideList) => {
              loadSupplierTelepass();
            }}
            forceSupplierTypeCode={"TELEPASS"}
          />
        }
      />
      <LoaderBackdrop
        loading={
          processing || loadingForm || loadingVehicles || loadingCompanyList
        }
      />
    </div>
  );
};

export default FormTelepass;
