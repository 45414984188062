/* REACT */
import { useState } from "react";

/* STYLE */
import "./AddNewBanner.scss";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* MUI */
// ICons
import AddCircleIcon from "@mui/icons-material/AddCircle";

/* INTERFACE */
export interface IAddNewBanner {
  label: string;
  new: () => void;
  disabled?: boolean;
  suppressLayout?: boolean;
  forceDisplay?: boolean;
}

/* COMPONENT */
const AddNewBanner = (props: IAddNewBanner) => {
  /* STATES */
  const [showLayout] = useState<string>(props.suppressLayout ? "no-background" : "");

  /* CONSTS */
  const visible =
    !window.location.href.includes(AppRoutes.INTERNAL_DASHBOARD) || props.forceDisplay;

  /* RETURN */
  return (
    <div>
      {!visible && <div className="margin-top-small"></div>}

      {visible && (
        <div
          className={
            showLayout +
            " add-form-wrapper " +
            (props.disabled ? " disabled-add-form" : "")
          }
        >
          <div onClick={props.disabled ? () => {} : props.new}>
            <span className="button-add-form">
              <AddCircleIcon />
            </span>

            <span dangerouslySetInnerHTML={{ __html: props.label }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewBanner;
