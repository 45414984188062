export interface IGoogleAddress {
  latitude?: number;
  longitude?: number;
  street_number?: number;
  route?: string;
  postal_code?: string;
  locality?: string;
  state?: string;
  province?: string;
  country?: string;
}

export const getAddressCompleted = (address: IGoogleAddress) => {
  window.document.dispatchEvent(
    new CustomEvent("newAddressAutoComplete", {
      detail: address,
    })
  );
};

export const initAutocomplete = (addressField: any, loader: any) => {
  let autocomplete: any;
  let place: any;
  loader
    .load()
    .then((google: any) => {
      autocomplete = new google.maps.places.Autocomplete(addressField, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      autocomplete.addListener("place_changed", () => {
        place = autocomplete.getPlace();
        if (place === undefined) {
          return {};
        }
        let address: IGoogleAddress = {};
        address["latitude"] = place.geometry.location.lat();
        address["longitude"] = place.geometry.location.lng();

        for (const component of place.address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case "street_number": {
              address["street_number"] = component.long_name;
              break;
            }

            case "route": {
              address["route"] = component.short_name;
              break;
            }
            case "postal_code": {
              address["postal_code"] = component.long_name;
              break;
            }
            case "locality":
              address["locality"] = component.long_name;
              break;
            case "administrative_area_level_1": {
              address["state"] = component.short_name;
              break;
            }
            case "administrative_area_level_2": {
              address["province"] = component.short_name;
              break;
            }
            case "country": {
              address["country"] = component.short_name;
              break;
            }
          }
        }
        getAddressCompleted(address);
      });
    })
    .catch((e: any) => {
    });
};
