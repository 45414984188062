import "./WhoWeArePage.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Trans} from "react-i18next";
import BannerCarouselComponent from "../../Components/BannerCarouselComponent/BannerCarouselComponent";
import BannerVideoComponent from "../../Components/BannerVideoComponent/BannerVideoComponent";
import BannerCardComponent from "../../Components/BannerCardComponent/BannerCardComponent";
import BannerTextComponent from "../../Components/BannerTextComponent/BannerTextComponent";
import i18next from "i18next";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const companyLogoImg = [
    {src: 'agos.png', alt: 'Logo Agos'},
    {src: 'carandfun.png', alt: 'Logo CarAndFun'},
    {src: 'coca_cola.png', alt: 'Logo Coca Cola'},
    {src: 'Credit_Suisse.png', alt: 'Logo Credit Suisse'},
    {src: 'dompe.png', alt: 'Logo Dompe'},
    {src: 'edison.png', alt: 'Logo Edison'},
    {src: 'fastweb.png', alt: 'Logo Fastweb'},
    {src: 'getinge.png', alt: 'Logo Getinge'},
    {src: 'aon.png', alt: 'Logo Aon'},
    {src: 'b3.png', alt: 'Logo B3'},
    {src: 'sobi.png', alt: 'Logo Sobi'},
    {src: 'txt.png', alt: 'Logo TXT'},
    {src: 'wolterskluwer.png', alt: 'Logo Wolterskluwer'},
    {src: 'wurth.png', alt: 'Logo Wurth'}
];

const WhoWeArePage = () => {

    const imgStructureSection = '/Images/newImages/CTA_cropped.png';

    return (
        <div className="ff-whowearepage">
            <BannerVideoComponent
                filename='video-company-720p-trimmed.mp4'
                title={<Trans i18nKey='intro:whoWeAreFirstTitle' components={[<strong/>, <br/>]}/>}
            />

            <BannerCardComponent
                rightTitle={<Trans i18nKey='intro:theCompany'/>}
                leftContent={
                    <div className="ff-image-box ff-img-left">
                        <img src={'/Images/newImages/353210425.jpeg'} alt="" loading="lazy"/>
                    </div>
                }
                rightContent={
                    <div className="ff-text-box">
                        <p>{<Trans i18nKey='intro:whoWeAreFirstParagraph' components={[<strong/>]}/>}</p>
                        <p>{<Trans i18nKey='intro:whoWeAreSecondParagraph' components={[<strong/>]}/>}</p>
                    </div>
                }
            />

            <BannerTextComponent
                imgPath={'/Images/newImages/168030344.jpeg'}
                textTitle={<Trans i18nKey='intro:whoWeAreSecondTitle' components={[<strong/>, <br/>]}/>}
                textContent={<Trans i18nKey='intro:whoWeAreSecondParagraphSection'/>}
            />

            <BannerCardComponent
                leftTitle={<Trans i18nKey='intro:whoWeAreFirstMidTitle'/>}
                leftContent={
                    <div className="ff-text-box ff-mission-par">
                        <p>{<Trans i18nKey='intro:whoWeAreFirstMidParagraph' components={[<strong/>]}/>}</p>
                    </div>
                }
                rightContent={
                    <div className="ff-points-wrapper" style={{width: "150%", position: "relative", top: "-72px", left: "0"}}>
                        <div className="ff-second-mid-paragraph-wrapper">
                            <h2 className="ff-second-mid-title">{<Trans i18nKey='intro:whoWeAreSecondMidTitle'
                                                                        components={[<strong/>]}/>}</h2>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph1')}</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph2')}</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph3')}</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph4')}</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph5')}</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
                                <CircleRoundedIcon className="ff-icon-circle-right"/>
                                <p className="ff-second-mid-text">{i18next.t('intro:whoWeAreSecondMidParagraph6')}</p>
                            </div>
                        </div>
                    </div>
                }
            />

            <BannerCarouselComponent
                title={<Trans i18nKey='intro:someCustomers' components={[<strong/>]}/>}
                images={companyLogoImg}
            />

            <BannerCardComponent
                leftTitle={<Trans i18nKey='intro:onlyRecognitions'/>}
                leftContent={
                    <div className="ff-text-box">
                        <h1>{<Trans i18nKey='intro:MFA2022_title'/>}</h1>
                        <p className="ff-recognitions-par">{<Trans i18nKey='intro:MFA2022_description'/>}</p>
                    </div>
                }
                rightContent={
                    <div className="ff-image-box ff-img-right">
                        <img src={'/Images/newImages/recogn1.jpg'} alt="" loading="lazy"/>
                    </div>
                }
            />

            <BannerCardComponent
                leftContent={
                    <div className="ff-image-box ff-img-left">
                        <img src={'/Images/newImages/recogn2.jpg'} alt="" loading="lazy"/>
                    </div>
                }
                rightContent={
                    <div className="ff-text-box ff-rec-box">
                        <h1>{<Trans i18nKey='intro:MFA2023_title'/>}</h1>
                        <p className="ff-recognitions-par">{<Trans i18nKey='intro:MFA2023_description'/>}</p>
                    </div>
                }
            />

            <div className="ff-structure-banner" style={{backgroundImage: `url(${imgStructureSection})`}}>
                <div className="ff-structure-overlay"></div>
                <div className="ff-structure-content">
                    <div className="ff-structure-box">
                        <h2>{<Trans i18nKey='intro:structure_firstTitle'/>}</h2>
                        <p className="ff-structure-box-text">{<Trans i18nKey='intro:structure_firstDescription1'/>}</p>
                        <p className="ff-structure-box-text">{<Trans i18nKey='intro:structure_firstDescription2'/>}</p>
                    </div>
                    <div className="ff-structure-box">
                        <h2>{<Trans i18nKey='intro:structure_secondTitle'/>}</h2>
                        <p className="ff-structure-box-text">{<Trans i18nKey='intro:structure_secondDescription'/>}</p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default WhoWeArePage;
