import { Autocomplete, IconButton, TextField } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { ICompanyGet } from "../../Models/ICompany";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import FlagPicker from "../FlagPicker/FlagPicker";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import MenuIcon from "@mui/icons-material/Menu";
import "./BlueToolbarInternal.scss";
import { triggerNavMenu } from "../NavMenu/NavMenu";
import { userRoleFits } from "../../Utils/Session";
import AccountAvatar from "../AccountAvatar/AccountAvatar";
import NotificationCenter from "../NotificationCenter/NotificationCenter";
import MessageCenter from "../MessageCenter/MessageCenter";

export interface ISearchFormProps {
  autocompleteOptions: any[];
}

export const ActionZone = () => {
  const menuVoicesExpired: boolean | undefined = useSelector(
    (state: GlobalState) => state.user.menuPagesExpired
  );

  /* RETURN */
  return (
    <div className="blue-toolbar-right-wrap-buttons">
      {!menuVoicesExpired && (
        <>
          <MessageCenter />

          {userRoleFits(["FleetManager", "Broker"]) && <NotificationCenter />}
        </>
      )}

      <FlagPicker />

      <AccountAvatar />
    </div>
  );
};

const SearchForm = (props: ISearchFormProps) => {
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState<string>("");
  const menuVoicesExpired: boolean | undefined = useSelector(
    (state: GlobalState) => state.user.menuPagesExpired
  );
  const getSearchOptions = () => {
    let options: any[] = (props.autocompleteOptions ?? []).map(
      (x: { key: string | number | boolean; text: string }) => {
        return {
          key: x.key,
          label: x.text,
        };
      }
    );

    let final: any[] = [{ key: searchedText, label: searchedText }];
    for (let i = 0; i < options.length; i++) {
      if (
        final.filter(
          (x: any) =>
            x.key.toLocaleLowerCase().trim() === options[i].key.toLocaleLowerCase().trim()
        ).length === 0
      ) {
        final.push({ ...options[i] });
      }
    }

    return final;
  };

  const autocompleteOptions = getSearchOptions();

  return (
    <div>
      <Autocomplete
        className="toolbar-searchbar"
        disabled={menuVoicesExpired}
        id={"combo-box-searchBar"}
        options={autocompleteOptions}
        onBlur={() => {
          setSearchedText("");
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            navigate(
              `${AppRoutes.INTERNAL_DASHBOARD}${AppRoutes.SEARCH}?searchText=${searchedText}`
            );
          }
        }}
        onChange={(
          e: any,
          value: {
            key: number | string | boolean;
            label: string;
          } | null
        ) => {
          if (value) {
            let selectedOption = value.key.toString();
            if (selectedOption) {
              setSearchedText(selectedOption);
              navigate(
                `${AppRoutes.INTERNAL_DASHBOARD}${AppRoutes.SEARCH}?searchText=${selectedOption}`
              );
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e: any) => {
              setSearchedText(e.target.value);
            }}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                paddingLeft: "20px !important",
                borderRadius: "50px",
              },
              "& .MuiInputLabel-outlined": {
                paddingLeft: "20px",
              },
              "& .MuiInputLabel-shrink": {
                paddingLeft: "20px",
              },
              "& .MuiFilledInput-root:before": {
                borderBottom: "transparent",
              },
              "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
                borderBottom: "transparent",
              },
            }}
            variant={"filled"}
            label={i18next.t("form:searchbar_text").toString()}
          />
        )}
      />
    </div>
  );
};

const BlueToolbarInternal = () => {
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const navigate = useNavigate();
  const location = useLocation();

  /* STATES */
  // State for data container
  const [rerender, setRerender] = useState<boolean>(false);

  // State for search result list
  const [searchList, setSearchList] = useState<
    { type: "driver" | "plate"; value: any }[]
  >([]);

  // State for spinning and loading the process
  const [loadingSearchList, setLoadingSearchList] = useState<boolean>(false);

  /* API */
  /* GET */
  // Api to load search result list
  const loadSearchList = () => {
    if (loggedUserCompany) {
      setLoadingSearchList(true);
      ApiService.VehicleDataController.VehicleSearchPreview(
        loggedUserCompany.companyID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSearchList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSearchList(false);
        }
      );
    }
  };

  const doRerender = () => {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 50);
  };

  /* USE EFFECT */
  useEffect(() => {
    loadSearchList();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes(AppRoutes.SEARCH)) {
      doRerender();
    }
  }, [location]);

  /* VAR */
  // Autocomplete options

  let autocompleteOptions = searchList.map(
    (item: { type: "driver" | "plate"; value: any }) => {
      return {
        key: item.value.toString(),
        text: item.type === "plate" ? formatPlate(item.value) : item.value,
        extra: item,
      };
    }
  );

  /* RETURN */
  return (
    <>
      <nav>
        <div className="blue-toolbar-main-wrap-solid">
          <div className="blue-toolbar-left-wrap">
            <div className="show-on-mobile">
              <IconButton
                style={{ color: "white" }}
                onClick={() => {
                  triggerNavMenu();
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className="hide-on-mobile">
              <div>
                <img
                  className="logo"
                  src="/Images/logo-dark.svg"
                  onClick={() => {
                    navigate(AppRoutes.HOME_PAGE_ROUTE);
                  }}
                />
              </div>
            </div>
            {userRoleFits(["FleetManager", "Broker"]) &&
              (rerender ? (
                <SearchForm autocompleteOptions={autocompleteOptions} />
              ) : (
                <SearchForm autocompleteOptions={autocompleteOptions} />
              ))}
          </div>
          <div className="hide-on-mobile">{<ActionZone />}</div>
        </div>
      </nav>

      <LoaderBackdrop loading={loadingSearchList} />
    </>
  );
};

export default BlueToolbarInternal;
