import "./BannerCardComponent.scss";
import React, { useLayoutEffect, useRef, useState } from "react";
import backgroundImage from "../../Assets/ImagesEmbedded/Grainy_Background.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";

export type BannerCardProps = React.HTMLAttributes<HTMLElement> & {
  disableAnimation?: boolean;
  leftTitle?: React.ReactNode;
  rightTitle?: React.ReactNode;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  variant?: "light" | "dark";
  imgPath?: string;
};

export const BannerCardComponent: React.FC<BannerCardProps> = ({
  disableAnimation,
  leftTitle,
  rightTitle,
  leftContent,
  rightContent,
  variant = "dark",
  imgPath,
  ...articleProps
}) => {
  const leftTitleRef = useRef<HTMLHeadingElement>(null);
  const rightTitleRef = useRef<HTMLHeadingElement>(null);

  const [leftBarWidth, setLeftBarWidth] = useState<number>();
  const [rightBarWidth, setRightBarWidth] = useState<number>();

  const matches = useMediaQuery("(max-width: 1570px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { ref: leftTextRef, inView: leftTextInView } = useInView({
    triggerOnce: true,
    rootMargin: "-50px",
  });

  const { ref: rightTextRef, inView: rightTextInView } = useInView({
    triggerOnce: true,
    rootMargin: "-50px",
  });

  useLayoutEffect(() => {
    const evaluateBarWidth = () => {
      if (leftBarWidth && isMobile) {
        setLeftBarWidth(undefined);
        return;
      }

      if (rightBarWidth && isMobile) {
        setRightBarWidth(undefined);
        return;
      }

      if (leftTitleRef.current) {
        const leftWidth = leftTitleRef.current.offsetLeft + leftTitleRef.current.clientWidth;
        if (leftWidth !== leftBarWidth && !isMobile) {
          setLeftBarWidth(leftTitleRef.current.offsetLeft + leftTitleRef.current.clientWidth);
        }
      }

      if (rightTitleRef.current) {
        if (rightTitleRef.current.offsetLeft !== rightBarWidth && !isMobile) {
          setRightBarWidth(rightTitleRef.current.offsetLeft);
        }
      }
    };

    evaluateBarWidth();
    window.addEventListener("resize", evaluateBarWidth);
    return () => {
      window.removeEventListener("resize", evaluateBarWidth);
    };
  }, [isMobile, leftBarWidth, rightBarWidth]);

  return (
    <article
      {...articleProps}
      className={`ff-banner-card-wrapper ${variant}`}
      style={{ backgroundImage: imgPath ? `url(${imgPath})` : `url(${backgroundImage})` }}
    >
      <div className="ff-banner-card-container">
        <div style={{ zIndex: 10 }}>
          {leftTitle && <h2 ref={leftTitleRef}>{leftTitle}</h2>}
          {leftTitle && <h3 style={{ width: leftBarWidth }}>{leftTitle}</h3>}

          {matches || disableAnimation ? (
            <div className="ff-box-content">{leftContent}</div>
          ) : (
            <motion.div
              ref={leftTextRef}
              initial={{ x: "-100%" }}
              animate={leftTextInView ? { x: 0 } : { x: "-100%" }}
              transition={{ type: "spring", stiffness: 50 }}
              className="ff-animation-mobile"
            >
              <div className="ff-box-content">{leftContent}</div>
            </motion.div>
          )}
        </div>
        <div>
          {rightTitle && <h2 ref={rightTitleRef}>{rightTitle}</h2>}
          {rightTitle && <h3 style={{ width: rightBarWidth }}>{rightTitle}</h3>}

          {matches || disableAnimation ? (
            <div className="ff-box-content">{rightContent}</div>
          ) : (
            <motion.div
              ref={rightTextRef}
              initial={{ x: "100%" }}
              animate={rightTextInView ? { x: 0 } : { x: "100%" }}
              transition={{ type: "spring", stiffness: 50 }}
              className="ff-animation-mobile"
            >
              <div className="ff-box-content">{rightContent}</div>
            </motion.div>
          )}
        </div>
      </div>
    </article>
  );
};

export default BannerCardComponent;
