import i18next from "i18next";
import "./AwaitConfirmationPaymentPage.scss";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { displayUTC0_ISODate } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const AwaitConfirmationPaymentPage = () => {
  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );
  /* RETURN */
  return ( 
    <div className="await-confirmation-payment-page-wrap">
      {getCurrentSubscription.isTrial ?
        <div className="await-confirmation-text-wrap">
          <h1
            dangerouslySetInnerHTML={{
              __html: i18next.t("navigation:awaitTrialModeTitle") ?? "",
            }}
          ></h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: i18next.t("navigation:awaitTrialModeSubtitle").replace("TRIAL_END_DATE", getCurrentSubscription.trialEndDT !== null ? displayUTC0_ISODate(getCurrentSubscription.trialEndDT, true) : displayUTC0_ISODate(getCurrentSubscription.validTo, true)) ?? "",
            }}
          />
        </div>
      :
        <div className="await-confirmation-text-wrap">
          <h1
            dangerouslySetInnerHTML={{
              __html: i18next.t("navigation:awaitConfirmPaymentTitle") ?? "",
            }}
          ></h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: i18next.t("navigation:awaitConfirmPaymentSubtitle") ?? "",
            }}
          ></h2>
        </div>  
      }            
    </div>
  );
};

export default AwaitConfirmationPaymentPage;
