import { JSONPrint } from "../../Utils/Decoder";
import "./ExpiryBox.scss";

export interface IExpiryBoxProps {
  title: string;
  subTitle: string;
  date: string;
  icon: JSX.Element;
}

const ExpiryBox = (props: IExpiryBoxProps) => {
  return (
    <div className="expiry-box-wrap">
      <div className="expiry-box-icon-zone">{props.icon}</div>
      <div className="expiry-box-title-zone">
        <div className="expiry-box-title">{props.title}</div>
        {props.subTitle && (
          <div className="expiry-box-subtitle">{props.subTitle}</div>
        )}
      </div>
      <div className="expiry-box-date-zone">
        <div>{props.date}</div>
      </div>
    </div>
  );
};

export default ExpiryBox;
