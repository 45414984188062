/* STYLE */
import "./CO2Emission__StatisticsPage.scss";

/* COMPONENTS */
import CO2Emission__StatisticsPage_Data from "./CO2Emission__StatisticsPage_Data";

/* INTERFACE */
interface ICO2Emission__StatisticsPage_Body {
  title?: string | null;
}

/* COMPONENT */
const CO2Emission__StatisticsPage_Body = (props: ICO2Emission__StatisticsPage_Body) => {
  /* RETURN */
  return (
    <div className="dashboardForm__box">
      {props.title && (
        <h1
          className="text-color-hint"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      )}

      <CO2Emission__StatisticsPage_Data />
    </div>
  );
};

export default CO2Emission__StatisticsPage_Body;
