/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./QuotesPageReceived.scss";

/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import AccordionContent from "../../../../../Components/AccordionContent/AccordionContent";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
/* MUI */
import { Alert, Box, Button, CircularProgress, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApiService from "../../../../../Services/ApiService";
import { IAPIRequest, IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IQuotation, IQuotationSolution } from "../../../../../Models/IQuotation";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { IVehicleDataGet } from "../../../../../Models/IVehicles";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";
import { renderInterventionTypeString } from "../QuotesPageRequests/QuotesPageRequests";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import { downloadWithName } from "../../../../../Utils/FileToBase";
import { getBlobDesc } from "../../../../../Utils/FileToBase";
import { useSearchParams } from "react-router-dom";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { sendMessage } from "../../../../../Utils/Messaging";
import { IUser } from "../../../../../Models/IUser";

const SolutionTableDisplay = (props: {
  solution: IQuotationSolution;
  onReloadPls: () => void;
  previewFile: (solution: IQuotationSolution) => void;
  downloadFile: (solution: IQuotationSolution) => void;
}) => {
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [solution, setSolution] = useState<IQuotationSolution>(props.solution);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [quotationRequest, setQuotationRequest] = useState<IQuotation>();
  const [vehicle, setVehicle] = useState<IVehicleDataGet>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const preopenId = +(searchParams.get("preopenId") ?? "0");

  const getBaseMsg = (text: string, preopenId: number) => {
    return JSON.stringify({
      triggerContent: "unpackJsonMessage",
      baseText: text,
      extraParams: [
        {
          key: "$PREOPEN_URL",
          value:
            AppRoutes.INTERNAL_DASHBOARD +
            AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE +
            "?preopenId=" +
            preopenId.toString(),
        },
        {
          key: "$SERVICE_NAME",
          fun: "renderInterventionTypeString",
          value: quotationRequest?.interventionType,
        },
        {
          key: "$REQUESTER",
          value: loggedUser?.accountName + " " + loggedUser?.accountSurname,
        },
      ],
    });
  };

  const sendAcceptanceMessage = (accepted: boolean, targId: number) => {
    /// accepted
    if (accepted) {
      sendMessage(
        solution.solutionOwnerId,
        "translate__quotation_solution_obj_acc",
        getBaseMsg("translate__quotation_solution_accepted", targId),
        getBaseMsg("translate__quotation_solution_accepted_short", targId),
        AppRoutes.INTERNAL_DASHBOARD +
          AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE +
          "?preopenId=" +
          targId
      );
    } else {
      sendMessage(
        solution.solutionOwnerId,
        "translate__quotation_solution_obj_ref",
        getBaseMsg("translate__quotation_solution_refused", targId),
        getBaseMsg("translate__quotation_solution_refused_short", targId),
        AppRoutes.INTERNAL_DASHBOARD +
          AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE +
          "?preopenId=" +
          targId
      );
    }
  };

  const acceptStatusUpdate = (status: number) => {
    setLoading(true);
    let solution_: IQuotationSolution = { ...solution };
    solution_.accepted = status;
    if (!solution_.note) {
      solution_.note = "";
    }
    ApiService.QuotationController.QuotationsSolutionUpdate(
      solution_,
      (response: IAPIResponse) => {
        if (response.error === null) {
          sendAcceptanceMessage(status === 1, solution.solutionToQuotationId);
          ToastMessage(i18next.t("navigation:quotation_solution_update"), "success");
          props.onReloadPls();
        } else {
          ToastMessage(i18next.t("error:generic_api_error"), "error");
        }
        setLoading(false);
      }
    );
  };

  const emitFilePreview = () => {
    if (solution.attachmentFile) {
      props.previewFile(solution);
    }
  };
  const emitDownloadFile = () => {
    if (solution.attachmentFile) {
      props.downloadFile(solution);
    }
  };

  /* STATES */
  /* FUNCTIONS */
  // Render download
  const renderDownload = (props: GridRenderCellParams) => {
    return (
      <div>
        <IconButton onClick={emitFilePreview}>
          <VisibilityIcon className="icon-table" />
        </IconButton>

        <IconButton onClick={emitDownloadFile}>
          <FileDownloadIcon className="icon-table" />
        </IconButton>
      </div>
    );
  };

  // Render button
  const renderButton = (props: GridRenderCellParams) => {
    const acceptStatus: 0 | 1 | 2 = props.row.accepted;

    let contentAccpeted = <div></div>;

    // Check accepted status
    if (acceptStatus === 0) {
      contentAccpeted = (
        <div
          className="display-flex-vertical-center content-accepted"
          style={{ gap: "2em", justifyContent: "space-evenly" }}
        >
          <Button
            variant="contained"
            onClick={() => {
              acceptStatusUpdate(1);
            }}
            className="buttonAccept"
            startIcon={<DoneIcon />}
          >
            {i18next.t("form:accept")}
          </Button>
          <Button
            variant="contained"
            className="buttonAccept"
            onClick={() => {
              acceptStatusUpdate(2);
            }}
            startIcon={<DeleteForeverIcon />}
          >
            {i18next.t("navigation:refuse")}
          </Button>
        </div>
      );
    }

    if (acceptStatus === 1) {
      contentAccpeted = (
        <div className="display-flex-vertical-center content-accepted">
          <DoneIcon /> {i18next.t("navigation:km_code_status_ACCEPTED")}
        </div>
      );
    }

    if (acceptStatus === 2) {
      contentAccpeted = (
        <div className="content-refused">
          {i18next.t("navigation:km_code_status_REFUSED")}
        </div>
      );
    }

    return <div className="text-align-center">{contentAccpeted}</div>;
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "download",
      headerName: i18next.t("form:attachments"),
      renderCell: renderDownload,
      type: 'custom'
    },
    {
      field: "requestCreated",
      headerName: i18next.t("form:_mctc_dataRichiestaEvento"),
    },
    {
      field: "solutionCreated",
      headerName: i18next.t("form:creationData"),
    },
    {
      field: "button",
      headerName: "",
      renderCell: renderButton,
      type: 'custom'
    },
  ];

  const loadVehicle = () => {
    setLoading(true);

    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      quotationRequest?.vehicleId,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            setVehicle(response.payload[0]);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const loadQuotationsRequests = () => {
    setLoading(true);
    ApiService.QuotationController.QuotationsGet(
      solution.solutionToQuotationId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            setQuotationRequest(response.payload[0]);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (quotationRequest) {
      loadVehicle();
    }
  }, [quotationRequest]);

  useEffect(() => {
    loadQuotationsRequests();
  }, []);

  return (
    <div>
      {!loading && (
        <AccordionContent
          startsOpen={preopenId === solution.solutionId}
          classNameAccordion="margin-vertical-small accordion-noBorderRadius"
          classNameSummary="accordion-background padding-horizontal-medium"
          title={
            <div>
              <strong>{i18next.t("form:requestNumber")}:</strong>{" "}
              {quotationRequest?.quotationId}
            </div>
          }
          middleSummary={
            <div className="display-flex gap-extra-large">
              <div>
                <strong>{i18next.t("form:vehicle")}:</strong>{" "}
                {formatPlate(vehicle?.vehicleLicensePlate)}
              </div>
              <div>
                <strong>{i18next.t("form:operation")}:</strong>{" "}
                {renderInterventionTypeString(quotationRequest?.interventionType)}
              </div>
            </div>
          }
          content={
            <div className={"margin-vertical-small"}>
              <DataGridWrap
                headers={columnsDef}
                rows={[
                  {
                    ...solution,
                    solutionCreated: displayUTC0_ISODate((solution as any)?.createdDT),
                    requestCreated: displayUTC0_ISODate(
                      (quotationRequest as any)?.createdDT
                    ),
                  },
                ]}
                hideToolbar={true}
              />
            </div>
          }
        />
      )}
      {loading && (
        <div className="loading-placeholder">
          <CircularProgress size={"1em"} />
        </div>
      )}
    </div>
  );
};

/* COMPONENT */
const QuotesPageReceived = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [solutions, setSolutions] = useState<IQuotationSolution[]>([]);
  const [openSolution, setOpenSolution] = useState<IQuotationSolution | null>(null);

  const loadProposalsForUserQuotes = () => {
    setSolutions([]);
    setLoading(true);
    ApiService.QuotationController.QuotationsSolutionGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setSolutions(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadProposalsForUserQuotes();
  }, []);

  const [filePreviewData, setFilePreviewData] = useState<string[]>([]);

  const base64size = () => {
    if (filePreviewData.length > 1) {
      let str = filePreviewData[1];
      let size = str.length * (3 / 4) - (str.endsWith("==") ? 2 : 1);

      const formatSizeUnits = (bytes: number) => {
        if (bytes >= 1073741824) {
          return (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
          return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
          return (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
          return bytes + " bytes";
        } else if (bytes == 1) {
          return bytes + " byte";
        } else {
          return "0 bytes";
        }
      };
      return formatSizeUnits(size);
    }
    return "";
  };

  const fileSize = base64size();

  const previewFileWithName = (path: string, fileName: string) => {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest();

    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var file = new Blob([xhr.response], { type: "application/octet-stream" });

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result as string;
        let data: string[] = [fileName, result];

        setFilePreviewData(data);
        setTimeout(() => {
          SummonModal("preview_modal_attach");
        }, 500);
      };
    };
    xhr.send();
  };

  const downloadFile = (solution: IQuotationSolution) => {
    ApiService.UploadController.GetSASString(
      "attachment-forquotationsolutions",
      solution.attachmentFile,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(solution.attachmentFile ?? ""));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  const previewFile = (solution: IQuotationSolution) => {
    setOpenSolution(solution);
    setFilePreviewData([]);
    ApiService.UploadController.GetSASString(
      "attachment-forquotationsolutions",
      solution.attachmentFile,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            previewFileWithName(sas, getBlobDesc(solution.attachmentFile ?? ""));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:quotesReceived")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          {solutions.reverse().map((x: IQuotationSolution) => {
            return (
              <SolutionTableDisplay
                downloadFile={downloadFile}
                previewFile={previewFile}
                solution={x}
                onReloadPls={() => {
                  loadProposalsForUserQuotes();
                }}
              />
            );
          })}
        </Box>
      </div>
      {filePreviewData.length > 0 && (
        <SmartModal
          modalUniqueId="preview_modal_attach"
          title={filePreviewData[0]}
          modalInnerComponent={
            <div>
              <strong>{i18next.t("form:note")}</strong>
              <div>{openSolution?.note}</div>
              <hr />

              {fileSize.includes("GB") ||
                (fileSize.includes("MB") && (
                  <Alert severity="info">
                    {i18next.t("navigation:too_big_pls_wait")} {fileSize}
                  </Alert>
                ))}
              <br />
              <iframe
                style={{ width: "80vw", height: "80vh" }}
                src={filePreviewData[1].replaceAll(
                  "application/octet-stream",
                  "application/pdf"
                )}
              ></iframe>
            </div>
          }
          onReject={() => {
            DismissModal("preview_modal_attach");
          }}
        />
      )}
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default QuotesPageReceived;
