import React, {useEffect, useState} from "react";
import "./FlagPicker.scss";
import {England, Italy} from "./Flags";

export interface ILanguage {
    key: string;
    text: string;
    image: string;
}

export interface IISOLanguage {
    languageID: number;
    languageISO2: string;
    languageISO3: string;
    languageName: string;
}

export type FlagPickerProps = {
    isPublic?: boolean;
}

const voices = [
    {
        key: "ita",
        text: "ITA",
        image: Italy,
    },
    {
        key: "eng",
        text: "ENG",
        image: England,
    },
];

const FlagPicker: React.FC<FlagPickerProps> = ({isPublic}) => {
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguage | null>(null);

    const pickByKey = (key: string) => {
        const match = voices.filter((x: ILanguage) => x.key === key);
        const option = match.length > 0 ? match[0] : voices[0];
        setSelectedLanguage(option);
        localStorage.setItem("language", option.key);
    };

    const getCurrentLang = () => {
        const lang = navigator.language.split("-")[0];
        const storedLanguage = localStorage.getItem("language");
        const target = storedLanguage ? storedLanguage : lang;
        return target;
    };

    useEffect(() => {
        pickByKey(getCurrentLang());
    }, []);

    return isPublic ? (
            <select
                defaultValue={getCurrentLang()}
                id="flag-picker-select"
                className="ff-flag-picker-select"
                onChange={(e) => {
                    let key = e.target.value;
                    pickByKey(key);
                    window.location.reload();
                }}
            >
                {voices.map((x: ILanguage, i: number) => {
                    return (
                        <option key={i} value={x.key}>
                            {x.text}
                        </option>
                    );
                })}
            </select>
        ) :
        (
            <div>
                {selectedLanguage && (
                    <div className="flag-picker-wrap">
                        <img
                            className="flag-picker-image"
                            src={process.env.PUBLIC_URL + selectedLanguage.image}
                            alt={selectedLanguage.text}
                        />

                        {/* <IconButton color="primary" aria-label="language" component="label">
            <LanguageIcon />
          </IconButton> */}

                        <select
                            defaultValue={getCurrentLang()}
                            id="flag-picker-select"
                            className="flag-picker-select"
                            onChange={(e) => {
                                let key = e.target.value;
                                pickByKey(key);
                                window.location.reload();
                            }}
                        >
                            {voices.map((x: ILanguage, i: number) => {
                                return (
                                    <option key={i} value={x.key}>
                                        {x.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            </div>
        );
};

export default FlagPicker;
