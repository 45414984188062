import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleReservationOutgoingInsert,
  IVehicleReservationOutgoingUpdate,
} from "../../Models/IVehicleReservationOutgoing";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleReservationOutgoingController = {
  VehicleReservationOutgoingGet: async (
    companyID: number,
    vehicleReservationID: number,
    vehicleID: number,
    outgoingVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_OUTGOING_GET;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleReservationID) {
      parameters.push(`vehicleReservationID=${vehicleReservationID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (outgoingVehicleID) {
      parameters.push(`outgoingVehicleID=${outgoingVehicleID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationOutgoingInsert: async (
    body: IVehicleReservationOutgoingInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_OUTGOING_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationOutgoingUpdate: async (
    body: IVehicleReservationOutgoingUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_OUTGOING_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationOutcomingDelete: async (
    outgoingVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_OUTGOING_DELETE;
    request.method = "DELETE";
    request.payload = {
      outgoingVehicleID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleReservationOutgoingController;
