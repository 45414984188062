/* REACT */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MODELS */
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IUser } from "../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../Models/IVehicles";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Status__VehiclesPage.scss";

/* LIBRARY */
import i18next from "i18next";
import moment from "moment";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import FormVehicleData from "../../../../../Components/StepVehicles/TabVehicleData/FormVehicleData";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../VehiclesPage";
import { dateIsPast, displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* MUI */
import { Box, Button, ButtonGroup, CircularProgress, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IMctcGet } from "../../../../../Models/IMctcGet";

import { requestFormRefresh } from "../../../../../Components/MultiForm/MultiForm";
import { VehiclesActions } from "../../../../../Reducers/Vehicle/VehicleAction";
import { t } from "i18next";

/* RENDER BRAND IMAGE - Datagrid */
export const renderBrandImageGrid = (props: GridRenderCellParams) => {
  if (props.value) {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          onError={(e: any) =>
            (e.target.src =
              process.env.PUBLIC_URL + "/Images/brandVehicle/brand-not-found.svg")
          }
          src={
            process.env.PUBLIC_URL +
            "/Images/brandVehicle/" +
            props.value.replace(" ", "_") +
            ".png"
          }
          title={props.value}
          alt={props.value}
        />
      </div>
    );
  } else {
    return (
      <div className="table-image-brand-wrapper">
        <img
          className="image-responsive"
          src={process.env.PUBLIC_URL + "/Images/brandVehicle/brand-not-found.svg"}
          alt=''
        />
      </div>
    );
  }
};

/* INTERFACE */
interface IVehiclePage {
  title?: string | null;
}

/* COMPONENT */
const Status__VehiclesPage_Data = (props: IVehiclePage) => {
  const dispatch = useDispatch();
  const vehicleMctc: IMctcGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.vehicleMctc
  );
  /* REDUCER OF USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATES */
  const [loadingVehiclesList, setLoadingVehiclesList] = useState<boolean>(false);

  // Container for list
  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);
  const [checkCanInsert, setCheckCanInsert] = useState<boolean>(false);
  const [loadingCheckCanInsert, setLoadingCheckCanInsert] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [stateVehicle, setStateVehicle] = useState<any>({});
  /* STATES */
  /* APIs */
  const getVehicles = () => {
    setLoadingVehiclesList(true);
    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehiclesList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehiclesList(false);
      }
    );
  };

  const checkCanInsertVehicle = () => {
    setLoadingCheckCanInsert(true);
    ApiService.VehicleDataController.CheckUserCanInsertVehicle(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCheckCanInsert(response.payload);
        } else {
          if (response.payload.detail) {
            ToastMessage(i18next.t("error:" + response.payload.detail), "error");
          } else {
            ToastMessage(response.error, "error");
          }
        }
        setLoadingCheckCanInsert(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    getVehicles();
    checkCanInsertVehicle();
  }, []);

  useEffect(() => {
    if(loggedUser){
      checkCanInsertVehicle();
    }
  }, [loggedUser]);

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 1000);
  };

  useEffect(() => {
    if (vehicleMctc && vehicleMctc.forForm !== undefined) {
      let cleanState: any = {};
      let keys = Object.keys(vehicleMctc.forForm);
      for (let i = 0; i < keys.length; i++) {
        let val: any = vehicleMctc.forForm[keys[i]];
        if (val !== null && val !== undefined) {
          cleanState[keys[i]] = val;
        }
      }
      setStateVehicle({ ...stateVehicle, ...cleanState });
      requestFormRefresh();
    }
  }, [vehicleMctc]);

  /* FUNCTIONS */
  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  const renderDisposed = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value ? i18next.t('form:disposedVehicle') : i18next.t('form:activeVehicle')}
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "contractType",
      headerName: i18next.t("navigation:contractTabTitle"),
    },
    {
      field: "brand",
      headerName: i18next.t("form:orderBrand"),
      renderCell: renderBrandImageGrid,
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "fitting",
      headerName: i18next.t("form:orderEquipment"),
    },
    {
      field: "driverNameSurname",
      headerName: i18next.t("form:assignDriverName"),
    },
    {
      field: "chassis",
      headerName: i18next.t("form:_mctc_numeroTelaio"),
    },
    {
      field: "revision",
      headerName: i18next.t("form:lastWarrantyCheck"),
      fieldOriginal: "revisionOriginal",
    },
    {
      field: "duty",
      headerName: i18next.t("form:dateExpirePayVehicle"),
      fieldOriginal: "dutyOriginal",
    },
    {
      field: "disposed",
      headerName: i18next.t("form:statusVehicle"),
      renderCell: renderDisposed
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < vehiclesList.length; i++) {
    rowsDef.push({
      id: i,
      vehicleID: vehiclesList[i].vehicleID,
      plate: vehiclesList[i].vehicleLicensePlate,
      contractType: vehiclesList[i].contractTypeName || "-",
      brand: vehiclesList[i].brandDescription,
      model: vehiclesList[i].modelDescription?.replace(/\s\s+/g, " "),
      fitting: vehiclesList[i].fittingDescription,
      chassis: vehiclesList[i].vehicleChassis,
      revision: vehiclesList[i].vehicleLastInspectionDT
        ? displayUTC0_ISODate(vehiclesList[i].vehicleLastInspectionDT, true)
        : "-",
      revisionOriginal: vehiclesList[i].vehicleLastInspectionDT,
      duty: vehiclesList[i].vehicleExpireRoadTaxDT
        ? displayUTC0_ISODate(vehiclesList[i].vehicleExpireRoadTaxDT, true)
        : "-",
      dutyOriginal: vehiclesList[i].vehicleExpireRoadTaxDT,
      disposed: vehiclesList[i].disposed,
      driverNameSurname: vehiclesList[i].driverName ? (vehiclesList[i].driverName + " " + (vehiclesList[i].driverSurname || "")) : "-",
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm__box">
      {props.title && (
        <h1
          className="text-color-hint"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      )}

      {checkCanInsert && (
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                SummonModal("vehicle-modal");
                dispatch(VehiclesActions.setVehicleMctc(undefined));
              }}
            >
              {i18next.t("navigation:_add_vehicle")}
            </Button>
          </ButtonGroup>
        </div>
      )}

      <Box className="dashboardForm__data">
        <DataGridWrap headers={columnsDef} rows={rowsDef} />
      </Box>
      <SmartModal
        hideButtons={true}
        modalUniqueId="vehicle-modal"
        title={i18next.t("generic:titleAddVehicle")}
        modalInnerComponent={
          <FormVehicleData
            doResetVehicle={() => {
              getVehicles();
            }}
            canInsertVehicles
            loggedUser={loggedUser}
            companyUser={companyUser}
            isModal={true}
            dataVehicle={stateVehicle}
          />
        }
      />

      <LoaderBackdrop loading={loadingVehiclesList || loadingCheckCanInsert || refreshForm} />
    </div>
  );
};

export default Status__VehiclesPage_Data;
