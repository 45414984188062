import { Stack, Button, Alert } from "@mui/material";
import i18next from "i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { JSONPrint, calcVatCost, currencyDecoder } from "../../Utils/Decoder";
import { validationPhone } from "../../Utils/Validation";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import ResumeIconLine, {
  IResumeIconLineProps,
} from "../ResumeIconLine/ResumeIconLine";
import StepSummaryTotalLabel from "../StepSummaryTotalLabel/StepSummaryTotalLabel";
import { IUser } from "../../Models/IUser";
import { useEffect, useState } from "react";
import { IPaymentType } from "../../Models/IPayment";

import "./StepPayment.scss";
import {
  IFleetPlan,
  IFleetPlanMostConvenient,
  ISavePlanService,
} from "../../Models/IFleetPlan";
import { IAddedService } from "../../Models/IService";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { IResumeContent } from "../StepSummary/StepSummary";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { GlobalState } from "../../Reducers/RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { ICompanyGet } from "../../Models/ICompany";
import DeleteIcon from "@mui/icons-material/Delete";
import { IBrokerCompanyGet } from "../../Models/IBroker";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { IVehicleResume } from "../../Models/IVehicles";
import { RegistrationAction } from "../../Reducers/Registration/RegistrationAction";
import { dateIsPast } from "../MultiForm/SpecialInputs/StrongDatePicker";
import AppRoutes from "../../Costants/AppRoutes";

export interface IFormPaymentProps {
  loggedUser: IUser | undefined;
  paymentInfo?: any;
  paymentType: IPaymentType[];
  subscriptionID?: number | null;
  isRegistration?: boolean;
  onPaymentEdit: (paymentInfos: any) => void;
  onPaymentCompleted?: () => void;
  updatePaymentType?: (subscriptionDurationID: number) => void;
}

const FormPayment = (props: IFormPaymentProps) => {
  const dispatch = useDispatch();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const currentBrokerCompany: IBrokerCompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.currentBrokerCompany
  );
  const subscriptionPlanService: ISavePlanService | undefined = useSelector(
    (state: GlobalState) => state.registration.planService
  );

  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );
  const getVehicleSummary: IVehicleResume[] | undefined = useSelector(
    (state: GlobalState) => state.registration.vehicleSummany
  );
  const [state, setState] = useState<any>(
    props.paymentInfo ? props.paymentInfo : null
  );
  const [servicesSelected, setServicesSelected] = useState<IAddedService[]>(
    subscriptionPlanService &&
      subscriptionPlanService?.accountID !== 0 &&
      subscriptionPlanService.selectedServiceIDList
      ? subscriptionPlanService.selectedServiceIDList
      : []
  );
  const [loadingSelectedData, setLoadingSelectedData] =
    useState<boolean>(false);
  const [loadingSubscriptions, setLoadingSubscriptions] =
    useState<boolean>(false);
  const [selectedPlanID, setSelectedPlanID] = useState<number>(
    subscriptionPlanService &&
      subscriptionPlanService?.accountID !== 0 &&
      subscriptionPlanService.fleetPlanID
      ? subscriptionPlanService.fleetPlanID
      : -1
  );
  const [fleetPlans, setFleetPlans] = useState<IFleetPlan[]>([]);
  const [loadingFleetPlans, setLoadingFleetPlans] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);

  // Switch between selected payment methods
  const handleSelected = (value: number) => {
    setSelected(value);
    setState({ ...state, paymentTypeID: value });
  };
  const [recapVoices, setRecapVoices] = useState<IResumeContent[]>([]);
  const [subscriptionDurationID, setSubscriptionDurationID] = useState<number>(
    subscriptionPlanService &&
      subscriptionPlanService?.accountID !== 0 &&
      subscriptionPlanService.subscriptionDurationID
      ? subscriptionPlanService.subscriptionDurationID
      : 0
  );
  const [discountVoucher, setDiscountVoucher] = useState<any>({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [recapTotals, setRecapTotals] = useState<any>({});
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [loadingCheckMostConvenient, setLoadingCheckMostConvenient] =
    useState<boolean>(false);
  const [mostConvenientFleetPlan, setMostConvenientFleetPlan] = useState<
    IFleetPlan[]
  >([]);

  const fromServicesAndPlansToRecapVoices = () => {
    let targetPlan: IFleetPlan | undefined = fleetPlans.find(
      (x: IFleetPlan) => x.fleetPlanID === selectedPlanID && !x.stored
    );

    let recapVoices: IResumeContent[] = [];
    if (targetPlan) {
      recapVoices.push({
        title: targetPlan.fleetPlanName,
        subtitle: targetPlan.fleetPlanDescription,
        price:
          subscriptionDurationID === 1
            ? targetPlan.fleetPlanMonthlyCost.toString()
            : targetPlan.fleetPlanYearlyCost.toString(),
        icon: "check-car",
        fleetPlanID: selectedPlanID,
      });
    }

    if (
      targetPlan &&
      getVehicleSummary.length > targetPlan?.fleetPlanMaxVehicle
    ) {
      let extraVehicles: number =
        getVehicleSummary.length - targetPlan?.fleetPlanMaxVehicle;
      recapVoices.push({
        title: i18next.t("navigation:extraVehicle"),
        subtitle: i18next
          .t("navigation:extraVehicleDescriptionCost")
          .replace("EXTRA_VEHICLES", extraVehicles.toString())
          .replace(
            "FLEET_PLAN_ADDITIONAL_CAR_COST",
            currencyDecoder(targetPlan.fleetPlanAdditionalCarCost, 2)
          ),
        price: (
          extraVehicles * targetPlan.fleetPlanAdditionalCarCost
        ).toString(),
        warning: "",
        icon: "extra-car",
      });
    }
    for (let i = 0; i < servicesSelected.length; i++) {
      recapVoices.push({
        title: servicesSelected[i].addedServiceName,
        subtitle: servicesSelected[i].addedServiceDescription,
        price: subscriptionDurationID === 1
            ? servicesSelected[i].addedServiceMontlyCost.toString()
            : servicesSelected[i].addedServiceYearlyCost.toString(),
        warning: servicesSelected[i].addedServiceNote,
        icon: "rental-car",
        isTrialFree: servicesSelected[i].isTrialFree,
        addedServiceID: servicesSelected[i].addedServiceID,
      });
    }

    setRecapVoices(recapVoices);
  };

  const loadSubscriptions = () => {
    if (props.loggedUser) {
      setLoadingSubscriptions(true);
      ApiService.SubscriptionController.SubscriptionGetData(
        props.subscriptionID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload.length > 0) {
              setSubscriptionDurationID(
                response.payload[0].subscriptionDurationID
              );
              if (props.updatePaymentType) {
                props.updatePaymentType(
                  response.payload[0].subscriptionDurationID
                );
              }
              setSelectedPlanID(response.payload[0].fleetPlanID);
              loadSelectedData(response.payload[0].subscriptionID);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSubscriptions(false);
        }
      );
    }
  };

  const loadFleetPlans = () => {
    setLoadingFleetPlans(true);
    ApiService.FleetPlanController.FleetPlanGetData(
      null,
      false,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setFleetPlans(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFleetPlans(false);
      }
    );
  };

  const loadSelectedData = (subscriptionID: number) => {
    setLoadingSelectedData(true);
    ApiService.ServiceController.ServiceGetSelectedData(
      subscriptionID,
      "",
      (response: IAPIResponse) => {
        if (response.error === null) {
          setServicesSelected(
            response.payload.filter((x: IAddedService) => !x.stored)
          );
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingSelectedData(false);
      }
    );
  };

  const loadVehiclesResume = () => {
    if (loggedUserCompany) {
      setLoadingSummary(true);
      ApiService.VehicleDataController.VehicleDataResumeGetByAccount(
        loggedUserCompany?.companyID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              RegistrationAction.setVehicleSummaryData(response.payload)
            );
          } else {
            ToastMessage(
              i18next.t("error:could_not_load_vehicle_resume"),
              "error"
            );
          }
          setLoadingSummary(false);
        }
      );
    }
  };

  const checkMostConvenientFleetPlan = (data: IFleetPlanMostConvenient) => {
    setLoadingCheckMostConvenient(true);
    ApiService.FleetPlanController.checkMostConvenientFleetPlan(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setMostConvenientFleetPlan([response.payload]);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingCheckMostConvenient(false);
      }
    );
  };

  // se isTrial = true e enabled = false > calcolare da pagare servizi e piani tariffari = isTrial = false
  // dopo pagamento a BE isTrial = false e enabled = true
  /*

  !enabled && paymentrequired && istrial = registrazione

  !enabled && !paymentrequired = (attesa bonifico)

  !enabled && paymentrequired && !istrial = abbonamento scaduto.
  
  */
  const getRecapTotals = () => {
    let total = 0;
    let totalLater = 0;

    // registrazione
    if (
      !getCurrentSubscription.enabled &&
      getCurrentSubscription.paymentRequired &&
      getCurrentSubscription.isTrial
    ) {
      recapVoices
        .filter((x: IResumeContent) => !getCurrentSubscription.isTrial)
        .forEach((x: IResumeContent) => {
          total += +x.price;
        });
      recapVoices
        .filter((x: IResumeContent) => getCurrentSubscription.isTrial)
        .forEach((x: IResumeContent) => {
          totalLater += +x.price;
        });
    }
    // abbonamento scaduto.
    if (
      !getCurrentSubscription.enabled &&
      getCurrentSubscription.paymentRequired &&
      !getCurrentSubscription.isTrial
    ) {
      recapVoices
        .filter((x: IResumeContent) => getCurrentSubscription.isTrial)
        .forEach((x: IResumeContent) => {
          total += +x.price;
        });
      recapVoices
        .filter((x: IResumeContent) => !getCurrentSubscription.isTrial)
        .forEach((x: IResumeContent) => {
          totalLater += +x.price;
        });
    }

    setRecapTotals({ total, totalLater });
  };

  const refreshForm = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 50);
  };

  useEffect(() => {
    if (subscriptionPlanService.accountID === 0) {
      loadSubscriptions();
    } else {
      if (props.updatePaymentType) {
        props.updatePaymentType(subscriptionPlanService.subscriptionDurationID);
      }
    }
    loadFleetPlans();
    if (getVehicleSummary.length === 0) {
      loadVehiclesResume();
    }
  }, []);

  useEffect(() => {
    if (fleetPlans.length > 0) {
      fromServicesAndPlansToRecapVoices();
      setState({ ...state });
    }
  }, [fleetPlans, selectedPlanID, servicesSelected]);

  useEffect(() => {
    if (recapTotals) {
      setState({
        ...state,
        amount: calcVatCost(recapTotals.total, "total", true),
      });
    }
  }, [recapTotals]);

  useEffect(() => {
    getRecapTotals();
  }, [recapVoices]);

  useEffect(() => {
    if (
      selectedPlanID > 0 &&
      subscriptionDurationID > 0 &&
      getVehicleSummary.length > 0
    ) {
      checkMostConvenientFleetPlan({
        fleetPlanID: selectedPlanID,
        subscriptionDurationID: subscriptionDurationID,
        numberOfVehicles: getVehicleSummary.length ?? 0,
      });
    }
  }, [selectedPlanID, subscriptionDurationID, getVehicleSummary]);

  const getInputs = () => {
    let inputs: IMultiFormField[] = [
      {
        width: 50,
        type: "text",
        name: "billingName",
        defaultValue:
          currentBrokerCompany?.companyReferentName ??
          loggedUserCompany?.companyReferentName,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:name")}`,
      },
      {
        width: 50,
        type: "text",
        name: "billingSurname",
        defaultValue:
          currentBrokerCompany?.companyReferentSurname ??
          loggedUserCompany?.companyReferentSurname,
        required: true,
        inputProps: {
          maxLength: 50,
        },
        label: `${i18next.t("form:surname")}`,
      },
      {
        width: 100,
        type: "email",
        name: "billingEmail",
        defaultValue:
          currentBrokerCompany?.companyReferentEmail ??
          loggedUser?.accountEmail,
        required: true,
        label: `${i18next.t("form:email")}`,
      },
      {
        width: 100,
        type: "tel",
        name: "billingPhone",
        defaultValue:
          currentBrokerCompany?.companyReferentPhone ??
          loggedUserCompany?.companyReferentPhone,
        required: true,
        label: `${i18next.t("form:telephonePersonal")}`,
        errorText:
          state && (validationPhone(state.billingPhone) || !state.billingPhone)
            ? ""
            : `${i18next.t("form:validationPhone")}`,
      },
    ];

    inputs.push({
      type: "custom",
      name: "paymentStack",
      width: 100,
      defaultValue: selected,
      element: (
        <div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            marginTop={2}
          >
            {props.paymentType.map((item: IPaymentType) => {
              return (
                <div
                  className={
                    "StepPayment-image-box StepPayment-image-box-hover " +
                    (selected === item.paymentTypeID &&
                      "StepPayment-image-box-selected")
                  }
                  onClick={() => handleSelected(item.paymentTypeID)}
                >
                  <img
                    className="StepPayment-image-paypal"
                    src={"/Images/paymentType_" + item.paymentTypeID + ".png"}
                    alt={item.paymentTypeName}
                  />
                  {item.paymentTypeDescription && (
                    <p className="StepPayment-bankTransfer-title">
                      {i18next.t("form:wireTransfer")}
                      <span className="StepPayment-bankTransfer-subtitle">
                        {item.paymentTypeDescription}
                      </span>
                    </p>
                  )}
                  {selected === item.paymentTypeID && (
                    <CheckCircleOutlineIcon className="StepPayment-icon" />
                  )}
                </div>
              );
            })}
          </Stack>
        </div>
      ),
    });
    return inputs;
  };

  return (
    <div className="StepPayment-container">
      <div className="StepPayment-form">
        <h3>{i18next.t("navigation:personaDataPayment")}</h3>
        <MultiForm
          suppressSubmit
          suppressLayout
          formTitle={i18next.t("navigation:personaDataPayment")}
          formId={"payment-page-form"}
          inputs={getInputs()}
          onSubmit={async (data: any) => {
            let data_ = { ...data };
            data_["purchaseList"] = [];

            let recapVoicesToPay: IResumeContent[] = [...recapVoices];
            for (let i = 0; i < recapVoicesToPay.length; i++) {
              let shouldPay = props.isRegistration
                ? !recapVoicesToPay[i].isTrialFree
                : recapVoicesToPay[i].isTrialFree;
              let price = +recapVoicesToPay[i].price ?? 0;
              data_["purchaseList"].push({
                fleetPlanID: recapVoicesToPay[i].fleetPlanID ?? null,
                addedServiceID: recapVoicesToPay[i].addedServiceID ?? null,
                purchaseCost: shouldPay ? price : 0,
              });
            }

            data_["subscriptionDurationID"] = subscriptionDurationID;
            props.onPaymentEdit({ ...state, ...data_ });
          }}
          onChange={(data: any) => {
            setState({ ...state, ...data });
          }}
        />
      </div>

      <div className="StepPayment-Summary add-border">
        <h3>{i18next.t("navigation:SummaryPayment")}</h3>

        <div className="StepPayment-Summary-container">
          {recapVoices
            .filter((x: any) => x.title)
            .map((x: IResumeIconLineProps, i: number) => {
              return (
                <ResumeIconLine
                  key={i}
                  title={x.title}
                  subtitle={x.subtitle}
                  warning={x.warning}
                  icon={x.icon}
                  price={x.price}
                />
              );
            })}
        </div>

        {!refresh && (
          <MultiForm
            suppressLayout
            formTitle={i18next.t("form:titlePaymentVoucher")}
            formId="paymentVoucherForm"
            inputs={[
              {
                width: 100,
                type: "text",
                name: "StepPayment_voucher",
                defaultValue: state?.StepPayment_voucher ?? null,
                required: false,
                inputProps: { maxLength: 100 },
                disabled: discountVoucher?.discount,
                label: `${i18next.t("navigation:voucherPayment")}`,
              },
            ]}
            onChange={(data: any) => {
              setState({ ...state, ...data });
            }}
            suppressSubmit={true}
            onSubmit={async (data: any) => {
              setState({ ...state, ...data });
            }}
          />
        )}
        {mostConvenientFleetPlan
          .filter((x: IFleetPlan) => x.fleetPlanID !== selectedPlanID)
          .map((fleetPlan: IFleetPlan, i: number) => {
            return (
              <Alert severity="warning" key={i}>
                <p
                  style={{ margin: "0px" }}
                  dangerouslySetInnerHTML={{
                    __html: i18next
                      .t("generic:fleetPlanVehicleRangeMostConvenient")
                      .replace(
                        "VEHICLE_FROM",
                        fleetPlan.fleetPlanMinVehicle.toString()
                      )
                      .replace(
                        "VEHICLE_TO",
                        fleetPlan.fleetPlanMaxVehicle.toString()
                      )
                      .replace(
                        "PRICE",
                        currencyDecoder(
                          subscriptionDurationID === 1
                            ? fleetPlan.fleetPlanMonthlyCost
                            : fleetPlan.fleetPlanYearlyCost,
                          2
                        )
                      ),
                  }}
                />
              </Alert>
            );
          })}

        <StepSummaryTotalLabel
          title={i18next.t("message:resumePageSummaryTotal")}
          price={calcVatCost(recapTotals.total, "total", false)}
          iva={calcVatCost(recapTotals.total, "extract", false)}
          discountIva={
            discountVoucher?.discount
              ? calcVatCost(
                  discountVoucher?.discount &&
                    (
                      recapTotals.total -
                      discountVoucher?.discount * recapTotals.total
                    ).toFixed(2),
                  "extract",
                  false
                )
              : undefined
          }
          discountPrice={
            discountVoucher?.discount &&
            calcVatCost(
              recapTotals.total - discountVoucher?.discount * recapTotals.total,
              "total",
              false
            )
          }
        />
        <StepSummaryTotalLabel
          title={
            !getCurrentSubscription.enabled &&
            dateIsPast(getCurrentSubscription.validTo)
              ? i18next.t("message:resumePageAlreadyPaid")
              : i18next.t("message:resumePageSummaryTotalNext")
          }
          price={calcVatCost(recapTotals.totalLater, "total", false)}
          iva={calcVatCost(recapTotals.totalLater, "extract", false)}
        />
      </div>

      <LoaderBackdrop
        loading={
          loadingSubscriptions ||
          loadingFleetPlans ||
          loadingSelectedData ||
          refresh ||
          loadingSummary ||
          loadingCheckMostConvenient
        }
      />
    </div>
  );
};

export default FormPayment;
