import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import "./PaymentReadyPage.scss";
import {
  ICreatePaypalPLanPayload,
  InsertPaymentPayload,
  IPaymentInsert,
} from "../../Models/IPayment";
import { IAPIRequest, IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { CircularProgress } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { IUser } from "../../Models/IUser";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Link } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { dateIsPast } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { userRoleFits } from "../../Utils/Session";
import { ISavePlanService } from "../../Models/IFleetPlan";
import i18next from "i18next";
import SubscriptionController from "../../Services/Controllers/SubscriptionController";
import PayPalSubscription from "../../Components/PayPalSubscription/PayPalSubscription";
import { IInsertTrialSubscription } from "../../Models/ISubscriptions";
import Suppliers__PurchasesPage_single from "../DashboardPage/DashBoard_Pages/ServicesPage/Suppliers__PurchasesPage_single/Suppliers__PurchasesPage_single";

export interface IPaymentReadyPageProps {}

export const writePaymentData = (data: IPaymentInsert) => {
  if (data) {
    localStorage.setItem("payment-data", JSON.stringify(data));
  }
};

export const getPaymentData = () => {
  let dt = localStorage.getItem("payment-data");
  if (dt) {
    return JSON.parse(dt);
  } else {
    return null;
  }
};

const PaymentReadyPage = (props: IPaymentReadyPageProps) => {
  const subscriptionPlanService: ISavePlanService | undefined = useSelector(
    (state: GlobalState) => state.registration.planService
  );

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [paymentData, setPaymentData] = useState<any | null>(getPaymentData());
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSent, setPaymentSent] = useState<boolean>(false);
  const [paymentErrored, setPaymentErrored] = useState<boolean>(false);
  const [paypalMode, setPaypalMode] = useState<boolean>(false);
  const [paypalPayment, setPaypalPayment] = useState<boolean>(false);
  const [navigationLink, setNavigationLink] = useState<string>("");
  const [planId, setPlanId] = useState<string>("");
  const [subscriptionID, setSubscriptionID] = useState<number>(0);

  const applyPayment = async (data: any, stepIndex: number | undefined) => {
    setLoading(true);
    delete data.paymentStack;
    delete data.StepPayment_voucher;
    delete data.save;
    delete data.stepIndex;

    const canSendAccountID =
      (userRoleFits(["FleetManager"]) && data["payingForDriver"]) ||
      userRoleFits(["Broker"]);

    if (!canSendAccountID) {
      data["accountID"] = null;
    }
    // prevedi la insert plan se passata da props
    let data_: InsertPaymentPayload = {
      accountID: data.accountID ?? null,
      paymentTypeID: data.paymentTypeID,
      paymentTransactionCode: data.paymentTransactionCode,
      amount: data.amount,
      newSubscriptionDurationID: data.newSubscriptionDurationID,
      purchaseList: data.purchaseList,
      numExtraVehicles: data.numExtraVehicles,
      billingEmail: data.billingEmail,
      billingPhone: data.billingPhone,
      billingName: data.billingName,
      billingSurname: data.billingSurname,
      payingForDriver: false,
      voucherName: data.data.voucherName,
    };
    if (data.isDecreasePlan) {
      data_.vehiclesToKeep = data.vehiclesToKeep;
      ApiService.PaymentController.DecreasePlan(
        data_,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentSent(true);
          } else {
            ToastMessage(
              i18next.t("error:" + response.payload.detail),
              "error"
            );
            setPaymentErrored(true);
          }
          setLoading(false);
        }
      );
    } else if (data.insertPlanMethod) {
      ApiService.PaymentController.InsertPlan(
        data_,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentSent(true);
          } else {
            ToastMessage(
              i18next.t("error:" + response.payload.detail),
              "error"
            );
            setPaymentErrored(true);
          }
          setLoading(false);
        }
      );
    } else {
      ApiService.PaymentController.IncresePlan(
        data_,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentSent(true);
          } else {
            ToastMessage(
              i18next.t("error:" + response.payload.detail),
              "error"
            );
            setPaymentErrored(true);
          }
          setLoading(false);
        }
      );
    }
  };

  const insertSubscriptionFromPaypalPlan = (
    subscriptionId: string,
    orderId: string
  ) => {
    setLoading(true);
    if (paymentData.data.subscriptionID === null) {
      ApiService.PaymentController.CreateSubscription(
        paymentData,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let subscriptionFleetID = response.payload.subscriptionID;
            let data_: any = {
              subscriptionID: subscriptionFleetID,
              paypalSubscriptionID: subscriptionId,
            };
            if (userRoleFits(["Broker", "FleetSupportAdmin"])) {
              data_.accountID = paymentData?.accountID;
            }
            ApiService.PaymentController.PaypalInsertSubscriptionFromPaypal(
              data_,
              (response: IAPIResponse) => {
                if (response.error === null) {
                  let data = { ...paymentData };
                  data.paymentTransactionCode = orderId;
                  applyPayment(data, paymentData?.stepIndex);
                } else {
                  setLoading(false);
                  setPaymentErrored(true);
                }
              }
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    } else {
      ApiService.PaymentController.PaypalInsertSubscriptionFromPaypal(
        {
          subscriptionID: paymentData.data.subscriptionID,
          paypalSubscriptionID: subscriptionId,
        },
        (response: IAPIResponse) => {
          if (response.error === null) {
            let data = { ...paymentData };
            data.paymentTransactionCode = orderId;
            applyPayment(data, paymentData?.stepIndex);
          } else {
            setLoading(false);
            setPaymentErrored(true);
          }
        }
      );
    }
  };

  const createPaypalPlan = (setupData: any) => {
    ApiService.PaymentController.CreatePaypalPlan(
      {
        newSubscriptionDurationID: setupData.data.subscriptionDurationID,
        purchaseList: setupData.purchaseList,
        voucherName: setupData.data.voucherName,
        numExtraVehicles: setupData?.numExtraVehicles ?? 0,
        accountID: setupData.accountID,
      },
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPlanId(response.payload.id);
          setPaypalMode(true);
        } else {
          ToastMessage(response.error ?? "", "error");
        }
      }
    );
  };

  const setupPaymentData = () => {
    if (!paymentData.data) {
      return null;
    }

    let purchaseList: any = [];

    if (paymentData.data.selectedServiceIDList) {
      purchaseList = [
        {
          fleetPlanID: paymentData.data.fleetPlanID,
          addedServiceID: undefined,
          purchaseCost: 0,
        },
      ];

      paymentData.data.selectedServiceIDList.forEach(
        (element: { selectedServiceID: number }) => {
          purchaseList.push({
            fleetPlanID: undefined,
            addedServiceID: element.selectedServiceID,
            purchaseCost: 0,
          });
        }
      );
    } else {
      purchaseList = paymentData.data.purchaseList_;
    }

    const data: ICreatePaypalPLanPayload = {
      newSubscriptionDurationID: paymentData.data.subscriptionDurationID,
      purchaseList: purchaseList,
      accountID: paymentData.accountID,
    };
    let resultingData = {
      ...paymentData,
      ...data,
    };

    resultingData.amount = resultingData.price;

    setPaymentData(resultingData);
    return resultingData;
  };

  const sendTrialPayment = (setupData: any) => {
    const canSendAccountID =
      (userRoleFits(["FleetManager"]) && setupData["payingForDriver"]) ||
      userRoleFits(["Broker"]);
    const accountID = setupData["accountID"] ?? null;

    setLoading(true);
    let body: IInsertTrialSubscription = {
      subscriptionDurationID: setupData.data.subscriptionDurationID,
      payingForDriver: canSendAccountID,
      accountID: accountID,
      fleetPlanID: setupData.data.fleetPlanID,
    };
    SubscriptionController.SubscriptionInsertTrialSubscription(
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentErrored(false);
          setPaymentSent(true);
        } else {
          setPaymentErrored(true);
        }

        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (paymentData) {
      let setupData = setupPaymentData();

      if (setupData) {
        if (setupData.data.isTrial) {
          sendTrialPayment(setupData);
        } else {
          let requestFreeTrial = setupData.requestFreeTrial;
          if (requestFreeTrial) {
            setupData["paymentTransactionCode"] = "request-free-trial";
            applyPayment(setupData, setupData?.stepIndex);
          } else {
            if (setupData?.paymentEvaluation.cost === 0) {
              setupData["paymentTransactionCode"] = "price-is-zero";
              applyPayment(setupData, setupData?.stepIndex);
            } else {
              // paypal
              if (setupData.paymentTypeID === 1) {
                createPaypalPlan(setupData);
              } else {
                applyPayment(setupData, setupData?.stepIndex);
              }
            }
          }
        }
      } else {
        setPaymentErrored(true);
      }
    } else {
      setPaymentErrored(true);
    }
  }, []);

  useEffect(() => {
    let navigationLink: string = "";
    if (userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) {
      navigationLink = AppRoutes.DASHBOARD___MESSAGING_ROUTE;
    } else {
      navigationLink = AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE;
    }
    setNavigationLink(navigationLink);
  }, [paymentSent]);

  return (
    <div className="payment-page-wrapper">
      {paypalMode && paymentData && (
        <div className="payment-page-spinner-loader">
          <div className="payment-page-spinner-loader-label">
            {i18next.t("navigation:payment_paypal")}
          </div>
          <PayPalSubscription
            planId={planId}
            onPaymentCancelled={() => {}}
            onPaymentDone={(data: any) => {
              // recupera il transaction code da paypal
              setPaypalPayment(true);
              setPaypalMode(false);
              insertSubscriptionFromPaypalPlan(
                data.data.subscriptionID,
                data.data.orderID
              );
            }}
          />
        </div>
      )}
      {!paypalMode && (
        <div>
          {loading && (
            <div className="payment-page-spinner-loader">
              <div className="payment-page-spinner-loader-label">
                {i18next.t("navigation:payment_processing")}
              </div>
              <CircularProgress />
            </div>
          )}
          {!loading && paymentSent && (
            <div className="payment-page-spinner-loader">
              <div className="payment-page-spinner-loader-label">
                {!paymentData?.requestFreeTrial &&
                  i18next.t("navigation:payment_processing_done")}
                {paymentData?.requestFreeTrial &&
                  i18next.t("navigation:payment_processing_done_free_trial")}
                <div className="payment-page-spinner-loader-sub-label">
                  {!paymentData?.requestFreeTrial &&
                    !paypalPayment &&
                    i18next.t("navigation:payment_bonifico_pending")}
                  {!paymentData?.requestFreeTrial &&
                    paypalPayment &&
                    i18next.t("navigation:payment_paypal_done")}
                </div>
                <DoneOutlineIcon />
                {paymentData?.redirectToRegistrationStepper ? (
                  <Link
                    to={AppRoutes.CONTINUE_REGISTRATION_ROUTE}
                    style={{ marginTop: "0.5em", fontSize: "0.5em" }}
                  >
                    {i18next.t(
                      "navigation:payment_done_go_to_registration_stepper"
                    )}
                  </Link>
                ) : (
                  <Link
                    to={AppRoutes.INTERNAL_DASHBOARD + navigationLink}
                    style={{ marginTop: "0.5em", fontSize: "0.5em" }}
                  >
                    {i18next.t("navigation:payment_done_go_to_dashboard")}
                  </Link>
                )}

                {!paymentData?.data.isTrial && (
                  <> 
                    <hr />
                    {i18next.t("navigation:or_continue_buyng_stuff")}
                    <div style={{ fontSize: "0.8em" }}>
                      <Link to={AppRoutes.EXTRA_SHOP}>
                        {i18next.t("navigation:or_continue_buyng_stuff_link")}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {!loading && paymentErrored && (
            <div className="payment-page-spinner-loader">
              <div className="payment-page-spinner-loader-label red">
                {i18next.t("navigation:payment_processing_error")}
                <ReportGmailerrorredIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentReadyPage;
