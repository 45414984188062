/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "./VehicleRequestsPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* SERVICE */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_CarConfigurator_Vehicle,
  IUPDATE_CarConfigurator_Vehicle_Request_Reject,
} from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import { renderBrandImageFunc } from "../VehicleOffersPage/VehicleOffersPage";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Alert, Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
// Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/* COMPONENT */
const VehicleRequestsPageSummary = () => {
  /* STATES */
  // Vehicle's data
  const [list, setList] = useState<IGET_CarConfigurator_Vehicle[]>([]);
  // Button choose list type
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingUpdateReject, setLoadingUpdateReject] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* URL PARAMS */
  const [params] = useSearchParams();
  const vehicleID: string | null = params.get("vehicle");

  /* FUNCTIONS */
  // ADD BUTTON
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* API */
  // Reject request
  const UPDATE_CarConfigurator_Vehicle_Request_Reject = (
    data: IUPDATE_CarConfigurator_Vehicle_Request_Reject
  ) => {
    setLoadingUpdateReject(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfigurator_Vehicle_Request_Reject(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");

          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS
          );
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateReject(false);
      }
    );
  };

  // Get vehicle info
  const GET__CARCONFIGURATOR_VEHICLES = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET__CARCONFIGURATOR_VEHICLES(
      vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    if (vehicleID) {
      GET__CARCONFIGURATOR_VEHICLES();
    }
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("navigation:_configure_vechicles_request")}
        subtitle={i18next.t("navigation:_configure_vechicles_request_summary")}
        subtitlePath={AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS}
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS
          )
        }
      />

      <div className="dashboardForm__box">
        {vehicleID && list && Object.keys(list).length > 0 ? (
          <Box className="dashboardForm__data display-flex gap-large padding-small">
            <div className="VehicleRequestsPage-box">
              <div className="display-flex-horizontal-space-between">
                <div>
                  <div className="VehicleRequestsPage-brand">
                    {renderBrandImageFunc(list[0].vehicleBrandName)}

                    <Typography variant="h4">{list[0].vehicleBrandName}</Typography>
                  </div>

                  <Typography variant="overline">{list[0].vehicleFittingName}</Typography>
                </div>

                <Typography variant="overline">
                  <strong>{i18next.t("form:applicant")}:</strong>{" "}
                  {list[0].vehicleApplicant}
                </Typography>
              </div>

              <Divider className="margin-vertical-small" />

              <div className="VehicleConfigurationPage__Summary-list">
                {[
                  "vehicleAntipollutionLegislation",
                  "vehicleCarBody",
                  "vehicleDoorsNumber",
                  "vehicleSeatsNumber",
                  "vehicleLength",
                  "vehicleWidth",
                  "vehicleHeight",
                  "vehicleTires",
                ].map((x: any, i: number) => (
                  <Typography variant="overline" className="margin-vertical-small">
                    <strong>{i18next.t(`form:vehiclesCompareLabel_${i + 3}`)}</strong>
                    {": "}
                    {/* list[x] */}
                  </Typography>
                ))}
              </div>

              <Divider className="margin-vertical-medium" />

              <div className="display-flex-end margin-vertical-medium gap-small">
                <Button
                  variant="outlined"
                  onClick={() => {
                    SummonModal("reject-request-modal");
                  }}
                >
                  {i18next.t("navigation:refuse")}
                </Button>

                <Button
                  variant="contained"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {i18next.t("navigation:approve")}
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(
                        AppRoutes.INTERNAL_DASHBOARD +
                          AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY_SEARCH +
                          "?vehicle=" +
                          vehicleID
                      );
                    }}
                  >
                    {i18next.t("form:searchPromptDeliver")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      navigate(
                        AppRoutes.INTERNAL_DASHBOARD +
                          AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_ORDERS +
                          "?vehicle=" +
                          vehicleID
                      );
                    }}
                  >
                    {i18next.t("form:serachOtherContract")}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Box>
        ) : (
          <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
        )}
      </div>

      <SmartModal
        modalUniqueId="reject-request-modal"
        title={i18next.t("navigation:refuseRequest")}
        modalInnerComponent={
          <div className="modal-width-small">
            <p>{i18next.t("navigation:refuseRequestText")}</p>
          </div>
        }
        onAccept={() => {
          if (vehicleID) {
            UPDATE_CarConfigurator_Vehicle_Request_Reject({
              driverGroupVehiclesRequestID: +vehicleID,
            });
          }
        }}
        onReject={() => {
          DismissModal("reject-request-modal");
        }}
      />

      <LoaderBackdrop loading={loadingGet || loadingUpdateReject} />
    </div>
  );
};

export default VehicleRequestsPageSummary;
