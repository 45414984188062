import "./StepSummaryTotalLabel.scss";

export interface IStepSummaryTotalLabelProps {
  iva: string | number;
  title: string;
  price: string | number;
  discountPrice?: string | number;
  discountIva?: string | number | undefined;
}

const StepSummaryTotalLabel = (props: IStepSummaryTotalLabelProps) => {
  return (
    <div className="summary-step-label-total-item">
      <div className="summary-step-label-total-item-row">
        <div className="summary-step-label-total-item-title">{props.title}</div>
        <div className="summary-step-label-total-item-price">
          {props.discountPrice && (
            <>
              <span
                style={{ textDecoration: "line-through", color: "rgba(0, 0, 0, 0.38)" }}
              >
                {props.price}
              </span>
              <br />
            </>
          )}
          {props.discountPrice ? props.discountPrice : props.price}
        </div>
      </div>
      <div className="summary-step-label-total-item-iva">
        {props.discountIva && (
          <>
            <span
              style={{ textDecoration: "line-through", color: "rgba(0, 0, 0, 0.38)" }}
            >
              IVA {props.iva}
            </span>
            <br />
          </>
        )}
        IVA {props.discountIva ? props.discountIva : props.iva}
      </div>
      
    </div>
  );
};

export default StepSummaryTotalLabel;
