import {
  GenericActionType,
  SET_CURRENT_SUBSCRIPTION,
  SET_LANGUAGE,
  SET_LAST_STEP,
  SET_OPTIONS,
  SET_SUBSCRIPTION_ENABLED,
  SET_TEMPLATE_GENERIC_W_DATA,
  SET_TEMPLATE_RELOADS_W_DATA,
  SET_DEBIT_COST,
  SET_ADDONS,
} from "./GenericAction";
import { GenericState } from "./GenericState";

const getLastStep = () => {
  let u: any = localStorage.getItem("current-last-step");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const getCurrentSubscription = () => {
  let u: any = localStorage.getItem("current-subscription");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return {
      subscriptionID: 0,
      accountID: 0,
      fleetPlanID: 0,
      fleetPlanName: "",
      fleetPlanCustomMaxVehicle: 0,
      validFrom: "",
      validTo: "",
      subscriptionDurationID: 0,
      enabled: false,
      isTrial: false,
      trialEndDT: "",
    };
  }
};

const DEFAULT_STATE: GenericState = {
  language: "ita",
  options: [],
  addons: [],
  currentLastStep: getLastStep(),
  subscriptionEnabledResponse: false,
  currentSubscription: getCurrentSubscription(),
  templateGenericWorkingData: null,
  templateReloadsWorkingData: null,
  debitCost: 0,
};

export const genericReducer = (
  state: GenericState = DEFAULT_STATE,
  action: GenericActionType
): GenericState => {
  switch (action.type) {
    case SET_TEMPLATE_RELOADS_W_DATA:
      return {
        ...state,
        templateReloadsWorkingData: action.payload,
      };
    case SET_TEMPLATE_GENERIC_W_DATA:
      return {
        ...state,
        templateGenericWorkingData: action.payload,
      };
    case SET_ADDONS:
      return {
        ...state,
        addons: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case SET_LAST_STEP:
      if (action.payload !== undefined) {
        localStorage.setItem(
          "current-last-step",
          JSON.stringify(action.payload)
        );
      } else {
        localStorage.removeItem("current-last-step");
      }
      return {
        ...state,
        currentLastStep: action.payload,
      };
    case SET_SUBSCRIPTION_ENABLED:
      return {
        ...state,
        subscriptionEnabledResponse: action.payload,
      };
    case SET_CURRENT_SUBSCRIPTION:
      if (action.payload !== undefined) {
        localStorage.setItem(
          "current-subscription",
          JSON.stringify(action.payload)
        );
      } else {
        localStorage.removeItem("current-subscription");
      }
      return {
        ...state,
        currentSubscription: action.payload,
      };
    case SET_DEBIT_COST:
      return {
        ...state,
        debitCost: action.payload,
      };
    default:
      return state;
  }
};
