import { Code, Email, Password, Phone } from "@mui/icons-material";
import { IUser } from "../../../Models/IUser";

import { IVehicleDataGet } from "../../../Models/IVehicles";
import FormServices from "./FormServices";
import "./TabServices.scss";

export interface ITabServicesProps {
  currentTab: string;
  dataVehicle: IVehicleDataGet | null;
  loggedUser: IUser | undefined;
  doRefreshForm?: () => void;
}

const TabServices = (props: ITabServicesProps) => {
  return (
    <div
      role="tabpanel"
      hidden={props.currentTab !== "secondary-tab-services"}
      id="secondary-tab-services"
      aria-labelledby="secondary-tab-services"
    >
      {props.currentTab === "secondary-tab-services" && (
        <div>
          <FormServices
            loggedUser={props.loggedUser}
            dataVehicle={props.dataVehicle ?? undefined}
            disabled={!props.dataVehicle?.vehicleID}
            doRefreshForm={props.doRefreshForm}
          />
        </div>
      )}
    </div>
  );
};

export default TabServices;
