import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, IconButton } from "@mui/material";
import { IGET_FAQ_BY_CATEGORY } from "../../Models/IFAQ";
import { userRoleFits } from "../../Utils/Session";
import { SummonModal } from "../SmartModal/SmartModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "./AccordionFaq.scss";

export interface IAccordionFaqProps {
  infoFaq: IGET_FAQ_BY_CATEGORY;
  setCurrentSelection?: (data: any) => void;
  setFormData?: (data: any) => void;
  isPublic?: boolean;
}

const AccordionFaq = (props: IAccordionFaqProps) => {
  return (
    <Accordion elevation={3} className="AdminPage__FAQ__Accordion--Item">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={props.isPublic ? 'ff-accordion-title' : ''}
      >
        <span>{props.infoFaq.faq}</span>
      </AccordionSummary>

      <AccordionDetails>
        <div
          className={props.isPublic ? 'AdminPage__FAQ__Accordion--Text ff-accordion-description' : 'AdminPage__FAQ__Accordion--Text text-grey'}
          dangerouslySetInnerHTML={{ __html: props.infoFaq.faqResponse }}
        />
      </AccordionDetails>

      {userRoleFits(["FleetSupportAdmin"]) && (
        <AccordionActions>
          <IconButton
            onClick={() => {
              if(props.setCurrentSelection){
                props.setCurrentSelection({ faqID: props.infoFaq.faqID });
                SummonModal("delete-faq-modal");
              }
            }}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              if(props.setFormData){
                props.setFormData(props.infoFaq);
                SummonModal("add-faq-modal");
              }
            }}
          >
            <BorderColorIcon />
          </IconButton>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default AccordionFaq;