import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const ContractController = {
  // Get the contract type (proprietà, finanziamento, etc.)
  ContractGetContractType: async (
    inOrder: boolean,
    contractTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.ORDER_GET_CONTRACT_TYPE}?inOrder=${inOrder}`;
    if (contractTypeID) {
      request.url += `&contractTypeID=${contractTypeID}`;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Get the property list
  ContractGetPropertyList: async (
    inOrder: boolean,
    inContract: boolean,
    inRegistration: boolean,
    contractTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_GET_PROPERTY_LIST;
    let parameters: string[] = [];
    if (inOrder) {
      parameters.push(`inOrder=${inOrder}`);
    }
    if (inContract) {
      parameters.push(`inContract=${inContract}`);
    }
    if (inRegistration) {
      parameters.push(`inRegistration=${inRegistration}`);
    }
    if (contractTypeID) {
      parameters.push(`contractTypeID=${contractTypeID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default ContractController;
