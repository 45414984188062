/* REACT */
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./HeaderPageDashboard.scss";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* MUI */
import { Breadcrumbs, IconButton, Link, Typography } from "@mui/material";
// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* INTERFACE */
export interface IHeaderPageDashboardProps {
  title: string;
  subtitle?: string | undefined | null;
  subtitlePath?: string;
  arrowBack?: boolean;
  onBack?: () => void;
}

/* COMPONENT */
const HeaderPageDashboard = (props: IHeaderPageDashboardProps) => {
  /* NAVIGATE */
  const navigate = useNavigate();

  /* BREADCRUMS PATH */
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Facile Flotta
    </Link>,
    ,
  ];

  if (props.subtitle) {
    if(props.subtitlePath){
      breadcrumbs.push(
        <Link
          underline="hover"
          key="2"
          color="inherit"
          className="cursor-pointer"
          onClick={() => navigate(AppRoutes.INTERNAL_DASHBOARD + props.subtitlePath)}
        >
          {props.title}
        </Link>,
        <Typography
          key="3"
          color="text.primary"
          dangerouslySetInnerHTML={{
            __html: props.subtitle,
          }}
        />
      );
    }else{
      breadcrumbs.push(
        <Typography
          key="3"
          color="text.primary"
          dangerouslySetInnerHTML={{
            __html: props.subtitle,
          }}
        />
      );
    }
  } else {
    breadcrumbs.push(
      <Typography
        key="2"
        color="text.primary"
        dangerouslySetInnerHTML={{
          __html: props.title,
        }}
      />
    );
  }

  /* RETURN */
  return (
    <div className="header">
      {!props.arrowBack && (
        <strong
          id="page-header"
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></strong>
      )}

      {props.arrowBack && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "1em" }}>
            <IconButton
              onClick={() => {
                props.onBack!();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <strong dangerouslySetInnerHTML={{ __html: props.title }}></strong>
        </div>
      )}

      <div>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default HeaderPageDashboard;
