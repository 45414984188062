import { IStepperTickets } from "../../Models/IStepperTickets";
import { ITicketInsertDetailResponse } from "../../Models/ITicketInsertDetailResponse";
import { ITicketInsertPaymentResponse } from "../../Models/ITicketInsertPaymentResponse";
import { ITicketInsertResponse } from "../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../Models/ITicketStatus";

export const SET_STEPPER_TICKETS = "SET_STEPPER_TICKETS";
export const SET_TICKET_INSERT_RESPONSE = "SET_TICKET_INSERT_RESPONSE";
export const SET_TICKET_INSERT_DETAIL_RESPONSE =
  "SET_TICKET_INSERT_DETAIL_RESPONSE";
export const SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE =
  "SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE";
export const SET_TICKET_INSERT_PAYMENT_RESPONSE =
  "SET_TICKET_INSERT_PAYMENT_RESPONSE";
export const SET_TICKET_DRIVER = "SET_TICKET_DRIVER";
export const SET_TICKET_STATUS = "SET_TICKET_STATUS";

interface SetStepperTicketsActionType {
  type: typeof SET_STEPPER_TICKETS;
  payload: IStepperTickets | undefined;
}

interface SetTicketInsertResponseActionType {
  type: typeof SET_TICKET_INSERT_RESPONSE;
  payload: ITicketInsertResponse[] | undefined;
}

interface SetTicketInsertDetailResponseActionType {
  type: typeof SET_TICKET_INSERT_DETAIL_RESPONSE;
  payload: ITicketInsertDetailResponse[] | undefined;
}

interface SetTicketInsertMultipleDetailResponseActionType {
  type: typeof SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE;
  payload: any[] | undefined;
}

interface SetTicketInsertPaymentResponseActionType {
  type: typeof SET_TICKET_INSERT_PAYMENT_RESPONSE;
  payload: ITicketInsertPaymentResponse[] | undefined;
}

interface SetTicketDriverActionType {
  type: typeof SET_TICKET_DRIVER;
  payload: any | undefined;
}

interface SetTicketStatusActionType {
  type: typeof SET_TICKET_STATUS;
  payload: ITicketStatus | undefined;
}

export type TicketsActionType =
  | SetStepperTicketsActionType
  | SetTicketInsertResponseActionType
  | SetTicketInsertDetailResponseActionType
  | SetTicketInsertMultipleDetailResponseActionType
  | SetTicketInsertPaymentResponseActionType
  | SetTicketDriverActionType
  | SetTicketStatusActionType;

const setStepperTickets = (
  ticketsStepper: IStepperTickets | undefined
): SetStepperTicketsActionType => {
  return { type: SET_STEPPER_TICKETS, payload: ticketsStepper };
};

const setTicketInsertResponse = (
  ticketInsertResponse: ITicketInsertResponse[] | undefined
): SetTicketInsertResponseActionType => {
  return { type: SET_TICKET_INSERT_RESPONSE, payload: ticketInsertResponse };
};

const setTicketInsertDetailResponse = (
  ticketInsertDetailResponse: ITicketInsertDetailResponse[] | undefined
): SetTicketInsertDetailResponseActionType => {
  return {
    type: SET_TICKET_INSERT_DETAIL_RESPONSE,
    payload: ticketInsertDetailResponse,
  };
};

const setTicketInsertMultipleDetailResponse = (
  ticketInsertMultipleDetailResponse: any[] | undefined
): SetTicketInsertMultipleDetailResponseActionType => {
  return {
    type: SET_TICKET_INSERT_MULTIPLE_DETAIL_RESPONSE,
    payload: ticketInsertMultipleDetailResponse,
  };
};

const setTicketInsertPaymentResponse = (
  ticketInsertPaymentResponse: ITicketInsertPaymentResponse[] | undefined
): SetTicketInsertPaymentResponseActionType => {
  return {
    type: SET_TICKET_INSERT_PAYMENT_RESPONSE,
    payload: ticketInsertPaymentResponse,
  };
};

const setTicketDriver = (
  driverTicket: any | undefined
): SetTicketDriverActionType => {
  return { type: SET_TICKET_DRIVER, payload: driverTicket };
};

const setTicketStatus = (
  ticketStatus: ITicketStatus | undefined
): SetTicketStatusActionType => {
  return { type: SET_TICKET_STATUS, payload: ticketStatus };
};

export const TicketsAction = {
  setStepperTickets,
  setTicketInsertResponse,
  setTicketInsertDetailResponse,
  setTicketInsertMultipleDetailResponse,
  setTicketInsertPaymentResponse,
  setTicketDriver,
  setTicketStatus,
};
