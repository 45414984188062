import i18next from "i18next";
import { AjaxService } from "../../Services/Internal/AjaxService";
import "./MctcTableViewer.scss";
import moment from "moment";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
export interface IMctcTableViewerProps {
  vehicleInfoMctc: any;
}

const MctcTableViewer = (props: IMctcTableViewerProps) => {
  const decodeString = (string: string) => {
    let isDate = new RegExp(
      /^([0-9]{4})-(0[0-9]|1[0-2])?-?([0-2][0-9]|3[0-1])\T/g
    ).test(string);

    if (isDate) {
      return moment(string).format("L");
    }
    return string;
  };

  return (
    <div className="mctc-wrapper">
      <ol>
        {Object.keys(props.vehicleInfoMctc)
          .filter((x: any) => x !== "pdf")
          .map((k: any, h: number) => {
            return (
              <li key={h}>
                <b className="text-color-hint">
                  {i18next.t("form:_mctc_" + k)}
                </b>
                {Array.isArray(props.vehicleInfoMctc[k]) ? (
                  props.vehicleInfoMctc[k].map((item: any, i: number) => {
                    return (
                      <ol key={i}>
                        {typeof item === "object" &&
                          item !== null &&
                          !Array.isArray(item) &&
                          Object.keys(item).map((sub: any, s: number) => {
                            return (
                              <li key={s}>
                                <b>{i18next.t("form:_mctc_" + sub)}</b>:{" "}
                                {decodeString(item[sub])}
                              </li>
                            );
                          })}
                      </ol>
                    );
                  })
                ) : (
                  <ol>
                    {props.vehicleInfoMctc[k] !== undefined && props.vehicleInfoMctc[k] !== null ?
                      Object.keys(props.vehicleInfoMctc[k]).map(
                        (objItem: any, o: number) => {
                          return (
                            <li key={o}>
                              <b>{i18next.t("form:_mctc_" + objItem)}</b>:{" "}
                              {decodeString(props.vehicleInfoMctc[k][objItem])}
                            </li>
                          );
                        }
                      )
                    :
                      <li key={h}>
                        <b>{i18next.t("form:_mctc_" + k)}</b>:{" "}{props.vehicleInfoMctc[k]}
                      </li>
                    }
                  </ol>
                )}
              </li>
            );
          })}
        {props.vehicleInfoMctc.pdf && (
          <Button
            variant="contained"
            endIcon={<DownloadIcon />}
            onClick={() =>
              AjaxService.downloadBase64File(
                props.vehicleInfoMctc.datiBaseVeicolo.targaVeicolo + ".pdf",
                props.vehicleInfoMctc.pdf
              )
            }
          >
            {i18next.t("form:downloadVehiclePDF")}
          </Button>
        )}
      </ol>
    </div>
  );
};

export default MctcTableViewer;
