import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

import {
  IOrderInsertData,
  IOrderInsertDeadline,
  IOrderUpdateData,
} from "../../Models/IOrder";
import { IAttachment } from "../../Models/IAttachments";

const OrderController = {
  /* GET */
  // Get the type of order for the select type (single/multiple)
  OrderGetType: async (
    vehicleOrderTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_GET_TYPE;
    if (vehicleOrderTypeID) {
      request.url += `?vehicleOrderTypeID=${vehicleOrderTypeID}`;
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  // Get the orders side list
  OrderGetSideList: async (
    vehicleOrderID?: number,
    vehicleID?: number,
    driverID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_GET_DATA;
    let params: string[] = [];
    if (vehicleOrderID) {
      params.push("vehicleOrderID=" + vehicleOrderID);
    }

    if (vehicleID) {
      params.push("vehicleID=" + vehicleID);
    }
    if (driverID) {
      params.push("driverID=" + driverID);
    }
    if (params.length > 0) {
      request.url += "?" + params.join("&");
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  OrderMarkDetailForVehicle: async (
    vehicleOrderDetailID: number,
    vehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.ORDER_SET_DETAIL_VEHICLE}?vehicleOrderDetailID=${vehicleOrderDetailID}&vehicleID=${vehicleID}`;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* INSERT */
  // Insert a new order
  OrderInsertData: async (
    body: IOrderInsertData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_POST_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* UPDATE */
  // Update the selected order
  OrderUpdateData: async (
    body: IOrderUpdateData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* DELETE */
  // Delete the selected order
  OrderDeleteData: async (
    vehicleOrderID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_DELETE_DATA;
    request.method = "DELETE";
    request.payload = {
      vehicleOrderID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* ATTACHMENT */
  // Insert the attachment
  OrderInsertAttachment: async (
    body: IAttachment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_INSERT_ATTACHMENT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update the attachment
  OrderUpdateAttachment: async (
    body: IAttachment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_UPDATE_ATTACHMENT;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Delete the attachment
  OrderDeleteAttachment: async (
    vehicleOrderAttachmentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_DELETE_ATTACHMENT;
    request.method = "DELETE";
    request.payload = {
      vehicleOrderAttachmentID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  /* DEADLINE */
  // Insert the deadline
  OrderInsertDeadline: async (
    body: IOrderInsertDeadline,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.ORDER_INSERT_DEADLINE;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default OrderController;
