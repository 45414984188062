import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ITicketDetail, ITicketInfo } from "../../../../../../Models/ITicket";
import { IUser } from "../../../../../../Models/IUser";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import EuroIcon from "@mui/icons-material/Euro";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import "./VehiclesPage_subPages_Tickets.scss";
import EditIcon from "@mui/icons-material/Edit";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import TicketDetailForm from "../../../../../../Components/TicketDetailForm/TicketDetailForm";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import DashboardSectionTitle from "../../../../../../Components/DashboardSectionTitle/DashboardSectionTitle";
import i18next from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { IAssignmentGet } from "../../../../../../Models/IAssignment";
import { TicketsAction } from "../../../../../../Reducers/Tickets/TicketsAction";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { currencyDecoder } from "../../../../../../Utils/Decoder";

const VehiclesPage_subPages_Tickets = () => {
  
  const [params] = useSearchParams();
  const vehicleIDParam: string | null = params.get("vehicleID");
  
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const assignment: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignment
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [ticketsInfos, setTicketsInfos] = useState<ITicketInfo[]>([]);
  const [openTicket, setOpenTicket] = useState<any>();
  const [driverList, setDriverList] = useState<any[]>([]);

  const loadTickets = () => {
    if (vehicle) {
      setLoading(true);
      ApiService.TicketController.GetTicketList(
        null,
        vehicle.vehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTicketsInfos(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    loadTickets();
  }, [vehicle]);

  const EditTicket = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          onClick={() => {
            setOpenTicket(props.row.allData);
            setTimeout(() => {
              SummonModal("ShowDashboardTicketDetails");
            }, 100);
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const StatusTicket = (props: GridRenderCellParams) => {
    let tmpTickets: any[] = [...validTickets];
    let color: string = "";
    let now = new Date().getTime();
    let expirationDT = new Date(props.row.ticketExpirationDTFormat).getTime();
    if (now > expirationDT && props.row.ticketStatusID !== 3) {
      color = "red";
    } else {
      switch (props.row.ticketStatusID) {
        case 1:
          color = "white";
          break;
        case 3:
          color = "green";
          break;
        case 5:
          color = "orange";
          break;
      }
    }

    if (tmpTickets.length > 0) {
      for (let i = 0; i < tmpTickets.length; i++) {
        if (
          tmpTickets && props.row.ticketID === tmpTickets[i].ticketID &&
          !tmpTickets[i].statusTicket
        ) {
          tmpTickets[i].statusTicket = color;

          validTickets = tmpTickets
        }
      }
    }

    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EuroIcon
            className="euro-wrapper"
            style={{
              backgroundColor: color,
              color:
                props.row.ticketStatusID === 1 && color !== "red" ? "black" : "white",
              border:
                props.row.ticketStatusID === 1 && color !== "red"
                  ? "1px solid black"
                  : "",
            }}
          />
          <div style={{ marginLeft: "0.5em" }}>{props.value}</div>
        </div>
      </>
    );
  };

  const multipleDriver = (props: GridRenderCellParams) => {
    if (props.value === "multiple") {
      return (
        <Button
          onClick={() => {
            setDriverList(props.row.driverDisplayNameMultiple);
            SummonModal("multipleDriver");
          }}
        >
          multiple
        </Button>
      );
    } else {
      return <div>{props.value}</div>;
    }
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: " ",
      renderCell: EditTicket,
      type: 'custom'
    },
    {
      field: "ticketStatusName",
      headerName: i18next.t("navigation:_tickets_page_column_title_ticket_status").toUpperCase(),
      renderCell: StatusTicket,
    },
    {
      field: "ticketNumber",
      headerName: i18next.t("form:VerbalNumber").toUpperCase(),
    },
    {
      field: "driverDisplayName",
      headerName: i18next.t("navigation:_tickets_page_column_assignee").toUpperCase(),
      renderCell: multipleDriver,
    },
    {
      field: "ticketReducedExpirationDT",
      headerName: i18next.t("navigation:_tickets_page_column_data_reduced_expiry").toUpperCase(),
      fieldOriginal: "ticketReducedExpirationDTOriginal",
    },
    {
      field: "reducedTotalAmount",
      headerName: i18next.t("navigation:_tickets_page_column_reduce_amount").toUpperCase(),
      type: "number",
      fieldOriginal: "reducedTotalAmountOriginal",
    },
    {
      field: "ticketExpirationDT",
      headerName: i18next.t("navigation:_tickets_page_column_data_expiry").toUpperCase(),
      fieldOriginal: "ticketExpirationDTOriginal",
    },
    {
      field: "totalAmount",
      headerName: i18next.t("navigation:_tickets_page_column_amount").toUpperCase(),
      type: "number",
      fieldOriginal: "totalAmountOriginal",
    },
    {
      field: "paidAmount",
      headerName: i18next.t("navigation:_tickets_page_column_amount_paid").toUpperCase(),
      type: "number",
      fieldOriginal: "paidAmountOriginal",
    },
    {
      field: "ticketTypeName",
      headerName: i18next.t("navigation:_tickets_page_column_type_of_ticket").toUpperCase(),
    },
    {
      field: "ticketIssuingInstitutionName",
      headerName: i18next.t("navigation:_tickets_page_column_issuer_entity").toUpperCase(),
    },
  ];

  const getValidTickets = () => {
    let id: number = assignment?.driverID ?? -1;
    let filtered: ITicketInfo[] = [];
    for (let i = 0; i < ticketsInfos.length; i++) {
      let curr: ITicketInfo = ticketsInfos[i];
      let drivers: number[] = curr.ticketDetail.map((x: ITicketDetail) => {
        return x.driverID;
      });
      if (drivers.includes(id) || id === -1) {
        filtered.push({ ...curr });
      }
    }
    return filtered;
  };

  let validTickets: ITicketInfo[] = getValidTickets();
  const plateTickets =
    validTickets[0] &&
    validTickets[0].ticketDetail[0].vehicleLicensePlate &&
    validTickets[0].ticketDetail[0].vehicleLicensePlate.replace(/ /g, "");

  const vehicleID = validTickets[0] && validTickets[0].ticketDetail[0].vehicleID ? validTickets[0].ticketDetail[0].vehicleID : vehicleIDParam;
  let paramsURL: string = "";
  if(vehicleID){
    paramsURL = "/?vehicleID=" + vehicleID;
  }
  if(plateTickets && plateTickets !== undefined){
    paramsURL += "&plate=" + plateTickets;
  }
  return (
    <div>
      <LoaderBackdrop loading={loading} />
      <DashboardSectionTitle
        title={i18next.t("navigation:_add_services_tickets")}
        rightButtons={[
          {
            disabled: disposed > 0,
            label: "+ " + i18next.t("navigation:_add_ticket"),
            onClick: () => {
              dispatch(TicketsAction.setStepperTickets(undefined));

              navigate(
                AppRoutes.INTERNAL_DASHBOARD + AppRoutes.TICKETS_MANAGEMENT + paramsURL
              );
            },
          },
        ]}
      />

      <SmartModal
        title={openTicket ? openTicket.ticket.ticketNumber.toString() : "-"}
        modalUniqueId="ShowDashboardTicketDetails"
        modalInnerComponent={
          <div>
            <TicketDetailForm
              disabled={disposed > 0}
              onTicketEdit={(edited: ITicketInfo) => {
                let ticketsInfos_ = [
                  ...ticketsInfos.filter(
                    (x: ITicketInfo) => x.ticket.ticketID !== edited.ticket.ticketID
                  ),
                ];
                ticketsInfos_.push(edited);
                setTicketsInfos(ticketsInfos_);
              }}
              ticket={openTicket}
              minorEdit
            />
          </div>
        }
      />

      <Box className="VehiclesPage_subPages_Tickets" style={{ marginTop: "1em" }}>
        <DataGridWrap
          headers={columnsDef}
          rows={validTickets.map((x: any, i: number) => {
            return {
              ticketID_: x.ticket.ticketID,
              ticketID: x.ticket.ticketID,
              ticketNumber: x.ticket.ticketNumber,
              ticketTypeName: x.ticket.ticketTypeName,
              ticketDT: displayUTC0_ISODate(x.ticket.ticketDT, true),
              ticketIssuingInstitutionName: x.ticket.ticketIssuingInstitutionName,
              ticketReducedExpirationDT: displayUTC0_ISODate(x.ticket.ticketReducedExpirationDT, true),
              ticketReducedExpirationDTOriginal: x.ticket.ticketReducedExpirationDT,
              reducedTotalAmount: currencyDecoder(x.ticket.reducedTotalAmount, 2),
              reducedTotalAmountOriginal: x.ticket.reducedTotalAmount,
              ticketExpirationDT: displayUTC0_ISODate(x.ticket.ticketExpirationDT, true),
              ticketExpirationDTOriginal: x.ticket.ticketExpirationDT,
              totalAmount: currencyDecoder(x.ticket.totalAmount, 2),
              totalAmountOriginal: x.ticket.totalAmount,
              paidAmount: currencyDecoder(x.ticket.paidAmount, 2),
              paidAmountOriginal: x.ticket.paidAmount,
              vehicleLicensePlate:
                x.ticketDetail.length === 0
                  ? null
                  : x.ticketDetail.length === 1
                  ? x.ticketDetail[0].vehicleLicensePlate
                  : "multiple",
              driverDisplayName:
                x.ticketDetail.length === 0
                  ? null
                  : x.ticketDetail.length === 1
                  ? x.ticketDetail[0].driverDisplayName
                  : "multiple",
              driverDisplayNameMultiple: x.ticketDetail.map((x: any) => {
                return x;
              }),
              vehicleLicensePlateMultiple: x.ticketDetail.map((x: any) => {
                return x;
              }),
              ticketStatusName: x.ticket.ticketContested
                ? i18next.t("navigation:_tickets_page_contested")
                : x.ticket.ticketStatusID === 5
                ? i18next.t("navigation:_tickets_page_paid")
                : x.ticket.ticketStatusName,
              ticketStatusNameContested: x.ticket.ticketStatusName,
              ticketContested: x.ticket.ticketContested,
              ticketStatusID: x.ticket.ticketStatusID,
              allData: x
            };
          })}
        />
      </Box>
      <SmartModal
        modalUniqueId={"multipleDriver"}
        title={i18next.t("navigation:_tickets_page_payment_driver_list")}
        modalInnerComponent={
          <div>
            {driverList.map((x: any) => {
              return <div className="multiple-driver-wrapper">{x.driverDisplayName}</div>;
            })}
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:close"),
            onClick: () => {
              DismissModal("multipleDriver");
            },
          },
        ]}
      />
    </div>
  );
};

export default VehiclesPage_subPages_Tickets;
