import PrettyTable from "../PrettyTable/PrettyTable";
import "./DataGridWrap.scss";

export interface IDataGridColumnDef {
  field: string;
  fieldOriginal?: string;
  headerName: string;
  editable?: boolean;
  type?: string;
  valueOptions?: any;
  renderCell?: (e: any) => JSX.Element;
  hideExport?: boolean;
}

export interface IDataGridWrapProps {
  rows: any[];
  headers: IDataGridColumnDef[];
  headerToPrint?: string[];
  tableName?: string;
  onRowClick?: (row: (string | number)[]) => void;
  hideToolbar?: boolean;
  hidePagination?: boolean;
}

const DataGridWrap = (props: IDataGridWrapProps) => {
  const rows: any[][] = [];
  const headers: string[] = [];
  const types: string[] = [];

  for (let i = 0; i < props.headers.length; i++) {
    headers.push(props.headers[i].headerName);
    types.push(props.headers[i].type ? props.headers[i].type! : '');
  }

  for (let i = 0; i < props.rows.length; i++) {
    let rowItem = props.rows[i];
    let simpleRow: {valueExternal: string | number, valueInternal: string | number }[] = [];
    for (let j = 0; j < props.headers.length; j++) {
      let rowKey = props.headers[j].field;
      let rowKeyOriginal = props.headers[j].fieldOriginal ?? props.headers[j].field;
      let cRender = props.headers[j].renderCell;
      simpleRow.push(
        {
          valueExternal:
            cRender ? (
              <div id={rowItem[rowKey]}>
                {cRender({ row: rowItem, value: rowItem[rowKey] })}
              </div>
            ) : (
              rowItem[rowKey]
            ),
          valueInternal: rowItem[rowKeyOriginal],
        }
      );
    }

    rows.push(simpleRow);
  }

  return (
    <PrettyTable
      onRowClick={(row: (string | number)[]) => {
        if (props.onRowClick) {
          props.onRowClick(row);
        }
      }}
      title={props.tableName}
      headerToPrint={props.headers.filter((e: IDataGridColumnDef) => !e.hideExport).map((e: IDataGridColumnDef) => {return e.headerName})}
      headers={headers}
      rows={rows}
      columnsType={types}
      rowKeyToSkip={props.headers.filter((e: IDataGridColumnDef) => e.hideExport).map((e: any, i: number) => {return i})}
      hideToolbar={props.hideToolbar}
      hidePagination={props.hidePagination}
    />
  );
};

export default DataGridWrap;
