import { IAddedService } from "../../Models/IService";
import { IStepperStep } from "../../Models/IStepperBody";
import { ISubscriptions } from "../../Models/ISubscriptions";

export const SET_TEMPLATE_RELOADS_W_DATA = "SET_TEMPLATE_RELOADS_W_DATA";
export const SET_TEMPLATE_GENERIC_W_DATA = "SET_TEMPLATE_GENERIC_W_DATA";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_LAST_STEP = "SET_LAST_STEP";
export const SET_SUBSCRIPTION_ENABLED = "SET_SUBSCRIPTION_ENABLED";
export const SET_CURRENT_SUBSCRIPTION = "SET_CURRENT_SUBSCRIPTION";
export const SET_DEBIT_COST = "SET_DEBIT_COST";
export const SET_ADDONS = "SET_ADDONS";

interface SetTemplateReloadsWDataActionType {
  type: typeof SET_TEMPLATE_RELOADS_W_DATA;
  payload: any;
}
interface SetAddonsActionType {
  type: typeof SET_ADDONS;
  payload: IAddedService[];
}
interface SetTemplateGenericWDataActionType {
  type: typeof SET_TEMPLATE_GENERIC_W_DATA;
  payload: any;
}
interface SetLanguageActionType {
  type: typeof SET_LANGUAGE;
  payload: string;
}

interface SetOptionsActionType {
  type: typeof SET_OPTIONS;
  payload: any[];
}

interface SetLastStepActionType {
  type: typeof SET_LAST_STEP;
  payload: IStepperStep;
}

interface SetSubscriptionEnabledActionType {
  type: typeof SET_SUBSCRIPTION_ENABLED;
  payload: boolean;
}

interface SetCurrentSubscriptionActionType {
  type: typeof SET_CURRENT_SUBSCRIPTION;
  payload: ISubscriptions;
}

interface SetDebitCostActionType {
  type: typeof SET_DEBIT_COST;
  payload: number;
}

export type GenericActionType =
  | SetLanguageActionType
  | SetOptionsActionType
  | SetLastStepActionType
  | SetTemplateGenericWDataActionType
  | SetTemplateReloadsWDataActionType
  | SetSubscriptionEnabledActionType
  | SetAddonsActionType
  | SetCurrentSubscriptionActionType
  | SetDebitCostActionType;

const setTemplateReloafsWorkingData = (
  data: any
): SetTemplateReloadsWDataActionType => {
  return { type: SET_TEMPLATE_RELOADS_W_DATA, payload: data };
};

const setAddons = (data: IAddedService[]): SetAddonsActionType => {
  return { type: SET_ADDONS, payload: data };
};

const setTemplateGenericWorkingData = (
  data: any
): SetTemplateGenericWDataActionType => {
  return { type: SET_TEMPLATE_GENERIC_W_DATA, payload: data };
};

const setLanguage = (language: string): SetLanguageActionType => {
  return { type: SET_LANGUAGE, payload: language };
};

const setOptions = (options: any[]): SetOptionsActionType => {
  return { type: SET_OPTIONS, payload: options };
};

const setLastStep = (lastStep: IStepperStep): SetLastStepActionType => {
  return { type: SET_LAST_STEP, payload: lastStep };
};

const setSubscriptionEnabled = (
  subscriptionEnabled: boolean
): SetSubscriptionEnabledActionType => {
  return { type: SET_SUBSCRIPTION_ENABLED, payload: subscriptionEnabled };
};

const setCurrentSubscription = (
  currentSubscription: ISubscriptions
): SetCurrentSubscriptionActionType => {
  return { type: SET_CURRENT_SUBSCRIPTION, payload: currentSubscription };
};

const setDebitCost = (debitCost: number): SetDebitCostActionType => {
  return { type: SET_DEBIT_COST, payload: debitCost };
};

export const GenericActions = {
  setLanguage,
  setOptions,
  setLastStep,
  setTemplateGenericWorkingData,
  setTemplateReloafsWorkingData,
  setSubscriptionEnabled,
  setCurrentSubscription,
  setDebitCost,
  setAddons,
};
