import { Box, ButtonGroup, Button } from "@mui/material";
import i18next from "i18next";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import ApiService from "../../../../../Services/ApiService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { validationFiscalCode } from "../../../../../Utils/Validation";
import { IFleetManager, IFleetManagerInsert } from "../../../../../Models/IFleetManager";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const FleetManager__AnagraphicsPage = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [state, setState] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [currentFleetManager, setCurrentFleetManager] = useState<any>(null);
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [loadingFleetManager, setLoadingFleetManager] = useState<boolean>(false);
  const [fleetManager, setFleetManager] = useState<IFleetManager[]>([]);

  // Load company list
  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanyList(false);
        }
      );
    }
  };

  const loadFleetManager = () => {
    setLoadingFleetManager(true);
    if (loggedUser) {
      ApiService.FleetManagerController.FleetManagerGet((response: IAPIResponse) => {
        if (response.error === null) {
          setFleetManager(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFleetManager(false);
      });
    }
  };

  const insertFleetManager = (data: IFleetManagerInsert) => {
    setProcessing(true);
    if (loggedUser) {
      ApiService.FleetManagerController.FleetManagerInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:success"), "success");

            loadFleetManager();

            DismissModal("fleet-manager-page-modal");
          } else if (response.raw.status === 409) {
            ToastMessage(i18next.t("error:REGISTRATION_USER_ALREADY_REGISTRED"), "error");
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "accountName",
      headerName: i18next.t("form:name"),
    },
    {
      field: "accountSurname",
      headerName: i18next.t("form:surname"),
    },
    {
      field: "accountEmail",
      headerName: i18next.t("form:email"),
    },
    {
      field: "accountFiscalCode",
      headerName: i18next.t("form:taxIdCode"),
    },
    {
      field: "accountPhone",
      headerName: i18next.t("form:telephonePersonal"),
    },
  ];
  const rowsDef: any[] = [];

  for (let i = 0; i < fleetManager.length; i++) {
    rowsDef.push({
      enabled: fleetManager[i].enabled,
      accountName: fleetManager[i].accountName,
      accountSurname: fleetManager[i].accountSurname,
      accountEmail: fleetManager[i].accountEmail,
      accountFiscalCode: fleetManager[i].accountFiscalCode?.toUpperCase(),
      accountPhone: fleetManager[i].accountPhone,
    });
  }

  useEffect(() => {
    loadCompanyList();
    loadFleetManager();
  }, []);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_fleet_manager")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentFleetManager(null);
                SummonModal("fleet-manager-page-modal");
              }}
            >
              {i18next.t("navigation:_add_fleet_manager")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          hideButtons={true}
          modalUniqueId="fleet-manager-page-modal"
          title={i18next.t("navigation:_invite_fleet_manager_title")}
          modalInnerComponent={
            <div>
              <p>{i18next.t("navigation:_invite_fleet_manager_subtitle")}</p>
              <MultiForm
                formId="fleet-manager-form"
                inputs={[
                  {
                    width: 33.33,
                    type: "text",
                    name: "accountName",
                    required: true,
                    inputProps: {
                      maxLength: 50,
                    },
                    label: `${i18next.t("form:name")}`,
                  },
                  {
                    width: 33.33,
                    type: "text",
                    name: "accountSurname",
                    required: true,
                    inputProps: {
                      maxLength: 50,
                    },
                    label: `${i18next.t("form:surname")}`,
                  },
                  {
                    width: 33.33,
                    type: "select_multiple",
                    required: true,
                    defaultValue: state?.fleetManagerCompanies ?? [],
                    name: "fleetManagerCompanies",
                    label: i18next.t("form:orderCompany") as string,
                    options: companyList.map((x: ICompanyGet) => {
                      return {
                        key: x.companyID,
                        text: x.companyBusinessName,
                      };
                    }),
                  },
                  {
                    width: 33.33,
                    type: "email",
                    name: "accountEmail",
                    required: true,
                    inputProps: { maxLength: 100 },
                    label: `${i18next.t("form:email")}`,
                  },
                  {
                    width: 33.33,
                    type: "text",
                    name: "accountFiscalCode",
                    required: true,
                    inputProps: {
                      maxLength: 16,
                      style: { textTransform: "uppercase" },
                    },
                    label: `${i18next.t("form:taxIdCode")}`,
                    errorText:
                      state?.accountFiscalCode &&
                      state?.accountFiscalCode.length > 0 &&
                      !validationFiscalCode(state?.accountFiscalCode)
                        ? (i18next.t("form:validationCF") as string)
                        : "",
                  },
                  {
                    width: 33.33,
                    type: "tel",
                    name: "accountPhone",
                    inputProps: { maxLength: 50 },
                    label: `${i18next.t("form:telephonePersonal")}`,
                  },
                  {
                    type: "custom",
                    width: 100,
                    name: "inviteButton",
                    element: (
                      <div className="display-flex-end">
                        <Button
                          variant="contained"
                          disabled={processing}
                          onClick={() => {
                            triggerFormValidation("fleet-manager-form");
                          }}
                          endIcon={<SendIcon />}
                        >
                          {i18next.t("generic:sendInvite")}
                        </Button>
                      </div>
                    ),
                  },
                ]}
                onChange={(data: any) => {
                  setState({ ...state, ...data });
                }}
                suppressLayout
                suppressSubmit
                onSubmit={(data: any) => {
                  let parseData = { ...data };
                  delete parseData["fleetManagerCompanies"];
                  delete parseData["inviteButton"];
                  parseData["accountFiscalCode"] =
                    data["accountFiscalCode"]?.toUpperCase();
                  parseData["accountTypeID"] = 2;
                  parseData["companies"] = data["fleetManagerCompanies"].map(
                    (x: number | string) => {
                      return {
                        companyID: +x,
                      };
                    }
                  );
                  insertFleetManager(parseData);
                }}
              />
            </div>
          }
        />
        <LoaderBackdrop
          loading={loadingFleetManager || loadingCompanyList || processing}
        />
      </div>
    </div>
  );
};

export default FleetManager__AnagraphicsPage;
