/* COMPONENTS */
import KProjection__StatisticsPage_Data from "./KProjection__StatisticsPage_Data";

/* INTERFACE */
interface IKProjection__StatisticsPage_Body {
  title?: string | null;
}

/* COMPONENT */
const KProjection__StatisticsPage_Body = (props: IKProjection__StatisticsPage_Body) => {
  /* RETURN */
  return (
    <div className="dashboardForm__box">
      {props.title && (
        <h1
          className="text-color-hint"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      )}

      <KProjection__StatisticsPage_Data />
    </div>
  );
};

export default KProjection__StatisticsPage_Body;
