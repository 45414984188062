import React from "react";
import AppInner from "./AppInner";
import CountdownPage from "./Pages/CountdownPage/CountdownPage";
import { Loader } from "@googlemaps/js-api-loader";

export const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  libraries: ["places"],
});

const App: React.FC = () => {
  return (
    <div>
      <AppInner />
    </div>
  );
};

export default App;
