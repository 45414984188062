import EditIcon from "@mui/icons-material/Edit";
import { Alert, Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomStepper from "../../../../../../Components/CustomStepper/CustomStepper";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import { triggerFormValidation } from "../../../../../../Components/MultiForm/MultiForm";
import BetterSelect from "../../../../../../Components/MultiForm/SpecialInputs/BetterSelect";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import StepAmountDeadelines from "../../../../../../Components/StepAmountDeadelines/StepAmountDeadelines";
import StepAttachmentsTickets from "../../../../../../Components/StepAttachmentsTickets/StepAttachmentsTickets";
import StepBreachData from "../../../../../../Components/StepBreachData/StepBreachData";
import StepSanctionData from "../../../../../../Components/StepSanctionData/StepSanctionData";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { IStepperTickets } from "../../../../../../Models/IStepperTickets";
import { ITicketInsertDetailResponse } from "../../../../../../Models/ITicketInsertDetailResponse";
import { ITicketInsertResponse } from "../../../../../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../../../../../Models/ITicketStatus";
import { ITicketUpdate } from "../../../../../../Models/ITicketUpdate";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { TicketsAction } from "../../../../../../Reducers/Tickets/TicketsAction";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import "./TicketsManagementPage.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const TicketsManagementPage = (props: { cannotSee: boolean }) => {
  const dispatch = useDispatch();
  /* REDUX */
  // User
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const ticketStatus: ITicketStatus | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketStatus
  );
  const ticketInsertDetailResponse: ITicketInsertDetailResponse[] | undefined =
    useSelector((state: GlobalState) => state.tickets.ticketInsertDetailResponse);
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );
  const driverTicket: any | undefined = useSelector(
    (state: GlobalState) => state.tickets.driverTicket
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [currentAssignment, setCurrentAssignment] = useState<any[]>([]);
  const [assignmentSelected, setAssignmentSelected] = useState<any>();

  const navigate = useNavigate();

  const setNewDriver = () => {
    ApiService.TicketController.UpdateTicketDriver(
      ticketInsertDetailResponse![0].ticketDetailID,
      driverTicket?.driverID,
      (response: IAPIResponse) => {
        if (response.error === null) {
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const UpdateTicket = (body: ITicketUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertResponse(response.payload));
          ToastMessage(i18next.t("message:TicketUpdateCorrectly"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const SendTicketMailToDriver = (body: {ticketID: number}) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.SendTicketMailToDriver(body, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("navigation:sendTicketMailToDriverSuccessfully"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const loadCurrentAssignment = () => {
    if (loggedUser) {
      ApiService.DriverController.DriverNameSurnameGet((response: IAPIResponse) => {
        if (response.error === null) {
          setCurrentAssignment(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      });
    }
  };

  useEffect(() => {
    loadCurrentAssignment();
  }, []);

  return (
    <div>
      {props.cannotSee && (
        <div style={{ padding: "2em" }}>
          <Alert severity="warning">
            <div
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:you_must_buy_the_addon") ?? "",
              }}
            ></div>
          </Alert>
        </div>
      )}
      {!props.cannotSee && (
        <div className="display-flex">
          <div className="tickets-management-wrapper">
            <HeaderPageDashboard
              title={i18next.t("navigation:_add_services_tickets")}
              arrowBack
              onBack={() => navigate(-1)}
            />
            <div className="stepper-content">
              <div className="tickets-managemt-header">
                {driverTicket ? (
                  <>
                    <div>
                      <strong>Driver: {driverTicket.driverDisplayName}</strong>
                      <Button
                        style={{ color: "grey" }}
                        onClick={() => {
                          SummonModal("changeAssignment");
                        }}
                      >
                        <EditIcon style={{ padding: "0.1em" }} />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
                <strong>
                  ID {i18next.t("navigation:ticket").toUpperCase()}:{" "}
                  {ticketInsertResponse ? ticketInsertResponse[0].ticketID : null}
                </strong>
              </div>

              <CustomStepper
                isCustomDashboard
                isNextStep
                stepperNodes={[
                  {
                    element: <StepSanctionData />,
                    stepTitle: i18next.t("navigation:_tickets_page_step1_title"),
                    onNext: () => {
                      triggerFormValidation("sanction");
                      return false;
                    },
                  },
                  {
                    element: <StepBreachData />,
                    stepTitle: i18next.t("navigation:_tickets_page_step2_title"),
                    onNext: () => {
                      if (
                        stepperTicket &&
                        (stepperTicket?.stepSanction?.ticketTypeID === 1 ||
                          stepperTicket?.stepSanction?.ticketTypeID === 5 ||
                          stepperTicket?.stepSanction?.ticketTypeID === 6)
                      ) {
                        triggerFormValidation("multiple-breach");
                      } else {
                        triggerFormValidation("single-breach");
                      }
                      return false;
                    },
                    onBack: () => {
                      dispatch(TicketsAction.setTicketDriver(undefined));
                      return true;
                    },
                  },
                  {
                    element: <StepAmountDeadelines />,
                    stepTitle: i18next.t("navigation:AmountsDeadlines"),
                    onBack: () => {
                      return true;
                    },
                    onNext: () => {
                      triggerFormValidation("amount-deadlines");
                      return false;
                    },
                  },
                  {
                    element: <StepAttachmentsTickets />,
                    stepTitle: i18next.t("navigation:AddAttachments"),
                    onNext: () => {
                      if (ticketInsertDetailResponse) {
                        setNewDriver();
                      }
                      return true;
                    },
                  },
                  {
                    element: (
                      <div className="end-of-import">
                        <CheckCircleOutlineIcon />
                        {i18next.t("navigation:EndOfEntry")}
                      </div>
                    ),
                    stepTitle: i18next.t("navigation:EndOfEntry"),
                    onNext: () => {
                      return true;
                    },
                  },
                ]}
                onFinish={() => {
                  let tmp: any = stepperTicket!.stepAmountDeadlines;
                  let bodyUpdate: ITicketUpdate = tmp;
                  bodyUpdate.ticketID = ticketInsertResponse![0].ticketID;
                  bodyUpdate.completed = true;
                  if (ticketStatus?.alreadyDisputed) {
                    bodyUpdate.ticketContested = ticketStatus?.alreadyDisputed;
                  }
                  UpdateTicket(bodyUpdate);
                  SummonModal("InsertTicket");
                  dispatch(TicketsAction.setStepperTickets(undefined));
                  dispatch(TicketsAction.setTicketInsertResponse(undefined));
                  dispatch(TicketsAction.setTicketInsertDetailResponse(undefined));
                  dispatch(TicketsAction.setTicketInsertPaymentResponse(undefined));
                  dispatch(TicketsAction.setTicketStatus(undefined));
                  dispatch(TicketsAction.setTicketDriver(undefined));
                }}
              />
            </div>
            <SmartModal
              modalUniqueId={"InsertTicket"}
              title={""}
              modalInnerComponent={
                <>
                  <div className="insert-ticket-wrapper">
                    <strong className="insert-ticket-title">
                      {i18next.t("navigation:EndOfEntryModalTitle")}
                    </strong>
                    <div className="insert-ticket-subtitle">
                      {i18next.t("navigation:EndOfEntryModalBody")}
                    </div>
                    <div className="insert-ticket-buttons">
                      <Button
                        style={{ margin: "0.5em", width: "200px" }}
                        variant="outlined"
                        onClick={() => {
                          DismissModal("InsertTicket");
                          navigate(
                            AppRoutes.INTERNAL_DASHBOARD +
                              AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS
                          );
                        }}
                      >
                        {i18next.t("navigation:EndOfEntryModalDenyButton")}
                      </Button>
                      <Button
                        style={{ margin: "0.5em", width: "200px" }}
                        variant="contained"
                        onClick={() => {
                          SendTicketMailToDriver({ticketID: ticketInsertResponse && ticketInsertResponse[0].ticketID ? ticketInsertResponse[0].ticketID : 0})
                          DismissModal("InsertTicket");
                          navigate(
                            AppRoutes.INTERNAL_DASHBOARD +
                              AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS
                          );
                        }}
                      >
                        {i18next.t("navigation:EndOfEntryModalConfirmButton")}
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
            <SmartModal
              modalUniqueId={"changeAssignment"}
              title={i18next.t("navigation:_tickets_page_payment_change_assignment")}
              modalInnerComponent={
                <div>
                  <div className="change-assignment-header">
                    {i18next.t("navigation:_tickets_page_payment_infrigment_assignment")}
                  </div>
                  <BetterSelect
                    onChange={(value) => {
                      let tmp = currentAssignment.find((x: any) => {
                        return x.driverID.toString() === value.toString();
                      });
                      if (tmp) {
                        setAssignmentSelected(tmp);
                      }
                    }}
                    name={""}
                    type={"select"}
                    options={currentAssignment.map((x: any) => {
                      return {
                        key: x.driverID,
                        text: x.driverName + " " + x.driverSurname,
                      };
                    })}
                  />
                </div>
              }
              buttons={[
                {
                  text: i18next.t("navigation:reject"),
                  onClick: () => {
                    DismissModal("changeAssignment");
                  },
                },
                {
                  text: i18next.t("navigation:_tickets_page_payment_owner_change"),
                  onClick: () => {
                    dispatch(
                      TicketsAction.setTicketDriver({
                        driverID: assignmentSelected?.driverID,
                        driverDisplayName:
                          assignmentSelected.driverName +
                          " " +
                          assignmentSelected.driverSurname,
                      })
                    );
                    let tmp: any = { ...stepperTicket };
                    let tmpStepBreach: any = tmp.stepBreach;
                    tmpStepBreach.driverID = assignmentSelected?.driverID;
                    tmp.stepBreach = tmpStepBreach;
                    dispatch(TicketsAction.setStepperTickets(tmp));
                    DismissModal("changeAssignment");
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketsManagementPage;
