/* REACT */
import { useEffect, useState } from "react";

/* LIBRARY */
import i18next from "i18next";

/* STYLE */
import "./FormInsertTelepassUsage.scss";

/* COMPONENTS */
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";

/* MUI */
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ITelepassGet } from "../../Models/ITelepass";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { ICompanyGet } from "../../Models/ICompany";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { VehiclesActions } from "../../Reducers/Vehicle/VehicleAction";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import {
  ITelepassClass,
  ITelepassUsageInsert,
  ITelepassUsageType,
  ITelepassUsageUpdate,
} from "../../Models/ITelepassUsage";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import { JSONPrint } from "../../Utils/Decoder";

/* COMPONENT */

export interface IFormInsertTelepassUsageProps {
  data?: any;
  doRefreshForm?: () => void;
  idModal?: string;
}

const FormInsertTelepassUsage = (props: IFormInsertTelepassUsageProps) => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [state, setState] = useState<any>(props.data);
  const [loadingTelepass, setLoadingTelepass] = useState<boolean>(false);
  const [telepass, setTelepass] = useState<ITelepassGet[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingTelepassClass, setLoadingTelepassClass] =
    useState<boolean>(false);
  const [telepassClass, setTelepassClass] = useState<ITelepassClass[]>([]);
  const [loadingTelepassUsageType, setLoadingTelepassUsageType] = useState<boolean>(false); 
  const [telepassUsageTypeList, setTelepassUsageTypeList] = useState<ITelepassUsageType[]>([]);

  const loadTelepass = () => {
    if (loggedUser) {
      setLoadingTelepass(true);
      ApiService.TelepassController.TelepassGet(
        null,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepass(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepass(false);
        }
      );
    }
  };

  const loadTelepassClass = () => {
    if (loggedUser) {
      setLoadingTelepassClass(true);
      ApiService.TelepassUsageController.TelepassClassGet(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassClass(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassClass(false);
        }
      );
    }
  };

  const loadTelepassUsageType = () => {
    if (loggedUser) {
      setLoadingTelepassUsageType(true);
      ApiService.TelepassUsageController.TelepassUsageTypeGet(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassUsageTypeList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassUsageType(false);
        }
      );
    }
  };

  const insertTelepassUsage = (data: ITelepassUsageInsert) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.TelepassUsageController.TelepassUsageInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassUsageSuccessfullAdded"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateTelepassUsage = (data: ITelepassUsageUpdate) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.TelepassUsageController.TelepassUsageUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassUsageSuccessfullUpdated"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const deleteTelepassUsage = (telepassUsageID: number) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.TelepassUsageController.TelepassUsageDelete(
        telepassUsageID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:telepassUsageSuccessfullDeleted"),
              "success"
            );
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  useEffect(() => {
    loadTelepass();
    loadTelepassClass();
    loadTelepassUsageType();
  }, []);

  /* RETURN */
  return (
    <div className="telepass-usage-insert-modal-wrapper">
      <MultiForm
        formId={"form-modal-telepass-usage"}
        suppressSubmit={true}
        inputs={[
          {
            width: 33.33,
            required: true,
            type: "select",
            name: "telepassID",
            options: telepass.map((x: ITelepassGet) => {
              return {
                key: x.telepassID,
                text: x.telepassTypeName + " - " + x.telepassSerialNumber,
              };
            }),
            defaultValue: state?.telepassID,
            disabled: state?.vehicleID <= 0,
            label: i18next.t("form:telepassType") as string,
          },
          {
            width: 33.33,
            defaultValue: state?.telepassClassID,
            name: "telepassClassID",
            type: "select",
            label: i18next.t("form:telepassUsageClass") as string,
            options: telepassClass.map((x: ITelepassClass) => {
              return {
                key: x.telepassClassID,
                text: x.telepassClassName.toString(),
              };
            }),
          },
          {
            width: 33.33,
            defaultValue: state?.telepassUsageType,
            name: "telepassUsageType",
            required: true,
            type: "select",
            label: i18next.t("form:telepassUsageTypeDescription") as string,
            options: telepassUsageTypeList.map((x: ITelepassUsageType) => {
              return {
                key: x.telepassUsageType,
                text: x.telepassUsageTypeDescription,
              };
            }),
          },
          {
            width: 33.33,
            required: true,
            name: "telepassUsageDT",
            type: "datetime",
            defaultValue: state?.telepassUsageDT,
            label: i18next.t("form:telepassUsageDT") as string,
          },
          {
            width: 33.33,
            name: "telepassUsageCost",
            type: "number",
            inputProps: {
              step: "0.01",
            },
            required: true,
            inputAdornament: { adornament: "€" },
            defaultValue: state?.telepassUsageCost,
            label: i18next.t("form:telepassUsageCost") as string,
          },
          {
            width: 33.33,
            required: true,
            name: "telepassUsageRoute",
            type: "text",
            defaultValue: state?.telepassUsageRoute,
            label: i18next.t("form:telepassUsageRoute") as string,
          },
          {
            width: 100,
            type: "custom",
            name: "buttons",
            element: (
              <div className="display-flex-end">
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={state && !state?.telepassUsageID}
                  onClick={() => {
                    SummonModal("delete-telepass-usage-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("generic:delete")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    triggerFormValidation("form-modal-telepass-usage");
                  }}
                  endIcon={<SaveIcon />}
                >
                  {i18next.t("generic:save")}
                </Button>
              </div>
            ),
          },
        ]}
        onChange={async (data: any) => {
          let newSt = { ...state };
          let keys = Object.keys(data);
          for (let i = 0; i < keys.length; i++) {
            let v = data[keys[i]];
            if (v) {
              newSt[keys[i]] = v;
            }
          }
          setState(newSt);
        }}
        onSubmit={async () => {
          let parsedState = { ...state };
          delete parsedState["buttons"];
          parsedState["telepassUsageCost"] = +state?.telepassUsageCost;
          if (state?.telepassUsageID) {
            updateTelepassUsage(parsedState);
          } else {
            insertTelepassUsage(parsedState);
          }
        }}
      />
      <SmartModal
        modalUniqueId="delete-telepass-usage-modal"
        title={i18next.t("generic:titleRecordModal")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace("CURRENT_ITEM_LIST", state?.telepassUsageID),
            }}
          />
        }
        onAccept={() => {
          deleteTelepassUsage(state?.telepassUsageID);
        }}
        onReject={() => {}}
      />
      <LoaderBackdrop
        loading={processing || loadingTelepass || loadingTelepassClass || loadingTelepassUsageType}
      />
    </div>
  );
};

export default FormInsertTelepassUsage;
