import i18next from "i18next";
import { IMessageInsert } from "../Models/IMessages";
import ApiService from "../Services/ApiService";

export interface SmartMessageItem {
  key: string;
  value: string;
  fun?: string;
}

export const sendMessage = (
  toAccount: number,
  subject: string,
  content: string,
  shortContent: string,
  url: string
) => {
  let date: Date = new Date();
  let validFrom = date.toISOString();
  date.setFullYear(date.getFullYear() + 1);
  let validTo = date.toISOString();
  let msg: IMessageInsert = {
    accountToIDList: [{ id: toAccount }],
    subject: subject,
    content: content,
    shortContent: shortContent,
    url: url,
    validFrom: validFrom,
    validTo: validTo,
    highPriority: false,
    markAsRead: false,
    bypassBroker: true,
    accountToAll: false,
    forwardingFromMessageID: null,
  };
  ApiService.MessagesController.MessagesInsert(msg);
};

export const handleOptionalTranslatable = (text: string) => {
  let actualText = text;
  let extraParams: SmartMessageItem[] = [];
  let defaultParams: SmartMessageItem[] = [
    {
      key: "$LOGGED_USER_NAME",
      value: JSON.parse(localStorage.getItem("logged-user") ?? "{}")
        .accountName,
    },
    {
      key: "$LOGGED_USER_SURNAME",
      value: JSON.parse(localStorage.getItem("logged-user") ?? "{}")
        .accountSurname,
    },
  ];

  if (text.includes("unpackJsonMessage")) {
    let asJson = JSON.parse(text);
    actualText = asJson.baseText;
    extraParams = asJson.extraParams;
  }

  if (actualText.startsWith("translate__")) {
    actualText = i18next.t(
      "navigation:" + actualText.replaceAll("translate__", "")
    );
  }

  for (let i = 0; i < extraParams.length; i++) {
    let key = extraParams[i].key;
    let fun = extraParams[i].fun;
    let value = fun
      ? (window as any)[fun](extraParams[i].value)
      : extraParams[i].value;

    actualText = actualText.replaceAll(key, value);
  }

  for (let i = 0; i < defaultParams.length; i++) {
    let key = defaultParams[i].key;
    let fun = defaultParams[i].fun;
    let value = fun
      ? (window as any)[fun](defaultParams[i].value)
      : defaultParams[i].value;
    actualText = actualText.replaceAll(key, value);
  }

  return actualText;
};
