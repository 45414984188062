import { Button, CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import "./../LoginPage/LoginPage.scss";
import { Link as RouterLink } from "react-router-dom";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";

const ResendEmailPage = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [checkValidate, setCheckValidate] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const [done, setDone] = useState<boolean>(false);

  const resetPassword = (email: any) => {
    setProcessing(true);
    ApiService.AccountController.AccountRegistrationResendEmail(
      email,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDone(true);
          ToastMessage(
            i18next.t("navigation:successfullEmailResend"),
            "success"
          );
        } else {
          if (response.raw.status === 404) {
            ToastMessage(
              i18next.t("navigation:successfullEmailResendFail"),
              "error"
            );
          } else {
            ToastMessage(
              i18next.t("error:" + response.payload.detail),
              "error"
            );
          }
        }
        setProcessing(false);
      }
    );
  };

  /* RETURN */
  return (
    <div className="multi-form-main-wrap form-login-page">
      <h1>{i18next.t("navigation:titleResendEmailPage")}</h1>
      {done && (
        <div className="multi-form-subtitle">
          {i18next.t("navigation:successfullEmailResend")}
        </div>
      )}
      {!done && (
        <div>
          <div className="multi-form-subtitle">
            {i18next.t("navigation:subtitleResendEmailPage")}
          </div>
          <MultiForm
            formId="forgotPasswordForm"
            inputs={[
              {
                width: 100,
                name: "email",
                type: "email",
                disabled: processing,
                required: true,
                label: i18next.t("form:email") as string,
              },
              {
                width: 100,
                name: "confirm_email",
                type: "email",
                disabled: processing,
                required: true,
                label: i18next.t("form:emailConfirm") as string,
                errorText:
                  state?.confirm_email === state?.email
                    ? ""
                    : (i18next.t("form:emailUpNotCorrespond") as string),
              },
              {
                width: 50,
                type: "custom",
                name: "",
                element: (
                  <Button
                    sx={{ marginTop: "15px" }}
                    component={RouterLink}
                    to="/login"
                    variant="outlined"
                    fullWidth
                  >
                    {i18next.t("navigation:reject")}
                  </Button>
                ),
              },
              {
                width: 50,
                type: "custom",
                name: "",
                element: (
                  <Button
                    sx={{ marginTop: "15px" }}
                    fullWidth
                    disabled={checkValidate || processing}
                    variant="contained"
                    onClick={() => {
                      triggerFormValidation("forgotPasswordForm");
                    }}
                    endIcon={
                      processing ? (
                        <CircularProgress size={25} />
                      ) : (
                        <ChevronRightIcon />
                      )
                    }
                  >
                    {i18next.t("navigation:accept")}
                  </Button>
                ),
              },
            ]}
            suppressLayout
            suppressSubmit
            onChange={(data: any) => {
              setCheckValidate(data.email !== data.confirm_email);
              setState(data);
            }}
            onSubmit={(data: any) => {
              resetPassword(data.email);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ResendEmailPage;
