/* REACT */
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./TelepassUsages__ServicesPage.scss";

/* COMPONENTS */
import AppRoutes from "../../../../../Costants/AppRoutes";
import SupplyInsertModal from "../../../../../Components/FormInsertSupply/FormInsertSupply";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import { Button } from "@mui/material";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import TableViewIcon from "@mui/icons-material/TableView";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FormInsertTelepassUsage from "../../../../../Components/FormInsertTelepassUsage/FormInsertTelepassUsage";

/* COMPONENT */
const TelepassUsages__ServicesPage = () => {
  /* NAVIGATE */
  const navigate = useNavigate();

  /* RETURN */
  return (
    <div className="forniture-wrapper">
      <HeaderPageDashboard title={i18next.t("navigation:_services_telepass_usages")} />

      <div className="forniture-content">
        <Button
          className="button-forniture"
          onClick={() => navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.VIEW_TELEPASS_USAGE)}
        >
          <div>
            <TableViewIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_telepass_page_view_table")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_telepass_page_view_table_subtitle")}</div>
        </Button>

        <Button
          className="button-forniture"
          onClick={() => {
            SummonModal("add-transit-modal");
          }}
        >
          <div>
            <EditRoadIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_telepass_page_view_insert")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_telepass_page_view_insert_subtitle")}</div>
        </Button>

        <Button
          className="button-forniture"
          onClick={() => {
            navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.LOAD_FILE_TELEPASS_USAGE);
          }}
        >
          <div>
            <UploadFileIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_telepass_page_view_insert_file")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_telepass_page_view_insert_file_subtitle")}</div>
        </Button>
      </div>

      <SmartModal
        modalUniqueId={"add-transit-modal"}
        title={i18next.t("navigation:_telepass_page_add_new_usage")}
        modalInnerComponent={<FormInsertTelepassUsage 
          data={[]}
          idModal="add-transit-modal"
       />}
      />
    </div>
  );
};

export default TelepassUsages__ServicesPage;
