import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, Autocomplete, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import i18next, { use } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStepperTickets } from "../../Models/IStepperTickets";
import { ITicketArticle } from "../../Models/ITicketArticle";
import { ITicketInsertDetail } from "../../Models/ITicketInsertDetail";
import { ITicketInsertDetailResponse } from "../../Models/ITicketInsertDetailResponse";
import { ITicketInsertResponse } from "../../Models/ITicketInsertResponse";
import { ITicketStatus } from "../../Models/ITicketStatus";
import { ITicketUpdateDetail } from "../../Models/ITicketUpdateDetail";
import { IUser } from "../../Models/IUser";
import { IVehicleDataByAccountID } from "../../Models/IVehicleDataByAccountID";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import { GlobalState } from "../../Reducers/RootReducer";
import { TicketsAction } from "../../Reducers/Tickets/TicketsAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import MultiForm, { IMultiFormField, triggerFormValidation } from "../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../SmartModal/SmartModal";
import "./StepBreachData.scss";
import { JSONPrint } from "../../Utils/Decoder";
import { getProPDFData } from "../../Pages/DashboardPage/DashBoard_Pages/AdditionalServicesPage/Tickets__AdditionalServicesPage/Tickets__AdditionalServicesPage";
import { useSearchParams } from "react-router-dom";
import { IAddedService } from "../../Models/IService";
import { IArticleGet } from "../../Models/IArticleGet";
import { IAssignmentGet } from "../../Models/IAssignment";
import { IDriverGet } from "../../Models/IDriver";

export interface IStepBreachData {
  header?: boolean;
}

const StepBreachData = (props: IStepBreachData) => {
  const dispatch = useDispatch();
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [isPointDeduction, setIsPointDeduction] = useState<boolean>(false);
  const [isHeader, setIsHeader] = useState<boolean>(true);
  const [loadingVehicleData, setLoadingVehicleData] = useState<boolean>(true);
  const [loadingLinkableTicket, setLoadingLinkableTicket] = useState<boolean>(true);
  const [refreshForm, setRefreshForm] = useState<boolean>(true);
  const [loadingTicketArticle, setLoadingTicketArticle] = useState<boolean>(true);
  const [vehicleData, setVehicleData] = useState<IVehicleDataByAccountID[]>([]);
  const [ticketArticle, setTicketArticle] = useState<ITicketArticle[]>([]);
  const [linkableTicket, setLinkableTicket] = useState<any[]>([]);
  const [drivers, setDrivers] = useState<IAssignmentGet[]>([]);
  const [loadingChangeArticle, setLoadingChangeArticle] = useState<boolean>(false);
  const [alreadyShowErrorDriver, setAlreadyShowErrorDriver] = useState<boolean>(true);
  const stepperTicket: IStepperTickets | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketsStepper
  );
  const ticketStatus: ITicketStatus | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketStatus
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const ticketInsertMultipleDetailResponse: any[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertMultipleDetailResponse
  );
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );
  const driverTicket: any | undefined = useSelector(
    (state: GlobalState) => state.tickets.driverTicket
  );
  const ticketInsertDetailResponse: ITicketInsertDetailResponse[] | undefined =
    useSelector((state: GlobalState) => state.tickets.ticketInsertDetailResponse);
  const addonService: IAddedService[] = useSelector(
    (state: GlobalState) => state.generic.addons
  );
  const [initVehiclePlateError, setInitVehiclePlateError] = useState<string>("");

  const [processing, setProcessing] = useState<boolean>(false);
  const [descriptionSelected, setDescriptionSelected] = useState<ITicketArticle | any>();
  const [state, setState] = useState<any>({
    article: "",
    comma: "",
    description: "",
  });
  const [ticketRowTable, setTicketRowtable] = useState<ITicketInsertDetail[]>([
    {
      violationDT: "",
      vehicleID: 0,
      driverID: 0,
      amount: 0,
      vehicleLicensePlate: "",
    },
  ]);

  useEffect(() => {
    if (stepperTicket) {
      if (ticketInsertMultipleDetailResponse || stepperTicket?.stepBreach?.length! > 0) {
        setTicketRowtable(stepperTicket?.stepBreach!);
      }
    }

    setTimeout(() => {
      acceptArticleDataFromPro();
    }, 500);
  }, []);

  const InsertTicketDetail = (body: ITicketInsertDetail) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertDetailTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertDetailResponse(response.payload));
          ToastMessage(i18next.t("message:TicketDetailInsertCorrectly"), "success");
          stepperGoToPage(2);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const DeleteTicketDetail = (ticketDeatilID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.DeleteTicketDetail(
        ticketDeatilID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage("ticket detail deleted", "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const InsertMultipleTicketDetail = (body: any) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.InsertMultipleDetailTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              TicketsAction.setTicketInsertMultipleDetailResponse(
                response.payload[0].ticketDetail
              )
            );
            ToastMessage(i18next.t("message:TicketDetailInsertCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateMultipleTicketDetail = (body: any) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.TicketController.UpdateMultipleDetailTicket(
        body,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:TicketDetailUpdateCorrectly"), "success");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const UpdateTicketDetail = (body: ITicketUpdateDetail) => {
    if (loggedUser) {
      setProcessing(true);

      ApiService.TicketController.UpdateDetailTicket(body, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(TicketsAction.setTicketInsertDetailResponse(response.payload));
          ToastMessage(i18next.t("message:TicketDetailUpdateCorrectly"), "success");
          stepperGoToPage(2);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const loadVehicleDataByAccountID = () => {
    setLoadingVehicleData(true);
    ApiService.VehicleDataController.VehicleDataGetByAccountID(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehicleData(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingVehicleData(false);
      }
    );
  };

  const loadLinkableTicket = () => {
    setLoadingLinkableTicket(true);
    ApiService.TicketController.GetLinkableTicket(null, (response: IAPIResponse) => {
      if (response.error === null) {
        setLinkableTicket(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingLinkableTicket(false);
    });
  };

  const loadTicketArticle = () => {
    setLoadingTicketArticle(true);
    ApiService.TicketController.GetTicketArticleList(null, (response: IAPIResponse) => {
      if (response.error === null) {
        dispatch(
          TicketsAction.setStepperTickets({
            ...stepperTicket!,
            articleSanction: undefined,
          })
        );
        if (stepperTicket!.stepBreach) {
          if (stepperTicket!.stepBreach!.length > 0) {
            let tmp = response.payload.filter(
              (x: any) =>
                x.ticketArticleID === stepperTicket!.stepBreach![0].ticketArticleID
            );
            if (tmp.length > 0) {
              setState({
                article: tmp[0].article,
                comma: tmp[0].comma,
                description: tmp[0].ticketArticleDescription,
              });
              dispatch(
                TicketsAction.setStepperTickets({
                  ...stepperTicket!,
                  articleSanction: tmp[0],
                })
              );
            }
          }
        }
        setTicketArticle(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoadingTicketArticle(false);
    });
  };

  const loadCurrentAssignment = (vehicleID: number, dateBreachDataDT: string) => {
    if (loggedUser) {
      ApiService.AssignmentController.AssignmentGetByCarID(
        vehicleID,
        (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp = response.payload.filter((x: IAssignmentGet) => dateBreachDataDT >= x.startDT && dateBreachDataDT <= x.endDT)
          if (tmp.length > 0) {
            setDrivers(tmp);
            if (!driverTicket) {
              dispatch(
                TicketsAction.setTicketDriver({
                  driverID: tmp[0].driverID,
                  driverDisplayName: tmp[0].driverName + " " + tmp[0].driverSurname,
                })
              );
            }
          }else{
            setAlreadyShowErrorDriver(false);
            setDrivers([]);
            dispatch(
              TicketsAction.setTicketDriver({
                driverID: 0,
                driverDisplayName: "",
              })
            );
            setTimeout(() => {
              setAlreadyShowErrorDriver(true);
            }
            , 50);
          }
        } else {
          ToastMessage(response.error, "error");
        }
      });
    }
  };

  useEffect(() => {
    loadVehicleDataByAccountID();
    loadTicketArticle();
    loadLinkableTicket();
    if (props.header === false) {
      setIsHeader(props.header);
    }
  }, []);

  const [params] = useSearchParams();
  const plate: string | null = params.get("plate");
  const vehicleID: string | null = params.get("vehicleID");

  const multiformArticle = () => {
    let multiformInputs: IMultiFormField[] = [];
    multiformInputs.push({
      width: 100,
      type: "select",
      name: "article",
      defaultValue: state.article,
      label: i18next.t("navigation:_tickets_page_article") ?? "",
      upperLabel: i18next.t("navigation:_tickets_page_article") ?? "",
      options: ticketArticle
        .map((x: ITicketArticle) => {
          return x.article;
        })
        .filter((element, index) => {
          return (
            ticketArticle
              .map((x: ITicketArticle) => {
                return x.article;
              })
              .indexOf(element) === index
          );
        })
        .map((x: string) => {
          return {
            key: x,
            text: x,
          };
        }),
      required: true,
    });
    multiformInputs.push({
      width: 100,
      type: "select",
      name: "comma",
      defaultValue: state.comma,
      label: i18next.t("navigation:_tickets_page_paragraph") ?? "",
      upperLabel: i18next.t("navigation:_tickets_page_paragraph") ?? "",
      options: ticketArticle
        .filter((y: ITicketArticle) => y.article === state.article)
        .map((x: ITicketArticle) => {
          return {
            key: x.comma,
            text: x.comma,
          };
        }),
      disabled: state.article === "" || !state.article,
      required: true,
    });
    multiformInputs.push({
      width: 100,
      type: "custom",
      name: "description",
      defaultValue: "",
      label: i18next.t("navigation:_tickets_page_description") ?? "",
      upperLabel: i18next.t("navigation:_tickets_page_description") ?? "",
      element: (
        <div>
          {ticketArticle
            .filter(
              (z: ITicketArticle) =>
                z.article === state.article && z.comma === state.comma
            )
            .map((x: ITicketArticle) => {
              return (
                <div style={{ marginBottom: "1em" }}>
                  <Checkbox
                    required={ descriptionSelected
                      ? descriptionSelected.ticketArticleID <= 0
                      : true}
                    checked={
                      descriptionSelected
                        ? x.ticketArticleID === descriptionSelected.ticketArticleID
                        : false
                    }
                    onChange={(_, checked) => {
                      if (checked) {
                        setDescriptionSelected(x);
                        dispatch(
                          TicketsAction.setStepperTickets({
                            ...stepperTicket!,
                            articleSanction: x,
                          })
                        );
                      } else {
                        setDescriptionSelected(null);
                        dispatch(
                          TicketsAction.setStepperTickets({
                            ...stepperTicket!,
                            articleSanction: undefined,
                          })
                        );
                      }
                    }}
                  />
                  {x.ticketArticleDescription}
                </div>
              );
            })}
        </div>
      ),
      required: true,
    });
    return multiformInputs;
  };

  const getPreloadedVehicleData = () => {
    if (initVehiclePlateError === "") {
      let suggestedPlate = (
        getProPDFData(
          "licensePlate",
          stepperTicket!.stepBreach
            ? stepperTicket!.stepBreach![0]?.vehicleLicensePlate
            : ""
        ) ?? ""
      ).replaceAll(" ", "");
      let match: IVehicleDataByAccountID | undefined = vehicleData.find(
        (x: IVehicleDataByAccountID) => x.vehicleLicensePlate.includes(suggestedPlate)
      );
      if (match) {
        return formatPlate(match.vehicleLicensePlate);
      }
      else if(stepperTicket!.stepBreach && stepperTicket!.stepBreach![0]?.vehicleLicensePlate){
        return stepperTicket!.stepBreach![0]?.vehicleLicensePlate;
      } else {
        setInitVehiclePlateError(suggestedPlate);
        return null;
      }
    }
  };

  /* 
  (x: IVehicleDataByAccountID) =>
        x.vehicleLicensePlate
          .replaceAll(" ", "")
          .includes(plate.replaceAll(" ", "")) 
  */

  const isRegisteredVehicle = (plate: string) => {
    let match: IVehicleDataByAccountID[] = vehicleData.filter(
      (x: IVehicleDataByAccountID) => x.vehicleLicensePlate.includes(plate)
    );
    return match.length > 0;
  };

  const multiformInputsInsurance = () => {
    let multiformInputs: IMultiFormField[] = [];
    if (stepperTicket?.stepSanction?.ticketTypeID === 4) {
      multiformInputs.push({
        width: 25,
        type: "datetime",
        name: "violationDT",
        defaultValue: getProPDFData(
          "ticketDateTimeSelected",
          stepperTicket!.stepBreach ? stepperTicket!.stepBreach![0]?.violationDT : null
        ),
        label: i18next.t("form:DateTimeInfraction") ?? "",
        upperLabel: i18next.t("form:DateTimeInfraction") ?? "",
        required: true,
      });

      if (plate && vehicleID) {
        multiformInputs.push(
          // Plate
          {
            width: 25,
            type: "select",
            name: "vehicleID",
            required: true,
            defaultValue: vehicleID,
            label: `${i18next.t("form:plate")}`,
            disabled: true,
            options: [
              {
                key: vehicleID,
                text: "formatPlate(plate)",
              },
            ],
          }
        );
      } else {
        multiformInputs.push({
          width: 25,
          type: "custom",
          name: "vehicleID",
          placeholder: "(A)",
          label: i18next.t("form:plate") ?? "",
          required: true,
          element: (
            <div>
              <Autocomplete
                freeSolo
                onChange={(data: any) => {
                  console.log(data)
                }}
                options={vehicleData.map((x: IVehicleDataByAccountID) => {
                  return formatPlate(x.vehicleLicensePlate);
                })}
                defaultValue={getPreloadedVehicleData()}
                onInputChange={(_, value: string) => {
                  
                  let tmp: any[] = stepperTicket?.stepBreach ?? [];
                  let tmpEmpty: any = {};
                  if (tmp.length > 0) {
                    if (value) {
                      let tmpVehicleID = vehicleData.find(
                        (x: IVehicleDataByAccountID) =>
                          formatPlate(x.vehicleLicensePlate) === value
                      )?.vehicleID;
                      if (tmpVehicleID) {
                        tmp[0].vehicleID = tmpVehicleID;
                      }
                      tmp[0].vehicleLicensePlate = value;
                    } else {
                      tmp[0].vehicleLicensePlate = null;
                      tmp[0].vehicleID = null;
                      tmp[0].driverID = null;
                    }
                    dispatch(
                      TicketsAction.setStepperTickets({
                        ...stepperTicket!,
                        stepBreach: tmp,
                      })
                    );
                  } else {
                    if (value) {
                      let tmpVehicleID = vehicleData.find(
                        (x: IVehicleDataByAccountID) =>
                          formatPlate(x.vehicleLicensePlate) === value
                      )?.vehicleID;
                      if (tmpVehicleID) {
                        tmpEmpty.vehicleID = tmpVehicleID;
                      }
                      tmpEmpty.vehicleLicensePlate = value;
                    } else {
                      tmpEmpty.vehicleLicensePlate = null;
                      tmpEmpty.vehicleID = null;
                      tmpEmpty.driverID = null;
                    }
                    dispatch(
                      TicketsAction.setStepperTickets({
                        ...stepperTicket!,
                        stepBreach: tmpEmpty,
                      })
                    );
                  }
                }}
                size="small"
                renderInput={(params) => <TextField required {...params} />}
              />
            </div>
          ),
        });
      }
      multiformInputs.push({
        width: 25,
        type: "custom",
        name: "linkedTicketID",
        defaultValue: stepperTicket!.stepBreach
          ? stepperTicket!.stepBreach![0]?.linkedTicketNumber
          : null,
        label: i18next.t("navigation:_tickets_page_payment_original_infraction") ?? "",
        required: true,
        element: (
          <div>
            <Autocomplete
              freeSolo
              options={linkableTicket.map((x: any) => {
                return x.ticketNumber;
              })}
              defaultValue={
                stepperTicket!.stepBreach
                  ? stepperTicket!.stepBreach![0]?.linkedTicketNumber
                  : null
              }
              onInputChange={(_, value: string) => {
                let tmp: any = { ...stepperTicket?.stepBreach };
                if (value) {
                  let tmpLinkedTicketID = linkableTicket.find((x: any) => {
                    return x.ticketNumber === value;
                  })?.linkedTicketID;
                  if (tmpLinkedTicketID) {
                    tmp[0]["linkedTicketID"] = tmpLinkedTicketID;
                  } else {
                    tmp[0]["linkedTicketID"] = null;
                  }
                  tmp[0]["linkedTicketNumber"] = value;
                  dispatch(
                    TicketsAction.setStepperTickets({
                      ...stepperTicket!,
                      stepBreach: tmp,
                    })
                  );
                }
              }}
              size="small"
              renderInput={(params) => <TextField required {...params} />}
            />
          </div>
        ),
      });
      multiformInputs.push({
        width: 25,
        type: "custom",
        name: "ticketArticleID",
        defaultValue: stepperTicket!.stepBreach
          ? stepperTicket!.stepBreach![0]?.ticketArticleID
          : null,
        label: i18next.t("form:ArticleInfraction") ?? "",
        upperLabel: i18next.t("form:ArticleInfraction") ?? "",
        element: (
          <div>
            <Button
              onClick={() => {
                SummonModal("articleModal");
              }}
            >
              <SearchIcon />
            </Button>
            {state.article && state.comma ? state.article + state.comma : ""}
          </div>
        ),
        required: true,
      });

      
    } else {
      multiformInputs.push({
        width: 50,
        type: "datetime",
        name: "violationDT",
        defaultValue: getProPDFData(
          "ticketDateTimeSelected",
          stepperTicket!.stepBreach ? stepperTicket!.stepBreach![0]?.violationDT : null
        ),
        label: i18next.t("form:DateTimeInfraction") ?? "",
        upperLabel: i18next.t("form:DateTimeInfraction") ?? "",
        required: true,
      });

      if (plate && vehicleID) {
        multiformInputs.push(
          // Plate
          {
            width: 33,
            type: "select",
            name: "vehicleID",
            required: true,
            defaultValue: vehicleID,
            label: `${i18next.t("form:plate")}`,
            disabled: true,
            options: [
              {
                key: vehicleID,
                text: formatPlate(plate),
              },
            ],
          }
        );
      } else {
        multiformInputs.push({
          width: 25,
          type: "custom",
          name: "vehicleID",
          placeholder: "(A)",
          label: i18next.t("form:plate") ?? "",
          element: (
            <Autocomplete
              freeSolo
              onChange={(data: any) => {
                console.log(data)
              }}
              options={vehicleData.map((x: IVehicleDataByAccountID) => {
                return formatPlate(x.vehicleLicensePlate);
              })}
              defaultValue={getPreloadedVehicleData()}
              onInputChange={(_, value: string) => {
                let tmpBreach: any[] = stepperTicket?.stepBreach ?? [];
                let tmp: any = {};
                
                if (tmpBreach.length > 0) {
                  if (value) {
                    let tmpVehicleID = vehicleData.find(
                      (x: IVehicleDataByAccountID) =>
                        formatPlate(x.vehicleLicensePlate) === value
                    )?.vehicleID;
                    if (tmpVehicleID) {
                      tmpBreach[0].vehicleID = tmpVehicleID;
                    }
                    tmpBreach[0].vehicleLicensePlate = value;
                  } else {
                    tmpBreach[0].vehicleLicensePlate = null;
                    tmpBreach[0].vehicleID = null;
                    tmpBreach[0].driverID = null;
                  }
                } else {
                  if (value) {
                    let tmpVehicleID = vehicleData.find(
                      (x: IVehicleDataByAccountID) =>
                        formatPlate(x.vehicleLicensePlate) === value
                    )?.vehicleID;
                    if (tmpVehicleID) {
                      tmp.vehicleID = tmpVehicleID;
                    }
                    tmp.vehicleLicensePlate = value;
                  } else {
                    tmp.vehicleLicensePlate = null;
                    tmp.vehicleID = null;
                    tmp.driverID = null;
                  }
                  tmpBreach.push(tmp);
                }
                dispatch(
                  TicketsAction.setStepperTickets({
                    ...stepperTicket!,
                    stepBreach: tmpBreach,
                  })
                );
              }}
              size="small"
              renderInput={(params) => <TextField required {...params} />}
            />
          ),
          required: true,
        });
      }
      
      multiformInputs.push({
        width: 25,
        type: "select",
        required: true,
        name: "driverID",
        defaultValue: stepperTicket && stepperTicket?.stepBreach && stepperTicket.stepBreach.length > 0 ? stepperTicket.stepBreach[0]?.driverID : null,
        label: "Driver",
        tooltip: {
          title: i18next.t("message:DriverTooltip"),
        },
        options: drivers.map((x: IAssignmentGet) => {
          return {
            key: x.driverID,
            text: x.driverDisplayName,
          };
        }),
      });

      if (stepperTicket?.stepSanction?.ticketTypeID === 2) {
        multiformInputs.push({
          width: 30,
          type: "custom",
          name: "ticketArticleDescription",
          label: i18next.t("form:ArticleInfraction") ?? "",
          upperLabel: i18next.t("form:ArticleInfraction") ?? "",
          element: (
            <Autocomplete
              freeSolo
              options={
                [] /* ticketArticle.map((x: ITicketArticle) => {
                return x.article;
              }) */
              }
              defaultValue={
                stepperTicket!.stepBreach
                  ? stepperTicket!.stepBreach![0]?.ticketArticleDescription
                  : ""
              }
              onInputChange={(_, value: string) => {
                let tmpBreach: any[] = stepperTicket?.stepBreach ?? [];
                let tmp: any = {};
                if (tmpBreach.length > 0) {
                  if (value) {
                    tmpBreach[0].ticketArticleDescription = value;
                  }
                } else {
                  if (value) {
                    tmp.ticketArticleDescription = value;
                  }
                  tmpBreach.push(tmp);
                }
                dispatch(
                  TicketsAction.setStepperTickets({
                    ...stepperTicket!,
                    stepBreach: tmpBreach,
                  })
                );
              }}
              size="small"
              renderInput={(params) => <TextField required {...params} />}
            />
          ),
          required: true,
        });
      } else {
        multiformInputs.push({
          width: 30,
          type: "custom",
          name: "ticketArticleID",
          label: i18next.t("form:ArticleInfraction") ?? "",
          upperLabel: i18next.t("form:ArticleInfraction") ?? "",
          element: (
            <div>
              <Button
                onClick={() => {
                  SummonModal("articleModal");
                }}
              >
                <SearchIcon />
              </Button>
              {state.article && state.comma ? state.article + state.comma : ""}
            </div>
          ),
          required: true,
        });
      }
    }
    multiformInputs.push({
      width: 15,
      type: "radio",
      name: "pointsDeduction",
      defaultValue: stepperTicket!.stepBreach
        ? stepperTicket!.stepBreach![0]?.pointsDeduction
          ? 1
          : 0
        : null,
      label: i18next.t("form:PointsDeduction") ?? "",
      options: [
        { key: 1, text: "Si" },
        { key: 0, text: "No" },
      ],
    });
    multiformInputs.push({
      width: 20,
      type: "number",
      name: "pointsDeducted",
      defaultValue: isPointDeduction ? stepperTicket!.stepBreach
        ? stepperTicket!.stepBreach![0]?.pointsDeducted
        : descriptionSelected?.licensePointsDeducted : null,
      inputProps: {
        step: "0.01",
      },
      label: i18next.t("form:PointsDeducted") ?? "",
      disabled: !isPointDeduction,
      required: isPointDeduction,
    });
    multiformInputs.push({
      width: 15,
      type: "radio",
      name: "licenseSuspension",
      defaultValue: stepperTicket!.stepBreach
        ? stepperTicket!.stepBreach![0]?.licenseSuspension
          ? 1
          : 0
        : null,
      label: i18next.t("form:Suspension") ?? "",
      options: [
        { key: 1, text: "Si" },
        { key: 0, text: "No" },
      ],
    });
    multiformInputs.push({
      width: 20,
      type: "number",
      name: "licenseSuspensionPeriod",
      currentValue: isSuspended ? stepperTicket!.stepBreach
        ? stepperTicket!.stepBreach![0]?.licenseSuspensionPeriod
        : descriptionSelected?.licenseSuspensionPeriod  : null,
      label: i18next.t("form:SuspensionPeriod") ?? "",
      upperLabel: i18next.t("form:SuspensionPeriod") ?? "",
      inputProps: {
        step: "0.01",
      },
      disabled: !isSuspended,
      required: isSuspended,
    });
    multiformInputs.push(
      {
        label: "",
        type: "custom",
        width: 100,
        name: "",
        element: (
          <div>
            {descriptionSelected?.ticketArticleID && (
              <p>
                <span><b>{i18next.t("navigation:article_reduced_payment")}</b>: {descriptionSelected?.reducedPayment}</span><br/>
                <span><b>{i18next.t("navigation:article_full_payment")}</b>: {descriptionSelected?.fullPayment}</span><br/>
                <span><b>{i18next.t("navigation:article_points_deducted")}</b>: {descriptionSelected?.licensePointsDeducted}</span><br/>
                <span><b>{i18next.t("navigation:article_license_suspension")}</b>: {descriptionSelected?.licenseSuspensionPeriod}</span><br/>
                <span><b>{i18next.t("navigation:article_license_suspension_time")}</b>: {descriptionSelected?.extraSanction}</span>
              </p>
            )}
          </div>
        )
      }
    );
    return multiformInputs;
  };
  
  const multiformInputsHighways = () => {
    let multiformInputs: IMultiFormField[] = [];
    for (let i = 0; i < ticketRowTable.length; i++) {
      let rowData: ITicketInsertDetail = ticketRowTable[i];
      if (stepperTicket?.stepSanction?.ticketTypeID === 6) {
        multiformInputs.push({
          width: 19,
          type: "custom",
          name: "linkedTicketID_" + i,
          defaultValue: rowData.linkedTicketID,
          label: i18next.t("navigation:_tickets_page_payment_original_infraction") ?? "",
          required: true,
          element: (
            <Autocomplete
              freeSolo
              options={linkableTicket.map((x: any) => {
                return x.ticketNumber;
              })}
              defaultValue={rowData.linkedTicketNumber}
              onInputChange={(_, value: string) => {
                let tmp: any = { ...stepperTicket?.stepBreach };
                if (value) {
                  let tmpLinkedTicketID = linkableTicket.find(
                    (x: any) => x.ticketNumber === value
                  )?.linkedTicketID;
                  if (tmpLinkedTicketID) {
                    tmp[i]["linkedTicketID"] = tmpLinkedTicketID;
                  } else {
                    tmp[i]["linkedTicketID"] = null;
                  }
                  tmp[i]["linkedTicketNumber"] = value;
                }
                dispatch(
                  TicketsAction.setStepperTickets({
                    ...stepperTicket!,
                    stepBreach: tmp,
                  })
                );
              }}
              size="small"
              renderInput={(params) => <TextField required {...params} />}
            />
          ),
        });
        multiformInputs.push({
          width: 19,
          type: "datetime",
          name: "violationDT_" + i,
          defaultValue: rowData.violationDT,
          label: i18next.t("form:DateTimeInfraction") ?? "",
          required: true,
        });
        multiformInputs.push({
          width: 19,
          type: "custom",
          name: "vehicleID_" + i,
          defaultValue: rowData.vehicleID,
          placeholder: "(A)",
          label: i18next.t("form:plate") ?? "",
          required: true,
          element: (
            <div>
              <Autocomplete
                freeSolo
                options={vehicleData.map((x: IVehicleDataByAccountID) => {
                  return formatPlate(x.vehicleLicensePlate);
                })}
                defaultValue={getPreloadedVehicleData()}
                onInputChange={(_, value: string) => {
                  let tmp: any = { ...stepperTicket?.stepBreach };
                  if (value && Object.keys(tmp).length > i) {
                    let tmpVehicleID = vehicleData.find(
                      (x: IVehicleDataByAccountID) =>
                        formatPlate(x.vehicleLicensePlate) === value
                    )?.vehicleID;
                    if (tmpVehicleID) {
                      tmp[i].vehicleID = tmpVehicleID;
                    } else {
                      tmp[i].vehicleID = null;
                    }
                    tmp[i].vehicleLicensePlate = value;
                    if(tmp[i].vehicleID === 0){
                      tmp[i].vehicleID = vehicleData.filter((x: IVehicleDataByAccountID) => formatPlate(x.vehicleLicensePlate) === value)[0].vehicleID;
                    }
                  } else {
                    /* tmp[i].vehicleLicensePlate = null;
                    tmp[i].vehicleID = null;
                    tmp[i].driverID = null; */
                    tmp[i] = {
                      ...tmp[i],
                      ...{
                        vehicleLicensePlate: null,
                        vehicleID: null,
                        driverID: null,
                      },
                    };
                  }
                  
                  dispatch(
                    TicketsAction.setStepperTickets({
                      ...stepperTicket!,
                      stepBreach: tmp,
                    })
                  );
                }}
                size="small"
                renderInput={(params) => <TextField required {...params} />}
              />
            </div>
          ),
        });
        multiformInputs.push({
          width: 19,
          type: "select",
          name: "driverID_" + i,
          defaultValue: rowData.driverID,
          label: "Driver",
          options: drivers.map((x: IAssignmentGet) => {
            return {
              key: x.driverID,
              text: x.driverDisplayName,
            };
          }),
        });
        multiformInputs.push({
          width: 19,
          type: "number",
          name: "amount_" + i,
          defaultValue: rowData.amount,
          label: i18next.t("form:serviceImport") ?? "",
          upperLabel: i18next.t("form:serviceImport") ?? "",
          inputAdornament: { adornament: "€" },
        });
        multiformInputs.push({
          width: 4,
          type: "custom",
          name: "delete_" + i,
          element: (
            <div
              style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                marginTop: "3em",
              }}
            >
              <Button
                className="delete-button"
                variant="contained"
                onClick={() => {
                  let tmpTicketRowTable = [...ticketRowTable].filter(
                    (x: any, j: number) => j !== i
                  );
                  setTicketRowtable(tmpTicketRowTable);
                  DoRefreshForm();
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ),
        });
      } else {
        multiformInputs.push({
          width: 24,
          type: "datetime",
          name: "violationDT_" + i,
          defaultValue: rowData.violationDT,
          label: i18next.t("form:DateTimeInfraction") ?? "",
          required: true,
        });

        if (plate && vehicleID) {
          multiformInputs.push({
            width: 33,
            type: "select",
            name: "vehicleID_" + i,
            required: true,
            defaultValue: +vehicleID,
            label: `${i18next.t("form:plate")}`,
            disabled: true,
            options: [
              {
                key: +vehicleID,
                text: formatPlate(plate),
              },
            ],
          });
        } else {
          multiformInputs.push({
            width: 24,
            type: "custom",
            name: "vehicleID_" + i,
            defaultValue: rowData.vehicleID,
            placeholder: "(A)",
            label: i18next.t("form:plate") ?? "",
            required: true,
            element: (
              <div>
                <Autocomplete
                  freeSolo
                  options={vehicleData.map((x: IVehicleDataByAccountID) => {
                    return formatPlate(x.vehicleLicensePlate);
                  })}
                  defaultValue={getPreloadedVehicleData()}
                  onInputChange={(_, value: string) => {
                    let tmp: any = { ...stepperTicket?.stepBreach };
                    if (value && Object.keys(tmp).length > i) {
                      let tmpVehicleID = vehicleData.find(
                        (x: IVehicleDataByAccountID) =>
                          formatPlate(x.vehicleLicensePlate) === value
                      )?.vehicleID;
                      if (tmpVehicleID) {
                        tmp[i].vehicleID = tmpVehicleID;
                      } else {
                        tmp[i].vehicleID = null;
                      }
                      tmp[i].vehicleLicensePlate = value;
                      if(tmp[i].vehicleID === 0){
                        tmp[i].vehicleID = vehicleData.filter((x: IVehicleDataByAccountID) => formatPlate(x.vehicleLicensePlate) === value)[0].vehicleID;
                      }
                    } else {
                      /* tmp[i].vehicleLicensePlate = null;
                      tmp[i].vehicleID = null;
                      tmp[i].driverID = null; */
                      tmp[i] = {
                        ...tmp[i],
                        ...{
                          vehicleLicensePlate: null,
                          vehicleID: null,
                          driverID: null,
                        },
                      };
                    }
                    dispatch(
                      TicketsAction.setStepperTickets({
                        ...stepperTicket!,
                        stepBreach: tmp,
                      })
                    );
                  }}
                  size="small"
                  renderInput={(params) => <TextField required {...params} />}
                />
              </div>
            ),
          });
        }

        multiformInputs.push({
          width: 24,
          type: "select",
          name: "driverID_" + i,
          defaultValue: rowData.driverID,
          label: "Driver",
          options: drivers.map((x: IAssignmentGet) => {
            return {
              key: x.driverID,
              text: x.driverDisplayName,
            };
          }),
        });
        multiformInputs.push({
          width: 24,
          type: "number",
          name: "amount_" + i,
          defaultValue: rowData.amount,
          label: i18next.t("form:serviceImport") ?? "",
          upperLabel: i18next.t("form:serviceImport") ?? "",
          inputAdornament: { adornament: "€" },
        });
        multiformInputs.push({
          width: 4,
          type: "custom",
          name: "delete_" + i,
          element: (
            <div
              style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                marginTop: "3em",
              }}
            >
              <Button
                className="delete-button"
                variant="contained"
                onClick={() => {
                  let tmpTicketRowTableDelete = [...ticketRowTable].filter(
                    (x: any, j: number) => j === i
                  );
                  if (tmpTicketRowTableDelete.length > 0) {
                    if (tmpTicketRowTableDelete[0].ticketDetailID) {
                      DeleteTicketDetail(tmpTicketRowTableDelete[0].ticketDetailID);
                    }
                  }
                  let tmpTicketRowTable = [...ticketRowTable].filter(
                    (x: any, j: number) => j !== i
                  );
                  setTicketRowtable(tmpTicketRowTable);
                  DoRefreshForm();
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          ),
        });
      }
    }
    return multiformInputs;
  };

  const DoRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 50);
  };

  const FromFormToDataVetor = (data: any) => {
    let keys: string[] = Object.keys(data);
    let newTicketRowTable: any = {};
    for (let i = 0; i < ticketRowTable.length; i++) {
      let item: ITicketInsertDetail = ticketRowTable[i];
      if (!newTicketRowTable[i]) {
        newTicketRowTable[i] = {};
      }
      newTicketRowTable[i] = { ...item };
    }
    for (let i = 0; i < keys.length; i++) {
      let splKey: string[] = keys[i].split("_");
      if (splKey.length === 2) {
        let keyName: string = splKey[0];
        let keyIndex: string = splKey[1];
        if (data[keys[i]] !== null && data[keys[i]] !== undefined) {
          newTicketRowTable[keyIndex][keyName] = data[keys[i]];
        }
      }
    }
    let tmp: ITicketInsertDetail[] = Object.values(newTicketRowTable);
    setTicketRowtable(tmp);
    return tmp;
  };

  const GetTitle = (idTitle: number) => {
    switch (idTitle) {
      case 1:
        return i18next.t("navigation:_tickets_page_step2_header_highway");
      case 2:
      case 3:
      case 4:
      case 6:
        return i18next.t("navigation:_tickets_page_step2_header_general");
      case 5:
        return i18next.t("navigation:_tickets_page_step2_header_parking");
      default:
        return -1;
    }
  };

  const RenderForm = () => {
    return (
      <MultiForm
        inputs={multiformInputsHighways()}
        formTitle={""}
        formId="multiple-breach"
        suppressSubmit
        styles={{
          display: "flex",
          flexFlow: "flow wrap",
          alignItems: "flex-end",
          flexWrap: "wrap",
          width: "100%",
        }}
        onChange={async (data: any) => {
          let tmp = ticketRowTable
          if(tmp) {
            let tmpPlate = getPreloadedVehicleData()
            let tmpVehicleID = vehicleData.find(
              (x: IVehicleDataByAccountID) =>
                formatPlate(x.vehicleLicensePlate) === tmpPlate
            )?.vehicleID;
            for(let i = 0; i < tmp.length; i++){
              if(data["vehicleID_" + i] === 0 && tmpVehicleID && (!data["vehicleLicensePlate_" + i] || data["vehicleLicensePlate_" + i] === "") && tmpPlate){
                data["vehicleID_" + i] = tmpVehicleID
                data["vehicleLicensePlate_" + i] = tmpPlate
              } else {
                data["vehicleID_" + i] = tmp[i].vehicleID
                data["vehicleLicensePlate_" + i] = tmp[i].vehicleLicensePlate
              }
            }
          }
          dispatch(
            TicketsAction.setStepperTickets({
              ...stepperTicket!,
              stepBreach: FromFormToDataVetor(data),
            })
          );
        }}
        onSubmit={(data: any) => {
          if (initVehiclePlateError === data.vehicleLicensePlate) {
            ToastMessage(
              i18next.t("navigation:you_may_not_add_ticket_on_untracked_vehicle"),
              "warning"
            );
            return;
          }
          let tmpData: any[] = [...stepperTicket?.stepBreach!];
          if (ticketInsertMultipleDetailResponse) {
            let tmpBodyInsert: any[] = [];
            let tmpBodyUpdate: any[] = [];
            for (let i = 0; i < stepperTicket!.stepBreach!.length; i++) {
              if (
                i >= tmpData.length - 1 &&
                tmpData.length > ticketInsertMultipleDetailResponse.length
              ) {
                tmpBodyInsert.push({
                  ...tmpData[i],
                  ticketID: ticketInsertResponse![0].ticketID,
                });
              } else {
                tmpBodyUpdate.push({
                  ...tmpData[i],
                  ticketDetailID: ticketInsertMultipleDetailResponse![i].ticketDetailID,
                  ticketID: ticketInsertResponse![0].ticketID,
                });
              }
            }
            if (tmpBodyInsert.length > 0) {
              let tmpBody: any = { ticketDetails: tmpBodyInsert };
              InsertMultipleTicketDetail(tmpBody);
            }
            let tmpBody: any = { ticketDetails: tmpBodyUpdate };
            UpdateMultipleTicketDetail(tmpBody);
          } else {
            let tmp: any[] = [...stepperTicket?.stepBreach!];
            tmp = tmp.map((x: ITicketInsertDetail) => {
              return {
                ...x,
                ticketID: ticketInsertResponse![0].ticketID,
              };
            });
            let tmpBody: any = { ticketDetails: tmp };
            InsertMultipleTicketDetail(tmpBody);
          }
          stepperGoToPage(2);
        }}
      />
    );
  };

  useEffect(() => {
    if (
      stepperTicket &&
      stepperTicket?.stepBreach &&
      stepperTicket?.stepBreach?.length > 0
    ) {
      let data: any = stepperTicket?.stepBreach[0];
      if (data.pointsDeduction == 1) {
        setIsPointDeduction(true);
      } else if (data.pointsDeduction == 0) {
        setIsPointDeduction(false);
      }
      if (data.licenseSuspension == 1) {
        setIsSuspended(true);
      } else if (data.licenseSuspension == 0) {
        setIsSuspended(false);
      }
    }
  }, [stepperTicket]);

  useEffect(() => {
    if (
      stepperTicket &&
      stepperTicket?.stepBreach &&
      stepperTicket?.stepBreach?.length > 0
    ) {
      let data: any = stepperTicket?.stepBreach[0];
      
      if(data.driverID !== undefined && drivers && drivers.length > 0){
        dispatch(
          TicketsAction.setTicketDriver({
            driverID: data.driverID,
            driverDisplayName: drivers.find((x: IAssignmentGet) => x.driverID === +data.driverID)?.driverDisplayName,
          })
        );
      }
    }
  }, [stepperTicket, drivers]);

  useEffect(() => {
    if (
      stepperTicket !== undefined &&
      stepperTicket?.stepBreach &&
      stepperTicket?.stepBreach?.length > 0 && stepperTicket?.stepBreach[0].violationDT && stepperTicket?.stepBreach[0].vehicleID){
        loadCurrentAssignment(stepperTicket?.stepBreach[0].vehicleID, stepperTicket?.stepBreach[0].violationDT);
      }
    }, [stepperTicket]);

  const acceptArticleDataFromPro = () => {
    let articleSelectedByUser: any = getProPDFData("articleSelectedByUser", null);
    if (articleSelectedByUser) {
      let data: any = {
        article: articleSelectedByUser.article,
        comma: articleSelectedByUser.comma,
        description: articleSelectedByUser.ticketArticleDescription,
        ticketArticleID: articleSelectedByUser.ticketArticleID,
      };
      acceptArticleData(data, articleSelectedByUser);
    }
  };

  const acceptArticleData = (data: any, descriptionSelectedMeta = undefined) => {
    let tmp: any = { ...data };
    let tmpBreach: any[] = stepperTicket?.stepBreach ?? [];
    let articleInfo: any = ticketArticle.length > 0 ? ticketArticle.filter((x: IArticleGet) => x.article === state.article && x.comma === state.comma) : 
    descriptionSelectedMeta !== undefined ? [descriptionSelectedMeta] : [];
    if (Object.keys(tmpBreach).length > 0) {
      tmpBreach[0].ticketArticleID = tmp.ticketArticleID;
      tmpBreach[0].licenseSuspension = articleInfo[0].licenseSuspension ? "1" : "0";
      tmpBreach[0].pointsDeduction = articleInfo[0].licensePointsDeduction ? "1" : "0";
      dispatch(
        TicketsAction.setStepperTickets({
          ...stepperTicket!,
          stepBreach: tmpBreach,
        })
      );
    } else {
      tmpBreach.push({ ticketArticleID: tmp.ticketArticleID });
      tmpBreach[0].pointsDeduction = articleInfo[0].licensePointsDeduction ? "1" : "0";
      tmpBreach[0].licenseSuspension = articleInfo[0].licenseSuspension ? "1" : "0";
      dispatch(
        TicketsAction.setStepperTickets({
          ...stepperTicket!,
          stepBreach: tmpBreach,
        })
      );
    }
    if(descriptionSelectedMeta !== undefined){
      setDescriptionSelected(descriptionSelectedMeta);
      dispatch(
        TicketsAction.setStepperTickets({
          ...stepperTicket!,
          articleSanction: descriptionSelectedMeta,
        })
      );
    }
    let desc = descriptionSelectedMeta ? descriptionSelectedMeta : descriptionSelected;

    tmp.ticketArticleID = desc?.ticketArticleID;
    tmp.description = articleInfo.find(
      (x: ITicketArticle) => x.ticketArticleID === desc.ticketArticleID
    )?.ticketArticleDescription;
    setState(tmp);
  };

  useEffect(() => {
    if (descriptionSelected?.ticketArticleID > 0 && state.ticketArticleID !== descriptionSelected?.ticketArticleID) {
      setLoadingChangeArticle(true);
      setTimeout(() => {
        setLoadingChangeArticle(false);
      }, 50);
    }
  }, [state, descriptionSelected?.ticketArticleID]);

  return (
    <>
      <LoaderBackdrop loading={loadingVehicleData || loadingTicketArticle} />
      {!loadingVehicleData && !loadingTicketArticle && (
        <div className="breach-data-wrapper">
          {isHeader && (
            <div className="breach-data-header">
              <strong>{GetTitle(stepperTicket?.stepSanction?.ticketTypeID!)}</strong>
            </div>
          )}
          {initVehiclePlateError !== "" && (
            <Alert severity="warning">
              {i18next.t("navigation:no_valid_plate_ticket_preload")}
              {": "}
              {initVehiclePlateError}
            </Alert>
          )}
          {!loadingChangeArticle && stepperTicket?.stepSanction !== undefined &&
            stepperTicket?.stepSanction?.ticketTypeID !== 1 &&
            stepperTicket?.stepSanction?.ticketTypeID !== 5 &&
            stepperTicket?.stepSanction?.ticketTypeID !== 6 && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MultiForm
                  inputs={multiformInputsInsurance()}
                  formTitle={""}
                  suppressSubmit
                  formId="single-breach"
                  styles={{
                    display: "flex",
                    flexFlow: "flow wrap",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                  onChange={async (data: any) => {
                    let tmpData: any[] = stepperTicket?.stepBreach ?? [];
                    let tmp: any = [];
                    if (tmpData.length > 0) {
                      if (tmpData[0].isEdit) {
                        tmpData[0].pointsDeducted = data.pointsDeducted
                          ? data.pointsDeducted
                          : tmpData[0].pointsDeducted;
                        tmpData[0].pointsDeduction = data.pointsDeduction
                          ? data.pointsDeduction
                          : tmpData[0].pointsDeduction;
                        tmpData[0].licenseSuspension = data.licenseSuspension
                          ? data.licenseSuspension
                          : tmpData[0].licenseSuspension;
                        tmpData[0].licenseSuspensionPeriod = data.licenseSuspensionPeriod
                          ? data.licenseSuspensionPeriod
                          : tmpData[0].licenseSuspensionPeriod;

                        tmpData[0].violationDT = data.violationDT
                          ? data.violationDT
                          : tmpData[0].violationDT;
                        tmpData[0].vehicleID = data.vehicleID
                          ? data.vehicleID
                          : tmpData[0].vehicleID;
                        tmpData[0].vehicleLicensePlate = data.vehicleLicensePlate
                          ? data.vehicleLicensePlate
                          : tmpData[0].vehicleLicensePlate;
                        tmpData[0].driverID = data.driverID
                          ? data.driverID
                          : tmpData[0].driverID;
                        tmpData[0].amount = data.amount ? data.amount : tmpData[0].amount;
                        tmpData[0].ticketDetailID = data.ticketDetailID
                          ? data.ticketDetailID
                          : tmpData[0].ticketDetailID;
                        tmpData[0].ticketArticleID = data.ticketArticleID
                          ? data.ticketArticleID
                          : tmpData[0].ticketArticleID;
                        tmpData[0].isEdit = true;
                        tmp.push(tmpData[0]);
                      } else {
                        data.vehicleID = tmpData[0].vehicleID;
                        data.vehicleLicensePlate = tmpData[0].vehicleLicensePlate;
                        tmp.push(data);
                      }
                      if(data) {
                        let tmpPlate = getPreloadedVehicleData()
                        let tmpVehicleID = vehicleData.find(
                          (x: IVehicleDataByAccountID) =>
                            formatPlate(x.vehicleLicensePlate) === tmpPlate
                        )?.vehicleID;
                          if(!data["vehicleID"] && tmpVehicleID && !data["vehicleLicensePlate"] && tmpPlate){
                            data["vehicleID"] = tmpVehicleID
                            data["vehicleLicensePlate"] = tmpPlate
                          }
                      }
                    } else {
                      data.pointsDeducted = descriptionSelected?.licensePointsDeducted;
                      data.licenseSuspensionPeriod = descriptionSelected?.licenseSuspensionPeriod;
                      tmp.push(data);
                    }
                  
                    dispatch(
                      TicketsAction.setStepperTickets({
                        ...stepperTicket!,
                        stepBreach: tmp,
                      })
                    );
                  }}
                  onSubmit={(data: any) => {
                    if (!isRegisteredVehicle(data.vehicleLicensePlate?.replace(/\s/g, ''))) {
                      ToastMessage(
                        i18next.t(
                          "navigation:you_may_not_add_ticket_on_untracked_vehicle"
                        ),
                        "warning"
                      );
                      return;
                    }
                    
                    let tmp: any = { ...stepperTicket?.stepBreach };
                    let bodyInsert: any = {};

                    if (tmp.length === 0 || (ticketInsertResponse ?? []).length === 0) {
                      ToastMessage(
                        i18next.t("navigation:_all_fields_are_required"),
                        "error"
                      );
                      return;
                    }
                    bodyInsert.ticketArticleDescription = descriptionSelected ? descriptionSelected?.ticketArticleDescription : "";
                    bodyInsert.vehicleLicensePlate = data.vehicleLicensePlate;
                    bodyInsert.violationDT = data.violationDT;
                    bodyInsert.vehicleID = data.vehicleID !== 0 ? data.vehicleID : null;
                    bodyInsert.ticketID = ticketInsertResponse![0].ticketID;
                    bodyInsert.driverID = data?.driverID;
                    bodyInsert.linkedTicketID = data.linkedTicketID;
                    bodyInsert.linkedTicketNumber = data.linkedTicketNumber;
                    bodyInsert.ticketArticleID = state.ticketArticleID;

                    bodyInsert.pointsDeduction = data.pointsDeduction;
                    bodyInsert.pointsDeducted = +data.pointsDeducted;
                    bodyInsert.licenseSuspension = data.licenseSuspension;
                    bodyInsert.licenseSuspensionPeriod = +data.licenseSuspensionPeriod;
                    bodyInsert.pointsDeduction = bodyInsert.pointsDeduction == 1;
                    bodyInsert.licenseSuspension = bodyInsert.licenseSuspension == 1;

                    if (ticketInsertDetailResponse) {
                      bodyInsert.ticketDetailID =
                        ticketInsertDetailResponse[0].ticketDetailID;
                      UpdateTicketDetail(bodyInsert);
                    } else {
                      InsertTicketDetail(bodyInsert);
                    }
                  }}
                />
              </div>
            )}
          {stepperTicket?.stepSanction !== undefined &&
            (stepperTicket?.stepSanction?.ticketTypeID === 1 ||
              stepperTicket?.stepSanction?.ticketTypeID === 5 ||
              stepperTicket?.stepSanction?.ticketTypeID === 6) && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {refreshForm && RenderForm()}
                {!refreshForm && RenderForm()}
              </div>
            )}
          {stepperTicket?.stepSanction !== undefined &&
            (stepperTicket?.stepSanction?.ticketTypeID === 1 ||
              stepperTicket?.stepSanction?.ticketTypeID === 5 ||
              stepperTicket?.stepSanction?.ticketTypeID === 6) && (
              <>
                <div
                  className="add-content"
                  style={{
                    marginBottom: "2em",
                  }}
                >
                  <div className="add-left">
                    <Button
                      variant="contained"
                      sx={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        marginRight: "1em",
                      }}
                      onClick={() => {
                        let tmpTicketRowTable = [...ticketRowTable];
                        tmpTicketRowTable.push({
                          violationDT: "",
                          vehicleID: 0,
                          driverID: 0,
                          amount: 0,
                        });
                        setTicketRowtable(tmpTicketRowTable);
                        DoRefreshForm();
                      }}
                    >
                      <AddIcon />
                    </Button>
                    <div>
                      {i18next.t("navigation:add")}
                      <strong style={{ color: "#009fe3" }}>
                        {" " + i18next.t("navigation:row_detail")}
                      </strong>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                      width: "80%",
                    }}
                  ></div>
                </div>
              </>
            )}
          {stepperTicket?.stepSanction!.ticketTypeID !== 0 && (
            <div className="checkbox-wrapper">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={
                  ticketStatus
                    ? ticketStatus.alreadyPaid
                      ? "alreadyPaid"
                      : ticketStatus?.alreadyDisputed
                      ? "alreadyDisputed"
                      : ""
                    : ""
                }
                onChange={(_, checked) => {
                  let tmp: any = { ...ticketStatus };
                  tmp.alreadyPaid = checked === "alreadyPaid";
                  tmp.alreadyDisputed = checked === "alreadyDisputed";
                  dispatch(TicketsAction.setTicketStatus(tmp));
                }}
              >
                <FormControlLabel
                  value="alreadyPaid"
                  control={<Radio />}
                  label={<>{i18next.t("navigation:ticket")}
                  <strong style={{ color: "#009fe3" }}>
                    {" " + i18next.t("navigation:alreadyPaid")}
                  </strong></>}
                />
                <FormControlLabel
                  value="alreadyDisputed"
                  control={<Radio />}
                  label={<>{i18next.t("navigation:ticket")}
                  <strong style={{ color: "#009fe3" }}>
                    {" " + i18next.t("navigation:alreadyDisputed")}
                  </strong></>}
                />
              </RadioGroup>
            </div>
          )}
          <SmartModal
            modalUniqueId={"articleModal"}
            title={i18next.t("navigation:_tickets_page_choose_article")}
            modalInnerComponent={
              <div>
                <MultiForm
                  suppressLayout
                  formId="article-modal-step-breach-data"
                  inputs={multiformArticle()}
                  styles={{ minWidth: "1200px" }}
                  onChange={async (data: any) => {
                    setState({ ...state, ...data });
                  }}
                  onSubmit={() => {
                    if(descriptionSelected){
                      acceptArticleData(state);
                      DismissModal("articleModal");
                    }
                  }}  
                  suppressSubmit
                />
              </div>
            }
            onAccept={() => {
              triggerFormValidation("article-modal-step-breach-data");
            }}
            onReject={() => {
              DismissModal("articleModal");
            }}
          />
        </div>
      )}
    </>
  );
};

export default StepBreachData;
