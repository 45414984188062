/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "./DealerPageProposal.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* SERVICE */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_CarConfigurator_Vehicle,
  IGET_Car_Colors,
  IINSERT_PROPOSAL_PROMPT_DELIVERY,
} from "../../../../../../Models/ICarConfigurator";
import {
  IVehicleDataBrands,
  IVehicleDataModels,
  IVehicleDataFitting,
} from "../../../../../../Models/IVehicles";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import AttachementMainHandler from "../../../../../../Components/AttachmentMainHandler/AttachmentMainHandler";
import UploadForm from "../../../../../../Components/UploadForm/UploadForm";
import {
  getCompressedBase64,
  getCompressedFileName,
} from "../../../../../../Utils/FileToBase";

/* MUI */
import { Alert, Button, Typography } from "@mui/material";

/* COMPONENT */
const DealerPageProposal = () => {
  /* STATES */
  // Vehicle's data
  const [list, setList] = useState<IGET_CarConfigurator_Vehicle[]>([]);
  // Form data
  const [formData, setFormData] = useState<any>([]);
  // States for veichles informations
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);
  // Car colors
  const [outsideColors, setOutsideColors] = useState<IGET_Car_Colors[]>([]);
  const [insideColors, setInsideColors] = useState<IGET_Car_Colors[]>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingOutsideColors, setLoadingOutsideColors] = useState<boolean>(false);
  const [loadingInsideColors, setLoadingInsideColors] = useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* NAVIGATE */
  const navigate = useNavigate();

  /* URL PARAMS */
  const [params] = useSearchParams();
  const requestID: string | null = params.get("request");
  const brandCode: string | null = params.get("brand");
  const modelCode: string | null = params.get("model");

  /* API */
  // Get vehicle info
  const GET__CARCONFIGURATOR_VEHICLES = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET__CARCONFIGURATOR_VEHICLES(
      requestID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  // API to load brands list
  const loadBrandsList = (inProduction: number) => {
    setLoadingBrandsList(true);

    const inProductionValue =
      +inProduction === 1 || inProduction === undefined ? true : false;

    ApiService.VehicleDataController.VehicleDataGetBrands(
      inProductionValue,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBrandsList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingBrandsList(false);
      }
    );
  };

  // API to load model list
  const loadModelsList = (brandCode: string, inProduction: number) => {
    if (brandCode) {
      setLoadingModelsList(true);

      const inProductionValue =
        +inProduction === 1 || inProduction === undefined ? true : false;

      ApiService.VehicleDataController.VehicleDataGetModels(
        brandCode,
        inProductionValue,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setModelsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }

          setLoadingModelsList(false);
        }
      );
    }
  };

  // API to load fitting list
  const loadFittingList = (
    brandCode: string,
    modelCode: string,
    inProduction: number
  ) => {
    if (brandCode && modelCode) {
      setLoadingFittingsList(true);

      const inProductionValue =
        +inProduction === 1 || inProduction === undefined ? true : false;

      ApiService.VehicleDataController.VehicleDataGetFittings(
        brandCode,
        modelCode,
        inProductionValue,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFittingsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }

          setLoadingFittingsList(false);
        }
      );
    }
  };

  // API to load outside colors
  const loadOutsideColors = (
    fittingCode: string,
    vehiclesRequestPromptDeliveryID: number
  ) => {
    if (fittingCode) {
      setLoadingOutsideColors(true);

      ApiService.CarConfiguratorController.GET_Car_Colors(
        fittingCode,
        vehiclesRequestPromptDeliveryID,
        true,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOutsideColors(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingOutsideColors(false);
        }
      );
    }
  };

  // API to load inside colors
  const loadInsideColors = (
    fittingCode: string,
    vehiclesRequestPromptDeliveryID: number
  ) => {
    if (fittingCode) {
      setLoadingInsideColors(true);

      ApiService.CarConfiguratorController.GET_Car_Colors(
        fittingCode,
        vehiclesRequestPromptDeliveryID,
        false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setInsideColors(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsideColors(false);
        }
      );
    }
  };

  // Save the vehicle summary
  const INSERT_PROPOSAL_PROMPT_DELIVERY = (data: IINSERT_PROPOSAL_PROMPT_DELIVERY) => {
    setLoadingInsert(true);

    ApiService.CarConfiguratorController.INSERT_PROPOSAL_PROMPT_DELIVERY(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:success"), "success");

          navigate(-1);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsert(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    if (requestID) {
      GET__CARCONFIGURATOR_VEHICLES();
    }
  }, []);

  // Load brand and model
  useEffect(() => {
    loadBrandsList(formData?.inProduction);

    if (brandCode) {
      loadModelsList(brandCode, formData?.inProduction);
    }

    if (brandCode && modelCode) {
      loadFittingList(brandCode, modelCode, formData?.inProduction);
    }
  }, [formData?.inProduction]);

  // Load fitting
  useEffect(() => {
    if (formData?.fittingCode && requestID) {
      loadOutsideColors(formData?.fittingCode, +requestID);
      loadInsideColors(formData?.fittingCode, +requestID);
    }
  }, [formData?.fittingCode]);

  /* FUNCTIONS */
  // Upload photo
  const updateFile = (files: File[]) => {
    getCompressedBase64(
      files.length > 0 ? files[0] : null,
      (result: string | null) => {
        let oldState = { ...formData };
        oldState["vehicleImagesURL"] = result;
        setFormData(oldState);
      },
      { height: 220, width: 340 }
    );
  };

  /* MULTIFORM */
  const multiFormInputs: IMultiFormField[] = [
    // Title
    {
      type: "custom",
      name: "title",
      width: 100,
      element: (
        <div>
          <Typography variant="h5">{i18next.t("navigation:sendYourProposal")}</Typography>

          <Typography variant="body2">
            {i18next.t("navigation:sendYourProposalText")}
          </Typography>
        </div>
      ),
    },
    {
      type: "radio",
      name: "vehicleStatus",
      width: 100,
      required: true,
      defaultValue: formData?.vehicleStatus || 1,
      options: [
        {
          key: 1,
          text: i18next.t("navigation:perfectVehicle"),
        },
        {
          key: 0,
          text: i18next.t("navigation:notPerfectVehicle"),
        },
      ],
    },
    {
      width: 50,
      type: "select",
      name: "vehicleRegistered",
      defaultValue: formData?.vehicleRegistered,
      label: `${i18next.t("navigation:registredVehicle")}`,
      required: true,
      options: [
        { key: 1, text: i18next.t("message:yes") },
        { key: 0, text: i18next.t("message:no") },
      ],
    },
    {
      width: 50,
      type: "number",
      name: "vehicleCost",
      defaultValue: formData.vehicleCost,
      label: `${i18next.t("form:fuelSupplyCost")}`,
      required: true,
      inputAdornament: { adornament: "€" },
    },
    {
      width: 50,
      type: "number",
      name: "vehicleAvailability",
      defaultValue: formData.driverCostCenterPercentage,
      label: `${i18next.t("navigation:vehicleAvailability")}`,
      required: true,
      inputAdornament: { adornament: "gg" },
    },
  ];

  // Vehicle avilability type
  if (+formData?.vehicleStatus === 1 && formData?.vehicleRegistered === 1) {
    multiFormInputs.push({
      width: 50,
      type: "select",
      name: "vehicleAvailabilityType",
      defaultValue: formData?.vehicleAvailabilityType,
      label: `${i18next.t("navigation:vehicleAvailabilityType")}`,
      required: true,
      options: [
        { key: 1, text: i18next.t("navigation:vehicleAvailabilityReady") },
        { key: 0, text: i18next.t("navigation:vehicleAvailabilityInOrder") },
      ],
    });
  }

  // Inputs based on vehicle avilability
  if (+formData?.vehicleStatus === 0) {
    multiFormInputs.push(
      // In production
      {
        type: "radio",
        name: "inProduction",
        label: `${i18next.t("form:changeHistoricallyVehicle")}`,
        width: 50,
        defaultValue: formData?.inProduction || 1,
        options: [
          {
            key: 1,
            text: i18next.t("form:vehicleInProdution"),
          },
          {
            key: 0,
            text: i18next.t("form:vehicleNotMoreInProdution"),
          },
        ],
      },
      // Brand
      {
        width: 33.33,
        type: "select",
        name: "brandCode",
        disabled: true,
        defaultValue: brandCode,
        required: true,
        label: `${i18next.t("form:orderBrand")}`,
        options: brandsList.map((item: IVehicleDataBrands) => {
          return {
            key: item.brandCode,
            text: item.brandDescription,
          };
        }),
      },
      // Model
      {
        width: 33.33,
        type: "select",
        name: "modelCode",
        disabled: true,
        defaultValue: modelCode,
        required: true,
        label: `${i18next.t("form:orderModel")}`,
        options: modelList.map((item: IVehicleDataModels) => {
          return {
            key: item.modelCode,
            text: item.modelDescription,
          };
        }),
      },
      // Fitting
      {
        width: 33.33,
        type: "select",
        name: "fittingCode",
        defaultValue: formData?.fittingCode,
        required: true,
        label: `${i18next.t("form:orderEquipment")}`,
        options: fittingList.map((item: IVehicleDataFitting) => {
          return {
            key: item.fittingCode,
            text: item.fittingDescription,
          };
        }),
      },
      // Outside colors
      {
        width: 50,
        type: "select",
        name: "outsideColors",
        disabled: formData?.fittingCode ? false : true,
        defaultValue: formData?.outsideColors,
        required: outsideColors.length > 0 ? true : false,
        label: `${i18next.t("navigation:outsideColor")}`,
        options: outsideColors.map((item: any) => {
          return {
            key: item.code,
            text: item.description,
          };
        }),
      },
      // Inside colors
      {
        width: 50,
        type: "select",
        name: "insideColors",
        disabled: formData?.fittingCode ? false : true,
        defaultValue: formData?.insideColors,
        required: insideColors.length > 0 ? true : false,
        label: `${i18next.t("navigation:insideColor")}`,
        options: insideColors.map((item: any) => {
          return {
            key: item.code,
            text: item.description,
          };
        }),
      },
      // Optionals
      {
        width: 100,
        type: "multiline",
        name: "optionals",
        className: "container-add-textarea",
        multilineRows: 5,
        defaultValue: formData?.optionals,
        label: `${i18next.t("navigation:optionals")}`,
      }
    );
  }

  // Upload photos
  multiFormInputs.push({
    width: 100,
    type: "custom",
    name: "vehicleImagesURL",
    required: true,
    label: `${i18next.t("form:uploadPhotos")}`,
    element: (
      <UploadForm
        name={"vehicleImagesURL"}
        accept={"image/*"}
        onFileChanged={updateFile}
        title={i18next.t("form:uploadPhotos")}
        description={i18next.t("form:descriptionImageUpload")}
        initialFiles={
          formData && formData.vehicleImagesURL
            ? [getCompressedFileName(formData.vehicleImagesURL)]
            : []
        }
      />
    ),
  });

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("navigation:_configure_vechicles_prompt_delivery_request")}
        subtitle={i18next.t("navigation:requiredVehicle")}
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___DEALER_REQUEST_RECEIVED
          )
        }
      />

      {!loadingBrandsList && !loadingModelsList && (
        <div className="dashboardForm__box">
          {requestID && list && !(Object.keys(list).length > 0) ? (
            <div>
              <div className="VehicleConfigurationPage__Summary-box">
                <MultiForm
                  suppressLayout
                  suppressSubmit
                  formId={"VehicleConfigurationDealer"}
                  inputs={multiFormInputs}
                  onChange={(data: any) => {
                    setFormData({ ...formData, ...data });
                  }}
                  onSubmit={() => {
                    // Clone
                    const tempData = lodash.cloneDeep(formData);

                    delete tempData["title"];

                    if (requestID) {
                      tempData["vehiclesRequestPromptDeliveryID"] = +requestID;
                    }

                    // Se conforme o meno
                    tempData["vehicleStatus"] =
                      tempData["vehicleStatus"] == 1 ? true : false;

                    // Se immatricolato o meno
                    tempData["vehicleRegistered"] =
                      tempData["vehicleRegistered"] == 1 ? true : false;

                    tempData["vehicleCost"] = +tempData["vehicleCost"];

                    tempData["vehicleAvailability"] = +tempData["vehicleAvailability"];

                    if (tempData["vehicleStatus"] === true) {
                      if (tempData["vehicleRegistered"] === true) {
                        tempData["vehicleAvailabilityType"] =
                          tempData["vehicleAvailabilityType"] == 1 ? true : false;
                      }

                      tempData["brandCode"] = brandCode;
                      tempData["modelCode"] = modelCode;

                      delete tempData["brandCode"];
                      delete tempData["modelCode"];
                      delete tempData["fittingCode"];
                      delete tempData["insideColors"];
                      delete tempData["outsideColors"];
                      delete tempData["optionals"];
                      delete tempData["inProduction"];
                    }

                    if (tempData["vehicleStatus"] === false) {
                      tempData["inProduction"] =
                        +tempData["inProduction"] === 1 ? true : false;

                      delete tempData["vehicleAvailabilityType"];
                    }

                    // Save
                    INSERT_PROPOSAL_PROMPT_DELIVERY(tempData);
                  }}
                />

                <div className="padding-extrasmall">
                  <AttachementMainHandler
                    attachmentFamily={"dealerproposal"}
                    extraGetParametersVector={
                      requestID
                        ? [
                            {
                              name: "vehiclesRequestPromptDeliveryID",
                              value: +requestID,
                            },
                          ]
                        : undefined
                    }
                  />
                </div>

                <div className="display-flex-end padding-y-small padding-right-small">
                  <Button
                    disabled={formData?.vehicleImagesURL ? false : true}
                    variant="contained"
                    onClick={() => {
                      triggerFormValidation("VehicleConfigurationDealer");
                    }}
                  >
                    {i18next.t("navigation:sendProposal")}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
          )}
        </div>
      )}

      <LoaderBackdrop
        loading={
          loadingGet ||
          loadingBrandsList ||
          loadingModelsList ||
          loadingFittingsList ||
          loadingInsert ||
          loadingInsideColors ||
          loadingOutsideColors
        }
      />
    </div>
  );
};

export default DealerPageProposal;
