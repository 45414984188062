import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IArticleGet } from "../../Models/IArticleGet";
import { ITicketInsert } from "../../Models/ITicketInsert";
import { ITicketInsertDetail } from "../../Models/ITicketInsertDetail";
import { ITicketInsertMultipleDetail } from "../../Models/ITicketInsertMultipleDetail";
import { ITicketInsertPayment } from "../../Models/ITicketInsertPayment";
import { ITicketIssuingInstitutionGet } from "../../Models/ITicketIssuingInstitutionGet";
import { ITicketUpdate } from "../../Models/ITicketUpdate";
import { ITicketUpdateDetail } from "../../Models/ITicketUpdateDetail";
import { ITicketUpdatePayment } from "../../Models/ITicketUpdatePayment";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";
const TicketController = {
  TicketAddProPDF: async (
    ticketTypeID: number,
    languageID: number,
    filePdf: File,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_PDF_PRO;
    request.method = "post";

    var formData = new FormData();
    formData.append("ticket", filePdf);
    request.port = "7237";
    request.payload = formData;
    request.dontStringify = true;

    request.url +=
      "?ticketTypeID=" + ticketTypeID + "&languageID=" + languageID;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketStatus: async (
    ticketStatusID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_GET_STATUS;
    request.url += "?ticketStatusID=" + ticketStatusID;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketList: async (
    ticketID?: number,
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_GET_LIST;
    let params: string[] = [];
    if (ticketID) {
      params.push("ticketID=" + ticketID);
    }
    if (vehicleID) {
      params.push("vehicleID=" + vehicleID);
    }
    if (ticketID || vehicleID) {
      request.url += "?" + params.join("&");
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketTypeList: async (
    ticketTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_TYPE_GET_LIST;
    request.port = "7237";
    if (ticketTypeID) {
      request.url += "?ticketTypeID=" + ticketTypeID;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketIssuingInstitutionList: async (
    ticketIssuingInstitutionID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ISSUING_INSTITUTION_GET_LIST;
    request.port = "7237";
    if (ticketIssuingInstitutionID) {
      request.url +=
        "?ticketIssuingInstitutionID=" + ticketIssuingInstitutionID;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertTicketIssuingInstitutionList: async (
    data: ITicketIssuingInstitutionGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ISSUING_INSTITUTION_INSERT;
    request.method = "POST";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = data;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateTicketIssuingInstitutionList: async (
    data: ITicketIssuingInstitutionGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ISSUING_INSTITUTION_UPDATE;
    request.method = "PUT";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = data;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteTicketIssuingInstitutionList: async (
    ticketIssuingInstitutionID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ISSUING_INSTITUTION_DELETE;
    request.method = "DELETE";
    request.port = "7237";
    request.payload = {
      ticketIssuingInstitutionID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  GetTicketArticleList: async (
    ticketArticleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_ARTICLE_GET_LIST;
    if (ticketArticleID) {
      request.url += "?ticketArticleID=" + ticketArticleID;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertTicketArticle: async (
    data: IArticleGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ARTICLE_INSERT;
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = data;
    request.method = "POST";
    request.port = "7237";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateTicketArticle: async (
    data: IArticleGet,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ARTICLE_UPDATE;
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = data;
    request.method = "PUT";
    request.port = "7237";

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteTicketArticle: async (
    ticketArticleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_ARTICLE_DELETE;
    request.extraHeaders = { "Content-Type": "application/json" };
    request.port = "7237";
    request.payload = {
      ticketArticleID,
    };
    request.method = "DELETE";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  GetLinkableTicket: async (
    vehicleID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_LINKABLE_TICKET_GET;
    if (vehicleID) {
      request.url += "?vehicleID=" + vehicleID;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketDeliveryTypeList: async (
    ticketDeliveryTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_DELIVERY_TYPE_GET_LIST;
    request.port = "7237";
    if (ticketDeliveryTypeID) {
      request.url += "?ticketDeliveryTypeID=" + ticketDeliveryTypeID;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertTicket: async (
    body: ITicketInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.TICKETS_INSERT_TICKET;
    request.payload = body;
    request.port = "7237";
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateTicket: async (
    body: ITicketUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_UPDATE_TICKET;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteTicket: async (
    ticketID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_DELETE_TICKET;
    request.method = "DELETE";
    request.payload = {
      ticketID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertDetailTicket: async (
    body: ITicketInsertDetail,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_INSERT_DETAIL_TICKET;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateDetailTicket: async (
    body: ITicketUpdateDetail,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_UPDATE_DETAIL_TICKET;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteTicketDetail: async (
    ticketDetailID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_DELETE_TICKET_DETAIL;
    request.method = "DELETE";
    request.payload = {
      ticketDetailID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertTicketPayment: async (
    body: ITicketInsertPayment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_INSERT_TICKET_PAYMENT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateTicketPayment: async (
    body: ITicketUpdatePayment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_UPDATE_TICKET_PAYMENT;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetTicketPaymentList: async (
    ticketID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_GET_PAYMENT_LIST;
    if (ticketID) {
      request.url += "?ticketID=" + ticketID;
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteTicketPayment: async (
    ticketPaymentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_DELETE_PAYMENT;
    request.method = "DELETE";
    request.payload = {
      ticketPaymentID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertMultipleDetailTicket: async (
    body: ITicketInsertMultipleDetail[],
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_INSERT_MULTIPLE_DETAIL_TICKET;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateMultipleDetailTicket: async (
    body: ITicketInsertMultipleDetail[],
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.TICKETS_UPDATE_MULTIPLE_DETAIL_TICKET;
    request.payload = body;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateTicketDriver: async (
    ticketDetailID: number,
    driverID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = `${ApiEndPoints.TICKETS_UPDATE_DRIVER}`;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    request.payload = {
      ticketDetailID,
      driverID,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetViolationType: async (
    violationTypeID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = `${ApiEndPoints.TICKETS_GET_VIOLATION_TYPE}?violationTypeID=${violationTypeID}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  SendTicketMailToDriver: async (
    body: {ticketID: number},
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.SEND_TICKET_MAIL_TO_DRIVER;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TicketController;
