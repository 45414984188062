import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IInsuranceAttachmentInsert,
  IInsuranceAttachmentUpdate,
  IInsuranceInsert,
  IInsuranceUpdate,
} from "../../Models/IInsurance";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const InsuranceController = {
  InsuranceGetDetailType: async (
    insuranceDetailTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_GET_TYPE;
    if (insuranceDetailTypeID) {
      request.url += `?insuranceDetailTypeID=${insuranceDetailTypeID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceDeleteDetail: async (
    insuranceDetailID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_DELETE_TYPE;
    request.payload = {
      insuranceDetailID,
    };
    request.method = "DELETE";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceGet: async (
    insuranceID: number,
    vehicleID: number,
    companyID: number,
    stored?: number | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_GET;
    let parameters: string[] = [];
    if (insuranceID) {
      parameters.push(`insuranceID=${insuranceID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceInsert: async (
    body: IInsuranceInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_INSERT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceUpdate: async (
    body: IInsuranceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceDelete: async (
    insuranceID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_DELETE;
    request.method = "DELETE";
    request.payload = {
      insuranceID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceAttachmentInsert: async (
    body: IInsuranceAttachmentInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_INSERT_ATTACHMENT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceAttachmentUpdate: async (
    body: IInsuranceAttachmentUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_UPDATE_ATTACHMENT;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsuranceAttachmentDelete: async (
    insuranceAttachmentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_INSURANCE_DELETE_ATTACHMENT;
    request.method = "DELETE";
    request.payload = {
      insuranceAttachmentID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default InsuranceController;
