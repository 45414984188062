import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  ITelepassUsageInsert,
  ITelepassUsageUpdate,
} from "../../Models/ITelepassUsage";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const TelepassUsageController = {
  TelepassUsageGet: async (
    vehicleID: number,
    telepassUsageID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_USAGE_GET;
    let parameters: string[] = [];
    if (telepassUsageID) {
      parameters.push(`telepassUsageID=${telepassUsageID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassUsageTypeGet: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_USAGE_TYPE_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassClassGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_CLASS_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassUsageInsert: async (
    body: ITelepassUsageInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_USAGE_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassUsageUpdate: async (
    body: ITelepassUsageUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_USAGE_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassUsageDelete: async (
    telepassUsageID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_USAGE_DELETE;
    request.method = "DELETE";
    request.payload = {
      telepassUsageID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TelepassUsageController;
