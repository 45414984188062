import { BrandsActionType, SET_BRANDS } from "./BrandsAction";
import { BrandsState } from "./BrandsState";

const DEFAULT_STATE: BrandsState = { 
  vehicleBrands: [],
};

export const brandsReducer = (
  state: BrandsState = DEFAULT_STATE,
  action: BrandsActionType
): BrandsState => {
  switch (action.type) {
    case SET_BRANDS:
      return {
        ...state,
        vehicleBrands: action.payload,
      };
    default:
      return state;
  }
};
