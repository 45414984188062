import { Delete } from "@mui/icons-material";
import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  IVehicleKilometersEntriesChangeStatus,
  IVehicleKilometersEntriesInsert,
} from "../../Models/IVehicles";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleKilometersEntriesController = {
  GetVehicleKilometersLimit: async (
    vehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url =
      ApiEndPoints.VEHICLE_GET_KM_VEHICLE_CURR_LEVEL +
      "?vehicleID=" +
      vehicleID;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetVehicleKilometersEntries: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_GET_KM_ENTRIES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertVehicleKilometersEntries: async (
    entry: IVehicleKilometersEntriesInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.payload = entry;
    request.method = "POST";
    request.url = ApiEndPoints.VEHICLE_INSERT_KM_ENTRIES;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ChangeStatusVehicleKilometersEntries: async (
    cangeStatus: IVehicleKilometersEntriesChangeStatus,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.payload = cangeStatus;
    request.method = "POST";
    request.url = ApiEndPoints.VEHICLE_CHANGE_STATUS_KM_ENTRIES;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteVehicleKilometersEntries: async (
    kmDetectionID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_DELETE_KM_ENTRIES;
    request.method = "DELETE";
    request.payload = { 
      kmDetectionID 
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  }
};

export default VehicleKilometersEntriesController;
