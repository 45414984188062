/* REACT */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

/* STYLE */
import "../VehicleGroupPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import CloseGroupGrid from "./CloseGroupContent";

/* COMPONENT */
const CloseGroupPage = () => {
  /* STATES */
  const [payload, setPayload] = useState<any>();
  // Loading
  const [loadingCloseGroup, setLoadingCloseGroup] = useState<boolean>(false);

  /* URL PARAMS */
  const [params] = useSearchParams();
  const groupID = params.get("groupID") ? +params.get("groupID")! : null;

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get close group
  const GET_CarConfiguratorGroup_Close = () => {
    setLoadingCloseGroup(true);

    ApiService.CarConfiguratorController.GET_CarConfiguratorGroup_Close(
      groupID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPayload(response.payload[0]);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingCloseGroup(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    groupID && GET_CarConfiguratorGroup_Close();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        arrowBack
        title={i18next.t("navigation:_configure_vechicles_group")}
        subtitle={i18next.t("generic:closeList")}
        subtitlePath={AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP}
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP
          )
        }
      />

      <div className="dashboardForm__box">
        {!loadingCloseGroup && (
          <CloseGroupGrid
            data={payload || []}
            groupID={groupID}
            onSubmit={() => GET_CarConfiguratorGroup_Close()}
          />
        )}
      </div>

      <LoaderBackdrop loading={loadingCloseGroup} />
    </div>
  );
};

export default CloseGroupPage;
