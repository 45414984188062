/* REACT */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* LIBRARY */
import i18next from "i18next";

/* MODALS */
import { IUser } from "../../../../../../Models/IUser";

/* STYLE */
import "./ViewTelepassUsagePage.scss";

/* API SERVICES */

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { GlobalState } from "../../../../../../Reducers/RootReducer";

/* MUI */
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { ITelepassUsage } from "../../../../../../Models/ITelepassUsage";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import FormInsertTelepassUsage from "../../../../../../Components/FormInsertTelepassUsage/FormInsertTelepassUsage";
import { formatPlate } from "../../../VehiclesPage/VehiclesPage";
import { JSONPrint, currencyDecoder } from "../../../../../../Utils/Decoder";

/* COMPONENT */
const ViewTelepassUsagePage = () => {
  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [loadingTelepassUsage, setLoadingTelepassUsage] =
    useState<boolean>(false);
  const [telepassUsageList, setTelepassUsageList] = useState<ITelepassUsage[]>(
    []
  );
  const [currentTelepassUsage, setCurrentTelepassUsage] = useState<any>(null);
  
  /* NAVIGATE */
  const navigate = useNavigate();

  // Load list
  const loadTelepassUsage = () => {
    if (loggedUser) {
      setLoadingTelepassUsage(true);
      ApiService.TelepassUsageController.TelepassUsageGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTelepassUsageList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingTelepassUsage(false);
        }
      );
    }
  };

  useEffect(() => {
    loadTelepassUsage();
  }, []);

  /* FUNCTIONS */
  // Linkable value
  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          let telepassUsage_: ITelepassUsage = telepassUsageList.filter(
            (x: ITelepassUsage) =>
              x.telepassUsageID === props.row.telepassUsageID
          )[0];
          setCurrentTelepassUsage(telepassUsage_);
          SummonModal("telepassID_Modal");
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };
  
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "vehicleLicencePlate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "telepassTypeName",
      headerName: i18next.t("form:telepassType"),
    },
    {
      field: "telepassUsageTypeDescription",
      headerName: i18next.t("form:telepassUsageTypeDescription"),
    },
    {
      field: "telepassSerialNumber",
      headerName: i18next.t("form:numberSerial"),
    },
    {
      field: "telepassUsageDTView",
      headerName: i18next.t("form:telepassUsageDT"),
    },
    {
      field: "telepassUsageRoute",
      headerName: i18next.t("form:telepassUsageRoute"),
    },
    {
      field: "telepassClassName",
      headerName: i18next.t("form:telepassUsageClass"),
    },
    {
      field: "telepassUsageCost",
      headerName: i18next.t("form:telepassUsageCost"),
      type: "number"
    },
  ];

  // ROWS
  const rowsDef = [];

  for (let i = 0; i < telepassUsageList.length; i++) {
    rowsDef.push({
      id: i,
      telepassTypeName: telepassUsageList[i].telepassTypeName,
      telepassSerialNumber: telepassUsageList[i].telepassSerialNumber,
      telepassUsageDTView: displayUTC0_ISODate(
        telepassUsageList[i].telepassUsageDT
      ),
      telepassClassName: telepassUsageList[i].telepassClassName,
      telepassUsageRoute: telepassUsageList[i].telepassUsageRoute,
      telepassClassID: telepassUsageList[i].telepassClassID,
      telepassUsageCost: currencyDecoder(telepassUsageList[i].telepassUsageCost, 2),
      vehicleLicencePlate: telepassUsageList[i].vehicleLicensePlate,
      vehicleID: telepassUsageList[i].vehicleID,
      telepassUsageDT: telepassUsageList[i].telepassUsageDT,
      telepassUsageID: telepassUsageList[i].telepassUsageID,
      telepassUsageTypeDescription: telepassUsageList[i].telepassUsageTypeDescription
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_telepass_page_title")}
        arrowBack
        onBack={() =>
          navigate(
            AppRoutes.INTERNAL_DASHBOARD +
              AppRoutes.DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES
          )
        }
      />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentTelepassUsage(null);
                SummonModal("telepassID_Modal");
              }}
            >
              {i18next.t("navigation:_telepass_page_insert_single_usage")}
            </Button>

            <Button
              onClick={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD +
                    AppRoutes.LOAD_FILE_TELEPASS_USAGE
                );
              }}
            >
              {i18next.t("navigation:_telepass_page_insert_usage")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          modalUniqueId={"telepassID_Modal"}
          title={
            currentTelepassUsage
              ? i18next.t("navigation:_telepass_page_edit_usage")
              : i18next.t("navigation:_telepass_page_add_new_usage")
          }
          modalInnerComponent={
            <FormInsertTelepassUsage
              data={currentTelepassUsage ?? []}
              idModal="telepassID_Modal"
              doRefreshForm={() => {
                loadTelepassUsage();
              }}
            />
          }
        />
        <LoaderBackdrop loading={loadingTelepassUsage} />
      </div>
    </div>
  );
};

export default ViewTelepassUsagePage;
