/* LIBRARY */
import i18next from "i18next";

/* CHART */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// Bar
import { Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

/* INTERFACE */
interface ICO2Emission_Emitted {
  co2Emitted: any[];
  co2VehiclesEmitted: number;
}

/* COMPONENT */
const CO2Emission__StatisticsPage = (props: ICO2Emission_Emitted) => {
  // Chart options
  const options = {
    indexAxis: "y" as const,
    responsive: true,
    barThickness: 20,
    borderRadius: 2,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          font: {
            size: 14,
          },
          display: true,
          text: i18next.t("navigation:_statistics_CO2Tons"),
        },
      },
      y: { beginAtZero: true },
    },
  };

  // Chart data
  const data = {
    labels: [
      i18next.t("navigation:_statistics_Current"),
      i18next.t("navigation:_statistics_Period"),
    ],
    datasets: [
      {
        label: i18next.t("navigation:_statistics_Current"),
        data: [props.co2Emitted[0], props.co2Emitted[1]],
        borderColor: "rgba(255, 164, 34, 1)",
        backgroundColor: "rgba(255, 164, 34, 0.5)",
      },
    ],
  };

  /* RETURN */
  return (
    <div className="CO2Emission__card">
      <div className="CO2Emission__emitted">
        <p className="display-flex-direction-column">
          <span
            className="text-color-hint font-size-medium text-bold"
            dangerouslySetInnerHTML={{
              __html: `${i18next.t("navigation:_statistics_CO2Emitted")}`,
            }}
          />

          <span className="text-thin">
            {i18next.t("navigation:_statistics_VehiclesAvg")} {props.co2VehiclesEmitted}
          </span>
        </p>

        <Bar height={70} options={options} data={data} />
      </div>
    </div>
  );
};

export default CO2Emission__StatisticsPage;
