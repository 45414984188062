import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ITicketInsertResponse } from "../../Models/ITicketInsertResponse";
import { GlobalState } from "../../Reducers/RootReducer";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import "./StepAttachmentsTickets.scss";

export interface IStepAttachmentsTickets {
  header?: boolean;
}

const StepAttachmentsTickets = (props: IStepAttachmentsTickets) => {
  const [isHeader, setIsHeader] = useState<boolean>(true);
  const ticketInsertResponse: ITicketInsertResponse[] | undefined = useSelector(
    (state: GlobalState) => state.tickets.ticketInsertResponse
  );

  useEffect(() => {
    if (props.header === false) {
      setIsHeader(props.header);
    }
  }, []);

  return (
    <div className="attachments-tickets-wrapper padding-top-small">
      {isHeader && (
        <div className="attachments-tickets-header">
          <strong>{i18next.t("navigation:AddAttachments")}</strong>
        </div>
      )}
      <AttachementMainHandler
        attachmentFamily={"ticket"}
        extraGetParametersVector={
          ticketInsertResponse !== undefined && ticketInsertResponse.length > 0 && ticketInsertResponse![0].ticketID
            ? [
                {
                  name: "ticketID",
                  value: ticketInsertResponse![0].ticketID,
                },
              ]
            : undefined
        }
      />
    </div>
  );
};

export default StepAttachmentsTickets;
