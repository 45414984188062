import i18next from "i18next";

import "./SearchResultBox.scss";

import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { IVehicleSearch } from "../../Models/IVehicles";
import { JSONPrint } from "../../Utils/Decoder";
import { IAssignmentGet } from "../../Models/IAssignment";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { VehiclesActions } from "../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { useState } from "react";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";

/* INTERFACE */
export interface ISearchResultBox {
  data: IVehicleSearch;
}

/* COMPONENT */
const SearchResultBox = (props: ISearchResultBox) => {
  /* NAVIGATE */
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loadingAssignmentsList, setLoadingAssignmentsList] = useState<any>()

  const loadAssignmentsList = async (vehicleId: number) => {
    if (vehicleId) {
      setLoadingAssignmentsList(true);
      let response: IAPIResponse = await ApiService.AssignmentController.AssignmentGetByCarID(
        vehicleId)
          if (response.error === null) {
            return response.payload
          } else {
            ToastMessage(response.error, "error");
            setLoadingAssignmentsList(false);
            return null
          }
        }
  };

  const openDetail = async () => {
    let result: IVehicleSearch = props.data;
    let assignments: any[] = await loadAssignmentsList(result.vehicleSearch.vehicleID)
    let selectedAssignment: IAssignmentGet | undefined =
                        assignments.find(
                          (x: IAssignmentGet) => x.assignmentID === result.vehicleSearch.assignmentID
                        );
    dispatch(
      VehiclesActions.setCurrentAssignment(
        selectedAssignment
      )
    );
      navigate(
        AppRoutes.INTERNAL_DASHBOARD +
          AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
          "?vehicleID=" +
          result.vehicleSearch.vehicleID.toString() +
          "&assignmentID=" +
          result.vehicleSearch.assignmentID.toString()
      );
  };

  const fillBlankString = (text: string) => {
    if (text && !text.includes("null")) {
      return text;
    }

    return "-";
  };

  /* RETURN */
  return (
    <div className="search-result-box-wrapper">
      <div className="search-result-box-header">
        <div className="search-result-box-header-title">{props.data.title}</div>

        <div
          className={
            props.data.vehicleSearch.activeAssignment
              ? "search-result-box-active-sign"
              : "search-result-box-no-active-sign"
          }
        >
          {props.data.vehicleSearch.activeAssignment
            ? i18next.t("navigation:activeAssigment")
            : i18next.t("navigation:endedAssigment")}
        </div>
      </div>
      <div className="search-result-box-content">
        <div className="search-result-box-plate" onClick={openDetail}>
          <DirectionsCarIcon className="search-result-icon" />
          <div style={{ marginLeft: "1em" }}>
            <strong>
              {formatPlate(props.data.vehicleSearch.vehicleLicensePlate)}
            </strong>
          </div>
        </div>
        <div className="search-result-box-data">
          <div className="data-box-wrapper">
            {i18next.t("form:searchActualAssignDriver")}:
            <div className="data-box">
              <Link
                className="cursor-pointer"
                onClick={() => {
                  navigate(
                    AppRoutes.INTERNAL_DASHBOARD +
                      AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS +
                      "?preload=" +
                      props.data.driverSearch.driverID
                  );
                }}
              >
                {fillBlankString(
                  props.data.driverSearch.driverName +
                    " " +
                    props.data.driverSearch.driverSurname
                )}
              </Link>
            </div>
          </div>
          <div className="data-box-wrapper">
            {i18next.t("form:numberChassis")}:
            <div className="data-box">
              {props.data.vehicleSearch.vehicleChassis}
            </div>
          </div>
          <div className="data-box-wrapper">
            {i18next.t("navigation:_vehicle_mileage")}:
            <div className="data-box">
              {props.data.vehicleSearch.vehicleLastInspectionKm}
            </div>
          </div>
          <div className="data-box-wrapper">
            {i18next.t("form:searchPageComapany_Natural")}:
            <div className="data-box">
              {props.data.companySearch &&
              props.data.companySearch.accountTypeID == 2
                ? props.data.companySearch.companyBusinessName
                : i18next.t("form:userTypeNatural")}
            </div>
          </div>
          <div className="data-box-wrapper">
            {i18next.t("form:vehiclePosition")}:
            <div className="data-box">
              {fillBlankString(
                props.data.driverSearch.driverAddressStreet +
                  " " +
                  props.data.driverSearch.driverAddressNumber +
                  ", " +
                  props.data.driverSearch.driverAddressCity
              )}
            </div>
          </div>
          <div className="data-box-wrapper">
            {i18next.t("form:searchContract")}:
            <div className="data-box">
              {fillBlankString(props.data.vehicleSearch.contractTypeName)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultBox;
