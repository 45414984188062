import RefreshIcon from "@mui/icons-material/Refresh";
import { Alert, Button, TextField } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import {
  DragAndDropper_DragItem,
  DragAndDropper_Receiver,
} from "../DragAndDropper/DragAndDropper";
import "./StepDashboardMapSupplyTypeTemplate.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  ITemplateInsertProperty,
  ITemplatePropertyMapping,
} from "../../Models/ITemplate";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ICompanyGet } from "../../Models/ICompany";
import { IFuelSupplyType } from "../../Models/IVehicleFuelSupply";
import { JSONPrint } from "../../Utils/Decoder";

const StepDashboardMapSupplyTypeTemplate = () => {
  const dispatch = useDispatch();
  const templateGenericFormData = useSelector(
    (state: GlobalState) => state.generic.templateGenericWorkingData
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [templateSupplyTypeProperties, setTemplateSupplyTypeProperties] =
    useState<IFuelSupplyType[]>([]);
  const [mappings, setMappings] = useState<ITemplatePropertyMapping[]>([]);

  const addMapping = (value: string, refId: number) => {
    let tmp: ITemplatePropertyMapping[] = [...mappings];
    let targ: ITemplatePropertyMapping[] = tmp.filter(
      (x: ITemplatePropertyMapping) => x.templateSystemPropertyID !== refId
    );

    targ.push({
      templateSystemPropertyID: refId,
      templateUserPropertyName: value,
    });
    setMappings(targ);
  };

  const getSupplyTypeData = () => {
    setLoading(true);
    ApiService.VehicleFuelSupplyController.VehicleFuelSupplyTypeGet(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setTemplateSupplyTypeProperties(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const saveSupplyData = () => {
    setProcessing(true);
    let data: ITemplateInsertProperty = {
      templateID: templateGenericFormData.formData.templateID,
      templateProperties: mappings,
    };
    ApiService.TemplateController.TemplateInsertSysPropertyMappingFuel(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          stepperGoToPage(4);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    getSupplyTypeData();
  }, []);

  return (
    <div style={{ width: "80%" }}>
      <h2>{i18next.t("navigation:_supply_template_extra_map_title")}</h2>
      <div>{i18next.t("navigation:_supply_template_extra_map_desc")}</div>
      <br />
      {templateSupplyTypeProperties.map((x: IFuelSupplyType, i: number) => {
        return (
          <div key={i} className="mapping-one-row-slot">
            <TextField
              required
              fullWidth
              label={x.fuelSupplyTypeName}
              placeholder={x.fuelSupplyTypeName + " *"}
              size={"small"}
              onChange={(e: any) => {
                addMapping(e.target.value, x.fuelSupplyTypeID);
              }}
            />
          </div>
        );
      })}
      <div style={{ height: "2em" }}></div>
      <div className="button-confirm">
        <Button
          disabled={mappings.length != templateSupplyTypeProperties.length}
          variant="contained"
          onClick={() => saveSupplyData()}
        >
          {i18next.t("navigation:next")}
        </Button>
      </div>

      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default StepDashboardMapSupplyTypeTemplate;
