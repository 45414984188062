import ApiEndPoints from "../../Costants/ApiEndPoints";
import { ITelepassInsert, ITelepassUpdate } from "../../Models/ITelepass";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const TelepassController = {
  TelepassGetType: async (
    telepassTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_GET_TYPE;

    if (telepassTypeID) {
      request.url += `?telepassTypeID=${telepassTypeID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassGet: async (
    telepassID: number,
    vehicleID: number,
    stored?: boolean | null,
    pending?: boolean | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_GET;
    let parameters: string[] = [];
    if (telepassID) {
      parameters.push(`telepassID=${telepassID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    if (pending) {
      parameters.push(`pending=${pending}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassInsert: async (
    body: ITelepassInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_INSERT;
    request.payload = body;
    request.method = "POST";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassUpdate: async (
    body: ITelepassUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  TelepassDelete: async (
    telepassID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_TELEPASS_DELETE;
    request.method = "DELETE";
    request.payload = {
      telepassID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TelepassController;
