/* REACT */
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./Forniture__ServicesPage.scss";

/* COMPONENTS */
import AppRoutes from "../../../../../Costants/AppRoutes";
import FormInsertSupply from "../../../../../Components/FormInsertSupply/FormInsertSupply";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import { Button } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";

/* COMPONENT */
const Forniture__ServicesPage = () => {
  /* NAVIGATE */
  const navigate = useNavigate();

  /* RETURN */
  return (
    <div className="forniture-wrapper">
      <HeaderPageDashboard title={i18next.t("navigation:_forniture_page_title")} />

      <div className="forniture-content">
        <Button
          className="button-forniture"
          onClick={() => navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.VIEW_SUPPLIES)}
        >
          <div>
            <TableViewIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_forniture_page_view_supplies")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_forniture_page_view_supplies_subtitle")}</div>
        </Button>

        <Button
          className="button-forniture"
          onClick={() => {
            SummonModal("add-supply-modal");
          }}
        >
          <div>
            <LocalGasStationIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_forniture_page_insert_supply")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_forniture_page_insert_supply_subtitle")}</div>
        </Button>

        <Button
          className="button-forniture"
          onClick={() => {
            navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.LOAD_FILE_SUPPLIES);
          }}
        >
          <div>
            <UploadFileIcon sx={{ fontSize: "5em" }} />
          </div>
          <div>
            <strong style={{ color: "black", fontSize: "1.2em" }}>
              {i18next.t("navigation:_forniture_page_upload_supply")}
            </strong>
          </div>
          <div>{i18next.t("navigation:_forniture_page_upload_supply_subtitle")}</div>
        </Button>
      </div>

      <SmartModal
        modalUniqueId={"add-supply-modal"}
        title={i18next.t("navigation:_forniture_page_add_new_supply")}
        modalInnerComponent={
          <FormInsertSupply 
            idModal="add-supply-modal"
        />}
      />
    </div>
  );
};

export default Forniture__ServicesPage;
