import { IVehicleDataBrands } from "../../Models/IVehicles";

export const SET_BRANDS = "SET_BRANDS";

interface SetBrandsActionType {
  type: typeof SET_BRANDS;
  payload: IVehicleDataBrands[] | undefined;
}

export type BrandsActionType = SetBrandsActionType;

const setBrandsList = (brands: IVehicleDataBrands[] | undefined): SetBrandsActionType => {
  return { type: SET_BRANDS, payload: brands };
};

export const BrandsActions = {
  setBrandsList
};
