/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { IDriverGet } from "../../../../../Models/IDriver";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IUPDATE_CarConfigurator_Driver_Config } from "../../../../../Models/ICarConfigurator";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Drivers__AnagraphicsPage.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import FormAssignedDriver from "../../../../../Components/StepAssignedDrivers/FormAssignedDriver";
import AppRoutes from "../../../../../Costants/AppRoutes";

/* MUI */
import { Box, Button, ButtonGroup, Link, Switch } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

export const DriverLink = (props: {
  driverID?: number;
  driverLabel?: string;
  navigate: string;
}) => {
  const navigate = useNavigate();

  const gotoDriver = () => {
    if (props.driverID !== -1) {
      navigate(
        AppRoutes.INTERNAL_DASHBOARD + props.navigate + "?preload=" + props.driverID
      );
    }
  };

  let label = props.driverLabel;
  let noAvailable = false;
  if ((props.driverLabel ?? "undefined").includes("undefined")) {
    label = i18next.t("navigation:vehicle_not_assgned") ?? "";
    noAvailable = true;
  }

  return (
    <div>
      {noAvailable && <span>{label}</span>}

      {!noAvailable && (
        <Link className="cursor-pointer" onClick={gotoDriver}>
          {(label ?? "").replaceAll("null", "").trim()}
        </Link>
      )}
    </div>
  );
};

/* COMPONENT */
const Drivers__AnagraphicsPage = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const [params] = useSearchParams();
  const preloadDriverId: string | null = params.get("preload");

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* STATES */
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [loadingUpdateDriver, setLoadingUpdateDriver] = useState<boolean>(false);

  // Container for drivers list
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [currentDriver, setCurrentDriver] = useState<any>(null);

  /* API */
  // Load drivers list
  const loadDriverList = () => {
    if (loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverList(response.payload);

          if (preloadDriverId) {
            selectDriver(+preloadDriverId, response.payload);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDriverList(false);
      });
    }
  };

  // Update driver config
  const UPDATE_CarConfigurator_Driver_Config = (
    data: IUPDATE_CarConfigurator_Driver_Config
  ) => {
    setLoadingUpdateDriver(true);

    ApiService.CarConfiguratorController.UPDATE_CarConfigurator_Driver_Config(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:updateSuccess"), "success");

          setTimeout(() => {
            loadDriverList();
          }, 100);
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingUpdateDriver(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadDriverList();
  }, []);

  const selectDriver = (driverID: number, thisList?: IDriverGet[]) => {
    let driver_: IDriverGet | undefined = (thisList ?? driverList).find(
      (x: IDriverGet) => x.driverInfo?.driverID === driverID
    );
    if (driver_) {
      let parsedItem = {
        ...driver_["driverInfo"],
        ...{ driverCostCenter: driver_["driverCostCenter"] },
        ...{ deadlineDriver: driver_["deadlineDriver"] },
        ...{
          licenceTypeList: driver_["driverInfo"]["licenceTypeList"].map(
            (x: any) => x.licenceTypeID
          ),
        },
      };
      setCurrentDriver(parsedItem);
      SummonModal("driver-page-modal");
    }
  };

  const editRow = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          selectDriver(props.row.driverID);
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };

  // Enable config
  const renderEnable = (props: GridRenderCellParams) => {
    return (
      <Switch
        checked={props.row.enabled}
        onClick={() => {
          UPDATE_CarConfigurator_Driver_Config({
            driverID: props.row.driverID,
            enabled: !props.row.enabled,
          });
        }}
      />
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "enable",
      headerName: i18next.t("form:enableVehicleConfiguration"),
      renderCell: renderEnable,
    },
    {
      field: "driverName",
      headerName: "Nominativo",
    },
    {
      field: "driverCompany",
      headerName: "Azienda",
    },
    {
      field: "driverType",
      headerName: "Tipo utente",
    },
    {
      field: "driverEmail",
      headerName: "Email",
    },
    {
      field: "driverPhone",
      headerName: "Telefono",
    },
    {
      field: "driverCity",
      headerName: "Città",
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < driverList.length; i++) {
    rowsDef.push({
      id: i,
      enabled: driverList[i].driverInfo.enabledVehicleConfigurationBtn,

      driverID: driverList[i].driverInfo.driverID,
      driverCompany: driverList[i].driverInfo.companyBusinessName,
      driverType: driverList[i].driverInfo.driverTypeName,
      driverName:
        driverList[i].driverInfo.driverName +
        (driverList[i].driverInfo.driverSurname
          ? " " + driverList[i].driverInfo.driverSurname
          : ""),
      driverEmail: driverList[i].driverInfo.driverEmail,
      driverPhone: driverList[i].driverInfo.driverPhone || "-",
      driverCity: driverList[i].driverInfo.driverAddressCity,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_drivers")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentDriver(null);
                SummonModal("driver-page-modal");
              }}
            >
              {i18next.t("navigation:_add_driver")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          hideButtons={true}
          modalUniqueId="driver-page-modal"
          title={
            currentDriver
              ? i18next.t("generic:titleUpdateDriver")
              : i18next.t("generic:titleAddDriver")
          }
          modalInnerComponent={
            <FormAssignedDriver
              loggedUser={loggedUser}
              data={currentDriver ?? []}
              companyUser={companyUser}
              idModal="driver-page-modal"
              doRefreshForm={loadDriverList}
            />
          }
        />

        <LoaderBackdrop loading={loadingDriverList || loadingUpdateDriver} />
      </div>
    </div>
  );
};

export default Drivers__AnagraphicsPage;
