import { useLayoutEffect, useRef } from "react";
import "./BannerCarouselComponent.scss";

export type BannerCarouselProps = {
  title: React.ReactNode;
  images: { src: string; alt: string }[];
};

const TIME_PER_IMAGE = 2000;

export const BannerCarouselComponent: React.FC<BannerCarouselProps> = ({ title, images }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let lastUpdate = Date.now();
    let animationRequest: number = -1;

    const evaluateFrame = () => {
      if (!carouselRef.current) return;

      if (carouselRef.current.scrollLeft >= images.length * (150 + 32)) {
        carouselRef.current.scrollTo({ left: 0 });
        lastUpdate = Date.now();
      } else {
        const elapsed = Date.now() - lastUpdate;
        const movement = elapsed * ((150 + 32) / TIME_PER_IMAGE);

        if (movement >= 1) {
          carouselRef.current.scrollTo({ left: carouselRef.current.scrollLeft + movement });
          lastUpdate = Date.now();
        }
      }

      animationRequest = window.requestAnimationFrame(evaluateFrame);
    };

    animationRequest = window.requestAnimationFrame(evaluateFrame);
    return () => {
      window.cancelAnimationFrame(animationRequest);
    };
  }, [images.length]);

  return (
    <div className="ff-banner-carousel-outer">
      <div className="ff-banner-carousel-inner">
        <h1>{title}</h1>
        <div ref={carouselRef} className="ff-banner-carousel-wrapper">
          {images.map((image) => (
            <img key={image.src} alt={image.alt} src={`/Images/newImages/${image.src}`} />
          ))}
          {images.map((image) => (
            <img key={image.src} alt={image.alt} src={`/Images/newImages/${image.src}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerCarouselComponent;
