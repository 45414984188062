import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AppRoutingMap, { IAppRoute } from "./AppRoutingMap";
import Footer from "./Components/Footer/Footer";
import { checkUserIsLogged } from "./Utils/Session";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "./Reducers/RootReducer";
import { IUser } from "./Models/IUser";
import ToolBarSelector from "./Components/ToolBarSelector/ToolBarSelector";
import AppRoutes from "./Costants/AppRoutes";
import { dateIsPast, dateToUTC0_ISO, displayUTC0_ISODate } from "./Components/MultiForm/SpecialInputs/StrongDatePicker";
import ExcelErrorDialog from "./Components/ExcelErrorDialog/ExcelErrorDialog";
import { IAPIResponse } from "./Services/Internal/AjaxService";
import ApiService from "./Services/ApiService";
import { IMenuRole } from "./Components/NavMenu/NavMenu";
import { UserActions } from "./Reducers/User/UserAction";
import { ILoginStatus } from "./Models/ILoginResult";
import { Alert } from "@mui/material";
import i18next from "i18next";
import CookieConsent from "react-cookie-consent";

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [appRoutes, setAppRoutes] = useState<IAppRoute[]>([]);
  const [remainingDays, setRemainingDays] = useState<number>(0);
  const [loadingvoices, setloadingvoices] = useState<boolean>(false);

  const menuVoicesExpired: boolean | undefined = useSelector((state: GlobalState) => state.user.menuPagesExpired);

  const menuPages: IMenuRole[] | undefined = useSelector((state: GlobalState) => state.user.menuVoices);

  const loginStatus: ILoginStatus | undefined = useSelector((state: GlobalState) => state.user.loginStatus);

  const loggedUserNorm: IUser | undefined = useSelector((state: GlobalState) => state.user.currentUser);
  const loggedUserImp: IUser | undefined = useSelector((state: GlobalState) => state.user.currentImpUser);
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const getMenuVoices = () => {
    if (loggedUser) {
      setloadingvoices(true);
      ApiService.SessionController.LoginMenuVoices((response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(UserActions.setMenuPages(response.payload));
        }
        setloadingvoices(false);
      });
    }
  };

  const getYourBrokerGrants = () => {
    ApiService.BrokerController.BroketGrantsGet(loggedUser?.accountID, (response: IAPIResponse) => {
      if (response.error === null) {
        dispatch(UserActions.setBrokerGrants(response.payload));
      }
    });
  };

  useEffect(() => {
    setAppRoutes(
      AppRoutingMap.filter((x: IAppRoute) => x.requiresLogin === checkUserIsLogged() || x.requiresLogin === false)
    );
    getMenuVoices();

    if (loggedUser) {
      getYourBrokerGrants();
    }
  }, [loggedUser]);

  useEffect(() => {
    if (loginStatus) {
      let remDays = getRemainingDays();
      setRemainingDays(remDays);
      dispatch(UserActions.setMenuPagesExpired(remDays <= 0));
      if (remDays <= 0) {
        navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS);
      }
    }
  }, [loginStatus]);

  const goToLogin = () => {
    (window as any)["session-is-expired"] = true;
    navigate(AppRoutes.LOGIN_ROUTE);
  };
  (window as any)["goToLogin"] = goToLogin;

  const getRemainingDays = () => {
    if (loginStatus && loginStatus?.subscriptionDurationID) {
      var date1 = new Date();
      var date2 = new Date(loginStatus?.validTo);

      const diffTime = date2.getTime() - date1.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    }
    return 0;
  };

  const getExpiriDate = () => {
    if (loginStatus && loginStatus?.validTo) {
      let date = dateToUTC0_ISO(new Date(loginStatus?.validTo));
      if (date) {
        return displayUTC0_ISODate(date, true);
      }
    }
    return "";
  };

  return (
    <>
      {!loadingvoices && (
        <div className={!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) ? "public-portal" : undefined}>
          <CookieConsent
            buttonWrapperClasses="cookie-consent-content-btn"
            disableStyles
            location="bottom"
            enableDeclineButton
            overlay
            buttonText={i18next.t("navigation:accept_cookies")}
            cookieName="FacileFlottaCookieConsent"
            declineButtonText={i18next.t("navigation:decline_cookies")}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:cookie_message").replace("URL_LINK", AppRoutes.COOKIE_POLICY_PAGE),
              }}
            />
          </CookieConsent>
          <ToolBarSelector />

          <ExcelErrorDialog />

          <div
            className={`page-global-main-wrap ${
              window.location.pathname.includes(AppRoutes.COOKIE_POLICY_PAGE) ? "cookie-wrapper" : ""
            }`}
          >
            {window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) &&
              remainingDays <= 0 &&
              loginStatus?.validTo && (
                <Alert severity="error">
                  {i18next.t("navigation:subscription_did_expire").replaceAll("$EXPECTED_EXPIRY_DATE", getExpiriDate())}
                </Alert>
              )}

            {window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) &&
              remainingDays > 0 &&
              remainingDays < 30 && (
                <Alert severity="warning">
                  {i18next
                    .t("navigation:subscription_near_expiry")
                    .replaceAll("$EXPECTED_EXPIRY_DATE", getExpiriDate())
                    .replaceAll("$DAYS_TO_USE_APP", remainingDays.toString())}
                </Alert>
              )}

            <Routes>
              {appRoutes.map((x: IAppRoute, i: number) => {
                return <Route key={i} path={x.path} element={x.component} />;
              })}
            </Routes>
          </div>
          {!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) && <Footer />}
        </div>
      )}
    </>
  );
};

export default AppLayout;
