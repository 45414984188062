import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const SessionController = {
  LoginStatus: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7234";
    request.url = ApiEndPoints.LOGIN_STATUS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  Login: async (
    email: string,
    password: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.LOGIN;
    request.port = "7234";
    request.method = "POST";
    request.payload = {
      email: email,
      password: password,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  LoginMenuVoices: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.LOGIN_GET_MENU_VOICES;
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  ResetPassword: async (
    email: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.RESET_PASSWORD}?email=${email}`;
    request.port = "7234";
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default SessionController;
