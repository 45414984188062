/* REACT */
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* STYLE */
import "./DashboardPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../Costants/AppRoutes";

/* REDUCER */
import { GlobalState } from "../../Reducers/RootReducer";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import SpeedIcon from "@mui/icons-material/Speed";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
/* MODELS */
import { IUser } from "../../Models/IUser";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { IAddedService } from "../../Models/IService";
import { IDebitCreditRoadtax } from "../../Models/IRoadtax";
import { IVehicleDataGet } from "../../Models/IVehicles";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
/* MUI */
import { Alert } from "@mui/material";

/* ICONS */
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ConstructionIcon from "@mui/icons-material/Construction";
import BuildIcon from "@mui/icons-material/Build";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import InsightsIcon from "@mui/icons-material/Insights";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import ArticleIcon from "@mui/icons-material/Article";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import OutboxIcon from "@mui/icons-material/Outbox";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GarageIcon from "@mui/icons-material/Garage";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ListIcon from "@mui/icons-material/List";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import RouterIcon from "@mui/icons-material/Router";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import QuizIcon from "@mui/icons-material/Quiz";
import DescriptionIcon from '@mui/icons-material/Description';
import TuneIcon from '@mui/icons-material/Tune';
import CalculateIcon from '@mui/icons-material/Calculate';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WalletIcon from '@mui/icons-material/Wallet';

/* UTILS and SERVICES */
import {
  checkAccountSessionStatusRedirect,
  userRoleFits,
} from "../../Utils/Session";
import { ToastMessage } from "../../Utils/Toastify";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* COMPONENTS */
import NavMenu from "../../Components/NavMenu/NavMenu";
import { displayUTC0_ISODate } from "../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import ImpModal from "../../Components/ImpModal/ImpModal";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
/* PAGES */
import AnagraphicsPage from "./DashBoard_Pages/AnagraphicsPage/AnagraphicsPage";
import ExpiryCalendarPage from "./DashBoard_Pages/ExpiryCalendarPage/ExpiryCalendarPage";
import MessagingPage from "./DashBoard_Pages/MessagingPage/MessagingPage";
import SatisticsPage from "./DashBoard_Pages/SatisticsPage/SatisticsPage";
import Drivers__AnagraphicsPage from "./DashBoard_Pages/AnagraphicsPage/Drivers__AnagraphicsPage/Drivers__AnagraphicsPage";
import Companies__AnagraphicsPage from "./DashBoard_Pages/AnagraphicsPage/Companies__AnagraphicsPage/Companies__AnagraphicsPage";
import Suppliers__AnagraphicsPage from "./DashBoard_Pages/AnagraphicsPage/Suppliers__AnagraphicsPage/Suppliers__AnagraphicsPage";
import Assurances__ServicesPage from "./DashBoard_Pages/ServicesPage/Assurances__ServicesPage/Assurances__ServicesPage";
import TelepassUsages__ServicesPage from "./DashBoard_Pages/ServicesPage/TelepassUsages__ServicesPage/TelepassUsages__ServicesPage";
import Forniture__ServicesPage from "./DashBoard_Pages/ServicesPage/Forniture__ServicesPage/Forniture__ServicesPage";
import Payments__AdditionalServicesPage from "./DashBoard_Pages/AdditionalServicesPage/Payments__AdditionalServicesPage/Payments__AdditionalServicesPage";
import Fleet__AdditionalServicesPage from "./DashBoard_Pages/AdditionalServicesPage/Fleet__AdditionalServicesPage/Fleet__AdditionalServicesPage";
import Tickets__AdditionalServicesPage from "./DashBoard_Pages/AdditionalServicesPage/Tickets__AdditionalServicesPage/Tickets__AdditionalServicesPage";
import CO2Emission__StatisticsPage from "./DashBoard_Pages/SatisticsPage/CO2Emission__StatisticsPage/CO2Emission__StatisticsPage";
import KProjection__StatisticsPage from "./DashBoard_Pages/SatisticsPage/KProjection__StatisticsPage/KProjection__StatisticsPage";
import FuelAnomaly__StatisticsPage from "./DashBoard_Pages/SatisticsPage/FuelAnomaly__StatisticsPage/FuelAnomaly__StatisticsPage";
import OwnershipCosts__StatisticsPage from "./DashBoard_Pages/SatisticsPage/OwnershipCosts__StatisticsPage/OwnershipCosts__StatisticsPage";
import Cards__VehiclesPage from "./DashBoard_Pages/VehiclesPage/Cards__VehiclesPage/Cards__VehiclesPage";
import Telepass__VehiclesPage from "./DashBoard_Pages/VehiclesPage/Telepass__VehiclesPage/Telepass__VehiclesPage";
import Orders__VehiclesPage from "./DashBoard_Pages/VehiclesPage/Orders__VehiclesPage/Orders__VehiclesPage";
import Status__VehiclesPage from "./DashBoard_Pages/VehiclesPage/Status__VehiclesPage/Status__VehiclesPage";
import Fleet__AdditionalServicesPage__Movements from "./DashBoard_Pages/AdditionalServicesPage/Fleet__AdditionalServicesPage/Fleet__AdditionalServicesPage__Movements/Fleet__AdditionalServicesPage__Movements";
import Fleet__AdditionalServicesPage__OutgoingVehicle from "./DashBoard_Pages/AdditionalServicesPage/Fleet__AdditionalServicesPage/Fleet__AdditionalServicesPage__OutgoingVehicle/Fleet__AdditionalServicesPage__OutgoingVehicle";
import Fleet__AdditionalServicesPage__IncomingVehicle from "./DashBoard_Pages/AdditionalServicesPage/Fleet__AdditionalServicesPage/Fleet__AdditionalServicesPage__IncomingVehicle/Fleet__AdditionalServicesPage__IncomingVehicle";
import Create__VehiclePage from "./DashBoard_Pages/VehiclesPage/Create__VehiclePage/Create__VehiclePage";
import Fleet__AdditionalServicesPage__Reservation from "./DashBoard_Pages/AdditionalServicesPage/Fleet__AdditionalServicesPage/Fleet__AdditionalServicesPage__Reservation/Fleet__AdditionalServicesPage__Reservation";
import AdminPage__Suppliers from "./DashBoard_Pages/AdminPage/AdminPage__Suppliers/AdminPage__Suppliers";
import AdminPage__FleetPlans from "./DashBoard_Pages/AdminPage/AdminPage__FleetPlans/AdminPage__FleetPlans";
import AdminPage__Clients from "./DashBoard_Pages/AdminPage/AdminPage__Clients/AdminPage__Clients";
import AdminPage__roadTaxes from "./DashBoard_Pages/AdminPage/AdminPage__roadTaxes/AdminPage__roadTaxes";
import AdminPage__emitEntities from "./DashBoard_Pages/AdminPage/AdminPage__emitEntities/AdminPage__emitEntities";
import AdminPage__articles from "./DashBoard_Pages/AdminPage/AdminPage__articles/AdminPage__articles";
import AdminPage__fringeBenefits from "./DashBoard_Pages/AdminPage/AdminPage__fringeBenefits/AdminPage__fringeBenefits";
import AdminPage__bills from "./DashBoard_Pages/AdminPage/AdminPage__bills/AdminPage__bills";
import AdminPage__quattroRuote from "./DashBoard_Pages/AdminPage/AdminPage__quattroRuote/AdminPage__quattroRuote";
import PaymentRegister from "./DashBoard_Pages/AdditionalServicesPage/Payments__AdditionalServicesPage/PaymentRegister/PaymentRegister";
import SearchPage from "../SearchPage/SearchPage";
import PaymentsPage from "./DashBoard_Pages/AdditionalServicesPage/Payments__AdditionalServicesPage/PaymentsPage/PaymentsPage";
import PaymentsPaid from "./DashBoard_Pages/AdditionalServicesPage/Payments__AdditionalServicesPage/PaymentsPaid/PaymentsPaid";
import TicketsManagementPage from "./DashBoard_Pages/AdditionalServicesPage/Tickets__AdditionalServicesPage/TicketsManagementPage/TicketsManagementPage";
import LoadFileSupplies from "./DashBoard_Pages/ServicesPage/Forniture__ServicesPage/LoadFileSupplies/LoadFileSupplies";
import ViewSuppliesPage from "./DashBoard_Pages/ServicesPage/Forniture__ServicesPage/ViewSuppliesPage/ViewSuppliesPage";
import LoadFileTelepassUsage from "./DashBoard_Pages/ServicesPage/TelepassUsages__ServicesPage/LoadFileTelepassUsage/LoadFileTelepassUsage";
import ViewTelepassUsagePage from "./DashBoard_Pages/ServicesPage/TelepassUsages__ServicesPage/ViewTelepassUsagePage/ViewTelepassUsagePage";
import VehiclesPage from "./DashBoard_Pages/VehiclesPage/VehiclesPage";
import AdminPage__Services from "./DashBoard_Pages/AdminPage/AdminPage__Services/AdminPage__Services";
import AdminPage__Voucher from "./DashBoard_Pages/AdminPage/AdminPage__Voucher/AdminPage__Voucher";
import Suppliers__PurchasesPage_sub from "./DashBoard_Pages/ServicesPage/Suppliers__PurchasesPage_sub/Suppliers__PurchasesPage_sub";
import FleetManager__AnagraphicsPage from "./DashBoard_Pages/AnagraphicsPage/FleetManager__AnagraphicsPage/FleetManager__AnagraphicsPage";
import NotAuthorizePage from "../NotAuthorizePage/NotAuthorizePage";
import ProfilePage from "../ProfilePage/ProfilePage";
import Km__VehiclesPage from "./DashBoard_Pages/VehiclesPage/Km__VehiclesPage/Km__VehiclesPage";
import { RoadTaxActions } from "../../Reducers/RoadTax/RoadTaxAction";
import VehicleConfigurationPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleConfigurationPage/VehicleConfigurationPage";
import VehicleGroupPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleGroupPage/VehicleGroupPage";
import VehicleRequestsPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleRequestsPage/VehicleRequestsPage";
import OpenListPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleGroupPage/OpenListPage/OpenGroupPage";
import CloseListPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleGroupPage/CloseListPage/CloseGroupPage";
import VehicleOffersPage from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleOffersPage/VehicleOffersPage";
import VehiclePromptVehiclePage from "./DashBoard_Pages/VehiclesConfigurationPage/VehiclePromptVehiclePage/VehiclePromptDeliveryPage";
import VehicleRequestsPageSummary from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleRequestsPage/VehicleRequestsPageSummary";
import VehicleRequestsPageOrder from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleRequestsPage/VehicleRequestsPageOrder";
import VehiclePromptDeliveryPageSearch from "./DashBoard_Pages/VehiclesConfigurationPage/VehiclePromptVehiclePage/VehiclePromptDeliveryPageSearch";
import QuotesPageRequests from "./DashBoard_Pages/QuotesPage/QuotesPageRequests/QuotesPageRequests";
import QuotesPageReceived from "./DashBoard_Pages/QuotesPage/QuotesPageReceived/QuotesPageReceived";
import SatelliteApparatus__VehiclesPageForm from "./DashBoard_Pages/VehiclesPage/SatelliteApparatus__VehiclesPage/SatelliteApparatus__VehiclesPageForm";
import VehicleConfigurationPageSummary from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleConfigurationPage/VehicleConfigurationPageSummary";
import HelpPage from "./DashBoard_Pages/HelpPage/HelpPage";
import QuotesPageProposed from "./DashBoard_Pages/QuotesPage/QuotesPageProposed/QuotesPageProposed";
import FormOfferPage_Supplier from "./DashBoard_Pages/VehiclesConfigurationPage/VehicleOffersPage/FormOfferPage_Supplier";
import DealerPageRequest from "./DashBoard_Pages/VehiclesConfigurationPage/DealerPages/DealerPageRequest/DealerPageRequest";
import DealerPageSent from "./DashBoard_Pages/VehiclesConfigurationPage/DealerPages/DealerPageSent/DealerPageSent";
import DealerPageProposal from "./DashBoard_Pages/VehiclesConfigurationPage/DealerPages/DealerPageProposal/DealerPageProposal";
import AdminPage__Menu from "./DashBoard_Pages/AdminPage/AdminPage_Menu/AdminPage_Menu";
import AdminPage__FAQ from "./DashBoard_Pages/AdminPage/AdminPage__FAQ/AdminPage_FAQ";
import Suppliers__PurchasesPage_single from "./DashBoard_Pages/ServicesPage/Suppliers__PurchasesPage_single/Suppliers__PurchasesPage_single";
import { JSONPrint } from "../../Utils/Decoder";
import ReduceSubscriptionPage from "../ReduceSubscriptionPage/ReduceSubscriptionPage";
import AdminPage__economicCredits from "./DashBoard_Pages/AdminPage/AdminPage__economicCredits/AdminPage__economicCredits";
import DownloadDataPage from "./DashBoard_Pages/DownloadDataPage/DownloadDataPage";
import { UserActions } from "../../Reducers/User/UserAction";
import AdminPage__FaqCategories from "./DashBoard_Pages/AdminPage/AdminPage__FaqCategories/AdminPage__FaqCategories";
import QuotesPageDelivered from "./DashBoard_Pages/QuotesPage/QuotesPageDelivered/QuotesPageDelivered";

/* INTERFACE */
export interface IAppRouteInternal {
  path: string;
  component: JSX.Element | null;
  label: string;
  icon: JSX.Element;
  visible?: boolean;
  children?: IAppRouteInternal[];
}

export const GetVehicleMinDate = (vehicle: IVehicleDataGet | undefined, skipContractDT?: boolean) => {
  if (vehicle) {
    let d1 = vehicle.contractStartDT;
    let d2 = vehicle.vehicleFirstRegistrationDT;
    let d3 = new Date().toISOString();

    if (d1 && !skipContractDT) {
      return d1;
    }
    if (d2) {
      return d2;
    }
    return d3;
  } else {
    return new Date().toISOString();
  }
};

/* FUNCTIONS */
export const getAnagraphicsRoutes = (
  loggedUser: IUser | undefined,
  addonService?: IAddedService[],
  menuVoicesExpired?: boolean
) => {
  let fleetManagerAddonIsActive: boolean = false;
  let carTaxAddonIsActive: boolean = false;

  if (
    addonService?.find(
      (x: IAddedService) => x.addedServiceCode === "FLEET_ADDON"
    )
  ) {
    fleetManagerAddonIsActive = true;
  }

  if (
    addonService?.find(
      (x: IAddedService) => x.addedServiceCode === "ROAD_TAX_ADDON"
    )
  ) {
    carTaxAddonIsActive = true;
  }

  // Set the anagraphics routes based on the user type
  const anagraphicsRoutes = [
    {
      path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS,
      component: <Suppliers__AnagraphicsPage />,
      label: "_add_services_providers",
      icon: <AddHomeWorkIcon />,
    },
    {
      path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS,
      component: <Drivers__AnagraphicsPage />,
      label: "_add_services_assegnee",
      icon: <MinorCrashIcon />,
    },
  ];

  if (loggedUser?.accountTypeID === 2) {
    anagraphicsRoutes.push({
      path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES,
      component: <Companies__AnagraphicsPage />,
      label: "_add_services_companies",
      icon: <EmojiTransportationIcon />,
    });

    anagraphicsRoutes.push({
      path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__FLEET_MANAGERS,
      component: <FleetManager__AnagraphicsPage />,
      label: "_fleet_manager",
      icon: <ManageAccountsIcon />,
    });
  }

  /* ROUTING MAP */
  let DashboardAppRoutingMap: IAppRouteInternal[] = [
    {
      path: AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE,
      component: <ExpiryCalendarPage />,
      label: "_calendar",
      visible: !menuVoicesExpired,
      icon: <EventAvailableIcon />,
    },
    {
      path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE,
      component: null,
      label: "_anagraphics",
      visible: !menuVoicesExpired,
      icon: <WidgetsIcon />,
      children: anagraphicsRoutes,
    },
    {
      path: AppRoutes.DASHBOARD___VEHICLES_ROUTE,
      component: null,
      label: "_vechicles",
      visible: !menuVoicesExpired,
      icon: <DirectionsCarIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__CREATE,
          component: <Create__VehiclePage />,
          label: "",
          visible: false,
          icon: <ConstructionIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__STATUS,
          component: <Status__VehiclesPage />,
          label: "_vehicles_status",
          icon: <GarageIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__ORDERS,
          component: <Orders__VehiclesPage />,
          label: "_vehicles_orders",
          icon: <ViewListIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__TELEPASS,
          component: <Telepass__VehiclesPage />,
          label: "_vehicles_telepass",
          icon: <AddRoadIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__CARDS,
          component: <Cards__VehiclesPage />,
          label: "_vehicles_cards",
          icon: <CreditCardIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__KM_SET,
          component: <Km__VehiclesPage />,
          label: "_vehicles_km",
          icon: <SpeedIcon />,
        },
        // {
        //   path: AppRoutes.DASHBOARD___VEHICLES_ROUTE__SATELLITE_APPARATUS,
        //   component: <SatelliteApparatus__VehiclesPageForm />,
        //   label: "_satellite_apparatus",
        //   icon: <RouterIcon />,
        // },
      ],
    },
    {
      path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE,
      component: null,
      label: "_configure_vechicles",
      visible: !menuVoicesExpired && addonService && addonService?.filter(
        (x: IAddedService) => x.addedServiceCode === "CAR_CONFIGURATOR_ADDON"
      ).length > 0,
      icon: <SettingsSuggestIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE,
          component: <VehicleConfigurationPage />,
          label: "_configure_vechicles_configuration",
          icon: <CarCrashIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP,
          component: <VehicleGroupPage />,
          label: "_configure_vechicles_group",
          icon: <RoomPreferencesIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS,
          component: <VehicleRequestsPage />,
          label: "_configure_vechicles_request",
          icon: <FormatListBulletedIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__OFFERS_RECEVICED,
          component: <VehicleOffersPage />,
          label: "_configure_vechicles_offers",
          icon: <ReceiptLongIcon />,
        },
        /* 
        {
          path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY,
          component: <VehiclePromptVehiclePage />,
          label: "_configure_vechicles_prompt_delivery",
          icon: <AllInboxIcon />,
        },
         */
      ],
    },
    {
      path: AppRoutes.DASHBOARD___MESSAGING_ROUTE,
      component: <MessagingPage />,
      label: "_messaging",
      visible: !menuVoicesExpired,
      icon: <MapsUgcIcon />,
    },
    {
      path: AppRoutes.DASHBOARD___QUOTE_ROUTE,
      component: null,
      label: "quotes",
      visible: (addonService && addonService?.filter(
        (x: IAddedService) => x.addedServiceCode === "QUOTE_ADDON"
      ).length > 0) || !menuVoicesExpired,
      icon: <RequestPageIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___QUOTE_ROUTE__REQUEST,
          component: <QuotesPageRequests />,
          label: "quotesRequest",
          icon: <NoteAddIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE,
          component: <QuotesPageProposed />,
          label: "quotesSolutions",
          icon: <AddBusinessIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___QUOTE_ROUTE__RECEIVED,
          component: <QuotesPageReceived />,
          label: "quotesReceived",
          icon: <FindInPageIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___QUOTE_ROUTE__DELIVERED,
          component: <QuotesPageDelivered />,
          label: "quotesDelivered",
          icon: <DescriptionIcon />,
        },
      ],
    },
    {
      path: AppRoutes.DASHBOARD___SERVICES_ROUTE,
      component: null,
      label: "_services",
      icon: <BuildIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS,
          component: <Suppliers__PurchasesPage_sub />,
          label: "_add_services_purchases_sub",
          icon: <ShoppingCartIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SINGLE,
          component: <Suppliers__PurchasesPage_single />,
          label: "_add_services_purchases",
          icon: <ShoppingCartIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___SERVICES_ROUTE__ASSURANCES,
          component: <Assurances__ServicesPage />,
          label: "_services_insurances",
          visible: !menuVoicesExpired,
          icon: <HealthAndSafetyIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES,
          component: <TelepassUsages__ServicesPage />,
          label: "_services_telepass_usages",
          visible: !menuVoicesExpired,
          icon: <EditRoadIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___SERVICES_ROUTE__FORNITURE,
          component: <Forniture__ServicesPage />,
          label: "_services_fornitures",
          visible: !menuVoicesExpired,
          icon: <LocalGasStationIcon />,
        },
      ],
    },
    {
      path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE,
      component: null,
      visible: true,
      label: "_add_services",
      icon: <ConstructionIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS,
          component: <Payments__AdditionalServicesPage />,
          visible: carTaxAddonIsActive,
          label: "_add_services_payments",
          icon: <TaxiAlertIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET,
          component: <Fleet__AdditionalServicesPage />,
          label: "_add_services_fleet",
          visible: fleetManagerAddonIsActive,
          icon: <CarRepairIcon />,
          children: [
            {
              path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__MOVEMENTS,
              component: <Fleet__AdditionalServicesPage__Movements />,
              label: "_add_services_fleet_movements",
              visible: fleetManagerAddonIsActive,
              icon: <BookmarksIcon />,
            },
            {
              path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__RESERVATION,
              component: <Fleet__AdditionalServicesPage__Reservation />,
              label: "_add_services_fleet_reservations",
              visible: fleetManagerAddonIsActive,
              icon: <BookmarkAddedIcon />,
            },
            {
              path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__OUTGOING_VEHICLE,
              component: <Fleet__AdditionalServicesPage__OutgoingVehicle />,
              label: "_add_services_fleet_outgoing_vehicle",
              visible: fleetManagerAddonIsActive,
              icon: <BookmarkRemoveIcon />,
            },
            {
              path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE,
              component: <Fleet__AdditionalServicesPage__IncomingVehicle />,
              label: "_add_services_fleet_incoming_vehicle",
              visible: fleetManagerAddonIsActive,
              icon: <BookmarkAddIcon />,
            },
          ],
        },
        {
          path: AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS,
          component: <Tickets__AdditionalServicesPage />,
          label: "_add_services_tickets",
          visible: !menuVoicesExpired,
          icon: <PriceChangeIcon />,
        },
      ],
    },
    {
      path: AppRoutes.DASHBOARD___STATISTIC_ROUTE,
      component: null,
      label: "_statistics",
      icon: <StackedBarChartIcon />,
      visible: !menuVoicesExpired,
      children: [
        {
          path: AppRoutes.DASHBOARD___STATISTIC_ROUTE__CO2,
          component: <CO2Emission__StatisticsPage />,
          label: "_statistics_co2",
          icon: <QueryStatsIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___STATISTIC_ROUTE__PROJECTION,
          component: <KProjection__StatisticsPage />,
          label: "_statistics_projection",
          icon: <BubbleChartIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___STATISTIC_ROUTE__FUEL_ANOMALY,
          component: <FuelAnomaly__StatisticsPage />,
          label: "_statistics_anomaly",
          icon: <TroubleshootIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___STATISTIC_ROUTE__OWNERSHIP_COST,
          component: <OwnershipCosts__StatisticsPage />,
          label: "_statistics_ownercost",
          icon: <InsightsIcon />,
        },
      ],
    },
    {
      path: AppRoutes.DASHBOARD___ADMIN_ROUTE,
      component: null,
      label: "_admin",
      icon: <AdminPanelSettingsIcon />,
      children: [
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__CLIENTS,
          component: <AdminPage__Clients />,
          label: userRoleFits(["FleetSupportAdmin"])
            ? "_admin_clients_broker"
            : "_admin_clients",
          icon: <SupervisorAccountIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__ECONOMIC_CREDITS,
          component: <AdminPage__economicCredits />,
          label: "_user_economic_credits",
          icon: <EuroSymbolIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__SERVICES,
          component: <AdminPage__Services />,
          label: "_admin_services",
          icon: <LocalOfferIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__SUPPLIERS,
          component: <AdminPage__Suppliers />,
          label: "_admin_suppliers",
          icon: <AddHomeWorkIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__FLEET_PLANS,
          component: <AdminPage__FleetPlans />,
          label: "_admin_fleetplans",
          icon: <DesignServicesIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__VOUCHER,
          component: <AdminPage__Voucher />,
          label: "_admin_voucher",
          icon: <MonetizationOnIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__CARTAXES,
          component: <AdminPage__roadTaxes />,
          label: "_admin_cartax",
          icon: <TaxiAlertIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__ENTITIES,
          component: <AdminPage__emitEntities />,
          label: "_admin_entity",
          icon: <OutboxIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__ARTICLES,
          component: <AdminPage__articles />,
          label: "_admin_articles",
          icon: <ArticleIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__BENEFITS,
          component: <AdminPage__fringeBenefits />,
          label: "_admin_benefits",
          icon: <NoCrashIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__INVOICES,
          component: <AdminPage__bills />,
          label: "_admin_invoice",
          icon: <RequestQuoteIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__QUATTRO_RUOTE,
          component: <AdminPage__quattroRuote />,
          label: "_admin_quattroRuote",
          icon: <AdminPanelSettingsIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___ADMIN_ROUTE__FAQ_CATEGORIES,
          component: <AdminPage__FaqCategories />,
          label: "_admin_faq_categories",
          icon: <LiveHelpIcon />,
        }
      ],
    },
    {
      path: AppRoutes.DASHBOARD___ADMIN_ROUTE__MENU,
      component: <AdminPage__Menu />,
      label: "_admin_menu",
      icon: <ListIcon />,
    },
    {
      path: AppRoutes.DASHBOARD___ADMIN_ROUTE__FAQ,
      component: <AdminPage__FAQ />,
      label: "_admin_faq",
      icon: <QuizIcon />,
    },
    {
      path: AppRoutes.DASHBOARD___HELP_ROUTE,
      component: <HelpPage />,
      label: "_help",
      icon: <HelpOutlineIcon />,
    },
    {
      path: AppRoutes.DOWNLOAD_DATA_PAGE,
      component: <DownloadDataPage />,
      label: "_download_data",
      icon: <CloudDownloadIcon />,
      visible: menuVoicesExpired
    },
  ];

  // Return routing map
  return DashboardAppRoutingMap;
};

/* COMPONENT */
const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );

  const addonService: IAddedService[] = useSelector(
    (state: GlobalState) => state.generic.addons
  );
  const menuVoicesExpired: boolean | undefined = useSelector(
    (state: GlobalState) => state.user.menuPagesExpired
  );
  const brokerType: any | undefined = useSelector(
    (state: GlobalState) => state.broker.brokerType
  );

  const brokerTypeDealer: boolean = brokerType
    .map((x: any) => x.brokerType.includes("DEALER_SERVICE"))
    .includes(true);

  const [coreLoading, setCoreLoading] = useState<boolean>(true);
  const [coreLoadingError, setCoreLoadingError] = useState<boolean>(false);

  const [plainRoutesMap, setPlainRoutesMap] = useState<IAppRouteInternal[]>([]);
  const [dashboardAppRoutingMap, setDashboardAppRoutingMap] = useState<
    IAppRouteInternal[]
  >([]);

  const loadAddonsService = (subscriptionID: number) => {
    setCoreLoading(true);
    setCoreLoadingError(false);
    ApiService.ServiceController.ServiceGetSelectedData(
      subscriptionID,
      "",
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.setAddons(response.payload));
        } else {
          ToastMessage(response.error, "error");
          setCoreLoadingError(true);
        }
        setCoreLoading(false);
      }
    );
  };

  const chechUserAsDebitOrCredit = (isDebit: boolean) => {
    ApiService.VehicleRoadTaxController.RoadTaxCreditDebitGet(
      isDebit,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let totalCost: number = 0;
          for (let i = 0; i < response.payload.length; i++) {
            if (response.payload[i].creditDebit) {
              totalCost = totalCost + response.payload[i].creditDebit;
            }
          }

          if (isDebit) {
            dispatch(
              RoadTaxActions.setRoadTaxList(
                response.payload.map((x: IDebitCreditRoadtax) => {
                  return x.roadTaxID;
                })
              )
            );
            dispatch(RoadTaxActions.setDebitCost(totalCost));

            if (totalCost < 0) {
              navigate("../" + AppRoutes.PAYMENTS_DEBIT_PAGE, {
                replace: false,
              });
            }
          }
        }
      }
    );
  };

  useEffect(() => {
    document.body.classList.add("dashboard");
    ApiService.SubscriptionController.SubscriptionGetData(
      null,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload && response.payload.length > 0) {
            let subscriptionStatus: boolean =
              response.payload[0].enabled || response.payload[0].isTrial;
            let getCurrentSubscription: ISubscriptions;
            if (response.payload && response.payload.length > 0) {
              subscriptionStatus =
                response.payload[0].enabled || response.payload[0].isTrial;
              dispatch(
                GenericActions.setSubscriptionEnabled(subscriptionStatus)
              );
              getCurrentSubscription = response.payload[0];
              dispatch(UserActions.setMenuPagesExpired(!response.payload[0].enabled && !response.payload[0].isTrial));
              dispatch(
                GenericActions.setCurrentSubscription(response.payload[0])
              );
            }
            ApiService.StepperController.StepperGetLastData(
              (response: IAPIResponse) => {
                if (response.error === null) {
                  if (response.payload) {
                    let newNavigationLink: string =
                      checkAccountSessionStatusRedirect(
                        response?.payload?.registrationCompleted ?? false,
                        getCurrentSubscription?.enabled ?? false,
                        getCurrentSubscription?.paymentRequired ?? false,
                        getCurrentSubscription?.isTrial ?? false
                      );

                    if (
                      response?.payload?.registrationCompleted &&
                      getCurrentSubscription?.enabled &&
                      !getCurrentSubscription?.paymentRequired &&
                      (getCurrentSubscription?.isTrial ||
                        !getCurrentSubscription?.isTrial)
                    ) {
                      chechUserAsDebitOrCredit(true);
                    } else {
                      if (
                        newNavigationLink ===
                        AppRoutes.CONTINUE_REGISTRATION_ROUTE
                      ) {
                        navigate(newNavigationLink);
                      }
                    }
                  }
                }
              }
            );
            loadAddonsService(response.payload[0].subscriptionID);
          } else {
            setCoreLoading(false);

            if (!userRoleFits(["Broker", "FleetSupportAdmin"])) {
              navigate(AppRoutes.CONTINUE_REGISTRATION_ROUTE);
            }
          }
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );

    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  useEffect(() => {
    let DashboardAppRoutingMap = getAnagraphicsRoutes(
      loggedUser,
      addonService,
      menuVoicesExpired
    );

    if (brokerTypeDealer) {
      DashboardAppRoutingMap.push(
        {
          path: AppRoutes.DASHBOARD___DEALER_REQUEST_RECEIVED,
          component: <DealerPageRequest />,
          label: "_request_received",
          icon: <LiveHelpIcon />,
        },
        {
          path: AppRoutes.DASHBOARD___DEALER_OFFERS_SENT,
          component: <DealerPageSent />,
          label: "_offers_sent",
          icon: <MarkEmailReadIcon />,
        }
      );
    }

    setDashboardAppRoutingMap(DashboardAppRoutingMap);
    const plainRoutesMap: IAppRouteInternal[] = [];
    for (let i = 0; i < DashboardAppRoutingMap.length; i++) {
      plainRoutesMap.push({ ...DashboardAppRoutingMap[i] });
      for (
        let j = 0;
        j < (DashboardAppRoutingMap[i].children ?? []).length;
        j++
      ) {
        plainRoutesMap.push({
          ...(DashboardAppRoutingMap[i].children ?? [])[j],
        });
        let children: any = DashboardAppRoutingMap[i].children ?? [];
        if (children && children.length > 0) {
          if (children[j].children !== undefined) {
            for (let k = 0; k < children[j].children.length; k++) {
              plainRoutesMap.push({ ...children[j].children[k] });
            }
          }
        }
      }
    }
    setPlainRoutesMap(plainRoutesMap);
  }, [addonService]);

  const getExtraRoutes = () => {
    let extraRoutes = [
      {
        path: AppRoutes.DASHBOARD___USERPAGE_ROUTE,
        element: <ProfilePage />,
      },
      {
        path: AppRoutes.PAYMENTS_REGISTER,
        element: <PaymentRegister />,
      },
      {
        path: AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE,
        element: <ReduceSubscriptionPage />,
      },
      {
        path: AppRoutes.DASHBOARD___DEALER_PROPOSAL,
        element: <DealerPageProposal />,
      },
      { path: AppRoutes.SEARCH, element: <SearchPage /> },
      {
        path: AppRoutes.SEARCH_VEHICLE_DETAIL + "/*",
        element: <VehiclesPage />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__OFFERS_SEND_OFFER,
        element: <FormOfferPage_Supplier />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__VEHICLE_COMPARE_SUMMARY,

        element: <VehicleConfigurationPageSummary />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__OPEN,
        element: <OpenListPage />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE,
        element: <CloseListPage />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_SUMMARY,
        element: <VehicleRequestsPageSummary />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_ORDERS,
        element: <VehicleRequestsPageOrder />,
      },
      {
        path: AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY_SEARCH,
        element: <VehiclePromptDeliveryPageSearch />,
      },
      { path: AppRoutes.VIEW_SUPPLIES, element: <ViewSuppliesPage /> },
      {
        path: AppRoutes.LOAD_FILE_SUPPLIES,
        element: <LoadFileSupplies />,
      },
      {
        path: AppRoutes.VIEW_TELEPASS_USAGE,
        element: <ViewTelepassUsagePage />,
      },
      {
        path: AppRoutes.LOAD_FILE_TELEPASS_USAGE,
        element: <LoadFileTelepassUsage />,
      },
      {
        path: AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE + "/tab*",
        element: <AnagraphicsPage />,
      },
      {
        path: AppRoutes.DOWNLOAD_DATA_PAGE,
        element: <DownloadDataPage />,
      },
    ];

    if (userRoleFits(["FleetManager"])) {
      extraRoutes.push({
        path: AppRoutes.TICKETS_MANAGEMENT,
        element: <TicketsManagementPage cannotSee={false} />,
      });
    }

    if (
      userRoleFits(["FleetManager"]) &&
      addonService?.find(
        (x: IAddedService) => x.addedServiceCode === "ROAD_TAX_ADDON"
      )
    ) {
      extraRoutes.push({
        path: AppRoutes.MANUAL_PAYMENTS,
        element: <PaymentsPage />,
      });
      extraRoutes.push({
        path: AppRoutes.PAID_PAYMENTS,
        element: <PaymentsPaid />,
      });
      extraRoutes.push({
        path: AppRoutes.PAYMENTS_REGISTER,
        element: <PaymentRegister />,
      });
    }

    return menuVoicesExpired
      ? [<Route element={<ReduceSubscriptionPage />} path={AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE} />]
      : extraRoutes.map((x: any, j: number) => {
          return <Route key={"_" + j} element={x.element} path={x.path} />;
    });
  };
  /* RETURN */
  return (
    <div>
      {getCurrentSubscription &&
        !menuVoicesExpired &&
        getCurrentSubscription.enabled &&
        getCurrentSubscription.isTrial && (
          <Alert severity="warning" sx={{ borderRadius: 0 }}>
            <p
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{
                __html:
                  i18next
                    .t("navigation:awaitTrialModeSubtitle")
                    .replace(
                      "TRIAL_END_DATE",
                      getCurrentSubscription.trialEndDT !== null
                        ? displayUTC0_ISODate(
                            getCurrentSubscription.trialEndDT,
                            true
                          )
                        : displayUTC0_ISODate(
                            getCurrentSubscription.validTo,
                            true
                          )
                    ) ?? "",
              }}
            />
          </Alert>
        )}
      <div className="dashboard-main-wrap">
        <NavMenu routes={dashboardAppRoutingMap} />

        <ImpModal />

        <div className="dashboard-scrollable-content">
          {!coreLoading && (
            <Routes>
              {getExtraRoutes()}
              {plainRoutesMap.map((x: IAppRouteInternal, i: number) => {
                if (x.visible !== false) {
                  return <Route key={i} path={x.path} element={x.component} />;
                } else {
                  return (
                    <Route
                      key={i}
                      path={x.path}
                      element={<NotAuthorizePage />}
                    />
                  );
                }
              })}
            </Routes>
          )}
          {coreLoadingError && (
            <div style={{ padding: "2em" }}>
              <Alert severity="error">
                {i18next.t("error:error_core_load")}
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
