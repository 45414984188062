/* COMPONENTS */
import OwnershipCosts__StatisticsPage_Data from "./OwnershipCosts__StatisticsPage_Data";

/* INTERFACE */
interface IOwnershipCosts__StatisticsPage_Body {
  title?: string | null;
}

/* COMPONENT */
const OwnershipCosts__StatisticsPage_Body = (
  props: IOwnershipCosts__StatisticsPage_Body
) => {
  /* RETURN */
  return (
    <div className="dashboardForm__box">
      {props.title && (
        <h1
          className="text-color-hint"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      )}

      <OwnershipCosts__StatisticsPage_Data />
    </div>
  );
};

export default OwnershipCosts__StatisticsPage_Body;
