import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IStepperBody } from "../../Models/IStepperBody";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const StepperController = {
  StepperGetLastData: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.STEPPER_GET_LAST_DATA}`;
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  StepperGetData: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ApiEndPoints.STEPPER_GET_DATA}`;
    request.port = "7234";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  StepperSetData: async (
    body: IStepperBody,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.STEPPER_SET_DATA;
    request.port = "7234";
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default StepperController;
