import { Alert, Box, Button } from "@mui/material";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import i18next from "i18next";
import { useEffect, useState } from "react";
import {
  IAccountCreditDto,
  IUpdateAccountCreditPayload,
} from "../../../../../Models/IPayment";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
const AdminPage__economicCredits = () => {
  const [credits, setCredits] = useState<IAccountCreditDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [evident, setEvident] = useState<IAccountCreditDto>();

  const getUserCredits = () => {
    setLoading(true);
    ApiService.PaymentController.GetAccountCredit((response: IAPIResponse) => {
      if (response.error === null) {
        setCredits(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setLoading(false);
    });
  };

  const setUserCreditsAsPaid = () => {
    setLoading(true);
    let data: IUpdateAccountCreditPayload = {
      accountCreditID: evident?.accountCreditID ?? 0,
      isPaid: true,
    };
    ApiService.PaymentController.UpdateAccountCredit(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("markAsPaidModal");
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getUserCredits();
  }, []);

  const SetAsPurchased = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        <Button
          //disabled={props.row.isPaid}
          onClick={() => {
            setEvident(props.row);
            SummonModal("markAsPaidModal");
          }}
        >
          <CheckCircleIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: SetAsPurchased,
      hideExport: true,
    },
    {
      field: "creditAmount",
      headerName: i18next.t("navigation:creditAmount") as string,
      type: "number"
    },
    {
      field: "accountName",
      headerName: i18next.t("form:name"),
    },
    {
      field: "accountSurname",
      headerName: i18next.t("form:surname"),
    },
    {
      field: "accountEmail",
      headerName: i18next.t("form:email"),
    },
    {
      field: "createdDT_",
      headerName: i18next.t("navigation:date_created") as string,
    },
    {
      field: "isPaid_",
      headerName: i18next.t("navigation:paid") as string,
    },
    {
      field: "paidDT_",
      headerName: i18next.t("navigation:payd_on_date") as string,
    },
  ];

  return (
    <div>
      <div className="dashboardForm">
        <HeaderPageDashboard
          title={i18next.t("navigation:_user_economic_credits")}
        />

        <div className="dashboardForm__box">
          <Box className="dashboardForm__data">
            <DataGridWrap
              headers={columnsDef}
              rows={credits.map((x: IAccountCreditDto) => {
                (x as any)["createdDT_"] = displayUTC0_ISODate(
                  x.createdDT,
                  true
                );
                (x as any)["paidDT_"] = x.paidDT
                  ? displayUTC0_ISODate(x.paidDT, true)
                  : "-";
                (x as any)["isPaid_"] = x.isPaid
                  ? i18next.t("message:yes")
                  : i18next.t("message:no");
                return x;
              })}
            />
          </Box>
          <SmartModal
            modalUniqueId="markAsPaidModal"
            title={i18next.t("navigation:mark_as_paid")}
            modalInnerComponent={
              <div>
                <Alert severity="info">
                  {i18next
                    .t("navigation:mark_as_paid_long")
                    .replace(
                      "?",
                      ", " +
                        evident?.accountName +
                        " " +
                        evident?.accountSurname +
                        ", " +
                        evident?.creditAmount +
                        " € ?"
                    )}
                </Alert>
                <br />

                <hr />
              </div>
            }
            onAccept={() => {
              setUserCreditsAsPaid();
            }}
            onReject={() => {
              DismissModal("markAsPaidModal");
            }}
          />

          <LoaderBackdrop loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default AdminPage__economicCredits;
