import i18next from "i18next";
import MultiForm, { IMultiFormField } from "../MultiForm/MultiForm";
import "./PdfProAutoForm.scss";
import { IArticleGet } from "../../Models/IArticleGet";
import { ITicketIssuingInstitutionGet } from "../../Models/ITicketIssuingInstitutionGet";
import { useEffect, useState } from "react";
import moment from "moment";
import { JSONPrint } from "../../Utils/Decoder";

export interface IPdfProAutoFormProps {
  data: any;
  onChange: (data: any) => void;
  onSubmit: (data: any) => void;
}

const SummaryEmitEntities = (props: {
  institute: ITicketIssuingInstitutionGet;
}) => {
  if(!props.institute) return null;
  return (
    <div className="iss-institute-box-card">
      <div className="iss-institute-box-line-1">
        {props.institute.ticketIssuingInstitutionName},{" "}
        {props.institute.institutionType}
      </div>
      <div className="iss-institute-box-line-2">
        {props.institute.addressCity}, {props.institute.addressStreet},{" "}
        {props.institute.addressCAP} {props.institute.addressProv}
      </div>
      <div className="iss-institute-box-pec">
        PEC: {props.institute.ticketIssuingInstitutionPEC}
      </div>
    </div>
  );
};

const SummaryArticle = (props: {
  article: IArticleGet;
  onSelected: () => void;
}) => {
  const [canExpand] = useState<boolean>(
    props.article.ticketArticleDescription.length > 150
  );
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className="article-box-card">
      <div
        style={{
          display: "flex",
          alignItems: "start",
          gap: "1em",
        }}
      >
        <input
          id={props.article.ticketArticleID.toString()}
          type="radio"
          name="selected_article"
          onChange={() => {
            props.onSelected();
          }}
        />
        <label htmlFor={props.article.ticketArticleID.toString()}>
          <div
            style={{
              cursor: "pointer",
            }}
            className="article-box-line-1"
          >
            {i18next.t("form:ArticleInfraction")}:{" "}
            <strong>
              {props.article.article}, comma {props.article.comma}
            </strong>
          </div>
        </label>
      </div>
      <br />
      <div className="article-box-line-2">
        {expand
          ? props.article.ticketArticleDescription
          : props.article.ticketArticleDescription.substring(0, 150) +
            (canExpand ? "..." : "")}
      </div>
      {canExpand && !expand && (
        <div
          className="expand-label"
          onClick={() => {
            setExpand(true);
          }}
        >
          {i18next.t("navigation:read_more")}
        </div>
      )}
      {canExpand && expand && (
        <div
          className="expand-label"
          onClick={() => {
            setExpand(false);
          }}
        >
          {i18next.t("navigation:read_less")}
        </div>
      )}

      <hr />
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:sanction")}: {props.article.reducedPayment}
              </div>
            </td>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:full_sanction")}: {props.article.fullPayment}
              </div>
            </td>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:sanction_extra")}:{" "}
                {props.article.extraSanction}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table style={{ marginTop: "1em", width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:PointsDeduction")}:{" "}
                {props.article.licensePointsDeduction
                  ? i18next.t("message:yes")
                  : i18next.t("message:no")}
              </div>
            </td>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:PointsDeducted")}:{" "}
                {props.article.licensePointsDeducted ?? "-"}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:Suspension")}:{" "}
                {props.article.licenseSuspension
                  ? i18next.t("message:yes")
                  : i18next.t("message:no")}
              </div>
            </td>
            <td>
              <div className="article-box-line-1">
                {i18next.t("form:Suspension")}:{" "}
                {props.article.licenseSuspensionPeriod &&
                props.article.licenseSuspensionPeriod !== ""
                  ? props.article.licenseSuspensionPeriod
                  : "-"}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const PdfProAutoForm = (props: IPdfProAutoFormProps) => {
  const getInputs = () => {
    const inputs: IMultiFormField[] = [];

    inputs.push({
      width: 100,
      type: "text",
      name: "licensePlate",
      required: true,
      label: i18next.t("navigation:ticket_plate") ?? "",
      defaultValue: props.data?.licensePlate,
    });

    inputs.push({
      width: 100,
      type: "text",
      name: "verbalNumber",
      required: true,
      label: i18next.t("navigation:ticket_verbal_n") ?? "",
      defaultValue: props.data?.verbalNumber,
    });

    inputs.push({
      width: 40,
      type: "switch",
      name: "toggleDateDropdown",
      label: i18next.t("navigation:scanned_form_switch_date") ?? "",
    });

    if (state.toggleDateDropdown) {
      inputs.push({
        width: 60,
        type: "datetime",
        name: "ticketDateTimeSelected",
        required: true,
        label: i18next.t("navigation:ticket_date") ?? "",
      });
    } else {
      inputs.push({
        width: 60,
        type: "select",
        name: "ticketDateTimeSelected",
        required: true,
        label: i18next.t("navigation:ticket_date") ?? "",
        options:
          props.data?.ticketDateTime &&
          Array.isArray(props.data?.ticketDateTime) &&
          props.data?.ticketDateTime.map((x: any) => {
            return {
              key: x,
              text: moment(x).utc().format("LLLL"),
            };
          }),
      });
    }

    inputs.push({
      width: 100,
      type: "text",
      required: true,
      name: "suggestedArticleNumber",
      label: i18next.t("form:ArticleInfraction") ?? "",
      defaultValue: props.data?.infringementArticle?.suggestedArticleNumber,
    });

    inputs.push({
      width: 100,
      type: "text",
      required: true,
      name: "suggestedCommaNumber",
      label: "Comma",
      defaultValue: props.data?.infringementArticle?.suggestedCommaNumber,
    });

    inputs.push({
      width: 100,
      type: "text",
      required: true,
      name: "suggestedReducedPayment",
      label: i18next.t("form:sanction") ?? "",
      defaultValue:
        props.data?.infringementArticle?.suggestedPayments
          ?.suggestedReducedPayment,
    });

    inputs.push({
      width: 100,
      type: "text",
      required: true,
      name: "suggestedFullPayment",
      label: i18next.t("form:full_sanction") ?? "",
      defaultValue:
        props.data?.infringementArticle?.suggestedPayments
          ?.suggestedFullPayment,
    });

    inputs.push({
      width: 100,
      type: "custom",
      name: "_0",
      element: (
        <div>
          <strong>{i18next.t("navigation:ticket_issuing_istitutions")}</strong>
          {(props.data?.ticketIssuingInstitution ?? []).map(
            (x: ITicketIssuingInstitutionGet, i: number) => {
              return <SummaryEmitEntities institute={x} />;
            }
          )}
        </div>
      ),
    });

    inputs.push({
      width: 100,
      type: "custom",
      name: "_1",
      element: (
        <div>
          {(props.data?.infringementArticle?.ticketArticleDTOs ?? []).length >
            0 && (
            <div>
              <strong>{i18next.t("navigation:ticket_articles")}</strong>
              {(props.data?.infringementArticle?.ticketArticleDTOs ?? []).map(
                (x: IArticleGet, i: number) => {
                  return (
                    <SummaryArticle
                      article={x}
                      onSelected={() => {
                        setState({ ...state, articleSelectedByUser: x });
                      }}
                    />
                  );
                }
              )}
            </div>
          )}
        </div>
      ),
    });

    return inputs;
  };

  const [state, setState] = useState<any>({});

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  return (
    <div>
      <strong>{i18next.t("navigation:scanned_suggested_values")}</strong>
      <MultiForm
        formId="pdf_pro_scan_form"
        formTitle={""}
        suppressLayout
        suppressSubmit
        inputs={getInputs()}
        onChange={setState}
        onSubmit={() => {props.onSubmit(state)}}
      />
    </div>
  );
};

export default PdfProAutoForm;
