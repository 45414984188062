import AppRoutes from "../src/Costants/AppRoutes";
import MissionPage from "./Pages/WhoWeArePage/WhoWeArePage";
import AwaitConfirmationPaymentPage from "./Pages/AwaitConfirmationPaymentPage/AwaitConfirmationPaymentPage";
import ConfirmAccountPage from "./Pages/ConfirmAccountPage/ConfirmAccountPage";
import ContactsPage from "./Pages/ContactsPage/ContactsPage";
import ContinuteRegistrationPage from "./Pages/ContinueRegistrationPage/ContinuteRegistrationPage";
import CountdownPage from "./Pages/CountdownPage/CountdownPage";
import DashboardPage from "./Pages/DashboardPage/DashboardPage";
import DownloadDataPage from "./Pages/DashboardPage/DashBoard_Pages/DownloadDataPage/DownloadDataPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage/ForgotPasswordPage";
import HomePage from "./Pages/HomePage/HomePage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import LogoutPage from "./Pages/LogoutPage/LogoutPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import PaymentDebitPage from "./Pages/PaymentDebitPage/PaymentDebitPage";
import PaymentReadyPage from "./Pages/PaymentReadyPage/PaymentReadyPage";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import ResendEmailPage from "./Pages/ResendEmailPage/ResendEmailPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage/ResetPasswordPage";
import RatesPage from "./Pages/RatesPage/RatesPage";
import FunctionalitiesPage from "./Pages/FunctionalitiesPage/FunctionalitiesPage";
import MarketplacePage from "./Pages/MarketplacePage/MarketplacePage";
import WhyUsPage from "./Pages/WhyUsPage/WhyUsPage";
import StructurePage from "./Pages/StructurePage/StructurePage";
import InfoRecognitionPage from "./Pages/InfoRecognitionPage/InfoRecognitionPage";
import MenuMobilePage from "./Pages/MenuMobilePage/MenuMobilePage";
import ReduceSubscriptionPage from "./Pages/ReduceSubscriptionPage/ReduceSubscriptionPage";
import StoreSubscriptionPage from "./Pages/StoreSubscriptionPage/StoreSubscriptionPage";
import WhoWeArePage from "./Pages/WhoWeArePage/WhoWeArePage";
import ExtraShopPage from "./Pages/ExtraShopPage/ExtraShopPage";
import CookiePolicyPage from "./Pages/CookiePolicyPage/CookiePolicyPage";

/* INTERFACE */
export interface IAppRoute {
  path: string;
  component: JSX.Element;
  requiresLogin: boolean;
}

/* ROUTING MAP */
const AppRoutingMap: IAppRoute[] = [
  {
    path: AppRoutes.HOME_PAGE_ROUTE,
    component: <HomePage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.WHO_WE_ARE_ROUTE,
    component: <WhoWeArePage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.EXTRA_SHOP,
    component: <ExtraShopPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.PAYMENT_READY_PAGE,
    component: <PaymentReadyPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.FUNCITIONALITIES_ROUTE,
    component: <FunctionalitiesPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.RATES_ROUTE,
    component: <RatesPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.MARKETPLACE_ROUTE,
    component: <MarketplacePage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.WHYUS_ROUTE,
    component: <WhyUsPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.STRUCTURE_ROUTE,
    component: <StructurePage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.INFO_RECOGNITION_ROUTE,
    component: <InfoRecognitionPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.CONTACTS_ROUTE,
    component: <ContactsPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.MOBILE_MENU_ROUTE,
    component: <MenuMobilePage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.LOGIN_ROUTE,
    component: <LoginPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.CONTINUE_REGISTRATION_ROUTE,
    component: <ContinuteRegistrationPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.CONFIRM_PASSWORD_RESET,
    component: <ResetPasswordPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.CONFIRM_REGISTRATION,
    component: <ConfirmAccountPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.LOGOUT_ROUTE,
    component: <LogoutPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.INTERNAL_DASHBOARD_WILDCARD,
    component: <DashboardPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.FORGOT_PASSWORD_ROUTE,
    component: <ForgotPasswordPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.RESEND_EMAIL_ROUTE,
    component: <ResendEmailPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.REGISTRATION_ROUTE,
    component: <RegistrationPage />,
    requiresLogin: false,
  },
  {
    path: "*",
    component: <NotFoundPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.AWAIT_CONFIRMATION_PAYMENT,
    component: <AwaitConfirmationPaymentPage />,
    requiresLogin: false,
  },
  {
    path: AppRoutes.PAYMENTS_DEBIT_PAGE,
    component: <PaymentDebitPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.STORE_SUBSCRIPTION_PAGE,
    component: <StoreSubscriptionPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.COOKIE_POLICY_PAGE,
    component: <CookiePolicyPage />,
    requiresLogin: false,
  }
];

export default AppRoutingMap;
