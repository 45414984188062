import './InfoRecognitionPage.scss'
import BannerImage from "../../Assets/ImagesEmbedded/info-riconoscimenti.jpg"
import Premiazione2022 from '../../Assets/ImagesEmbedded/premiazione2022.jpg'
import Premiazione2023 from '../../Assets/ImagesEmbedded/premiazione2023.jpg'
import i18next from 'i18next'

const InfoRecognitionPage = () => {
    return (
        <div>
          <div className="banner-image">
          <div className="title-banner">{i18next.t('intro:InfoRecognitions')}</div>
        <img src={BannerImage} alt='' />
      </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              minHeight: "60vh",
            }}
          >
            <div>
            <div className='section-wrapper'>
                <div className='mid-screen-text'>
                  <div>
                  <h1 className="title-background">{i18next.t('intro:MFA2022_title')}</h1>
                <p>{i18next.t('intro:MFA2022_description')}</p>
                </div>
                </div>
                <div className='mid-screen-image'>
                  <img src={Premiazione2022}  alt='' className='image'/>
                </div>
            </div>
            <div className='section-wrapper'>
                <div className='mid-screen-image'>
                  <img src={Premiazione2023}  alt='' className='image'/>
                </div>
                <div className='mid-screen-text'>
                  <div>
                  <h1 className="title-background">{i18next.t('intro:MFA2023_title')}</h1>
                <p>{i18next.t('intro:MFA2023_description')}</p>
                </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      );
}

export default InfoRecognitionPage