import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IDELETE_FAQ, IINSERT_FAQ, IInsertFaqCategory, IUPDATE_FAQ, IUpdateFaqCategory } from "../../Models/IFAQ";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

/* MODELS */

const FAQController = {
  GET_FAQ_CATEGORY: async (
    faqCategoryID?: number,
    languageID?: number,
    allTranslations?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_FAQ_CATEGORY;
    let parameters: string[] = [];
    if (faqCategoryID) {
      parameters.push(`faqCategoryID=${faqCategoryID}`);
    }
    if (languageID) {
      parameters.push(`languageID=${languageID}`);
    }
    if (allTranslations !== undefined) {
      parameters.push(`allTranslations=${allTranslations}`);
    }
    if(parameters.length > 0){
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  GET_FAQ_VISIBILITY: async (
    faqVisibilityID?: number,
    languageID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_FAQ_VISIBILITY;
    let parameters: string[] = [];
    if (faqVisibilityID) {
      parameters.push(`faqVisibilityID=${faqVisibilityID}`);
    }
    if (languageID) {
      parameters.push(`languageID=${languageID}`);
    }
    if (faqVisibilityID || languageID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  GET_FAQ_BY_CATEGORY: async (
    faqCategoryID: number,
    languageID?: number,
    faqVisibilityID?: number,
    faqSearchText?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.GET_FAQ_BY_CATEGORY;
    let parameters: string[] = [];
    if (faqCategoryID) {
      parameters.push(`faqCategoryID=${faqCategoryID}`);
    }
    if (languageID) {
      parameters.push(`languageID=${languageID}`);
    }
    if (faqVisibilityID) {
      parameters.push(`faqVisibilityID=${faqVisibilityID}`);
    }
    if (faqSearchText) {
      parameters.push(`faqSearchText=${faqSearchText}`);
    }
    if (parameters.length > 0 ) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  INSERT_FAQ: async (
    body: IINSERT_FAQ,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_FAQ;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  UPDATE_FAQ: async (
    body: IUPDATE_FAQ,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_FAQ;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  DELETE_FAQ: async (
    body: IDELETE_FAQ,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DELETE_FAQ;
    request.method = "DELETE";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertFaqCategory: async (
    body: IInsertFaqCategory,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.INSERT_FAQ_CATEGORY;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateFaqCategory: async (
    body: IUpdateFaqCategory,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.UPDATE_FAQ_CATEGORY;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteFaqCategory: async (
    faqCategoryID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.DELETE_FAQ_CATEGORY;
    request.method = "DELETE";
    request.payload = {
      faqCategoryID: faqCategoryID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FAQController;
