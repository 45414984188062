import i18next from "i18next";
import "./PrettyTable.scss";
import {
  Alert,
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FilterListIcon from "@mui/icons-material/FilterList";
import Switch from "@mui/material/Switch";
import { GridColumnIcon } from "@mui/x-data-grid";
import { columnResizePlugin } from "../../Utils/ResizeTableHandler";

import moment from "moment";

export interface IPaginatorProps {
  max: number;
  emitPage: (page: number, perPage: number) => void;
  hidePagination?: boolean;
}

export const PAGE_SIZE_OPTIONS = [10, 15, 20, 50, 100];

const isObject = (variable: any) => {
  return typeof variable === "object" && !Array.isArray(variable) && variable !== null;
};

const Paginator = (props: IPaginatorProps) => {
  const DEFAULT_INITIAL_PAGES = 15;
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(DEFAULT_INITIAL_PAGES);

  useEffect(() => {
    props.emitPage(page, perPage);
  }, [page, perPage]);

  const maxPage = Math.ceil(props.max / perPage);
  const currPage = props.max === 0 ? 0 : page + 1;
  const displayBottom = perPage * page + 1;
  const displayTop = currPage * perPage;
  const maxDisplay = displayTop > props.max ? props.max : displayTop;
  const minDisplay = displayBottom > props.max ? props.max : displayBottom;

  return (
    <div>
      {props.max > PAGE_SIZE_OPTIONS[0] && !props.hidePagination && (
        <div className="pagination-main-wrap">
          <IconButton
            aria-label="delete"
            disabled={page === 0}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <div style={{ opacity: "0.6" }}>
            {i18next.t("navigation:_prettyTable_page")} {currPage}{" "}
            {i18next.t("navigation:_prettyTable_of")} {maxPage},{" "}
            {i18next.t("navigation:_prettyTable_rows_from")}
            {minDisplay} {i18next.t("navigation:_prettyTable_rows_to")} {maxDisplay}{" "}
            {i18next.t("navigation:_prettyTable_of")}
            {props.max}, {i18next.t("navigation:_prettyTable_per_page")}:
            <select
              defaultValue={DEFAULT_INITIAL_PAGES}
              className="paginator-select"
              onChange={(e: any) => {
                setPage(0);
                setPerPage(e.target.value);
              }}
            >
              {PAGE_SIZE_OPTIONS.map((x: number, i: number) => {
                return (
                  <option key={i} value={x}>
                    {x}
                  </option>
                );
              })}
            </select>
          </div>
          <IconButton
            aria-label="delete"
            disabled={currPage >= maxPage}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const extractItemValueOnPath = (item: any, path: string) => {
  try {
    let dotPath: string[] = path.split(".");
    for (let i = 0; i < dotPath.length; i++) {
      item = item[dotPath[i]];
    }
    return item;
  } catch (e) {
    return null;
  }
};

export interface IPrettyTableProps {
  title?: string;
  headers: string[];
  headerToPrint?: string[];
  rows: {valueExternal: string | number, valueInternal: string | number }[][];
  onRowClick?: (row: (string | number)[]) => void;
  hideToolbar?: boolean;
  hidePagination?: boolean;
  rowKeyToSkip?: number[];
  columnsType?: string[]
}

interface IFilterColumn {
  headerName: string;
  filterType: string;
  filterValue: string | number;
}

const PrettyTable = (props: IPrettyTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [filterText, setFilterText] = useState<string>("");
  const [c_filterText, setC_FilterText] = useState<string>("");
  const [activeFilterText, setActiveFilterText] = useState<string>("");
  const [anchorEl_1, setAnchorEl_1] = useState<null | HTMLElement>(null);
  const open_1 = Boolean(anchorEl_1);
  const [anchorEl_2, setAnchorEl_2] = useState<null | HTMLElement>(null);
  const open_2 = Boolean(anchorEl_2);
  const [anchorEl_3, setAnchorEl_3] = useState<null | HTMLElement>(null);
  const open_3 = Boolean(anchorEl_3);

  const [filters, setFilters] = useState<IFilterColumn[]>([]);

  const [sortOnColumn, setSortOnColumn] = useState<{
    order: number;
    index: number;
  } | null>(null);

  const [rowStyle, setRowStyle] = useState<number>(1);
  const [allowedColumns, setAllowedColumns] = useState<string[]>(props.headers);

  const changeRowStyle = (value: number) => {
    setRowStyle(value);
    setAnchorEl_1(null);
  };

  const handleClick_3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_3(event.currentTarget);
  };

  const handleClick_1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_1(event.currentTarget);
  };

  const handleClick_2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_2(event.currentTarget);
  };
  const handleClose_2 = () => {
    setAnchorEl_2(null);
    setTimeout(() => {
      setC_FilterText("");
    }, 200);
  };

  const testFiltersOnRow = (row: any[]) => {
    for (let i = 0; i < filters.length; i++) {
      let f: IFilterColumn = filters[i];
      let columnIndex: number = props.headers
        .filter((x: string) => allowedColumns.includes(x))
        .indexOf(f.headerName);

      if (columnIndex !== -1) {
        let cell: any = row[columnIndex];
        let value: string = (cell ? cell.toString() : "").toLowerCase();
        let fValue: string = f.filterValue.toString().toLowerCase();

        let numValue = 0;
        let fNumValue = 0;
        if (
          !isNaN(value as any) &&
          !isNaN(fValue as any) &&
          ["grt", "lrt"].includes(f.filterType)
        ) {
          numValue = +value;
          value = "";
          fNumValue = +fValue;
          fValue = "";
        }

        if (f.filterType === "equals" && value !== fValue) {
          return false;
        }
        if (f.filterType === "differs" && value === fValue) {
          return false;
        }
        if (f.filterType === "contans" && !value.includes(fValue)) {
          return false;
        }
        if (f.filterType === "containsNot" && value.includes(fValue)) {
          return false;
        }
        if (f.filterType === "grt" && (fValue > value || fNumValue > numValue)) {
          return false;
        }
        if ((f.filterType === "lrt" && fValue < value) || fNumValue < numValue) {
          return false;
        }
      }
    }

    return true;
  };

  const extractCellValue = (x: any) => {
    if (!x) {
      return "";
    }
    if (isObject(x) && x["$$typeof"].toString().includes("react.element")) {
      return x.props.id;
    }

    return x.toString();
  };

  const getActiveRows = () => {
    let activeRows: any = [...props.rows];

    let srcTxt = activeFilterText?.toLowerCase();
    let srcTxNoSpace = activeFilterText?.toLowerCase().replaceAll(" ", "");

    activeRows = sortOnColumnRoutine(activeRows);
    activeRows = activeRows.filter((x: any[], i: number) => {
      for (let j = 0; j < x.length; j++) {
        let x_: string = extractCellValue(x[j]);
        if (x_ !== undefined && x_ !== null) {
          let str_val = x_?.toString()?.toLowerCase();
          if ((str_val && str_val.includes(srcTxt)) || str_val.includes(srcTxNoSpace)) {
            return true;
          }
        }
      }
      return false;
    });

    activeRows = activeRows.filter((x: any[]) => testFiltersOnRow(x));

    return activeRows;
  };

  const getActivePaginatedRows = (allRows?: boolean) => {
    let activeRows = getActiveRows();
    if(!allRows){
      activeRows = activeRows.filter(
        (x: any, i: number) => page * perPage <= i && i < (page + 1) * perPage
      );
    }
    return activeRows;
  };

  const downloadAsCSV = () => {
    // Select rows from table_id
    let rows: any[][] = getActivePaginatedRows(true);
    // Construct csv
    var csv = [(props?.headerToPrint ?? props.headers).join(",")];
    for (let i = 0; i < rows.length; i++) {
      let row: string[] = [];
      for (let j = 0; j < rows[i].length; j++) {
        if(!(props.rowKeyToSkip ?? []).includes(j)){
          let item: any = rows[i][j];
          if (isObject(item) && item["$$typeof"].toString().includes("react.element")) {
            item = item.props.id ?? "";
          }
          row.push(item?.toString().replaceAll(" €", ""));
        }
      }
      csv.push(row.join(","));
    }
    let csv_string = csv.join("\n");

    let filenameTitle = props.title ?? "resume";

    if (!props.title) {
      let h: any = document.getElementById("page-header");
      if (h) {
        filenameTitle = h.innerHTML;
      }
      let m: any = document.getElementById("modal-title");
      if (m) {
        filenameTitle = h.innerHTML;
      }
    }

    // Download it
    let filename =
      filenameTitle.replaceAll(" ", "").toLowerCase() +
      "__" +
      new Date().toLocaleDateString() +
      ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,\n" + encodeURI(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const search = () => {
    setActiveFilterText(filterText);
  };

  const sortOnColumnRoutine = (rows: {valueExternal: string | number, valueInternal: string | number }[][]) => {
    if (sortOnColumn) {
      return rows.sort((a: any, b: any) => {
        let valueOnColumn_A: any = a[sortOnColumn?.index].valueInternal;
        let valueOnColumn_B: any = b[sortOnColumn?.index].valueInternal;

        // is a react node (value is stored in node id)
        if (
          isObject(valueOnColumn_A) &&
          valueOnColumn_A["$$typeof"].toString().includes("react.element")
        ) {
          valueOnColumn_A = valueOnColumn_A.props.id;
        }

        // is a react node (value is stored in node id)
        if (
          isObject(valueOnColumn_B) &&
          valueOnColumn_B["$$typeof"].toString().includes("react.element")
        ) {
          valueOnColumn_B = valueOnColumn_B.props.id;
        }

        let asText_a: any = valueOnColumn_A ? valueOnColumn_A.toString() : "";
        let asText_b: any = valueOnColumn_B ? valueOnColumn_B.toString() : "";

        if(moment(valueOnColumn_B ?? undefined).isValid() && moment(valueOnColumn_A ?? undefined).isValid()){
          
          // A is null, B is not, A goes last
          if (valueOnColumn_A === null) 
            return 1;
          // B is null, A is not, B goes last
          if (valueOnColumn_B === null) 
            return -1;
          const dateA = new Date(valueOnColumn_A).getTime();
          const dateB = new Date(valueOnColumn_B).getTime();
         
          if (sortOnColumn.order === 1) {
            return dateA - dateB;
          }else{
            return dateB - dateA;
          }
        }else{ 
          if(isNaN(valueOnColumn_A)){
            if (sortOnColumn.order === 1) {
              return (asText_a ?? "").localeCompare(asText_b ?? "");
            } else {
              return (asText_b ?? "").localeCompare(asText_a ?? "");
            }
          }else{
            if (sortOnColumn.order === 1) {
              return asText_a - asText_b;
            } else {
              return ( asText_b - asText_a);
            }
          }
        }
      }).map((x: {valueExternal: string | number, valueInternal: string | number }[]) => {
        return x.map((y: {valueExternal: string | number, valueInternal: string | number }) => {
          return y.valueExternal;
        }
      )});
    } else {
      return rows.map((x: {valueExternal: string | number, valueInternal: string | number }[]) => {
        return x.map((y: {valueExternal: string | number, valueInternal: string | number }) => {
          return y.valueExternal;
        });
      });
    }
  };

  useEffect(() => {
    columnResizePlugin();
  }, [props.rows, allowedColumns]);

  let activeRows: any[][] = getActivePaginatedRows();

  const FILTERS = [
    {
      text: i18next.t("form:equals"),
      value: "equals",
    },
    {
      text: i18next.t("form:differs"),
      value: "differs",
    },
    {
      text: i18next.t("form:contans"),
      value: "contans",
    },
    {
      text: i18next.t("form:containsNot"),
      value: "containsNot",
    },
    {
      text: i18next.t("form:grt"),
      value: "grt",
    },
    {
      text: i18next.t("form:lrt"),
      value: "lrt",
    },
  ];

  const getCellName = (content: any) => {
    if (content) {
      let str = content.toString();
      if (!str.includes("Object")) {
        return str;
      }
    }
    return "";
  };

  return (
    <div>
      {props.rows.length > 0 && !props.hideToolbar && (
        <div className="pretty-table-filter-zone">
          <div>
            <Button
              startIcon={<TableRowsIcon />}
              variant={"text"}
              onClick={handleClick_1}
            >
              {i18next.t("navigation:rowsize")}
            </Button>
            <Button
              startIcon={<GridColumnIcon />}
              variant={"text"}
              onClick={handleClick_2}
            >
              {i18next.t("navigation:columns")}
            </Button>
            <Button startIcon={<DownloadIcon />} variant={"text"} onClick={downloadAsCSV}>
              {i18next.t("navigation:export")}
            </Button>
            <Button
              onClick={handleClick_3}
              startIcon={<FilterListIcon />}
              variant={"text"}
            >
              {i18next.t("navigation:filter")}
            </Button>

            <Menu
              id="basic-menu-filter"
              anchorEl={anchorEl_3}
              open={open_3}
              onClose={() => {
                setAnchorEl_3(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div className="pretty-table-filter-row-list">
                {filters.map((x: IFilterColumn, i: number) => {
                  return (
                    <div key={i} className="pretty-table-filter-row">
                      <IconButton
                        onClick={() => {
                          setFilters(
                            [...filters].filter((k: IFilterColumn, j: number) => j !== i)
                          );
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <select
                        value={filters[i].headerName}
                        onChange={(e: any) => {
                          let f: IFilterColumn[] = [...filters];
                          f[i].headerName = e.target.value;
                          setFilters(f);
                        }}
                        className="pretty-table-filter-row-select"
                      >
                        {props.headers
                          .filter((x: string) =>
                            x.toLowerCase().includes(c_filterText.toLowerCase())
                          )
                          .filter(
                            (x: string) =>
                              filters.filter((k: IFilterColumn) => k.headerName === x)
                                .length === 0
                          )
                          .concat([filters[i].headerName])
                          .map((x: string, i: number) => {
                            return (
                              <option key={i} value={x}>
                                {x}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        value={filters[i].filterType}
                        onChange={(e: any) => {
                          let f: IFilterColumn[] = [...filters];
                          f[i].filterType = e.target.value;
                          setFilters(f);
                        }}
                        className="pretty-table-filter-row-select"
                      >
                        {FILTERS.map((l: { text: string; value: string }, i: number) => {
                          return (
                            <option key={i} value={l.value}>
                              {l.text}
                            </option>
                          );
                        })}
                      </select>
                      <TextField
                        value={filters[i].filterValue}
                        size="small"
                        onChange={(e: any) => {
                          let f: IFilterColumn[] = [...filters];
                          f[i].filterValue = e.target.value;
                          setFilters(f);
                        }}
                      ></TextField>
                    </div>
                  );
                })}
                <div className="row-on-right">
                  <Button
                    variant="contained"
                    onClick={() => {
                      let f: IFilterColumn[] = [...filters];
                      f.push({
                        headerName: "",
                        filterValue: "",
                        filterType: "equals",
                      });
                      setFilters(f);
                    }}
                  >
                    {i18next.t("navigation:add")}
                  </Button>
                </div>
              </div>
            </Menu>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl_1}
              open={open_1}
              onClose={() => {
                setAnchorEl_1(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  changeRowStyle(0);
                }}
              >
                {i18next.t("generic:rowStyle_1")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeRowStyle(1);
                }}
              >
                {i18next.t("generic:rowStyle_2")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeRowStyle(2);
                }}
              >
                {i18next.t("generic:rowStyle_3")}
              </MenuItem>
            </Menu>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl_2}
              open={open_2}
              onClose={handleClose_2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div>
                <TextField
                  style={{ margin: "0.2em 1em" }}
                  placeholder={i18next.t("generic:Search") ?? ""}
                  variant={"standard"}
                  onChange={(e: any) => {
                    setC_FilterText(e.target.value);
                  }}
                />
                <div style={{ maxHeight: "20em", overflowY: "auto" }}>
                  {props.headers
                    .filter((x: string) =>
                      x.toLowerCase().includes(c_filterText.toLowerCase())
                    )
                    .map((x: string, i: number) => {
                      return (
                        <div key={i} style={{ padding: "0.5em" }}>
                          <FormControlLabel
                            onChange={(e: any, checked: boolean) => {
                              if (checked) {
                                setAllowedColumns(allowedColumns.concat([x]));
                              } else {
                                setAllowedColumns(
                                  allowedColumns.filter((y: string) => y !== x)
                                );
                              }
                            }}
                            control={
                              <Switch defaultChecked={allowedColumns.includes(x)} />
                            }
                            label={x.replace(/(<([^>]+)>)/ig, "")}
                          />
                          <div
                            style={{
                              borderBottom: "1px solid rgba(0,0,0,0.1)",
                            }}
                          ></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Menu>
          </div>

          <div>
            <TextField
              variant={"standard"}
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
              placeholder={i18next.t("generic:Search") ?? ""}
            />
            {activeFilterText === "" && (
              <SearchIcon onClick={search} className="on-input-icon" />
            )}
            {activeFilterText !== "" && (
              <ClearIcon
                onClick={() => {
                  setFilterText("");
                  setActiveFilterText("");
                }}
                className="on-input-icon"
              />
            )}
          </div>
        </div>
      )}

      {activeRows.length === 0 && (
        <div style={{ margin: "1em 0" }}>
          <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
        </div>
      )}

      {activeRows.length > 0 && (
        <div className="pretty-table-main-wrap-outer">
          <div className="pretty-table-main-wrap">
            <table id="prettytable" className="resume-details-table-wrap ">
              <thead>
                <tr>
                  {props.headers
                    .filter((x: string) => allowedColumns.includes(x))
                    .map((x: string, i: number) => {
                      return (
                        <th
                          className="sticky-header"
                          key={i}
                          onClick={() => {
                            if (sortOnColumn) {
                              // same header, do reverse
                              if (sortOnColumn.index === i && sortOnColumn.order === 1) {
                                setSortOnColumn({
                                  index: i,
                                  order: -1,
                                });
                                return;
                              }
                              // same header reversed, disable
                              if (sortOnColumn.index === i && sortOnColumn.order === -1) {
                                setSortOnColumn(null);
                                return;
                              }

                              // other header
                              if (sortOnColumn.index !== i) {
                                setSortOnColumn({
                                  index: i,
                                  order: 1,
                                });
                                return;
                              }
                            } else {
                              setSortOnColumn({
                                index: i,
                                order: 1,
                              });
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "0.5em",
                              position: "relative",
                            }}
                          >
                            <div className="header-cell" dangerouslySetInnerHTML={{__html: x.toLocaleUpperCase()}} />
                            {sortOnColumn?.index === i && sortOnColumn?.order === 1 && (
                              <div className="sort-table-icon-column">
                                <ArrowDropDownIcon />
                              </div>
                            )}
                            {sortOnColumn?.index === i && sortOnColumn?.order === -1 && (
                              <div className="sort-table-icon-column">
                                <ArrowDropUpIcon />
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {activeRows.map((x: (string | number)[], i: number) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        if (props.onRowClick) {
                          props.onRowClick(x);
                        }
                      }}
                    >
                      {x
                        .filter((x: string | number, j: number) =>
                          allowedColumns.includes(props.headers[j])
                        )
                        .map((y: string | number, j: number) => {
                          return (
                            <td className={"content-td-" + rowStyle} key={j}>
                              <Tooltip title={getCellName(y)} placement="top-start">
                              <div className={"content-td-inner" +
                              ((props.columnsType && props.columnsType[j] === 'number') ? ' content-td-inner-number' : '') +
                              ((props.columnsType && props.columnsType[j] === 'custom') ? ' content-td-inner-custom' : '')}>{y}</div>
                              </Tooltip>
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Paginator
        max={getActiveRows().length}
        emitPage={(page: number, perPage: number) => {
          setPage(page);
          setPerPage(perPage);
        }}
        hidePagination={props.hidePagination}
      />
    </div>
  );
};

export default PrettyTable;
