/* REACT */
import { useEffect, useState } from "react";

/* API */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* LIBRARIES  */
import i18next from "i18next";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import {
  ISupplierGetType,
  ISupplierInsertGeneric,
  ISupplierUpdateGeneric,
  ISupplierDeleteGeneric,
} from "../../../../../Models/ISupplier";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* UTILS */
import {
  validationVAT,
  validationPhone,
} from "../../../../../Utils/Validation";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import { IGoogleAddress } from "../../../../../Utils/Google";

/* MUI */
import { Button } from "@mui/material";
// Icons
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

/*INTERFACE*/
export interface IAdminPage__SuppliersForm {
  loggedUser: IUser | undefined;
  data: any;
  idModal?: string;
  doRefreshForm?: () => void;
}

/* COMPONENT */
const AdminPage__SuppliersForm = (props: IAdminPage__SuppliersForm) => {
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(props.data);

  // State for supplier list select
  const [supplierTypeList, setSupplierTypeList] = useState<ISupplierGetType[]>(
    []
  );

  // State for spinning the process
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  /* APIs */
  /* GET */
  // API to load suppliers select list - the first select
  const loadSupplierList = () => {
    setLoadingSupplierList(true);
    if (props.loggedUser) {
      ApiService.SupplierController.SupplierGetType(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierTypeList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  /* INSERT */
  // API to insert a generic supplier
  const insertGenericSupplier = (data: ISupplierInsertGeneric) => {
    setLoadingInsert(true);
    ApiService.SupplierController.SupplierInsertGeneric(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullAdded"),
            "success"
          );
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
          setState({}); // Clean the state so inputs are empty
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingInsert(false);
      }
    );
  };

  /* UPDATE */
  // API to update the generic supplier
  const updateGenericSupplier = (data: ISupplierUpdateGeneric) => {
    setLoadingUpdate(true);
    ApiService.SupplierController.SupplierUpdateGeneric(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullUpdated"),
            "success"
          );
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingUpdate(false);
      }
    );
  };

  /* DELETE */
  // API to delete the generic supplier
  const deleteGenericSupplier = (data: ISupplierDeleteGeneric) => {
    setLoadingDelete(true);
    if (data === null) {
      ToastMessage(i18next.t("message:supplierNotDeleted"), "warning");
    }
    ApiService.SupplierController.SupplierDeleteGeneric(
      data.genericSupplierID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:supplierSuccessfullDeleted"),
            "success"
          );
          setState({}); // Clean the state so inputs are empty
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDelete(false);
      }
    );
  };

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push({
      width: 50,
      type: "select",
      name: "supplierTypeID",
      defaultValue: state?.supplierTypeID,
      required: true,
      label: String(i18next.t(`form:suppliersTypePlaceholderSelect`)),
      options: supplierTypeList.map((supplierType: ISupplierGetType) => {
        return {
          key: supplierType.supplierTypeID.toString(),
          text: supplierType.supplierTypeName.toString(),
        };
      }),
    });

    multiformInputs.push(
      {
        width: 50,
        type: "text",
        name: "supplierName",
        defaultValue: state?.supplierName,
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:nameSupplier")}`,
      },
      {
        width: 34,
        type: "text",
        name: "supplierVAT",
        defaultValue: state?.supplierVAT,
        required: true,
        inputProps: { maxLength: 13 },
        label: `${i18next.t("form:vatNumber")}`,
        errorText:
          state?.supplierVAT &&
          state?.supplierVAT.length > 0 &&
          !validationVAT(state?.supplierVAT)
            ? (i18next.t("form:validationIVA") as string)
            : "",
      },
      {
        width: 33,
        type: "email",
        name: "supplierPEC",
        defaultValue: state?.supplierPEC,
        required: true,
        inputProps: { maxLength: 100 },
        label: `${i18next.t("form:pec")}`,
      },
      {
        width: 33,
        type: "tel",
        name: "supplierPhone",
        defaultValue: state?.supplierPhone,
        required: true,
        label: String(i18next.t("form:contactCenter")),
        errorText:
          state?.supplierPhone &&
          state?.supplierPhone.length > 0 &&
          !validationPhone(state?.supplierPhone)
            ? (i18next.t("form:validationPhone") as string)
            : "",
      }
    );

    multiformInputs.push({
      width: 100,
      fullWidth: true,
      type: "custom",
      name: "PlaceAutoCompleteForm",
      className: "content-place-auto-complete",
      element: (
        <PlaceAutoCompleteForm
          onChange={(data: IGoogleAddress) => {
            setState({ ...state, ...data });
          }}
          addressId="supplierAddressStreet"
          disableForm={processing}
          inputs={[
            {
              width: 70,
              type: "text",
              name: "supplierAddressStreet",
              defaultValue: state?.supplierAddressStreet,
              mapDecode: "route",
              label: String(i18next.t("form:addressLegal")),
            },
            {
              width: 30,
              type: "text",
              name: "supplierAddressNumber",
              defaultValue: state?.supplierAddressNumber,
              mapDecode: "street_number",
              label: String(i18next.t("form:civicNumberAbbr")),
            },
            {
              width: 100,
              type: "select_nations",
              name: "supplierAddressCountryID",
              currentValue: state?.supplierAddressCountryID ?? "",
              defaultValue: state?.supplierAddressCountryID,
              mapDecode: "country",
              label: String(i18next.t("form:countryResidence")),
            },
            {
              width: 25,
              type: "text",
              name: "supplierAddressCAP",
              defaultValue: state?.supplierAddressCAP,
              mapDecode: "postal_code",
              inputProps: { maxLength: 20 },
              label: String(i18next.t("form:postalCode")),
            },
            {
              width: 50,
              type: "text",
              name: "supplierAddressCity",
              defaultValue: state?.supplierAddressCity,
              mapDecode: "locality",
              label: String(i18next.t("form:city")),
            },
            {
              width: 25,
              type: "text",
              name: "supplierAddressProv",
              defaultValue: state?.supplierAddressProv,
              maxLength: 5,
              mapDecode: "province",
              label: String(i18next.t("form:provinceAbbr")),
            },
          ]}
        />
      ),
    });

    multiformInputs.push({
      width: 100,
      type: "custom",
      name: "save",
      element: (
        <div className="display-flex-end">
          <Button
            color="error"
            sx={{ marginRight: "1em" }}
            disabled={!state?.genericSupplierID}
            onClick={() => {
              SummonModal("StepSupplier-modal");
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            {i18next.t("form:deleteSupplier")}
          </Button>

          <Button
            variant="contained"
            disabled={processing}
            onClick={() => {
              triggerFormValidation("StepSuppliers");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveSupplier")}
          </Button>

          <SmartModal
            modalUniqueId="StepSupplier-modal"
            title={i18next.t("generic:titleRecordModal")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModal")
                    .replace("CURRENT_ITEM_LIST", state?.supplierName),
                }}
              />
            }
            onAccept={() => {
              deleteGenericSupplier(state);
            }}
            onReject={() => {}}
          />
        </div>
      ),
    });

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Init country and account type API
  useEffect(() => {
    loadSupplierList();
  }, []);

  /* RETURN */
  return (
    <>
      <MultiForm
        formId={"StepSuppliers"}
        suppressLayout
        formTitle={"StepSuppliers"}
        classNameForm="multi-form-flex"
        inputs={multiformInputs()}
        suppressSubmit={true}
        onChange={async (data: any) => {
          setState({ ...state, ...data });
        }}
        onSubmit={async () => {
          let parsedState = { ...state };

          // Delete for the payload the unuseful state
          delete parsedState["save"];
          delete parsedState["PlaceAutoCompleteForm"];

          // Lunch the API based on the genericSupplierID
          // (if present the element already exist so UPDATE, if not INSERT)
          if (
            parsedState.genericSupplierID &&
            parsedState.genericSupplierID > 0
          ) {
            updateGenericSupplier(parsedState);
          } else {
            insertGenericSupplier(parsedState);
          }
        }}
      />

      <LoaderBackdrop
        loading={
          loadingSupplierList || loadingInsert || loadingUpdate || loadingDelete
        }
      />
    </>
  );
};

export default AdminPage__SuppliersForm;
