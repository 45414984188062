import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import MultiForm from "../../Components/MultiForm/MultiForm";
import AppRoutes from "../../Costants/AppRoutes";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { validationPassword } from "../../Utils/Validation";
import "./ResetPasswordPage.scss";
import i18next from "i18next";

const ResetPasswordPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const token = params.get("token");

  const changePassword = (password: string) => {
    setLoading(true);
    ApiService.AccountController.ResetPassword(
      token,
      password,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:passwordReset"), "success");
          navigate(AppRoutes.LOGIN_ROUTE);
        } else {
          ToastMessage(response.error, "error");
          setError(true);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (!token) {
      setError(true);
    }
  }, [token]);

  return (
    <div className="reset-password-page-wrap">
      <div
        className="reset-password-main-text"
        dangerouslySetInnerHTML={{
          __html: error ? i18next.t("error:RESET_PASSWORD_ERROR") ?? "" : "",
        }}
      ></div>
      {!error && (
        <MultiForm
          onChange={(data: any) => {
            setPassword(data.password);
          }}
          formId={"resetPassword"}
          formTitle={i18next.t("generic:reset_password")}
          onSubmit={(data: any) => {
            setPassword(data.password);
            setConfirmPassword(data.c_password);

            let valid = validationPassword(data.password);
            if (valid !== "") {
              return;
            }

            if (data.password !== data.c_password) {
              ToastMessage(i18next.t("form:validationPassword"), "error");
            } else {
              changePassword(data.password);
            }
          }}
          inputs={[
            {
              width: 50,
              type: "password",
              name: "password",
              label: "Password",
              required: true,
              errorText: validationPassword(password),
            },
            {
              width: 50,
              type: "password",
              name: "c_password",
              label: i18next.t("form:passwordConfirm") as string,
              required: true,
              errorText:
                password !== confirmPassword && confirmPassword !== ""
                  ? (i18next.t("form:validationPassword") as string)
                  : "",
            },
          ]}
        />
      )}
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default ResetPasswordPage;
