import { Box, Button, ButtonGroup } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import i18next from "i18next";
import DataGridWrap, { IDataGridColumnDef } from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { DismissModal, SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import { useEffect, useState } from "react";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { useSelector } from "react-redux";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from '@mui/icons-material/EditOff';
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import FaqCategoryForm from "../../../../../Components/FaqCategoryForm/FaqCategoryForm";
import { triggerFormValidation } from "../../../../../Components/MultiForm/MultiForm";
import { IGET_FAQ_CATEGORY } from "../../../../../Models/IFAQ";
import { set } from "rsuite/esm/utils/dateUtils";

const AdminPage__FaqCategories = () => {
  const [loadingFaqCategories, setLoadingFaqCategories] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [faqCategories, setFaqCategories] = useState<IGET_FAQ_CATEGORY[]>([]);
  const [faqCategoriesObject, setFaqCategoriesObject] = useState<IGET_FAQ_CATEGORY[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentFaqCategory, setCurrentFaqCategory] = useState<any>(null);
  const [rowsDef, setRowDef] = useState<any>([]);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const loadFaqCategories = () => {
    if(loggedUser){
      setLoadingFaqCategories(true);
      ApiService.FAQController.GET_FAQ_CATEGORY(
        null, 
        null, 
        true,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFaqCategories(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFaqCategories(false);
        }
      );
    }
  };

  const deleteFaqCategory = (faqCategoryID: number) => {
    setProcessing(true);
    ApiService.FAQController.DeleteFaqCategory(
      faqCategoryID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:faqCategorySuccessfullDeleted"), "success");
          setFaqCategories(faqCategories.filter((x: any) => x.faqCategoryID !== faqCategoryID));
          DismissModal("faq-category-page-modal");
          DismissModal("faq-category-page-delete-modal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            let faqCategory = faqCategoriesObject.filter(
              (x: any) => x.faqCategoryID === props.row.faqCategoryID
            )[0];
            setIsEdit(true);
            setCurrentFaqCategory(faqCategory);
            SummonModal("faq-category-page-modal");
          }}
        >
          {props.row.stored ?
            <EditOffIcon className="icon-table" />
          :
            <EditIcon className="icon-table" />
          }
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: renderEdit,
      hideExport: true,
      type: 'custom'
    },
    {
      field: "faqCategoryName",
      headerName: i18next.t("navigation:faqCategoryName"),
    },
  ];

  const servButtons = () => {
    let btns = [];

    btns.push({
      text: isEdit ? i18next.t("form:save_langs") : i18next.t("form:save"),
      onClick: () => {
        triggerFormValidation("faq-category-form-modal");
      },
    });

    if(isEdit){
      btns.push({
        text: i18next.t("generic:delete"),
        onClick: () => {
          SummonModal("faq-category-page-delete-modal");
        },
      });
    }
    return btns;
  };

  useEffect(() => {
    if(faqCategories.length > 0){
      let rawList: IGET_FAQ_CATEGORY[] = faqCategories;
      let joinedList: any = {};

      for (let i = 0; i < rawList.length; i++) {
        let el: IGET_FAQ_CATEGORY = { ...rawList[i] };

        if (!joinedList[rawList[i].faqCategoryID.toString()]) {
          (el as any)["faqCategoryTranslation"] = [
            {
              languageID: el.languageID,
              name: el.faqCategoryName,
            },
          ];
          joinedList[rawList[i].faqCategoryID.toString()] = el;
        } else {
          joinedList[rawList[i].faqCategoryID.toString()].faqCategoryTranslation.push(
            {
              languageID: el.languageID,
              name: el.faqCategoryName,
            }
          );
        }
      }
      setFaqCategoriesObject(Object.values(joinedList));
    }
  }, [faqCategories]);

  useEffect(() => {
    if(faqCategoriesObject.length > 0){
      let rowsDef_: any[] = [];
      for (let i = 0; i < faqCategoriesObject.length; i++) {
        rowsDef_.push({
          id: "edit",
          faqCategoryID: faqCategoriesObject[i].faqCategoryID,
          faqCategoryName: faqCategoriesObject[i].faqCategoryName,
        });
      }
      setRowDef(rowsDef_);
    }
  }, [faqCategoriesObject]);

  useEffect(() => {
    loadFaqCategories();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_faq_categories")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentFaqCategory(null);
                setIsEdit(false);
                SummonModal("faq-category-page-modal");
              }}
            >
              {i18next.t("navigation:add")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          modalUniqueId="faq-category-page-modal"
          title={!currentFaqCategory?.faqCategoryID ? i18next.t("navigation:titleAddFaqCategory") : i18next.t("navigation:titleEditFaqCategory")}
          modalInnerComponent={
            <FaqCategoryForm
              currentFaqCategory={currentFaqCategory}
              formID="faq-category-form-modal"
              onCloseModal={() => {
                DismissModal("faq-category-page-modal");
              }}
              onRefresh={(data: any) => {
                setFaqCategories(data);
              }}
            />
          }
          buttons={servButtons()}
        />
        <SmartModal
          modalUniqueId="faq-category-page-delete-modal"
          title={i18next.t("generic:titleRecordModal")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModal")
                  .replace("CURRENT_ITEM_LIST", currentFaqCategory?.faqCategoryName ?? ""),
              }}
            />
          }
          onAccept={() => {
            deleteFaqCategory(currentFaqCategory?.faqCategoryID);
          }}
          onReject={() => {
            DismissModal("faq-category-page-delete-modal");
          }}
        />
        <LoaderBackdrop loading={loadingFaqCategories || processing} />
      </div>
    </div>
  );
};

export default AdminPage__FaqCategories;