/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ISupplierGetSideList } from "../../../../../Models/ISupplier";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Suppliers__AnagraphicsPage.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import FormSupplier from "../../../../../Components/StepSupplier/FormSupplier";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const Suppliers__AnagraphicsPage = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  /* STATES */
  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);

  // Container for supplier list
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [currentSupplier, setCurrentSupplier] = useState<any>(null);

  /* APIs */
  // Load suppliers list
  const loadSuppliersList = () => {
    setLoadingSupplierList(true);
    if (loggedUser) {
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  const fromAPItoState = (rawState: ISupplierGetSideList) => {
    let supplierInfo: any = { ...rawState.supplierInfo };

    let arr: any = rawState.companyIDs.map((x: any) => x.companyID.toString());
    let companyIDs: any = { supplierCompany: arr };

    // Merge into a final state
    let finaleState: any = {
      ...supplierInfo,
      ...companyIDs,
    };

    return finaleState;
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadSuppliersList();
  }, []);

  const editRow = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          let supplier_ = supplierList.filter(
            (x: ISupplierGetSideList) =>
              x.supplierInfo.supplierID === props.row.supplierID
          )[0];
          setCurrentSupplier(fromAPItoState(supplier_));
          SummonModal("supplier-page-modal");
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "supplierName",
      headerName: i18next.t("form:nameSupplier"),
    },
    {
      field: "supplierTypeCode",
      headerName: i18next.t("form:supplierTypeName"),
    },
    {
      field: "supplierVAT",
      headerName: i18next.t("form:vatNumber"),
    },
    {
      field: "supplierPhone",
      headerName: i18next.t("form:contactCenter"),
    },
    {
      field: "supplierReferentName",
      headerName: i18next.t("form:referent"),
    },
    {
      field: "supplierReferentEmail",
      headerName: i18next.t("form:emailReferent"),
    },
    {
      field: "supplierReferentPhone",
      headerName: i18next.t("form:telephoneReferent"),
    },
    {
      field: "supplierCity",
      headerName: i18next.t("form:city"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < supplierList.length; i++) {
    rowsDef.push({
      id: i,
      supplierID: supplierList[i].supplierInfo.supplierID,
      supplierName: supplierList[i].supplierInfo.supplierName,
      supplierVAT: supplierList[i].supplierInfo.supplierVAT,
      supplierPhone: supplierList[i].supplierInfo.supplierPhone,
      supplierReferentName: supplierList[i].supplierInfo.supplierReferentName,
      supplierReferentEmail: supplierList[i].supplierInfo.supplierReferentEmail,
      supplierReferentPhone: supplierList[i].supplierInfo.supplierReferentPhone,
      supplierCity: supplierList[i].supplierInfo.supplierAddressCity,
      // supplierTypeCode: supplierL
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_suppliers")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentSupplier("");
                SummonModal("supplier-page-modal");
              }}
            >
              {i18next.t("navigation:_add_supplier")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          hideButtons={true}
          modalUniqueId="supplier-page-modal"
          title={i18next.t("generic:titleAddSupplier")}
          modalInnerComponent={
            <FormSupplier
              loggedUser={loggedUser}
              data={currentSupplier ?? []}
              idModal="supplier-page-modal"
              updateList={(data: ISupplierGetSideList) => {
                let supplierList_: ISupplierGetSideList[] = [...supplierList];
                supplierList_.push(data);
                setSupplierList(supplierList_);
              }}
            />
          }
        />
        <LoaderBackdrop loading={loadingSupplierList} />
      </div>
    </div>
  );
};

export default Suppliers__AnagraphicsPage;
