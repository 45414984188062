import "./VehiclesPage_subPages_Assegnee_StepAssignedDrivers.scss";
import { IUser } from "../../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import FormAssignement from "../../../../../../Components/StepVehicles/TabAssignment/FormAssignment";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { dateIsPast } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

export interface IFormAssignedDriver {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  data: any;
  numPage?: number;
  doRefreshForm?: () => void;
}

const VehiclesPage_subPages_FormAssignedDriver = (props: IFormAssignedDriver) => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const loggedUserCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  return (
    <div>
      {vehicle && (
        <FormAssignement
          suppressWarning
          disabled={disposed > 0}
          data={props.data}
          companyUser={loggedUserCompany}
          dataVehicle={vehicle}
          loggedUser={loggedUser}
        />
      )}
    </div>
  );
};

export default VehiclesPage_subPages_FormAssignedDriver;
