import i18next from "i18next";
import SmartModal, { DismissModal } from "../SmartModal/SmartModal";
import "./ReimmatricolateVehicleModal.scss";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { IVehicleDataGet, IVehicleReimmPlate } from "../../Models/IVehicles";
import { useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import { validatePlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";

export interface IReimmatricolateVehicleModalProps {
  onDone: (data: any) => void;
}

const ReimmatricolateVehicleModal = (props: IReimmatricolateVehicleModalProps) => {
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );

  const [plates, setPlates] = useState<IVehicleReimmPlate[]>([]);
  const [state, setState] = useState<any>({});

  const loadPlates = () => {
    ApiService.VehicleDataController.VehicleReimmPlateGet(
      vehicle?.vehicleID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPlates(response.payload);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  const insertPlate = (data: IVehicleReimmPlate) => {
    ApiService.VehicleDataController.VehicleReimmPlateInsert(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPlates(response.payload);
          ToastMessage(i18next.t("navigation:reimmSuccess"), "success");
          DismissModal("ReimmatricolateVehicleModal");
          props.onDone(data);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  const updatePlate = (data: IVehicleReimmPlate) => {
    ApiService.VehicleDataController.VehicleReimmPlateUpdate(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPlates(response.payload);
          ToastMessage(i18next.t("navigation:reimmSuccess"), "success");
          DismissModal("ReimmatricolateVehicleModal");
          props.onDone(data);
        } else {
          ToastMessage(i18next.t("error:errorLoadingData"), "error");
        }
      }
    );
  };

  return (
    <div>
      <SmartModal
        onBeforeSummon={() => {
          loadPlates();
        }}
        title={i18next.t("navigation:reimmTitile")}
        modalUniqueId="ReimmatricolateVehicleModal"
        modalInnerComponent={
          <div>
            <MultiForm
              formId="ReimmatricolateVehicleForm"
              formTitle={""}
              suppressLayout
              suppressSubmit
              onChange={(data: any) => {
                setState({ ...state, ...data });
              }}
              inputs={[
                {
                  type: "text",
                  name: "vehicleLicencePlate",
                  required: true,
                  placeholder: i18next.t("navigation:reimmPlate") ?? "",
                  label: i18next.t("navigation:reimmPlate") ?? "",
                  defaultValue: state?.vehicleLicencePlate,
                  errorText: state
                    ? validatePlate(state?.vehicleLicencePlate)
                    : undefined,
                },
                {
                  type: "date",
                  name: "vehicleRegistrationDT",
                  required: true,
                  placeholder: i18next.t("navigation:reimmDate") ?? "",
                  label: i18next.t("navigation:reimmDate") ?? "",
                  defaultValue: state?.vehicleRegistrationDT,
                  minDate: new Date().toISOString(),
                },
              ]}
              onSubmit={(data: any) => {
                if (validatePlate(data?.vehicleLicencePlate)) {
                  return;
                }

                data["vehicleID"] = vehicle?.vehicleID;
                if (data.vehicleLicencePlateID) {
                  updatePlate(data);
                } else {
                  insertPlate(data);
                }
              }}
            />
          </div>
        }
        buttons={[
          {
            text: i18next.t("navigation:reject"),
            onClick: () => {
              DismissModal("ReimmatricolateVehicleModal");
            },
          },
          {
            text: i18next.t("navigation:accept"),
            onClick: () => {
              triggerFormValidation("ReimmatricolateVehicleForm");
            },
          },
        ]}
      />
    </div>
  );
};

export default ReimmatricolateVehicleModal;
