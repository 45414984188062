import { useState, useEffect } from "react";

import "./UploadForm.scss";

import PublishSharpIcon from "@mui/icons-material/PublishSharp";
import { DragAndDropContainer } from "../DragAndDropContainer/DragAndDropContainer";

export interface IUploadForm {
  title: string;
  accept: string;
  name?: string | "";
  description: string;
  multiple?: boolean;
  initialFiles?: string[];
  disableFileListing?: boolean;
  onFileChanged?: (files: File[]) => void;
  disabled?: boolean;
}

const UploadForm = (props: IUploadForm) => {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (props.onFileChanged && !props.disabled) {
      props.onFileChanged(files);
    }
  }, [files]);

  /* RETURN */
  return (
    <>
      <DragAndDropContainer
        preLoadFiles={props.initialFiles?.map((x: string) => {
          return { name: x } as File;
        })}
        disabled={props.disabled}
        allowFileListing={props.disableFileListing !== false}
        multiple={props.multiple ?? false}
        disableInternalListing={false}
        accept={props.accept}
        enabled
        icon={<PublishSharpIcon />}
        title={<div>{props.title}</div>}
        lowerElement={<div>{props.description}</div>}
        onDrop={(files_: File[]) => {
          if (!props.disabled) {
            setFiles(files_.length > 0 ? files_ : []);
          }
        }}
        onRemove={(files_: File[]) => {
          if (!props.disabled) {
            setFiles(files_.length > 0 ? files_ : []);
          }
        }}
      />
    </>
  );
};

export default UploadForm;
