/* REACT */
import { useEffect, useState } from "react";

/* MODELS */
import {
  IFringeCompleteModel,
  IVehicleFringeFuel,
} from "../../../../../Models/IVehiclesFringe";

/* REDUCERS */

/* STYLE */
import "./AdminPage__quattroRuote.scss";
import DeleteIcon from "@mui/icons-material/Delete";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import { ToastMessage } from "../../../../../Utils/Toastify";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import DataGridWrap from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";

/* MUI */
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import UploadIcon from "@mui/icons-material/Upload";
import TaskIcon from "@mui/icons-material/Task";
import { IInfoCarZipGet, IInfoCarZipInsert } from "../../../../../Models/IInfoCarZip";
import {displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { downloadWithName, getBlobDesc } from "../../../../../Utils/FileToBase";

/* COMPONENT */
const AdminPage__quattroRuote = () => {
  /* STATES */
  const [processing, setProcessing] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<any[]>([]);
  const [loadingInfoCarZip, setLoadingInfoCarZip] = useState<boolean>(true)
  const [infoCarZip, setInfoCarZip] = useState<IInfoCarZipGet[]>([])
  const [infoCarZipNotImported, setInfoCarZipNotImported] = useState<IInfoCarZipGet[]>([])
  const [fileToDelete, setfileToDelete] = useState<IInfoCarZipGet>()
  const [existInfocarFileZip, setExistInfocarFileZip] = useState<boolean>(false)
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [counterFileUploaded, setCounterFileuploaded] = useState<number>(0)

  const loadInfocarZip = (isLoad?: boolean) => {
    setLoadingInfoCarZip(true);
    ApiService.InfoCarController.GetInfoCarZipUpload(
      (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp = response.payload.filter((el: IInfoCarZipGet) => el.isImported === false)
          setInfoCarZip(response.payload);
          setInfoCarZipNotImported(tmp)
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingInfoCarZip(false);
        if(!isLoad) {
          SummonModal('MassiveInfoCarModal')
        }
      }
    );
  };

  const uploadInfoCar = (file: IInfoCarZipInsert) => {
    ApiService.InfoCarController.InsertInfoCarZipUpload(
      file,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let tmp = response.payload.filter((el: IInfoCarZipGet) => el.isImported === false)
          setInfoCarZip(response.payload);
          setInfoCarZipNotImported(tmp)
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  }

  const DeleteInfoCar = (infoCarZipUploadID: number) => {
    let tmpDelete = {infoCarZipUploadID: infoCarZipUploadID}
      setProcessing(true);
      ApiService.InfoCarController.DeleteInfoCarZipUpload(
        tmpDelete, 
        (response: IAPIResponse) => {
        if (response.error === null) {
          setInfoCarZip(response.payload)
          setInfoCarZipNotImported(response.payload.filter((el: any) => el.isImported === false))
          let checkExistFile = false
          for(let i = 0; i < uploadFile.length; i++){
            checkExistFile = response.payload.filter((x: IInfoCarZipGet) => (x.displayName + '.zip') === uploadFile[i].name).length > 0
          }
          setExistInfocarFileZip(checkExistFile)
          ToastMessage("file deleted", "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
  };

  const uploadFileList = async (file: File[]) => {
    setProcessing(true)
    let response: IAPIResponse = await ApiService.UploadController.UploadFileList(
      "infocar",
      file)
        if (response.error === null) {
          let tmp: any = []
          for (let i = 0; i < response.payload.length; i++) {
            tmp.push({zipName: response.payload[i].fileReference})
          }
          let tmpZip: IInfoCarZipInsert = {
            infoCarZipTable: tmp
          }
          uploadInfoCar(tmpZip)
          ToastMessage(i18next.t("message:upload_success"), "success");
        } else {
          ToastMessage(i18next.t("error:could_not_upload"), "error");
        }
  };

  /* USE EFFECT HOOK */ 
  useEffect(() => {
    loadInfocarZip()
  }, []);

  const IsImported = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        {props.value ? 'Yes' : 'No'}
      </div>
    );
  };

  

  const ZipDownload = (props: GridRenderCellParams) => {

    const downloadFile = (blobUri: string) => {
      setProcessing(true)
      ApiService.UploadController.GetSASString(
        "infocar",
        blobUri,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let sas: string = response.payload.tokenSAS;
            if (sas) {
              downloadWithName(sas, getBlobDesc(blobUri));
            }
          } else {
            ToastMessage(i18next.t("error:could_not_download"), "error");
          }
          setProcessing(false)
        }
      );
    };
    return (
      <div className="edit-wrapper" style={{cursor: 'pointer'}}>
      <a 
      title={props.row.displayName}
      onClick={() => {
        downloadFile(props.row.zipName)
      }}
      target="_blank"
      rel="noreferrer">
        {props.value}
      </a>
      </div>
    );
  };

  const RenderDate = (props: GridRenderCellParams) => {
    return (
      <div className="edit-wrapper">
        {displayUTC0_ISODate(props.value, true)}
      </div>
    );
  };

  const renderDelete = (props: GridRenderCellParams) => {
    return (
      <>
      {!props.row.isImported && <IconButton
        onClick={() => {
          let tmp: any = props.row
          tmp.deleteMenuFile = true
        setfileToDelete(tmp)
        SummonModal('Delete-file-modal')
        } }
        >
        <DeleteIcon />
      </IconButton>}
      </>
    );
  };

  const ExecuteUpload = async () => {
    if (uploadFile && uploadFile.length > 0) {
      for(let i = 0; i < uploadFile.length; i++) {
        await uploadFileList([uploadFile[i]])
      }
        setProcessing(false)
        DismissModal('MassiveInfoCarModal')
    }
  }

  /* COLUMNS */
  const COLUMNS = [
    {
      field: "delete",
      headerName: i18next.t('navigation:infocar_header1'),
      renderCell: renderDelete,
      hideExport: true,
      type: 'custom'
    },
    {
      field: "displayName",
      headerName: i18next.t('navigation:infocar_header2'),
      renderCell: ZipDownload
    },
    {
      field: "uploadedDT",
      headerName: i18next.t('navigation:infocar_header3'),
      renderCell: RenderDate
    },
    {
      field: "isImported",
      headerName: i18next.t('navigation:infocar_header4'),
      renderCell: IsImported
    },
    {
      field: "importedDT",
      headerName: i18next.t('navigation:infocar_header5'),
      renderCell: RenderDate
    },
  ];

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_quattroRuote")} />

      {!loadingInfoCarZip && <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small gap-small">
          <Button
            startIcon={<UploadIcon />}
            variant={"outlined"}
            onClick={() => {
              SummonModal("MassiveInfoCarModal");
              setCounterFileuploaded(0)
              setExistInfocarFileZip(false)
            }}
          >
            {i18next.t("navigation:upload_infocar")}
          </Button>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap
            headers={COLUMNS}
            rows={infoCarZip}
          />
        </Box>

        <SmartModal
          onBeforeSummon={() => {
            setUploadFile([]);
          }}
          buttons={[
            {
              disabled: uploadFile === null || existInfocarFileZip || processing,
              text: i18next.t("form:save"),
              onClick: () => {
                triggerFormValidation("MassiveInfoCarForm");
              },
            },
          ]}
          modalUniqueId="MassiveInfoCarModal"
          title={i18next.t("navigation:massive_infocar_load")}
          modalInnerComponent={
            <div>
              <MultiForm
                formId="MassiveInfoCarForm"
                suppressLayout
                suppressSubmit
                formTitle={""}
                inputs={[
                  {
                    width: 100,
                    type: "custom",
                    name: "-",
                    element: (
                      <div
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {infoCarZipNotImported.length > 0 && 
                        <>
                        <div>{i18next.t('navigation:infocar_info')}</div>
                        {infoCarZipNotImported.map((el: IInfoCarZipGet) => {
                          return (
                            <>
                            <div className="delete-zip-wrapper">
                              <IconButton
                              onClick={() => {
                                setfileToDelete(el)
                                SummonModal('Delete-file-modal')
                              } }
                            >
                              <DeleteIcon />
                            </IconButton>
                              <div className="delete-zip-text">
                              {el.displayName}
                              </div>
                            </div>
                            </>
                          )
                        })}
                          </>
                        }
                        <div
                          className="select-model-wrapper"
                          onClick={() => {
                            document.getElementById("file-inp")?.click();
                          }}
                        >
                          <input
                          disabled={processing}
                            onChange={(e: any) => {
                              let tmp: any = []
                              for(let i = 0; i < e.target.files.length; i++) {
                                tmp.push(e.target.files[i])
                                let checkExistFile = infoCarZip.filter((x: IInfoCarZipGet) => (x.displayName + '.zip') === e.target.files[i].name).length > 0
                                setExistInfocarFileZip(checkExistFile)
                              }
                              setUploadFile(tmp);
                            }}
                            id="file-inp"
                            multiple
                            type="file"
                            hidden
                            accept=".zip"
                          />
                          <div>
                            <div className="upload-icon-wrapper">
                              <TaskIcon className="text-color-hint StepDashboardLoadTemplate__icon StepDashboardLoadTemplate__icon--size" />
                            </div>

                            <div className="title-files-wrapper">
                              {uploadFile && uploadFile.length > 0 && uploadFile.map((el: File) => {
                                return (
                                  <div className="title-files">
                                    <strong>
                                      {el.name}
                                    </strong>
                                    <br/>
                                  </div>
                                )
                              })}
                            </div>
                            {existInfocarFileZip &&
                            <div className="error-exist-file-wrapper">
                              {i18next.t('navigation:infocar_warning')}
                            </div>}

                            <div style={{ textAlign: "center" }}>
                              <div className="accepted-file-wrapper">
                                {i18next.t('navigation:infocar_upload_message')}
                              </div>
                              <div>.zip</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                onSubmit={(data: any) => {
                  setIsFileUploading(true)
                  ExecuteUpload()
                }}
              />
              {processing && (
                <><div style={{display: 'flex', justifyContent: 'center'}}>
                  {i18next.t('navigation:infocar_loading_upload')}
                </div>
              <div className="center-progress-spinner">
                <CircularProgress />
              </div>
              </>
            )}
            </div>
          }
        />
        <SmartModal 
        modalUniqueId={"Delete-file-modal"}
        title={"Sei Sicuro di voler eliminare il file " + fileToDelete?.displayName + ' ?'}
        modalInnerComponent={<></>}
        buttons={[
          {
            disabled: false,
            text: i18next.t('message:no'),
            onClick: () => {
              DismissModal('Delete-file-modal')
            },
          },
          {
            disabled: false,
            text: i18next.t('message:yes'),
            onClick: () => {
              if(fileToDelete) {
                  DeleteInfoCar(fileToDelete.infocarZipUploadID)
              }
              DismissModal('Delete-file-modal')
            },
          },
        ]
        }
        />
      </div>}

      <LoaderBackdrop loading={loadingInfoCarZip || processing} title={isFileUploading ? i18next.t('navigation:infocar_loading_upload') ?? '' : ''}  />
    </div>
  );
};

export default AdminPage__quattroRuote;
