import { dateIsPast } from "../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IUser } from "../Models/IUser";
import AppRoutes from "../Costants/AppRoutes";

const exists = (value: null | undefined | string) => {
  return value !== null && value !== undefined;
};

export const checkUserIsLogged = () => {
  return (
    exists(localStorage.getItem("logged-user")) &&
    exists(localStorage.getItem("sessionToken")) // &&
    // exists(localStorage.getItem("logged-user-company"))
  );
};

export const userRoleFits = (roles: string[]) => {
  let u_: string | null = localStorage.getItem("logged-user-imp");
  if (u_) {
    let user: IUser = JSON.parse(u_);
    if (!dateIsPast(user.expireImpDT)) {
      return roles.includes(user.roleName);
    }
  }

  let u: string | null = localStorage.getItem("logged-user");
  if (u) {
    let user: IUser = JSON.parse(u);
    return roles.includes(user.roleName);
  }

  return false;
};

export const getUserRole = () => {
  let u_: string | null = localStorage.getItem("logged-user-imp");
  if (u_) {
    let user: IUser = JSON.parse(u_);
    if (!dateIsPast(user.expireImpDT)) {
      return user.roleName;
    }
  }
  let u: string | null = localStorage.getItem("logged-user");
  if (u) {
    let user: IUser = JSON.parse(u);
    return user.roleName;
  }

  return "";
};

export const checkAccountSessionStatusRedirect = (
  registrationCompleted: boolean,
  enabled: boolean,
  paymentRequired: boolean,
  isTrial: boolean
) => {

  if (userRoleFits(["FleetSupportAdmin", "Broker"])) {
    return (
      AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___MESSAGING_ROUTE
    );
  }else{
    // Registrazione in corso
    if (!registrationCompleted && (enabled || isTrial)) {
      return AppRoutes.CONTINUE_REGISTRATION_ROUTE;
    }

    if (registrationCompleted && (enabled || isTrial)) {
      if (userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) {
        return (
          AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___MESSAGING_ROUTE
        );
      } else {
        return (
          AppRoutes.INTERNAL_DASHBOARD +
          AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE
        );
      }
    }

    if (registrationCompleted && !enabled && !isTrial) {
      return (
        AppRoutes.INTERNAL_DASHBOARD +
        AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS
      );
    }
  }
  if(!enabled && !paymentRequired){
    return AppRoutes.CONTINUE_REGISTRATION_ROUTE;
  }else{
    return AppRoutes.STORE_SUBSCRIPTION_PAGE;
  }
};
