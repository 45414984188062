import { IStepperBody } from "../Models/IStepperBody";
import { IUser } from "../Models/IUser";
import ApiService from "../Services/ApiService";

export const updateAccountRegistrationStep = (
  loggedUser: IUser | undefined,
  numStep: number | undefined,
  StepCompleted?: boolean
) => {
  if (loggedUser && numStep) {
    let data: IStepperBody = {
      registrationStepID: numStep,
      registrationStepCompleted: StepCompleted ? StepCompleted : false,
    };
    ApiService.StepperController.StepperSetData(data);

    localStorage.setItem("current-last-step", JSON.stringify(data));
  }
};
