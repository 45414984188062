import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IInsertTrialSubscription } from "../../Models/ISubscriptions";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const SubscriptionController = {
  SubscriptionGetData: async (
    SubscriptionID: number,
    FleetPlanID?: number | null,
    PaymentTypeID?: number | null,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SUBSCRIPTION_GET_DATA;
    let parameters: string[] = [];
    if (SubscriptionID) {
      parameters.push(`SubscriptionID=${SubscriptionID}`);
    }
    if (FleetPlanID) {
      parameters.push(`FleetPlanID=${FleetPlanID}`);
    }
    if (PaymentTypeID) {
      parameters.push(`PaymentTypeID=${PaymentTypeID}`);
    }
    if (SubscriptionID || FleetPlanID || PaymentTypeID) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  SubscriptionUpdateData: async (
    body: any,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SUBSCRIPTION_UPDATE_DATA;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  SubscriptionGetDataDuration: async (
    SubscriptionDurationID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SUBSCRIPTION_GET_DATA_DURATION;
    let parameters: string[] = [];
    if (SubscriptionDurationID) {
      parameters.push(`SubscriptionDurationID=${SubscriptionDurationID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  SubscriptionInsertTrialSubscription: async (
    body: IInsertTrialSubscription,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.SUBSCRIPTION_INSERT_TRIAL_PLAN_DATA;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default SubscriptionController;
