/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_FAQ_BY_CATEGORY,
  IGET_FAQ_CATEGORY,
  IGET_FAQ_VISIBILITY,
  IUPDATE_FAQ,
} from "../../../../../Models/IFAQ";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import CustomCKEditorFour from "../../../../../Components/CustomCKEditorFour/CustomCKEditorFour";
import { IISOLanguage } from "../../../../../Components/FlagPicker/FlagPicker";
import { userRoleFits } from "../../../../../Utils/Session";

/* MUI */
import {
  Alert,
  Box,
  Button,
} from "@mui/material";
// Icons
import FaqCategoryForm from "../../../../../Components/FaqCategoryForm/FaqCategoryForm";
import AddIcon from "@mui/icons-material/Add";
import AccordionFaq from "../../../../../Components/AccordionFaq/AccordionFaq";
import { Search } from "@mui/icons-material";
import { set } from "rsuite/esm/utils/dateUtils";
import { voices } from "../../../../../i18n";

const AdminPage__FAQ = () => {
  /* STATES */
  // Form data
  const [formCategory, setFormCategory] = useState<any>([]);
  const [formData, setFormData] = useState<any>([]);
  const [CKEditorData, setCKEditorData] = useState<any>(null);
  // Languages
  const [languages, setLanguages] = useState<IISOLanguage[]>([]);
  // Categories
  const [category, setCategory] = useState<IGET_FAQ_CATEGORY[]>([]);
  // Visibility
  const [visibility, setVisibility] = useState<IGET_FAQ_VISIBILITY[]>([]);
  // Visibility
  const [faqByCategory, setFaqByCategory] = useState<IGET_FAQ_BY_CATEGORY[]>([]);
  // Current selection
  const [currentSelection, setCurrentSelection] = useState<any>([]);
  // Loading
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
  const [loadingFaqCategory, setLoadingFaqCategory] = useState<boolean>(false);
  const [loadingFaqVisibility, setLoadingFaqVisibility] = useState<boolean>(false);
  const [loadingFaqByCategory, setLoadingFaqByCategory] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [reloadFaqs, setReloadFaq] = useState<boolean>(false);
  /* LODASH */
  const lodash = require("lodash");
  const [searchByText, setSearchByText] = useState<boolean>(false);

  /* API */
  // Get categories
  const GET_FAQ_CATEGORY = () => {
    setLoadingFaqCategory(true);

    ApiService.FAQController.GET_FAQ_CATEGORY(null, null, false, (response: IAPIResponse) => {
      if (response.error === null) {
        let categories_ = [{ ...{ faqCategoryID: 0, faqCategoryName: " - " + i18next.t("navigation:all") + " - "} }, ...response.payload];
        console.log("categories_", categories_)
        setCategory(categories_);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingFaqCategory(false);
    });
  };

  // Get languages
  const loadLanguages = () => {
    setLoadingLanguages(true);

    ApiService.DriverController.DriverGetLanguages((response: IAPIResponse) => {
      if (response.error === null) {
        setLanguages(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingLanguages(false);
    });
  };

  // Get visibility options
  const GET_FAQ_VISIBILITY = () => {
    setLoadingFaqVisibility(true);

    ApiService.FAQController.GET_FAQ_VISIBILITY(null, null, (response: IAPIResponse) => {
      if (response.error === null) {
        setVisibility(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingFaqVisibility(false);
    });
  };

  // Get FAQs by category
  const GET_FAQ_BY_CATEGORY = (faqCategoryID?: number, languageID?: number, searchText?: string) => {
    setLoadingFaqByCategory(true);

    ApiService.FAQController.GET_FAQ_BY_CATEGORY(
      faqCategoryID || null,
      languageID || null,
      null,
      searchText,
      (response: IAPIResponse) => {
        if (response.error === null) {
          const payload = response.payload;
          setFaqByCategory(payload);
          if (formData?.faqID) {
            setFormData({
              ...payload.filter((x: any) => x.faqID === formData?.faqID)[0],
              ...{
                faqID: formData?.faqID,
                languageID: languageID,
                faqVisibilityID: formData?.faqVisibilityID,
                faqCategoryID: formData?.faqCategoryID,
                faqResponseID: formData?.faqResponseID,
              },
            });
          }
          if(searchByText){
            setSearchByText(false);
          }
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingFaqByCategory(false);
      }
    );
  };

  // Insert faq
  const INSERT_FAQ = (data: IUPDATE_FAQ) => {
    setLoadingInsert(true);

    ApiService.FAQController.INSERT_FAQ(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:success"), "success");

        GET_FAQ_BY_CATEGORY(formCategory?.categorySelection);
        setReloadFaq(true);
        setTimeout(() => {
          setReloadFaq(false);
        }, 100);
        DismissModal("add-faq-modal");
      } else {
        ToastMessage(i18next.t("error:ERROR"), "error");
      }

      setLoadingInsert(false);
    });
  };

  // Update faq
  const UPDATE_FAQ = (data: IUPDATE_FAQ) => {
    setLoadingUpdate(true);

    ApiService.FAQController.UPDATE_FAQ(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:updateSuccess"), "success");
        GET_FAQ_BY_CATEGORY(formCategory?.categorySelection, formData?.languageID);
        setReloadFaq(true);
        setTimeout(() => {
          setReloadFaq(false);
        }, 100);
        DismissModal("add-faq-modal");
      } else {
        ToastMessage(i18next.t("error:ERROR"), "error");
      }

      setLoadingUpdate(false);
    });
  };

  // Delete faq
  const DELETE_FAQ = (body: any) => {
    setLoadingDelete(true);

    ApiService.FAQController.DELETE_FAQ(body, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:deleteSuccess"), "success");

        GET_FAQ_BY_CATEGORY(formCategory?.categorySelection);
        setReloadFaq(true);
        setTimeout(() => {
          setReloadFaq(false);
        }, 100);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingDelete(false);
    });
  };

  /* USE EFFECT */
  // First render
  useEffect(() => {
    GET_FAQ_CATEGORY();
    GET_FAQ_VISIBILITY();
    loadLanguages();
  }, []);

  useEffect(() => {
    if(formCategory?.categorySelection){
      GET_FAQ_BY_CATEGORY(formCategory?.categorySelection, undefined, formCategory?.searchText);
    }else{
      GET_FAQ_BY_CATEGORY(0, undefined, formCategory?.searchText);
    }
  }, [formCategory?.categorySelection]);

  // Render faq based on languages
  useEffect(() => {
    if (formData?.languageID) {
      GET_FAQ_BY_CATEGORY(formCategory?.categorySelection, formData?.languageID);
    }
  }, [formData?.languageID]);

  /* MULTIFORM */
  // Function to build the multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: 20,
        // className: "modal-width-extra-small",
        type: "select",
        name: "categorySelection",
        defaultValue: formCategory?.categorySelection || 0,
        currentValue: formCategory?.categorySelection || 0,
        label: `${i18next.t("form:carCategory")}`,
        options: category.map((x: IGET_FAQ_CATEGORY) => {
          return {
            key: x.faqCategoryID,
            text: x.faqCategoryName,
          };
        }),
      },
      {
        width: 70,
        type: "text",
        name: "searchText",
        inputProps: {
          maxLength: 100,
        },        
        label: `${i18next.t("form:searchInfo")}`,
      },
      {
        width: 10,
        name: "",
        type: "custom",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              variant="contained"
              startIcon={<Search />}
              onClick={() => {
                GET_FAQ_BY_CATEGORY(formCategory?.categorySelection, undefined, formCategory?.searchText);
              }}
            >
              {i18next.t("generic:Search")}
            </Button>
          </div>
        ),
      }
    );
    // Return the full and complete Multiform
    return multiformInputs;
  };

  const servButtons = () => {
    let btns = [];

    btns.push({
      text: i18next.t("form:save"),
      onClick: () => {
        triggerFormValidation("faq-category-modal-add");
      },
    });
    return btns;
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_faq")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data" style={{ marginTop: 0 }}>
          <Alert severity="info"><div dangerouslySetInnerHTML={{ __html: i18next.t("generic:faqInfo") as string}} /></Alert>
          <div className="display-flex-horizontal-space-between">
            <MultiForm
              suppressLayout
              suppressSubmit
              formId={"FormAdminCategoryFAQ"}
              formTitle={"FormAdminCategoryFAQ"}
              inputs={multiformInputs()}
              onChange={(data: any) => {
                setFormCategory(data);
              }}
            />
            {userRoleFits(["FleetSupportAdmin"]) && (
              <div className="AdminPage__FAQ--Buttons">
                <Button style={{height: '43px', marginTop: '10px'}}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setFormData([]);

                    SummonModal("add-faq-modal");
                  }}
                >
                  {i18next.t("form:addFAQ")}
                </Button>
              </div>
            )}
          </div>
          {!reloadFaqs && (
            <div className="AdminPage__FAQ__Accordion--Box">
              {faqByCategory.length > 0 ? (
                faqByCategory.map((x: IGET_FAQ_BY_CATEGORY, i: number) => {
                  return <AccordionFaq 
                    key={i} 
                    infoFaq={x} 
                    setCurrentSelection={(data: any) => setCurrentSelection(data)}
                    setFormData={(data: any) => setFormData(data)}
                  />;
                })
              ) : (
                <div style={{ margin: "1em 0" }}>
                  <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
                </div>
              )}
            </div>
          )}
        </Box>
      </div>

      <SmartModal
        modalUniqueId="add-faq-modal"
        labelAcceptButton={`${i18next.t("form:save")}`}
        title={i18next.t("navigation:_admin_faq")}
        modalInnerComponent={
          <div className="modal-width-large">

            {!loadingFaqByCategory && (
              <MultiForm
                suppressLayout
                suppressSubmit
                formId={"FormAdminAddFAQ"}
                formTitle={"FormAdminAddFAQ"}
                inputs={[
                  {
                    width: 33,
                    type: "select",
                    name: "languageID",
                    defaultValue: formData?.languageID,
                    required: true,
                    label: `${i18next.t("navigation:language")}`,
                    options: languages.map((x: IISOLanguage) => {
                      return {
                        key: x.languageID,
                        text: x.languageName,
                      };
                    }),
                  },
                  {
                    width: 33,
                    type: "select",
                    name: "faqCategoryID",
                    required: true,
                    defaultValue: formData?.faqCategoryID,
                    currentValue: formData?.faqCategoryID,
                    label: `${i18next.t("form:carCategory")}`,
                    options: category.filter((x: IGET_FAQ_CATEGORY) => x.faqCategoryID > 0).map((x: IGET_FAQ_CATEGORY) => {
                      return {
                        key: x.faqCategoryID,
                        text: x.faqCategoryName,
                      };
                    }),
                  },
                  {
                    width: 10,
                    name: "",
                    type: "custom",
                    element: (
                      <div className="multi-form-button-add-record">
                        <Button
                          variant="contained"
                          onClick={() => {
                            SummonModal("faq-category-modal");
                          }}
                        > 
                          <AddIcon />
                        </Button>
                      </div>
                    ),
                  },
                  {
                    width: 23,
                    type: "select",
                    required: true,
                    name: "faqVisibilityID",
                    label: `${i18next.t("form:visibility")}`,
                    defaultValue: formData?.faqVisibilityID,
                    currentValue: formData?.faqVisibilityID,
                    options: visibility.map((x: IGET_FAQ_VISIBILITY) => {
                      return {
                        key: x.faqVisibilityID,
                        text: x.faqVisibilityName,
                      };
                    }),
                  },
                  {
                    width: 100,
                    type: "text",
                    name: "faq",
                    defaultValue: formData?.faq,
                    label: `${i18next.t("form:question")}`,
                    required: true,
                  },
                  {
                    type: "custom",
                    width: 100,
                    required: true,
                    name: "faqResponse",
                    label: `${i18next.t("form:answer")}`,
                    defaultValue: formData?.faqResponse,
                    element: (
                      <CustomCKEditorFour
                        formSelector="faqResponse"
                        formData={formData?.faqResponse ?? ""}
                        onChange={(data: any) => {
                          setCKEditorData(data);
                        }}
                      />
                    ),
                  },
                ]}
                onChange={(data: any) => {
                  setFormData({ ...formData, ...data });
                }}
                onSubmit={(data: any) => {
                  // Clone
                  const tempData = lodash.cloneDeep(data);

                  if (!tempData["languageID"] && formData?.languageID) {
                    tempData["languageID"] = formData?.languageID;
                  } else if (!tempData["languageID"]) {
                    tempData["languageID"] = voices.find((v: any) => localStorage.getItem("language") === v.key)?.num;
                  }

                  // tempData["faqResponse"] = tempData["faqResponse"] || CKEditorData;
                  tempData["faqResponse"] = CKEditorData ?? formData?.faqResponse;

                  // Insert - Update
                  if (formData.faqID) {
                    tempData["faqID"] = formData.faqID;
                    tempData["faqResponseID"] = +formData.faqResponseID;

                    UPDATE_FAQ(tempData);
                  } else {
                    INSERT_FAQ(tempData);
                  }
                  setFormData([]);
                }}
              />
            )}
          </div>
        }
        onAccept={() => {
          triggerFormValidation("FormAdminAddFAQ");
        }}
        onReject={() => {
          DismissModal("add-faq-modal");
        }}
      />

      <SmartModal
        modalUniqueId="delete-faq-modal"
        title={i18next.t("generic:delete")}
        modalInnerComponent={
          <div className="modal-width-small">
            <p>{i18next.t("generic:proceedDeleteSure")}</p>
          </div>
        }
        onAccept={() => {
          DELETE_FAQ(currentSelection);
          DismissModal("delete-faq-modal");
          setFormData([]);
        }}
        onReject={() => {
          DismissModal("delete-faq-modal");
        }}
      />

      <SmartModal
        modalUniqueId="faq-category-modal"
        title={i18next.t("navigation:titleAddFaqCategory")}
        modalInnerComponent={
          <FaqCategoryForm
            formID="faq-category-modal-add"
            onCloseModal={() => {
              DismissModal("faq-category-modal");
            }}
            onRefresh={(data: any) => {
              setCategory(data);
            }}
          />
        }
        buttons={servButtons()}
      />

      <LoaderBackdrop
        loading={
          loadingLanguages ||
          loadingFaqVisibility ||
          loadingFaqCategory ||
          loadingFaqByCategory ||
          loadingInsert ||
          loadingUpdate ||
          loadingDelete
        }
      />
    </div>
  );
};

export default AdminPage__FAQ;
