import { useDispatch, useSelector } from "react-redux";
import IconCardComponent from "../../../../../../Components/IconCardComponent/IconCardComponent";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import {
  IVehicleDataGet,
  IVehicleDeadlineItem,
  IVehicleDriverKm,
  IVehicleSubsitute,
} from "../../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { formatPlate } from "../../VehiclesPage";
import CheckIcon from "@mui/icons-material/Check";
import "./VehiclesPage_subPages_status.scss";
import ExpiryBox, {
  IExpiryBoxProps,
} from "../../../../../../Components/ExpiryBox/ExpiryBox";
import GitCordBox, {
  IGitCordBoxProps,
} from "../../../../../../Components/GitCordBox/GitCordBox";
import i18next from "i18next";
import { IDriverGet } from "../../../../../../Models/IDriver";
import { IAssignmentGet } from "../../../../../../Models/IAssignment";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import { Alert, Button } from "@mui/material";
import { SummonModal } from "../../../../../../Components/SmartModal/SmartModal";
import DismissVehicleModal from "../../../../../../Components/DismissVehicleModal/DismissVehicleModal";
import { useEffect, useState } from "react";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import SubstituteVehicleModal from "../../../../../../Components/SubstituteVehicleModal/SubstituteVehicleModal";
import { DriverLink } from "../../../AnagraphicsPage/Drivers__AnagraphicsPage/Drivers__AnagraphicsPage";
import { VehiclesActions } from "../../../../../../Reducers/Vehicle/VehicleAction";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { JSONPrint } from "../../../../../../Utils/Decoder";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { downloadWithName, getBlobDesc } from "../../../../../../Utils/FileToBase";
import { IAttachmentMctc } from "../../../../../../Models/IAttachments";
import { Download } from "@mui/icons-material";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { get } from "http";

export interface IVehicleHistBrief {
  vehicleEventID: number;
  vehicleID: number;
  vehicleEventDescription: string;
  vehicleEventDT: string;
}

const VehiclesPage_subPages_status = () => {
  const dispatch = useDispatch();
  const vehicle: IVehicleDataGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicle
  );
  const vehicleSubstitutes: IVehicleSubsitute[] | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentVehicleSubstitutes
  );
  const company: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const driver: IDriverGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeDriver
  );

  const assignments: IAssignmentGet[] = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignments
  );
  const selectedAssignment: IAssignmentGet | undefined = useSelector(
    (state: GlobalState) => state.vehicles.currentAssignment
  );

  const activeAssignment: IAssignmentGet | undefined = assignments.find(
    (x: IAssignmentGet) => x.effectiveEndDT === null
  );

  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );

  const [history, setHistory] = useState<IVehicleHistBrief[]>([]);
  const [deadlines, setDeadlines] = useState<IVehicleDeadlineItem[]>([]);

  const [driverKm, setDriverKm] = useState<IVehicleDriverKm[]>([]);
  let [sumDriverKm, setSumDriverKm] = useState(0);
  const [driverKmValue, setDriverKmValue] = useState(0);
  const [processing, setProcessing] = useState<boolean>(false);
  const [mctcAttachment, setMctcAttachment] = useState<IAttachmentMctc[]>([]);
  const [loadingMctcAttachment, setLoadingMctcAttachment] = useState<boolean>(false);
  const handleChangeDriverKm = (event: any) => {
    setDriverKmValue(event.target.value);
  };
  const getVehicleDeadlines = () => {
    ApiService.VehicleDataController.VehicleGetDeadlinesByVehicleId(
      vehicle?.vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDeadlines(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const vehicleGetDriverKm = () => {
    ApiService.VehicleServiceController.VehicleGetDriverKm(
      vehicle?.vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverKm(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const getKMCurrentActiveOnly = () => {
    let assignment = { ...selectedAssignment };
    if (assignment.assignmentID === -1) {
      assignment = { ...activeAssignment };
    }

    return assignment && assignment.effectiveEndDT !== null
      ? (assignment.endVehicleKM ?? "-") + " km"
      : i18next.t("message:still_running");
  };

  const tableData = [
    [
      i18next.t("navigation:_vehicle_page_resume_row_1"),
      company ? company.companyBusinessName.toUpperCase() : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_2"),
      vehicle ? formatPlate(vehicle.vehicleLicensePlate) : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_3"),
      vehicle ? vehicle.brandDescription : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_4"),
      vehicle ? vehicle.modelDescription : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_5"),
      vehicle ? vehicle.fittingDescription : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_6"),
      vehicle ? vehicle.vehicleChassis : "-",
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_7"),
      vehicle?.vehicleAge +
        " " +
        (vehicle?.vehicleAge === 1
          ? i18next.t("navigation:year").toLowerCase()
          : i18next.t("navigation:years").toLowerCase()),
    ],
    [i18next.t("navigation:_vehicle_page_resume_row_8"), "marciante"],
    [
      i18next.t("navigation:_vehicle_page_resume_row_9"),
      <DriverLink
        driverID={
          (driver as any)?.driverInfo.driverIDOriginal ?? driver?.driverInfo.driverID
        }
        driverLabel={
          driver?.driverInfo.driverName + " " + driver?.driverInfo.driverSurname
        }
        navigate={AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS}
      />,
    ],
    [i18next.t("navigation:_vehicle_page_resume_row_10"), getKMCurrentActiveOnly()],
    [
      i18next.t("navigation:_vehicle_page_resume_row_11"),
      (driver?.driverInfo.driverAddressStreet ?? "-") +
        ", " +
        (driver?.driverInfo.driverAddressNumber ?? "-"),
    ],
    [
      i18next.t("navigation:_vehicle_page_resume_row_12"),
      vehicle?.contractTypeName ?? "-",
    ],
  ];

  const loadVehicleHistory = () => {
    ApiService.VehicleServiceController.VehicleBriefHistoryGet(
      vehicle?.vehicleID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setHistory(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  const downloadMctcFile = (blobUri: string) => {
    setProcessing(true);
    ApiService.UploadController.GetSASString(
      "attachmentmctc",
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(blobUri) + ".pdf");
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setProcessing(false);
      }
    );
  };

  const loadMctcAttachment = () => {
    setLoadingMctcAttachment(true);
    ApiService.VehicleDataController.MctcAttachmentGet(
      null,
      null,
      vehicle?.vehicleLicensePlate,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setMctcAttachment(response.payload);
          if(response.payload.length > 0){
            dispatch(VehiclesActions.setVehicleMctc(JSON.parse(response.payload[0].mctcAttachmentJSON)));
          }else{
            dispatch(VehiclesActions.setVehicleMctc(undefined));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setLoadingMctcAttachment(false);
      }
    );
  }

  useEffect(() => {
    loadVehicleHistory();
    getVehicleDeadlines();
    vehicleGetDriverKm();
  }, []);

  useEffect(() => {
    if(vehicle && vehicle.vehicleID){
      loadMctcAttachment();
    }
  }, [vehicle, vehicle?.vehicleID])

  const processedHistory: IGitCordBoxProps[] = history.map(
    (x: IVehicleHistBrief, i: number) => {
      return {
        content: <div>{x.vehicleEventDescription}</div>,
        date: x.vehicleEventDT,
        type: i === 0 ? "first" : i === history.length - 1 ? "last" : "middle",
      };
    }
  );

  const getSillabationName = (name: string | null) => {
    if (name) {
      return (
        name.charAt(0).toLocaleUpperCase() +
        name.charAt(name.length / 2).toLocaleUpperCase()
      );
    }
    return "-";
  };

  const processedDeadlines: IExpiryBoxProps[] = deadlines.map(
    (x: IVehicleDeadlineItem, i: number) => {
      return {
        title: x.deadlineTypeName ?? "-",
        subTitle: x.deadlineDescription ?? "",
        date: displayUTC0_ISODate(x.deadlineExpirationDT, true),
        icon: (
          <div className="vehicles-page-center-circle-icon">
            {getSillabationName(x.deadlineTypeName)}
          </div>
        ),
      };
    }
  );

  const getProperIcon = () => {
    let status = vehicle?.vehicleStatusID;
    switch (status) {
      case 1:
        return (
          <div className="vehicles-page-center-icon vehicles-page-center-icon-dismiss">
            <CancelIcon />
          </div>
        );
      case 2:
        return (
          <div className="vehicles-page-center-icon vehicles-page-center-icon-toassign">
            <AssignmentLateIcon />
          </div>
        );
      case 3:
        return (
          <div className="vehicles-page-center-icon vehicles-page-center-icon-checkicon">
            <CheckIcon />
          </div>
        );
    }
    return <div></div>;
  };

  return (
    <div className="vehicles-page-sub-pages-wrap">
      <div className="vehicles-page-sub-pages-row">
        <IconCardComponent
          image={getProperIcon()}
          sideInfo={
            <div style={{ fontSize: "0.9em" }}>
              <div>{i18next.t("navigation:_vehicle_page_status")}</div>
              <small style={{ opacity: "0.5" }}>{vehicle?.vehicleStatusName}</small>
            </div>
          }
        />
        <IconCardComponent
          image={
            <div className="vehicles-page-center-icon">
              <div>
                {selectedAssignment?.driverDisplayName
                  ?.split(" ")
                  .map((n) => n[0])
                  .join(" ")
                  .toLocaleUpperCase()}
              </div>
            </div>
          }
          sideInfo={
            <div style={{ fontSize: "0.9em" }}>
              <div>{i18next.t("navigation:_vehicle_page_assegnee")}</div>
              <small style={{ opacity: "0.5" }}>
                {selectedAssignment?.driverDisplayName}
              </small>
            </div>
          }
        />

        <IconCardComponent
          image={<div className="vehicles-page-center-circle-icon">KM</div>}
          sideInfo={
            <div style={{ fontSize: "0.9em" }}>
              <div>{i18next.t("navigation:_vehicle_page_assign_km_tot")}&nbsp;
                <small style={{ opacity: "0.5" }}>{
                  driverKm.find((x: IVehicleDriverKm) => !x.isTotal && x.driverID === selectedAssignment?.driverID)?.km ?? '-'} Km
                </small>
              </div>
              <div>{i18next.t("navigation:_vehicle_page_km_tot")}&nbsp;
                <small style={{ opacity: "0.5" }}>
                  {driverKm.find((x: IVehicleDriverKm) => x.isTotal)?.km} Km
                </small>
              </div>
            </div>
          }
        />
      </div>
      <div style={{ marginTop: "1em" }}></div>

      <div className="vehicles-page-sub-pages-underzone">
        <div className="half-size-content">
          <div className="partial-size-content">
            <div className="vehicles-page-sub-pages-title">
              {i18next.t("navigation:_vehicle_page_resume")}
            </div>
            {tableData.map((x: (string | JSX.Element)[], i: number) => {
              return (
                <div key={i} className="vehicles-page-sub-pages-table-row">
                  <div className="vehicles-page-sub-pages-table-left">{x[0]}:</div>
                  <div className="vehicles-page-sub-pages-table-right">{x[1]}</div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", gap: "1em" }}>
            {mctcAttachment && mctcAttachment.length > 0 && (
              <Button
                variant="contained"
                startIcon={<Download />}
                onClick={() => {
                  downloadMctcFile(mctcAttachment[0].attachmentURL);
                }}
                >
                {i18next.t("navigation:download_mctc")}
              </Button>
            )}
           {(disposed === -1 || disposed === 0) && (
              <>
                {activeAssignment?.assignmentID === selectedAssignment?.assignmentID && (
                  <Button
                    disabled={disposed === -1}
                    variant={"contained"}
                    onClick={() => {
                      SummonModal("SubstituteVehicleModal");
                    }}
                  >
                    {vehicleSubstitutes.filter((x: IVehicleSubsitute) => x.endDT === null)
                      .length > 0
                      ? i18next.t("navigation:deactivateSubstituteVehicle")
                      : i18next.t("navigation:activateSubstituteVehicle")}
                  </Button>
                )}
                <Button
                  disabled={disposed === -1}
                  variant="contained"
                  onClick={() => {
                    SummonModal("DismissVehicleModal");
                  }}
                >
                  {i18next.t("navigation:dismissSubstituteVehicle")}
                </Button>
              </>
            )}
          </div>

          <DismissVehicleModal
            dismissDone={() => {
              dispatch(VehiclesActions.setVehicleDisposed(1));
            }}
          />
          <SubstituteVehicleModal />
        </div>
        <div className="half-size-content">
          <div className="vehicles-page-sub-pages-card-box">
            <div className="vehicles-page-sub-pages-title">
              {i18next.t("navigation:_vehicle_page_next_expirations")}
            </div>
            <div className="vehicles-page-sub-pages-card-box-content">
              {processedDeadlines.length === 0 && (
                <Alert severity="info">
                  {i18next.t("navigation:no_deadline_events")}
                </Alert>
              )}
              {processedDeadlines.map((x: IExpiryBoxProps, i: number) => {
                return <ExpiryBox {...x} key={i} />;
              })}
            </div>
          </div>
          <div style={{ height: "0.5em" }}></div>
          
          <div className="vehicles-page-sub-pages-card-box">
            <div className="vehicles-page-sub-pages-title">
              {i18next.t("navigation:_vehicle_page_history")}
            </div>
            <div className="vehicles-page-sub-pages-card-box-content">
              {processedHistory.length === 0 && (
                <Alert severity="info">{i18next.t("navigation:no_history_events")}</Alert>
              )}
              {processedHistory.map((x: IGitCordBoxProps, i: number) => {
                return <GitCordBox {...x} key={i} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <LoaderBackdrop loading={processing || loadingMctcAttachment} />
    </div>
  );
};

export default VehiclesPage_subPages_status;
