import "./AnagraphicsPage.scss";

import car from "./../../../../Assets/ImagesEmbedded/veicolo_striscia.jpg";

import UrlTabbedView from "../../../../Components/UrlTabbedView/UrlTabbedView";
import AppRoutes from "../../../../Costants/AppRoutes";

import AnagraphicsSuppliers from "./AnagraphicsPageTabs/AnagraphicsSuppliers";
import AnagraphicsCompanies from "./AnagraphicsPageTabs/AnagraphicsCompanies";
import AnagraphicsDrivers from "./AnagraphicsPageTabs/AnagraphicsDrivers";

import { useSelector } from "react-redux";
import { IUser } from "../../../../Models/IUser";
import { GlobalState } from "../../../../Reducers/RootReducer";
import { dateIsPast } from "../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const AnagraphicsPage = () => {
  /* REDUX */
  // User
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const tabs: any[] = [
    {
      label: "_data_suppliers",
      path:
        AppRoutes.INTERNAL_DASHBOARD +
        AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS_TAB,
      element: <AnagraphicsSuppliers />,
    },
    {
      label: "_data_drivers",
      path:
        AppRoutes.INTERNAL_DASHBOARD +
        AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS_TAB,
      element: <AnagraphicsDrivers />,
    },
  ];

  if (loggedUser?.accountTypeID === 2) {
    tabs.push({
      label: "_data_companies",
      path:
        AppRoutes.INTERNAL_DASHBOARD +
        AppRoutes.DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES_TAB,
      element: <AnagraphicsCompanies />,
    });
  }

  /* RETURN */
  return (
    <div className="veichles-page-main-wrap">
      <div
        className="vehicles-banner-image-title"
        style={{ backgroundImage: `url(${car})` }}
      >
        <div className="vehicles-banner-image-title-inner display-flex-center">
          <div className="vehicles-banner-image-title-label">
            Gestisci Anagrafiche
          </div>
        </div>
      </div>

      <div style={{ height: "1em" }}></div>

      <UrlTabbedView tabs={tabs} />
    </div>
  );
};

export default AnagraphicsPage;
