/* LIBRARY */
import i18next from "i18next";
// Icons
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { numberToLocalDecoder } from "../../../../../Utils/Decoder";

interface ICO2Emission_EcologicDriver {
  ecologicDriver: any[];
}

/* COMPONENT */
const CO2Emission__StatisticsPage_EcologicDriver = (
  props: ICO2Emission_EcologicDriver
) => {
  /* RETURN */
  return (
    <div className="dashboardForm__card display-flex-end-column CO2Emission__card">
      <div className="display-flex-horizontal-space-between full-height padding-small CO2Emission__EcologicDriver">
        <div className="display-flex-column padding-small CO2Emission__EcologicDriver-box">
          <span className="text-color-hint font-size-medium text-bold">
            {i18next.t("navigation:_statistics_MostEcologicDriver")}
          </span>

          <span className="text-thin">
            {props.ecologicDriver[0].driverDisplayName || "-"}
          </span>

          <div className="display-flex-horizontal-space-between full-height">
            <span className=" text-color-hint font-size-huge text-bold">
              {numberToLocalDecoder(props.ecologicDriver[0].totalCO2g / 1000)} Kg
            </span>

            {props.ecologicDriver[0].trendCO2Percentage ? (
              <div className="display-flex-center">
                <span className="font-size-small">
                  {numberToLocalDecoder(props.ecologicDriver[0].trendCO2Percentage)} %
                </span>

                <TrendingDownIcon className="CO2Emission__icon-down" />
              </div>
            ) : (
              ""
            )}
          </div>

          <span className="text-thin font-size-small">
            {numberToLocalDecoder(props.ecologicDriver[0].trendCO2g / 1000)} Kg
            <span
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:_statistics_CO2Saved") ?? "",
              }}
            />
          </span>
        </div>

        <div className="display-flex-column padding-small CO2Emission__EcologicDriver-box">
          <span className="text-color-hint font-size-medium text-bold">
            {i18next.t("navigation:_statistics_LeastEcologicDriver")}
          </span>

          <span className="text-thin">
            {props.ecologicDriver[1].driverDisplayName || "-"}
          </span>

          <div className="display-flex-horizontal-space-between full-height">
            <span className=" text-color-hint font-size-huge text-bold">
              {numberToLocalDecoder(props.ecologicDriver[1].totalCO2g / 1000)} Kg
            </span>

            {props.ecologicDriver[1].trendCO2Percentage ? (
              <div className="display-flex-center">
                <span className="font-size-small">
                  {numberToLocalDecoder(props.ecologicDriver[1].trendCO2Percentage)} %
                </span>
                
                <TrendingUpIcon className="CO2Emission__icon-up" />
              </div>
            ) : (
              ""
            )}
          </div>

          <span className="text-thin font-size-small">
            {numberToLocalDecoder(props.ecologicDriver[1].trendCO2g / 1000)} Kg
            <span
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:_statistics_CO2Excess") ?? "",
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CO2Emission__StatisticsPage_EcologicDriver;
