const HOME_PAGE_ROUTE = "/";
const WHO_WE_ARE_ROUTE = "/whoWeAre";
const PAYMENT_READY_PAGE = "/paymentready";
const FUNCITIONALITIES_ROUTE = "/functionalities";
const RATES_ROUTE = "/rates";
const MARKETPLACE_ROUTE = "/Marketplace";
const WHYUS_ROUTE = "/whyUs";
const STRUCTURE_ROUTE = "/structure";
const INFO_RECOGNITION_ROUTE = "/infoRecognitions";
const CONTACTS_ROUTE = "/contacts";
const MOBILE_MENU_ROUTE = "/menu";
const LOGIN_ROUTE = "/login";
const FORGOT_PASSWORD_ROUTE = "/forgotPassword";
const RESEND_EMAIL_ROUTE = "/resendemail";
const REGISTRATION_ROUTE = "/signUp";
const LOGOUT_ROUTE = "/logout";
const CONTINUE_REGISTRATION_ROUTE = "/enrollmentWizard";
const CONFIRM_REGISTRATION = "/signUpConfirm";
const CONFIRM_PASSWORD_RESET = "/passwordResetConfirm";
const AWAIT_CONFIRMATION_PAYMENT = "/awaitConfirmPayment";
const EXTRA_SHOP = "/externalShop";
const INTERNAL_DASHBOARD_WILDCARD = `/dashboard*`;
const INTERNAL_DASHBOARD = `/dashboard`;

const DASHBOARD___ADDITIONAL_SERVICE_ROUTE = `/additionalServices`;
const DASHBOARD___EXPIRY_CALENDAR_ROUTE = `/calendar`;
const DASHBOARD___MESSAGING_ROUTE = `/messaging`;
const DASHBOARD___STATISTIC_ROUTE = `/statistics`;
const DASHBOARD___SERVICES_ROUTE = `/services`;

const DASHBOARD___CONFIGURATION_ROUTE = `/configuration`;
const DASHBOARD___CONFIGURATION_ROUTE__VEHICLE = `${DASHBOARD___CONFIGURATION_ROUTE}/vehicle`;
const DASHBOARD___CONFIGURATION_ROUTE__VEHICLE_COMPARE_SUMMARY = `${DASHBOARD___CONFIGURATION_ROUTE__VEHICLE}/summary`;
const DASHBOARD___CONFIGURATION_ROUTE__GROUP = `${DASHBOARD___CONFIGURATION_ROUTE}/group`;
const DASHBOARD___CONFIGURATION_ROUTE__GROUP__OPEN = `${DASHBOARD___CONFIGURATION_ROUTE__GROUP}/openList`;
const DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE = `${DASHBOARD___CONFIGURATION_ROUTE__GROUP}/closeList`;
const DASHBOARD___CONFIGURATION_ROUTE__REQUESTS = `${DASHBOARD___CONFIGURATION_ROUTE}/requests`;
const DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_SUMMARY = `${DASHBOARD___CONFIGURATION_ROUTE__REQUESTS}/vehicleSummary`;
const DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_ORDERS = `${DASHBOARD___CONFIGURATION_ROUTE__REQUESTS}/orders`;
const DASHBOARD___CONFIGURATION_ROUTE__OFFERS_SEND_OFFER = `${DASHBOARD___CONFIGURATION_ROUTE}/sendOffer`;
const DASHBOARD___CONFIGURATION_ROUTE__OFFERS_RECEVICED = `${DASHBOARD___CONFIGURATION_ROUTE}/offersReceived`;
const DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY = `${DASHBOARD___CONFIGURATION_ROUTE}/promptDelivery`;
const DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY_SEARCH = `${DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY}/search`;

const DASHBOARD___QUOTE_ROUTE = `/quote`;
const DASHBOARD___QUOTE_ROUTE__REQUEST = `${DASHBOARD___QUOTE_ROUTE}/requests`;
const DASHBOARD___QUOTE_ROUTE__PROPOSE = `${DASHBOARD___QUOTE_ROUTE}/propose`;
const DASHBOARD___QUOTE_ROUTE__RECEIVED = `${DASHBOARD___QUOTE_ROUTE}/received`;

const DASHBOARD___HELP_ROUTE = `/help`;

const DASHBOARD___DEALER = `/dealer`;
const DASHBOARD___DEALER_REQUEST_RECEIVED = `${DASHBOARD___DEALER}/requestreceived`;
const DASHBOARD___DEALER_PROPOSAL = `${DASHBOARD___DEALER_REQUEST_RECEIVED}/proposal`;
const DASHBOARD___DEALER_OFFERS_SENT = `${DASHBOARD___DEALER}/offersent`;

const DASHBOARD___ADMIN_ROUTE = `/admin`;
const DASHBOARD___ADMIN_ROUTE__SUPPLIERS = `${DASHBOARD___ADMIN_ROUTE}/suppliers`;
const DASHBOARD___ADMIN_ROUTE__SERVICES = `${DASHBOARD___ADMIN_ROUTE}/services`;
const DASHBOARD___ADMIN_ROUTE__BROKERS = `${DASHBOARD___ADMIN_ROUTE}/brokers`;
const DASHBOARD___ADMIN_ROUTE__USERS = `${DASHBOARD___ADMIN_ROUTE}/users`;
const DASHBOARD___ADMIN_ROUTE__CLIENTS = `${DASHBOARD___ADMIN_ROUTE}/clients`;
const DASHBOARD___ADMIN_ROUTE__FLEET_PLANS = `${DASHBOARD___ADMIN_ROUTE}/fleetplans`;
const DASHBOARD___ADMIN_ROUTE__CARTAXES = `${DASHBOARD___ADMIN_ROUTE}/car-taxes`;
const DASHBOARD___ADMIN_ROUTE__ENTITIES = `${DASHBOARD___ADMIN_ROUTE}/entities`;
const DASHBOARD___ADMIN_ROUTE__ARTICLES = `${DASHBOARD___ADMIN_ROUTE}/articles`;
const DASHBOARD___ADMIN_ROUTE__BENEFITS = `${DASHBOARD___ADMIN_ROUTE}/fringe-benefits`;
const DASHBOARD___ADMIN_ROUTE__INVOICES = `${DASHBOARD___ADMIN_ROUTE}/invoices`;
const DASHBOARD___ADMIN_ROUTE__QUATTRO_RUOTE = `${DASHBOARD___ADMIN_ROUTE}/four-wheels`;
const DASHBOARD___ADMIN_ROUTE__MENU = `${DASHBOARD___ADMIN_ROUTE}/menu`;
const DASHBOARD___ADMIN_ROUTE__FAQ = `${DASHBOARD___ADMIN_ROUTE}/faq`;
const DASHBOARD___ADMIN_ROUTE__VOUCHER = `${DASHBOARD___ADMIN_ROUTE}/voucher`;
const DASHBOARD___ADMIN_ROUTE__ECONOMIC_CREDITS = `${DASHBOARD___ADMIN_ROUTE}/economiccredits`;

const DASHBOARD___ANAGRAPHICS_ROUTE = `/anagraphics`;
const DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS = `${DASHBOARD___ANAGRAPHICS_ROUTE}/suppliers`;
const DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES = `${DASHBOARD___ANAGRAPHICS_ROUTE}/companies`;
const DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS = `${DASHBOARD___ANAGRAPHICS_ROUTE}/drivers`;
const DASHBOARD___ANAGRAPHICS_ROUTE__FLEET_MANAGERS = `${DASHBOARD___ANAGRAPHICS_ROUTE}/fleet-managers`;
const DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS_TAB = `${DASHBOARD___ANAGRAPHICS_ROUTE}/tab/suppliers`;
const DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES_TAB = `${DASHBOARD___ANAGRAPHICS_ROUTE}/tab/companies`;
const DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS_TAB = `${DASHBOARD___ANAGRAPHICS_ROUTE}/tab/drivers`;

const DASHBOARD___USERPAGE_ROUTE = "/profile";

const DASHBOARD___VEHICLES_ROUTE = `/vehicles`;
const DASHBOARD___VEHICLES_ROUTE__TELEPASS = `${DASHBOARD___VEHICLES_ROUTE}/telepass`;
const DASHBOARD___VEHICLES_ROUTE__ORDERS = `${DASHBOARD___VEHICLES_ROUTE}/orders`;
const DASHBOARD___VEHICLES_ROUTE__CARDS = `${DASHBOARD___VEHICLES_ROUTE}/cards`;
const DASHBOARD___VEHICLES_ROUTE__KM_SET = `${DASHBOARD___VEHICLES_ROUTE}/kmset`;
const DASHBOARD___VEHICLES_ROUTE__SATELLITE_APPARATUS = `${DASHBOARD___VEHICLES_ROUTE}/satellite-apparatus`;

const DASHBOARD___VEHICLES_ROUTE__STATUS = `${DASHBOARD___VEHICLES_ROUTE}/status`;

const DASHBOARD___SERVICES_ROUTE__ASSURANCES = `${DASHBOARD___SERVICES_ROUTE}/assurances`;
const DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES = `${DASHBOARD___SERVICES_ROUTE}/telepass-usages`;
const DASHBOARD___SERVICES_ROUTE__FORNITURE = `${DASHBOARD___SERVICES_ROUTE}/supplies`;
const DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS = `${DASHBOARD___SERVICES_ROUTE}/purchases-subscriptions`;
const DASHBOARD___SERVICES_ROUTE__PURCHASE_SINGLE = `${DASHBOARD___SERVICES_ROUTE}/purchases-store`;
const DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE = `${DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS}/reduce-subscription`;

const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE}/payments`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE}/fleet`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__MOVEMENTS = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET}/movements`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__RESERVATION = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET}/reservation`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__OUTGOING_VEHICLE = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET}/outgoing-vehicle`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET}/incoming-vehicle`;
const DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE}/tickets`;

const DASHBOARD___STATISTIC_ROUTE__CO2 = `${DASHBOARD___STATISTIC_ROUTE}/co2-emissions`;
const DASHBOARD___STATISTIC_ROUTE__PROJECTION = `${DASHBOARD___STATISTIC_ROUTE}/projection`;
const DASHBOARD___STATISTIC_ROUTE__FUEL_ANOMALY = `${DASHBOARD___STATISTIC_ROUTE}/fuel-anomaly`;
const DASHBOARD___STATISTIC_ROUTE__OWNERSHIP_COST = `${DASHBOARD___STATISTIC_ROUTE}/ownership-costs`;

const SEARCH = "/search";
const SEARCH_VEHICLE_DETAIL = `${SEARCH}/vehicledetail`;

const CREATE_VEHICLE_MARKER = `/newvehicle`;

const DASHBOARD___SEARCH_VEHICLE_ROUTE__CREATE = `${INTERNAL_DASHBOARD}${SEARCH_VEHICLE_DETAIL}${CREATE_VEHICLE_MARKER}`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/status`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__TELEPASS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/telepass`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__ORDERS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/orders`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__OWNERSHIP_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/ownership`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__ASSURANCE_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/assurance`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__TICKETS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/tickets`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__CONTRACTS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/contracts`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__OTHER_SERVICES_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/other-services`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__DATA_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/data`;
const DASHBOARD___SEARCH_VEHICLE_ROUTE__CARDS_TAB = `${SEARCH_VEHICLE_DETAIL}/tab/cards`;

const VIEW_SUPPLIES = `${DASHBOARD___SERVICES_ROUTE__FORNITURE}/view-supplies`;
const LOAD_FILE_SUPPLIES = `${DASHBOARD___SERVICES_ROUTE__FORNITURE}/load-file-supplies`;

const DOWNLOAD_UPLOAD_PAYMENTS = `${INTERNAL_DASHBOARD}${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS}/download-upload`;
const PAYMENTS_REGISTER = `${INTERNAL_DASHBOARD}${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS}/payment-register`;
const MANUAL_PAYMENTS = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS}/manual-payments`;
const PAID_PAYMENTS = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS}/paid-payments`;

const TICKETS_MANAGEMENT = `${DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS}/tickets-management`;

const VIEW_TELEPASS_USAGE = `${DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES}/view-telepass-usage`;
const LOAD_FILE_TELEPASS_USAGE = `${DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES}/load-file-telepass-usage`;

const PAYMENTS_DEBIT_PAGE = "paymentdebit";
const DOWNLOAD_DATA_PAGE = `${INTERNAL_DASHBOARD}/download-data`;
const STORE_SUBSCRIPTION_PAGE = "/store-subscription";
const DASHBOARD___ADMIN_ROUTE__FAQ_CATEGORIES = `${DASHBOARD___ADMIN_ROUTE}/faq-categories`;
const DASHBOARD___QUOTE_ROUTE__DELIVERED = `${DASHBOARD___QUOTE_ROUTE}/delivered`;
const COOKIE_POLICY_PAGE = "/cookie-policy";


const AppRoutes = {
  PAYMENT_READY_PAGE,

  HOME_PAGE_ROUTE,
  WHO_WE_ARE_ROUTE,
  FUNCITIONALITIES_ROUTE,
  RATES_ROUTE,
  MARKETPLACE_ROUTE,
  WHYUS_ROUTE,
  STRUCTURE_ROUTE,
  INFO_RECOGNITION_ROUTE,
  CONTACTS_ROUTE,
  MOBILE_MENU_ROUTE,

  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESEND_EMAIL_ROUTE,
  REGISTRATION_ROUTE,
  LOGOUT_ROUTE,
  CONTINUE_REGISTRATION_ROUTE,
  CONFIRM_REGISTRATION,
  CONFIRM_PASSWORD_RESET,
  EXTRA_SHOP,
  INTERNAL_DASHBOARD,
  INTERNAL_DASHBOARD_WILDCARD,
  DASHBOARD___HELP_ROUTE,
  DASHBOARD___DEALER_REQUEST_RECEIVED,
  DASHBOARD___DEALER_PROPOSAL,
  DASHBOARD___DEALER_OFFERS_SENT,
  DASHBOARD___ADMIN_ROUTE,
  DASHBOARD___ADMIN_ROUTE__SUPPLIERS,
  DASHBOARD___ADMIN_ROUTE__SERVICES,
  DASHBOARD___ADMIN_ROUTE__USERS,
  DASHBOARD___ADMIN_ROUTE__CLIENTS,
  DASHBOARD___ADMIN_ROUTE__BROKERS,
  DASHBOARD___ADMIN_ROUTE__FLEET_PLANS,
  DASHBOARD___ADMIN_ROUTE__CARTAXES,
  DASHBOARD___ADMIN_ROUTE__ENTITIES,
  DASHBOARD___ADMIN_ROUTE__ARTICLES,
  DASHBOARD___ADMIN_ROUTE__BENEFITS,
  DASHBOARD___ADMIN_ROUTE__INVOICES,
  DASHBOARD___ADMIN_ROUTE__QUATTRO_RUOTE,
  DASHBOARD___ADMIN_ROUTE__MENU,
  DASHBOARD___ADMIN_ROUTE__FAQ,
  DASHBOARD___ADMIN_ROUTE__VOUCHER,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__PAYMENTS,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__MOVEMENTS,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__RESERVATION,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__OUTGOING_VEHICLE,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE,
  DASHBOARD___ADDITIONAL_SERVICE_ROUTE__TICKETS,
  DASHBOARD___ANAGRAPHICS_ROUTE,
  DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS,
  DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES,
  DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS,
  DASHBOARD___ANAGRAPHICS_ROUTE__SUPPLIERS_TAB,
  DASHBOARD___ANAGRAPHICS_ROUTE__COMPANIES_TAB,
  DASHBOARD___ANAGRAPHICS_ROUTE__DRIVERS_TAB,
  DASHBOARD___ANAGRAPHICS_ROUTE__FLEET_MANAGERS,
  DASHBOARD___EXPIRY_CALENDAR_ROUTE,
  DASHBOARD___CONFIGURATION_ROUTE,
  DASHBOARD___CONFIGURATION_ROUTE__VEHICLE,
  DASHBOARD___CONFIGURATION_ROUTE__VEHICLE_COMPARE_SUMMARY,
  DASHBOARD___CONFIGURATION_ROUTE__GROUP,
  DASHBOARD___CONFIGURATION_ROUTE__GROUP__OPEN,
  DASHBOARD___CONFIGURATION_ROUTE__GROUP__CLOSE,
  DASHBOARD___CONFIGURATION_ROUTE__REQUESTS,
  DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_SUMMARY,
  DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_ORDERS,
  DASHBOARD___CONFIGURATION_ROUTE__OFFERS_SEND_OFFER,
  DASHBOARD___CONFIGURATION_ROUTE__OFFERS_RECEVICED,
  DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY,
  DASHBOARD___CONFIGURATION_ROUTE__PROMPT_DELIVERY_SEARCH,
  DASHBOARD___QUOTE_ROUTE,
  DASHBOARD___QUOTE_ROUTE__REQUEST,
  DASHBOARD___QUOTE_ROUTE__PROPOSE,
  DASHBOARD___QUOTE_ROUTE__RECEIVED,
  DASHBOARD___MESSAGING_ROUTE,
  DASHBOARD___STATISTIC_ROUTE,
  DASHBOARD___STATISTIC_ROUTE__CO2,
  DASHBOARD___STATISTIC_ROUTE__PROJECTION,
  DASHBOARD___STATISTIC_ROUTE__FUEL_ANOMALY,
  DASHBOARD___STATISTIC_ROUTE__OWNERSHIP_COST,
  DASHBOARD___SERVICES_ROUTE,
  DASHBOARD___SERVICES_ROUTE__ASSURANCES,
  DASHBOARD___SERVICES_ROUTE__TELEPASS_USAGES,
  DASHBOARD___SERVICES_ROUTE__FORNITURE,
  DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS,
  DASHBOARD___SERVICES_ROUTE__PURCHASE_SINGLE,
  DASHBOARD___VEHICLES_ROUTE,
  DASHBOARD___VEHICLES_ROUTE__STATUS,
  DASHBOARD___VEHICLES_ROUTE__TELEPASS,
  DASHBOARD___VEHICLES_ROUTE__ORDERS,
  DASHBOARD___VEHICLES_ROUTE__CARDS,
  DASHBOARD___VEHICLES_ROUTE__KM_SET,
  DASHBOARD___VEHICLES_ROUTE__SATELLITE_APPARATUS,
  DASHBOARD___USERPAGE_ROUTE,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__CREATE,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__TELEPASS_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__OWNERSHIP_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__ASSURANCE_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__TICKETS_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__OTHER_SERVICES_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__CONTRACTS_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__DATA_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__ORDERS_TAB,
  DASHBOARD___SEARCH_VEHICLE_ROUTE__CARDS_TAB,
  DASHBOARD___ADMIN_ROUTE__ECONOMIC_CREDITS,
  SEARCH,
  SEARCH_VEHICLE_DETAIL,
  VIEW_SUPPLIES,
  LOAD_FILE_SUPPLIES,
  DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE,
  DOWNLOAD_UPLOAD_PAYMENTS,
  PAYMENTS_REGISTER,
  MANUAL_PAYMENTS,
  PAID_PAYMENTS,
  TICKETS_MANAGEMENT,
  CREATE_VEHICLE_MARKER,

  VIEW_TELEPASS_USAGE,
  LOAD_FILE_TELEPASS_USAGE,

  AWAIT_CONFIRMATION_PAYMENT,
  PAYMENTS_DEBIT_PAGE,
  DOWNLOAD_DATA_PAGE,
  STORE_SUBSCRIPTION_PAGE,
  DASHBOARD___ADMIN_ROUTE__FAQ_CATEGORIES,
  DASHBOARD___QUOTE_ROUTE__DELIVERED,
  COOKIE_POLICY_PAGE
};

export default AppRoutes;
