import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IMessageInsert } from "../../Models/IMessages";
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const MessagesController = {
  MessagesGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.MESSAGES_GET;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  MessagesInsert: async (
    message: IMessageInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.MESSAGES_INSERT;
    request.method = "POST";
    request.payload = message;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  MessagesMarkAsRead: async (
    messageID: number,
    forwardedToMessageID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.MESSAGES_UPDATE;
    request.method = "PUT";
    request.payload = {
      messageID: messageID,
      read: true,
      forwardedToMessageID: forwardedToMessageID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  MessageAttachmentGet: async (
    messageID?: number,
    messageAttachmentID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.MESSAGES_ATTACHMENT_GET;
    let params: string[] = [];
    if (messageID) {
      params.push("messageID=" + messageID);
    }
    if (messageAttachmentID) {
      params.push("messageAttachmentID=" + messageAttachmentID);
    }
    let extra = params.length > 0 ? "?" + params.join("&") : "";
    request.url += extra;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  MessageAttachmentInsert: async (
    messageID: number,
    messageAttachmentName: string,
    messageAttachmentURL: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.MESSAGES_ATTACHMENT_INSERT;
    request.method = "POST";
    request.payload = {
      messageID,
      messageAttachmentName,
      messageAttachmentURL,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default MessagesController;
