/* REACT */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import { IContractGetProperty, IContractGetType } from "../../../../../Models/IContract";
import { IINSERT_CarConfigurator_Supplier_SendOffer } from "../../../../../Models/ICarConfigurator";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import AttachementMainHandler from "../../../../../Components/AttachmentMainHandler/AttachmentMainHandler";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";

/* MUI */
import { Box, Button } from "@mui/material";
// Icons
import SaveIcon from "@mui/icons-material/Save";

/* COMPONENT */
const FormOfferPage_Supplier = () => {
  /* STATES */
  // Form
  const [formData, setFormData] = useState<any>([]);
  // Request info (get)
  const [requestInfo, setRequestInfo] = useState<any>([]);
  // Contract list
  const [contractType, setContractType] = useState<IContractGetType[]>([]);
  // Contract properties
  const [contractProperties, setContractProperties] = useState<IContractGetProperty[]>(
    []
  );
  // Loading
  const [loadingContractType, setLoadingContractType] = useState<boolean>(false);
  const [loadingRequestInfo, setLoadingRequestInfo] = useState<boolean>(false);
  const [loadingInsertOffer, setLoadingInsertOffer] = useState<boolean>(false);
  const [loadingContractProperties, setLoadingContractProperties] =
    useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");

  /* URL PARAMS */
  const [params] = useSearchParams();
  const requestNumber: string | null = params.get("requestNumber");

  /* API */
  // Api to load contract type
  const loadContractType = () => {
    setLoadingContractType(true);

    ApiService.ContractController.ContractGetContractType(
      true,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setContractType(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingContractType(false);
      }
    );
  };

  // API to load the property list based on cotnract type
  const loadContractProperties = (contractTypeID: number) => {
    if (contractTypeID) {
      setLoadingContractProperties(true);

      ApiService.ContractController.ContractGetPropertyList(
        true,
        false,
        true,
        contractTypeID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setContractProperties(response.payload);

            multiformInputs();
          } else {
            ToastMessage(response.error, "error");
          }

          setLoadingContractProperties(false);
        }
      );
    }
  };

  // API to load request info
  const GET_CarConfigurator_Supplier_SendOffer = () => {
    setLoadingRequestInfo(true);

    ApiService.CarConfiguratorController.GET_CarConfigurator_Supplier_SendOffer(
      requestNumber,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setRequestInfo(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingRequestInfo(false);
      }
    );
  };

  // Insert request
  const INSERT_CarConfigurator_Supplier_SendOffer = (
    data: IINSERT_CarConfigurator_Supplier_SendOffer
  ) => {
    setLoadingInsertOffer(true);

    ApiService.CarConfiguratorController.INSERT_CarConfigurator_Supplier_SendOffer(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:requestSent"), "success");
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }

        setLoadingInsertOffer(false);
      }
    );
  };

  /* USE EFFECT */
  // First render
  useEffect(() => {
    loadContractType();

    requestNumber && GET_CarConfigurator_Supplier_SendOffer();
  }, []);

  // Render based on contract type
  useEffect(() => {
    if (formData?.contractType) {
      loadContractProperties(formData?.contractType);
    }
  }, [formData?.contractType]);

  // /* FUNCTIONS */
  // Convert the form value from state to API
  const fromStateToAPI = (data: any) => {
    const tempData = lodash.cloneDeep(data);

    tempData["validityDays"] = +tempData["validityDays"];
    tempData["requestNumber"] = requestNumber;
    tempData["supplierID"] = +requestInfo.supplierID;

    // Poi da togliere
    tempData["attachment"] = "Questo è un test!";

    // contractProperties convert for API
    tempData["vehicleOrderCTProperties"] = contractProperties.map(
      (x: IContractGetProperty) => {
        return {
          propertyID: x.propertyID,
          propertyValue: tempData[x.propertyName] ?? "",
        };
      }
    );

    // Delete contractProperties values in root
    for (let i = 0; i < contractProperties.length; i++) {
      delete tempData[contractProperties[i].propertyName];
    }

    // Return the converted state
    return tempData;
  };

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      // Supplier
      {
        width: 33.3,
        type: "text",
        name: "supplierID",
        defaultValue: requestInfo?.supplierName,
        label: `${i18next.t("form:suppliers")}`,
        required: true,
        disabled: true,
      },
      // Request number
      {
        width: 33.3,
        type: "text",
        name: "requestNumber",
        defaultValue: requestInfo?.requestNumber,
        label: `${i18next.t("form:requestNumber")}`,
        required: true,
        disabled: true,
      },
      // Offer number
      {
        width: 33.3,
        type: "text",
        name: "offerNumber",
        defaultValue: formData?.offerNumber,
        label: `${i18next.t("form:offerNumber")}`,
        required: true,
        inputProps: { maxLength: 100 },
      },
      // Validity in days
      {
        width: 33.3,
        type: "number",
        name: "validityDays",
        defaultValue: formData?.validityDays,
        label: `${i18next.t("form:validityDays")}`,
        required: true,
        inputProps: { maxLength: 100 },
      },
      // Arrival date
      {
        width: 33.3,
        type: "date",
        name: "arrivalDT",
        defaultValue: formData?.arrivalDT,
        label: `${i18next.t("form:orderArriveDate")}`,
        required: true,
        inputProps: { maxLength: 100 },
      },
      // Offer type - contract
      {
        width: 33.3,
        type: "select",
        name: "contractType",
        defaultValue: formData?.contractType,
        label: `${i18next.t("form:offerType")}`,
        required: true,
        options: contractType.map((item: IContractGetType) => {
          return {
            key: item.contractTypeID,
            text: item.contractTypeName?.toString(),
          };
        }),
      }
    );

    // Based on type of contract show others input
    contractProperties.map((item: IContractGetProperty, i: number) => {
      let typeID = "text";

      switch (item.propertyTypeName) {
        case "decimal":
        case "integer":
          typeID = "number";
          break;
      }

      multiformInputs.push({
        type: typeID as any,
        name: item.propertyName,
        required: item.mandatory,
        label: item.propertyClientName,
        defaultValue: "",
        width: 50,
      });
    });

    multiformInputs.push({
      width: 100,
      type: "multiline",
      name: "note",
      defaultValue: formData?.note,
      label: `${i18next.t("form:note")}`,
      required: false,
      multilineRows: 4,
    });

    return multiformInputs;
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("generic:sendOffer")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          {!loadingRequestInfo && (
            <MultiForm
              suppressLayout
              suppressSubmit={true}
              formId={"FormOfferPage_Supplier"}
              inputs={multiformInputs()}
              onChange={(data: any) => {
                setFormData({ ...formData, ...data });
              }}
              onSubmit={(data: any) => {
                INSERT_CarConfigurator_Supplier_SendOffer(fromStateToAPI(data));
              }}
            />
          )}

          <AttachementMainHandler
            attachmentFamily={"sendoffer"}
            extraGetParametersVector={
              requestNumber
                ? [
                    {
                      name: "VehiclesRequestOfferID",
                      value: +requestNumber,
                    },
                  ]
                : undefined
            }
          />

          <div className="display-flex-end padding-y-small padding-right-extrasmall">
            <Button
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={() => {
                triggerFormValidation("FormOfferPage_Supplier");
              }}
            >
              {i18next.t("navigation:send")}
            </Button>
          </div>

          <LoaderBackdrop
            loading={
              loadingContractType ||
              loadingInsertOffer ||
              loadingContractProperties ||
              loadingRequestInfo
            }
          />
        </Box>
      </div>
    </div>
  );
};

export default FormOfferPage_Supplier;
