import i18next from "i18next";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { Box, Button, ButtonGroup } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import {
  IVoucher,
  IVoucherInsert,
  IVoucherUpdate,
} from "../../../../../Models/IVoucher";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { hasWhiteSpace } from "../../../../../Utils/Validation";

const AdminPage__Voucher = () => {
  const [currentVoucher, setCurrentVoucher] = useState<any>(null);
  const [loadingVouchers, setLoadingVoucher] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const [rowsTable, setRowsTable] = useState<any>([]);

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const loadVouchers = () => {
    if (loggedUser) {
      setLoadingVoucher(true);
      ApiService.VoucherController.GetVoucher(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setVouchers(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVoucher(false);
        }
      );
    }
  };

  const updateVoucher = (data: IVoucherUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VoucherController.UpdateVoucher(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:voucherSuccessfullUpdated"),
              "success"
            );

            loadVouchers();
            DismissModal("voucher-page-modal");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const insertVoucher = (data: IVoucherInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VoucherController.InsertVoucher(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:voucherSuccessfullAdded"),
              "success"
            );
            loadVouchers();
            DismissModal("voucher-page-modal");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const deleteVoucher = (voucherID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VoucherController.DeleteVoucher(
        voucherID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:voucherSuccessfullDeleted"),
              "success"
            );
            loadVouchers();
            DismissModal("voucher-page-modal");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const renderDiscount = (props: GridRenderCellParams) => {
    return <div>{props.value * 100} %</div>;
  };

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div style={props.row.stored ? { opacity: "0.2" } : {}}>
        <Button
          onClick={() => {
            let voucher = vouchers.filter(
              (x: IVoucher) => x.voucherID === props.row.voucherID
            )[0];
            voucher.discount = voucher.discount * 100;
            setCurrentVoucher(voucher);
            SummonModal("voucher-page-modal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "voucherName",
      headerName: i18next.t("form:voucherName"),
    },
    {
      field: "discount",
      headerName: i18next.t("form:voucherDiscount"),
      renderCell: renderDiscount,
      type: 'number'
    },
    {
      field: "expirationDTView",
      headerName: i18next.t("form:voucherExpirationDT"),
    },
  ];

  useEffect(() => {
    loadVouchers();
  }, []);

  useEffect(() => {
    /* ROWS */
    const rowsDef = [];

    for (let i = 0; i < vouchers.length; i++) {
      rowsDef.push({
        id: "edit",
        voucherID: vouchers[i].voucherID,
        voucherName: vouchers[i].voucherName,
        discount: vouchers[i].discount,
        expirationDT: vouchers[i].expirationDT,
        expirationDTView: displayUTC0_ISODate(vouchers[i].expirationDT, true),
      });
    }

    setRowsTable(rowsDef);
  }, [vouchers]);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_voucher")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentVoucher(null);
                SummonModal("voucher-page-modal");
              }}
            >
              {i18next.t("navigation:_add_voucher")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsTable} />
        </Box>

        <SmartModal
          hideButtons={true}
          modalUniqueId="voucher-page-modal"
          title={
            currentVoucher && currentVoucher.voucherID
              ? i18next.t("generic:titleEditVoucher")
              : i18next.t("generic:titleAddVocuher")
          }
          modalInnerComponent={
            <MultiForm
              formId="voucher-form-modal"
              inputs={[
                {
                  type: "text",
                  name: "voucherName",
                  defaultValue: currentVoucher
                    ? currentVoucher.voucherName
                    : "",
                  width: 33.33,
                  required: true,
                  label: i18next.t("form:voucherName") as string,
                  inputProps: {
                    maxLength: 16,
                    style: { textTransform: "uppercase" },
                  },
                  errorText:
                    currentVoucher?.voucherName &&
                    currentVoucher?.voucherName.length > 0 &&
                    hasWhiteSpace(currentVoucher?.voucherName)
                      ? (i18next.t(
                          "error:voucherNameCannotContainSpace"
                        ) as string)
                      : "",
                },
                {
                  type: "number",
                  required: true,
                  inputProps: {
                    min: 0,
                    max: 100,
                  },
                  inputAdornament: { adornament: "%" },
                  name: "discount",
                  defaultValue: currentVoucher ? currentVoucher.discount : "",
                  width: 33.33,
                  label: i18next.t("form:voucherDiscount") as string,
                },
                {
                  type: "date",
                  name: "expirationDT",
                  label: i18next.t("form:voucherExpirationDT") as string,
                  defaultValue: currentVoucher
                    ? currentVoucher.expirationDT
                    : "",
                  disablePast: true,
                  required: true,
                  width: 33.33,
                },
                {
                  width: 100,
                  type: "custom",
                  name: "save",
                  element: (
                    <div className="display-flex-end">
                      <Button
                        color="error"
                        sx={{ marginRight: "1em" }}
                        disabled={!currentVoucher?.voucherID}
                        onClick={() => {
                          SummonModal("voucher-form-modal-delete");
                        }}
                        variant="contained"
                        endIcon={<DeleteIcon />}
                      >
                        {i18next.t("form:deleteVoucher")}
                      </Button>

                      <Button
                        variant="contained"
                        disabled={processing}
                        onClick={() => {
                          triggerFormValidation("voucher-form-modal");
                        }}
                        endIcon={<SaveIcon />}
                      >
                        {i18next.t("form:saveVoucher")}
                      </Button>
                      <SmartModal
                        modalUniqueId="voucher-form-modal-delete"
                        title={i18next.t("generic:titleRecordModal")}
                        modalInnerComponent={
                          <p
                            dangerouslySetInnerHTML={{
                              __html: i18next
                                .t("generic:descriptionRecordModal")
                                .replace(
                                  "CURRENT_ITEM_LIST",
                                  currentVoucher?.voucherName
                                ),
                            }}
                          />
                        }
                        onAccept={() => {
                          deleteVoucher(currentVoucher.voucherID);
                          loadVouchers();
                        }}
                        onReject={() => {}}
                      />
                    </div>
                  ),
                },
              ]}
              onChange={async (data: any) => {
                setCurrentVoucher({ ...currentVoucher, ...data });
              }}
              onSubmit={(data: any) => {
                let dataParsed = { ...data };
                delete dataParsed["save"];
                dataParsed["voucherName"] =
                  dataParsed["voucherName"]?.toUpperCase();
                dataParsed["discount"] = +(dataParsed["discount"] / 100);

                if (
                  currentVoucher &&
                  currentVoucher?.voucherName &&
                  hasWhiteSpace(currentVoucher?.voucherName)
                ) {
                  ToastMessage(
                    i18next.t("error:voucherNameCannotContainSpace") as string,
                    "error"
                  );
                  return;
                }

                if (currentVoucher && currentVoucher.voucherID) {
                  dataParsed["voucherID"] = currentVoucher.voucherID;
                  updateVoucher(dataParsed);
                } else {
                  insertVoucher(dataParsed);
                }
              }}
              suppressLayout
              suppressSubmit
            />
          }
        />
        <LoaderBackdrop loading={loadingVouchers || processing} />
      </div>
    </div>
  );
};

export default AdminPage__Voucher;
