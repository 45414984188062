import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import { formatPlate } from "../../../VehiclesPage/VehiclesPage";
import i18next from "i18next";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import SmartModal, {
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import FleetReservationForm from "../../../../../../Components/FleetReservationForm/FleetReservationForm";
import { useEffect, useState } from "react";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import { IDriverGet } from "../../../../../../Models/IDriver";
import { IVehicleReservation } from "../../../../../../Models/IVehicleReservation";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { VehiclesActions } from "../../../../../../Reducers/Vehicle/VehicleAction";
import "./Fleet__AdditionalServicesPage__Movements.scss";
import { JSONPrint } from "../../../../../../Utils/Decoder";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

const Fleet__AdditionalServicesPage__Movements = () => {
  const dispatch = useDispatch();
  /* REDUCER OF USER */
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [loadingReservation, setLoadingReservation] = useState<boolean>(false);
  const [reservationList, setReservationList] = useState<IVehicleReservation[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [preSelectedData, setPreSelectedData] = useState<any>({});
  const [movementsFilter, setMovementsFilter] = useState<any>({});
  const [rowsDef, setRowsDef] = useState<any>([]);

  const loadVehicles = () => {
    if (loggedCompany) {
      setLoading(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadingDrivers = () => {
    if (loggedCompany) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDriverList(false);
        }
      );
    }
  };

  const loadReservations = () => {
    if (loggedCompany) {
      setLoadingReservation(true);
      ApiService.VehicleReservationController.VehicleReservationGet(
        loggedCompany.companyID,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setReservationList(response.payload.filter((x: IVehicleReservation) => +x.vehicleReservationID > 0));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingReservation(false);
        }
      );
    }
  };

  /* NAVIGATE */
  const navigate = useNavigate();

  /* FUNCTIONS */
  const EditReservation = (props: GridRenderCellParams) => {
    let custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__OUTGOING_VEHICLE + "?vehicleReservationID=" + props.row.vehicleReservationID;
    if (props.row.checkOutIncomingDT && props.row.checkOutOutgoingDT) {
      custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE + "?outgoingVehicleID=" + props.row.outgoingVehicleID;
      if (props.row.checkInIncomingDT && props.row.checkInOutgoingDT) {
        custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE + "?incomingVehicleID=" + props.row.incomingVehicleID;
      }
    }

    return (
      <div className="edit-wrapper">
        <Button
          disabled={props.row.isReservationDefinitive}
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD + custom_route
            );
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const renderDateCheckIn = (props: GridRenderCellParams) => {
    let colorText = "#888";
    if (props.row.checkInIncomingDT && props.row.checkInOutgoingDT) {
      colorText = "#000";
    }
    if (props.row.isReservationDefinitive) {
      colorText = "#000";
    }
    return <div style={{ color: colorText }}>{props.value}</div>;
  };

  const renderDateCheckOut = (props: GridRenderCellParams) => {
    let colorText = "#888";
    if (props.row.checkInIncomingDT && props.row.checkInOutgoingDT) {
      colorText = "#000";
    }
    if (props.row.isReservationDefinitive) {
      colorText = "#000";
    }
    return <div style={{ color: colorText }}>{props.value}</div>;
  };

  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___VEHICLES_ROUTE__STATUS +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {formatPlate(props.value)}
        </Link>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: EditReservation,
      type: 'custom'
    },
    {
      field: "vehicleReservationID",
      headerName: "ID Noleggio",
      type: "number"
    },
    {
      field: "reservationStatus", 
      headerName: i18next.t("form:_km_detection_status"),
    },
    {
      field: "firstDriverIDView",
      headerName: i18next.t("form:firstDriver"),
    },
    {
      field: "secondDriverIDView",
      headerName: i18next.t("form:secondDriver"),
    },
    {
      field: "vehicleLicensePlate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "reservationDTFromView",
      headerName: i18next.t("form:checkOutPrevCheckOut"),
      renderCell: renderDateCheckIn,
      fieldOriginal: "revervationDTFromOriginal",
    },
    {
      field: "reservationDTToView",
      headerName: i18next.t("form:checkInPrevCheckIn"),
      renderCell: renderDateCheckOut,
      fieldOriginal: "revervationDTToOriginal",
    },
    {
      field: "motivation",
      headerName: i18next.t("form:motivation"),
    },
  ];

  useEffect(() => {
    loadVehicles();
    loadingDrivers();
    loadReservations();
  }, []);

  useEffect(() => {
    /* ROWS */
    let reservationListFiltered = [...reservationList];
    if (movementsFilter.onlyCheckOut) {
      reservationListFiltered = reservationListFiltered.filter(
        (x: IVehicleReservation) => x.checkOutIncomingDT && x.checkOutOutgoingDT
      );
    }
    if (movementsFilter.onlyCheckIn) {
      reservationListFiltered = reservationListFiltered.filter(
        (x: IVehicleReservation) => x.checkInIncomingDT && x.checkInOutgoingDT
      );
    }

    const rowsDef = [];
    for (let i = 0; i < reservationListFiltered.length; i++) {
      let firstTitleDriver = reservationListFiltered[i].firstDriverName;
      if (reservationListFiltered[i].firstDriverSurname) {
        firstTitleDriver += " " + reservationListFiltered[i].firstDriverSurname;
      }

      let secondTitleDriver = reservationListFiltered[i].secondDriverName;
      if (reservationListFiltered[i].secondDriverSurname) {
        secondTitleDriver += " " + reservationListFiltered[i].secondDriverSurname;
      }

      let start_date_parsed = reservationListFiltered[i].reservationDTFrom;
      let end_time_parsed = reservationListFiltered[i].reservationDTTo;
      let reservationStatus = i18next.t("navigation:vehicleReserved");
      if (
        reservationListFiltered[i].checkOutIncomingDT &&
        reservationListFiltered[i].checkOutOutgoingDT
      ) {
        start_date_parsed = reservationListFiltered[i].checkOutOutgoingDT;
        end_time_parsed = reservationListFiltered[i].checkOutIncomingDT;
        reservationStatus = i18next.t("navigation:vehicleNotAvailable");
        if (
          reservationListFiltered[i].checkInIncomingDT &&
          reservationListFiltered[i].checkInOutgoingDT
        ) {
          start_date_parsed = reservationListFiltered[i].checkInOutgoingDT;
          end_time_parsed = reservationListFiltered[i].checkInIncomingDT;
          reservationStatus = i18next.t("navigation:vehicleReservationEnd");
        }
      }

      if(reservationListFiltered[i].isReservationDefinitive){
        reservationStatus = i18next.t("navigation:vehicleReservationEnd");
      }

      rowsDef.push({
        edit: "",
        vehicleReservationID: reservationListFiltered[i].vehicleReservationID,
        outgoingVehicleID: reservationListFiltered[i].outgoingVehicleID,
        incomingVehicleID: reservationListFiltered[i].incomingVehicleID,
        driverID: reservationListFiltered[i].firstDriverID,
        firstDriverID: reservationListFiltered[i].firstDriverID,
        firstDriverIDView: firstTitleDriver,
        secondDriverIDView: secondTitleDriver,
        secondDriverID: reservationListFiltered[i].secondDriverID,
        vehicleID: reservationListFiltered[i].vehicleID,
        vehicleLicensePlate: vehicles?.find(
          (v: IVehicleDataGet) =>
            v.vehicleID === reservationListFiltered[i].vehicleID
        )?.vehicleLicensePlate,
        reservationDTFromView: displayUTC0_ISODate(start_date_parsed),
        revervationDTFromOriginal: start_date_parsed,
        reservationDTToView: displayUTC0_ISODate(end_time_parsed),
        revervationDTToOriginal: end_time_parsed,
        reservationDTFrom: reservationListFiltered[i].reservationDTFrom,
        reservationDTTo: reservationListFiltered[i].reservationDTTo,
        checkOutOutgoingDT: reservationListFiltered[i].checkOutOutgoingDT,
        checkOutIncomingDT: reservationListFiltered[i].checkOutIncomingDT,
        checkInIncomingDT: reservationListFiltered[i].checkInIncomingDT,
        checkInOutgoingDT: reservationListFiltered[i].checkInOutgoingDT,
        motivation: reservationListFiltered[i].motivation,
        isReservationDefinitive: reservationListFiltered[i].isReservationDefinitive,
        reservationStatus: reservationStatus,
      });
    }
    setRowsDef(rowsDef);
  }, [movementsFilter, reservationList]);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_add_services_fleet_movements")}
      />
      <div className="dashboardForm__box">
        <div className="fleet-movements-filters-wrapper">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  name="onlyCheckOut"
                  onChange={(e: any) => {
                    setMovementsFilter({
                      ...movementsFilter,
                      ...{ onlyCheckOut: e.target.checked },
                    });
                  }}
                />
              }
              label={i18next.t("navigation:onlyCheckOut")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  name="onlyCheckIn"
                  onChange={(e: any) => {
                    setMovementsFilter({
                      ...movementsFilter,
                      ...{ onlyCheckIn: e.target.checked },
                    });
                  }}
                />
              }
              label={i18next.t("navigation:onlyCheckIn")}
            />
          </div>
          <div className="display-flex-end margin-bottom-small">
            <ButtonGroup variant="outlined">
              <Button
                onClick={() => {
                  SummonModal("new-reservation-vehicle-modal");
                }}
              >
                {i18next.t("navigation:_services_fleet_add_booking")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <Box className="dashboardForm__data">
          <DataGridWrap tableName="" headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          modalUniqueId={"new-reservation-vehicle-modal"}
          title={""}
          modalInnerComponent={
            <FleetReservationForm vehicles={vehicles} />
          }
        />
      </div>
      <LoaderBackdrop
        loading={loadingDriverList || loadingReservation || loading}
      />
    </div>
  );
};

export default Fleet__AdditionalServicesPage__Movements;
