import { useEffect, useState } from "react";
import i18next from "i18next";
import { Button, Tab, Tabs } from "@mui/material";
import "./StepAssignedDrivers.scss";
import CustomSideList from "../CustomSideList/CustomSideList";
import UploadForm from "../UploadForm/UploadForm";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import FormAssignedDriver from "./FormAssignedDriver";
import { IDriverGet } from "../../Models/IDriver";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { ICompanyGet } from "../../Models/ICompany";
import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import SaveIcon from "@mui/icons-material/Save";
import { SkipButtonCustomStepper } from "../CustomStepper/CustomStepper";
import { SummonErrorExcelDialog } from "../ExcelErrorDialog/ExcelErrorDialog";

export interface IStepAssignedDrivers {
  nextPage?: number;
  stepId?: number;
}

const StepAssignedDrivers = (props: IStepAssignedDrivers) => {
  /* REDUCER OF LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>(null);
  const [templateFile, setTemplateFile] = useState<File[]>([]);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("tab-manual");

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue);
  };

  const loadDriverList = (doReload?: boolean) => {
    if (loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverList(response.payload);
            updateAccountRegistrationStep(loggedUser, props.stepId, true);
            if (doReload) {
              setState(null);
              doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDriverList(false);
        }
      );
    }
  };

  const insertDriverFromExcel = (files: File[]) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.DriverController.DriverInsertFromExcel(
        files,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload.length === 0) {
              ToastMessage(
                i18next.t("message:driverFromExcelUploadCorrectly"),
                "success"
              );
              loadDriverList();
            } else {
              SummonErrorExcelDialog(response.payload);
            }
          } else {
            if (response.payload.status === 400) {
              ToastMessage(response.payload.title, "error");
            } else if (response.payload.status === 500) {
              ToastMessage(response.payload.detail, "error");
            } else {
              ToastMessage(i18next.t("error:" + response.error), "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateCurrent = (item: any) => {
    let parsedItem = { ...item };
    parsedItem = {
      ...item["driverInfo"],
      ...{ driverCostCenter: item["driverCostCenter"] },
      ...{ deadlineDriver: item["deadlineDriver"] },
      ...{
        licenceTypeList: item["driverInfo"]["licenceTypeList"].map(
          (x: any) => x.licenceTypeID
        ),
      },
    };
    setState(parsedItem);
    doRefreshForm();
  };

  const doRefreshForm = () => {
    setRefreshForm(true);
    setLoading(true);
    setTimeout(() => {
      setRefreshForm(false);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    loadDriverList();
  }, []);

  const newAssignedDriver = () => {
    setState(null);
    doRefreshForm();
  };

  const updateFile = (files: File[]) => {
    setTemplateFile(files);
  };

  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleAssignedDrivers") ?? "",
          }}
        />
        <h2
          className="custom-stepper-text"
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:subtitleAssignedDrivers") ?? "",
          }}
        />
        {props.nextPage && (
          <SkipButtonCustomStepper nextPage={props.nextPage} />
        )}
        <div>
          <Tabs
            value={currentTab}
            aria-label="basic tabs example"
            className="tabs-registration-wrapper"
          >
            <Tab
              value="tab-manual"
              onClick={() => handleChange("tab-manual")}
              label={i18next.t("generic:uploadManual")}
              id="tab-manual"
              aria-controls="tab-manual"
            />
            <Tab
              value="tab-template"
              onClick={() => handleChange("tab-template")}
              label={i18next.t("generic:uploadFromTemplate")}
              id="tab-template"
              aria-controls="tab-template"
            />
          </Tabs>
          <div
            role="tabpanel"
            hidden={currentTab !== "tab-template"}
            id="tab-template"
            aria-labelledby="tab-template"
          >
            <ol>
              <li>{i18next.t("generic:guidelineUploadTemplate1")}</li>
              <div style={{display: "flex", flexDirection: "row", gap: "2em"}}>
                <div className="wrapper-download-template">
                  <div className="padding-small">DriverTemplate_Italiano.xlsx</div>
                  <div>
                    <a
                      title="Download DriverTemplate_Italiano.xlsx"
                      href={
                        process.env.PUBLIC_URL + "templates/DriverTemplate_Italiano.xlsx"
                      }
                    >
                      <Button>
                        <DownloadIcon />
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="wrapper-download-template">
                  <div className="padding-small">DriverTemplate_English.xlsx</div>
                  <div>
                    <a
                      title="Download DriverTemplate_English.xlsx"
                      href={
                        process.env.PUBLIC_URL + "templates/DriverTemplate_English.xlsx"
                      }
                    >
                      <Button>
                        <DownloadIcon />
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              
              <li>{i18next.t("generic:guidelineUploadTemplate2")}</li>
            </ol>
            <MultiForm
              formId={"StepAssignedDriversMultipleUpload"}
              suppressLayout
              formTitle={""}
              inputs={[
                {
                  label: i18next.t("form:titleTemplateUpload") as string,
                  width: 100,
                  type: "custom",
                  name: "templateXSL",
                  required: true,
                  element: (
                    <UploadForm
                      title={i18next.t("form:titleTemplateUpload")}
                      description={i18next.t("form:subtitleTemplateUpload")}
                      accept={".xlsx"}
                      onFileChanged={updateFile}
                    ></UploadForm>
                  ),
                },
              ]}
              suppressSubmit={true}
              onSubmit={() => {
                insertDriverFromExcel(templateFile);
              }}
            />
            <div style={{ margin: "20px 7.5px" }}>
              <div
                className="multi-form-input-wrap"
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  variant="contained"
                  disabled={templateFile.length <= 0}
                  onClick={() => {
                    triggerFormValidation("StepAssignedDriversMultipleUpload");
                  }}
                  endIcon={<SaveIcon />}
                >
                  {i18next.t("form:uploadSubmit")}
                </Button>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            hidden={currentTab !== "tab-manual"}
            id="tab-manual"
            aria-labelledby="tab-manual"
          >
            {!refreshForm && currentTab === "tab-manual" && (
              <div>
                <AddNewBanner
                  label={
                    i18next.t("navigation:addNewAssignedDrivers") as string
                  }
                  new={() => newAssignedDriver()}
                />
                <div className="container-content-form">
                  <div className="container-stepper-form add-border multi-form-background">
                    <FormAssignedDriver
                      loggedUser={loggedUser}
                      companyUser={companyUser}
                      data={state}
                      doRefreshForm={() => doRefreshForm()}
                      nextPage={props.nextPage}
                      onLoadingDriverList={(doReload: boolean) =>
                        loadDriverList(doReload)
                      }
                    />
                  </div>
                  {!loadingDriverList && (
                    <CustomSideList
                      title={i18next.t("generic:titleSideListAssigneDrivers")}
                      updateCurrent={updateCurrent}
                      sideContent={"#form-StepAssignedDriver"}
                      selected={{
                        value: state?.driverID,
                        key: "driverInfo.driverID",
                      }}
                      list={driverList}
                      elementTitle={(item: IDriverGet) => {
                        let titleDriver = item.driverInfo.driverName;
                        if (item.driverInfo?.driverSurname) {
                          titleDriver += " " + item.driverInfo.driverSurname;
                        }
                        return titleDriver;
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoaderBackdrop
        loading={loading || processing || refreshForm || loadingDriverList}
      />
    </>
  );
};

export default StepAssignedDrivers;
