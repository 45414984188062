import { IRoadTaxPaymentsRequestInsert } from "../../Models/IRoadTaxPaymentsRequestInsert";

export const SET_DEBIT_COST = "SET_DEBIT_COST";
export const SET_CREDIT_COST = "SET_CREDIT_COST";
export const SET_ROAD_TAX_LIST = "SET_ROAD_TAX_LIST";

interface SetDebitCostActionType {
  type: typeof SET_DEBIT_COST;
  payload: number;
}

interface SetCreditCostActionType {
  type: typeof SET_CREDIT_COST;
  payload: number;
}

interface SetRoadTaxListActionType {
  type: typeof SET_ROAD_TAX_LIST;
  payload: IRoadTaxPaymentsRequestInsert[];
}

export type RoadTaxActionType =
  | SetDebitCostActionType
  | SetCreditCostActionType
  | SetRoadTaxListActionType;


const setDebitCost = (
  debitCost: number
): SetDebitCostActionType => {
  return { type: SET_DEBIT_COST, payload: debitCost };
};

const setCreditCost = (
  creditCost: number
): SetCreditCostActionType => {
  return { type: SET_CREDIT_COST, payload: creditCost };
};

const setRoadTaxList = (
  roadTaxList: IRoadTaxPaymentsRequestInsert[]
): SetRoadTaxListActionType => {
  return { type: SET_ROAD_TAX_LIST, payload: roadTaxList };
};

export const RoadTaxActions = {
  setDebitCost,
  setCreditCost,
  setRoadTaxList
};
