/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./QuotesPageDelivered.scss";

/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import AccordionContent from "../../../../../Components/AccordionContent/AccordionContent";
/* MUI */
import { Box, CircularProgress, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IQuotation } from "../../../../../Models/IQuotation";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { IVehicleDataGet } from "../../../../../Models/IVehicles";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";
import { renderInterventionTypeString } from "../QuotesPageRequests/QuotesPageRequests";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { downloadWithName, getMimeTypeByBase64 } from "../../../../../Utils/FileToBase";
import { useSearchParams } from "react-router-dom";
import { IUser } from "../../../../../Models/IUser";

const SolutionTableDisplay = (props: {
  quote: IQuotation;
  downloadFile: (quote: IQuotation) => void;
}) => {
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [quote, setQuote] = useState<IQuotation>(props.quote);
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }
  const [vehicle, setVehicle] = useState<IVehicleDataGet>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const preopenId = +(searchParams.get("preopenId") ?? "0");

  const emitDownloadFile = () => {
    if (quote.attachmentFile) {
      props.downloadFile(quote);
    }
  };

  /* STATES */
  /* FUNCTIONS */
  // Render download
  const renderDownload = (props: GridRenderCellParams) => {
    return (
      <div>
        {quote.attachmentFile ?
          <IconButton onClick={emitDownloadFile}>
            <FileDownloadIcon className="icon-table" />
          </IconButton>
        :
          <></>
        }
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "download",
      headerName: i18next.t("form:attachments"),
      renderCell: renderDownload,
    },
    {
      field: "areaOfIntervention",
      headerName: i18next.t("navigation:quotations_area"),
    },
    {
      field: "requestCreated",
      headerName: i18next.t("form:_mctc_dataRichiestaEvento"),
    },
    {
      field: "solutionCreated",
      headerName: i18next.t("form:creationData"),
    },
  ];

  const loadVehicle = () => {
    setLoading(true);

    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      props.quote?.vehicleId,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            setVehicle(response.payload[0]);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (props.quote) {
      loadVehicle();
    }
  }, [props.quote]);

  return (
    <div>
      {!loading && (
        <AccordionContent
          startsOpen={preopenId === quote.quotationId}
          classNameAccordion="margin-vertical-small accordion-noBorderRadius"
          classNameSummary="accordion-background padding-horizontal-medium"
          title={
            <div>
              <strong>{i18next.t("form:requestNumber")}:</strong>{" "}
              {props.quote?.quotationId}
            </div>
          }
          middleSummary={
            <div className="display-flex gap-extra-large">
              <div>
                <strong>{i18next.t("form:vehicle")}:</strong>{" "}
                {formatPlate(vehicle?.vehicleLicensePlate)}
              </div>
              <div>
                <strong>{i18next.t("form:operation")}:</strong>{" "}
                {renderInterventionTypeString(props.quote?.interventionType)}
              </div>
            </div>
          }
          content={
            <div className={"margin-vertical-small"}>
              {props.quote?.note && (
                <div style={{width: "100%", marginBottom: "0.5em"}}>
                  <strong>{i18next.t("form:note")}:</strong> {props.quote?.note}
                </div>
              )}
              <div style={{width: "100%"}}>
                <strong>{i18next.t("navigation:quoteDetail")}:</strong>{" "}
              </div>
              <DataGridWrap
                headers={columnsDef}
                rows={[
                  {
                    ...quote,
                    solutionCreated: displayUTC0_ISODate((quote as any)?.createdDT),
                    requestCreated: displayUTC0_ISODate(
                      (props.quote as any)?.createdDT
                    ),
                  },
                ]}
                hideToolbar={true}
              />
            </div>
          }
        />
      )}
      {loading && (
        <div className="loading-placeholder">
          <CircularProgress size={"1em"} />
        </div>
      )}
    </div>
  );
};

/* COMPONENT */
const QuotesPageDelivered = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<IQuotation[]>([]);
  const [openSolution, setOpenSolution] = useState<IQuotation | null>(null);

  const loadQuotesDelivered = () => {
    setQuotes([]);
    setLoading(true);
    ApiService.QuotationController.QuotationsGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setQuotes(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadQuotesDelivered();
  }, []);

  const downloadFile = (quote: IQuotation) => {
    if(quote.attachmentFile){
      downloadWithName(quote.attachmentFile, i18next.t("form:attachment") + "_" + quote.quotationId + getMimeTypeByBase64(quote.attachmentFile));
    }
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:quotesReceived")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          {quotes.reverse().map((x: IQuotation) => {
            return (
              <SolutionTableDisplay
                downloadFile={downloadFile}
                quote={x}
              />
            );
          })}
        </Box>
      </div>
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default QuotesPageDelivered;
