import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IVehicleDataGet } from "../../../../../Models/IVehicles";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ICompanyGet } from "../../../../../Models/ICompany";
import i18next from "i18next";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, ButtonGroup, IconButton, Popover, Tooltip } from "@mui/material";
import SmartModal, {
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import { IUser } from "../../../../../Models/IUser";
import FleetReservationForm from "../../../../../Components/FleetReservationForm/FleetReservationForm";
import moment from "moment";
import Timeline, { CustomMarker } from "react-calendar-timeline";

import { formatPlate } from "../../VehiclesPage/VehiclesPage";
import "./Timeline.scss";
import "./Fleet__AdditionalServicesPage.scss";
import { JSONPrint, decodeDateLocale } from "../../../../../Utils/Decoder";
import { IVehicleReservation, IVehicleReservationAvailableVehicle, IVehicleReservationUpdate } from "../../../../../Models/IVehicleReservation";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { useNavigate } from "react-router-dom";
import BetterSelect from "../../../../../Components/MultiForm/SpecialInputs/BetterSelect";
import { IVehicleReservationOutgoingUpdate } from "../../../../../Models/IVehicleReservationOutgoing";
import {
  dateToUTC0_ISO, displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IVehicleReservationIncomingUpdate } from "../../../../../Models/IVehicleReservationIncoming";
import { VehiclesActions } from "../../../../../Reducers/Vehicle/VehicleAction";

export interface IDataHover {
  plate: string;
  firstDriver: string;
  secondDriver?: string;
  dateFrom: string;
  dateTo: string;
}

const Fleet__AdditionalServicesPage = () => {
  const navigate = useNavigate();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const [preSelectedData, setPreSelectedData] = useState<any>({});
  const [dataHoverTimeline, setDataHoverTimeline] = useState<IDataHover>();

  const [loadingReservation, setLoadingReservation] = useState<boolean>(false);
  const [reservationList, setReservationList] = useState<IVehicleReservation[]>(
    []
  );
  const [reservationGroups, setReservationGroups] = useState<any>([]);
  const [reservationGroupsOptions, setReservationGroupsOptions] = useState<any>(
    []
  );
  const [reservationListFilter, setReservationListFilter] = useState<any>([]);
  const [reservationGroupsFilter, setReservationGroupsFilter] = useState<any>(
    []
  );
  const [filterVehicleID, setFilterVehicleID] = useState<number>(-1);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleClick = (element: any) => {
    setAnchorEl(element.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loadVehicles = () => {
    if (loggedCompany) {
      setLoading(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadReservations = () => {
    if (loggedCompany) {
      setLoadingReservation(true);
      ApiService.VehicleReservationController.VehicleReservationGet(
        loggedCompany.companyID,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let tmp = [];
            for (let i = 0; i < response.payload.length; i++) {
              if(tmp.filter((x: any) => x.vehicleReservationID === response.payload[i].vehicleReservationID).length === 0){
                tmp.push(response.payload[i]);
              }
            }

            setReservationList(tmp);
            let groups = [];
            for (let g = 0; g < response.payload.length; g++) {
              
              if (
                groups.filter(
                  (e) => e.vehicleID === response.payload[g].vehicleID
                ).length <= 0
              ) {
                let lastReservation: IVehicleReservation[] = response.payload.filter((x: IVehicleReservation) => x.vehicleID === response.payload[g].vehicleID).slice(-1);
                
                groups.push({
                  vehicleID: response.payload[g].vehicleID,
                  vehicleLicensePlate: response.payload[g].vehicleLicensePlate,
                  vehicleFuelPercentage: lastReservation && lastReservation[0]?.checkInFuelPercentage ? lastReservation[0]?.checkInFuelPercentage : lastReservation[0]?.checkOutFuelPercentage,
                });
              }
            }

            setReservationGroups(groups);
            if (filterVehicleID === -1) {
              let optionGroups = [{ ...{ vehicleID: "-1" } }, ...groups];
              setReservationGroupsOptions(optionGroups);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingReservation(false);
        }
      );
    }
  };

  const updateReservationList = (data: IVehicleReservation) => {
    let reservationList_ = [...reservationList];
    reservationList_.push(data);
    setReservationList(reservationList_);
  };

  const updateOutgoing = (data: IVehicleReservationOutgoingUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleReservationOutgoingController.VehicleReservationOutgoingUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationOutgoingSuccessfullUpdated"),
              "success"
            );
          } else {
            if (response.payload.status === 400) {
              ToastMessage(
                i18next.t("error:" + response.payload.detail),
                "error"
              );
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateIncoming = (data: IVehicleReservationIncomingUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleReservationIncomingController.VehicleReservationIncomingUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationIncomingSuccessfullUpdated"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateReservation = (data: IVehicleReservationUpdate) => {
    if (loggedCompany) {
      setProcessing(true);
      ApiService.VehicleReservationController.VehicleReservationUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:vehicleReservationSuccessfullUpdated"), "success");
          } else {
            if(response.payload.status === 400){
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            }else{
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
     );
    }
  }

  useEffect(() => {
    moment.locale(decodeDateLocale());
    moment.locale();
    loadVehicles();
    loadReservations();
  }, []);

  useEffect(() => {
    if (filterVehicleID) {
      let decodeFilterVehicleID = +filterVehicleID;
      if (decodeFilterVehicleID <= -1) {
        setReservationListFilter(reservationList);
        setReservationGroupsFilter(reservationGroups);
      } else {
        setReservationListFilter(
          reservationList.filter(
            (x: IVehicleReservation) => x.vehicleID === decodeFilterVehicleID
          )
        );
        setReservationGroupsFilter(
          reservationGroups.filter(
            (x: IVehicleReservation) => x.vehicleID === decodeFilterVehicleID
          )
        );
      }
    }
  }, [filterVehicleID, reservationList]);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_add_services_fleet")}
      />
      <div className="dashboardForm__box">
        <div className="fleet-service-filters-wrapper">
          <div className="select-filter-wrapper">
            <div className="multi-form-input-upper-label">
              {i18next.t("navigation:filterByVehicle")}
            </div>
            <BetterSelect
              width={100}
              name={"vehicleID"}
              defaultValue={filterVehicleID}
              onChange={(value: any) => {
                setFilterVehicleID(value);
              }}
              sortByOptions={1}
              options={(reservationGroupsOptions ?? []).map(
                (x: IVehicleReservation) => {
                  let vehicleBrandDescription = vehicles?.find(
                    (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                  )?.brandDescription;
                  let vehicleModelDescription = vehicles?.find(
                    (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                  )?.modelDescription;
                  let vehicleLicensePlate =
                    vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.vehicleLicensePlate ?? "";
                  return {
                    key: x.vehicleID.toString(),
                    text:
                      x.vehicleID.toString() === "-1"
                        ? i18next.t("navigation:all")
                        : formatPlate(vehicleLicensePlate) +
                          " - " +
                          vehicleBrandDescription +
                          " " +
                          vehicleModelDescription
                        
                  };
                }
              )}
              type={"number"}
            />
          </div>
          <div className="button-new-reservation margin-bottom-small">
            <Tooltip title={reservationGroups.length <= 0 ? i18next.t("navigation:_services_fleet_add_booking_info") : ""}>
              <ButtonGroup variant="outlined">
                <Button
                  disabled={loadingReservation || reservationGroups.length === 0}
                  onClick={() => {
                    setPreSelectedData({});
                    SummonModal("new-booking-vehicle-modal");
                  }}
                >
                  {i18next.t("navigation:_services_fleet_add_booking")}
                </Button>
              </ButtonGroup>
            </Tooltip>
          </div>
        </div>
        <div className="fleet-additional-services-wrapper">
          <div className="fleet-additional-service-gantt-wrapper">
            {reservationListFilter && reservationListFilter.length > 0 && (
              <Timeline
                className={"custom-timeline-calendar"}
                keys={{
                  groupIdKey: "id",
                  groupTitleKey: "title",
                  groupRightTitleKey: "rightTitle",
                  itemIdKey: "id",
                  itemTitleKey: "title", // key for item div content
                  itemDivTitleKey: "tooltip", // key for item div title (<div title="text"/>)
                  itemGroupKey: "group",
                  itemTimeStartKey: "start_time",
                  itemTimeEndKey: "end_time",
                }}
                groups={reservationGroupsFilter.map(
                  (x: IVehicleReservation) => {
                    let vehicleBrandDescription = vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.brandDescription;
                    let vehicleModelDescription = vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.modelDescription;
                    return {
                      id: x.vehicleID,
                      height: 60,
                      title: (
                        <div>
                          <div className="image-wrapper">
                            <img
                              className="image-responsive"
                              onError={(e: any) =>
                                (e.target.src =
                                  process.env.PUBLIC_URL +
                                  "/Images/brandVehicle/brand-not-found.svg")
                              }
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/brandVehicle/" +
                                vehicleBrandDescription?.replace(" ", "_") +
                                ".png"
                              }
                              title={vehicleBrandDescription}
                              alt={vehicleBrandDescription}
                            />
                          </div>
                          <div
                            className="description-wrapper"
                            title={
                              formatPlate(x.vehicleLicensePlate) +
                              " - " +
                              vehicleBrandDescription +
                              " " +
                              vehicleModelDescription
                            }
                          >
                            {vehicleBrandDescription} {vehicleModelDescription}
                            <br />
                            <small>{formatPlate(x.vehicleLicensePlate)}</small>
                            <br/>
                            {x.vehicleFuelPercentage && (
                              <small>{i18next.t("form:fuelPercentage")}: {x.vehicleFuelPercentage}%</small>
                            )}
                          </div>
                        </div>
                      ),
                    };
                  }
                )}
                items={reservationListFilter.map(
                  (x: IVehicleReservation, i: number) => {
                    let vehicleBrandDescription = vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.brandDescription;
                    let vehicleModelDescription = vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.modelDescription;

                    let firstTitleDriver = x.firstDriverName;
                    if (x.firstDriverSurname) {
                      firstTitleDriver += " " + x.firstDriverSurname;
                    }

                    let secondTitleDriver = x.secondDriverName;
                    if (x.secondDriverSurname) {
                      secondTitleDriver += " " + x.secondDriverSurname;
                    }

                    let title = i18next.t("navigation:vehicleReserved");
                    let canEditReservation = true;
                    let backgroundHex = "#feaf43";
                    let start_date_parsed = x.reservationDTFrom;
                    let end_time_parsed = x.reservationDTTo;
                    let custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__OUTGOING_VEHICLE + "?vehicleReservationID=" + x.vehicleReservationID;
                    if (x.isReservationDefinitive) {
                      canEditReservation = false;
                      title = i18next.t("navigation:vehicleReservationEnd");
                      backgroundHex = "#bebebe";
                    }
                    if (x.checkOutIncomingDT && x.checkOutOutgoingDT) {
                      title = i18next.t("navigation:vehicleNotAvailable");
                      backgroundHex = "#ff6584";
                      start_date_parsed = x.checkOutOutgoingDT;
                      end_time_parsed = x.checkOutIncomingDT;
                      custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE + "?outgoingVehicleID=" + x.outgoingVehicleID;
                      if (x.checkInIncomingDT && x.checkInOutgoingDT) {
                        title = i18next.t("navigation:vehicleReservationEnd");
                        start_date_parsed = x.checkInOutgoingDT;
                        end_time_parsed = x.checkInIncomingDT;
                        backgroundHex = "#bebebe";
                        custom_route = AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET__INCOMING_VEHICLE + "?incomingVehicleID=" + x.incomingVehicleID;
                      }
                    }

                    return {
                      id: x.vehicleReservationID,
                      group: x.vehicleID,
                      title: title,
                      tooltip:
                        formatPlate(x.vehicleLicensePlate) +
                        " - " +
                        vehicleBrandDescription +
                        " " +
                        vehicleModelDescription +
                        " (" +
                        title +
                        ")",
                      start_time: moment(start_date_parsed),
                      end_time: moment(end_time_parsed),
                      canChangeGroup: false,
                      canMove: canEditReservation,
                      canResize: canEditReservation,
                      dimensions: {
                        heigth: "60px",
                      },
                      selected: canEditReservation,
                      itemProps: {
                        className: canEditReservation ? "" : "custom_not_edit",
                        style: {
                          lineHeigth: "40px",
                          background: backgroundHex,
                          borderColor: "#000",
                          cursor: !canEditReservation && "disabled",
                        },
                        onContextMenu: (e: any) => {
                          let dataHover = {
                            plate: formatPlate(x.vehicleLicensePlate),
                            firstDriver: firstTitleDriver ?? "",
                            secondDriver: secondTitleDriver ?? "",
                            dateFrom: start_date_parsed,
                            dateTo: end_time_parsed,
                          };
                          handleClick(e);
                          setDataHoverTimeline(dataHover);
                        },
                        onDoubleClick: (e: any) => {
                          e.preventDefault();
                          if (
                            x.vehicleReservationID &&
                            custom_route &&
                            custom_route.length > 0 && canEditReservation
                          ) {
                            return navigate(
                              AppRoutes.INTERNAL_DASHBOARD +
                                custom_route
                            );
                          }
                        },
                      },
                      canSelect: canEditReservation,
                    };
                  }
                )}
                defaultTimeStart={moment().startOf("month")}
                defaultTimeEnd={moment().endOf("month")}
                timeSteps={{
                  second: 0,
                  minute: 30,
                  hour: 1,
                  day: 1,
                  month: 1,
                  year: 1,
                }}
                useResizeHandle={true}
                sidebarWidth={200}
                onCanvasClick={(groupId: number, time: number) => {
                  let data = {
                    vehicleID: groupId,
                    reservationDTFrom: moment(time).toISOString(),
                  };
                  setPreSelectedData(data);
                  SummonModal("new-booking-vehicle-modal");
                }}
                onItemMove={(itemId: number, dragTime: number) => {
                  setProcessing(true);
                  let currentItem = reservationListFilter.find(
                    (item: IVehicleReservation) =>
                      item.vehicleReservationID === itemId
                  );
                  let currentItemOriginal = {...currentItem}; 
                  let startDate: string = "";
                  let endDate: string = "";
                  let updateData: any = {
                    vehicleReservationID: currentItem.vehicleReservationID,
                    firstDriverID: currentItem.firstDriverID,
                    secondDriverID: currentItem.secondDriverID,
                    vehicleID: currentItem.vehicleID,
                  };
                  
                  // reservation update
                  if(currentItem.reservationDTTo && !currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT){
                    if(dragTime){
                      let dateDiff: number = 0;
                      updateData["vehicleReservationID"] = currentItem.vehicleReservationID;
                      if(new Date(dragTime) > new Date(currentItem.reservationDTFrom)){
                        dateDiff = moment(dragTime).diff(moment(currentItem.reservationDTFrom));
                        updateData["reservationDTFrom"] = moment(currentItem.reservationDTFrom).add(dateDiff).toISOString();
                        updateData["reservationDTTo"] = moment(currentItem.reservationDTTo).add(dateDiff).toISOString();
                      }
                      if(new Date(dragTime) < new Date(currentItem.reservationDTFrom)){
                        dateDiff = moment(currentItem.reservationDTFrom).diff(moment(dragTime));
                        updateData["reservationDTFrom"] = moment(currentItem.reservationDTFrom).subtract(dateDiff).toISOString();
                        updateData["reservationDTTo"] = moment(currentItem.reservationDTTo).subtract(dateDiff).toISOString();
                      }
                      
                      currentItem["reservationDTFrom"] = updateData["reservationDTFrom"];
                      currentItem["reservationDTTo"] = updateData["reservationDTTo"];
                      startDate = updateData["reservationDTFrom"];
                      endDate = updateData["reservationDTTo"];
                    }
                  }

                  // outgoing update
                  if (
                    currentItem.reservationDTTo &&
                    currentItem.checkOutIncomingDT &&
                    !currentItem.checkInIncomingDT
                  ) {
                    if (dragTime) {
                      let dateDiff: number = 0;
                      updateData["outgoingVehicleID"] =
                        currentItem.outgoingVehicleID;
                      if (
                        new Date(dragTime) >
                        new Date(currentItem.checkOutOutgoingDT)
                      ) {
                        dateDiff = moment(dragTime).diff(
                          moment(currentItem.checkOutOutgoingDT)
                        );
                        updateData["outgoingDT"] = moment(
                          currentItem.checkOutOutgoingDT
                        )
                          .add(dateDiff)
                          .toISOString();
                        updateData["incomingDT"] = moment(
                          currentItem.checkOutIncomingDT
                        )
                          .add(dateDiff)
                          .toISOString();
                      }
                      if (
                        new Date(dragTime) <
                        new Date(currentItem.checkOutOutgoingDT)
                      ) {
                        dateDiff = moment(currentItem.checkOutOutgoingDT).diff(
                          moment(dragTime)
                        );
                        updateData["outgoingDT"] = moment(
                          currentItem.checkOutOutgoingDT
                        )
                          .subtract(dateDiff)
                          .toISOString();
                        updateData["incomingDT"] = moment(
                          currentItem.checkOutIncomingDT
                        )
                          .subtract(dateDiff)
                          .toISOString();
                      }
                      currentItem["checkOutOutgoingDT"] = updateData["outgoingDT"];
                      currentItem["checkOutIncomingDT"] = updateData["incomingDT"];
                      startDate = updateData["outgoingDT"];
                      endDate = updateData["incomingDT"];
                    }
                  }

                  // incoming update
                  if (
                    currentItem.reservationDTTo &&
                    currentItem.checkOutIncomingDT &&
                    currentItem.checkInIncomingDT
                  ) {
                    if (dragTime) {
                      let dateDiff: number = 0;
                      updateData["incomingVehicleID"] =
                        currentItem.incomingVehicleID;
                      if (
                        new Date(dragTime) >
                        new Date(currentItem.checkInOutgoingDT)
                      ) {
                        dateDiff = moment(dragTime).diff(
                          moment(currentItem.checkInOutgoingDT)
                        );
                        updateData["outgoingDT"] = moment(
                          currentItem.checkInOutgoingDT
                        )
                          .add(dateDiff)
                          .toISOString();
                        updateData["incomingDT"] = moment(
                          currentItem.checkInIncomingDT
                        )
                          .add(dateDiff)
                          .toISOString();
                      }
                      if (
                        new Date(dragTime) <
                        new Date(currentItem.checkInOutgoingDT)
                      ) {
                        dateDiff = moment(currentItem.checkInOutgoingDT).diff(
                          moment(dragTime)
                        );
                        updateData["outgoingDT"] = moment(
                          currentItem.checkInOutgoingDT
                        )
                          .subtract(dateDiff)
                          .toISOString();
                        updateData["incomingDT"] = moment(
                          currentItem.checkInIncomingDT
                        )
                          .subtract(dateDiff)
                          .toISOString();
                      }
                      updateData["outgoingVehicleID"] = currentItem.outgoingVehicleID;
                      currentItem["checkInOutgoingDT"] = updateData["outgoingDT"];
                      currentItem["checkInIncomingDT"] = updateData["incomingDT"];
                      startDate = updateData["outgoingDT"];
                      endDate = updateData["incomingDT"];
                    }
                  }
                  let reservationList_: IVehicleReservation[] = [
                    ...reservationList,
                  ];
                  let indexReservation = reservationList_.indexOf(currentItem);

                  ApiService.VehicleReservationController.VehicleReservationGetAvailableVehicle(
                    startDate,
                    endDate,
                    loggedCompany?.companyID,
                    updateData["vehicleReservationID"],
                    updateData["outgoingVehicleID"],
                    updateData["incomingVehicleID"],
                    (response: IAPIResponse) => {
                      if (response.error === null) {
                        if(response.payload && response.payload.length > 0 && response.payload.filter((x: IVehicleReservationAvailableVehicle) => x.vehicleID === updateData.vehicleID).length > 0){
                          
                          if(currentItem.reservationDTTo && !currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT){
                            updateReservation(updateData);
                          }
                          if (currentItem.reservationDTTo && currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT) {
                            updateData["fuelPercentage"] = reservationList_[indexReservation].checkOutFuelPercentage;
                            updateData["incomingKM"] = reservationList_[indexReservation].checkOutIncomingKM;
                            updateOutgoing(updateData);
                          }
                          if (currentItem.reservationDTTo && currentItem.checkOutIncomingDT && currentItem.checkInIncomingDT) {
                            updateData["fuelPercentage"] = reservationList_[indexReservation].checkInFuelPercentage;
                            updateData["incomingKM"] = reservationList_[indexReservation].checkInIncomingKM;
                            updateIncoming(updateData);
                          }
                          reservationList_[indexReservation] = currentItem;
                        }else{
                          reservationList_[indexReservation] = currentItemOriginal;
                          ToastMessage(i18next.t("error:RESERVATION_ALREADY_EXITS")
                          .replace("START_DATE", displayUTC0_ISODate(startDate))
                          .replace("END_DATE", displayUTC0_ISODate(endDate)),  "error");
                        }
                      } else{
                        ToastMessage(response.error, "error");
                      }
                      setReservationList(reservationList_);
                      setProcessing(false);
                    }
                  );
                }}
                onItemResize={(
                  itemId: number,
                  endTimeOrStartTime: number,
                  edge: "left" | "right"
                ) => {
                  setProcessing(true);
                  let currentItem: IVehicleReservation =
                    reservationListFilter.find(
                      (item: IVehicleReservation) =>
                        item.vehicleReservationID === itemId
                    );
                  let currentItemOriginal = {...currentItem}; 
                  let startDate: string = "";
                  let endDate: string = "";
                  let updateData: any = {
                    vehicleReservationID: currentItem.vehicleReservationID,
                    firstDriverID: currentItem.firstDriverID,
                    secondDriverID: currentItem.secondDriverID,
                    vehicleID: currentItem.vehicleID,
                  };
                  
                  // reservation update
                  if(currentItem.reservationDTTo && !currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT){
                    currentItem["reservationDTTo"] = moment(endTimeOrStartTime).toISOString();
                    updateData["reservationDTFrom"] = currentItem.reservationDTFrom;
                    updateData["reservationDTTo"] = dateToUTC0_ISO(new Date(endTimeOrStartTime));
                    startDate = updateData["reservationDTFrom"];
                    endDate = updateData["reservationDTTo"];
                  }

                  // outgoing update
                  if (
                    currentItem.reservationDTTo &&
                    currentItem.checkOutIncomingDT &&
                    !currentItem.checkInIncomingDT
                  ) {
                    currentItem["checkOutIncomingDT"] = moment(endTimeOrStartTime).toISOString();
                    updateData["outgoingVehicleID"] = currentItem.outgoingVehicleID;
                    updateData["outgoingDT"] = currentItem.checkOutOutgoingDT;
                    updateData["incomingDT"] = dateToUTC0_ISO(
                      new Date(endTimeOrStartTime)
                    );
                    startDate = updateData["outgoingDT"];
                    endDate = updateData["incomingDT"];
                  }

                  // incoming update
                  if (
                    currentItem.reservationDTTo &&
                    currentItem.checkOutIncomingDT &&
                    currentItem.checkInIncomingDT
                  ) {
                    currentItem["checkInIncomingDT"] = moment(endTimeOrStartTime).toISOString();
                    updateData["incomingVehicleID"] = currentItem.incomingVehicleID;
                    updateData["outgoingDT"] = currentItem.checkInOutgoingDT;
                    updateData["incomingDT"] = dateToUTC0_ISO(
                      new Date(endTimeOrStartTime)
                    );
                    startDate = updateData["outgoingDT"];
                    endDate = updateData["incomingDT"];
                    updateData["outgoingVehicleID"] = currentItem.outgoingVehicleID;
                  }
                  let reservationList_: IVehicleReservation[] = [
                    ...reservationList,
                  ];
                  let indexReservation = reservationList_.indexOf(currentItem);
                  ApiService.VehicleReservationController.VehicleReservationGetAvailableVehicle(
                    startDate,
                    endDate,
                    loggedCompany?.companyID,
                    updateData["vehicleReservationID"],
                    updateData["outgoingVehicleID"],
                    updateData["incomingVehicleID"],
                    (response: IAPIResponse) => {
                      if (response.error === null) {
                        if(response.payload && response.payload.length > 0 && response.payload.filter((x: IVehicleReservationAvailableVehicle) => x.vehicleID === updateData.vehicleID).length > 0){
                          if(currentItem.reservationDTTo && !currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT){
                            updateReservation(updateData);
                          }
                          if (currentItem.reservationDTTo && currentItem.checkOutIncomingDT && !currentItem.checkInIncomingDT) {
                            updateData["fuelPercentage"] = reservationList_[indexReservation].checkOutFuelPercentage;
                            updateData["incomingKM"] = reservationList_[indexReservation].checkOutIncomingKM;
                            updateOutgoing(updateData);
                          }
                          if (currentItem.reservationDTTo && currentItem.checkOutIncomingDT && currentItem.checkInIncomingDT) {
                            updateData["fuelPercentage"] = reservationList_[indexReservation].checkInFuelPercentage;
                            updateData["incomingKM"] = reservationList_[indexReservation].checkInIncomingKM;
                            updateIncoming(updateData);
                          }
                          reservationList_[indexReservation] = currentItem;
                        }else{
                          reservationList_[indexReservation] = currentItemOriginal;
                          ToastMessage(i18next.t("error:RESERVATION_ALREADY_EXITS")
                          .replace("START_DATE", displayUTC0_ISODate(startDate))
                          .replace("END_DATE", displayUTC0_ISODate(endDate)),  "error");
                        }
                      } else{
                        ToastMessage(response.error, "error");
                      }
                      
                      setReservationList(reservationList_);
                      setProcessing(false);
                    }
                  );
                }}
                itemTouchSendsClick={true}
              >
                <CustomMarker date={Date.now()}>
                  {({ styles, date }) => (
                    <div className="custom-marker-today">
                      <div style={styles}></div>
                    </div>
                  )}
                </CustomMarker>
              </Timeline>
            )}
            {dataHoverTimeline !== undefined && (
              <Popover
                id={id}
                className="tooltipHover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div className="icon-tooltip-wrapper">
                  <IconButton
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </div>
                <div className="custom-tooltip">
                  <p>
                    {i18next.t("form:plate")}: {dataHoverTimeline.plate}
                  </p>
                  <p>
                    {i18next.t("form:firstDriver")}:{" "}
                    {dataHoverTimeline.firstDriver}
                  </p>
                  {dataHoverTimeline.secondDriver && (
                    <p>
                      {i18next.t("form:secondDriver")}:{" "}
                      {dataHoverTimeline.secondDriver}
                    </p>
                  )}
                  <p>
                    {i18next.t("form:disposalDate")}:{" "}
                    {displayUTC0_ISODate(dataHoverTimeline.dateFrom)}
                    &nbsp;-&nbsp;
                    {displayUTC0_ISODate(dataHoverTimeline.dateTo)}
                  </p>
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <SmartModal
        modalUniqueId={"new-booking-vehicle-modal"}
        title={""}
        modalInnerComponent={
          <FleetReservationForm
            inModal={true}
            preSelectedData={preSelectedData}
            reservationDates={reservationList}
            vehicles={vehicles}
            loadDataBeforeDismiss={updateReservationList}
          />
        }
      />
      <LoaderBackdrop
        loading={loading || processing || loadingReservation}
      />
    </div>
  );
};

export default Fleet__AdditionalServicesPage;
