import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IVoucherInsert, IVoucherUpdate } from "../../Models/IVoucher";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VoucherController = {
  GetVoucher: async (
    voucherID: number,
    voucherName: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_VOUCHER_GET;
    let parameters: string[] = [];
    if (voucherID) {
      parameters.push(`voucherID=${voucherID}`);
    }
    if (voucherName) {
      parameters.push(`voucherName=${voucherName}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetCheckVoucher: async (
    voucherName: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_VOUCHER_CHECK_GET;
    let parameters: string[] = [];
    if (voucherName) {
      parameters.push(`voucherName=${voucherName}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertVoucher: async (
    body: IVoucherInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_VOUCHER_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateVoucher: async (
    body: IVoucherUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_VOUCHER_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteVoucher: async (
    voucherID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_VOUCHER_DELETE;
    request.method = "DELETE";
    request.payload = {
      voucherID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VoucherController;
