import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export interface ILoaderBackdrop {
  loading: boolean;
  title?: string
}

const LoaderBackdrop = (props: ILoaderBackdrop) => {
  return (
    <div>
      <Backdrop
        title={props.title}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.loading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default LoaderBackdrop;
