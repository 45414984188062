import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleRoadTaxInsert,
  IVehicleRoadTaxUpdate,
} from "../../Models/IVehicleRoadTax";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleRoadTaxController = {
  VehicleRoadTaxGet: async (
    roadTaxID: number,
    vehicleID: number,
    stored: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_GET;
    let parameters: string[] = [];
    if (roadTaxID) {
      parameters.push(`roadTaxID=${roadTaxID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (stored) {
      parameters.push(`stored=${stored}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxInsert: async (
    body: IVehicleRoadTaxInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxUpdate: async (
    body: IVehicleRoadTaxUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleRoadTaxDelete: async (
    roadTaxID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_ROAD_TAX_DELETE;
    request.method = "DELETE";
    request.payload = {
      roadTaxID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxCreditDebitGet: async (
    isDebit: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.ROAD_TAX_CREDIT_DEDIT_GET;
    request.method = "GET";
    let parameters: string[] = [];
    if (isDebit !== undefined) {
      parameters.push(`isDebit=${isDebit}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleRoadTaxController;
