/* REACT */
import { useEffect, useState } from "react";

/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./StepDashboardLoadTemplate.scss";

/* COMPONENTS */
import { useFilePicker } from "use-file-picker";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import DetailsFileSupply from "../DetailsFileSupply/DetailsFileSupply";
import SmartModal, { DismissModal } from "../SmartModal/SmartModal";
import StepDashboardLoadTemplate_Buttons from "./StepDashboardLoadTemplate_Buttons";

/* MUI */
import { Button } from "@mui/material";
// Icon
import TaskIcon from "@mui/icons-material/Task";
import UploadIcon from "@mui/icons-material/Upload";

import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { useDispatch, useSelector } from "react-redux";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { GlobalState } from "../../Reducers/RootReducer";
import { ITemplate, ITemplateInsert } from "../../Models/ITemplate";
import { ICompanyGet } from "../../Models/ICompany";

export interface IStepDashboardLoadTemplateProps {
  templateTypeID: number;
  primaryTitle: string;
  primarySubTitle: string;
}

// Validate form
export const ValidateForm = (state: any, isCreating = false) => {
  if (
    state &&
    state["separator"] &&
    state["dateFormat"] &&
    state["readFromRow"]
  ) {
    if (isCreating) {
      return state["templateName"] && state["supplierID"] && state["templateImage"];
    }

    return true;
  } else {
    return false;
  }
};

/* COMPONENT */
const StepDashboardLoadTemplate = (props: IStepDashboardLoadTemplateProps) => {
  const dispatch = useDispatch();
  const templateGenericFormData = useSelector(
    (state: GlobalState) => state.generic.templateGenericWorkingData
  );

  /* STATES */
  const [isFileUpload, setIsFileUpload] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const [knownHeaders, setKnownHeaders] = useState<string[]>([]);
  const company: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL",
      accept: [".csv", ".xls", ".xlsx", ".txt"],
      limitFilesConfig: { min: 1, max: 3 },
    });

  /* FUNCTIONS */
  const uplaodFile = (file: File) => {
    setProcessing(true);

    ApiService.TemplateController.TemplateGetFromExcel(file, (response: IAPIResponse) => {
      if (response.error === null) {
        setKnownHeaders(response.payload);
        setIsFileUpload(true);
        dispatch(
          GenericActions.setTemplateGenericWorkingData({
            ...templateGenericFormData,
            file: file,
            headers: response.payload,
            templateTypeID: props.templateTypeID,
          })
        );
      } else {
        ToastMessage(response.error, "error");
      }

      setProcessing(false);
    });
  };

  useEffect(() => {
    if (plainFiles.length > 0 && !isFileUpload) {
      uplaodFile(plainFiles[0]);
    }
  }, [plainFiles]);

  useEffect(() => {
    // reset all
    dispatch(GenericActions.setTemplateGenericWorkingData(null));
  }, []);

  const createTemplate = () => {
    if (state && company) {
      setProcessing(true);
      let template: ITemplateInsert = {
        templateName: state.templateName,
        templateTypeID: props.templateTypeID,
        companyID: company.companyID,
        supplierID: state.supplierID,
        templateImage: state.templateImage,
      };
      ApiService.TemplateController.TemplateInsert(template, (response: IAPIResponse) => {
        if (response.error === null) {
          let templateCreated: ITemplate = response.payload[0];
          let formDataEnhanced: any = { ...state };
          formDataEnhanced["templateID"] = templateCreated.templateID;
          dispatch(
            GenericActions.setTemplateGenericWorkingData({
              headers: knownHeaders,
              formData: formDataEnhanced,
              templateTypeID: props.templateTypeID,
              file: plainFiles && plainFiles.length > 0 ? plainFiles[0] : null,
            })
          );

          stepperGoToPage(1);
        } else {
          if (response.raw.status === 400) {
            ToastMessage(i18next.t("error:duplicate_template_name"), "error");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      });
    }
  };

  /* RETURN */
  return (
    <div>
      {!isFileUpload && (
        <div className="button-load-template-wrapper">
          {errors.length === 0 && (
            <Button onClick={() => openFileSelector()}>
              <div className="upload-icon-wrapper">
                <UploadIcon className="text-color-hint StepDashboardLoadTemplate__icon StepDashboardLoadTemplate__icon--size" />
              </div>
              <div className="title-wrapper">
                <strong>{props.primaryTitle}</strong>
              </div>
              <div className="accepted-file-wrapper">
                {props.primarySubTitle}: .csv .xls .xlsx, .txt
              </div>
            </Button>
          )}
        </div>
      )}

      {isFileUpload && (
        <div>
          <div className="select-model-wrapper">
            <div>
              <div className="upload-icon-wrapper">
                <TaskIcon className="text-color-hint StepDashboardLoadTemplate__icon StepDashboardLoadTemplate__icon--size" />
              </div>

              <div className="title-wrapper">
                <strong>{plainFiles[0].name}</strong>
              </div>

              <div className="accepted-file-wrapper">
                {props.primarySubTitle}: .csv .xls .xlsx, .txt
              </div>
            </div>

            <StepDashboardLoadTemplate_Buttons templateTypeID={props.templateTypeID} />
          </div>

          <SmartModal
            modalUniqueId={"detailsFileId"}
            title={""}
            modalInnerComponent={
              <div>
                <DetailsFileSupply
                  data={state}
                  onChange={(data: any) => {
                    setState(data);
                  }}
                />
              </div>
            }
            buttons={[
              {
                text: i18next.t("navigation:reject") ?? "",
                onClick: () => {
                  DismissModal("detailsFileId");
                },
                disabled: false,
              },
              {
                text: i18next.t("navigation:accept") ?? "",
                onClick: () => {
                  createTemplate();
                },
                disabled: !ValidateForm(state, true),
              },
            ]}
          />
        </div>
      )}

      <LoaderBackdrop loading={processing} />
    </div>
  );
};

export default StepDashboardLoadTemplate;
