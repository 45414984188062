import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import "./DragAndDropContainer.scss";
import { DragAndDropContainerInner } from "./DragAndDropContainerInner";
import i18next from "i18next";
import { ToastMessage } from "../../Utils/Toastify";

export interface DragAndDropContainerProps {
  icon: JSX.Element | null;
  title: JSX.Element | null;
  lowerElement: JSX.Element | null;
  onDrop: (files: File[]) => any;
  allowFileListing: boolean;
  preLoadFiles?: File[];
  enabled?: boolean;
  multiple: boolean;
  disableInternalListing: boolean;
  wrapperClass?: string;
  accept?: string;
  disabled?: boolean;
  onRemove: (files: File[]) => any;
}

const MAX_FILE_SIZE = 750_000_000; // 750 MB !

export const DragAndDropContainer = (props: DragAndDropContainerProps) => {
  const fileId: string = uuid();

  const [files, setFiles] = useState<File[]>([]);

  const addFiles = (toAddFiles_: File[]) => {
    let toAddFiles: File[] = [];

    for (let i = 0; i < toAddFiles_.length; i++) {
      if (toAddFiles_[i].size <= MAX_FILE_SIZE) {
        toAddFiles.push(toAddFiles_[i]);
      } else {
        ToastMessage(i18next.t("message:max_file_size_error"), "error");
      }
    }

    if (props.enabled === false) {
      return;
    }

    let files_ = props.multiple ? [...files] : [];

    for (let i = 0; i < toAddFiles.length; i++) {
      if (files_.filter((x) => x.name === toAddFiles[i].name).length === 0) {
        files_.push(toAddFiles[i]);
      }
    }

    // emit only newly added files
    props.onDrop(files_);

    if (!props.disableInternalListing) {
      setFiles(files_);
    }
  };

  const removeFile = (index: number) => {
    if (props.enabled === false) {
      return;
    }

    files.splice(index, 1);
    if (!props.disableInternalListing) {
      setFiles([...files]);
      props.onRemove(files);
    }
  };

  useEffect(() => {
    if (props.preLoadFiles && props.preLoadFiles.length > 0) {
      setFiles(props.preLoadFiles);
    }
    if (props.preLoadFiles && props.preLoadFiles.length === 0) {
      setFiles([]);
    }
  }, [props.preLoadFiles]);

  return (
    <div>
      <div className={"formDropFileWrap " + (props.disabled && "input_disabled")}>
        <div
          onClick={() => {
            if(!props.disabled){
              document.getElementById(fileId)?.click();
            }
          }}
        >
          <DragAndDropContainerInner
            wrapperClass={props.wrapperClass}
            onDrop={(f: any) => {
              if(!props.disabled){
                let files: File[] = Array.from(f);
                if (props.accept && props.accept !== "*") {
                  files = files.filter((x: File) =>
                    new RegExp(props.accept ?? "").test(x.type)
                  );
                }
                addFiles(files);
              }
            }}
          >
            <div className="drag-n-drop-file-idle-wrap">
              <div className="drag-n-drop-file-idle-icon">
                {props.icon && <div>{props.icon}</div>}
              </div>
              <div>
                {props.title && (
                  <div
                    style={{
                      margin: "0.5em 0",
                      userSelect: "none",
                      fontWeight: "600",
                    }}
                  >
                    {props.title}
                  </div>
                )}
                {props.lowerElement && (
                  <div
                    style={{
                      margin: "0.5em 0",
                      userSelect: "none",
                      fontWeight: "300",
                      fontSize: "0.8em",
                    }}
                  >
                    {props.lowerElement}
                  </div>
                )}
              </div>
            </div>
          </DragAndDropContainerInner>
        </div>
        {props.enabled !== false && (
          <div className="drop-input-wrapper">
            <input
              multiple={props.multiple}
              accept={props.accept}
              onChange={(e: any) => {
                let input: any = document.getElementById(fileId);
                if (!props.disabled && input != null) {
                  addFiles(e.target.files);
                  input["value"] = "";
                }
              }}
              id={fileId}
              type="file"
              style={{ position: "absolute", zIndex: "-1" }}
            />
          </div>
        )}
      </div>

      {props.allowFileListing && (
        <>
          <div>
            {files.map((x: File, i: number) => {
              return (
                <div key={i} className="fileUploadedWrap">
                  <div className="fileUploadedVoice">
                    <div className="fileUploadedLabel">{x.name}</div>
                    <div
                      onClick={() => {
                        if(!props.disabled){
                          removeFile(i);
                        }
                      }}
                      className="fileRemoveButton"
                    >
                      x
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
