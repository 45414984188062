import ApiEndPoints from "../../Costants/ApiEndPoints";
import { IPaymentRoadTaxReqStep } from "../../Models/IPaymentRoadTaxReqStep";
import { IRoadTaxPaymentsRequestInsert } from "../../Models/IRoadTaxPaymentsRequestInsert";
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const PaymentsRoadTaxController = {
  RoadTaxGet: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_GET;
    request.port = "7237";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxBillGet: async (
    roadTaxBillID?: number,
    roadTaxBillTypeID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_BILL_GET;
    request.port = "7237";
    if (roadTaxBillID) {
      request.url += `?roadTaxBillID=${roadTaxBillID}`;
    }
    if (roadTaxBillTypeID) {
      request.url += `?roadTaxBillTypeID=${roadTaxBillTypeID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DownloadRoadTaxPaymentRequestReportGet: async (
    dateTo: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url =
      ApiEndPoints.PAYMENTS_ROAD_TAX_DOWNLOAD_PAYMENT_REQUEST_REPORT;
    request.url += "?dateTo=" + dateTo;
    request.port = "7237";
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DownloadRoadTaxPaymentRequestPaidGet: async (
    dateTo: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_DOWNLOAD_PAYMENT_REQUEST_PAID;
    request.url += "?dateTo=" + dateTo;
    request.skipResponseJsonParse = true;
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/zip" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsRequestInsert: async (
    body: IRoadTaxPaymentsRequestInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_INSERT_PAYMENT_REQUEST;
    request.payload = body;
    request.method = "POST";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsReqStepGet: async (
    roadTaxPayReqStepID?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7237";
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_PAYMENT_REQ_STEP_GET;
    if (roadTaxPayReqStepID) {
      request.url += `?roadTaxPayReqStepID=${roadTaxPayReqStepID}`;
    }
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsUploadInt: async (
    body: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    for (let i = 0; i < body.length; i++) {
      formData.append("files", body[i], body[i].name);
    }
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_UPLOAD_INT;
    request.method = "POST";
    request.port = "7237";
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsUploadVer: async (
    body: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    for (let i = 0; i < body.length; i++) {
      formData.append("files", body[i], body[i].name);
    }
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_UPLOAD_VER;
    request.method = "POST";
    request.port = "7237";
    request.payload = formData;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsUploadScanFiles: async (
    body: any,
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    formData.append("zip", body[0], body[0].name);
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_UPLOAD_SCAN_FILES;
    request.method = "POST";
    request.port = "7237";
    request.payload = formData;
    request.ignoreDefaultHeaders = true;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsSendUpdate: async (
    body: IRoadTaxPaymentsRequestInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_SEND_PAYMENT_UPDATE;
    request.payload = body;
    request.port = "7237";
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  RoadTaxPaymentsReqStepUpdate: async (
    body: IPaymentRoadTaxReqStep,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.PAYMENTS_ROAD_TAX_PAYMENT_REQ_STEP_UPDATE;
    request.payload = body;
    request.method = "PUT";
    request.port = "7237";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default PaymentsRoadTaxController;
