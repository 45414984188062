import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import { dateIsPast } from "../MultiForm/SpecialInputs/StrongDatePicker";
import { useEffect, useState } from "react";
import { IWriteableUsers } from "../../Models/IMessages";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/Toastify";
import { UserActions } from "../../Reducers/User/UserAction";
import SmartModal, { DismissModal } from "../SmartModal/SmartModal";
import i18next from "i18next";
import { Alert, CircularProgress } from "@mui/material";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import { checkAccountSessionStatusRedirect, userRoleFits } from "../../Utils/Session";
import { useNavigate } from "react-router-dom";

export interface ISearchFormProps {
  autocompleteOptions: any[];
}

export const ImpModal = () => {
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  const currentImpUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  var impIsActive: boolean = false;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
      impIsActive = true;
    }
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* STATES */
  // State for data container
  const [impusers, setImpUsers] = useState<IWriteableUsers[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);

  const loadUsers = () => {
    setLoading(true);
    ApiService.AccountController.GetImpersonatableAccountsList(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setImpUsers(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const impersonateUser = (data: any) => {
    let userId = data.userId;
    setLoading(true);
    ApiService.AdminController.ImpersonateStart(
      userId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("ImpersonateModal");
          dispatch(UserActions.setUserImp(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const impersonateClose = () => {
    setLoading(true);
    ApiService.AdminController.ImpersonateClose((response: IAPIResponse) => {
      if (response.error === null) {
        DismissModal("ImpersonateModal");
        dispatch(UserActions.setUserImp(undefined));
        navigate(checkAccountSessionStatusRedirect(false, false, false, false));
      } else {
        ToastMessage(response.error, "error");
      }
      setLoading(false);
    });
  };

  const loadGetCompany = () => {
    setLoadingCompany(true);
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(UserActions.setCompanies(response.payload));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompany(false);
        }
      );
    }
  };

  useEffect(() => {
    loadGetCompany();
  }, [currentImpUser]);
  return (
    <SmartModal
      onBeforeSummon={() => {
        loadUsers();
      }}
      title={i18next.t("navigation:impersonate")}
      modalUniqueId="ImpersonateModal"
      modalInnerComponent={
        <div className="modal-width-small">
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "2em",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <div>
              {impIsActive && (
                <div>{i18next.t("navigation:disable_imp_msg")}</div>
              )}
              {!impIsActive && (
                <div>
                  {impusers && impusers.length === 0 && (
                    <div>
                      <Alert severity="info">
                        {i18next.t("navigation:impersonate_cannot")}
                      </Alert>
                    </div>
                  )}
                  <MultiForm
                    suppressLayout
                    suppressSubmit
                    formId="ImpersonateForm"
                    inputs={[
                      {
                        width: 100,
                        type: "select",
                        name: "userId",
                        required: true,
                        disabled: (window as any)["preloadImp"] !== undefined,
                        defaultValue: (window as any)["preloadImp"],
                        label: i18next.t("navigation:impersonate") ?? "",
                        options: impusers.map((x: IWriteableUsers) => {
                          let name = x.accountName + " " + x.accountSurname;

                          return {
                            key: x.accountID,
                            text: name,
                          };
                        }),
                      },
                    ]}
                    onSubmit={(data: any) => {
                      impersonateUser(data);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      }
      buttons={
        impIsActive
          ? [
              {
                text: i18next.t("navigation:disable_imp_btn"),
                onClick: () => {
                  impersonateClose();
                },
              },
            ]
          : [
              {
                disabled: impusers.length === 0,
                text: i18next.t("navigation:impersonation"),
                onClick: () => {
                  triggerFormValidation("ImpersonateForm");
                },
              },
            ]
      }
    />
  );
};

export default ImpModal;
