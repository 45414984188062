import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICompanyGet } from "../../../Models/ICompany";
import { IMctcGet, IMctcGetRaw } from "../../../Models/IMctcGet";
import { IUser } from "../../../Models/IUser";
import {
  IVehicleDataBrands,
  IVehicleDataFitting,
  IVehicleDataGet,
  IVehicleDataInsert,
  IVehicleDataModels,
  IVehicleDataUpdate,
} from "../../../Models/IVehicles";
import { VehiclesActions } from "../../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import { validationErrorMessages } from "../../../Utils/Validation";
import DeadlineMainHandler from "../../DeadlineMainHandler/DeadlineMainHandler";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import SmartModal, { DismissModal, SummonModal } from "../../SmartModal/SmartModal";
import { BrandsActions } from "../../../Reducers/Brands/BrandsAction";
import AddNewBanner from "../../AddNewBanner/AddNewBanner";
import AccordionContent from "../../AccordionContent/AccordionContent";
import MctcTableViewer from "../../MctcTableViewer/MctcTableViewer";
import { fromOrderGet_vehicle } from "../../../Utils/OrderToVehicle";
import AppRoutes from "../../../Costants/AppRoutes";
import { validatePlate } from "../../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { GlobalState } from "../../../Reducers/RootReducer";
import { dateIsPast, dateToUTC0_ISO } from "../../MultiForm/SpecialInputs/StrongDatePicker";
import { base64ToFileBlob } from "../../../Utils/FileToBase";
import ApiEndPoints from "../../../Costants/ApiEndPoints";
import { IAttachmentMctc } from "../../../Models/IAttachments";
import { IOrderGetSide } from "../../../Models/IOrder";

export interface IFormVehicleData {
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  dataVehicle?: IVehicleDataGet;
  noDeadlines?: boolean;
  isModal?: boolean;
  doResetVehicle?: (vehicle: IVehicleDataGet | null) => void;
  canInsertVehicles?: boolean;
  doRefreshForm?:  (vehicle: IVehicleDataGet | null) => void;
}

const FormVehicleData = (props: IFormVehicleData) => {
  const disposed: number | undefined = useSelector(
    (state: GlobalState) => state.vehicles.activeVehicleDispose
  );
  const dispatch = useDispatch();
  const [state, setState] = useState<any>(fromOrderGet_vehicle(props.dataVehicle ?? {}));
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingDeadline, setProcessingDeadline] = useState<boolean>(false);
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);
  const [canUserUseMctc, setCanUserUseMctc] = useState<boolean>(false);
  const [mctcAttachment, setMctcAttachment] = useState<IAttachmentMctc[]>([]);
  const vehicleMctc: IMctcGet | any = useSelector((state: GlobalState) => state.vehicles.vehicleMctc);
  const companies: ICompanyGet[] | undefined = useSelector(
    (state: GlobalState) => state.user.companies
  );

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const [loadingMctcAttachment, setLoadingMctcAttachment] = useState<boolean>(false);

  const ReprocessMctcData = (raw: IMctcGetRaw) => {
    let brandCode: any = brandsList.find(
      (el: IVehicleDataBrands) =>
        el.brandDescription === raw.datiBaseVeicolo.marcaVeicolo
    )?.brandCode;
    loadModelsList(brandCode, state?.inProduction);
    return {
      raw: raw,
      forForm: {
        vehicleLicensePlate: raw.datiBaseVeicolo.targaVeicolo,
        brandCode: brandCode,
        modelCode: modelList.find((el: IVehicleDataModels) => el.modelDescription === raw.datiTecniciVeicolo.denominazioneCommercialeVeicolo)?.modelCode,
        vehicleChassis: raw.datiBaseVeicolo.numeroTelaio,
        vehicleFirstRegistrationDT: dateToUTC0_ISO(
          new Date (raw.datiBaseVeicolo.dataPrimaImmatricolazione)
        ),
        vehicleLastInspectionDT: raw.datiUltimaRevisione !== null ? dateToUTC0_ISO(
          new Date (raw.datiUltimaRevisione.dataUltimaRevisione)
        ) : null,
      },
    };
  };

  const VehicleMctc = () => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.VehicleDataController.VehicleMctc(
        state?.vehicleLicensePlate,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let mctcData: any = ReprocessMctcData(response.payload);
            if(mctcData.raw.pdf){
              uploadMctcFile(mctcData.raw.pdf, mctcData.forForm.vehicleLicensePlate, JSON.stringify(mctcData.raw));
            }
            setState(mctcData.forForm);
            
            DismissModal("MCTCModalAcceptFormVehicle");
            dispatch(VehiclesActions.setVehicleMctc(mctcData));
          } else {
            if(response.raw.status === 400){
              ToastMessage(response.payload.title, "error");
            }else{
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const CheckCanUserUseMctc = () => {
    if (props.loggedUser) {
      ApiService.VehicleDataController.VehicleCheckCanUserUseMctc(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCanUserUseMctc(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
  };

  const uploadMctcFile = (file: string, fileName: string, mctcAttachmentJSON: string) => {
    ApiService.UploadController.UploadFile(
      "attachmentmctc",
      [base64ToFileBlob(file, fileName, "application/pdf")],
      (response: IAPIResponse) => {
        if (response.error === null) {
          setProcessing(true);
          let data: any = {
            attachmentName: fileName,
            attachmentDescription: "",
            attachmentURL: response.payload.fileReference,
            mctcAttachmentJSON: mctcAttachmentJSON,
          }
          ApiService.AttachmentController.InsertAttachment(
            ApiEndPoints.MCTC_ATTACHMENT_INSERT,
            data,
            (response: IAPIResponse) => {
              if (response.error === null) {
                setMctcAttachment(response.payload);
              } else if (response.raw.status === 400) {
                ToastMessage(i18next.t("error:MISSING_FIELDS"), "error");
              } else {
                ToastMessage(response.error, "error");
              }
      
              setProcessing(false);
            }
          );
        } else {
          ToastMessage(i18next.t("error:could_not_upload"), "error");
        }
      }
    );
  };

  const updateMctcFile = (body: IAttachmentMctc) => {
    ApiService.AttachmentController.UpdateAttachment(
      ApiEndPoints.MCTC_ATTACHMENT_UPDATE,
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setMctcAttachment(response.payload);
        } else if (response.raw.status === 400) {
          ToastMessage(i18next.t("error:MISSING_FIELDS"), "error");
        } else {
          ToastMessage(response.error, "error");
        }

        setProcessing(false);
      }
    );
  }

  const loadMctcAttachment = () => {
    setLoadingMctcAttachment(true);
    ApiService.VehicleDataController.MctcAttachmentGet(
      null,
      null,
      props.dataVehicle?.vehicleLicensePlate,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if(response.payload.length > 0){
            dispatch(VehiclesActions.setVehicleMctc(JSON.parse(response.payload[0].mctcAttachmentJSON)));
          }else{
            dispatch(VehiclesActions.setVehicleMctc(undefined));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
        setLoadingMctcAttachment(false);
      }
    );
  }

  const loadBrandsList = (inProduction: number) => {
    if (props.loggedUser) {
      setLoadingBrandsList(true);

      ApiService.VehicleDataController.VehicleDataGetBrands(
        inProduction === 1 ? true : false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (props.dataVehicle && props.dataVehicle.customBrandName) {
              response.payload.push({
                brandCode: props.dataVehicle.customBrandName,
                brandDescription: props.dataVehicle.customBrandName,
              });
            }
            setBrandsList(response.payload);
            dispatch(BrandsActions.setBrandsList(response.payload));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingBrandsList(false);
        }
      );
    }
  };

  const loadModelsList = (brandCode: string, inProduction: number) => {
    if (props.loggedUser && brandCode) {
      setLoadingModelsList(true);
      ApiService.VehicleDataController.VehicleDataGetModels(
        brandCode,
        inProduction === 1 ? true : false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (props.dataVehicle && props.dataVehicle.customModelName) {
              response.payload.push({
                modelCode: props.dataVehicle.customModelName,
                modelDescription: props.dataVehicle.customModelName,
              });
            }
            setModelsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingModelsList(false);
        }
      );
    }
  };

  const loadFittingList = (
    brandCode: string,
    modelCode: string,
    inProduction: number
  ) => {
    if (props.loggedUser && brandCode && modelCode) {
      setLoadingFittingsList(true);
      if (props.loggedUser) {
        ApiService.VehicleDataController.VehicleDataGetFittings(
          brandCode,
          modelCode,
          inProduction === 1 ? true : false,
          (response: IAPIResponse) => {
            if (response.error === null) {
              if (props.dataVehicle && props.dataVehicle.customFittingName) {
                response.payload.push({
                  fittingCode: props.dataVehicle.customFittingName,
                  fittingDescription: props.dataVehicle.customFittingName,
                });
              }
              setFittingsList(response.payload);
            } else {
              ToastMessage(response.error, "error");
            }
            setLoadingFittingsList(false);
          }
        );
      }
    }
  };

  const markOrderAsCompleted = (vehicle: IVehicleDataGet) => {
    let order: { detailIndex: number; order: IOrderGetSide } = JSON.parse(
      localStorage.getItem("order-transform") ?? "{}"
    );
    let data: IOrderGetSide = { ...order.order };
    let vehicleOrderDetailID: number =
      data.details[order.detailIndex].vehicleOrderDetailID;
    ApiService.OrderController.OrderMarkDetailForVehicle(
      vehicleOrderDetailID,
      vehicle.vehicleID
    );
  };

  const insertVehicleData = (data: IVehicleDataInsert) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataInsert(
      data,
      !window.location.pathname.includes("dashboard") || props.isModal,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:vehicleDataSucceffulAdded"), "success");
          let v: any = { ...data };
          v.vehicleID = response.payload;
          if(mctcAttachment && mctcAttachment.length > 0){
            let mctcBody: IAttachmentMctc = {...mctcAttachment[0]};
            mctcBody.vehicleID = response.payload.vehicleID;  
            updateMctcFile(mctcBody);
          }
          if (props.doResetVehicle) {
            props.doResetVehicle(v);
          }
          if(props.doRefreshForm){
            props.doRefreshForm(response.payload);
          }
          // if vehicle is from order
          if (localStorage.getItem("order-transform")) {
            markOrderAsCompleted(response.payload);
          }
          DismissModal("vehicle-modal");
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402 || response.raw.status === 400) {
            ToastMessage(i18next.t("message:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessing(false);
      }
    );
  };

  const updateVehicleData = (data: IVehicleDataUpdate) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataUpdate(
      data,
      true,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:vehicleDataSucceffulUpdated"), "success");

          if (props.doResetVehicle) {
            props.doResetVehicle(data as any);
          }
        } else {
          let errors = validationErrorMessages(response.payload);
          if (response.raw.status === 402) {
            ToastMessage(i18next.t("error:" + response.error), "error");
          } else {
            if (errors) {
              for (let e = 0; e < errors.length; e++) {
                ToastMessage(`${errors[e]}`, "error");
              }
            } else {
              ToastMessage(response.error, "error");
            }
          }
        }
        setProcessing(false);
      }
    );
  };

  const deleteVehicleData = (vehicleID: number) => {
    setProcessing(true);
    ApiService.VehicleDataController.VehicleDataDelete(
      vehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:vehicleDataSucceffulDeleted"), "success");
          if (props.doResetVehicle) {
            props.doResetVehicle(null);
          }
          newVehicleData();
          DismissModal("StepVehicles-modal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    // if you are admin, you must declare the target company too
    if (loggedUser?.roleID === 1 && companies) {
      multiformInputs.push({
        width: 100,
        type: "select",
        name: "companyID",
        label: i18next.t("navigation:admin_must_set_company") ?? "",
        options: companies.map((x: ICompanyGet) => {
          return {
            key: x.companyID,
            text:
              (x.companyBusinessName ?? "?") +
              " , ref: " +
              (x.companyReferentName ?? "?") +
              " " +
              (x.companyReferentSurname ?? "?"),
          };
        }),
      });
    }

    multiformInputs.push(
      {
        width: 25,
        type: "text",
        name: "vehicleLicensePlate",
        defaultValue: state?.vehicleLicensePlate,
        inputProps: {
          maxLength: 20,
          style: { textTransform: "uppercase" },
        },
        errorText: state ? validatePlate(state?.vehicleLicensePlate) : undefined,
        label: i18next.t("form:numberPlateLicense") as string,
        placeholder: "(A)",
        required: true,
      },
      {
        width: 15,
        type: "custom",
        name: "MCTC",
        className: "mctc-wrapper",
        element: (
          <Tooltip title={i18next.t("message:vehicleMctcInfo")}>
            <div className="multi-form-button-add-record">
              <Button
                disabled={!canUserUseMctc || disposed > 0 || processing}
                variant="contained"
                onClick={() => {
                  if(vehicleMctc && state?.vehicleLicensePlate !== undefined){
                    SummonModal("MCTCModalAcceptFormVehicle");
                  }else{
                    if (state?.vehicleLicensePlate) {
                      VehicleMctc();
                    }
                  }
                }}
              >
                MCTC
              </Button>
            </div>
          </Tooltip>
        ),
      },
      {
        type: "radio",
        name: "inProduction",
        defaultValue:
          state?.inProduction !== undefined && state?.inProduction !== null
            ? state.inProduction
              ? 1
              : 0
            : 1,
        width: loggedUser?.managedByBroker ? 35 : 60,
        label: i18next.t("form:changeHistoricallyVehicle") as string,
        options: [
          {
            key: 1,
            text: i18next.t("form:vehicleInProdution"),
          },
          {
            key: 0,
            text: i18next.t("form:vehicleNotMoreInProdution"),
          },
        ],
      }
    );

    if (loggedUser?.managedByBroker) {
      multiformInputs.push({
        width: 25,
        type: "switch",
        name: "managedByBroker",
        defaultValue: state?.managedByBroker,
        label: i18next.t("form:managedByBroker").toString(),
      });
    }

      
    multiformInputs.push(
      {
        width: 100,
        type: "custom",
        name: "banner",
        element: (
          <Alert severity="info">
            {i18next.t("navigation:vehicleDataMCTCInfoBanner")}
          </Alert>
        )
      },
      {
        width: 33.33,
        type: "select",
        name: "brandCode",
        inputProps: { maxLength: 6 },
        defaultValue: state?.customBrandName ? state?.customBrandName : state?.brandCode,
        required: true,
        label: i18next.t("form:orderBrand") as string,
        placeholder: "(D,1)",
        options: brandsList.map((item: IVehicleDataBrands) => {
          return {
            key: item.brandCode,
            text: item.brandDescription,
          };
        }),
      },
      {
        width: 33.33,
        type: "select",
        name: "modelCode",
        required: true,
        inputProps: { maxLength: 6 },
        defaultValue: state?.customModelName ? state?.customModelName : state?.modelCode,
        label: i18next.t("form:orderModel") as string,
        placeholder: "(D,2)",
        options: modelList.map((item: IVehicleDataModels) => {
          return {
            key: item.modelCode,
            text: item.modelDescription,
          };
        }),
      },
      {
        width: 33.33,
        type: "select",
        name: "fittingCode",
        required: true,
        defaultValue: state?.customFittingName
          ? state?.customFittingName
          : state?.fittingCode,
        inputProps: { maxLength: 6 },
        label: i18next.t("form:orderEquipment") as string,
        placeholder: "(D,3)",
        options: fittingList.map((item: IVehicleDataFitting) => {
          return {
            key: item.fittingCode,
            text: item.fittingDescription,
          };
        }),
      },
      {
        width: 50,
        type: "text",
        name: "vehicleChassis",
        required: true,
        defaultValue: state?.vehicleChassis,
        currentValue: state?.vehicleChassis,
        inputProps: {
          maxLength: 17,
          style: { textTransform: "uppercase" },
        },
        label: i18next.t("form:numberChassis").toString(),
      },
      {
        width: 50,
        type: "date",
        required: true,
        name: "vehicleFirstRegistrationDT",
        defaultValue: state?.vehicleFirstRegistrationDT,
        currentValue: state?.vehicleFirstRegistrationDT,
        maxDate: dayjs(state?.vehicleLastInspectionDT),
        disableFuture: true,
        label: String(i18next.t("form:firstWarrantyCheck")),
      },
      {
        width: 50,
        type: "date",
        name: "vehicleLastInspectionDT",
        defaultValue: state?.vehicleLastInspectionDT,
        disableFuture: true,
        required: true,
        minDate: dayjs(state?.vehicleFirstRegistrationDT),
        label: String(i18next.t("form:lastWarrantyCheck")),
      },
      {
        width: 50,
        type: "number",
        required: true,
        name: "vehicleLastInspectionKm",
        inputProps: {
          max: 1000000,
        },
        defaultValue: state?.vehicleLastInspectionKm,
        label: String(i18next.t("form:lastWarrantyCheckKM")),
      },
      {
        width: 50,
        type: "date",
        name: "vehicleExpireRoadTaxDT",
        required: true,
        disablePast: true,
        defaultValue: state?.vehicleExpireRoadTaxDT,
        label: String(i18next.t("form:dateExpirePayVehicle")),
      },
      {
        width: 50,
        type: "text",
        name: "vehicleSatelliteDeviceNumber",
        required: false,
        inputProps: {
          maxLength: 50,
        },
        defaultValue: state?.vehicleSatelliteDeviceNumber,
        label: i18next.t("form:numberSATVehicle") as string,
      },
      {
        width: 100,
        type: "switch",
        name: "roadTaxPaymentAddon",
        defaultValue: state?.roadTaxPaymentAddon,
        placeholder: (
          <div
            dangerouslySetInnerHTML={{
              __html: i18next.t("form:switchPayVehicle") ?? "",
            }}
          />
        ),
      }
    );

    return multiformInputs;
  };

  const newVehicleData = () => {
    setResetForm(true);
    setState(null);
    setTimeout(() => {
      setResetForm(false);
      if (props.doResetVehicle) {
        props.doResetVehicle(null);
      }
    }, 200);
  };

  useEffect(() => {
    if (state?.inProduction !== undefined && state?.inProduction !== null) {
      loadBrandsList(+state?.inProduction);
    } else {
      setBrandsList([]);
      setModelsList([]);
      setFittingsList([]);
    }
  }, [state?.inProduction]);

  useEffect(() => {
    loadModelsList(state?.brandCode, +state?.inProduction);
  }, [state?.brandCode, state?.inProduction]);

  useEffect(() => {
    loadFittingList(state?.brandCode, state?.modelCode, +state?.inProduction);
  }, [state?.brandCode, state?.modelCode, state?.inProduction]);

  useEffect(() => {
    CheckCanUserUseMctc();
  }, []);

  useEffect(() => {
    if((state?.vehicleID !== undefined || state?.vehicleID !== null) && props.dataVehicle?.vehicleLicensePlate){
      loadMctcAttachment();
    }
  }, [state?.vehicleID, props.dataVehicle?.vehicleLicensePlate]);

  useEffect(() => {
    if (vehicleMctc && vehicleMctc.forForm !== undefined) {
      let cleanState: any = {};
      let keys = Object.keys(vehicleMctc.forForm);
      for (let i = 0; i < keys.length; i++) {
        let val: any = vehicleMctc.forForm[keys[i]];
        if (val !== null && val !== undefined) {
          cleanState[keys[i]] = val;
        }
      }
      setState({ ...state, ...cleanState });
      setResetForm(true);
      setTimeout(() => {
        setResetForm(false);
      }, 200);
    }
  }, [vehicleMctc]);

  const globalLoading = loadingBrandsList || loadingModelsList || loadingFittingsList;
  return (
    <div>
      {!resetForm && (
        <div>
          {props.canInsertVehicles && (
            <div className="padding-small">
              <AddNewBanner
                suppressLayout
                label={i18next.t("navigation:addNewVehicleData") as string}
                new={() => {
                  dispatch(VehiclesActions.setVehicleMctc(undefined));
                  newVehicleData();
                }}
              />
            </div>
          )}
          {vehicleMctc && (
            <div className="padding-small">
              <AccordionContent
                title="Mostra dati motorizzazione"
                content={<MctcTableViewer vehicleInfoMctc={vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw : vehicleMctc} />}
              />
            </div>
          )}
          
          <MultiForm
            formId={"StepVehicles"}
            suppressLayout
            disableForm={!props.canInsertVehicles && state?.vehicleID <= 0}
            formTitle={""}
            inputs={multiformInputs()}
            onChange={async (data: any) => {
              if(state?.vehicleID === undefined || state?.vehicleID === null){
                data['vehicleID'] = 0;
              }
              setState({ ...state, ...data });
            }}
            suppressSubmit={true}
            onSubmit={(data: any) => {
              if (validatePlate(state?.vehicleLicensePlate)) {
                return;
              }

              let parseData = { ...data };
              delete parseData["MCTC"];
              parseData["vehicleLastInspectionKm"] = +data["vehicleLastInspectionKm"];
              parseData["inProduction"] = +data["inProduction"] === 1 ? true : false;
              if (state?.vehicleID) {
                parseData["vehicleID"] = state?.vehicleID;
                if (props.dataVehicle) {
                  if (parseData["brandCode"] !== props.dataVehicle["customBrandName"]) {
                    delete parseData["customBrandName"];
                  } else {
                    delete parseData["brandCode"];
                    parseData["customBrandName"] = props.dataVehicle["customBrandName"];
                  }
                }
                if (props.dataVehicle) {
                  if (parseData["modelCode"] !== props.dataVehicle["customModelName"]) {
                    delete parseData["customModelName"];
                  } else {
                    delete parseData["modelCode"];
                    parseData["customModelName"] = props.dataVehicle["customModelName"];
                  }
                }
                if (props.dataVehicle) {
                  if (
                    parseData["fittingCode"] !== props.dataVehicle["customFittingName"]
                  ) {
                    delete parseData["customFittingName"];
                  } else {
                    delete parseData["fittingCode"];
                    parseData["customFittingName"] =
                      props.dataVehicle["customFittingName"];
                  }
                }
                updateVehicleData(parseData);
              } else {
                if(vehicleMctc){
                  parseData["euroDirective"] =  vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiBaseVeicolo.classeEURO : vehicleMctc.datiBaseVeicolo.classeEURO;
                  parseData["cO2Emission"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cO2 : vehicleMctc.datiTecniciVeicolo.cO2;
                  parseData["power"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.kw : vehicleMctc.datiTecniciVeicolo.kw;
                  parseData["engineCapacity"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cilindrataInCentimetriCubi : vehicleMctc.datiTecniciVeicolo.cilindrataInCentimetriCubi;
                  parseData["horses"] = vehicleMctc.hasOwnProperty('raw') ? vehicleMctc.raw.datiTecniciVeicolo.cavalliFiscali : vehicleMctc.datiTecniciVeicolo.cavalliFiscali;
                }
                insertVehicleData(parseData);
              }
            }}
          />

          {props.noDeadlines !== true && (
            <DeadlineMainHandler
              DeadlineFamily={"vehicles"}
              extraGetParameters={
                state?.vehicleID
                  ? {
                      name: "vehicleID",
                      value: state?.vehicleID,
                    }
                  : undefined
              }
            />
          )}

          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={processing && processingDeadline}
                onClick={() => {
                  triggerFormValidation("StepVehicles");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveVehicle")}
              </Button>

              {!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) && (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.vehicleID}
                  onClick={() => {
                    SummonModal("StepVehicles-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteVehicle")}
                </Button>
              )}

              <SmartModal
                modalUniqueId="StepVehicles-modal"
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          state?.vehicleLicensePlate?.toUpperCase()
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  deleteVehicleData(state?.vehicleID);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </div>
      )}
      <SmartModal 
        modalUniqueId={"MCTCModalAcceptFormVehicle"}
        title={i18next.t("navigation:MCTCModalAccepTitle")}
        modalInnerComponent={
          <p>{i18next.t("navigation:MCTCModalAcceptDescription")}</p>
        }
        onAccept={() => {
          VehicleMctc();
        }}
        onReject={() => {
          DismissModal("MCTCModalAcceptFormVehicle");
        }}
      />
      <LoaderBackdrop loading={globalLoading || processing || resetForm || loadingMctcAttachment} />
    </div>
  );
};

export default FormVehicleData;
