/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* LIBRARIES */
import i18next from "i18next";
import moment from "moment";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import MultiForm, {
  IMultiFormField,
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* MODELS */
import { ICo2SEmissionStatistics } from "../../../../../Models/IStatistics";

/* MUI */
import { Alert, Box, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import RemoveIcon from "@mui/icons-material/Remove";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import CO2Emission__StatisticsPage_Emitted from "./CO2Emission__StatisticsPage_Emitted";
import CO2Emission__StatisticsPage_EcologicDriver from "./CO2Emission__StatisticsPage_EcologicDriver";
import { displayUTC0_ISODate } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { numberToLocalDecoder } from "../../../../../Utils/Decoder";

/* COMPONENT */
const CO2Emission__StatisticsPage_Data = () => {
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>({});
  const [responseData, setResponseData] = useState<ICo2SEmissionStatistics[]>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [rowsDef, setRowsDef] = useState<any>([]);

  const [totalCO2Emitted, setTotalCO2Emitted] = useState<any>([]);
  const [ecologicDriver, setEcologicDriver] = useState<any>([]);
  // Loading
  const [loadingCo2Emissions, setLoadingCo2Emissions] = useState<boolean>(false);

  /* API */
  // Load co2 emissions
  const loadStatisticsCo2Emissions = (
    prodFrom: Date,
    prodTo: Date,
    avgFrom: any,
    avgTo: any
  ) => {
    setLoadingCo2Emissions(true);

    ApiService.StatisticsController.getStatisticsCo2Emissions(
      prodFrom,
      prodTo,
      avgFrom,
      avgTo,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setResponseData(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingCo2Emissions(false);
      }
    );
  };

  /* NAVIGATE */
  const navigate = useNavigate();

  /* FUNCTIONS */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      // From
      {
        width: 20,
        type: "date",
        size: "small",
        name: "statisticsCO2DateFrom",
        defaultValue: state?.statisticsCO2DateFrom,
        label: String(i18next.t(`form:from`)),
        maxDate: state?.statisticsCO2DateTo,
      },
      // To
      {
        width: 20,
        type: "date",
        size: "small",
        name: "statisticsCO2DateTo",
        defaultValue: state?.statisticsCO2DateTo,
        disabled: state?.statisticsCO2DateFrom ? false : true,
        label: String(i18next.t(`form:to`)),
        minDate: state?.statisticsCO2DateFrom,
      },
      {
        width: 40,
        type: "custom",
        name: "statisticsCO2DateSpace",
        element: <div></div>,
      },
      // Compare with
      {
        width: 20,
        type: "select",
        size: "small",
        name: "statisticsCO2DateCompare",
        defaultValue: state?.statisticsCO2DateCompare,
        currentValue: state?.statisticsCO2DateCompare,
        label: i18next.t("navigation:_statistics_co2_compare") as string,
        disabled: state?.statisticsCO2DateTo ? false : true,
        options: [
          {
            key: 1,
            text: i18next.t("navigation:lastMonth"),
          },
          {
            key: 6,
            text: i18next.t("navigation:last6Months"),
          },
          {
            key: 12,
            text: i18next.t("navigation:last12Months"),
          },
        ],
      }
    );

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Linkable value
  const renderLink = (props: GridRenderCellParams) => {
    return (
      <div>
        <Link
          className="cursor-pointer"
          onClick={() => {
            navigate(
              AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                "?vehicleID=" +
                props.row.vehicleID
            );
          }}
        >
          {props.value}
        </Link>
      </div>
    );
  };
  // Render Trend
  const renderTrend = (props: GridRenderCellParams) => {
    const sign = Math.sign(props.row.trend);

    switch (sign) {
      case 1:
        return <NorthEastIcon style={{ color: "red" }} />;
      case -1:
        return <SouthEastIcon style={{ color: "green" }} />;
      default:
        return <RemoveIcon style={{ color: "grey" }} />;
    }
  };

  // Columns
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "plate",
      headerName: i18next.t("form:plate"),
      renderCell: renderLink,
    },
    {
      field: "brand",
      headerName: i18next.t("form:brand"),
    },
    {
      field: "activeSince",
      headerName: i18next.t("navigation:_statistics_co2_active_since"),
    },
    {
      field: "activeUntil",
      headerName: i18next.t("navigation:_statistics_co2_active_until"),
    },
    {
      field: "fuelType",
      headerName: i18next.t("navigation:_statistics_co2_fuel"),
    },
    {
      field: "amount",
      headerName: i18next.t("navigation:_statistics_co2_amount"),
      type: "number",
    },
    {
      field: "co2_real",
      headerName: i18next.t("navigation:_statistics_co2_produced"),
      type: "number"
    },
    {
      field: "co2_projected",
      headerName: i18next.t("navigation:_statistics_co2_projected"),
      type: "number"
    },
    {
      field: "trend",
      headerName: i18next.t("navigation:_statistics_co2_trend"),
      renderCell: renderTrend,
    },
  ];

  // Calculate co2 for the chart
  const calcTotalCO2 = () => {
    const sumTotalCO2 = (
      responseData
        .map((x: any) => {
          return x.totalCO2g;
        })
        .reduce((partialSum, a) => partialSum + a, 0) / 1000
    );
    const sumAvgCO2 = (
      responseData
        .map((x: any) => {
          return x.expectedTotalCO2;
        })
        .reduce((partialSum, a) => partialSum + a, 0) / 1000
    );

    setTotalCO2Emitted([sumTotalCO2.toFixed(2) || 0, sumAvgCO2.toFixed(2) || 0]);
  };

  // Calculate the ecologic driver
  const ecologicDriverCO2 = () => {
    let maxEcologicDriver = {
      driverID: 0,
      driverDisplayName: "",
      totalCO2g: 0,
      trendCO2g: 0,
      trendCO2Percentage: 0,
    };
    let maxTrend = 0;

    let minEcologicDriver = {
      driverID: 0,
      driverDisplayName: "",
      totalCO2g: 0,
      trendCO2g: 0,
      trendCO2Percentage: 0,
    };
    let minTrend = 0;

    // Find min and max ecologic driver
    rowsData.forEach((x: any) => {
      if (x.trendCO2Percentage > maxTrend) {
        maxEcologicDriver.driverID = x.driverID;
        maxEcologicDriver.driverDisplayName = x.driverDisplayName;
        maxEcologicDriver.totalCO2g = x.totalCO2g;
        maxEcologicDriver.trendCO2g = x.trendCO2g;
        maxEcologicDriver.trendCO2Percentage = x.trendCO2Percentage;

        maxTrend = x.trendCO2Percentage;
      }

      if (x.trendCO2Percentage < minTrend) {
        minEcologicDriver.driverID = x.driverID;
        minEcologicDriver.driverDisplayName = x.driverDisplayName;
        minEcologicDriver.totalCO2g = x.totalCO2g;
        minEcologicDriver.trendCO2g = x.trendCO2g;
        minEcologicDriver.trendCO2Percentage = x.trendCO2Percentage;

        minTrend = x.trendCO2Percentage;
      }
    });

    // Set min and max ecologic driver
    setEcologicDriver([minEcologicDriver, maxEcologicDriver]);
  };

  /* USE EFFECT HOOK */
  // First render
  useEffect(() => {
    if (
      state?.statisticsCO2DateFrom &&
      state?.statisticsCO2DateTo &&
      state?.statisticsCO2DateCompare
    ) {
      setRowsData(responseData);

      calcTotalCO2();

      ecologicDriverCO2();

      // Rows
      setRowsDef(
        rowsData.map((x: any, i: number) => {
          return {
            id: i,
            vehicleID: x.vehicleID,
            plate: formatPlate(x.vehicleLicensePlate),
            brand: x.brandDescription,
            activeSince: displayUTC0_ISODate(x.startDT, true),
            activeUntil: displayUTC0_ISODate(x.endDT, true),
            fuelType: x.fuelTypeDescription,
            amount: numberToLocalDecoder(x.totalVolume) + " " + (x.measurementUnitName || ""),
            co2_real: numberToLocalDecoder(x.totalCO2g / 1000) + " Kg",
            co2_projected: numberToLocalDecoder(x.expectedTotalCO2 / 1000) + " Kg",
            trend: x.trendCO2g,
          };
        })
      );
    }
  }, [state, loadingCo2Emissions]);

  /* RETURN */
  return (
    <>
      {!loadingCo2Emissions && (
        <div>
          <div className="display-flex-horizontal-space-between-start CO2Emission__selectDate full-height">
            <MultiForm
              suppressLayout
              classNameForm="multi-form-flex"
              classNameFormGroup="full-width"
              suppressSubmit={true}
              formId={"FormCO2DatePickerRange"}
              formTitle={"FormCO2DatePickerRange"}
              inputs={multiformInputs()}
              onChange={(data: any) => {
                delete data["statisticsCO2DateSpace"];

                setState(data);

                if (
                  data.statisticsCO2DateFrom &&
                  data.statisticsCO2DateTo &&
                  data.statisticsCO2DateCompare
                ) {
                  loadStatisticsCo2Emissions(
                    data.statisticsCO2DateFrom,
                    data.statisticsCO2DateTo,
                    moment(new Date())
                      .subtract(data.statisticsCO2DateCompare, "months")
                      .format("YYYY-MM-DD"),
                    moment(new Date())
                      .subtract(1, "days")
                      .format("YYYY-MM-DD")
                  );
                }
              }}
            />
          </div>

          {rowsData.length === 0 ? (
            <Alert severity="info">{i18next.t("message:nodatatodisplay")}</Alert>
          ) : (
            <div>
              <div className="CO2Emission__box full-height">
                <CO2Emission__StatisticsPage_Emitted
                  co2Emitted={totalCO2Emitted}
                  co2VehiclesEmitted={rowsData.length}
                />

                <CO2Emission__StatisticsPage_EcologicDriver
                  ecologicDriver={ecologicDriver}
                />
              </div>

              <Box className="CO2Emission__data">
                <DataGridWrap headers={columnsDef} rows={rowsDef} />
              </Box>
            </div>
          )}
        </div>
      )}

      <LoaderBackdrop loading={loadingCo2Emissions} />
    </>
  );
};

export default CO2Emission__StatisticsPage_Data;
