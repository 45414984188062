import i18next from "i18next";

import "./NotAuthorizePage.scss";

const NotAuthorizePage = () => {
  /* RETURN */
  return (
    <div className="not-authorize-wrap">
      <div className="not-authorize-center">
        <div className="not-authorize-title">
          {i18next.t("navigation:titleNotAuthorizePage")}
        </div>
        <div className="not-authorize-number">403</div>
        <div className="not-authorize-message">
          {i18next.t("navigation:subtitleNotAuthorizePage")}
        </div>
      </div>
    </div>
  );
};

export default NotAuthorizePage;
