import "./RegistrationPage.scss";

import StepNewAccount_Parent from "../../Components/StepNewAccount/StepNewAccount_Parent";

const RegistrationPage = () => {
  /* RETURN */
  return (
    <div className="RegistrationPage--container">
      <StepNewAccount_Parent />
    </div>
  );
};

export default RegistrationPage;
