import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  BuyCallServicePayload,
  DecreasePaymentPayload,
  IAccountCreditDto,
  ICreatePaypalPLanPayload,
  InsertPaymentPayload,
  InsertSubscriptionPaypalSubscriptionPayload,
  IPaymentInsert,
  IPaymentUpdate,
  IUpdateAccountCreditPayload,
} from "../../Models/IPayment";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const PaymentController = {
  GetPayPalToken: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_PAYPAL_TOKEN_GET;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetPaymentType: async (
    paymentTypeID?: number | null,
    subscriptionDurationID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_TYPE_GET;
    let parameters: string[] = [];
    if (paymentTypeID) {
      parameters.push(`paymentTypeID=${paymentTypeID}`);
    }
    if (subscriptionDurationID) {
      parameters.push(`subscriptionDurationID=${subscriptionDurationID}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetPayment: async (
    paymentID: number,
    subscriptionID: number,
    paymentTypeID: number,
    pending: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_GET;
    let parameters: string[] = [];
    if (paymentID) {
      parameters.push(`paymentID=${paymentID}`);
    }
    if (subscriptionID) {
      parameters.push(`subscriptionID=${subscriptionID}`);
    }
    if (paymentTypeID) {
      parameters.push(`paymentTypeID=${paymentTypeID}`);
    }
    if (pending !== null) {
      parameters.push(`pending=${pending}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertPayment: async (
    body: IPaymentInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdatePayment: async (
    body: IPaymentUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  EvaluatePayment: async (
    body: ICreatePaypalPLanPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_EVALUATE;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CreatePaypalPlan: async (
    body: ICreatePaypalPLanPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_PAYPAL_CREATE_PLAN;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  PaypalInsertSubscriptionFromPaypal: async (
    body: InsertSubscriptionPaypalSubscriptionPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_INSERT_SUBSCRIPTION_FROM_PAYPAL;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  IncresePlan: async (
    body: InsertPaymentPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_INCREASE_PLAN;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DecreasePlan: async (
    body: DecreasePaymentPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_DECREASE_PLAN;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CancelSubscription: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_CANCEL_SUBSCRIPTION;
    request.method = "PUT";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  BuySinglePurchase: async (
    body: BuyCallServicePayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENR_SINGLE_PAYMENT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  InsertPlan: async (
    body: InsertPaymentPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_INSERT_PLAN;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  GetAccountCredit: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_GET_CREDITS;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UpdateAccountCredit: async (
    body: IUpdateAccountCreditPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_UPDATE_CREDIT;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  CreateSubscription: async (
    body: InsertPaymentPayload,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_CREATE_SUBSCRIPTION;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetPaymentNumPending: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.PAYMENT_GET_NUM_PENDING;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetBillingLastInfo: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.GET_BILLING_LAST_INFO;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  GetPurchase: async (
    paymentID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7238";
    request.url = ApiEndPoints.GET_PURCHASE + "?paymentID=" + paymentID;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default PaymentController;
