/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
/* STYLE */
import "./AdminPage__bills.scss";

/* LIBRARY */
import i18next from "i18next";
import TaskIcon from "@mui/icons-material/Task";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { JSONPrint } from "../../../../../Utils/Decoder";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { IFleetPlanUpdateBody } from "../../../../../Models/IFleetPlanUpdateBody";
import { IFleetPlanSetBody } from "../../../../../Models/IFleetPlanSetBody";
import { IInvoice } from "../../../../../Models/IInvoice";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { Link } from "react-router-dom";
import {
  downloadWithName,
  getBlobContainer,
  getBlobDesc,
} from "../../../../../Utils/FileToBase";

interface IInvoiceType {
  invoiceTypeID: number;
  invoiceTypeName: string;
}

/* COMPONENT */
const AdminPage__bills = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const [invoiceTypes, setInvoiceTypes] = useState<IInvoiceType[]>([]);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [targetInvoice, setTargetInvoice] = useState<IInvoice | null>(null);
  const [targetInvoiceType, setTargetInvoiceType] = useState<number | null>(
    null
  );

  const loadInvoices = () => {
    setLoading(true);
    ApiService.InvoiceController.InvoiceListGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setInvoices(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const loadInvoicesType = () => {
    setLoading(true);
    ApiService.InvoiceController.InvoiceTypesGet(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setInvoiceTypes(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const deleteInvoice = () => {
    setProcessing(true);
    ApiService.InvoiceController.InvoiceDelete(
      targetInvoice?.invoiceID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:invoice_deleted"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const uploadInvoiceFiles = () => {
    setProcessing(true);
    ApiService.InvoiceController.InvoiceUpload(
      uploadFiles,
      targetInvoiceType,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:invoice_upload_done"), "success");
          DismissModal("UploadInvoicesModal");
          loadInvoices();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const uploadInvoiceFilesZip = () => {
    setProcessing(true);
    ApiService.InvoiceController.InvoiceUploadZip(
      uploadFiles[0],
      targetInvoiceType,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:invoice_upload_done"), "success");
          DismissModal("UploadInvoicesModal");
          loadInvoices();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const downloadFile = (blobUri: string) => {
    ApiService.UploadController.GetSASString(
      getBlobContainer(blobUri),
      blobUri,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let sas: string = response.payload.tokenSAS;
          if (sas) {
            downloadWithName(sas, getBlobDesc(blobUri));
          }
        } else {
          ToastMessage(i18next.t("error:could_not_download"), "error");
        }
      }
    );
  };

  useEffect(() => {
    loadInvoicesType();
    loadInvoices();
  }, []);

  const renderDelete = (props: GridRenderCellParams) => {
    return (
      <div style={props.row.stored ? { opacity: "0.2" } : {}}>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            setTargetInvoice(props.row);
            SummonModal("DeleteConfirmModal");
          }}
        >
          <Tooltip
            title={i18next.t("navigation:_delete_invoice")}
            placement="top-start"
          >
            <DeleteOutlineIcon className="icon-table" />
          </Tooltip>
        </Button>
      </div>
    );
  };

  const renderLink1 = (props: GridRenderCellParams) => {
    return (
      <Button
        startIcon={<DownloadIcon />}
        onClick={() => {
          downloadFile(props.row.xmlURL);
        }}
      >
        {"XML"}
      </Button>
    );
  };
  const renderLink2 = (props: GridRenderCellParams) => {
    return (
      <Button
        startIcon={<DownloadIcon />}
        onClick={() => {
          downloadFile(props.row.pdfURL);
        }}
      >
        {"PDF"}
      </Button>
    );
  };

  const COLUMNS = [
    {
      field: "invoiceNumber",
      headerName: i18next.t("navigation:_invoice_delete") as string,
      renderCell: renderDelete,
      type: 'custom'
    },
    {
      field: "invoiceNumber",
      headerName: i18next.t("navigation:_invoice_number") as string,
      type: "number"
    },
    {
      field: "InvoiceTypeName_",
      headerName: i18next.t("navigation:_invoice_type_name") as string,
    },
    {
      field: "invoiceDT_",
      headerName: i18next.t("navigation:_invoice_date") as string,
    },
    {
      field: "clientFiscalCode",
      headerName: i18next.t("navigation:_invoice_client_f_code") as string,
    },
    {
      field: "clientVATNumber",
      headerName: i18next.t("navigation:_invoice_client_vat_code") as string,
      type: "number"
    },
    {
      field: "xmlURL",
      headerName: i18next.t("navigation:_invoice_xml") as string,
      renderCell: renderLink1,
    },
    {
      field: "pdfURL",
      headerName: i18next.t("navigation:_invoice_PDF") as string,
      renderCell: renderLink2,
    },
  ];

  const validFiles = () => {
    if (uploadFiles.length > 0) {
      if (uploadFiles.length === 1 && uploadFiles[0].name.endsWith("zip")) {
        return true;
      }

      // no zip in the multiple upload
      return (
        uploadFiles.filter((x: File) => x.name.endsWith("zip")).length === 0
      );
    }

    return false;
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_invoice")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setTargetInvoice(null);
                SummonModal("UploadInvoicesModal");
              }}
            >
              {i18next.t("navigation:upload_invoices")}
            </Button>
          </ButtonGroup>
        </div>
        <DataGridWrap
          headers={COLUMNS}
          rows={invoices.map((x: IInvoice) => {
            let type: IInvoiceType | undefined = invoiceTypes.find(
              (y: IInvoiceType) => y.invoiceTypeID === x.invoiceTypeID
            );
            let d: any = { ...x };
            d["invoiceDT_"] = displayUTC0_ISODate(x.invoiceDT, true);
            d["InvoiceTypeName_"] = type ? type.invoiceTypeName : "-";
            return d;
          })}
        />
      </div>
      <SmartModal
        onBeforeSummon={() => {
          setTargetInvoiceType(null);
        }}
        modalUniqueId="UploadInvoicesModal"
        title={i18next.t("navigation:upload_invoices")}
        modalInnerComponent={
          <div>
            <div>
              <Alert severity="info">
                {i18next.t("navigation:upload_invoices_info")}
              </Alert>
              <div style={{ padding: "0.5em 0" }}>
                <p>{i18next.t("navigation:_select_invoice_type_name")}</p>
                <Select
                  fullWidth
                  onChange={(e: any) => {
                    if (e) {
                      setTargetInvoiceType(e.target.value);
                    }
                  }}
                >
                  {invoiceTypes.map((x: IInvoiceType, i: number) => {
                    return (
                      <MenuItem key={i} value={x.invoiceTypeID}>
                        {x.invoiceTypeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div
                style={{
                  margin: "0 auto",
                }}
              >
                <div
                  className="select-model-wrapper"
                  onClick={() => {
                    document.getElementById("file-inp")?.click();
                  }}
                >
                  <input
                    onChange={(e: any) => {
                      setUploadFiles(Array.from(e.target.files));
                    }}
                    id="file-inp"
                    type="file"
                    hidden
                    multiple
                    accept=".xml, .zip"
                  />
                  <div>
                    <div className="upload-icon-wrapper">
                      <TaskIcon className="text-color-hint StepDashboardLoadTemplate__icon StepDashboardLoadTemplate__icon--size" />
                    </div>

                    <div className="title-wrapper">
                      <strong>Files:</strong>
                      {uploadFiles.map((x: File, i: number) => {
                        return <div key={i}>{x.name}</div>;
                      })}
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <div className="accepted-file-wrapper">
                        {i18next.t("navigation:_admin_upload_invoices")}
                      </div>
                      <div>.xml, .zip</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        buttons={[
          {
            disabled: !validFiles() || targetInvoiceType === null,
            text: i18next.t("navigation:upload"),
            onClick: () => {
              // zip
              if (
                uploadFiles.length === 1 &&
                uploadFiles[0].name.endsWith("zip")
              ) {
                uploadInvoiceFilesZip();
                return;
              }

              // many
              if (uploadFiles.length > 0) {
                uploadInvoiceFiles();
                return;
              }
            },
          },
        ]}
      />
      <SmartModal
        title={
          i18next.t("navigation:_delete_invoice") +
          " " +
          targetInvoice?.invoiceNumber +
          "?"
        }
        modalInnerComponent={
          <div>
            <div>{i18next.t("navigation:_delete_invoice_confirm")}</div>
          </div>
        }
        onAccept={() => {
          deleteInvoice();
        }}
        onReject={() => {
          DismissModal("DeleteConfirmModal");
        }}
        modalUniqueId="DeleteConfirmModal"
      />
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default AdminPage__bills;
