import "./ResumeIconLine.scss";
import NoCrashRoundedIcon from "@mui/icons-material/NoCrashRounded";
import CarRentalIcon from "@mui/icons-material/CarRental";
import BadgeIcon from "@mui/icons-material/Badge";
import InfoIcon from "@mui/icons-material/Info";
import CarCrashIcon from '@mui/icons-material/CarCrash';
import i18next from "i18next";

export interface IResumeIconLineProps {
  title: string;
  subtitle: string;
  price: string;
  warning?: string;
  icon: "check-car" | "rental-car" | "badge" | "extra-car";
}

const ResumeIconLine = (props: IResumeIconLineProps) => {
  return (
    <div className="resume-icon-line-Summary-box">
      <div className="resume-icon-line-Summary-content">
        {props.icon === "check-car" && (
          <NoCrashRoundedIcon
            className="resume-icon-line-Summary-icon"
            style={{ background: "var(--default-color-title-hint)" }}
          />
        )}
        {props.icon === "rental-car" && (
          <CarRentalIcon
            className="resume-icon-line-Summary-icon"
            style={{ background: "var(--default-color-title)" }}
          />
        )}
        {props.icon === "badge" && (
          <BadgeIcon
            className="resume-icon-line-Summary-icon"
            style={{ background: "var(--default-color-title)" }}
          />
        )}
        {props.icon === "extra-car" && (
          <CarCrashIcon
            className="resume-icon-line-Summary-icon"
            style={{ background: "var(--default-color-title)" }}
          />
        )}
        <div className="resume-icon-line-Summary-text">
          <span className="resume-icon-line-font-bold">{props.title}</span>
          <span className="resume-icon-line-font-light">{props.subtitle}</span>
          {props.warning && (
            <span className="resume-icon-line-font-warning">
              <InfoIcon />
              <strong>{i18next.t("message:warning")}:</strong>
              <div>{props.warning}</div>
            </span>
          )}
        </div>
      </div>
      <div className="resume-icon-line-Summary-cost">
        <span className="resume-icon-line-font-bold">{props.price} €</span>
      </div>
    </div>
  );
};

export default ResumeIconLine;
