import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Button, TextField } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import "./StepDashboardImportComplete.scss";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { JSONPrint } from "../../Utils/Decoder";
import { Api } from "@mui/icons-material";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ITemplateErrorVoice, ITemplateErrors } from "../../Models/ITemplate";

const StepDashboardImportComplete = () => {
  const navigate = useNavigate();
  const templateGenericFormData = useSelector(
    (state: GlobalState) => state.generic.templateGenericWorkingData
  );

  const [warnings, setWarnings] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingBase, setProcessingBase] = useState<boolean>(false);

  const uploadEntityTemplate = (
    onlyErrors: boolean,
    templateTypeID: number, // volendo fisso a 1 per rifornimenti e 2 per telepass
    templateID: number,
    decimalSeparator: string,
    startingRow: number,
    dateFormat: string,
    timeFormat: string,
    file: File
  ) => {
    if (onlyErrors) {
      setProcessingBase(true);
    } else {
      setProcessing(true);
    }
    ApiService.TemplateController.TemplateUploadFromExcel(
      file,
      onlyErrors,
      templateTypeID,
      templateID,
      decimalSeparator,
      startingRow,
      dateFormat,
      timeFormat,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (onlyErrors) {
            let result: ITemplateErrors = response.payload;
            let errors: string[] = [];
            let warnings: string[] = [];
            for (let i = 0; i < result.mappingErrors.length; i++) {
              let err: ITemplateErrorVoice = result.mappingErrors[i];
              let errText = `${i18next.t("error:" + err.title)}: ${err.value} - ${i18next.t(
                "message:row"
              )} ${err.row}`;
              if (err.blockingError) {
                errors.push(errText);
              } else {
                warnings.push(errText);
              }
            }
            setWarnings(warnings);
            setErrors(errors);
          } else {
            leavePage();
          }
          ToastMessage(
            i18next.t("message:template_validation_completed"),
            "success"
          );
        } else {
          ToastMessage(i18next.t("error:template_validation_error"), "error");
          setErrors([i18next.t("error:template_validation_error_2")]);
        }
        if (onlyErrors) {
          setProcessingBase(false);
        } else {
          setProcessing(false);
        }
      }
    );
  };

  const pushData = (onlyErrors: boolean) => {
    if (templateGenericFormData && templateGenericFormData.formData) {
      let tmpId =
        templateGenericFormData.formData?.templateID ??
        templateGenericFormData.template?.templateID;
      uploadEntityTemplate(
        onlyErrors,
        templateGenericFormData.templateTypeID,
        tmpId,
        templateGenericFormData.formData?.separator !== "NONE" ? templateGenericFormData.formData?.separator : "",
        +templateGenericFormData.formData?.readFromRow,
        templateGenericFormData.formData?.dateFormat,
        templateGenericFormData.formData?.timeFormat,
        templateGenericFormData.file
      );
    } else {
      setErrors([
        i18next.t("error:impossible_to_complete_import_data_is_missing"),
      ]);
    }
  };

  useEffect(() => {
    pushData(true);
  }, []);

  const leavePage = () => {
    if (templateGenericFormData) {
      if (templateGenericFormData.templateTypeID === 1) {
        navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.VIEW_SUPPLIES);
      } else {
        navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.VIEW_TELEPASS_USAGE);
      }
    }
  };

  return (
    <div>
      {!processingBase && (
        <div className="import-complete-wrapper">
          <div className="status-wrapper">
            {errors.length === 0 && (
              <>
                <CheckIcon style={{ color: "green", marginRight: "0.5em" }} />
                <strong style={{ fontSize: "1.4em" }}>
                  {i18next.t("navigation:_forniture_page_header_stepper3")}
                </strong>
              </>
            )}
            {errors.length > 0 && (
              <>
                <CloseIcon style={{ color: "red", marginRight: "0.5em" }} />
                <strong style={{ fontSize: "1.4em" }}>
                  {i18next.t(
                    "navigation:_forniture_page_header_stepper3_error"
                  )}
                </strong>
              </>
            )}
          </div>
          {(errors.length > 0 || warnings.length > 0) && (
            <div className="button-wrapper">
              <h3>
                {i18next.t(
                  "navigation:_forniture_page_header_stepper3_subtitle"
                )}
              </h3>
            </div>
          )}
          {(errors.length > 0 || warnings.length > 0) && (
            <div>
              <div className="error-warning-wrapper">
                {warnings.map((el: string) => {
                  return (
                    <div className="status-wrapper">
                      <ReportProblemIcon
                        style={{ color: "#a7a707", marginRight: "0.5em" }}
                      />
                      <div>{el}</div>
                    </div>
                  );
                })}
              </div>
              <div className="error-warning-wrapper">
                {errors.map((el: string) => {
                  return (
                    <div className="status-wrapper">
                      <CloseIcon
                        style={{ color: "#ab3d3d", marginRight: "0.5em" }}
                      />
                      <div>{el}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="button-wrapper">
            {(errors.length > 0 || warnings.length > 0) && (
              <div className="button-wrapper">
                <div className="button-content">
                  <Button variant="outlined">
                    {i18next.t(
                      "navigation:_forniture_page_header_stepper3_button2"
                    )}
                  </Button>
                </div>
                <div className="button-content">
                  <Button
                    variant="contained"
                    onClick={() => {
                      leavePage();
                    }}
                  >
                    {i18next.t(
                      "navigation:_forniture_page_header_stepper3_button3_conclude"
                    )}
                  </Button>
                </div>
              </div>
            )}
            {errors.length === 0 && (
              <div className="button-content">
                <Button
                  variant="contained"
                  onClick={() => {
                    pushData(false);
                  }}
                >
                  {i18next.t(
                    "navigation:_forniture_page_header_stepper3_button3"
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <LoaderBackdrop loading={processing || processingBase} />
    </div>
  );
};

export default StepDashboardImportComplete;
