/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* STYLE */
import "./VehicleRequestsPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* CONSTS */
import AppRoutes from "../../../../../Costants/AppRoutes";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import { renderBrandImageGrid } from "../../VehiclesPage/Status__VehiclesPage/Status__VehiclesPage_Data";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Badge, Box, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";

/* COMPONENT */
const VehicleRequestsPage = () => {
  /* STATES */
  const [list, setList] = useState<any>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);

  /* NAVIGATE */
  const navigate = useNavigate();

  /* API */
  // Get vehicles request list
  const GET_CarConfigurator_Vehicle_Request = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_CarConfigurator_Vehicle_Request(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    GET_CarConfigurator_Vehicle_Request();
  }, []);

  /* FUNCTIONS */
  // Actions
  const renderActions = (props: GridRenderCellParams) => {
    const color: string = props.row.new
      ? "#02aa3f"
      : props.row.actionRequired
      ? "#d32f2f"
      : "";

    return (
      <div className="display-flex-vertical-center VehicleRequestsPage-badge">
        <Badge
          color="primary"
          variant="dot"
          invisible={!props.row.new && !props.row.actionRequired}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: color,
            },
          }}
        />

        <IconButton>
          <VisibilityIcon
            className="icon-table"
            onClick={() =>
              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___CONFIGURATION_ROUTE__REQUESTS_SUMMARY +
                  "?vehicle=" +
                  props.row.driverGroupVehiclesID
              )
            }
          />
        </IconButton>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "actions",
      headerName: i18next.t("navigation:actions"),
      renderCell: renderActions,
      type: 'custom'
    },
    {
      field: "brandImage",
      headerName: i18next.t("form:service_logo"),
      renderCell: renderBrandImageGrid,
      type: 'custom'
    },
    {
      field: "vehicleDetails",
      headerName: i18next.t("form:vehicleDetails"),
    },
    {
      field: "applicant",
      headerName: i18next.t("form:applicant"),
    },
    {
      field: "vehicleGroup",
      headerName: i18next.t("form:vehicleGroup"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,

      new: list[i].new,
      actionRequired: list[i].actionRequired,
      driverGroupVehiclesID: list[i].driverGroupVehiclesRequestID,
      brandImage: list[i].brand,
      vehicleDetails: list[i].vehicleDetails,
      applicant: list[i].applicant,
      vehicleGroup: list[i].vehicleGroup,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_configure_vechicles_request")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
      </div>

      <LoaderBackdrop loading={loadingGet} />
    </div>
  );
};

export default VehicleRequestsPage;
