import { useEffect, useState } from "react";
import i18next from "i18next";

import "./StepOrders.scss";

import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import { ToastMessage } from "../../Utils/Toastify";
import FormCompanyUser from "../StepCompanyUsers/FormCompanyUser";
import FormSupplier from "../StepSupplier/FormSupplier";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { IUser } from "../../Models/IUser";

import { ISupplierGetSideList } from "../../Models/ISupplier";
import { ICompanyGet } from "../../Models/ICompany";
import {
  IOrderGetType,
  IOrderInsertData,
  IOrderUpdateData,
} from "../../Models/IOrder";
import {
  IVehicleDataBrands,
  IVehicleDataModels,
  IVehicleDataFitting,
} from "../../Models/IVehicles";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";
import { IDriverGet } from "../../Models/IDriver";
import { IContractGetProperty, IContractGetType } from "../../Models/IContract";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import DeadlineMainHandler from "../DeadlineMainHandler/DeadlineMainHandler";
import FormAssignedDriver from "../StepAssignedDrivers/FormAssignedDriver";

export interface IFormOrders {
  nextPage?: number;
  loggedUser: IUser | undefined;
  companyUser: ICompanyGet | undefined;
  data: any;
  orderPropertyList?: IContractGetProperty[];
  modalId?: string;
  loadOrderSideList?: () => void;
  doRefreshForm?: () => void;
}

const FormOrders = (props: IFormOrders) => {
  /* STATES */
  // State for data container
  const [state, setState] = useState<any>(props.data);

  // State for supplier list select
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);

  // State for company list select
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);

  // State for order type (single/multiple)
  const [orderTypeList, setOrderTypeList] = useState<IOrderGetType[]>([]);

  // State for order contract type (proprietà, finanziamento, etc.)
  const [orderContractTypeList, setOrderContractTypeList] = useState<
    IContractGetType[]
  >([]);

  // States for veichles informations
  const [brandsList, setBrandsList] = useState<IVehicleDataBrands[]>([]);
  const [modelList, setModelsList] = useState<IVehicleDataModels[]>([]);
  const [fittingList, setFittingsList] = useState<IVehicleDataFitting[]>([]);

  // State for spinning and loading the process
  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);
  const [processingApiCompany, setProcessingApiCompany] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingApiContract, setProcessingApiContract] =
    useState<boolean>(false);
  const [processingApiType, setProcessingApiType] = useState<boolean>(false);
  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] =
    useState<boolean>(false);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [loadingPropertyList, setLoadingPropertyList] =
    useState<boolean>(false);
  const [orderPropertyList, setOrderPropertyList] = useState<
    IContractGetProperty[]
  >(props.orderPropertyList ?? []);
  
  const [supplierIDValue, setSupplierIDValue] = useState<number | undefined>(0);

  /* APIs */
  /* GET */
  const loadDriverList = () => {
    if (props.loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDriverList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingDriverList(false);
        }
      );
    }
  };

  // API load order type (single/multiple)
  const loadOrderType = () => {
    setProcessingApiType(true);
    ApiService.OrderController.OrderGetType(null, (response: IAPIResponse) => {
      if (response.error === null) {
        setOrderTypeList(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }
      setProcessingApiType(false);
    });
  };

  // API to load the contract type (proprietà, finanziamento, etc.)
  const loadOrderContractType = () => {
    setProcessingApiContract(true);
    ApiService.ContractController.ContractGetContractType(
      true,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setOrderContractTypeList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessingApiContract(false);
      }
    );
  };

  // API to load the property list
  const loadOrderPropertyList = (contractTypeID: number) => {
    if (contractTypeID) {
      setLoadingPropertyList(true);
      ApiService.ContractController.ContractGetPropertyList(
        true,
        false,
        true,
        contractTypeID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setOrderPropertyList(response.payload);
            multiformInputs();
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPropertyList(false);
        }
      );
    }
  };

  const loadSupplierList = () => {
    if (props.loggedUser) {
      setLoadingSupplierList(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  // API to load company select list
  const loadCompanyList = () => {
    if (props.loggedUser) {
      setProcessingApiCompany(true);
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessingApiCompany(false);
        }
      );
    }
  };

  // API to load brands list
  const loadBrandsList = (inProduction: number) => {
    setLoadingBrandsList(true);

    ApiService.VehicleDataController.VehicleDataGetBrands(
      +inProduction === 1 ? true : false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBrandsList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingBrandsList(false);
      }
    );
  };

  // API to load model list
  const loadModelsList = (brandCode: string, inProduction: number) => {
    if (props.loggedUser && brandCode) {
      setLoadingModelsList(true);
      ApiService.VehicleDataController.VehicleDataGetModels(
        brandCode,
        +inProduction === 1 ? true : false,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setModelsList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingModelsList(false);
        }
      );
    }
  };

  // API to load fitting list
  const loadFittingList = (
    brandCode: string,
    modelCode: string,
    inProduction: number
  ) => {
    if (props.loggedUser && brandCode && modelCode) {
      setLoadingFittingsList(true);
      if (props.loggedUser) {
        ApiService.VehicleDataController.VehicleDataGetFittings(
          brandCode,
          modelCode,
          +inProduction === 1 ? true : false,
          (response: IAPIResponse) => {
            if (response.error === null) {
              setFittingsList(response.payload);
            } else {
              ToastMessage(response.error, "error");
            }
            setLoadingFittingsList(false);
          }
        );
      }
    }
  };

  /* INSERT */
  // Order
  const insertOrder = (data: IOrderInsertData) => {
    setProcessing(true);
    ApiService.OrderController.OrderInsertData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:orderSuccessfullAdded"), "success");
          setState({ ...data, vehicleOrderID: response.payload }); // Clean the state so inputs are empty
          if (props.loadOrderSideList) {
            props.loadOrderSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.modalId) {
            DismissModal(props.modalId);
          }
        } else if (response.raw.status === 400) {
          ToastMessage(i18next.t("error:REGISTRATION_USER_ERROR"), "error");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* UPDATE */
  // Order
  const updateOrder = (data: IOrderUpdateData) => {
    setProcessing(true);
    ApiService.OrderController.OrderUpdateData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:orderSuccessfullUpdated"), "success");
          if (props.loadOrderSideList) {
            props.loadOrderSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.modalId) {
            DismissModal(props.modalId);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* DELETE */
  // Order
  const deleteOrder = (data: any) => {
    setProcessing(true);
    if (data === null) {
      ToastMessage(i18next.t("message:orderNotDeleted"), "warning");
    }
    ApiService.OrderController.OrderDeleteData(
      data.vehicleOrderID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:orderSuccessfullDeleted"), "success");
          setState({}); // Clean the state so inputs are empty
          loadSupplierList();
          if (props.loadOrderSideList) {
            props.loadOrderSideList();
          }
          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  // /* FUNCTIONS */
  // // Convert the form value from state to API
  const fromStateToAPI = (state: any) => {
    // Var to alterate state cloning the original state
    let state_ = { ...state };
    // Var states as numbers
    state_["vehicleOrderTypeID"] = +state_["vehicleOrderTypeID"];
    state_["contractTypeID"] = +state_["contractTypeID"];
    // Array of object state for the vehicle order detalis
    state_["vehicleOrderDetails"] = [];

    for (let i = 0; i < state_.numberOfVehicles; i++) {
      state_["vehicleOrderDetails"].push({
        companyID:
          props.loggedUser?.accountTypeID === 2
            ? state_["companyID_" + i]
            : companyList[0].companyID,
        driverID: Number(state_["driverID_" + i]),
        reference: state_["reference_" + i],
        arrivalDT: state_["arrivalDT_" + i],
      });

      if (props.data?.vehicleOrderID && props.data?.vehicleOrderID > 0) {
        state_.vehicleOrderDetails[i]["vehicleOrderDetailID"] =
          state_["vehicleOrderDetailID_" + i];
      }
      delete state_["contractTypeProperties"];
      // Delete these states in the root
      delete state_["companyID_" + i];
      delete state_["companyName_" + i];
      delete state_["driverID_" + i];
      delete state_["driverName_" + i];
      delete state_["vehicleOrderDetailID_" + i];
      delete state_["reference_" + i];
      delete state_["arrivalDT_" + i];
    }
    // Array of object state for the vehicle order properties
    state_["vehicleOrderCTProperties"] = orderPropertyList.map(
      (x: IContractGetProperty) => {
        return {
          propertyID: x.propertyID,
          propertyValue: state_[x.propertyID].toString() ?? "",
        };
      }
    );

    // Delete unuseful states in root
    for (let i = 0; i < orderPropertyList.length; i++) {
      delete state_[orderPropertyList[i].propertyID];
    }

    delete state_["addSupplier"];
    delete state_["addDriver"];
    delete state_["addCompany"];
    delete state_["addRow"];
    delete state_["companyID"];
    delete state_["reference"];
    delete state_["companyBusinessName"];
    delete state_["driverName"];

    // Return the new state
    return state_;
  };

  // Function to get the fiscal code
  const getFiscalCodeForUser = () => {
    if (props.companyUser) {
      return props.companyUser.companyFiscalCode;
    }
    return null;
  };

  /* HOOK EFFECT */
  // Load the page elements
  useEffect(() => {
    loadOrderType(); // Load order type (single/multiple)
    loadOrderContractType(); // Load contract type (proprietà, finanziamento, etc.)
    loadSupplierList(); // Load suppliers list
    loadCompanyList(); // Load company list
    loadDriverList();
    setState({ ...state, ...{ vehicleOrderTypeID: 1, numberOfVehicles: 1 } });
  }, []);

  // Load the vehicle info
  useEffect(() => {
    setBrandsList([]);
    setModelsList([]);
    setFittingsList([]);

    if (state?.inProduction !== undefined && state?.inProduction !== null) {
      loadBrandsList(state?.inProduction);
    }
  }, [state?.inProduction]);

  useEffect(() => {
    if (state?.brandCode) {
      loadModelsList(state?.brandCode, state?.inProduction);
    }
  }, [state?.brandCode]);

  useEffect(() => {
    if (state?.brandCode && state?.modelCode) {
      loadFittingList(state?.brandCode, state?.modelCode, state?.inProduction);
    }
  }, [state?.brandCode, state?.modelCode]);

  useEffect(() => {
    if (state?.contractTypeID) {
      loadOrderPropertyList(state.contractTypeID);
      setSupplierIDValue(orderContractTypeList.find(x => x.contractTypeID === +state.contractTypeID)?.supplierTypeID);
    }
  }, [state?.contractTypeID]);

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    // Order Type (Single / Multiple)
    multiformInputs.push({
      width: 50,
      type: "select",
      name: "vehicleOrderTypeID",
      defaultValue: state?.vehicleOrderTypeID,
      required: true,
      label: String(i18next.t(`form:orderType`)),
      options: orderTypeList.map((orderType: IOrderGetType) => {
        return {
          key: orderType.vehicleOrderTypeID,
          text: orderType.vehicleOrderTypeName.toString(),
        };
      }),
    });

    // Common inputs
    multiformInputs.push(
      // Order number
      {
        width: 50,
        type: "text",
        name: "vehicleOrderNumber",
        defaultValue: state?.vehicleOrderNumber,
        required: true,
        inputProps: { 
          maxLength: 50 
        },
        label: `${i18next.t("form:orderNumber")}`,
      }
    );
    // Multiple order
    if (state?.vehicleOrderTypeID === 2) {
      // Number of vehicles
      multiformInputs.push({
        width: 50,
        type: "number",
        name: "numberOfVehicles",
        defaultValue: state?.numberOfVehicles,
        inputProps: { min: 1 },
        required: true,
        label: String(i18next.t(`form:orderNumberVehicles`)),
      });
    }

    multiformInputs.push(
      {
        type: "radio",
        name: "inProduction",
        defaultValue:
          state?.inProduction !== undefined && state?.inProduction !== null
            ? state.inProduction
              ? 1
              : 0
            : 1,
        width: 100,
        options: [
          {
            key: 1,
            text: i18next.t("form:vehicleInProdution"),
          },
          {
            key: 0,
            text: i18next.t("form:vehicleNotMoreInProdution"),
          },
        ],
      },
      // Order brand
      {
        width: 33.33,
        type: "select",
        name: "brandCode",
        defaultValue: state?.brandCode,
        required: false,
        label: `${i18next.t("form:orderBrand")}`,
        options: brandsList.map((item: IVehicleDataBrands) => {
          return {
            key: item.brandCode,
            text: item.brandDescription,
          };
        }),
      },
      // Order modal
      {
        width: 33.33,
        type: "select",
        name: "modelCode",
        disabled: state?.brandCode ? false : true,
        defaultValue: state?.modelCode,
        required: false,
        label: `${i18next.t("form:orderModel")}`,
        options: modelList.map((item: IVehicleDataModels) => {
          return {
            key: item.modelCode,
            text: item.modelDescription,
          };
        }),
      },
      // Order fitting
      {
        width: 33.33,
        type: "select",
        name: "fittingCode",
        disabled: state?.modelCode ? false : true,
        defaultValue: state?.fittingCode,
        required: false,
        label: `${i18next.t("form:orderEquipment")}`,
        options: fittingList.map((item: IVehicleDataFitting) => {
          return {
            key: item.fittingCode,
            text: item.fittingDescription,
          };
        }),
      },
      // Contract Type
      {
        width: 50,
        type: "select",
        name: "contractTypeID",
        defaultValue: state?.contractTypeID,
        required: true,
        label: String(i18next.t(`form:orderContractType`)),
        options: orderContractTypeList.map((contractType: IContractGetType) => {
          return {
            key: contractType.contractTypeID.toString(),
            text: contractType.contractTypeName.toString(),
          };
        }),
      }
    );

    orderPropertyList
      .filter((item: IContractGetProperty) => item.isDropdown)
      .map((item: IContractGetProperty) => {
        if (item.propertyID === 8) {
          multiformInputs.push(
            {
              type: "select",
              name: item.propertyID.toString(),
              required: item.mandatory,
              label: item.propertyClientName,
              ordinal: item.propertyIndex,
              defaultValue:
                state?.contractTypeProperties !== undefined
                ? state?.contractTypeProperties.find(
                    (x: IContractGetProperty) => x.propertyID === item.propertyID
                  )?.propertyValue
                : state?.[item.propertyID],
              width: 40,
              options: supplierList
                .filter(
                  (x: ISupplierGetSideList) =>
                    +x.supplierInfo.supplierTypeID === supplierIDValue
                )
                .map((item: ISupplierGetSideList) => {
                  return {
                    key: item.supplierInfo.supplierID?.toString(),
                    text: item.supplierInfo.supplierName?.toString(),
                  };
                }),
            },
            {
              width: 10,
              type: "custom",
              name: "addSupplier",
              element: (
                <div className="multi-form-button-add-record">
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: "55px" }}
                    onClick={() => {
                      SummonModal("supplierUserOrder-modal");
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              ),
            }
          );
        }
        if (item.propertyID === 2 || item.propertyID === 1) {
          multiformInputs.push(
            {
              type: "select",
              name: item.propertyID.toString(),
              required: item.mandatory,
              label: item.propertyClientName,
              ordinal: item.propertyIndex,
              defaultValue:
              state?.contractTypeProperties !== undefined
                ? state?.contractTypeProperties.find(
                    (x: IContractGetProperty) => x.propertyID === item.propertyID
                  )?.propertyValue
                : state?.[item.propertyID],
              width: 40,
              options: companyList.map((item: ICompanyGet) => {
                return {
                  key: item.companyID?.toString(),
                  text: item.companyBusinessName?.toString(),
                };
              }),
            },
            {
              width: 10,
              type: "custom",
              name: "addSupplier",
              element: (
                <div className="multi-form-button-add-record">
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: "55px" }}
                    onClick={() => {
                      SummonModal("companyUserOrder-modal");
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              ),
            }
          );
        }
      });

    // Based on type of contract show others input
    orderPropertyList.filter((item: IContractGetProperty) => !item.isDropdown).map((item: IContractGetProperty, i: number) => {
      let typeID = "text";
      switch (item.propertyTypeName) {
        case "decimal":
        case "integer":
          typeID = "number";
          break;
      }

      multiformInputs.push({
        type: typeID as any,
        name: item.propertyID.toString(),
        required: item.mandatory,
        label: item.propertyClientName,
        defaultValue:
          state?.contractTypeProperties !== undefined
          ? state?.contractTypeProperties.find(
              (x: IContractGetProperty) => x.propertyID === item.propertyID
            )?.propertyValue
          : state?.[item.propertyID],
        width: 50,
        inputAdornament: item.isMoney ? { adornament: "€" } : {},
      });
    });

    let adornamentVehicleOrder = "";
    if (state?.vehicleOrderNumber) {
      adornamentVehicleOrder = state?.vehicleOrderNumber + " - ";
    }
    for (let i = 0; i < state?.numberOfVehicles; i++) {
      let parsedDriverList = [];
      let disabledDriverSelect: boolean = false;
      if (props.loggedUser?.accountTypeID === 2) {
        parsedDriverList = driverList.filter(
          (driver: IDriverGet) =>
            driver.driverInfo.companyID ===
            parseInt(
              state?.vehicleOrder && state?.vehicleOrder[i]
                ? state?.vehicleOrder[i].companyID
                : state["companyID_" + i]
            )
        );
        disabledDriverSelect = !(state?.vehicleOrder && state?.vehicleOrder[i]
          ? state?.vehicleOrder[i].companyID
          : state["companyID_" + i]);
      } else {
        disabledDriverSelect = false;
        parsedDriverList = [...driverList];
      }
      multiformInputs.push(
        {
          type: "hidden",
          idcontainer: `vehicleOrder_${i}`,
          name: `vehicleOrderDetailID_${i}`,
          defaultValue: state?.vehicleOrderDetailID,
        },
        {
          width: 100,
          idcontainer: `vehicleOrder_${i}`,
          type: "custom",
          name: "",
          element: (
            <div className="divider-top">
              <Typography>
                <b>{`${i18next.t("generic:labelVehicles")} ${i + 1}`}</b>
              </Typography>
            </div>
          ),
        },
        // Order reference
        {
          idcontainer: `vehicleOrder_${i}`,
          width: 50,
          type: "text",
          name: `reference_${i}`,
          required: true,
          defaultValue:
            state?.vehicleOrder && state.vehicleOrder[i]
              ? state?.vehicleOrder[i].reference
              : state["reference_" + i],
          inputProps: { 
            maxLength: 50 
          },
          inputAdornament: {
            adornament: adornamentVehicleOrder,
            position: "start",
          },
          label: i18next.t("form:orderReference") as string,
        },
        props.loggedUser?.accountTypeID === 2
          ? // Company
            {
              width: 50,
              idcontainer: `vehicleOrder_${i}`,
              type: "select",
              name: `companyID_${i}`,
              defaultValue:
                state?.vehicleOrder && state?.vehicleOrder[i]
                  ? state?.vehicleOrder[i].companyID
                  : state["companyID_" + i],
              required: true,
              label: `${i18next.t("form:orderCompany")}`,
              options: companyList.map((companyItem: ICompanyGet) => {
                return {
                  key: companyItem.companyID,
                  text: companyItem.companyBusinessName.toString(),
                };
              }),
            }
          : // CF
            {
              width: 50,
              idcontainer: `vehicleOrder_${i}`,
              type: "text",
              disabled: true,
              name: "companyID",
              defaultValue: getFiscalCodeForUser(),
              required: true,
              label: i18next.t("form:taxIdCode").toString(),
            },
        // Driver
        {
          width: 40,
          idcontainer: `vehicleOrder_${i}`,
          type: "select",
          name: `driverID_${i}`,
          required: true,
          disabled: disabledDriverSelect,
          defaultValue:
            state?.vehicleOrder && state?.vehicleOrder[i]
              ? state?.vehicleOrder[i].driverID
              : state["driverID_" + i],
          label: String(i18next.t(`form:orderDriver`)),
          options: parsedDriverList.map((item: IDriverGet) => {
            let titleDriver = item.driverInfo.driverName;
            if (item.driverInfo?.driverSurname) {
              titleDriver += " " + item.driverInfo.driverSurname;
            }
            return {
              key: item.driverInfo.driverID.toString(),
              text: titleDriver.toString(),
            };
          }),
        },
        {
          width: 10,
          type: "custom",
          idcontainer: `vehicleOrder_${i}`,
          name: "addDriver",
          element: (
            <div className="multi-form-button-add-record">
              <Button
                variant="contained"
                onClick={() => {
                  SummonModal("assigned-driver-modal");
                }}
              >
                <AddIcon />
              </Button>
            </div>
          ),
        },
        // Order arrive date
        {
          width: 50,
          idcontainer: `vehicleOrder_${i}`,
          type: "date",
          name: `arrivalDT_${i}`,
          disablePast: true,
          className: "padding-bottom-small",
          required: true,
          defaultValue:
            state?.vehicleOrder && state.vehicleOrder[i]
              ? state?.vehicleOrder[i].arrivalDT
              : state["arrivalDT_" + i],
          label: String(i18next.t(`form:orderArriveDate`)),
        }
      );
    }

    // Return the full and complete Multiform
    return multiformInputs;
  };

  const loadData =
    loadingSupplierList ||
    processingApiCompany ||
    processingApiContract ||
    processingApiType ||
    loadingPropertyList;

  return (
    <div>
      {!loadData && (
        <MultiForm
          formId={"StepOrders"}
          suppressLayout
          formTitle={"StepOrders"}
          classNameForm="multi-form-flex"
          inputs={multiformInputs()}
          suppressSubmit={true}
          onChange={async (data: any) => {
            let parseData = { ...data };
            if (data.vehicleOrderTypeID === 1) {
              parseData["numberOfVehicles"] = 1;
            }
            setState({ ...state, ...parseData });
            setSupplierIDValue(orderContractTypeList.find(x => x.contractTypeID === +data.contractTypeID)?.supplierTypeID);
          }}
          onSubmit={() => {
            let state_ = fromStateToAPI(state);
            delete state_[""];
            state_["vehicleOrderInProduction"] = +state["inProduction"] === 1 ? true : false;
            if (state?.vehicleOrderID && state?.vehicleOrderID > 0) {
              state_["vehicleOrderID"] = state.vehicleOrderID;
              updateOrder(state_);
            } else {
              insertOrder(state_);
            }
          }}
        />
      )}
      <DeadlineMainHandler
        DeadlineFamily={"orders"}
        extraGetParameters={
          state?.vehicleOrderID
            ? {
                name: "vehicleOrderID",
                value: state?.vehicleOrderID,
              }
            : undefined
        }
      />

      <AttachementMainHandler
        attachmentFamily={"orders"}
        extraGetParametersVector={
          state?.vehicleOrderID
            ? [
                {
                  name: "vehicleOrderID",
                  value: state?.vehicleOrderID,
                },
              ]
            : undefined
        }
      />

      <div className="padding-small">
        <hr className="custom-stepper-line" />
        <p className="multi-form-paragraph">
          <span className="font-size-medium">
            {i18next.t("generic:tipOrder")}
          </span>
        </p>

        <div className="display-flex-end margin-vertical-medium">
          <Button
            color="error"
            sx={{ marginRight: "1em" }}
            disabled={state && !state?.vehicleOrderID}
            onClick={() => {
              SummonModal("StepOrder-modal");
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            {i18next.t("form:deleteOrder")}
          </Button>

          <Button
            variant="contained"
            disabled={processing}
            onClick={() => {
              triggerFormValidation("StepOrders");
            }}
            endIcon={<SaveIcon />}
          >
            {i18next.t("form:saveOrder")}
          </Button>

          <SmartModal
            modalUniqueId="StepOrder-modal"
            title={i18next.t("generic:titleRecordModal")}
            modalInnerComponent={
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("generic:descriptionRecordModal")
                    .replace(
                      "CURRENT_ITEM_LIST",
                      state?.supplierName + " - " + state?.vehicleOrderID
                    ),
                }}
              />
            }
            onAccept={() => {
              deleteOrder(state);
            }}
            onReject={() => {}}
          />
        </div>
      </div>
      <SmartModal
        modalUniqueId="supplierUserOrder-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={props.loggedUser}
            data={[]}
            forceSupplierTypeID={supplierIDValue}
            idModal="supplierUserOrder-modal"
            updateList={(data: ISupplierGetSideList) => {
              let supplierList_: ISupplierGetSideList[] = [...supplierList];
              supplierList_.push(data);
              setSupplierList(supplierList_);
            }}
          />
        }
      />

      <SmartModal
        modalUniqueId="companyUserOrder-modal"
        title={i18next.t("generic:titleAddCompany")}
        hideButtons={true}
        modalInnerComponent={
          <FormCompanyUser
            loggedUser={props.loggedUser}
            data={[]}
            companyUser={props.companyUser}
            idModal="companyUserOrder-modal"
          />
        }
      />

      <SmartModal
        modalUniqueId="assigned-driver-modal"
        title={i18next.t("generic:titleAddDriver")}
        hideButtons={true}
        modalInnerComponent={
          <FormAssignedDriver
            loggedUser={props.loggedUser}
            data={[]}
            companyUser={props.companyUser}
            idModal="assigned-driver-modal"
            updateList={(data: any) => loadDriverList()}
          />
        }
      />

      <LoaderBackdrop
        loading={
          processing ||
          processingApiCompany ||
          loadingSupplierList ||
          processingApiContract ||
          processingApiType ||
          loadingBrandsList ||
          loadingModelsList ||
          loadingFittingsList ||
          loadingDriverList ||
          loadingPropertyList
        }
      />
    </div>
  );
};

export default FormOrders;
