import i18next from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UrlTabbedView.scss";

export interface IUrlTabbedViewTab {
  path: string;
  label: string;
  element: JSX.Element;
}

export interface IUrlTabbedViewProps {
  tabs: IUrlTabbedViewTab[];
}

const UrlTabbedView = (props: IUrlTabbedViewProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let tab: IUrlTabbedViewTab | null = null;
    let index: number = 0;
    for (let i = 0; i < props.tabs.length; i++) {
      if (window.location.pathname.includes(props.tabs[i].path)) {
        if (!tab || (tab && tab.path.length < props.tabs[i].path.length)) {
          tab = props.tabs[i];
          index = i;
        }
      }
    }
    setActiveTab(index);
  }, [location]);

  let content: JSX.Element =
    activeTab !== -1 ? props.tabs[activeTab].element : <div></div>;

  return (
    <div>
      <div className="tabbed-view-tab-list">
        {props.tabs.map((x: IUrlTabbedViewTab, i: number) => {
          return (
            <div
              className={
                "display-flex-center tabbed-view-tab-list-tab " +
                (i === activeTab ? "tabbed-view-tab-list-tab-selected" : "")
              }
              key={i}
              onClick={() => {
                navigate(x.path + window.location.search);
              }}
            >
              {i18next.t("navigation:" + x.label)}
            </div>
          );
        })}
      </div>
      <div className="tabbed-view-tab-content">{content}</div>
    </div>
  );
};

export default UrlTabbedView;
