import { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import i18next from "i18next";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import "./LoginPage.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import AppRoutes from "../../Costants/AppRoutes";
import { useDispatch } from "react-redux";
import { UserActions } from "../../Reducers/User/UserAction";
import { ILoginResult } from "../../Models/ILoginResult";
import { RoadTaxActions } from "../../Reducers/RoadTax/RoadTaxAction";
import {
  checkAccountSessionStatusRedirect,
  userRoleFits,
} from "../../Utils/Session";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { IDebitCreditRoadtax } from "../../Models/IRoadtax";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { BrokerActions } from "../../Reducers/Broker/BrokerAction";

export const getRememberCreds = (credName: string) => {
  let creds: string | null = localStorage.getItem("remember-creds");
  if (creds) {
    let p: any = JSON.parse(creds);
    return p[credName] ?? null;
  }
  return null;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(
    (window as any)["session-is-expired"] === true
  );

  const rememberPassword = (data: any) => {
    if (data.remeber_password) {
      localStorage.setItem("remember-creds", JSON.stringify(data));
    } else {
      localStorage.removeItem("remember-creds");
    }
  };

  const chechUserAsDebitOrCredit = (
    isDebit: boolean,
    registrationCompleted: boolean,
    enabled: boolean,
    paymentRequired: boolean,
    isTrial: boolean
  ) => {
    ApiService.VehicleRoadTaxController.RoadTaxCreditDebitGet(
      isDebit,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let totalCost: number = 0;
          for (let i = 0; i < response.payload.length; i++) {
            if (response.payload[i].creditDebit) {
              totalCost = totalCost + response.payload[i].creditDebit;
            }
          }
          if (isDebit) {
            dispatch(
              RoadTaxActions.setRoadTaxList(
                response.payload.map((x: IDebitCreditRoadtax) => {
                  return x.roadTaxID;
                })
              )
            );
            dispatch(RoadTaxActions.setDebitCost(totalCost));

            if (totalCost < 0) {
              navigate("../" + AppRoutes.PAYMENTS_DEBIT_PAGE, {
                replace: false,
              });
            } else {
              navigate(
                checkAccountSessionStatusRedirect(
                  registrationCompleted,
                  enabled,
                  paymentRequired,
                  isTrial
                )
              );
            }
          }
        }
      }
    );
  };

  const getMenuVoices = (loggedUser: any) => {
    ApiService.SessionController.LoginMenuVoices((response: IAPIResponse) => {
      if (response.error === null) {
        dispatch(UserActions.setMenuPages(response.payload));

        ApiService.SubscriptionController.SubscriptionGetData(
          null,
          null,
          null,
          (response: IAPIResponse) => {
            if (response.error === null) {
              let subscriptionStatus: boolean = false;
              let getCurrentSubscription: ISubscriptions;
              if (response.payload && response.payload.length > 0) {
                subscriptionStatus =
                  response.payload[0].enabled || response.payload[0].isTrial;
                dispatch(
                  GenericActions.setSubscriptionEnabled(subscriptionStatus)
                );
                getCurrentSubscription = response.payload[0];
                dispatch(
                  GenericActions.setCurrentSubscription(response.payload[0])
                );
              }

              ApiService.CompanyController.CompanyGetData(
                null,
                null,
                (response: IAPIResponse) => {
                  if (response.error === null) {
                    dispatch(UserActions.setUser(loggedUser));
                    if (response.payload.length > 0) {
                      dispatch(UserActions.setCompanies(response.payload));
                    }
                    (window as any)["session-is-expired"] = false;

                    ApiService.StepperController.StepperGetLastData(
                      (response: IAPIResponse) => {
                        if (response.error === null) {
                          dispatch(
                            GenericActions.setLastStep(response.payload)
                          );
                          if (
                            response?.payload?.registrationCompleted &&
                            getCurrentSubscription?.enabled &&
                            !getCurrentSubscription?.paymentRequired &&
                            (getCurrentSubscription?.isTrial ||
                              !getCurrentSubscription?.isTrial)
                          ) {
                            chechUserAsDebitOrCredit(
                              true,
                              response?.payload?.registrationCompleted ?? false,
                              getCurrentSubscription?.enabled ?? false,
                              getCurrentSubscription?.paymentRequired ?? false,
                              getCurrentSubscription?.isTrial ?? false
                            );
                          } else {
                            navigate(
                              checkAccountSessionStatusRedirect(
                                response?.payload?.registrationCompleted ??
                                  false,
                                getCurrentSubscription?.enabled ?? false,
                                getCurrentSubscription?.paymentRequired ??
                                  false,
                                getCurrentSubscription?.isTrial ?? false
                              )
                            );
                          }
                        } else {
                          ToastMessage(response.error, "error");
                        }
                        setProcessing(false);
                      }
                    );
                  } else {
                    ToastMessage(response.error, "error");
                  }
                  setProcessing(false);
                }
              );
            } else {
              setProcessing(false);
              ToastMessage(response.error, "error");
            }
            setProcessing(false);
          }
        );
      } else {
        ToastMessage(i18next.t("error:generic_api_error"), "error");
      }
    });
  };

  const getBrokerTypes = (brokerAccountId: number) => {
    ApiService.UserTypesController.BrokerTypeGet(
      null,
      null,
      brokerAccountId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.length > 0) {
            dispatch(BrokerActions.setBrokerType(response.payload));
          }
        } else {
          ToastMessage(i18next.t("error:ERROR"), "error");
        }
      }
    );
  };

  const makeLogin = async (data: any) => {
    dispatch(UserActions.setMenuPages(undefined));
    rememberPassword(data);
    (window as any)["suppressToasts"] = false;
    setProcessing(true);
    ApiService.SessionController.Login(
      data.email,
      data.password,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload.accountImage) {
            let image = response.payload.accountImage;
            if (image) {
              dispatch(UserActions.setAvatar(image));
            }
          }

          localStorage.removeItem("StepConfirmEmail");
          let result: ILoginResult = response.payload;
          localStorage.setItem("sessionToken", result.token);
          ToastMessage(i18next.t("message:successfullLogin"), "success");

          let loggedUser = { ...response.payload };
          ApiService.SessionController.LoginStatus((response: IAPIResponse) => {
            if (response.error === null) {
              dispatch(UserActions.setLoginStatus(response.payload));
              getMenuVoices(loggedUser);
            } else {
              ToastMessage(i18next.t("error:generic_api_error"), "error");
            }
          });

          // Get broker types
          getBrokerTypes(response.payload.accountID);
        } else {
          setProcessing(false);

          if (
            response.payload.detail.includes(
              "A token related to the submitted user and TokenTypeID does not exist"
            )
          ) {
            ToastMessage(i18next.t("error:email_login_not_validated"), "error");
          } else {
            ToastMessage(
              i18next.t("error:" + response.payload.detail),
              "error"
            );
          }
        }
      }
    );
  };

  /* RETURN */
  return (
    <>
      <div className="multi-form-main-wrap form-login-page">
        <img
          src="/Images/logo.svg"
          className="form-login-logo"
          alt="Facile Flotta"
        />
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleLoginPage") ?? "",
          }}
        />
        <div className="multi-form-subtitle">
          {i18next.t("navigation:subtitleLoginPage")}
        </div>
        {sessionExpired && (
          <Alert sx={{ marginTop: "1em" }} severity="error">
            {i18next.t("navigation:login-session-expired")}
          </Alert>
        )}
        <div className="content-login-form">
          <MultiForm
            styles={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "baseline",
              justifyContent: "flex-end",
            }}
            suppressSubmit={true}
            formId={"LoginPage"}
            suppressLayout
            formTitle={i18next.t("form:titleLoginPage")}
            disableForm={processing}
            submitButtonLabel={String(i18next.t("navigation:login"))}
            inputs={[
              {
                width: 100,
                type: "email",
                name: "email",
                defaultValue: getRememberCreds("email"),
                required: true,
                label: String(i18next.t("form:email")),
              },
              {
                width: 100,
                type: "password",
                name: "password",
                defaultValue: getRememberCreds("password"),
                required: true,
                label: String(i18next.t("form:password")),
              },
              {
                width: 100,
                type: "checkbox",
                name: "remeber_password",
                defaultValue: getRememberCreds("remeber_password") ?? false,
                placeholder: String(i18next.t("form:rememberCredentials")),
              },

              {
                type: "custom",
                name: "",
                width: 50,
                element: (
                  <Button
                    fullWidth
                    className="multi-form-input-wrap"
                    component={RouterLink}
                    to={AppRoutes.REGISTRATION_ROUTE}
                    variant="outlined"
                  >
                    {i18next.t("navigation:registration")}
                  </Button>
                ),
              },
              {
                type: "custom",
                width: 50,
                name: "",
                element: (
                  <Button
                    fullWidth
                    className="multi-form-input-wrap"
                    variant="contained"
                    disabled={processing}
                    onClick={() => {
                      triggerFormValidation("LoginPage");
                    }}
                    endIcon={<ChevronRightIcon />}
                  >
                    {i18next.t("navigation:login")}
                  </Button>
                ),
              },
              {
                width: 100,
                type: "custom",
                name: "forgot_password",
                element: (
                  <div className="form-login-page-password">
                    <Link
                      className="text-color-hint"
                      to={AppRoutes.FORGOT_PASSWORD_ROUTE}
                    >
                      {i18next.t("navigation:forgotPassword")}
                    </Link>

                    <Link
                      className="text-color-hint"
                      to={AppRoutes.RESEND_EMAIL_ROUTE}
                    >
                      {i18next.t("navigation:resendEmail")}
                    </Link>
                  </div>
                ),
              },
            ]}
            onSubmit={(data: any) => {
              makeLogin(data);
            }}
          />
        </div>
      </div>
      <LoaderBackdrop loading={processing} />
    </>
  );
};

export default LoginPage;

/**
 *      {(window.location.href.includes("localhost") ||
          localStorage.getItem("isDev")) && (
          <div>
            <Button
              onClick={() => {
                makeLogin({
                  email: "admin@facileflotta.com",
                  password: "Password1!",
                });
              }}
            >
              Login Admin
            </Button>
            <Button
              onClick={() => {
                makeLogin({
                  email: "picete9336@anwarb.com",
                  password: "LRSEea93a!HC^q",
                });
              }}
            >
              Login Broker
            </Button>
            <Button
              onClick={() => {
                makeLogin({
                  email: "cokaqoxu@tutuapp.bid",
                  password: "Password1!",
                });
              }}
            >
              Login User (giuridico)
            </Button>
            <Button
              onClick={() => {
                makeLogin({
                  email: "cewisi4408@anomgo.com",
                  password: "Password1!",
                });
              }}
            >
              Login User (persona fisica)
            </Button>
          </div>
        )}
 * 
 */
