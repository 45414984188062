/* REACT */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Km__VehiclesPage.scss";
/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import AppRoutes from "../../../../../Costants/AppRoutes";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { formatPlate } from "../VehiclesPage";

/* MUI */
import { Alert, Box, Button, ButtonGroup, Link, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  dateIsPast,
  displayUTC0_ISODate,
} from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { ICompanyGet } from "../../../../../Models/ICompany";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { DismissModal } from "../../../../../Components/SmartModal/SmartModal";
import {
  IVehicleDataGet,
  IVehicleKilometersEntries,
  IVehicleKilometersEntriesChangeStatus,
  IVehicleKilometersEntriesInsert,
} from "../../../../../Models/IVehicles";
import { IDriverGet } from "../../../../../Models/IDriver";
import { VehiclesActions } from "../../../../../Reducers/Vehicle/VehicleAction";
import FormAssignedDriver from "../../../../../Components/StepAssignedDrivers/FormAssignedDriver";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

/* COMPONENT */
const Km__VehiclesPage = () => {
  const dispatch = useDispatch();
  /* REDUCER OF LOGGED USER */
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const drivers: IDriverGet[] | undefined = useSelector(
    (state: GlobalState) => state.vehicles.driversForCompany
  );
  const vehicles: IVehicleDataGet[] | undefined = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<IVehicleKilometersEntries[]>([]);
  const [lowerDetection, setLowerDetection] = useState<IVehicleKilometersEntries>();
  const [formData, setFormData] = useState<any>([]);
  const [entrieApprove, setEntriespprove] = useState<IVehicleKilometersEntries | null>(
    null
  );
  const [currentDriver, setCurrentDriver] = useState<any>(null);
  const [kmDetectionID, setKmDetectionID] = useState<number>(0);

  /* NAVIGATE */
  const navigate = useNavigate();

  const loadVehicles = (driverID?: string) => {
    setLoading(true)
    if (loggedCompany) {
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        null,
        driverID ? driverID : null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
        }
      );
    }
    setLoading(false)
  };

  const loadDrivers = () => {
    if (loggedUser) {
      setLoadingDriverList(true);

      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(VehiclesActions.setDriversForCompany(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingDriverList(false);
      });
    }
  };

  const DriverLink = (props: { driverID: number; driverLabel?: string }) => {
    let label = props.driverLabel;
    let noAvailable = false;
    if ((props.driverLabel ?? "undefined").includes("undefined")) {
      label = i18next.t("navigation:vehicle_not_assgned") ?? "";
      noAvailable = true;
    }

    return (
      <div>
        {noAvailable && <span>{label}</span>}

        {!noAvailable && (
          <Link
            className="cursor-pointer"
            onClick={() => {
              setCurrentDriver(selectDriver(props.driverID, drivers));
              SummonModal("driver-page-modal");
            }}
          >
            {(label ?? "").replaceAll("null", "").trim()}
          </Link>
        )}
      </div>
    );
  };

  const selectDriver = (driverID: number, thisList?: IDriverGet[]) => {
    let driver_: IDriverGet | undefined = (thisList ?? drivers).find(
      (x: IDriverGet) => x.driverInfo?.driverID === driverID
    );
    if (driver_) {
      let parsedItem = {
        ...driver_["driverInfo"],
        ...{ driverCostCenter: driver_["driverCostCenter"] },
        ...{ deadlineDriver: driver_["deadlineDriver"] },
        ...{
          licenceTypeList: driver_["driverInfo"]["licenceTypeList"].map(
            (x: any) => x.licenceTypeID
          ),
        },
      };

      return parsedItem;
    }
  };

  const getVehicleKilometersEntries = () => {
    setLoading(true);
    ApiService.VehicleKilometersEntriesController.GetVehicleKilometersEntries(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setEntries(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const insertVehicleKilometersEntries = (data: IVehicleKilometersEntriesInsert) => {
    data.totalVehicleKm = +data.totalVehicleKm;
    setProcessing(true);
    ApiService.VehicleKilometersEntriesController.InsertVehicleKilometersEntries(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("km-page-modal");
          DismissModal("km-page-modal-confirm");
          setTimeout(() => {
            getVehicleKilometersEntries();
          }, 500);
          setFormData([]);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteVehicleKilometersEntries = (kmDetectionID: number) => {
    setProcessing(true);
    ApiService.VehicleKilometersEntriesController.DeleteVehicleKilometersEntries(
      kmDetectionID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setKmDetectionID(0);
          setEntries(entries.filter((x: IVehicleKilometersEntries) => x.kmDetectionID !== kmDetectionID));
          DismissModal("DeleteConfirmKmDetectionModal");
          ToastMessage(i18next.t("navigation:deleteKmDetectionSuccess"), "success");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const renderDriver = (props: GridRenderCellParams) => {
    let d: IDriverGet | undefined = drivers.find(
      (x: IDriverGet) => x.driverInfo.driverID === props.row.driverID
    );

    return (
      <div>
        {d && (
          <DriverLink
            driverID={d.driverInfo.driverID}
            driverLabel={
              d?.driverInfo.driverSurname
                ? d?.driverInfo.driverName + " " + d?.driverInfo.driverSurname
                : d?.driverInfo.driverName
            }
          />
        )}
      </div>
    );
  };
  const renderVehicle = (props: GridRenderCellParams) => {
    let v: IVehicleDataGet | undefined = vehicles.find(
      (x: IVehicleDataGet) => x.vehicleID === props.row.vehicleID
    );
    return (
      <div>
        {v && (
          <Link
            className="cursor-pointer"
            onClick={() => {
              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SEARCH_VEHICLE_ROUTE__STATUS_TAB +
                  "?vehicleID=" +
                  v?.vehicleID
              );
            }}
          >
            {formatPlate(v.vehicleLicensePlate)}
          </Link>
        )}
      </div>
    );
  };

  const renderDate = (props: GridRenderCellParams) => {
    return <div>{displayUTC0_ISODate(props.row.kmDetectionDT)}</div>;
  };

  const renderKm = (props: GridRenderCellParams) => {
    let tmp = entries
    tmp = tmp.filter((el: IVehicleKilometersEntries) => (el.driverID === props.row.driverID &&
                                                        el.vehicleID === props.row.vehicleID && 
                                                        el.kmDetectionStatusID === 2 &&
                                                        new Date(el.kmDetectionDT) < new Date(props.row.kmDetectionDT)) ||
                                                        (el.kmDetectionDT === props.row.kmDetectionDT &&
                                                          el.kmDetectionID !== props.row.kmDetectionID &&
                                                          el.totalVehicleKm < props.row.totalVehicleKm))
    let tmpRender: any = ''
    if(props.row.kmDetectionStatusID === 2){
      if(tmp.length > 0) {
          tmpRender = props.row.totalVehicleKm - tmp[0].totalVehicleKm
      } else {
        tmpRender = props.row.totalVehicleKm
      }
    }
    return <div>{tmpRender}</div>;
  };

  const renderSource = (props: GridRenderCellParams) => {
    return (
      <div>
        {i18next
          .t("navigation:km_code_source_" + props.row.kmDetectionSourceCode)
          .toLocaleUpperCase()}
      </div>
    );
  };
  const renderStatus = (props: GridRenderCellParams) => {
    let color = "#fff";
    let changeStatus = false;
    if (props.row.kmDetectionStatusCode === "ACCEPTED") {
      color = "rgb(115 255 96)";
    }
    if (props.row.kmDetectionStatusCode === "PENDING") {
      color = "rgb(255 197 157)";
      changeStatus = true;
    }
    if (props.row.kmDetectionStatusCode === "REFUSED") {
      color = "rgb(255 96 96)";
    }
    return (
      <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
        <div style={{ background: color }} className="status-ball"></div>
        {i18next
          .t("navigation:km_code_status_" + props.row.kmDetectionStatusCode)
          .toLocaleUpperCase()}
        {changeStatus && (
          <Button
            variant="contained"
            onClick={() => {
              setEntriespprove(props.row);

              let relatedPending: IVehicleKilometersEntries[] = entries.filter(
                (x: IVehicleKilometersEntries) =>
                  x.vehicleID === props.row.vehicleID &&
                  x.kmDetectionStatusID === 1 &&
                  x.kmDetectionDT < props.row.kmDetectionDT
              );

              setShowWarning(relatedPending.length > 0);

              SummonModal("approve-reject-entry-km");
            }}
          >
            {i18next.t("navigation:approve")} | {i18next.t("navigation:refuse")}
          </Button>
        )}
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "action",
      headerName: i18next.t("navigation:actions"),
      type: "custom",
      renderCell: (props: GridRenderCellParams) => {
        if(props.row.kmDetectionSourceCode === "MANUAL"){
          return (
            <Button
              disabled={props.row.stored}
              onClick={() => {
                setKmDetectionID(props.row.kmDetectionID);
                SummonModal("DeleteConfirmKmDetectionModal");
              }}
            >
              <Tooltip
                title={i18next.t("navigation:_delete_km_detection")}
                placement="top-start"
              >
                <DeleteOutlineIcon className="icon-table" />
              </Tooltip>
            </Button>
          )
        }else{
          return <></>
        }
      }
    },
    {
      field: "kmDetectionSourceCode",
      headerName: i18next.t("form:km_detection_source_code"),
      renderCell: renderSource,
    },
    {
      field: "km",
      headerName: i18next.t("form:_km_detection_km"),
      renderCell: renderKm,
      type: "number"
    },
    {
      field: "totalVehicleKm",
      headerName: i18next.t("form:_km_detection_km_total"),
      type: "number"
    },
    {
      field: "driver_",
      headerName: i18next.t("form:_km_detection_driver"),
      renderCell: renderDriver,
    },
    {
      field: "vehicle_",
      headerName: i18next.t("form:_km_detection_vehicle"),
      renderCell: renderVehicle,
    },
    {
      field: "kmDetectionStatusCode",
      headerName: i18next.t("form:_km_detection_status_code"),
      renderCell: renderStatus,
    },
    {
      field: "kmDetectionDT",
      headerName: i18next.t("form:_km_detection_dt"),
      renderCell: renderDate,
    },
  ];

  useEffect(() => {
    loadVehicles();
    loadDrivers();
  }, []);

  useEffect(() => {
    if (vehicles.length > 0 && drivers.length > 0 && entries.length === 0) {
      getVehicleKilometersEntries();
    }
  }, [vehicles, drivers]);

  const changeEntryStatus = (selection: number) => {
    if (entrieApprove) {
      let data: IVehicleKilometersEntriesChangeStatus = {
        kmDetectionStatusID: selection,
        kmDetectionID: entrieApprove?.kmDetectionID,
      };
      setProcessing(true);
      ApiService.VehicleKilometersEntriesController.ChangeStatusVehicleKilometersEntries(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            DismissModal("approve-reject-entry-km");
            setTimeout(() => {
              getVehicleKilometersEntries();
            }, 500);
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_vehicles_km")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                SummonModal("km-page-modal");
                setFormData([])
              }}
            >
              {i18next.t("navigation:_add_km_measure")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap
            headers={columnsDef}
            rows={entries.map((x: any) => {
              let d: IDriverGet | undefined = drivers.find(
                (y: IDriverGet) => y.driverInfo.driverID === x.driverID
              );
              let v: IVehicleDataGet | undefined = vehicles.find(
                (y: IVehicleDataGet) => y.vehicleID === x.vehicleID
              );
              x["action"] = "";
              x["vehicle_"] = v ? formatPlate(v.vehicleLicensePlate) : "";
              x["driver_"] = d?.driverInfo.driverName + " " + d?.driverInfo.driverSurname;
              return x;
            })}
          />
        </Box>

        <SmartModal
          modalUniqueId={"km-page-modal"}
          title={i18next.t("navigation:_add_km_measure")}
          modalInnerComponent={
            <>{!loading && <MultiForm
              suppressLayout
              suppressSubmit
              formId="km-page-modal-form"
              formTitle={""}
              inputs={[
                {
                  width: 50,
                  type: "select",
                  name: "driverID",
                  defaultValue: formData?.driverID,
                  placeholder: i18next.t("form:_km_detection_driver") ?? "",
                  label: i18next.t("form:_km_detection_driver") ?? "",
                  required: true,
                  options: drivers.map((x: IDriverGet) => {
                    return {
                      key: x.driverInfo.driverID,
                      text: x.driverInfo.driverName + " " + x.driverInfo.driverSurname,
                    };
                  }),
                },
                {
                  width: 50,
                  type: "select",
                  name: "vehicleID",
                  defaultValue: formData?.vehicleID,
                  placeholder: i18next.t("form:_km_detection_vehicle") ?? "",
                  label: i18next.t("form:_km_detection_vehicle") ?? "",
                  required: true,
                  disabled: !formData?.driverID,
                  options: vehicles.map((x: IVehicleDataGet) => {
                    return {
                      key: x.vehicleID,
                      text: formatPlate(x.vehicleLicensePlate),
                    };
                  }),
                },
                {
                  width: 50,
                  type: "number",
                  defaultValue: formData?.totalVehicleKm,
                  placeholder: i18next.t("form:_km_detection_km_total") ?? "",
                  label: i18next.t("form:_km_detection_km_total") ?? "",
                  name: "totalVehicleKm",
                  required: true,
                  inputProps: {
                    max: 1000000,
                  },
                },
                {
                  width: 50,
                  type: "datetime",
                  defaultValue: formData?.kmDetectionDT,
                  label: i18next.t("form:_km_detection_dt") ?? "",
                  name: "kmDetectionDT",
                  maxDate: new Date().toString(),
                  required: true,
                  disabled: !formData?.driverID || !formData?.vehicleID,
                },
              ]}
              onChange={(data: any) => {
                if(formData && data.driverID && formData.driverID !== data.driverID) {
                  loadVehicles(data.driverID)
                }
                setFormData({ ...formData, ...data });
              }}
              onSubmit={(data: any) => {
                let tmp = entries
                let tmpHigh = entries
                tmp = tmp.filter((el: IVehicleKilometersEntries) => el.driverID === data.driverID && el.vehicleID === data.vehicleID && el.kmDetectionStatusCode === "ACCEPTED" &&
                                  new Date(el.kmDetectionDT) < new Date(data.kmDetectionDT) && el.totalVehicleKm > +data.totalVehicleKm)
                tmpHigh = tmpHigh.filter((el: IVehicleKilometersEntries) => el.driverID === data.driverID && el.vehicleID === data.vehicleID && el.kmDetectionStatusCode === "ACCEPTED" &&
                                  new Date(el.kmDetectionDT) > new Date(data.kmDetectionDT) && el.totalVehicleKm < +data.totalVehicleKm)
                if(tmpHigh.length > 0) {
                  ToastMessage(i18next.t("message:wrongKilometers") + displayUTC0_ISODate(tmpHigh[0].kmDetectionDT), "warning");
                } else {
                if(tmp.length > 0){
                  setLowerDetection(tmp[0])
                  SummonModal("km-page-modal-confirm");
                } else {
                  insertVehicleKilometersEntries(data);
                }}
              }}
            />}</>
          }
          buttons={[
            {
              text: i18next.t("navigation:reject"),
              onClick: () => {
                DismissModal("km-page-modal");
              },
            },
            {
              text: i18next.t("navigation:accept"),
              onClick: () => {
                triggerFormValidation("km-page-modal-form");
              },
            },
          ]}
        />

        <SmartModal
          modalUniqueId="km-page-modal-confirm"
          title={i18next.t("message:warning")}
          modalInnerComponent={
            <div className="modal-width-small">
              <p
                dangerouslySetInnerHTML={{
                  __html: i18next
                    .t("navigation:km_change_status_text")
                    .replace("DIFF_KM", ((lowerDetection ? lowerDetection.totalVehicleKm : 0) - formData?.totalVehicleKm).toString())
                    .replace(
                      "DATE_KM",
                      (lowerDetection?.kmDetectionDT ?
                        displayUTC0_ISODate(lowerDetection?.kmDetectionDT) : '')
                    ),
                }}
              />
            </div>
          }
          buttons={[
            {
              text: i18next.t("message:yes"),
              onClick: () => {
                insertVehicleKilometersEntries(formData);
              },
            },
            {
              text: i18next.t("message:no"),
              onClick: () => {
                DismissModal("km-page-modal");
                DismissModal("km-page-modal-confirm");
                setFormData([]);
              },
            },
          ]}
        />

        <SmartModal
          modalUniqueId="approve-reject-entry-km"
          title={i18next.t("navigation:km_change_status")}
          modalInnerComponent={
            <div>
              {showWarning && (
                <Alert severity={"warning"}>
                  {i18next.t("navigation:km_change_status_warn")}
                </Alert>
              )}
            </div>
          }
          buttons={[
            {
              text: i18next.t("navigation:refuse"),
              onClick: () => {
                changeEntryStatus(3);
              },
            },
            {
              text: i18next.t("navigation:approve"),
              onClick: () => {
                changeEntryStatus(2);
              },
            },
          ]}
        />

        <SmartModal
          hideButtons={true}
          modalUniqueId="driver-page-modal"
          title={
            currentDriver
              ? i18next.t("generic:titleUpdateDriver")
              : i18next.t("generic:titleAddDriver")
          }
          modalInnerComponent={
            <FormAssignedDriver
              loggedUser={loggedUser}
              data={currentDriver ?? []}
              companyUser={companyUser}
              idModal="driver-page-modal"
              /* updateList={() => {
                loadDriverList(null);
              }} */
            />
          }
        />
        <SmartModal 
          title={i18next.t("navigation:km_detection_delete_title")}
          modalUniqueId="DeleteConfirmKmDetectionModal"
          modalInnerComponent={
            <p>{i18next.t("navigation:km_detection_delete")}</p>
          }
          onReject={() => {
            DismissModal("DeleteConfirmKmDetectionModal")
          }}
          onAccept={() => {
            deleteVehicleKilometersEntries(kmDetectionID);
          }}
        />
        <LoaderBackdrop loading={loading || processing || loadingDriverList} />
      </div>
    </div>
  );
};

export default Km__VehiclesPage;
