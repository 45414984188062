/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./QuotesPageRequests.scss";

/* LIBRARIES */
import i18next from "i18next";

/* MODELS */
import {
  IVehicleDataBrands,
  IVehicleDataFitting,
  IVehicleDataGet,
  IVehicleDataModels,
} from "../../../../../Models/IVehicles";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IGoogleAddress } from "../../../../../Utils/Google";
import {
  getCompressedBase64,
  getCompressedFileName,
} from "../../../../../Utils/FileToBase";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import MultiForm, {
  IMultiFormField,
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import PlaceAutoCompleteForm from "../../../../../Components/PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import UploadForm from "../../../../../Components/UploadForm/UploadForm";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* MUI */
import {
  Alert,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
// Icons
import SendIcon from "@mui/icons-material/Send";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FormVehicleData from "../../../../../Components/StepVehicles/TabVehicleData/FormVehicleData";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { IUser } from "../../../../../Models/IUser";
import { useSelector } from "react-redux";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { Form__vehicleData } from "../../VehiclesPage/VehiclesPage_subPages/VehiclesPage_subPages_vehicleData/VehiclesPage_subPages_vehicleData";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { IQuotation } from "../../../../../Models/IQuotation";
import { IBrokerGet } from "../../../../../Models/IBroker";
import { sendMessage } from "../../../../../Utils/Messaging";
import AppRoutes from "../../../../../Costants/AppRoutes";
import GoogleRadiusMap from "../../../../../Components/GoogleRadiusMap/GoogleRadiusMap";

var CORE_DICT: any = {};

const initCoreDict = () => {
  const mechanicOperationList = [
    {
      key: "__other_intervention",
      text: i18next.t("navigation:mechanicOperationList_other"),
    },
    {
      key: "_revision",
      text: i18next.t("navigation:mechanicOperationList_revision"),
    },
    {
      key: "__test",
      text: i18next.t("navigation:mechanicOperationList_test"),
    },
  ];

  const opTypeList = [
    {
      key: "__tire_op",
      text: i18next.t("navigation:opTypeList_tire"),
    },
    {
      key: "__mechanic_op",
      text: i18next.t("navigation:opTypeList_mechanic"),
    },
    {
      key: "__fitting_op",
      text: i18next.t("navigation:opTypeList_fitting"),
    },
  ];

  const wheelsTypeList = [
    {
      key: "__summer_tire",
      text: i18next.t("navigation:wheelsTypeList_summer"),
    },
    {
      key: "__winter_tire",
      text: i18next.t("navigation:wheelsTypeList_winter"),
    },
    {
      key: "__4season_tire",
      text: i18next.t("navigation:wheelsTypeList_4season"),
    },
  ];
  CORE_DICT = {
    mechanicOperationList,
    opTypeList,
    wheelsTypeList,
  };
};

export const renderInterventionTypeString = (str: string | undefined) => {
  initCoreDict();

  if (str && str != "") {
    let splStr: string[] = str.split(":");

    let baseDesc: any = CORE_DICT.opTypeList.find(
      (x: any) => x.key === splStr[0]
    );
    let extraDesc: any =
      splStr.length > 1
        ? CORE_DICT.mechanicOperationList.find((x: any) => x.key === splStr[1])
        : undefined;

    if (!extraDesc) {
      extraDesc = { text: "" };
    }

    if (baseDesc) {
      return baseDesc.text + (extraDesc.text ? ", " + extraDesc.text : "");
    }
  }

  return "";
};
(window as any)["renderInterventionTypeString"] = renderInterventionTypeString;

/* COMPONENT */
const QuotesPageRequests = () => {
  /* STATES */
  const [formData, setFormData] = useState<any>({
    requestMaxDistance: 25000,
  });

  // Loading
  const [loading, setLoading] = useState<boolean>(false);
  const [vehiclesList, setVehiclesList] = useState<IVehicleDataGet[]>([]);
  const [vehiclesPick, setVehiclePick] = useState<IVehicleDataGet>();
  const [page, setPage] = useState<number>(0);

  const [loadingBrandsList, setLoadingBrandsList] = useState<boolean>(false);
  const [loadingModelsList, setLoadingModelsList] = useState<boolean>(false);
  const [loadingFittingsList, setLoadingFittingsList] =
    useState<boolean>(false);

  /* LODASH */
  const lodash = require("lodash");
  const [broker, setBroker] = useState<IBrokerGet | null>(null);
  initCoreDict();

  /* REDUCER OF USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* FUNCTIONS */
  // Upload photo
  const updateFile = (files: File[]) => {
    getCompressedBase64(
      files.length > 0 ? files[0] : null,
      (result: string | null) => {
        let oldState = { ...formData }; // Valutare un deep clone
        oldState["operationImageURL"] = result;
        setFormData(oldState);
      },
      { height: 220, width: 340 }
    );
  };

  const getVehicles = () => {
    setLoading(true);
    ApiService.VehicleDataController.VehicleDataGet(
      companyUser?.companyID,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVehiclesList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const getYourBroker = () => {
    ApiService.BrokerController.BrokerInvitationsGet(
      loggedUser?.accountID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let brokers: IBrokerGet[] = response.payload;
          if (brokers.length > 0) {
            ApiService.BrokerController.BrokerGet(
              null,
              brokers[0].brokerID,
              (response2: IAPIResponse) => {
                if (response.error === null) {
                  let brokers2: IBrokerGet[] = response2.payload;
                  if (brokers2.length > 0) {
                    setBroker(brokers2[0]);
                  }
                }
              }
            );
          }
        }
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    getVehicles();
    getYourBroker();
  }, []);

  useEffect(() => {
    setVehiclePick(
      vehiclesList.find(
        (x: IVehicleDataGet) =>
          x.vehicleLicensePlate === formData.vehicleLicensePlate
      )
    );
  }, [formData.vehicleLicensePlate]);

  const formIsReady = () => {
    return vehiclesPick !== undefined;
  };

  /* MULTIFORM */
  const multiFormInputs: IMultiFormField[] = !vehiclesPick
    ? [
        // Plate
        {
          width: 33.3,
          type: "autocomplete",
          name: "vehicleLicensePlate",
          required: true,
          defaultValue: formData?.vehicleLicensePlate,
          label: `${i18next.t("form:plate")}`,
          options: vehiclesList.map((item: any) => {
            return {
              key: item.vehicleLicensePlate,
              text: formatPlate(item.vehicleLicensePlate),
            };
          }),
        },
      ]
    : [
        {
          type: "custom",
          name: "space",
          width: 100,
          className: "minHeight",
          element: (
            <div>
              {vehiclesPick && (
                <Button
                  onClick={() => {
                    setVehiclePick(undefined);
                  }}
                >
                  {i18next.t("navigation:change_vehicle")}
                </Button>
              )}
            </div>
          ),
        },
      ];

  if (vehiclesPick) {
    multiFormInputs.push(
      {
        type: "custom",
        name: "space",
        width: 100,
        className: "minHeight",
        element: <Divider />,
      },
      // Address
      {
        fullWidth: true,
        width: 100,
        type: "custom",
        name: "PlaceAutoCompleteForm",
        className: "content-place-auto-complete",
        element: (
          <PlaceAutoCompleteForm
            onChange={(data: IGoogleAddress) => {
              setFormData({ ...formData, ...data });
            }}
            addressId="companyBillingStreet"
            inputs={[
              {
                width: 70,
                type: "text",
                required: true,
                name: "companyBillingStreet",
                inputProps: { maxLength: 100 },
                defaultValue: formData?.companyBillingStreet,
                mapDecode: "route",
                label: String(i18next.t("form:selectArea")),
              },
              {
                width: 30,
                type: "text",
                required: true,
                name: "companyBillingNumber",
                defaultValue: formData?.companyBillingNumber,
                mapDecode: "street_number",
                inputProps: { maxLength: 10 },
                label: String(i18next.t("form:civicNumberAbbr")),
              },
              {
                width: 100,
                required: true,
                type: "select_nations",
                name: "companyBillingCountryID",
                currentValue: formData?.companyBillingCountryID ?? "",
                mapDecode: "country",
                label: String(i18next.t("form:countryBilling")),
              },
              {
                width: 25,
                type: "text",
                required: true,
                name: "companyBillingCAP",
                defaultValue: formData?.companyBillingCAP,
                mapDecode: "postal_code",
                inputProps: { maxLength: 20 },
                label: String(i18next.t("form:postalCode")),
              },
              {
                width: 50,
                type: "text",
                required: true,
                name: "companyBillingCity",
                defaultValue: formData?.companyBillingCity,
                mapDecode: "locality",
                inputProps: { maxLength: 50 },
                label: String(i18next.t("form:city")),
              },
              {
                width: 25,
                type: "text",
                required: true,
                name: "companyBillingProv",
                defaultValue: formData?.companyBillingProv,
                inputProps: { maxLength: 50 },
                mapDecode: "province",
                label: String(i18next.t("form:provinceAbbr")),
              },
            ]}
          />
        ),
      },
      // Select quote area
      {
        width: 50,
        type: "select",
        name: "selectQuote",
        required: true,
        defaultValue: formData?.selectQuote,
        label: `${i18next.t("form:selectQuote")}`,
        options: CORE_DICT.opTypeList.map((item: any) => {
          return {
            key: item.key,
            text: item.text,
          };
        }),
      },
      {
        width: 50,
        type: "number",
        name: "requestMaxDistance",
        defaultValue: formData?.requestMaxDistance,
        required: true,
        label: i18next.t("form:requestMaxDistance_label") ?? "",
        placeholder: i18next.t("form:requestMaxDistance_label") ?? "",
        inputProps: { min: 0, max: 1000000 },
      },

      // Divider
      {
        type: "custom",
        name: "space",
        width: 100,
        className: "minHeight",
        element: <Divider />,
      }
    );
  }

  // Gommista
  if (formData.selectQuote === "__tire_op") {
    multiFormInputs.push(
      // Title
      {
        type: "custom",
        name: "title",
        width: 100,
        element: (
          <div>
            <Typography variant="h5">
              {i18next.t("form:requestTires")}
            </Typography>
            <Typography variant="body2">
              {i18next.t("form:requestTiresText")}
            </Typography>
          </div>
        ),
      },
      // Type
      {
        width: 25,
        type: "select",
        name: "meta.selectWheels",
        required: true,
        defaultValue: formData?.selectWheels,
        label: `${i18next.t("form:selectWheels")}`,
        options: CORE_DICT.wheelsTypeList.map((item: any) => {
          return {
            key: item.key,
            text: item.text,
          };
        }),
      },
      // Space
      {
        type: "custom",
        name: "space",
        width: 75,
        element: <div></div>,
      },

      // Title - Ruote anteriori
      {
        type: "custom",
        name: "title",
        width: 100,
        element: (
          <div>
            <Typography variant="overline" className="text-bold">
              {i18next.t("form:frontTyres")}
            </Typography>
          </div>
        ),
      },

      // Tire width
      {
        width: 16.6,
        type: "select",
        name: "meta.tireWidthFront",
        required: true,
        defaultValue: formData?.tireWidthFront,
        label: `${i18next.t("form:width")}`,
        options: [
          115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245,
          255, 265, 275, 285, 295, 305, 315, 325, 335, 345, 355, 365,
        ].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },
      // Tire serie
      {
        width: 16.6,
        type: "select",
        name: "meta.tireSerieFront",
        required: true,
        defaultValue: formData?.tireSerieFront,
        label: `${i18next.t("form:serie")}`,
        options: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85].map(
          (item: any) => {
            return {
              key: item,
              text: item.toString(),
            };
          }
        ),
      },
      // Tire diameter
      {
        width: 16.6,
        type: "select",
        name: "meta.tireDiameterFront",
        required: true,
        defaultValue: formData?.tireDiameterFront,
        label: `${i18next.t("form:diameter")}`,
        options: [
          10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
        ].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },
      // Tire speed code
      {
        width: 16.6,
        type: "select",
        name: "meta.tireSpeedCodeFront",
        required: true,
        defaultValue: formData?.tireSpeedCodeFront,
        label: `${i18next.t("form:speedCode")}`,
        options: [
          "Q (160 km/h)",
          "R (170 km/h)",
          "S (180 km/h)",
          "T (190 km/h)",
          "H (210 km/h)",
          "V (240 km/h)",
          "ZR (240 km/h)",
          "W (270 km/h)",
          "Y (300 km/h)",
        ].map((item: any) => {
          return {
            key: item,
            text: item,
          };
        }),
      },
      // Tire load index
      {
        width: 16.6,
        type: "select",
        name: "meta.tireLoadIndexFront",
        required: true,
        defaultValue: formData?.tireLoadIndexFront,
        label: `${i18next.t("form:loadIndex")}`,
        options: [
          "89 (580kg)",
          "91 (615kg)",
          "94 (670kg)",
          "97 (730kg)",
          "98 (750kg)",
        ].map((item: any) => {
          return {
            key: item,
            text: item,
          };
        }),
      },
      // Wheels number
      {
        width: 16.6,
        type: "select",
        name: "meta.wheelsNumberFront",
        required: true,
        defaultValue: formData?.wheelsNumberFront,
        label: `${i18next.t("form:wheelsNumber")}`,
        options: [1, 2].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },

      // Title - Ruote Posteriori | Copia valori
      {
        type: "custom",
        name: "title",
        width: 100,
        element: (
          <div className="display-flex-vertical-center gap-extra-small">
            <Typography variant="overline" className="text-bold">
              {i18next.t("form:rearTyres")}
            </Typography>

            <Tooltip title={i18next.t("form:copyFrontTires")}>
              <IconButton
                color="primary"
                onClick={() => {
                  formData &&
                    (formData.tireWidthRear = formData?.tireWidthFront);
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },

      // Tire width
      {
        width: 16.6,
        type: "select",
        name: "meta.tireWidthRear",
        required: true,
        defaultValue: formData?.tireWidthRear,
        label: `${i18next.t("form:width")}`,
        options: [
          115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245,
          255, 265, 275, 285, 295, 305, 315, 325, 335, 345, 355, 365,
        ].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },
      // Tire serie
      {
        width: 16.6,
        type: "select",
        name: "meta.tireSerieRear",
        required: true,
        defaultValue: formData?.tireSerieRear,
        label: `${i18next.t("form:serie")}`,
        options: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85].map(
          (item: any) => {
            return {
              key: item,
              text: item.toString(),
            };
          }
        ),
      },
      // Tire diameter
      {
        width: 16.6,
        type: "select",
        name: "meta.tireDiameterRear",
        required: true,
        defaultValue: formData?.tireDiameterRear,
        label: `${i18next.t("form:diameter")}`,
        options: [
          10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
        ].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },
      // Tire speed code
      {
        width: 16.6,
        type: "select",
        name: "meta.tireSpeedCodeRear",
        required: true,
        defaultValue: formData?.tireSpeedCodeRear,
        label: `${i18next.t("form:speedCode")}`,
        options: [
          "Q (160 km/h)",
          "R (170 km/h)",
          "S (180 km/h)",
          "T (190 km/h)",
          "H (210 km/h)",
          "V (240 km/h)",
          "ZR (240 km/h)",
          "W (270 km/h)",
          "Y (300 km/h)",
        ].map((item: any) => {
          return {
            key: item,
            text: item,
          };
        }),
      },
      // Tire load index
      {
        width: 16.6,
        type: "select",
        name: "meta.tireLoadIndexRear",
        required: true,
        defaultValue: formData?.tireLoadIndexRear,
        label: `${i18next.t("form:loadIndex")}`,
        options: [
          "89 (580kg)",
          "91 (615kg)",
          "94 (670kg)",
          "97 (730kg)",
          "98 (750kg)",
        ].map((item: any) => {
          return {
            key: item,
            text: item,
          };
        }),
      },
      // Wheels number
      {
        width: 16.6,
        type: "select",
        name: "meta.wheelsNumberRear",
        required: true,
        defaultValue: formData?.wheelsNumberRear,
        label: `${i18next.t("form:wheelsNumber")}`,
        options: [1, 2].map((item: any) => {
          return {
            key: item,
            text: item.toString(),
          };
        }),
      },

      // Divider
      {
        type: "custom",
        name: "space",
        width: 100,
        element: <Divider className="margin-top-small" />,
      }
    );
  }

  // Meccanico
  if (formData.selectQuote === "__mechanic_op") {
    multiFormInputs.push(
      // Title
      {
        type: "custom",
        name: "title",
        width: 100,
        element: (
          <div>
            <Typography variant="h5">
              {i18next.t("form:requestMechanical")}
            </Typography>
            <Typography variant="body2">
              {i18next.t("form:requestMechanicalText")}
            </Typography>
          </div>
        ),
      },
      {
        width: 25,
        type: "select",
        name: "mechanicOperation",
        required: true,
        defaultValue: formData?.mechanicOperation,
        label: `${i18next.t("form:operation")}`,
        options: CORE_DICT.mechanicOperationList.map((item: any) => {
          return {
            key: item.key,
            text: item.text,
          };
        }),
      }
    );

    if (formData.mechanicOperation === "__other_intervention") {
      multiFormInputs.push({
        width: 100,
        type: "multiline",
        name: "note",
        required: true,
        multilineRows: 5,
        defaultValue: formData?.note,
        label: `${i18next.t("form:note")}`,
      });
    }
  }

  // Carrozziere
  if (formData.selectQuote === "__fitting_op") {
    multiFormInputs.push(
      // Title
      {
        type: "custom",
        name: "title",
        width: 100,
        element: (
          <div>
            <Typography variant="h5">
              {i18next.t("form:requestCarbody")}
            </Typography>
            <Typography variant="body2">
              {i18next.t("form:requestMechanicalText")}
            </Typography>
          </div>
        ),
      },
      {
        width: 60,
        type: "multiline",
        name: "note",
        required: true,
        multilineRows: 8,
        defaultValue: formData?.note,
        label: `${i18next.t("form:note")}`,
      },
      {
        width: 40,
        type: "custom",
        name: "operationImageURL",
        required: true,
        label: `${i18next.t("form:uploadPhotos")}`,
        element: (
          <UploadForm
            name={"operationImageURL"}
            accept={"image/*"}
            onFileChanged={updateFile}
            title={i18next.t("form:uploadOperationPhotos")}
            description={i18next.t("form:descriptionImageUpload")}
            initialFiles={
              formData && formData.operationImageURL
                ? [getCompressedFileName(formData.operationImageURL)]
                : []
            }
          />
        ),
      }
    );
  }

  const getAddressFromForm = () => {
    let fields = [
      "companyBillingStreet",
      "companyBillingNumber",
      "companyBillingCAP",
      "companyBillingCity",
      "companyBillingProv",
    ];

    return fields
      .map((x: string) => {
        return formData[x];
      })
      .join(" ");
  };

  const testQuotationCanBeServed = () => {
    let quotationType: string =
      formData.selectQuote + ":" + (formData.mechanicOperation ?? "");
    let address: string = getAddressFromForm();
    let searchDistance: number = formData.requestMaxDistance;
    let RequestedToBrokerId: number | null = null;

    if (broker) {
      RequestedToBrokerId = broker.brokerAccountID;
    }

    setLoading(true);

    ApiService.QuotationController.QuotationCanBeServed(
      quotationType,
      address,
      searchDistance,
      RequestedToBrokerId,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          let value = await response.payload.text();

          if (value == 0) {
            ToastMessage(
              i18next.t("navigation:no_services_in_area"),
              "warning"
            );
          } else {
            sendQuotationRequest();
          }
        } else {
          let result = await response.raw.json();
          if (result.detail.toLocaleLowerCase().includes("bad address")) {
            let addresses = result.detail
              .replaceAll(
                "Bad Google API Behavior: Bad address cannot be resolved: ",
                ""
              )
              .replaceAll(", from", " -> ");
            ToastMessage(
              i18next.t("error:bad_google_address_result") + ": " + addresses,
              "error"
            );
          } else {
            ToastMessage(i18next.t("error:generic_api_error"), "error");
          }
        }
        setLoading(false);
      }
    );
  };

  const sendQuotationRequest = () => {
    if (vehiclesPick && loggedUser) {
      setLoading(true);

      let operation: string =
        formData.selectQuote + ":" + (formData.mechanicOperation ?? "");

      let quotation: IQuotation = {
        vehicleId: vehiclesPick.vehicleID,
        requestedById: loggedUser.accountID,
        areaOfIntervention: getAddressFromForm(),
        interventionType: operation,
        note: formData.note ?? "",
        attachmentFile: formData.operationImageURL
          ? JSON.parse(formData.operationImageURL).url
          : "",
        dynamicMetadata: formData.meta ? JSON.stringify(formData.meta) : "",
      };

      if (broker) {
        quotation.requestedToBrokerId = broker.brokerAccountID;
      }

      const getBaseMsg = (text: string, preopenId: number) => {
        return JSON.stringify({
          triggerContent: "unpackJsonMessage",
          baseText: text,
          extraParams: [
            {
              key: "$PREOPEN_URL",
              value:
                AppRoutes.INTERNAL_DASHBOARD +
                AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE +
                "?preopenId=" +
                preopenId.toString(),
            },
            {
              key: "$SERVICE_NAME",
              fun: "renderInterventionTypeString",
              value:
                formData.selectQuote + ":" + (formData.mechanicOperation ?? ""),
            },
            {
              key: "$REQUESTER",
              value: loggedUser?.accountName + " " + loggedUser?.accountSurname,
            },
          ],
        });
      };

      ApiService.QuotationController.QuotationsInsert(
        quotation,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (broker) {
              sendMessage(
                broker.brokerAccountID,
                "translate__should_serve_quote_obj",
                getBaseMsg(
                  "translate__should_serve_quote_cont",
                  response.payload
                ),
                getBaseMsg(
                  "translate__should_serve_quote_cont_short",
                  response.payload
                ),
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___QUOTE_ROUTE__PROPOSE +
                  "?preopenId=" +
                  response.payload
              );
            }
            setPage(1);
            ToastMessage(i18next.t("navigation:quotation_added_ok"), "success");
          } else {
            ToastMessage(
              i18next.t("navigation:quotation_added_not_ok"),
              "error"
            );
          }
          setLoading(false);
        }
      );
    }
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:quotesRequest")} />

      {page === 0 && (
        <div className="dashboardForm__box">
          {!vehiclesPick && (
            <Alert severity="info">
              {i18next.t("navigation:please_pick_vehicle")}
            </Alert>
          )}
          {vehiclesPick && (
            <div>
              <Divider />
              <Form__vehicleData
                reducedForm
                vehicle={vehiclesPick}
                onResetVehicleUpdate={() => {}}
              />
            </div>
          )}
          <MultiForm
            suppressLayout
            suppressSubmit={true}
            formId={"FormQuotesRequests"}
            inputs={multiFormInputs}
            onChange={(data: any) => {
              setFormData({ ...formData, ...data });
            }}
            onSubmit={() => {
              if (
                formData.selectQuote === "__fitting_op" &&
                !formData.operationImageURL
              ) {
                ToastMessage(
                  i18next.t("navigation:please_upload_file"),
                  "warning"
                );
              } else {
                testQuotationCanBeServed();
              }
            }}
          />

          {
            <div className="display-flex-end padding-right-small padding-bottom-small">
              <Button
                disabled={!formIsReady()}
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => {
                  triggerFormValidation("FormQuotesRequests");
                }}
              >
                {i18next.t("navigation:send")}
              </Button>
            </div>
          }
        </div>
      )}
      {page === 1 && (
        <div className="dashboardForm__box">
          <div className="done-submit-zone">
            <DoneOutlineIcon />
            <span>{i18next.t("navigation:quotation_sent")}</span>
          </div>
        </div>
      )}

      <LoaderBackdrop
        loading={
          loadingBrandsList ||
          loading ||
          loadingModelsList ||
          loadingFittingsList
        }
      />
    </div>
  );
};

export default QuotesPageRequests;
