import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

import "./IconCardComponent.scss";

export interface IIconCardComponentProps {
  image: JSX.Element;
  sideInfo: JSX.Element;
}

const IconCardComponent = (props: IIconCardComponentProps) => {
  return (
    <div className="icon-cad-component-wrap">
      <div className="icon-cad-component-wrap-row">
        <div className="icon-cad-component-wrap-image">{props.image}</div>
        <div className="icon-cad-component-wrap-content">{props.sideInfo}</div>
        {false && (
          <div className="icon-cad-component-wrap-side-commands">
            <IconButton aria-label="actions">
              <MoreVertIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};
export default IconCardComponent;
