import { Divider, ListItemText, MenuItem, MenuList } from '@mui/material'
import './MenuMobilePage.scss'
import { useNavigate } from 'react-router-dom'
import AppRoutes from '../../Costants/AppRoutes'
import i18next from 'i18next'

const MenuMobilePage = () => {
    const navigate = useNavigate()

    return (
        <div>
        <MenuList dense style={{marginBottom: '10em'}}>
        <MenuItem onClick={() => {navigate(AppRoutes.HOME_PAGE_ROUTE)}}>
          <ListItemText inset>Home</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.WHO_WE_ARE_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:menuWhoWeAre')}</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.FUNCITIONALITIES_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:functionality')}</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.RATES_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:ratesTitle')}</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.MARKETPLACE_ROUTE)}}>
          <ListItemText inset>Area marketplace</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.WHYUS_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:whyUs')}</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.STRUCTURE_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:structure')}</ListItemText>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => {navigate(AppRoutes.INFO_RECOGNITION_ROUTE)}}>
          <ListItemText inset>{i18next.t('intro:InfoRecognitions')}</ListItemText>
        </MenuItem>
        </MenuList>
        </div>
    )
}

export default MenuMobilePage