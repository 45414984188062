/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import EditIcon from "@mui/icons-material/Edit";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__articles.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { IconButton } from "rsuite";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { IFleetPlanUpdateBody } from "../../../../../Models/IFleetPlanUpdateBody";
import { IFleetPlanSetBody } from "../../../../../Models/IFleetPlanSetBody";
import { IArticleGet } from "../../../../../Models/IArticleGet";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const AdminPage__articles = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [article, setArticle] = useState<IArticleGet | null>(null);
  const [articles, setArticles] = useState<IArticleGet[]>([]);

  const loadArticles = () => {
    setLoading(true);
    ApiService.TicketController.GetTicketArticleList(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setArticles(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadArticles();
  }, []);

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          onClick={() => {
            setArticle(props.row);
            SummonModal("articleNewEditModal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const COLUMNS = [
    {
      field: "",
      headerName: "",
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "reducedPayment",
      headerName: i18next.t("navigation:article_reduced_payment") as string,
      type: "number"
    },
    {
      field: "fullPayment",
      headerName: i18next.t("navigation:article_full_payment") as string,
      type: "number"
    },
    {
      field: "licensePointsDeduction_",
      headerName: i18next.t("navigation:article_points_deduction") as string,
      type: "number"
    },
    {
      field: "licensePointsDeducted",
      headerName: i18next.t("navigation:article_points_deducted") as string,
      type: "number"
    },
    {
      field: "licenseSuspension_",
      headerName: i18next.t("navigation:article_license_suspension") as string,
      type: "number"
    },
    {
      field: "licenseSuspensionPeriod",
      headerName: i18next.t(
        "navigation:article_license_suspension_time"
      ) as string,
      type: "number"
    },
    {
      field: "article",
      headerName: i18next.t("navigation:article_article") as string,
    },
    {
      field: "comma",
      headerName: i18next.t("navigation:article_comma") as string,
    },
    {
      field: "ticketArticleDescription",
      headerName: i18next.t("navigation:article_description") as string,
    },
    {
      field: "extraSanction",
      headerName: i18next.t("navigation:article_extra_sanction") as string,
    },
  ];

  const insertArticle = (data: IArticleGet) => {
    setProcessing(true);
    ApiService.TicketController.InsertTicketArticle(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("articleNewEditModal");
          loadArticles();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const updateArticle = (data: IArticleGet) => {
    setProcessing(true);
    ApiService.TicketController.UpdateTicketArticle(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("articleNewEditModal");
          loadArticles();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteArticle = (ticketArticleID: number) => {
    setProcessing(true);
    ApiService.TicketController.DeleteTicketArticle(
      ticketArticleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("articleNewEditModal");
          loadArticles();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_articles")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setArticle(null);
                SummonModal("articleNewEditModal");
              }}
            >
              {i18next.t("form:nuovo")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap
            headers={COLUMNS}
            rows={articles.map((x: IArticleGet) => {
              (x as any)["licensePointsDeduction_"] = x[
                "licensePointsDeduction"
              ]
                ? i18next.t("message:yes")
                : i18next.t("message:no");
              (x as any)["licenseSuspension_"] = x["licenseSuspension"]
                ? i18next.t("message:yes")
                : i18next.t("message:no");

              return x;
            })}
          />
        </Box>
      </div>
      <SmartModal
        buttons={[
          {
            disabled: !article?.ticketArticleID,
            text: i18next.t("generic:delete"),
            onClick: () => {
              SummonModal("article-form-modal-delete")
            }
          },
          {
            text: i18next.t("form:save"),
            onClick: () => {
              triggerFormValidation("articleNewEditForm");
            },
          },
        ]}
        title={
          article
            ? i18next.t("navigation:article_edit")
            : i18next.t("navigation:article_new")
        }
        modalUniqueId="articleNewEditModal"
        modalInnerComponent={
          <div>
            <MultiForm
              suppressLayout
              suppressSubmit
              formTitle={""}
              inputs={[
                {
                  width: 50,
                  type: "text",
                  name: "article",
                  required: true,
                  label: i18next.t("navigation:article_article") ?? "",
                  defaultValue: article?.article,
                },
                {
                  width: 50,
                  type: "text",
                  name: "comma",
                  required: true,
                  label: i18next.t("navigation:article_comma") ?? "",
                  defaultValue: article?.comma,
                },
                {
                  width: 50,
                  type: "text",
                  name: "ticketArticleDescription",
                  required: true,
                  label: i18next.t("navigation:article_description") ?? "",
                  defaultValue: article?.ticketArticleDescription,
                },
                {
                  width: 50,
                  type: "text",
                  name: "reducedPayment",
                  required: true,
                  label: i18next.t("navigation:article_reduced_payment") ?? "",
                  defaultValue: article?.reducedPayment,
                },
                {
                  width: 50,
                  type: "text",
                  name: "fullPayment",
                  required: true,
                  label: i18next.t("navigation:article_full_payment") ?? "",
                  defaultValue: article?.fullPayment,
                },
                {
                  width: 25,
                  type: "switch",
                  name: "licensePointsDeduction",
                  required: true,
                  label: i18next.t("navigation:article_points_deduction") ?? "",
                  defaultValue: article?.licensePointsDeduction,
                },
                {
                  width: 25,
                  type: "switch",
                  name: "licenseSuspension",
                  required: true,
                  label:
                    i18next.t("navigation:article_license_suspension") ?? "",
                  defaultValue: article?.licenseSuspension,
                },
                {
                  width: 50,
                  type: "text",
                  name: "licensePointsDeducted",
                  required: true,
                  label: i18next.t("navigation:article_points_deducted") ?? "",
                  defaultValue: article?.licensePointsDeducted,
                },
                {
                  width: 50,
                  type: "text",
                  name: "licenseSuspensionPeriod",
                  required: true,
                  label:
                    i18next.t("navigation:article_license_suspension_time") ??
                    "",
                  defaultValue: article?.licenseSuspensionPeriod,
                },
                {
                  width: 50,
                  type: "text",
                  name: "extraSanction",
                  required: true,
                  label: i18next.t("navigation:article_extra_sanction") ?? "",
                  defaultValue: article?.extraSanction,
                },

                {
                  width: 50,
                  type: "date",
                  name: "validityEndDT",
                  required: true,
                  label: i18next.t("navigation:article_end_validity") ?? "",
                  defaultValue: article?.validityEndDT,
                },
              ]}
              formId="articleNewEditForm"
              onSubmit={(data: any) => {
                let dt = article ? { ...article, ...data } : { ...data };
                if (dt.ticketArticleID) {
                  updateArticle(dt);
                } else {
                  insertArticle(dt);
                }
              }}
            />
          </div>
        }
      />
      {article && (
        <SmartModal
          modalUniqueId="article-form-modal-delete"
          title={i18next.t("generic:titleRecordModal")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModal")
                  .replace("CURRENT_ITEM_LIST", "#" + article?.ticketArticleID),
              }}
            />
          }
          onAccept={() => {
            deleteArticle(article?.ticketArticleID);
          }}
          onReject={() => {}}
        />
      )}
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default AdminPage__articles;
