/* REACT */
import { useEffect, useState } from "react";

/* LIBRARY */
import i18next from "i18next";

/* STYLE */
import "./FormInsertSupply.scss";

/* COMPONENTS */
import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../MultiForm/MultiForm";

/* MUI */
import { Alert, Button, IconButton, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { VehiclesActions } from "../../Reducers/Vehicle/VehicleAction";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/Toastify";
import { useDispatch, useSelector } from "react-redux";
import { ICompanyGet } from "../../Models/ICompany";
import { IUser } from "../../Models/IUser";
import { IVehicleDataGet } from "../../Models/IVehicles";
import { GlobalState } from "../../Reducers/RootReducer";
import { formatPlate } from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import {
  IFuelSupplyType,
  IVehicleFuelSupplyInsert,
  IVehicleFuelSupplyUpdate,
} from "../../Models/IVehicleFuelSupply";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import AddNewBanner from "../AddNewBanner/AddNewBanner";
import AttachementMainHandler from "../AttachmentMainHandler/AttachmentMainHandler";
import { ISupplierGetSideList } from "../../Models/ISupplier";
import AddIcon from "@mui/icons-material/Add";
import FormSupplier from "../StepSupplier/FormSupplier";
import { JSONPrint } from "../../Utils/Decoder";

export interface IFormInsertSupplyProps {
  data?: any;
  fuelSupplyServices?: any[];
  doRefreshForm?: () => void;
  idModal?: string;
}

/* COMPONENT */
const FormInsertSupply = (props: IFormInsertSupplyProps) => {
  /* STATES */
  // State for data container
  const dispatch = useDispatch();
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const [state, setState] = useState<any>(props.data);
  const [loadingVehicles, setLoadingVehicles] = useState<boolean>(false);
  const [loadingFuelSupplyTypes, setLoadingFuelSupplyTypes] =
    useState<boolean>(false);
  const [fuelSupplyTypes, setFuelSupplyTypes] = useState<IFuelSupplyType[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fuelSupplyServices, setFuelSupplyServices] = useState<any[]>(
    props.data && props.data.fuelSupplyServices
      ? props.data.fuelSupplyServices
      : [
          {
            fuelSupplyServiceName: "",
            fuelSupplyServiceCost: 0,
          },
        ]
  );
  const [supplierList, setSupplierList] = useState<ISupplierGetSideList[]>([]);
  const [loadingSupplierList, setLoadingSupplierList] =
    useState<boolean>(false);

  const loadVehicles = () => {
    if (loggedCompany) {
      setLoadingVehicles(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let vehicles = response.payload;
            dispatch(VehiclesActions.setVehiclesForCompany(vehicles));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicles(false);
        }
      );
    }
  };

  const loadFuelSupplyType = () => {
    if (loggedUser) {
      setLoadingFuelSupplyTypes(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyTypeGet(
        (response: IAPIResponse) => {
          if (response.error === null) {
            setFuelSupplyTypes(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingFuelSupplyTypes(false);
        }
      );
    }
  };

  const loadSupplierList = () => {
    if (loggedUser) {
      setLoadingSupplierList(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "FUEL_CARD",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierList(false);
        }
      );
    }
  };

  const insertFuelSupply = (data: IVehicleFuelSupplyInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:fuelSupplySuccessfullAdded"),
              "success"
            );
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateFuelSupply = (data: IVehicleFuelSupplyUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:fuelSupplySuccessfullUpdated"),
              "success"
            );
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const deleteFuelSupply = (fuelSupplyID: number) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleFuelSupplyController.VehicleFuelSupplyDelete(
        fuelSupplyID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:fuelSupplySuccessfullDeleted"),
              "success"
            );
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    multiformInputs.push(
      {
        width: 25,
        type: "select",
        name: "vehicleID",
        label: i18next.t("form:plate") as string,
        required: true,
        defaultValue: state?.vehicleID,
        options: vehicles.map((x: IVehicleDataGet) => {
          return {
            key: x.vehicleID,
            text: formatPlate(x.vehicleLicensePlate),
          };
        }),
      },
      {
        width: 25,
        type: "text",
        name: "fuelCardNumber",
        label: i18next.t("form:numberFuelCard") as string,
        defaultValue: state?.fuelCardNumber,
        inputProps: {
          maxLength: 50,
        },
      },
      {
        width: 25,
        type: "select",
        name: "fuelCardID",
        label: i18next.t("form:fuelCardType") as string,
        required: true,
        disabled: true,
        defaultValue: "1",
        options: [{ key: "1", text: i18next.t("form:private") }].map(
          (x: { key: string; text: string }) => {
            return {
              key: x.key,
              text: x.text,
            };
          }
        ),
      },
      {
        width: 25,
        type: "select",
        name: "fuelSupplyTypeID",
        required: true,
        defaultValue: state?.fuelSupplyTypeID,
        label: i18next.t("form:product") as string,
        options: fuelSupplyTypes.map((x: IFuelSupplyType) => {
          return {
            key: x.fuelSupplyTypeID,
            text: x.fuelSupplyTypeName.toString(),
          };
        }),
      },
      {
        width: 25,
        type: "datetime",
        name: "fuelSupplyDT",
        required: true,
        label: i18next.t("form:refillDate") as string,
        defaultValue: state?.fuelSupplyDT,
      },
      {
        width: 25,
        type: "select",
        name: "supplierID",
        required: true,
        defaultValue: state?.supplierID,
        label: String(i18next.t(`form:supplierVehicleService`)),
        options: supplierList.map((supplier: ISupplierGetSideList) => {
          return {
            key: supplier.supplierInfo.supplierID,
            text: supplier.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: 10,
        type: "custom",
        name: "supplierAddType",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              variant="contained"
              onClick={() => {
                SummonModal("Supplier-supply-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      },
      {
        width: 25,
        type: "text",
        name: "fuelSupplyPlace",
        defaultValue: state?.fuelSupplyPlace,
        label: i18next.t("form:pointOfSale") as string,
      },
      {
        width: 15,
        type: "number",
        name: "fuelSupplyKm",
        label: i18next.t("form:km") as string,
        defaultValue: state?.fuelSupplyKm,
        inputProps: {
          max: 1000000,
        },
      },
      {
        type: "radio",
        name: "fuelSupplyServed",
        defaultValue: state?.fuelSupplyServed ? "0" : "1",
        label: i18next.t("form:served").toString(),
        width: 33.33,
        required: false,
        options: [
          {
            key: "0",
            text: i18next.t("message:yes"),
          },
          {
            key: "1",
            text: i18next.t("message:no"),
          },
        ],
      },
      {
        width: 33.33,
        type: "number",
        defaultValue: state?.fuelSupplyAmount,
        required: true,
        inputProps: {
          step: "0.01",
        },
        name: "fuelSupplyAmount",
        label: i18next.t("form:fuelSupplyAmount") as string,
        inputAdornament: {
          adornament: state?.fuelSupplyTypeID
            ? fuelSupplyTypes.find(
                (x: IFuelSupplyType) =>
                  x.fuelSupplyTypeID === state?.fuelSupplyTypeID
              )?.measurementUnitName +
                " (" +
                fuelSupplyTypes.find(
                  (x: IFuelSupplyType) =>
                    x.fuelSupplyTypeID === state?.fuelSupplyTypeID
                )?.measurementUnitSymbol +
                ")" ?? ""
            : "",
        },
      },
      {
        width: 33.33,
        type: "number",
        required: true,
        defaultValue: state?.fuelSupplyCost,
        name: "fuelSupplyCost",
        inputProps: {
          step: "0.01",
        },
        label: i18next.t("form:fuelSupplyCost") as string,
        inputAdornament: { adornament: "€" },
      },
      {
        width: 100,
        type: "custom",
        name: "buttons",
        element: (
          <Alert severity="info">
            <div>{i18next.t("navigation:_other_costs_supply")}</div>
          </Alert>
        ),
      }
    );

    for (let i = 0; i < fuelSupplyServices.length; i++) {
      let rowData: any = fuelSupplyServices[i];
      multiformInputs.push(
        {
          width: 40,
          type: "text",
          name: "fuelSupplyServiceName_" + i,
          defaultValue: rowData.fuelSupplyServiceName,
          required: false,
          label: i18next.t("form:nameService").toString(),
        },
        {
          width: 30,
          type: "number",
          name: "fuelSupplyServiceCost_" + i,
          inputProps: {
            step: "0.01",
          },
          defaultValue: +rowData.fuelSupplyServiceCost,
          required: false,
          label: i18next.t("form:serviceImport").toString(),
          inputAdornament: { adornament: "€" },
        },
        {
          width: 5,
          type: "custom",
          className: "insurance-delete-button",
          name: "delete",
          id: "delete_" + i,
          element: (
            <Tooltip title={i18next.t("navigation:remove_fuelsupply_detail")}>
              <IconButton
                color="primary"
                component="label"
                onClick={() => {
                  let tmpfuelSupplyServices = [...fuelSupplyServices].filter(
                    (x: any, j: number) => j !== i
                  );
                  setFuelSupplyServices(tmpfuelSupplyServices);
                  let state_: any = { ...state };
                  delete state_["fuelSupplyServiceName_" + i];
                  delete state_["fuelSupplyServiceCost_" + i];
                  setState(state_);
                  requestFormRefresh();
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          ),
        }
      );
    }

    multiformInputs.push({
      width: 100,
      type: "custom",
      name: "addNewFuelSupplyService",
      element: (
        <AddNewBanner
          forceDisplay
          suppressLayout
          label={i18next.t("generic:newFuelSupplyService")}
          new={() => {
            let fuelSupplyServices_ = [...fuelSupplyServices];
            fuelSupplyServices_.push({
              fuelSupplyServiceName: "",
              fuelSupplyServiceCost: 0,
            });
            setFuelSupplyServices(fuelSupplyServices_);
          }}
        />
      ),
    });

    return multiformInputs;
  };

  const FromFormToDataVetor = (data: any) => {
    let keys: string[] = Object.keys(data);
    let newFuelSupplyServices: any = {};
    for (let i = 0; i < fuelSupplyServices.length; i++) {
      let item: any = fuelSupplyServices[i];
      if (!newFuelSupplyServices[i]) {
        newFuelSupplyServices[i] = {};
      }
      newFuelSupplyServices[i] = { ...item };
    }
    
    for (let i = 0; i < keys.length; i++) {
      let splKey: string[] = keys[i].split("_");
      if (splKey.length === 2) {
        let keyName: string = splKey[0];
        let keyIndex: string = splKey[1];
        if (data[keys[i]] !== null && data[keys[i]] !== undefined && newFuelSupplyServices[keyIndex] !== undefined) {
          newFuelSupplyServices[keyIndex][keyName] = data[keys[i]];
        }
      }
    }
    let tmp: any[] = Object.values(newFuelSupplyServices);
    setFuelSupplyServices(tmp);
  };

  useEffect(() => {
    loadVehicles();
    loadFuelSupplyType();
    loadSupplierList();
  }, []);

  /* FUNCTIONS */

  /* RETURN */
  return (
    <div className="supply-insert-modal-wrapper">
      <MultiForm
        suppressLayout
        suppressSubmit
        formId={"form-modal-supply"}
        inputs={multiformInputs()}
        onChange={(data: any) => {
          setState({ ...state, ...data });
          FromFormToDataVetor({ ...state, ...data });
        }}
        onSubmit={(data: any) => {
          let parsedData = { ...state, ...data };
          if (
            fuelSupplyServices &&
            fuelSupplyServices.length > 0 &&
            fuelSupplyServices[0].fuelSupplyServiceName !== undefined
          ) {
            parsedData["fuelSupplyFuelSupplyServices"] = fuelSupplyServices;
          }
          delete parsedData["supplierAddType"];
          delete parsedData["buttons"];
          delete parsedData["fuelCardID"];
          delete parsedData["addNewFuelSupplyService"];
          delete parsedData["delete"];
          for (let i = 0; i < fuelSupplyServices.length; i++) {
            delete parsedData["fuelSupplyServiceName_" + i];
            delete parsedData["fuelSupplyServiceCost_" + i];
          }
          parsedData["fuelSupplyServed"] =
            parsedData["fuelSupplyServed"] === "0" ? true : false;
          parsedData["fuelSupplyCost"] = +parsedData["fuelSupplyCost"];
          parsedData["fuelSupplyAmount"] = +parsedData["fuelSupplyAmount"];
          parsedData["fuelSupplyKm"] = +parsedData["fuelSupplyKm"];
          if (state?.fuelSupplyID) {
            updateFuelSupply(parsedData);
          } else {
            insertFuelSupply(parsedData);
          }
        }}
      />
      <AttachementMainHandler
        attachmentFamily={"fuelsupply"}
        extraGetParametersVector={
          state?.fuelSupplyID
            ? [
                {
                  name: "fuelSupplyID",
                  value: state?.fuelSupplyID,
                },
              ]
            : undefined
        }
      />

      <div className="display-flex-end padding-right-extrasmall">
        <Button
          color="error"
          sx={{ marginRight: "1em" }}
          disabled={state && !state?.fuelSupplyID}
          onClick={() => {
            SummonModal("delete-fuel-supply-modal");
          }}
          variant="contained"
          endIcon={<DeleteIcon />}
        >
          {i18next.t("generic:delete")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            triggerFormValidation("form-modal-supply");
          }}
          endIcon={<SaveIcon />}
        >
          {i18next.t("generic:save")}
        </Button>
      </div>

      <SmartModal
        modalUniqueId="delete-fuel-supply-modal"
        title={i18next.t("generic:titleRecordModal")}
        modalInnerComponent={
          <p
            dangerouslySetInnerHTML={{
              __html: i18next
                .t("generic:descriptionRecordModal")
                .replace("CURRENT_ITEM_LIST", state?.fuelSupplyID),
            }}
          />
        }
        onAccept={() => {
          deleteFuelSupply(state?.fuelSupplyID);
        }}
        onReject={() => {}}
      />
      <SmartModal
        modalUniqueId="Supplier-supply-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            loggedUser={loggedUser}
            data={[]}
            forceSupplierTypeCode={"FUEL_CARD"}
            idModal="Supplier-supply-modal"
            updateList={(data: ISupplierGetSideList) => {
              let supplierList_: ISupplierGetSideList[] = [...supplierList];
              supplierList_.push(data);
              setSupplierList(supplierList_);
            }}
          />
        }
      />
      <LoaderBackdrop
        loading={
          loadingVehicles ||
          loadingFuelSupplyTypes ||
          processing ||
          loadingSupplierList
        }
      />
    </div>
  );
};

export default FormInsertSupply;
