import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { UserActions } from "../../Reducers/User/UserAction";

import "./LogoutPage.scss";

import i18next from "i18next";

import { CircularProgress } from "@mui/material";

const LogoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(UserActions.setUser(undefined));
      localStorage.removeItem("logged-user");
      localStorage.removeItem("sessionToken");
      sessionStorage.clear();
      navigate(AppRoutes.LOGIN_ROUTE);
    }, 2000);
  }, []);

  /* RETURN */
  return (
    <div>
      <div className="logout-spinner-wrap display-flex-center-column">
        <p
          className="margin-vertical-medium font-size-medium"
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:logoutProgress") ?? "",
          }}
        />
        <CircularProgress />
      </div>
    </div>
  );
};

export default LogoutPage;
