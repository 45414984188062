import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

import { IAttachment } from "../../Models/IAttachments";

const AttachmentController = {
  // Insert the attachment
  GetAttachment: async (
    url: string,
    vehicleID: number,
    extraSearchParams?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = url;
    if (vehicleID && extraSearchParams) {
      request.url += `?vehicleID=${vehicleID}&${extraSearchParams}`;
    } else {
      if (vehicleID) {
        request.url += `?vehicleID=${vehicleID}`;
      }
      if (extraSearchParams) {
        request.url += "?" + extraSearchParams;
      }
    }

    request.method = "GET";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  // Insert the attachment
  InsertAttachment: async (
    url: string,
    body: IAttachment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = url;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Update the attachment
  UpdateAttachment: async (
    url: string,
    body: IAttachment,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = url;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Delete the attachment
  DeleteAttachment: async (
    url: string,
    data: any,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = url;
    request.method = "DELETE";
    request.payload = data;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AttachmentController;
