import "./BannerVideoComponent.scss";
import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import AppRoutes from "../../Costants/AppRoutes";
import { useNavigate } from "react-router-dom";

export type BannerVideoProps = {
  filename: string;
  title: React.ReactNode;
  withButtons?: boolean;
  onScrollToSection?: () => void;
};

export const FFButtonContainer = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#2A3644",
  backgroundColor: "#ffffff",
  fontWeight: "bold",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#BFE7F8",
  },
}));

export const FFButtonOutlined = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#ffffff",
  border: "1px solid #ffffff",
  fontWeight: "bold",
  padding: "10px",
  "&:hover": {
    color: "#BFE7F8",
    borderColor: "#BFE7F8",
  },
}));

export const BannerVideoComponent: React.FC<BannerVideoProps> = ({
  filename,
  title,
  withButtons,
  onScrollToSection,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const evaluateContentHeight = () => {
      if (!overlayRef.current || !videoRef.current) return;
      videoRef.current.height = overlayRef.current.getBoundingClientRect().height;
    };

    evaluateContentHeight();
    window.addEventListener("resize", evaluateContentHeight);

    return () => {
      window.removeEventListener("resize", evaluateContentHeight);
    };
  }, []);

  return (
    <>
      <div className="ff-video-background-container">
        <video ref={videoRef} autoPlay muted loop playsInline controls={false}>
          <source src={`/video/${filename}`} type="video/mp4" />
        </video>

        <div ref={overlayRef} className="ff-overlay">
          <div className="ff-text-overlay">
            <p className="ff-title-homepage">{title}</p>
            {withButtons && (
              <FFButtonContainer className="ff-btn-homepage" onClick={onScrollToSection}>
                {t("navigation:findoutmode")}
              </FFButtonContainer>
            )}
            {withButtons && (
              <FFButtonOutlined
                onClick={() => {
                  navigate(AppRoutes.REGISTRATION_ROUTE);
                }}
              >
                {" "}
                {t("intro:freeDay")}
              </FFButtonOutlined>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerVideoComponent;
