import RefreshIcon from "@mui/icons-material/Refresh";
import { Alert, Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";
import {
  DragAndDropper_DragItem,
  DragAndDropper_Receiver,
} from "../DragAndDropper/DragAndDropper";
import "./StepDashboardMapFileTemplate.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import {
  ITemplate,
  ITemplateInsert,
  ITemplateInsertProperty,
  ITemplateProperty,
  ITemplatePropertyMapping,
} from "../../Models/ITemplate";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ICompanyGet } from "../../Models/ICompany";
import { GenericActions } from "../../Reducers/Generic/GenericAction";

export interface StepDashboardMapFileTemplateProps {
  templateTypeID: number;
}

const StepDashboardMapFileTemplate = (
  props: StepDashboardMapFileTemplateProps
) => {
  const dispatch = useDispatch();
  const templateGenericFormData: any = useSelector(
    (state: GlobalState) => state.generic.templateGenericWorkingData
  );

  const [errorMapping, setErrorMapping] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [templateCreated, setTemplateCreated] = useState<ITemplate | null>(
    null
  );

  const [fileMapConfiguration, setFileMapConfiguration] = useState<
    ITemplatePropertyMapping[]
  >([]);

  const [fileMapTableInitial, setFileMapTableInitial] = useState<
    ITemplateProperty[]
  >([]);
  const [fileMapTable, setFileMapTable] = useState<ITemplateProperty[]>([]);

  const reload = () => {
    setProcessing(true);
    setFileMapConfiguration([]);
    setFileMapTable([...fileMapTableInitial]);
    setTimeout(() => {
      setProcessing(false);
    }, 500);
  };

  const getTemplateMapData = () => {
    setLoading(true);
    ApiService.TemplateController.TemplateGetSysProperty(
      props.templateTypeID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setFileMapTableInitial(response.payload);
          setFileMapTable(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const sendMappedRows = () => {
    setErrorMapping(false);
    if (
      templateGenericFormData &&
      templateGenericFormData.formData &&
      templateGenericFormData.formData.templateID > 0
    ) {
      setProcessing(true);
      let data: ITemplateInsertProperty = {
        templateID: templateGenericFormData.formData.templateID,
        templateProperties: fileMapConfiguration,
      };
      ApiService.TemplateController.TemplateInsertSysPropertyMapping(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(
              GenericActions.setTemplateGenericWorkingData({
                ...templateGenericFormData,
                template: templateCreated,
                templateTypeID: props.templateTypeID,
              })
            );

            stepperGoToPage(2);
          } else {
            ToastMessage(i18next.t("error:error_mapping_data"), "error");
            setErrorMapping(true);
          }
          setProcessing(false);
        }
      );
    }
  };

  useEffect(() => {
    if (
      templateGenericFormData &&
      templateGenericFormData.formData &&
      templateGenericFormData.formData.templateID > 0
    ) {
      getTemplateMapData();
    }
  }, []);

  const getDefaultValueForDragAndDropper = (rowIndex: number) => {
    let targetMapName: string = "";
    let h: string = templateGenericFormData.headers[rowIndex];
    let targetMap: ITemplatePropertyMapping | undefined =
      fileMapConfiguration.find(
        (x: ITemplatePropertyMapping) => x.templateUserPropertyName === h
      );

    if (targetMap) {
      let item: ITemplateProperty | undefined = fileMapTableInitial.find(
        (x: ITemplateProperty) =>
          x.templateSystemPropertyName === targetMap?.templateUserPropertyName
      );
      if (item) {
        targetMapName = item.templateSystemPropertyName;
      }
    }

    return targetMapName;
  };

  return (
    <div>
      {!loading && !processing && (
        <div>
          <div className="map-file-wrapper">
            {errorMapping && (
              <Alert severity="error">
                {i18next.t("error:error_mapping_data")}
              </Alert>
            )}
            <div className="table-map-file-wrapper">
              <div className="table-to-map">
                <div className="colums-table">
                  <div className="header-table">
                    <strong>
                      {i18next.t(
                        "navigation:_forniture_page_header_stepper2_table1_title"
                      )}
                    </strong>
                  </div>
                  <div className="subtitle-table">
                    {i18next.t(
                      "navigation:_forniture_page_header_stepper2_table1_subtitle"
                    )}
                  </div>
                  {templateGenericFormData.headers.map(
                    (el: string, i: number) => {
                      return <div className="content-column">{el}</div>;
                    }
                  )}
                </div>
                <div className="colums-table">
                  <div className="header-table">
                    <strong>
                      {i18next.t(
                        "navigation:_forniture_page_header_stepper2_table2_title"
                      )}
                    </strong>
                  </div>
                  <div className="subtitle-table">
                    {i18next.t(
                      "navigation:_forniture_page_header_stepper2_table2_subtitle"
                    )}
                  </div>

                  {templateGenericFormData.headers
                    .sort((a: string, b: string) => {
                      return a.localeCompare(b);
                    })
                    .map((el: string, i: number) => {
                      return (
                        <DragAndDropper_Receiver
                          key={i}
                          defaultValue={getDefaultValueForDragAndDropper(i)}
                          onDropped={(
                            oldValue: string | undefined,
                            value: string
                          ) => {
                            let prop: ITemplateProperty | undefined =
                              fileMapTable.find(
                                (el: ITemplateProperty) =>
                                  el.templateSystemPropertyName === value
                              );
                            if (prop) {
                              let conf: ITemplatePropertyMapping[] = [
                                ...fileMapConfiguration,
                              ];
                              conf.push({
                                templateSystemPropertyID:
                                  prop.templateSystemPropertyID,
                                templateUserPropertyName:
                                  templateGenericFormData.headers[i],
                              });
                              setFileMapConfiguration(conf);

                              let newList = fileMapTable.filter(
                                (el: ITemplateProperty) =>
                                  el.templateSystemPropertyName !== value &&
                                  value !== oldValue
                              );

                              if (oldValue) {
                                let old: ITemplateProperty | undefined =
                                  fileMapTableInitial.find(
                                    (x: ITemplateProperty) =>
                                      x.templateSystemPropertyName === oldValue
                                  );
                                if (old) {
                                  newList.push(old);
                                }
                              }

                              setFileMapTable(newList);
                            } else {
                              let newList = [...fileMapTable];
                              let old: ITemplateProperty | undefined =
                                fileMapTableInitial.find(
                                  (x: ITemplateProperty) =>
                                    x.templateSystemPropertyName === oldValue
                                );
                              if (old) {
                                newList.push(old);
                              }
                              setFileMapTable(newList);
                            }
                          }}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="colums-table-map">
                <div className="voice-to-map">
                  <div className="header-table">
                    <strong>
                      {i18next.t(
                        "navigation:_forniture_page_header_stepper2_table3_title"
                      )}
                    </strong>
                    <Button
                      style={{ color: "black" }}
                      onClick={() => {
                        reload();
                      }}
                    >
                      <RefreshIcon />
                    </Button>
                  </div>
                  <div className="subtitle-table">
                    {i18next.t(
                      "navigation:_forniture_page_header_stepper2_table3_subtitle"
                    )}
                  </div>
                  {fileMapTable
                    .sort((a: ITemplateProperty, b: ITemplateProperty) => {
                      return a.templateSystemPropertyName.localeCompare(
                        b.templateSystemPropertyName
                      );
                    })
                    .map((el: ITemplateProperty, i: number) => {
                      return (
                        <DragAndDropper_DragItem
                          key={i}
                          value={el.templateSystemPropertyName}
                          text={
                            el.templateSystemPropertyName +
                            "" +
                            (el.mandatory ? " *" : "")
                          }
                        />
                      );
                    })}
                </div>
                <div className="button-confirm">
                  <Button
                    disabled={
                      fileMapTable.filter((x: ITemplateProperty) => x.mandatory)
                        .length > 0
                    }
                    variant="contained"
                    onClick={() => sendMappedRows()}
                  >
                    {i18next.t("navigation:next")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default StepDashboardMapFileTemplate;
