import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import { Button, IconButton, Tooltip } from "@mui/material";
import i18next, { use } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ICompanyGet } from "../../../Models/ICompany";
import {
  IInsuranceDetail,
  IInsuranceGet,
  IInsuranceGetDetailType,
  IInsuranceInsert,
  IInsuranceUpdate,
} from "../../../Models/IInsurance";
import EditIcon from "@mui/icons-material/Edit";
import { IPaymentType } from "../../../Models/IPayment";
import { ISupplierGetSideList } from "../../../Models/ISupplier";
import { IUser } from "../../../Models/IUser";
import { IVehicleDataGet } from "../../../Models/IVehicles";
import { GlobalState } from "../../../Reducers/RootReducer";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../Utils/Toastify";
import AddNewBanner from "../../AddNewBanner/AddNewBanner";
import AttachementMainHandler from "../../AttachmentMainHandler/AttachmentMainHandler";
import LoaderBackdrop from "../../LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
  requestFormRefresh,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import { displayUTC0_ISODate } from "../../MultiForm/SpecialInputs/StrongDatePicker";
import SmartModal, { DismissModal, SummonModal } from "../../SmartModal/SmartModal";
import FormSupplier from "../../StepSupplier/FormSupplier";
import AppRoutes from "../../../Costants/AppRoutes";
import { IVehicleDataByAccountID } from "../../../Models/IVehicleDataByAccountID";
import { formatPlate } from "../../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/VehiclesPage";
import DataGridWrap, { IDataGridColumnDef } from "../../DataGridWrap/DataGridWrap";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { GetVehicleMinDate } from "../../../Pages/DashboardPage/DashboardPage";
import { IVehicleServicePaymentRecurrenceType } from "../../../Models/IVehicleService";
import { JSONPrint, currencyDecoder } from "../../../Utils/Decoder";

export interface IFormInsuranceProps {
  loggedUser: IUser | undefined;
  dataVehicle: IVehicleDataGet | any;
  disabled?: boolean;
  disabledAccordion?: boolean;
  doRefreshForm?: () => void;
  onDataEdited?: () => void;
  emitCoreLists?: (
    suppInsuranceList: ISupplierGetSideList[],
    detailGetType: IInsuranceGetDetailType[],
    paymentType: IPaymentType[],
    insurances: IInsuranceGet[]
  ) => void;
  suppressWarning?: boolean;
  loadingInsurance?: boolean;
  vehicleData?: IVehicleDataByAccountID[];
  expressInsuranceData?: IInsuranceGet;
}

let stateClone: any = {};
const FormInsurance = (props: IFormInsuranceProps) => {
  const mainCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [loadingSupplierInsurance, setLoadingSupplierInsurance] =
    useState<boolean>(false);
  const [supplierInsurance, setSupplierInsurance] = useState<ISupplierGetSideList[]>([]);
  const [insuranceDetailGetType, setInsuranceDetailGetType] = useState<
    IInsuranceGetDetailType[]
  >([]);
  const [insurances, setInsurances] = useState<IInsuranceGet[]>([]);
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);

  const [loadingInsuranceDetailGetType, setLoadingInsuranceDetailGetType] =
    useState<boolean>(false);
  const [state, setState_] = useState<any>(null);

  const [loadingCurrentInsurance, setLoadingCurrentInsurance] = useState<boolean>(false);
  const [loadingInsurance, setLoadingInsurance] = useState<boolean>(false);

  const [loadingPaymentType, setLoadingPaymentType] = useState<boolean>(false);
  const isInDashBoard = window.location.href.includes(AppRoutes.INTERNAL_DASHBOARD);

  const [insuranceRowTable, setInsuranceRowtable] = useState<IInsuranceDetail[]>([
    {
      insuranceDetailTypeID: 0,
      covarageLimit: null,
      deductible: null,
      covarage: null,
    },
  ]);

  const [paymentRecurrenceType, setPaymentRecurrenceType] = useState<
    IVehicleServicePaymentRecurrenceType[]
  >([]);
  const [loadingPaymentRecurrenceType, setLoadingPaymentRecurrenceType] =
    useState<boolean>(false);

  const [insuranceDetailTypeIDs, setInsuranceDetailTypeIDs] = useState<string[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [vehicleInfo, setVehicleInfo] = useState<IVehicleDataByAccountID | null>(null);

  const FromFormToDataVetor = (data: any) => {
    let keys: string[] = Object.keys(data);
    let newInsuranceRowTable: any = {};

    for (let i = 0; i < insuranceRowTable.length; i++) {
      let item: IInsuranceDetail = insuranceRowTable[i];
      if (!newInsuranceRowTable[i]) {
        newInsuranceRowTable[i] = {};
      }
      newInsuranceRowTable[i] = { ...item };
    }

    for (let i = 0; i < keys.length; i++) {
      let splKey: string[] = keys[i].split("_");
      if (splKey.length === 2) {
        let keyName: string = splKey[0];
        let keyIndex: string = splKey[1];
        if (data[keys[i]] !== null && data[keys[i]] !== undefined) {
          if (newInsuranceRowTable[keyIndex]) {
            newInsuranceRowTable[keyIndex][keyName] = data[keys[i]];
          }
        }
      }
    }
    let tmp: IInsuranceDetail[] = Object.values(newInsuranceRowTable);
    setInsuranceRowtable(tmp);
  };

  const loadSupplierInsurance = () => {
    if (props.loggedUser) {
      setLoadingSupplierInsurance(true);
      ApiService.SupplierController.SupplierGetSideList(
        null,
        null,
        "INSURANCE",
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSupplierInsurance(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSupplierInsurance(false);
        }
      );
    }
  };

  const loadInsuranceDetailGetType = () => {
    if (props.loggedUser) {
      setLoadingInsuranceDetailGetType(true);
      ApiService.InsuranceController.InsuranceGetDetailType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setInsuranceDetailGetType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsuranceDetailGetType(false);
        }
      );
    }
  };

  const loadPaymentType = () => {
    if (props.loggedUser) {
      setLoadingPaymentType(true);
      ApiService.PaymentController.GetPaymentType(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentType(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentType(false);
        }
      );
    }
  };

  const setState = (state: any) => {
    stateClone = JSON.parse(JSON.stringify(state));
    setState_(state);
  };

  // Merge the keys of state and data
  const mergeObjects = (data: any) => {
    if (stateClone && data) {
      let state_: any = { ...stateClone };
      let keys: string[] = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (data[key]) {
          state_[key] = data[key];
        }
      }

      return state_;
    }

    return {};
  };

  useEffect(() => {
    if(props.vehicleData && state?.vehicleID){
      setVehicleInfo(props.vehicleData.filter((x: IVehicleDataByAccountID) => x.vehicleID === state.vehicleID)[0]);
    }
  }, [props.vehicleData, state?.vehicleID]);

  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];
    if (props.vehicleData) {
      multiformInputs.push({
        width: 25,
        type: "select",
        required: true,
        disabled: props.disabled,
        name: "vehicleID",
        label: i18next.t("form:plate") as string,
        defaultValue: state?.vehicleID ?? "",
        options: props.vehicleData.map((item: IVehicleDataByAccountID) => {
          return {
            key: item.vehicleID,
            text: formatPlate(item.vehicleLicensePlate),
          };
        }),
      });
    }
    multiformInputs.push(
      {
        width: props.vehicleData ? 20 : 30,
        type: "select",
        required: true,
        disabled: props.disabled,
        name: "supplierID",
        label: i18next.t("form:vehicleInsuranceCompany") as string,
        defaultValue: state?.supplierID ?? "",
        options: supplierInsurance.map((item: ISupplierGetSideList) => {
          return {
            key: item.supplierInfo.supplierID.toString(),
            text: item.supplierInfo.supplierName.toString(),
          };
        }),
      },
      {
        width: props.vehicleData ? 5 : 10,
        type: "custom",
        name: "supplierAddType",
        element: (
          <div className="multi-form-button-add-record">
            <Button
              disabled={props.disabled}
              fullWidth
              variant="contained"
              onClick={() => {
                SummonModal("Supplier-Insurance-modal");
              }}
            >
              <AddIcon />
            </Button>
          </div>
        ),
      },
      {
        width: props.vehicleData ? 25 : 30,
        required: true,
        type: "datetime",
        name: "startDT",
        disabled: props.disabled,
        label: i18next.t("form:dateStart") as string,
        defaultValue: state?.startDT,
        minDate: GetVehicleMinDate(props.dataVehicle ?? vehicleInfo),
      },
      {
        width: props.vehicleData ? 25 : 30,
        required: true,
        type: "datetime",
        name: "endDT",
        disabled: props.disabled,
        label: i18next.t("form:dateFinish") as string,
        defaultValue: state?.endDT,
        minDate: state?.startDT,
      }
    );

    for (let i = 0; i < insuranceRowTable.length; i++) {
      let rowData: IInsuranceDetail = insuranceRowTable[i];
      multiformInputs.push(
        {
          type: "hidden",
          name: `insuranceDetailID_${i}`,
          defaultValue: rowData.insuranceDetailID,
        },
        {
          width: 5,
          name: "row",
          type: "custom",
          className: "custom-add-row-wrapper",
          element: (
            <div
              className={
                "row-added-line-wrapper" + (props.disabled ? " disabled-line" : "")
              }
            >
              <div className="vertical-line"></div>
              <div className="horizontal-line"></div>
            </div>
          ),
        },
        {
          width: 30,
          type: "select",
          required: true,
          disabled: props.disabled,
          name: `insuranceDetailTypeID_${i}`,
          label: i18next.t("form:insuranceDetailType") as string,
          defaultValue: rowData.insuranceDetailTypeID,
          options: insuranceDetailGetType.map(
            (insuranceDetail: IInsuranceGetDetailType) => {
              return {
                key: insuranceDetail.insuranceDetailTypeID,
                text: insuranceDetail.insuranceDetailTypeName.toString(),
                disabled:
                  insuranceDetailTypeIDs.indexOf(
                    insuranceDetail.insuranceDetailTypeID.toString()
                  ) > -1,
              };
            }
          ),
        },
        {
          width: 20,
          type: "number",
          required: true,
          disabled: props.disabled,
          defaultValue: rowData.covarageLimit,
          name: `covarageLimit_${i}`,
          label: i18next.t("form:insuranceMaxValue") as string,
          inputAdornament: {
            adornament: "€",
          },
        },
        {
          width: 20,
          type: "number",
          required: true,
          disabled: props.disabled,
          defaultValue: rowData.deductible,
          name: `deductible_${i}`,
          label: i18next.t("form:serviceShoreLeave") as string,
          inputAdornament: {
            adornament: "€",
          },
        },
        {
          width: 20,
          type: "number",
          required: true,
          defaultValue: rowData.covarage,
          disabled: props.disabled,
          name: `covarage_${i}`,
          inputAdornament: {
            adornament: "%",
          },
          inputProps: {
            max: 100,
          },
          label: i18next.t("form:insuranceValue") as string,
        },
        {
          width: 5,
          type: "custom",
          className: "insurance-delete-button",
          name: "delete",
          id: "delete_" + i,
          element: (
            <Tooltip title={i18next.t("navigation:remove_insurance_detail")}>
              <IconButton
                color="primary"
                component="label"
                disabled={props.disabled}
                onClick={() => {
                  if (
                    insuranceRowTable &&
                    insuranceRowTable[i]?.insuranceDetailID &&
                    insuranceRowTable[i].insuranceDetailID !== undefined
                  ) {
                    insuranceDetailDelete(insuranceRowTable[i].insuranceDetailID);
                  }
                  let tmpInsuranceRowTable = [...insuranceRowTable].filter(
                    (x: any, j: number) => j !== i
                  );
                  setInsuranceRowtable(tmpInsuranceRowTable);
                  requestFormRefresh();
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          ),
        }
      );
    }

    if (!props.disabled) {
      multiformInputs.push(
        {
          width: 100,
          type: "custom",
          name: "addInsuranceCompany",
          element: (
            <div className="container-add-form padding-0">
              <div
                onClick={() => {
                  let tmpInsuranceRowTable = [...insuranceRowTable];
                  tmpInsuranceRowTable.push({
                    insuranceDetailTypeID: 0,
                    covarageLimit: null,
                    deductible: null,
                    covarage: null,
                  });

                  setInsuranceRowtable([]);
                  setTimeout(() => {
                    setInsuranceRowtable(tmpInsuranceRowTable);
                  }, 50);
                }}
              >
                <span className="button-add-form">
                  <AddCircleIcon />
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18next.t("generic:newInsuranceCompany") ?? "",
                  }}
                />
              </div>
            </div>
          ),
        },
        {
          width: 25,
          type: "select",
          required: true,
          name: "paymentTypeID",
          label: i18next.t("form:insuranceModePayment") as string,
          defaultValue: state?.paymentTypeID ?? "",
          disabled: props.disabled,
          options: paymentType.map((item: IPaymentType) => {
            return {
              key: item.paymentTypeID,
              text: item.paymentTypeName.toString(),
            };
          }),
        },
        {
          type: "select",
          width: 25,
          disabled: props.disabled,
          required: true,
          defaultValue: state?.paymentRecurrenceID,
          name: "paymentRecurrenceID",
          label: i18next.t("form:insuranceRecurrence") as string,
          options: paymentRecurrenceType.map(
            (item: IVehicleServicePaymentRecurrenceType) => {
              return {
                key: item.paymentRecurrenceID,
                text: item.paymentRecurrenceName,
              };
            }
          ),
        },
        {
          width: 25,
          type: "number",
          name: "amount",
          disabled: props.disabled,
          label: i18next.t("form:insuranceImportInstallment") as string,
          defaultValue: state?.amount,
          inputAdornament: {
            adornament: "€",
          },
          required: true,
        },
        {
          width: 25,
          type: "text",
          name: "policyNumber",
          disabled: props.disabled,
          inputProps: { maxLength: 50 },
          label: i18next.t("form:insuranceNumber") as string,
          defaultValue: state?.policyNumber,
          required: true,
        }
      );
    }

    return multiformInputs;
  };

  const newInsurance = () => {
    setState(null);
    if (props.doRefreshForm) {
      props.doRefreshForm();
    }
  };

  const loadInsurance = (vehicleID: number) => {
    if (props.loggedUser) {
      setLoadingInsurance(true);
      ApiService.InsuranceController.InsuranceGet(
        null,
        vehicleID,
        mainCompany?.companyID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setInsurances(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingInsurance(false);
        }
      );
    }
  };

  const loadCurrentInsurance = (insurance: IInsuranceGet | null) => {
    setLoadingCurrentInsurance(true);
    if (insurance) {
      setState(insurance.info);
      setInsuranceRowtable(insurance.details);
    } else {
      setState(null);
      setInsuranceRowtable([]);
    }

    setTimeout(() => {
      setLoadingCurrentInsurance(false);
    }, 1000);
  };

  const insuranceInsert = (data: IInsuranceInsert) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.InsuranceController.InsuranceInsert(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:insuranceSuccessfullAdded"), "success");
          setLoadingCurrentInsurance(true);
          setState(null);
          setInsuranceRowtable([]);
          if(props.dataVehicle && props.dataVehicle.vehicleID){
            loadInsurance(props.dataVehicle.vehicleID);
          }
          if (props.onDataEdited) {
            props.onDataEdited();
          }
          setTimeout(() => {
            setLoadingCurrentInsurance(false);
          }, 100);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const insuranceArchive = (data: IInsuranceUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      data.stored = true;
      ApiService.InsuranceController.InsuranceUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:insuranceSuccessfullUpdated"), "success");
          DismissModal("InsuranceArchive-modal");
          setLoadingCurrentInsurance(true);
          setState(null);
          setInsuranceRowtable([]);
          if (props.onDataEdited) {
            props.onDataEdited();
          }
          if(props.dataVehicle && props.dataVehicle.vehicleID){
            loadInsurance(props.dataVehicle.vehicleID);
          }
          setTimeout(() => {
            setLoadingCurrentInsurance(false);
          }, 100);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const insuranceUpdate = (data: IInsuranceUpdate) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.InsuranceController.InsuranceUpdate(data, (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:insuranceSuccessfullUpdated"), "success");
          setLoadingCurrentInsurance(true);
          setState(null);
          setInsuranceRowtable([]);

          if (props.onDataEdited) {
            props.onDataEdited();
          }
          if(props.dataVehicle && props.dataVehicle.vehicleID){
            loadInsurance(props.dataVehicle.vehicleID);
          }
          setTimeout(() => {
            setLoadingCurrentInsurance(false);
          }, 100);
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      });
    }
  };

  const insuranceDetailDelete = (insuranceDetailID?: number) => {
    if (props.loggedUser && insuranceDetailID) {
      setProcessing(true);
      ApiService.InsuranceController.InsuranceDeleteDetail(
        insuranceDetailID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:insuranceDetailSuccessfullDeleted"),
              "success"
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const insuranceDelete = (insuranceID: number) => {
    if (props.loggedUser) {
      setProcessing(true);
      ApiService.InsuranceController.InsuranceDelete(
        insuranceID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:insuranceSuccessfullDeleted"), "success");
            requestFormRefresh();
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const loadPaymentRecurrenceType = () => {
    if (props.loggedUser) {
      setLoadingPaymentRecurrenceType(true);
      ApiService.VehicleServiceController.GetPaymentRecurrenceType(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setPaymentRecurrenceType(response.payload.filter((x: IVehicleServicePaymentRecurrenceType) => x.paymentRecurrenceID !== 6));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingPaymentRecurrenceType(false);
        }
      );
    }
  };

  const getInsuranceFullInfo = () => {};

  useEffect(() => {
    loadSupplierInsurance();
    loadInsuranceDetailGetType();
    loadPaymentType();
    loadPaymentRecurrenceType();

    if (props.expressInsuranceData) {
      loadCurrentInsurance({
        ...{ info: props.expressInsuranceData.info },
        ...{ details: props.expressInsuranceData.details },
      });
    }
  }, []);

  useEffect(() => {
    if (!props.disabled && props.dataVehicle && props.dataVehicle.vehicleID) {
      loadInsurance(props.dataVehicle.vehicleID);
    }
  }, [props?.dataVehicle?.vehicleID]);

  const prepareForUpdate = (parsedData: any) => {
    if (!parsedData.vehicleID && props.dataVehicle) {
      parsedData["vehicleID"] = props.dataVehicle.vehicleID;
    }

    delete parsedData["delete"];
    delete parsedData["row"];
    delete parsedData["supplierAddType"];
    delete parsedData["addInsuranceCompany"];
    delete parsedData["detailsInsuranceRow"];
    parsedData["insuranceDetails"] = insuranceRowTable;

    for (let i = 0; i < insuranceRowTable.length; i++) {
      delete parsedData["insuranceDetails"][i]["insuranceDetailTypeName"];
      delete parsedData["insuranceDetailID_" + i];
      delete parsedData["insuranceDetailTypeID_" + i];
      delete parsedData["covarageLimit_" + i];
      delete parsedData["deductible_" + i];
      delete parsedData["covarage_" + i];
    }
    return parsedData;
  };

  const loadingAllPage =
    loadingDetails ||
    processing ||
    loadingSupplierInsurance ||
    loadingCurrentInsurance ||
    loadingInsurance ||
    loadingInsuranceDetailGetType ||
    loadingPaymentType;

  const emitCoreData = () => {
    if (props.emitCoreLists) {
      props.emitCoreLists(
        supplierInsurance,
        insuranceDetailGetType,
        paymentType,
        insurances
      );
    }
  };

  useEffect(() => {
    if (!loadingAllPage) {
      emitCoreData();
    }
  }, [loadingAllPage]);

  useEffect(() => {
    let insuranceDetailTypeIDs: string[] = [];
    for (let i = 0; i < insuranceRowTable.length; i++) {
      if (insuranceRowTable[i].insuranceDetailTypeID > 0) {
        insuranceDetailTypeIDs.push(
          insuranceRowTable[i].insuranceDetailTypeID.toString()
        );
      }
    }
    setInsuranceDetailTypeIDs(insuranceDetailTypeIDs);
  }, [insuranceRowTable]);

  useEffect(() => {
    return () => {
      setState(null);
    };
  }, []);

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <IconButton>
          <EditIcon
            onClick={() =>
              loadCurrentInsurance({
                ...{ info: props.row.info },
                ...{ details: props.row.details },
              })
            }
            className="icon-table"
          />
        </IconButton>
        {props.value}
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "action",
      headerName: "",
      type: "custom",
      hideExport: true,
      renderCell: renderEdit,
    },
    {
      field: "vehicleInsuranceCompanyName",
      headerName: i18next.t("form:vehicleInsuranceCompany") as string,
    },
    {
      field: "startDTView",
      headerName: i18next.t("form:dateStart") as string,
    },
    {
      field: "endDTView",
      headerName: i18next.t("form:dateFinish") as string,
    },
    {
      field: "insuranceModePaymentName",
      headerName: i18next.t("form:insuranceModePayment") as string,
    },
    {
      field: "paymentRecurrenceID",
      headerName: i18next.t("form:insuranceRecurrence") as string,
      type: "number"
    },
    {
      field: "amount",
      headerName: i18next.t("form:insuranceImportInstallment") as string,
      type: "number",
    },
    {
      field: "policyNumber",
      headerName: i18next.t("form:insuranceNumber") as string,
      type: "number"
    },
  ];

  /* ROWS */
  
  useEffect(() => {
    const rowsDef = [];
    if(insurances && paymentRecurrenceType && supplierInsurance){
      let insuranceNotStored = insurances?.filter((x: IInsuranceGet) => !x.info.stored); 
      for (let i = 0; i < insuranceNotStored.length; i++) {
        rowsDef.push({
          id: i,
          action: "",
          vehicleInsuranceCompanyName: insuranceNotStored[i].info.supplierName,
          startDTView: displayUTC0_ISODate(insuranceNotStored[i].info.startDT, false).slice(0, -3),
          endDTView: displayUTC0_ISODate(insuranceNotStored[i].info.endDT, false).slice(0, -3),
          insuranceModePaymentName: insuranceNotStored[i].info.paymentTypeName,
          amount: currencyDecoder(insuranceNotStored[i].info.amount, 2),
          policyNumber: insuranceNotStored[i].info.policyNumber,
          info: insuranceNotStored[i].info,
          details: insuranceNotStored[i].details,
          paymentRecurrenceID: paymentRecurrenceType.find(
            (x: IVehicleServicePaymentRecurrenceType) =>
              x.paymentRecurrenceID === insuranceNotStored[i].info.paymentRecurrenceID
          )?.paymentRecurrenceName,
        });
      }
      
      setRows(rowsDef);
    }
  }, [insurances, paymentRecurrenceType, supplierInsurance]);

  return (
    <div>
      {props.dataVehicle &&  props.dataVehicle.vehicleID && (
        <div className="padding-small">
          <AddNewBanner
            suppressLayout
            forceDisplay
            label={i18next.t("navigation:addNewInsurance") as string}
            new={() => newInsurance()}
          />
        </div>
      )}
      {props.disabled && !props.suppressWarning && (
        <div className="vehicles-multi-form-title-wrap">
          {i18next.t("generic:vehicle_enable")}
        </div>
      )}

      {!processing && rows.length > 0 && !props.disabledAccordion && (
        <div className="padding-small">
          <DataGridWrap
            tableName={i18next.t("navigation:insuranceTabTitle") as string}
            headers={columnsDef}
            rows={rows}
          />
        </div>
      )}

      {!loadingCurrentInsurance && !loadingDetails && (
        <div>
          <MultiForm
            formId={"StepInsurance"}
            suppressLayout
            formTitle={""}
            inputs={multiformInputs()}
            onChange={(data: any) => {
              setState(mergeObjects(data));
              let parsedData = mergeObjects(data);
              FromFormToDataVetor(parsedData);
            }}
            disableForm={processing}
            onSubmit={(data: any) => {
              let parsedData = prepareForUpdate(data);
              if (state.insuranceID) {
                parsedData["insuranceID"] = state.insuranceID;
                insuranceUpdate(parsedData);
              } else {
                insuranceInsert(parsedData);
              }
            }}
            suppressSubmit={true}
          />
          <div className="margin-vertical-medium">
            <AttachementMainHandler
              attachmentFamily={"insurance"}
              extraGetParametersVector={
                state?.insuranceID
                  ? [
                      {
                        name: "insuranceID",
                        value: state?.insuranceID,
                      },
                    ]
                  : undefined
              }
            />
          </div>
          <div className="padding-small">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                disabled={props.disabled}
                onClick={() => {
                  triggerFormValidation("StepInsurance");
                }}
                endIcon={<SaveIcon />}
              >
                {i18next.t("form:saveInsurance")}
              </Button>
              {isInDashBoard ? (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.insuranceID}
                  onClick={() => {
                    SummonModal("InsuranceArchive-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:archiveInsurance")}
                </Button>
              ) : (
                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={!state?.insuranceID}
                  onClick={() => {
                    SummonModal("InsuranceDelete-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteInsurance")}
                </Button>
              )}
              <SmartModal
                modalUniqueId="InsuranceArchive-modal"
                title={i18next.t("generic:titleRecordModalArchive")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModalArchive")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          (supplierInsurance.find(
                            (x: ISupplierGetSideList) =>
                              x.supplierInfo.supplierID === state?.supplierID
                          )?.supplierInfo.supplierName ?? "") +
                            ", " +
                            state?.policyNumber
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  insuranceArchive(prepareForUpdate(state));
                }}
                onReject={() => {}}
              />
              <SmartModal
                modalUniqueId="InsuranceDelete-modal"
                title={i18next.t("generic:titleRecordModal")}
                modalInnerComponent={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: i18next
                        .t("generic:descriptionRecordModal")
                        .replace(
                          "CURRENT_ITEM_LIST",
                          (supplierInsurance.find(
                            (x: ISupplierGetSideList) =>
                              x.supplierInfo.supplierID === state?.supplierID
                          )?.supplierInfo.supplierName ?? "") +
                            ", " +
                            state?.policyNumber
                        ),
                    }}
                  />
                }
                onAccept={() => {
                  insuranceDelete(state?.insuranceID);
                }}
                onReject={() => {}}
              />
            </div>
          </div>
        </div>
      )}
      <SmartModal
        modalUniqueId="Supplier-Insurance-modal"
        title={i18next.t("generic:titleAddSupplier")}
        hideButtons={true}
        modalInnerComponent={
          <FormSupplier
            forceSupplierTypeCode={"INSURANCE"}
            loggedUser={props.loggedUser}
            data={[]}
            updateList={(data: ISupplierGetSideList) => {
              let supplierInsurance_: ISupplierGetSideList[] = [...supplierInsurance];
              supplierInsurance_.push(data);
              setSupplierInsurance(supplierInsurance_);
            }}
            idModal="Supplier-Insurance-modal"
          />
        }
      />
      <LoaderBackdrop loading={loadingAllPage} />
    </div>
  );
};

export default FormInsurance;
