import ApiEndPoints from "../../Costants/ApiEndPoints";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const StatisticsController = {
  /* GET */
  // CO2 emissions

  getStatisticsCo2Emissions: async (
    prodFrom: Date,
    prodTo: Date,
    avgFrom: any,
    avgTo: any,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.STATISTICS_CO2_EMISSIONS;
    let parameters: string[] = [];
    if (prodFrom) {
      parameters.push(`prodFrom=${prodFrom}`);
    }
    if (prodTo) {
      parameters.push(`prodTo=${prodTo}`);
    }
    if (avgFrom) {
      parameters.push(`avgFrom=${avgFrom}`);
    }
    if (avgTo) {
      parameters.push(`avgTo=${avgTo}`);
    }
    request.url += "?" + parameters.join("&");
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Kilometric projection
  getStatisticsKilometricProjection: async (
    from: Date,
    to: Date,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.STATISTICS_KILOMETRIC_PROJECTION}`;
    let parameters: string[] = [];
    if (from) {
      parameters.push(`from=${from}`);
    }
    if (to) {
      parameters.push(`to=${to}`);
    }
    if (from || to) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Fuel anomalies
  getStatisticsFuelAnomalies: async (
    from: Date,
    to: Date,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.STATISTICS_FUEL_ANOMALIES}`;
    let parameters: string[] = [];
    if (from) {
      parameters.push(`from=${from}`);
    }
    if (to) {
      parameters.push(`to=${to}`);
    }
    if (from || to) {
      request.url += "?" + parameters.join("&");
    }
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  // Ownership costs
  getStatisticsOwnershipCosts: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = `${ApiEndPoints.STATISTICS_OWNERSHIP_COSTS}`;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default StatisticsController;
