import i18next from "i18next";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import { IAppRouteInternal } from "../../Pages/DashboardPage/DashboardPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./NavMenu.scss";
import SmartModal from "../SmartModal/SmartModal";
import FormOrders from "../StepOrders/FormOders";
import { ICompanyGet } from "../../Models/ICompany";
import { GlobalState } from "../../Reducers/RootReducer";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import FormVehicleData from "../StepVehicles/TabVehicleData/FormVehicleData";
import { ActionZone } from "../BlueToolbarInternal/BlueToolbarInternal";
import SpeedDialMenu from "./SpeedDialMenu";
import { JSONPrint } from "../../Utils/Decoder";
import { getUserRole } from "../../Utils/Session";

export interface IMenuRole {
  admittedRole: string;
  page: string;
  enabled: number;
}

export const triggerNavMenu = () => {
  window.document.dispatchEvent(new Event("toggle-nav-menu"));
};

export interface INavMenuProps {
  routes: IAppRouteInternal[];
}
var _isOpen = false;
var lockNavigate = false;
const NavMenu = (props: INavMenuProps) => {
  // User
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  // Company
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  const menuVoices: IMenuRole[] | undefined = useSelector(
    (state: GlobalState) => state.user.menuVoices
  );

  const menuVoicesExpired: boolean | undefined = useSelector(
    (state: GlobalState) => state.user.menuPagesExpired
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen_] = useState<boolean>(false);
  const [expandedPath, setExpandedPath] = useState<number>(-1);
  const [expandedSecondaryPath, setExpandedSecondaryPath] =
    useState<number>(-1);
  const [current, setCurrent] = useState<string>("");

  const setIsOpen = (toggle: boolean) => {
    _isOpen = toggle;
    setIsOpen_(toggle);
  };

  const doLockNav = () => {
    lockNavigate = true;
    setTimeout(() => {
      lockNavigate = false;
    }, 200);
  };

  const timedNavigate = (path: string) => {
    setIsOpen(false);
    setTimeout(() => {
      if (!lockNavigate) {
        navigate(path + window.location.search);
      }
    }, 100);
  };

  useEffect(() => {
    setCurrent(window.location.pathname);
  }, [location]);

  const toggleNavMenu = () => {
    setIsOpen(!_isOpen);
  };

  useEffect(() => {
    window.document.addEventListener("toggle-nav-menu", toggleNavMenu);
    return () => {
      _isOpen = false;
      window.document.removeEventListener("toggle-nav-menu", toggleNavMenu);
    };
  }, []);

  const isActivePath = (x: IAppRouteInternal) => {
    return current.includes(x.path);
  };

  const isActiveChild = (x: IAppRouteInternal) => {
    return (
      (x.children ?? []).filter((y: IAppRouteInternal) =>
        current.includes(y.path)
      ).length > 0
    );
  };

  const isActive = (x: IAppRouteInternal, i: number) => {
    if (expandedPath !== i) {
      if (isActiveChild(x)) {
        return true;
      }
    } else {
      if (isActivePath(x) && !isActiveChild(x)) {
        return true;
      }
    }

    return false;
  };

  const filterMenuVoices = (menuVoices: IMenuRole[]) => {
    const routes: IAppRouteInternal[] = [...props.routes];
    const userRole = getUserRole();

    if (menuVoicesExpired) {
      menuVoices = menuVoices.filter(
        (x: IMenuRole) =>
          x.page.startsWith("/services/purchases") || x.page === "/services" || x.page === "/dashboard/download-data"
      );
    }

    for (let i = 0; i < routes.length; i++) {
      let r: IAppRouteInternal = routes[i];
      let voice: IMenuRole | undefined = menuVoices.find(
        (x: IMenuRole) =>
          (x.admittedRole
            .toLocaleLowerCase()
            .includes(userRole.toLocaleLowerCase()) ||
            x.admittedRole.toLocaleLowerCase() === "all") &&
          x.page.toLocaleLowerCase() === r.path.toLocaleLowerCase() &&
          x.enabled
      );

      if (voice === undefined) {
        routes[i].visible = false;
      }

      // first level of children
      let c1: IAppRouteInternal[] = r.children ?? [];
      for (let k = 0; k < c1.length; k++) {
        let r1: IAppRouteInternal = c1[k];

        let voice: IMenuRole | undefined = menuVoices.find(
          (x: IMenuRole) =>
            (x.admittedRole
              .toLocaleLowerCase()
              .includes(userRole.toLocaleLowerCase()) ||
              x.admittedRole.toLocaleLowerCase() === "all") &&
            x.page.toLocaleLowerCase() === r1.path.toLocaleLowerCase() &&
            x.enabled
        );

        if (voice === undefined) {
          r1.visible = false;
        }

        // second level of children
        let c2: IAppRouteInternal[] = r1.children ?? [];
        for (let k2 = 0; k2 < c2.length; k2++) {
          let r2: IAppRouteInternal = c2[k2];

          let voice: IMenuRole | undefined = menuVoices.find(
            (x: IMenuRole) =>
              (x.admittedRole
                .toLocaleLowerCase()
                .includes(userRole.toLocaleLowerCase()) ||
                x.admittedRole.toLocaleLowerCase() === "all") &&
              x.page.toLocaleLowerCase() === r2.path.toLocaleLowerCase() &&
              x.enabled
          );

          if (voice === undefined) {
            r2.visible = false;
          }
        }
      }
    }

    return routes;
  };

  return (
    <div>
      {isOpen && (
        <div
          className="navmenu-inkdrop"
          onClick={() => {
            toggleNavMenu();
          }}
        />
      )}

      <div
        className="navmenu-main-wrap"
        style={isOpen ? { left: "0" } : { left: "-400px" }}
      >
        <div className="sticky-element">
          <div className="show-on-mobile">
            <ActionZone />
          </div>
          {menuVoices &&
            filterMenuVoices(menuVoices)
              .filter((x: IAppRouteInternal) => x.visible !== false)
              .map((x: IAppRouteInternal, i: number) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        "navmenu-single-voice " +
                        (isActive(x, i) ? "navmenu-single-voice-selected" : "")
                      }
                      onClick={() => {
                        if (x.component) {
                          timedNavigate(AppRoutes.INTERNAL_DASHBOARD + x.path);
                        }
                      }}
                    >
                      <div className="navmenu-icon-handle">{x.icon}</div>
                      <Link
                        className="navmenu-link-formatted"
                        to={
                          (x.component
                            ? AppRoutes.INTERNAL_DASHBOARD + x.path
                            : window.location.pathname) + window.location.search
                        }
                        dangerouslySetInnerHTML={{
                          __html: i18next.t(`navigation:${x.label}`) ?? "",
                        }}
                        onClick={() => {
                          doLockNav();
                          setExpandedPath(i);
                        }}
                      />
                      {x.children && (
                        <div>
                          {expandedPath !== i && (
                            <ExpandMoreIcon
                              onClick={() => {
                                doLockNav();
                                setExpandedPath(i);
                              }}
                            />
                          )}
                          {expandedPath === i && (
                            <ExpandLessIcon
                              onClick={() => {
                                doLockNav();
                                setExpandedPath(-1);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>

                    <div
                      className="navmenu-children-wrap"
                      style={
                        x.children && expandedPath === i
                          ? { height: "100%" }
                          : {}
                      }
                    >
                      {(x.children ?? [])
                        .filter((y: IAppRouteInternal) => y.visible !== false)
                        .map((y: IAppRouteInternal, j: number) => {
                          return (
                            <div key={j}>
                              <div>
                                <div
                                  className={
                                    "navmenu-single-voice " +
                                    (current.includes(y.path)
                                      ? "navmenu-single-voice-selected"
                                      : "")
                                  }
                                  onClick={() => {
                                    if (y.component) {
                                      timedNavigate(
                                        AppRoutes.INTERNAL_DASHBOARD + y.path
                                      );
                                    }
                                  }}
                                >
                                  <div className="navmenu-icon-handle">
                                    {y.icon}
                                  </div>
                                  <Link
                                    className="navmenu-link-formatted"
                                    to={
                                      (y.component
                                        ? AppRoutes.INTERNAL_DASHBOARD + y.path
                                        : window.location.pathname) +
                                      window.location.search
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        i18next.t(`navigation:${y.label}`) ??
                                        "",
                                    }}
                                    onClick={() => {
                                      doLockNav();
                                      setExpandedSecondaryPath(j);
                                    }}
                                  />
                                  {y.children && (
                                    <div>
                                      {expandedSecondaryPath !== j && (
                                        <ExpandMoreIcon
                                          onClick={() => {
                                            doLockNav();
                                            setExpandedSecondaryPath(j);
                                          }}
                                        />
                                      )}
                                      {expandedSecondaryPath === j && (
                                        <ExpandLessIcon
                                          onClick={() => {
                                            doLockNav();
                                            setExpandedSecondaryPath(-1);
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {y.children && (
                                <div
                                  className="navmenu-children-wrap"
                                  style={
                                    x.children && expandedSecondaryPath === j
                                      ? { height: "100%" }
                                      : {}
                                  }
                                >
                                  {(y.children ?? [])
                                    .filter(
                                      (k: IAppRouteInternal) =>
                                        k.visible !== false
                                    )
                                    .map((k: IAppRouteInternal, kj: number) => {
                                      return (
                                        <div key={kj}>
                                          <div
                                            className={
                                              "navmenu-single-voice " +
                                              (current.includes(k.path)
                                                ? "navmenu-single-voice-selected"
                                                : "")
                                            }
                                            onClick={() => {
                                              if (k.component) {
                                                timedNavigate(
                                                  AppRoutes.INTERNAL_DASHBOARD +
                                                    k.path
                                                );
                                              }
                                            }}
                                          >
                                            <div className="navmenu-icon-handle">
                                              {k.icon}
                                            </div>
                                            <Link
                                              className="navmenu-link-formatted"
                                              to={
                                                (k.component
                                                  ? AppRoutes.INTERNAL_DASHBOARD +
                                                    k.path
                                                  : window.location.pathname) +
                                                window.location.search
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  i18next.t(
                                                    `navigation:${k.label}`
                                                  ) ?? "",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
