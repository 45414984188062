import { useEffect } from "react";
import i18next from "i18next";

import "../StepConfirmEmail/StepConfirmEmail.scss";

import { updateAccountRegistrationStep } from "../../Utils/Stepper";
import { useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";

export interface IStepWelcomeProps {
  nextPage: number;
  stepId?: number;
  stepsActive: {stepID: number, i: number}[];
}

const StepWelcome = (props: IStepWelcomeProps) => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  useEffect(() => {
    updateAccountRegistrationStep(loggedUser, props.stepId, true);
  }, []);
  /* RETURN */
  return (
    <>
      <div className="custom-stepper-content-wrap">
        <h1
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:titleContinueRegistration") ?? "",
          }}
        ></h1>
        <h2 className="custom-stepper-text">
          {i18next.t("navigation:subtitleContinueRegistration")}
        </h2>

        <div>
          <h4>{i18next.t("navigation:listTitleRegistration")}</h4>

          <div className="stepWelcome-content">
            <div>
              {props.stepsActive.map((x: {stepID: number, i: number}) => {
                switch (x.stepID) {
                  case 2:
                    return (
                      <p key={x.i} 
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list2Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                  case 3:
                    return (
                      <p key={x.i} 
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list3Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                  case 4:
                    return (
                      <p key={x.i} 
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list4Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                  case 5:
                    return (
                      <p key={x.i}
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list5Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                  case 6:
                    return (
                      <p key={x.i}
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list6Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                }
              })}
            </div>
            <div className="stepWelcome-second">
              {props.stepsActive.map((x: {stepID: number, i: number}) => {
                switch (x.stepID) {
                  case 7:
                    return (
                      <span key={x.i}>
                        <p 
                          dangerouslySetInnerHTML={{
                            __html: i18next.t("navigation:list7Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                          }}
                        />
                        <ul className="stepWelcome-list">
                          <li>
                            <span className="custom-stepper-text-color custom-stepper-text-bold">
                              {i18next.t("navigation:list7Registration-Insurance")}
                            </span>
                          </li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html:
                                i18next.t("navigation:list7Registration-CardAndTelepass") ?? "",
                            }}
                          ></li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html: i18next.t("navigation:list7Registration-Driver") ?? "",
                            }}
                          ></li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html:
                                i18next.t("navigation:list7Registration-OthersServices") ?? "",
                            }}
                          ></li>
                        </ul>
                      </span>
                    );
                  case 8:
                    return (
                      <p key={x.i}
                        dangerouslySetInnerHTML={{
                          __html: i18next.t("navigation:list8Registration").replace("NUMBER_LIST", x.i.toString()) ?? "",
                        }}
                      />
                    );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepWelcome;
