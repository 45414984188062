/* LIBRARIES */
import i18next from "i18next";

/* STYLE */
import "./TemplateModel.scss";

/* COMPONENTS */
import { stepperGoToPage } from "../CustomStepper/CustomStepper";

import CartissimaQ8Icon from "../../Assets/ImagesEmbedded/cartissimaQ8.png";
import DkvIcon from "../../Assets/ImagesEmbedded/dkv.png";
import EdenredIcon from "../../Assets/ImagesEmbedded/edenred.png";
import EssoIcon from "../../Assets/ImagesEmbedded/esso.png";
import IpPlusIcon from "../../Assets/ImagesEmbedded/ip-plus.png";
import UnipolMove from "../../Assets/ImagesEmbedded/unipolMove.png";
import Telepass from "../../Assets/ImagesEmbedded/telepass.png";
import DoneIcon from "@mui/icons-material/Done";

/* MUI */
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import { ITemplate } from "../../Models/ITemplate";

export interface ITemplateModelProps {
  templateTypeID: number;
  onClick: (data: ITemplate) => void;
}

/* COMPONENT */
const TemplateModel = (props: ITemplateModelProps) => {
  // Default template
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultTemplates, setDefaultTemplates] = useState<ITemplate[]>([]);
  const [selected, setSelected] = useState<number>(-1);

  const [loadingTemplateType, setLoadingTemplateType] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const loadActiveTemplates = () => {
    setLoading(true);
    ApiService.TemplateController.TemplateGet(
      props.templateTypeID,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDefaultTemplates(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };
  const updateSelected = (template: ITemplate) => {
    setSelected(template.templateID);
    props.onClick(template);
  };

  useEffect(() => {
    loadActiveTemplates();
  }, []);

  /* RETURN */
  return (
    <div className="content-wrapper">
      <div className="template-default-wrapper">
        <h3>{i18next.t("navigation:_forniture_page_template_default")}</h3>

        <div className="box-template-custom">
          {defaultTemplates
            .filter((x: ITemplate) => !x.companyID)
            .map((item: ITemplate) => {
              return (
                <div className="button-container">
                  {item.templateImage && (
                    <img className="box-model-image" src={item.templateImage} />
                  )}
                  {item.templateName}
                  <Button
                    endIcon={selected === item.templateID && <DoneIcon />}
                    variant="contained"
                    onClick={() => {
                      updateSelected(item);
                    }}
                  >
                    {selected === item.templateID
                      ? i18next.t("navigation:selected")
                      : i18next.t("navigation:select")}
                  </Button>
                </div>
              );
            })}
        </div>
      </div>

      <div className="template-default-wrapper">
        <h3>{i18next.t("navigation:_forniture_page_template_custom")}</h3>

        <div className="box-template-custom">
          {defaultTemplates
            .filter((x: ITemplate) => x.companyID !== null)
            .map((item: ITemplate) => {
              return (
                <div className="button-container">
                  {item.templateImage && (
                    <img className="box-model-image" src={item.templateImage} />
                  )}
                  {item.templateName}
                  <Button
                    endIcon={selected === item.templateID && <DoneIcon />}
                    variant="contained"
                    onClick={() => {
                      updateSelected(item);
                    }}
                  >
                    {selected === item.templateID
                      ? i18next.t("navigation:selected")
                      : i18next.t("navigation:select")}
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default TemplateModel;
