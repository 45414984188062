import { Button } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { ICompanyBody, ICompanyGet } from "../../Models/ICompany";
import { IGoogleAddress } from "../../Utils/Google";
import {
  validationVAT,
  validationFiscalCode,
  validationPhone,
} from "../../Utils/Validation";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import PlaceAutoCompleteForm from "../PlaceAutoCompleteForm/PlaceAutoCompleteForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import UploadForm from "../UploadForm/UploadForm";
import { ToastMessage } from "../../Utils/Toastify";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { IUser } from "../../Models/IUser";
import {
  getCompressedBase64,
  getCompressedFileName,
} from "../../Utils/FileToBase";

export interface IFormCompanyUser {
  loggedUser: IUser | undefined;
  data: ICompanyGet | any;
  doRefreshForm?: () => void;
  onLoadingCompanyList?: () => void;
  companyUser: ICompanyGet | undefined;
  idModal?: string;
}

const FormCompanyUser = (props: IFormCompanyUser) => {
  const [state, setState] = useState<any>(props.data);
  const [processing, setProcessing] = useState<boolean>(false);

  const updateCompany = (data: ICompanyBody) => {
    setProcessing(true);
    delete data["companyParentID"];
    ApiService.CompanyController.CompanyUpdateData(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:companySuccessfullUpdated"),
            "success"
          );
          if (props.onLoadingCompanyList) {
            props.onLoadingCompanyList();
          }

          if (props.doRefreshForm) {
            props.doRefreshForm();
          }
          if (props.idModal) {
            DismissModal(props.idModal as string);
          }
        } else {
          if(response.payload.detail){
            ToastMessage(i18next.t("error:" + response.payload.detail), "error");
          }else{
            ToastMessage(response.error, "error");
          }
        }
        setProcessing(false);
      }
    );
  };

  const insertCompany = (data: ICompanyBody) => {
    if (props.loggedUser) {
      setProcessing(true);
      data["companyParentID"] = props.companyUser?.companyID;
      ApiService.CompanyController.CompanySetData(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:companySuccessfullAdded"),
              "success"
            );
            if (props.onLoadingCompanyList) {
              props.onLoadingCompanyList();
            }

            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
          } else {
            if(response.payload.detail){
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            }else{
              ToastMessage(response.error, "error");
            }
          }

          setProcessing(false);
        }
      );
    }
  };

  const deleteCompany = (data: any) => {
    if (props.loggedUser) {
      setProcessing(true);
      if (data === null) {
        ToastMessage(i18next.t("message:companyNotDeleted"), "warning");
      }
      ApiService.CompanyController.CompanyDeleteData(
        data.companyID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:companySuccessfullDeleted"),
              "success"
            );
            if (props.onLoadingCompanyList) {
              props.onLoadingCompanyList();
            }

            if (props.doRefreshForm) {
              props.doRefreshForm();
            }
            if (props.idModal) {
              DismissModal(props.idModal as string);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateFile = (files: File[]) => {
    getCompressedBase64(
      files.length > 0 ? files[0] : null,
      (result: string | null) => {
        let oldState = { ...state };
        oldState["companyImageURL"] = result;
        setState(oldState);
      }
    );
  };

  return (
    <div>
      <MultiForm
        styles={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        formId={"StepCompanyUsers"}
        suppressLayout
        formTitle={""}
        disableForm={processing}
        inputs={[
          {
            width: 50,
            type: "text",
            name: "companyBusinessName",
            defaultValue: state?.companyBusinessName,
            required: true,
            inputProps: { maxLength: 50 },
            label: `${i18next.t("form:nameCompany")}`,
          },
          {
            width: 50,
            type: "text",
            name: "companySDIPEC",
            defaultValue: state?.companySDIPEC,
            required: true,
            label: `${i18next.t("form:SDICodeOrPEC")}`,
          },
          {
            width: 25,
            type: "text",
            name: "companyVAT",
            defaultValue: state?.companyVAT,
            required: true,
            inputProps: { maxLength: 13 },
            label: `${i18next.t("form:vatNumber")}`,
            errorText:
              state?.companyVAT &&
              state?.companyVAT.length > 0 &&
              !validationVAT(state?.companyVAT)
                ? (i18next.t("form:validationIVA") as string)
                : "",
          },
          {
            width: 25,
            type: "text",
            name: "companyFiscalCode",
            defaultValue: state?.companyFiscalCode,
            label: i18next.t("form:taxIdCode").toString(),
            inputProps: {
              maxLength: 16,
              style: { textTransform: "uppercase" },
            },
            errorText:
              state?.companyFiscalCode &&
              state?.companyFiscalCode.length > 0 &&
              !validationFiscalCode(state?.companyFiscalCode) &&
              !validationVAT(state?.companyFiscalCode)
                ? (i18next.t("form:validationCF") as string)
                : "",
          },
          {
            width: 25,
            type: "text",
            name: "companyReferentName",
            defaultValue: state?.companyReferentName,
            required: true,
            inputProps: {
              maxLength: 50,
            },
            label: `${i18next.t("form:nameReferent")}`,
          },
          {
            width: 25,
            type: "text",
            name: "companyReferentSurname",
            defaultValue: state?.companyReferentSurname,
            inputProps: {
              maxLength: 50,
            },
            required: true,
            label: `${i18next.t("form:surnameReferent")}`,
          },
          {
            width: 50,
            type: "email",
            name: "companyReferentEmail",
            tooltip: {
              title: i18next.t("form:tooltipEmailReferent"),
            },
            inputProps: { maxLength: 100 },
            defaultValue: state?.companyReferentEmail,
            required: true,
            label: String(i18next.t("form:emailReferent")),
          },
          {
            width: 50,
            type: "tel",
            inputProps: { maxLength: 20 },
            name: "companyReferentPhone",
            defaultValue: state?.companyReferentPhone,
            label: String(i18next.t("form:telephoneReferent")),
            errorText:
              state?.companyReferentPhone &&
              state?.companyReferentPhone.length > 0 &&
              !validationPhone(state?.companyReferentPhone)
                ? (i18next.t("form:validationPhone") as string)
                : "",
          },
          {
            width: 50,
            name: "autocomlete",
            fullWidth: true,
            type: "custom",
            className: "content-place-auto-complete",
            element: (
              <PlaceAutoCompleteForm
                onChange={(data: IGoogleAddress) => {
                  setState({ ...state, ...data });
                }}
                addressId="companyBillingStreet"
                disableForm={processing}
                inputs={[
                  {
                    width: 70,
                    type: "text",
                    required: true,
                    name: "companyBillingStreet",
                    inputProps: { maxLength: 100 },
                    defaultValue: state?.companyBillingStreet,
                    mapDecode: "route",
                    label: String(i18next.t("form:addressLegal")),
                  },
                  {
                    width: 30,
                    type: "text",
                    required: true,
                    name: "companyBillingNumber",
                    defaultValue: state?.companyBillingNumber,
                    mapDecode: "street_number",
                    inputProps: { maxLength: 10 },
                    label: String(i18next.t("form:civicNumberAbbr")),
                  },
                  {
                    width: 100,
                    required: true,
                    type: "select_nations",
                    name: "companyBillingCountryID",
                    currentValue: state?.companyBillingCountryID ?? "",
                    defaultValue: state?.companyBillingCountryID,
                    mapDecode: "country",
                    label: String(i18next.t("form:countryResidence")),
                  },
                  {
                    width: 25,
                    required: true,
                    type: "text",
                    name: "companyBillingCAP",
                    inputProps: { maxLength: 20 },
                    defaultValue: state?.companyBillingCAP,
                    mapDecode: "postal_code",
                    label: String(i18next.t("form:postalCode")),
                  },
                  {
                    width: 50,
                    required: true,
                    type: "text",
                    name: "companyBillingCity",
                    defaultValue: state?.companyBillingCity,
                    mapDecode: "locality",
                    inputProps: { maxLength: 50 },
                    label: String(i18next.t("form:city")),
                  },
                  {
                    width: 25,
                    required: true,
                    type: "text",
                    name: "companyBillingProv",
                    defaultValue: state?.companyBillingProv,
                    inputProps: { maxLength: 50 },
                    mapDecode: "province",
                    label: String(i18next.t("form:provinceAbbr")),
                  },
                ]}
              />
            ),
          },
          {
            width: 50,
            type: "custom",
            name: "companyImageURL",
            label: i18next.t("form:titleImageImageCompanyUpload").toString(),
            element: (
              <UploadForm
                initialFiles={
                  state && state.companyImageURL
                    ? [getCompressedFileName(state.companyImageURL)]
                    : []
                }
                name={"companyImageURL"}
                title={i18next.t("form:subtitleImageCompanyUpload")}
                description={i18next.t("form:descriptionImageUpload")}
                onFileChanged={updateFile}
                accept={"image/*"}
              />
            ),
          },
          {
            width: 100,
            name: "save",
            type: "custom",
            element: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  variant="contained"
                  disabled={processing}
                  onClick={() => {
                    triggerFormValidation("StepCompanyUsers");
                  }}
                  endIcon={<SaveIcon />}
                >
                  {i18next.t("form:saveCompanyUser")}
                </Button>

                <Button
                  color="error"
                  sx={{ marginRight: "1em" }}
                  disabled={state && !state.companyID}
                  onClick={() => {
                    SummonModal("StepCompanyUsers-modal");
                  }}
                  variant="contained"
                  endIcon={<DeleteIcon />}
                >
                  {i18next.t("form:deleteCompanyUser")}
                </Button>
                <SmartModal
                  modalUniqueId="StepCompanyUsers-modal"
                  title={i18next.t("generic:titleRecordModal")}
                  modalInnerComponent={
                    <p
                      dangerouslySetInnerHTML={{
                        __html: i18next
                          .t("generic:descriptionRecordModal")
                          .replace(
                            "CURRENT_ITEM_LIST",
                            state?.companyBusinessName
                          ),
                      }}
                    />
                  }
                  onAccept={() => {
                    deleteCompany(state);
                  }}
                  onReject={() => {}}
                />
              </div>
            ),
          },
        ]}
        suppressSubmit={true}
        onSubmit={async () => {
          delete state["createdBy"];
          delete state["createdDT"];
          delete state["modifiedBy"];
          delete state["modifiedDT"];
          delete state["autocomlete"];
          delete state["save"];
          state["companyFiscalCode"] = state.companyFiscalCode?.toUpperCase();
          if (state.companyID && state.companyID > 0) {
            updateCompany(state);
          } else {
            insertCompany(state);
          }
        }}
        onChange={async (data: any) => {
          setState({ ...state, ...data });
        }}
      />
    </div>
  );
};

export default FormCompanyUser;
