import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IFringeCompleteModel,
  IVehicleFringeBenefit,
} from "../../Models/IVehiclesFringe";
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

const FringeController = {
  FringeMassiveImport: async (
    fuelTypeAciID: number,
    inProduction: boolean,
    fileData: File,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.FRINGE_MASSIVE_IMPORT;
    request.port = "7236";
    let parameters: string[] = [];
    parameters.push(`fuelTypeAciID=${fuelTypeAciID}`);
    parameters.push(`inProduction=${inProduction}`);
    request.url += "?" + parameters.join("&");
    request.method = "POST";

    let formData = new FormData();
    formData.append("fringeTable", fileData, fileData.name);
    request.payload = formData;
    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeFuelTypeAciGet: async (
    fuelTypeAciID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_FUEL_TYPE_GET;
    let parameters: string[] = [];
    if (fuelTypeAciID) {
      parameters.push(`fuelTypeAciID=${fuelTypeAciID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBrandAciGet: async (
    fuelTypeAciID?: number,
    inProduction?: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BRAND_GET;
    let parameters: string[] = [];
    if (fuelTypeAciID) {
      parameters.push(`fuelTypeAciID=${fuelTypeAciID}`);
    }
    if (inProduction !== undefined) {
      parameters.push(`inProduction=${inProduction ? "true" : "false"}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeModelAciGet: async (
    fuelTypeAciID?: number,
    inProduction?: boolean,
    aciBrandName?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_MODEL_GET;
    let parameters: string[] = [];
    if (fuelTypeAciID) {
      parameters.push(`fuelTypeAciID=${fuelTypeAciID}`);
    }
    if (inProduction !== undefined) {
      parameters.push(`inProduction=${inProduction ? "true" : "false"}`);
    }
    if (aciBrandName) {
      parameters.push(`aciBrandName=${aciBrandName}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeVersionAciGet: async (
    fuelTypeAciID?: number,
    inProduction?: boolean,
    aciBrandName?: string,
    acimodelName?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_VERSION_GET;
    let parameters: string[] = [];
    if (fuelTypeAciID) {
      parameters.push(`fuelTypeAciID=${fuelTypeAciID}`);
    }
    if (inProduction !== undefined) {
      parameters.push(`inProduction=${inProduction ? "true" : "false"}`);
    }
    if (aciBrandName) {
      parameters.push(`aciBrandName=${aciBrandName}`);
    }
    if (acimodelName) {
      parameters.push(`acimodelName=${acimodelName}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefitGetAll: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_GET_ALL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefiteInsertRow: async (
    row: IFringeCompleteModel,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_INSERT_ROW;
    request.method = "POST";
    request.payload = row;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefiteUpdateRow: async (
    row: IFringeCompleteModel,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_UPDATE_ROW;
    request.method = "PUT";
    request.payload = row;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefiteDeleteRow: async (
    fringeBenefitID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_DELETE_ROW;
    request.method = "DELETE";
    request.payload = {
      fringeBenefitID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefitGet: async (
    aciCode?: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_GET;
    let parameters: string[] = [];
    if (aciCode) {
      parameters.push(`aciCode=${aciCode}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  FringeBenefitUpdate: async (
    payload?: IVehicleFringeBenefit,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.FRINGE_BENEFIT_UPDATE;
    request.payload = payload;
    request.method = "PUT";
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FringeController;
