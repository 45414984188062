// React
import { useEffect, useState } from "react";

// Style
import "./ContinuteRegistrationPage.scss";

// Translation library
import i18next from "i18next";

// Components step
import CustomStepper, {
  ICustomStepperItem,
  stepperGoToPage,
} from "../../Components/CustomStepper/CustomStepper";
import StepAssignedDrivers from "../../Components/StepAssignedDrivers/StepAssignedDrivers";
import StepCompanyUsers from "../../Components/StepCompanyUsers/StepCompanyUsers";
import StepContinueRegistration from "../../Components/StepConfirmEmail/StepContinueRegistration";
import StepOrders from "../../Components/StepOrders/StepOrders";
import StepPayment from "../../Components/StepPayment/StepPayment";
import StepSummary from "../../Components/StepSummary/StepSummary";
import StepSuppliers from "../../Components/StepSupplier/StepSuppliers";
import StepVehicles from "../../Components/StepVehicles/StepVehicles";

// User info
import { useDispatch, useSelector } from "react-redux";
import LoaderBackdrop from "../../Components/LoaderBackdrop/LoaderBackdrop";
import { IRegistrationStep } from "../../Models/IRegistration";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { useNavigate } from "react-router-dom";
import { IStepperStep } from "../../Models/IStepperBody";
import {
  checkAccountSessionStatusRedirect,
} from "../../Utils/Session";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { ISubscriptions } from "../../Models/ISubscriptions";
import { Alert } from "@mui/material";
import AppRoutes from "../../Costants/AppRoutes";

const ContinuteRegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  const currentStep: IStepperStep | undefined = useSelector(
    (state: GlobalState) => state.generic.currentLastStep
  );
  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );
  const [loadingRegistrationSteps, setLoadingRegistrationSteps] =
    useState<boolean>(false);
  const [noStepAvailable, setNoStepAvailable] = useState<boolean>(false);
  const [registrationSteps, setRegistrationSteps] = useState<
    IRegistrationStep[]
  >([]);
  const [availableSteps, setAvailableSteps] = useState<ICustomStepperItem[]>(
    []
  );

  const [pendingForPayment, setPendingForPayment] = useState<boolean>(false);

  const getRegistrationSteps = () => {
    setLoadingRegistrationSteps(true);
    ApiService.StepperController.StepperGetData((response: IAPIResponse) => {
      if (response.error === null) {
        let payStep: any = response.payload.find(
          (x: any) => x.registrationStepID === 9 && x.registrationStepCompleted
        );
        setPendingForPayment(payStep !== undefined);
        setRegistrationSteps(response.payload);
      } else {
        if (response.raw.status === 404) {
          setNoStepAvailable(true);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    });
  };

  const initRegStepComponents = () => {
    let steps: (ICustomStepperItem | undefined)[] = (
      registrationSteps ?? []
    ).map((stepItem: IRegistrationStep, i: number) => {
      switch (stepItem.registrationStepID) {
        case 1:
          // Step 1 - Continue Registration
          return {
            element: (
              <StepContinueRegistration
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
                stepsActive={registrationSteps.map((x: IRegistrationStep, i: number) => {return {stepID: x.registrationStepID, i: i + 1}})}
              />
            ),
            stepTitle: i18next.t("navigation:titleContinueRegistrationStep"),
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 3:
          // Step 3 - Company Users
          return {
            element: (
              <StepCompanyUsers
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next.t("navigation:titleCompanyUsersStep").toString(),
            skipStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 4:
          // Step 4 - Suppliers
          return {
            element: (
              <StepSuppliers
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next.t("navigation:titleSuppliersStep").toString(),
            skipStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 5:
          // Step 5 - Drivers
          return {
            element: (
              <StepAssignedDrivers
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next
              .t("navigation:titleAssignedDriversStep")
              .toString(),
            skipStep: true,
            updateStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 6:
          // Step 6 - Orders
          return {
            element: (
              <StepOrders
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next.t("navigation:titleOrdersStep").toString(),
            skipStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 7:
          // Step 7 - Vehicles, Contracts, Services
          return {
            element: (
              <StepVehicles
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next.t("navigation:titleVehiclesStep").toString(),
            updateStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 8:
          // Step 8 - Summary
          return {
            element: (
              <StepSummary
                stepId={stepItem.registrationStepID}
                nextPage={i + 1}
              />
            ),
            stepTitle: i18next.t("navigation:titleSummaryStep").toString(),
            skipStep: true,
            onNext: () => {
              return true;
            },
          } as ICustomStepperItem;
        case 9:
          // Step 9 - Payment
          return {
            element: (
              <StepPayment
                isRegistration={true}
                stepId={stepItem.registrationStepID}
                nextPage={i}
              />
            ),
            stepTitle: i18next.t("navigation:titlePaymentStep").toString(),
            onNext: () => {
              return false;
            },
          } as ICustomStepperItem;
      }
    });

    setAvailableSteps(
      steps.filter(
        (x: ICustomStepperItem | undefined) => x !== undefined
      ) as any
    );
  };

  const checkRegistrationEnd = () => {
    ApiService.SubscriptionController.SubscriptionGetData(
      null,
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.payload && response.payload.length > 0) {
            let subscriptionStatus: boolean =
              response.payload[0].enabled || response.payload[0].isTrial;
            dispatch(GenericActions.setSubscriptionEnabled(subscriptionStatus));
            dispatch(
              GenericActions.setCurrentSubscription(response.payload[0])
            );
            ApiService.StepperController.StepperGetLastData(
              (response: IAPIResponse) => {
                if (response.error === null) {
                  navigate(
                    checkAccountSessionStatusRedirect(
                      response.payload.registrationCompleted,
                      getCurrentSubscription.enabled,
                      getCurrentSubscription.paymentRequired,
                      getCurrentSubscription.isTrial
                    )
                  );
                } else {
                  ToastMessage(response.error, "error");
                }
              }
            );
          }else{
            navigate(AppRoutes.STORE_SUBSCRIPTION_PAGE);
          }
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  };

  useEffect(() => {
    initRegStepComponents();
    loadInitialPage();
  }, [registrationSteps]);

  useEffect(() => {
    checkRegistrationEnd();
    getRegistrationSteps();
  }, []);

  const loadInitialPage = () => {
    if (loggedUser && currentStep && !currentStep.registrationCompleted) {
      for (let i = 0; i < registrationSteps.length; i++) {
        if (
          registrationSteps[i].registrationStepID ===
          currentStep.registrationStepID
        ) {
          stepperGoToPage(i);
        }
      }
      setLoadingRegistrationSteps(false);
    } else {
      setLoadingRegistrationSteps(false);
    }
  };

  /* RETURN */
  return (
    <div>
      {getCurrentSubscription &&
        !getCurrentSubscription.enabled &&
        getCurrentSubscription.paymentRequired &&
        !getCurrentSubscription.isTrial && (
          <Alert severity="warning">
            {i18next.t("error:SUBSCRIPTION_EXPIRED_BANNER")}
          </Alert>
        )}
      {!pendingForPayment && registrationSteps.length > 0 && (
        <CustomStepper
          onFinish={() => {
            window.document.dispatchEvent(new Event("set-registration-end"));
          }}
          stepperNodes={availableSteps}
        />
      )}

      {pendingForPayment && (
        <div style={{ height: "50vh", padding: "10em" }}>
          <Alert severity="warning">
            {i18next.t("navigation:payment_is_pending")}
          </Alert>
        </div>
      )}

      {!loadingRegistrationSteps && registrationSteps.length === 0 && (
        <div style={{ height: "50vh", padding: "10em" }}>
          <Alert severity="warning">
            {i18next.t("navigation:no_reg_step_pls_wait")}
          </Alert>
        </div>
      )}

      {noStepAvailable && (
        <div className="registration-page-no-more-steps">
          {i18next.t("navigation:no_step_more_register")}
        </div>
      )}

      <LoaderBackdrop loading={loadingRegistrationSteps} />
    </div>
  );
};

export default ContinuteRegistrationPage;
