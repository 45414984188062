import { IAPIRequest, IAPIResponse } from "./AjaxService";

const ACCEPT_ALL_GETS: boolean = true;
const DROP_CACHE_AFTER_TIME: number = 3000;
const API_ELIGIBLE_FOR_CACHE: string[] = [];

const initCache = () => {
  let c = localStorage.getItem("api-cache-subsystem");
  if (c) {
    return JSON.parse(c);
  }
  return {};
};

var StoredCacheApi: any = initCache();

export const RemoveCacheVoice = (apiKey: string) => {
  let keys = Object.keys(StoredCacheApi);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i].toLocaleLowerCase().includes(apiKey.toLocaleLowerCase())) {
      delete StoredCacheApi[keys[i]];
    }
  }
  localStorage.setItem("api-cache-subsystem", JSON.stringify(StoredCacheApi));
};

const apiIsEligible = (apiKey: string) => {
  if (apiKey) {
    if (ACCEPT_ALL_GETS) {
      return true;
    } else {
      for (let i = 0; i < API_ELIGIBLE_FOR_CACHE.length; i++) {
        let match = API_ELIGIBLE_FOR_CACHE[i];
        if (apiKey.toLocaleLowerCase().includes(match.toLocaleLowerCase())) {
          return true;
        }
      }
    }
  }

  return false;
};

const setStoreCache = (cacheKey: string, cacheValue: IAPIResponse) => {
  StoredCacheApi[cacheKey] = cacheValue;
  localStorage.setItem("api-cache-subsystem", JSON.stringify(StoredCacheApi));
};

export const deleteStoreCacheAPI = () => {
  StoredCacheApi = {};
  localStorage.removeItem("api-cache-subsystem");
};

const getAPIUniqueKeyDescriptor = (apiCall: IAPIRequest) => {
  return apiCall.url.replace(window.location.origin, "");
};

export const TryGetApiFromCache = (apiCall: IAPIRequest) => {
  // console.log("Try request API from cache. Request is", apiCall.url, apiCall);
  let data = null;
  let apiLockKey = getAPIUniqueKeyDescriptor(apiCall);
  if (apiCall.method?.toLocaleLowerCase() === "get") {
    data = StoredCacheApi[apiLockKey];
    DelayedClearAPICache();
  }
  return data;
};

export const DelayedClearAPICache = () => {
  if (DROP_CACHE_AFTER_TIME > 0) {
    setTimeout(() => {
      deleteStoreCacheAPI();
    }, DROP_CACHE_AFTER_TIME);
  }
};

export const AddApiInCache = (apiCall: IAPIRequest, response: IAPIResponse) => {
  let apiLockKey = getAPIUniqueKeyDescriptor(apiCall);

  if (
    apiCall.method?.toLocaleLowerCase() === "get" &&
    response.error === null &&
    response.raw.status === 200 &&
    apiIsEligible(apiLockKey)
  ) {
    setStoreCache(apiLockKey, { ...response });
    DelayedClearAPICache();
  }
};
