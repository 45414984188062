/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./DealerPageSent.scss";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../../Utils/Toastify";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../../Components/DataGridWrap/DataGridWrap";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";

/* MUI */
import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

/* COMPONENT */
const DealerPageSent = () => {
  /* STATES */
  const [list, setList] = useState<any>([]);
  // Loading
  const [loadingGet, setLoadingGet] = useState<boolean>(false);

  /* API */
  // Get dealer request list
  const GET_Prompt_Delivery_Dealer_Sent = () => {
    setLoadingGet(true);

    ApiService.CarConfiguratorController.GET_PROPOSAL_PROMPT_DELIVERY(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingGet(false);
      }
    );
  };

  /* USE EFFECT */
  useEffect(() => {
    GET_Prompt_Delivery_Dealer_Sent();
  }, []);

  /* FUNCTIONS */
  // Vehicle cost
  const renderVehicleCost = (props: GridRenderCellParams) => {
    return <div>{props.value} €</div>;
  };

  // Vehicle status
  const renderVehicleStatus = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value === true
          ? i18next.t("form:proposalTypeOk")
          : i18next.t("form:proposalTypeNo")}
      </div>
    );
  };

  // Vehicle registred
  const renderVehicleRegistred = (props: GridRenderCellParams) => {
    return (
      <div>
        {props.value === true
          ? i18next.t("form:registredVehicle")
          : i18next.t("form:noRegistredVehicle")}
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "brand",
      headerName: i18next.t("form:orderBrand"),
    },
    {
      field: "model",
      headerName: i18next.t("form:orderModel"),
    },
    {
      field: "fitting",
      headerName: i18next.t("form:orderEquipment"),
    },
    {
      field: "vehicleStatus",
      headerName: i18next.t("form:proposalType"),
      renderCell: renderVehicleStatus,
    },
    {
      field: "vehicleAvailability",
      headerName: i18next.t("form:vehicleAvailability"),
    },
    {
      field: "vehicleCost",
      headerName: i18next.t("form:fuelSupplyCost"),
      renderCell: renderVehicleCost,
      type: "number"
    },
    {
      field: "vehicleRegistered",
      headerName: i18next.t("form:registred"),
      renderCell: renderVehicleRegistred,
      type: "number"
    },
    {
      field: "outsideColors",
      headerName: i18next.t("navigation:outsideColor"),
    },
    {
      field: "insideColors",
      headerName: i18next.t("navigation:insideColor"),
    },
  ];

  /* ROWS */
  const rowsDef: any = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      vehiclesRequestPromptDeliveryID: list[i].vehiclesRequestPromptDeliveryID,

      brand: list[i].brandDescription,
      modal: list[i].modalDescription,
      fitting: list[i].fittingDescription,
      vehicleStatus: list[i].vehicleStatus,
      vehicleAvailability: list[i].vehicleAvailability,
      vehicleCost: list[i].vehicleCost,
      vehicleRegistered: list[i].vehicleRegistered,
      outsideColors: list[i].outsideColors,
      insideColors: list[i].insideColors,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_offers_sent")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} hideToolbar={true} />
        </Box>
      </div>

      <LoaderBackdrop loading={loadingGet} />
    </div>
  );
};

export default DealerPageSent;
