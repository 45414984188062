import {
  Accordion,
  AccordionSummary,
  ToggleButton,
  ToggleButtonGroup,
  FormGroup,
  Switch,
  FormControlLabel,
  Alert,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PricingSubscriptions from "../PricingSubscriptions/PricingSubscriptions";
import LoaderBackdrop from "../LoaderBackdrop/LoaderBackdrop";
import ServicesSubscriptions from "../ServicesSubscriptions/ServicesSubscriptions";
import "./StepFleetPlan.scss";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import {
  IInsertTrialSubscription,
  ISubscriptions,
  ISubscriptionsDuration,
} from "../../Models/ISubscriptions";
import {
  IFleetPlan,
  IPaymentEvaluation,
  ISavePlanService,
} from "../../Models/IFleetPlan";
import { IAddedService } from "../../Models/IService";
import { JSONPrint, calcVatCost, currencyDecoder } from "../../Utils/Decoder";
import { stepperGoToPage } from "../CustomStepper/CustomStepper";

import { IAPIResponse } from "../../Services/Internal/AjaxService";
import ApiService from "../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../Models/IUser";
import { GlobalState } from "../../Reducers/RootReducer";
import AppRoutes from "../../Costants/AppRoutes";
import { RegistrationAction } from "../../Reducers/Registration/RegistrationAction";
import ServicesSubscriptionsTile from "../ServicesSubscriptionsTile/ServicesSubscriptionsTile";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import SatelliteApparatus__VehiclesPageModal from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/SatelliteApparatus__VehiclesPage/SatelliteApparatus__VehiclesPageModal";
import RequestFuelCardModal from "../../Pages/DashboardPage/DashBoard_Pages/VehiclesPage/Cards__VehiclesPage/RequestFuelCardModal";
import BasicBillingInfoForm from "../BasicBillingInfoForm/BasicBillingInfoForm";
import { userRoleFits } from "../../Utils/Session";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { useLocation, useNavigate } from "react-router-dom";
import { InsertPaymentPayload } from "../../Models/IPayment";
import PayPalSubscription from "../PayPalSubscription/PayPalSubscription";
import { ToastMessage } from "../../Utils/Toastify";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { displayUTC0_ISODate } from "../MultiForm/SpecialInputs/StrongDatePicker";

export interface IStepFleetPlan {
  nextPage?: number;
  stepId?: number;
  emitState?: (data: any) => void;
  emitBillingInfos?: (data: any) => void;
  newAccountID?: number | null;
  subscriptionID?: number | null;
  onlyPlans?: boolean;
  includeBillingForm?: boolean;
  isRegistration?: boolean;
}

const StepFleetPlan = (props: IStepFleetPlan) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* REDUCER OF LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const getCurrentSubscription: ISubscriptions | undefined = useSelector(
    (state: GlobalState) => state.generic.currentSubscription
  );
  const subscriptionPlanService: ISavePlanService | undefined = useSelector(
    (state: GlobalState) => state.registration.planService
  );
  const getUserServiceAddons: IAddedService[] | undefined = useSelector(
    (state: GlobalState) => state.registration.userServiceAddons
  );

  const [state, setState] = useState<any>({
    pricingType:
      subscriptionPlanService.subscriptionDurationID > 0
        ? subscriptionPlanService.subscriptionDurationID.toString()
        : "1",
  });
  const [tileView, setTileView] = useState<boolean>(true);

  const [selected, setSelected] = useState<number>(
    getCurrentSubscription && getCurrentSubscription.fleetPlanID
      ? getCurrentSubscription.fleetPlanID
      : -1
  );
  const [selectedInitial, setSelectedInitial] = useState<number>(-1);
  const [paymentEvaluation, setPaymentEvaluation] =
    useState<IPaymentEvaluation>();
  const [fleetPlans, setFleetPlans] = useState<IFleetPlan[]>([]);
  const [loadingFleetPlans, setLoadingFleetPlans] = useState<boolean>(false);
  const [servicesList, setServicesList] = useState<IAddedService[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscriptions[]>([]);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [servicesSelected, setServicesSelected] = useState<IAddedService[]>([]);
  const [loadingSubscriptions, setLoadingSubscriptions] =
    useState<boolean>(false);
  const [activePlan, setActive_] = useState<IFleetPlan>();

  const [loadingSelectedData, setLoadingSelectedData] =
    useState<boolean>(false);
  const [loadingSavePlanService, setLoadingSavePlanService] =
    useState<boolean>(false);

  const [loadingSubscriptionsDuration, setLoadingSubscriptionsDuration] =
    useState<boolean>(false);
  const [subscriptionDuration, setSubscriptionsDuration] = useState<
    ISubscriptionsDuration[]
  >([]);
  const [loadingCheckVoucher, setLoadingCheckVoucher] =
    useState<boolean>(false);
  const [voucherCode, setVoucherCode] = useState<string>("");
  const [voucherCodeApplied, setVoucherCodeApplied] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSent, setPaymentSent] = useState<boolean>(false);
  const [paymentErrored, setPaymentErrored] = useState<boolean>(false);
  const [addedServiceToDelete, setAddedServiceToDelete] = useState<number>(0);

  const [isTrial, setIsTrial] = useState<boolean>(
    getCurrentSubscription ? getCurrentSubscription.isTrial : true
  );
  const [purchaseList_, setPurchaseList] = useState<any[]>([]);
  const [billingInfoForm, setBillingInfoForm] = useState<any>(null);
  const [reloadBillingForm, setReloadBillingForm] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string>("");
  const [paypalMode, setPaypalMode] = useState<boolean>(false);
  const [paypalPayment, setPaypalPayment] = useState<boolean>(false);
  const [alreadyCreatePaypalPlan, setAlreadyCreatePaypalPlan] =
    useState<boolean>(false);

  useEffect(() => {
    if (selectedInitial === -1) {
      setSelectedInitial(selected);
    }
  }, [selected]);

  const updateChange = (name: string, value: any) => {
    let u: any = { ...state };
    u[name] = value;
    setState(u);
    if (subscriptions.length > 0 && subscriptions[0].fleetPlanID === selected) {
      setSelected(subscriptions[0].fleetPlanID);
    }
  };

  const setActive = (value: IFleetPlan) => {
    setSelected(value.fleetPlanID);
    setActive_(value);
  };

  const setActiveServices = (value: IAddedService) => {
    let services: IAddedService[] = [...servicesSelected];
    let index = services.findIndex(
      (item: IAddedService) => item.addedServiceID === value.addedServiceID
    );

    if (index <= -1) {
      services.push(value);
    } else {
      services.splice(index, 1);
    }

    setServicesSelected(services);
  };

  const loadFleetPlans = () => {
    setLoadingFleetPlans(true);
    ApiService.FleetPlanController.FleetPlanGetData(
      null,
      undefined,
      false,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(
            RegistrationAction.setFleetPlans(
              response.payload.filter((x: IFleetPlan) => !x.stored)
            )
          );
          setFleetPlans(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingFleetPlans(false);
      }
    );
  };

  const loadServices = () => {
    setLoadingServices(true);
    ApiService.ServiceController.ServiceGetAddedData(
      false,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setServicesList(response.payload);
          dispatch(
            RegistrationAction.setAddedServices(
              response.payload.filter((x: IAddedService) => !x.stored)
            )
          );
          if (subscriptionPlanService.selectedServiceIDList.length > 0) {
            let serviceSelectedBefore: IAddedService[] = [];
            for (
              let j = 0;
              j < subscriptionPlanService.selectedServiceIDList.length;
              j++
            ) {
              let addedService: IAddedService[] = response.payload.filter(
                (x: IAddedService) =>
                  x.addedServiceID ===
                  subscriptionPlanService.selectedServiceIDList[j]
                    .selectedServiceID
              );
              if (addedService.length > 0) {
                serviceSelectedBefore.push(addedService[0]);
              }
            }
            setServicesSelected(serviceSelectedBefore);
          }
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingServices(false);
      }
    );
  };

  const loadSubscriptions = () => {
    setLoadingSubscriptions(true);
    if (loggedUser) {
      ApiService.SubscriptionController.SubscriptionGetData(
        props.subscriptionID,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSubscriptions(response.payload);
            dispatch(RegistrationAction.setUserSubscriptions(response.payload));

            if (response.payload.length > 0) {
              updateChange(
                "pricingType",
                response.payload[0].subscriptionDurationID.toString()
              );
              setSelected(response.payload[0].fleetPlanID);

              if (subscriptionPlanService.selectedServiceIDList.length === 0) {
                loadSelectedData(response.payload[0].subscriptionID);
              }
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSubscriptions(false);
        }
      );
    }
  };

  const loadSubscriptionsDuration = () => {
    setLoadingSubscriptionsDuration(true);
    if (loggedUser) {
      ApiService.SubscriptionController.SubscriptionGetDataDuration(
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setSubscriptionsDuration(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSubscriptionsDuration(false);
        }
      );
    }
  };

  const loadSelectedData = (subscriptionID: number) => {
    setLoadingSelectedData(true);
    ApiService.ServiceController.ServiceGetSelectedData(
      subscriptionID,
      "",
      (response: IAPIResponse) => {
        if (response.error === null) {
          setServicesSelected(response.payload);
          dispatch(RegistrationAction.setUserServiceAddons(response.payload));
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingSelectedData(false);
      }
    );
  };

  const getPurchasedData = () => {
    let newData: ISavePlanService = {
      subscriptionID:
        subscriptions.length > 0 && subscriptions[0].subscriptionID
          ? subscriptions[0].subscriptionID
          : null,
      subscriptionDurationID: parseInt(state.pricingType),
      fleetPlanID: selected ? selected : subscriptions[0].fleetPlanID,
      selectedServiceIDList: servicesSelected
        .filter((x: IAddedService) => !x.stored && x.addedServiceCallCost <= 0)
        .map((item: IAddedService) => {
          return Object.assign({}, { selectedServiceID: item.addedServiceID });
        }),
      isTrial: isTrial,
    };

    if (newData.selectedServiceIDList.length === 0) {
      newData.selectedServiceIDList = [];
    }

    newData.subscriptionDurationID = +state.pricingType;
    newData.voucherName = voucherCodeApplied;

    return newData;
  };

  const savePlanService = () => {
    if (loggedUser) {
      if (selected <= -1) {
        ToastMessage(i18next.t("message:planServiceEmpty"), "warning");
      } else {
        if (window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD)) {
          if (props.emitState) {
            dispatch(
              RegistrationAction.setPlanServiceData({
                ...getPurchasedData(),
                ...{ accountID: props.newAccountID },
                paymentEvaluation: paymentEvaluation,
              })
            );
            props.emitState({
              data: getPurchasedData(),
              price: paymentEvaluation?.totalCost ?? 0,
              paymentEvaluation: paymentEvaluation,
            });

            if (props.nextPage) {
              stepperGoToPage(props.nextPage);
            }
          }
        }
      }
    }
  };

  const deleteAddedSubscription = (deselectedServiceIDList: any[]) => {
    if (subscriptions && subscriptions.length > 0) {
      setLoadingSelectedData(true);
      ApiService.ServiceController.ServiceDeleteAddedSubscription(
        subscriptions[0]?.subscriptionID,
        deselectedServiceIDList,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let getUserServiceAddons_ = [...getUserServiceAddons];
            dispatch(
              RegistrationAction.setUserServiceAddons(
                getUserServiceAddons_.filter(
                  (x: IAddedService) =>
                    x.addedServiceID !==
                    +deselectedServiceIDList[0].deselectedServiceID
                )
              )
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingSelectedData(false);
        }
      );
    }
  };

  const sendTrialPayment = () => {
    if (fleetPlans.length > 0 && getCurrentSubscription?.subscriptionID === 0) {
      setLoading(true);
      ApiService.SubscriptionController.SubscriptionInsertTrialSubscription(
        1,
        false,
        userRoleFits(["Broker"]) ? loggedUser?.accountID : null,
        fleetPlans.filter((x: IFleetPlan) => x.isTrialFree)[0].fleetPlanID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(GenericActions.setCurrentSubscription(response.payload));
            if (props.nextPage) {
              stepperGoToPage(props.nextPage);
            }
          } else {
            ToastMessage(
              i18next.t("navigation:payment_processing_error"),
              "error"
            );
          }

          setLoading(false);
        }
      );
    } else {
      if (props.nextPage) {
        stepperGoToPage(props.nextPage);
      }
    }
  };

  (window as any)["savePlanService"] = savePlanService;

  const fleetPlanStepSubmit = () => {
    (window as any)["savePlanService"]();
  };

  useEffect(() => {
    window.document.addEventListener(
      "fleetPlanStepSubmit",
      fleetPlanStepSubmit
    );

    loadSubscriptions();
    loadSubscriptionsDuration();
    loadFleetPlans();
    loadServices();
  }, []);

  useEffect(() => {
    getPurchasedData();
  }, [selected, isTrial, billingInfoForm, voucherCodeApplied, purchaseList_]);

  const loadPurchaseValue = () => {
    let purchaseList: any[] = [
      {
        fleetPlanID: selected,
        addedServiceID: null,
        purchaseCost: 0,
      },
    ];

    servicesSelected.forEach((service: any) => {
      purchaseList.push({
        fleetPlanID: null,
        addedServiceID: service.addedServiceID,
        purchaseCost: 0,
      });
    });
    let data: any = {
      newSubscriptionDurationID: +state.pricingType,
      purchaseList: purchaseList,
      voucherName: voucherCodeApplied,
      numExtraVehicles: activePlan?.extraVehicles ?? 0,
    };
    if (userRoleFits(["Broker", "FleetSupportAdmin"])) {
      data["accountID"] = props.newAccountID
        ? props.newAccountID
        : loggedUser?.accountID;
    }
    setPurchaseList(purchaseList);
    setProcessing(true);
    setAlreadyCreatePaypalPlan(false);
    ApiService.PaymentController.EvaluatePayment(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setPaymentEvaluation(response.payload);
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    if (selected !== -1) {
      loadPurchaseValue();
    }
  }, [state, selected, servicesSelected, voucherCodeApplied, activePlan]);

  useEffect(() => {
    if (addedServiceToDelete > 0) {
      SummonModal("added-service-delete-modal");
    }
  }, [addedServiceToDelete]);

  useEffect(() => {
    if (paymentEvaluation && props.emitState) {
      props.emitState({
        data: getPurchasedData(),
        price: paymentEvaluation.totalCost,
        paymentEvaluation: paymentEvaluation,
        selectedPlan: { ...activePlan },
        numExtraVehicles: activePlan?.extraVehicles ?? 0,
      });
    }
  }, [paymentEvaluation, voucherCodeApplied]);

  const checkVoucher = (voucherName: string) => {
    setLoadingCheckVoucher(true);
    ApiService.VoucherController.GetCheckVoucher(
      voucherName,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setVoucherCodeApplied(voucherName);
        } else {
          if (response.payload.status === 404) {
            ToastMessage(i18next.t("message:voucherNotValid"), "error");
          } else {
            ToastMessage(i18next.t("error:" + response.error), "error");
          }
        }
        setLoadingCheckVoucher(false);
      }
    );
  };

  const isReduceMode = window.location.pathname.includes(
    AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE
  );

  const selectedPlan = fleetPlans.find(
    (x: IFleetPlan) => selectedInitial === x.fleetPlanID
  );
  return (
    <>
      <div style={{ float: "right" }}>
        {window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) &&
          !window.location.pathname.includes(
            AppRoutes.DASHBOARD___ADMIN_ROUTE__CLIENTS
          ) &&
          !isReduceMode && (
            <Button
              onClick={() => {
                navigate(
                  AppRoutes.INTERNAL_DASHBOARD +
                    AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS_REDUCE
                );
              }}
              variant="contained"
              color="error"
            >
              {i18next.t("form:reduceSubscription")}
            </Button>
          )}
      </div>
      {isReduceMode && (
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___SERVICES_ROUTE__PURCHASE_SUBS
              );
            }}
            variant="contained"
          >
            Indietro
          </Button>
        </div>
      )}
      <div className="custom-stepper-content-wrap pricing-plans-step">
        {!window.location.pathname.includes(AppRoutes.INTERNAL_DASHBOARD) && (
          <h1
            dangerouslySetInnerHTML={{
              __html: i18next.t("navigation:titlePricingPlans") ?? "",
            }}
          />
        )}
        {isReduceMode && (
          <div>
            <h3>{i18next.t("navigation:reduce_plan_rule")}</h3>
          </div>
        )}
        {!isReduceMode && (
          <div>
            <h2
              className="custom-stepper-text"
              dangerouslySetInnerHTML={{
                __html: i18next.t("navigation:subtitlePricingPlans") ?? "",
              }}
            />
          </div>
        )}
        <FormGroup className="container-switch">
          <ToggleButtonGroup
            color="primary"
            value={state.pricingType}
            onChange={(e: any) => {
              updateChange("pricingType", e.target.value);
            }}
            exclusive
          >
            {subscriptionDuration.map(
              (duration: ISubscriptionsDuration, i: number) => {
                return (
                  <ToggleButton
                    key={i}
                    value={duration.subscriptionDurationID.toString()}
                  >
                    {duration.subscriptionDurationName}
                  </ToggleButton>
                );
              }
            )}
          </ToggleButtonGroup>
        </FormGroup>
        <p
          className="text-secondary"
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{
            __html: i18next.t("navigation:firstInfoPricingPlans") ?? "",
          }}
        />
      </div>
      <div className="pricing-plans-container">
        <div className="subscriptions-container">
          {fleetPlans
            .filter(
              (x: IFleetPlan) =>
                !x.stored ||
                x.fleetPlanID ===
                  ((subscriptions.length > 0 && subscriptions[0].fleetPlanID
                    ? subscriptions[0].fleetPlanID
                    : 0) as any)
            )
            .sort((a, b) =>
              a.fleetPlanMaxVehicle > b.fleetPlanMaxVehicle ? 1 : -1
            )
            .map((fleetPlan: IFleetPlan, i: number) => {
              return (
                <PricingSubscriptions
                  isReduceMode={isReduceMode}
                  // currentSelectedPlan={fleetPlans.find((x: IFleetPlan) => x.fleetPlanID === selectedInitial)}
                  currentSelectedPlan={selectedPlan}
                  onClickFullMode={(data: any) => {
                    setActive(data);
                    setIsTrial(false);
                  }}
                  onClickTrialMode={(data: any) => {
                    setIsTrial(true);
                    setActive(data);
                  }}
                  isActive={
                    subscriptions.filter(
                      (x: ISubscriptions) =>
                        fleetPlan.fleetPlanID === x.fleetPlanID &&
                        x.validTo &&
                        x.validTo >= new Date().toISOString()
                    ).length > 0
                  }
                  isRegistration={props.isRegistration}
                  isStored={fleetPlan.stored}
                  selectedTrial={isTrial && selected === fleetPlan.fleetPlanID}
                  selected={!isTrial && selected === fleetPlan.fleetPlanID}
                  subscription={fleetPlan}
                  pricingType={state.pricingType}
                  keySubscription={i}
                  key={i}
                />
              );
            })}
        </div>
        {!props.onlyPlans && (
          <Accordion defaultExpanded>
            <AccordionSummary
              sx={{ height: "3em" }}
              expandIcon={<KeyboardArrowUpIcon />}
              className="accordion-head"
            >
              <h2>{i18next.t("navigation:addServices")}</h2>
            </AccordionSummary>
            <div style={{ padding: "0.5em" }}>
              <FormControlLabel
                onChange={(e: any, checked: boolean) => {
                  setTileView(checked);
                }}
                control={<Switch defaultChecked={tileView} />}
                label={
                  !tileView
                    ? i18next.t("navigation:tableView")
                    : i18next.t("navigation:tileView")
                }
              />
            </div>
            {isTrial && (
              <Alert severity="warning">
                {i18next.t("navigation:neet_to_purchase_to_unlock")}
              </Alert>
            )}
            <div>
              <br />
              {tileView &&
                servicesList
                  .filter(
                    (x: IAddedService) =>
                      !x.stored ||
                      x.addedServiceID ===
                        getUserServiceAddons.find(
                          (s: IAddedService) =>
                            s.addedServiceID === x.addedServiceID
                        )?.addedServiceID
                  )
                  .filter(
                    (x: IAddedService) =>
                      x.addedServiceCallCost == null ||
                      x.addedServiceCallCost == 0
                  )
                  .filter((x: IAddedService) => x.stored === false) // rimuovere dopo demo
                  .map((service: IAddedService, i: number) => {
                    return (
                      <ServicesSubscriptionsTile
                        isReduceMode={isReduceMode}
                        isTrial={isTrial}
                        key={i}
                        isStored={service.stored}
                        onDeleteAddedService={(addedServiceID: number) => {
                          setAddedServiceToDelete(addedServiceID);
                        }}
                        isActive={
                          getUserServiceAddons.filter(
                            (x: IAddedService) =>
                              service.addedServiceID === x.addedServiceID &&
                              x.validTo >= new Date().toISOString()
                          ).length > 0
                        }
                        selected={
                          servicesSelected.filter(
                            (item: IAddedService) =>
                              service.addedServiceID === item.addedServiceID
                          ).length > 0
                        }
                        keySubscription={i}
                        onClick={(value: any) => {
                          setActiveServices(value);
                        }}
                        service={service}
                        pricingType={state.pricingType}
                        getUserServiceAddons={getUserServiceAddons}
                      />
                    );
                  })}
            </div>
            {!tileView &&
              servicesList
                .filter(
                  (x: IAddedService) =>
                    !x.stored ||
                    x.addedServiceID ===
                      getUserServiceAddons.find(
                        (s: IAddedService) =>
                          s.addedServiceID === x.addedServiceID
                      )?.addedServiceID
                )
                .filter(
                  (x: IAddedService) =>
                    x.addedServiceCallCost == null ||
                    x.addedServiceCallCost == 0
                )
                .filter((x: IAddedService) => x.stored === false) // rimuovere dopo demo
                .map((service: IAddedService, i: number) => {
                  return (
                    <ServicesSubscriptions
                      isReduceMode={isReduceMode}
                      isTrial={isTrial}
                      key={i}
                      isStored={service.stored}
                      onDeleteAddedService={(addedServiceID: number) => {
                        setAddedServiceToDelete(addedServiceID);
                      }}
                      onlyView={!props.isRegistration}
                      isActive={
                        getUserServiceAddons.filter(
                          (x: IAddedService) =>
                            service.addedServiceID === x.addedServiceID &&
                            x.validTo >= new Date().toISOString()
                        ).length > 0
                      }
                      selected={
                        servicesSelected.filter(
                          (item: IAddedService) =>
                            service.addedServiceID === item.addedServiceID
                        ).length > 0
                      }
                      keySubscription={i}
                      onClick={(value: any) => {
                        setActiveServices(value);
                      }}
                      service={service}
                      pricingType={state.pricingType}
                      getUserServiceAddons={getUserServiceAddons}
                    />
                  );
                })}
          </Accordion>
        )}
        {window.location.pathname.includes(
          AppRoutes.CONTINUE_REGISTRATION_ROUTE
        ) && (
          <p className="text-secondary">
            {i18next.t("navigation:secondInfoPricingPlans")}
          </p>
        )}

        {props.includeBillingForm && !reloadBillingForm && !isTrial && (
          <div className="pricing-plans-footer">
            <div className="pricing-plans-footer-left">
              <div>
                <BasicBillingInfoForm
                  emit={(data: any) => {
                    if (props.emitBillingInfos) {
                      props.emitBillingInfos(data);
                    }
                  }}
                  selectedSubscriptionDuration={state.pricingType}
                />
              </div>
            </div>

            <div className="pricing-plans-footer-right">
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  flexDirection: "column",
                }}
              >
                <TextField
                  variant={"outlined"}
                  size="small"
                  fullWidth
                  onChange={(t: any) => {
                    setVoucherCode(t.target.value);
                  }}
                  label={`${i18next.t("navigation:voucherPayment")}`}
                />
                <Button
                  onClick={() => {
                    checkVoucher(voucherCode);
                  }}
                  disabled={voucherCode.length === 0}
                  variant="contained"
                >
                  {i18next.t("form:applyVoucherCode")}
                </Button>
              </div>
              <p>
                {i18next.t("navigation:titleCallToActionPricingPlans")}
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18next
                      .t("navigation:subtitleCallToActionPricingPlans")
                      .replace("CONTACTS_ROUTE", AppRoutes.CONTACTS_ROUTE),
                  }}
                />
              </p>
              <div>
                <span>
                  <small>{i18next.t("generic:total")}</small>
                </span>
                <span>
                  <small>{currencyDecoder(paymentEvaluation?.cost ?? 0)}</small>
                </span>
              </div>
              <div>
                <span>
                  <small>
                    {i18next.t("generic:total_credit_from_current_sub")}
                  </small>
                </span>
                <span>
                  <small>
                    {currencyDecoder(
                      paymentEvaluation ? (paymentEvaluation?.creditFromCurrentSub + paymentEvaluation?.remainingCredit) : 0 
                    )}
                  </small>
                </span>
              </div>
              {paymentEvaluation && paymentEvaluation?.giftCredit > 0 && (
                <div style={{ color: "#009fe3", fontWeight: "bolder" }}>
                  <span>
                    <small title={i18next.t("generic:total_credit_gift_info") as string}>
                      
                      {i18next.t("generic:total_credit_gift")}
                      {" "}<svg style={{height: "1.3em", fill: "#009fe3"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/></svg>
                    </small>
                    <i
                      className="info-modal-form"
                      style={{width: "1.2em", height: "1.2em", fontWeight: "500"}}
                      onClick={() => SummonModal("infoTotalCreditGiftModal")}
                    >
                      i
                    </i>
                  </span>
                  <span>
                    <small>
                      {currencyDecoder(paymentEvaluation?.giftCredit ?? 0)}
                    </small>
                  </span>
                </div>
              )}
              <div>
                <span>
                  <small>{i18next.t("generic:discount")}</small>
                </span>
                <span>
                  <small>
                    {(paymentEvaluation?.discount ?? 0) * 100 + "%"}
                  </small>
                </span>
              </div>
              <div>
                <span>
                  <small>{i18next.t("generic:final_price_no_iva")}</small>
                </span>
                <span>
                  <small>
                    {currencyDecoder(paymentEvaluation?.totalCostNoVAT ?? 0)}
                  </small>
                </span>
              </div>
              <div>
                <span>
                  <small>{i18next.t("generic:vatCost")}</small>
                </span>
                <span>
                  <small>
                    {calcVatCost(
                      paymentEvaluation?.totalCost ?? 0,
                      "extract",
                      false
                    )}
                  </small>
                </span>
              </div>
              {paymentEvaluation && paymentEvaluation?.remainingCredit > 0 && (
                <div style={{ color: "green", fontWeight: "bolder" }}>
                  <span>
                    <small>{i18next.t("generic:total_remaining_credit")}</small>
                  </span>
                  <span>
                    <small>
                      {currencyDecoder(paymentEvaluation?.remainingCredit ?? 0)}
                    </small>
                  </span>
                </div>
              )}
              <div>
                <h3>{i18next.t("generic:totalWithVAT")}</h3>
                <h3>{currencyDecoder(paymentEvaluation?.totalCost ?? 0)}</h3>
              </div>
              {paymentEvaluation && (
                <Alert severity="info" style={{ margin: "1em 0" }}>
                  <div style={{display: "block"}} dangerouslySetInnerHTML={{
                    __html: i18next
                      .t("generic:paymentInfoDescription")
                      .replace(
                        "nextPaymentDT",
                        displayUTC0_ISODate(
                          paymentEvaluation.nextPaymentDT,
                          true
                        )
                      ).replace("nextPaymentTotalCost", paymentEvaluation.nextPaymentTotalCost.toString()),
                  }}
                />
                </Alert>
              )}
            </div>
          </div>
        )}
        <SmartModal
          modalUniqueId="added-service-delete-modal"
          title={i18next.t("navigation:deleteAddedServicePermantly")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("navigation:deleteAddedServicePermantlyDescr")
                  .replace(
                    "SERVICE_NAME",
                    servicesList.find(
                      (x: IAddedService) =>
                        x.addedServiceID === addedServiceToDelete
                    )?.addedServiceName ?? ""
                  ),
              }}
            />
          }
          onReject={() => {
            setAddedServiceToDelete(0);
            DismissModal("added-service-delete-modal");
          }}
          onAccept={() => {
            if (addedServiceToDelete > 0) {
              let getUserServiceAddons_ = [...getUserServiceAddons];
              let tmpAddedServece = getUserServiceAddons_.find(
                (x: IAddedService) => x.addedServiceID === addedServiceToDelete
              );
              if (tmpAddedServece) {
                setActiveServices(tmpAddedServece);
              }
              dispatch(
                RegistrationAction.setUserServiceAddons(
                  getUserServiceAddons_.filter(
                    (x: IAddedService) =>
                      x.addedServiceID !== addedServiceToDelete
                  )
                )
              );
            }
            DismissModal("added-service-delete-modal");
          }}
        />
        <SmartModal
          modalUniqueId={"request-satellite-equipment-modal"}
          title={i18next.t("navigation:_satellite_apparatus")}
          modalInnerComponent={<SatelliteApparatus__VehiclesPageModal />}
        />
        <SmartModal
          modalUniqueId={"request-fuel-card-modal"}
          title={i18next.t("form:fuelCardRequest")}
          modalInnerComponent={<RequestFuelCardModal />}
        />
        <SmartModal
          modalUniqueId="cant_pay_modal_subscription"
          title={i18next.t("message:cant_pay_title")}
          modalInnerComponent={<div>{i18next.t("message:cant_pay_text")}</div>}
          buttons={[
            {
              text: i18next.t("navigation:close"),
              onClick: () => {
                DismissModal("cant_pay_modal_subscription");
              },
            },
          ]}
        />
        <SmartModal
          title={i18next.t("generic:total_credit_gift")}
          modalUniqueId="infoTotalCreditGiftModal"
          modalInnerComponent={
            <div>
              <p>
                {i18next.t("generic:total_credit_gift_info")}
              </p>
            </div>
          }
        />
        <LoaderBackdrop
          loading={
            loadingFleetPlans ||
            loadingServices ||
            loadingSubscriptions ||
            loadingSelectedData ||
            loadingSavePlanService ||
            loadingSubscriptionsDuration ||
            loading ||
            processing
          }
        />
      </div>
    </>
  );
};

export default StepFleetPlan;
