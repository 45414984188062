/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ICompanyGet } from "../../../../../Models/ICompany";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./Companies__AnagraphicsPage.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import FormCompanyUser from "../../../../../Components/StepCompanyUsers/FormCompanyUser";

/* MUI */
import { Box, Button, ButtonGroup } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const Companies__AnagraphicsPage = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );

  /* STATES */
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(false);

  // Container for company list
  const [companyList, setCompanyList] = useState<ICompanyGet[]>([]);
  const [currentCompany, setCurrentCompany] = useState<any>(null);
  /* APIs */
  // Load company list
  const loadCompanyList = () => {
    setLoadingCompanyList(true);
    if (loggedUser) {
      ApiService.CompanyController.CompanyGetData(
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCompanyList(response.payload);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingCompanyList(false);
        }
      );
    }
  };

  const editRow = (props: GridRenderCellParams) => {
    return (
      <Button
        onClick={() => {
          let company_: any = companyList.filter(
            (x: ICompanyGet) => x.companyID === props.row.companyID
          )[0];
          setCurrentCompany(company_);
          SummonModal("company-page-modal");
        }}
      >
        <EditIcon className="icon-table" />
      </Button>
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadCompanyList();
  }, []);

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: editRow,
      type: 'custom'
    },
    {
      field: "companyBusinessName",
      headerName: i18next.t("form:orderCompany"),
    },
    {
      field: "companyVAT",
      headerName: i18next.t("form:vatNumber"),
    },
    {
      field: "companyFiscalCode",
      headerName: i18next.t("form:taxIdCode"),
    },
    {
      field: "companyReferentName",
      headerName: i18next.t("form:nameAlias"),
    },
    {
      field: "companyReferentEmail",
      headerName: i18next.t("form:email"),
    },
    {
      field: "companyReferentPhone",
      headerName: i18next.t("form:telephone"),
    },
    {
      field: "companyBillingCity",
      headerName: i18next.t("form:city"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < companyList.length; i++) {
    rowsDef.push({
      id: i,
      companyID: companyList[i].companyID,
      companyBusinessName: companyList[i].companyBusinessName,
      companyVAT: companyList[i].companyVAT,
      companyFiscalCode: companyList[i].companyFiscalCode,
      companyReferentName:
        companyList[i].companyReferentName +
        " " +
        companyList[i].companyReferentSurname,
      companyReferentEmail: companyList[i].companyReferentEmail,
      companyReferentPhone: companyList[i].companyReferentPhone,
      companyBillingCity: companyList[i].companyBillingCity,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_companies")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentCompany(null);
                SummonModal("company-page-modal");
              }}
            >
              {i18next.t("navigation:_add_company")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          hideButtons={true}
          modalUniqueId="company-page-modal"
          title={i18next.t("generic:titleAddCompany")}
          modalInnerComponent={
            <FormCompanyUser
              loggedUser={loggedUser}
              data={currentCompany ?? []}
              companyUser={companyUser}
              doRefreshForm={() => {
                loadCompanyList();
              }}
              idModal="company-page-modal"
            />
          }
        />

        <LoaderBackdrop loading={loadingCompanyList} />
      </div>
    </div>
  );
};

export default Companies__AnagraphicsPage;
