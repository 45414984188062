import { useEffect, useState } from "react";
import "./MultiLangDescPicker.scss";
import { IGenericDescriptor } from "../../Models/IService";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import { ToastMessage } from "../../Utils/Toastify";
import { Alert, CircularProgress, FormControl, TextField } from "@mui/material";
import i18next from "i18next";
import { IISOLanguage } from "../FlagPicker/FlagPicker";

(window as any)["knwonLanguages"] = [];

export interface IMultiLangDescPickerProps {
  defaultValue: IGenericDescriptor[];
  disabled?: boolean;
  noNotes?: boolean;
  noDescription?: boolean;
  onChange: (data: IGenericDescriptor[]) => void;
}

const MultiLangDescPicker = (props: IMultiLangDescPickerProps) => {
  const [state, setState] = useState<IGenericDescriptor[]>([]);
  const [languages, setLanguages] = useState<IISOLanguage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const initState = (langs: IISOLanguage[]) => {
    let state_: IGenericDescriptor[] = [];
    for (let i = 0; i < langs.length; i++) {
      let curr: IGenericDescriptor | null = null;
      if (props.defaultValue && props.defaultValue.length > i) {
        curr = props.defaultValue.filter((x) => x.languageID === langs[i].languageID)[0];
      }
      let newItem: IGenericDescriptor = {
        name: curr ? curr.name : "",
        description: curr ? curr.description : "",
        note: curr ? curr.note : "",
        languageID: langs[i].languageID,
      };

      if (props.noNotes) {
        delete newItem.note;
      }
      if (props.noDescription) {
        delete newItem.description;
      }
      state_.push(newItem);
    }
    setState(state_);
  };

  const getLanguages = () => {
    if ((window as any)["knwonLanguages"].length > 0) {
      let langs: IISOLanguage[] = (window as any)["knwonLanguages"];
      initState(langs);
      setLanguages(langs);
      return;
    }

    setLoading(true);
    ApiService.DriverController.DriverGetLanguages((response: IAPIResponse) => {
      if (response.error === null) {
        let langs: IISOLanguage[] = response.payload;
        initState(langs);
        setLanguages(langs);
        (window as any)["knwonLanguages"] = langs;
      } else {
        ToastMessage(response.error, "error");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (state.length > 0) {
      props.onChange([...state]);
    }
  }, [state]);

  useEffect(() => {
    getLanguages();
  }, []);

  const updateAdIndex = (value: string, key: string, index: number) => {
    let state_: any = [...state];
    state_[index][key] = value;
    setState(state_);
  };

  return (
    <div>
      <hr />
      <Alert severity="info">{i18next.t("form:translations_list")}</Alert>
      <div style={{ marginTop: "0.5em" }}></div>
      <FormControl disabled={props.disabled} fullWidth>
        {loading && (
          <div
            style={{
              margin: "2em 45%",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading &&
          languages.map((x: IISOLanguage, i: number) => {
            return (
              <div key={i} style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                    minWidth: "7em",
                  }}
                >
                  {x.languageName}
                </div>
                <TextField
                  required
                  style={{ margin: "0.3em" }}
                  fullWidth
                  value={state[i].name}
                  disabled={props.disabled}
                  label={i18next.t("form:name")}
                  onChange={(e: any) => {
                    updateAdIndex(e.target.value, "name", i);
                  }}
                />
                {!props.noDescription && (
                  <TextField
                    fullWidth
                    style={{ margin: "0.3em" }}
                    value={state[i].description}
                    disabled={props.disabled}
                    label={i18next.t("form:description")}
                    onChange={(e: any) => {
                      updateAdIndex(e.target.value, "description", i);
                    }}
                  />
                )}
                {!props.noNotes && (
                  <TextField
                    fullWidth
                    style={{ margin: "0.3em" }}
                    value={state[i].note}
                    disabled={props.disabled}
                    label={i18next.t("form:note")}
                    onChange={(e: any) => {
                      updateAdIndex(e.target.value, "note", i);
                    }}
                  />
                )}
              </div>
            );
          })}
      </FormControl>
    </div>
  );
};

export default MultiLangDescPicker;
