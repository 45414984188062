import './StructurePage.scss'
import BannerImage from "../../Assets/ImagesEmbedded/struttura.jpg"
import SecurityImage from "../../Assets/ImagesEmbedded/security.jpg"
import DevelopersImage from "../../Assets/ImagesEmbedded/sviluppatori.jpg"
import i18next from 'i18next';

const StructurePage = () => {
    return (
        <div>
          <div className="banner-image">
          <div className="title-banner">{i18next.t('intro:structure')}</div>
            <img src={BannerImage} alt='' />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              minHeight: "60vh",
            }}
          >
            <div>
              <div className='section-wrapper'>
                <div className='mid-screen-image'>
                  <img src={DevelopersImage} alt='' className='image' />
                </div>
                <div className='mid-screen-text'>
                  <div>
                <h1  className="title-background">{i18next.t("intro:structure_firstTitle")}</h1>
                <p>{i18next.t("intro:structure_firstDescription1")}</p>
                <p>{i18next.t("intro:structure_firstDescription2")}</p>
                </div>
                </div>
              </div>
              <div className='section-wrapper'>
                <div className='mid-screen-text'>
                  <div>
                <h1  className="title-background">{i18next.t("intro:structure_secondTitle")}</h1>
                <p>{i18next.t("intro:structure_secondDescription")}</p>
                </div>
                </div>
                <div className='mid-screen-image'>
                  <img src={SecurityImage} alt='' className='image'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default StructurePage