/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./OwnershipCosts__StatisticsPage.scss";

/* LIBRARIES */
import i18next from "i18next";

/* MODELS */
import { IVehicleDataByAccountID } from "../../../../../Models/IVehicleDataByAccountID";
import { IUser } from "../../../../../Models/IUser";

/* CHART */
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import MultiForm, {
} from "../../../../../Components/MultiForm/MultiForm";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { formatPlate } from "../../VehiclesPage/VehiclesPage";

/* MUI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* REDUCER */
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import { IVehicleTotalCostStatistics, IStatisticsTotalCost } from "../../../../../Models/IStatistics";

/* CHART */
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Title,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);
const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick;
/* COMPONENT */
const OwnershipCosts__StatisticsPage_Data = () => {
  /* LOGGED USER */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );

  /* VARS */
  const currentYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear() - 1;
  const twoLastYear = new Date().getFullYear() - 2;

  /* STATES */
  // State for data container
  const [state, setState] = useState<any>({ statisticsVehicleID: -1 });
  const [costsList, setCostsList] = useState<IVehicleTotalCostStatistics[]>([]);
  const [vehicleData, setVehicleData] = useState<IVehicleDataByAccountID[]>([]);

  // Costs states
  // Histogram
  const [sumCurrentYear, setSumCurrentYear] = useState<number>(0);
  const [vehiclesCurrentYear, setVehiclesCurrentYear] = useState<number>(0);
  const [barCurrentYear, setBarCurrentYear] = useState<any[]>([]);

  const [sumLastYear, setSumLastYear] = useState<number>(0);
  const [vehiclesLastYear, setVehiclesLastYear] = useState<number>(0);
  const [barLastYear, setBarLastYear] = useState<any[]>([]);

  const [sumTwoLastYear, setSumTwoLastYear] = useState<number>(0);
  const [vehiclesTwoLastYear, setVehiclesTwoLastYear] = useState<number>(0);
  const [barTwoLastYear, setBarTwoLastYear] = useState<any[]>([]);

  // Bars
  const [totalTicket, setTotalTicket] = useState<any[]>([]);
  const [totalSupply, setTotalSupply] = useState<any[]>([]);
  const [totalVehicleService, setTotalVehicleService] = useState<any[]>([]);
  const [totalPropertyContract, setTotalPropertyContract] = useState<any[]>([]);
  const [totalPropertyContractOriginal, setTotalPropertyContractOriginal] = useState<any[]>([]);
  const [totalLongRentalContract, setTotalLongRentalContract] = useState<any[]>([]);
  const [totalLongRentalContractOriginal, setTotalLongRentalContractOriginal] = useState<any[]>([]);
  const [totalLeasingContract, setTotalLeasingContract] = useState<any[]>([]);
  const [totalLeasingContractOriginal, setTotalLeasingContractOriginal] = useState<any[]>([]);
  const [totalFundingContract, setTotalFundingContract] = useState<any[]>([]);
  const [totalFundingContractOriginal, setTotalFundingContractOriginal] = useState<any[]>([]);
  const [totalVehicleContract, setTotalVehicleContract] = useState<any[]>([]);
  const [totalVehicleMaintenance, setTotalVehicleMaintenance] = useState<any[]>([]);
  const [totalVehicleDamage, setTotalVehicleDamage] = useState<any[]>([]);
  const [totalAdditionalRoadTax, setTotalAdditionalRoadTax] = useState<any[]>([]);
  const [totalRoadTax, setTotalRoadTax] = useState<any[]>([]);
  const [totalInsurance, setTotalInsurance] = useState<any[]>([]);
  const [totalTelepass, setTotalTelepass] = useState<any[]>([]);
  const [totalTelepassOriginal, setTotalTelepassOriginal] = useState<any[]>([]);
  const [totalInsuranceOriginal, setTotalInsuranceOriginal] = useState<any[]>([]);
  const [totalRoadTaxOriginal, setTotalRoadTaxOriginal] = useState<any[]>([]);
  const [totalAdditionalRoadTaxOriginal, setTotalAdditionalRoadTaxOriginal] = useState<any[]>([]);
  const [totalVehicleServiceOriginal, setTotalVehicleServiceOriginal] = useState<any[]>([]);
  // Loading
  const [loadingListCosts, setLoadingListCosts] = useState<boolean>(false);
  const [loadingVehicleData, setLoadingVehicleData] = useState<boolean>(false);
  const [toolbarHidden, setToolbarHidden] = useState<any>({});
  const [otherCostTotal, setOtherCostTotal] = useState<any[]>([]);

  /* API */
  // Load vehicle data
  const loadVehicleData = () => {
    if (loggedUser) {
      setLoadingVehicleData(true);
      ApiService.VehicleDataController.VehicleDataGetByAccountID(
        (response: IAPIResponse) => {
          if (response.error === null) {
            let optionsForm = [{ ...{ vehicleID: "-1" } }, ...response.payload];
            setVehicleData(optionsForm);
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingVehicleData(false);
        }
      );
    }
  };

  // Load costs
  const loadVehicleStatisticsCost = () => {
    setLoadingListCosts(true);
    ApiService.StatisticsController.getStatisticsOwnershipCosts(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCostsList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingListCosts(false);
      }
    );
  };

  // Render charts data
  const processCurrentData = () => {
    // CURRENT YEAR
    setToolbarHidden({});
    const currentYearList = costsList.filter((x: IVehicleTotalCostStatistics) => 
      x.startDT.indexOf(currentYear.toString()) > -1
    );
    let sumCostCurrentYearList = 0;
    let sumVehiclesCurrentYear = 0;
    let arrBarCurrentYear = [];

    let totalTicketCurrentYear = 0;
    let totalSupplyCurrentYear = 0;
    let totalVehicleServiceCurrentYear = 0;
    let totalPropertyContractCurrentYear = 0;
    let totalLongRentalContractCurrentYear = 0;
    let totalLeasingContractCurrentYear = 0;
    let totalFundingContractCurrentYear = 0;
    let totalVehicleContractCurrentYear = 0;
    let totalVehicleMaintenanceCurrentYear = 0;
    let totalVehicleDamageCurrentYear = 0;
    let totalAdditionalRoadTaxCurrentYear = 0;
    let totalRoadTaxCurrentYear = 0;
    let totalInsuranceCurrentYear = 0;
    let totalTelepassCurrentYear = 0;

    for (let i = 0; i < currentYearList.length; i++) {
      let singleRowTotalCost = currentYearList[i].totalCost.filter((x: IStatisticsTotalCost) => x.vehicleID === +state?.statisticsVehicleID || +state?.statisticsVehicleID === -1);
      sumVehiclesCurrentYear += singleRowTotalCost.length;
      let monthCurrentYear = 0;
      for (let j = 0; j < singleRowTotalCost.length; j++) {
        // Histogram
        sumCostCurrentYearList += singleRowTotalCost[j].totalCost;
        monthCurrentYear += singleRowTotalCost[j].totalCost;
        // Bars
        totalTicketCurrentYear += singleRowTotalCost[j].totalTicket;
        totalSupplyCurrentYear += singleRowTotalCost[j].totalSupply;
        totalVehicleServiceCurrentYear += singleRowTotalCost[j].totalVehicleService;
        totalPropertyContractCurrentYear += singleRowTotalCost[j].totalPropertyContract;
        totalLongRentalContractCurrentYear += singleRowTotalCost[j].totalLongRentalContract;
        totalLeasingContractCurrentYear += singleRowTotalCost[j].totalLeasingContract;
        totalFundingContractCurrentYear += singleRowTotalCost[j].totalFundingContract;
        totalVehicleContractCurrentYear += singleRowTotalCost[j].totalVehicleContract;
        totalVehicleMaintenanceCurrentYear += singleRowTotalCost[j].totalVehicleMaintenance;
        totalVehicleDamageCurrentYear += singleRowTotalCost[j].totalVehicleDamage;
        totalAdditionalRoadTaxCurrentYear += singleRowTotalCost[j].totalAdditionalRoadTax;
        totalRoadTaxCurrentYear += singleRowTotalCost[j].totalRoadTax;
        totalInsuranceCurrentYear += singleRowTotalCost[j].totalInsurance;
        totalTelepassCurrentYear += singleRowTotalCost[j].totalTelepass;
      }
      arrBarCurrentYear.push(monthCurrentYear);
    }

    setSumCurrentYear(sumCostCurrentYearList);
    setVehiclesCurrentYear(Math.round(sumVehiclesCurrentYear / currentYearList.length));
    setBarCurrentYear(arrBarCurrentYear);

    // LAST YEAR
    const lastYearList = costsList.filter((x: IVehicleTotalCostStatistics) => 
      x.startDT.indexOf(lastYear.toString()) > -1
    );
    let sumCostLastYearList = 0;
    let sumVehiclesLastYear = 0;
    let arrBarLastYear = [];

    let totalTicketLastYear = 0;
    let totalSupplyLastYear = 0;
    let totalVehicleServiceLastYear = 0;
    let totalPropertyContractLastYear = 0;
    let totalLongRentalContractLastYear = 0;
    let totalLeasingContractLastYear = 0;
    let totalFundingContractLastYear = 0;
    let totalVehicleContractLastYear = 0;
    let totalVehicleMaintenanceLastYear = 0;
    let totalVehicleDamageLastYear = 0;
    let totalAdditionalRoadTaxLastYear = 0;
    let totalRoadTaxLastYear = 0;
    let totalInsuranceLastYear = 0;
    let totalTelepassLastYear = 0;

    for (let i = 0; i < lastYearList.length; i++) {
      let singleRowTotalCost = lastYearList[i].totalCost.filter((x: IStatisticsTotalCost) => x.vehicleID === +state?.statisticsVehicleID || +state?.statisticsVehicleID === -1);
      sumVehiclesLastYear += singleRowTotalCost.length;
      let monthLastYear = 0;
      for (let j = 0; j < singleRowTotalCost.length; j++) {
        // Histogram
        sumCostLastYearList += singleRowTotalCost[j].totalCost;
        monthLastYear += singleRowTotalCost[j].totalCost;
        // Bars
        totalTicketLastYear += singleRowTotalCost[j].totalTicket;
        totalSupplyLastYear += singleRowTotalCost[j].totalSupply;
        totalVehicleServiceLastYear += singleRowTotalCost[j].totalVehicleService;
        totalPropertyContractLastYear += singleRowTotalCost[j].totalPropertyContract;
        totalLongRentalContractLastYear += singleRowTotalCost[j].totalLongRentalContract;
        totalLeasingContractLastYear += singleRowTotalCost[j].totalLeasingContract;
        totalFundingContractLastYear += singleRowTotalCost[j].totalFundingContract;
        totalVehicleContractLastYear += singleRowTotalCost[j].totalVehicleContract;
        totalVehicleMaintenanceLastYear += singleRowTotalCost[j].totalVehicleMaintenance;
        totalVehicleDamageLastYear += singleRowTotalCost[j].totalVehicleDamage;
        totalAdditionalRoadTaxLastYear += singleRowTotalCost[j].totalAdditionalRoadTax;
        totalRoadTaxLastYear += singleRowTotalCost[j].totalRoadTax;
        totalInsuranceLastYear += singleRowTotalCost[j].totalInsurance;
        totalTelepassLastYear += singleRowTotalCost[j].totalTelepass;
      }
      arrBarLastYear.push(monthLastYear);
    }

    setSumLastYear(sumCostLastYearList);
    setVehiclesLastYear(Math.round(sumVehiclesLastYear / lastYearList.length));
    setBarLastYear(arrBarLastYear);

    // TWO LAST YEAR
    const lastTwoYearList = costsList.filter((x: IVehicleTotalCostStatistics) => 
      x.startDT.indexOf(twoLastYear.toString()) > -1
    );
    let sumCostTwoLastYearList = 0;
    let sumVehiclesTwoLastYear = 0;
    let arrBarTwoLastYear = [];

    let totalTicketTwoLastYear = 0;
    let totalSupplyTwoLastYear = 0;
    let totalVehicleServiceTwoLastYear = 0;
    let totalPropertyContractTwoLastYear = 0;
    let totalLongRentalContractTwoLastYear = 0;
    let totalLeasingContractTwoLastYear = 0;
    let totalFundingContractTwoLastYear = 0;
    let totalVehicleContractTwoLastYear = 0;
    let totalVehicleMaintenanceTwoLastYear = 0;
    let totalVehicleDamageTwoLastYear = 0;
    let totalAdditionalRoadTaxTwoLastYear = 0;
    let totalRoadTaxTwoLastYear = 0;
    let totalInsuranceTwoLastYear = 0;
    let totalTelepassTwoLastYear = 0;

    for (let i = 0; i < lastTwoYearList.length; i++) {
      let singleRowTotalCost = lastTwoYearList[i].totalCost.filter((x: IStatisticsTotalCost) => x.vehicleID === +state?.statisticsVehicleID || +state?.statisticsVehicleID === -1);

      sumVehiclesTwoLastYear += singleRowTotalCost.length;
      let monthTwoLastYear = 0;
      for (let j = 0; j < singleRowTotalCost.length; j++) {
        // Histogram
        sumCostTwoLastYearList += singleRowTotalCost[j].totalCost;
        monthTwoLastYear += singleRowTotalCost[j].totalCost;
        // Bars
        totalTicketTwoLastYear += singleRowTotalCost[j].totalTicket;
        totalSupplyTwoLastYear += singleRowTotalCost[j].totalSupply;
        totalVehicleServiceTwoLastYear += singleRowTotalCost[j].totalVehicleService;
        totalPropertyContractTwoLastYear += singleRowTotalCost[j].totalPropertyContract;
        totalLongRentalContractTwoLastYear += singleRowTotalCost[j].totalLongRentalContract;
        totalLeasingContractTwoLastYear += singleRowTotalCost[j].totalLeasingContract;
        totalFundingContractTwoLastYear += singleRowTotalCost[j].totalFundingContract;
        totalVehicleContractTwoLastYear += singleRowTotalCost[j].totalVehicleContract;
        totalVehicleMaintenanceTwoLastYear += singleRowTotalCost[j].totalVehicleMaintenance;
        totalVehicleDamageTwoLastYear += singleRowTotalCost[j].totalVehicleDamage;
        totalAdditionalRoadTaxTwoLastYear += singleRowTotalCost[j].totalAdditionalRoadTax;
        totalRoadTaxTwoLastYear += singleRowTotalCost[j].totalRoadTax;
        totalInsuranceTwoLastYear += singleRowTotalCost[j].totalInsurance;
        totalTelepassTwoLastYear += singleRowTotalCost[j].totalTelepass;
      }
      arrBarTwoLastYear.push(monthTwoLastYear);
    }

    setSumTwoLastYear(sumCostTwoLastYearList);
    setVehiclesTwoLastYear(Math.round(sumVehiclesTwoLastYear / lastYearList.length));
    setBarTwoLastYear(arrBarTwoLastYear);

    // Bars costs
    setTotalTicket([totalTicketCurrentYear, totalTicketLastYear, totalTicketTwoLastYear]);
    setTotalSupply([totalSupplyCurrentYear, totalSupplyLastYear, totalSupplyTwoLastYear]);
    setTotalVehicleService([
      totalVehicleServiceCurrentYear,
      totalVehicleServiceLastYear,
      totalVehicleServiceTwoLastYear,
    ]);
    setTotalVehicleServiceOriginal([
      totalVehicleServiceCurrentYear,
      totalVehicleServiceLastYear,
      totalVehicleServiceTwoLastYear,
    ]);
    setTotalPropertyContract([
      totalPropertyContractCurrentYear,
      totalPropertyContractLastYear,
      totalPropertyContractTwoLastYear,
    ]);
    setTotalPropertyContractOriginal([
      totalPropertyContractCurrentYear,
      totalPropertyContractLastYear,
      totalPropertyContractTwoLastYear,
    ]);
    
    setTotalLongRentalContract([
      totalLongRentalContractCurrentYear,
      totalLongRentalContractLastYear,
      totalLongRentalContractTwoLastYear,
    ]);
    setTotalLongRentalContractOriginal([
      totalLongRentalContractCurrentYear,
      totalLongRentalContractLastYear,
      totalLongRentalContractTwoLastYear,
    ]);
    setTotalLeasingContract([
      totalLeasingContractCurrentYear,
      totalLeasingContractLastYear,
      totalLeasingContractTwoLastYear,
    ]);
    setTotalLeasingContractOriginal(
      [
        totalLeasingContractCurrentYear,
        totalLeasingContractLastYear,
        totalLeasingContractTwoLastYear,
      ]
    )
    setTotalFundingContract([
      totalFundingContractCurrentYear,
      totalFundingContractLastYear,
      totalFundingContractTwoLastYear,
    ]);
    setTotalFundingContractOriginal(
      [
        totalFundingContractCurrentYear,
        totalFundingContractLastYear,
        totalFundingContractTwoLastYear,
      ]
    )
    setTotalVehicleContract([
      totalVehicleContractCurrentYear,
      totalVehicleContractLastYear,
      totalVehicleContractTwoLastYear,
    ]);
    setTotalVehicleMaintenance([
      totalVehicleMaintenanceCurrentYear,
      totalVehicleMaintenanceLastYear,
      totalVehicleMaintenanceTwoLastYear,
    ]);
    setTotalVehicleDamage([
      totalVehicleDamageCurrentYear,
      totalVehicleDamageLastYear,
      totalVehicleDamageTwoLastYear,
    ]);
    setTotalAdditionalRoadTax([
      totalAdditionalRoadTaxCurrentYear,
      totalAdditionalRoadTaxLastYear,
      totalAdditionalRoadTaxTwoLastYear,
    ]);
    setTotalAdditionalRoadTaxOriginal([
      totalAdditionalRoadTaxCurrentYear,
      totalAdditionalRoadTaxLastYear,
      totalAdditionalRoadTaxTwoLastYear,
    ]);
    setTotalRoadTax([
      totalRoadTaxCurrentYear,
      totalRoadTaxLastYear,
      totalRoadTaxTwoLastYear,
    ]);
    setTotalRoadTaxOriginal([
      totalRoadTaxCurrentYear,
      totalRoadTaxLastYear,
      totalRoadTaxTwoLastYear,
    ]);
    setTotalInsurance([
      totalInsuranceCurrentYear,
      totalInsuranceLastYear,
      totalInsuranceTwoLastYear,
    ]);
    setTotalInsuranceOriginal([
      totalInsuranceCurrentYear,
      totalInsuranceLastYear,
      totalInsuranceTwoLastYear,
    ]);

    setTotalTelepass([
      totalTelepassCurrentYear,
      totalTelepassLastYear,
      totalTelepassTwoLastYear,
    ]);
    setTotalTelepassOriginal([  
      totalTelepassCurrentYear,
      totalTelepassLastYear,
      totalTelepassTwoLastYear,
    ]);

    setOtherCostTotal(
      [
        (totalVehicleServiceCurrentYear + totalRoadTaxCurrentYear + totalAdditionalRoadTaxCurrentYear + totalInsuranceCurrentYear + totalTelepassCurrentYear),
        (totalVehicleServiceLastYear + totalRoadTaxLastYear + totalAdditionalRoadTaxLastYear + totalInsuranceLastYear + totalTelepassLastYear),
        (totalVehicleServiceTwoLastYear + totalRoadTaxTwoLastYear + totalAdditionalRoadTaxTwoLastYear + totalInsuranceTwoLastYear + totalTelepassTwoLastYear),
      ],
    );
  };

  /* USE EFFECT HOOK */
  // First render
  useEffect(() => {
    loadVehicleData();
    loadVehicleStatisticsCost();
  }, []);

  // Render charts data
  useEffect(() => {
    if (vehicleData.length > 0 && costsList.length > 0) {
      processCurrentData();
    }
  }, [state.statisticsVehicleID, vehicleData, costsList]);

  /* HISTOGRAM */
  // Settings and data for histogram
  const optionsChart = {
    responsive: true,
    borderRadius: 2,
    barPercentage: 0.8,
    categoryPercentage: 0.5,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        title: {
          font: {
            size: 14,
          },
          display: true,
          text: i18next.t("navigation:statisticsLabelCosts"),
        },
      },
    },
  };

  const avgYearsLineFunc = () => {
    let avgYears: any[] = [];

    for (let i = 0; i < 12; i++) {
      let barCurrentYearValue = barCurrentYear[i] || 0;
      let barLastYearValue = barLastYear[i] || 0;
      let barTwoLastYearValue = barTwoLastYear[i] || 0;

      avgYears.push(
        Math.round((barCurrentYearValue + barLastYearValue + barTwoLastYearValue) / 3)
      );
    }

    return avgYears;
  };
  const avgYearsLine: any[] = avgYearsLineFunc();

  const dataChart = {
    labels: [
      i18next.t("navigation:statistics_January"),
      i18next.t("navigation:statistics_February"),
      i18next.t("navigation:statistics_March"),
      i18next.t("navigation:statistics_April"),
      i18next.t("navigation:statistics_May"),
      i18next.t("navigation:statistics_June"),
      i18next.t("navigation:statistics_July"),
      i18next.t("navigation:statistics_August"),
      i18next.t("navigation:statistics_September"),
      i18next.t("navigation:statistics_October"),
      i18next.t("navigation:statistics_November"),
      i18next.t("navigation:statistics_December"),
    ],
    datasets: [
      {
        type: "line" as const,
        label: i18next.t("navigation:statisticsTrend"),
        data: avgYearsLine.map((row) => row),
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false,
      },
      {
        type: "bar" as const,
        label: String(currentYear),
        data: barCurrentYear.map((row) => row),
        borderColor: "rgba(53, 162, 235, 1)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        type: "bar" as const,
        label: String(lastYear),
        data: barLastYear.map((row) => row),
        borderColor: "rgba(142, 179, 37, 1)",
        backgroundColor: "rgba(142, 179, 37, 0.5)",
      },
      {
        type: "bar" as const,
        label: String(twoLastYear),
        data: barTwoLastYear.map((row) => row),
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
    ],
  };

  /* BARS */
  // Settings and data for Bars
  const optionsBar = {
    indexAxis: "y" as const,
    responsive: true,
    borderRadius: 2,
    barPercentage: 0.8,
    categoryPercentage: 0.5,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: true, 
        onClick: (e: any, legendItem: any, legend: any) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const meta = ci.getDatasetMeta(index);
          meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          ci.update();
          if(e.chart.config._config.options.idBar){
            let toolbarHidden_ = {...toolbarHidden};
            toolbarHidden_[e.chart.config._config.options.idBar] = {
              ...toolbarHidden[e.chart.config._config.options.idBar] || {},
              [legendItem.text]: meta.hidden ? true : false,
            };
            setToolbarHidden(toolbarHidden_);
          }
        }
      },
      title: {
        display: false,
      },
    },
  };

  const dataLables = [
    totalTicket,
    totalSupply,
    totalVehicleService,
    totalPropertyContract,
    totalLongRentalContract,
    totalLeasingContract,
    totalFundingContract,
    totalVehicleContract,
    totalVehicleMaintenance,
    totalVehicleDamage,
    totalRoadTax,
    totalAdditionalRoadTax,
    totalInsurance,
    totalTelepass,
    otherCostTotal
  ];

  let dataTotalBar = dataLables.map((x: any) => {
    return {
      labels: [i18next.t("navigation:statisticsLabelCosts")],
      datasets: [
        {
          type: "bar" as const,
          label: String(currentYear),
          data: [x[0] || 0],
          borderColor: "rgba(53, 162, 235, 1)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          type: "bar" as const,
          label: String(lastYear),
          data: [x[1] || 0],
          borderColor: "rgba(142, 179, 37, 1)",
          backgroundColor: "rgba(142, 179, 37, 0.5)",
        },
        {
          type: "bar" as const,
          label: String(twoLastYear),
          data: [x[2] || 0],
          borderColor: "rgba(255, 159, 64, 1)",
          backgroundColor: "rgba(255, 159, 64, 0.5)",
        },
      ],
    };
  });

  useEffect(() => {
    let keys = Object.keys(toolbarHidden);
    if (keys.length > 0){
      if(keys.includes("statistics_Property")) {
        let totalPropertyContract_: any[] = [];
        let totalPropertyContractOriginal_: any[] = totalPropertyContractOriginal;
        if(!toolbarHidden['statistics_Property'][currentYear]){
          totalPropertyContract_.push(totalPropertyContractOriginal_[0]);
        }else{
          totalPropertyContract_.push(0);
        }
        if(!toolbarHidden['statistics_Property'][lastYear]){
          totalPropertyContract_.push(totalPropertyContractOriginal_[1]);
        }else{
          totalPropertyContract_.push(0);
        }
        if(!toolbarHidden['statistics_Property'][twoLastYear]){
          totalPropertyContract_.push(totalPropertyContractOriginal_[2]);
        }else{
          totalPropertyContract_.push(0);
        }
        setTotalPropertyContract(totalPropertyContract_);
      } 
      if(keys.includes("statistics_LongRental")){
        let totalLongRentalContract_: any[] = [];
        let totalLongRentalContractOriginal_: any[] = totalLongRentalContractOriginal;
        if(!toolbarHidden['statistics_LongRental'][currentYear]){
          totalLongRentalContract_.push(totalLongRentalContractOriginal_[0]);
        }else{
          totalLongRentalContract_.push(0);
        }
        if(!toolbarHidden['statistics_LongRental'][lastYear]){
          totalLongRentalContract_.push(totalLongRentalContractOriginal_[1]);
        }else{
          totalLongRentalContract_.push(0);
        }
        if(!toolbarHidden['statistics_LongRental'][twoLastYear]){
          totalLongRentalContract_.push(totalLongRentalContractOriginal_[2]);
        }else{
          totalLongRentalContract_.push(0);
        }
        setTotalLongRentalContract(totalLongRentalContract_);
      }
      if(keys.includes("statistics_Leasing")){
        let totalLeasingContract_: any[] = [];
        let totalLeasingContractOriginal_: any[] = totalLeasingContractOriginal;
        if(!toolbarHidden['statistics_Leasing'][currentYear]){
          totalLeasingContract_.push(totalLeasingContractOriginal_[0]);
        }else{
          totalLeasingContract_.push(0);
        }
        if(!toolbarHidden['statistics_Leasing'][lastYear]){
          totalLeasingContract_.push(totalLeasingContractOriginal_[1]);
        }else{
          totalLeasingContract_.push(0);
        }
        if(!toolbarHidden['statistics_Leasing'][twoLastYear]){
          totalLeasingContract_.push(totalLeasingContractOriginal_[2]);
        }else{
          totalLeasingContract_.push(0);
        }
        setTotalLeasingContract(totalLeasingContract_);
      }
      if(keys.includes("statistics_Financing")){
        let totalFundingContract_: any[] = [];
        let totalFundingContractOriginal_: any[] = totalFundingContractOriginal;
        if(!toolbarHidden['statistics_Financing'][currentYear]){
          totalFundingContract_.push(totalFundingContractOriginal_[0]);
        }else{
          totalFundingContract_.push(0);
        }
        if(!toolbarHidden['statistics_Financing'][lastYear]){
          totalFundingContract_.push(totalFundingContractOriginal_[1]);
        }else{
          totalFundingContract_.push(0);
        }
        if(!toolbarHidden['statistics_Financing'][twoLastYear]){
          totalFundingContract_.push(totalFundingContractOriginal_[2]);
        }else{
          totalFundingContract_.push(0);
        }
        setTotalLeasingContract(totalFundingContract_);
      }
      if(keys.includes("statistics_ServiceCost")) {
        let totalVehicleSerive_: any[] = [];
        let totalVehicleServiceOriginal_: any[] = [...totalVehicleServiceOriginal];
        if(!toolbarHidden['statistics_ServiceCost'][currentYear]){
          totalVehicleSerive_.push(totalVehicleServiceOriginal_[0]);
        }else{
          totalVehicleSerive_.push(0);
        }
        if(!toolbarHidden['statistics_ServiceCost'][lastYear]){
          totalVehicleSerive_.push(totalVehicleServiceOriginal_[1]);
        }else{
          totalVehicleSerive_.push(0);
        }
        if(!toolbarHidden['statistics_ServiceCost'][twoLastYear]){
          totalVehicleSerive_.push(totalVehicleServiceOriginal_[2]);
        }else{
          totalVehicleSerive_.push(0);
        }
        setTotalVehicleService(totalVehicleSerive_);
      }
      if(keys.includes("statistics_RoadTax")) {
        let totalRoadTax_: any[] = [];
        let totalRoadTaxOriginal_: any[] = [...totalRoadTaxOriginal];
        if(!toolbarHidden['statistics_RoadTax'][currentYear]){
          totalRoadTax_.push(totalRoadTaxOriginal_[0]);
        }else{
          totalRoadTax_.push(0);
        }
        if(!toolbarHidden['statistics_RoadTax'][lastYear]){
          totalRoadTax_.push(totalRoadTaxOriginal_[1]);
        }else{
          totalRoadTax_.push(0);
        }
        if(!toolbarHidden['statistics_RoadTax'][twoLastYear]){
          totalRoadTax_.push(totalRoadTaxOriginal_[2]);
        }else{
          totalRoadTax_.push(0);
        }
        setTotalRoadTax(totalRoadTax_);
      }
      if(keys.includes("statistics_AdditionalRoadTax")) {
        let totalAdditionalRoadTax_: any[] = [];
        let totalAdditionalRoadTaxOriginal_: any[] = [...totalAdditionalRoadTaxOriginal];
        if(!toolbarHidden['statistics_AdditionalRoadTax'][currentYear]){
          totalAdditionalRoadTax_.push(totalAdditionalRoadTaxOriginal_[0]);
        }else{
          totalAdditionalRoadTax_.push(0);
        }
        if(!toolbarHidden['statistics_AdditionalRoadTax'][lastYear]){
          totalAdditionalRoadTax_.push(totalAdditionalRoadTaxOriginal_[1]);
        }else{
          totalAdditionalRoadTax_.push(0);
        }
        if(!toolbarHidden['statistics_AdditionalRoadTax'][twoLastYear]){
          totalAdditionalRoadTax_.push(totalAdditionalRoadTaxOriginal_[2]);
        }else{
          totalAdditionalRoadTax_.push(0);
        }
        setTotalAdditionalRoadTax(totalAdditionalRoadTax_);
      }
      if(keys.includes("statistics_Insurance")) {
        let totalInsurance_: any[] = [];
        let totalInsuranceOriginal_: any[] = [...totalInsuranceOriginal];
        if(!toolbarHidden['statistics_Insurance'][currentYear]){
          totalInsurance_.push(totalInsuranceOriginal_[0]);
        }else{
          totalInsurance_.push(0);
        }
        if(!toolbarHidden['statistics_Insurance'][lastYear]){
          totalInsurance_.push(totalInsuranceOriginal_[1]);
        }else{
          totalInsurance_.push(0);
        }
        if(!toolbarHidden['statistics_Insurance'][twoLastYear]){
          totalInsurance_.push(totalInsuranceOriginal_[2]);
        }else{
          totalInsurance_.push(0);
        }
        setTotalInsurance(totalInsurance_);
      }
      if(keys.includes("statistics_Telepass")) {
        let totalTelepass_: any[] = [];
        let totalTelepassOriginal_: any[] = [...totalTelepassOriginal];
        if(!toolbarHidden['statistics_Telepass'][currentYear]){
          totalTelepass_.push(totalTelepassOriginal_[0]);
        }else{
          totalTelepass_.push(0);
        }
        if(!toolbarHidden['statistics_Telepass'][lastYear]){
          totalTelepass_.push(totalTelepassOriginal_[1]);
        }else{
          totalTelepass_.push(0);
        }
        if(!toolbarHidden['statistics_Telepass'][twoLastYear]){
          totalTelepass_.push(totalTelepassOriginal_[2]);
        }else{
          totalTelepass_.push(0);
        }
        setTotalTelepass(totalTelepass_);
      } 
    }
  }, [toolbarHidden]);

  /* RETURN */
  return (
    <Box className="CO2Emission__minHeight">
      <MultiForm
        suppressLayout
        classNameForm="multi-form-flex"
        suppressSubmit={true}
        formId={"FormCostsDatePickerRange"}
        formTitle={"FormCostsDatePickerRange"}
        inputs={[
          {
            className: "modal-width-extra-small",
            type: "select",
            sortByOptions: 1,
            name: "statisticsVehicleID",
            defaultValue: state?.statisticsVehicleID,
            currentValue: state?.statisticsVehicleID,
            label: i18next.t("form:plate") ?? "",
            required: true,
            options: vehicleData.map((x: IVehicleDataByAccountID) => {
              return {
                key: x.vehicleID.toString(),
                text:
                  x.vehicleID.toString() === "-1"
                    ? i18next.t("navigation:viewAll")
                  : formatPlate(x.vehicleLicensePlate)
              };
            }),
          }
        ]}
        onChange={async (data: any) => {
          setState(data);
        }}
      />
      <Chart type="bar" data={dataChart} height={90} options={optionsChart} />

      <Divider className="margin-vertical-small" />

      <div className="margin-bottom-medium padding-small">
        <h2 className="text-color-hint">{i18next.t("navigation:statisticsDetails")}</h2>

        <div className="ownershipCosts__box">
          <div className="ownershipCosts__card">
            <span className="text-color-hint font-size-extra-huge text-bold">
              {sumCurrentYear.toLocaleString("it-IT") + " €"}
            </span>
            <span className="text-bold font-size-small">
              {currentYear} | {i18next.t("navigation:statisticsVehiclesNumber")}:{" "}
              {vehiclesCurrentYear || 0}
            </span>
          </div>
          <div className="ownershipCosts__card ownershipCosts__border">
            <span className="text-color-hint font-size-extra-huge text-bold">
              {sumLastYear.toLocaleString("it-IT") + " €"}
            </span>
            <span className="text-bold font-size-small">
              {lastYear} | {i18next.t("navigation:statisticsVehiclesNumber")}:{" "}
              {vehiclesLastYear || 0}
            </span>
          </div>
          <div className="ownershipCosts__card">
            <span className="text-color-hint font-size-extra-huge text-bold">
              {sumTwoLastYear.toLocaleString("it-IT") + " €"}
            </span>
            <span className="text-bold font-size-small">
              {twoLastYear} | {i18next.t("navigation:statisticsVehiclesNumber")}:{" "}
              {vehiclesTwoLastYear || 0}
            </span>
          </div>
        </div>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_Fuel")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar id="statistics_Fuel" height={30} options={optionsBar} data={dataTotalBar[1]} />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_Contracts")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar id="statistics_Contracts" height={30} options={optionsBar} data={dataTotalBar[7]} />

            <div className="margin-vertical-medium">
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_Property")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalPropertyContract
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar id="statistics_Property" height={30} options={{...optionsBar, ...{idBar: "statistics_Property"}}} data={dataTotalBar[3]} />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>
                      {i18next.t("navigation:statistics_LongRental")}
                    </Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalLongRentalContract
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar id="statistics_LongRental" height={30} options={{...optionsBar, ...{idBar: "statistics_LongRental"}}} data={dataTotalBar[4]} />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_Leasing")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalLeasingContract
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar id="statistics_Leasing" height={30} options={{...optionsBar, ...{idBar: "statistics_Leasing"}}} data={dataTotalBar[5]} />
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>
                      {i18next.t("navigation:statistics_Financing")}
                    </Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalFundingContract
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar id="statistics_Financing" height={30} options={{...optionsBar, ...{idBar: "statistics_Financing"}}} data={dataTotalBar[6]} />
                </AccordionDetails>
              </Accordion>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_Maintenance")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar height={30} options={optionsBar} data={dataTotalBar[8]} />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_Damage")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar height={30} options={optionsBar} data={dataTotalBar[9]} />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_Tickets")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar height={30} options={optionsBar} data={dataTotalBar[0]} />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{i18next.t("navigation:statistics_OthersCosts")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bar height={30} options={optionsBar} data={dataTotalBar[14]} />
            <div className="margin-vertical-medium">
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_ServiceCost")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalVehicleService
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar height={30} options={{...optionsBar, ...{idBar: "statistics_ServiceCost"}}} data={dataTotalBar[2]} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_RoadTax")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalRoadTax
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar height={30} options={{...optionsBar, ...{idBar: "statistics_RoadTax"}}} data={dataTotalBar[10]} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_AdditionalRoadTax")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalAdditionalRoadTax
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar height={30} options={{...optionsBar, ...{idBar: "statistics_AdditionalRoadTax"}}} data={dataTotalBar[11]} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_Insurance")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalInsurance
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar height={30} options={{...optionsBar, ...{idBar: "statistics_Insurance"}}} data={dataTotalBar[12]} />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="ownershipCosts__accordion--summary">
                    <Typography>{i18next.t("navigation:statistics_Telepass")}</Typography>
                    <Typography>
                      <span className="text-color-hint text-bold">
                        {i18next.t("navigation:statistics_Total")}:{" "}
                      </span>
                      <span className="text-grey">
                        {totalTelepass
                          .reduce((partialSum, a) => partialSum + a, 0)
                          .toLocaleString("it-IT") + " €"}
                      </span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Bar height={30} options={{...optionsBar, ...{idBar: "statistics_Telepass"}}} data={dataTotalBar[13]} />
                </AccordionDetails>
              </Accordion>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <LoaderBackdrop loading={loadingListCosts || loadingVehicleData} />
    </Box>
  );
};

export default OwnershipCosts__StatisticsPage_Data;
