import i18next from "i18next";
import HeaderPageDashboard from "../../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../../Components/MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../../Components/SmartModal/SmartModal";
import VehicleDamageForm from "../../../../../../Components/VehicleDamageForm/VehicleDamageForm";
import { IDriverGet } from "../../../../../../Models/IDriver";
import { IVehicleDataGet } from "../../../../../../Models/IVehicles";
import { formatPlate } from "../../../VehiclesPage/VehiclesPage";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICompanyGet } from "../../../../../../Models/ICompany";
import { IUser } from "../../../../../../Models/IUser";
import { GlobalState } from "../../../../../../Reducers/RootReducer";
import ApiService from "../../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../../Services/Internal/AjaxService";
import { ToastMessage } from "../../../../../../Utils/Toastify";
import AddIcon from "@mui/icons-material/Add";
import "./Fleet__AdditionalServicesPage__IncomingVehicle.scss";
import { Button } from "@mui/material";
import FormAssignedDriver from "../../../../../../Components/StepAssignedDrivers/FormAssignedDriver";
import {
  IVehicleReservation,
  IVehicleReservationAvailableVehicle,
} from "../../../../../../Models/IVehicleReservation";
import {
  IVehicleReservationIncomingInsert,
  IVehicleReservationIncomingUpdate,
} from "../../../../../../Models/IVehicleReservationIncoming";
import { getDatesBetween } from "../../../../../../Utils/Decoder";
import { useNavigate, useSearchParams } from "react-router-dom";
import FleetReservationDeliveryLetter from "../../../../../../Components/FleetReservationDeliveryLetter/FleetReservationDeliveryLetter";
import { displayUTC0_ISODate } from "../../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import AppRoutes from "../../../../../../Costants/AppRoutes";
import { VehiclesActions } from "../../../../../../Reducers/Vehicle/VehicleAction";
import DeleteIcon from "@mui/icons-material/Delete"; 

const Fleet__AdditionalServicesPage__IncomingVehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const outgoingVehicleIDQuery: string | null = params.get("outgoingVehicleID");
  const incomingVehicleIDQuery: string | null = params.get("incomingVehicleID");
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const vehicles: IVehicleDataGet[] = useSelector(
    (state: GlobalState) => state.vehicles.vehiclesForCompany
  );
  const loggedCompany: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [loadingDriverList, setLoadingDriverList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [driverList, setDriverList] = useState<IDriverGet[]>([]);
  const [state, setState] = useState<any>({});
  const [vehicleReservationID, setVehicleReservationID] = useState<number>(-1);
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingReservation, setLoadingReservation] = useState<boolean>(false);
  const [reservationList, setReservationList] = useState<IVehicleReservation[]>([]);
  const [loadingIncoming, setLoadingIncoming] = useState<boolean>(false);
  const [loadingOutgoing, setLoadingOutgoing] = useState<boolean>(false);
  const [forceLoadDamageVehicle, setForceLoadDamageVehicle] = useState<boolean>(false);
  const [printLetterDelivery, setPrintLetterDelivery] = useState<boolean>(false);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [disabledReservations, setDisabledReservations] = useState<boolean>(false);
  const [minKm, setMinKm] = useState<number>(0);
  const [loadingAvailableVehicles, setLoadingAvailableVehicles] =
    useState<boolean>(false);
  const [availableVehicles, setAvailableVehicles] = useState<
    IVehicleReservationAvailableVehicle[]
  >([]);
  const [disablePlateOption, setDisablePlateOption] = useState<boolean>(true);
  const [currentVehicle, setCurrentVehicle] = useState<IVehicleDataGet[]>([]);
  const [disabledDates, setDisabledDates] = useState<string[]>([]);
  const [incomingVehicleID, setIncomingVehicleID] = useState<number>(-1);
  const [outgoingVehicleID, setOutgoingVehicleID] = useState<number>(-1);

  const loadVehicles = (vehicleID: number | null) => {
    if (loggedCompany) {
      setLoading(true);
      ApiService.VehicleDataController.VehicleDataGet(
        loggedCompany.companyID,
        vehicleID,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload && response.payload.length > 0) {
              let vehicles = response.payload;
              dispatch(VehiclesActions.setVehiclesForCompany(vehicles));

              setPrintLetterDelivery(true);
              setCurrentVehicle(vehicles);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoading(false);
        }
      );
    }
  };

  const loadingDrivers = () => {
    if (loggedUser) {
      setLoadingDriverList(true);
      ApiService.DriverController.DriverGet(null, null, (response: IAPIResponse) => {
        if (response.error === null) {
          setDriverList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingDriverList(false);
      });
    }
  };

  const loadReservations = () => {
    if (loggedCompany) {
      setLoadingReservation(true);
      ApiService.VehicleReservationController.VehicleReservationGet(
        loggedCompany.companyID,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setReservationList(
              response.payload.filter(
                (x: IVehicleReservation) => x.checkOutIncomingDT && x.checkOutOutgoingDT
              )
            );
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingReservation(false);
        }
      );
    }
  };

  const loadIncoming = (
    incomingVehicleID?: number | null,
    vehicleReservationID?: number | null
  ) => {
    if (loggedCompany) {
      setLoadingIncoming(true);
      ApiService.VehicleReservationIncomingController.VehicleReservationIncomingGet(
        null,
        vehicleReservationID,
        null,
        incomingVehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload && response.payload.length > 0) {
              loadVehicles(response.payload[0].vehicleID);
              setState(response.payload[0]);
              setPrintLetterDelivery(true);
              setVehicleReservationID(response.payload[0].vehicleReservationID);
              if (incomingVehicleID) {
                setDisabledReservations(true);
              }
              setMinKm(response.payload[0].incomingKM);
              doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingIncoming(false);
        }
      );
    }
  };

  const loadOutgoing = (
    outgoingVehicleID?: number | null,
    vehicleReservationID?: number | null
  ) => {
    if (loggedCompany) {
      setLoadingOutgoing(true);
      ApiService.VehicleReservationOutgoingController.VehicleReservationOutgoingGet(
        null,
        vehicleReservationID,
        null,
        outgoingVehicleID,
        (response: IAPIResponse) => {
          if (response.error === null) {
            if (response.payload && response.payload.length > 0) {
              loadVehicles(response.payload[0].vehicleID);
              setState(response.payload[0]);
              setPrintLetterDelivery(true);
              setVehicleReservationID(response.payload[0].vehicleReservationID);
              if (outgoingVehicleID) {
                setDisabledReservations(true);
              }
              setMinKm(response.payload[0].incomingKM);
              doRefreshForm();
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingOutgoing(false);
        }
      );
    }
  };

  const loadAvailableVehicles = () => {
    if (loggedCompany) {
      setLoadingAvailableVehicles(true);
      ApiService.VehicleReservationController.VehicleReservationGetAvailableVehicle(
        null,
        null,
        loggedCompany.companyID,
        null,
        null,
        null,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setAvailableVehicles(response.payload);
            if (response.payload && response.payload.length > 0) {
              setDisablePlateOption(false);
            }
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingAvailableVehicles(false);
        }
      );
    }
  };

  const insertIncoming = (data: IVehicleReservationIncomingInsert) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleReservationIncomingController.VehicleReservationIncomingInsert(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationIncomingSuccessfullAdded"),
              "success"
            );
            setTimeout(() => {
              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET
              );
            }, 100);
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const updateIncoming = (data: IVehicleReservationIncomingUpdate) => {
    if (loggedUser) {
      setProcessing(true);
      ApiService.VehicleReservationIncomingController.VehicleReservationIncomingUpdate(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              i18next.t("message:vehicleReservationIncomingSuccessfullUpdated"),
              "success"
            );
            setTimeout(() => {
              navigate(
                AppRoutes.INTERNAL_DASHBOARD +
                  AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET
              );
            }, 100);
          } else {
            if (response.payload.status === 400) {
              ToastMessage(i18next.t("error:" + response.payload.detail), "error");
            } else {
              ToastMessage(response.error, "error");
            }
          }
          setProcessing(false);
        }
      );
    }
  };

  const vehicleReservationOutcomingDelete = (outgoingVehicleID: number) => {
    ApiService.VehicleReservationOutgoingController.VehicleReservationOutcomingDelete(
      outgoingVehicleID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            i18next.t("message:vehicleReservationOutgoingSuccessfullDeleted"),
            "success"
          );
          setTimeout(() => {
            navigate(AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___ADDITIONAL_SERVICE_ROUTE__FLEET)
          }, 100);
        } else {
          ToastMessage(response.error, "error");
        }
      }
    );
  }

  const doRefreshForm = () => {
    setRefreshForm(true);
    setTimeout(() => {
      setRefreshForm(false);
    }, 50);
  };

  useEffect(() => {
    loadingDrivers();
    loadAvailableVehicles();
    loadReservations();
    loadVehicles(null);

    if (outgoingVehicleIDQuery || incomingVehicleIDQuery) {
      if (outgoingVehicleIDQuery) {
        loadOutgoing(+outgoingVehicleIDQuery);
      }

      if (incomingVehicleIDQuery) {
        loadIncoming(+incomingVehicleIDQuery);
      }
    }

    return () => {
      navigate(window.document.location.pathname);
    };
  }, []);

  useEffect(() => {
    if (!disabledReservations && vehicleReservationID && vehicleReservationID > 0) {
      let reservationList_ = [...reservationList];
      setState(
        reservationList_.filter(
          (x: IVehicleReservation) => x.vehicleReservationID === vehicleReservationID
        )[0]
      );
      doRefreshForm();
    }
  }, [vehicleReservationID, reservationList]);

  useEffect(() => {
    if (reservationList) {
      setDisabledDates([]);
      let currentReservationVehicle = reservationList.filter(
        (x: IVehicleReservation) => x.vehicleID === state?.vehicleID
      );
      for (let i = 0; i < currentReservationVehicle.length; i++) {
        let newDisabledDates: string[] = [];
        let skipCheckDates: boolean = false;

        if (
          outgoingVehicleIDQuery &&
          currentReservationVehicle[i].outgoingVehicleID === +outgoingVehicleIDQuery
        ) {
          skipCheckDates = true;
        }
        if (
          incomingVehicleIDQuery &&
          currentReservationVehicle[i].incomingVehicleID === +incomingVehicleIDQuery
        ) {
          skipCheckDates = true;
        }

        if (!skipCheckDates) {
          if (
            currentReservationVehicle[i].reservationDTTo &&
            !currentReservationVehicle[i].checkOutIncomingDT &&
            !currentReservationVehicle[i].checkInIncomingDT
          ) {
            newDisabledDates = getDatesBetween(
              new Date(currentReservationVehicle[i].reservationDTFrom),
              new Date(currentReservationVehicle[i].reservationDTTo),
              true
            );
          }

          if (
            currentReservationVehicle[i].reservationDTTo &&
            currentReservationVehicle[i].checkOutIncomingDT &&
            !currentReservationVehicle[i].checkInIncomingDT
          ) {
            newDisabledDates = getDatesBetween(
              new Date(currentReservationVehicle[i].checkOutOutgoingDT),
              new Date(currentReservationVehicle[i].checkOutIncomingDT),
              true
            );
          }

          if (
            currentReservationVehicle[i].reservationDTTo &&
            currentReservationVehicle[i].checkOutIncomingDT &&
            currentReservationVehicle[i].checkInIncomingDT
          ) {
            newDisabledDates = getDatesBetween(
              new Date(currentReservationVehicle[i].checkInOutgoingDT),
              new Date(currentReservationVehicle[i].checkInIncomingDT),
              true
            );
          }

          setDisabledDates([...disabledDates, ...newDisabledDates]);
        }
      }
    }
  }, [state?.vehicleID, reservationList]);

  useEffect(() => {
    if (incomingVehicleID > -1 && vehicleReservationID > 0) {
      loadIncoming(null, vehicleReservationID);
    }
  }, [incomingVehicleID]);

  useEffect(() => {
    if (outgoingVehicleID > -1 && vehicleReservationID > 0) {
      loadOutgoing(null, vehicleReservationID);
    }
  }, [outgoingVehicleID]);

  useEffect(() => {
    setForceLoadDamageVehicle(false);
  }, [state?.vehicleID]);

  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_add_services_fleet_incoming_vehicle_title")}
      />
      <div className="dashboardForm__box">
        <b>{i18next.t("navigation:fleet_reservation_title_preselected")}</b>
        <div className="fleet-incoming-wrapper">
          {!refreshForm && (
            <MultiForm
              inputs={[
                {
                  width: 100,
                  type: "select",
                  name: "vehicleReservationID",
                  label: i18next.t("form:reservation") as string,
                  disabled: disabledReservations,
                  defaultValue: vehicleReservationID,
                  options: reservationList.map((x: IVehicleReservation) => {
                    const plateNumber = vehicles?.find(
                      (v: IVehicleDataGet) => v.vehicleID === x.vehicleID
                    )?.vehicleLicensePlate;

                    const driverName = driverList?.find(
                      (d: IDriverGet) => d.driverInfo.driverID === x.firstDriverID
                    )?.driverInfo.driverName;

                    const driverSurname = driverList?.find(
                      (d: IDriverGet) => d.driverInfo.driverID === x.firstDriverID
                    )?.driverInfo.driverSurname;

                    const driverNameSurname =
                      driverName + (driverSurname ? driverSurname : "");

                    const textDescription: string =
                      (plateNumber !== undefined ? formatPlate(plateNumber) : "") +
                      " - " +
                      driverNameSurname +
                      " - da " +
                      displayUTC0_ISODate(
                        x.checkInOutgoingDT ? x.checkInOutgoingDT : x.checkOutOutgoingDT
                      ) +
                      " a " +
                      displayUTC0_ISODate(
                        x.checkInIncomingDT ? x.checkInIncomingDT : x.checkOutIncomingDT
                      );

                    return {
                      key: x.vehicleReservationID,
                      text: textDescription,
                    };
                  }),
                },
              ]}
              suppressLayout
              onChange={(data: any) => {
                let currentRersevation: IVehicleReservation[] = reservationList.filter(
                  (x: IVehicleReservation) =>
                    x.vehicleReservationID === data.vehicleReservationID
                );
                let newQueryParameters: URLSearchParams = new URLSearchParams();
                if (
                  !outgoingVehicleIDQuery &&
                  !incomingVehicleIDQuery &&
                  currentRersevation &&
                  currentRersevation.length > 0
                ) {
                  if (currentRersevation[0].incomingVehicleID) {
                    setIncomingVehicleID(currentRersevation[0].incomingVehicleID);
                  } else if (currentRersevation[0].outgoingVehicleID) {
                    setOutgoingVehicleID(currentRersevation[0].outgoingVehicleID);
                  }
                } else {
                  if (!disabledReservations && data.vehicleReservationID > 0) {
                    newQueryParameters.set(
                      "vehicleReservationID",
                      data.vehicleReservationID
                    );
                    setSearchParams(newQueryParameters);
                  }
                }
                if (!disabledReservations && data.vehicleReservationID > 0) {
                  setVehicleReservationID(data.vehicleReservationID);
                }
              }}
            />
          )}

          <hr className="margin-bottom-medium" />

          <span>
            <strong>{i18next.t("navigation:fleet_incoming_title_data")}</strong>
          </span>

          {!refreshForm && (
            <MultiForm
              formId="fleet-incoming-vehicle"
              inputs={[
                {
                  type: "select",
                  name: "firstDriverID",
                  width: 33.33,
                  label: i18next.t("form:firstDriver") as string,
                  required: true,
                  defaultValue: state?.firstDriverID,
                  options: (driverList ?? []).map((x: IDriverGet) => {
                    let titleDriver = x.driverInfo.driverName;
                    if (x.driverInfo?.driverSurname) {
                      titleDriver += " " + x.driverInfo.driverSurname;
                    }
                    return {
                      key: x.driverInfo.driverID,
                      text: titleDriver.toString(),
                    };
                  }),
                },
                {
                  type: "select",
                  name: "secondDriverID",
                  width: 33.33,
                  label: i18next.t("form:secondDriver") as string,
                  disabled: !state?.firstDriverID,
                  defaultValue: state?.secondDriverID,
                  options: (
                    driverList?.filter(
                      (x: IDriverGet) => x.driverInfo.driverID !== state?.firstDriverID
                    ) ?? []
                  ).map((x: IDriverGet) => {
                    let titleDriver = x.driverInfo.driverName;
                    if (x.driverInfo?.driverSurname) {
                      titleDriver += " " + x.driverInfo.driverSurname;
                    }
                    return {
                      key: x.driverInfo.driverID,
                      text: titleDriver.toString(),
                    };
                  }),
                },
                {
                  width: 10,
                  type: "custom",
                  name: "AssignDriver",
                  element: (
                    <div className="multi-form-button-add-record">
                      <Button
                        variant="contained"
                        onClick={() => {
                          SummonModal("fleet-driver-page-modal");
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </div>
                  ),
                },
                {
                  type: "select",
                  name: "vehicleID",
                  defaultValue: state?.vehicleID,
                  required: true,
                  disabled: disablePlateOption,
                  width: 33.33,
                  label: i18next.t("form:plate") as string,
                  options: availableVehicles.map(
                    (x: IVehicleReservationAvailableVehicle) => {
                      return {
                        key: x.vehicleID,
                        text: formatPlate(x.vehicleLicensePlate),
                      };
                    }
                  ),
                },
                {
                  type: "datetime",
                  defaultValue: state?.outgoingDT
                    ? state.outgoingDT
                    : state?.checkInOutgoingDT
                    ? state?.checkInOutgoingDT
                    : state?.checkOutOutgoingDT,
                  name: "outgoingDT",
                  disabledDates: disabledDates ?? [],
                  required: true,
                  label: i18next.t("form:dateOutgoing") as string,
                  maxDate: state?.incomingDT ? state.incomingDT : state?.reservationDTTo,
                  width: 33.33,
                },
                {
                  /* minDate: state?.outgoingDT
                    ? state.outgoingDT
                    : state?.checkInOutgoingDT
                    ? state?.checkInOutgoingDT
                    : state?.checkOutOutgoingDT, */
                  // disabledDates: disabledDates ?? [],
                  type: "datetime",
                  minDate: state?.outgoingDT,
                  defaultValue: state?.incomingDT
                    ? state.incomingDT
                    : state?.checkInIncomingDT
                    ? state?.checkInIncomingDT
                    : state?.checkOutIncomingDT,
                  name: "incomingDT",
                  required: true,
                  label: i18next.t("form:dateIncoming") as string,
                  width: 33.33,
                },
                {
                  type: "text",
                  name: "motivation",
                  defaultValue: state?.motivation,
                  label: i18next.t("form:motivation") as string,
                  width: 33.33,
                },
                {
                  type: "number",
                  width: 33.33,
                  hint: "min KM: " + (minKm && minKm > 0 ? minKm : "" ?? ""),
                  defaultValue: state?.incomingKM,
                  inputProps: { min: minKm, max: 1000000 },
                  required: true,
                  label: i18next.t("form:kmToIncoming") as string,
                  name: "incomingKM",
                  inputAdornament: { adornament: "Km" },
                },
                {
                  type: "select",
                  name: "fuelPercentage",
                  required: true,
                  inputAdornament: { adornament: "%" },
                  inputProps: { min: 1, max: 100 },
                  defaultValue: state?.fuelPercentage,
                  width: 33.33,
                  label: i18next.t("form:fuelIncoming") as string,
                  options: ["25", "50", "75", "100"].map((x: string) => {
                    return {
                      key: parseInt(x),
                      text: x + "%",
                    };
                  }),
                },
              ]}
              onChange={(data: any) => {
                setState({ ...state, ...data });
              }}
              suppressLayout
              suppressSubmit
              onSubmit={(data: any) => {
                let parseData = { ...data };

                delete parseData["AssignDriver"];
                parseData["vehicleReservationID"] = vehicleReservationID;
                parseData["isReservationDefinitive"] = state?.isReservationDefinitive;
                parseData["fuelPercentage"] = +data["fuelPercentage"];
                parseData["incomingKM"] = +data["incomingKM"];
                if (outgoingVehicleIDQuery) {
                  parseData["outgoingVehicleID"] = +outgoingVehicleIDQuery;
                }
                if (state?.incomingVehicleID) {
                  parseData["outgoingVehicleID"] = reservationList.find(
                    (x: IVehicleReservation) =>
                      x.incomingVehicleID === state?.incomingVehicleID
                  )?.outgoingVehicleID;
                  parseData["incomingVehicleID"] = +state?.incomingVehicleID;
                }
                ApiService.VehicleReservationController.VehicleReservationGetAvailableVehicle(
                  parseData["outgoingDT"],
                  parseData["incomingDT"],
                  loggedCompany?.companyID,
                  vehicleReservationID,
                  parseData["outgoingVehicleID"],
                  parseData["incomingVehicleID"],
                  (response: IAPIResponse) => {
                    if (response.error === null) {
                      if (response.payload && response.payload.length > 0) {
                        if (
                          response.payload.filter(
                            (x: IVehicleReservationAvailableVehicle) =>
                              x.vehicleID === +parseData.vehicleID
                          ).length > 0
                        ) {
                          if (state?.incomingVehicleID) {
                            updateIncoming(parseData);
                          } else {
                            insertIncoming(parseData);
                          }
                        } else {
                          ToastMessage(
                            i18next.t("error:vehicleNotMatchReservation"),
                            "error"
                          );
                        }
                      } else {
                        ToastMessage(
                          i18next
                            .t("error:RESERVATION_ALREADY_EXITS")
                            .replace(
                              "START_DATE",
                              displayUTC0_ISODate(parseData["outgoingDT"])
                            )
                            .replace(
                              "END_DATE",
                              displayUTC0_ISODate(parseData["incomingDT"])
                            ),
                          "error"
                        );
                      }
                    } else {
                      ToastMessage(response.error, "error");
                    }
                  }
                );
              }}
            />
          )}
          <div className="display-flex-end margin-vertical-medium">
          {!state?.incomingVehicleID && state?.outgoingVehicleID && (
              <Button
              color="error"
                sx={{ marginRight: "1em" }}
                disabled={processing || !state?.vehicleID}
                onClick={() => {
                  SummonModal("delete-fleet-incoming-vehicle-modal");
                }}
                variant="contained"
                endIcon={<DeleteIcon />}
              >
                {i18next.t("generic:delete")}
              </Button>
            )}
            <Button
              sx={{ marginRight: "1em" }}
              variant="outlined"
              disabled={!printLetterDelivery}
              onClick={() => {
                window.print();
              }}
            >
              {i18next.t("form:printLetterDelivery")}
            </Button>
            <Button
              sx={{ marginRight: "1em" }}
              variant="outlined"
              disabled={processing || !state?.vehicleID}
              onClick={() => {
                setForceLoadDamageVehicle(true);
                SummonModal("VehicleDamage-modal");
              }}
            >
              {i18next.t("form:insertDamangeVehicle")}
            </Button>
            <Button
              variant="contained"
              disabled={processing || !vehicleReservationID}
              onClick={() => {
                triggerFormValidation("fleet-incoming-vehicle");
              }}
            >
              {i18next.t("form:confirmIncomingVehicle")}
            </Button>
          </div>
        </div>
      </div>
      <VehicleDamageForm
        onlyImage={false}
        forceLoadDamageVehicle={forceLoadDamageVehicle}
        vehicleID={state?.vehicleID}
      />
      
      <SmartModal
        modalUniqueId="delete-fleet-incoming-vehicle-modal"
        title={i18next.t("generic:delete")}
        modalInnerComponent={
          <div>{i18next.t("message:vehicleReservationOutcomingDeleteMessage")}</div>
        }
        onAccept={() => {
          vehicleReservationOutcomingDelete(state?.outgoingVehicleID);
        }}
        onReject={() => {
          DismissModal("delete-fleet-incoming-vehicle-modal");
        }}
      />
      <SmartModal
        modalUniqueId="fleet-driver-page-modal"
        title={i18next.t("generic:titleAddDriver")}
        modalInnerComponent={
          <FormAssignedDriver
            loggedUser={loggedUser}
            data={[]}
            companyUser={loggedCompany}
            idModal="fleet-driver-page-modal"
            updateList={(data: any) => {
              let drivers_: IDriverGet[] = [...driverList];
              let data_: any = data;
              let addedDriver: any = {
                driverCostCenter: [],
                driverInfo: data_,
              };
              drivers_.push(addedDriver);
              setDriverList(drivers_);
            }}
          />
        }
      />
      {state?.vehicleID && (
        <FleetReservationDeliveryLetter
          vehicleInfo={currentVehicle[0]}
          driversData={{
            firstDriver: driverList.filter(
              (x: IDriverGet) => x.driverInfo.driverID === state?.firstDriverID
            )[0],
            secondDriver: driverList.filter(
              (x: IDriverGet) => x.driverInfo.driverID === state?.secondDriverID
            )[0],
          }}
        />
      )}
      <LoaderBackdrop
        loading={
          loading ||
          loadingReservation ||
          loadingIncoming ||
          loadingDriverList ||
          refreshForm ||
          loadingOutgoing ||
          loadingAvailableVehicles
        }
      />
    </div>
  );
};

export default Fleet__AdditionalServicesPage__IncomingVehicle;
