import i18next from "i18next";

import "./NotFoundPage.scss";

const NotFoundPage = () => {
  /* RETURN */
  return (
    <div className="not-found-wrap">
      <div className="not-found-center">
        <div className="not-found-title">{i18next.t("navigation:titleNotFoundPage")}</div>
        <div className="not-found-number">404</div>
        <div className="not-found-message">
          {i18next.t("navigation:subtitleNotFoundPage")}
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
