import { Box, Button, ButtonGroup } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import i18next from "i18next";
import DataGridWrap, { IDataGridColumnDef } from "../../../../../Components/DataGridWrap/DataGridWrap";
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import SmartModal, { DismissModal, SummonModal } from "../../../../../Components/SmartModal/SmartModal";
import { useEffect, useState } from "react";
import { ToastMessage } from "../../../../../Utils/Toastify";
import { IGenericDescriptor, IService, IServiceInsert, IServiceUpdate } from "../../../../../Models/IService";
import { useSelector } from "react-redux";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";
import { IUser } from "../../../../../Models/IUser";
import { GlobalState } from "../../../../../Reducers/RootReducer";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from '@mui/icons-material/EditOff';
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";
import MultiForm, { triggerFormValidation } from "../../../../../Components/MultiForm/MultiForm";
import MultiLangDescPicker from "../../../../../Components/MultiLangDescPicker/MultiLangDescPicker";

const AdminPage__Services = () => {
  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [services, setServices] = useState<IService[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentService, setCurrentService] = useState<any>(null);

  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  const loadServices = () => {
    if(loggedUser){
      setLoadingServices(true);
      ApiService.ServiceController.ServiceGetData(
        null, 
        null, 
        true,
        undefined,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let rawList: IService[] = response.payload;
            let joinedList: any = {};

            for (let i = 0; i < rawList.length; i++) {
              let el: IService = { ...rawList[i] };

              if (!joinedList[rawList[i].serviceID.toString()]) {
                (el as any)["serviceTranslation"] = [
                  {
                    languageID: el.languageID,
                    name: el.serviceName,
                    description: el.serviceDescription,
                    note: el.serviceNote,
                  },
                ];
                joinedList[rawList[i].serviceID.toString()] = el;
              } else {
                joinedList[rawList[i].serviceID.toString()].serviceTranslation.push(
                  {
                    languageID: el.languageID,
                    name: el.serviceName,
                    description: el.serviceDescription,
                    note: el.serviceNote,
                  }
                );
              }
            }
            setServices(Object.values(joinedList));
          } else {
            ToastMessage(response.error, "error");
          }
          setLoadingServices(false);
        }
      );
    }
  };

  const updateService = (data: IServiceUpdate) => {
    if(loggedUser){
      setProcessing(true);
      ApiService.ServiceController.ServiceUpdateData(
        data,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(i18next.t("message:serviceContractSuccessfullUpdated"), "success");
            loadServices();
            DismissModal("service-page-modal");
          } else {
            ToastMessage(response.error, "error");
          }
          setProcessing(false);
        }
      );
    }
  };

  const insertService = (body: IServiceInsert) => {
    setProcessing(true);
    ApiService.ServiceController.ServiceInsertData(
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:serviceContractSuccessfullAdded"), "success");
          loadServices();
          DismissModal("service-page-modal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteService = (serviceID: number) => {
    setProcessing(true);
    ApiService.ServiceController.ServiceDeleteData(
      serviceID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(i18next.t("message:serviceContractSuccessfullDeleted"), "success");
          loadServices();
          DismissModal("service-page-modal");
          DismissModal("service-page-delete-modal");
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            let service = services.filter(
              (x: any) => x.serviceID === props.row.serviceID
            )[0];
            setIsEdit(true);
            setCurrentService(service);
            SummonModal("service-page-modal");
          }}
        >
          {props.row.stored ?
            <EditOffIcon className="icon-table" />
          :
            <EditIcon className="icon-table" />
          }
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: "",
      renderCell: renderEdit,
      hideExport: true,
      type: 'custom'
    },
    {
      field: "serviceName",
      headerName: i18next.t("form:nameService"),
    },
    {
      field: "serviceDescription",
      headerName: i18next.t("form:description"),
    },
  ];

  /* ROWS */
  const rowsDef = [];
  
  for (let i = 0; i < services.length; i++) {
    rowsDef.push({
      id: "edit",
      serviceID: services[i].serviceID,
      serviceName: services[i].serviceName,
      serviceDescription: services[i].serviceDescription,
      stored: services[i].stored
    });
  }

  const servButtons = () => {
    let btns = [];

    btns.push({
      text: isEdit ? i18next.t("form:save_langs") : i18next.t("form:save"),
      onClick: () => {
        triggerFormValidation("service-form-modal");
      },
    });

    if(isEdit){
      btns.push({
        text: i18next.t("generic:delete"),
        onClick: () => {
          SummonModal("service-page-delete-modal");
        },
      });
    }
    return btns;
  };

  useEffect(() => {
    loadServices();
  }, []);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_service_contract")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentService(null);
                setIsEdit(false);
                SummonModal("service-page-modal");
              }}
            >
              {i18next.t("navigation:_add_service")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>
        <SmartModal
          modalUniqueId="service-page-modal"
          title={!currentService?.serviceID ? i18next.t("generic:titleAddService") : i18next.t("generic:titleEditService")}
          modalInnerComponent={
            <MultiForm
              styles={{width: "60vw"}}
              formId="service-form-modal"
              inputs={[
                {
                  type: "custom",
                  width: 100,
                  name: "serviceTranslation",
                  element: (
                    <div>
                      <MultiLangDescPicker
                        noNotes
                        disabled={currentService?.stored}
                        defaultValue={
                          currentService ? currentService.serviceTranslation : []
                        }
                        onChange={(data: IGenericDescriptor[]) => {
                          let data_ = currentService ? { ...currentService } : {};
                          data_.serviceTranslation = data;
                          setCurrentService(data_);
                        }}
                      />
                    </div>
                  ),
                },
              ]}
              onSubmit={(data: any) => {
                let data_ = currentService
                  ? { ...data, ...currentService }
                  : { ...data };
                if (data_.serviceID) {
                  updateService(data_);
                } else {
                  insertService(data_);
                }
              }}
              suppressLayout
              suppressSubmit
            />
          }
          buttons={servButtons()}
        />
        <SmartModal
          modalUniqueId="service-page-delete-modal"
          title={i18next.t("generic:titleRecordModal")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModal")
                  .replace("CURRENT_ITEM_LIST", currentService?.serviceID),
              }}
            />
          }
          onAccept={() => {
            deleteService(currentService?.serviceID);
          }}
          onReject={() => {}}
        />
        <LoaderBackdrop loading={loadingServices || processing} />
      </div>
    </div>
  );
};

export default AdminPage__Services;