/* REACT */
import { useEffect, useState } from "react";

/* STYLE */
import "./AdminPage_Menu.scss";

/* LIBRARIES */
import i18next from "i18next";

/* SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* UTILS */
import { ToastMessage } from "../../../../../Utils/Toastify";

/* MODELS */
import {
  IGET_Navigation_Menu,
  IUPDATE_Navigation_Menu,
} from "../../../../../Models/IMenu";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import MultiForm, {
  IMultiFormField,
} from "../../../../../Components/MultiForm/MultiForm";

/* MUI */
import { Box, Button, Checkbox } from "@mui/material";

const AdminPage__Menu = () => {
  /* STATES */
  // Form data
  const [formData, setFormData] = useState<any>([]);
  // Menu
  const [menu, setMenu] = useState<IGET_Navigation_Menu[]>([]);
  // Roles
  const [roles, setRoles] = useState<any[]>([]);
  // Render menu
  const [renderMenu, setRenderMenu] = useState<any>([]);
  // Current selection
  const [currentSelection, setCurrentSelection] = useState<any>([]);
  // Loading
  const [loadingGetRoles, setLoadingGetRoles] = useState<boolean>(false);
  const [loadingGetMenu, setLoadingGetMenu] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  /* API */
  // Get navigation menu roles
  const GET_Navigation_Menu_Roles = () => {
    setLoadingGetRoles(true);

    ApiService.MenuController.GET_Navigation_Menu_Roles((response: IAPIResponse) => {
      if (response.error === null) {
        setRoles(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingGetRoles(false);
    });
  };

  // Get navigation menu
  const GET_Navigation_Menu = (role: string) => {
    setLoadingGetMenu(true);

    ApiService.MenuController.GET_Navigation_Menu(role, (response: IAPIResponse) => {
      if (response.error === null) {
        // Payload
        const payload: any = response.payload;
        payload.map((x: any) => {
          if (x.fields.length > 1) {
            x.fields[0]['parent'] = true
          }
        });
        // Set menu
        setMenu(payload);

        // Init current status for update
        const initStatus: any[] = [];

        response.payload.map((x: any) =>
          x.fields.map((k: any) => initStatus.push({ name: k.name, enabled: k.enabled }))
        );

        setCurrentSelection(initStatus);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingGetMenu(false);
    });
  };

  // Update navigation menu
  const UPDATE_Navigation_Menu = (data: IUPDATE_Navigation_Menu) => {
    setLoadingUpdate(true);

    ApiService.MenuController.UPDATE_Navigation_Menu(data, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage(i18next.t("message:updateSuccess"), "success");

        if (data.role === "FleetSupportAdmin") {
          window.location.reload();
        }
      } else {
        ToastMessage(i18next.t("error:ERROR"), "error");
      }

      setLoadingUpdate(false);
    });
  };

  /* USE EFFECT */
  // Render menu roles dropdown
  useEffect(() => {
    GET_Navigation_Menu_Roles();
  }, []);

  // Render menu
  useEffect(() => {
    GET_Navigation_Menu(formData?.roleName || "All");
  }, [formData?.roleName]);

  /* MULTIFORM */
  // Function to build the Multiform
  const multiformInputs = () => {
    let multiformInputs: IMultiFormField[] = [];

    let optionsForm = roles.map((x: any[]) => {
      return {
        key: `${x}`,
        text: `${x}`,
      };
    });

    multiformInputs.push(
      // Roles
      {
        className: "modal-width-extra-small",
        type: "select",
        name: "roleName",
        defaultValue: formData?.roleName || "All",
        currentValue: formData?.roleName || "All",
        label: `${i18next.t("form:role")}`,
        options: optionsForm,
      }
    );

    // Return the full and complete Multiform
    return multiformInputs;
  };

  // Build menu function
  const renderMenuFunc = () => {
    return menu.map((x: any, i: number) => {
      return (
        <div className="Menu__Box" key={i}>
          <span className="Menu__Title">{x.description}</span>

          {x.fields.map((item: any, k: number) => {
            return (
              <div className={"Menu__Item" + (!item.parent ? " Child__Item" : "")} key={k}>
                <Checkbox
                  checked={
                    currentSelection.filter((x: any) => x.name === item.name)[0].enabled
                  }
                  onChange={(_, checked) => {
                    const newArr = currentSelection.map((x: any) => {
                      if (x.name === item.name) {
                        return { ...x, enabled: checked };
                      }

                      return x;
                    });

                    setCurrentSelection(newArr);
                  }}
                />

                {item.description}
              </div>
            );
          })}
        </div>
      );
    });
  };

  // Render menu div
  useEffect(() => {
    setRenderMenu(renderMenuFunc());
  }, [currentSelection]);

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_menu")} />

      <div className="dashboardForm__box">
        <Box className="dashboardForm__data" style={{ marginTop: 0 }}>
          <div className="display-flex-horizontal-space-between">
            <MultiForm
              suppressLayout
              suppressSubmit={true}
              classNameForm="multi-form-flex"
              formId={"FormAdminMenu"}
              formTitle={"FormAdminMenu"}
              inputs={multiformInputs()}
              onChange={(data: any) => {
                setFormData(data);
              }}
            />

            <Button
              variant="contained"
              style={{ marginRight: "1em" }}
              onClick={() =>
                UPDATE_Navigation_Menu({
                  role: formData?.roleName,
                  menu: currentSelection,
                })
              }
            >
              {i18next.t("form:save")}
            </Button>
          </div>

          {currentSelection && renderMenu}
        </Box>
      </div>

      <LoaderBackdrop loading={loadingGetRoles || loadingGetMenu || loadingUpdate} />
    </div>
  );
};

export default AdminPage__Menu;
