/* LIBRARIES */
import i18next from "i18next";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import KProjection__StatisticsPage_Body from "./KProjection__StatisticsPage_Body";

import "./KProjection__StatisticsPage.scss";

/* COMPONENT */
const KProjection__StatisticsPage = () => {
  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard
        title={i18next.t("navigation:_statistics_projection")}
      />

      <KProjection__StatisticsPage_Body />
    </div>
  );
};

export default KProjection__StatisticsPage;
