import React, {useEffect, useState} from "react";
import "./ToolBar.scss";
import {useLocation, useNavigate} from "react-router-dom";
import AppRoutes from "../../Costants/AppRoutes";
import {MenuButton} from "./MenuButton";
import i18next from "i18next";
import {FFButtonContainer} from "../BannerVideoComponent/BannerVideoComponent";
import {IUser} from "../../Models/IUser";
import {useSelector} from "react-redux";
import {GlobalState} from "../../Reducers/RootReducer";
import FlagPicker from "../FlagPicker/FlagPicker";
import {Button} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {userRoleFits} from "../../Utils/Session";
import ApiService from "../../Services/ApiService";
import {IStepperStep} from "../../Models/IStepperBody";


const ToolBar: React.FC = () => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const [pendingRegistration, setPendingRedistration] = useState<boolean>(false);

    const loggedUser: IUser | undefined = useSelector(
        (state: GlobalState) => state.user.currentUser
    );
    const currentStep: IStepperStep | undefined = useSelector(
        (state: GlobalState) => state.generic.currentLastStep
    );

    // random API used to test the token validity
    const testUserTokenIsValid = () => {
        ApiService.StepperController.StepperGetData();
    };

    useEffect(() => {
        if (loggedUser && currentStep) {
            if (!currentStep.registrationCompleted) {
                setPendingRedistration(true);
            }

            testUserTokenIsValid();
        }
    }, []);

    /**
     * Function to handle navigation menu, and open/close hamburger menu
     * @param route link to the correct menu voice
     */
    const handleNavigation = (route: string) => {
        setIsOpen(false);
        navigate(route);
    }

    const useScrollToTop = () => {
        const { pathname } = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
    };

    useScrollToTop();

    return (
        <div>
            <nav className="navbar">

                {/* Main logo navbar */}
                <div className="logo" role="button"
                     onClick={() => navigate(AppRoutes.HOME_PAGE_ROUTE)}>
                    <img className="logo-dark" src="/Images/logo_homepage.svg" alt='logo'/>
                </div>

                {/* Hamburger menu with animation */}
                <MenuButton isOpen={isOpen} onToggle={() => setIsOpen((prev) => !prev)}/>
            </nav>

            {/* Panel Menu Overlay */}
            {isOpen &&
                <div className={`ff-sidebar ${isOpen ? 'sidebar--open' : ''}`}>
                    <ul className="nav-links">
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.HOME_PAGE_ROUTE)}>{i18next.t("navigation:navTabHome")}</li>
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.WHO_WE_ARE_ROUTE)}>{i18next.t("intro:companyMenu")}</li>
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.FUNCITIONALITIES_ROUTE)}>{i18next.t('intro:functionality')}</li>
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.RATES_ROUTE)}>{i18next.t('intro:ratesTitle')}</li>
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.WHYUS_ROUTE)}>{i18next.t('intro:faq')}</li>
                        <li role="button" tabIndex={-1} onClick={() => handleNavigation(AppRoutes.CONTACTS_ROUTE)}>{i18next.t('intro:companyContacts')}</li>
                        <FlagPicker isPublic/>
                        <FFButtonContainer className="ff-button" onClick={() => {
                            navigate(loggedUser ? AppRoutes.LOGOUT_ROUTE : AppRoutes.LOGIN_ROUTE);
                        }}>
                            {loggedUser
                                ? i18next.t("navigation:logout")
                                : i18next.t("navigation:login")}
                        </FFButtonContainer>
                    </ul>
                </div>
            }


            {loggedUser && (
                <div className="ff-container-warning-logged">
                    {pendingRegistration && (
                        <div className="ff-warning-on-registration">
                            <div>{i18next.t("navigation:continue_registration_title")}</div>
                            <FFButtonContainer className="ff-button-dashboard"
                                onClick={() => {
                                    navigate(AppRoutes.CONTINUE_REGISTRATION_ROUTE);
                                }}
                            >
                                {i18next.t("navigation:continue_registration")}
                            </FFButtonContainer>
                        </div>
                    )}

                    {!pendingRegistration && (
                        <div className="ff-warning-on-registration">
                            <div>{i18next.t("navigation:no_continue_registration_title")}</div>
                            <FFButtonContainer className="ff-button-dashboard"
                                onClick={() => {
                                    if (userRoleFits(["FleetSupportAdmin", "Broker", "Driver"])) {
                                        navigate(
                                            AppRoutes.INTERNAL_DASHBOARD + AppRoutes.DASHBOARD___MESSAGING_ROUTE
                                        );
                                    } else {
                                        navigate(
                                            AppRoutes.INTERNAL_DASHBOARD +
                                            AppRoutes.DASHBOARD___EXPIRY_CALENDAR_ROUTE
                                        );
                                    }
                                }}
                            >{i18next.t("navigation:no_continue_registration")}
                            </FFButtonContainer>
                        </div>
                    )}
                </div>
            )}

        </div>
    )
}

export default ToolBar;