/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";
import { ITicketIssuingInstitutionGet } from "../../../../../Models/ITicketIssuingInstitutionGet";
import { ICompanyGet } from "../../../../../Models/ICompany";
import { IFleetPlan } from "../../../../../Models/IFleetPlan";
import EditIcon from "@mui/icons-material/Edit";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__emitEntities.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { JSONPrint } from "../../../../../Utils/Decoder";
import { IconButton } from "rsuite";
import MultiForm, {
  triggerFormValidation,
} from "../../../../../Components/MultiForm/MultiForm";
import { IFleetPlanUpdateBody } from "../../../../../Models/IFleetPlanUpdateBody";
import { IFleetPlanSetBody } from "../../../../../Models/IFleetPlanSetBody";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const AdminPage__emitEntities = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* REDUCER OF COMPANY */
  const companyUser: ICompanyGet | undefined = useSelector(
    (state: GlobalState) => state.user.mainCompany
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [openEntity, setOpenEntity] =
    useState<ITicketIssuingInstitutionGet | null>(null);
  const [entities, setEntities] = useState<ITicketIssuingInstitutionGet[]>([]);

  const loadEntities = () => {
    setLoading(true);
    ApiService.TicketController.GetTicketIssuingInstitutionList(
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setEntities(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoading(false);
      }
    );
  };

  const insertEntity = (data: ITicketIssuingInstitutionGet) => {
    setProcessing(true);
    ApiService.TicketController.InsertTicketIssuingInstitutionList(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("EntityAddEditModal");
          loadEntities();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const updateEntity = (data: ITicketIssuingInstitutionGet) => {
    setProcessing(true);
    ApiService.TicketController.UpdateTicketIssuingInstitutionList(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("EntityAddEditModal");
          loadEntities();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  const deleteEntity = (ticketIssuingInstitutionID: number) => {
    setProcessing(true);
    ApiService.TicketController.DeleteTicketIssuingInstitutionList(
      ticketIssuingInstitutionID,
      (response: IAPIResponse) => {
        if (response.error === null) {
          DismissModal("EntityAddEditModal");
          loadEntities();
        } else {
          ToastMessage(response.error, "error");
        }
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    loadEntities();
  }, []);

  // Edit row
  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div style={props.row.stored ? { opacity: "0.2" } : {}}>
        <Button
          disabled={props.row.stored}
          onClick={() => {
            setOpenEntity(props.row);
            SummonModal("EntityAddEditModal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  const COLUMNS = [
    {
      field: "edit",
      headerName: i18next.t("generic:edit"),
      renderCell: renderEdit,
      type: 'custom',
      hideExport: true,
    },
    {
      field: "ticketIssuingInstitutionName",
      headerName: i18next.t("navigation:_issuing_istitute_name") as string,
    },
    {
      field: "institutionType",
      headerName: i18next.t("navigation:_issuing_istitute_type") as string,
    },
    {
      field: "addressProv",
      headerName: i18next.t("navigation:_issuing_istitute_prov") as string,
    },
    {
      field: "addressStreet",
      headerName: i18next.t("navigation:_issuing_istitute_str") as string,
    },
    {
      field: "ticketIssuingInstitutionPEC",
      headerName: i18next.t("navigation:_issuing_istitute_pec") as string,
    },
  ];

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_admin_entity")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setOpenEntity(null);
                SummonModal("EntityAddEditModal");
              }}
            >
              {i18next.t("form:nuovo")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={COLUMNS} rows={entities} />
        </Box>
      </div>
      <SmartModal
        buttons={[
          {
            disabled: !openEntity?.ticketIssuingInstitutionID,
            text: i18next.t("generic:delete"),
            onClick: () => {
              SummonModal("ticket-issue-form-modal-delete")
            }
          },
          {
            text: i18next.t("form:save"),
            onClick: () => {
              triggerFormValidation("EntityAddEditForm");
            },
          }
        ]}
        title={
          openEntity === null
            ? i18next.t("navigation:issueHandle_new")
            : i18next.t("navigation:issueHandle_edit")
        }
        modalUniqueId="EntityAddEditModal"
        modalInnerComponent={
          <div>
            <MultiForm
              suppressLayout
              suppressSubmit
              formTitle={""}
              inputs={[
                {
                  width: 50,
                  type: "text",
                  name: "ticketIssuingInstitutionName",
                  required: true,
                  label: i18next.t("navigation:_issuing_istitute_name") ?? "",
                  defaultValue: openEntity?.ticketIssuingInstitutionName,
                },
                {
                  width: 50,
                  type: "text",
                  name: "institutionType",
                  required: true,
                  label: i18next.t("navigation:_issuing_istitute_type") ?? "",
                  defaultValue: openEntity?.institutionType,
                },
                {
                  width: 50,
                  type: "text",
                  name: "addressCity",
                  required: true,
                  label: i18next.t("form:city") ?? "",
                  defaultValue: openEntity?.addressCity,
                },
                {
                  width: 50,
                  type: "text",
                  name: "addressStreet",
                  required: true,
                  label: i18next.t("navigation:_issuing_istitute_str") ?? "",
                  defaultValue: openEntity?.addressStreet,
                },
                {
                  width: 50,
                  type: "text",
                  name: "addressCAP",
                  required: true,
                  label: i18next.t("form:postalCode") ?? "",
                  defaultValue: openEntity?.addressCAP,
                  inputProps: { maxLength: 20 },
                },
                {
                  width: 50,
                  type: "text",
                  name: "addressProv",
                  required: true,
                  label: i18next.t("navigation:_issuing_istitute_prov") ?? "",
                  defaultValue: openEntity?.addressProv,
                },
                {
                  width: 50,
                  type: "email",
                  name: "ticketIssuingInstitutionPEC",
                  required: true,
                  label: i18next.t("navigation:_issuing_istitute_pec") ?? "",
                  defaultValue: openEntity?.ticketIssuingInstitutionPEC,
                },
              ]}
              formId="EntityAddEditForm"
              onSubmit={(data: any) => {
                let dt = openEntity ? { ...openEntity, ...data } : { ...data };
                if (dt.ticketIssuingInstitutionID) {
                  updateEntity(dt);
                } else {
                  insertEntity(dt);
                }
              }}
            />
          </div>
        }
      />
      {openEntity && (
        <SmartModal
          modalUniqueId="ticket-issue-form-modal-delete"
          title={i18next.t("generic:titleRecordModal")}
          modalInnerComponent={
            <p
              dangerouslySetInnerHTML={{
                __html: i18next
                  .t("generic:descriptionRecordModal")
                  .replace("CURRENT_ITEM_LIST", "#" + openEntity?.ticketIssuingInstitutionID),
              }}
            />
          }
          onAccept={() => {
            deleteEntity(openEntity?.ticketIssuingInstitutionID);
          }}
          onReject={() => {}}
        />
      )}
      <LoaderBackdrop loading={loading || processing} />
    </div>
  );
};

export default AdminPage__emitEntities;
