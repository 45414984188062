import ApiEndPoints from "../../Costants/ApiEndPoints";
import {
  IVehicleReservationInsert,
  IVehicleReservationUpdate,
} from "../../Models/IVehicleReservation";

import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../Internal/AjaxService";

const VehicleReservationController = {
  VehicleReservationGet: async (
    companyID: number,
    vehicleID: number,
    driverID: number,
    vehicleReservationID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_GET;
    let parameters: string[] = [];
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleID) {
      parameters.push(`vehicleID=${vehicleID}`);
    }
    if (driverID) {
      parameters.push(`driverID=${driverID}`);
    }
    if (vehicleReservationID) {
      parameters.push(`vehicleReservationID=${vehicleReservationID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationInsert: async (
    body: IVehicleReservationInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_INSERT;
    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationUpdate: async (
    body: IVehicleReservationUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_UPDATE;
    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationDelete: async (
    vehicleReservationID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_DELETE;
    request.method = "DELETE";
    request.payload = {
      vehicleReservationID,
    };
    request.extraHeaders = { "Content-Type": "application/json" };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  VehicleReservationGetAvailableVehicle: async (
    startDate: string,
    endDate: string,
    companyID: number,
    vehicleReservationID: number,
    outgoingVehicleID: number,
    incomingVehicleID: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.port = "7236";
    request.url = ApiEndPoints.VEHICLE_RESERVATION_GET_VEHICLE_AVAILABLE;
    let parameters: string[] = [];
    if (startDate) {
      parameters.push(`startDate=${startDate}`);
    }
    if (endDate) {
      parameters.push(`endDate=${endDate}`);
    }
    if (companyID) {
      parameters.push(`companyID=${companyID}`);
    }
    if (vehicleReservationID) {
      parameters.push(`vehicleReservationID=${vehicleReservationID}`);
    }
    if (outgoingVehicleID) {
      parameters.push(`outgoingVehicleID=${outgoingVehicleID}`);
    }
    if (incomingVehicleID) {
      parameters.push(`incomingVehicleID=${incomingVehicleID}`);
    }
    request.url += "?" + parameters.join("&");
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VehicleReservationController;
