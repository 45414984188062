/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MODELS */
import { IUser } from "../../../../../Models/IUser";

/* REDUCERS */
import { GlobalState } from "../../../../../Reducers/RootReducer";

/* STYLE */
import "./AdminPage__Suppliers.scss";

/* LIBRARY */
import i18next from "i18next";

/* API SERVICES */
import ApiService from "../../../../../Services/ApiService";
import { IAPIResponse } from "../../../../../Services/Internal/AjaxService";

/* COMPONENTS */
import HeaderPageDashboard from "../../../../../Components/HeaderPageDashboard/HeaderPageDashboard";
import LoaderBackdrop from "../../../../../Components/LoaderBackdrop/LoaderBackdrop";
import { ToastMessage } from "../../../../../Utils/Toastify";
import DataGridWrap, {
  IDataGridColumnDef,
} from "../../../../../Components/DataGridWrap/DataGridWrap";
import SmartModal, {
  SummonModal,
} from "../../../../../Components/SmartModal/SmartModal";
import AdminPage__SuppliersForm from "./AdminPage__SuppliersForm";

/* MUI */
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import { dateIsPast } from "../../../../../Components/MultiForm/SpecialInputs/StrongDatePicker";

/* COMPONENT */
const AdminPage__Suppliers = () => {
  /* REDUCER OF LOGGED USER */
  const loggedUserNorm: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentUser
  );
  const loggedUserImp: IUser | undefined = useSelector(
    (state: GlobalState) => state.user.currentImpUser
  );
  var loggedUser: IUser | undefined = loggedUserNorm;
  if (loggedUserImp) {
    // expired imp
    if (!dateIsPast(loggedUserImp.expireImpDT)) {
      loggedUser = loggedUserImp;
    }
  }

  /* STATES */
  const [loadingSuppliers, setLoadingSuppliers] = useState<boolean>(false);

  // Container for supplier list
  const [list, setList] = useState<any>([]);
  const [currentSupplier, setCurrentSupplier] = useState<any>(null);

  /* APIs */
  // API to get supplier data
  const loadSuppliersList = () => {
    setLoadingSuppliers(true);
    ApiService.SupplierController.SupplierGetGeneric(
      null,
      null,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setList(response.payload);
        } else {
          ToastMessage(response.error, "error");
        }
        setLoadingSuppliers(false);
      }
    );
  };

  /* USE EFFECT HOOK */
  useEffect(() => {
    loadSuppliersList();
  }, []);

  /* FUNCTIONS */
  // Edit row
  const renderEdit = (props: GridRenderCellParams) => {
    return (
      <div style={props.row.stored ? { opacity: "0.2" } : {}}>
        <Button
          onClick={() => {
            let supplier = list.filter(
              (x: any) => x.genericSupplierID === props.row.genericSupplierID
            )[0];

            setCurrentSupplier(supplier);
            SummonModal("supplier-page-modal");
          }}
        >
          <EditIcon className="icon-table" />
        </Button>
      </div>
    );
  };

  /* COLUMNS */
  const columnsDef: IDataGridColumnDef[] = [
    {
      field: "edit",
      headerName: i18next.t("generic:edit"),
      renderCell: renderEdit,
      type: 'custom'
    },
    {
      field: "supplierName",
      headerName: i18next.t("form:nameSupplier"),
    },
    {
      field: "supplierVAT",
      headerName: i18next.t("form:vatNumber"),
    },
    {
      field: "supplierPEC",
      headerName: i18next.t("form:pec"),
    },
    {
      field: "supplierPhone",
      headerName: i18next.t("form:contactCenter"),
    },
    {
      field: "supplierAddressCity",
      headerName: i18next.t("form:city"),
    },
  ];

  /* ROWS */
  const rowsDef = [];

  for (let i = 0; i < list.length; i++) {
    rowsDef.push({
      id: i,
      genericSupplierID: list[i].genericSupplierID,
      supplierTypeID: list[i].supplierTypeID,
      supplierName: list[i].supplierName,
      supplierVAT: list[i].supplierVAT,
      supplierPEC: list[i].supplierPEC,
      supplierPhone: list[i].supplierPhone,
      supplierAddressCity: list[i].supplierAddressCity,
    });
  }

  /* RETURN */
  return (
    <div className="dashboardForm">
      <HeaderPageDashboard title={i18next.t("navigation:_data_suppliers")} />

      <div className="dashboardForm__box">
        <div className="display-flex-end margin-bottom-small">
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => {
                setCurrentSupplier("");
                SummonModal("supplier-page-modal");
              }}
            >
              {i18next.t("navigation:_add_supplier")}
            </Button>
          </ButtonGroup>
        </div>

        <Box className="dashboardForm__data">
          <DataGridWrap headers={columnsDef} rows={rowsDef} />
        </Box>

        <SmartModal
          hideButtons={true}
          modalUniqueId="supplier-page-modal"
          title={i18next.t("generic:titleAddSupplier")}
          modalInnerComponent={
            <AdminPage__SuppliersForm
              loggedUser={loggedUser}
              data={currentSupplier ?? []}
              idModal="supplier-page-modal"
              doRefreshForm={() => {
                loadSuppliersList();
              }}
            />
          }
        />

        <LoaderBackdrop loading={loadingSuppliers} />
      </div>
    </div>
  );
};

export default AdminPage__Suppliers;
